import { React, useState, useEffect } from 'react';
import styled from 'styled-components';

export default function SubscriptionColumnView() {
  return (
    <TopLevelWrapper>
      {/* <Column style={{marginRight: '28px', marginLeft: '28px'}}>번호</Column> */}
      <Column style={{marginRight: '218px', marginLeft: '28px'}}>회원권</Column>
      <Column style={{marginRight: '62px'}}>시작일</Column>
      <Column style={{marginRight: '62px'}}>종료일</Column>
      <Column style={{marginRight: '118px'}}>결제 금액</Column>
      <Column style={{marginRight: '54px'}}>구독 상태</Column>
      <Column style={{marginRight: '28px'}}>결제 횟수</Column>
      {/* <Column >상태</Column> */}
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  // justify-content: center;
  
  // justify-content: center;
  align-items: center;

  width: 1020px;
  margin-top: 34px;
  margin-bottom: 20px;
  
  transition: all 0.3s ease-in-out;
`;

const Column = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;
`;
