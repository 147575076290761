import { React, useRef, useImperativeHandle, forwardRef } from 'react';
import styled from 'styled-components';

const LoginSubject = forwardRef((props, ref) => {
	const {onClickLoginCategory} = props;

	const TopLevelRef = useRef()

	useImperativeHandle(ref, () => ({
		setLocation: (val) => {
			TopLevelRef.current.style.transform = `translateX(${val}%)`;
		},
		setOpacity: (val) => {
			TopLevelRef.current.style.opacity = val;
		}
	}));

	return (
		<TopLevelWrapper ref={TopLevelRef}>
			<CategoryWrapper>
				<Category>로그인</Category>
				<PaginationWrapper>
					<Pagination />
					<Pagination />
					<Pagination />
					<Pagination />
				</PaginationWrapper>
			</CategoryWrapper>
			<CategoryExplanationWrapper>
				<CategoryExplanation>새로운 사용자이신가요?</CategoryExplanation>
				<CategoryExplanation className='SignUp' onClick={onClickLoginCategory}>회원 가입</CategoryExplanation>
			</CategoryExplanationWrapper>
		</TopLevelWrapper>
	)
})

export default LoginSubject;

const TopLevelWrapper = styled.div`
	display: flex;
	flex-direction: column;

  transition: all 0.5s ease-in-out;
`;

const CategoryWrapper = styled.div`
	display: flex;
	flex-direction: row;

	align-items: center;
`;

const Category = styled.p`
	font-family: NotoSansKR-Bold;
	font-size: 24px;
	// font-size: max(18px, 1.25vw);
	// line-height: 34.75px;
 
	margin: 0 0 0 0;

	cursor: default;
`;

const PaginationWrapper = styled.div`
	display: flex;
	flex-direction: row;

	margin: 0 0 0 262px;
	// margin-top: 0px;
	// margin-right: 0px;
	// margin-bottom: 0px;
	// margin-left: 13.64583333333333vw;
	// margin-left: 0px;

	gap: 4px;
	opacity: 0;
`;

const Pagination = styled.div`
	width: 25px;
	height: 8px;

	border-radius: 2px;
	background-color: #33C8FF;
`;

const CategoryExplanationWrapper = styled.div`
	display: flex;
	flex-direction: row;

	margin-top: 13px;
`;

const CategoryExplanation = styled.p`
	font-family: ${props => props.className === 'SignUp' ? `NotoSansKR-Medium` : `NotoSansKR-Regular`}; 
	font-size: 14px;
	// font-size: max(12px, 0.7291666666666667vw);
	// line-height: 20.27px;
	color: ${props => props.className === 'SignUp' ? `#FF8B48` : `#777777`};
	
	margin: 0 8px 0 0;

	:hover {
		cursor: ${props => props.className === 'SignUp' ? `pointer` : `default`};
	}
`;