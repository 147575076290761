import { React, useState } from 'react';
import styled from 'styled-components';
import PasswordChangeModal from '../../Model/Individual/MySettingModel/PasswordChangeModal';

function PasswordInputView(props) {
  const { isClicked, column, content } = props;

  const [isClickedChangePassword, setIsClickedChangePassword] = useState(false);

  return (
    <TopLevelWrpper>
      { isClickedChangePassword && <PasswordChangeModal onClickExit={() => setIsClickedChangePassword(false)}/> }
      <Column>{column}</Column>
      <InputBox /*isClicked={isClicked}*/ >
        <Content>{content}</Content>
        <ChangeButton onClick={() => setIsClickedChangePassword(true)}>변경</ChangeButton>
      </InputBox>
    </TopLevelWrpper>
  )
}

export default PasswordInputView;

const TopLevelWrpper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;
`;

const InputBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: 8px;

  width: 363px;
  height: 30px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  // background-color: ${props => props.isClicked ? `rgba(255, 139, 72, 0.7)` : `#FFFFFF`};
  background-color: #FFFFFF;
`;

const Column = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #595959;

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  } 
`;

const Content = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 8px;

  :hover {
    // cursor: pointer;
    cursor: default;
  } 
`;

const ChangeButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 50px;
  height: 100%;

  border-radius: 0px 8px 8px 0px;
  background-color: #5BD98A;
  
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  // margin: 0 0 0 8px;

  :hover {
    cursor: pointer;
  } 
`;