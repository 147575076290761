import { React, useRef, forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import FetchModule from '../../Share/Network/FetchModule';

import LockerMemberSearchInput from '../../../Component/LockerComponent/LockerMemberSearchInput';
import LockerStartInput from '../../../Component/LockerComponent/LockerStartInput';
import CalenderInput from '../../../Component/LockerComponent/LockerCalenderInput';

const LockerHistoryModal = forwardRef((props, ref) => {
  const { exitClick, saveClick, data} = props;

  const history = useHistory();

  const [dataList, setDataList] = useState([]);

  const MemberRef = useRef();
  const StartCalenderRef = useRef();
  const EndCalenderRef = useRef();
  const PriceRef = useRef();
  const CardRef = useRef();
  const CashRef = useRef();

  useImperativeHandle(ref, () => ({
		getMemberData: () => {
      return MemberRef.current.getValue();
    },
		getStartDate: () => {
      return StartCalenderRef.current.getValue();
    },
		getEndDate: () => {
      return EndCalenderRef.current.getValue();
    },
		getPrice: () => {
      return PriceRef.current.getValue();
    },
		getCard: () => {
      return CardRef.current.getValue();
    },
		getCash: () => {
      return CashRef.current.getValue();
    },
	}));

  // Modal Page Scroll Controll //
  useEffect(() => {
    document.body.style= `overflow: hidden`;
    return () => document.body.style = `overflow: auto`;
  }, [])

  useEffect(() => {
    var requestData = {
      lockerId: data.id
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postData('locker/usage/', 'POST', requestData);
        // console.log(responseData.data);
        if (responseData.status === 200) {
          setDataList(responseData.data);
        }
        else if (responseData.status === 401) {
          alert('로그인 후 다시시도하세요');
          history.push('/');
        }
      }
    )();
  }, [])

  function onClickSendMessageButton(index) {
    var text = dataList[index].userName + '님!, 지금까지 사용하셨던 락커에 물품이 남아있어요!\n빠르게 확인해보세요!! :)';

    var requestData = {
      userId: dataList[index].userId,
      text: text
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataOfCoach('push_notification/send', 'POST', requestData);
        
        if (responseData.status === 200) {
          alert("푸시알림을 전송하였습니다!");
        }
        else if (responseData.status === 204) {
          alert("Link Coach를 탈퇴한 회원입니다!");
        }
      }
    )();
  }

  // 전화번호 정리 //
  function calcPhoneView(data) {
    // console.log(data);
    // 휴대폰 번호가 없는 회원인 경우 //
    if (data.phone === '') return '번호 없음';
    else return data.phone.slice(0, 3) + '-' + data.phone.slice(3, 7) + '-' + data.phone.slice(7, 11);
  }

  return (
    <TopLevelWrapper>
      <ModalView>
        <TotalWrapper>
        <Title>이용 내역</Title>
          <InfoWrapper>
          {
            dataList.map((data, index) => (
              <InfoBox key={index}>
                <Table>
                  <Body>
                    <Tr>
                      <Td>
                        <TextWrapper style={{ width: `57px` }}>
                          <Name>{data.userName}</Name>
                        </TextWrapper>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <TextWrapper style={{ width: `95px`, marginLeft: `10px` }}>
                          {/* <Phone>{data.phone[0]
                                  + data.phone[1]
                                  + data.phone[2]
                                  + '-'
                                  + data.phone[3]
                                  + data.phone[4]
                                  + data.phone[5]
                                  + data.phone[6]
                                  + '-'
                                  + data.phone[7]
                                  + data.phone[8]
                                  + data.phone[9]
                                  + data.phone[10]}</Phone> */}
                          <Phone>{calcPhoneView(data)}</Phone>
                        </TextWrapper>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <TextWrapper style={{ width: `71px` }}>
                          <State State={data.state}>{data.state}</State>
                        </TextWrapper>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <TextWrapper style={{ width: `83px` }}>
                          {
                            data.state !== '사용중' ?
                            <SendButton onClick={() => onClickSendMessageButton(index)}>분실물 알림 전송</SendButton>
                            :
                            null
                          }
                          {/* <SendButton>분실물 알림 전송</SendButton> */}
                        </TextWrapper>
                      </Td>
                    </Tr>
                  </Body>
                </Table>
              </InfoBox>
            ))
          }
        </InfoWrapper>
        <ExitButton onClick={exitClick}>닫기</ExitButton>
        </TotalWrapper>
      </ModalView>
    </TopLevelWrapper>
  )
})

export default LockerHistoryModal;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 1000;

  width: 100vw;
  height: 100vh;

  overflow-y: scroll;

  background-color: rgba(0, 0, 0, 0.2);
`;

const ModalView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 373px;
  height: 354px;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(0, 0, 0, 0.1);
  background-color: #FFFFFF;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: 0 0 0 0;
`;


const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  // width: 318px;
  height: 132px;

  overflow-y: scroll;

  margin-top: 16px;

  &::-webkit-scrollbar {
		width: 9px;
	}

	&::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 20px #DFDFDF;
		border: 3px solid transparent;
		height: 25px;
		// background-color: #FF8B48;
		border-radius: 200px;
	}

	&::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px transparent;
		border: 4px solid transparent;
		background-color: transparent;
	}

	&::-webkit-scrollbar-track-piece {
		background-color: transparent;
	}
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  // align-items: center;

  {/* width: 318px; */}
  width: 100%;
  height: 32px;
  
  border-bottom: 1px solid #F5F5F5;
`;

const Table = styled.table`
  display: flex;
  flex-direction: row;

  height: 32px;
`;

const Body = styled.tbody`
  display: flex;
  flex-direction: row;
  
  {/* margin-left: 28px; */}
`;

const Tr = styled.tr`
  display: flex;
  flex-direction: row;
`;

const Td = styled.td`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  overflow: hidden;

  max-height: 20px;
  // border: 1px solid #232323;
`;

const Name = styled.span`
  font-family: NotoSansKR-Medium;
  font-size: 12px;

  color: #595959;

  margin: 0 0 0 0;

  transition: all 0.2s ease-in-out;
`;

const Phone = styled.span`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #777777;

  margin: 0 0 0 0;

  transition: all 0.2s ease-in-out;
`;

const State = styled.span`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: ${props => props.State === '사용중' ? `#5BD98A` : `#FF3131`};

  transition: all 0.2s ease-in-out;
`;

const SendButton = styled.span`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #A6A6A6;

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 72px;
  height: 38px;

  margin-top: 86px;
  margin-left: 243px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;

  transition: all 0.2s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #777777;
    color: #777777;
  }
`;