import { React, useEffect, useState } from 'react';
import styled from 'styled-components';

// Image Import //
import ProgramVideoIcon from '../../../image/ProgramVideoIcon.png';
import CheckImage from '../../../image/WhiteCheck.png';
// Image Import //

export default function ScaleNormalMovementBox(props) {
  const { 
    data, 
    idx, 
    tag, 
    reps,
    division, 
    scaleOptionList,
    onClickMovementVideo, 
  } = props;

  const [scaleViewIndex, setScaleViewIndex] = useState(-1);

  // 한자리 숫자 앞에 0 붙이기 //
  function numberPad(n, width) {
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
  }

  return (
    <TopLevelWrapper>
      <MovementWrapper key={idx}>
        <MovementTitleWrapper>
          <NumberWrapper>
            <NumberBox>
              <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>{numberPad(idx + 1, 2)}</Text>
            </NumberBox>
            <Text fontFamily='Poppins-Medium' fontSize={12} color='#4F4F4F'>{data.movementName}</Text>
          </NumberWrapper>
          <IconWrapper>
            <Icon src={ProgramVideoIcon} onClick={() => onClickMovementVideo(division, idx)}/>
          </IconWrapper>
        </MovementTitleWrapper>
        {
          data.isUseWeight &&
          <LayerWrapper topMargin={16}>
            <ComponentWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>무게</Text>
              <DataBox>
                  {
                    (data.divisionWeight !== 'None' && data.divisionWeight !== '') ?
                      <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.divisionWeight}</Text>
                      :
                      <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.weight}</Text>
                  }
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>{data.weightUnit === 'Pounds' ? 'lb' : data.weightUnit}</Text>
              </DataBox>
            </ComponentWrapper>
          </LayerWrapper>
        }
        {
          data.isUseDistance &&
          <LayerWrapper topMargin={12}>
            <ComponentWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>거리</Text>
              <DataBox>
                {/* <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.distance}</Text> */}
                {
                  (data.divisionDistance !== 'None' && data.divisionDistance !== '') ?
                    <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.divisionDistance}</Text>
                    :
                    <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.distance}</Text>
                }
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>{data.distanceUnit}</Text>
              </DataBox>
            </ComponentWrapper>
          </LayerWrapper>
        }
        {
          data.isUseHeight &&
          <LayerWrapper topMargin={12}>
            <ComponentWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>높이</Text>
              <DataBox>
                {/* <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.height}</Text> */}
                {
                  (data.divisionHeight !== 'None' && data.divisionHeight !== '') ?
                    <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.divisionHeight}</Text>
                    :
                    <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.height}</Text>
                }
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>{data.heightUnit}</Text>
              </DataBox>
            </ComponentWrapper>
          </LayerWrapper>
        }
        {
          data.isUseCalories &&
          <LayerWrapper topMargin={12}>
            <ComponentWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>열량</Text>
              <DataBox>
                {/* <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.calories}</Text> */}
                {
                  (data.divisionCalories !== 'None' && data.divisionCalories !== '') ?
                    <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.divisionCalories}</Text>
                    :
                    <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.calories}</Text>
                }
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>{data.caloriesUnit}</Text>
              </DataBox>
            </ComponentWrapper>
          </LayerWrapper>
        }
        {
          data.isUseCount &&
          <LayerWrapper topMargin={12}>
            <ComponentWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>횟수</Text>
              { 
                tag === 'SINGLE' &&
                <TimeInputWrapper>
                  <TimeDataBox>
                    <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.count}</Text>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REPS</Text>
                  </TimeDataBox>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>X</Text>
                  <TimeDataBox>
                    {/* <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.sets}</Text> */}
                    {
                      (data.divisionSets !== 'None' && data.divisionSets !== '') ?
                        <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.divisionSets}</Text>
                        :
                        <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.sets}</Text>
                    }
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>SETS</Text>
                  </TimeDataBox>
                </TimeInputWrapper>
              }
              {
                tag === 'ForTime-Different Reps' &&
                <DataBox>
                  <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{reps}</Text>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REPS</Text>
                </DataBox>
              }
              {
                tag !== 'SINGLE' && tag !== 'ForTime-Different Reps' &&
                <DataBox>
                  <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{tag === 'TABATA' ? 'MAX' : data.count}</Text>
                  {/* <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{viewCountData()}</Text> */}
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REPS</Text>
                </DataBox>
              }
            </ComponentWrapper>
          </LayerWrapper>
        }
        {
          data.isUseTime &&
          <LayerWrapper topMargin={12}>
            <ComponentWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>시간</Text>
              <TimeInputWrapper>
                <TimeDataBox>
                  <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{parseInt(data.time / 60)}</Text>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>MIN</Text>
                </TimeDataBox>
                <TimeDataBox>
                  <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{parseInt(data.time % 60)}</Text>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>SEC</Text>
                </TimeDataBox>
              </TimeInputWrapper>
            </ComponentWrapper>
          </LayerWrapper>
        }
        {
          data.isUseTempo &&
          <LayerWrapper topMargin={12}>
            <ComponentWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>템포</Text>
              <DataBox>
                {/* <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.tempo}</Text> */}
                {
                  (data.divisionTempo !== 'None' && data.divisionTempo !== '') ?
                    <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.divisionTempo}</Text>
                    :
                    <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.tempo}</Text>
                }
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>TEMPO</Text>
              </DataBox>
            </ComponentWrapper>
          </LayerWrapper>
        }
        {
          tag === 'SINGLE' &&
          <RestComponentWrapper topMargin={12}>
            <CheckBox isClicked={data.isUseRest}>
              <Check src={CheckImage} />
            </CheckBox>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>쉬는 시간</Text>
            <RestTimeDataBox>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.rest}</Text>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>SEC</Text>
            </RestTimeDataBox>
          </RestComponentWrapper>
        }
      </MovementWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: 100%;
`;

const MovementWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: 100%;

  margin-top: 23px;
`;

const NumberWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const MovementTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 12px;
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;

  :hover {
    cursor: pointer;
  }
`;

const NumberBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 27px;
  height: 18px;

  border-radius: 6px;
  // background-color: ${props => props.isGreen ? `#6DD49E` : `#FF8B48`};
  background-color: #4161AF;

  // font-family: NotoSansKR-Bold;
  // font-size: 12px;

  // color: #FFFFFF;

  margin: 0 0 0 0;
  padding: 0 0 0 0;
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;

  margin-top: ${props => props.topMargin}px;
`; 

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100%;

  gap: 8px;
`;

const SingleCountWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 93.38%;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  padding: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const TimeInputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  // gap: 24px;
  width: 93.38%;
`;

const RestComponentWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 7px;

  width: 100%;

  margin-top: ${props => props.topMargin}px;
`;

const CheckBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;

  border-radius: 6px;
  background-color: ${props => props.isClicked ? `#6DD49E` : `#E0E0E0`};

  transition: all 0.3s ease-in-out;
`;

const Check = styled.img`
  width: 10px;
  height: 9px;
`;

const DataBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: calc(93.38% - 34px);
  height: 34px;

  padding: 0 16px 0 16px;
  margin: 0 0 0 0;

  border: 1px solid #E0E0E0;
  border-radius: 15px;
  background-color: #FFFFFF;
`;

const RestTimeDataBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: calc(82.29% - 34px);
  height: 34px;

  padding: 0 16px 0 16px;
  margin: 0 0 0 0;

  border: 1px solid #E0E0E0;
  border-radius: 15px;
  background-color: #FFFFFF;
`;

const TimeDataBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: calc(47.5% - 34px);
  height: 34px;

  padding: 0 16px 0 16px;
  margin: 0 0 0 0;

  border: 1px solid #E0E0E0;
  border-radius: 15px;
  background-color: #FFFFFF;
`;