import { React } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import IdentifierPageLogoPath from '../../../image/SearchIdentifierLogo.png';
import SearchId from './SearchId.js';
import SearchPassword from './SearchPassword.js';

const SearchView = () => {
  const history = useHistory();

  return (
    <TopLevelWrapper>
      <TitleWrapper onClick={() => history.push('/')}>
        <Logo src={IdentifierPageLogoPath}/>
        <Title>Link Zone</Title>
      </TitleWrapper>
      <InfoBoxWrapper>
        <SearchId/>
        <SearchPassword/>
      </InfoBoxWrapper>
    </TopLevelWrapper>
  );
}

export default SearchView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  // justify-content: flex-start;
  align-items: center;
  
  // width: max(375px, 62.1875vw);
  width: 345px;

  // margin-top: 42px;

  :hover {
    cursor: pointer;
  }
`;

const Logo = styled.img`
  width: 78px;
  // width: max(40px, 4.0625vw);
  height: 78px;
  // height: max(40px, 4.0625vw);

  margin-right: 20px;
  // margin-right: max(8px, 1.041666666666667vw);
`;

const Title = styled.p`
  font-family: Poppins-SemiBold;
  font-size: 36px;
  // font-size: max(24px, 1.875vw);

  color: #FF8B48;

  margin: 0 0 0 0;
`;

const InfoBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: flex-start;

  // flex-wrap: wrap;

  // width: 1194px;
  // width: max(375px, 62.1875vw);

  // background-color: blue;
  margin-top: 40px;
  // margin-top: max(16px, 2.083333333333333vw);

  gap: 41px;
  // gap: 30px;
  // gap: max(24px, 1.5625vw);
`;