import React, { useRef, useState } from "react";
import styled from "styled-components";
import { cloneDeep } from 'lodash';

import CheckBox from "./CheckBox";
import SettingInput from "./SettingInput";
import IndexSettingInput from "./IndexSettingInput";
import FetchModule from "../../../Model/Share/Network/FetchModule";

export default function ExpiredSettingMessageBox(props) {
  const {
    isActivate,
    expired,
    setExpired,
    onClickEdit
  } = props;

  const [willSavedData, setWillSavedData] = useState({
    data: undefined,
    index: undefined
  });

  const BeExpiredMembershipTimerRef = useRef(null);
  const BeExpiredLockerTimerRef = useRef(null);
  const ExpiredMembershipTimerRef = useRef(null);
  const HadBeenExpiredMembershipTimerRef = useRef(null);
  const HadBeenExpiredLockerTimerRef = useRef(null);
  const StartMembershipHoldingTimerRef = useRef(null);

  // 변수 치환 //
  function replaceText(value) {
    return value.replaceAll('{store_name}', '{시설 이름}')
                .replaceAll('{user_name}', '{회원 이름}')
                .replaceAll('{membership_name}', '{보유 회원권}')
                .replaceAll('{exercise_period}', '{운동 기간}')
                .replaceAll('{locker_name}', '{락커 번호}')
                .replaceAll('{coach_name}', '{코치 이름}')
                .replaceAll('{class_name}', '{수업 이름}')
                .replaceAll('{reserve_time}', '{예약 시간}')
                .replaceAll('{class_time}', '{수업 시간}')
                .replaceAll('{holding_start_date}', '{홀딩 시작일}')
                .replaceAll('{holding_end_date}', '{홀딩 종료일}')
  }

  // 체크박스 클릭시 //
  function onClickCheckBox(division, index) {
    if (!isActivate) return;

    let newData = cloneDeep(expired);
    
    // 회원권 만료 예정 //
    if (division === 'BeExpiredMembership') {
      newData.beExpiredMembership[index].isUseSetting = !newData.beExpiredMembership[index].isUseSetting;

      saveMessageSetting(newData.beExpiredMembership[index], index);
    }
    // 락커 만료 예정 //
    else if (division === 'BeExpiredLocker') {
      newData.beExpiredLocker[index].isUseSetting = !newData.beExpiredLocker[index].isUseSetting;

      saveMessageSetting(newData.beExpiredLocker[index], index);
    }
    // 회원권 만료시//
    else if (division === 'ExpiredMembership') {
      newData.expiredMembership.isUseSetting = !newData.expiredMembership.isUseSetting;

      saveMessageSetting(newData.expiredMembership, -1);
    }
    // 회원권 만료 후 //
    else if (division === 'HadBeenExpiredMembership') {
      newData.hadBeenExpiredMembership[index].isUseSetting = !newData.hadBeenExpiredMembership[index].isUseSetting;
      
      saveMessageSetting(newData.hadBeenExpiredMembership[index], index);
    }
    // 락커 만료 후 //
    else if (division === 'HadBeenExpiredLocker') {
      newData.hadBeenExpiredLocker[index].isUseSetting = !newData.hadBeenExpiredLocker[index].isUseSetting;
      
      saveMessageSetting(newData.hadBeenExpiredLocker[index], index);
    }
    // 홀딩 적용시 //
    else if (division === 'ApplyMembershipHolding') {
      newData.applyMembershipHolding.isUseSetting = !newData.applyMembershipHolding.isUseSetting;

      saveMessageSetting(newData.applyMembershipHolding, -1);
    }
    // 홀딩 시작시 //
    else if (division === 'StartMembershipHolding') {
      newData.startMembershipHolding.isUseSetting = !newData.startMembershipHolding.isUseSetting;

      saveMessageSetting(newData.startMembershipHolding, -1);
    }

    // setExpired(newData);
  }

  // 회원권 만료 예정 날짜 변경시 //
  function onChangeBeExpiredMembershipDay(value, index) {
    if (!isActivate) return;

    if (value < 100) {
      let newData = cloneDeep(expired);

      newData.beExpiredMembership[index].applyDay = value;
  
      setExpired(newData);
      setWillSavedData({data: newData.beExpiredMembership[index], index: index});

      if (BeExpiredMembershipTimerRef.current !== null) clearTimeout(BeExpiredMembershipTimerRef.current);

      BeExpiredMembershipTimerRef.current = setTimeout(async () => {
        saveMessageSetting(newData.beExpiredMembership[index], index);
      }, 3000);
    }
  }

  // 회원권 만료 예정 시간 변경시 //
  function onChangeBeExpiredMembershipTime(value, index) {
    if (!isActivate) return;
    
    if (value < 24) {
      let newData = cloneDeep(expired);

      newData.beExpiredMembership[index].applyTime = value;
  
      setExpired(newData);
      setWillSavedData({data: newData.beExpiredMembership[index], index: index});

      if (BeExpiredMembershipTimerRef.current !== null) clearTimeout(BeExpiredMembershipTimerRef.current);

      BeExpiredMembershipTimerRef.current = setTimeout(async () => {
        saveMessageSetting(newData.beExpiredMembership[index], index);
      }, 3000);
    }
  }

  // 락커 만료 예정 날짜 변경시 //
  function onChangeBeExpiredLockerDay(value, index) {
    if (!isActivate) return;
    
    if (value < 100) {
      let newData = cloneDeep(expired);

      newData.beExpiredLocker[index].applyDay = value;
  
      setExpired(newData);
      setWillSavedData({data: newData.beExpiredLocker[index], index: index});

      if (BeExpiredLockerTimerRef.current !== null) clearTimeout(BeExpiredLockerTimerRef.current);

      BeExpiredLockerTimerRef.current = setTimeout(async () => {
        saveMessageSetting(newData.beExpiredLocker[index], index);
      }, 3000);
    }
  }

  // 락커 만료 예정 시간 변경시 //
  function onChangeBeExpiredLockerTime(value, index) {
    if (!isActivate) return;
    
    if (value < 24) {
      let newData = cloneDeep(expired);

      newData.beExpiredLocker[index].applyTime = value;
  
      setExpired(newData);
      setWillSavedData({data: newData.beExpiredLocker[index], index: index});

      if (BeExpiredLockerTimerRef.current !== null) clearTimeout(BeExpiredLockerTimerRef.current);

      BeExpiredLockerTimerRef.current = setTimeout(async () => {
        saveMessageSetting(newData.beExpiredLocker[index], index);
      }, 3000);
    }
  }

  // 회원권 만료 시간 변경시 //
  function onChangeExpiredMembershipTime(value) {
    if (!isActivate) return;
    
    if (value < 24) {
      let newData = cloneDeep(expired);

      newData.expiredMembership.applyTime = value;
  
      setExpired(newData);

      if (ExpiredMembershipTimerRef.current !== null) clearTimeout(ExpiredMembershipTimerRef.current);

      ExpiredMembershipTimerRef.current = setTimeout(async () => {
        saveMessageSetting(newData.expiredMembership, -1);
      }, 3000);
    }
  }

  // 회원권 만료 후 날짜 변경시 //
  function onChangeHadBeenExpiredMembershipDay(value, index) {
    if (!isActivate) return;
    
    if (value < 100) {
      let newData = cloneDeep(expired);

      newData.hadBeenExpiredMembership[index].applyDay = value;
  
      setExpired(newData);
      setWillSavedData({data: newData.hadBeenExpiredMembership[index], index: index});

      if (HadBeenExpiredMembershipTimerRef.current !== null) clearTimeout(HadBeenExpiredMembershipTimerRef.current);

      HadBeenExpiredMembershipTimerRef.current = setTimeout(async () => {
        saveMessageSetting(newData.hadBeenExpiredMembership[index], index);
      }, 3000);
    }
  }

  // 회원권 만료 후 시간 변경시 //
  function onChangeHadBeenExpiredMembershipTime(value, index) {
    if (!isActivate) return;
    
    if (value < 24) {
      let newData = cloneDeep(expired);

      newData.hadBeenExpiredMembership[index].applyTime = value;
  
      setExpired(newData);
      setWillSavedData({data: newData.hadBeenExpiredMembership[index], index: index});

      if (HadBeenExpiredMembershipTimerRef.current !== null) clearTimeout(HadBeenExpiredMembershipTimerRef.current);

      HadBeenExpiredMembershipTimerRef.current = setTimeout(async () => {
        saveMessageSetting(newData.hadBeenExpiredMembership[index], index);
      }, 3000);
    }
  }

  // 락커 만료 후 날짜 변경시 //
  function onChangeHadBeenExpiredLockerDay(value, index) {
    if (!isActivate) return;
    
    if (value < 100) {
      let newData = cloneDeep(expired);

      newData.hadBeenExpiredLocker[index].applyDay = value;
  
      setExpired(newData);
      setWillSavedData({data: newData.hadBeenExpiredLocker[index], index: index});

      if (HadBeenExpiredLockerTimerRef.current !== null) clearTimeout(HadBeenExpiredLockerTimerRef.current);

      HadBeenExpiredLockerTimerRef.current = setTimeout(async () => {
        saveMessageSetting(newData.hadBeenExpiredLocker[index], index);
      }, 3000);
    }
  }

  // 락커 만료 후 시간 변경시 //
  function onChangeHadBeenExpiredLockerTime(value, index) {
    if (!isActivate) return;
    
    if (value < 24) {
      let newData = cloneDeep(expired);

      newData.hadBeenExpiredLocker[index].applyTime = value;
  
      setExpired(newData);
      setWillSavedData({data: newData.hadBeenExpiredLocker[index], index: index});

      if (HadBeenExpiredLockerTimerRef.current !== null) clearTimeout(HadBeenExpiredLockerTimerRef.current);

      HadBeenExpiredLockerTimerRef.current = setTimeout(async () => {
        saveMessageSetting(newData.hadBeenExpiredLocker[index], index);
      }, 3000);
    }
  }

  // 회원권 활성화 날짜 변경시 //
  function onChangeStartMembershipHoldingDay(value) {
    if (!isActivate) return;
    
    if (value < 100) {
      let newData = cloneDeep(expired);

      newData.startMembershipHolding.applyDay = value;
  
      setExpired(newData);

      if (StartMembershipHoldingTimerRef.current !== null) clearTimeout(StartMembershipHoldingTimerRef.current);

      StartMembershipHoldingTimerRef.current = setTimeout(async () => {
        saveMessageSetting(newData.startMembershipHolding, -1);
      }, 3000);
    }
  }

  // 회원권 활성화 시간 변경시 //
  function onChangeStartMembershipHoldingTime(value) {
    if (!isActivate) return;
    
    if (value < 24) {
      let newData = cloneDeep(expired);

      newData.startMembershipHolding.applyTime = value;
  
      setExpired(newData);

      if (StartMembershipHoldingTimerRef.current !== null) clearTimeout(StartMembershipHoldingTimerRef.current);

      StartMembershipHoldingTimerRef.current = setTimeout(async () => {
        saveMessageSetting(newData.startMembershipHolding, -1);
      }, 3000);
    }
  }

  // 데이터 추가 클릭시 //
  function onClickAddOrDeleteButton(data, isAdd) {
    // 데이터 추가의 경우 //
    if (isAdd) {
      let requestData = {
        id: 0,
        storeId: 0,
        division: data.division,
        text: data.text,
        title: data.title,
        applyDay: data.applyDay,
        applyTime: data.applyTime,
        isUseSetting: data.isUseSetting
      }
  
      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataVersion2('message_setting/save', 'POST', requestData);
  
          if (responseData.status === 200) {
            let newData = cloneDeep(expired);
  
            if (data.division === 'BeExpiredMembership') newData.beExpiredMembership.push(responseData.data);
            else if (data.division === 'BeExpiredLocker') newData.beExpiredLocker.push(responseData.data);
            else if (data.division === 'HadBeenExpiredMembership') newData.hadBeenExpiredMembership.push(responseData.data);
            else if (data.division === 'HadBeenExpiredLocker') newData.hadBeenExpiredLocker.push(responseData.data);
        
            setExpired(newData);
          }
        }
      )();
    }
    // 데이터 삭제의 경우 //
    else {
      let requestData = {
        id: data.id
      }
  
      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataVersion2('message_setting/delete', 'POST', requestData);
          console.log(responseData);
          if (responseData.status === 200) {
            let newData = cloneDeep(expired);
  
            if (data.division === 'BeExpiredMembership') {
              let updateList = newData.beExpiredMembership.filter(obj => obj.id !== data.id);

              newData.beExpiredMembership = updateList;
            }
            else if (data.division === 'BeExpiredLocker') {
              let updateList = newData.beExpiredLocker.filter(obj => obj.id !== data.id);

              newData.beExpiredLocker = updateList;
            }
            else if (data.division === 'HadBeenExpiredMembership') {
              let updateList = newData.hadBeenExpiredMembership.filter(obj => obj.id !== data.id);

              newData.hadBeenExpiredMembership = updateList;
            }
            else if (data.division === 'HadBeenExpiredLocker') {
              let updateList = newData.hadBeenExpiredLocker.filter(obj => obj.id !== data.id);

              newData.hadBeenExpiredLocker = updateList;
            }
        
            console.log(data);
            setExpired(newData);
          }
        }
      )();
    }
  }

  // 데이터 저장 //
  function saveMessageSetting(data, index) {
    let requestData = {
      id: data.id,
      storeId: data.storeId,
      division: data.division,
      text: data.text,
      title: data.title,
      applyDay: data.applyDay,
      applyTime: data.applyTime,
      isUseSetting: data.isUseSetting
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataVersion2('message_setting/save', 'POST', requestData);

        if (responseData.status === 200) {
          let newData = cloneDeep(expired);

          if (data.division === 'BeExpiredMembership') newData.beExpiredMembership[index] = responseData.data;
          else if (data.division === 'BeExpiredLocker') newData.beExpiredLocker[index] = responseData.data;
          else if (data.division === 'ExpiredMembership') newData.expiredMembership.isUseSetting = responseData.data;
          else if (data.division === 'HadBeenExpiredMembership') newData.hadBeenExpiredMembership[index] = responseData.data;
          else if (data.division === 'HadBeenExpiredLocker') newData.hadBeenExpiredLocker[index] = responseData.data;
          else if (data.division === 'ApplyMembershipHolding') newData.applyMembershipHolding = responseData.data;
          else if (data.division === 'StartMembershipHolding') newData.startMembershipHolding = responseData.data;
      
          setExpired(newData);
        }
      }
    )();
  }

  // Input 포커싱 잃는경우 바로 저장 //
  function onBlurInput() {
    if (willSavedData.data !== undefined) {
      saveMessageSetting(willSavedData.data, willSavedData.index);

      if (willSavedData.data.division === 'BeExpiredMembership') clearTimeout(BeExpiredMembershipTimerRef.current);
      else if (willSavedData.data.division === 'BeExpiredLocker') clearTimeout(BeExpiredLockerTimerRef.current);
      else if (willSavedData.data.division === 'HadBeenExpiredMembership') clearTimeout(HadBeenExpiredMembershipTimerRef.current);
      else if (willSavedData.data.division === 'HadBeenExpiredLocker') clearTimeout(HadBeenExpiredLockerTimerRef.current);

      setWillSavedData({data: undefined, index: undefined});
    }
  }

  // 아무것도 하지 않기 //
  function doNothing() {}

  return (
    <TopLevelWrapper>
      <DivisionBox isActivate={isActivate}>
        <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>만료/정지</Text>
      </DivisionBox>
      <SettingBox>
        {
          expired.beExpiredMembership.map((data, index) => 
            <SettingColumnBoxWrapper key={'BeExpiredMembership-' + index}>
              <SettingColumnBox>
                <CheckBoxWrapper>
                  <CheckBox
                    isClicked={data.isUseSetting && isActivate}
                    onClickCheckBox={() => onClickCheckBox('BeExpiredMembership', index)}

                    boxWidth={15}
                    boxHeight={15}
                    boxRadius={5}

                    imageWidth={10}
                    imageHeight={7} /> 
                  <CheckBoxTextWrapper>
                    <Text fontFamily='NotoSansKR-Regular' fontSize={14} color={index === 0 ? '#595959' : '#FFFFFF'} hover='pointer'>만료 예정</Text>
                    <InputWrapper>
                      <IndexSettingInput
                        index={index}
                        value={data.applyDay}
                        setValue={onChangeBeExpiredMembershipDay}
                        onBlur={onBlurInput}
                        placeholder={0}/>
                    </InputWrapper>
                    <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='pointer'>일 전</Text>
                    <InputWrapper>
                      <IndexSettingInput
                        index={index}
                        value={data.applyTime}
                        setValue={onChangeBeExpiredMembershipTime}
                        onBlur={onBlurInput}
                        placeholder={0}/>
                    </InputWrapper>
                    <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='pointer'>시에</Text>
                  </CheckBoxTextWrapper>
                  <PlusButtonWrapper style={{ marginLeft: '36px' }}>
                    <PlusButton isFirst={index === 0} onClick={() => onClickAddOrDeleteButton(data, index === 0)}>
                      <Stick width={10} height={2} radius={0} backgroundColor={index === 0 ? '#FFFFFF' : '#FF2626'}/>
                      <Stick width={2} height={10} radius={0} backgroundColor={index === 0 ? '#FFFFFF' : '#FF2626'}/>
                    </PlusButton>
                  </PlusButtonWrapper>
                </CheckBoxWrapper>
                <SettingTextWrapper>
                  <TextWrapper>
                    <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='pointer'>{replaceText(data.text)}</Text>
                  </TextWrapper>
                </SettingTextWrapper>
                <ModifyButton onClick={() => onClickEdit(data)}>
                  <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FF8B48' hover='pointer'>수정하기</Text>
                </ModifyButton>
              </SettingColumnBox>

              <DivisionLine />
            </SettingColumnBoxWrapper>
          )
        }
        {
          expired.beExpiredLocker.map((data, index) =>
            <SettingColumnBoxWrapper key={'BeExpiredLocker-' + index}>
              <SettingColumnBox>
                <CheckBoxWrapper>
                  <CheckBox
                    isClicked={data.isUseSetting && isActivate}
                    onClickCheckBox={() => onClickCheckBox('BeExpiredLocker', index)}

                    boxWidth={15}
                    boxHeight={15}
                    boxRadius={5}

                    imageWidth={10}
                    imageHeight={7} />
                  <CheckBoxTextWrapper>
                    <Text fontFamily='NotoSansKR-Regular' fontSize={14} color={index === 0 ? '#595959' : '#FFFFFF'} hover='pointer'>락커 만료 예정</Text>
                    <InputWrapper>
                      <IndexSettingInput
                        index={index}
                        value={data.applyDay}
                        setValue={onChangeBeExpiredLockerDay}
                        onBlur={onBlurInput}
                        placeholder={0} />
                    </InputWrapper>
                    <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='pointer'>일 전</Text>
                    <InputWrapper>
                      <IndexSettingInput
                        index={index}
                        value={data.applyTime}
                        setValue={onChangeBeExpiredLockerTime}
                        onBlur={onBlurInput}
                        placeholder={0} />
                    </InputWrapper>
                    <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='pointer'>시에</Text>
                  </CheckBoxTextWrapper>
                  <PlusButtonWrapper style={{ marginLeft: '8px' }}>
                    <PlusButton isFirst={index === 0} onClick={() => onClickAddOrDeleteButton(data, index === 0)}>
                      <Stick width={10} height={2} radius={0} backgroundColor={index === 0 ? '#FFFFFF' : '#FF2626'}/>
                      <Stick width={2} height={10} radius={0} backgroundColor={index === 0 ? '#FFFFFF' : '#FF2626'}/>
                    </PlusButton>
                  </PlusButtonWrapper>
                </CheckBoxWrapper>
                <SettingTextWrapper>
                  <TextWrapper>
                    <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='default'>{replaceText(data.text)}</Text>
                  </TextWrapper>
                </SettingTextWrapper>
                <ModifyButton onClick={() => onClickEdit(data)}>
                  <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FF8B48' hover='pointer'>수정하기</Text>
                </ModifyButton>
              </SettingColumnBox>

              <DivisionLine />
            </SettingColumnBoxWrapper>
          )
        }
        <SettingColumnBox>
          <CheckBoxWrapper>
            <CheckBox
              isClicked={expired.expiredMembership.isUseSetting && isActivate}
              onClickCheckBox={() => onClickCheckBox('ExpiredMembership', -1)}

              boxWidth={15}
              boxHeight={15}
              boxRadius={5}

              imageWidth={10}
              imageHeight={7} />
            <CheckBoxTextWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='pointer'>회원권 만료시</Text>
              <InputWrapper>
                <SettingInput
                  value={expired.expiredMembership.applyTime}
                  setValue={onChangeExpiredMembershipTime}
                  placeholder={0}/>
              </InputWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='pointer'>시에</Text>
            </CheckBoxTextWrapper>
          </CheckBoxWrapper>
          <SettingTextWrapper>
            <TextWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='default'>{replaceText(expired.expiredMembership.text)}</Text>
            </TextWrapper>
          </SettingTextWrapper>
          <ModifyButton onClick={() => onClickEdit(expired.expiredMembership)}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FF8B48' hover='pointer'>수정하기</Text>
          </ModifyButton>
        </SettingColumnBox>

        <DivisionLine />

        {
          expired.hadBeenExpiredMembership.map((data, index) => 
            <SettingColumnBoxWrapper key={'HadBeenExpiredMembership-' + index}>
              <SettingColumnBox>
                <CheckBoxWrapper>
                  <CheckBox
                    isClicked={data.isUseSetting && isActivate}
                    onClickCheckBox={() => onClickCheckBox('HadBeenExpiredMembership', index)}

                    boxWidth={15}
                    boxHeight={15}
                    boxRadius={5}

                    imageWidth={10}
                    imageHeight={7} /> 
                  <CheckBoxTextWrapper>
                    <Text fontFamily='NotoSansKR-Regular' fontSize={14} color={index === 0 ? '#595959' : '#FFFFFF'} hover='pointer'>회원권 만료</Text>
                    <InputWrapper>
                      <IndexSettingInput
                        index={index}
                        value={data.applyDay}
                        setValue={onChangeHadBeenExpiredMembershipDay}
                        onBlur={onBlurInput}
                        placeholder={0}/>
                    </InputWrapper>
                    <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='pointer'>일 후</Text>
                    <InputWrapper>
                      <IndexSettingInput
                        index={index}
                        value={data.applyTime}
                        setValue={onChangeHadBeenExpiredMembershipTime}
                        onBlur={onBlurInput}
                        placeholder={0}/>
                    </InputWrapper>
                    <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='pointer'>시에</Text>
                  </CheckBoxTextWrapper>
                  <PlusButtonWrapper style={{ marginLeft: '24px' }}>
                    <PlusButton isFirst={index === 0} onClick={() => onClickAddOrDeleteButton(data, index === 0)}>
                      <Stick width={10} height={2} radius={0} backgroundColor={index === 0 ? '#FFFFFF' : '#FF2626'}/>
                      <Stick width={2} height={10} radius={0} backgroundColor={index === 0 ? '#FFFFFF' : '#FF2626'}/>
                    </PlusButton>
                  </PlusButtonWrapper>
                </CheckBoxWrapper>
                <SettingTextWrapper>
                  <TextWrapper>
                    <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='default'>{replaceText(data.text)}</Text>
                  </TextWrapper>
                </SettingTextWrapper>
                <ModifyButton onClick={() => onClickEdit(data)}>
                  <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FF8B48' hover='pointer'>수정하기</Text>
                </ModifyButton>
              </SettingColumnBox>

              <DivisionLine />
            </SettingColumnBoxWrapper>
          )
        }
        {
          expired.hadBeenExpiredLocker.map((data, index) =>
            <SettingColumnBoxWrapper key={'HadBeenExpiredLocker-' + index}>
              <SettingColumnBox>
                <CheckBoxWrapper>
                  <CheckBox
                    isClicked={data.isUseSetting && isActivate}
                    onClickCheckBox={() => onClickCheckBox('HadBeenExpiredLocker', index)}

                    boxWidth={15}
                    boxHeight={15}
                    boxRadius={5}

                    imageWidth={10}
                    imageHeight={7} />
                  <CheckBoxTextWrapper>
                    <Text fontFamily='NotoSansKR-Regular' fontSize={14} color={index === 0 ? '#595959' : '#FFFFFF'} hover='pointer'>락커 만료</Text>
                    <InputWrapper>
                      <IndexSettingInput
                        index={index}
                        value={data.applyDay}
                        setValue={onChangeHadBeenExpiredLockerDay}
                        onBlur={onBlurInput}
                        placeholder={0} />
                    </InputWrapper>
                    <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='pointer'>일 후</Text>
                    <InputWrapper>
                      <IndexSettingInput
                        index={index}
                        value={data.applyTime}
                        setValue={onChangeHadBeenExpiredLockerTime}
                        onBlur={onBlurInput}
                        placeholder={0} />
                    </InputWrapper>
                    <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='pointer'>시에</Text>
                  </CheckBoxTextWrapper>
                  <PlusButtonWrapper style={{ marginLeft: '36px' }}>
                    <PlusButton isFirst={index === 0} onClick={() => onClickAddOrDeleteButton(data, index === 0)}>
                      <Stick width={10} height={2} radius={0} backgroundColor={index === 0 ? '#FFFFFF' : '#FF2626'}/>
                      <Stick width={2} height={10} radius={0} backgroundColor={index === 0 ? '#FFFFFF' : '#FF2626'}/>
                    </PlusButton>
                  </PlusButtonWrapper>
                </CheckBoxWrapper>
                <SettingTextWrapper>
                  <TextWrapper>
                    <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='default'>{replaceText(data.text)}</Text>
                  </TextWrapper>
                </SettingTextWrapper>
                <ModifyButton onClick={() => onClickEdit(data)}>
                  <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FF8B48' hover='pointer'>수정하기</Text>
                </ModifyButton>
              </SettingColumnBox>

              <DivisionLine />
            </SettingColumnBoxWrapper>
          )
        }
        <SettingColumnBox>
          <CheckBoxWrapper onClick={() => onClickCheckBox('ApplyMembershipHolding', -1)}>
            <CheckBox
              isClicked={expired.applyMembershipHolding.isUseSetting && isActivate}
              onClickCheckBox={doNothing}

              boxWidth={15}
              boxHeight={15}
              boxRadius={5}

              imageWidth={10}
              imageHeight={7} />
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='pointer'>{expired.applyMembershipHolding.title}</Text>
          </CheckBoxWrapper>
          <SettingTextWrapper>
            <TextWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='default'>{replaceText(expired.applyMembershipHolding.text)}</Text>
            </TextWrapper>
          </SettingTextWrapper>
          <ModifyButton onClick={() => onClickEdit(expired.applyMembershipHolding)}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FF8B48' hover='pointer'>수정하기</Text>
          </ModifyButton>
        </SettingColumnBox>

        <DivisionLine />

        <SettingColumnBox>
          <CheckBoxWrapper>
            <CheckBox
              isClicked={expired.startMembershipHolding.isUseSetting && isActivate}
              onClickCheckBox={() => onClickCheckBox('StartMembershipHolding', -1)}

              boxWidth={15}
              boxHeight={15}
              boxRadius={5}

              imageWidth={10}
              imageHeight={7} />
              <CheckBoxTextWrapper>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='pointer'>홀딩 이후 활성화</Text>
                <InputWrapper>
                  <SettingInput
                    value={expired.startMembershipHolding.applyDay}
                    setValue={onChangeStartMembershipHoldingDay}
                    placeholder={0} />
                </InputWrapper>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='pointer'>일 전</Text>
                <InputWrapper>
                  <SettingInput
                    value={expired.startMembershipHolding.applyTime}
                    setValue={onChangeStartMembershipHoldingTime}
                    placeholder={0} />
                </InputWrapper>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='pointer'>시에</Text>
              </CheckBoxTextWrapper>
          </CheckBoxWrapper>
          <SettingTextWrapper>
            <TextWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='default'>{replaceText(expired.startMembershipHolding.text)}</Text>
            </TextWrapper>
          </SettingTextWrapper>
          <ModifyButton onClick={() => onClickEdit(expired.startMembershipHolding)}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FF8B48' hover='pointer'>수정하기</Text>
          </ModifyButton>
        </SettingColumnBox>

      </SettingBox>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
`;

const DivisionBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 0px 20px;

  height: 25px;

  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  background-color: ${props => props.isActivate ? `#D92828` : `#CBCBCB`};

  transition: all 0.3s ease-in-out;
`;

const SettingBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 10px 5px;

  width: calc(100% - 10px);
  
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  background-color: #FFFFFF;
`;

const SettingColumnBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const SettingColumnBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100%;
  min-height: 40px;
`;

const CheckBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  padding: 0px 5px;

  width: 304px;
  height: 100%;

  gap: 14px;

  :hover {
    cursor: pointer;
  }
`;

const CheckBoxTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 5px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 36px;
  height: 32px;
`;

const PlusButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const PlusButton = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 18px;
  height 18px;

  border-radius: 18px;
  background-color: ${props => props.isFirst ? `#FF8B48` : `#FFFFFF`};

  transform: ${props => props.isFirst ? `rotate(0deg)` : `rotate(45deg)`};

  :hover {
    cursor: pointer;
  }
`;

const Stick = styled.div`
  position: absolute;
  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: ${props => props.radius}px;
  background-color: ${props => props.backgroundColor};
`;

const SettingTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  justify-content: center;
  align-items: center;

  width: 604px;
  height: 100%;

  border-left: 1px solid #969696;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  padding: 10px 15px;

  width: calc(100% - 30px);
`;

const ModifyButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 76px;
  height: 100%;

  background-color: #FFFFFF;

  :hover {
    cursor: pointer;
  }
`;

const DivisionLine = styled.div`
  width: calc(100% - 20px);
  height: 1px;

  margin: 15px 0px;

  background-color: #D9D9D9;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;