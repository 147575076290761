import { React, useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';

import FetchModule from '../../Share/Network/FetchModule';

import LeftArrow from '../../../image/Left.png'//왼쪽 화살표
import RightArrow from '../../../image/Right.png'//우측 화살표
import TestClassSelectBox from '../../../Component/NoteComponent/TestClassSelectBox';
import ScheduleBox from './ScheduleBox';
import CalenderView from './CalenderView';
import ExerciseSaveModal from './ExerciseSaveModal';
import RankingBox from './RankingBox';
import RankingExerciseBox from './RankingExerciseBox';
import LessonSelectBox from '../../../Component/NoteComponent/LessonSelectBox';
import ScheduleSelectBox from '../../../Component/NoteComponent/ScheduleSelectBox';
import RankingScheduleBox from './RankingScheduleBox';

function RankingTotalView() {
  const history = useHistory();

  const [data, setData] = useState([]);
  const [date, setDate] = useState(moment());
  const [rendering, setRendering] = useState(false);

  const [lessonId, setLessonId] = useState(-1);
  const [scheduleId, setScheduleId] = useState(-1);

  const [selectedLesson, setSelectedLesson] = useState(undefined);
  const [selectedScheduleData, setSelectedScheduleData] = useState(undefined);
  const [rankingList, setRankingList] = useState([]);
  const [nowSection, setNowSection] = useState(0);
  const [nowClickLinkCoachId, setNowClickLinkCoachId] = useState('');
  const [isClickedDetail, setIsClickedDetail] = useState(false);
  const [buttonText, setButtonText] = useState('');
  const [isClickedModify, setIsClickedModify] = useState(false);
  const [isViewConfirm, setIsViewConfirm] = useState(false);
  const [lessonName, setLessonName] = useState('');
  const [clickDate, setClickDate] = useState(date.clone().format('YYYY.MM.DD'));
  const [clickWorkoutIndex, setClickWorkoutIndex] = useState(0);
  const [dateList, setDateList] = useState([]);
  // const [useData, setUseData] = useState([{
  //   classDate: moment(),
  //   workouts: [{
  //     workoutId: 0,
  //     scheduleId: 0,
  //     storeId: parseInt(window.localStorage.getItem('StoreId')),
  //     classDate: moment(),
  //     title: '작성이 필요합니다.',
  //     sectionList: []
  //   }]
  // }]);

  const [useData, setUseData] = useState([]);

  const [dateSetData, setDateSetData] = useState([{
    classDate: moment(),
    workouts: [{
      workoutId: 0,
      scheduleId: 0,
      storeId: parseInt(window.localStorage.getItem('StoreId')),
      classDate: moment(),
      title: '작성이 필요합니다.',
      sectionList: []
    }]
  }]);

  const [clickedWorkoutId, setClickedWorkoutId] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isNotNecessaryLoading, setIsNotNecessaryLoading] = useState(false);

  const [isFirst, setIsFirst] = useState(true);
  const [isSettingData, setIsSettingData] = useState(false);

  const firstWeek = date.clone().startOf('month').week();
  const lastWeek = date.clone().endOf('month').week() === 1 ? 53 : date.clone().endOf('month').week();

  const TopLevelRef = useRef();
  const ExerciseRef = useRef();
  const ClassSelectRef = useRef();
  const ScheduleRef = useRef();
  const CalenderRef = useRef();

  // // workout Data에 따라 버튼 텍스트 변경 //
  // useEffect(() => {
  //   for (let i = 0; i < useData.length; i++) {
  //     if (useData[i].classDate.format('YYYY.MM.DD') === clickDate) {
  //       if (isClickedModify) setButtonText('저장하기');
  //       else {
  //         if (useData[i].workoutId === 0) setButtonText('추가하기');
  //         else setButtonText('수정하기');
  //       }
  //       break;
  //     }
  //   }
  // }, [isClickedModify, clickDate, useData])

  useEffect(() => {
    let newDateList = [];

    for (let week = firstWeek; week <= lastWeek; week++) {
      for (let i = 0; i < 7; i++) {
        let days = date.clone().startOf('year').week(week).startOf('week').add(i, 'day');

        if (days.format('MM') === date.format('MM')) newDateList.push(days);
      }
    }
    setDateList(newDateList);
  }, [date, firstWeek, lastWeek])

  // 새로 받은 리스트가 공백일 경우 //
  useEffect(() => {
    if (isNotNecessaryLoading) {
      setIsLoading(false);
      setIsNotNecessaryLoading(false);
    }
   }, [isNotNecessaryLoading])

  // 새로운 버전의 일정표 데이터 불러오기 //
  useEffect(() => {
    let requestData = {
      year: date.clone().format('YYYY'),
      month: date.clone().format('MM')
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataOfExerciseVersion2('workout/title_list', 'POST', requestData);

        if (responseData.status === 200) {
          setData(responseData.data);
          setTimeout(() => {

            setIsSettingData(true);
          }, 300)
        }
      }
    )();
  }, [history, date, rendering])

  useEffect(() => {

    // if (scheduleId === -1) return;
    if (clickedWorkoutId === 0) {
      setUseData([]);
      return;
    }

    let data = {
      // scheduleId: scheduleId,
      month: date.clone().format('MM'),
      year: date.clone().format('YYYY')
    }

    setIsLoading(true);

    let requestData = {
      workoutId: clickedWorkoutId
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        // let responseData = await fetchModule.postDataOfExerciseVersion2('workout/ranking', 'POST', data);
        let responseData = await fetchModule.postDataOfExerciseVersion2('workout/tmp/ranking', 'POST', requestData);
        // console.log(responseData);
        if (responseData.status === 200) {
          let workoutData = responseData.data.slice();

          // 운동 데이터 정리 //
          for (let i = 0; i < workoutData.length; i++) {

            workoutData[i].sectionList.sort(function (a, b) {
              return a.sectionIndex < b.sectionIndex ? -1 : a.sectionIndex > b.sectionIndex ? 1 : 0;
            })

            for (let j = 0; j < workoutData[i].sectionList.length; j++) {
              workoutData[i].sectionList[j].buyinExerciseList = [];
              workoutData[i].sectionList[j].buyinScaleExerciseList = [];
              workoutData[i].sectionList[j].mainExerciseList = [];
              workoutData[i].sectionList[j].mainScaleExerciseList = [];
              workoutData[i].sectionList[j].emomExerciseList = [];
              workoutData[i].sectionList[j].emomScaleExerciseList = [];
              workoutData[i].sectionList[j].buyoutExerciseList = [];
              workoutData[i].sectionList[j].buyoutScaleExerciseList = [];
              workoutData[i].sectionList[j].reps = workoutData[i].sectionList[j].reps.split('-');

              // Section Record Data 정리 //
              for (let u = 0; u < workoutData[i].sectionList[j].sectionRecordList.length; u++) {

                workoutData[i].sectionList[j].sectionRecordList.sort(function (a, b) {
                  return a.sectionIndex < b.sectionIndex ? -1 : a.sectionIndex > b.sectionIndex ? 1 : 0;
                })

                workoutData[i].sectionList[j].sectionRecordList[u].buyinExerciseList = [];
                workoutData[i].sectionList[j].sectionRecordList[u].buyinScaleExerciseList = [];
                workoutData[i].sectionList[j].sectionRecordList[u].mainExerciseList = [];
                workoutData[i].sectionList[j].sectionRecordList[u].mainScaleExerciseList = [];
                workoutData[i].sectionList[j].sectionRecordList[u].emomExerciseList = [];
                workoutData[i].sectionList[j].sectionRecordList[u].emomScaleExerciseList = [];
                workoutData[i].sectionList[j].sectionRecordList[u].buyoutExerciseList = [];
                workoutData[i].sectionList[j].sectionRecordList[u].buyoutScaleExerciseList = [];


                for (let h = 0; h < workoutData[i].sectionList[j].sectionRecordList[u].exerciseList.length; h++) {

                  if (workoutData[i].sectionList[j].tag === 'EMOM') workoutData[i].sectionList[j].sectionRecordList[u].roundList = workoutData[i].sectionList[j].roundList;

                  workoutData[i].sectionList[j].sectionRecordList[u].exerciseList.sort(function (a, b) {
                    return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
                  })

                  workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].movementId = workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].movement.movementId;
                  workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].movementName = workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].movement.movementName;
                  workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].videoLinkId = workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].movement.videoLinkId;

                  // 무게 구분 데이터 사용할 경우 //
                  if (workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionWeight !== undefined
                    && workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionWeight !== ''
                    && workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionWeight !== 'None') {
                    workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].weight = workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionWeight;
                  }
                  else workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionWeight = '';

                  // 거리 구분 데이터 사용할 경우 //
                  if (workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionDistance !== undefined
                    && workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionDistance !== ''
                    && workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionDistance !== 'None') {
                    workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].distance = workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionDistance;
                  }
                  else workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionDistance = '';

                  // 높이 구분 데이터 사용할 경우 //
                  if (workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionHeight !== undefined
                    && workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionHeight !== ''
                    && workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionHeight !== 'None') {
                    workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].height = workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionHeight;
                  }
                  else workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionHeight = '';

                  // 칼로리 구분 데이터 사용할 경우 //
                  if (workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionCalories !== undefined
                    && workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionCalories !== ''
                    && workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionCalories !== 'None') {
                    workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].calories = workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionCalories;
                  }
                  else workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionCalories = '';

                  // 템포 구분 데이터 사용할 경우 //
                  if (workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionTempo !== undefined
                    && workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionTempo !== ''
                    && workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionTempo !== 'None') {
                    workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].tempo = workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionTempo;
                  }
                  else workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionTempo = '';

                  // Sets 구분 데이터 사용할 경우 //
                  if (workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionSets !== undefined
                    && workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionSets !== ''
                    && workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionSets !== 'None') {
                    workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].sets = workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionSets;
                  }
                  else workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionSets = '';

                  if (workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].division === 'Buyin') {
                    workoutData[i].sectionList[j].sectionRecordList[u].buyinExerciseList.push(workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h]);
                  }
                  else if (workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].division === 'Buyin.Scale') {
                    workoutData[i].sectionList[j].sectionRecordList[u].buyinScaleExerciseList.push(workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h]);
                  }
                  else if (workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].division === 'Main') {
                    workoutData[i].sectionList[j].sectionRecordList[u].mainExerciseList.push(workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h]);
                  }
                  else if (workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].division === 'Main.Scale') {
                    workoutData[i].sectionList[j].sectionRecordList[u].mainScaleExerciseList.push(workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h]);
                  }
                  else if (workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].division === 'Main.Emom') {
                    // workoutData[i].sectionList[j].sectionRecordList[u].emomExerciseList.push(workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h]);
                    let exerciseData = workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h];

                    for (let h = 0; h < workoutData[i].sectionList[j].exerciseList.length; h++) {
                      if (exerciseData.exerciseId === workoutData[i].sectionList[j].exerciseList[h].exerciseId) {
                        exerciseData.emomRoundIndex = workoutData[i].sectionList[j].exerciseList[h].emomRoundIndex;
                        break;
                      }
                    }

                    workoutData[i].sectionList[j].sectionRecordList[u].emomExerciseList.push(exerciseData);
                  }
                  else if (workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].division === 'Main.Emom.Scale') {
                    workoutData[i].sectionList[j].sectionRecordList[u].emomScaleExerciseList.push(workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h]);
                  }
                  else if (workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].division === 'Buyout') {
                    workoutData[i].sectionList[j].sectionRecordList[u].buyoutExerciseList.push(workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h]);
                  }
                  else if (workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].division === 'Buyout.Scale') {
                    workoutData[i].sectionList[j].sectionRecordList[u].buyoutScaleExerciseList.push(workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h]);
                  }
                }
              }

              for (let h = 0; h < workoutData[i].sectionList[j].exerciseList.length; h++) {
                workoutData[i].sectionList[j].exerciseList[h].movementId = workoutData[i].sectionList[j].exerciseList[h].movement.movementId;
                workoutData[i].sectionList[j].exerciseList[h].movementName = workoutData[i].sectionList[j].exerciseList[h].movement.movementName;
                workoutData[i].sectionList[j].exerciseList[h].videoLinkId = workoutData[i].sectionList[j].exerciseList[h].movement.videoLinkId;

                // // 무게 구분 데이터 사용할 경우 //
                // if (workoutData[i].sectionList[j].exerciseList[h].divisionWeight !== ''
                //   && workoutData[i].sectionList[j].exerciseList[h].divisionWeight !== 'None') {
                //   workoutData[i].sectionList[j].exerciseList[h].weight = workoutData[i].sectionList[j].exerciseList[h].divisionWeight;
                // }
                // else workoutData[i].sectionList[j].exerciseList[h].divisionWeight = '';

                // // 거리 구분 데이터 사용할 경우 //
                // if (workoutData[i].sectionList[j].exerciseList[h].divisionDistance !== ''
                //   && workoutData[i].sectionList[j].exerciseList[h].divisionDistance !== 'None') {
                //   workoutData[i].sectionList[j].exerciseList[h].distance = workoutData[i].sectionList[j].exerciseList[h].divisionDistance;
                // }
                // else workoutData[i].sectionList[j].exerciseList[h].divisionDistance = '';

                // // 높이 구분 데이터 사용할 경우 //
                // if (workoutData[i].sectionList[j].exerciseList[h].divisionHeight !== ''
                //   && workoutData[i].sectionList[j].exerciseList[h].divisionHeight !== 'None') {
                //   workoutData[i].sectionList[j].exerciseList[h].height = workoutData[i].sectionList[j].exerciseList[h].divisionHeight;
                // }
                // else workoutData[i].sectionList[j].exerciseList[h].divisionHeight = '';

                // // 칼로리 구분 데이터 사용할 경우 //
                // if (workoutData[i].sectionList[j].exerciseList[h].divisionCalories !== ''
                //   && workoutData[i].sectionList[j].exerciseList[h].divisionCalories !== 'None') {
                //     workoutData[i].sectionList[j].exerciseList[h].calories = workoutData[i].sectionList[j].exerciseList[h].divisionCalories;
                // }
                // else workoutData[i].sectionList[j].exerciseList[h].divisionCalories = '';

                // // 템포 구분 데이터 사용할 경우 //
                // if (workoutData[i].sectionList[j].exerciseList[h].divisionTempo !== ''
                //   && workoutData[i].sectionList[j].exerciseList[h].divisionTempo !== 'None') {
                //     workoutData[i].sectionList[j].exerciseList[h].tempo = workoutData[i].sectionList[j].exerciseList[h].divisionTempo;
                // }
                // else workoutData[i].sectionList[j].exerciseList[h].divisionTempo = '';

                // // Sets 구분 데이터 사용할 경우 //
                // if (workoutData[i].sectionList[j].exerciseList[h].divisionSets !== '' 
                //   && workoutData[i].sectionList[j].exerciseList[h].divisionSets !== 'None') {
                //     workoutData[i].sectionList[j].exerciseList[h].sets = workoutData[i].sectionList[j].exerciseList[h].divisionSets;
                // }
                // else workoutData[i].sectionList[j].exerciseList[h].divisionSets = '';

                if (workoutData[i].sectionList[j].exerciseList[h].division === 'Buyin') {
                  workoutData[i].sectionList[j].buyinExerciseList.push(workoutData[i].sectionList[j].exerciseList[h]);
                }
                else if (workoutData[i].sectionList[j].exerciseList[h].division === 'Buyin.Scale') {
                  workoutData[i].sectionList[j].buyinScaleExerciseList.push(workoutData[i].sectionList[j].exerciseList[h]);
                }
                else if (workoutData[i].sectionList[j].exerciseList[h].division === 'Main') {
                  workoutData[i].sectionList[j].mainExerciseList.push(workoutData[i].sectionList[j].exerciseList[h]);
                }
                else if (workoutData[i].sectionList[j].exerciseList[h].division === 'Main.Scale') {
                  workoutData[i].sectionList[j].mainScaleExerciseList.push(workoutData[i].sectionList[j].exerciseList[h]);
                }
                else if (workoutData[i].sectionList[j].exerciseList[h].division === 'Main.Emom') {
                  workoutData[i].sectionList[j].emomExerciseList.push(workoutData[i].sectionList[j].exerciseList[h]);
                }
                else if (workoutData[i].sectionList[j].exerciseList[h].division === 'Main.Emom.Scale') {
                  workoutData[i].sectionList[j].emomScaleExerciseList.push(workoutData[i].sectionList[j].exerciseList[h]);
                }
                else if (workoutData[i].sectionList[j].exerciseList[h].division === 'Buyout') {
                  workoutData[i].sectionList[j].buyoutExerciseList.push(workoutData[i].sectionList[j].exerciseList[h]);
                }
                else if (workoutData[i].sectionList[j].exerciseList[h].division === 'Buyout.Scale') {
                  workoutData[i].sectionList[j].buyoutScaleExerciseList.push(workoutData[i].sectionList[j].exerciseList[h]);
                }
              }

              // exerciseIndex.asc 진행 //
              workoutData[i].sectionList[j].buyinExerciseList.sort(function (a, b) {
                return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
              })
              // exerciseIndex.asc 진행 //
              workoutData[i].sectionList[j].buyinScaleExerciseList.sort(function (a, b) {
                return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
              })
              // exerciseIndex.asc 진행 //
              workoutData[i].sectionList[j].mainExerciseList.sort(function (a, b) {
                return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
              })
              // exerciseIndex.asc 진행 //
              workoutData[i].sectionList[j].mainScaleExerciseList.sort(function (a, b) {
                return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
              })
              // exerciseIndex.asc 진행 //
              workoutData[i].sectionList[j].emomExerciseList.sort(function (a, b) {
                return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
              })
              // exerciseIndex.asc 진행 //
              workoutData[i].sectionList[j].emomScaleExerciseList.sort(function (a, b) {
                return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
              })
              // exerciseIndex.asc 진행 //
              workoutData[i].sectionList[j].buyoutExerciseList.sort(function (a, b) {
                return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
              })
              // exerciseIndex.asc 진행 //
              workoutData[i].sectionList[j].buyoutScaleExerciseList.sort(function (a, b) {
                return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
              })
            }
          }

          // setData(workoutData);
          // console.log(workoutData);
          setUseData(workoutData);
        }
        else if (responseData.status === 204) setData([]);
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }, [history, date, /*scheduleId,*/ clickedWorkoutId, rendering])

  // useEffect(() => {
  //   if (dateList.length === 0 || selectedScheduleData === undefined) return;

  //   var newData = data.slice();

  //   if (newData.length === 0) { // 데이터가 없으면 1개 넣고 시작
  //     let isClickable = false;

  //     // 반복날짜에 해당하는지 체크 //
  //     if (selectedScheduleData.applyWeek === 'All' || selectedScheduleData.applyWeek.includes(dateList[0].format('ddd'))) {
  //       // 반복 주차에 해당하는지 확인 //
  //       if (selectedScheduleData.repeatWeek === '0' || selectedScheduleData.repeatWeek.includes(getWeekNumber(dateList[0].format('YYYY-MM-DD')).toString())) {
  //         isClickable = true;
  //       }
  //     }

  //     var tmpData = {
  //       workoutId: 0,
  //       scheduleId: 0,
  //       isClickable: isClickable,
  //       storeId: parseInt(window.localStorage.getItem('StoreId')),
  //       classDate: dateList[0],
  //       title: '',
  //       sectionList: []
  //     }
  //   }

  //   for (var i = 0; i < dateList.length; i++) {
  //     var isNone = true;

  //     for (var j = 0; j < newData.length; j++) {
  //       if (dateList[i].format('MM.DD') === moment(newData[j].classDate).format('MM.DD')) {
  //         isNone = false;
  //         newData[j].isClickable = true;
  //         newData[j].classDate = moment(newData[j].classDate);
  //         break;
  //       }
  //     }

  //     if (isNone) {
  //       let isClickable = false;
  //       // 반복날짜에 해당하는지 체크 //
  //       if (selectedScheduleData.applyWeek === 'All' || selectedScheduleData.applyWeek.includes(dateList[i].format('ddd'))) {
  //         // 반복 주차에 해당하는지 확인 //
  //         if (selectedScheduleData.repeatWeek === '0' || selectedScheduleData.repeatWeek.includes(getWeekNumber(dateList[i].format('YYYY-MM-DD')).toString())) {
  //           isClickable = true;
  //         }
  //       }

  //       tmpData = {
  //         workoutId: 0,
  //         scheduleId: 0,
  //         isClickable: isClickable,
  //         storeId: parseInt(window.localStorage.getItem('StoreId')),
  //         classDate: dateList[i],
  //         title: '',
  //         sectionList: []
  //       }
  //       newData.push(tmpData);
  //     }
  //   }

  //   newData.sort(function (a, b) {
  //     return a.classDate < b.classDate ? -1 : a.classDate > b.classDate ? 1 : 0;
  //   })

  //   setUseData(newData);
  // }, [dateList, data])
  useEffect(() => {
    if (dateList.length === 0) return;

    let workoutData = data.slice();
    let newData = [];

    // 데이터가 없으면 1개 넣고 시작 //
    if (workoutData.length === 0) {

      let tmpData = {
        workoutId: 0,
        scheduleId: 0,
        storeId: parseInt(window.localStorage.getItem('StoreId')),
        classDate: dateList[0],
        title: '',
        lessonName: '',
        sectionList: []
      }

      let tempDateData = {
        isNone: true,
        classDate: dateList[0],
        workouts: [tmpData]
      }

      newData.push(tempDateData);
    }

    for (let i = 0; i < dateList.length; i++) {
      let isNone = true;

      let tempDateData = {
        isNone: false,
        classDate: dateList[i],
        workouts: []
      }

      for (let j = 0; j < workoutData.length; j++) {
        if (dateList[i].format('MM.DD') === moment(workoutData[j].classDate).format('MM.DD')) {
          isNone = false;

          workoutData[j].classDate = moment(workoutData[j].classDate);
          tempDateData.workouts.push(workoutData[j]);
        }
      }

      if (isNone) {
        let tmpEmptyData = {
          workoutId: 0,
          scheduleId: 0,
          // isClickable: isClickable,
          storeId: parseInt(window.localStorage.getItem('StoreId')),
          classDate: dateList[i],
          title: '',
          lessonName: '',
          sectionList: []
        }

        let tempEmptyDateData = {
          isNone: true,
          classDate: dateList[i],
          workouts: [tmpEmptyData]
        }

        newData.push(tempEmptyDateData);
      }
      else {
        newData.push(tempDateData);
      }
    }

    newData.sort(function (a, b) {
      return a.classDate < b.classDate ? -1 : a.classDate > b.classDate ? 1 : 0;
    })
    // console.log(newData);
    // setUseData(newData);
    setDateSetData(newData);
  }, [dateList, data])

  // 날짜에 맞게 세팅 //
  useEffect(() => {
    if (!isFirst) return;
    else if (!isSettingData) return;
    else if (dateSetData.length <= 1) return;
    
    for (let i = 0; i < dateSetData.length; i++) {
      if (dateSetData[i].classDate.clone().format('YYYY.MM.DD') === date.clone().format('YYYY.MM.DD')) {
        setClickedWorkoutId(dateSetData[i].workouts[0].workoutId);
        setIsFirst(false);
        break;
      }
    }
  }, [dateSetData, date, isFirst, isSettingData])


  // 순위 정리 //
  useEffect(() => {
    let tag = '';
    let scoreKeyword = '';
    let userRankingList = [];

    for (let i = 0; i < useData.length; i++) {

      if (useData[i].sectionList[nowSection] === undefined) {
        setRankingList([]);
        return;
      }


      tag = useData[i].sectionList[nowSection].tag;
      scoreKeyword = useData[i].sectionList[nowSection].scoreKeyword;

      let tmpList = [];
      // let totalScore = 0;

      // Custom인 경우 순위 따로 산출 //
      if (useData[i].sectionList[nowSection].tag === 'Custom') {
        let finalScoreText = '';

        // 순위표 구성을 위한 커스텀 기준데이터 확인 //
        // 점수 산출 방법이 없을 경우 ( 입력 순서대로 오름차순 )//
        if (useData[i].sectionList[nowSection].scoreKeyword === 'Not Scored') {
          let totalScore = 0;
          finalScoreText = '점수 기준 없음';
          tmpList = useData[i].sectionList[nowSection].sectionRecordList.slice();

          tmpList.sort(function (a, b) {
            return a.createdDate < b.createdDate ? -1 : a.createdDate < b.createdDate ? 1 : 0;
          })

          totalScore = tmpList.length;

          for (let j = 0; j < tmpList.length; j++) {
            totalScore -= j;

            // 이미 저장된 내역 확인 Flag //
            let isExist = false;
            // 이미 저장된 유저 점수가 있는지 확인 //
            for (let x = 0; x < userRankingList.length; x++) {
              // 이미 점수가 있으면 기존점수에 + 하기 //
              if (userRankingList[x].linkCoachId === tmpList[j].linkCoachId) {
                totalScore += totalScore;
                // 저장 플래그 //
                isExist = true;
              }
            }

            // 저장된게 없으면 //
            if (!isExist) {
              let rankingInfo = {
                linkCoachId: useData[i].sectionList[nowSection].sectionRecordList[j].linkCoachId,
                userName: useData[i].sectionList[nowSection].sectionRecordList[j].userName,
                userProfile: useData[i].sectionList[nowSection].sectionRecordList[j].userProfile,
                createdDate: useData[i].sectionList[nowSection].sectionRecordList[j].createdDate,
                finalScoreText: finalScoreText,
                totalScore: totalScore
              }

              userRankingList.push(rankingInfo);
            }
          }
        }
        // 시간 기준 점수 산출일 경우 //
        else if (useData[i].sectionList[nowSection].scoreKeyword === 'Time') {

          // 높은 점수 기준 //
          if (useData[i].sectionList[nowSection].scorePriority === 'heigher') {
            for (let j = 0; j < useData[i].sectionList[nowSection].sectionRecordList.length; j++) {
              let totalScore = 0;

              totalScore = useData[i].sectionList[nowSection].sectionRecordList[j].recordTime;

              // 이미 저장된 내역 확인 Flag //
              let isExist = false;
              // 이미 저장된 유저 점수가 있는지 확인 //
              for (let x = 0; x < userRankingList.length; x++) {
                // 이미 점수가 있으면 기존점수에 + 하기 //
                if (userRankingList[x].linkCoachId === useData[i].sectionList[nowSection].sectionRecordList[j].linkCoachId) {
                  totalScore += totalScore;
                  // 저장 플래그 //
                  isExist = true;
                }
              }

              // 저장된게 없으면 //
              if (!isExist) {

                let totalText = ''
                let min = 0;
                let sec = 0;
                let decimalPoint = (useData[i].sectionList[nowSection].sectionRecordList[j].recordTime % 1).toFixed(2);

                min = parseInt(useData[i].sectionList[nowSection].sectionRecordList[j].recordTime / 60);

                if (decimalPoint !== '0.00') sec = parseInt(useData[i].sectionList[nowSection].sectionRecordList[j].recordTime % 60) + parseFloat(decimalPoint);
                else sec = parseInt(useData[i].sectionList[nowSection].sectionRecordList[j].recordTime % 60);

                if (sec === 0) {
                  if (min < 10) totalText = '0' + min + ':00';
                  else totalText = min + ':00';
                }
                else {
                  if (min < 10) {
                    if (sec < 10) totalText = min + ':0' + sec;
                    else totalText = min + ':' + sec;
                  }
                  else {
                    if (sec < 10) totalText = min + ':0' + sec;
                    else totalText = min + ':' + sec;
                  }
                }

                finalScoreText = totalText;

                let rankingInfo = {
                  linkCoachId: useData[i].sectionList[nowSection].sectionRecordList[j].linkCoachId,
                  userName: useData[i].sectionList[nowSection].sectionRecordList[j].userName,
                  userProfile: useData[i].sectionList[nowSection].sectionRecordList[j].userProfile,
                  createdDate: useData[i].sectionList[nowSection].sectionRecordList[j].createdDate,
                  finalScoreText: finalScoreText,
                  totalScore: totalScore
                }

                userRankingList.push(rankingInfo);
              }
            }
          }
          // 낮은 점수 기준 //
          else {
            for (let j = 0; j < useData[i].sectionList[nowSection].sectionRecordList.length; j++) {
              let totalScore = 0;

              if (useData[i].sectionList[nowSection].sectionRecordList[j].recordTime === 0
                || useData[i].sectionList[nowSection].sectionRecordList[j].success === 'Not Done') {
                totalScore = -50000;
              }
              else {
                totalScore = (useData[i].sectionList[nowSection].sectionRecordList[j].recordTime * -1);
              }

              // 이미 저장된 내역 확인 Flag //
              let isExist = false;
              // 이미 저장된 유저 점수가 있는지 확인 //
              for (let x = 0; x < userRankingList.length; x++) {
                // 이미 점수가 있으면 기존점수에 + 하기 //
                if (userRankingList[x].linkCoachId === useData[i].sectionList[nowSection].sectionRecordList[j].linkCoachId) {
                  totalScore += totalScore;
                  // 저장 플래그 //
                  isExist = true;
                }
              }

              // 저장된게 없으면 //
              if (!isExist) {


                let totalText = ''
                let min = 0;
                let sec = 0;
                let decimalPoint = (useData[i].sectionList[nowSection].sectionRecordList[j].recordTime % 1).toFixed(2);

                min = parseInt(useData[i].sectionList[nowSection].sectionRecordList[j].recordTime / 60);

                if (decimalPoint !== '0.00') sec = parseInt(useData[i].sectionList[nowSection].sectionRecordList[j].recordTime % 60) + parseFloat(decimalPoint);
                else sec = parseInt(useData[i].sectionList[nowSection].sectionRecordList[j].recordTime % 60);

                if (sec === 0) {
                  if (min < 10) totalText = '0' + min + ':00';
                  else totalText = min + ':00';
                }
                else {
                  if (min < 10) {
                    if (sec < 10) totalText = min + ':0' + sec;
                    else totalText = min + ':' + sec;
                  }
                  else {
                    if (sec < 10) totalText = min + ':0' + sec;
                    else totalText = min + ':' + sec;
                  }
                }

                finalScoreText = totalText;

                let rankingInfo = {
                  linkCoachId: useData[i].sectionList[nowSection].sectionRecordList[j].linkCoachId,
                  userName: useData[i].sectionList[nowSection].sectionRecordList[j].userName,
                  userProfile: useData[i].sectionList[nowSection].sectionRecordList[j].userProfile,
                  createdDate: useData[i].sectionList[nowSection].sectionRecordList[j].createdDate,
                  finalScoreText: finalScoreText,
                  totalScore: totalScore
                }

                userRankingList.push(rankingInfo);
              }
            }
          }
        }
        // 횟수, 무게, 거리, 포인트 기준 점수 산출일 경우 //
        else if (useData[i].sectionList[nowSection].scoreKeyword === 'Reps'
          || useData[i].sectionList[nowSection].scoreKeyword === 'Weight'
          || useData[i].sectionList[nowSection].scoreKeyword === 'Distance'
          || useData[i].sectionList[nowSection].scoreKeyword === 'Points'
          || useData[i].sectionList[nowSection].scoreKeyword === 'Peak Watts'
          || useData[i].sectionList[nowSection].scoreKeyword === 'Max Speed') {

          let finalScoreText = '';
          
          // 높은 점수 기준 //
          if (useData[i].sectionList[nowSection].scorePriority === 'heigher') {
            for (let j = 0; j < useData[i].sectionList[nowSection].sectionRecordList.length; j++) {
              // let totalScore = (useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordValue);
              let totalScore = 0;

              if (useData[i].sectionList[nowSection].scoreKeyword === 'Weight') {
                if (useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordUnit === 'Kg') totalScore = (useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordValue * 2.205);
                else totalScore = useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordValue;
              }
              else if (useData[i].sectionList[nowSection].scoreKeyword === 'Distance') {
                if (useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordUnit === 'Km') totalScore = (useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordValue / 1000);
                else if (useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordUnit === 'cm') totalScore = (useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordValue * 100);
                else if (useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordUnit === 'inch') totalScore = (useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordValue * 39.37);
                else if (useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordUnit === 'Feet') totalScore = (useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordValue * 3.281);
                else totalScore = useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordValue;
              }
              else totalScore = useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordValue;

              if (useData[i].sectionList[nowSection].scoreKeyword === 'Weight') finalScoreText = useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordValue + ' ' + (useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordUnit === 'Pounds' ? 'lb' : useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordUnit);
              else if (useData[i].sectionList[nowSection].scoreKeyword === 'Distance') finalScoreText = useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordValue + ' ' + useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordUnit;
              else finalScoreText = useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordValue + ' ' + useData[i].sectionList[nowSection].scoreKeyword;

              // 이미 저장된 내역 확인 Flag //
              let isExist = false;
              // 이미 저장된 유저 점수가 있는지 확인 //
              for (let x = 0; x < userRankingList.length; x++) {
                // 이미 점수가 있으면 기존점수에 + 하기 //
                if (userRankingList[x].linkCoachId === useData[i].sectionList[nowSection].sectionRecordList[j].linkCoachId) {
                  totalScore += totalScore;
                  // 저장 플래그 //
                  isExist = true;
                }
              }

              // 저장된게 없으면 //
              if (!isExist) {
                let rankingInfo = {
                  linkCoachId: useData[i].sectionList[nowSection].sectionRecordList[j].linkCoachId,
                  userName: useData[i].sectionList[nowSection].sectionRecordList[j].userName,
                  userProfile: useData[i].sectionList[nowSection].sectionRecordList[j].userProfile,
                  createdDate: useData[i].sectionList[nowSection].sectionRecordList[j].createdDate,
                  finalScoreText: finalScoreText,
                  totalScore: totalScore
                }

                userRankingList.push(rankingInfo);
              }
            }
          }
          // 낮은 점수 기준 //
          else {
            for (let j = 0; j < useData[i].sectionList[nowSection].sectionRecordList.length; j++) {
              let totalScore = 0;

              if (useData[i].sectionList[nowSection].scoreKeyword === 'Weight') {
                if (useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordUnit === 'Kg') totalScore = (useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordValue * 2.205);
                else totalScore = useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordValue;
              }
              else if (useData[i].sectionList[nowSection].scoreKeyword === 'Distance') {
                if (useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordUnit === 'Km') totalScore = (useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordValue / 1000);
                else if (useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordUnit === 'cm') totalScore = (useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordValue * 100);
                else if (useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordUnit === 'inch') totalScore = (useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordValue * 39.37);
                else if (useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordUnit === 'Feet') totalScore = (useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordValue * 3.281);
                else totalScore = useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordValue;
              }
              else totalScore = useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordValue;

              if (useData[i].sectionList[nowSection].scoreKeyword === 'Weight') finalScoreText = useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordValue + ' ' + (useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordUnit === 'Pounds' ? 'lb' : useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordUnit);
              else if (useData[i].sectionList[nowSection].scoreKeyword === 'Distance') finalScoreText = useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordValue + ' ' + useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordUnit;
              else finalScoreText = useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordValue + ' ' + useData[i].sectionList[nowSection].scoreKeyword;

              if (useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordValue === 0
                || useData[i].sectionList[nowSection].sectionRecordList[j].success === 'Not Done') {
                totalScore = -50000;
              }
              else {
                totalScore = (useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordValue * -1);
              }
              // let totalScore = (useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[j].customKeywordValue * -1);

              // 이미 저장된 내역 확인 Flag //
              let isExist = false;
              // 이미 저장된 유저 점수가 있는지 확인 //
              for (let x = 0; x < userRankingList.length; x++) {
                // 이미 점수가 있으면 기존점수에 + 하기 //
                if (userRankingList[x].linkCoachId === useData[i].sectionList[nowSection].sectionRecordList[j].linkCoachId) {
                  totalScore += totalScore;
                  // 저장 플래그 //
                  isExist = true;
                }
              }

              // 저장된게 없으면 //
              if (!isExist) {
                let rankingInfo = {
                  linkCoachId: useData[i].sectionList[nowSection].sectionRecordList[j].linkCoachId,
                  userName: useData[i].sectionList[nowSection].sectionRecordList[j].userName,
                  userProfile: useData[i].sectionList[nowSection].sectionRecordList[j].userProfile,
                  createdDate: useData[i].sectionList[nowSection].sectionRecordList[j].createdDate,
                  finalScoreText: finalScoreText,
                  totalScore: totalScore
                }

                userRankingList.push(rankingInfo);
              }
            }
          }
        }
        // 라운드 횟수 기준 점수 산출일 경우 //
        else if (useData[i].sectionList[nowSection].scoreKeyword === 'Rounds And Reps') {
          let totalScore = 0;

          // 높은 점수 기준 //
          if (useData[i].sectionList[nowSection].scorePriority === 'heigher') {
            for (let j = 0; j < useData[i].sectionList[nowSection].sectionRecordList.length; j++) {
              totalScore = 0;

              let round = (useData[i].sectionList[nowSection].sectionRecordList[j].customLastRound * 100);
              let reps = (useData[i].sectionList[nowSection].sectionRecordList[j].customLastReps);

              finalScoreText = useData[i].sectionList[nowSection].sectionRecordList[j].customLastRound + ' Rounds ' + useData[i].sectionList[nowSection].sectionRecordList[j].customLastReps + ' Reps';
              // console.log('-------');
              // // console.log(useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[j].linkCoachId);
              // console.log(useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[j].userName);
              // console.log('round : ' + round);
              // console.log('reps : ' + reps);
              // console.log('totalScore : ' + totalScore);

              totalScore += (round + reps);

              // 이미 저장된 내역 확인 Flag //
              let isExist = false;
              // 이미 저장된 유저 점수가 있는지 확인 //
              for (let x = 0; x < userRankingList.length; x++) {
                // 이미 점수가 있으면 기존점수에 + 하기 //
                if (userRankingList[x].linkCoachId === useData[i].sectionList[nowSection].sectionRecordList[j].linkCoachId) {

                  totalScore += totalScore;
                  // 저장 플래그 //
                  isExist = true;
                }
              }

              // 저장된게 없으면 //
              if (!isExist) {
                let rankingInfo = {
                  linkCoachId: useData[i].sectionList[nowSection].sectionRecordList[j].linkCoachId,
                  userName: useData[i].sectionList[nowSection].sectionRecordList[j].userName,
                  userProfile: useData[i].sectionList[nowSection].sectionRecordList[j].userProfile,
                  createdDate: useData[i].sectionList[nowSection].sectionRecordList[j].createdDate,
                  finalScoreText: finalScoreText,
                  totalScore: totalScore
                }
                userRankingList.push(rankingInfo);
              }
            }
          }
          // 낮은 점수 기준 //
          else {
            for (let j = 0; j < useData[i].sectionList[nowSection].sectionRecordList.length; j++) {
              let round = (useData[i].sectionList[nowSection].sectionRecordList[j].customLastRound * 100);
              let reps = (useData[i].sectionList[nowSection].sectionRecordList[j].customLastReps);

              finalScoreText = useData[i].sectionList[nowSection].sectionRecordList[j].customLastRound + ' Rounds ' + useData[i].sectionList[nowSection].sectionRecordList[j].customLastReps + ' Reps';

              totalScore = 0;

              if (useData[i].sectionList[nowSection].sectionRecordList[j].success === 'Not Done') {
                totalScore = -50000;
              }
              else {
                totalScore += ((round + reps) * -1);
              }

              // 이미 저장된 내역 확인 Flag //
              let isExist = false;
              // 이미 저장된 유저 점수가 있는지 확인 //
              for (let x = 0; x < userRankingList.length; x++) {
                // 이미 점수가 있으면 기존점수에 + 하기 //
                if (userRankingList[x].linkCoachId === useData[i].sectionList[nowSection].sectionRecordList[j].linkCoachId) {
                  totalScore += totalScore;
                  // 저장 플래그 //
                  isExist = true;
                }
              }

              // 저장된게 없으면 //
              if (!isExist) {
                let rankingInfo = {
                  linkCoachId: useData[i].sectionList[nowSection].sectionRecordList[j].linkCoachId,
                  userName: useData[i].sectionList[nowSection].sectionRecordList[j].userName,
                  userProfile: useData[i].sectionList[nowSection].sectionRecordList[j].userProfile,
                  createdDate: useData[i].sectionList[nowSection].sectionRecordList[j].createdDate,
                  finalScoreText: finalScoreText,
                  totalScore: totalScore
                }

                userRankingList.push(rankingInfo);
              }
            }
          }
        }
      }
      // Custom이 아닌경우 점수로 순위 산출 //
      else {
        for (let h = 0; h < useData[i].sectionList[nowSection].sectionRecordList.length; h++) {
          // var round = 0;
          // var roundOfCount = 0;
          // var weight = 0;
          // var reps = 0;
          // var calories = 0;
          // var distance = 0;
          // var height = 0;
          // var timeRemaining = 0;
          // var successScore = 0;
          // 점수 구성 항목 //
          let round = 0;
          let roundOfCount = 0;
          let weight = 0;
          let reps = 0;
          let calories = 0;
          let distance = 0;
          let height = 0;
          let time = useData[i].sectionList[nowSection].sectionRecordList[h].recordTime;
          let timeRemaining = 0;
          let successScore = 0;
          let finalScoreText = '';
          let minusPointCount = 0;

          let lastMovementBonus = 1;
          let lastExerciseIndex = 0;
          let lastRound = useData[i].sectionList[nowSection].sectionRecordList[h].completeRound * 10000;
          let lastReps = useData[i].sectionList[nowSection].sectionRecordList[h].lastRound;

          for (let j = 0; j < useData[i].sectionList[nowSection].sectionRecordList[h].exerciseList.length; j++) {
            if (useData[i].sectionList[nowSection].sectionRecordList[h].lastExerciseId === useData[i].sectionList[nowSection].sectionRecordList[h].exerciseList[j].exerciseId) {
              lastExerciseIndex = j;
              lastMovementBonus = (useData[i].sectionList[nowSection].sectionRecordList[h].exerciseList[j].exerciseIndex * 100);
              break;
            }
          }

          // // 운동 내역 전체 점수 부여 ( 아예 진행하지 않은 운동은 점수 부여 제외 ) //
          // if (useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[h].success !== 'Not Done') {

          // for (let z = 0; z < useData[i].sectionList[nowSection].sectionRecordList[h].exerciseList.length; z++) {
          //   // 무게 전부 더하기 //
          //   weight += useData[i].sectionList[nowSection].sectionRecordList[h].exerciseList[z].weight;
          //   // 횟수 전부 더하기 //
          //   reps += parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].exerciseList[z].count);
          //   // 칼로리 전부 더하기 //
          //   calories += useData[i].sectionList[nowSection].sectionRecordList[h].exerciseList[z].calories;
          //   // 거리 전부 더하기 //
          //   distance += useData[i].sectionList[nowSection].sectionRecordList[h].exerciseList[z].distance;
          //   // 높이 전부 더하기 //
          //   height += useData[i].sectionList[nowSection].sectionRecordList[h].exerciseList[z].height;
          // }

          let buyinExceptIndexs = [];
          let mainExceptIndexs = [];
          let emomExceptIndexs = [];
          let buyoutExceptIndexs = [];
          
          // buyin Scale 진행 내역 찾기 //
          for (let z = 0; z < useData[i].sectionList[nowSection].sectionRecordList[h].buyinScaleExerciseList.length; z++) {
            if (useData[i].sectionList[nowSection].sectionRecordList[h].buyinScaleExerciseList[z].isProgress) {
              // 순위 산출을 위한 단위 통일 //
              let weightUnitScale = useData[i].sectionList[nowSection].sectionRecordList[h].buyinScaleExerciseList[z].weightUnit === 'Kg' ? 2.2 : 1;
              let caloriesUnitScale = useData[i].sectionList[nowSection].sectionRecordList[h].buyinScaleExerciseList[z].caloriesUnit === 'Kcal' ? 1000 : 1;
              let distanceUnitScale = 1;
              let heightUnitScale = 1;
  
              if (useData[i].sectionList[nowSection].sectionRecordList[h].buyinScaleExerciseList[z].heightUnit === 'Km') distanceUnitScale = 1000;
              else if (useData[i].sectionList[nowSection].sectionRecordList[h].buyinScaleExerciseList[z].heightUnit === 'inch') distanceUnitScale = (1 / 39.37);
              else if (useData[i].sectionList[nowSection].sectionRecordList[h].buyinScaleExerciseList[z].heightUnit === 'Feet') distanceUnitScale = (1 / 3.281);
              else if (useData[i].sectionList[nowSection].sectionRecordList[h].buyinScaleExerciseList[z].heightUnit === 'inch') distanceUnitScale = (1 / 100);
  
              if (useData[i].sectionList[nowSection].sectionRecordList[h].buyinScaleExerciseList[z].heightUnit === 'Km') heightUnitScale = 1000;
              else if (useData[i].sectionList[nowSection].sectionRecordList[h].buyinScaleExerciseList[z].heightUnit === 'inch') heightUnitScale = (1 / 39.37);
              else if (useData[i].sectionList[nowSection].sectionRecordList[h].buyinScaleExerciseList[z].heightUnit === 'Feet') heightUnitScale = (1 / 3.281);
              else if (useData[i].sectionList[nowSection].sectionRecordList[h].buyinScaleExerciseList[z].heightUnit === 'inch') heightUnitScale = (1 / 100);
  
              // 무게 전부 더하기 //
              weight += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].buyinScaleExerciseList[z].weight) * weightUnitScale * 0.5);
              // 횟수 전부 더하기 //
              reps += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].buyinScaleExerciseList[z].count === '' ? 0 : useData[i].sectionList[nowSection].sectionRecordList[h].buyinScaleExerciseList[z].count) * 0.5);
              // 칼로리 전부 더하기 //
              calories += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].buyinScaleExerciseList[z].calories) * caloriesUnitScale * 0.5);
              // 거리 전부 더하기 //
              distance += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].buyinScaleExerciseList[z].distance) * distanceUnitScale * 0.5);
              // 높이 전부 더하기 //
              height += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].buyinScaleExerciseList[z].height) * heightUnitScale * 0.5);

              buyinExceptIndexs.push(useData[i].sectionList[nowSection].sectionRecordList[h].buyinScaleExerciseList[z].dependencyIndex);
            }
          }
          
          // main Scale 진행 내역 찾기 //
          for (let z = 0; z < useData[i].sectionList[nowSection].sectionRecordList[h].mainScaleExerciseList.length; z++) {
            if (useData[i].sectionList[nowSection].sectionRecordList[h].mainScaleExerciseList[z].isProgress) {
              // 순위 산출을 위한 단위 통일 //
              let weightUnitScale = useData[i].sectionList[nowSection].sectionRecordList[h].mainScaleExerciseList[z].weightUnit === 'Kg' ? 2.2 : 1;
              let caloriesUnitScale = useData[i].sectionList[nowSection].sectionRecordList[h].mainScaleExerciseList[z].caloriesUnit === 'Kcal' ? 1000 : 1;
              let distanceUnitScale = 1;
              let heightUnitScale = 1;
  
              if (useData[i].sectionList[nowSection].sectionRecordList[h].mainScaleExerciseList[z].heightUnit === 'Km') distanceUnitScale = 1000;
              else if (useData[i].sectionList[nowSection].sectionRecordList[h].mainScaleExerciseList[z].heightUnit === 'inch') distanceUnitScale = (1 / 39.37);
              else if (useData[i].sectionList[nowSection].sectionRecordList[h].mainScaleExerciseList[z].heightUnit === 'Feet') distanceUnitScale = (1 / 3.281);
              else if (useData[i].sectionList[nowSection].sectionRecordList[h].mainScaleExerciseList[z].heightUnit === 'inch') distanceUnitScale = (1 / 100);
  
              if (useData[i].sectionList[nowSection].sectionRecordList[h].mainScaleExerciseList[z].heightUnit === 'Km') heightUnitScale = 1000;
              else if (useData[i].sectionList[nowSection].sectionRecordList[h].mainScaleExerciseList[z].heightUnit === 'inch') heightUnitScale = (1 / 39.37);
              else if (useData[i].sectionList[nowSection].sectionRecordList[h].mainScaleExerciseList[z].heightUnit === 'Feet') heightUnitScale = (1 / 3.281);
              else if (useData[i].sectionList[nowSection].sectionRecordList[h].mainScaleExerciseList[z].heightUnit === 'inch') heightUnitScale = (1 / 100);
  
              // 무게 전부 더하기 //
              weight += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].mainScaleExerciseList[z].weight) * weightUnitScale * 0.5);
              // 횟수 전부 더하기 //
              reps += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].mainScaleExerciseList[z].count === '' ? 0 : useData[i].sectionList[nowSection].sectionRecordList[h].mainScaleExerciseList[z].count) * 0.5);
              // 칼로리 전부 더하기 //
              calories += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].mainScaleExerciseList[z].calories) * caloriesUnitScale * 0.5);
              // 거리 전부 더하기 //
              distance += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].mainScaleExerciseList[z].distance) * distanceUnitScale * 0.5);
              // 높이 전부 더하기 //
              height += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].mainScaleExerciseList[z].height) * heightUnitScale * 0.5);

              mainExceptIndexs.push(useData[i].sectionList[nowSection].sectionRecordList[h].mainScaleExerciseList[z].dependencyIndex);
            }
          }

          // emom Scale 진행 내역 찾기 //
          for (let z = 0; z < useData[i].sectionList[nowSection].sectionRecordList[h].emomScaleExerciseList.length; z++) {
            if (useData[i].sectionList[nowSection].sectionRecordList[h].emomScaleExerciseList[z].isProgress) {
              // 순위 산출을 위한 단위 통일 //
              let weightUnitScale = useData[i].sectionList[nowSection].sectionRecordList[h].emomScaleExerciseList[z].weightUnit === 'Kg' ? 2.2 : 1;
              let caloriesUnitScale = useData[i].sectionList[nowSection].sectionRecordList[h].emomScaleExerciseList[z].caloriesUnit === 'Kcal' ? 1000 : 1;
              let distanceUnitScale = 1;
              let heightUnitScale = 1;
  
              if (useData[i].sectionList[nowSection].sectionRecordList[h].emomScaleExerciseList[z].heightUnit === 'Km') distanceUnitScale = 1000;
              else if (useData[i].sectionList[nowSection].sectionRecordList[h].emomScaleExerciseList[z].heightUnit === 'inch') distanceUnitScale = (1 / 39.37);
              else if (useData[i].sectionList[nowSection].sectionRecordList[h].emomScaleExerciseList[z].heightUnit === 'Feet') distanceUnitScale = (1 / 3.281);
              else if (useData[i].sectionList[nowSection].sectionRecordList[h].emomScaleExerciseList[z].heightUnit === 'inch') distanceUnitScale = (1 / 100);
  
              if (useData[i].sectionList[nowSection].sectionRecordList[h].emomScaleExerciseList[z].heightUnit === 'Km') heightUnitScale = 1000;
              else if (useData[i].sectionList[nowSection].sectionRecordList[h].emomScaleExerciseList[z].heightUnit === 'inch') heightUnitScale = (1 / 39.37);
              else if (useData[i].sectionList[nowSection].sectionRecordList[h].emomScaleExerciseList[z].heightUnit === 'Feet') heightUnitScale = (1 / 3.281);
              else if (useData[i].sectionList[nowSection].sectionRecordList[h].emomScaleExerciseList[z].heightUnit === 'inch') heightUnitScale = (1 / 100);
  
              // 무게 전부 더하기 //
              weight += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].emomScaleExerciseList[z].weight) * weightUnitScale * 0.5);
              // 횟수 전부 더하기 //
              reps += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].emomScaleExerciseList[z].count === '' ? 0 : useData[i].sectionList[nowSection].sectionRecordList[h].emomScaleExerciseList[z].count) * 0.5);
              // 칼로리 전부 더하기 //
              calories += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].emomScaleExerciseList[z].calories) * caloriesUnitScale * 0.5);
              // 거리 전부 더하기 //
              distance += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].emomScaleExerciseList[z].distance) * distanceUnitScale * 0.5);
              // 높이 전부 더하기 //
              height += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].emomScaleExerciseList[z].height) * heightUnitScale * 0.5);

              emomExceptIndexs.push(useData[i].sectionList[nowSection].sectionRecordList[h].emomScaleExerciseList[z].dependencyIndex);
            }
          }

          // buyout Scale 진행 내역 찾기 //
          for (let z = 0; z < useData[i].sectionList[nowSection].sectionRecordList[h].buyoutScaleExerciseList.length; z++) {
            if (useData[i].sectionList[nowSection].sectionRecordList[h].buyoutScaleExerciseList[z].isProgress) {
              // 순위 산출을 위한 단위 통일 //
              let weightUnitScale = useData[i].sectionList[nowSection].sectionRecordList[h].buyoutScaleExerciseList[z].weightUnit === 'Kg' ? 2.2 : 1;
              let caloriesUnitScale = useData[i].sectionList[nowSection].sectionRecordList[h].buyoutScaleExerciseList[z].caloriesUnit === 'Kcal' ? 1000 : 1;
              let distanceUnitScale = 1;
              let heightUnitScale = 1;
  
              if (useData[i].sectionList[nowSection].sectionRecordList[h].buyoutScaleExerciseList[z].heightUnit === 'Km') distanceUnitScale = 1000;
              else if (useData[i].sectionList[nowSection].sectionRecordList[h].buyoutScaleExerciseList[z].heightUnit === 'inch') distanceUnitScale = (1 / 39.37);
              else if (useData[i].sectionList[nowSection].sectionRecordList[h].buyoutScaleExerciseList[z].heightUnit === 'Feet') distanceUnitScale = (1 / 3.281);
              else if (useData[i].sectionList[nowSection].sectionRecordList[h].buyoutScaleExerciseList[z].heightUnit === 'inch') distanceUnitScale = (1 / 100);
  
              if (useData[i].sectionList[nowSection].sectionRecordList[h].buyoutScaleExerciseList[z].heightUnit === 'Km') heightUnitScale = 1000;
              else if (useData[i].sectionList[nowSection].sectionRecordList[h].buyoutScaleExerciseList[z].heightUnit === 'inch') heightUnitScale = (1 / 39.37);
              else if (useData[i].sectionList[nowSection].sectionRecordList[h].buyoutScaleExerciseList[z].heightUnit === 'Feet') heightUnitScale = (1 / 3.281);
              else if (useData[i].sectionList[nowSection].sectionRecordList[h].buyoutScaleExerciseList[z].heightUnit === 'inch') heightUnitScale = (1 / 100);
  
              // 무게 전부 더하기 //
              weight += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].buyoutScaleExerciseList[z].weight) * weightUnitScale * 0.5);
              // 횟수 전부 더하기 //
              reps += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].buyoutScaleExerciseList[z].count === '' ? 0 : useData[i].sectionList[nowSection].sectionRecordList[h].buyoutScaleExerciseList[z].count) * 0.5);
              // 칼로리 전부 더하기 //
              calories += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].buyoutScaleExerciseList[z].calories) * caloriesUnitScale * 0.5);
              // 거리 전부 더하기 //
              distance += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].buyoutScaleExerciseList[z].distance) * distanceUnitScale * 0.5);
              // 높이 전부 더하기 //
              height += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].buyoutScaleExerciseList[z].height) * heightUnitScale * 0.5);

              buyoutExceptIndexs.push(useData[i].sectionList[nowSection].sectionRecordList[h].buyoutScaleExerciseList[z].dependencyIndex);
            }
          }

          minusPointCount = (buyinExceptIndexs.length + mainExceptIndexs.length + emomExceptIndexs.length + buyoutExceptIndexs.length);

          // buyin 진행 내역 찾기 //
          for (let z = 0; z < useData[i].sectionList[nowSection].sectionRecordList[h].buyinExerciseList.length; z++) {
            if (!buyinExceptIndexs.includes(useData[i].sectionList[nowSection].sectionRecordList[h].buyinExerciseList[z].exerciseIndex - 1)) {
              // 순위 산출을 위한 단위 통일 //
              let weightUnitScale = useData[i].sectionList[nowSection].sectionRecordList[h].buyinExerciseList[z].weightUnit === 'Kg' ? 2.2 : 1;
              let caloriesUnitScale = useData[i].sectionList[nowSection].sectionRecordList[h].buyinExerciseList[z].caloriesUnit === 'Kcal' ? 1000 : 1;
              let distanceUnitScale = 1;
              let heightUnitScale = 1;
  
              if (useData[i].sectionList[nowSection].sectionRecordList[h].buyinExerciseList[z].heightUnit === 'Km') distanceUnitScale = 1000;
              else if (useData[i].sectionList[nowSection].sectionRecordList[h].buyinExerciseList[z].heightUnit === 'inch') distanceUnitScale = (1 / 39.37);
              else if (useData[i].sectionList[nowSection].sectionRecordList[h].buyinExerciseList[z].heightUnit === 'Feet') distanceUnitScale = (1 / 3.281);
              else if (useData[i].sectionList[nowSection].sectionRecordList[h].buyinExerciseList[z].heightUnit === 'inch') distanceUnitScale = (1 / 100);
  
              if (useData[i].sectionList[nowSection].sectionRecordList[h].buyinExerciseList[z].heightUnit === 'Km') heightUnitScale = 1000;
              else if (useData[i].sectionList[nowSection].sectionRecordList[h].buyinExerciseList[z].heightUnit === 'inch') heightUnitScale = (1 / 39.37);
              else if (useData[i].sectionList[nowSection].sectionRecordList[h].buyinExerciseList[z].heightUnit === 'Feet') heightUnitScale = (1 / 3.281);
              else if (useData[i].sectionList[nowSection].sectionRecordList[h].buyinExerciseList[z].heightUnit === 'inch') heightUnitScale = (1 / 100);
  
              // 무게 전부 더하기 //
              weight += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].buyinExerciseList[z].weight) * weightUnitScale);
              // 횟수 전부 더하기 //
              reps += parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].buyinExerciseList[z].count === '' ? 0 : useData[i].sectionList[nowSection].sectionRecordList[h].buyinExerciseList[z].count);
              // 칼로리 전부 더하기 //
              calories += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].buyinExerciseList[z].calories) * caloriesUnitScale);
              // 거리 전부 더하기 //
              distance += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].buyinExerciseList[z].distance) * distanceUnitScale);
              // 높이 전부 더하기 //
              height += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].buyinExerciseList[z].height) * heightUnitScale);
            }
          }

          // main 진행 내역 찾기 //
          for (let z = 0; z < useData[i].sectionList[nowSection].sectionRecordList[h].mainExerciseList.length; z++) {
            if (!mainExceptIndexs.includes(useData[i].sectionList[nowSection].sectionRecordList[h].mainExerciseList[z].exerciseIndex - 1)) {
              // 순위 산출을 위한 단위 통일 //
              let weightUnitScale = useData[i].sectionList[nowSection].sectionRecordList[h].mainExerciseList[z].weightUnit === 'Kg' ? 2.2 : 1;
              let caloriesUnitScale = useData[i].sectionList[nowSection].sectionRecordList[h].mainExerciseList[z].caloriesUnit === 'Kcal' ? 1000 : 1;
              let distanceUnitScale = 1;
              let heightUnitScale = 1;
  
              if (useData[i].sectionList[nowSection].sectionRecordList[h].mainExerciseList[z].heightUnit === 'Km') distanceUnitScale = 1000;
              else if (useData[i].sectionList[nowSection].sectionRecordList[h].mainExerciseList[z].heightUnit === 'inch') distanceUnitScale = (1 / 39.37);
              else if (useData[i].sectionList[nowSection].sectionRecordList[h].mainExerciseList[z].heightUnit === 'Feet') distanceUnitScale = (1 / 3.281);
              else if (useData[i].sectionList[nowSection].sectionRecordList[h].mainExerciseList[z].heightUnit === 'inch') distanceUnitScale = (1 / 100);
  
              if (useData[i].sectionList[nowSection].sectionRecordList[h].mainExerciseList[z].heightUnit === 'Km') heightUnitScale = 1000;
              else if (useData[i].sectionList[nowSection].sectionRecordList[h].mainExerciseList[z].heightUnit === 'inch') heightUnitScale = (1 / 39.37);
              else if (useData[i].sectionList[nowSection].sectionRecordList[h].mainExerciseList[z].heightUnit === 'Feet') heightUnitScale = (1 / 3.281);
              else if (useData[i].sectionList[nowSection].sectionRecordList[h].mainExerciseList[z].heightUnit === 'inch') heightUnitScale = (1 / 100);
  
              // 무게 전부 더하기 //
              weight += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].mainExerciseList[z].weight) * weightUnitScale);
              // 횟수 전부 더하기 //
              reps += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].mainExerciseList[z].count === '' ? 0 : useData[i].sectionList[nowSection].sectionRecordList[h].mainExerciseList[z].count));
              // 칼로리 전부 더하기 //
              calories += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].mainExerciseList[z].calories) * caloriesUnitScale);
              // 거리 전부 더하기 //
              distance += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].mainExerciseList[z].distance) * distanceUnitScale);
              // 높이 전부 더하기 //
              height += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].mainExerciseList[z].height) * heightUnitScale);
            }
          }

          // emom 진행 내역 찾기 //
          for (let z = 0; z < useData[i].sectionList[nowSection].sectionRecordList[h].emomExerciseList.length; z++) {
            if (!emomExceptIndexs.includes(useData[i].sectionList[nowSection].sectionRecordList[h].emomExerciseList[z].exerciseIndex - 1)) {
              // 순위 산출을 위한 단위 통일 //
              let weightUnitScale = useData[i].sectionList[nowSection].sectionRecordList[h].emomExerciseList[z].weightUnit === 'Kg' ? 2.2 : 1;
              let caloriesUnitScale = useData[i].sectionList[nowSection].sectionRecordList[h].emomExerciseList[z].caloriesUnit === 'Kcal' ? 1000 : 1;
              let distanceUnitScale = 1;
              let heightUnitScale = 1;
  
              if (useData[i].sectionList[nowSection].sectionRecordList[h].emomExerciseList[z].heightUnit === 'Km') distanceUnitScale = 1000;
              else if (useData[i].sectionList[nowSection].sectionRecordList[h].emomExerciseList[z].heightUnit === 'inch') distanceUnitScale = (1 / 39.37);
              else if (useData[i].sectionList[nowSection].sectionRecordList[h].emomExerciseList[z].heightUnit === 'Feet') distanceUnitScale = (1 / 3.281);
              else if (useData[i].sectionList[nowSection].sectionRecordList[h].emomExerciseList[z].heightUnit === 'inch') distanceUnitScale = (1 / 100);
  
              if (useData[i].sectionList[nowSection].sectionRecordList[h].emomExerciseList[z].heightUnit === 'Km') heightUnitScale = 1000;
              else if (useData[i].sectionList[nowSection].sectionRecordList[h].emomExerciseList[z].heightUnit === 'inch') heightUnitScale = (1 / 39.37);
              else if (useData[i].sectionList[nowSection].sectionRecordList[h].emomExerciseList[z].heightUnit === 'Feet') heightUnitScale = (1 / 3.281);
              else if (useData[i].sectionList[nowSection].sectionRecordList[h].emomExerciseList[z].heightUnit === 'inch') heightUnitScale = (1 / 100);
  
              // 무게 전부 더하기 //
              weight += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].emomExerciseList[z].weight) * weightUnitScale);
              // 횟수 전부 더하기 //
              reps += parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].emomExerciseList[z].count === '' ? 0 : useData[i].sectionList[nowSection].sectionRecordList[h].emomExerciseList[z].count);
              // 칼로리 전부 더하기 //
              calories += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].emomExerciseList[z].calories) * caloriesUnitScale);
              // 거리 전부 더하기 //
              distance += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].emomExerciseList[z].distance) * distanceUnitScale);
              // 높이 전부 더하기 //
              height += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].emomExerciseList[z].height) * heightUnitScale);
            }
          }

          // buyout 진행 내역 찾기 //
          for (let z = 0; z < useData[i].sectionList[nowSection].sectionRecordList[h].buyoutExerciseList.length; z++) {
            if (!buyoutExceptIndexs.includes(useData[i].sectionList[nowSection].sectionRecordList[h].buyoutExerciseList[z].exerciseIndex - 1)) {
              // 순위 산출을 위한 단위 통일 //
              let weightUnitScale = useData[i].sectionList[nowSection].sectionRecordList[h].buyoutExerciseList[z].weightUnit === 'Kg' ? 2.2 : 1;
              let caloriesUnitScale = useData[i].sectionList[nowSection].sectionRecordList[h].buyoutExerciseList[z].caloriesUnit === 'Kcal' ? 1000 : 1;
              let distanceUnitScale = 1;
              let heightUnitScale = 1;
  
              if (useData[i].sectionList[nowSection].sectionRecordList[h].buyoutExerciseList[z].heightUnit === 'Km') distanceUnitScale = 1000;
              else if (useData[i].sectionList[nowSection].sectionRecordList[h].buyoutExerciseList[z].heightUnit === 'inch') distanceUnitScale = (1 / 39.37);
              else if (useData[i].sectionList[nowSection].sectionRecordList[h].buyoutExerciseList[z].heightUnit === 'Feet') distanceUnitScale = (1 / 3.281);
              else if (useData[i].sectionList[nowSection].sectionRecordList[h].buyoutExerciseList[z].heightUnit === 'inch') distanceUnitScale = (1 / 100);
  
              if (useData[i].sectionList[nowSection].sectionRecordList[h].buyoutExerciseList[z].heightUnit === 'Km') heightUnitScale = 1000;
              else if (useData[i].sectionList[nowSection].sectionRecordList[h].buyoutExerciseList[z].heightUnit === 'inch') heightUnitScale = (1 / 39.37);
              else if (useData[i].sectionList[nowSection].sectionRecordList[h].buyoutExerciseList[z].heightUnit === 'Feet') heightUnitScale = (1 / 3.281);
              else if (useData[i].sectionList[nowSection].sectionRecordList[h].buyoutExerciseList[z].heightUnit === 'inch') heightUnitScale = (1 / 100);
  
              // 무게 전부 더하기 //
              weight += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].buyoutExerciseList[z].weight) * weightUnitScale);
              // 횟수 전부 더하기 //
              reps += parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].buyoutExerciseList[z].count === '' ? 0 : useData[i].sectionList[nowSection].sectionRecordList[h].buyoutExerciseList[z].count);
              // 칼로리 전부 더하기 //
              calories += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].buyoutExerciseList[z].calories) * caloriesUnitScale);
              // 거리 전부 더하기 //
              distance += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].buyoutExerciseList[z].distance) * distanceUnitScale);
              // 높이 전부 더하기 //
              height += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].buyoutExerciseList[z].height) * heightUnitScale);
            }
          }


          let roundOfCountList = useData[i].sectionList[nowSection].sectionRecordList[h].roundOfCount.split('-')

          for (let z = 0; z < roundOfCountList.length; z++) {
            if (roundOfCountList[z] !== '') roundOfCount += parseInt(roundOfCountList[z]);
          }
          
          // 제한시간이 설정되어있는 경우 ( 제한시간 - 진행한 시간 ) 으로 추산 // 
          if (useData[i].sectionList[nowSection].isUseTimeLimit) {
            timeRemaining += useData[i].sectionList[nowSection].timeLimit - useData[i].sectionList[nowSection].sectionRecordList[h].recordTime;
          }

          // 운동을 끝까지 완료한 경우 //
          if (useData[i].sectionList[nowSection].sectionRecordList[h].success === 'Success') {
            // 진행을 완료 가산점 //
            if (minusPointCount === 0) successScore += 70000000;
            else successScore += 10000000;

            // 진행한 라운드 가산점 ( 운동 성공 => 전체 라운드 * 운동 개수 ) //
            round = useData[i].sectionList[nowSection].round * useData[i].sectionList[nowSection].exerciseList.length;

            if (tag.includes('ForTime-')) {
              let totalText = ''
              let min = 0;
              let sec = 0;
              let decimalPoint = (useData[i].sectionList[nowSection].sectionRecordList[h].recordTime % 1).toFixed(2);
        
              min = parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].recordTime / 60);
        
              if (decimalPoint !== '0.00') sec = parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].recordTime % 60) + parseFloat(decimalPoint);
              else sec = parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].recordTime % 60);
        
              if (sec === 0) {
                if (min < 10) totalText = min + ':00';
                else totalText = min + ':00';
              }
              else {
                if (min < 10) {
                  if (sec < 10) totalText = min + ':0' + sec;
                  else totalText = min + ':' + sec;
                }
                else {
                  if (sec < 10) totalText = min + ':0' + sec;
                  else totalText = min + ':' + sec;
                }
              }

              finalScoreText = totalText;
            }
            
          }
          // 운동이 중간에 종료된 경우 //
          else {
            // 마지막 진행한 운동 찾기 //
            let division = 'Buyin';
            let index = 0;

            // 마지막 운동 찾기 //
            for (let z = 0; z < useData[i].sectionList[nowSection].sectionRecordList[h].exerciseList.length; z++) {
              if (useData[i].sectionList[nowSection].sectionRecordList[h].lastExerciseId === useData[i].sectionList[nowSection].sectionRecordList[h].exerciseList[z].exerciseId) {
                division = useData[i].sectionList[nowSection].sectionRecordList[h].exerciseList[z].division;
                index = useData[i].sectionList[nowSection].sectionRecordList[h].exerciseList[z].exerciseIndex;
                break;
              }
            }

            // 진행한 라운드 가산점 ( 운동 중도 포기 => 완벽히 이행한 라운드 * 운동 개수 + 마지막 운동 개수) //
            round = useData[i].sectionList[nowSection].sectionRecordList[h].completeRound * useData[i].sectionList[nowSection].exerciseList.length;

            // console.log('i : ' + useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[h].userName);
            // console.log('round : ' + round);
            // 이행하지 못하고 남은 라운드 //
            let remainingRound = useData[i].sectionList[nowSection].round - useData[i].sectionList[nowSection].sectionRecordList[h].completeRound;
            // console.log(division);
            // Buyin에서 멈췄을 경우 //
            if (division === 'Buyin') {
              round += (remainingRound + ((index * 0.1) * useData[i].sectionList[nowSection].sectionRecordList[h].lastRound)) * 0.3;
              // useData[i].sectionList[nowSection].sectionRecordList[h].lastDivision = 'Buyin';
            }
            // Main에서 멈췄을 경우 //
            else if (division === 'Main') {
              round += (remainingRound + ((index * 0.1) * useData[i].sectionList[nowSection].sectionRecordList[h].lastRound)) * 0.6;
              // useData[i].sectionList[nowSection].sectionRecordList[h].lastDivision = 'Main';
            }
            // Buyout에서 멈췄을 경우 //
            else if (division === 'Buyout') {
              round += (remainingRound + ((index * 0.1) * useData[i].sectionList[nowSection].sectionRecordList[h].lastRound)) * 0.9;
              // useData[i].sectionList[nowSection].sectionRecordList[h].lastDivision = 'Buyout';
            }

            if (tag.includes('ForTime-')) {
              let completeReps = 0;
              
              if (division === 'Buyin') {
                for (let z = 0; z < useData[i].sectionList[nowSection].sectionRecordList[h].buyinExerciseList.length; z++) {
                  if (useData[i].sectionList[nowSection].sectionRecordList[h].buyinExerciseList[z].exerciseIndex < index) {
                    completeReps += useData[i].sectionList[nowSection].sectionRecordList[h].buyinExerciseList[z].count;
                  }
                }
              }
              else if (division === 'Main') {
                for (let z = 0; z < useData[i].sectionList[nowSection].sectionRecordList[h].mainExerciseList.length; z++) {
                  if (useData[i].sectionList[nowSection].sectionRecordList[h].mainExerciseList[z].exerciseIndex < index) {
                    completeReps += useData[i].sectionList[nowSection].sectionRecordList[h].mainExerciseList[z].count;
                  }
                }
              }
              else if (division === 'Buyout') {
                for (let z = 0; z < useData[i].sectionList[nowSection].sectionRecordList[h].buyoutExerciseList.length; z++) {
                  if (useData[i].sectionList[nowSection].sectionRecordList[h].buyoutExerciseList[z].exerciseIndex < index) {
                    completeReps += useData[i].sectionList[nowSection].sectionRecordList[h].buyoutExerciseList[z].count;
                  }
                }
              }

              // for (let o = 0; o < index; o++) {
              //   completeReps += parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].exerciseList[o].count);
              // }

              // console.log(completeReps);
              completeReps += parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].lastRound);
              finalScoreText += useData[i].sectionList[nowSection].sectionRecordList[h].completeRound + ' Rounds ' + completeReps + ' Reps';
            }
          }
          // }

          if (tag === 'SINGLE') {
            let weightTotalText = '';

            for (let z = 0; z < useData[i].sectionList[nowSection].sectionRecordList[h].mainExerciseList.length; z++) {
              if (z !== useData[i].sectionList[nowSection].sectionRecordList[h].mainExerciseList.length - 1) {
                weightTotalText += parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].mainExerciseList[z].weight) + ' - ';
              }
              else {
                weightTotalText += parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].mainExerciseList[z].weight) + ' ' + (useData[i].sectionList[nowSection].sectionRecordList[h].exerciseList[z].weightUnit === 'Pounds' ? 'lb' : useData[i].sectionList[nowSection].sectionRecordList[h].exerciseList[z].weightUnit);
              }
            }

            finalScoreText = weightTotalText;
          }
          else if (tag === 'AMRAP') {
            // 마지막 진행한 운동 찾기 //
            let division = 'Buyin';
            let index = 0;

            // 마지막 운동 찾기 //
            for (let z = 0; z < useData[i].sectionList[nowSection].sectionRecordList[h].exerciseList.length; z++) {
              if (useData[i].sectionList[nowSection].sectionRecordList[h].lastExerciseId === useData[i].sectionList[nowSection].sectionRecordList[h].exerciseList[z].exerciseId) {
                division = useData[i].sectionList[nowSection].sectionRecordList[h].exerciseList[z].division;
                index = useData[i].sectionList[nowSection].sectionRecordList[h].exerciseList[z].exerciseIndex;
                break;
              }
            }

            let completeReps = 0;

            // console.log(useData[i].sectionList[nowSection].sectionRecordList[h]);
            // console.log(division);

            if (division === 'Buyin') {
              for (let z = 0; z < useData[i].sectionList[nowSection].sectionRecordList[h].buyinExerciseList.length; z++) {
                if (useData[i].sectionList[nowSection].sectionRecordList[h].buyinExerciseList[z].exerciseIndex < index) {
                  completeReps += parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].buyinExerciseList[z].count);
                }
              }
            }
            else if (division === 'Main') {
              for (let z = 0; z < useData[i].sectionList[nowSection].sectionRecordList[h].mainExerciseList.length; z++) {
                if (useData[i].sectionList[nowSection].sectionRecordList[h].mainExerciseList[z].exerciseIndex < index) {
                  completeReps += parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].mainExerciseList[z].count);
                }
              }
            }
            else if (division === 'Buyout') {
              for (let z = 0; z < useData[i].sectionList[nowSection].sectionRecordList[h].buyoutExerciseList.length; z++) {
                if (useData[i].sectionList[nowSection].sectionRecordList[h].buyoutExerciseList[z].exerciseIndex < index) {
                  completeReps += parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].buyoutExerciseList[z].count);
                }
              }
            }

            // for (let o = 0; o < lastExerciseIndex; o++) {
            //   completeReps += parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].exerciseList[o].count);
            // }

            completeReps += parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].lastRound);
            finalScoreText += useData[i].sectionList[nowSection].sectionRecordList[h].completeRound + ' Rounds ' + completeReps + ' Reps';
          }
          else if (tag === 'EMOM' || tag === 'TABATA') {
            finalScoreText += useData[i].sectionList[nowSection].sectionRecordList[h].roundOfCount;
          }

          let minusPercent = 1;

          if (minusPointCount !== 0) minusPercent = 0.4;

          for (let o = 0; o < minusPointCount; o++) {
            if (minusPercent > 0.1) minusPercent -= 0.1;
          }

          if (minusPercent === 1) successScore += 40000000;

          // 이미 저장된 내역 확인 Flag //
          let isExist = false;
          // 이미 저장된 유저 점수가 있는지 확인 //
          for (let x = 0; x < userRankingList.length; x++) {
            // 이미 점수가 있으면 기존점수에 + 하기 //
            if (userRankingList[x].linkCoachId === useData[i].sectionList[nowSection].sectionRecordList[h].linkCoachId) {

              if (useData[i].sectionList[nowSection].sectionRecordList[h].success === 'Success') {
                // userRankingList[x].round += (round * 10000000);
                // userRankingList[x].roundOfCount += (roundOfCount * 1000000);
                // userRankingList[x].weight += (weight * 100000)
                // userRankingList[x].reps += reps * 10000;
                // userRankingList[x].calories = calories * 1000;
                // userRankingList[x].distance = distance * 100;
                // userRankingList[x].height = height * 10;
                // userRankingList[x].timeRemaining += timeRemaining * 1;
                // userRankingList[x].recordTime = time;
                // userRankingList[x].successScore += successScore;
                // userRankingList[x].lastRound = lastRound;
                // userRankingList[x].lastMovementBonus = lastMovementBonus;
                // userRankingList[x].lastReps = lastReps;
                // userRankingList[x].minusPointCount = minusPointCount;
                // userRankingList[x].minusPercent = minusPercent;
                // userRankingList[x].finalScoreText = finalScoreText;

                // let totalScore = ((round * 10000000) + (roundOfCount * 1000000) + (weight * 100000) + (reps * 10000) + (calories * 1000) + (distance * 100) + (height * 10) + timeRemaining * 1 + successScore + lastRound + (lastMovementBonus + lastReps)) - time;
                
                // userRankingList[x].totalScore += (totalScore * minusPercent);
                // // 저장 플래그 //
                // isExist = true;
                // userRankingList[x].round += (round * 3000000);
                // userRankingList[x].roundOfCount += (roundOfCount * 300000);
                // userRankingList[x].weight += (weight * 30000)
                // userRankingList[x].reps += reps * 3000;
                // userRankingList[x].calories = calories * 300;
                // userRankingList[x].distance = distance * 30;
                // userRankingList[x].height = height;
                // userRankingList[x].timeRemaining += timeRemaining * 1;
                // userRankingList[x].recordTime = time;
                // userRankingList[x].successScore += successScore;
                // userRankingList[x].lastRound = lastRound;
                // userRankingList[x].lastMovementBonus = lastMovementBonus;
                // userRankingList[x].lastReps = lastReps;
                // userRankingList[x].minusPointCount = minusPointCount;
                // userRankingList[x].minusPercent = minusPercent;
                // userRankingList[x].finalScoreText = finalScoreText;

                // let totalScore = ((round * 3000000) + (roundOfCount * 300000) + (weight * 30000) + (reps * 3000) + (calories * 300) + (distance * 30) + (height) + timeRemaining * 1 + successScore + lastRound + (lastMovementBonus + lastReps)) - time;
                
                // userRankingList[x].totalScore += (totalScore * minusPercent);
                // 저장 플래그 //
                isExist = true;
              }
              else if (useData[i].sectionList[nowSection].sectionRecordList[h].success === 'Not Success') {
                
                // userRankingList[x].round += (round * 1000000);
                // userRankingList[x].roundOfCount += (roundOfCount * 100000);
                // userRankingList[x].weight += (weight * 10000)
                // userRankingList[x].reps += reps * 1000;
                // userRankingList[x].calories = calories * 100;
                // userRankingList[x].distance = distance * 10;
                // userRankingList[x].height = height;
                // userRankingList[x].timeRemaining += timeRemaining * 1;
                // userRankingList[x].recordTime = time;
                // userRankingList[x].successScore += successScore;
                // userRankingList[x].lastRound = lastRound;
                // userRankingList[x].lastMovementBonus = lastMovementBonus;
                // userRankingList[x].lastReps = lastReps;
                // userRankingList[x].minusPointCount = minusPointCount;
                // userRankingList[x].minusPercent = minusPercent;
                // userRankingList[x].finalScoreText = finalScoreText;

                // let totalScore = ((round * 100000) + (roundOfCount * 100000) + (weight * 10000) + (reps * 1000) + (calories * 100) + (distance * 10) + (height) + timeRemaining * 1 + successScore + lastRound + (lastMovementBonus + lastReps)) - time;
                
                // userRankingList[x].totalScore += (totalScore * minusPercent);
                // 저장 플래그 //
                isExist = true;
              }
              else if (useData[i].sectionList[nowSection].sectionRecordList[h].success === 'Not Done') {
                // userRankingList[x].round += 0;
                // userRankingList[x].roundOfCount += 0;
                // userRankingList[x].weight += 0;
                // userRankingList[x].reps += 0;
                // userRankingList[x].calories = 0;
                // userRankingList[x].distance = 0;
                // userRankingList[x].height = 0;
                // userRankingList[x].timeRemaining += 0;
                // userRankingList[x].recordTime = 0;
                // userRankingList[x].successScore += 0;
                // userRankingList[x].lastRound = 0;
                // userRankingList[x].lastMovementBonus = 0;
                // userRankingList[x].lastReps = 0;
                // userRankingList[x].minusPointCount = 0;
                // userRankingList[x].minusPercent = 0;
                // userRankingList[x].finalScoreText = 'DNF';

                // userRankingList[x].totalScore += 0;
              }
            }
          }

          // 저장된게 없으면 //
          if (!isExist) {

            let totalScore = ((round * 10000000) + (roundOfCount * 1000000) + (weight * 100000) + (reps * 10000) + (calories * 1000) + (distance * 100) + (height * 10) + timeRemaining + successScore + lastRound + (lastMovementBonus + lastReps)) - time;
            let rankingInfo = {
              linkCoachId: useData[i].sectionList[nowSection].sectionRecordList[h].linkCoachId,
              userName: useData[i].sectionList[nowSection].sectionRecordList[h].userName,
              userProfile: useData[i].sectionList[nowSection].sectionRecordList[h].userProfile,
              createdDate: useData[i].sectionList[nowSection].sectionRecordList[h].createdDate,
              round: (round * 10000000),
              roundOfCount: (roundOfCount * 1000000),
              weight: (weight * 100000),
              reps: reps * 10000,
              calories: calories * 1000,
              distance: distance * 100,
              height: height * 10,
              timeRemaining: timeRemaining * 1,
              recordTime: time,
              successScore: successScore,
              lastRound: lastRound,
              lastMovementBonus: lastMovementBonus,
              lastReps: lastReps,
              minusPointCount: minusPointCount,
              minusPercent: minusPercent,
              finalScoreText: finalScoreText,
              totalScore: (totalScore * minusPercent)
            }

            if (useData[i].sectionList[nowSection].sectionRecordList[h].success === 'Success') {
              // let successTotalScore = ((round * 10000000) + (roundOfCount * 1000000) + (weight * 100000) + (reps * 10000) + (calories * 1000) + (distance * 100) + (height * 10) + timeRemaining + successScore + lastRound + (lastMovementBonus + lastReps)) - time;
              let successTotalScore = ((round * 3000000) + (roundOfCount * 300000) + (weight * 30000) + (reps * 3000) + (calories * 300) + (distance * 30) + (height) + timeRemaining + successScore + lastRound + (lastMovementBonus + lastReps)) - time;

              rankingInfo = {
                linkCoachId: useData[i].sectionList[nowSection].sectionRecordList[h].linkCoachId,
                userName: useData[i].sectionList[nowSection].sectionRecordList[h].userName,
                userProfile: useData[i].sectionList[nowSection].sectionRecordList[h].userProfile,
                createdDate: useData[i].sectionList[nowSection].sectionRecordList[h].createdDate,
                round: (round * 3000000),
                roundOfCount: (roundOfCount * 300000),
                weight: (weight * 30000),
                reps: reps * 3000,
                calories: calories * 300,
                distance: distance * 30,
                height: height,
                timeRemaining: timeRemaining * 1,
                recordTime: time,
                successScore: successScore,
                lastRound: lastRound,
                lastMovementBonus: lastMovementBonus,
                lastReps: lastReps,
                minusPointCount: minusPointCount,
                minusPercent: minusPercent,
                finalScoreText: finalScoreText,
                totalScore: (successTotalScore * minusPercent)
              }
            }
            else if (useData[i].sectionList[nowSection].sectionRecordList[h].success === 'Not Success') {
              let notSuccessTotalScore = ((round * 1000000) + (roundOfCount * 100000) + (weight * 10000) + (reps * 1000) + (calories * 100) + (distance * 10) + (height) + timeRemaining + successScore + lastRound + (lastMovementBonus + lastReps)) - time;

              rankingInfo = {
                linkCoachId: useData[i].sectionList[nowSection].sectionRecordList[h].linkCoachId,
                userName: useData[i].sectionList[nowSection].sectionRecordList[h].userName,
                userProfile: useData[i].sectionList[nowSection].sectionRecordList[h].userProfile,
                createdDate: useData[i].sectionList[nowSection].sectionRecordList[h].createdDate,
                round: (round * 1000000),
                roundOfCount: (roundOfCount * 100000),
                weight: (weight * 10000),
                reps: reps * 1000,
                calories: calories * 100,
                distance: distance * 10,
                height: height,
                timeRemaining: timeRemaining,
                recordTime: time,
                successScore: successScore,
                lastRound: lastRound,
                lastMovementBonus: lastMovementBonus,
                lastReps: lastReps,
                minusPointCount: minusPointCount,
                minusPercent: minusPercent,
                finalScoreText: finalScoreText,
                totalScore: (notSuccessTotalScore * minusPercent)
              }
            }
            else if (useData[i].sectionList[nowSection].sectionRecordList[h].success === 'Not Done') {
              rankingInfo = {
                linkCoachId: useData[i].sectionList[nowSection].sectionRecordList[h].linkCoachId,
                userName: useData[i].sectionList[nowSection].sectionRecordList[h].userName,
                userProfile: useData[i].sectionList[nowSection].sectionRecordList[h].userProfile,
                createdDate: useData[i].sectionList[nowSection].sectionRecordList[h].createdDate,
                round: 0,
                roundOfCount: 0,
                weight: 0,
                reps: 0,
                calories: 0,
                distance: 0,
                height: 0,
                timeRemaining: 0,
                recordTime: 0,
                successScore: 0,
                lastRound: 0,
                lastMovementBonus: 0,
                lastReps: 0,
                minusPointCount: 0,
                minusPercent: 0,
                totalScore: 0,
                finalScoreText: 'DNF'
              }
            }

            // console.log(rankingInfo);
            userRankingList.push(rankingInfo);
          }
        }
      }
      // }
    }

    // userRankingList.sort(function (a, b) {
    //   return a.totalScore > b.totalScore ? -1 : a.totalScore < b.totalScore ? 1 : 0;
    // })

    if (scoreKeyword === 'Not Scored' && tag === 'Custom') {
      for (let i = 0; i < userRankingList.length; i++) {
        userRankingList[i].rank = 0;
      }
    }
    else {
      userRankingList.sort((a, b) => b.totalScore - a.totalScore || moment(a.createdDate).isBefore(b.createdDate));

      for (let i = 0; i < userRankingList.length; i++) {
        // console.log(userRankingList[i].totalScore);
        if (i !== 0) {
          if (userRankingList[i].totalScore === userRankingList[i - 1].totalScore) userRankingList[i].rank = userRankingList[i - 1].rank;
          else userRankingList[i].rank = i + 1;
        }
        else userRankingList[i].rank = i + 1;
      }
    }

    if (userRankingList.length === 0) {
      setTimeout(() => {
        setIsNotNecessaryLoading(true);
      }, 500)
    }

    // console.log(userRankingList);
    setRankingList(userRankingList);

    // setTimeout(() => (
    //   console.log('hi'),
    //   setIsLoading(false)
    // ), 500)
  }, [useData, clickDate, nowSection, clickWorkoutIndex])

  // // 저장 모달에 띄울 수업 이름 저장 //
  // useEffect(() => {
  //   // setLessonName(ClassSelectRef.current.getValue().className);
  //   // setWorkoutId(ExerciseRef.current.getWorkoutId());
  // }, [isViewConfirm, ClassSelectRef, ExerciseRef])

  // 몇주차인지 계산하기 //
  function getWeekNumber(date) {
    const standard = new Date(date);

    const currentDate = standard.getDate();
    const startOfMonth = new Date(standard.setDate(1));
    const weekDay = startOfMonth.getDay();

    return parseInt(((weekDay - 1) + currentDate) / 7) + 1;
  }

  // 스케줄 변경 가능여부 확인 //
  function checkAvailableChangeScheduleData() {
    if (isClickedModify) {
      let exerciseData = ExerciseRef.current.getValue();

      if (exerciseData.length === 0) {
        setIsClickedModify(false);
        setIsClickedDetail(false);
        return true;
      }
      else return false;
    }
    else {
      setIsClickedDetail(false);
      return true;
    }
  }

  // 일정표내의 날짜 클릭시 //
  function dateClick(value, index) {
    setIsClickedDetail(false);

    setRankingList([]);
    setClickDate(value.classDate.format('YYYY.MM.DD'));
    // if (value.workouts[index].workoutId)
    setClickedWorkoutId(value.workouts[index].workoutId);
    setNowSection(0);
    
    if (value.workouts[index].workoutId === 0) {
      // setTimeout(() => {
      //   setIsNotNecessaryLoading(true);
      // }, 500)
      setIsNotNecessaryLoading(true);
    }
    // ScheduleRef.current.setValue(value.classDate.format('YYYY.MM.DD'));
  }

  // 월 단위 날짜 변경 클릭시 //
  function onClickDateArrow(division) {
    setIsClickedDetail(false);
    if (isClickedModify) setIsViewConfirm(true);
    else {
      if (division === 'Prev') {
        setDate(date.clone().subtract(1, 'month'));
        setClickDate(date.clone().subtract(1, 'month').format('YYYY.MM.DD'));
      }
      else if (division === 'Next') {
        setDate(date.clone().add(1, 'month'));
        setClickDate(date.clone().add(1, 'month').format('YYYY.MM.DD'));
      }
    }

    setIsFirst(true);
    setIsSettingData(false);
    setClickWorkoutIndex(0);
    setIsLoading(true);
  }

  // 자세히 보기 클릭시 //
  function onClickDetailView(data) {
    setNowClickLinkCoachId(data.linkCoachId);
  }

  // 스크롤 애니메이션를 위한 UI제어 //
  function changeTopLevelWrapperPosition() {
    if (TopLevelRef.current.style.position === 'fixed') {
      TopLevelRef.current.style.position = 'relative';
    }
    else {
      TopLevelRef.current.style.position = 'fixed';
      TopLevelRef.current.style.top = '0px';
    }
  }

  return (
    <TopLevelWrapper ref={TopLevelRef}>
      <TopBarWrapper>
        <TextWrapper>
          <Title>순위표</Title>
          <Explanation>운동 프로그램 별 수행 순위를 확인할 수 있어요</Explanation>
        </TextWrapper>
      </TopBarWrapper>
      <TopButtonWrapper>
        <LeftWrapper>
          {/* <TestClassSelectBox 
            ref={ClassSelectRef} 
            date={date} 
            setScheduleId={setScheduleId}
            setSelectedScheduleData={setSelectedScheduleData} 
            setLessonId={setLessonId}
            setLessonName={setLessonName}
            checkAvailableChangeScheduleData={checkAvailableChangeScheduleData} 
            rendering={() => setRendering(!rendering)} /> */}
          {/* <ScheduleSelectBoxWrapper>
            <LessonSelectBox defaultVal={selectedLesson} setDefaultVal={setSelectedLesson}/>
            <ScheduleSelectBox 
              date={moment(clickDate)} 
              selectedLesson={selectedLesson} 
              defaultVal={selectedScheduleData} 
              setDefaultVal={setSelectedScheduleData}
              checkAvailableChangeScheduleData={checkAvailableChangeScheduleData}/>
          </ScheduleSelectBoxWrapper> */}
          <DateButtonWrapper>
            <ArrowWrapper onClick={() => onClickDateArrow('Prev')}>
              <ArrowImage src={LeftArrow} />
            </ArrowWrapper>
            <DateText>{date.clone().format('YYYY년 MM월')}</DateText>
            <ArrowWrapper onClick={() => onClickDateArrow('Next')} >
              <ArrowImage src={RightArrow} />
            </ArrowWrapper>
          </DateButtonWrapper>
        </LeftWrapper>
      </TopButtonWrapper>
      <MainView>
        <LeftComponent>
          <RankingScheduleBox
            ref={ScheduleRef}
            clickDate={clickDate}
            // useData={useData}
            useData={dateSetData}
            dateClickFn={dateClick}
            clickWorkoutIndex={clickWorkoutIndex}
            setClickWorkoutIndex={setClickWorkoutIndex}
            changeTopLevelWrapperPosition={changeTopLevelWrapperPosition} />
          {/* <CalenderView ref={CalenderRef} clickDate={clickDate} date={date} dateClickFn={dateClick} /> */}
        </LeftComponent>
        {
          isClickedDetail ?
            <RankingExerciseBox
              clickDate={clickDate}
              useData={useData}
              rankingList={rankingList}
              nowSection={nowSection}
              setNowSection={setNowSection}
              setIsClickedDetail={setIsClickedDetail}
              nowClickLinkCoachId={nowClickLinkCoachId}
              clickWorkoutIndex={clickWorkoutIndex}
              changeTopLevelWrapperPosition={changeTopLevelWrapperPosition} />
            :
            <RankingBox
              useData={useData}
              clickDate={clickDate}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              isNotNecessaryLoading={isNotNecessaryLoading}
              setIsNotNecessaryLoading={setIsNotNecessaryLoading}
              rankingList={rankingList}
              setRankingList={setRankingList}
              isClickedDetail={isClickedDetail}
              setIsClickedDetail={setIsClickedDetail}
              nowSection={nowSection}
              setNowSection={setNowSection}
              clickWorkoutIndex={clickWorkoutIndex}
              onClickDetailView={onClickDetailView} />
        }
      </MainView>
    </TopLevelWrapper>
  )
}

export default RankingTotalView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  // margin-left: 34px;
  margin-left: 42px;

  // background-color: red;
  transition: all 0.3s ease-in-out;
`;

const TopBarWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-left: 28px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: 0 0 0 0;
`;

const Explanation = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #777777;

  margin: 0 0 0 0;
`;

const TopButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;

  margin-top: 38px;
`;

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 30px;
`;

const DateButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 205px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;

  gap: 37px;
`;

const ArrowWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 22px;
  height: 22px;

  :hover { 
    cursor: pointer;
  }
`;

const ArrowImage = styled.img`
  display: flex;
  flex-direction: row;

  width: 9px;
  height: 15px;
`;

const DateText = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #595959;

  margin: 0 0 0 0;
`;

const MainView = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: flex-start;

  gap: 30px;
  margin-top: 16px;
  margin-bottom: 255px;
`;

const LeftComponent = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  gap: 30px;
`;

const ScheduleSelectBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 408px;

  gap: 16px;
`;