import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import moment from 'moment';

import FetchModule from '../../Share/Network/FetchModule';

import WodLoadingClassSelectBox from '../../../Component/NoteComponent/WodLoadingClassSelectBox';
import LoadingWodList from './LoadingWodList';
import WodLoadingMemberSelectBox from '../../../Component/NoteComponent/WodLoadingMemberSelectBox';
import WodLoadingNormalSectionBox from './WodLoadingNormalSectionBox';

// Image //
import CalenderRightArrowImagePath from '../../../image/DateRightArrow.png';
import CalenderLeftArrowImagePath from '../../../image/DateLeftArrow.png';
import CalenderLeftArrow2ImagePath from '../../../image/CalenderLeftArrow2.png';
import CalenderRightArrow2ImagePath from '../../../image/CalenderRightArrow2.png';
import EmptySignImage from '../../../image/EmptySign.png';
// Image //

export default function WodLoadingModal(props) {
  const { standardDate, completeFn, closeFn } = props;

  const [date, setDate] = useState(moment(standardDate));
  const [dateList, setDateList] = useState([]);
  const [data, setData] = useState([]);
  const [memberList, setMemberList] = useState([]);
  const [memberIndex, setMemberIndex] = useState(-1);
  const [division, setDivision] = useState('Program');
  const [clickWorkout, setClickWorkout] = useState({ workoutId: 0 });
  const [rendering, setRendering] = useState(false);
  const [scheduleId, setScheduleId] = useState(-1);
  const [useData, setUseData] = useState([{
    workoutId: 0,
    scheduleId: 0,
    storeId: parseInt(window.localStorage.getItem('StoreId')),
    classDate: moment(),
    title: '작성이 필요합니다.',
    sectionList: []
  }]);

  const firstWeek = date.clone().startOf('month').week();
  const lastWeek = date.clone().endOf('month').week() === 1 ? 53 : date.clone().endOf('month').week();

  const ClassRef = useRef();

  // Modal 창 스크롤 제어 //
  useEffect(() => {
    document.body.style= `overflow: hidden`;
    return () => document.body.style = `overflow: auto`;
  }, [])

  // 날짜 세팅 //
  useEffect(() => {
    let newDateList = [];

    for (let week = firstWeek; week <= lastWeek; week++) {
      for (let i = 0; i < 7; i++) {
        let days = date.clone().startOf('year').week(week).startOf('week').add(i, 'day');

        if (days.format('MM') === date.format('MM')) newDateList.push(days);
      }
    }
    // setClickDate(newDateList[0].format('YYYY.MM.DD'));
    setDateList(newDateList);
  }, [date, firstWeek, lastWeek])

  // OneOnOne 회원 데이터 불러오기 //
  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        let responseData = await fetchModule.postDataOfExercise('one-on-one/registered/member', 'POST');
        // console.log('-------------');
        // console.log(responseData);
        // console.log('-------------');
        if (responseData.status === 200) {
          setMemberList(responseData.data);
          if (responseData.data.length !== 0) setMemberIndex(0);
        }
        // else alert('서버와 연결이 불안정합니다. 잠시후 다시시도해 주세요.');
      }
    )();
  }, [])

  // 프로그램 데이터 불러오기 //
  useEffect(() => {

    let data = {
      // scheduleId: scheduleId,
      year: date.clone().format('YYYY'),
      month: date.clone().format('MM'),
      userId: memberIndex !== -1 ? memberList[memberIndex].userId : undefined,
    }
    // console.log(data);
    // var responseData = await fetchModule.postDataOfExercise('one-on-one/exercise_list', 'POST', requestData);

    const fetchModule = new FetchModule();
    (
      async () => {
        let responseData;

        if (division === 'Program') responseData = await fetchModule.postDataOfExerciseVersion2('workout/', 'POST', data);
        else responseData = await fetchModule.postDataOfExercise('one-on-one/exercise_list', 'POST', data);
        // console.log(responseData);
        if (responseData.status === 200) {
          let workoutData = responseData.data.slice();

          // 운동 데이터 정리 //
          for (let i = 0; i < workoutData.length; i++) {

            workoutData[i].sectionList.sort(function (a, b) {
              return a.sectionIndex < b.sectionIndex ? -1 : a.sectionIndex > b.sectionIndex ? 1 : 0;
            })

            for (let j = 0; j < workoutData[i].sectionList.length; j++) {
              workoutData[i].sectionList[j].buyinExerciseList = [];
              workoutData[i].sectionList[j].buyinScaleOptionExerciseList = [];
              workoutData[i].sectionList[j].mainExerciseList = [];
              workoutData[i].sectionList[j].mainScaleOptionExerciseList = [];
              workoutData[i].sectionList[j].emomExerciseList = [];
              workoutData[i].sectionList[j].emomScaleOptionExerciseList = [];
              workoutData[i].sectionList[j].buyoutExerciseList = [];
              workoutData[i].sectionList[j].buyoutScaleOptionExerciseList = [];
              workoutData[i].sectionList[j].reps = workoutData[i].sectionList[j].reps.split('-');

              for (let h = 0; h < workoutData[i].sectionList[j].exerciseList.length; h++) {
                workoutData[i].sectionList[j].exerciseList[h].movementId = workoutData[i].sectionList[j].exerciseList[h].movement.movementId;
                workoutData[i].sectionList[j].exerciseList[h].movementName = workoutData[i].sectionList[j].exerciseList[h].movement.movementName;
                workoutData[i].sectionList[j].exerciseList[h].videoLinkId = workoutData[i].sectionList[j].exerciseList[h].movement.videoLinkId;

                // 무게 구분 데이터 사용할 경우 //
                if (workoutData[i].sectionList[j].exerciseList[h].divisionWeight !== ''
                  && workoutData[i].sectionList[j].exerciseList[h].divisionWeight !== 'None') {
                  workoutData[i].sectionList[j].exerciseList[h].weight = workoutData[i].sectionList[j].exerciseList[h].divisionWeight;
                }
                // 거리 구분 데이터 사용할 경우 //
                if (workoutData[i].sectionList[j].exerciseList[h].divisionDistance !== ''
                  && workoutData[i].sectionList[j].exerciseList[h].divisionDistance !== 'None') {
                  workoutData[i].sectionList[j].exerciseList[h].distance = workoutData[i].sectionList[j].exerciseList[h].divisionDistance;
                }
                // 높이 구분 데이터 사용할 경우 //
                if (workoutData[i].sectionList[j].exerciseList[h].divisionHeight !== ''
                  && workoutData[i].sectionList[j].exerciseList[h].divisionHeight !== 'None') {
                  workoutData[i].sectionList[j].exerciseList[h].height = workoutData[i].sectionList[j].exerciseList[h].divisionHeight;
                }
                // 칼로리 구분 데이터 사용할 경우 //
                if (workoutData[i].sectionList[j].exerciseList[h].divisionCalories !== ''
                  && workoutData[i].sectionList[j].exerciseList[h].divisionCalories !== 'None') {
                    workoutData[i].sectionList[j].exerciseList[h].calories = workoutData[i].sectionList[j].exerciseList[h].divisionCalories;
                }
                // 템포 구분 데이터 사용할 경우 //
                if (workoutData[i].sectionList[j].exerciseList[h].divisionTempo !== ''
                  && workoutData[i].sectionList[j].exerciseList[h].divisionTempo !== 'None') {
                    workoutData[i].sectionList[j].exerciseList[h].tempo = workoutData[i].sectionList[j].exerciseList[h].divisionTempo;
                }
                // Sets 구분 데이터 사용할 경우 //
                if (workoutData[i].sectionList[j].exerciseList[h].divisionSets !== '' 
                  && workoutData[i].sectionList[j].exerciseList[h].divisionSets !== 'None') {
                    workoutData[i].sectionList[j].exerciseList[h].sets = workoutData[i].sectionList[j].exerciseList[h].divisionSets;
                }
                // Sets 구분 데이터 사용할 경우 //
                if (workoutData[i].sectionList[j].exerciseList[h].divisionRest !== '' 
                  && workoutData[i].sectionList[j].exerciseList[h].divisionRest !== 'None') {
                    workoutData[i].sectionList[j].exerciseList[h].rest = workoutData[i].sectionList[j].exerciseList[h].divisionRest;
                }


                if (workoutData[i].sectionList[j].exerciseList[h].division === 'Buyin') {
                  workoutData[i].sectionList[j].buyinExerciseList.push(workoutData[i].sectionList[j].exerciseList[h]);
                }
                else if (workoutData[i].sectionList[j].exerciseList[h].division === 'Buyin.Scale') {
                  workoutData[i].sectionList[j].buyinScaleOptionExerciseList.push(workoutData[i].sectionList[j].exerciseList[h]);
                }
                else if (workoutData[i].sectionList[j].exerciseList[h].division === 'Main') {
                  workoutData[i].sectionList[j].mainExerciseList.push(workoutData[i].sectionList[j].exerciseList[h]);
                }
                else if (workoutData[i].sectionList[j].exerciseList[h].division === 'Main.Scale'){
                  workoutData[i].sectionList[j].mainScaleOptionExerciseList.push(workoutData[i].sectionList[j].exerciseList[h]);
                }
                else if (workoutData[i].sectionList[j].exerciseList[h].division === 'Main.Emom') {
                  workoutData[i].sectionList[j].emomExerciseList.push(workoutData[i].sectionList[j].exerciseList[h]);
                }
                else if (workoutData[i].sectionList[j].exerciseList[h].division === 'Main.Emom.Scale'){
                  workoutData[i].sectionList[j].emomScaleOptionExerciseList.push(workoutData[i].sectionList[j].exerciseList[h]);
                }
                else if (workoutData[i].sectionList[j].exerciseList[h].division === 'Buyout') {
                  workoutData[i].sectionList[j].buyoutExerciseList.push(workoutData[i].sectionList[j].exerciseList[h]);
                }
                else if (workoutData[i].sectionList[j].exerciseList[h].division === 'Buyout.Scale'){
                  workoutData[i].sectionList[j].buyoutScaleOptionExerciseList.push(workoutData[i].sectionList[j].exerciseList[h]);
                }
              }

              // exerciseIndex.asc 진행 //
              workoutData[i].sectionList[j].buyinExerciseList.sort(function (a, b) {
                return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
              })
              // exerciseIndex.asc 진행 //
              workoutData[i].sectionList[j].buyinScaleOptionExerciseList.sort(function (a, b) {
                return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
              })
              // exerciseIndex.asc 진행 //
              workoutData[i].sectionList[j].mainExerciseList.sort(function (a, b) {
                return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
              })
              // exerciseIndex.asc 진행 //
              workoutData[i].sectionList[j].mainScaleOptionExerciseList.sort(function (a, b) {
                return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
              })
              // exerciseIndex.asc 진행 //
              workoutData[i].sectionList[j].emomExerciseList.sort(function (a, b) {
                return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
              })
              // exerciseIndex.asc 진행 //
              workoutData[i].sectionList[j].emomScaleOptionExerciseList.sort(function (a, b) {
                return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
              })
              // exerciseIndex.asc 진행 //
              workoutData[i].sectionList[j].buyoutExerciseList.sort(function (a, b) {
                return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
              })
              // exerciseIndex.asc 진행 //
              workoutData[i].sectionList[j].buyoutScaleOptionExerciseList.sort(function (a, b) {
                return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
              })
            }
          }

          setData(workoutData);
        }
        else if (responseData.status === 204) setData([]);
      }
    )();
  }, [date, division, rendering])

  // 데이터 정보 정리 //
  useEffect(() => {
    if (dateList.length === 0) return;

    let newData = data.slice();

    // if (newData.length === 0) { // 데이터가 없으면 1개 넣고 시작
    //   var tmpData = {
    //     workoutId: 0,
    //     scheduleId: 0,
    //     storeId: parseInt(window.localStorage.getItem('StoreId')),
    //     classDate: dateList[0],
    //     title: '',
    //     sectionList: []
    //   }
    // }

    // for (var i = 0; i < dateList.length; i++) {
    //   var isNone = true;

    //   for (var j = 0; j < newData.length; j++) {
    //     if (dateList[i].format('MM.DD') === moment(newData[j].classDate).format('MM.DD')) {
    //       isNone = false;
    //       newData[j].classDate = moment(newData[j].classDate);
    //       break;
    //     }
    //   }
    // }

    newData.map((workoutData) => (workoutData.classDate = moment(workoutData.classDate)));

    newData.sort(function (a, b) {
      return a.classDate < b.classDate ? -1 : a.classDate > b.classDate ? 1 : 0;
    })

    setUseData(newData);
  }, [dateList, data])

  // 운동 클릭시 //
  function onClickWorkoutList(data) {
    if (clickWorkout.workoutId === data.workoutId) setClickWorkout({ workoutId: 0 });
    else setClickWorkout(data);
  }

  // 완료 버튼 클릭시 //
  function onClickComplete() {
    if (clickWorkout.workoutId === 0) {
      alert('가져올 운동을 선택해주세요!');
      return;
    }

    completeFn(clickWorkout);
  }

  return (
    <TopLevelWrapper>
      <TotalModal>
        <Modal>
          <ComponentWrapper>
            <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#232323' cursor='default'>프로그램 가져오기</Text>
            <SettingComponentWrapper marginTop={18}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959' cursor='default'>날짜 선택</Text>
              <DateTotalWrapper>
                <DateButton onClick={() => setDate(date.clone().subtract(1, 'year'))}>
                  <Arrow src={CalenderLeftArrow2ImagePath} />
                </DateButton>
                <DateButton onClick={() => setDate(date.clone().subtract(1, 'month'))}>
                  <Arrow src={CalenderLeftArrowImagePath} />
                </DateButton>
                <DateWrapper>
                  <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#232323' cursor='default'>{date.clone().format('YYYY년 MM월')}</Text>
                </DateWrapper>
                <DateButton onClick={() => setDate(date.clone().add(1, 'month'))}>
                  <Arrow src={CalenderRightArrowImagePath} />
                </DateButton>
                <DateButton onClick={() => setDate(date.clone().add(1, 'year'))}>
                  <Arrow src={CalenderRightArrow2ImagePath} />
                </DateButton>
              </DateTotalWrapper>
            </SettingComponentWrapper>
            <DivisionWrapper>
              <DivisionButton isClicked={division === 'Program'} onClick={() => setDivision('Program')}>
                프로그램
              </DivisionButton>
              <DivisionButton isClicked={division === 'OneToOne'} onClick={() => setDivision('OneToOne')}>
                1 : 1
              </DivisionButton>
            </DivisionWrapper>
            {
              division === 'Program' ?
                // <SettingComponentWrapper marginTop={16}>
                //   <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959' cursor='default'>수업 선택</Text>
                //   <WodLoadingClassSelectBox ref={ClassRef} date={date} setScheduleId={setScheduleId} rendering={() => setRendering(!rendering)} />
                // </SettingComponentWrapper>
                null
                :
                <SettingComponentWrapper marginTop={16}>
                  <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959' cursor='default'>회원 선택</Text>
                  <WodLoadingMemberSelectBox options={memberList} setMemberIndex={setMemberIndex} rendering={() => setRendering(!rendering)} />
                </SettingComponentWrapper>
            }
            {
              useData.length === 0 ?
                <EmptyBox isGroup={division === 'Program'}>
                  <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#CBCBCB' cursor='pointer'>저장된 데이터가 없습니다.</Text>
                </EmptyBox>
                :
                <LoadingWodList isGroup={division === 'Program'} useData={useData} clickWorkout={clickWorkout} setClickWorkout={onClickWorkoutList} />
            }
            <ButtonWrapper>
              <ExitButton onClick={closeFn}>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#CBCBCB' cursor='pointer'>취소</Text>
              </ExitButton>
              <SaveButton onClick={onClickComplete}>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='pointer'>완료</Text>
              </SaveButton>
            </ButtonWrapper>
          </ComponentWrapper>
        </Modal>
        <Modal>
          {
            clickWorkout.workoutId !== 0 ?
              <TotalWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#232323'>{clickWorkout.classDate.format('YYYY년 MM월 DD일')}</Text>
                <ScrollWrapper>
                  <ScrollInnerWrapper>
                    <TitleWrapper topMargin={0}>
                      <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>제목</Text>
                    </TitleWrapper>
                    <TotalTitleBox>{clickWorkout.title}</TotalTitleBox>
                    {
                      clickWorkout.sectionList.map((data, index) => (
                        <WodLoadingNormalSectionBox
                          key={index}
                          index={index}
                          sectionData={data} />
                      ))
                    }
                  </ScrollInnerWrapper>
                </ScrollWrapper>
              </TotalWrapper>
              :
              <EmptyImageWrapper>
                <EmptyImage src={EmptySignImage} />
                <Text style={{ marginTop: '16px' }} fontFamily='NotoSansKR-Medium' fontSize={16} color='#DCDCDC'>운동을 선택해주세요</Text>
              </EmptyImageWrapper>
          }
        </Modal>
      </TotalModal>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  top: 0;
  left: 0;

  z-index: 500;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.5);
`;

const TotalModal = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 818px;
  height: 734px;

  border-radius: 30px;
  background-color: #F7F7F7;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 90.33%;
`;

const ScrollWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 24px;

  width: 100%;
  // width: 90.33%;
  // max-height: 1039px;
  max-height: 648px;
  
  overflow-x: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
		border: 3px solid transparent;
		// height: 92px;
		background-color: #DADADA;
		border-radius: 200px;
	}

  &::-webkit-scrollbar-track {
    // height: 439px;
		// border: 4px solid transparent;
		background-color: #F0F0F0;
		border-radius: 200px;
	}
`;

const ScrollInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 96%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 97.71%;

  margin-top: ${props => props.topMargin}px;
`;

const TotalTitleBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: calc(97.71% - 34px);
  min-height: 34px;

  margin-top: 8px;
  padding-right: 16px;
  padding-left: 16px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;
`;

const Modal = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 409px;
  // height: 668px;
  height: 734px;

  border-radius: 30px;
  background-color: #FFFFFF;
  // box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 86.3%;
`;

const SettingComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: ${props => props.marginTop}px;

  width: 100%;
  
  gap: 8px;
`;

const DateTotalWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const DateWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 45.6%;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background-color: #FFFFFF;
`;

const DateButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 38px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background-color: #ECECEC;

  :hover {
    cursor: pointer;
    background-color: #DFDFDF;
  }
  
  transition: all 0.3s ease-in-out;
`;

const Arrow = styled.img`
  width: 24px;
  height: 24px;

  :hover {
    cursor: pointer;
  }
`;

const DivisionWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: 22px;

  width: 100%;
`;

const DivisionButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: calc(48.15% - 2px);
  height: 38px;

  font-family: NotoSansKR-Medium;
  font-size: 14px;
  color: ${props => props.isClicked ? '#FFFFFF' : '#595959'};

  border: ${props => props.isClicked ? '1px solid #FF8B48' : '1px solid #DFDFDF'};
  border-radius: 8px;
  background-color: ${props => props.isClicked ? '#FF8B48' : '#FFFFFF'};

	:hover {
		cursor: pointer;
	}
  
  transition: all 0.3s ease-in-out;
`;

const EmptyBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 25px;
  padding-top: 20px;

  width: 96.31%;
  // height: 320px;
  height: ${props => props.isGroup ? '401px' : '320px'};

  border-top: 1px solid #ECECEC;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: flex-end;

  width: 100%;

  gap: 16px;

  margin-top: 36px;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 72px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;

  :hover {
    cursor: pointer;
  }
`;

const SaveButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 74px;
  height: 40px;

  border-radius: 15px;
  background-color: #6DD49E;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }

  transition: all 0.3s ease-in-out;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }
`;

const EmptyImageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 24px;

  width: 100%;
  height: 648px;
`;

const EmptyImage = styled.img`
  width: 148px;
  height: 62px;
`;