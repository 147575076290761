import { React, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import styled from 'styled-components';

import FetchModule from '../Network/FetchModule';

// import HoverNoticePath from '../../../image/Notice.png';
// import NotHoverNoticePath from '../../../image/RedNotHoverNotice.png';

import DefaultProfile from '../../../image/DefaultProfile.png';
import HoverNotice from '../../../image/HoverNotice.svg';
import NotHoverNotice from '../../../image/NotHoverNotice.svg';

function NoticeBox() {
  const [nowPage, setNowPage] = useState('Holding');
  const [onView, setOnView] = useState(false);
  const [isProfileError, setIsProfileError] = useState(false);

  const [notice, setNotice] = useState(NotHoverNotice);
  const [holdData, setHoldData] = useState(new Array);
  const [expireData, setExpireData] = useState(new Array);
  const [notReceivedData, setNotReceivedData] = useState([]);
  const [birthData, setBirthData] = useState([]);
  const [newMemberData, setNewMemberData] = useState([]);

  const NoticeButtonRef = useRef();
  const NoticeRef = useRef();

  const history = useHistory();

  useEffect(() => {
    if (onView) {
      NoticeRef.current.style.height = `480px`;
      NoticeRef.current.style.border = `1px solid #FF8B48`;
    }
    else {
      NoticeRef.current.style.height = `0px`;
      NoticeRef.current.style.border = `0px`;
    }
  }, [onView])

  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        // const responseData = await fetchModule.getIndividualData('store/notification', 'GET');
        const responseData = await fetchModule.postData('store/notification', 'POST');
        // console.log("=========");
        // console.log(responseData.data[0].newMembers);
        if (responseData.status === 200) {
          setExpireData(responseData.data[0].expires);
          setHoldData(responseData.data[0].hold);
          setNotReceivedData(responseData.data[0].notReceiveds);
          setNewMemberData(responseData.data[0].newMembers);

          let birthMembers = responseData.data[0].birthDayMembers.slice();

          for (let i = 0; i < birthMembers.length; i++) {
            // birthMembers[i].thisYear = moment().format('YYYY');
            birthMembers[i].year = birthMembers[i].birthDay.slice(0, 4);
            birthMembers[i].month = birthMembers[i].birthDay.slice(4, 6);
            birthMembers[i].day = birthMembers[i].birthDay.slice(6, 8);

            // 12월인 경우만 해당 //
            if (moment().format('MM') === '12') {
              if (birthMembers[i].month === '01') birthMembers[i].thisYear = moment().add(1, 'year').format('YYYY');
              else  birthMembers[i].thisYear = moment().format('YYYY');
            }
            else birthMembers[i].thisYear = moment().format('YYYY');

            birthMembers[i].thisBirth = birthMembers[i].thisYear + '.' + birthMembers[i].month + '.' + birthMembers[i].day;
          }

          birthMembers.sort((a, b) => moment(a.thisBirth).diff(moment(b.thisBirth)) || a.name - b.name);

          // console.log(birthMembers);
          setBirthData(birthMembers);
        }
        // else if (responseData.status === 401) history.push('/');
        // else alert('서버와 연결이 불안정합니다. 잠시후 다시시도해 주세요.');
      }
    )();
  }, [])

  useEffect(() => {
    document.addEventListener("mousedown", onClickOutSide);

    return () => {
      document.removeEventListener("mousedown", onClickOutSide);
    }
  }, [])

  function onClickOutSide(e) {
    if (NoticeRef.current !== e.target && !NoticeRef.current.contains(e.target) && !NoticeButtonRef.current.contains(e.target)) {
      setOnView(false);
    }
  }

  function onClickMember(data) {
    // data.division === 'MemberShip' ? '회원권' : '락커'
    if (data.division === 'Locker') history.push('/locker');
    else if (data.division === 'MemberShip') history.push('/member/valid/detail/' + data.memberId);
    else history.push('/member/valid/detail/' + data.memberId);

    // setOnView(!onView);
    setOnView(false);
  }

  // 미수금에서 회원 클릭시 //
  function onClickMemberInNotReceivedPrice(data) {
    history.push('/member/valid/detail/' + data.memberId);

    setOnView(false);
  }

  function onClickImage() {
    if (onView) setOnView(false);
    else setOnView(true);
  }

  // 생일 몇일 남았는지 표시 //
  function decideRemainBirth(data) {
    let remainDays = moment(moment(data.thisBirth)).diff(moment().format('YYYY.MM.DD'), 'days');

    if (remainDays === 0) return 'D-Day';
    else return 'D-' + remainDays;
  }

  // 회원 프로필 정리 //
  function decideUserProfile(profile) {
    if (isProfileError || profile === '') return DefaultProfile;
    else return profile;
  }

  // 업체 등록 날짜 정리 //
  function decideCreatedDate(date) {
    // let remainDays = moment(moment(date)).diff(moment().format('YYYY.MM.DD'), 'days');
    let remainDays = moment(moment().format('YYYY.MM.DD')).diff(moment(date), 'days');

    // if (remainDays === 0) return 'D-Day';
    // else return 'D-' + remainDays;

    if (remainDays === 0) return moment(date).format('YYYY.MM.DD') + ' (D-Day)';
    else return moment(date).format('YYYY.MM.DD') + ' (D+' + remainDays + ')';
  }

  return (
    <TopLevelWrapper>
      <NoticeButton ref={NoticeButtonRef} onMouseOver={() => setNotice(HoverNotice)} onMouseLeave={() => setNotice(NotHoverNotice)} onClick={onClickImage}>
        <NoticeImage src={notice} />
      </NoticeButton>
      <NoticeView ref={NoticeRef}>
        <InfoWrapper>
          <Title>알림</Title>
          <ButtonWrapper>
            <Button isClicked={nowPage === 'Holding'} onClick={() => setNowPage('Holding')}>홀딩 요청</Button>
            <Button isClicked={nowPage === 'Expired'} onClick={() => setNowPage('Expired')}>만료 예정</Button>
            <Button isClicked={nowPage === 'NotReceived'} onClick={() => setNowPage('NotReceived')}>미수금 내역</Button>
            <Button isClicked={nowPage === 'Birth'} onClick={() => setNowPage('Birth')}>생일 회원</Button>
            <Button isClicked={nowPage === 'NewMember'} onClick={() => setNowPage('NewMember')}>신규 회원</Button>
          </ButtonWrapper>
          {
            nowPage === 'Holding' &&
            <InfoBox>
              <ColumnWrapper>
                <Column style={{ marginRight: `48px` }}>이름</Column>
                <Column style={{ marginRight: `24px` }}>적용일수</Column>
                {/* <Column style={{ marginRight: `36px` }}>남은 일수</Column> */}
                <Column style={{ marginRight: `26px` }}>남은 일수</Column>
                <Column>신청일</Column>
              </ColumnWrapper>
              {
                holdData.map((data, index) => (
                  <Info key={index} onClick={() => onClickMember(data)}>
                    <Tr>
                      <Td style={{ width: `66px`, height: `20px`, overflow: `hidden`, marginRight: `8px` }}>{data.name}</Td>
                      <Td style={{ width: `68px`, marginRight: `8px` }}>{(moment(data.endDate).diff(moment(data.startDate), 'day') + 1) + '일'}</Td>
                      {/* <Td style={{ width: `83px`, marginRight: `8px` }}>{data.redundancyDay}일</Td> */}
                      <Td style={{ width: `73px`, marginRight: `8px` }}>{data.redundancyDay}일</Td>
                      {/* <Td style={{ width: `70px` }}>{moment(data.startDate).format('YYYY.MM.DD')}</Td> */}
                      <Td style={{ width: `90px`, marginRight: `16px` }}>{moment(data.startDate).format('MM.DD') + ' - ' + moment(data.endDate).format('MM.DD')}</Td>
                      {
                        moment(data.startDate).isBefore(moment()) ?
                          <DdayBox>
                            <Text fontFmaily='NotoSansKR-Regular' fontSize={11} color='#FFFFFF' hover='pointer'>홀딩중</Text>
                          </DdayBox>
                          :
                          <Text fontFmaily='NotoSansKR-Regular' fontSize={11} color='#757575' hover='pointer'>홀딩 예정</Text>
                      }
                    </Tr>
                  </Info>
                ))
              }
            </InfoBox>
          }
          {
            nowPage === 'Expired' &&
            <InfoBox>
              <ColumnWrapper>
                <Column style={{ marginRight: `48px` }}>이름</Column>
                <Column style={{ marginRight: `50px` }}>구분</Column>
                <Column style={{ marginRight: `36px` }}>남은 일수</Column>
                <Column>만료일</Column>
              </ColumnWrapper>
              {
                expireData.map((data, index) => (
                  <Info key={index} onClick={() => onClickMember(data)}>
                    <Tr>
                      <Td style={{ width: `66px`, height: `20px`, marginRight: `8px`, overflow: `hidden` }}>{data.name}</Td>
                      <Td style={{ width: `68px`, marginRight: `8px` }}>{data.division === 'MemberShip' ? '회원권' : '락커'}</Td>
                      {/* <Td style={{ width: `83px`, marginRight: `8px` }}>{moment(data.endDate).diff(moment(), 'day') === 0 ? 'D-day' : moment(data.endDate).diff(moment(), 'day') + '일'}</Td> */}
                      <Td style={{ width: `83px`, marginRight: `8px` }}>{moment(moment(data.endDate).format('YYYY-MM-DD')).diff(moment(moment().format('YYYY-MM-DD')), 'day') === 0 ? 'D-day' : 'D-' + moment(moment(data.endDate).format('YYYY-MM-DD')).diff(moment(moment().format('YYYY-MM-DD')), 'day')}</Td>
                      <Td style={{ width: `70px`, marginRight: `16px` }}>{moment(data.endDate).format('YYYY.MM.DD')}</Td>
                      {
                        data.isSendKakao ?
                          <KakaoBox>
                            <Text fontFmaily='NotoSansKR-Regular' fontSize={11} color='#FFFFFF' hover='pointer'>카톡완료</Text>
                          </KakaoBox>
                          :
                          <Text fontFmaily='NotoSansKR-Regular' fontSize={11} color='#969696' hover='pointer'>미발송</Text>
                      }
                    </Tr>
                  </Info>
                ))
              }
            </InfoBox>
          }
          {
            nowPage === 'NotReceived' &&
            <InfoBox>
              <ColumnWrapper>
                <Column style={{ marginRight: `48px` }}>이름</Column>
                <Column style={{ marginRight: `84px` }}>구매내역</Column>
                <Column style={{ marginRight: `48px` }}>미수금</Column>
                <Column>결제일</Column>
              </ColumnWrapper>
              {
                notReceivedData.map((data, index) => (
                  <Info key={index} onClick={() => onClickMemberInNotReceivedPrice(data)}>
                    <Tr>
                      <Td style={{ width: `66px`, height: `20px`, marginRight: `8px`, overflow: `hidden` }}>{data.name}</Td>
                      <Td style={{ width: `128px`, height: `20px`, marginRight: `8px`, overflow: `hidden` }}>{data.itemName}</Td>
                      {/* <Td style={{ width: `83px`, marginRight: `8px` }}>{moment(data.endDate).diff(moment(), 'day') === 0 ? 'D-day' : moment(data.endDate).diff(moment(), 'day') + '일'}</Td> */}
                      <Td style={{ width: `80px`, marginRight: `8px` }}>{data.notReceivedPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Td>
                      <Td style={{ width: `70px` }}>{moment(data.approvalDate).format('YYYY.MM.DD')}</Td>
                    </Tr>
                  </Info>
                ))
              }
            </InfoBox>
          }
          {
            nowPage === 'Birth' &&
            <InfoBox>
              <ColumnWrapper>
                <Column style={{ marginRight: `40px` }}>이름</Column>
                <Column style={{ marginRight: `60px` }}>생년월일</Column>
                <Column>전화번호</Column>
              </ColumnWrapper>
              {
                birthData.map((data, index) => (
                  <Info key={index} onClick={() => onClickMemberInNotReceivedPrice(data)}>
                    <Tr>
                      <Td style={{ width: `60px`, height: `20px`, marginRight: `8px`, overflow: `hidden` }}>{data.name}</Td>
                      <Td style={{ width: `100px`, height: `20px`, marginRight: `8px`, overflow: `hidden` }}>{data.birthDay.slice(0, 4) + '.' + data.birthDay.slice(4, 6) + '.' + data.birthDay.slice(6, 8)}</Td>
                      <Td style={{ width: `150px`, height: `20px`, marginRight: `8px`, overflow: `hidden` }}>{data.phone.slice(0, 3) + '-' + data.phone.slice(3, 7) + '-' + data.phone.slice(7, 11)}</Td>
                      {/* <Td style={{ width: `45px` }}></Td> */}
                      <DdayBox>
                        <Text fontFmaily='NotoSansKR-Regular' fontSize={11} color='#FFFFFF' hover='pointer'>{decideRemainBirth(data)}</Text>
                      </DdayBox>
                    </Tr>
                  </Info>
                ))
              }
            </InfoBox>
          }
          {
            nowPage === 'NewMember' &&
            <InfoBox>
              <ColumnWrapper>
                <Column style={{ marginRight: `40px`, marginLeft: `68px` }}>이름</Column>
                <Column style={{ marginRight: `98px` }}>등록일</Column>
                <Column>전화번호</Column>
              </ColumnWrapper>
              {
                newMemberData.map((data, index) => (
                  <Info key={index} style={{ marginTop: '4px', paddingBottom: '4px' }} onClick={() => onClickMemberInNotReceivedPrice(data)}>
                    <Tr>
                      <Td style={{ width: `60px`, height: `42px`, marginRight: `8px`, overflow: `hidden` }}>
                        <ProfileBox src={decideUserProfile(data.profile)} onError={() => setIsProfileError(true)}/>
                      </Td>
                      <Td style={{ width: `60px`, height: `20px`, marginRight: `8px`, overflow: `hidden` }}>{data.name}</Td>
                      <Td style={{ width: `128px`, height: `20px`, marginRight: `8px`, overflow: `hidden` }}>{decideCreatedDate(data.createdDate)}</Td>
                      <Td style={{ width: `100px`, height: `20px`, marginRight: `8px`, overflow: `hidden` }}>{data.phone.slice(0, 3) + '-' + data.phone.slice(3, 7) + '-' + data.phone.slice(7, 11)}</Td>
                    </Tr>
                  </Info>
                ))
              }
            </InfoBox>
          }
        </InfoWrapper>
      </NoticeView>
    </TopLevelWrapper>
  )
}

export default NoticeBox;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-end;
`;

const NoticeButton = styled.div`
  display: flex;
  flex-direction: row;
  
  width: 32px;
  height: 32px;

  border-radius: 32px;

  justify-content: center;
  align-items: center;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #FF8B48;
  }
`;

const NoticeImage = styled.img`
  width: 24px;
  height: 24px;

  // border-radius: 32px;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const NoticeView = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 40px;
  margin-right: -20px;

  // width: 399px;
  width: 462px;
  // height: 480px;
  height: 0px;

  overflow: hidden;

  z-index: 100;

  // border: 1px solid #FF8B48;
  border: 0px;
  border-radius: 8px;
  background-color: #FFFFFF;

  transition: all 0.3s ease-in-out;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  // width: 343px;
  width: 400px;
  height: 424px;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 24px;

  gap: 16px;
`;

const Button = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding-bottom: 5px;

  border-bottom: ${props => props.isClicked ? `2px solid #FF8B48` : `2px solid #FFFFFF`};

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: ${props => props.isClicked ? `#232323` : `#BDBDBD`};

  margin: 0 0 0 0;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border-bottom: 2px solid #FF8B48;
    color: #232323;
  }
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  overflow-x: default;
  overflow-y: scroll;

  margin-top: 20px;

  width: 100%;
  height: 332px;

  // border-top: 2px solid #ECECEC;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border: 3px solid transparent;
    // height: 92px;
    background-color: #DADADA;
    border-radius: 200px;
  }

  &::-webkit-scrollbar-track {
    // height: 439px;
    // border: 4px solid transparent;
    background-color: #F0F0F0;
    border-radius: 200px;
  }
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;
`;

const Column = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #595959;

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 16px;
  padding-bottom: 16px;
  
  border-bottom: 1px solid #F5F5F5;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;

  transition: all 0.3s ease-in-out;
  
  :hover {
    cursor: pointer;
    color: #FF8B48;
  }
`;

const Tr = styled.tr`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const Td = styled.tr`
  
`;

const DdayBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 45px;
  height: 20px;

  border-radius: 30px;
  background-color: #FF8B48;
`;

const ProfileBox = styled.img`
  width: 42px;
  height: 42px;

  border-radius: 6px;

  overflow: hidden;
`;

const KakaoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 45px;
  height: 20px;

  border-radius: 30px;
  background-color: #FFB800;
`;

const Text = styled.p`
  font-family: ${props => props.fontFmaily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.hover};
  }
`;