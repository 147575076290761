import { React, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import FetchModule from '../../Share/Network/FetchModule';

import DefaultProfile from '../../../image/DefaultProfile.png';

const MemberListView = (props) => {
  const { date, memberListRendering, scheduleId, classTime, setMemberAddClick } = props;

  const history = useHistory();

  const [memberData, setMemberData] = useState([]);

  useEffect(() => {
    if (scheduleId === -1) return;
    (
      async () => {
        const fetchModule = new FetchModule();
        const responseData = await fetchModule.getIndividualData('reserve/board_member/'
                                              + scheduleId + '/'
                                              + classTime + '/'
                                              + date.clone().format('YYYY-MM-DD'), 'GET');
        // console.log('scheduleId : ' + scheduleId);
        // console.log('classTime : ' + classTime);
        // console.log('date : ' + date.clone().format('YYYY-MM-DD'));
        // console.log(responseData);
        if (responseData.status === 200) setMemberData(responseData.data); 
        else if (responseData.status === 204) setMemberData([]);
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }, [history, date, scheduleId, classTime, memberListRendering])

  return (
    <TopLevelWrapper>
      <ViewWrapper>
        <TopLineWrapper>
          <LeftWrapper>
            <Title>회원 명단</Title>
            <Count>{memberData.length}</Count>
          </LeftWrapper>
          <AddButton onClick={() => setMemberAddClick(true)}>추가</AddButton>
        </TopLineWrapper>
        {
          memberData.map((data, index) => (
            <MemberWrapper key={index}>
              <Profile src={data.profile === '' ? DefaultProfile : data.profile}/>
              <InfoWrapper>
                <Text color='#232323'>{data.name}</Text>
                <Text color='#777777'>{data.phone[0] 
                                      + data.phone[1]
                                      + data.phone[2]
                                      + '-'
                                      + data.phone[3]
                                      + data.phone[4]
                                      + data.phone[5]
                                      + data.phone[6]
                                      + '-'
                                      + '****'}</Text>
              </InfoWrapper>
            </MemberWrapper>
          ))
        }
      </ViewWrapper>
    </TopLevelWrapper>
  )
}

export default MemberListView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 204px;
  // height: 364px;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  background-color: #FFFFFF;
`;

const ViewWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 148px;
`;

const TopLineWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;

  margin-top: 28px;
  margin-bottom: 24px;
`;

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 3px;
`;

const Title = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;
`;

const Count = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FF8B48;

  margin: 0 0 0 0;
`;

const AddButton = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #777777;

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;

const MemberWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 16px;

  margin-bottom: 28px;
`;

const Profile = styled.img`
  width: 40px;
  height: 40px;

  border-radius: 15px;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  gap: 2px;
`;

const Text = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  margin: 0 0 0 0;

  color: ${props => props.color};
`;