import { React } from 'react';
import styled from 'styled-components';

function PhoneChangeInput(props) {
  const { value, setValue, placeholder, onClickCheck, isCheckedPhone, setIsCheckedPhone, originalPhone } = props;

  function handleChange(e) {
		setValue(e.target.value);
    setIsCheckedPhone(false);
  }

  function calcButtonColor() {
    if (isCheckedPhone) return '#DBDBDB';
    else if (value === originalPhone) return '#DBDBDB';
    else return '#6DD49E';
  }

  return (
    <TopLevelWrapper>
      <Input
        placeholder={placeholder}
        value={value || ''}
        onChange={handleChange} />
      <CheckButton onClick={onClickCheck} backgroundColor={calcButtonColor()}>
        <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF'>확인</Text>
      </CheckButton>
    </TopLevelWrapper>
  )
}

export default PhoneChangeInput;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 32px;
`;

const Input = styled.input`
  // width: calc(100% - 18px);
  width: calc(70% - 18px);
  height: calc(100% - 2px);

  font-family: NotoSansKR-Regular;
  font-size: 14px;
  color: #232323;

  padding: 0 8px 0 8px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  outline: none;

  background-color: ${props => props.disabled ? '#ECECEC' : '#FFFFFF'};

  transition: all 0.3s ease-in-out;

  ::placeholder {
    color: #CBCBCB;
  }
`;

const CheckButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 26.19%;
  height: 32px;

  border-radius: 8px;
  // background-color: ${props => props.isActive ? `#6DD49E` : `#BDBDBD`};
  background-color: ${props => props.backgroundColor};

  :hover { 
    cursor: pointer;
  }
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;