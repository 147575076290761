import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { cloneDeep } from 'lodash';

import LeftArrow from '../../image/Left.png'//왼쪽 화살표
import FetchModule from "../../Model/Share/Network/FetchModule";
import { organizationRanking, calcLegacyRanking } from "../Transaction/Transaction";
import RenewalRankingDetailView from "./RenewalRankingDetailView";
import RankingCommentTextArea from "../../Component/NoteComponent/RankingCommentTextArea";
import RankingCommentBox from "./RenewalRankingCommentBox";
import RankingExerciseBox from './Legacy/LegacyRankingExerciseBox';

export default function RankingDetailBox(props) {
  const {
    width,
    maxHeight,
    nowSection,
    setNowSection,
    clickDate,
    clickWodIndex,
    clickRankingData,
    setClickRankingData,
    clickDetailRankingData,
    clickUserId,
    onClickExitRankingDetail
  } = props;

  const [isClickedEdit, setIsClickedEdit] = useState(false);
  const [editSectionRecord, setEditSectionRecord] = useState(undefined);
  const [legacyRankingData, setLegacyRankingData] = useState([]);
  const [legacyUseData, setLegacyUseData] = useState([]);

  const [sectionRecordId, setSectionRecordId] = useState(undefined);
  const [data, setData] = useState({
    title: '',
    sectionList: [],
    completeSectionList: []
  });
  const [rank, setRank] = useState([]);
  const [records, setRecords] = useState([]);
  const [linkCoachId, setLinkCoachId] = useState('');
  const [selectedRank, setSelectedRank] = useState(undefined);
  const [userName, setUserName] = useState('');
  const [commentIdForReplySave, setCommentIdForReplySave] = useState(-1);
  const [comments, setComments] = useState([]);
  const [commentValue, setCommentValue] = useState('');

  const [isClickedViewCommentIndex, setIsClickedViewCommentIndex] = useState(-1);

  // Ranking Data API Call //
  useEffect(() => {
    if (clickRankingData === undefined || clickRankingData.wodVersion === 2) return;
    
    setSectionRecordId(clickRankingData.sectionRecordId);
    setIsClickedEdit(false);
    setEditSectionRecord(undefined);
    // setLinkCoachId(clickRankingData.linkCoachId);
    // console.log(clickRankingData);

    let requestData = {
      workoutId: clickRankingData.workoutId
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        let responseData = await fetchModule.postDataOfExerciseVersion2('workout/tmp/ranking', 'POST', requestData);
        // console.log(responseData);
        if (responseData.status === 200) {
          let workoutData = responseData.data.slice();

          setLegacyUseData(organizationRanking(workoutData));
        }
        else if (responseData.status === 204) setLegacyUseData([]);
      }
    )();
  }, [clickRankingData])

  // 옛날버전 랭킹 산정 //
  useEffect(() => {
    if (legacyUseData.length === 0) {
      setLegacyRankingData([]);
      return;
    }

    let legacyRanking = calcLegacyRanking(legacyUseData, nowSection);

    setLegacyRankingData(legacyRanking);
  }, [legacyUseData, nowSection])

  useEffect(() => {
    if (clickDetailRankingData === undefined) {
      setLinkCoachId('');
      setRecords([]);
      setData({
        title: '',
        sectionList: [],
        completeSectionList: []
      });
      setSelectedRank(undefined);
      setUserName('');
      setIsClickedEdit(false);
      setEditSectionRecord(undefined);
      return;
    }

    setSectionRecordId(clickDetailRankingData.sectionRecordId);
    setIsClickedEdit(false);
    setEditSectionRecord(undefined);

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getDataOfExerciseVersion2('record/detail/' + clickDetailRankingData.sectionRecordId, 'GET');
        // console.log(responseData);
        if (responseData.status === 200) {
          // 사용할 데이터 정리 //
          let newData = responseData.data.workout;

          // sectionIndex.asc 진행 //
          newData.sectionList.sort(function (a, b) {
            return a.sectionIndex < b.sectionIndex ? -1 : a.sectionIndex > b.sectionIndex ? 1 : 0;
          })

          for (let i = 0; i < newData.sectionList.length; i++) {
            let mainExerciseList = [];

            // 기록이 없는경우 데이터 대체 //
            if (newData.sectionList[i].sectionRecord === undefined) {
              for (let j = 0; j < newData.sectionList[i].exerciseList.length; j++) {
                mainExerciseList.push(newData.sectionList[i].exerciseList[j]);
              }
            }
            // 기록이 있는경우 데이터 정리 //
            else {
              for (let j = 0; j < newData.sectionList[i].sectionRecord.exerciseList.length; j++) {
                mainExerciseList.push(newData.sectionList[i].sectionRecord.exerciseList[j]);
              }
            }

            // exerciseIndex.asc 진행 //
            mainExerciseList.sort(function (a, b) {
              return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
            })

            if (newData.sectionList[i].sectionRecord === undefined) {
              let value = {
                sectionRecordId: 0,
                sectionId: newData.sectionList[i].sectionId,
                linkCoachId: undefined,
                success: 'Not Done',
                recordTime: 0,
                completeRound: 0,
                lastRound: 0,
                lastExerciseId: 0,
                roundOfCount: [],
                photos: '',
                userExperience: '',
                exerciseList: mainExerciseList,
                mainExerciseList: mainExerciseList,
                customLastRound: 0,
                customLastReps: 0,
                customKeywordValue: 0,
                customKeywordUnit: '',
                youtubeLink: '',
                youtubeLinks: [],
              }

              newData.sectionList[i].sectionRecord = value;
            }
            else {
              newData.sectionList[i].mainExerciseList = mainExerciseList;
              newData.sectionList[i].sectionRecord.mainExerciseList = mainExerciseList;
            }
          }

          // if (newData.userId !== 'None') setIsMyWorkout(true);

          for (let j = 0; j < newData.sectionList.length; j++) {
            if (newData.sectionList[j].derivationSectionId !== undefined) {
              for (let h = 0; h < newData.sectionList.length; h++) {
                if (newData.sectionList[h].sectionId === newData.sectionList[j].derivationSectionId) {
                  if (newData.sectionList[h].scaleSections === undefined) newData.sectionList[h].scaleSections = [];

                  newData.sectionList[h].scaleSections.push(newData.sectionList[j]);
                }
              }
            }
          }

          let newSections = [];

          for (let j = 0; j < newData.sectionList.length; j++) {
            if (newData.sectionList[j].derivationSectionId === undefined) {
              newSections.push(newData.sectionList[j]);
            }
          }

          for (let j = 0; j < newData.sectionList.length; j++) {
            if (newData.sectionList[j].scaleSections === undefined) newData.sectionList[j].scaleSections = [];
            if (newData.sectionList[j].youtubeLink !== '') newData.sectionList[j].isViewVideo = true;
            else newData.sectionList[j].isViewVideo = false;
          }

          newData.sectionList = newSections;

          // 완료한 운동 모음집 만들기 //
          let completeSectionList = [];

          for (let i = 0; i < newData.sectionList.length; i++) {
            let isExist = false;

            if (newData.sectionList[i].sectionRecord.success !== 'Not Done') {
              // let record = cloneDeep(newData.sectionList[i].sectionRecord);
              let record = newData.sectionList[i].sectionRecord;

              record.customTitle = newData.sectionList[i].customTitle === undefined ? '' : newData.sectionList[i].customTitle;
              record.tag = newData.sectionList[i].tag;
              record.round = newData.sectionList[i].round;
              record.exerciseTime = newData.sectionList[i].exerciseTime;
              record.restTime = newData.sectionList[i].restTime;
              record.mainProgram = newData.sectionList[i].mainProgram;
              record.mainNote = newData.sectionList[i].mainNote;
              record.scoreKeyword = newData.sectionList[i].scoreKeyword;
              record.scorePriority = newData.sectionList[i].scorePriority;
              record.isScale = false;
              record.scaleSectionIndex = undefined;
              record.youtubeLink = newData.sectionList[i].youtubeLink;
              record.youtubeLinks = newData.sectionList[i].youtubeLinks;

              completeSectionList.push(record);
              isExist = true;
            }
            else {
              for (let j = 0; j < newData.sectionList[i].scaleSections.length; j++) {
                if (newData.sectionList[i].scaleSections[j].sectionRecord.success !== 'Not Done') {
                  // let record = cloneDeep(newData.sectionList[i].scaleSections[j].sectionRecord);
                  let record = newData.sectionList[i].scaleSections[j].sectionRecord;

                  record.customTitle = newData.sectionList[i].scaleSections[j] === undefined ? '' : newData.sectionList[i].scaleSections[j].customTitle;
                  record.tag = newData.sectionList[i].scaleSections[j].tag;
                  record.round = newData.sectionList[i].scaleSections[j].round;
                  record.exerciseTime = newData.sectionList[i].scaleSections[j].exerciseTime;
                  record.restTime = newData.sectionList[i].scaleSections[j].restTime;
                  record.mainProgram = newData.sectionList[i].scaleSections[j].mainProgram;
                  record.mainNote = newData.sectionList[i].scaleSections[j].mainNote;
                  record.scoreKeyword = newData.sectionList[i].scaleSections[j].scoreKeyword;
                  record.scorePriority = newData.sectionList[i].scaleSections[j].scorePriority;
                  record.isScale = true;
                  record.scaleSectionIndex = newData.sectionList[i].scaleSections[j].sectionIndex;
                  record.youtubeLink = newData.sectionList[i].scaleSections[j].youtubeLink;
                  record.youtubeLinks = newData.sectionList[i].scaleSections[j].youtubeLinks;

                  completeSectionList.push(record);
                  isExist = true;
                  break;
                }
              }
            }

            // 추가된 데이터가 없는경우 //
            if (!isExist) {
              // let record = cloneDeep(newData.sectionList[i].sectionRecord);
              let record = newData.sectionList[i].sectionRecord;

              record.isScale = false;

              completeSectionList.push(record);
            }
          }

          newData.completeSectionList = completeSectionList;

          setLinkCoachId(responseData.data.myUserId);
          setRecords(responseData.data.sectionRank);
          setData(newData);
        }
      }
    )();
  }, [clickDetailRankingData])

  // 성별에 따른 순위 산출 //
  useEffect(() => {
    if (records.length === 0 || nowSection === undefined) return;

    setRank(records[nowSection].sectionRecords);
  }, [records, nowSection])


  // 내 기록 있는지 확인 //
  useEffect(() => {
    if (rank.length === 0 || linkCoachId === '' || nowSection === undefined) {
      setSelectedRank(undefined);
      setUserName('');
      return;
    }

    for (let i = 0; i < rank.length; i++) {
      if (rank[i].linkCoachId === linkCoachId) {
        rank[i].rank = i + 1;
        setSelectedRank(rank[i]);
        setUserName(rank[i].userName);
        break;
      }
    }
  }, [rank, nowSection, linkCoachId])

  useEffect(() => {
    if (legacyUseData.length === 0 || nowSection === undefined) {
      setLegacyRankingData([]);
      return;
    }

    let legacyRanking = calcLegacyRanking(legacyUseData, nowSection);
    
    setLegacyRankingData(legacyRanking);
  }, [legacyUseData, nowSection])

  // Comment API Call //
  useEffect(() => {
    if (sectionRecordId === undefined) {
      setComments([]);
      return;
    }

    let requestData = {
      sectionRecordId: sectionRecordId
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataOfExercise('comment/', 'POST', requestData);
        // console.log(responseData);
        if (responseData.status === 200) {
          setComments(responseData.data);
          setCommentValue('');
        }
      }
    )();
  }, [sectionRecordId])


  // 회원님 기록 수정하기 클릭시 //
  function onClickEditRecord() {
    // 저장 또는 복구 //
    if (isClickedEdit) {
      // setEditSectionRecord(undefined);
      // setIsClickedEdit(false);

      // console.log(editSectionRecord);

      let exerciseRecords = [];

      for (let i = 0; i < editSectionRecord.mainExerciseList.length; i++) {
        exerciseRecords.push({
          exerciseRecordId: editSectionRecord.mainExerciseList[i].exerciseRecordId,
          exerciseId: editSectionRecord.mainExerciseList[i].exerciseId,
          weight: editSectionRecord.mainExerciseList[i].weight,
          distance: editSectionRecord.mainExerciseList[i].distance,
          height: editSectionRecord.mainExerciseList[i].height,
          calories: editSectionRecord.mainExerciseList[i].calories,
          count: editSectionRecord.mainExerciseList[i].count,
          time: editSectionRecord.mainExerciseList[i].time,
          tempo: editSectionRecord.mainExerciseList[i].tempo
        });
      }

      let requestData = {
        sectionRecordId: editSectionRecord.sectionRecordId,
        sectionId: editSectionRecord.sectionId,
        linkCoachId: editSectionRecord.linkCoachId,
        success: editSectionRecord.success,
        // recordTime: editSectionRecord.recordTime,
        recordTime: (editSectionRecord.minute * 60) + editSectionRecord.second,
        completeRound: editSectionRecord.completeRound,
        lastRound: editSectionRecord.lastRound,
        roundOfCount: editSectionRecord.roundOfCount,
        customKeywordValue: editSectionRecord.customKeywordValue,
        customLastRound: editSectionRecord.customLastRound,
        customLastReps: editSectionRecord.customLastReps,

        exercises: exerciseRecords
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          let responseData = await fetchModule.postDataOfExerciseVersion2('record/edit', 'POST', requestData);
          // console.log(responseData);
          if (responseData.status === 200) {

            setIsClickedEdit(false);
            setEditSectionRecord(undefined);

            let newClickRankingData = cloneDeep(clickRankingData);

            setClickRankingData(newClickRankingData);

            onClickExitRankingDetail();
          }
          // else if (responseData.status === 20
        }
      )();
    }
    // 저장 데이터 세팅 //
    else {
      let newEditData = cloneDeep(data.completeSectionList[nowSection]);

      // 끝난 시간 계산 //
      let recordTime = newEditData.recordTime;
      let minute = parseInt(recordTime / 60) === 0 ? '0' : parseInt(recordTime / 60);
      let second = parseInt(recordTime % 60) === 0 ? '0' : parseInt(recordTime % 60);

      newEditData.minute = minute;
      newEditData.second = second;

      setEditSectionRecord(newEditData);
      setIsClickedEdit(true);
    }
  }

  // 답글 달기 클릭시 //
  function onPressReplyButton(commentId) {
    setCommentIdForReplySave(commentId);

    // setClickCommentRef(commentRef);
    // setClickCommentData(commentData);
    // setCommentY(commentY);

    // console.log(commentId);
    // InputRef.current.onFocus();
  }

  // 댓글 등록 클릭시 //
  function onClickCommentSaveButton() {

    if (commentValue === '') {
      alert('댓글 내용을 입력해주세요!');
      return;
    }

    if (commentIdForReplySave === -1) {

      let requestData = {
        commentId: undefined,
        storeId: undefined,
        userId: 'None',
        sectionRecordId: sectionRecordId,
        text: commentValue
      }
  
      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataOfExercise('comment/save_zone', 'POST', requestData);
  
          if (responseData.status === 200) {
            setComments(responseData.data);
            setCommentValue('');
          }
        }
      )();
    }
    else {
      let requestData = {
        id: undefined,
        storeId: undefined,
        userId: undefined,
        commentId: commentIdForReplySave,
        text: commentValue
      } 
  
      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataOfExercise('reply/save_zone', 'POST', requestData);
          
          if (responseData.status === 200) {
            setComments(responseData.data);
            setCommentValue('');
          }
        }
      )();
    }
  }

  // 운동 섹션 변경시 //
  function onClickNowSection(value) {
    if (isClickedEdit) {
      setIsClickedEdit(false);
      setEditSectionRecord(undefined);
    }

    setNowSection(value);
  }

  return (
    <TotalRankingDetailBoxWrapper width={width} height={maxHeight}>
      {
        (clickRankingData !== undefined) &&
        <RankingDetailBoxWrapper>
          <RankingDetailBoxInnerWrapper>
          <TopBarWrapper>
            <GoBackButton style={{ marginTop: '8px' }} onClick={onClickExitRankingDetail}>
              <Image src={LeftArrow}/>
            </GoBackButton>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>운동 기록 확인</Text>
            {/* <GoBackButton style={{opacity: 0}}>
              <Image src={LeftArrow}/>
            </GoBackButton> */}
            <ModifyButton isClicked={isClickedEdit} onClick={onClickEditRecord}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FFFFFF' hover='pointer'>{isClickedEdit ? `저장하기` : `수정하기`}</Text>
            </ModifyButton>
          </TopBarWrapper>
          {
            clickRankingData.wodVersion === 2 ?
              <RankingViewBox>
                <RenewalRankingDetailView
                  isClickedEdit={isClickedEdit}
                  data={data}
                  editData={editSectionRecord}
                  setEditData={setEditSectionRecord}
                  userName={userName}
                  selectedRank={selectedRank}
                  nowClickedIndex={nowSection}
                  setNowClickedIndex={onClickNowSection} />
              </RankingViewBox>
              :
              <RankingViewBox style={{ marginTop: '26px' }}>
                <PrevRankingViewInnerWrapper>
                    <RankingExerciseBox
                      clickDate={clickDate}
                      useData={legacyUseData}
                      rankingList={legacyRankingData}
                      nowSection={nowSection}
                      setNowSection={setNowSection}
                      // nowClickLinkCoachId={linkCoachId}
                      nowClickLinkCoachId={clickUserId}
                      clickWorkoutIndex={clickWodIndex} />
                </PrevRankingViewInnerWrapper>
              </RankingViewBox>
          }

          <DivisionLineWrapper>
            <DivisionLine />
          </DivisionLineWrapper>
          <CommentTitleWrapper>
            <CommentInfoWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>댓글</Text>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{comments.length}</Text>
            </CommentInfoWrapper>
          </CommentTitleWrapper>
          {
            comments.map((data, index) => (
              <RankingCommentBox
                key={index}
                index={index}
                data={data}
                setComments={setComments}
                onPressInput={onPressReplyButton}
                isClickedViewCommentIndex={isClickedViewCommentIndex}
                setIsClickedViewCommentIndex={setIsClickedViewCommentIndex} />
            ))
          }
          <CommentWriteBox>
            <CommentInputWrapper>
              <RankingCommentTextArea value={commentValue} setValue={setCommentValue} placeholder='내용을 입력해주세요' />
              <CommentSaveButton onClick={onClickCommentSaveButton}>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FFFFFF'>등록</Text>
              </CommentSaveButton>
            </CommentInputWrapper>
          </CommentWriteBox>
          </RankingDetailBoxInnerWrapper>
        </RankingDetailBoxWrapper>
      }
    </TotalRankingDetailBoxWrapper>
  )
}


const TotalRankingDetailBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  // margin-bottom: 30px;

  width: ${props => props.width};
  min-width: ${props => props.width};
  height: ${props => props.height}px;
  min-height: ${props => props.height}px;

  // height: 300px;

  transition: all 0.3s ease-in-out;
`;

const RankingDetailBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 100%;

  overflow-x: hidden;

  outline: none;
  
  // border-top-left-radius: 20px;
  // border-top-right-radius: 20px;
  // border-radius: 5px;
  // background-color: #FFFFFF;
  // box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);

  height: 100%;

  overflow-x: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
		border: 0px solid transparent;
		background-color: #D9D9D9;
		border-radius: 2px;
	}

  &::-webkit-scrollbar-track {
		// background-color: #F9F9F9;
		border-radius: 200px;
    opacity: 0;
	}

  transition: all 0.3s ease-in-out;
`;

const RankingDetailBoxInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  padding-bottom: 6px;
  width: 100%;
  // height: auto;

  // overflow-x: hidden;

  outline: none;
  
  border-radius: 5px;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);

  transition: all 0.3s ease-in-out;
`;

const TopBarWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding: 22px 20px 0px 20px;

  width: calc(100% - 22px);
  height: 44px;
`;

const GoBackButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 24px;
  height: 24px;

  :hover {
    cursor: pointer;
  }
`;

const ModifyButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 79px;
  height: 30px;
  
  border-radius: 10px;
  background-color: ${props => props.isClicked ? '#FF8B48' : '#6DD49E'};

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const Image = styled.img`
  width: 10px;
  height: 18px;
`;


const RankingViewBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  // height: 100%;

  background-color: #F9F9F9;
`;

const PrevRankingViewInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;  
`;

const DivisionLineWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const DivisionLine = styled.div`
  width: 90.33%;
  height: 1px;

  background-color: #E0E0E0;
`;

const CommentTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 12px;
  gap: 8px;

  width: 94%;
`;

const CommentInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 4px;
`;

const CommentWriteBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 12px;

  width: 100%;

  gap: 8px;
`;

const CommentInputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  padding: 5px;

  width: calc(100% - 10px);
`;

const CommentSaveButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 45px;
  height: 36px;
  
  // border: 1px solid #DFDFDF;
  // border: 1px solid #FF8B48;
  border-radius: 6px;
  background-color: #6DD49E;

  :hover {
    cursor: pointer;
  }
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  
  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;