import { React, useState, useEffect } from 'react';
import styled from 'styled-components';

import { loadTossPayments } from '@tosspayments/payment-sdk';

import FetchModule from '../../Share/Network/FetchModule.js';
import moment from 'moment';

import ProductLogo from '../../../image/ProductLogo.png';
import ExitButtonImg from '../../../image/BlackDeleteButton.png';
import TossLogoImg from '../../../image/TossLogo.png';
import ExitButtonIcon from '../../../image/GrayExitIcon.png';
import LinkPayIcon from '../../../image/LinkPayIcon.png';
import OnSiteIcon from '../../../image/OnSiteIcon.png';

export default function MemberShipRegistrationPaymentModal(props) {
  const {
    isUsePaymentSystem,
    name,
    validity,
    price,
    cashPrice,
    discount,
    notReceived,
    merchantUid,
    totalRegistData,
    onRegist,
    onCompleteExpectedLinkpay,
    cancel,
    saveRegistData,
    getApprovalDate,
    setIsLoading
  } = props;

  const [isInProgress, setIsInProgress] = useState(false);
  const [hover, setHover] = useState('LinkPay');
  const [isAdmin, setIsAdmin] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
    document.body.style = `overflow: hidden`;
    return () => document.body.style = `overflow: auto`;
  }, [])

  useEffect(() => {
    let storeId = localStorage.getItem('StoreId');

    if (storeId === '1') setIsAdmin(true);
  }, [])

  function onClickOnSitePayment() {
    let approvalDate = getApprovalDate();

    let requestData = {
      merchantUid: merchantUid,
      cardPrice: price,
      cashPrice: cashPrice,
      discount: discount,
      notReceived: notReceived,
      approvalDate: approvalDate
    }
    // console.log(requestData);
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataVersion2('sales/onSite/effectiveness', 'POST', requestData);

        if (responseData.status === 200) {
          // console.log('Effect Success');
          onRegist(responseData.data);
        }
        else if (responseData.status === 201) {
          alert('올바르지 않은 요청입니다');
          return;
        }
        else if (responseData.status === 202) {
          alert('이미 결제가 진행된 건 입니다.');
          return;
        }
        else if (responseData.status === 204) {
          alert('결제 내역이 존재하지 않습니다.');
          return;
        }
        // cancel();
      }
    )();
  }


  // 일반결제 버튼 클릭시 ( 페이플 ) //
  function onClickPaymentOnPayple() {
    if (isInProgress) {
      alert('결제를 진행중입니다!');
      return;
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataVersion2('membership/expected_usage', 'POST', totalRegistData);

        // console.log(responseData);
        if (responseData.status === 200) {
          // onRegist(merchant_uid);
          // cancel(responseData.data);
          onCompleteExpectedLinkpay();
          setIsInProgress(false);
          return;
        }
        else if (responseData.status === 201) {
          alert('링크코치 가입자가 아닙니다');
          setIsInProgress(false);
          return;
        }
        else if (responseData.status === 202) {
          alert('데이터가 존재하지 않습니다');
          setIsInProgress(false);
          return;
        }
        else {
          alert(responseData.error);
          setIsInProgress(false);
          return;
        }
      }
    )();
  }


  return (
    <TopLevelWrapper>
      {
        isUsePaymentSystem ?

          <LinkPayModal>
            <PayTopLineWrapper>
              <NoneButton />
              <PayIconWrapper backgroundColor={hover === 'LinkPay' ? '#6DD49E' : '#FF8B48'}>
                {
                  hover === 'LinkPay' ?
                    <PayIcon src={LinkPayIcon} width={52} height={52} />
                    :
                    <PayIcon src={OnSiteIcon} width={44} height={44} />
                }
              </PayIconWrapper>
              <ExitIconButton onClick={() => cancel()}>
                <ExitButton style={{ width: '16px', height: '16px' }} src={ExitButtonIcon} />
              </ExitIconButton>
            </PayTopLineWrapper>
            {
              hover === 'LinkPay' ?
                <PayTextWrapper>
                  <Text fontFmaily='NotoSansKR-Bold' fontSize={16} color='#232323'>링크페이 문자 발송</Text>
                  <Text style={{ marginTop: '8px' }} fontFmaily='NotoSansKR-Medium' fontSize={12} color='#232323'>회원님들이 바로 결제 할수 있도록</Text>
                  <Text fontFmaily='NotoSansKR-Medium' fontSize={12} color='#232323'>카카오 알림톡을 보내드려요</Text>
                </PayTextWrapper>
                :
                <PayTextWrapper>
                  <Text fontFmaily='NotoSansKR-Bold' fontSize={16} color='#232323'>현장 결제 등록</Text>
                  <Text style={{ marginTop: '8px' }} fontFmaily='NotoSansKR-Medium' fontSize={12} color='#232323'>현장 결제 수단으로</Text>
                  <Text fontFmaily='NotoSansKR-Medium' fontSize={12} color='#232323'>회원권 결제를 진행합니다</Text>
                </PayTextWrapper>
            }
            <SelectButtonWrapper>
              <SelectButton onClick={onClickOnSitePayment} onMouseOver={() => setHover('OnSite')} backgroundColor={hover === 'OnSite' ? '#FF8B48' : '#CFCFCF'}>
                <Text fontFmaily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF'>현장 결제</Text>
              </SelectButton>
              <SelectButton onClick={onClickPaymentOnPayple} onMouseOver={() => setHover('LinkPay')} backgroundColor={hover === 'LinkPay' ? '#6DD49E' : '#CFCFCF'}>
                <Text fontFmaily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF'>링크 페이</Text>
              </SelectButton>
            </SelectButtonWrapper>
          </LinkPayModal>
          :
          <ModalView isUsePaymentSystem={isUsePaymentSystem}>
            <TotalWrapper>
              <TitleLineWrapper>
                <Title>상품 정보</Title>
                <ExitButton src={ExitButtonImg} onClick={() => cancel()} />
              </TitleLineWrapper>
              <ProductInfoBox>
                {/* <Logo src={ProductLogo} /> */}
                <Logo src='https://picture.link-zone.org/ZoneLogo.png' />
                <TextWrapper>
                  <TextEng>Link Zone</TextEng>
                  <TextKor>{name}</TextKor>
                  <TextExplanation>{validity} 일</TextExplanation>
                </TextWrapper>
              </ProductInfoBox>
              <PriceWrapper>
                <Title>최종 결제 금액</Title>
                <LayerWrapper style={{ marginTop: `24px` }}>
                  <TextNormal style={{ color: `#595959` }}>결제 금액</TextNormal>
                  <DivisionLine />
                  <TextNormal style={{ color: `#595959` }}>{(parseInt(price) + parseInt(cashPrice)).toLocaleString('ko-KR')}원</TextNormal>
                </LayerWrapper>
                <LayerWrapper style={{ marginTop: `8px` }}>
                  <TextBold style={{ color: `#232323` }}>최종 금액</TextBold>
                  <DivisionLine />
                  <TextBold style={{ color: `#FF8B48` }}>{(parseInt(price) + parseInt(cashPrice)).toLocaleString('ko-KR')}원</TextBold>
                </LayerWrapper>
              </PriceWrapper>
              <OnSiteWrapper isUsePaymentSystem={isUsePaymentSystem}>
                <OnSitePayment onClick={onClickOnSitePayment}>현장에서 직접 결제하기</OnSitePayment>
              </OnSiteWrapper>
            </TotalWrapper>
          </ModalView>
      }
      {/* <ModalView isUsePaymentSystem={isUsePaymentSystem}>
        <TotalWrapper>
          <TitleLineWrapper>
            <Title>상품 정보</Title>
            <ExitButton src={ExitButtonImg} onClick={() => cancel()} />
          </TitleLineWrapper>
          <ProductInfoBox>
            <Logo src='https://picture.link-zone.org/ZoneLogo.png' />
            <TextWrapper>
              <TextEng>Link Zone</TextEng>
              <TextKor>{name}</TextKor>
              <TextExplanation>{validity} 일</TextExplanation>
            </TextWrapper>
          </ProductInfoBox>
          <PriceWrapper>
            <Title>최종 결제 금액</Title>
            <LayerWrapper style={{ marginTop: `24px` }}>
              <TextNormal style={{ color: `#595959` }}>결제 금액</TextNormal>
              <DivisionLine />
              <TextNormal style={{ color: `#595959` }}>{(parseInt(price) + parseInt(cashPrice)).toLocaleString('ko-KR')}원</TextNormal>
            </LayerWrapper>
            <LayerWrapper style={{ marginTop: `8px` }}>
              <TextBold style={{ color: `#232323` }}>최종 금액</TextBold>
              <DivisionLine />
              <TextBold style={{ color: `#FF8B48` }}>{(parseInt(price) + parseInt(cashPrice)).toLocaleString('ko-KR')}원</TextBold>
            </LayerWrapper>
          </PriceWrapper>
          {
            isUsePaymentSystem &&
            <OnSiteWrapper>
              <LinkPayPayment onClick={onClickPaymentOnPayple}>회원님의 링크페이로 결제 요청하기</LinkPayPayment>
            </OnSiteWrapper>
          }
          <OnSiteWrapper isUsePaymentSystem={isUsePaymentSystem}>
            <OnSitePayment onClick={onClickOnSitePayment}>현장에서 직접 결제하기</OnSitePayment>
          </OnSiteWrapper>
        </TotalWrapper>
      </ModalView> */}
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 1100;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.3);

  transition: all 0.3s ease-in-out;
`;

const LinkPayModal = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 354px;
  height: 200px;

  border-radius: 16px;
  background-color: #FFFFFF;
`;

const PayTopLineWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: -40px;

  width: 100%;
`;

const PayIconWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 86px;
  height: 86px;

  border-radius: 86px;
  background-color: ${props => props.backgroundColor};

  transition: all 0.3s ease-in-out;
`;

const PayIcon = styled.img`
  width: ${props => props.width}px;
  height: ${props => props.height}px;

  object-fit: contain;
`;

const ExitIconButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 24px 12px 0px 0px;

  width: 16px;
  height: 16px;

  :hover {
    cursor: pointer;
  }
`;

const NoneButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 24px 12px 0px 0px;

  width: 16px;
  height: 16px;
`;

const PayTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 12px;
`;

const SelectButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  margin-top: 28px;

  width: 100%;
  height: 52px;

  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;

  overflow: hidden;
`;

const SelectButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 50%;
  height: 100%;

  background-color: ${props => props.backgroundColor};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const ModalView = styled.div`
  // display: ${props => props.isClicked ? `none` : `flex`};
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 378px;
  // height: 416px;
  height: ${props => props.isUsePaymentSystem ? `454px` : `416px`};
  // height: 454px;

  border-radius: 30px;
  background-color: #FFFFFF;
  // box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  // width: 322px;
`;

const TitleLineWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const Title = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;
`;

const ExitButton = styled.img`
  width: 16px;
  height: 16px;

  :hover {
    cursor: pointer;
  }
`;

const ProductInfoBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  gap: 16px;
  margin: 24px 0 0 0;
  padding: 0 16px 0 16px;

  width: 290px;
  height: 100px;

  border-radius: 15px;
  background-color: #FF8B48;
`;

const Logo = styled.img`
  width: 60px;
  height: 60px;

  margin: 0 0 0 0;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const TextEng = styled.p`
  font-family: Poppins-Bold;
  font-size: 14px;

  color: #FFFFFF;

  margin: 0 0 0 0;
`;

const TextKor = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  margin: 0 0 0 0;
`;

const TextExplanation = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 12px;

  color: rgba(255, 255, 255, 0.5);

  margin: 0 0 0 0;
`;

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin: 24px 0 0 0;
  padding: 24px 0 0 0;

  width: 322px;

  border-top: 1px solid #ECECEC;
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const TextBold = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 14px;

  // color: #232323;

  margin: 0 0 0 0;
`;

const TextNormal = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  // color: #232323;

  margin: 0 0 0 0;
`;

const DivisionLine = styled.div`
  width: 160px;
  height: 1px;

  border-top: 1px dashed #ECECEC;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 12px;
  margin-top: 36px;
`;

const OnSiteWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // margin-top: 12px;
  margin-top: ${props => props.isUsePaymentSystem ? `12px` : `36px`};

  width: 100%;
`;

const OnSitePayment = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // width: 290px;
  width: 100%;
  height: 38px;

  border: 1px solid #E0E0E0;
  border-radius: 15px;
  background-color: #FFFFFF;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #BDBDBD;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #232323;
    color: #232323;
  }
`;

const LinkPayPayment = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // width: 155px;
  width: 100%;
  // height: 40px;
  height: 38px;

  border: 1px solid #E0E0E0;
  border-radius: 15px;
  background-color: #FFFFFF;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #BDBDBD;
  
  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    // background-color: #2BCB67;
    border: 1px solid #6DD49E;
    color: #6DD49E;
  }
`;

const Text = styled.div`
  font-family: ${props => props.fontFmaily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: ${props => props.cursor};
  }
`;