import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Lottie from 'lottie-react-web';

// import LoadingAnimation from '../../../Animation/MemberLoadingAnimation.json';
import LoadingAnimation from '../../../Animation/LoadingAnimation.json';

export default function RegistrationLoading() {

  const [loadingText, setLoadingText] = useState('회원권 등록 진행중');

  useEffect(() => {
    setTimeout(() => {
      if (loadingText === '회원권 등록 진행중') setLoadingText('회원권 등록 진행중.');
      else if (loadingText === '회원권 등록 진행중.') setLoadingText('회원권 등록 진행중..');
      else if (loadingText === '회원권 등록 진행중..') setLoadingText('회원권 등록 진행중...');
      else if (loadingText === '회원권 등록 진행중...') setLoadingText('회원권 등록 진행중');
    }, 800);
  }, [loadingText])

  return (
    <TopLevelWrapper>
      <ViewWrapper>
        <LottieWrapper>
          <Lottie options={{ animationData: LoadingAnimation }} />
        </LottieWrapper>
        <TextWrapper>
          <Text fontFmaily='NotoSansKR-Medium' fontSize={16} color='#595959' cursor='default'>{loadingText}</Text>
          {/* <Text fontFmaily='NotoSansKR-Medium' fontSize={16} color='#595959' cursor='default'>회원권 등록 진행중...</Text> */}
        </TextWrapper>
      </ViewWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  justify-content: center;
  align-items: center;

  z-index: 1000;

  width: 100vw;
  height: 100vh;

  overflow: scroll;

  background-color: rgba(0, 0, 0, 0.2);
`;

const ViewWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  gap: 40px;

  // width: 400px;
  // height: 430px;
  width: 180px;
  height: 240px;

  overflow: hidden;

  border-radius: 8px;
  background-color: #FFFFFF;
`;

const LottieWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 400px;
  // height: 300px;

  width: 160px;
  height: 160px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 140px;
`;

const Text = styled.div`
  font-family: ${props => props.fontFmaily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: ${props => props.cursor};
  }
`;