import { React, useState, useEffect } from 'react';
import styled from 'styled-components';

// Image Import //
import ProgramVideoIcon from '../../../image/ProgramVideoIcon.png';
import CheckImage from '../../../image/WhiteCheck.png';
// Image Import //

export default function ScaleNormalMovementBox(props) {
  const { 
    data, 
    idx, 
    realIndex,
    tag, 
    reps,
    scale,
    division, 
    scaleExerciseList,
    onClickMovementVideo, 
  } = props;

  const [scaleCount, setScaleCount] = useState(0);

  // 순서 정하기 //
  useEffect(() => {
    let dependencyIndex = data.dependencyIndex;
    let count = 0;

    for (let i = 0; i < scaleExerciseList.length; i++) {
      if (scaleExerciseList[i].dependencyIndex === dependencyIndex) {
        if (data.movementId === scaleExerciseList[i].movementId && i === realIndex) break;
        else count += 1;
      }
    }

    setScaleCount(count);
  }, [data, scaleExerciseList, realIndex])

  // 한자리 숫자 앞에 0 붙이기 //
  function numberPad(n, width) {
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
  }

  return (
    <TopLevelWrapper>
      <MovementWrapper key={idx}>
        <MovementTitleWrapper>
          <TextScaleOptionViewWrapper>
            <NumberWrapper>
              <NumberBox width={27 + (27 * scale / 100)} height={18 + (18 * scale / 100)} isGreen={division === 'buyin' || division === 'buyout'}>
                <Text fontFamily='NotoSansKR-Bold' fontSize={12 + (12 * scale / 100)} color='#FFFFFF'>{numberPad(idx + 1, 2)}</Text>
              </NumberBox>
              <TextWrapper>
                <Text fontFamily='Poppins-Medium' fontSize={12 + (14 * scale / 100)} color='#4F4F4F'>{data.movementName}</Text>
              </TextWrapper>
            </NumberWrapper>
            <ScalOptionView height={(18 + (18 * scale / 100))}>
              <Text fontFamily='Poppins-Medium' fontSize={12 + (12 * scale / 100)} color='#4161AF'>{'Scale movement ' + String.fromCharCode(scaleCount + 65)}</Text>
            </ScalOptionView>
          </TextScaleOptionViewWrapper>
          <IconWrapper>
            <Icon size={20 + (20 * scale / 100)} src={ProgramVideoIcon} onClick={() => onClickMovementVideo(division, idx)}/>
          </IconWrapper>
        </MovementTitleWrapper>
        {
          data.isUseWeight &&
          <LayerWrapper topMargin={16}>
            <ComponentWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14 + (14 * scale / 100)} color='#828282'>무게</Text>
              <DataBox blank={34 + (34 * scale / 100)} height={34 + (34 * scale / 100)}>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14 + (14 * scale / 100)} color='#4F4F4F'>{data.weight}</Text>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14 + (14 * scale / 100)} color='#BDBDBD'>{data.weightUnit}</Text>
              </DataBox>
            </ComponentWrapper>
          </LayerWrapper>
        }
        {
          data.isUseDistance &&
          <LayerWrapper topMargin={12}>
            <ComponentWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14 + (14 * scale / 100)} color='#828282'>거리</Text>
              <DataBox blank={34 + (34 * scale / 100)} height={34 + (34 * scale / 100)}>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14 + (14 * scale / 100)} color='#4F4F4F'>{data.distance}</Text>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14 + (14 * scale / 100)} color='#BDBDBD'>{data.distanceUnit}</Text>
              </DataBox>
            </ComponentWrapper>
          </LayerWrapper>
        }
        {
          data.isUseHeight &&
          <LayerWrapper topMargin={12}>
            <ComponentWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14 + (14 * scale / 100)} color='#828282'>높이</Text>
              <DataBox blank={34 + (34 * scale / 100)} height={34 + (34 * scale / 100)}>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14 + (14 * scale / 100)} color='#4F4F4F'>{data.height}</Text>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14 + (14 * scale / 100)} color='#BDBDBD'>{data.heightUnit}</Text>
              </DataBox>
            </ComponentWrapper>
          </LayerWrapper>
        }
        {
          data.isUseCalories &&
          <LayerWrapper topMargin={12}>
            <ComponentWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14 + (14 * scale / 100)} color='#828282'>열량</Text>
              <DataBox blank={34 + (34 * scale / 100)} height={34 + (34 * scale / 100)}>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14 + (14 * scale / 100)} color='#4F4F4F'>{data.calories}</Text>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14 + (14 * scale / 100)} color='#BDBDBD'>{data.caloriesUnit}</Text>
              </DataBox>
            </ComponentWrapper>
          </LayerWrapper>
        }
        {
          data.isUseCount &&
          <LayerWrapper topMargin={12}>
            <ComponentWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14 + (14 * scale / 100)} color='#828282'>횟수</Text>
              { 
                tag === 'SINGLE' &&
                <TimeInputWrapper blank={0 + (34 * scale / 100)}>
                  <TimeDataBox blank={34 + (34 * scale / 100)} height={34 + (34 * scale / 100)}>
                    <Text fontFamily='NotoSansKR-Regular' fontSize={14 + (14 * scale / 100)} color='#4F4F4F'>{data.count}</Text>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14 + (14 * scale / 100)} color='#BDBDBD'>REPS</Text>
                  </TimeDataBox>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14 + (14 * scale / 100)} color='#BDBDBD'>X</Text>
                  <TimeDataBox blank={34 + (34 * scale / 100)} height={34 + (34 * scale / 100)}>
                    <Text fontFamily='NotoSansKR-Regular' fontSize={14 + (14 * scale / 100)} color='#4F4F4F'>{data.sets}</Text>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14 + (14 * scale / 100)} color='#BDBDBD'>SETS</Text>
                  </TimeDataBox>
                </TimeInputWrapper>
              }
              {
                tag === 'ForTime-Different Reps' &&
                <DataBox blank={34 + (34 * scale / 100)} height={34 + (34 * scale / 100)}>
                  <Text fontFamily='NotoSansKR-Regular' fontSize={14 + (14 * scale / 100)} color='#4F4F4F'>{reps}</Text>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14 + (14 * scale / 100)} color='#BDBDBD'>REPS</Text>
                </DataBox>
              }
              {
                tag !== 'SINGLE' && tag !== 'ForTime-Different Reps' &&
                <DataBox blank={34 + (34 * scale / 100)} height={34 + (34 * scale / 100)}>
                  <Text fontFamily='NotoSansKR-Regular' fontSize={14 + (14 * scale / 100)} color='#4F4F4F'>{data.count}</Text>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14 + (14 * scale / 100)} color='#BDBDBD'>REPS</Text>
                </DataBox>
              }
            </ComponentWrapper>
          </LayerWrapper>
        }
        {
          data.isUseTime &&
          <LayerWrapper topMargin={12}>
            <ComponentWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14 + (14 * scale / 100)} color='#828282'>시간</Text>
              <TimeInputWrapper blank={0 + (34 * scale / 100)}>
                <TimeDataBox  blank={34 + (34 * scale / 100)} height={34 + (34 * scale / 100)}>
                  <Text fontFamily='NotoSansKR-Regular' fontSize={14 + (14 * scale / 100)} color='#4F4F4F'>{parseInt(data.time / 60)}</Text>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14 + (14 * scale / 100)} color='#BDBDBD'>MIN</Text>
                </TimeDataBox>
                <TimeDataBox blank={34 + (34 * scale / 100)} height={34 + (34 * scale / 100)}>
                  <Text fontFamily='NotoSansKR-Regular' fontSize={14 + (14 * scale / 100)} color='#4F4F4F'>{parseInt(data.time % 60)}</Text>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14 + (14 * scale / 100)} color='#BDBDBD'>SEC</Text>
                </TimeDataBox>
              </TimeInputWrapper>
            </ComponentWrapper>
          </LayerWrapper>
        }
        {
          data.isUseTempo &&
          <LayerWrapper topMargin={12}>
            <ComponentWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14 + (14 * scale / 100)} color='#828282'>템포</Text>
              <DataBox blank={34 + (34 * scale / 100)} height={34 + (34 * scale / 100)}>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14 + (14 * scale / 100)} color='#4F4F4F'>{data.tempo}</Text>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14 + (14 * scale / 100)} color='#BDBDBD'>TEMPO</Text>
              </DataBox>
            </ComponentWrapper>
          </LayerWrapper>
        }
        {
          tag === 'SINGLE' &&
          <RestComponentWrapper topMargin={12}>
            <CheckBox isClicked={data.isUseRest}>
              <Check src={CheckImage} />
            </CheckBox>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14 + (9 * scale / 100)} color='#828282'>쉬는 시간</Text>
            <RestTimeDataBox blank={34 + (34 * scale / 100)} height={34 + (34 * scale / 100)}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14 + (14 * scale / 100)} color='#4F4F4F'>{data.rest}</Text>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14 + (14 * scale / 100)} color='#BDBDBD'>SEC</Text>
            </RestTimeDataBox>
          </RestComponentWrapper>
        }
      </MovementWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: 100%;
`;

const MovementWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: 100%;

  margin-top: 23px;
`;

const NumberWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const MovementTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const TextScaleOptionViewWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 12px;
`;

const ScalOptionView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 0px 8px 0px 8px;
  // width: 88px;
  // height: 18px;
  height: ${props => props.height}px;

  white-space: nowrap;
  
  border-radius: 6px;
  background-color: #E0E0E0;

  :hover {
    cursor: pointer;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 12px;
`;

const Icon = styled.img`
  // width: 20px;
  // height: 20px;
  width: ${props => props.size}px;
  height: ${props => props.size}px;

  :hover {
    cursor: pointer;
  }
`;

const NumberBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  // width: 27px;
  // height: 18px;
  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: 6px;
  // background-color: ${props => props.isGreen ? `#6DD49E` : `#FF8B48`};
  background-color: #4161AF;

  margin: 0 0 0 0;
  padding: 0 0 0 0;
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;

  margin-top: ${props => props.topMargin}px;
`; 

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100%;

  gap: 8px;
`;

const SingleCountWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 93.38%;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  padding: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const TimeInputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  // width: 93.38%;
  width: calc(93.38% - ${props => props.blank}px);
`;

const RestComponentWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 7px;

  width: 100%;

  margin-top: ${props => props.topMargin}px;
`;

const CheckBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;

  border-radius: 6px;
  background-color: ${props => props.isClicked ? `#6DD49E` : `#E0E0E0`};

  transition: all 0.3s ease-in-out;
`;

const Check = styled.img`
  width: 10px;
  height: 9px;
`;

const DataBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: calc(93.38% - ${props => props.blank}px);
  // height: 34px;
  height: ${props => props.height}px;

  padding: 0 16px 0 16px;
  margin: 0 0 0 0;

  border: 1px solid #E0E0E0;
  border-radius: 15px;
  background-color: #FFFFFF;
`;

const RestTimeDataBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: calc(82.29% - ${props => props.blank}px);
  // height: 34px;
  height: ${props => props.height}px;

  padding: 0 16px 0 16px;
  margin: 0 0 0 0;

  border: 1px solid #E0E0E0;
  border-radius: 15px;
  background-color: #FFFFFF;
`;

const TimeDataBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: calc(47.5% - ${props => props.blank}px);
  // height: 34px;
  height: ${props => props.height}px;

  padding: 0 16px 0 16px;
  margin: 0 0 0 0;

  border: 1px solid #E0E0E0;
  border-radius: 15px;
  background-color: #FFFFFF;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  max-height: 39px;

  overflow: hidden;
`;
