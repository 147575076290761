import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

import BlackArrow from '../../../image/BlackDownArrow.png';
import OrangeArrow from '../../../image/OrangeDownArrow.png';
import CheckImage from '../../../image/CheckImage.png';

export default function SelectMembershipBox(props) {
  const {
    value,
    setValue,
    memberships
  } = props;

  const [isClicked, setIsClicked] = useState(false);

  const ButtonRef = useRef(null);
  const SelectBoxRef = useRef(null);

  // 컴포넌트 외부 클릭시 이벤트를 받기위한 리스너 연결
  useEffect(() => {
    document.addEventListener("mousedown", onClickOutSide);

    return () => {
      document.removeEventListener("mousedown", onClickOutSide);
    }
  }, [])

  // 컴포넌트 외부 클릭시 실행
  function onClickOutSide(e) {
    if (SelectBoxRef.current !== e.target 
      && !SelectBoxRef.current.contains(e.target)
      && ButtonRef.current !== e.target 
      && !ButtonRef.current.contains(e.target)) {
        SelectBoxRef.current.style.height = `0px`;
        SelectBoxRef.current.style.overflow = 'hidden';
        setIsClicked(false);
    }
  }

  // 더보기 클릭시 //
  function onClickMemberships() {
		if (isClicked) {
			SelectBoxRef.current.style.height = `0px`;
			SelectBoxRef.current.style.overflow = 'hidden';
			setIsClicked(false);
		}
		else {
			SelectBoxRef.current.style.height = (32 * (memberships.length + 1 )) > 230 ? `230px` : ((32 * (memberships.length + 1)) + 'px');
			SelectBoxRef.current.style.overflowY = 'scroll';
			SelectBoxRef.current.style.overflowX = 'none';
			setIsClicked(true);
		}
  }

  // 수업 클릭시
	function optionalClick(index) {
    // 입력시 중복확인 => 존재하면 삭제, 존재하지 않으면 추가
    let newDefaultVal = value.slice();

    if (newDefaultVal.includes(memberships[index])) {
      let deletedIndex = 0;

      newDefaultVal.filter(function(item, idx) {
        // 두개가 같으면
        if (memberships[index] === item) {
          deletedIndex = idx;
          return;
        }
      })

      newDefaultVal.splice(deletedIndex, 1);
      setValue(newDefaultVal);
    }
    else {
      newDefaultVal.push(memberships[index]);
      setValue(newDefaultVal);
    }
	}

  // 확인 버튼 클릭시 //
  function onClickComplete() {
		SelectBoxRef.current.style.height = `0px`;
		SelectBoxRef.current.style.overflow = 'hidden';
		setIsClicked(false);
  }
  
  // 초기화 버튼 클릭시 //
  function onClickReset() {
    SelectBoxRef.current.style.height = `0px`;
    SelectBoxRef.current.style.overflow = 'hidden';
    setValue([]);
    setIsClicked(false);
  }

  return (
    <StatusBoxWrapper>
      <StatusBox ref={ButtonRef} isClicked={isClicked} onClick={onClickMemberships}>
        <Text fontFamily='NotoSansKR-Regular' fontSize={12} color={isClicked ? `#FF8B48` : `#3E4148`} hover='pointer'>회원권 정보</Text>
        {
          (value.length === 0) &&
          <ArrowWrapper isClicked={isClicked}>
            <Arrow src={BlackArrow} opacity={isClicked ? 0 : 1} />
            <Arrow src={OrangeArrow} opacity={isClicked ? 1 : 0} />
          </ArrowWrapper>
        }
        {
          (value.length !== 0) &&
          <Text fontFamily='NotoSansKR-Regular' fontSize={12} color={isClicked ? `#FF8B48` : `#3E4148`} hover='pointer'>:</Text>
        }
        {
          (value.length > 0) &&
          <Text fontFamily='NotoSansKR-Regular' fontSize={12} color={isClicked ? `#FF8B48` : `#3E4148`} hover='pointer'>{value[0].membershipName}</Text>
        }
        {
          (value.length > 1) &&
          <Text fontFamily='NotoSansKR-Regular' fontSize={12} color={isClicked ? `#FF8B48` : `#3E4148`} hover='pointer'>, ...</Text>
        }
      </StatusBox>
      <SelectBox ref={SelectBoxRef} isClicked={isClicked}>
        {
          memberships.map((data, index) => (
            <OptionWrapper key={'Options-' + index} onClick={() => optionalClick(index)}>
              <TextWrapper>
                <Option isClicked={value.includes(data)}>{data.membershipName}</Option>
              </TextWrapper>
              { value.includes(data) && <Check src={CheckImage}/> }
            </OptionWrapper>
          ))
        }
        
      <CompleteButtonWrapper>
        <TextButton onClick={onClickComplete}>확인</TextButton>
        <TextButton onClick={onClickReset}>초기화</TextButton>
      </CompleteButtonWrapper>
      </SelectBox>
    </StatusBoxWrapper>
  )
}

const StatusBoxWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const StatusBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  padding: 0px 4px;

  height: 20px;

  gap: 4px;

  border: ${props => props.isClicked ? `1px solid #FF8B48` : `1px solid #DBDDE4`};
  border-radius: 5px;
  background-color: ${props => props.isClicked ? `#FFFFFF` : `#F5F6F8`};

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const ArrowWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 10px;
  height: 6px;

  transition: all 0.3s ease-in-out;

  transform: ${props => props.isClicked ? `rotateZ(-180deg)` : `rotateZ(0deg)`};
`;

const Arrow = styled.img`
  position: absolute;

  width: 10px;
  height: 6px;

  opacity: ${props => props.opacity};

  transition: all 0.3s ease-in-out;
`;

const SelectBox = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  margin-top: 26px;

  // width: 88px;
  width: 158px;
  height: ${props => props.isClicked ? `70px` : `0px`};

  gap: 8px;

  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  border-radius: 10px;
  background-color: #FFFFFF;

  overflow: hidden;

  transition: all 0.3s ease-in-out;


	:hover {
		cursor: pointer;
	}

	&::-webkit-scrollbar {
		width: 9px;
	}

	&::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 20px #FF8B48;
		border: 3px solid transparent;
		height: 25px;
		border-radius: 200px;
	}

	&::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px transparent;
		border: 4px solid transparent;
		background-color: transparent;
	}

	&::-webkit-scrollbar-track-piece {
		background-color: transparent;
	}
`;

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  padding: 0px 8px 0px 8px;

  width: calc(100% - 26px);
  height: 35px;

  gap: 6px;

  :hover {
    cursor: pointer;
  }
`;

const Option = styled.div`
	font-family: NotoSansKR-Regular;
	font-size: 13px;

  color: ${props => props.isClicked ? `#FF8B48` : `#555555`};

  margin: 0 0 0 0;
  padding: 0 0 0 0;
`;

const CheckBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 16px;
  height: 16px;

  border: ${props => props.isClicked ? `1px solid #FF8B48` : `1px solid #969696`};
  border-radius: 5px;
  background-color: ${props => props.isClicked ? `#FF8B48` : `#FFFFFF`};
  
  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const CompleteButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  padding: 0px 8px 0px 8px;

  gap: 8px;

  width: calc(100% - 16px);
  height: 36px;

  border-top: 1px solid #DBDBDB;
`;

const TextButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #BDBDBD;

  :hover {
    cursor: pointer;
    color: #FF8B48;
  }

  transition: all 0.3s ease-in-out;
`;

const Check = styled.img`
  width: 12px;
  height: 9px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  // margin: 6px 0 6px 0;

  width: calc(100% - 16px);
  height: 19px;

  overflow: hidden;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;