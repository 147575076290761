import { React, useState, useEffect } from 'react';
import styled from 'styled-components';

function HoldingHistoryColumn() {

  return (
    <TopLevelWrapper>
      <Column style={{marginRight: '168px', marginLeft: '28px'}}>기간</Column>
      <Column style={{marginRight: '92px'}}>적용일수</Column>
      <Column style={{marginRight: '285px'}}>회원권</Column>
      <Column>상태</Column>
    </TopLevelWrapper>
  )
}

export default HoldingHistoryColumn;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  // justify-content: center;
  
  // justify-content: center;
  align-items: center;

  width: 1020px;
  margin-top: 34px;
  margin-bottom: 20px;
  
  transition: all 0.3s ease-in-out;
`;

const Column = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;
`;
