import React from "react";
import styled from "styled-components";

export default function ProvisionTitleInput(props) {
  const {
    value, 
    setValue, 
    placeholder
  } = props;


	function handleClick(e) {
	}

	function handleChange(e) {
    setValue(e.target.value);
	}

	function handleFocus(e) {
	}

	function handleBlur(e) {
	}

  return (
    <InputView>
      <Input
        placeholder={placeholder}
        value={value}
        onClick={handleClick}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}/>
    </InputView>
  );
}

const InputView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 0;

  width: calc(100% - 2px);
  height: calc(100% - 2px);

  border: 1px solid #969696;
  border-radius: 10px;
  background-color: #F9F9F9;
`;

const Input = styled.input`
	font-family: NotoSansKR-Regular;
	font-size: 14px;
	line-height: 20px;

  padding: 0;

  width: calc(100% - 20px);
  height: 100%;

	outline: none;
  border: 0px;
	color: #595959;
  background-color: #F9F9F9;

	::placeholder {
    color: #CBCBCB;
	}
	
  transition: all 0.3s ease-in-out;
`;