import { React, useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled, { keyframes } from 'styled-components';

import BlackExitButtonImage from '../../image/BlackExitButton.png';
// import CheckImage from '../../../image/WhiteCheck.png';

export default function VideoViewModal(props) {
  const { data, close } = props;

  // Modal 창 스크롤 제어 //
  useEffect(() => {
    document.body.style= `overflow: hidden`;
    return () => document.body.style = `overflow: auto`
  }, [])

  return ReactDOM.createPortal(
    <TopLevelWrapper>
      <ModalView>
        <TotalView>
          <TitleWrapper>
            <Title>영상 보기</Title>
            <ButtonWrapper onClick={() => close()}>
              <ExitButton src={BlackExitButtonImage} />
            </ButtonWrapper>
          </TitleWrapper>
          <VideoViewWrapper>
            {
              data === '' ?
              <EmptyText>동영상이 등록되어있지 않습니다</EmptyText>
              :
              <iframe
                width="100%"
                height="100%"
                src={`https://www.youtube.com/embed/` + data}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"/>
            }
          </VideoViewWrapper>
        </TotalView>
      </ModalView>
    </TopLevelWrapper>, document.body
  )
}

const startAnimation = keyframes`
	0% { opacity: 0; }
	50% { opacity: 0.5; }
	100% { opacity: 1; }
`;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  justify-content: center;
  align-items: center;

  z-index: 1000;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.2);
	
	animation-name: ${startAnimation};
	animation-duration: 0.5s;
	animation-timing-function: ease-out;
`;

const ModalView = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 470px;
  // height: 409px;
  // height: 355px;
  width: 450px;
  // height: 320px;
  height: 310px;

  border-radius: 8px;
  box-shadow: 0px 40px 50px rgba(0, 0, 0, 0.1);

  background-color: #FFFFFF;
`;

const TotalView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  // width: 88.08%;
  width: 414px;

  // background-color: red;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: 0 0 0 0;
`;

const VideoViewWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // margin-top: 24px;
  margin-top: 16px;

  width: 100%;
  height: 232px;

  background-color: #E0E0E0;
`;

const EmptyText = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 12px;

  color: #232323;

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // gap: 16px;
  
  // width: 100%;

  // margin-top: 39px;
`;

const ExitButton = styled.img`
  width: 20px;
  height: 20px;

  :hover {
    cursor: pointer;
  }
`;

// const ExitButton = styled.div`
//   display: flex;
//   flex-direction: column;

//   justify-content: center;
//   align-items: center;

//   width: 72px;
//   height: 38px;

//   border: 1px solid #DFDFDF;
//   border-radius: 15px;
//   background-color: #FFFFFF;
  
//   font-family: NotoSansKR-Regular;
//   font-size: 14px;

//   color: #CBCBCB;

//   transition: all 0.3s ease-in-out;

//   :hover {
//     cursor: pointer;
//     border: 1px solid #777777;
//     color: #777777;
//   }
// `;
