import React, { useRef } from "react";
import styled from "styled-components";

import WhiteTrashIcon from '../../../image/WhiteTrashIcon.png';

export default function ProductBox(props) {
  const { data, onClickProduct, onClickProductDelete } = props;

  const DeleteRef = useRef();

  // 마우스 호버링 시작 //
  function onMouseOver() {
    DeleteRef.current.style.backgroundColor = '#FF3131';
  }

  // 마우스 호버링 끝 //
  function onMouseLeave() {
    DeleteRef.current.style.backgroundColor = '#CBCBCB';
  }

  // 물품 클릭시 //
  function onClickProductButton(e) {
    if (DeleteRef.current.contains(e.target)) return;

    onClickProduct(data);
  }

  // 물품 삭제 버튼 클릭시 //
  function onClickProductDeleteButton() {
    onClickProductDelete(data);
  }

  return (
    <ProductBoxView onMouseOver={onMouseOver} onMouseLeave={onMouseLeave} onClick={onClickProductButton}>
      <DeleteButtonWrapper onClick={onClickProductDeleteButton}>
        <DeleteButton ref={DeleteRef}>
          <DeleteImage src={WhiteTrashIcon} />
        </DeleteButton>
      </DeleteButtonWrapper>
      <ProductImageWrapper>
        <ProductImage isEmpty={data.picture === ''} src={data.picture === '' ? 'https://picture.link-zone.org/DefaultLogo.png' : data.picture}/>
      </ProductImageWrapper>
      <ProductInfoBox>
        <ProductInfoWrapper>
          <ProductTextWrapper width={65} alignItems='flex-start'>
            <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF'>{data.name}</Text>
          </ProductTextWrapper>
          <ProductTextWrapper width={30} alignItems='flex-end'>
            <Text fontFamily='NotoSansKR-Medium' fontSize={15} color='#FFFFFF'>{data.inventory.toLocaleString('ko-KR')}개</Text>
          </ProductTextWrapper>
        </ProductInfoWrapper>
        <ProductInfoWrapper>
          <ProductTextWrapper width={15} alignItems='flex-start'>
            <Text fontFamily='NotoSansKR-Regular' fontSize={15} color='#FFFFFF'>가격</Text>
          </ProductTextWrapper>
          <ProductTextWrapper width={80} alignItems='flex-end'>
            <Text fontFamily='NotoSansKR-Medium' fontSize={15} color='#FFFFFF'>{data.price.toLocaleString('ko-KR')}원</Text>
          </ProductTextWrapper>
        </ProductInfoWrapper>
      </ProductInfoBox>
    </ProductBoxView>
  )
}

const ProductBoxView = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 220px;
  height: 264px;

  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.10);
  border-radius: 24px 24px 10px 10px;
  background-color: #FFFFFF;

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const DeleteButtonWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  width: 100%;
`;

const DeleteButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 38px;
  height: 30px;

  border-radius: 0px 15px 0px 15px;
  background-color: #CBCBCB;

  transition: all 0.3s ease-in-out;
`;

const DeleteImage = styled.img`
  width: 20px;
  height: 20px;
`;

const ProductImageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 198px;

  overflow: hidden;

  border-radius: 24px 24px 0px 0px;
`;

const ProductImage = styled.img`
  width: 100%;
  height: 100%;

  object-fit: ${props => props.isEmpty ? `contains` : `scale-down`};
  // object-fit: cover;
`;

const ProductInfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 66px;

  gap: 4px;

  border-radius: 0px 0px 10px 10px;
  background-color: #000000;
`;

const ProductInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 86.36%;
  height: 25px;
`;

const ProductTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  // justify-content: center;
  justify-content: flex-start;
  align-items: ${props => props.alignItems};

  width: ${props => props.width}%;
  height: 100%;

  overflow: hidden;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
`;