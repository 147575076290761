import { React } from 'react';
import styled from 'styled-components';

export default function BizMessageSendList(props) {
  const { data, onClickData } = props;

  return (
    <TopLevelWrapper>
      <Table>
        <Head>
          <HeadTr>
            <Td style={{ width: `134px`, minWidth: `134px`, padding: `0px` }}>이름</Td>
            <Td style={{ width: `444px`, minWidth: `444px`, padding: `0px` }}>공지 목록</Td>
            <Td style={{ width: `151px`, minWidth: `151px`, padding: `0px` }}>발송 명수</Td>
            <Td style={{ width: `144px`, minWidth: `144px`, padding: `0px` }}>발송 금액</Td>
            <Td style={{ width: `75px`, minWidth: `75px`, padding: `0px` }}>발송일</Td>
          </HeadTr>
        </Head>
      </Table>
      <Table>
        <Body>
          {
            data.map((data, index) => (
              <BodyTr key={index} onClick={() => onClickData(data)}>
                <Td style={{ width: `126px`, padding: `0`, margin: `0px 8px`, overflow: 'hidden' , whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
                  {data.name}
                </Td>
                <Td style={{ width: `444px`, padding: `0` }}>
                 {data.noticeTitle}
                </Td>
                <Td style={{ width: `151px`, padding: `0` }}>
                  {data.successCount}
                </Td>
                <Td style={{ width: `144px`, padding: `0`}}>
                  {(data.successCount * 10).toLocaleString('ko-KR')}
                </Td>
                <Td style={{ width: `75px`, padding: `0` }}>
                  {data.createdDate.split('T')[0][2]
                  + data.createdDate.split('T')[0][3]
                  + '.'
                  + data.createdDate.split('T')[0][5]
                  + data.createdDate.split('T')[0][6]
                  + '.'
                  + data.createdDate.split('T')[0][8]
                  + data.createdDate.split('T')[0][9]}
                </Td>
              </BodyTr>
            ))
          }
        </Body>
      </Table>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const Table = styled.table`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

`;
const Head = styled.thead`
  display: flex;
  flex-direction: column;
  
  justify-content: center;
  align-items: center;

  width: 100%;
`;

const Body = styled.tbody`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  // width: 992px;
  width: 100%;
  height: 352px;

  margin-top: 16px;

  overflow-y: scroll;
  overflow-x: none;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
		border: 3px solid transparent;
		// height: 92px;
		background-color: #DADADA;
		border-radius: 200px;
	}

  &::-webkit-scrollbar-track {
    // height: 439px;
		// border: 4px solid transparent;
		background-color: #F0F0F0;
		border-radius: 200px;
	}
`;

const HeadTr = styled.tr`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 32px;

  margin-top: 16px;
  padding-bottom: 16px;

  border-bottom: 1px solid #ECECEC;
  // border-radius: 8px;
  // background-color: #ECECEC;
`;

const BodyTr = styled.tr`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  background-color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #F4F2F0;
  }
`;

const Td = styled.td`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  height: 36px;

  // color: ${props => props.isRefund ? `#FF3131` : `#595959`};
  color: #595959;
`;