import { React, useEffect } from 'react';
import styled from 'styled-components';
import Lottie from 'lottie-react-web';

import NotReceivedList from './NotReceivedList';

import LoadingAnimation from '../../../Animation/LoadingAnimation.json';

const NotReceivedHistory = (props) => {
  const { 
    calcDateText, 
    data, 
    selectedPartnerGym,
    isOpenPartnerGymSales,
    isNotReceivedLoading, 
    setIsNotReceivedLoading 
  } = props;

  useEffect(() => {
    setTimeout(() => {
      setIsNotReceivedLoading(false)
    }, 1000)
  }, [data])

  return (
    <TopLevelWrapper>
      <InfoBox>
        <TitleWrapper>
          {/* <Title>{today.clone().format('YYYY년 MM월 미수금 내역')}</Title> */}
          <Title>{calcDateText() + ' 미수금 내역'}</Title>
        </TitleWrapper>
        {
          isNotReceivedLoading ?
            <AnimationBox>
              <LottieWrapper>
                <Lottie options={{ animationData: LoadingAnimation }} />
              </LottieWrapper>
            </AnimationBox>
            :
            <TotalInfoBox>
              {
                isOpenPartnerGymSales ?
                  <NotReceivedList data={data} selectedPartnerGym={selectedPartnerGym}/>
                  :
                  <NotOpenTextWrapper>
                    <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#444444' cursor='default'>이 업체는 파트너 체육관에 매출을 비공개 합니다</Text>
                    <Text fontFamily='NotoSansKR-Regular' fontSize={15} color='#444444' cursor='default'>링크존 설정에서 “파트너 체육관”에서 권한 설정해 주세요</Text>
                  </NotOpenTextWrapper>
              }
            </TotalInfoBox>
        }
      </InfoBox>
    </TopLevelWrapper>
  )
}

export default NotReceivedHistory;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 24px 0px 24px 0px;
  
  width: 1020px;
  // height: 544px;

  margin-top: 38px;
  margin-bottom: 38px;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  background-color: #FFFFFF;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-conetent: center;
  align-items: center;

  // width: 98%;
  width: 94.5%;
  // height: 544px;
`;

const AnimationBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  z-index: 5;
  
  margin-top: 24px;

  width: 100%;
  height: 439px;
  // height: 100%;

  // background-color: rgba(0, 0, 0, 0.2);
  background-color: #FFFFFF;
`;

const TotalInfoBox = styled.div`
  display: flex;
  flex-drection: row;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 437px;
`;

const NotOpenTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const LottieWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 80px;
  height: 80px;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
`;

const Title = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #232323;

  // margin: 28px 0 0 13px;
  margin: 0;
  padding: 0;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }
`;
