import { React, forwardRef, useRef, useImperativeHandle } from 'react';
import styled from 'styled-components';

import { BrowserView, MobileView } from 'react-device-detect';

const SignUpFirstBottomComponent = forwardRef((props, ref) => {
	const {onClickNextButton} = props;

	const TopLevelRef = useRef();

	useImperativeHandle(ref, () => ({
		setOpacity: (val) => {
			TopLevelRef.current.style.opacity = val;
			if (val === 0) TopLevelRef.current.style.zIndex = -1;
			else TopLevelRef.current.style.zIndex = 1;
		},
	}));

	return (
		<TopLevelWrapper ref={TopLevelRef}>
			{/* <BrowserView> */}
				<MoreDetailsTotalWrapper>
					<MoreDetailsWrapper>
						<MoreDetailsNormal>사용자의 동의 없이 정보를 이용하지 않습니다.</MoreDetailsNormal>
					</MoreDetailsWrapper>
					<MoreDetailsWrapper>
						<MoreDetailsNormal>링크매니저의</MoreDetailsNormal>
						<MoreDetailsLink>이용약관</MoreDetailsLink>
						<MoreDetailsNormal>및</MoreDetailsNormal>
						<MoreDetailsLink>개인정보 처리방침</MoreDetailsLink>
						<MoreDetailsNormal>에 동의합니다. </MoreDetailsNormal>
					</MoreDetailsWrapper>
				</MoreDetailsTotalWrapper>
				<LoginButton onClick={onClickNextButton}>다음</LoginButton>
			{/* </BrowserView>
			<MobileView>
				<MobileWrapper>
					<MoreDetailsTotalWrapper>
						<MoreDetailsWrapper>
							<MoreDetailsNormal>사용자의 동의 없이 정보를 이용하지 않습니다.</MoreDetailsNormal>
						</MoreDetailsWrapper>
						<MoreDetailsWrapper>
							<MoreDetailsNormal>링크매니저의</MoreDetailsNormal>
							<MoreDetailsLink>이용약관</MoreDetailsLink>
							<MoreDetailsNormal>및</MoreDetailsNormal>
							<MoreDetailsLink>개인정보 처리방침</MoreDetailsLink>
							<MoreDetailsNormal>에 동의합니다. </MoreDetailsNormal>
						</MoreDetailsWrapper>
					</MoreDetailsTotalWrapper>
					<LoginButton style={{marginTop: `29px`}} onClick={onClickNextButton}>다음</LoginButton>
				</MobileWrapper>
			</MobileView> */}
		</TopLevelWrapper> 
	)
})

export default SignUpFirstBottomComponent;

const TopLevelWrapper = styled.div`
	position: absolute;
	display: flex;
	flex-direction: row;

	align-items: center;

	// margin-top: -122px;
	margin-top: 514px;
	// margin-top: max(397px, 26.77083333333333vw);
	// margin-top: max(371px, 26.77083333333333vw);

	z-index: -1;
  transition: all 0.5s ease-in-out;
`;

const MobileWrapper = styled.div`
	display: flex;
	flex-direction: column;

	justify-content: flex-start;
	align-items: flex-end;
`;

const MoreDetailsTotalWrapper = styled.div`
	display: flex;
	flex-direction: column;

	// flex-wrap: wrap;

	// width: 150px; 274px
	// width: max(150px, 14.27083333333333vw);
`;

const MoreDetailsWrapper = styled.div`
	display: flex;
	flex-direction: row;

	// flex-wrap: wrap;

	// width: max(150px, 14.27083333333333vw);
`;

const MoreDetailsLink = styled.p`
	font-family: NotoSansKR-Regular;
	font-size: 12px;
	// font-size: max(10px, 0.625vw);
	// line-height: 17.38px;

	margin: 0 3px 0 3px;

	color: #FF8B48;

	:hover {
		cursor: pointer;
	}
`;

const MoreDetailsNormal = styled.p`
	font-family: NotoSansKR-Regular;
	font-size: 12px;
	// font-size: max(10px, 0.625vw);
	// line-height: 17.38px;

	margin: 0 0 0 0;

	color: rgba(119, 119, 119, 1);
`;

const LoginButton = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	font-family: NotoSansKR-Bold;
	fons-size: 14px;
	// font-size: max(12px, 0.7291666666666667vw);
	// font-size: max(10px, 0.7291666666666667vw);
	// line-height: 20px;

	width: 118px;
	// width: max(92px, 6.145833333333333vw);
	// width: max(61px, 6.145833333333333vw);
	height: 46px;
	// height: max(36px, 2.395833333333333vw);
	// height: max(25px, 2.395833333333333vw);
	
	margin: 0 0 0 57px;
	// margin-top: 0px;
	// margin-right: 0px;
	// margin-bottom: 0px;
	// margin-left: max(30px, 2.96875vw);

	border-radius: 23px;
	// border-radius: max(18px, 1.197916666666667vw);
	background-color: #FF8B48;
	color: #FFFFFF;

	cursor: default;
  transition: all 0.5s ease-in-out;

	:hover {
		cursor: pointer;
		background-color: #FF7322;
	}
`;
