import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

export default function SalesCalendar(props) {
  const { standardDate, yearSales, isOpenPartnerGymSales } = props;

  const [monthData, setMonthData] = useState([]);
  const [monthSalesPrice, setMonthSalesPrice] = useState(0);
  const [todaySalesPrice, setTodaySalesPrice] = useState(0);
  const [expectedCalculationPrice, setExpectedCalculationPrice] = useState(0);
  const [settlementPrice, setSettlementPrice] = useState(0);

  const firstWeek = standardDate.clone().startOf('month').week();
  const lastWeek = standardDate.clone().endOf('month').week() === 1 ? 53 : standardDate.clone().endOf('month').week();

  // 달력 만들기 //
  useEffect(() => {
    let newDateList = [];

    for (let week = firstWeek; week <= lastWeek; week++) {
      for (let i = 0; i < 7; i++) {
        let days = standardDate.clone().startOf('year').week(week).startOf('week').add(i, 'day');
        
        let newData = {
          date: days,
          calculatePrice: 0,
          // settlementPrice: 0,
          salesPrice: 0,
        }

        newDateList.push(newData);
      } 
    }

    for (let i = 0; i < newDateList.length; i++) {
      for (let j = 0; j < yearSales.length; j++) {
        if (newDateList[i].date.clone().format('YYYY.MM.DD') === moment(yearSales[j].approvalDate).format('YYYY.MM.DD')
          && !yearSales[j].isLinkPaySettlementPayment) {
          newDateList[i].salesPrice += (yearSales[j].cardPrice + yearSales[j].cashPrice);
        }
      }
    }
    
    let newMonthSalesPrice = 0;
    let newTodaySalesPrice = 0;
    let newSettlementPrice = 0;
    let newExpectedPrice = 0;
    
    for (let i = 0; i < yearSales.length; i++) {
      // let splitStr = yearSales[i].merchantUid === undefined ? '' : yearSales[i].merchantUid.split('-')[2];
      
      // 해당월 매출액 합산 //
      if (standardDate.clone().format('YYYY.MM') === moment(yearSales[i].approvalDate).format('YYYY.MM')) {
        if (yearSales[i].isLinkPaySettlementPayment) newSettlementPrice += yearSales[i].cardPrice;
        else newMonthSalesPrice += (yearSales[i].cardPrice + yearSales[i].cashPrice);
      }
      // 금일 매출액 합산 //
      if (moment().clone().format('YYYY.MM.DD') === moment(yearSales[i].approvalDate).format('YYYY.MM.DD')
          && !yearSales[i].isLinkPaySettlementPayment) newTodaySalesPrice += (yearSales[i].cardPrice + yearSales[i].cashPrice);
      
      // // 링크페이 합산 //
      // if (standardDate.clone().subtract('1', 'months').format('YYYY.MM') === moment(yearSales[i].approvalDate).format('YYYY.MM')
      //   && !yearSales[i].isLinkPaySettlementPayment
      //   && yearSales[i].merchantUid.split('-')[2] === '1') newExpectedPrice += yearSales[i].cardPrice;

      // 링크페이 합산 //
      if (moment(standardDate.clone().subtract('1', 'months').format('YYYY.MM.15')).isBefore(moment(yearSales[i].approvalDate))
        && moment(standardDate.clone().format('YYYY.MM.15')).isAfter(moment(yearSales[i].approvalDate))
        && !yearSales[i].isLinkPaySettlementPayment
        && yearSales[i].merchantUid.split('-')[2] === '1') {
          newExpectedPrice += yearSales[i].cardPrice;
        }
    }
  
    let linkPayFee = Math.floor(newExpectedPrice * 0.035);

    // console.log('expectedPrice : ' + newExpectedPrice);
    // console.log('linkPayFee : ' + linkPayFee);
    // console.log('final : ' + (newExpectedPrice - linkPayFee));
    setMonthSalesPrice(newMonthSalesPrice);
    setTodaySalesPrice(newTodaySalesPrice);
    // setDateList(newDateList);
    setMonthData(newDateList);
    setExpectedCalculationPrice(newExpectedPrice - linkPayFee);
    setSettlementPrice(newSettlementPrice);
  }, [standardDate, firstWeek, lastWeek, yearSales]) 

  // 요일에 따른 날짜 색상 변경 //
  function decideDateFontColor(date) {
    if (date.clone().format('ddd') === 'Sun') return '#D60000';
    else if (date.clone().format('ddd') === 'Sat') return '#1C2EC9';
    else return '#232323';
  }

  // 해당 월에 따른 날짜 Opacity 결정 //
  function decideDateOpacity(date) {
    if (date.clone().format('YYYY.MM') === standardDate.clone().format('YYYY.MM')) return 1;
    else return 0.2
  }

  // 매출 가격 표시 여부 결정 //
  function decideViewSalesPriceText(salesPrice) {
    if (salesPrice > 0) return true;
    else return false;
  }

  // 링크페이 정산된 가격 표시 여부 결정 //
  function decideViewSettlementPriceText() {
    if (settlementPrice > 0) return true;
    else return false;
  }

  // 링크페이 정산 예장 가격 표시 여부 결정 //
  function decideExpectedSalesPriceText() {
    if (expectedCalculationPrice > 0) return true;
    else return false;
  }

  return (
    <TopLevelWrapper>
      <CalendarBox>
        <TitleWrapper>
          <Text fontFamily='NotoSansKR-Bold' size={20} color='#232323' cursor='default'>{standardDate.format('YYYY년 MM월')}</Text>
        </TitleWrapper>
        <InfoComponentBoxWrapper>
          <InfoComponentBox>
            <Text fontFamily='NotoSansKR-Medium' size={14} color='#232323' cursor='default'>{standardDate.format('M')}월 매출액</Text>
            <Text fontFamily='NotoSansKR-Bold' size={17} color='#232323' cursor='default'>{monthSalesPrice.toLocaleString('ko-KR') + ' 원'}</Text>
          </InfoComponentBox>
          <InfoComponentBox>
            <Text fontFamily='NotoSansKR-Medium' size={14} color='#232323' cursor='default'>{standardDate.format('M')}월 입금 정산액</Text>
            <Text fontFamily='NotoSansKR-Bold' size={17} color='#232323' cursor='default'>{settlementPrice.toLocaleString('ko-KR') + ' 원'}</Text>
          </InfoComponentBox>
          <InfoComponentBox>
            <Text fontFamily='NotoSansKR-Medium' size={14} color='#232323' cursor='default'>오늘 매출액</Text>
            <Text fontFamily='NotoSansKR-Bold' size={17} color='#232323' cursor='default'>{todaySalesPrice.toLocaleString('ko-KR') + ' 원'}</Text>
          </InfoComponentBox>
          <InfoComponentBox>
            <Text fontFamily='NotoSansKR-Medium' size={14} color='#232323' cursor='default'>입금 예정 정산액</Text>
            <Text fontFamily='NotoSansKR-Bold' size={17} color='#232323' cursor='default'>{expectedCalculationPrice.toLocaleString('ko-KR') + ' 원'}</Text>
          </InfoComponentBox>
        </InfoComponentBoxWrapper>
        <TotalExplanationWrapper>
          <ExplanationWrapper>
            <ColorBox backgroundColor='#FF8B48'/>
            <Text fontFamily='NotoSansKR-Regular' size={14} color='#232323' cursor='default'>매출액</Text>
          </ExplanationWrapper>
          <ExplanationWrapper>
            <ColorBox backgroundColor='#6DD49E'/>
            <Text fontFamily='NotoSansKR-Regular' size={14} color='#232323' cursor='default'>링크페이 입금 정산액</Text>
          </ExplanationWrapper>
          <ExplanationWrapper>
            <ColorBox backgroundColor='#B5B5B5'/>
            <Text fontFamily='NotoSansKR-Regular' size={14} color='#232323' cursor='default'>입금 예정 정산액</Text>
          </ExplanationWrapper>
        </TotalExplanationWrapper>
        <WeekWrapper>
          <WeekBox>
            <Text fontFamily='NotoSansKR-Regular' size={14} color='#232323' cursor='default'>일</Text>
          </WeekBox>
          <WeekBox>
            <Text fontFamily='NotoSansKR-Regular' size={14} color='#232323' cursor='default'>월</Text>
          </WeekBox>
          <WeekBox>
            <Text fontFamily='NotoSansKR-Regular' size={14} color='#232323' cursor='default'>화</Text>
          </WeekBox>
          <WeekBox>
            <Text fontFamily='NotoSansKR-Regular' size={14} color='#232323' cursor='default'>수</Text>
          </WeekBox>
          <WeekBox>
            <Text fontFamily='NotoSansKR-Regular' size={14} color='#232323' cursor='default'>목</Text>
          </WeekBox>
          <WeekBox>
            <Text fontFamily='NotoSansKR-Regular' size={14} color='#232323' cursor='default'>금</Text>
          </WeekBox>
          <WeekBox>
            <Text fontFamily='NotoSansKR-Regular' size={14} color='#232323' cursor='default'>토</Text>
          </WeekBox>
        </WeekWrapper>
        <DateBoxWrapper isNotOpen={!isOpenPartnerGymSales}>
          {
            !isOpenPartnerGymSales && <NotOpenOpacityBox/>
          }
          {
            !isOpenPartnerGymSales &&
            <NotOpenBox>
              <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#444444' cursor='default'>이 업체는 파트너 체육관에 매출을 비공개 합니다</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={15} color='#444444' cursor='default'>링크존 설정에서 “파트너 체육관”에서 권한 설정해 주세요</Text>
            </NotOpenBox>
          }
          {
            monthData.map((data, index) => (
              <DateBox key={'calendar-' + index} opacity={decideDateOpacity(data.date)}>
                <DateWrapper style={{ marginBottom: '12px' }}>
                  <Text fontFamily='NotoSansKR-Regular' size={14} color={decideDateFontColor(data.date)} cursor='default'>{data.date.clone().format('D')}</Text>
                  { 
                    data.date.clone().format('YYYY.MM.DD') === moment().format('YYYY.MM.DD') && 
                    <TodayComponent>
                      <Text fontFamily='NotoSansKR-Medium' size={10} color='#FFFFFF' cursor='default'>오늘</Text>
                    </TodayComponent> 
                  }
                </DateWrapper>
                {
                  decideViewSalesPriceText(data.salesPrice) &&
                  <PriceWrapper>
                    <Text fontFamily='NotoSansKR-Regular' size={14} color='#FF8B48' cursor='default'>{data.salesPrice.toLocaleString('ko-KR')}</Text>
                  </PriceWrapper> 
                }
                {
                  (data.date.clone().format('D') === '15' && decideViewSettlementPriceText()) &&
                  <PriceWrapper>
                    <Text fontFamily='NotoSansKR-Regular' size={14} color='#6DD49E' cursor='default'>{data.date.clone().format('D') === '15' ? settlementPrice.toLocaleString('ko-KR') : 0}</Text>
                  </PriceWrapper>
                }
                {
                  (data.date.clone().format('D') === '15' && decideExpectedSalesPriceText()) &&
                  <PriceWrapper>
                    <Text fontFamily='NotoSansKR-Regular' size={14} color='#B5B5B5' cursor='default'>{data.date.clone().format('D') === '15' ? expectedCalculationPrice.toLocaleString('ko-KR') : 0}</Text>
                  </PriceWrapper>
                }
              </DateBox>
            ))
          }
        </DateBoxWrapper>
      </CalendarBox>
      <DivisionLine/>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const CalendarBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 28px 52px 28px 52px;
  margin-top: 32px;

  width: 916px;
  // height: 779px;
  min-height: 660px;

  border-radius: 30px;
  background-color: #FFFFFF;
  box-shadow: 0px -20px 30px 0px rgba(0, 0, 0, 0.02);
`;

const DivisionLine = styled.div`
  margin: 32px 0px 32px 0px;

  width: 100%;
  height: 1px;

  background-color: #E6E8EA;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
`;

const InfoComponentBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 30px;

  width: 100%;

  gap: 24px;
`;

const InfoComponentBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 226px;

  gap: 4px;
`;

const TotalExplanationWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 50px;

  width: 100%;

  gap: 16px;
`;

const ExplanationWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px
`;

const ColorBox = styled.div`
  width: 13px;
  height: 13px;

  border-radius: 13px;
  background-color: ${props => props.backgroundColor};
`;

const WeekWrapper = styled.div`
  display: flex;
  flex-direction: row;

  flex-direction: flex-start;
  align-items: flex-start;

  margin-top: 26px;

  width: 100%;

  gap: 8px;
`;

const WeekBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 131px;
`;

const DateBoxWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  flex-wrap: wrap;

  width: 100%;

  gap: 8px;
`;

const DateBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 24px;

  // width: 131px;
  min-width: 124px;
  // height: 70px;
  height: 86px;

  opacity: ${props => props.opacity};
`;

const NotOpenOpacityBox = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  opacity: 0.8;
  background-color: #FFFFFF;
`;

const NotOpenBox = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
`;

const DateWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 8px;
`;

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;
`;

const TodayComponent = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 31px;
  height: 17px;

  border-radius: 8px;
  background-color: #FF8B48;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.size}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }
`;