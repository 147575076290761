import { React } from 'react';
import styled from 'styled-components';

import NoticeModifyView from '../../Model/Individual/NoticeModel/NoticeModifyView';

function NoticeModifyPage({match}) {

  return(
    <Container>
      <NoticeModifyView noticeId={match.params.noticeId}/>
    </Container>
  )
}

export default NoticeModifyPage;

const Container = styled.div`
  display: flex;
  flex-direcion: column;

  width: 100%;
`;