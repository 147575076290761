import { React, useRef, useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';

import MemberShipCategorySelectBox from '../../../Component/MemberComponent/MemberShipCategorySelectBox.js';
import MemberShipSelectBox from '../../../Component/MemberComponent/MemberShipSelectBox.js';
import CalenderInput from '../../../Component/MemberComponent/CalenderInput.js';
import PaymentInput from '../../../Component/MemberComponent/PaymentInput.js';

import FetchModule from '../../Share/Network/FetchModule.js';
import PaymentModal from './PaymentModal.js';
import SessionStorage from '../../Share/Network/SessionStorage.js';
import LessonSelectBox from '../../../Component/MemberComponent/LessonSelectBox.js';
import MemberShipTotalMaxCountInput from '../../../Component/MemberComponent/MemberShipTotalMaxCountInput.js';
import DiscountPaymentInput from '../../../Component/MemberComponent/DiscountPaymentInput.js';
// import PaymentButton from '../../../Component/MemberComponent/PaymentButton.js';

import CheckImagePath from '../../../image/MiniCheck.png';
import LockerImagePath from '../../../image/ClickLocker.png';
import CenterTermsPreviewModal from './MemberDetail/Modal/CenterTermsPreviewModal.js';

const MemberShipInfoView = forwardRef((props, ref) => {
  const {
    terms,
    isFindUserId,
    registerClick,
    isTermsEmphasize,
    setIsTermsEmphasize,
    isClickedAddLocker,
    setIsClickedAddLocker
  } = props;

  const history = useHistory();

  const [isUsing, setIsUsing] = useState(false);
  const [lessonData, setLessonData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [memberShipData, setMemberShipData] = useState(false);
  const [divisionMemberShipData, setDivisionMemberShipData] = useState(false);
  const [maxCount, setMaxCount] = useState(0);
  const [validity, setValidity] = useState(0);
  const [validityUnit, setValidityUnit] = useState('day');
  const [totalPrice, setTotalPrice] = useState(0);
  const [notReceivedPrice, setNotReceivedPrice] = useState(0);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [approvalDate, setApprovalDate] = useState('');
  const [overflow, setOverflow] = useState('hidden');

  const [isClickedAgreementTerms, setIsClickedAgreementTerms] = useState(false);
  const [isViewTermsPrev, setIsViewTermsPrev] = useState(false);

  const CategorySelectRef = useRef();
  const TotalMaxRef = useRef();
  const LessonSelectRef = useRef();
  const MemberShipSelectRef = useRef();
  const CardPaymentRef = useRef();
  const CashPaymentRef = useRef();
  const DiscountPaymentRef = useRef();
  const StartCalenderRef = useRef();
  const EndCalenderRef = useRef();
  const ApprovalCalenderRef = useRef();

  useImperativeHandle(ref, () => ({
    getIsUsing: () => {
      return isUsing;
    },
    getMemberShipId: () => {
      return MemberShipSelectRef.current.getMemberShipId();
    },
    getMemberShipName: () => {
      return MemberShipSelectRef.current.getValue();
    },
    getStartDate: () => {
      // return StartCalenderRef.current.getValue();
      return startDate;
    },
    getEndDate: () => {
      // return EndCalenderRef.current.getValue();
      return endDate;
    },
    getMemberShipData: () => {
      return MemberShipSelectRef.current.getObjectVal();
    },
    getCardPrice: () => {
      return CardPaymentRef.current.getValue();
    },
    getCashPrice: () => {
      return CashPaymentRef.current.getValue();
    },
    getDiscountPrice: () => {
      return DiscountPaymentRef.current.getValue();
    },
    getNotReceivePrice: () => {
      // return (notReceivedPrice - totalPrice) > 0 ? 0 : (notReceivedPrice - totalPrice);
      return notReceivedPrice;
    },
    getApprovalDate: () => {
      return approvalDate;
    },
    getTotalMax: () => {
      return TotalMaxRef.current.getValue();
    },
    getAgreeCenterTerms: () => {
      return isClickedAgreementTerms;
    }
  }))

  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getDataVersion2('membership/', 'GET');
        // console.log(responseData.data.memberShips);
        if (responseData.status === 200) {
          setMemberShipData(responseData.data.memberShips);
          setCategoryData(responseData.data.categories);
        }
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }, [history])

  useEffect(() => {
    if (isUsing) {
      LessonSelectRef.current.setValue({ name: '선택' });
      MemberShipSelectRef.current.setValue({ name: '선택' });
      
      setStartDate('');
      setEndDate('');
      setApprovalDate('');
    }
    else {
      setStartDate(moment().format('YYYY.MM.DD'));
      setEndDate(moment().add(1, 'days').format('YYYY.MM.DD'));
      setApprovalDate(moment().format('YYYY.MM.DD'));
    }
  }, [isUsing])

  useEffect(() => {
    // if (startDate === '' || validity === 0) return;
    if (startDate === '') return;

    // setEndDate(moment(startDate, 'YYYY.MM.DD').add(validity, 'days').format('YYYY.MM.DD'));
    let division = 'days';

    if (validityUnit === 'year') division = 'year';
    else if (validityUnit === 'month') division = 'month';

    setEndDate(moment(startDate, 'YYYY.MM.DD').add(validity, division).subtract(1, 'days').format('YYYY.MM.DD'));
  }, [startDate, validity, validityUnit])

  useEffect(() => {
    if (isFindUserId) {
      setTimeout(() => {
        setOverflow('visible');
      }, 500)
    }
    else {
      setOverflow('hidden');
    }
  }, [isFindUserId])

  function changeValue() {
    var card = CardPaymentRef.current.getValue();
    var cash = CashPaymentRef.current.getValue();
    var discount = DiscountPaymentRef.current.getValue();

    setNotReceivedPrice((totalPrice - (card + cash + discount)) > 0 ? (totalPrice - (card + cash + discount)) : 0);
  }

  // // 수업 선택시 //
  // function changeLessonSelectVal(value) {

  //   var newDataList = [];

  //   for (var i = 0; i < memberShipData.length; i++) {
  //     if (memberShipData[i].lessonId === value.id) newDataList.push(memberShipData[i]);
  //   }

  //   setDivisionMemberShipData(newDataList);
  //   MemberShipSelectRef.current.setData(newDataList);
  //   MemberShipSelectRef.current.setValue({ name: '선택' });
  // }

  // 카테고리 선택시 //
  function changeCategorySelectVal(value) {
    var newDataList = [];

    for (var i = 0; i < memberShipData.length; i++) {
      if (memberShipData[i].categoryId === value.id) newDataList.push(memberShipData[i]);
    }

    setDivisionMemberShipData(newDataList);
    MemberShipSelectRef.current.setData(newDataList);

    if (newDataList.length === 0) MemberShipSelectRef.current.setValue({ id: -1, name: '선택' });
    else MemberShipSelectRef.current.setValue({ id: 0, name: '선택' });

    TotalMaxRef.current.setValue('');
    setTotalPrice(0);
    CardPaymentRef.current.setValue('');
    CashPaymentRef.current.setValue('');
    DiscountPaymentRef.current.setValue('');
  }

  // 회원권 변경시 //
  function changeMemberShipSelectVal(value) {
    setMaxCount(value.totalMax);
    TotalMaxRef.current.setValue(value.totalMax);
    setTotalPrice(value.price);
    // setValidity((value.validity - 1));
    // setValidity(value.validityUnit === 'day' ? value.validity - 1 : value.validity);
    setValidity(value.validity);
    setValidityUnit(value.validityUnit);
    // setValidity((value.validity === 1 ? value.validity : value.validity - 1));

    // console.log(value.validity - 1);

    // CardPaymentRef.current.setValue(value.price);
    CardPaymentRef.current.setValue('');
    CashPaymentRef.current.setValue('');
    DiscountPaymentRef.current.setValue('');

    setNotReceivedPrice(value.price);
  }

  function CalenderClick(className) {
    if (className === 'StartCalender') EndCalenderRef.current.closeCalender();
    else StartCalenderRef.current.closeCalender();
  }

  // 센터 개별약관 동의 버튼 클릭 //
  function onClickAgreementTerms() {
    if (isClickedAgreementTerms) setIsTermsEmphasize(true);
    else setIsTermsEmphasize(false);

    setIsClickedAgreementTerms(!isClickedAgreementTerms);
  }

  // 열람 및 동의하기 클릭시 //
  function onClickViewTermsPrev() {
    setIsViewTermsPrev(true);
    // alert(terms.termsUrl);
    // window.open(terms.termsUrl, '_blank', "popup");
  }

  return (
    <TopLevelWrapper isView={isFindUserId} overflow={overflow}>
      { isViewTermsPrev && <CenterTermsPreviewModal terms={terms} closeFn={() => setIsViewTermsPrev(false)} /> }
      <WhiteBox>
        <TitleWrapper>
          <MemberShipTitle>회원권 정보</MemberShipTitle>
          {
            terms.termsUrl !== '' &&
            <CheckBoxWrapper>
              <CheckBox img={CheckImagePath} isClicked={isClickedAgreementTerms} onClick={onClickAgreementTerms} />
              <CheckBoxTextWrapper>
                <Text fontFamily='NotoSansKR-Regular' fontSize={12} color={isTermsEmphasize ? '#FF3131' : '#777777'} cursor='default'>체육관 개별 약관</Text>
                <TermsModalButton onClick={onClickViewTermsPrev}>
                  <Text fontFamily='NotoSansKR-Regular' fontSize={12} color={isTermsEmphasize ? '#FF3131' : '#FF8B48'} cursor='pointer'>열람 및 동의하기</Text>
                </TermsModalButton>
              </CheckBoxTextWrapper>
            </CheckBoxWrapper>
          }
        </TitleWrapper>
        <InfoBox style={{ borderTop: '0px', marginTop: '0px' }}>
          <FirstLayerWrapper>
            <ComponentWrapper>
              <ComponentTitle>카테고리</ComponentTitle>
              <LessonSelectBox ref={CategorySelectRef} options={categoryData} disabled={isUsing} changeFn={changeCategorySelectVal} />
            </ComponentWrapper>
            <ComponentWrapper>
              <ComponentTitle>회원권</ComponentTitle>
              <MemberShipSelectBox ref={MemberShipSelectRef} disabled={isUsing} data={divisionMemberShipData} changeFn={changeMemberShipSelectVal} />
            </ComponentWrapper>
          </FirstLayerWrapper>
          <SecondLayerWrapper>
            <ComponentWrapper style={{ marginRight: `24px` }}>
              <ComponentTitle>회원권 시작일</ComponentTitle>
              <CalenderInput ref={StartCalenderRef} disabled={isUsing} value={startDate} setValue={setStartDate} className='StartCalender' clickFn={CalenderClick} placeholder='시작일' width={280} />
            </ComponentWrapper>
            <ComponentWrapper style={{ marginRight: `26px` }}>
              <ComponentTitle>회원권 종료일</ComponentTitle>
              <CalenderInput ref={EndCalenderRef} disabled={isUsing} value={endDate} setValue={setEndDate} className='EndCalender' clickFn={CalenderClick} placeholder='종료일' width={280} />
            </ComponentWrapper>
            <ComponentWrapper style={{ marginRight: `16px` }}>
              <ComponentTitle>회원권 총 일수</ComponentTitle>
              <TotalMemberShipDateWrapper>
                <TotalDateBox>
                  <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#CBCBCB' cursor='default'>{moment(endDate).diff(moment(startDate), 'days') + 1}</Text>
                </TotalDateBox>
                <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#777777' cursor='default'>일</Text>
              </TotalMemberShipDateWrapper>
            </ComponentWrapper>
            <ComponentWrapper>
              <MemberShipTotalMaxCountInput ref={TotalMaxRef} noLimitCount={maxCount === -1} explanation='최대 수강 횟수' />
            </ComponentWrapper>
          </SecondLayerWrapper>
        </InfoBox>
        <TitleWrapper style={{ marginTop: `36px` }}>
          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#232323' cursor='default'>결제 정보</Text>
        </TitleWrapper>
        <InfoBox>
          <SecondLayerWrapper style={{ marginTop: '8px' }}>
            <ComponentWrapper style={{ marginRight: `60px` }}>
              <ComponentTitle>결제일</ComponentTitle>
              <CalenderInput ref={ApprovalCalenderRef} disabled={isUsing} value={approvalDate} setValue={setApprovalDate} className='PaymentCalender' clickFn={CalenderClick} placeholder='결제일' width={234} />
            </ComponentWrapper>
            <ComponentWrapper style={{ marginRight: `27px` }}>
              <ComponentTitle>회원권 가격</ComponentTitle>
              <CalcPaymentInputWrapper>
                <CalcPaymentInput>{totalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</CalcPaymentInput>
                <CalcPaymentUnit>원</CalcPaymentUnit>
              </CalcPaymentInputWrapper>
            </ComponentWrapper>
            <ComponentWrapper style={{ marginRight: `27px` }}>
              <ComponentTitle>카드 결제 금액</ComponentTitle>
              <PaymentInput ref={CardPaymentRef} changeFn={changeValue} width={77} placeholder={0} disabled={isUsing} />
            </ComponentWrapper>
            <ComponentWrapper style={{ marginRight: `27px` }}>
              <ComponentTitle>현금 결제 금액</ComponentTitle>
              <PaymentInput ref={CashPaymentRef} changeFn={changeValue} width={77} placeholder={0} disabled={isUsing} />
            </ComponentWrapper>
            <ComponentWrapper style={{ marginRight: `27px` }}>
              <ComponentTitle>할인 금액</ComponentTitle>
              <DiscountPaymentInput ref={DiscountPaymentRef} changeFn={changeValue} width={77} placeholder={0} disabled={isUsing} />
            </ComponentWrapper>
            <ComponentWrapper>
              <ComponentTitle>미수금 금액</ComponentTitle>
              <CalcPaymentInputWrapper>
                <NotReceivedPaymentInput>{notReceivedPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</NotReceivedPaymentInput>
                <CalcPaymentUnit>원</CalcPaymentUnit>
              </CalcPaymentInputWrapper>
            </ComponentWrapper>
          </SecondLayerWrapper>
          {/* <ButtonWrapper>
          <SecondSaveButton onClick={() => registerClick()}>등록하기</SecondSaveButton>
        </ButtonWrapper> */}
        </InfoBox>
      </WhiteBox>
      <LockerAddButton isClicked={isClickedAddLocker} onClick={() => setIsClickedAddLocker(true)}>
        <LockerImage src={LockerImagePath}/>
        <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='pointer'>락커 정보 추가</Text>
      </LockerAddButton>
    </TopLevelWrapper>
  )
})

export default MemberShipInfoView;

const TopLevelWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 1020px;
  height: ${props => props.isView ? `391px` : `0px`};

  // overflow: hidden;
  overflow: ${props => props.overflow};

  // box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);

  margin-top: 16px;
  
  transition: all 0.3s ease-in-out;
`;

const WhiteBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: 100%;
  height: 360px;

  border-radius: 10px;
  background-color: #FFFFFF;
  // box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  z-index: 5;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  
  justify-content: space-between;
  align-items: center;

  margin-left: 28px;

  // width: 964px;
  width: 958px;
`;

const MemberShipTitle = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;

  border-top: 2px solid #ECECEC;

  // margin-top: 24px;
  // margin-left: 28px;
  margin-top: 8px;
  margin-left: 28px;
`;

const FirstLayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  gap: 24px;
  margin-top: 24px;
`;

const SecondLayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  // gap: 24px;
  // margin-top: 24px;
  margin-top: 16px;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ComponentTitle = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #595959;

  margin: 0 0 0 0;
`;

const TotalMemberShipDateWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 8px;
  gap: 8px;
`;

const TotalDateBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 53px;
  height: 30px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;

  padding: 0 8px 0 8px;
`;

const CalcPaymentInputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
  margin-top: 8px;
`;

const CalcPaymentInput = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  padding: 0 8px 0 8px;
  margin: 0 0 0 0;

  width: 77px;
  height: 30px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background-color: #ECECEC;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #FFFFFF;
`;

const CalcPaymentUnit = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  margin: 0 0 0 0;

  color: #777777;
`;

const LockerAddButton = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: flex-end;

  padding-bottom: 6px;
  margin-top: -26px;

  gap: 8px;

  width: 126px;
  height: ${props => props.isClicked ? `0px` : `51px`};

  z-index: 4;

  border-radius: 8px;
  background-color: #FF8B48;

  :hover {
    cursor: pointer;
  }
  
  transition: all 0.3s ease-in-out;
`;

const LockerImage = styled.img`
  width: 18px;
  height: 18px;

  object-fit: cover;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: flex-end;

  margin-top: 40px;
  gap: 15px;

  width: 100%;
`;

const PaymentButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 150px;
  height: 32px;

  border-radius: 8px;
  background-color: #33C8FF;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  margin: 0 0 0 0;

  opacity: 0;

  :hover {
    cursor: default;
  }
`;

const SecondSaveButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 15px;
  background-color: #5BD98A;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  margin: 0 0 0 0;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }
`;

const CheckBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const CheckBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 14px;
  height: 14px;

  border: 1px solid #DFDFDF;
  border-radius: 4px;

  background: url(${props => props.img}) no-repeat center center;
  background-color: ${props => props.isClicked ? `#FF8B48` : `#FFFFFF`};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const CheckBoxTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: -3px;

  gap: 4px;
`;

const TermsModalButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  :hover {
    cursor: pointer;
  }
`;

const FixedInputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const FixedInput = styled.div`
  display: flex;
  flex-direction: column;

  width: 320px;
  height: 30px;

  justify-content: center;
  align-items: flex-start;

  margin: 8px 0 0 0;
  padding: 0 0 0 8px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background-color: #ECECEC;
  
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;
`;

const FixedUnit = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  margin: 0 0 0 8px;

  color: #777777;
`;

const NotReceivedPaymentInput = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  padding: 0 8px 0 8px;
  margin: 0 0 0 0;

  width: 77px;
  height: 30px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background-color: #FFFFFF;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #FF3131;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  margin: 0 0 0 0;

  color: ${props => props.color};

  :hover {
    cursor: ${props => props.cursor};
  }
`;