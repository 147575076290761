import React, { useRef } from 'react';
import styled from 'styled-components';
import moment from 'moment';

import WhiteTrashIcon from '../../../../image/WhiteTrashIcon.png';
import WhitePencelIcon from '../../../../image/WhitePencelIcon.png';

export default function PartnerGymHoldingAndExtendHistoryInfoView(props) {
  const { 
    data,
    onClickHoldingAndExtendButton, 
    onClickHoldingEdit,
    onClickDeleteHoldingHistory,
    onClickModifyExtend,
    onClickDeleteExtend 
  } = props;

  const ComponentRef = useRef();
  const ModifyRef = useRef();
  const DeleteRef = useRef();

  // 홀딩 상태 여부 또는 연장인지 표시 결정 //
  function calcDataStatus() {
    if (data.status === '연장 추가') return data.status;
    else {
      // moment(data.startDate).format('YYYY.MM.DD') > moment().format('YYYY.MM.DD') ? '홀딩 예정' : moment(data.endDate).format('YYYY.MM.DD HH:mm:ss') > moment().format('YYYY.MM.DD HH:mm:ss') ? '홀딩 진행중' : '홀딩 종료'
      if (moment(data.startDate).isAfter(moment())) return '홀딩 예정';
      else if (moment(data.endDate).isAfter(moment())) return '홀딩 진행중';
      else return '홀딩 종료';
    }
  }

  // 이행 날짜 계산 후 표출 //
  function calcDate() {
    return moment(data.startDate).format('YY.MM.DD') + " - " + moment(data.endDate).format('YY.MM.DD')
  }

  // 이행 일수 계산 후 표출 //
  function calcDays() {
    return (moment(data.endDate).diff(data.startDate, 'day') + 1) + '일';
  }

  // 폰트 색상 결정 //
  function calcFontColor() {
    if (data.status === '연장 추가') return '#000000';
    else if (moment(data.endDate).isAfter(moment())) return '#000000';
    else return '#FFFFFF';
  }

  // 배경색 결정 //
  function calcBackgroundColor() {
    if (data.status !== '연장 추가' && moment(data.endDate).isBefore()) return '#CACACA';
    else return '#E2E2E2';
  }

  // 컴포넌트 마우스 호버링 //
  function onMouseOver() {
    // ComponentRef.current.style.backgroundColor = '#CACACA';
    ModifyRef.current.style.opacity = 1;
    DeleteRef.current.style.opacity = 1;
  }

  // 컴포넌트 마우스 호버링 //
  function onMouseLeave() {
    // ComponentRef.current.style.backgroundColor = '#E2E2E2';
    ModifyRef.current.style.opacity = 0;
    DeleteRef.current.style.opacity = 0;
  }

  // 컴포넌트 클릭시 //
  function onClickComponent(e) {
    if (ModifyRef.current.contains(e.target) || DeleteRef.current.contains(e.target)) return;
    
    onClickHoldingAndExtendButton(data);
  }

  // 수정버튼 클릭시 //
  function onClickModify() {
    if (data.status === '연장 추가') onClickModifyExtend(data);
    else onClickHoldingEdit(data);
  }

  // 삭제버튼 클릭시 //
  function onClickDelete() {
    if (data.status === '연장 추가') onClickDeleteExtend(data);
    else onClickDeleteHoldingHistory(data);
  }

  return (
    <TopLevelWrapper>
      <ComponentBox 
        ref={ComponentRef}
        backgroundColor={calcBackgroundColor()}>
        <TotalTextWrapper>
          <TextWrapper width={79}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color={calcFontColor()} cursor='pointer'>{calcDataStatus()}</Text>
          </TextWrapper>
          <TextWrapper width={147}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color={calcFontColor()} cursor='pointer'>{calcDate()}</Text>
          </TextWrapper>
          <TextWrapper width={74}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color={calcFontColor()} cursor='pointer'>{calcDays()}</Text>
          </TextWrapper>
          <TextWrapper width={476}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color={calcFontColor()} cursor='pointer'>{data.reason}</Text>
          </TextWrapper>
          {/* <TotalIconWrapper>
            <IconWrapper ref={ModifyRef} onClick={onClickModify}>
              <Icon src={WhitePencelIcon}/>
            </IconWrapper>
            <IconWrapper ref={DeleteRef} onClick={onClickDelete}>
              <Icon src={WhiteTrashIcon}/>
            </IconWrapper>
          </TotalIconWrapper> */}
        </TotalTextWrapper>
      </ComponentBox>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: flex-start;

  width: 100%;
`;

const ComponentBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: -35px;

  width: 88.62%;
  height: 87px;
  
  border-radius: 0px 0px 12px 12px;
  box-shadow: 0px 1px 30px rgba(0, 0, 0, 0.1);
  // background-color: #E2E2E2;
  background-color: ${props => props.backgroundColor};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const TotalTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  margin-left: 16px;

  margin-top: 50px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  margin-right: 8px;

  width: ${props => props.width}px;
  height: 20px;

  overflow: hidden;
`;

const TotalIconWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 12px;
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 24px;
  height: 24px;

  opacity: 0;

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const Icon = styled.img`
  width: 100%;
  height: 100%;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }
`;