import React from "react";
import styled from "styled-components";

// import UserProfile from "../../Component/Normal/UserProfile";

import DefaultLogoPath from '../../image/DefaultLogo.png';
import UserProfile from "../../Component/Normal/UserProfile";

export default function ViewOptionBox(props) {
  const { index, data, onClickViewState } = props;

  return (
    <TopLevelWrapper>
      <LogoWrapper>
        <UserProfile profile={data.storeLogo} defaultProfile={DefaultLogoPath} />
      </LogoWrapper>

      <SlideOutBox isClicked={data.isView} onClick={() => onClickViewState(index)}>
        <SlideInBox isClicked={data.isView} />
      </SlideOutBox>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding: 0px 4px 0px 0px;

  width: 111px;
  min-width: 111px;
  height: 38px;

  overflow: hidden;

  border-radius: 10px;
  background-color: #FFFFFF;
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 38px;
  height: 38px;

  border-radius: 10px;
  overflow: hidden;
`;

const SlideOutBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 58px;
  height: 28px;

  border: ${props => props.isClicked ? '1px solid #FF8B48' : '1px solid #CBCBCB'};
  border-radius: 5px;
  background-color: ${props => props.isClicked ? '#FF8B48' : '#FFFFFF'};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const SlideInBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-left: ${props => props.isClicked ? '32px' : '4px'};
  width: 22px;
  height: 22px;

  border-radius: 6px;
  background-color: ${props => props.isClicked ? '#FFFFFF' : '#CBCBCB'};
  // background-color: #FFFFFF;

  transition: all 0.3s ease-in-out;
`;
