import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import MemberReservationModal from '../../Model/Individual/ClassManageModel/ClassManageModal/MemberReservationModal';
import NewMemberReservationModal from '../../Model/Individual/ClassManageModel/ClassManageModal/NewMemberReservationModal';
import UserProfile from '../Normal/UserProfile';
import SelectReserveAndQueueModal from '../../Model/Individual/ClassManageModel/ClassManageModal/SelectReserveAndQueueModal';

function ClassInfoBox (props) {
  const { data, reserveData, reserveRendering, setReserveRendering } = props;

  const [isClickedReserve, setIsClickedReserve] = useState(false);
  const [count, setCount] = useState(0);

  const InfoBoxRef = useRef();

  useEffect(() => {
    for (let i = 0; i < reserveData.length; i++) {
      if (data.scheduleId === reserveData[i].scheduleId) {
        setCount(reserveData[i].count);
        break;
      }
    }
  }, [data, reserveData])

	function onMouseOver() {
		if (data.classId === -1) return;

		InfoBoxRef.current.style.border = `1px solid ` + data.color;
	}

	function onMouseLeave() {
		if (data.classId === -1) return;
		
		InfoBoxRef.current.style.border = `1px solid #ECECEC`;
	}

  function onClickClassData() {
    if (isClickedReserve) return;

    // 브레이크 타임일 경우 //
    if (data.classId === -1) return;
    // console.log(data);
    setIsClickedReserve(true);
  }

  return (
    // <TopLevelWrapper  onClick={() => (!isClickedReserve && setIsClickedReserve(true))} onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
    <TopLevelWrapper  onClick={onClickClassData} onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
      {/* { isClickedReserve && <MemberReservationModal data={reserveData} scheduleData={data} reserveDataReLoad={reserveRendering} setReserveDataReLoad={setReserveRendering} cancel={() => setIsClickedReserve(false)}/> } */}
      {/* { isSelectedReserveModal && <SelectReserveAndQueueModal/> } */}
      { isClickedReserve && <NewMemberReservationModal data={reserveData} scheduleData={data} reserveDataReLoad={reserveRendering} setReserveDataReLoad={setReserveRendering} cancel={() => setIsClickedReserve(false)}/> }
      <InfoBox ref={InfoBoxRef}>
        <ClassNameWrapper>
          <ClassName>{data.className}</ClassName>
        </ClassNameWrapper>
        {
          data.classId === -1 ?
            <InfoWrapper>
              <TimeWrapper>
                <Time>{data.startTime} ~ {data.endTime}</Time>
              </TimeWrapper>
            </InfoWrapper>
            :
            <InfoWrapper>
              <TimeWrapper>
                <Time>{data.startTime} ~ {data.endTime}</Time>
                <TimeDivision />
                {/* <Number>예약 {reserveData && reserveData.length}명</Number> */}
                {/* <Number>예약 {reserveData.count}명</Number> */}
                <Number>예약 {count}명</Number>
              </TimeWrapper>
              <TrainerWrapper>
                {/* <TrainerImage src={data.profile === '' ? 'https://picture.link-zone.org/ZoneLogo.png' : data.profile} /> */}
                <TrainerImage>
                  <UserProfile profile={data.profile} defaultProfile='https://picture.link-zone.org/ZoneLogo.png'/>
                </TrainerImage>
                <TrainerName>{data.teacherName}</TrainerName>
              </TrainerWrapper>
            </InfoWrapper>
        }
      </InfoBox>
    </TopLevelWrapper>
  )
}

export default React.memo(ClassInfoBox);

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin: 0 0 0 0;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;

  width: 470px;
  height: 66px;

  border: 1px solid #ECECEC;
  border-radius: 15px;

  padding-left: 20px;
  margin-bottom: 12px;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const ClassNameWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const ClassName = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;
`;

const TimeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Time = styled.p`
  font-family: Poppins-Regular;
  font-size: 12px;

  color: #777777;

  margin: 0 8px 0 0;
`;

const TimeDivision = styled.div`
  height: 10px;
  border-right: 1px solid #969696;
`;

const Number = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #777777;

  margin: -3px 0 0 8px;
`;

const TrainerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  
  // margin-left: 148px;
  margin-right: 16px;
`;

// const TrainerImage = styled.img`
//   width: 20px;
//   height: 20px;

//   border-radius: 20px;
  
//   margin-right: 8px;

//   object-fit: cover;
// `;

const TrainerImage = styled.div`
  width: 20px;
  height: 20px;

  border-radius: 20px;
  
  margin-right: 8px;

  object-fit: cover;

  overflow: hidden;
`;

const TrainerName = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #595959;

  margin: 0 0 0 0;
`;