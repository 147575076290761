import { React, useState, useRef, forwardRef, useImperativeHandle, useEffect } from 'react';
import styled from 'styled-components';

import SearchImgPath from '../../../image/SearchIcon.png';
import SearchExitImgPath from '../../../image/MemberShipInfoSearchExitButton.png';
import SearchInputCheck from '../../../image/MemberShipSearchInputCheck.png';

const MemberShipSearchInput = forwardRef((props, ref) => {
	const { className, placeholder, type, clickCountFn, totalIsClicked, memberList, memberData } = props;

  // const [options, setOptions] = useState(memberData);

	const [value, setValue] = useState('');
  const [originalDates, setOriginalDates] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [data, setData] = useState(memberList);
	const [changed, setChanged] = useState(false);
	const [changedPlaceholderColor, setChangedPlaceholderColor] = useState(false);
	const [clickCount, setClickCount] = useState(0);
	const [defaultPlaceholder, setDefaultPlaceholder] = useState(placeholder);

	const [isClicked, setIsClicked] = useState(false);
  
  const TopLevelRef = useRef();
	const inputRef = useRef();
	const SelectRef = useRef();

	useImperativeHandle(ref, () => ({
		setValue: (val) => {
			setValue(val);
			setChanged(true);
		},
		getValue: () => {
			if (changed)
				return value;
			else
				return "";
		},
    getDataList: () => {
      return dataList;
    },
		plusClickCount: () => {
			setClickCount(clickCount + 1)
		},
		getClickCount: () => {
			return clickCount;
		},
		setDefaultPlaceholder: (val) => {
			setDefaultPlaceholder(val)
			setChangedPlaceholderColor(true);
			inputRef.current.style.borderColor = '#FF3131';
		},
		reset: (val) => {
			setClickCount(0);
			setDefaultPlaceholder(val)
			inputRef.current.style.borderColor = '#E8E8E8';
		}
	}));

  // // 모달창 뜰시 스크롤 제어 //
  // useEffect(() => {
  //   document.body.style.cssText = `position: fixed; top: -${window.scrollY}px`

  //   return () => {
  //     const scrollY = document.body.style.top;
  //     document.body.style.cssText = `position: ""; top: "";`
  //     window.scrollTo(0, parseInt(scrollY || '0') * -1)
  //   }
  // }, [])
  
  // MemberList 정리 //
  useEffect(() => {
    let tmpList = memberList.slice();
    let nameList = [];
    let phoneList = [];
    let finalList = [];

    for (let i = 0; i < tmpList.length; i++) {
      if (tmpList[i].name.toLowerCase().includes(value.toLowerCase())) nameList.push(tmpList[i]);
      else if (tmpList[i].phone.includes(value)) phoneList.push(tmpList[i]);
    }

    if (nameList.length === 0 && phoneList.length === 0) {
      for (let i = 0; i < tmpList.length; i++) {
        if (match(value.toLowerCase(), tmpList[i].name.toLowerCase())) nameList.push(tmpList[i]);
      }
    }

    finalList = nameList.concat(phoneList);

    if (finalList.length === 0) {
      for (let i = 0; i < tmpList.length; i++) {
        // 유효회원 목록에 연장 대상 회원이 존재할 경우 //
        if (tmpList[i].memberId === memberData.memberId) {
          tmpList[i] = Object.assign(tmpList[i], {isChecked: true});
          
          let newValue = [];
  
          newValue.push(tmpList[i]);
          setDataList(newValue);
        }
        else {
          tmpList[i] = Object.assign(tmpList[i], {isChecked: false})
        }
      }

      setData(tmpList);
    }
    else {
      for (let i = 0; i < finalList.length; i++) {
        // 유효회원 목록에 연장 대상 회원이 존재할 경우 //
        if (finalList[i].memberId === memberData.memberId) {
          finalList[i] = Object.assign(finalList[i], {isChecked: true});

          let newValue = [];
  
          newValue.push(finalList[i]);
          setDataList(newValue);
        }
        else {
          finalList[i] = Object.assign(finalList[i], {isChecked: false})
        }
      }

      setData(finalList);
    }
  }, [memberList, memberData, value])

  // 컴포넌트 밖을 클릭하면 셀렉트 창 닫기
  useEffect(() => {
    document.addEventListener("mousedown", onClickOutSide);

    return () => {
      document.removeEventListener("mousedown", onClickOutSide);
    }
  }, [])

  // 컴포넌트 밖을 클릭하면 셀렉트 창 닫기
  function onClickOutSide(e) {
    if (TopLevelRef.current !== e.target && !TopLevelRef.current.contains(e.target)) {
      // if(SelectRef.current.clientHeight !== 30) setDefaultVal({name: '전체'});
      SelectRef.current.style.border = '0px solid #CBCBCB';
      SelectRef.current.style.height = `0px`;
      SelectRef.current.style.overflow = 'hidden';
      setIsClicked(false);
    }
  }

	function handleClick(e) {
		inputRef.current.style.borderColor = '#FF8B48';

    if (isClicked) {
			SelectRef.current.style.border = '0px solid #CBCBCB';
			SelectRef.current.style.height = `0px`;
			SelectRef.current.style.overflow = 'hidden';
			setIsClicked(false);
		}
		else {
			SelectRef.current.style.border = '1px solid #FF8B48';
			SelectRef.current.style.height = `146px`;
			SelectRef.current.style.overflowY = 'scroll';
			SelectRef.current.style.overflowX = 'none';
			setIsClicked(true);
		}
	}

	function handleChange(e) {
		setValue(e.target.value);
		setChanged(true);
	}

	function handleFocus(e) {
		setChangedPlaceholderColor(false);
		if (changed) {
			// do nothing
		} else {
			setValue("");
		}
	}

	function handleBlur(e) {
		setClickCount(clickCount + 1);
		inputRef.current.style.borderColor = '#E8E8E8';
	}
  
	function optionalClick(index) {
    var newValue = dataList.slice();
    var newData = data.slice();

    // 이미 선택되어있을 경우 //
    if (newData[index].isChecked) {
      for (var i = 0; i < newValue.length; i++) {
        // memberData 찾아서 삭제 //
        if (newValue[i].memberId === newData[index].memberId){
          newValue.splice(i, 1);
          newData[index].isChecked = false;
          break;
        }
      }
    }
    // 아닐 경우 데이터 추가 //
    else {
      newData[index].isChecked = true;
      newValue.push(data[index]);
    }

    setDataList(newValue);
    setData(newData);
	}

  // 연장회원 목록에 삭제버튼 누를시 //
  function onClickDeleteButton(index) {
    var newValue = dataList.slice();

    newValue.splice(index, 1);

    setDataList(newValue);
  }


  // 입력된 글자가 한글인지 확인 //
  function is_kr(value) {
    if (value === undefined) return false;
    
    return ((('ㄱ'.charCodeAt() <= value.charCodeAt()) && (value.charCodeAt() <= 'ㅎ'.charCodeAt()))
            || (('가'.charCodeAt() <= value.charCodeAt()) && value.charCodeAt() <= '힣'.charCodeAt()));
  }

  // 한글 글자 유니코드 추출하여 검색 조건 확인 //
  function kr_num(value) {
    return value.charCodeAt() - '가'.charCodeAt();
  }

  // 초성 중성 종성 구분하여 연산 //
  function kr_list(value) {
    var consonant_list = ['ㄱ', 'ㄲ', 'ㄴ', 'ㄷ', 'ㄸ', 'ㄹ', 'ㅁ', 'ㅂ', 'ㅃ', 'ㅅ'
                        , 'ㅆ', 'ㅇ', 'ㅈ', 'ㅉ', 'ㅊ', 'ㅋ', 'ㅌ', 'ㅍ', 'ㅎ'];

    var res = [value];

    var kr_num_s = kr_num(value);

    if (kr_num_s > 0) {
      // 초성 + 중성일 경우 //
      if (kr_num_s % 28 !== 0) {
        res.push(String.fromCharCode(((parseInt(kr_num_s / 28) * 28) + '가'.charCodeAt())));
      }
      
      res.push(consonant_list[parseInt(kr_num_s / 588)])
    }

    return res;
  }

  // 한글, 영어 구분하여 검색 처리 //
  function eq_kr(value, data) {
    if (is_kr(value) && is_kr(data)) {
      return kr_list(data).includes(value);
    }
    else return value === data;
  }

  // 받침 확인하기 //
  function eq_kr_pos(value, data, nextData) {
    var consonant_list = ['ㄱ', 'ㄲ', 'ㄴ', 'ㄷ', 'ㄸ', 'ㄹ', 'ㅁ', 'ㅂ', 'ㅃ', 'ㅅ'
                        , 'ㅆ', 'ㅇ', 'ㅈ', 'ㅉ', 'ㅊ', 'ㅋ', 'ㅌ', 'ㅍ', 'ㅎ'];

    var kr_pos = [
      [0, 0], [1, 0], [2, 1], [2, 9], [4, 2], [1, 12], [2, 18],
      [7, 3], [8, 5], [1, 0], [2, 6], [3, 7], [4, 9], [5, 16],
      [6, 17], [7, 18], [16, 6], [17, 7], [1, 9], [19, 9], [20, 10],
      [21, 11], [22, 12], [23, 14], [24, 15], [25, 16], [26, 17], [27, 18]
    ]

    if (!(is_kr(value) && is_kr(data) && is_kr(nextData))) return false;
    if (kr_num(value) < 0) return false;
    
    var s_pos = String.fromCharCode(value.charCodeAt() - kr_pos[kr_num(value) % 28][0])
    var s_pos_consonant = consonant_list[kr_pos[kr_num(value) % 28][1]]

    return (s_pos === data) && (kr_list(nextData).includes(s_pos_consonant));
  }

  // 일치하는 글자가 존재하는지 확인 //
  function match(value, data) {
    if (value === undefined || data === undefined) return false;

    var valueSize = value.length;
    var dataSize = data.length;

    if (dataSize < valueSize) return false;

    for (var i = 0; i < (dataSize - valueSize + 1); i++) {
      var res = true;

      for (var j = 0; j < valueSize; j++) {
        if (j === (valueSize - 1)) {
          if (!eq_kr(value[j], data[i + j])) {
            if (i + j + 1 < dataSize) {
              if (eq_kr_pos(value[j], data[i + j], data[i + j + 1])) {
                break;
              }
            }
            res = false;
            break;
          }
        }
        else {
          if (value[j] !== data[i + j]) {
            res = false;
            break;
          }
        }
      }

      if (res) return true;
    }
    
    return false;
  }

  return (
    <TopLevelWrapper>
      <Text>연장회원 추가</Text>
      <SearchWrapper>
      <InputWrapper ref={TopLevelRef}>
        <Input
          ref={inputRef}
          img={SearchImgPath}
          clickCount={clickCount}
          type={type}
          placeholder={defaultPlaceholder}
          value={'' || value}
          changedColor={changedPlaceholderColor}
          className={`${className} ${changed ? "changed" : ""}`}
          onClick={handleClick}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur} />
          <SelectBox ref={SelectRef}>
            {
              data.map((data, index) => (
                <OptionWrapper key={index} onClick={() => optionalClick(index)}>
                  <Option isComprise={data.isChecked}>{data.name} ({data.phone})</Option>
                  <CheckImg src={SearchInputCheck} opacity={data.isChecked ? 1 : 0}/>
                </OptionWrapper>
              ))
            }
          </SelectBox>
          </InputWrapper>
          {
            totalIsClicked ?
            <ApplyAllInfoWrapper>
              <ApplyAllText>유효회원 전체 적용</ApplyAllText>
            </ApplyAllInfoWrapper>
            :
            <InfoWrapper>
            {
              dataList.map((data, index) => (
                (match(value, data) || value === '') &&
                <InfoBox key={index}>
                  <Table>
                    <Body>
                      <Tr>
                        <Td>
                          <TextWrapper style={{ width: `57px` }}>
                            <Name>{data.name}</Name>
                          </TextWrapper>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          <TextWrapper style={{ width: `83px` }}>
                            <Phone>{data.phone}</Phone>
                          </TextWrapper>
                        </Td>
                      </Tr>
                    </Body>
                  </Table>
                  <ExitButton src={SearchExitImgPath} onClick={onClickDeleteButton}/>
                </InfoBox>
              ))
            }
          </InfoWrapper>
          }
      </SearchWrapper>
    </TopLevelWrapper>
  )
})

export default MemberShipSearchInput;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: flex-start;
`;

const Text = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;

  margin: 0 0 0 0;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const SearchWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin: 0 0 0 16px;
`;

const Input = styled.input`
	font-family: NotoSansKR-Regular;
	font-size: 12px;
	line-height: 20px;

  padding: 0 0 0 40px;
  width: 223px;
  height: 30px;

	outline: none;
  border: 1px solid #DFDFDF;
  border-radius: 8px;
	color: #000000;

  background: url(${props => props.img}) no-repeat 8px center;

	::placeholder {
		color: ${props => props.changedColor ? `#FF3131` : `#CBCBCB`};
	}
	
  transition: all 0.3s ease-in-out;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 265px;
  height: 124px;

  overflow-y: scroll;

  margin-top: 16px;

  &::-webkit-scrollbar {
		width: 9px;
	}

	&::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 20px #DFDFDF;
		border: 3px solid transparent;
		height: 25px;
		// background-color: #FF8B48;
		border-radius: 200px;
	}

	&::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px transparent;
		border: 4px solid transparent;
		background-color: transparent;
	}

	&::-webkit-scrollbar-track-piece {
		background-color: transparent;
	}
`;

const ApplyAllInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 265px;
  height: 124px;

  margin-top: 16px;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  width: 253px;
  height: 32px;
  
  border-bottom: 1px solid #F5F5F5;
`;

const Table = styled.table`
  display: flex;
  flex-direction: row;

  height: 32px;
`;

const Body = styled.tbody`
  display: flex;
  flex-direction: row;
  
  margin-left: 28px;
`;

const Tr = styled.tr`
  display: flex;
  flex-direction: row;
`;

const Td = styled.td`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: flex-start;

  overflow: hidden;

  max-height: 20px;
  // border: 1px solid #232323;
`;

const Name = styled.span`
  font-family: NotoSansKR-Medium;
  font-size: 12px;

  color: #595959;

  margin: 0 0 0 0;
`;

const Phone = styled.span`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #777777;

  margin: 0 0 0 0;
`;

const CheckImg = styled.img`
  width: 12px;
  height: 9px;

  margin: 0 0 0 67px;

  opacity: ${props => props.opacity};

  transition: all 0.2s ease-in-out;
`;

const ExitButton = styled.img`
  width: 16px;
  height: 16px;

  margin: 2px 0 0 97px;

  :hover {
    cursor: pointer;
  }
`;

const SelectBox = styled.div`
	position: fixed;
  // position: absolute;

	display: flex;
	flex-direction: column;

	justify-content: flex-start;
	align-items: flex-start;

	width: 263px;
	height: 0px;

	border: 0px solid #CBCBCB;
	border-radius: 8px;
	overflow: hidden;

  z-index: 5;
  margin-top: 35px;

  background: url(${props => props.buttonPath}) no-repeat 96% ${props => props.isClicked ? `3` : `55`}%;
	background-color: rgba(255, 255, 255, 1);
  transition: all 0.3s ease-in-out;

	:hover {
		cursor: pointer;
	}

	&::-webkit-scrollbar {
		width: 9px;
	}

	&::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 20px #FF8B48;
		border: 3px solid transparent;
		height: 25px;
		// background-color: #FF8B48;
		border-radius: 200px;
	}

	&::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px transparent;
		border: 4px solid transparent;
		background-color: transparent;
	}

	&::-webkit-scrollbar-track-piece {
		background-color: transparent;
	}
`;

const OptionWrapper = styled.div`
	display: flex;
	flex-direction: row;

  justify-content: space-between;
  align-items: center;

	width: 100%;

	:hover {
		cursor: pointer;
	}
`;

const Option = styled.p`
	font-family: NotoSansKR-Regular;
	font-size: 14px;

  // color: #777777;
  color: ${props => props.isComprise ? `#FF8B48` : `#777777`};

	margin: 8px 0 8px 8px;
`;

const ApplyAllText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 13px;

  color: #CBCBCB;

  margin: 0 0 0 0;
`;
