import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import lodash from 'lodash';

function NewTimeDivisionLine (props) {
  const { conversionRate, totalData, index } = props;

  // useEffect(() => {
  //   console.log(scheduleData);
  // }, [scheduleData])
  const [isNeedNewTime, setIsNeedNewTime] = useState(false);
  const [viewTime, setViewTime] = useState('');
  const [lengthRate, setLengthRate] = useState(1);

  useEffect(() => {
    if (totalData === undefined) return;
    if (totalData.length === 0) return;

    let data = totalData[index];

    if (index === 0) setIsNeedNewTime(true);
    else if (totalData[index][0].viewTime !== totalData[index - 1][0].viewTime) setIsNeedNewTime(true);

    // let data = lodash.deepClone(totalData[index]);

    let finalStartTime = 0;
    let finalEndTime = 0;
    let minimumIndex = 0;

    for (let i = 0; i < data.length; i++) {
      if (data[i].className !== '수업없음') {
        let startTime = data[i].classTime.split('-')[0];
        let endTime = data[i].classTime.split('-')[1];

        let startTimeHour = parseInt(startTime.split(':')[0]);
        let startTimeMinute = parseInt(startTime.split(':')[1]);

        let endTimeHour = parseInt(endTime.split(':')[0]);
        let endTimeMinute = parseInt(endTime.split(':')[1]);

        let startValue = 0;
        let endValue = 0;

        // if (startTimeMinute > 50) startValue = startTimeHour + 0.83;
        // else if (startTimeMinute > 40) startValue = startTimeHour + 0.66;
        // else if (startTimeMinute > 30) startValue = startTimeHour + 0.5;
        // else if (startTimeMinute > 20) startValue = startTimeHour + 0.33;
        // else if (startTimeMinute > 10) startValue = startTimeHour + 0.16;
        // else startValue = startTimeHour;

        if (startTimeMinute >= 55) startValue = startTimeHour + 0.91;
        else if (startTimeMinute >= 50) startValue = startTimeHour + 0.83;
        else if (startTimeMinute >= 45) startValue = startTimeHour + 0.75;
        else if (startTimeMinute >= 40) startValue = startTimeHour + 0.66;
        else if (startTimeMinute >= 35) startValue = startTimeHour + 0.58;
        else if (startTimeMinute >= 30) startValue = startTimeHour + 0.5;
        else if (startTimeMinute >= 25) startValue = startTimeHour + 0.41;
        else if (startTimeMinute >= 20) startValue = startTimeHour + 0.33;
        else if (startTimeMinute >= 15) startValue = startTimeHour + 0.25;
        else if (startTimeMinute >= 10) startValue = startTimeHour + 0.16;
        else if (startTimeMinute >= 5) startValue = startTimeHour + 0.08;
        else startValue = startTimeHour;

        // if (endTimeMinute > 50) endValue = endTimeHour + 0.83;
        // else if (endTimeMinute > 40) endValue = endTimeHour + 0.66;
        // else if (endTimeMinute > 30) endValue = endTimeHour + 0.5;
        // else if (endTimeMinute > 20) endValue = endTimeHour + 0.33;
        // else if (endTimeMinute > 10) endValue = endTimeHour + 0.16;
        // else endValue = endTimeHour;

        if (endTimeMinute >= 55) endValue = endTimeHour + 0.91;
        else if (endTimeMinute >= 50) endValue = endTimeHour + 0.83;
        else if (endTimeMinute >= 45) endValue = endTimeHour + 0.75;
        else if (endTimeMinute >= 40) endValue = endTimeHour + 0.66;
        else if (endTimeMinute >= 35) endValue = endTimeHour + 0.58;
        else if (endTimeMinute >= 30) endValue = endTimeHour + 0.5;
        else if (endTimeMinute >= 25) endValue = endTimeHour + 0.41;
        else if (endTimeMinute >= 20) endValue = endTimeHour + 0.33;
        else if (endTimeMinute >= 15) endValue = endTimeHour + 0.25;
        else if (endTimeMinute >= 10) endValue = endTimeHour + 0.16;
        else if (endTimeMinute >= 5) endValue = endTimeHour + 0.08;
        else endValue = endTimeHour;

        if (finalStartTime === 0) {
          finalStartTime = startValue;
          minimumIndex = i;
        }
        else if (finalStartTime > startValue) {
          finalStartTime = startValue;
          minimumIndex = i;
        }

        if (finalEndTime === 0) finalEndTime = endValue;
        else if (finalEndTime < endValue) finalEndTime = endValue;
      }
    }

    let finalRate = finalEndTime - finalStartTime;

    if (finalRate <= 1) setLengthRate(1);
    else if (finalRate < 1.5) setLengthRate(1);
    else setLengthRate((((finalRate - 1) * conversionRate) + 1));
    // else return ((finalEndTime - finalStartTime) * 66) - 4;

    setViewTime(data[minimumIndex].viewTime);
  }, [totalData, index])

  // return (
  //   <TopLevelWrapper>
  //     {
  //         scheduleData.isNeedNewDivide ?
  //         <TextWrapper >
  //           <TimeText>{scheduleData.viewTime}</TimeText>
  //           <TextTimeLine isNeedNewDivide={scheduleData.isNeedNewDivide} isMain={isMain}/>
  //         </TextWrapper>
  //         :
  //         <TimeLine/>
  //     }
  //   </TopLevelWrapper>
  // )

  // 텍스트 포함한 구분선 길이 계산 //
  function onCalcTextTimeLineHeight() {
    // if (lengthRate === 1) return 74 + 8 - 18;
    // else return (lengthRate * 66) + 8 - 18;
    if (index === 0) {
      if (lengthRate === 1) return 68 + 4 - 18;
      else return (lengthRate * 68) + 4 - 18;
    }
    else {
      if (lengthRate === 1) return 68 + 8 - 18;
      else return (lengthRate * 68) + 8 - 18;
    }
  }

  // 텍스트를 포함하지 않은 구분선 길이 //
  function onCalcTimeLineHeight() {
    // if (lengthRate === 1) return 74;
    // else return (lengthRate * 68) + 8;

    if (index === 0) {
      if (lengthRate === 1) return 68 + 4;
      else return (lengthRate * 68) + 4;
    }
    else {
      if (lengthRate === 1) return 68 + 8;
      else return (lengthRate * 68) + 8;
    }
  }

  return (
    <TopLevelWrapper>
      {
          isNeedNewTime ?
          <TextWrapper>
            <TimeText>{viewTime}</TimeText>
            {/* <TextTimeLine isNeedNewDivide={isNeedNewTime} isMain={isMain}/> */}
            <TextTimeLine height={onCalcTextTimeLineHeight()}/>
          </TextWrapper>
          :
          <TimeLine height={onCalcTimeLineHeight()}/>
      }
    </TopLevelWrapper>
  )
}

export default React.memo(NewTimeDivisionLine);

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  justify-content: center;
  align-items: center;

  width: 38px;

  margin-right: 14px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;
  
`;

const TimeText = styled.p`
  font-family: Poppins-Regular;
  font-size: 12px;

  color: #777777;
  margin: 0 0 0 0;
`;

const TextTimeLine = styled.div`
  width: 2px;
  min-height: ${props => props.height}px;

  background-color: #ECECEC;
  // background-color: red;
`;


const TimeLine = styled.div`
  width: 2px;
  height: ${props => props.height}px;

  background-color: #ECECEC;
  // background-color: blue;
`;
