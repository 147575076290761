import { React } from 'react';
import styled from 'styled-components';

export default function NewPriceSettingInput(props) {
	const { value, setValue, placeholder } = props;

	// const [value, setValue] = useState(0);
	// const [changed, setChanged] = useState(false);
	// const [changedPlaceholderColor, setChangedPlaceholderColor] = useState(false);
	// const [clickCount, setClickCount] = useState(0);
	// const [defaultPlaceholder, setDefaultPlaceholder] = useState(placeholder);


	// useImperativeHandle(ref, () => ({
	// 	setValue: (val) => {
	// 		// setValue(val);
	// 		setValue(val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));
	// 	},
	// 	getValue: () => {
  //     return parseInt(value.toString().replaceAll(',', ''));
	// 	},
	// 	plusClickCount: () => {
	// 		setClickCount(clickCount + 1)
	// 	},
	// 	getClickCount: () => {
	// 		return clickCount;
	// 	},
	// 	setDefaultPlaceholder: (val) => {
	// 		setDefaultPlaceholder(val)
	// 		setChangedPlaceholderColor(true);
	// 		inputRef.current.style.borderColor = '#FF3131';
	// 	},
	// 	reset: (val) => {
	// 		setClickCount(0);
	// 		setDefaultPlaceholder(val)
	// 		inputRef.current.style.borderColor = '#E8E8E8';
	// 	}
	// }));

	function handleClick(e) {
	}

	function handleChange(e) {
    const curValue = e.target.value;
		const newValue = curValue.replace(/[^0-9]/g, '');
		const finalValue = newValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

		setValue(finalValue);
	}

	function handleFocus(e) {
    
	}

	function handleBlur(e) {
	}

	return (
    <TopLevelWrapper>
      <Input
        placeholder={placeholder}
        value={value}
        onClick={handleClick}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur} />
        <Unit>원</Unit>
    </TopLevelWrapper>
  ) 
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100%;

  gap: 8px;
`;

const Input = styled.input`
	font-family: NotoSansKR-Regular;
	font-size: 14px;
	line-height: 20px;
	
	padding: 0 8px 0 8px;

  // width: 316px;
  width: calc(100% - 18px);
  height: 30px;

	outline: none;

  border: 1px solid #DFDFDF;
  border-radius: 8px;

  color: #CBCBCB;

  :: placeholder {
    color: #DFDFDF;
  }
	
  transition: all 0.5s ease-in-out;
`;

const Unit = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #777777;

  margin: 0 0 0 0;
`;