import { React, useState, useRef } from 'react';
import styled from 'styled-components';
import CenterTypeSelect from '../../../Component/MySettingPageComponent/CenterTypeSelect';
import InfoInput from '../../../Component/MySettingPageComponent/InfoInput';
import OpenTimeInput from '../../../Component/MySettingPageComponent/OpenTimeInput';
import CenterIntroTextArea from '../../../Component/MySettingPageComponent/CenterIntroTextArea';
import UsePaymentSystemButton from '../../../Component/MySettingPageComponent/UsePaymentSystemButton';

import DefaultLogoPath from '../../../image/DefaultLogo.png'
import UserProfile from '../../../Component/Normal/UserProfile';

function MyGymIntroductionEdit(props) {
  const { data, 
    storeLogo,
    setStoreLogo,
    storeName,
    setStoreName,
    tel,
    setTel,
    type, 
    setType,
    address,
    setAddress,
    openingHour,
    setOpeningHour,
    isUsePaymentSystem,
    setIsUsePaymentSystem,
    storeIntro,
    setStoreIntro,
    onClickSave
   } = props;
  
	const hiddenFileInput = useRef(null);
  const FileInputRef = useRef();
	const [imgBase64, setImgBase64] = useState();
  const [imgFile, setImgFile] = useState();
  const [imgLocalPath, setImgLocalPath] = useState('');

  const CanvasRef = useRef();

  const handleClick = e => {
    hiddenFileInput.current.click();
    FileInputRef.current.style.borderColor = '#FF8B48';
	};
	
  const handleChange = e => { 
    
    var pathpoint = e.target.value.lastIndexOf('.');
    var filepoint = e.target.value.substring(pathpoint + 1, e.target.length);
    var filetype = filepoint.toLowerCase();

    if (filetype === 'jpg' || filetype === 'png' || filetype === 'jpeg') {

      // 정상적인 이미지 확장자 파일인 경우
      const reader = new FileReader();
      // setImgLocalPath(e.target.value);
      var tmp = e.target.value.split('\\');
      setImgLocalPath(tmp[tmp.length - 1]);
      reader.onloadend = () => {
        const base64 = reader.result;
        const naturalImage = new Image();
  
        naturalImage.src = reader.result.toString();
        
        // if (base64){
        //   setStoreLogo(base64.toString());
          
        // }
        naturalImage.onload = function (e) {
          
          let naturalWidth = e.target.naturalWidth;
          let naturalHeight = e.target.naturalHeight;

          let resizeWidth = 0;
          let resizeHeight = 0;

          if (naturalWidth < naturalHeight) {
            resizeWidth = 600;
            resizeHeight = (600 * naturalHeight) / naturalWidth;
          }
          else if (naturalWidth > naturalHeight) {
            resizeHeight = 600;
            resizeWidth = (600 * naturalWidth) / naturalHeight;
          }
          else {
            resizeWidth = 600;
            resizeHeight = 600;
          }

          const image = new Image();
          const canvas = CanvasRef.current;
          const ctx = canvas?.getContext('2d');

          if (ctx) {
            canvas.width = 600;
            canvas.height = 600;
            // canvas.backgroundColor = '#FFFFFF';

            image.src = base64.toString();

            // 사진 크기 조정후 Canvas에 그리기 //
            image.onload = function () {
              ctx.clearRect(0, 0, canvas.width, canvas.height);
              // ctx.drawImage(image, 0, 0, resizeWidth, resizeHeight);
              ctx.drawImage(image, ((600 - resizeWidth) / 2), ((600 - resizeHeight) / 2), resizeWidth, resizeHeight);
            }

            // 크기 조절된 사진 데이터 정리하기 //
            canvas.toBlob(function (blob) {
              const reader = new FileReader();
              // Canvas에 다 그려졌을때 Event //
              reader.onload = function (e) {
                setStoreLogo(canvas.toDataURL());
              }
              reader.readAsDataURL(blob);
            })
          }
          else {
            // throw new Error('Could not get context');
            console.log("Could not get context");
          }
        }
      };
      if (e.target.files[0]) {
        reader.readAsDataURL(e.target.files[0]);
        setImgFile(e.target.files[0]);
        // setStoreLogo(e.target.files[0]);
      }
    } 
    else {
      alert('.jpeg .jpg .png 파일만 가능합니다!');
    }
	};

  return (
    <TopLevelWrapper>
      <InfoView>
        <TitleWrapper>
          <Title>센터 정보</Title>
          <SaveButton onClick={onClickSave}>저장하기</SaveButton>
        </TitleWrapper>
        <InfoWrapper>
          <LayerWrapper>
            <LogoWrapper>
              {/* <Logo src={storeLogo ? storeLogo : DefaultLogoPath} /> */}
              <UserProfile profile={storeLogo} defaultProfile={DefaultLogoPath}/>
              <canvas ref={CanvasRef} style={{ display: 'none' }} />
              <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{ display: 'none' }} />
              <UploadButton ref={FileInputRef} onClick={handleClick}>사진 변경</UploadButton>
            </LogoWrapper>
            <TextWrapper>
              <InputWrapper topMargin={0}>
                <InfoInput column='상호명' defaultVal={storeName} setVal={setStoreName} width='457'/>
                <InfoInput column='전화번호' defaultVal={tel} setVal={setTel} width='224'/>
                <CenterTypeSelect defaultVal={type} setVal={setType}/>
              </InputWrapper>
              <InputWrapper topMargin={28}>
                <InfoInput column='센터 주소' defaultVal={address} setVal={setAddress} width='457'/>
                <OpenTimeInput column='영업시간' defaultVal={openingHour} setVal={setOpeningHour} width='224'/>
                <UsePaymentSystemButton isUsePaymentSystem={isUsePaymentSystem} setIsUsePaymentSystem={setIsUsePaymentSystem} width={240}/>
              </InputWrapper>
            </TextWrapper>
          </LayerWrapper>
        </InfoWrapper>
        <TitleWrapper style={{ marginTop: `32px` }}>
          <TitleTextWrapper>
            <Title>센터 소개</Title>
            <CustomText fontFamily='NotoSansKR-Regular' fontSize={12} color='#BDBDBD' cursor='default'>(최대 250자)</CustomText>
          </TitleTextWrapper>
        </TitleWrapper>
        <InfoWrapper>
          <TextWrapper style={{ marginTop: `24px` }}>
            <CenterIntroTextArea placeholder='센터 소개글을 입력해주세요' defaultValue={storeIntro} setVal={setStoreIntro}/>
          </TextWrapper>
        </InfoWrapper>
      </InfoView>
    </TopLevelWrapper>
  )
}

export default MyGymIntroductionEdit;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 34px;

  width: 100%;
`;

const InfoView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 14px 0px 14px 0px;

  width: 1191px;
  // height: 266px;

  border-radius: 30px;
  background-color: #FFFFFF;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 1135px;
`;

const TitleTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const Title = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 24px;
  border-top: 1px solid #ECECEC;

  width: 1135px;
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 100%;

  margin-top: 24px;
  gap: 20px;
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  gap: 8px
`;

const Logo = styled.img`
  width: 120px;
  height: 120px;

  border-radius: 15px;

  object-fit: cover;
`;

const LogoBox = styled.img`
  width: 120px;
  height: 120px;

  border-radius: 15px;

  object-fit: cover;
  overflow: hidden;
`;

const UploadButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 120px;
  height: 32px;

  border-radius: 8px;

  background-color: #33C8FF;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }

  :hover {
    cursor: pointer;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  gap: 8px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: ${props => props.topMargin}px;

  width: 995px;
`;

const SaveButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 85px;
  height: 31px;

  border: $1px solid #6DD49E;
  border-radius: 15px;
  background-color: #6DD49E;

  font-family: NotoSansKR-Bold;
  font-size: 12px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const Text = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;

const CustomText = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }
`;