import { React, useEffect, useRef } from 'react';
import styled from 'styled-components';

import UnitSelectBox from '../Component/UnitSelectBox';
import SingleRestInput from '../Component/SingleRestInput';
import SmallTimeInput from '../../Component/NoteComponent/SmallTimeInput';
import IncludeSlashDataInput from '../Component/IncludeSlashDataInput';
import IncludeSlashSingleDataInput from '../Component/IncludeSlashSingleDataInput';
import SingleSmallTimeInput from '../Component/SingleSmallTimeInput';

import ProgramVideoIcon from '../../image/ProgramVideoIcon.png';
import ExerciseDeleteIcon from '../../image/ExerciseDeleteIcon.png';
import MovementOptionIcon from '../../image/MovementOptionIcon.png';
import CheckImage from '../../image/WhiteCheck.png';
import GreenCheckImage from '../../image/GreenCheck.png';
import CopyIcon from '../../image/CopyIcon.png';

export default function SingleRecordMovementBox(props) {
  const {
    data,

    idx,
    tag,
    reps,
    division,

    onChangeData,
    onChangeUnit,
    onClickRest,

    onClickCopyMovement,
    onClickMovementVideo,
    onClickMovementOption,
    onClickDeleteMovement,
  } = props;

  const weightOptions = ['Kg', 'Pounds', '%RM', '%Body'];
  const distanceOptions = ['Km', 'm', 'Cm', 'inch', 'Feet'];
  const caloriesOptions = ['Kcal', 'Cal'];

  // 동작간 쉬는시간 인풋 글자수 비례 증감 //
  // const restInputBorderWidth = 0;
  const fontSize = 14;
  const restInputPadding = 32;
  const restInputMaxWidth = 240;
  const restInputMinWidth = 89;

  // 한자리 숫자 앞에 0 붙이기 //
  function numberPad(n, width) {
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
  }

  // 템포 컴포넌트 길이 계산 //
  function decideRestWidth(value) {
    let calcWidth = (fontSize * 0.6) * (value.toString().length + 3) + restInputPadding;
    
    if (calcWidth > restInputMaxWidth) return restInputMaxWidth;
    else if (calcWidth < restInputMinWidth) return restInputMinWidth;
    else return calcWidth;
  }

  return (
    <TopLevelWrapper>
      <TotalWrapper>
        <MovementWrapper>
          <MovementTitleWrapper>
            <TextScaleButtonWrapper>
              <NumberWrapper>
                <NumberBox isGreen={division === 'buyin' || division === 'buyout'}>
                  <HoverPointerText fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>{numberPad(idx + 1, 2)}</HoverPointerText>
                </NumberBox>
                <HoverPointerText fontFamily='Poppins-Medium' fontSize={12} color='#4F4F4F'>{data.movementName}</HoverPointerText>
              </NumberWrapper>
            </TextScaleButtonWrapper>
            <IconWrapper>
              <Icon src={ProgramVideoIcon} onClick={() => onClickMovementVideo(idx)} />
              <Icon style={{ width: `16px`, height: `16px` }} src={CopyIcon} onClick={() => onClickCopyMovement(data)} />
              <Icon src={MovementOptionIcon} onClick={() => onClickMovementOption(idx)} />
              <Icon src={ExerciseDeleteIcon} onClick={() => onClickDeleteMovement(idx)} />
            </IconWrapper>
          </MovementTitleWrapper>
          {
            data.isUseCount &&
            <LayerWrapper topMargin={16}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>횟수</Text>
              <ComponentWrapper>
                <SingleCountWrapper>
                  <IncludeSlashSingleDataInput value={data.count} setValue={onChangeData} unit='REPS' column='count' division={division} index={idx} />
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>X</Text>
                  <IncludeSlashSingleDataInput value={data.sets} setValue={onChangeData} unit='SETS' column='sets' division={division} index={idx} />
                </SingleCountWrapper>
              </ComponentWrapper>
              <EmptyBox/>
            </LayerWrapper>
          }
          {
            data.isUseWeight &&
            <LayerWrapper topMargin={12}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>무게</Text>
              <ComponentWrapper>
                <IncludeSlashDataInput value={data.weight} setValue={onChangeData} unit={data.weightUnit} column='weight' division={division} index={idx} />
              </ComponentWrapper>
              <UnitSelectBox value={data.weightUnit} setValue={onChangeUnit} options={weightOptions} column='weight' division={division} index={idx} />
            </LayerWrapper>
          }
          {
            data.isUseDistance &&
            <LayerWrapper topMargin={12}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>거리</Text>
              <ComponentWrapper>
                <IncludeSlashDataInput value={data.distance} setValue={onChangeData} unit={data.distanceUnit} column='distance' division={division} index={idx} />
              </ComponentWrapper>
              <UnitSelectBox value={data.distanceUnit} setValue={onChangeUnit} options={distanceOptions} column='distance' division={division} index={idx} />
            </LayerWrapper>
          }
          {
            data.isUseHeight &&
            <LayerWrapper topMargin={12}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>높이</Text>
              <ComponentWrapper>
                <IncludeSlashDataInput value={data.height} setValue={onChangeData} unit={data.heightUnit} column='height' division={division} index={idx} />
              </ComponentWrapper>
              <UnitSelectBox value={data.heightUnit} setValue={onChangeUnit} options={distanceOptions} column='height' division={division} index={idx} />
            </LayerWrapper>
          }
          {
            data.isUseCalories &&
            <LayerWrapper topMargin={12}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>열량</Text>
              <ComponentWrapper>
                <IncludeSlashDataInput value={data.calories} setValue={onChangeData} unit={data.caloriesUnit} column='calories' division={division} index={idx} />    
              </ComponentWrapper>
              <UnitSelectBox value={data.caloriesUnit} setValue={onChangeUnit} options={caloriesOptions} column='calories' division={division} index={idx} />
            </LayerWrapper>
          }
          {
            data.isUseTime &&
            <LayerWrapper topMargin={12}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>시간</Text>
              <ComponentWrapper>
                <TimeInputWrapper>
                  <SingleSmallTimeInput value={data.minute} setValue={onChangeData} unit='MIN' column='minute' division={division} index={idx} />
                  <SingleSmallTimeInput value={data.second} setValue={onChangeData} unit='SEC' column='second' division={division} index={idx} />
                </TimeInputWrapper>
              </ComponentWrapper>
              <EmptyBox/>
            </LayerWrapper>
          }
          {
            data.isUseTempo &&
            <LayerWrapper topMargin={12}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>템포</Text>
              <ComponentWrapper>
                <IncludeSlashDataInput value={data.tempo} setValue={onChangeData} unit='TEMPO' column='tempo' division={division} index={idx} />
              </ComponentWrapper>
              <EmptyBox/>
            </LayerWrapper>
          }
          <RestComponentWrapper topMargin={25} isClicked={data.isUseRest}>
            <RestNotMoveComponent>
              <RestComponentBox onClick={() => onClickRest(idx)}>
                <CheckBox isClicked={data.isUseRest}>
                  <Check isClicked={data.isUseRest} src={GreenCheckImage} />
                </CheckBox>
                <HoverText style={{ marginTop: '-2px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color={data.isUseRest ? '#FFFFFF' : '#828282'} hover='pointer'>동작간 쉬는 시간</HoverText>
              </RestComponentBox>
              <DotWrapper>
                <Dot />
                <Dot />
                <Dot />
              </DotWrapper>
            </RestNotMoveComponent>
            <SingleRestInputWrapper width={decideRestWidth(data.rest)}>
              <SingleRestInput value={data.rest} setValue={onChangeData} disabled={!data.isUseRest} unit='SEC' column='rest' division={division} index={idx} />
            </SingleRestInputWrapper>
          </RestComponentWrapper>
        </MovementWrapper>
      </TotalWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: 100%;
  z-index: ${props => props.index};
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: 100%;

  background-color: #FFFFFF;
`;

const MovementWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: 100%;

  margin-top: 23px;
  background-color: #FFFFFF;
`;

const NumberWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;

  :hover {
    cursor: pointer;
  }
`;

const MovementTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 12px;
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;

  :hover {
    cursor: pointer;
  }
`;

const NumberBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 27px;
  height: 18px;

  border-radius: 6px;
  background-color: ${props => props.isGreen ? `#6DD49E` : `#FF8B48`};

  // font-family: NotoSansKR-Bold;
  // font-size: 12px;

  // color: #FFFFFF;

  margin: 0 0 0 0;
  padding: 0 0 0 0;
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;

  margin-top: ${props => props.topMargin}px;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 63.87%;
  gap: 8px;
`;

const SingleCountWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const TextScaleButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 12px;
`;


const HoverPointerText = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  padding: 0 0 0 0;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  padding: 0 0 0 0;

  :hover {
    cursor: default;
  }

  transition: all 0.3s ease-in-out;
`;

const HoverText = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  padding: 0 0 0 0;

  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;

const TimeInputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 24px;
`;

const RestComponentWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding: 0px 15px;
  margin-left: -15px;
  
  width: 100%;
  height: 35px;

  gap: 7px;

  margin-top: ${props => props.topMargin}px;

  background-color: ${props => props.isClicked ? 'rgba(109, 212, 158, 0.50)' : '#FFFFFF'};

  transition: all 0.3s ease-in-out;
`;

const SingleRestInputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: ${props => props.width}px;
`;

const RestNotMoveComponent = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  gap: 114px;
`;

const RestComponentBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  gap: 6px;
`;

const DotWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-evenly;
  align-items: center;

  height: 60%;
`;

const Dot = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 5px;
  height: 5px;

  border-radius: 5px;
  background-color: #FFFFFF;
`;

const CheckBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 18px;
  height: 18px;

  border: ${props => props.isClicked ? `1px solid #FFFFFF` : `1px solid #CBCBCB`};
  border-radius: 6px;
  background-color: ${props => props.isClicked ? `#FFFFFF` : `#F6F6F6`};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const Check = styled.img`
  width: 10px;
  height: 9px;

  opacity: ${props => props.isClicked ? '1' : '0'};

  transition: all 0.3s ease-in-out;
`;

const EmptyBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 96px;
  height: 36px;
`;