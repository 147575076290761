import { React, useState, forwardRef, useImperativeHandle, useRef } from 'react';
import styled from 'styled-components';

import DownImage from '../../image/down.png';
import UpImage from '../../image/up.png';

export default function NewCountInput(props) {
  const { value, setValue, unit } = props;

	function handleChange(e) {
    var regex = /[^0-9]/g;				// 숫자가 아닌 문자열을 매칭하는 정규식
    var result = e.target.value.replace(regex, "");	// 원래 문자열에서 숫자가 아닌 모든 문자열을 빈 문자로 변경
    
    if (result === "") setValue(0);
		else setValue(parseInt(result));
	}

	function handleFocus(e) {

	}

  function upButtonClick() {
    setValue(value + 1);
  }

  function downButtonClick(){
    if (value > 0) setValue(value - 1);
  }

  return (
    <TopLevelWrapper>
      <Input
        placeholder={0}
        value={value}
        onChange={handleChange}
        onFocus={handleFocus} />
      <ComponentWrapper>
        <ButtonWrapper>
          <Button img={UpImage} onClick={upButtonClick} />
          <Button img={DownImage} onClick={downButtonClick} />
        </ButtonWrapper>
        <Text>{unit}</Text>
      </ComponentWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;

  transition: all 0.3s ease-in-out;
`;

const Input = styled.input`
  display: flex;
  flex-direction: row;

  align-items: center;

  width: 49.80%;
  height: 30px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background-color: #FFFFFF;

  padding: 0 0 0 8px;
  margin: 0 4px 0 0;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
  margin-right: 8px;
`;

const Button = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 30px;
  height: 30px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background: url(${props => props.img}) no-repeat center;
  background-color: #ECECEC;

  :hover {
    cursor: pointer;
  }
`;

const Text = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #777777;

  margin: 0 0 0 0;
`;