import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import FetchModule from "../../Share/Network/FetchModule";

import PasswordChangeInput from "../../../Component/MySettingPageComponent/PasswordChangeInput";
import { useHistory } from "react-router-dom";

export default function PasswordChangeModal(props) {
  const { onClickExit } = props;

  const history = useHistory();

  const [nowPassword, setNowPassword] = useState('');
  const [willChangePassword, setWillChangePassword] = useState('');
  const [checkWillChangePassword, setCheckWillChangePassword] = useState('');

  // 화면 스크롤 제어 //
  useEffect(() => {
    document.body.style = `overflow: hidden`;
    return () => document.body.style = `overflow: auto`;
  }, [])

  // 변경하기 클릭시 //
  function onClickSave() {
    // 비밀번호 입력 안했을 경우 //
    if (nowPassword === '') {
      alert('현재 비밀번호를 입력해주세요');
      return;
    }
    else if (willChangePassword === '') {
      alert('변경할 비밀번호를 입력해주세요');
      return;
    }
    else if (checkWillChangePassword === '') {
      alert('변경할 비밀번호를 확인해주세요');
      return;
    }
    else if (willChangePassword !== checkWillChangePassword) {
      alert('변경할 비밀번호가 일치하지 않습니다');
      return;
    }
    else if (nowPassword === willChangePassword) {
      alert('이전 비밀번호와 동일하게 변경할 수 없습니다.');
      return;
    }

    var requestData = {
      nowPassword: nowPassword,
      willChangePassword: willChangePassword,
    }
    
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataVersion2('account/change/password', 'POST', requestData);
        
        if (responseData.status === 200) {
          alert('비밀번호가 변경되었습니다!\n변경된 비밀번호로 다시 로그인해주세요!');
          
          window.localStorage.removeItem('StoreId');
          window.localStorage.removeItem('Token');
          window.localStorage.removeItem('RefreshToken');
          window.localStorage.removeItem('AccountId');
          
          history.push('/');
        }
        else {
          alert('현재 비밀번호가 올바르지 않습니다.');
          return;
        }
      }
    )();

  }

  return (
    <TopLevelWrapper>
      <Modal>
        <ModalInnerWrapper>
          <TitleWrapper>
            <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#232323' cursor='default'>비밀번호 변경</Text>
          </TitleWrapper>
          <InputWrapper marginTop={24}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959' cursor='default'>현재 비밀번호</Text>
            <PasswordChangeInput value={nowPassword} setValue={setNowPassword} placeholder='현재 비밀번호를 입력해주세요'/>
          </InputWrapper>
          <InputWrapper marginTop={12}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959' cursor='default'>변경할 비밀번호</Text>
            <PasswordChangeInput value={willChangePassword} setValue={setWillChangePassword} placeholder='변경할 비밀번호를 입력해주세요'/>
          </InputWrapper>
          <InputWrapper marginTop={12}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959' cursor='default'>변경할 비밀번호 확인</Text>
            <PasswordChangeInput value={checkWillChangePassword} setValue={setCheckWillChangePassword} placeholder='변경할 비밀번호를 입력해주세요'/>
          </InputWrapper>
          <ButtonWrapper>
            <ExitButton onClick={onClickExit}>취소</ExitButton>
            <SaveButton onClick={onClickSave}>변경하기</SaveButton>
          </ButtonWrapper>
        </ModalInnerWrapper>
      </Modal>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 1000;

  background-color: rgba(0, 0, 0, 0.2);
  
  transition: all 0.3s ease-in-out;
`;

const Modal = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 338px;
  height: 374px;

  border-radius: 30px;
  background-color: #FFFFFF;
`;

const ModalInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 83.43%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: ${props => props.marginTop}px;

  gap: 8px;

  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: flex-end;

  margin-top: 36px;

  gap: 16px;

  width: 100%;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 72px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;

  :hover {
    cursor: pointer;
    border: 1px solid #777777;
    color: #777777;
  }
  
  transition: all 0.3s ease-in-out;
`;

const SaveButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 15px;
  background-color: #5BD98A;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  :hover {
    cursor: pointer;
    background-color: #6DD49E;
  }
  
  transition: all 0.3s ease-in-out;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }
`;