import { React, useState, useEffect } from 'react';
import styled from 'styled-components';

import FullListSelectBox from './FullListSelectBox';
import FullRangeInput from '../../../Component/BoardComponent/FullRangeInput';

const FullExerciseBox = (props) => {
  const { date, setScheduleId, setLessonId, setClassTime, useData, clickDate, rendering } = props;

  const [index, setIndex] = useState(0);
  const [isView, setIsView] = useState(true);
  const [fontSize, setFontSize] = useState(14);
  const [titleFontSize, setTitleFontSize] = useState(14);
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined
  })


  useEffect(() => {
    for (var i = 0; i < useData.length; i++) {
      if (useData[i].classDate.format('YYYY.MM.DD') === clickDate) {
        setIndex(i);
        break;
      }
    }
  }, [clickDate, useData])

  
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.visualViewport.width,
        height: window.visualViewport.height
      });
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function changeFontSize(value) {
    if (parseInt(value) <= 29) setTitleFontSize(value);
    setFontSize(value);
  }

  return (
    <TopLevelWrapper>
      <TopWrapper>
        <Title>수업</Title>
        <FullListSelectBox date={date} setScheduleId={setScheduleId} setLessonId={setLessonId} setClassTime={setClassTime} rendering={rendering}/>
      </TopWrapper>
      <SubjectWrapper>
        <SubjectView>{useData[index].title}</SubjectView>
      </SubjectWrapper>
      <LineWrapper>
        <SubTitle>운동 내용</SubTitle>
        <ComponentWrapper>
          <HiddenButton onClick={() => setIsView(!isView)}>{isView ? `영상정보 숨기기` : `영상정보 보기`}</HiddenButton>
          <FullRangeInput defaultVal={14} changeFn={changeFontSize}/>
        </ComponentWrapper>
      </LineWrapper>
      <InfoBox>
        <ExerciseInfoWrapper>
          <Subject>준비 운동</Subject>
          {
            useData[index].warmupList.map((data, idx) => (
              <ExerciseInfoBox key={idx}>
                <ExerciseTopWrapper>
                  <ColumnSection isWarmup={true}>{String.fromCharCode(idx + 65)}</ColumnSection>
                  <TitleSection fontSize={titleFontSize}>{data.title}</TitleSection>
                </ExerciseTopWrapper>
                <TextSection>
                  <Text fontSize={fontSize}>{data.text}</Text>
                </TextSection>
                {isView &&
                  data.linkList.map((data, idx) => (
                    data !== '' &&
                    <VideoSection key={idx}>
                      <iframe
                        // width="482"
                        width={windowSize.width * 0.2510416666666667}
                        // height="270"
                        height={windowSize.width * 0.140625}
                        src={`https://www.youtube.com/embed/${data}`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded youtube"
                      />
                    </VideoSection>
                  ))
                }
              </ExerciseInfoBox>
            ))
          }
        </ExerciseInfoWrapper>
        <ExerciseInfoWrapper>
          <Subject>운동 프로그램</Subject>
          {
            useData[index].exerciseList.map((data, idx) => (
              <ExerciseInfoBox key={idx}>
                <ExerciseTopWrapper>
                  <ColumnSection>{String.fromCharCode(idx + 65)}</ColumnSection>
                  <TitleSection fontSize={titleFontSize}>{data.title}</TitleSection>
                </ExerciseTopWrapper>
                <TextSection>
                  <Text fontSize={fontSize}>{data.text}</Text>
                </TextSection>
                {isView &&
                  data.linkList.map((data, idx) => (
                    data !== '' &&
                    <VideoSection key={idx}>
                      <iframe
                        // width="482px"
                        width={windowSize.width * 0.2510416666666667}
                        // height="270px"
                        height={windowSize.width * 0.140625}
                        src={`https://www.youtube.com/embed/${data}`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded youtube"
                      />
                    </VideoSection>
                  ))
                }
              </ExerciseInfoBox>
            ))
          }
        </ExerciseInfoWrapper>
      </InfoBox>
    </TopLevelWrapper>
  )
}

export default FullExerciseBox;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 786px;
  width: 40.9375vw;
  // min-height: 378px;

  
  // padding-bottom: 28px;
  padding-bottom: 1.458333333333333vw;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  background-color: #FFFFFF;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  // width: 730px;
  width: 38.02083333333333vw;
  // max-height: 817px;
  max-height: 42.55208333333333vw;
  
  // margin-top: 24px;
  margin-top: 1.25vw;

  overflow-y: scroll;
  overflow-x: none;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
		border: 3px solid transparent;
		// height: 92px;
		background-color: #DADADA;
		border-radius: 200px;
	}

  &::-webkit-scrollbar-track {
    // height: 439px;
		// border: 4px solid transparent;
		background-color: #F0F0F0;
		border-radius: 200px;
	}
`;

const LineWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  // width: 730px;
  width: 38.02083333333333vw;

  // margin-top: 24px;
  margin-top: 1.25vw;
`;

const SubTitle = styled.p`
  font-family: NotoSansKR-Medium;
  // font-size: 14px;
  font-size: 0.7291666666666667vw;

  color: #232323;

  margin: 0 0 0 0;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // gap: 16px;
  gap: 0.8333333333333333vw;
`;

const HiddenButton = styled.p`
  font-family: NotoSansKR-Regular;
  // font-size: 12px;
  font-size: 0.625vw;

  color: #777777;

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;

const TopWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  // width: 730px;
  width: 38.02083333333333vw;

  // margin-top: 28px;
  margin-top: 1.458333333333333vw;
`;

const Title = styled.p`
  font-family: NotoSansKR-Medium;
  // font-size: 14px;
  font-size: 0.7291666666666667vw;

  color: #232323;

  margin: 0 0 0 0;
`;

const SubjectWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  // gap: 8px;
  gap: 0.4166666666666667vw;
  // margin-top: 8px;
  margin-top: 0.4166666666666667vw;
`;

const Subject = styled.p`
  font-family: NotoSansKR-Regular;
  // font-size: 12px;
  font-size: 0.625vw;

  color: #595959;

  margin: 0 0 0 0;
`;

const SubjectView = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  // width: 730px;
  width: 38.02083333333333vw;
  // height: 36px;
  height: 1.875vw;

  // padding-left: 16px;
  padding-left: 0.8333333333333333vw;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;

  font-family: NotoSansKR-Regular;
  // font-size: 14px;
  font-size: 0.7291666666666667vw;

  color: #595959;
`;

const ExerciseInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: 100%;

  // margin-top: 24px;
  margin-top: 1.25vw;
`;

const ExerciseInfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  // width: 514px;
  // width: 26.77083333333333vw;
  // min-height: 70px;
  // min-height: 3.645833333333333vw;
  
  // width: 718px;
  width: 37.39583333333333vw;
  // min-height: 92px;
  min-height: 4.791666666666667vw;

  // margin-top: 8px;
  margin-top: 0.4166666666666667vw;
  overflow: hidden;

  border: 1px solid #ECECEC;
  border-radius: 15px;
  background-color: #FFFFFF;
`;

const ExerciseTopWrapper = styled.div`
  display: flex;
  flex-direction: row;

  width: 100%;
  // height: 36px;
  height: 1.875vw;

  border: 1px solid #ECECEC;
`;

const ColumnSection = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 43px;
  width: 2.239583333333333vw;
  // height: 36px;
  height: 1.875vw;

  background-color: ${props => props.isWarmup ? `#5BD98A` : `#FF8B48` };

  font-family: Poppins-Bold;
  // font-size: 14px;
  font-size: 0.7291666666666667vw;

  color: #FFFFFF;
`;

const TitleSection = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: 100%;
  // height: 36px;
  height: 1.875vw;

  // padding-left: 16px;
  padding-left: 0.8333333333333333vw;

  font-family: NotoSansKR-Regular;
  // font-size: 14px;
  // font-size: 0.7291666666666667vw;
  font-size: ${props => props.fontSize}px;

  color: #595959;
`;

const TextSection = styled.div`
  display: flex;
  flex-direction: column;

  // gap: 5px;
  gap: 0.2604166666666667vw;
  // margin: 8px 0 8px 16px;
  margin-top: 0.4166666666666667vw;
  margin-right: 0px;
  margin-bottom: 0.4166666666666667vw;
  margin-left: 0.8333333333333333vw;
`;

const Text = styled.pre`
  font-family: NotoSansKR-Regular;
  font-size: ${props => props.fontSize}px;
  // font-size: ${props => props.fontSize}vw;

  color: #595959;

  margin: 0 0 0 0;

  white-space: pre-wrap;
`;

const VideoSection = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  // width: 482px;
  width: 25.10416666666667vw;
  // height: ${props => props.modify ? 306 : 270}px;
  height: ${props => props.modify ? 15.9375 : 14.0625}vw;

  // margin: 0 16px 16px 16px;
  margin-top: 0px;
  margin-right: 0.8333333333333333vw;
  margin-bottom: 0.8333333333333333vw;
  margin-left: 0.8333333333333333vw;
`;