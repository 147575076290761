import { useState, useImperativeHandle, forwardRef, useRef, React, useEffect } from 'react';
import styled from 'styled-components';

const NoticeTextArea = forwardRef((props, ref) => {
	const { className, placeholder, type, defaultValue } = props;
	const [value, setValue] = useState(defaultValue);
	const [changed, setChanged] = useState(false);
	const [changedPlaceholderColor, setChangedPlaceholderColor] = useState(false);
	const [clickCount, setClickCount] = useState(0);
	const [defaultPlaceholder, setDefaultPlaceholder] = useState(placeholder);

	const inputRef = useRef();

	useImperativeHandle(ref, () => ({
		setValue: (val) => {
			setValue(val);
			setChanged(true);
		},
		getValue: () => {
			return value;
		},
		plusClickCount: () => {
			setClickCount(clickCount + 1)
		},
		getClickCount: () => {
			return clickCount;
		},
		setDefaultPlaceholder: (val) => {
			setDefaultPlaceholder(val)
			setChangedPlaceholderColor(true);
			inputRef.current.style.borderColor = '#FF3131';
		},
		reset: (val) => {
			setClickCount(0);
			setDefaultPlaceholder(val)
			inputRef.current.style.borderColor = '#E8E8E8';
		}
	}));

	useEffect(() => {
		inputRef.current.style.height = `${(inputRef.current.scrollHeight + 9)}px`;
	}, [])

	function handleChange(e) {
		setValue(e.target.value);
		setChanged(true);
	}

	function handleFocus(e) {
		setChangedPlaceholderColor(false);
	}

	function handleBlur(e) {
		setClickCount(clickCount + 1);
	}

  function onPress(e) {
    if (e.key === 'Enter') {
      inputRef.current.style.height = `${(inputRef.current.scrollHeight + 9)}px`;
    }
  }

 
	return <Input
		ref={inputRef}
		clickCount={clickCount}
		type={type}
		placeholder={defaultPlaceholder}
		value={value}
		changedColor={changedPlaceholderColor}
		className={`${className} ${changed ? "changed" : ""}`}
		onChange={handleChange}
		onFocus={handleFocus}
		onBlur={handleBlur} 
    onKeyPress={onPress}/>
});

export default NoticeTextArea;

const Input = styled.textarea`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: 285px;
  height: 368px;
  // height: auto;
  // height: 32px;
  resize: none;
  overflow-y: hidden;
  // resize: vertical;

  // padding-left: 16px;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;
  border: 0px solid #DFDFDF;
	// line-height: 20px;

  color: #595959;

	outline: none;

	::placeholder {
		color: ${props => props.changedColor ? `#FF3131` : `#CBCBCB`};
	}
	
  // transition: all 0.3s ease-in-out;
`;