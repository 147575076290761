import { React, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import FetchModule from '../Model/Share/Network/FetchModule.js';
import MyAccountView from '../Model/Individual/MySettingModel/MyAccountView';
import MyGymIntroductionText from '../Model/Individual/MySettingModel/MyGymIntroduceText';
import MyGymIntroduction from '../Model/Individual/MySettingModel/MyGymIntroduction';
import MyGymIntroductionEdit from '../Model/Individual/MySettingModel/MyGymIntroductionEdit';
import MyGymPicture from '../Model/Individual/MySettingModel/MyGymPicture';
import MyZoneMemberPaymentView from '../Model/Individual/MySettingModel/MyMemberPaymentView';
import MyPageTopModel from '../Model/Individual/MySettingModel/MyPageTopModel';
import MyZoneSubscribeInfo from '../Model/Individual/MySettingModel/MyZoneSubscribeInfo';
import RefundModal from '../Model/Individual/MySettingModel/RefundModal.js';
import SubscribeModal from '../Model/Individual/MySettingModel/SubscribeModal.js';

import SessionStorage from '../Model/Share/Network/SessionStorage.js';
import MemberListExportModal from '../Model/Individual/MySettingModel/MemberListExportModal.js';
import CenterSubscriptionTermsView from '../Model/Individual/MySettingModel/CenterSubscriptionTermsView.js';
import CenterSubscriptionTermsEdit from '../Model/Individual/MySettingModel/CenterSubscriptionTermsEdit.js';
import CenterFollowView from '../Model/Individual/MySettingModel/CenterFollowView.js';
import CenterFollowerView from '../Model/Individual/MySettingModel/CenterFollowerView.js';
import PartnerGymFollowView from '../Model/Individual/MySettingModel/PartnerGymFollowView.js';
import PartnerGymFollowerView from '../Model/Individual/MySettingModel/PartnerGymFollowerView.js';
import BillingInfoModal from '../Model/Individual/MySettingModel/BillingInfoModal.js';


function MySettingPage() {

  const history = useHistory();

  const [isClickedSubscribe, setIsClickedSubscribe] = useState(false);
  const [isViewBillingInfoModal, setIsViewBillingInfoModal] = useState(false);
  const [isClickedModify, setIsClickedModify] = useState(false);

  const [billingInfo, setBillingInfo] = useState(undefined);

  const [isClickedModifyAccount, setIsClickedModifyAccount] = useState(false);
  const [isClickedModifyGymInfo, setIsClickedModifyGymInfo] = useState(false);
  // const [isClickedModifyTerms, setIsClickedModifyTerms] = useState(false);
  const [isClickedRefund, setIsClickedRefund] = useState(false);
  const [refundData, setRefundData] = useState();

  const [productName, setProductName] = useState('');
  const [productCoach, setProductCoach] = useState('');
  const [productValidity, setProductValidity] = useState(0);
  const [productPrice, setProductPrice] = useState(0);
  const [productMerchantUid, setProductMerchantUid] = useState('');

  const [data, setData] = useState(undefined);
  const [salesList, setSalesList] = useState(new Array);
  const [subscribeList, setSubscribeList] = useState(new Array);
  const [rendering, setRendering] = useState(false);

  const [exerciseFollows, setExerciseFollows] = useState([]);
  const [exerciseFollowers, setExerciseFollowers] = useState([]);

  const [partnerGymFollows, setPartnerGymFollows] = useState([]);
  const [partnerGymFollowers, setPartnerGymFollowers] = useState([]);

  const [id, setId] = useState('');
  const [email, setEmail] = useState('');
  const [ownerName, setOwnerName] = useState('');
  const [phone, setPhone] = useState('');
  const [storeLogo, setStoreLogo] = useState('');
  const [storeName, setStoreName] = useState('');
  const [tel, setTel] = useState('');
  const [type, setType] = useState('');
  const [address, setAddress] = useState('');
  const [openingHour, setOpeningHour] = useState('');
  const [storeIntro, setStoreIntro] = useState('');
  const [profiles, setProfiles] = useState(new Array);
  const [isUsePaymentSystem, setIsUsePaymentSystem] = useState('No');

  const [terms, setTerms] = useState({
    id: 0,
    registrationDate: '2000-01-01 00:00:00',
    termsUrl: ''
  })

  const [isClickedExportExcelModal, setIsClickedExportExcelModal] = useState(false);

  // const data = {
  //   storeName: '테스트',
  //   address: '충주시 지현동 1771번지',
  //   tel: '02-311-3233',
  //   openingHour: '09:00 - 21:00',
  //   storeType: '1:1PT'
  // }

  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getIndividualData('account/find', 'GET');

        if (responseData.status === 200) setData(responseData.data[0]);
        else if (responseData.status === 401) history.push('/');

        const subscribeResponseData = await fetchModule.getIndividualData('zone_subscribe/list');
        // console.log(subscribeResponseData.data)
        if (subscribeResponseData.status === 200) {

          let subscribeList = subscribeResponseData.data.slice();

          subscribeList.sort(function (a, b) {
            let x = a.approvalDate;
            let y = b.approvalDate;

            if (x < y) return 1;
            if (x > y) return -1;
          })

          // mergeEvents = ongoingEvents.concat(endedEvents);
          // mergeEvents = mergeEvents.concat(upcomingEvents);

          let finalSubscribes = [];
          let subscribeCount = 0;

          for (let i = 0; i < subscribeList.length; i++) {
            if ((subscribeList[i].itemName === 'Link-Zone 정기 구독권' 
              || subscribeList[i].itemName === 'Link-Zone 정기 구독권 (1개월)')
              && subscribeCount < 2) {
              finalSubscribes.push(subscribeList[i]);
              subscribeCount += 1;
            }
            else if (subscribeList[i].itemName !== 'Link-Zone 정기 구독권'
              && subscribeList[i].itemName !== 'Link-Zone 정기 구독권 (1개월)'
              && !subscribeList[i].itemName.includes('카카오 알림톡 사용')
            ) finalSubscribes.push(subscribeList[i]);
          }

          // setSubscribeList(subscribeList);
          setSubscribeList(finalSubscribes);
        }

        // const salesResponseData = await fetchModule.getIndividualData('sales/list', 'GET');
        // if (salesResponseData.status === 200) {
        //   var salesList = salesResponseData.data.slice();
        //   var finalSalesList = [];

        //   for (var i = 0; i < salesList.length; i++) {
        //     if (salesList[i].status !== 0) finalSalesList.push(salesList[i]);
        //   }

        //   setSalesList(finalSalesList);
        // }
      }
    )();
  }, [history, rendering])

  // 센터의 개별 가입 약관 내역 Call //
  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getIndividualData('center_terms/', 'GET');
        // console.log(responseData);
        if (responseData.status === 200) setTerms(responseData.data[0]);
      }
    )();
  }, [])

  // 운동 프로그램 팔로우 데이터 //
  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getDataOfExerciseVersion2('share/', 'GET');
        
        if (responseData.status === 200) {
          setExerciseFollows(responseData.data.follows);
          setExerciseFollowers(responseData.data.followers);
        }
      }
    )();
  }, [])

  // 파트너 체육관 데이터 //
  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getDataVersion2('partner_gym/', 'GET');
        // console.log(responseData);
        if (responseData.status === 200) {
          setPartnerGymFollows(responseData.data.follows);
          setPartnerGymFollowers(responseData.data.followers);
        }
      }
    )();
  }, [])

  // Data Setting //
  useEffect(() => {
    if (data === undefined) return;

    setId(data.loginId);
    setEmail(data.email);
    setOwnerName(data.ownerName);
    setPhone(data.phone);
    setStoreLogo(data.storeLogo);
    setStoreName(data.storeName);
    setAddress(data.storeAddress);
    setTel(data.storeTel);
    setType(data.storeType);
    setOpeningHour(data.openingHour);
    setStoreIntro(data.introduce);
    setIsUsePaymentSystem(data.isUsePaymentSystem);
    // setProfiles(data.profiles.split(','));

    let dates = data.profiles.split(',');
    let newData = [];

    for (let i = 0; i < dates.length; i++) {
      if (dates[i] === '') break;
      newData.push(dates[i]);
    }

    setProfiles(newData);
  }, [data])

  // useEffect(() => {
  //   console.log(isClickedExportExcelModal);
  // }, [isClickedExportExcelModal])

  function onClickModify() {
    if (isClickedModify) {

      var newData = '';

      for (var i = 0; i < profiles.length; i++) {
        if (i === 0) newData = profiles[i];
        else newData = newData + '<-@->' + profiles[i];
      }

      var requestData = {
        email: data.email,
        loginId: data.loginId,
        openingHour: openingHour,
        ownerName: ownerName,
        ownerSocialSecurityNul: '',
        phone: phone,
        introduce: storeIntro,
        profiles: newData,
        storeAddress: address,
        storeLogo: storeLogo,
        storeName: storeName,
        storeTel: tel,
        storeType: type,
        isUsePaymentSystem: isUsePaymentSystem,
      }
      // console.log(requestData);
      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postData('account/modify', 'POST', requestData);

          // console.log(responseData);
          if (responseData.status === 200) setData(responseData.data[0]);
          else if (responseData.status === 401) history.push('/');
        }
      )();
      setIsClickedModify(false);
    }
    else {
      setIsClickedModify(true);
    }
  }

  function onClickRefundButton(data) {

    // console.log(data);
    if (data.status === 0) {
      alert("아직 승인되지 않은 결제 내역입니다.");
      return;
    }
    else if (data.status === 2) {
      alert("옳바르지 않은 결제 내역입니다.");
      return;
    }
    else if (data.status === 3) {
      alert("이미 환불이 진행된 결제 내역입니다.");
      return;
    }

    setRefundData(data);
    setIsClickedRefund(true);
  }

  // 구독하기 클릭시 //
  function onClickSubscribeButton() {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getIndividualData('zone_subscribe/check', 'GET');
        
        if (responseData.status === 200) setIsClickedSubscribe(true);
        else if (responseData.status === 201) {
          setBillingInfo(responseData.data[0]);
          setIsViewBillingInfoModal(true);
        }
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }

  // 계정정보 수정하기, 저장하기 클릭시 //
  function onClickModifyAccount() {
    if (isClickedModifyAccount) {
      // API Call //

      if (ownerName === '') {
        alert('관리자 성함을 정확하게 입력해주세요');
        return;
      }
      else if (phone === '' || phone.length !== 11) {
        alert('전화번호를 정확하게 입력해주세요');
        return;
      }

      var requestData = {
        // email: email,
        ownerName: ownerName,
        phone: phone,
      }
      
      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataVersion2('account/info/modify', 'POST', requestData);
          
          if (responseData.status === 200) setData(responseData.data);
          else if (responseData.status === 401) history.push('/');
        }
      )();
      setIsClickedModifyAccount(false);
    }
    else {
      if (autoSave()) setIsClickedModifyAccount(true);
    }
  }

  // 센터 정보 저장하기 클릭시 //
  function onClickGymIntroSave() {
    // API Call //

    if (storeName === '') {
      alert('관리자 성함을 정확하게 입력해주세요');
      return;
    }
    else if (tel === '') {
      alert('전화번호를 정확하게 입력해주세요');
      return;
    }
    else if (type === '') {
      alert('센터 타입을 선택해주세요');
      return;
    }

    var requestData = {
      centerName: storeName,
      tel: tel,
      type: type,
      address: address,
      openingHour: openingHour,
      isUsePaymentSystem: isUsePaymentSystem,
      introduce: storeIntro,
      logo: storeLogo
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataVersion2('account/center_info/modify', 'POST', requestData);

        if (responseData.status === 200) {
          setData(responseData.data);
          setIsClickedModifyGymInfo(false);
        }
        else if (responseData.status === 201) {
          alert('결제 시스템 사용은 승인된 업체만 가능합니다.\n사용을 원하시면 관리자에게 문의 부탁드립니다.');
          setIsUsePaymentSystem('No');
          return;
        }
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }

  // 데이터 수정중 다른 컴포넌트 수정하기 버튼 클릭시 자동 저장 //
  function autoSave() {
    // 계정 정보 수정중 다른 컴포넌트 수정 클릭시 //
    if (isClickedModifyAccount) {
      if (ownerName === '') {
        alert('관리자 성함을 정확하게 입력해주세요');
        return false;
      }
      else if (phone === '' || phone.length !== 11) {
        alert('전화번호를 정확하게 입력해주세요');
        return false;
      }

      var requestData = {
        // email: email,
        ownerName: ownerName,
        phone: phone,
      }
      
      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataVersion2('account/info/modify', 'POST', requestData);
          
          if (responseData.status === 200) setData(responseData.data);
          else if (responseData.status === 401) history.push('/');
        }
      )();

      setIsClickedModifyAccount(false);

      return true;
    }
    // 센터 정보 수정중 다른 컴포넌트 수정 클릭시 //
    else if (isClickedModifyGymInfo) {

      if (storeName === '') {
        alert('관리자 성함을 정확하게 입력해주세요');
        return false;
      }
      else if (tel === '') {
        alert('전화번호를 정확하게 입력해주세요');
        return false;
      }
      else if (type === '') {
        alert('센터 타입을 선택해주세요');
        return false;
      }
  
      var requestData = {
        centerName: storeName,
        tel: tel,
        type: type,
        address: address,
        openingHour: openingHour,
        isUsePaymentSystem: isUsePaymentSystem,
        introduce: storeIntro,
        logo: storeLogo
      }
  
      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataVersion2('account/center_info/modify', 'POST', requestData);
          
          if (responseData.status === 200) {
            setData(responseData.data);
            setIsClickedModifyGymInfo(false);
          }
          else if (responseData.status === 201) {
            alert('결제 시스템 사용은 승인된 업체만 가능합니다.\n사용을 원하시면 관리자에게 문의 부탁드립니다.');
            setIsUsePaymentSystem('No');
            return;
          }
          else if (responseData.status === 401) history.push('/');
          else {
            setIsClickedModifyGymInfo(false);
          }
        }
      )();

      return true;
    }
    else return true;
  }

  // 센터 정보 수정하기 클릭시 //
  function onClickModifyCenterInfo() {
    if (autoSave()) setIsClickedModifyGymInfo(true);
  }

  // 운동 프로그램 팔로우 신청 클릭시 //
  function onClickExerciseFollowApplicateButton(data, isFollow) {
    // 팔로우 창에서 실행된 경우 //
    if (isFollow) {
      if (data.status === undefined) {
        let requestData = {
          followStoreId: data.storeId
        }
    
        const fetchModule = new FetchModule();
        (
          async () => {
            const responseData = await fetchModule.postDataOfExerciseVersion2('share/application', 'POST', requestData);
            
            if (responseData.status === 200) {
              alert('팔로우 신청이 완료되었습니다');
              setExerciseFollows(responseData.data.follows);
              setExerciseFollowers(responseData.data.followers);
            }
          }
        )();
      }
      else {
        let requestData = {
          shareId: data.shareId
        }
    
        const fetchModule = new FetchModule();
        (
          async () => {
            const responseData = await fetchModule.postDataOfExerciseVersion2('share/delete', 'POST', requestData);
            
            if (responseData.status === 200) {
              alert('팔로우 신청취소가 완료되었습니다');
              setExerciseFollows(responseData.data.follows);
              setExerciseFollowers(responseData.data.followers);
            }
          }
        )();
      }
    }
    // 팔로워 창에서 실행된 경우 //
    else {
      if (!data.status) {
        let requestData = {
          shareId: data.shareId
        }
    
        const fetchModule = new FetchModule();
        (
          async () => {
            const responseData = await fetchModule.postDataOfExerciseVersion2('share/apply', 'POST', requestData);
            
            if (responseData.status === 200) {
              alert('팔로우 승인이 완료되었습니다');
              setExerciseFollows(responseData.data.follows);
              setExerciseFollowers(responseData.data.followers);
            }
          }
        )();
      }
      else {
        let requestData = {
          shareId: data.shareId
        }
    
        const fetchModule = new FetchModule();
        (
          async () => {
            const responseData = await fetchModule.postDataOfExerciseVersion2('share/delete', 'POST', requestData);
            
            if (responseData.status === 200) {
              alert('팔로우 취소가 완료되었습니다');
              setExerciseFollows(responseData.data.follows);
              setExerciseFollowers(responseData.data.followers);
            }
          }
        )();
      }
    }
  }

  // 파트너 체육관 팔로우 신청 클릭시 //
  function onClickPartnerGymFollowApplicateButton(data, isFollow) {
    // 팔로우 창에서 실행된 경우 //
    if (isFollow) {
      if (data.status === undefined) {
        let requestData = {
          followStoreId: data.storeId
        }
    
        const fetchModule = new FetchModule();
        (
          async () => {
            const responseData = await fetchModule.postDataVersion2('partner_gym/application', 'POST', requestData);
            
            if (responseData.status === 200) {
              alert('팔로우 신청이 완료되었습니다');
              setPartnerGymFollows(responseData.data.follows);
              setPartnerGymFollowers(responseData.data.followers);
            }
          }
        )();
      }
      else {
        let requestData = {
          partnerId: data.partnerId
        }
        
        const fetchModule = new FetchModule();
        (
          async () => {
            const responseData = await fetchModule.postDataVersion2('partner_gym/delete', 'POST', requestData);
            
            if (responseData.status === 200) {
              alert('팔로우 신청취소가 완료되었습니다');
              setPartnerGymFollows(responseData.data.follows);
              setPartnerGymFollowers(responseData.data.followers);
            }
          }
        )();
      }
    }
    // 팔로워 창에서 실행된 경우 //
    else {
      if (!data.status) {
        let requestData = {
          partnerId: data.partnerId
        }
        
        const fetchModule = new FetchModule();
        (
          async () => {
            const responseData = await fetchModule.postDataVersion2('partner_gym/apply', 'POST', requestData);
            
            if (responseData.status === 200) {
              alert('팔로우 승인이 완료되었습니다');
              setPartnerGymFollows(responseData.data.follows);
              setPartnerGymFollowers(responseData.data.followers);
            }
          }
        )();
      }
      else {
        let requestData = {
          partnerId: data.partnerId
        }
    
        const fetchModule = new FetchModule();
        (
          async () => {
            const responseData = await fetchModule.postDataVersion2('partner_gym/delete', 'POST', requestData);
            
            if (responseData.status === 200) {
              alert('팔로우 취소가 완료되었습니다');
              setPartnerGymFollows(responseData.data.follows);
              setPartnerGymFollowers(responseData.data.followers);
            }
          }
        )();
      }
    }
  }

  return (
    <Container>
      {isClickedSubscribe && <SubscribeModal
        name={productName}
        validity={productValidity}
        price={productPrice}
        merchantUid={productMerchantUid}
        setIsClicked={setIsClickedSubscribe} />}
      { isViewBillingInfoModal && <BillingInfoModal data={billingInfo} onClickExit={() => setIsViewBillingInfoModal(false)}/> }
      {isClickedRefund && <RefundModal refundData={refundData} rendering={() => setRendering(!rendering)} exitClick={() => setIsClickedRefund(false)} />}
      {isClickedExportExcelModal && <MemberListExportModal onClickExit={() => setIsClickedExportExcelModal(false)} />}
      <MyPageTopModel onClickDownloadMemberList={setIsClickedExportExcelModal} onClickSubscribe={onClickSubscribeButton} />
      <MyAccountView 
        // onClickSubscribeButton={onClickSubscribeButton} 
        onClickModify={onClickModifyAccount}
        isClicked={isClickedModifyAccount}
        // setIsClicked={setIsClickedModifyAccount}
        id={id} 
        setData={setData} 
        email={email} 
        owenrName={ownerName} 
        setOwnerName={setOwnerName} 
        phone={phone} 
        setPhone={setPhone}/>
      {
        isClickedModifyGymInfo ?
          <MyGymIntroductionEdit
            data={data}
            storeLogo={storeLogo}
            setStoreLogo={setStoreLogo}
            storeName={storeName}
            setStoreName={setStoreName}
            tel={tel}
            setTel={setTel}
            type={type}
            setType={setType}
            address={address}
            setAddress={setAddress}
            openingHour={openingHour}
            setOpeningHour={setOpeningHour}
            isUsePaymentSystem={isUsePaymentSystem}
            setIsUsePaymentSystem={setIsUsePaymentSystem}
            storeIntro={storeIntro}
            setStoreIntro={setStoreIntro}
            onClickSave={onClickGymIntroSave} />
          :
          <MyGymIntroduction
            storeLogo={storeLogo}
            storeName={storeName}
            tel={tel}
            type={type}
            address={address}
            openingHour={openingHour}
            isUsePaymentSystem={isUsePaymentSystem}
            storeIntro={storeIntro}
            // setIsModifyGymInfo={setIsClickedModifyGymInfo} 
            onClickModifyCenterInfo={onClickModifyCenterInfo}/>
      }
      {/* <MyGymIntroductionText isClicked={isClickedModify} storeIntro={storeIntro} setStoreIntro={setStoreIntro}/> */}
      <MyGymPicture profiles={profiles} setProfiles={setProfiles} setData={setData} autoSave={autoSave}/>
      {
        // isClickedModifyTerms ?
        <CenterSubscriptionTermsEdit terms={terms} setTerms={setTerms}/>
          // :
          // <CenterSubscriptionTermsView terms={terms} setIsClickedModify={setIsClickedModifyTerms}/>
      }
      <CenterFollowView follows={exerciseFollows} onClickFollow={onClickExerciseFollowApplicateButton}/>
      <CenterFollowerView followers={exerciseFollowers} onClickFollow={onClickExerciseFollowApplicateButton}/>
      <PartnerGymFollowView follows={partnerGymFollows} onClickFollow={onClickPartnerGymFollowApplicateButton}/>
      <PartnerGymFollowerView followers={partnerGymFollowers} onClickFollow={onClickPartnerGymFollowApplicateButton}/>
      <MyZoneSubscribeInfo subscribeList={subscribeList} />
      {/* <MyZoneMemberPaymentView salesList={salesList} onClickRefundButton={onClickRefundButton} /> */}
    </Container>
  )
}

export default MySettingPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
`;