import { React, useEffect, useState } from 'react';
import styled from 'styled-components';

// 결제모듈 테스트 페이지 입니다.
function Test(props) {
  const { clickFn } = props;

  const [isClicked, setIsClicked] = useState(false);
  
  useEffect(() => {
    const jquery = document.createElement('script');
    jquery.src = "https://code.jquery.com/jquery-1.12.4.min.js";

    const iamport = document.createElement('script');
    iamport.src = "https://cdn.iamport.kr/js/iamport.payment-1.2.0.js";

    document.head.appendChild(jquery);
    document.head.appendChild(iamport);

    return () => {
      document.head.removeChild(jquery);
      document.head.removeChild(iamport);
    }
  }, []);

  function onClickPayment() {
    const { IMP } = window;
    IMP.init('imp22047599');

    setIsClicked(true);
    // clickFn(true);
    
    // Payment Data
    const data = {
      pg: 'tosspay',
      pay_method: 'card',
      name: '테스트입니다',
      amount: '1000',
      merchant_uid: '1234098751',
      custom_data: {
        name: '부가정보',
        desc: '세부 부가정보'
      },
      buyer_name: '안형진',
      buyer_tel: '01055192354',
      buyer_email: 'flic.jin@gamil.com',
      buyer_addr: '충주시 지현동 1771번지',
      buyer_postalcode: '05258'
      // m_redirect_url: 'http://localhost:3000/main'
    }

    IMP.request_pay(data, callback);
  }

  function callback(response) {
    const { success, error_msg, imp_uid, merchant_uid, pay_method, paid_amount, status } = response;

    if (success) {
      alert("테스트 성공?");
    }
    else {
      alert("테스트 실패!");
    }
    
    setIsClicked(false);
    // clickFn(false);
  }

  return (
    <TopLevelWrapper>
      { isClicked && <Test1/> }
      { isClicked && <PaymentBackground/> }
      <Button onClick={onClickPayment}>결제</Button>
    </TopLevelWrapper>
  )
}

export default Test;

// const TopLevelWrapper = styled.div`
//   position: absolute;
//   display: flex;
//   flex-direction: column;

//   justify-content: center;
//   align-items: center;
  
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;

//   > div {
//     position: absolute;
//     left: 0;
//     right: 0;
//   }
//   > div:first-child {
//     background-color: #344e81;
//     top: 0;
//     bottom: 50%;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     flex-direction: column;
//     > * {
//       color: #fff;
//     }
//     h4 {
//       margin: 0;
//       line-height: 1.5;
//     }
//   }
//   > div:nth-child(2) {
//     top: 50%;
//     bottom: 0;
//   }
// `;


const TopLevelWrapper = styled.div`
  width: 350px;
  border-radius: 3px;
  .ant-row {
    margin-bottom: 1rem;
  }
  .ant-form-item {
    display: flex;
    align-items: center;
  }
  .ant-col.ant-form-item-label {
    padding: 0 11px;
    width: 9rem;
    text-align: left;
    label {
      color: #888;
      font-size: 1.2rem;
    }
    & + .ant-col.ant-form-item-control-wrapper {
      width: 26rem;
      .ant-form-item-control {
        line-height: inherit;
      }
    }
  }
  .ant-col.ant-form-item-label > label::after {
    display: none;
  }
  .ant-row.ant-form-item.toggle-container .ant-form-item-control {
    padding: 0 11px;
    height: 4rem;
    display: flex;
    align-items: center;
    .ant-switch {
      margin: 0;
    }
  }
  .ant-form-explain {
    margin-top: 0.5rem;
    margin-left: 9rem;
  }
  .ant-input-group-addon:first-child {
    width: 9rem;
    text-align: left;
    color: #888;
    font-size: 1.2rem;
    border: none;
    background-color: inherit;
  }
  .ant-input-group > .ant-input:last-child {
    border-radius: 4px;
  }
  .ant-col {
    width: 100%;
  }
  button[type='submit'] {
    width: 100%;
    height: 5rem;
    font-size: 1.6rem;
    margin-top: 2rem;
  }
`;

const Test1 = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  
  z-index: 1000;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.3);

  transition: all 0.3s ease-in-out;
`;

const PaymentBackground = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
   
  z-index: 1000;

  width: 100vw;
  height: 100vh;

  background-color: rgba(255, 139, 72, 0.5);

  transition: all 0.3s ease-in-out;
`;

const Button = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 168px;
  height: 32px;

  border-radius: 8px;
  background-color: #33C8FF;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  margin: 0 0 0 0;
  :hover {
    cursor: pointer;
  }
`;