import { React, useRef, useEffect, useState } from 'react';
import styled from 'styled-components';

const DataBar = (props) => {
  const { data, maximum } = props;

  const [cardWidth, setCardWidth] = useState(0);
  const [cashWidth, setCashWidth] = useState(0);
  const [discountWidth, setDiscountWidth] = useState(0);
  const [notReceiveWidth, setNotReceiveWidth] = useState(0);
  const [noneWidth, setNoneWidth] = useState(0);

  const HoverRef = useRef();

  // useEffect(() => {
  //   console.log(typeof(maximum));
  // }, [maximum])

  useEffect(() => {
    // console.log(data);
    // console.log(maximum);
    // console.log(typeof(data.cardPrice));
    // console.log(typeof(data.cashPrice));
    // console.log(typeof(data.notReceived));

    if (maximum === 0) {
      setCardWidth(0);
      setCashWidth(0);
      setNotReceiveWidth(0);
      setNoneWidth(0);

      return;
    }

    if (data.cardPrice !== 0) setCardWidth((data.cardPrice * 450) / maximum);
    if (data.cashPrice !== 0) setCashWidth((data.cashPrice * 450) / maximum);
    if (data.discountPrice !== 0) setDiscountWidth((data.discountPrice * 450) / maximum);
    if (data.notReceived !== 0) setNotReceiveWidth((data.notReceived * 450) / maximum);
    if ((data.cardPrice + data.cashPrice + data.discountPrice + data.notReceived) === 0) setNoneWidth(((maximum / 2) * 450) / maximum);
    // console.log(noneWidth);
  }, [data, maximum])

  function mouseOver(e) {
    if (data.totalPrice === 0) return;
    
    HoverRef.current.style.zIndex = 5;
    HoverRef.current.style.opacity = 1;
  }

  function mouseLeave() {
    if (data.totalPrice === 0) return;

    HoverRef.current.style.zIndex = -5;
    HoverRef.current.style.opacity = 0;
  }

  return (
    <TopLevelWrapper onMouseLeave={mouseLeave} onMouseOver={mouseOver}>
       {
        // cardWidth > 57 ?
        cardWidth > 60 ?
          <DataShape width={cardWidth} color='#F7D17F'>
            <DataText>카드</DataText>
            {/* <DataPercent>({Math.ceil((data.cardPrice / (data.totalPrice)) * 100)}%)</DataPercent> */}
            <DataPercent>({Math.round((data.cardPrice / (data.cardPrice + data.cashPrice + data.notReceived)) * 100)}%)</DataPercent>
          </DataShape>
          :
          <DataShape width={cardWidth} color='#F7D17F' />
      }
      {
        // cashWidth > 57 ?
        cashWidth > 60 ?
          <DataShape width={cashWidth} color='#53B38F'>
            <DataText>현금</DataText>
            <DataPercent>({Math.round((data.cashPrice / (data.cardPrice + data.cashPrice + data.notReceived)) * 100)}%)</DataPercent>
          </DataShape>
          :
          <DataShape width={cashWidth} color='#53B38F' />
      }
      {
        // notReceiveWidth > 57 ?
        notReceiveWidth > 60 ?
          <DataShape width={notReceiveWidth} color='#FF8B48'>
            <DataText>미수금</DataText>
            <DataPercent>({Math.round((data.notReceived / (data.cardPrice + data.cashPrice + data.notReceived)) * 100)}%)</DataPercent>
          </DataShape>
          :
          <DataShape width={notReceiveWidth} color='#FF8B48' />
      }
      {
        data.cardPrice + data.cashPrice + data.notReceived === 0 ?
          <DataShape width={noneWidth} color='#ECECEC'/>
          :
          null
      }
      <HoverBox ref={HoverRef}>
        <InfoBox>
          <ColumnWrapper>
            <Column>총 매출</Column>
            <Column>현금</Column>
            <Column>카드</Column>
            <Column>미수금</Column>
          </ColumnWrapper>
          <ColumnWrapper>
            <Content>{data.totalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Content>
            <Content>{data.cashPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Content>
            <Content>{data.cardPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Content>
            <Content>{data.notReceived.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Content>
          </ColumnWrapper>
          <ColumnWrapper>
            <Unit>원</Unit>
            <Unit>원</Unit>
            <Unit>원</Unit>
            <Unit>원</Unit>
          </ColumnWrapper>
        </InfoBox>
      </HoverBox>
    </TopLevelWrapper>
  )
}

export default DataBar;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  overflow: hidden;
  border-radius: 0px 5px 5px 0px;
  
  transition: all 0.3s ease-in-out;
`;

const DataShape = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: ${props => props.width}px;
  height: 22px;

  background-color: ${props => props.color};

  font-family: NotoSansKR-Bold;
  font-size: 10px;

  overflow: hidden;
  color: #FFFFFF;

  :hover {
    cursor: pointer;
  }
`;

const DataText = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 10px;

  color: #FFFFFF;
  
  margin: 0 0 0 8px;

  :hover {
    cursor: pointer;
  }
`;

const DataPercent = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 10px;

  color: #FFFFFF;

  margin: 0 0 0 3px;
`;

const HoverBox = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 182px;
  height: 128px;

  z-index: -5;
  opacity: 0;

  margin-top: 161px;
  margin-left: 161px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  background-color: #FFFFFF;
  
  transition: all 0.3s ease-in-out;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  gap: 8px;
`;

const Column = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;

  margin: 0 0 0 0;
`;

const Content = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #595959;

  margin: 0 0 0 0;
`;

const Unit = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #BABABA;

  margin: 0 0 0 0;
`;