
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import styled from 'styled-components';

import SocialSecurityInput from './SocialSecurityInput.js';

import ContourPath from '../../image/Contour.png';
import EncryptionNumberPath from '../../image/EncryptionNumber.png';

const SocialSecurityInputWapper = forwardRef((props, ref) => {
	const { wrapperClassName, clickCountFn } = props;
	const [clickCount, setClickCount] = useState(0);

	const oneRef = useRef();
	const twoRef = useRef();
	const threeRef = useRef();
	const fourRef = useRef();
	const fiveRef = useRef();
	const sixRef = useRef();
	const sevenRef = useRef();

	useEffect(() => {
		clickCountFn(wrapperClassName, clickCount);
	}, [clickCount])

	useImperativeHandle(ref, () => ({
		getValue: () => {
			var one = oneRef.current.getValue();
			var two = twoRef.current.getValue();
			var three = threeRef.current.getValue();
			var four = fourRef.current.getValue();
			var five = fiveRef.current.getValue();
			var six = sixRef.current.getValue();
			var seven = sevenRef.current.getValue();

			return one + two + three + four + five + six + '-' + seven + '******';
		},
		isSocialSecurityBlank: () => {
			oneRef.current.isBold();
			twoRef.current.isBold();
			threeRef.current.isBold();
			fourRef.current.isBold();
			fiveRef.current.isBold();
			sixRef.current.isBold();
			sevenRef.current.isBold();
		}
	}));

	function onChangeValue(className) {
		if (className === 'one') twoRef.current.focus();
		else if (className === 'two') threeRef.current.focus();
		else if (className === 'three') fourRef.current.focus();
		else if (className === 'four') fiveRef.current.focus();
		else if (className === 'five') sixRef.current.focus();
		else if (className === 'six') sevenRef.current.focus();
		else if (className === 'seven') setClickCount(clickCount + 1);
	}

	return (
		<InputWrapper>
			<SocialSecurityInput ref={oneRef} className='one' onChangeValue={onChangeValue}/>
			<SocialSecurityInput ref={twoRef} className='two' onChangeValue={onChangeValue}/>
			<SocialSecurityInput ref={threeRef} className='three' onChangeValue={onChangeValue}/>
			<SocialSecurityInput ref={fourRef} className='four' onChangeValue={onChangeValue}/>
			<SocialSecurityInput ref={fiveRef} className='five' onChangeValue={onChangeValue}/>
			<SocialSecurityInput ref={sixRef} className='six' onChangeValue={onChangeValue}/>
			<Contour src={ContourPath}/>
			<SocialSecurityInput ref={sevenRef} className='seven' onChangeValue={onChangeValue}/>
			<EncryptionNumber src={EncryptionNumberPath}/>
			<EncryptionNumber src={EncryptionNumberPath}/>
			<EncryptionNumber src={EncryptionNumberPath}/>
			<EncryptionNumber src={EncryptionNumberPath}/>
			<EncryptionNumber src={EncryptionNumberPath}/>
			<EncryptionNumber src={EncryptionNumberPath}/>
		</InputWrapper>
	) 
})

export default SocialSecurityInputWapper;

const InputWrapper = styled.div`
	display: flex;
	flex-direction: row;

	align-items: center;

	margin-top: 16px;
	// margin-top: max(12.5px, 0.8333333333333333vw);
	gap: 8px;
	// gap: max(4.5px, 0.4166666666666667vw);
	// gap: max(5px, 0.4166666666666667vw);
`;

const Contour = styled.img`
	width: 20px;
	// width: max(9px, 1.041666666666667vw);
	// width: max(8px, 1.041666666666667vw);
	height: 2px;
`;

const EncryptionNumber = styled.img`
	width: 11px;
	// width: max(8px, 0.5729166666666667vw);
	// width: max(7.11px, 0.5729166666666667vw);
	height: 11px;
	// height: max(8px, 0.5729166666666667vw);
	// width: max(7.11px, 0.5729166666666667vw);
`;