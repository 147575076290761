import { React, useState } from 'react';
// import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Input from '../../../Component/MySettingPageComponent/InfoInput';
import InputView from '../../../Component/MySettingPageComponent/InputView';
import PasswordInputView from '../../../Component/MySettingPageComponent/PasswordInputView';
import FetchModule from '../../Share/Network/FetchModule';

function MyAccountView(props) {
  const { onClickModify, id, setData, email, owenrName, setOwnerName, phone, setPhone, isClicked, setIsClicked } = props;

  // const history = useHistory();

  // const [isClicked, setIsClicked] = useState(false);

  // function onClickModify() {
  //   if (isClicked) {
  //     // API Call //

  //     if (owenrName === '') {
  //       alert('관리자 성함을 정확하게 입력해주세요');
  //       return;
  //     }
  //     else if (phone === '' || phone.length !== 11) {
  //       alert('전화번호를 정확하게 입력해주세요');
  //       return;
  //     }

  //     var requestData = {
  //       // email: email,
  //       ownerName: owenrName,
  //       phone: phone,
  //     }
      
  //     const fetchModule = new FetchModule();
  //     (
  //       async () => {
  //         const responseData = await fetchModule.postDataVersion2('account/info/modify', 'POST', requestData);
          
  //         if (responseData.status === 200) setData(responseData.data);
  //         else if (responseData.status === 401) history.push('/');
  //       }
  //     )();
  //     setIsClicked(false);
  //   }
  //   else setIsClicked(true);
  // }

  return (
    <TopLevelWrapper>
      <InfoView>
        <TitleWrapper>
          <Title>계정 정보</Title>
          <ModifyButton isClicked={isClicked} onClick={onClickModify}>{isClicked ? `저장하기` : `수정하기`}</ModifyButton>
        </TitleWrapper>
        {
          isClicked ?
            <InfoWrapper>
              <LayerWrapper topMargin={24}>
                <Input column='관리자 성함' defaultVal={owenrName} setVal={setOwnerName} width={346}/>
                <InputView isClicked={isClicked} column='아이디' content={id} />
                <PasswordInputView isClicked={isClicked} column='패스워드' content='●●●●●●●●●●' /> 
              </LayerWrapper>
              <LayerWrapper topMargin={28}>
                <InputView isClicked={isClicked} column='이메일 계정' content={email} />
                <Input column='휴대전화 번호' defaultVal={phone} setVal={setPhone} width={346}/>
                <SubscribeWrapper/>
              </LayerWrapper>
            </InfoWrapper>
            :
            <InfoWrapper>
              <LayerWrapper topMargin={24}>
                <InputView column='관리자 성함' content={owenrName}/>
                <InputView column='아이디' content={id} />
                <InputView column='패스워드' content='●●●●●●●●●●' />
              </LayerWrapper>
              <LayerWrapper topMargin={28}>
                <InputView column='이메일 계정' content={email} />
                <InputView column='휴대전화 번호' content={phone} />
                <SubscribeWrapper>
                  {/* <SubscribeButton onClick={onClickSubscribeButton}>구독하기</SubscribeButton> */}
                </SubscribeWrapper>
              </LayerWrapper>
            </InfoWrapper>
        }
      </InfoView>
    </TopLevelWrapper>
  )
}

export default MyAccountView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 34px;

  width: 100%;
`;

const InfoView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 1191px;
  height: 266px;

  border-radius: 30px;
  background-color: #FFFFFF;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 1135px;
`;

const ModifyButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 85px;
  height: 31px;

  border: ${props => props.isClicked? `1px solid #6DD49E` : `1px solid #DFDFDF`};
  border-radius: 15px;
  background-color: ${props => props.isClicked ? `#6DD49E` : `#FFFFFF`};

  font-family: ${props => props.isClicked ? `NotoSansKR-Bold` : `NotoSansKR-Regular`};
  font-size: 12px;

  color: ${props => props.isClicked ? `#FFFFFF` : `#777777`};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const Title = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 24px;
  border-top: 1px solid #ECECEC;

  width: 1135px;
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;

  margin-top: ${props => props.topMargin}px;
`;

const SubscribeWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-end;

  margin-top: 25px;

  width: 365px;
`;

const SubscribeButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 99px;
  height: 32px;

  border-radius: 8px;
  background-color: #5BD98A;

  font-family: NotoSansKR-Bold;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }
`;