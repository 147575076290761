import React, { useRef, forwardRef, useState, useImperativeHandle, useEffect } from 'react';
import DaumPostcode from 'react-daum-postcode';
import styled from 'styled-components';

const AddressModal = forwardRef((props, ref) => {
  const { exitClick } = props;

  const [name, setName] = useState();
  const [address, setAddress] = useState();
  const [zoneCode, setZoneCode] = useState();
  const [fullAddress, setFullAddress] = useState();
  const [isDaumPost, setIsDaumPost] = useState('false');

  const TopLevelRef = useRef();

  const width = 280;//595;
  const height = 450;
  // const width = '100%';
  // const height = '100%';


  useEffect(() => {
    document.body.style.cssText = `position: fixed; top: -${window.scrollY}px`

    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = `position: ""; top: "";`
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }
  }, [])

  function handleAddress(data) {
    let AllAddress = data.address;
    let extraAddress = '';
    let zoneCodes = data.zonecode;

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
      }
      AllAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
    }
    setFullAddress(AllAddress);
    setZoneCode(zoneCodes);
    exitClick();
  }

  useImperativeHandle(ref, () => ({
    setFullAddress: (AllAddress) => {
      setFullAddress(AllAddress);
    },
    setZoneCode: (setZoneCode) => {
      setZoneCode(setZoneCode);
    },
    getFullAddress: () => {
      return fullAddress;
    },
    setOpacity: (val) => {
      if (val === 0) TopLevelRef.current.style.zIndex = -300;
      else TopLevelRef.current.style.zIndex = 300;
      TopLevelRef.current.style.opacity = val;
    }
  }));

  return (
    <TopLevelWrapper ref={TopLevelRef}>
      <ModalWrapper>
        <TopBarWrapper width={width + 20}>
          <ExitButton onClick={exitClick}>닫기</ExitButton>
        </TopBarWrapper>
        <DoumPostcodeWrapper>
          <DaumPostcode
            onComplete={handleAddress}
            autoClose
            width={width}
            height={height}
            isdaumdost={isDaumPost} />
        </DoumPostcodeWrapper>
      </ModalWrapper>
    </TopLevelWrapper>
  )
})

export default AddressModal;

const TopLevelWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  // z-index: -3;
  z-index: -300;
  opacity: 0;

  background-color: rgba(0, 0, 0, 0.6);
  transition: all 0.3s ease-in-out;
`;

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  // width: calc(100vw - 10px);
  // height: calc(100vw - 16px);
`;

const TopBarWrapper = styled.div`
  // display: flex;
  // flex-direction: row;

  // justify-content: flex-end;

  // background-color: #FF8B48;
  display: flex;
  flex-direction: row;

  justify-content: flex-end;

  width: ${props => props.width}px;

  background-color: #FF8B48;
`;

const ExitButton = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 16px;
  color: #FFFFFF;
  margin: 0 10px 0 0;

  :hover {
    cursor: pointer;
  }
`;

const DoumPostcodeWrapper = styled.div`
  // border: 1px solid #33C8FF;
  width: 100%;
  height: calc(100% - 16px);
`;

