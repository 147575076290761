import { React, useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import FetchModule from '../../Model/Share/Network/FetchModule';

import SelectOffPath from '../../image/SelectOff.png';
import AddImage from '../../image/CategoryAddImage.png';
import ModifyImage from '../../image/CategoryModifyButton.png';
import DeleteImage from '../../image/CategoryDeleteButton.png';

const CategorySelectBox = forwardRef((props, ref) => {
  const { width, changeVal } = props;

  const history = useHistory();
  const [isClicked, setIsClicked] = useState(false);
  const [addCategory, setAddCategory] = useState(false);
  const [value, setValue] = useState({name: ''});
  const [inputVal, setInputVal] = useState('');
  const [modifyVal, setModifyVal] = useState('');
  const [modifyIndex, setModifyIndex] = useState(-1);
  const [rendering, setRendering] = useState(false);
  const [categoryData, setCategoryData] = useState([]);

  const SelectRef = useRef();

	useImperativeHandle(ref, () => ({
		getValue: () => {
      return value;
		},
	}))

  useEffect(() => {
    setCategoryData([]);
    (
      async () => {
        const fetchModule = new FetchModule();
        const data = await fetchModule.getData('membership/category/', 'GET');
        
        if (data.status === 200) setCategoryData(data.data);
        else if (data.status === 401) history.push('/');
        else {};
      }
    )();
  }, [rendering, history])

  useEffect(() => {
    if (categoryData.length !== 0) setValue(categoryData[0]);
  }, [categoryData])

  useEffect(() => {
    if (addCategory) {
      SelectRef.current.style.height = `${28 * (categoryData.length + 1) + 8 + 28}px`; 
    }
    else {
      SelectRef.current.style.height = `${28 * (categoryData.length + 1) + 8}px`; 
    }
  }, [addCategory, categoryData])
  
  useEffect(() => {
    if (isClicked) {
      if (addCategory) {
        SelectRef.current.style.height = `${28 * (categoryData.length + 1) + 8 + 28}px`; 
        SelectRef.current.style.marginTop = `${64 + (28 * (categoryData.length + 1) + 28)}px`;
      }
      else {
        SelectRef.current.style.height = `${28 * (categoryData.length + 1) + 8}px`; 
        SelectRef.current.style.marginTop = `${64 + (28 * (categoryData.length + 1))}px`;
      }

      SelectRef.current.style.border = `1px solid #FF8B48`;
      SelectRef.current.style.opacity = 1;
      SelectRef.current.style.zIndex = 3;
    }
    else {
      SelectRef.current.style.height = `0px`;
      SelectRef.current.style.border = `0px solid #FF8B48`;
      SelectRef.current.style.opacity = 0;
      SelectRef.current.style.zIndex = -30;

      setAddCategory(false);
      setModifyIndex(-1);
    }
  }, [isClicked, addCategory, categoryData])

  useEffect(() => {
    if (modifyIndex !== -1) {
      setModifyVal(categoryData[modifyIndex].name);
    }
  }, [modifyIndex, categoryData])

	function handleChange(e) {
		setInputVal(e.target.value);
	}

  function modifyHandleChange(e) {
		setModifyVal(e.target.value);
  }

  function addCompleteButtonClick(className) {

    if (className === 'Add') {
      const data = {
        // storeId: sessionStorage.getItem('StoreId'),
        storeId: window.localStorage.getItem('StoreId'),
        name: inputVal
      }

      const fetchModule = new FetchModule();
      (
        async () =>{ 
        const responseData = await fetchModule.postData('membership/category/', 'POST', data);
  
        if (responseData.status === 200) {
          setRendering(!rendering);
          setAddCategory(false);
        }
        else if (responseData.status === 401) history.push('/');
        else alert('카테고리 추가에 실패하였습니다.\n잠시후 다시시도해 주세요.');
  
      })();
    }
    else {
      const data = {
        // storeId: sessionStorage.getItem('StoreId'),
        storeId: window.localStorage.getItem('StoreId'),
        name: modifyVal,
        id: categoryData[modifyIndex].id
      }

      const fetchModule = new FetchModule();
      (
        async () =>{ 
        const responseData = await fetchModule.postData('membership/category/modify', 'PATCH', data);
  
        if (responseData.status === 200) {
          setRendering(!rendering);
          setModifyIndex(-1);
        }
        else if (responseData.status === 401) history.push('/');
        else alert('카테고리 수정에 실패하였습니다.\n잠시후 다시시도해 주세요.');
  
      })();
    }
    
  }

  function deleteButtonClick(data) { 
    var id = data.id;

    (
      async () => {
        const fetchModule = new FetchModule();
        data = await fetchModule.deleteData('membership/category/delete/' + id, 'DELETE');

        if (data.status === 200) {
          setRendering(!rendering);
        }
        else if (data.status === 204) alert('해당 카테고리에 회원권이 존재합니다.\n회원권을 모두 삭제후 다시시도하세요');
        else if (data.status === 401) history.push('/');
        else alert('카테고리 삭제에 실패하였습니다.\n잠시후 다시시도해 주세요.')
      }
    )();
  }

  function optionClick(data) {
    setValue(data);
    setAddCategory(false);
    setIsClicked(false);
    changeVal(data);
  }

  return (
    <TopLevelWrapper>
      <Input width={width - 16} buttonPath={SelectOffPath} onClick={() => setIsClicked(!isClicked)}>{value.name}</Input>
      <SelectBox ref={SelectRef} width={width}>
        {
          categoryData.map((data, index) => (
            index !== modifyIndex ?
              <OptionWrapper key={index}>
                <Option onClick={() => optionClick(data)}>{data.name}</Option>
                <CategoryButtonWrapper>
                  <CategoryModifyButton img={ModifyImage} onClick={() => setModifyIndex(index)} />
                  <CategoryDeleteButton img={DeleteImage} onClick={() => deleteButtonClick(data)} />
                </CategoryButtonWrapper>
              </OptionWrapper>
              :
              <InputWrapper>
                <CategoryInput placeholder='카테고리 이름을 입력해주세요' value={modifyVal} onChange={modifyHandleChange} />
                <ButtonWrapper>
                  <AddExitButton onClick={() => setModifyIndex(-1)}>취소</AddExitButton>
                  <AddCompleteButton onClick={() => addCompleteButtonClick('Modify')}>완료</AddCompleteButton>
                </ButtonWrapper>
              </InputWrapper>
          ))
        }
        {
          addCategory ?
          <InputWrapper>
            <CategoryInput placeholder='카테고리 이름을 입력해주세요' onChange={handleChange}/>
            <ButtonWrapper>
              <AddExitButton onClick={() => setAddCategory(false)}>취소</AddExitButton>
              <AddCompleteButton onClick={() => addCompleteButtonClick('Add')}>완료</AddCompleteButton>
            </ButtonWrapper>
          </InputWrapper>
          :
          null
        }
        <CategoryAddButton img={AddImage} onClick={() => setAddCategory(!addCategory)}>추가</CategoryAddButton>
      </SelectBox>
    </TopLevelWrapper>
  )
})

export default CategorySelectBox;

const TopLevelWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  background-color: red;
`;

const Input = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  // width: 436px;
  width: ${props => props.width}px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background: url(${props => props.buttonPath}) no-repeat 97%;
  background-color: #FFFFFF;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #595959;

  margin-top: 8px;
  padding: 0 0 0 16px;

  :hover {
    cursor: pointer;
  }
`;

const SelectBox = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  // width: 452px;
  width: ${props => props.width}px;
  // height: 377px;
  height: 0px;

  border: 1px solid #FF8B48;
  border-radius: 15px;
  background-color: #FFFFFF;
  opacity: 0;

  // margin-top: 56px;

  transition: all 0.3s ease-in-out;
`;

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin: 8px 8px 0 16px;
`;

const Option = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    font-family: NotoSansKR-Medium;
    color: #FF8B48;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  margin: 8px 0 0 16px;
  gap: 170px;
`;

const CategoryInput = styled.input`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #595959;

  border: 0px;

  outline: none;

  ::placeholder {
    font-family: NotoSansKR-Medium;
    font-size: 14px;

    color: #DFDFDF;
  }
`;

const CategoryButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 6px;
`;

const CategoryModifyButton = styled.div`
  display: flex;
  flex-direction: row;
  
  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;

  background: url(${props => props.img}) no-repeat center;
  
  :hover {
    cursor: pointer;
  }
`;

const CategoryDeleteButton = styled.div`
  display: flex;
  flex-direction: row;
  
  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;
  
  background: url(${props => props.img}) no-repeat center;

  :hover {
    cursor: pointer;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const AddExitButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #DFDFDF;

  transition: all 0.3s ease-in-out;
  
  :hover {
    cursor: pointer;
  }
`;

const AddCompleteButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #5BD98A;

  transition: all 0.3s ease-in-out;
  
  :hover {
    cursor: pointer;
  }
`;

const CategoryAddButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #DFDFDF;

  margin: 8px 0 0 0;
  padding: 0 0 0 36px;
  background: url(${props => props.img}) no-repeat 3%;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    font-family: NotoSansKR-Medium;
    color: #FF8B48;
  }
`;