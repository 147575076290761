import { React } from 'react';
import styled from 'styled-components';

function MyPageTopModel(props) {
  const { onClickDownloadMemberList, onClickSubscribe } = props;

  return (
    <TopLevelWrapper>
      <LayerWrapper>
        <TextWrapper>
          <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#232323'>마이페이지</Text>
          <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#777777'>센터 관련 정보를 확인하고 변경할 수 있어요</Text>
        </TextWrapper>
        <RightWrapper>
          <HoverText fontFamily='NotoSansKR-Regular' fontSize={14} color='#FF8B48' onClick={onClickDownloadMemberList}>회원목록 다운로드</HoverText>
          <SubscribeButton onClick={onClickSubscribe}>링크존 구독하기</SubscribeButton>
        </RightWrapper>
      </LayerWrapper>
    </TopLevelWrapper>
  )
}

export default MyPageTopModel;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 1191px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-left: 28px;

  gap: 4px;
`;

const RightWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 17px;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  margin: 0 0 0 0;

  color: ${props => props.color};

  :hover {
    cursor: default;
  }
`;

const HoverText = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  margin: 0 0 0 0;

  color: ${props => props.color};

  :hover {
    cursor: pointer;
  }
`;

const SubscribeButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 144px;
  height: 32px;

  // border: ${props => props.isClicked ? `1px solid #5BD98A` : `1px solid #DFDFDF`};
  border-radius: 8px;

  background-color: #FF8B48;
  
  font-family: NotoSansKR-Bold;
  font-size: 14px;

  margin: 0 0 0 0;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #FF7322;
  }
`;