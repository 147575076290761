import React, { useRef, useState } from "react";
import styled from "styled-components";
import CenterFollowModal from "./CenterFollowModal";
import UserProfile from "../../../Component/Normal/UserProfile";
import DefaultLogoPath from '../../../image/DefaultLogo.png';

export default function CenterFollowView(props) {
  const { follows, onClickFollow } = props;

  const [clickData, setClickData] = useState(undefined);
  const [isClickedFollowSearch, setIsClickedFollowSearch] = useState(false);

  const ButtonRef = useRef();
  const ButtonTextRef = useRef();

  // 찾아보기 버튼 호버링 //
  function onMouseOver() {
    ButtonRef.current.style.border = '1px solid #6DD49E';
    ButtonRef.current.style.backgroundColor = '#6DD49E';
    ButtonTextRef.current.style.color = '#FFFFFF';
  }

  // 찾아보기 버튼 호버링 //
  function onMouseLeave() {
    ButtonRef.current.style.border = '1px solid #DFDFDF';
    ButtonRef.current.style.backgroundColor = '#FFFFFF';
    ButtonTextRef.current.style.color = '#777777';
  }

  // 찾아보기 버튼 클릭시 //
  function onClickSearchButton() {
    setIsClickedFollowSearch(true);
  }

  // 승인 신청 클릭시 //
  function onClickFollowButton(data) {
    onClickFollow(data, true);
    setIsClickedFollowSearch(false);
  }

  // 신청된 박스 클릭시 //
  function onClickStoreBox(data) {
    setClickData(data);
    setIsClickedFollowSearch(true);
  }

  return (
    <TopLevelWrapper>
      {isClickedFollowSearch && <CenterFollowModal isExercise={true} isFollow={true} clickData={clickData} setClickData={setClickData} onClickFollow={onClickFollowButton} close={() => setIsClickedFollowSearch(false)} />}
      <ComponentWrapper>
        <TitleWrapper>
          <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#232323' hover='default'>프로그램 팔로우</Text>
          <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#777777' hover='default'>링크존에서 다른 체육관의 프로그램을 받아볼수 있어요</Text>
        </TitleWrapper>
        <PartnerBox>
          <TopView>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#232323' hover='default'>프로그램 파트너 ({follows.length}명)</Text>
            <SearchButton ref={ButtonRef} onMouseOver={onMouseOver} onMouseLeave={onMouseLeave} onClick={onClickSearchButton}>
              <Text ref={ButtonTextRef} fontFamily='NotoSansKR-Regular' fontSize={12} color='#777777' hover='pointer'>찾아보기</Text>
            </SearchButton>
          </TopView>
          <BottomView>
            {
              follows.length === 0 ?
                <NoneFollowBox>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD' hover='default'>팔로우중인 파트너가 없습니다</Text>
                </NoneFollowBox>
                :
                follows.map((data, index) => (
                  <StoreBox key={index} onClick={() => onClickStoreBox(data)}>
                    <StoreLogoBox approval={data.status}>
                    <UserProfile profile={data.storeLogo} defaultProfile={DefaultLogoPath}/>
                    {
                      !data.status && 
                        <ApprovalWaitWrapper>
                          <Text fontFamily='NotoSansKR-Black' fontSize={14} color='#232323' hover='cursor'>승인 대기중</Text>
                        </ApprovalWaitWrapper>
                    }
                    </StoreLogoBox>
                  </StoreBox>
                ))
            }
          </BottomView>
        </PartnerBox>
      </ComponentWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 52px;

  width: 100%;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 1191px;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-left: 28px;
  
  gap: 4px;
`;

const PartnerBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  padding: 23px 0px 28px 0px;
  margin-top: 38px;

  width: 100%;
  // height: 223px;
  height: 172px;

  border-radius: 30px;
  background-color: #FFFFFF;
`;

const TopView = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding-bottom: 17px;

  width: 95.29%;

  border-bottom: 1px solid #ECECEC;
`;

const BottomView = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 24px;

  gap: 16px;
  
  width: 95.29%;
  height: 99px;
`;

const NoneFollowBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
`;

const StoreBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 99px;
  height: 99px;

  border-radius: 15px;

  overflow: hidden;

  :hover {
    cursor: pointer;
  }
`;

const StoreLogo = styled.img`
  width: 100%;
  height: 100%;

  opacity: ${props => props.approval ? '1' : '0.6'};
`;

const StoreLogoBox = styled.div`
  width: 100%;
  height: 100%;

  opacity: ${props => props.approval ? '1' : '0.6'};
`;

const SearchButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  
  width: 85px;
  height: 30px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const ApprovalWaitWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 99px;
  height: 99px;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;
  
  margin: 0 0 0 0;
  
  color: ${props => props.color};
  
  transition: all 0.3s ease-in-out;

  :hover {
    cursor: ${props => props.hover};
  }
`;