import { React, useEffect, useRef } from 'react';
import styled from 'styled-components';

import LogoPath from '../../../image/CompleteSignUpLogo.png';

export default function ReceiveStartCouponModal(props) {
  const { setIsViewReceiveModal, onClickConfirmCoupon } = props;

  const TopLevelRef = useRef();

  // Modal 창 스크롤 제어 //
  useEffect(() => {
    // document.body.style.cssText = `position: fixed; top: -${window.scrollY}px`

    // return () => {
    //   const scrollY = document.body.style.top;
    //   document.body.style.cssText = `position: ""; top: "";`
    //   window.scrollTo(0, parseInt(scrollY || '0') * -1)
    // }

    document.body.style= `overflow: hidden`;
    return () => document.body.style = `overflow: auto`
  }, [])

	return (
    <Background>
      <TopLevelWrapper ref={TopLevelRef}>
        <LogoWrapper>
          <Logo src={LogoPath} />
        </LogoWrapper>
        <ExplanationWrapper>
          <TextWrapper>
            <Text fontFamily='NotoSansKR-Bold' fontSize={25} color='#FF8B48'>Link Zone</Text>
            <Text fontFamily='NotoSansKR-Medium' fontSize={25} color='#333333'>을 이용해주셔서 감사합니다</Text>
          </TextWrapper>
          <TextWrapper style={{ marginTop: `12px` }}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={18} color='#333333'>고객님의 가입 기념으로</Text>
          </TextWrapper>
          <TextWrapper>
            <Text fontFamily='NotoSansKR-Regular' fontSize={18} color='#333333'>3개월 무료쿠폰을 지급해드렸어요</Text>
          </TextWrapper>
          <TextWrapper>
            <Text fontFamily='NotoSansKR-Regular' fontSize={18} color='#333333'>앞으로 더 나은 서비스로 보답하겠습니다</Text>
          </TextWrapper>
          {/* <KorExplanation>Link Zone을 이용해주셔서 감사합니다</KorExplanation>
          <KorExplanation>3개월 무료이용권을 발급해드렸어요</KorExplanation> */}
        </ExplanationWrapper>
        <ButtonWrapper>
          <ExitButton onClick={() => setIsViewReceiveModal(false)}>
            <ButtonText>계속 이용하기</ButtonText>
          </ExitButton>
          <GoSettingButton onClick={onClickConfirmCoupon}>
            <Text fontFamily='NotoSansKR-Medium' fontSize={18} color='#FFFFFF'>쿠폰 확인하기</Text>
          </GoSettingButton>
        </ButtonWrapper>
      </TopLevelWrapper>
    </Background>
  )
}

const Background = styled.div`
  position: fixed;
	display: flex;
	flex-direction: column;

  justify-content: center;
  align-items: center;

  top: 0;
  left: 0;

  z-index: 500;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.5);
`;

const TopLevelWrapper = styled.div`
	display: flex;
	flex-direction: column;

  justify-content: flex-start;
  align-items: center;

	width: 476px;
	height: 298px;
  
	border-radius: 50px;
  background-color: #FFFFFF;

  transition: all 0.5s ease-in-out;
`;

const LogoWrapper = styled.div`
  position: absolute;

  display: flex;
  flex-direction: column;

  // margin-top: -300px;
  margin-top: -100px;
`;

const Logo = styled.img`
  width: 200px;
  height: 200px;
`;

const ExplanationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  justify-content: center;
  align-items: center;

  // margin-top: 109px;
  margin-top: 84px;

  width: 90%;
  // height: 100px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  margin: 0 0 0 0;

  color: ${props => props.color};
`;

const KorExplanation = styled.pre`
  font-family: NotoSansKR-Bold;
  font-size: 25px;

  margin: 0 0 0 0;

  color: #000000;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  // margin-top: 50px;
  // margin-top: 48px;
  margin-top: 24px;

  width: 80%;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 47%;
  height: 45px;

  border-radius: 23px;
  background-color: #FF8B48;

  transition: all 0.3s ease-in-out;
  
  :hover {
    cursor: pointer;
    background: #FF7322;
  }
`;

const GoSettingButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 47%;
  height: 45px;

  border-radius: 23px;
  background-color: #6DD49E;

  transition: all 0.3s ease-in-out;
  
  :hover {
    cursor: pointer;
    background: #2BCB67;
  }
`;

const ButtonText = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 18px;
  
  color: #FFFFFF;

  margin: 0 0 0 0;
`;