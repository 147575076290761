import { React, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import TopBarLogoPath from '../../../image/TopBarLogo.png';
import ProfilePath from '../../../image/Profile.png';
import HoverNoticePath from '../../../image/Notice.png';
import NotHoverNoticePath from '../../../image/NotHoverNotice.png';
import HoverSettingPath from '../../../image/Setting.png';
import NotHoverSettingPath from '../../../image/NotHoverSetting.png';
import NoticeBox from './NoticeBox';

import KeypadIcon from '../../../image/KeypadIcon.png';
import KeypadHoverIcon from '../../../image/KeypadHoverIcon.png';

function TopBar(props) {
  const { data, setIsViewAdminChangeLogin } = props;
	const history = useHistory();

  const [keypadIcon, setKeypadIcon] = useState(KeypadIcon);

  const width = window?.screen?.width;
  const height = window?.screen?.height;

  function onClickLogout() {
    alert('로그아웃 되었습니다!');

    // window.localStorage.setItem('StoreId', null);
    // window.localStorage.setItem('Token', null);
    // window.localStorage.setItem('RefreshToken', null);
    // window.localStorage.setItem('AccountId', null);

    window.localStorage.removeItem('StoreId');
    window.localStorage.removeItem('Token');
    window.localStorage.removeItem('RefreshToken');
    window.localStorage.removeItem('AccountId');
    
    history.push('/');
  }

  function onClickProfile() {
    // alert("Click Profile");
    history.push('/mypage');
  }

  function onClickSetting() {
    alert("Click Setting");
    // history.push('/setting');
  }

  function onClickKeypad() {
    let childWindow = window.open(
      'https://m.link-zone.io/ready/keypad/' + window.localStorage.getItem('StoreId') + '/' + window.localStorage.getItem('Token') + '/' + window.localStorage.getItem('RefreshToken'), 
      "_blank", 
      `width=${width}, height=${height}`
    );

    // let childWindow = window.open(
    //   'http://localhost:3001/ready/keypad/' + window.localStorage.getItem('StoreId') + '/' + window.localStorage.getItem('Token') + '/' + window.localStorage.getItem('RefreshToken'), 
    //   "_blank", 
    //   `width=${width}, height=${height}`
    // );

    childWindow.location.reload(true);
  }

  function onClickTitle() {
    if (data.isAdmin) setIsViewAdminChangeLogin(true); 
  }

  return (
    <TopLevelWrapper>
      <TotalWrapper>
        <TitleWrapper onClick={() => history.push('/main')}>
          {/* <Logo src={TopBarLogoPath} /> */}
          <Logo src='https://picture.link-zone.org/LinkZoneLogo.png'/>
          <Title>Link Zone</Title>
        </TitleWrapper>
        <DynamicIslandView onClick={onClickTitle}>
          <Text fontFmaily='NotoSansKR-Medium' fontSize={16} color='#FFFFFF' cursor='default'>{data.storeName}</Text>
        </DynamicIslandView>
        <InfoWrapper>
          {/* <AdminButton>

          </AdminButton> */}
          <LogoutButton onClick={onClickLogout}>로그아웃</LogoutButton>
          {/* <KeypadButton onClick={onClickKeypad}/> */}
          <KeypadButton 
            onMouseOver={() => setKeypadIcon(KeypadHoverIcon)} 
            onMouseLeave={() => setKeypadIcon(KeypadIcon)}
            onClick={onClickKeypad}>
            <KeypadImage src={keypadIcon}/>
          </KeypadButton>
          <Profile src={data.storeLogo ? data.storeLogo : ProfilePath} onClick={onClickProfile} />
          <NoticeBox />
          {/* <Notice onClick={onClickNotice}/> */}
          {/* <Setting onClick={onClickSetting}/> */}
        </InfoWrapper>
      </TotalWrapper>
    </TopLevelWrapper>
  );
}

export default TopBar;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100vw;
  min-width: 1194px;
  
  height: 92px;
  // height: max(56px, 4.791666666666667vw);

  box-shadow: 0px 10px 50px rgba(240, 232, 227, 0.3);
  background-color: #FFFFFF;
  // background-color: red;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 1194px;
  max-width: 1194px;
  min-width: 1194px;
  // width: max(351px, 62.1875vw);
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  min-width: 202px;

  :hover {
    cursor: pointer;
  }
`;

const DynamicIslandView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 396px;
  // height: 52px;
  height: 45px;

  border-radius: 30px;
  background: linear-gradient(90deg, #FF8B48 0%, #FFB58A 100%);
`;

const Logo = styled.img`
  // width: 72px;
  width: 54px;
  // width: max(36px, 3.75vw);
  // height: 72px;
  height: 54px;
  // height: max(36px, 3.75vw);
`;

const Title = styled.p`
  font-family: Poppins-SemiBold;
  font-size: 24px;
  color: #FF8B48;

  margin: 0 0 0 16px;
  // margin-top: 0px;
  // margin-right: 0px;
  // margin-bottom: 0px;
  // margin-left: max(8px, 0.8333333333333333vw);
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center; 

  gap: 16px;

  min-width: 141px;
`;

const LogoutButton = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 12px;

  color: #000000;

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;

const Profile = styled.img`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 32px;
  height: 32px;

  border-radius: 32px;

  // background: url(${ProfilePath}) no-repeat center center;
  // background-color: #EBEBEB;
  
  transition: all 0.3s ease-in-out;

  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast;/* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */

  backface-visibility: hidden;
  transform: translateZ(0);

  :hover {
    cursor: pointer;
    // background-color: #FF8B48;
  }
`;

// const KeypadButton = styled.div`
//   display: flex;
//   flex-direction: column;

//   justify-content: center;
//   align-items: center;

//   width: 32px;
//   height: 32px;

//   background-color: red;

//   :hover {
//     cursor: pointer;
//   }
// `;

const KeypadButton = styled.div`
  display: flex;
  flex-direction: row;
  
  width: 32px;
  height: 32px;

  border-radius: 32px;

  justify-content: center;
  align-items: center;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #FF8B48;
  }
`;

const KeypadImage = styled.img`
  width: 24px;
  height: 24px;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const Text = styled.div`
  font-family: ${props => props.fontFmaily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: ${props => props.cursor};
  }
`;

const Notice = styled.div`
  display: flex;
  flex-direction: row;
  
  width: 32px;
  height: 32px;

  border-radius: 32px;

  justify-content: center;
  align-items: center;

  background: url(${NotHoverNoticePath}) no-repeat center center;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background: url(${HoverNoticePath}) no-repeat center center;
    background-color: #FF8B48;
  }
`;

const Setting = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
  
  width: 32px;
  height: 32px;

  border-radius: 32px;

  justify-content: center;
  align-items: center;

  background: url(${NotHoverSettingPath}) no-repeat center center;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background: url(${HoverSettingPath}) no-repeat center center;
    background-color: #FF8B48;
  }
`;
