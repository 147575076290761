import { React, useRef, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';
import AuthenticationBox from './AuthenticationBox';
import SecondAuthenticationBox from './SecondAuthenticationBox';

const SecondUserAuthenticationView = forwardRef((props, ref) => {
  const { clickFn } = props;

  const TopLevelRef = useRef();

  useImperativeHandle(ref, () => ({
		setOpacity: (val) => {
			TopLevelRef.current.style.opacity = val;
		},
    setZindex: (val) => {
			TopLevelRef.current.style.zIndex = val;
    }
	}))

  return (
    <TopLevelWrapper ref={TopLevelRef}>
      <TitleWrapper>
        <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#232323' cursor='default'>2차 비밀번호 입력</Text>
        <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#777777' cursor='default'>통계 데이터 보안을 위해 회원님의 비밀번호를 다시 한번 입력해주세요.</Text>
      </TitleWrapper>
      {/* <AuthenticationBox clickFn={clickFn}/> */}
      <SecondAuthenticationBox clickFn={clickFn}/>
    </TopLevelWrapper>
  )
})

export default SecondUserAuthenticationView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 1020px;
  height: 700px;

  z-index: 3;
  opacity: 1;
  
  transition: all 0.3s ease-in-out;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  gap: 4px;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }
`;