import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';

const RepeatWeekSetting = forwardRef((props, ref) => {

  const [all, setAll] = useState(true);
  const [one, setOne] = useState(true);
  const [two, setTwo] = useState(true);
  const [three, setThree] = useState(true);
  const [four, setFour] = useState(true);
  const [five, setFive] = useState(true);

  // All 버튼 색칠하기 //
  useEffect(() => {
    if (one && two && three && four && five) setAll(true);
  }, [one, two, three, four, five])

	useImperativeHandle(ref, () => ({
		setValue: (val) => {
      if (val.includes('0')) {
        setAll(true);
        setOne(true);
        setTwo(true);
        setThree(true);
        setFour(true);
        setFive(true);
      }
      else {
        setAll(false);
        setOne(false);
        setTwo(false);
        setThree(false);
        setFour(false);
        setFive(false);

        if (val.includes('1')) setOne(true);
        if (val.includes('2')) setTwo(true);
        if (val.includes('3')) setThree(true);
        if (val.includes('4')) setFour(true);
        if (val.includes('5')) setFive(true);
      }
		},
		getValue: () => {
      if (all) return '0';
      else if (one && two && three && four && five) return '0';
      else {
        let data = '';

        if (one) data = data + ',1';
        if (two) data = data + ',2';
        if (three) data = data + ',3';
        if (four) data = data + ',4';
        if (five) data = data + ',5';

        return data.substring(1);
      }
		}
	}))

  // All 버튼 클릭시 //
  function onClickAll() {
    if (!all) {
      setAll(true);
      setOne(true);
      setTwo(true);
      setThree(true);
      setFour(true);
      setFive(true);
    }
    else {
      setAll(false);
      setOne(false);
      setTwo(false);
      setThree(false);
      setFour(false);
      setFive(false);
    }
  }

  // 1주차 버튼 클릭시 //
  function onClickOne() {
    if (one) {
      setAll(false);
      setOne(false);
    }
    else setOne(true);
  }

  // 2주차 버튼 클릭시 //
  function onClickTwo() {
    if (two) {
      setAll(false);
      setTwo(false);
    }
    else setTwo(true);
  }

  // 3주차 버튼 클릭시 //
  function onClickThree() {
    if (three) {
      setAll(false);
      setThree(false);
    }
    else setThree(true);
  }

  // 4주차 버튼 클릭시 //
  function onClickFour() {
    if (four) {
      setAll(false);
      setFour(false);
    }
    else setFour(true);
  }

  // 5주차 버튼 클릭시 //
  function onClickFive() {
    if (five) {
      setAll(false);
      setFive(false);
    }
    else setFive(true);
  }

  return (
    <TopLevelWrapper>
      <Button isClicked={all} onClick={onClickAll}>All</Button>
      <Button isClicked={one} onClick={onClickOne}>1주차</Button>
      <Button isClicked={two} onClick={onClickTwo}>2주차</Button>
      <Button isClicked={three} onClick={onClickThree}>3주차</Button>
      <Button isClicked={four} onClick={onClickFour}>4주차</Button>
      <Button isClicked={five} onClick={onClickFive}>5주차</Button>
    </TopLevelWrapper>
  )
});

export default RepeatWeekSetting;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  margin-top: 8px;

  height: 32px;

  gap: 7px;
`;

const Button = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 39px;
  height: 30px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background-color: ${props => props.isClicked ? '#FF8B48' : '#FFFFFF'};

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: ${props => props.isClicked ? '#FFFFFF' : '#CBCBCB'};

  :hover {
    cursor: pointer;
		border: 1px solid #FF8B48;
  }

  transition: all 0.3s ease-in-out;
`;