import { React } from 'react';
import styled, { keyframes } from 'styled-components';

import SearchView from '../../Model/Individual/AccountModel/SearchView.js';

// 아이디 및 비밀번호 찾기 페이지

const SearchIdentifierPage = () => {
  return (
    <Container>
      <SearchView/>
    </Container>
  );
}

export default SearchIdentifierPage;

const startAnimation = keyframes`
	0% { opacity: 0; }
	50% { opacity: 0.5; }
	100% { opacity: 1; }
`;

const Container = styled.div`
  // position: fixed;
  display: flex;

  justify-content: center;
  align-items: center;

  gap: 132px;
  top: 0;
  left: 0;

  // width: 1920px;
  width: 100vw;
  // min-width: 1194px;
  height: 100vh;

  background-color: #FDFCFB;

	animation-name: ${startAnimation};
	animation-duration: 0.5s;
	animation-timing-function: ease-out;
`;