import { React, useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

import ViewOnPath from '../../image/ViewOn.png';
import ViewOffPath from '../../image/ViewOff.png';

export default function PasswordChangeInput(props) {
	const { value, setValue, placeholder } = props;

  const [isViewPassword, setIsViewPassword] = useState(false);
  // const [hideValue, setHideValue] = useState('');

	const InputRef = useRef();

  // // 비밀번호 가리기 //
  // useEffect(() => {
  //   let newHideValue = '';

  //   for (let i = 0; i < value.length; i++) {
  //     newHideValue += '●';
  //   }

  //   setHideValue(newHideValue);
  // }, [value])

  // useEffect(() => {
  //   console.log(hideValue);
  // }, [hideValue])

	function handleClick(e) {

	}

	function handleChange(e) {
    setValue(e.target.value);
	}

	function handleFocus(e) {
	}

	function handleBlur(e) {
	}

  return (
    <TopLevelWrapper>
      <Input
        ref={InputRef}
        type={isViewPassword ? 'text' : 'password'}
        value={value}
        placeholder={placeholder}
        onClick={handleClick}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur} />
      <ViewIcon onClick={() => setIsViewPassword(!isViewPassword)} src={isViewPassword ? ViewOffPath : ViewOnPath}/>
    </TopLevelWrapper>
  )
};

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  padding: 0px 8px 0px 8px;

  width: calc(100% - 18px);
  height: 32px;

	border: 1px solid #E8E8E8;
	border-radius: 8px;
  background-color: #FFFFFF;
`;

const Input = styled.input`
	font-family: NotoSansKR-Regular;
	font-size: 14px;

	outline: none;
	border: 0px;
	color: #000000;

  width: 100%;
	height: 30px;
  
	::placeholder {
		color: #CBCBCB;
	}
`;

const ViewIcon = styled.img`
	width: 20px;
	height: 20px;

  :hover {
    cursor: pointer;
  }
`;