import { React, useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';

import MemberShipHistoryColumn from './MemberShipHistoryColumn.js';
import MemberShipHistoryInfoView from './MemberShipHistoryInfoView.js';
import CourseHistoryColumnView from './CourseHistoryColumnView.js';
import CourseHistoryInfoView from './CourseHistoryInfoView.js';
import LockerHistoryColumnView from './LockerHistoryColumnView.js';
import LockerHistoryInfoView from './LockerHistoryInfoView.js';
import InfoPagination from '../../../../Component/MemberComponent/InfoPagination.js';

// import Modal //
import PaymentModal from '../PaymentModal.js';
import MemberShipAddModal from './Modal/MemberShipAddModal.js';
import MemberShipEditModal from './Modal/MemberShipEditModal.js';
import MemberShipDeleteModal from './Modal/MemberShipDeleteModal.js';
import MemberShipHoldingModal from './Modal/MemberShipHoldingModal.js';
import MemberShipExtendModal from './Modal/MemberShipExtensionModal.js';
import RefundModal from '../../MySettingModel/RefundModal.js';

import FetchModule from '../../../Share/Network/FetchModule.js';

import ButtonImagePath from '../../../../image/CreateMemberShipButton.png';
import CoachHistoryView from './CoachHistoryView.js';
import HoldingHistoryColumn from './HoldingHistoryColumn.js';
import HoldingHistoryInfoView from './HoldingHistoryInfoView.js';
import HoldingCancelModal from './Modal/HoldingCancelModal.js';
import HoldingDeleteModal from './Modal/HoldingDeleteModal.js';
import HoldingAndExtendsDetailModal from './Modal/HoldingAndExtendsDetailModal.js';
import MemberShipHoldingEditModal from './Modal/MemberShipHoldingEditModal.js';
import MemberShipExtendModifyModal from './Modal/MemberShipExtendModifyModal.js';
import MemberShipExtendDeleteModal from './Modal/MemberShipExtendDeleteModal.js';
import LockerUsageAddModal from './Modal/LockerUsageAddModal.js';
import LockerExtendsModal from '../../LockerModel/LockerExtendsModal.js';
import LockerUsageExtendDeleteModal from './Modal/LockerUsageStopModal.js';
import CourseHistoryAddModal from './Modal/CourseHistoryAddModal.js';
import ProductSalesAddModal from './Modal/ProductSalesAddModal.js';
import ProductUsageColumn from './ProductUsageColumn.js';
import ProductUsageInfoView from './ProductUsageInfoView.js';
import ProductSalesDeleteModal from './Modal/ProductSalesDeleteModal.js';
import PartnerGymMemberShipHistoryInfoView from './PartnerGymMemberShipHistoryInfo.js';
import PartnerGymCourseHistoryInfoView from './PartnerGymCourseHistoryInfoView.js';

const PartnerGymHistoryView = forwardRef((props, ref) => {
  const { 
    memberId, 
    memberData,
    // data, 
    memberShipData,
    // setMemberShipData,
    holdingData, 
    division, 
    lessonHistoryData, 
    physicalData, 
    lockerData, 
    productUsages,
    setProductUsages,
    rendering, 
    lockerRendering
    /*reserveRerendering*/ 
  } = props;
  
  const history = useHistory();

  const [nowPage, setNowPage] = useState('MemberShip');
  const [index, setIndex] = useState(-1);
  const [buttonText, setButtonText] = useState('회원권 추가');

  // const [memberShipData, setMemberShipData] = useState(data);

  const [refundData, setRefundData] = useState(null);
  const [holdingMemberShipData, setHoldingMemberShipData] = useState(undefined);
  const [holdingEditData, setHoldingEditData] = useState(undefined);
  const [holdingDeleteData, setHoldingDeleteData] = useState(undefined);

  const [userData, setUserData] = useState(undefined);

  const [productName, setProductName] = useState('');
  const [productCoach, setProductCoach] = useState('');
  const [productValidity, setProductValidity] = useState(0);
  const [productPrice, setProductPrice] = useState(0);
  const [productCash, setProductCash] = useState(0);
  const [productDiscountPrice, setProductDiscountPrice] = useState(0);
  const [productNotReceivedPrice, setProductNotReceivedPrice] = useState(0);
  const [productMerchantUid, setProductMerchantUid] = useState('');
  const [productCount, setProductCount] = useState(0);

  const [isClickedPayment, setIsClickedPayment] = useState(false);
  const [isClickedProductPayment, setIsClickedProductPayment] = useState(false);
  const [isClickedAddMemberShip, setIsClickedAddMemberShip] = useState(false);
  const [isClickedModify, setIsClickedModify] = useState(false);
  const [isClickedHolding, setIsClickedHolding] = useState(false);
  const [isClickedRefund, setIsClickedRefund] = useState(false);
  const [isClickedExtension, setIsClickedExtension] = useState(false);
  const [isClickedDelete, setIsClickedDelete] = useState(false);

  const [isClickedAddProduct, setIsClickedAddProduct] = useState(false);
  const [isClickedDeleteProduct, setIsClickedDeleteProduct] = useState(false);

  const [isClickedAddCourse, setIsClickedAddCourse] = useState(false);

  const [isClickedHoldingEdit, setIsClickedHoldingEdit] = useState(false);
  const [isClickedHoldingCancel, setIsClickedHoldingCancel] = useState(false);
  const [isClickedHoldingDelete, setIsClickedHoldingDelete] = useState(false);
  const [isClickedHoldingDetail, setIsClickedHoldingDetail] = useState(false);
  const [isClickedModifyExtend, setIsClickedModifyExtend] = useState(false);
  const [isClickedDeleteExtend, setIsClickedDeleteExtend] = useState(false);
  const [isClickedStartLocker, setIsClickedStartLocker] = useState(false);
  const [isClickedExtendsLocker, setIsClickedExtendsLocker] = useState(false);
  const [isClickedDeleteLocker, setIsClickedDeleteLocker] = useState(false);

  const [isExtend, setIsExtend] = useState(false);
  const [clickHoldingAndExtendData, setClickHoldingAndExtendData] = useState(undefined);
  const [clickLockerData, setClickLockerData] = useState(undefined);
  const [clickProductData, setClickProductData] = useState(undefined);

  const [memberPage, setMemberPage] = useState(0);
  const [reservePage, setReservePage] = useState(0);
  const [lockerPage, setLockerPage] = useState(0);
  const [useMemberShips, setUseMemberShips] = useState([]);
  const [useReserve, setUseReserve] = useState([]);
  const [useLocker, setUseLocker] = useState([]);

  const MemberShipAddModalRef = useRef();
  const AddButtonRef = useRef();
  const MemberShipRef = useRef();
  const CourseRef = useRef();
  const CoachRef = useRef();
  const LockerRef = useRef();
  const HoldingRef = useRef();
  const PaginationRef = useRef();
  const HistoryButtonRef = useRef();
  const ProductModalRef = useRef();

  // useImperativeHandle(ref, () => ({
  //   setMemberShipData: (val) => {
  //     setMemberShipData(val);
  //   }
  // }))

  useEffect(() => {
    if (division === 'locker') setNowPage('Locker');
    else if (division === 'holding') setNowPage('Holding');
    else if (division === 'course') setNowPage('Course');
  }, [division])

  // useEffect(() => {
  //   if (nowPage === 'MemberShip') {
  //     setButtonText('회원권 추가');

  //     // AddButtonRef.current.style.opacity = 1;
  //     // AddButtonRef.current.style.zIndex = 1;
  //     // AddButtonRef.current.style.width = '103px';

  //     // PaginationRef.current.style.opacity = 1;
  //     // PaginationRef.current.style.zIndex = 1;
  //   }
  //   else if (nowPage === 'Course') {
  //     setButtonText('수강 내역 추가');
  //     // AddButtonRef.current.style.opacity = 0;
  //     // AddButtonRef.current.style.zIndex = -1;
  //     // AddButtonRef.current.style.opacity = 1;
  //     // AddButtonRef.current.style.zIndex = 1;
  //     // AddButtonRef.current.style.width = '118px';

  //     // PaginationRef.current.style.opacity = 1;
  //     // PaginationRef.current.style.zIndex = 1;
  //   }
  //   // else if (nowPage === 'Product') {
  //   //   setButtonText('구입 물품 추가');

  //   //   AddButtonRef.current.style.opacity = 1;
  //   //   AddButtonRef.current.style.zIndex = 1;
  //   //   AddButtonRef.current.style.width = '118px';
  //   // }
  //   // else if (nowPage === 'Coach') {
  //   //   AddButtonRef.current.style.opacity = 0;
  //   //   AddButtonRef.current.style.zIndex = -1;
  //   //   AddButtonRef.current.style.width = '103px';

  //   //   // PaginationRef.current.style.opacity = 0;
  //   //   // PaginationRef.current.style.zIndex = -1;
  //   // }
  //   // else if (nowPage === 'Locker') {
  //   //   setButtonText('락커 추가');

  //   //   AddButtonRef.current.style.opacity = 1;
  //   //   AddButtonRef.current.style.zIndex = 1;
  //   //   AddButtonRef.current.style.width = '103px';

  //   //   // PaginationRef.current.style.opacity = 1;
  //   //   // PaginationRef.current.style.zIndex = 1;
  //   // }
  //   // else if (nowPage === 'Holding') {
  //   //   AddButtonRef.current.style.opacity = -1;
  //   //   AddButtonRef.current.style.zIndex = -1;
  //   //   AddButtonRef.current.style.width = '103px';

  //   //   // PaginationRef.current.style.opacity = 1;
  //   //   // PaginationRef.current.style.zIndex = 1;
  //   // }
  // }, [nowPage])

  useEffect(() => {
    let requestData = {
      memberId: memberId
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postData('member/recently', 'POST', requestData);
        // console.log(responseData.data);
        if (responseData.status === 200) {
          setUserData(responseData.data[0]);
        }
        else if (responseData.status === 401) {
          alert('로그인 후 다시시도하세요');
          history.push('/');
        }
      }
    )();
  }, [])


  // 컬럼 페이지네이션 //
  useEffect(() => {
    setUseMemberShips(memberShipData.slice((memberPage * 10), (memberPage * 10) + 10));
  }, [memberShipData, memberPage])

  // 컬럼 페이지네이션 //
  useEffect(() => {
    setUseReserve(lessonHistoryData.slice(reservePage * 10, (reservePage * 10) + 10));
  }, [lessonHistoryData, reservePage])

  // 컬럼 페이지네이션 //
  useEffect(() => {
    setUseLocker(lockerData.slice(lockerPage * 10, (lockerPage * 10) + 10));
  }, [lockerData, lockerPage])
  
  // 회원권 수정 버튼 클릭시
  function memberShipModifyClick(index) {
    let merchantUids = memberShipData[index].merchantUids;

    let merchantUidList = merchantUids.split(',');
    
    if (merchantUidList[merchantUidList.length - 1].split('-')[2] === '3') {
      setIsClickedModify(!isClickedModify);
      setIndex(index);
    }
    else alert("링크의 결제서비스를 이용하신 경우 결제내역을 변경하실 수 없습니다!");
  }

  // 회원권 홀딩 버튼 클릭시
  function memberShipHoldingClick(index) {
    setIsClickedHolding(!isClickedHolding);
    setIndex(index);
  }

  // 회원권 연장 버튼 클릭시
  function memberShipExtensionClick(index) {
    setIsClickedExtension(!isClickedExtension);
    // setIsClickedAddMemberShip(true);
    setIsExtend(true);
    setIndex(index);
  }

  // 회원권 삭제 버튼 클릭시
  function memberShipDeleteClick(index) {
    setIsClickedDelete(!isClickedDelete);
    setIndex(index);
  }

  // 모달창에서 닫기 버튼 클릭시
  function exitClick(className) {
    if ( className === 'Modify' ) setIsClickedModify(false);
    else if ( className === 'Holding' ) setIsClickedHolding(false);
    else if ( className === 'Extension' ) {
      setIsExtend(false);
      setIsClickedExtension(false);
    }
    else if ( className === 'Delete' ) setIsClickedDelete(false);

    // HistoryButtonRef.current.setIsClicked();
    setIndex(-1);
    rendering();
  }

  // 회원권 추가 버튼 클릭
  function onClickAddMemberShipOrLockerButton(className) {
    if (className === '회원권 추가') {
      setIsClickedAddMemberShip(true);
    }
    else if (className === '락커 추가') {
      setIsClickedStartLocker(true);
    }
    else if (className === '수강 내역 추가') setIsClickedAddCourse(true);
    else if (className === '구입 물품 추가') setIsClickedAddProduct(true);
  }
  
  // // 홀딩 취소버튼 클릭
  // function onClickHoldingCancel(data) {
    
  //   if (moment(data.endDate).format('YYYY.MM.DD') > moment().format('YYYY.MM.DD')) {
  //     setHoldingCancelData(data);
  //     setIsClickedHoldingCancel(true);
  //   }
  //   else {
  //     alert("이미 종료된 홀딩 정보입니다!");
  //   }
  // }

  // // 홀딩 취소를 진행하지 않을경우
  // function onClickHoldingCancelExit() {
  //   setHoldingCancelData(undefined);
  //   setIsClickedHoldingCancel(false);
  // }

  // 홀딩 수정버튼 클릭
  function onClickHoldingEdit(data) {
    for (let i = 0; i < memberShipData.length; i++) {
      if (memberShipData[i].usageId === data.usageId) {
        setHoldingMemberShipData(memberShipData[i]);
        break;
      }
    }

    setHoldingEditData(data);
    setIsClickedHoldingEdit(true);
    // if (moment(data.endDate).format('YYYY.MM.DD') > moment().format('YYYY.MM.DD')) {

    //   for (var i = 0; i < memberShipData.length; i++) {
    //     if (memberShipData[i].usageId === data.usageId) {
    //       setHoldingMemberShipData(memberShipData[i]);
    //       break;
    //     }
    //   }

    //   setHoldingEditData(data);
    //   setIsClickedHoldingEdit(true);
    // }
    // else {
    //   alert("이미 종료된 홀딩 정보입니다!");
    // }
  }

  // 홀딩 수정을 진행하지 않을경우
  function onClickHoldingEditExit() {
    setIsClickedHoldingEdit(false);
    setHoldingEditData(undefined);
    setHoldingMemberShipData(undefined);
  }

  // 회원권 홀딩 내역 삭제 버튼 클릭시 //
  function onClickDeleteHoldingHistory(data) {
    setHoldingDeleteData(data);
    setIsClickedHoldingDelete(true);
  }

  // 홀딩 내역 삭제를 진행하지 않을경우 //
  function onClickHoldingDeleteExit() {
    setHoldingDeleteData(undefined);
    setIsClickedHoldingDelete(false);
  }

  // 회원권 환불 버튼 클릭
  function onClickRefundButton(index) {
    let merchantList = memberShipData[index].merchantUids.split(',');
    let lastMerchantUid = merchantList[merchantList.length - 1];

    setRefundData({ merchantUid: lastMerchantUid });
    setIsClickedRefund(true);
  }

  // 회원권 홀딩 또는 연장 자세히 보기 버튼 클릭 //
  function onClickHoldingAndExtendButton(data) {
    setClickHoldingAndExtendData(data);
    setIsClickedHoldingDetail(true);
  }

  function onRegist() { 
    // cancelFunction();
    let memberShipData = MemberShipAddModalRef.current.getMemberShipData();
    let cardPrice = MemberShipAddModalRef.current.getCardPrice();
    let cashPrice = MemberShipAddModalRef.current.getCashPrice();
    let discountPrice = MemberShipAddModalRef.current.getDiscountPrice();
    let notReceivedPrice = MemberShipAddModalRef.current.getNotReceivedPrice();
  
    if (memberShipData.name === '선택') {
      alert('회원권을 설정해주세요');
      return;
    }
    else if (cardPrice + cashPrice + discountPrice === 0) {
      // alert('결제 금액을 설정해주세요');
      // return;
      let answer = window.confirm('결제 금액을 설정하지 않으셨습니다.\n계속 진행하시겠습니까?');

      if (answer) {
        console.log("yes");
      }
      else {
        console.log('no');
        return;
      }
    }

    let requestData = {
      itemName: memberShipData.name,
      cashPrice: cashPrice,
      cardPrice: cardPrice,
      discountPrice: discountPrice,
      notReceivedPrice: notReceivedPrice,
      // storeId: SessionStorage.getStoreId(),
      storeId: window.localStorage.getItem('StoreId'),
      memberId: parseInt(memberId),
      status: 0
    }
    // console.log(requestData);
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postData('sales/merchant', 'POST', requestData);
        // console.log(responseData);
        if (responseData.status === 200) {
          setProductName(responseData.data[0].itemName);
          setProductCoach('');
          setProductValidity(memberShipData.validity);
          setProductPrice(responseData.data[0].cardPrice);
          setProductCash(responseData.data[0].cashPrice);
          setProductDiscountPrice(responseData.data[0].discountPrice);
          setProductNotReceivedPrice(responseData.data[0].notReceivedPrice);
          setProductMerchantUid(responseData.data[0].merchantUid);
          setProductCount(1);

          setIsClickedPayment(true);
        }
        else if (responseData.status === 401) {
          alert('로그인 후 다시시도하세요');
          history.push('/');
        }
      }
    )();
  }

  function onRegistForProduct() { 
    if (clickProductData === undefined) {
      let productData = ProductModalRef.current.getProductData();
      let cardPrice = ProductModalRef.current.getCardPrice();
      let cashPrice = ProductModalRef.current.getCashPrice();
      let discountPrice = ProductModalRef.current.getDiscountPrice();
      let notReceivedPrice = ProductModalRef.current.getNotReceivedPrice();
      let count = ProductModalRef.current.getCount();
    
      if (productData === undefined) {
        alert('구매할 물품을 선택해주세요');
        return;
      }
      else if (count === 0) {
        alert('구매할 물품의 개수를 설정해주세요');
        return;
      }
      else if (cardPrice + cashPrice + discountPrice === 0) {
        // alert('결제 금액을 설정해주세요');
        // return;
        let answer = window.confirm('결제 금액을 설정하지 않으셨습니다.\n계속 진행하시겠습니까?');
  
        if (answer) {
          console.log("yes");
        }
        else {
          console.log('no');
          return;
        }
      }
  
      let requestData = {
        itemName: productData.name,
        cashPrice: cashPrice,
        cardPrice: cardPrice,
        discountPrice: discountPrice,
        notReceivedPrice: notReceivedPrice,
        // storeId: SessionStorage.getStoreId(),
        storeId: window.localStorage.getItem('StoreId'),
        memberId: parseInt(memberId),
        status: 0,
        count: count,
        productId: ProductModalRef.current.getProductId()
      }
      // console.log(requestData);
      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postData('sales/merchant', 'POST', requestData);
          console.log(responseData);
          if (responseData.status === 200) {
            setProductName(responseData.data[0].itemName);
            setProductCoach('');
            setProductValidity(memberShipData.validity);
            setProductPrice(responseData.data[0].cardPrice);
            setProductCash(responseData.data[0].cashPrice);
            setProductDiscountPrice(responseData.data[0].discountPrice);
            setProductNotReceivedPrice(responseData.data[0].notReceivedPrice);
            setProductMerchantUid(responseData.data[0].merchantUid);
            setProductCount(count);
  
            setIsClickedProductPayment(true);
          }
          else if (responseData.status === 201) {
            alert('재고가 부족합니다.');
            return;
          }
          else if (responseData.status === 401) {
            alert('로그인 후 다시시도하세요');
            history.push('/');
          }
        }
      )();
    }
    else {

      let storeId = window.localStorage.getItem('StoreId');
      let productData = ProductModalRef.current.getProductData();
      let productId = ProductModalRef.current.getProductId();
      let cardPrice = ProductModalRef.current.getCardPrice();
      let cashPrice = ProductModalRef.current.getCashPrice();
      let discountPrice = ProductModalRef.current.getDiscountPrice();
      let notReceivedPrice = ProductModalRef.current.getNotReceivedPrice();
      let count = ProductModalRef.current.getCount();
  
      let data = {
        id: clickProductData.usageId,
        storeId: parseInt(storeId),
        memberId: parseInt(memberId),
        productId: productId,
        itemName: productData.name,
        merchantUid: clickProductData.merchantUid,
        cardPrice: cardPrice,
        cashPrice: cashPrice,
        discountPrice: discountPrice,
        notReceivedPrice: notReceivedPrice,
        count: count
      }
      
      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postData('product/usage/modify', 'POST', data);
          // console.log(responseData);
          if (responseData.status === 200) {
            alert("구매정보 변경이 완료되었습니다.");
            setIsClickedAddProduct(false);
            setProductUsages(responseData.data);
            setClickProductData(undefined);
            // rendering();
            // history.push('/main');
          }
          else if (responseData.status === 201) {
            alert('재고가 부족합니다.');
            return;
          }
          else if (responseData.status === 401) {
            alert("로그인 후 사용해주세요.");
            history.push('/');
          }
        }
      )();
    }
  }

  function cancelFunction(merchantUid) {

    let storeId = window.localStorage.getItem('StoreId');
    let memberShipId = MemberShipAddModalRef.current.getMemberShipId();
    let cardPrice = MemberShipAddModalRef.current.getCardPrice();
    let cashPrice = MemberShipAddModalRef.current.getCashPrice();
    let startDate = MemberShipAddModalRef.current.getStartDate().replaceAll('.', '-') + 'T00:00:00';
    let endDate = MemberShipAddModalRef.current.getEndDate().replaceAll('.', '-') + 'T23:59:59';
    let approvalDate = MemberShipAddModalRef.current.getApprovalDate().replaceAll('.', '-') + 'T' + moment().format('HH:mm:ss');
    let totalMax = MemberShipAddModalRef.current.getTotalMax();

    let data = {
      storeId: parseInt(storeId),
      memberId: parseInt(memberId),
      memberShipId: memberShipId,
      merchantUid: merchantUid,
      courseTakeCount: 0,
      cardPrice: cardPrice,
      cashPrice: cashPrice,
      startDate: startDate,
      endDate: endDate,
      approvalDate: approvalDate,
      totalMax: totalMax
    }
    
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postData('membership/usage', 'POST', data);

        if (responseData.status === 200) {
          alert("등록이 완료되었습니다.");
          setIsClickedAddMemberShip(false);
          rendering();
          // history.push('/main');
        }
        else if (responseData.status === 401) {
          alert("로그인 후 사용해주세요.");
          history.push('/');
        }
      }
    )();

    setIsClickedPayment(false);
    setIsExtend(false);
  }

  function cancelFunctionForProduct(merchantUid) {

    let storeId = window.localStorage.getItem('StoreId');
    let productId = ProductModalRef.current.getProductId();
    let cardPrice = ProductModalRef.current.getCardPrice();
    let cashPrice = ProductModalRef.current.getCashPrice();
    let discountPrice = ProductModalRef.current.getDiscountPrice();
    let notReceivedPrice = ProductModalRef.current.getNotReceivedPrice();
    let count = ProductModalRef.current.getCount();
    let approvalDate = moment().format('YYYY-MM-DDTHH:mm:ss');

    let data = {
      id: -1,
      storeId: parseInt(storeId),
      memberId: parseInt(memberId),
      productId: productId,
      merchantUid: merchantUid,
      cardPrice: cardPrice,
      cashPrice: cashPrice,
      discountPrice: discountPrice,
      notReceivedPrice: notReceivedPrice,
      count: count,
      approvalDate: approvalDate,
    }
    
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postData('product/usage/save', 'POST', data);

        if (responseData.status === 200) {
          alert("물품구매가 완료되었습니다.");
          setIsClickedAddProduct(false);
          rendering();
          // history.push('/main');
        }
        else if (responseData.status === 401) {
          alert("로그인 후 사용해주세요.");
          history.push('/');
        }
      }
    )();

    setIsClickedProductPayment(false);
    setIsExtend(false);
  }

  function saveRegistData(merchantUid) {
    // var storeId = SessionStorage.getStoreId();
    let storeId = window.localStorage.getItem('StoreId');
    let memberShipId = MemberShipAddModalRef.current.getMemberShipId();
    let cardPrice = MemberShipAddModalRef.current.getCardPrice();
    let cashPrice = MemberShipAddModalRef.current.getCashPrice();
    let startDate = MemberShipAddModalRef.current.getStartDate().replaceAll('.', '-') + 'T' + moment().format('HH:mm:ss');
    let endDate = MemberShipAddModalRef.current.getEndDate().replaceAll('.', '-') + 'T23:59:59';
    let approvalDate = MemberShipAddModalRef.current.getApprovalDate().replaceAll('.', '-') + 'T' + moment().format('HH:mm:ss');

    let data = {
      storeId: parseInt(storeId),
      memberId: parseInt(memberId),
      memberShipId: memberShipId,
      merchantUid: merchantUid,
      courseTakeCount: 0,
      cardPrice: cardPrice,
      cashPrice: cashPrice,
      startDate: startDate,
      endDate: endDate,
      approvalDate: approvalDate
    }

    window.sessionStorage.setItem("RegistData", 'None');
    window.sessionStorage.setItem("UsageData", JSON.stringify(data));
  }

  function saveRegistDataForProduct(merchantUid) {
    let storeId = window.localStorage.getItem('StoreId');
    let productId = ProductModalRef.current.getProductId();
    let cardPrice = ProductModalRef.current.getCardPrice();
    let cashPrice = ProductModalRef.current.getCashPrice();
    let discountPrice = ProductModalRef.current.getDiscountPrice();
    let notReceivedPrice = ProductModalRef.current.getNotReceivedPrice();
    let count = ProductModalRef.current.getCount();
    let approvalDate = moment().format('YYYY-MM-DDTHH:mm:ss');

    let data = {
      storeId: parseInt(storeId),
      memberId: parseInt(memberId),
      productId: productId,
      merchantUid: merchantUid,
      cardPrice: cardPrice,
      cashPrice: cashPrice,
      discountPrice: discountPrice,
      notReceivedPrice: notReceivedPrice,
      count: count,
      approvalDate: approvalDate
    }

    window.sessionStorage.setItem("ProductRegistData", 'None');
    window.sessionStorage.setItem("ProductUsageData", JSON.stringify(data));
  }

  // 연장 내역 수정 클릭시 //
  function onClickModifyExtend(data) {
    setClickHoldingAndExtendData(data);
    setIsClickedModifyExtend(true);
  }

  // 연장 내역 수정 완료 또는 닫기 클릭시 //
  function onClickModifyExit() {
    setIsClickedModifyExtend(false);
    rendering();
  }

  // 연장 내역 삭제 클릭시 //
  function onClickDeleteExtend(data) {
    setClickHoldingAndExtendData(data);
    setIsClickedDeleteExtend(true);
  }

  // 연장내역 삭제 완료 또는 닫기 클릭시 //
  function onClickDeleteExit() {
    setIsClickedDeleteExtend(false);
    rendering();
  }

  // 락커 연장 클릭시 //
  function onClickLockerExtends(data) {
    setClickLockerData(data);
    setIsClickedExtendsLocker(true);
  }

  // 락커 사용종료 클릭시 //
  function onClickLockerExit(data) {
    setClickLockerData(data)
    setIsClickedDeleteLocker(true);
  }

  // 사용 내역 연장 모달 내에서 저장하기 버튼 클릭시 //
  function onClickSaveExtends(endDate, cardPrice, cashPrice) {
    // console.log(lockerInfo);
    // console.log(endDate.clone().format('YYYY-MM-DDTHH:mm:ss'));
    
    if (moment().clone().format('YYYY-MM-DDTHH:mm:ss') > endDate.clone().format('YYYY-MM-DDTHH:mm:ss')) {
      alert('사용 날짜 변경은 금일 보다 이전일 수 없습니다.');
      return;
    }
    
    let requestData = {
      lockerId: clickLockerData.lockerId,
      usageId: clickLockerData.usageId,
      endDate: endDate.clone().format('YYYY-MM-DDT23:59:59'),
      merchantUid: clickLockerData.merchantUid,
      cardPrice: parseInt(cardPrice.toString().replaceAll(',', '')),
      cashPrice: parseInt(cashPrice.toString().replaceAll(',', ''))
    }
    
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postData('locker/extend', 'POST', requestData);

        if (responseData.status === 200) {
          alert('변경이 완료되었습니다.');
          lockerRendering();
          setIsClickedExtendsLocker(false);
        }
      }
    )();
  }

  // 회원권 페이지네이션 다음 클릭시 //
  function onClickMemberNextPage() {
    if (Math.ceil(memberShipData.length / 10) > (memberPage + 1)) setMemberPage(memberPage + 1);
  }

  // 회원권 페이지네이션 이전 클릭시 //
  function onClickMemberPrevPage() {
    if (memberPage > 0) setMemberPage(memberPage - 1);
  }

  // 출석내역 페이지네이션 다음 클릭시 //
  function onClickReserveNextPage() {
    if (Math.ceil(lessonHistoryData.length / 10) > (reservePage + 1)) setReservePage(reservePage + 1);
  }

  // 출석내역 페이지네이션 이전 클릭시 //
  function onClickReservePrevPage() {
    if (reservePage > 0) setReservePage(reservePage - 1);
  }

  // 락커 페이지네이션 다음 클릭시 //
  function onClickLockerNextPage() {
    if (Math.ceil(lockerData.length / 10) > (lockerPage + 1)) setLockerPage(lockerPage + 1);
  }

  // 락커 페이지네이션 이전 클릭시 //
  function onClickLockerPrevPage() {
    if (lockerPage > 0) setLockerPage(lockerPage - 1);
  }

  function getApprovalDate() {
    return MemberShipAddModalRef.current.getApprovalDate().replaceAll('.', '-') + 'T' + moment().format('HH:mm:ss');
  }

  function getApprovalDateForProduct() {
    return moment().format('YYYY-MM-DDTHH:mm:ss');
  }

  // 물품 구입 모달 닫기 버튼 클릭시 //
  function onClickExitAddProduct() {
    setIsClickedAddProduct(false);
    setClickProductData(undefined);
  }

  // 물품 내역 변경 클릭시 //
  function onClickEditProductUsage(data) {
    setClickProductData(data);
    setIsClickedAddProduct(true);
  }

  // 물품 내역 삭제 클릭시 //
  function onClickDeleteProductUsage(data) {
    setClickProductData(data);
    setIsClickedDeleteProduct(true);
  }

  // 물품 내역 삭제 모달에서 삭제하기 클릭시 //
  function onClickDeleteYesProductUsage() {

     let requestData = {
      storeId: window.localStorage.getItem('StoreId'),
      productUsageId: clickProductData.usageId,
      merchantUid: clickProductData.merchantUid,
    }
    
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postData('product/usage/delete', 'POST', requestData);

        if (responseData.status === 200) {
          alert('삭제가 완료되었습니다.');
          setClickProductData(undefined);
          setIsClickedDeleteProduct(false);
          rendering();
        }
      }
    )();
  }

  // 물품 내역 삭제 모달에서 취소 클릭시 //
  function onClickDeleteExitProductUsage() {
    setClickProductData(undefined);
    setIsClickedDeleteProduct(false);
  }

  return (
    <TopLevelWrapper>
      {/* { 
        isClickedPayment && 
        <PaymentModal
          name={productName}
          // coach={productCoach}
          validity={productValidity}
          price={productPrice}
          cashPrice={productCash}
          discount={productDiscountPrice}
          notReceived={productNotReceivedPrice}
          merchantUid={productMerchantUid}
          count={productCount}
          onRegist={cancelFunction}
          cancel={() => setIsClickedPayment(false)}
          saveRegistData={saveRegistData} 
          getApprovalDate={getApprovalDate}/>
      }
      {
        isClickedProductPayment &&
          <PaymentModal
            name={productName}
            validity={productValidity}
            price={productPrice}
            cashPrice={productCash}
            discount={productDiscountPrice}
            notReceived={productNotReceivedPrice}
            merchantUid={productMerchantUid}
            count={productCount}
            onRegist={cancelFunctionForProduct}
            cancel={() => setIsClickedProductPayment(false)}
            saveRegistData={saveRegistDataForProduct} 
            getApprovalDate={getApprovalDateForProduct}/>
        
      }
      { isClickedAddMemberShip && <MemberShipAddModal ref={MemberShipAddModalRef} userData={userData} isExtend={isExtend} data={memberShipData} index={index} registerClick={onRegist} cancel={() => setIsClickedAddMemberShip(false)}/>}
      { isClickedModify && <MemberShipEditModal className='Modify' data={memberShipData} userData={userData} index={index} exitClickFn={exitClick} /> }
      { isClickedHolding && <MemberShipHoldingModal className='Holding' data={memberShipData} index={index} exitClickFn={exitClick} /> }
      { isClickedRefund && <RefundModal refundData={refundData} rendering={rendering} exitClick={() => setIsClickedRefund(false)}/> }
      { isClickedExtension && <MemberShipExtendModal className='Extension' data={memberShipData} index={index} memberData={memberData} exitClickFn={exitClick} /> }
      { isClickedDelete && <MemberShipDeleteModal className='Delete' data={memberShipData} index={index} exitClickFn={exitClick} rendering={rendering}/> }
      
      { isClickedAddCourse && <CourseHistoryAddModal memberId={memberId} rendering={rendering} onClickClose={() => setIsClickedAddCourse(false)}/> }

      { isClickedHoldingEdit && <MemberShipHoldingEditModal data={holdingEditData} memberShipData={holdingMemberShipData} exitClickFn={onClickHoldingEditExit} rendering={rendering}/> }
      { isClickedHoldingDelete && <HoldingDeleteModal data={holdingDeleteData} exitClickFn={onClickHoldingDeleteExit} rendering={rendering} /> }
      { isClickedHoldingDetail && <HoldingAndExtendsDetailModal data={clickHoldingAndExtendData} closeFn={() => setIsClickedHoldingDetail(false)}/> }
      { isClickedModifyExtend && <MemberShipExtendModifyModal data={clickHoldingAndExtendData} onClickExit={onClickModifyExit} /> }
      { isClickedDeleteExtend && <MemberShipExtendDeleteModal data={clickHoldingAndExtendData} onClickExit={onClickDeleteExit} /> }
      
      { isClickedStartLocker && <LockerUsageAddModal memberName={memberData.name} lockerRendering={lockerRendering} onClickExit={() => setIsClickedStartLocker(false)}/> }
      { isClickedExtendsLocker && <LockerExtendsModal data={clickLockerData} saveFn={onClickSaveExtends} closeFn={() => setIsClickedExtendsLocker(false)}/> }
      { isClickedDeleteLocker && <LockerUsageExtendDeleteModal data={clickLockerData} lockerRendering={lockerRendering} onClickExit={() => setIsClickedDeleteLocker(false)}/> } */}
      
      {/* 물품 구입 모달 */}
      {/* { isClickedAddProduct && <ProductSalesAddModal ref={ProductModalRef} clickUsageData={clickProductData} setClickData={setClickProductData} onRegist={onRegistForProduct} onClickExit={onClickExitAddProduct} /> }
      { isClickedDeleteProduct && <ProductSalesDeleteModal deleteClickFn={onClickDeleteYesProductUsage} exitClickFn={onClickDeleteExitProductUsage}/> } */}
      {/* 물품 구입 모달 */}
      
      <ButtonWrapper>
        <SortButtonWrapper>
          <Button className='MemberShip' nowPage={nowPage} width={64} onClick={() => setNowPage('MemberShip')}>회원권</Button>
          <Button className='Course' nowPage={nowPage} width={75} onClick={() => setNowPage('Course')}>수강내역</Button>
          {/* <Button className='Product' nowPage={nowPage} width={75} onClick={() => setNowPage('Product')}>구입 물품</Button>
          <Button className='Locker' nowPage={nowPage} width={55} onClick={() => setNowPage('Locker')}>락커</Button> */}
        </SortButtonWrapper>
        {/* <CreateButton ref={AddButtonRef} img={ButtonImagePath} onClick={() => onClickAddMemberShipOrLockerButton(buttonText)}>{buttonText}</CreateButton> */}
      </ButtonWrapper>
      { nowPage === 'MemberShip' &&
        <InfoBox ref={MemberShipRef}>
          <MemberShipHistoryColumn />
          {
            useMemberShips.map((data, index) => (
              <PartnerGymMemberShipHistoryInfoView
                key={index}
                ref={HistoryButtonRef}
                size={useMemberShips.length}
                data={data}
                index={index}
                modifyClick={memberShipModifyClick}
                holdingClick={memberShipHoldingClick}
                extensionClick={memberShipExtensionClick}
                refundClick={onClickRefundButton}
                deleteClick={memberShipDeleteClick}

                onClickHoldingAndExtendButton={onClickHoldingAndExtendButton} 
                onClickHoldingEdit={onClickHoldingEdit}
                onClickDeleteHoldingHistory={onClickDeleteHoldingHistory}
                onClickModifyExtend={onClickModifyExtend}
                onClickDeleteExtend={onClickDeleteExtend} />
            ))
          }
        </InfoBox>
      }
      {
        nowPage === 'Course' &&
        <InfoBox ref={CourseRef} style={{ /*marginTop: `-600px` /*marginTop: `-332px`*/ }}>
          <CourseHistoryColumnView />
          {
            useReserve.map((data, index) => (
              <PartnerGymCourseHistoryInfoView key={index} data={data} rendering={rendering}/*reserveRerendering={reserveRerendering}*/ />
            ))
          }
        </InfoBox>
      }
      {/* {
        nowPage === 'Locker' &&
        <InfoBox ref={LockerRef}>
          <LockerHistoryColumnView />
          {
            useLocker.map((data, index) => (
              <LockerHistoryInfoView 
                key={index} 
                data={data} 
                onClickLockerExtends={onClickLockerExtends}
                onClickLockerExit={onClickLockerExit}
                lockerRendering={lockerRendering}/>
            ))
          }
        </InfoBox>
      } */}
      {/* {
        nowPage === 'Coach' &&
        <InfoBox ref={CoachRef}>
          <CoachHistoryView physicalData={physicalData} />
        </InfoBox>
      } */}
      {/* {
        nowPage === "Holding" && 
        <InfoBox ref={HoldingRef}>
          <HoldingHistoryColumn/>
          {
            holdingData.map((data, index) => (
              <HoldingHistoryInfoView 
                key={index} 
                data={data} 
                onClickHoldingAndExtendButton={onClickHoldingAndExtendButton} 
                // onClickHoldingCancel={onClickHoldingCancel} 
                onClickHoldingEdit={onClickHoldingEdit}
                onClickDeleteHoldingHistory={onClickDeleteHoldingHistory}
                onClickModifyExtend={onClickModifyExtend}
                onClickDeleteExtend={onClickDeleteExtend}/>
            ))
          }
        </InfoBox>
      } */}
      {/* {
        nowPage === 'Product' &&
        <InfoBox>
          <ProductUsageColumn/>
          {
            productUsages.map((data, index) => (
              <ProductUsageInfoView
                key={index}
                data={data}
                onClickEditProductUsage={onClickEditProductUsage}
                onClickDeleteProductUsage={onClickDeleteProductUsage}/>
            ))
          }
        </InfoBox>
      } */}
      <PaginationWrapper>
        { nowPage === 'MemberShip' && <InfoPagination nowPage={memberPage} totalPage={Math.ceil(memberShipData.length / 10)} prevClickFn={onClickMemberPrevPage} nextClickFn={onClickMemberNextPage}/> }
        { nowPage === 'Course' && <InfoPagination nowPage={reservePage} totalPage={Math.ceil(lessonHistoryData.length / 10)} prevClickFn={onClickReservePrevPage} nextClickFn={onClickReserveNextPage}/> }
        {/* { nowPage === 'Locker' && <InfoPagination nowPage={lockerPage} totalPage={Math.ceil(lockerData.length / 10)} prevClickFn={onClickLockerPrevPage} nextClickFn={onClickLockerNextPage}/> } */}
      </PaginationWrapper>
    </TopLevelWrapper>
  )
})

export default PartnerGymHistoryView;

const TopLevelWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 38px;
  margin-bottom: 96px;

  transition: all 0.3s ease-in-out;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const SortButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  // width: 323px;
  // width: 220px;
  // width: 416px;
  width: 159px;
`;

const Button = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  width: ${props => props.width}px;
  height: 38px;

  border: ${props => props.nowPage === props.className ? `1px solid #FF8B48` : `1px solid #DFDFDF`};
  border-radius: 15px;
  background-color: ${props => props.nowPage === props.className ? `#FF8B48` : `#FFFFFF`};
 
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: ${props => props.nowPage === props.className ? `#FFFFFF` : `#777777`};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    font-family: NotoSansKR-Medium;
    color: #FFFFFF;
    background-color: #FF8B48;
    border: 1px solid #FF8B48;
  }
`;

const CreateButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 90px;
  height: 40px;

  border-radius: 15px;

  padding: 0 0 0 20px;
  background: url(${props => props.img}) no-repeat 16px center;
  background-color: #5BD98A;

  font-family: NotoSansKR-Medium;
  font-size: 12px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;

  // justify-content: center;
  justify-content: flex-start;
  align-items: center;

  min-height: 332px;

  gap: 8px;
  
  transition: all 0.3s ease-in-out;
`;

const PaginationWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;

  margin-top: 44px;
`;