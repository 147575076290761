import { React, useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import LogoView from '../../../Component/MySettingPageComponent/LogoView';

import DefaultLogoPath from '../../../image/DefaultLogo.png'
import PictureAddImage from '../../../image/PictureAdditionPlus.png';
import FetchModule from '../../Share/Network/FetchModule';

function MyGymPicture(props) {
  const { isClicked, profiles, setData, autoSave } = props;

  const hiddenFileInput = useRef(null);
  const FileInputRef = useRef();
  const CanvasRef = useRef();

  const [imgBase64, setImgBase64] = useState([]);
  const [imgFile, setImgFile] = useState();
  const [imgLocalPath, setImgLocalPath] = useState('');

  const [naturalWidth, setNaturalWidth] = useState(0);
  const [naturalHeight, setNaturalHeight] = useState(0);
  // useEffect(() => {
  //   console.log(profiles);
  //   // setImgBase64(profiles);
  // }, [profiles])

  const handleClick = e => {
    console.log(autoSave());
    if (autoSave()) hiddenFileInput.current.click();
    // FileInputRef.current.style.borderColor = '#FF8B48';
  };

  const handleChange = e => {

    var pathpoint = e.target.value.lastIndexOf('.');
    var filepoint = e.target.value.substring(pathpoint + 1, e.target.length);
    var filetype = filepoint.toLowerCase();

    if (filetype === 'jpg' || filetype === 'png' || filetype === 'jpeg') {

      // 정상적인 이미지 확장자 파일인 경우

    }
    else {
      alert('.jpeg .jpg .png 파일만 가능합니다!');
      var parentObj = e.target.parentNode
      var node = parentObj.replaceChild(e.target.cloneNode(true), e.target);

      return false;
    }

    const reader = new FileReader();
    // setImgLocalPath(e.target.value);
    var tmp = e.target.value.split('\\');
    setImgLocalPath(tmp[tmp.length - 1]);
    reader.onloadend = () => {
      const base64 = reader.result;
      const naturalImage = new Image();

      naturalImage.src = reader.result.toString();

      if (base64) {
        naturalImage.onload = function (e) {
          let standardSize = 800;

          let naturalWidth = e.target.naturalWidth;
          let naturalHeight = e.target.naturalHeight;

          let resizeWidth = 0;
          let resizeHeight = 0;

          // 원본 사이즈로 기준사이즈 정해서 비율계산//
          if (naturalWidth < naturalHeight) {
            resizeWidth = standardSize;
            resizeHeight = (standardSize * naturalHeight) / naturalWidth;
          }
          else if (naturalWidth > naturalHeight) {
            resizeHeight = standardSize;
            resizeWidth = (standardSize * naturalWidth) / naturalHeight;
          }
          else {
            resizeWidth = standardSize;
            resizeHeight = standardSize;
          }

          let image = new Image();
          let canvas = CanvasRef.current;
          let ctx = canvas?.getContext('2d');

          if (ctx) {
            canvas.width = standardSize;
            canvas.height = standardSize;
            canvas.backgroundColor = '#FFFFFF';

            image.src = base64.toString();

            // 사진 크기 조정후 Canvas에 그리기 //
            image.onload = function () {
              ctx.clearRect(0, 0, canvas.width, canvas.height);

              // 800 x 800 일반 설정형 //
              // ctx.drawImage(image, 0, 0, standardSize, standardSize);

              // 원본 비율 생존형 //
              ctx.drawImage(image, ((standardSize - resizeWidth) / 2), ((standardSize - resizeHeight) / 2), resizeWidth, resizeHeight);

              // 원본 사진 크롭형 //
              // ctx.drawImage(image, ((naturalWidth / 2) - (standardSize / 2)), ((naturalHeight / 2) - (standardSize / 2)), standardSize, standardSize, 0, 0, 800, 800);
            }

            // 크기 조절된 사진 데이터 정리하기 //
            canvas.toBlob(function (blob) {
              const reader = new FileReader();
              // Canvas에 다 그려졌을때 Event //
              reader.onload = function (e) {
                var requestData = {
                  profile: canvas.toDataURL()
                }

                const fetchModule = new FetchModule();
                (
                  async () => {
                    const responseData = await fetchModule.postDataVersion2('account/profile/upload', 'POST', requestData);

                    if (responseData.status === 200) setData(responseData.data);
                  }
                )();
              }
              reader.readAsDataURL(blob);
            })
          }
          else {
            // throw new Error('Could not get context');
            console.log("Could not get context");
          }
        }
      }
    };
    if (e.target.files[0]) {
      reader.readAsDataURL(e.target.files[0]);
      setImgFile(e.target.files[0]);
    }
  };

  function onClickDeleteButton(index) {
    // var newData = profiles.slice();

    // newData.splice(index, 1);

    // setProfiles(newData);
    var requestData = {
      profile: profiles[index]
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataVersion2('account/profile/delete', 'POST', requestData);

        if (responseData.status === 200) setData(responseData.data);
      }
    )();
  }

  return (
    <TopLevelWrapper>
      <InfoView>
        <TitleWrapper>
          <Title>센터 사진(최대 10장)</Title>
          <canvas ref={CanvasRef} style={{ display: 'none' }} />
          <input
            type="file"
            ref={hiddenFileInput}
            onChange={handleChange}
            style={{ display: 'none' }} />
          {/* { isClicked && <PictureAddButton ref={FileInputRef} onClick={handleClick}>사진추가</PictureAddButton>} */}
        </TitleWrapper>
        {/* <InfoWrapper>
          {
            // isClicked ?
            profiles.length > 0 ?
              <LayerWrapper>
                {
                  profiles.map((data, index) => (
                    <LogoView key={index} isClicked={isClicked} data={data} index={index} onClickDeleteButton={onClickDeleteButton}/>
                  ))
                }
              </LayerWrapper>
              :
              <LayerWrapper>
                <EmptyTextWrapper>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>등록된 사진이 없습니다</Text>
                </EmptyTextWrapper>
              </LayerWrapper>
          }
        </InfoWrapper> */}
        <InfoWrapper>
          <LayerWrapper>
            {
              profiles.map((data, index) => (
                <LogoView key={index} isClicked={isClicked} data={data} index={index} onClickDeleteButton={onClickDeleteButton} />
              ))
            }
            <AdditionBox onClick={handleClick}>
              <AdditionImage src={PictureAddImage} />
            </AdditionBox>
          </LayerWrapper>
        </InfoWrapper>
      </InfoView>
    </TopLevelWrapper>
  )
}

export default MyGymPicture;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 34px;

  width: 100%;
`;

const InfoView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 1191px;
  height: 223px;

  border-radius: 30px;
  background-color: #FFFFFF;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  width: 1135px;
`;

const Title = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const PictureAddButton = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #5BD98A;

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 24px;
  border-top: 1px solid #ECECEC;

  width: 1135px;
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 100%;
  height: 99px;

  margin-top: 24px;
  gap: 16px;
`;

const Logo = styled.img`
  width: 99px;
  height: 99px;

  border-radius: 15px;

  object-fit: cover;
`;

const EmptyTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const AdditionBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 99px;
  height: 99px;

  border-radius: 15px;
  background-color: #E7E7E7;

  object-fit: cover;

  :hover {
    cursor: pointer;
  }
`;

const AdditionImage = styled.img`
  width: 64px;
  height: 64px;

  object-fit: cover;
`;