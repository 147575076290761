import { React, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import NoticeViewBox2 from './NoticeViewBox2';
import NoticeViewBox from './NoticeViewBox';

import FetchModule from '../../Share/Network/FetchModule';
import NoticeDeleteModal from './NoticeDeleteModal';
import NewNoticeViewBox from './NewNoticeViewBox';

function NoticeView(props) {
  const { noticeId } = props;

  const history = useHistory();

  const [data, setData] = useState({
    title: '',
    content: '',
    pictures: '',
    commentList: []
  });
  const [deleteClick, setDeleteClick] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [rendering, setRendering] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    // const fetchModule = new FetchModule();
    // (
    //   async () => {
    //     const responseData = await fetchModule.getData('notice/detail/' + noticeId + '/', 'GET');
    //     // console.log(responseData.data);
    //     if (responseData.status === 200) setData(responseData.data[0]);
    //     else if (responseData.status === 401) history.push('/');
    //     else if (responseData.status !== 204) {}
    //   }
    // )();
    let requestData = {
      noticeId: noticeId
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postData('notice/detail/zone', 'POST', requestData);
        // console.log(responseData.data);
        if (responseData.status === 200) setData(responseData.data[0]);
        else if (responseData.status === 401) history.push('/');

        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      }
    )();
  }, [history, noticeId, rendering])

  function modifyNoticeClick() {
    history.push('/notice/modify/' + noticeId);
  }

  return (
    <TopLevelWrapper>
      {deleteClick && <NoticeDeleteModal noticeId={noticeId} exitClickFn={() => setDeleteClick(false)}/>}
      <TopBarWrapper>
        <TextWrapper>
          <Title>공지</Title>
          <Explanation>일정, 회원관리 등을 공지하고 확인할 수 있어요</Explanation>
        </TextWrapper>
        <ButtonWrapper>
          <ExitButton onClick={() => setDeleteClick(true)}>삭제</ExitButton>
          <AddButton onClick={modifyNoticeClick}>수정하기</AddButton>
        </ButtonWrapper>
      </TopBarWrapper>
      {/* <NoticeViewBox 
        data={data} 
        title={data.title} 
        defaultVal={data.details} 
        noticeId={noticeId} 
        rendering={() => setRendering(!rendering)}
        isLoading={isLoading}/> */}
        <NewNoticeViewBox 
          data={data} 
          title={data.title} 
          defaultVal={data.details} 
          noticeId={noticeId} 
          storeLogo={data.storeLogo}
          isFixed={data.isFixed}
          rendering={() => setRendering(!rendering)}
          isLoading={isLoading}/>
    </TopLevelWrapper>
  )
}

export default NoticeView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin-left: 42px;
  // margin-left: 48px;

  transition: all 0.3s ease-in-out;
`;

const TopBarWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 992px;

  // gap: 674px;
  margin-left: 28px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: 0 0 0 0;
`;

const Explanation = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #777777;

  margin: 0 0 0 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 16px;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 72px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;

  transition: all 0.2s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #777777;
    color: #777777;
  }
`;

const AddButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 15px;
  background-color: #5BD98A;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }
`;
