import { React, forwardRef, useRef, useImperativeHandle } from 'react';
import styled from 'styled-components';


const SignUpSecondBottomComponent = forwardRef((props, ref) => {
	const { onClickNextButton, onClickPrevButton } = props;

	const TopLevelRef = useRef()

	useImperativeHandle(ref, () => ({
		setOpacity: (val) => {
			TopLevelRef.current.style.opacity = val;
			if (val === 0) TopLevelRef.current.style.zIndex = -1;
			else TopLevelRef.current.style.zIndex = 1;
		},
	}));

	return (
		<TopLevelWrapper ref={TopLevelRef}>
			<MoreDetails onClick={onClickPrevButton}>이전</MoreDetails>
			<LoginButton onClick={onClickNextButton}>다음</LoginButton>
		</TopLevelWrapper>
	)
})

export default SignUpSecondBottomComponent;

const TopLevelWrapper = styled.div`
	position: absolute;
	display: flex;
	flex-direction: row;

	align-items: center;

	// margin-top: 155px;
	margin-top: 514px;
	// margin-top: max(397px, 26.77083333333333vw);
	gap: 332px;
	// gap: max(158px, 17.29166666666667vw);
	opacity: 0;

	z-index: -1;
  transition: all 0.5s ease-in-out;
`;

const MoreDetails = styled.p`
	font-family: NotoSansKR-Regular;
	font-size: 14px;
	// font-size: max(14px, 0.7291666666666667vw);
	// line-height: 17.38px;

	margin: 0 0 0 0;

	color: #FF8B48;

	:hover {
		cursor: pointer;
	}
`;

const LoginButton = styled.div`
	display: flex;
	flex-direction: column;

	justify-content: center;
	align-items: center;

	font-family: NotoSansKR-Bold;
	fons-size: 14px;
	// font-size: max(12px, 0.7291666666666667vw);
	// line-height: 20px;

	width: 118px;
	// width: max(92px, 6.145833333333333vw);
	height: 46px;
	// height: max(36px, 2.395833333333333vw);
	
	// margin: 0 0 0 171px;

	border-radius: 23px;
	// border-radius: max(18px, 1.197916666666667vw);
	background-color: #FF8B48;
	// background-color: red;
	color: #FFFFFF;

	cursor: default;

	:hover {
		cursor: pointer;
		background-color: #FF7322;
	}
`;
