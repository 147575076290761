import { React, forwardRef, useState, useRef, useImperativeHandle, useEffect } from 'react';
import styled from 'styled-components';

import SelectOnPath from '../../image/SelectOn.png';
import SelectOffPath from '../../image/SelectOff.png';

const RepeatSelect = forwardRef((props, ref) => {
	const { date, scheduleId } = props;	

	const [sunday, setSunday] = useState(false);
	const [monday, setMonday] = useState(false);
	const [tuesday, setTuesday] = useState(false);
	const [wednesday, setWednesday] = useState(false);
	const [thursday, setThursday] = useState(false);
	const [friday, setFriday] = useState(false);
	const [saturday, setSaturday] = useState(false);

	useImperativeHandle(ref, () => ({
		setValue: (val) => {
			if (val === 'All') {
				setSunday(true);
				setMonday(true);
				setTuesday(true);
				setWednesday(true);
				setThursday(true);
				setFriday(true);
				setSaturday(true);
			}
			else {
				if (val.includes('Sun')) setSunday(true);
				if (val.includes('Mon')) setMonday(true);
				if (val.includes('Tue')) setTuesday(true);
				if (val.includes('Wed')) setWednesday(true);
				if (val.includes('Thu')) setThursday(true);
				if (val.includes('Fri')) setFriday(true);
				if (val.includes('Sat')) setSaturday(true);
			}
		},
		getValue: () => {
			// return defaultVal.value;
			var value = '';

			if (sunday && monday && tuesday && wednesday && thursday && friday && saturday) return 'All';

			if (sunday) value += 'Sun,';
			if (monday) value += 'Mon,';
			if (tuesday) value += 'Tue,';
			if (wednesday) value += 'Wed,';
			if (thursday) value += 'Thu,';
			if (friday) value += 'Fri,';
			if (saturday) value += 'Sat,';

			return value;
		}
	}))

	// 요일 클릭시 //
	function onClickDailyButton(division) {
		if (scheduleId !== -1) return;

		if (division === 'Sun') setSunday(!sunday);
		else if (division === 'Mon') setMonday(!monday);
		else if (division === 'Tue') setTuesday(!tuesday);
		else if (division === 'Wed') setWednesday(!wednesday);
		else if (division === 'Thu') setThursday(!thursday);
		else if (division === 'Fri') setFriday(!friday);
		else if (division === 'Sat') setSaturday(!saturday);
	}

	return (
		<TopLevelWrapper>
			<DailyButton hoverAvailable={scheduleId === -1} isClicked={sunday && (scheduleId === -1 || date.format('ddd') === 'Sun')} onClick={() => onClickDailyButton('Sun')}>일</DailyButton>
			<DailyButton hoverAvailable={scheduleId === -1} isClicked={monday && (scheduleId === -1 || date.format('ddd') === 'Mon')} onClick={() => onClickDailyButton('Mon')}>월</DailyButton>
			<DailyButton hoverAvailable={scheduleId === -1} isClicked={tuesday && (scheduleId === -1 || date.format('ddd') === 'Tue')} onClick={() => onClickDailyButton('Tue')}>화</DailyButton>
			<DailyButton hoverAvailable={scheduleId === -1} isClicked={wednesday && (scheduleId === -1 || date.format('ddd') === 'Wed')} onClick={() => onClickDailyButton('Wed')}>수</DailyButton>
			<DailyButton hoverAvailable={scheduleId === -1} isClicked={thursday && (scheduleId === -1 || date.format('ddd') === 'Thu')} onClick={() => onClickDailyButton('Thu')}>목</DailyButton>
			<DailyButton hoverAvailable={scheduleId === -1} isClicked={friday && (scheduleId === -1 || date.format('ddd') === 'Fri')} onClick={() => onClickDailyButton('Fri')}>금</DailyButton>
			<DailyButton hoverAvailable={scheduleId === -1} isClicked={saturday && (scheduleId === -1 || date.format('ddd') === 'Sat')} onClick={() => onClickDailyButton('Sat')}>토</DailyButton>
		</TopLevelWrapper>
	)
})

export default RepeatSelect;

const TopLevelWrapper = styled.div`
	display: flex;
	flex-direction: row;

  margin-top: 8px;

  width: 282px;
  height: 32px;

	gap: 8px;
`;

const DailyButton = styled.div`
	display: flex;
	flex-direction: column;

	justify-content: center;
	align-items: center;

	width: 32px;
	height: 32px;

	border: 1px solid #DFDFDF;
	border-radius: 8px;

	background-color: ${props => props.isClicked ? `#FF8B48` : `#FFFFFF`};

	font-family: NotoSansKR-Regular;
	font-size: 14px;

	color: ${props => props.isClicked ? `#FFFFFF` : `#CBCBCB`};

  transition: all 0.3s ease-in-out;

	:hover {
		cursor: pointer;
		border: ${props => props.hoverAvailable ? `1px solid #FF8B48` : `1px solid #DFDFDF`};
	}
`;