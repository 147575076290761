import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import VideoIcon from '../../image/ProgramVideoIcon.png';

export default function RenewalResultExerciseBoxInReview(props) {
  const { index, tag, reps, division, exerciseData, onClickMovementVideo } = props;

  const [movementName, setMovementName] = useState('');
  const [movementVideoLink, setMovementVideoLink] = useState('');

  useEffect(() => {
    setMovementName(exerciseData.movement.movementName);
    setMovementVideoLink(exerciseData.movement.videoLinkId);
  }, [exerciseData, tag, reps])


  // 한자리 숫자 앞에 0 붙이기 //
  function numberPad(n, width) {
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
  }

  return (
    <TopLevelWrapper>
      <TotalWrapper>
        <TitleLineWrapper>
          <TitleWrapper>
            <TitleNumberBox isMain={division === 'Main'}>
              <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>{numberPad(index + 1, 2)}</Text>
            </TitleNumberBox>
            <Text fontFamily='Poppins-Medium' fontSize={12} color='#4F4F4F' numberOfLines={1}>{movementName}</Text>
          </TitleWrapper>
          <ButtonWrapper>
            {
              movementVideoLink !== '' &&
              <Button activeOpacity={0.8} onPress={() => onClickMovementVideo(movementVideoLink)}>
                <WithLocalSvg
                  width={20}
                  height={20}
                  src={VideoIcon} />
              </Button>
            }
          </ButtonWrapper>
        </TitleLineWrapper>
        <TextWrapper>
          {/* 무게 ( Weight ) */}
          {
            exerciseData.isUseWeight && (exerciseData.weightUnit === '%RM' || exerciseData.weightUnit === '%Body') &&
            <StandardWrapper>
              <InfoWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#666666'>기준</Text>
                <InputWrapper>
                  <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F'>{exerciseData.weight}</Text>
                  <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F'>{exerciseData.weightUnit}</Text>
                </InputWrapper>
              </InfoWrapper>
              <InfoWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#666666'>무게</Text>
                <InputWrapper>
                  <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F'>{exerciseData.conversionWeight}</Text>
                  <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F'>{exerciseData.conversionUnit}</Text>
                </InputWrapper>
              </InfoWrapper>
            </StandardWrapper>
          }
          {
            exerciseData.isUseWeight && (exerciseData.weightUnit !== '%RM' && exerciseData.weightUnit !== '%Body') &&
            <InfoWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#666666'>무게</Text>
              <InputWrapper>
                <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F'>{exerciseData.weight}</Text>
                <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F'>{exerciseData.weightUnit === 'Pounds' ? 'lbs' : exerciseData.weightUnit}</Text>
              </InputWrapper>
            </InfoWrapper>
          }
          {/* 거리 ( Distance ) */}
          {
            exerciseData.isUseDistance &&
            <InfoWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#666666'>거리</Text>
              <InputWrapper>
                <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F'>{exerciseData.distance}</Text>
                <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F'>{exerciseData.distanceUnit}</Text>
              </InputWrapper>
            </InfoWrapper>
          }
          {/* 높이 ( Height ) */}
          {
            exerciseData.isUseHeight &&
            <InfoWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#666666'>높이</Text>
              <InputWrapper>
                <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F'>{exerciseData.height}</Text>
                <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F'>{exerciseData.heightUnit}</Text>
              </InputWrapper>
            </InfoWrapper>
          }
          {/* 열량 ( Calories ) */}
          {
            exerciseData.isUseCalories &&
            <InfoWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#666666'>열량</Text>
              <InputWrapper>
                <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F'>{exerciseData.calories}</Text>
                <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F'>{exerciseData.caloriesUnit}</Text>
              </InputWrapper>
            </InfoWrapper>
          }
          {/* 횟수 ( Count ) */}
          {
            exerciseData.isUseCount &&
            (
              <InfoWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#666666'>횟수</Text>
                <InputWrapper>
                  <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F'>{exerciseData.count}</Text>
                  <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F'>Reps</Text>
                </InputWrapper>
              </InfoWrapper>
            )
          }
          {/* 시간 ( Time ) */}
          {
            exerciseData.isUseTime &&
            <InfoWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#666666'>시간</Text>
              <TimeColumnWrapper>
                <TimeInputWrapper>
                  <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F'>{parseInt(exerciseData.time / 60)}</Text>
                  <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F'>min</Text>
                </TimeInputWrapper>
                <TimeInputWrapper>
                  <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F'>{parseInt(exerciseData.time % 60)}</Text>
                  <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F'>sec</Text>
                </TimeInputWrapper>
              </TimeColumnWrapper>
            </InfoWrapper>
          }
          {/* 템포 ( Tempo ) */}
          {
            exerciseData.isUseTempo &&
            <InfoWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#666666'>템포</Text>
              <InputWrapper>
                <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F'>{exerciseData.tempo}</Text>
                <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F'>Tempo</Text>
              </InputWrapper>
            </InfoWrapper>
          }
        </TextWrapper>
      </TotalWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  padding: 0px 0px 0px 0px;

  width: 100%;
  
  overflow: hidden;

  margin-bottom: 10px;
  
  background-color: #F9F9F9;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  padding: 12px 0px 16px 0px;

  width: 100%;
`;

const TitleLineWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;
  
  width: 72.45%;
`;

const TitleNumberBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-right: 8px;

  width: 27px;
  height: 18px;

  border-radius: 6px;
  background-color: ${props => props.isMain ? `#FF8B48` : `#6DD49E`};
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // width: 13.9%;
`;

const Button = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  
  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  // justify-content: center;
  // align-items: center; 

  margin-top: 10px;

  width: 100%;
`;

const StandardWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: 16px;

  width: 100%;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding: 0px 15px;

  width: 86%;
  height: 34px;

  border: 1px solid #CBCBCB;
  border-radius: 12px;
  background-color: #F6F6F6;
`;

const TimeColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  width: calc(86% + 32px);
`;

const TimeInputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding: 0px 15px;

  width: calc(48% - 32px);
  height: 34px;

  border: 1px solid #CBCBCB;
  border-radius: 12px;
  background-color: #F6F6F6;
`;

const WithLocalSvg = styled.img`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;