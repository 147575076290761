import React from "react";
import styled from "styled-components";

import CheckImagePath from '../../../image/Check.png';

export default function CheckBox(props) {
  const {
    isClicked,
    onClickCheckBox,

    boxWidth,
    boxHeight,
    boxRadius,

    imageWidth,
    imageHeight
  } = props;

  return (
    <CheckBoxView
      isClicked={isClicked}
      width={boxWidth}
      height={boxHeight}
      radius={boxRadius}
      onClick={onClickCheckBox}>
      { isClicked && <Image src={CheckImagePath} width={imageWidth} height={imageHeight}/> }
    </CheckBoxView>
  );
}

const CheckBoxView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border: ${props => props.isClicked ? `1px solid #FF8B48` : `1px solid #969696`};
  border-radius: ${props => props.radius}px;
  background-color: ${props => props.isClicked ? `#FF8B48` : `#FFFFFF`};

  :hover {
    cursor: pointer;
  }
  
  transition: all 0.3s ease-in-out;
`;

const Image = styled.img`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;