import { React } from 'react';
import styled from 'styled-components';

import DefaultLogoPath from '../../../image/DefaultLogo.png'
import UserProfile from '../../../Component/Normal/UserProfile';

function MyGymIntroduction(props) {
  const { storeLogo, storeName, tel, type, address, openingHour, isUsePaymentSystem, setIsModifyGymInfo, storeIntro, onClickModifyCenterInfo } = props;

  return (
    <TopLevelWrapper>
      <InfoView>
        <TitleWrapper>
          <ComponentWrapper>
            <Title>센터 정보</Title>
            <UsePaymentWrapper>
              <UsePaymentBracketText>(</UsePaymentBracketText>
              <UsePaymentText isUsePaymentSystem={isUsePaymentSystem === 'Yes'}>{isUsePaymentSystem === 'Yes' ? '결제 사용' : '결제 미사용'}</UsePaymentText>
              <UsePaymentBracketText>)</UsePaymentBracketText>
            </UsePaymentWrapper>
          </ComponentWrapper>
          {/* <ModifyButton onClick={() => setIsModifyGymInfo(true)}>수정하기</ModifyButton> */}
          <ModifyButton onClick={onClickModifyCenterInfo}>수정하기</ModifyButton>
        </TitleWrapper>
        <InfoWrapper>
          <LayerWrapper>
            {/* <Logo src={storeLogo ? storeLogo : DefaultLogoPath}/> */}
            <LogoBox>
              <UserProfile profile={storeLogo} defaultProfile={DefaultLogoPath} />
            </LogoBox>
            <TextWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={18} color='#232323'>{storeName}</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>{address}</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>{tel}</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>{openingHour}</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>{type}</Text>
            </TextWrapper>
          </LayerWrapper>
        </InfoWrapper>
        <TitleWrapper style={{ marginTop: `32px` }}>
          <Title>센터 소개</Title>
        </TitleWrapper>
        <IntroInfoWrapper>
          <IntroTextWrapper>
            <PreText fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>{storeIntro}</PreText>
          </IntroTextWrapper>
        </IntroInfoWrapper>
      </InfoView>
    </TopLevelWrapper>
  )
}

export default MyGymIntroduction;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 34px;

  width: 100%;
`;

const InfoView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 14px 0px 14px 0px;

  width: 1191px;
  // height: 266px;

  border-radius: 30px;
  background-color: #FFFFFF;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 1135px;
`;

const Title = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const UsePaymentWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const UsePaymentBracketText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const UsePaymentText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: ${props => props.isUsePaymentSystem ? `#87C8A6` : `#FF3131`};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const ModifyButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 85px;
  height: 31px;

  border: ${props => props.isClicked? `1px solid #6DD49E` : `1px solid #DFDFDF`};
  border-radius: 15px;
  background-color: ${props => props.isClicked ? `#6DD49E` : `#FFFFFF`};

  font-family: ${props => props.isClicked ? `NotoSansKR-Bold` : `NotoSansKR-Regular`};
  font-size: 12px;

  color: ${props => props.isClicked ? `#FFFFFF` : `#777777`};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 24px;
  border-top: 1px solid #ECECEC;

  width: 1135px;
`;

const IntroInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 24px;
  border-top: 1px solid #ECECEC;

  width: 1135px;
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 100%;

  margin-top: 24px;
  gap: 20px;
`;

const Logo = styled.img`
  width: 140px;
  height: 140px;

  border-radius: 15px;

  object-fit: cover;
`;

const LogoBox = styled.div`
  width: 140px;
  height: 140px;

  border-radius: 15px;

  object-fit: cover;
  overflow: hidden;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  gap: 8px;
`;

const IntroTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  word-break:break-all;
  flex-wrap: wrap;

  margin-top: 24px;
  margin-bottom: 28px;

  width: 100%;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;

const PreText = styled.pre`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  
  white-space: pre-wrap;

  :hover {
    cursor: pointer;
  }
`;