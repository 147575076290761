import { React, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Notice from '../../../Component/MainComponent/Notice.js';

import NoticeList from '../NoticeModel/NoticeList.js';
import Pagination from '../../../Component/MainComponent/NoticePagination.js';

import FetchModule from '../../Share/Network/FetchModule.js';

const NoticeView = () => {
  const history = useHistory();

  const [data, setData] = useState([]);
  const [useData, setUseData] = useState([]);
  const [nowPage, setNowPage] = useState(1);
  
  useEffect(() => {

    const fetchModule = new FetchModule();
    (
      async () => {
        // const responseData = await fetchModule.getData('notice/', 'GET');
        const responseData = await fetchModule.postData('notice/', 'POST');
        // console.log(responseData.data);
        if (responseData.status === 200) setData(responseData.data);
        // else if (responseData.status === 401) history.push('/');
        else if (responseData.status !== 204) {}
      }
    )();
  }, [history])

  useEffect(() => {
    var newDataList = data.slice((nowPage - 1) * 12, (nowPage) * 12);
    setUseData(newDataList);
  }, [nowPage, data])

  function clickNextPage() {
    if (nowPage >= Math.ceil(data.length/12)) return;

    setNowPage(nowPage + 1);
  }

  function clickPrevPage() {
    if (nowPage <= 1) return;

    setNowPage(nowPage - 1);
  }

  return (
    <TopLevelWrapper>
      <TitleWrapper>
        <Title>공지사항</Title>
        <ExplanationWrapper>
          <Explanation>일정, 회원관리 등을 공지할 수 있어요</Explanation>
          <Go onClick={() => history.push('/notice')}>전체 보기</Go>
        </ExplanationWrapper>
      </TitleWrapper>
      <ViewBox>
        <ListBox>
          <Notice data={useData}/>
        </ListBox>
      <Pagination 
        nowPage={nowPage} 
        totalPage={Math.ceil(data.length/12)} 
        prevFn={clickPrevPage} 
        nextFn={clickNextPage}/>
      </ViewBox>
    </TopLevelWrapper>
  )
};

export default NoticeView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 1059px;
  height: 592px;
  // height: 385px;

  border-radius: 30px;
  background-color: #FFFFFF;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);

  margin-bottom: 96px;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: 28px 0 0 0;
`;

const ExplanationWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 773px;
  margin-top: 4px;
  margin-bottom: 24px;
`;

const Explanation = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;
  
  color: #777777;
  
  margin: 0 0 0 0;
`;

const Go = styled.p`
  font-family: NotoSansKr-Medium;
  font-size: 12px;

  color: #FF8B48;
  margin: 0 0 0 0;
  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    color: #FF7322;
  }
`;

const ViewBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  width: 1019px;
  height: 448px;

  gap: 16px;
  padding-top: 20px;
  margin-bottom: 27px;

  border-top: 2px solid #ECECEC;
  // border-bottom: 2px solid #ECECEC;
`;

const ListBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;

  // background-color: red;
`;

