import { React, useRef } from 'react';
import styled from 'styled-components';

export default function SmallTimeInput(props) {
	const { value, setValue, unit, column, index } = props;

	const InputRef = useRef();

	function handleClick(e) {
	}

	function handleChange(e) {

    let changedValue = e.target.value;

    if (changedValue === 'MAX') return;
    else if (changedValue === '') {
      setValue(index, column, 0);
      return;
    }

    let regex = /[^0-9]/g; // 숫자만 감지 

    let lastValue = changedValue[changedValue.length - 1];
    
    if (regex.test(lastValue)) {
      if (lastValue === '/'
        || lastValue === '~'
        || lastValue === '-'
        || lastValue === '.'
        || lastValue === '@') setValue(index, column, changedValue);
    }
    else {
      if (regex.test(changedValue)) setValue(index, column, changedValue);
      else setValue(index, column, parseInt(changedValue));
    }
	}

	function handleFocus(e) {
	}

	function handleBlur(e) {
		if (value === "") {
			// setDefaultPlaceholder(placeholder);
			// inputRef.current.style.borderColor = '#FF3131';
		} 
		else {
			// inputRef.current.style.borderColor = '#E8E8E8';
		}
	}

	return (
    <TopLevelWrapper>
      <Input
        ref={InputRef}
        type='text'
        placeholder=''
        // value={value === 'MAX' ? value : parseInt(value)}
        value={value}
        onClick={handleClick}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur} />
      <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>{unit}</Text>
    </TopLevelWrapper>
  )
};

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // width: 114px;
  width: 62px;
  height: 34px;

  padding-left: 16px;
  padding-right: 16px;

  border: 1px solid #E0E0E0;
  border-radius: 30px;

  background-color: ${props => props.disabled ? `#ECECEC` : `#F6F6F6`};
  
  transition: all 0.3s ease-in-out;
`;

const Input = styled.input` 
	font-family: NotoSansKR-Regular;
	font-size: 14px;
	line-height: 20px;
	
  display: flex;
  flex-direction: row;

  width: 100%;

  border: 0px;
  background-color: #F6F6F6;

  color: #595959;

	outline: none;

	::placeholder {
    font-family: NotoSansKR-Regular;
    font-size: 14px;

		color: #CBCBCB;
	}

  &:disabled {
    background-color: #ECECEC;
  }

  transition: all 0.3s ease-in-out;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;
