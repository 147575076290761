import { React, useState, useEffect } from 'react';
import styled from 'styled-components';

const LockerHistoryColumnView = () => {

  return (
    <TopLevelWrapper>
      {/* <Column style={{marginRight: '56px', marginLeft: '28px'}}>락커번호</Column>
      <Column style={{marginRight: '182px'}}>기간</Column>
      <Column style={{marginRight: '168px'}}>초과일수</Column>
      <Column style={{marginRight: '142px'}}>상태</Column>
      <Column >승인날짜</Column> */}
      <Column style={{marginRight: '41px', marginLeft: '28px'}}>락커번호</Column>
      <Column style={{marginRight: '108px'}}>사용 기간</Column>
      <Column style={{marginRight: '41px'}}>사용일수</Column>
      <Column style={{marginRight: '52px'}}>상태</Column>
      <Column style={{marginRight: '206px'}}>이용금액</Column>
      <Column>락커 결제일</Column>
    </TopLevelWrapper>
  )
}

export default LockerHistoryColumnView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  // justify-content: center;
  
  // justify-content: center;
  align-items: center;

  width: 1020px;
  margin-top: 34px;
  margin-bottom: 20px;
  
  transition: all 0.3s ease-in-out;
`;

const Column = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;
`;
