import { React } from 'react';
import styled from 'styled-components';
import BoardView from '../../Model/Individual/BoardModel/BoardView';

function Board() {

  return (
    <Container>
      <BoardView/>
    </Container>
  )
}

export default Board;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  
  width: 100%;
`;