import { React, useState, useRef, useImperativeHandle, forwardRef } from 'react';
import styled from 'styled-components';

const ReasonInput = forwardRef((props, ref) => {
  const { value, setValue, className, placeholder, type, disabled } = props;
	// const [value, setValue] = useState('');
	const [changed, setChanged] = useState(false);
	const [clickCount, setClickCount] = useState(0);
	const [defaultPlaceholder, setDefaultPlaceholder] = useState(placeholder);

	const inputRef = useRef();

	useImperativeHandle(ref, () => ({
		setValue: (val) => {
			setValue(val);
			setChanged(true);
		},
		getValue: () => {
			if (changed)
				return value;
			else
				return "";
		},
		plusClickCount: () => {
			setClickCount(clickCount + 1)
		},
		getClickCount: () => {
			return clickCount;
		},
		invalidValue: () => {
			inputRef.current.style.borderColor = '#FF3131';
		},
		reset: (val) => {
			setClickCount(0);
			setDefaultPlaceholder(val)
			inputRef.current.style.borderColor = '#E8E8E8';
		}
	}));

	function handleClick(e) {
		inputRef.current.style.borderColor = '#FF8B48';
	}

	function handleChange(e) {
		setValue(e.target.value);
		setChanged(true);
	}

	function handleFocus(e) {
		if (changed) {
			// do nothing
		} else {
			setValue("");
		}
	}

	function handleBlur(e) {
		setClickCount(clickCount + 1);
		inputRef.current.style.borderColor = '#E8E8E8';
		if (value === "") {
			// setDefaultPlaceholder(placeholder);
			// inputRef.current.style.borderColor = '#FF3131';
		} 
		else {
			// inputRef.current.style.borderColor = '#E8E8E8';
		}
	}
  return(
    <TopLevelWrapper>
      <Text>사유</Text>
      <Input
        ref={inputRef}
        clickCount={clickCount}
        type={type}
        placeholder={defaultPlaceholder}
        value={value || ''}
        disabled={disabled}
        className={`${className} ${changed ? "changed" : ""}`}
        onClick={handleClick}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur} />
    </TopLevelWrapper>
  )
})

export default ReasonInput;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 11px;
  margin: 24px 0 0 24px;
`;

const Text = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;

  margin: 0 0 0 0;
`;

const Input = styled.textarea`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;
  
  // width: 317px;
	width: 309px;
  // height: 96px;
	height: 90px;

  // padding: 6px 0 0 8px;
	padding: 6px 8px 6px 8px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;

  font-fmaily: NotoSansKR-Regular;
  font-size: 14px;
  line-height: 20.27px;

	color: #000000;
  
	outline: none;

  ::placeholder {
		color: #CBCBCB;
	}
`;