import React, { useEffect } from "react";
import styled from "styled-components";

export default function CenterTermsUploadCheckModal(props) {
  const { onClickExit, onClickUpload } = props;

  // 화면 스크롤 제어 //
  useEffect(() => {
    document.body.style = `overflow: hidden`;
    return () => document.body.style = `overflow: auto`
  }, [])

  return (
    <TopLevelWrapper>
      <Modal>
        <ModalInnerWrapper>
          <TitleWrapper>
            <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#232323' cursor='default'>센터 가입 약관 업로드</Text>
          </TitleWrapper>
          <ExplanationWrapper>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' cursor='pointer'>센터 가입 약관을 업로드 하시겠습니까?</Text>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' cursor='pointer'>업로드를 진행하면 기존 약관은 삭제됩니다.</Text>
          </ExplanationWrapper>
          <ButtonWrapper>
            <ExitButton onClick={onClickExit}>
              <ExitText>취소</ExitText>
            </ExitButton>
            <SaveButton onClick={onClickUpload}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='pointer'>업로드</Text>
            </SaveButton>
          </ButtonWrapper>
        </ModalInnerWrapper>
      </Modal>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 1000;

  background-color: rgba(0, 0, 0, 0.2);
  
  transition: all 0.3s ease-in-out;
`;

const Modal = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 371px;
  height: 217px;

  border-radius: 30px;
  background-color: #FFFFFF;
`;

const ModalInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 84.9%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const ExplanationWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 28px;

  gap: 8px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  margin-top: 28px;

  gap: 16px;

  width: 100%;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 72px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;

  :hover { 
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const ExitText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  margin: 0 0 0 0;

  color: #CBCBCB;

  :hover {
    cursor: ${props => props.cursor};
  }

  transition: all 0.3s ease-in-out;
`;

const SaveButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 15px;
  background-color: #5BD98A;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }

  transition: all 0.3s ease-in-out;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }
`;