import { React, useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

import DayCountInput from '../../../../../Component/MemberComponent/MemberDetail/DayCountInput.js';
import DayApplyInput from '../../../../../Component/MemberComponent/MemberDetail/DayApplyInput.js';
import MemberShipSearchInput from '../../../../../Component/MemberComponent/MemberDetail/MemberSearchInput.js';

import FetchModule from '../../../../Share/Network/FetchModule.js';
import DayReasonInput from '../../../../../Component/MemberComponent/MemberDetail/DayReasonInput.js';

export default function HoldingAndExtendsDetailModal(props){
  const { data, closeFn } = props;

  return (
    <TopLevelWrapper>
      <ModalView isExtends={data.extendId !== 0}>
        <ModalInnerWrapper>
          <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#232323' hover='default'>{data.extendId === 0 ? '홀딩내역' : '연장내역'} 자세히 보기</Text>
          <TextWrapper style={{ marginTop: `24px` }}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282' hover='default'>회원권 명</Text>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333' hover='default'>{data.memberShipName}</Text>
          </TextWrapper> 
          <TextWrapper style={{ marginTop: `12px` }}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282' hover='default'>{data.extendId === 0 ? '홀딩' : '연장'} 시작날짜</Text>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333' hover='default'>{data.startDate.split('T')[0].replaceAll('-', '.')}</Text>
          </TextWrapper>
          <TextWrapper style={{ marginTop: `12px` }}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282' hover='default'>{data.extendId === 0 ? '홀딩' : '연장'} 종료날짜</Text>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333' hover='default'>{data.endDate.split('T')[0].replaceAll('-', '.')}</Text>
          </TextWrapper>
          {
            data.extendId !== 0 &&
            <TextWrapper style={{ marginTop: `12px` }}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282' hover='default'>수강 추가</Text>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333' hover='default'>{data.plusTotalMax} 회</Text>
            </TextWrapper>
          }
          <ReasonBoxWrapper>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282' hover='default'>{data.extendId === 0 ? '홀딩' : '연장'} 사유</Text>
            <ReasonBox>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333' hover='default'>{data.reason}</Text>
            </ReasonBox>
          </ReasonBoxWrapper>
          <ExitButtonWrapper>
            <ExitButton onClick={closeFn}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#CBCBCB' hover='pointer'>닫기</Text>
            </ExitButton>
          </ExitButtonWrapper>
        </ModalInnerWrapper>
      </ModalView>
    </TopLevelWrapper>
  )
}

const startAnimation = keyframes`
	0% { opacity: 0; }
	50% { opacity: 0.5; }
	100% { opacity: 1; }
`;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  justify-content: center;
  align-items: center;

  z-index: 1000;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.2);
	
	animation-name: ${startAnimation};
	animation-duration: 0.5s;
	animation-timing-function: ease-out;
`;

const ModalView = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 371px;
  height: ${props => props.isExtends ? `410px` : `378px`};

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(0, 0, 0, 0.1);

  background-color: #FFFFFF;
`;

const ModalInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 84.9%;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const ReasonBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 12px;
  gap: 8px;

  width: 100%;
`;

const ReasonBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  padding: 12px 12px 12px 12px;

  width: calc(100% - 26px);
  height: 61px;

  border: 1px solid #E0E0E0;
  border-radius: 8px;
`;

const ExitButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-end;
  align-items: flex-end;

  margin-top: 24px;

  width: 100%;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 72px;
  height: 38px;

  border: 1px solid #E0E0E0;
  border-radius: 15px;

  :hover {
    cursor: pointer;
  }
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.hover};
  }
`;
