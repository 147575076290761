import { forwardRef, useImperativeHandle, useState, useRef, useEffect } from 'react';
// import logoImage from '../../../image/LinkLogo.png';
import styled from 'styled-components';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';

import HomeImagePath from '../../../image/Home.png';
import MemberImagePath from '../../../image/Member.png';
import ClassImagePath from '../../../image/Class.png';
import ChartImagePath from '../../../image/Chart.png';
import LockerImagePath from '../../../image/Locker.png';
import BoardImagePath from '../../../image/Board.png';
import NavNoticeImagePath from '../../../image/NavNotice.png';
import NoteImagePath from '../../../image/Note.png';
import NavSettingImagePath from '../../../image/NavSetting.png';

import HoverHomeImagePath from '../../../image/HoverHome.png';
import HoverMemberImagePath from '../../../image/HoverMember.png';
import HoverClassImagePath from '../../../image/HoverClass.png';
import HoverChartImagePath from '../../../image/HoverChart.png';
import HoverLockerImagePath from '../../../image/HoverLocker.png';
import HoverBoardImagePath from '../../../image/HoverBoard.png';
import HoverNavNoticeImagePath from '../../../image/HoverNavNotice.png';
import HoverNoteImagePath from '../../../image/HoverNote.png';
import HoverNavSettingImagePath from '../../../image/HoverNavSetting.png';

import ClickHomeImagePath from '../../../image/ClickHome.png';
import ClickMemberImagePath from '../../../image/ClickMember.png';
import ClickClassImagePath from '../../../image/ClickClass.png';
import ClickChartImagePath from '../../../image/ClickChart.png';
import ClickLockerImagePath from '../../../image/ClickLocker.png';
import ClickBoardImagePath from '../../../image/ClickBoard.png';
import ClickNavNoticeImagePath from '../../../image/ClickNavNotice.png';
import ClickNoteImagePath from '../../../image/ClickNote.png';
import ClickNavSettingImagePath from '../../../image/ClickNavSetting.png';
import FetchModule from '../Network/FetchModule';

const NavigationBar = forwardRef((props, ref) => {
  const { nowPage, setNowPage } = props;

  // const nowPageURL = window.location.href;
  // const defaultURL = 'http://localhost:3000';
  const defaultURL = 'https://link-zone.io';

  const history = useHistory();
  const [index, setIndex] = useState(0);
  // const [nowPage, setNowPage] = useState(nowPageURL);
  const [nowPageKey, setNowPageKey] = useState('홈');
  const [isExistPartnerGym, setIsExistPartnerGym] = useState(false);
  const [registrationCount, setRegistrationCount] = useState(0);

  let location = useLocation(); // URL 감지용

  useImperativeHandle(ref, () => ({
  }));

  let NavInfo = [{
    buttonRef: useRef(),
    img: HomeImagePath,
    hoverImg: HoverHomeImagePath,
    text: "홈",
    imgRef: useRef(),
    textRef: useRef(),
    subRef: useRef(),
    children: [],
    destination: ['/main'],
  },
  {
    buttonRef: useRef(),
    img: MemberImagePath,
    hoverImg: HoverMemberImagePath,
    text: "회원",
    imgRef: useRef(),
    textRef: useRef(),
    subRef: useRef(),
    children: [{
      btnRef: useRef(),
      subText: "유효 회원"
    },
    {
      btnRef: useRef(),
      subText: "만료 회원"
    },
    {
      btnRef: useRef(),
      subText: "회원 등록"
    },
    {
      btnRef: useRef(),
      subText: "회원권 신청"
    },
    {
      btnRef: useRef(),
      subText: '파트너 회원'
    }
    ],
    destination: ['/member/valid', '/member/expired', '/member/registration', '/member/waitting', '/member/partner_gym'],
  },
  // {
  //   buttonRef: useRef(),
  //   img: ClassImagePath,
  //   hoverImg: HoverClassImagePath,
  //   text: "수업",
  //   imgRef: useRef(),
  //   textRef: useRef(),
  //   subRef: useRef(),
  //   children: [{
  //     btnRef: useRef(),
  //     subText: "수업 관리", 
  //   },
  //   {
  //     btnRef: useRef(),
  //     subText: "시간표 제작",
  //   },
  // ],
  //   destination: ['/class', '/schedule'],
  // },
  {
    buttonRef: useRef(),
    img: ClassImagePath,
    hoverImg: HoverClassImagePath,
    text: "수업",
    imgRef: useRef(),
    textRef: useRef(),
    subRef: useRef(),
    children: [],
    destination: ['/class'],
  },
  {
    buttonRef: useRef(),
    img: ChartImagePath,
    hoverImg: HoverChartImagePath,
    text: "통계",
    imgRef: useRef(),
    textRef: useRef(),
    subRef: useRef(),
    children: [],
    destination: ['/statistics'],
  },
  {
    buttonRef: useRef(),
    img: LockerImagePath,
    hoverImg: HoverLockerImagePath,
    text: "락커",
    imgRef: useRef(),
    textRef: useRef(),
    subRef: useRef(),
    children: [],
    destination: ['/locker'],
  },
  // {
  //   buttonRef: useRef(),
  //   img: BoardImagePath,
  //   hoverImg: HoverBoardImagePath,
  //   text: "보드",
  //   imgRef: useRef(),
  //   textRef: useRef(),
  //   subRef: useRef(),
  //   children: [],
  //   destination: ['/board'],
  // },
  // {
  //   buttonRef: useRef(),
  //   img: NoteImagePath,
  //   hoverImg: HoverNoteImagePath,
  //   text: "노트",
  //   imgRef: useRef(),
  //   textRef: useRef(),
  //   subRef: useRef(),
  //   children: [{
  //     btnRef: useRef(),
  //     subText: "1:1",
  //   },
  //   {
  //     btnRef: useRef(),
  //     subText: "그룹"
  //   },
  //   {
  //     btnRef: useRef(),
  //     subText: "순위표",
  //   }
  //   ],
  //   destination: ['/note/one-on-one', '/note/program', '/note/ranking'],
  // },
  {
    buttonRef: useRef(),
    img: NoteImagePath,
    hoverImg: HoverNoteImagePath,
    text: "노트",
    imgRef: useRef(),
    textRef: useRef(),
    subRef: useRef(),
    children: [],
    destination: ['/note/program'],
  },
  // {
  //   buttonRef: useRef(),
  //   img: NavNoticeImagePath,
  //   hoverImg: HoverNavNoticeImagePath,
  //   text: "공지",
  //   imgRef: useRef(),
  //   textRef: useRef(),
  //   subRef: useRef(),
  //   children: [],
  //   destination: ['/notice'],
  // },
  {
    buttonRef: useRef(),
    img: NavNoticeImagePath,
    hoverImg: HoverNavNoticeImagePath,
    text: "공지",
    imgRef: useRef(),
    textRef: useRef(),
    subRef: useRef(),
    children: [{
      btnRef: useRef(),
      subText: '공지 작성'
    },
    {
      btnRef: useRef(),
      subText: '문자 발송'
    }],
    destination: ['/notice', '/message'],
  },
  {
    buttonRef: useRef(),
    img: NavSettingImagePath,
    hoverImg: HoverNavSettingImagePath,
    text: "설정",
    imgRef: useRef(),
    textRef: useRef(),
    subRef: useRef(),
    children: [],
    destination: ['/setting'],
  }] // 공지를 맨 아래로 내리고 그 밑에 설정 추가...


  useEffect(() => {
    let path = location.pathname;

    if (path === '/main') {
      setNowPageKey('홈');
      setIndex(0);
    }
    else if (path.includes('/member/valid')
      || path.includes('/member/expired')
      || path.includes('/member/registration')
      || path.includes('/member/waitting')
      || path.includes('/member/partner_gym')) {
      setNowPageKey('회원');
      setIndex(1)
    }
    else if (path.includes('/class')) {
      setNowPageKey('수업');
      setIndex(2);
    }
    else if (path.includes('/statistics')) {
      setNowPageKey('통계');
      setIndex(3);
    }
    else if (path.includes('/locker')) {
      setNowPageKey('락커');
      setIndex(4);
    }
    // else if (path.includes('/board')) {
    //   setNowPageKey('보드');
    //   setIndex(5);
    // }
    // else if (path.includes('/note/one-on-one')
    //   || path.includes('/note/program')
    //   || path.includes('/note/ranking')) {
    //   setNowPageKey('노트');
    //   setIndex(6)
    // }
    else if (path.includes('/notice')
      || path.includes('/message')) {
      setNowPageKey('공지');
      setIndex(6);
      // setIndex(6);
    }
    else if (path.includes('/setting')) {
      setNowPageKey('설정');
      setIndex(7);
      // setIndex(7);
    }

    setNowPage(defaultURL + location.pathname);
  }, [location])

  // useEffect(() => {
  //   if (!location.pathname.includes('/member/')) {
  //     window.localStorage.removeItem('ValidMemberMembershipNames');
  //     window.localStorage.removeItem('ValidMemberGenderSort');
  //     window.localStorage.removeItem('ValidMemberBirthSort');
  //     window.localStorage.removeItem('ValidMemberStartSort');
  //     window.localStorage.removeItem('ValidMemberEndSort');
  //     window.localStorage.removeItem('ValidMemberClassSort');
  //     window.localStorage.removeItem('ValidMemberPage');
      
  //     window.localStorage.removeItem('InvalidMemberMembershipNames');
  //     window.localStorage.removeItem('InvalidMemberGenderSort');
  //     window.localStorage.removeItem('InvalidMemberBirthSort');
  //     window.localStorage.removeItem('InvalidMemberStartSort');
  //     window.localStorage.removeItem('InvalidMemberEndSort');
  //     window.localStorage.removeItem('InvalidMemberClassSort');
  //     window.localStorage.removeItem('InvalidMemberPage');
  //   }
  // }, [location])

  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getDataVersion2('membership/registration/count', 'GET');

        if (responseData.status === 200) {
          setIsExistPartnerGym(responseData.data.isExistPartnerGym);
          setRegistrationCount(responseData.data.memberCount);
        }
        // else if (responseData.status === 401) {
        //   return;
        // }
      }
    )();
  }, [location])

  useEffect(() => {

    if (nowPage === defaultURL + '/'
      || nowPage.includes(defaultURL + '/certification')
      || nowPage.includes(defaultURL + '/identifier')
      || nowPage.includes(defaultURL + '/permute')
      || nowPage.includes(defaultURL + '/rule/')
      || nowPage.includes(defaultURL + '/full/board/')
      || nowPage.includes(defaultURL + '/full/board-test/')
      || nowPage.includes(defaultURL + '/payment')
      || nowPage === defaultURL + '/mypage'
      || nowPage.includes(defaultURL + '/find-id')
      || nowPage.includes(defaultURL + '/keypad')) return null;

    for (let i = 0; i < NavInfo.length; i++) {
      if (index !== i) {
        // NavInfo[i].textRef.current.style.display = `none`;
        NavInfo[i].textRef.current.style.color = `#DCD4CF`;
        NavInfo[i].buttonRef.current.style.backgroundColor = `#FDFCFB`;

        if (NavInfo[i].text === '홈') NavInfo[i].imgRef.current.style.background = `url(${HomeImagePath}) no-repeat center center`;
        if (NavInfo[i].text === '회원') {
          NavInfo[i].imgRef.current.style.background = `url(${MemberImagePath}) no-repeat center center`;
          // NavInfo[i].subRef.current.style.height = `0px`;
        }
        if (NavInfo[i].text === '수업') {
          NavInfo[i].imgRef.current.style.background = `url(${ClassImagePath}) no-repeat center center`;
          // NavInfo[i].subRef.current.style.height = `0px`;
        }
        if (NavInfo[i].text === '통계') NavInfo[i].imgRef.current.style.background = `url(${ChartImagePath}) no-repeat center center`;
        if (NavInfo[i].text === '락커') NavInfo[i].imgRef.current.style.background = `url(${LockerImagePath}) no-repeat center center`;
        // if (NavInfo[i].text === '보드') NavInfo[i].imgRef.current.style.background = `url(${BoardImagePath}) no-repeat center center`;
        if (NavInfo[i].text === '공지') NavInfo[i].imgRef.current.style.background = `url(${NavNoticeImagePath}) no-repeat center center`;
        // if (NavInfo[i].text === '노트') NavInfo[i].imgRef.current.style.background = `url(${NoteImagePath}) no-repeat center center`;
        if (NavInfo[i].text === '설정') NavInfo[i].imgRef.current.style.background = `url(${NavSettingImagePath}) no-repeat center center`;
      }
    }

    // NavInfo[index].textRef.current.style.display = `inline-block`;
    NavInfo[index].textRef.current.style.color = `#FFFFFF`;
    NavInfo[index].clicked = true;
    NavInfo[index].buttonRef.current.style.backgroundColor = `#FF8B48`;

    if (NavInfo[index].text === '홈') {
      NavInfo[index].imgRef.current.style.background = `url(${ClickHomeImagePath}) no-repeat center center`;
    }
    else if (NavInfo[index].text === '회원') {
      NavInfo[index].imgRef.current.style.background = `url(${ClickMemberImagePath}) no-repeat center center`;

      if (isExistPartnerGym) NavInfo[index].subRef.current.style.height = `240px`;
      else NavInfo[index].subRef.current.style.height = `192px`;
    }
    else if (NavInfo[index].text === '수업') {
      NavInfo[index].imgRef.current.style.background = `url(${ClickClassImagePath}) no-repeat center center`;
      // NavInfo[index].subRef.current.style.height = `96px`;
    }
    else if (NavInfo[index].text === '통계') {
      NavInfo[index].imgRef.current.style.background = `url(${ClickChartImagePath}) no-repeat center center`;
    }
    else if (NavInfo[index].text === '락커') {
      NavInfo[index].imgRef.current.style.background = `url(${ClickLockerImagePath}) no-repeat center center`;
    }
    // else if (NavInfo[index].text === '보드') {
    //   NavInfo[index].imgRef.current.style.background = `url(${ClickBoardImagePath}) no-repeat center center`;
    // }
    // else if (NavInfo[index].text === '노트') {
    //   NavInfo[index].imgRef.current.style.background = `url(${ClickNoteImagePath}) no-repeat center center`;
    //   NavInfo[index].subRef.current.style.height = `144px`;
    // }
    else if (NavInfo[index].text === '노트') {
      NavInfo[index].imgRef.current.style.background = `url(${ClickNoteImagePath}) no-repeat center center`;
    }
    else if (NavInfo[index].text === '공지') {
      NavInfo[index].imgRef.current.style.background = `url(${ClickNavNoticeImagePath}) no-repeat center center`;
      NavInfo[index].subRef.current.style.height = `96px`;
    }
    else if (NavInfo[index].text === '설정') {
      NavInfo[index].imgRef.current.style.background = `url(${ClickNavSettingImagePath}) no-repeat center center`;
    }
  }, [index, nowPage, isExistPartnerGym])

  // if (nowPage === defaultURL + '/'
  //   || nowPage.includes(defaultURL + '/certification')
  //   || nowPage.includes(defaultURL + '/identifier')
  //   || nowPage.includes(defaultURL + '/permute')
  //   || nowPage.includes(defaultURL + '/rule/')
  //   || nowPage.includes(defaultURL + '/full/board/')
  //   || nowPage.includes(defaultURL + '/full/board-test/')
  //   || nowPage.includes(defaultURL + '/payment')
  //   || nowPage === defaultURL + '/mypage'
  //   || nowPage.includes(defaultURL + '/find-id')
  //   || nowPage.includes(defaultURL + '/keypad')
  //   || nowPage.includes('/note/')) return null;

  if (nowPage === defaultURL + '/'
    || nowPage.includes(defaultURL + '/certification')
    || nowPage.includes(defaultURL + '/identifier')
    || nowPage.includes(defaultURL + '/permute')
    || nowPage.includes(defaultURL + '/rule/')
    || nowPage.includes(defaultURL + '/full/board/')
    || nowPage.includes(defaultURL + '/full/board-test/')
    || nowPage.includes(defaultURL + '/payment')
    || nowPage === defaultURL + '/mypage'
    || nowPage.includes(defaultURL + '/find-id')
    || nowPage.includes(defaultURL + '/keypad')) return null;


  function clickButton(index) {
    // for (var i = 0; i < NavInfo.length; i++) {
    //   if (NavInfo[i].text === nowPageKey) {
    //     NavInfo[i].textRef.current.style.display = `none`;
    //     NavInfo[i].buttonRef.current.style.backgroundColor = `#FDFCFB`;

    //     if (NavInfo[i].text === '홈') NavInfo[i].imgRef.current.style.background = `url(${HomeImagePath}) no-repeat center center`;
    //     else if (NavInfo[i].text === '회원') {
    //       NavInfo[i].imgRef.current.style.background = `url(${MemberImagePath}) no-repeat center center`;
    //       // NavInfo[i].subRef.current.style.height = `0px`;
    //     }
    //     else if (NavInfo[i].text === '수업') {
    //       NavInfo[i].imgRef.current.style.background = `url(${ClassImagePath}) no-repeat center center`;
    //       // NavInfo[i].subRef.current.style.height = `0px`;
    //     }  
    //     else if (NavInfo[i].text === '통계') NavInfo[i].imgRef.current.style.background = `url(${ChartImagePath}) no-repeat center center`;
    //     else if (NavInfo[i].text === '락커') NavInfo[i].imgRef.current.style.background = `url(${LockerImagePath}) no-repeat center center`;
    //     else if (NavInfo[i].text === '보드') NavInfo[i].imgRef.current.style.background = `url(${BoardImagePath}) no-repeat center center`;
    //     else if (NavInfo[i].text === '공지') NavInfo[i].imgRef.current.style.background = `url(${NavNoticeImagePath}) no-repeat center center`;
    //     else if (NavInfo[i].text === '노트') NavInfo[i].imgRef.current.style.background = `url(${NoteImagePath}) no-repeat center center`;
    //     else if (NavInfo[i].text === '설정') NavInfo[i].imgRef.current.style.background = `url(${NavSettingImagePath}) no-repeat center center`;
    //     break;
    //   }
    // }

    // if (nowPageKey === NavInfo[index].text && nowPageKey !== '홈') {
    //   setNowPageKey('홈');
    //   setIndex(0);
    // }
    // else {

    setNowPageKey(NavInfo[index].text);
    setIndex(index);

    if (NavInfo[index].children.length === 0) history.push(NavInfo[index].destination[0]);
  }

  function focusButton(index) {
    if (NavInfo[index].text === '홈' && NavInfo[index].text !== nowPageKey) {
      NavInfo[index].textRef.current.style.color = `#FF8B48`;
      NavInfo[index].imgRef.current.style.background = `url(${HoverHomeImagePath}) no-repeat center center`;
    }
    else if (NavInfo[index].text === '회원' && NavInfo[index].text !== nowPageKey) {
      NavInfo[index].textRef.current.style.color = `#FF8B48`;
      NavInfo[index].imgRef.current.style.background = `url(${HoverMemberImagePath}) no-repeat center center`;
    }
    else if (NavInfo[index].text === '수업' && NavInfo[index].text !== nowPageKey) {
      NavInfo[index].textRef.current.style.color = `#FF8B48`;
      NavInfo[index].imgRef.current.style.background = `url(${HoverClassImagePath}) no-repeat center center`;
    }
    else if (NavInfo[index].text === '통계' && NavInfo[index].text !== nowPageKey) {
      NavInfo[index].textRef.current.style.color = `#FF8B48`;
      NavInfo[index].imgRef.current.style.background = `url(${HoverChartImagePath}) no-repeat center center`;
    }
    else if (NavInfo[index].text === '락커' && NavInfo[index].text !== nowPageKey) {
      NavInfo[index].textRef.current.style.color = `#FF8B48`;
      NavInfo[index].imgRef.current.style.background = `url(${HoverLockerImagePath}) no-repeat center center`;
    }
    else if (NavInfo[index].text === '보드' && NavInfo[index].text !== nowPageKey) {
      NavInfo[index].textRef.current.style.color = `#FF8B48`;
      NavInfo[index].imgRef.current.style.background = `url(${HoverBoardImagePath}) no-repeat center center`;
    }
    else if (NavInfo[index].text === '공지' && NavInfo[index].text !== nowPageKey) {
      NavInfo[index].textRef.current.style.color = `#FF8B48`;
      NavInfo[index].imgRef.current.style.background = `url(${HoverNavNoticeImagePath}) no-repeat center center`;
    }
    else if (NavInfo[index].text === '노트' && NavInfo[index].text !== nowPageKey) {
      NavInfo[index].textRef.current.style.color = `#FF8B48`;
      NavInfo[index].imgRef.current.style.background = `url(${HoverNoteImagePath}) no-repeat center center`;
    }
    else if (NavInfo[index].text === '설정' && NavInfo[index].text !== nowPageKey) {
      NavInfo[index].textRef.current.style.color = `#FF8B48`;
      NavInfo[index].imgRef.current.style.background = `url(${HoverNavSettingImagePath}) no-repeat center center`;
    }
  }

  function blurButton(index) {
    if (NavInfo[index].text === '홈' && NavInfo[index].text !== nowPageKey) {
      NavInfo[index].textRef.current.style.color = `#DCD4CF`;
      NavInfo[index].imgRef.current.style.background = `url(${HomeImagePath}) no-repeat center center`;
    }
    else if (NavInfo[index].text === '회원' && NavInfo[index].text !== nowPageKey) {
      NavInfo[index].textRef.current.style.color = `#DCD4CF`;
      NavInfo[index].imgRef.current.style.background = `url(${MemberImagePath}) no-repeat center center`;
    }
    else if (NavInfo[index].text === '수업' && NavInfo[index].text !== nowPageKey) {
      NavInfo[index].textRef.current.style.color = `#DCD4CF`;
      NavInfo[index].imgRef.current.style.background = `url(${ClassImagePath}) no-repeat center center`;
    }
    else if (NavInfo[index].text === '통계' && NavInfo[index].text !== nowPageKey) {
      NavInfo[index].textRef.current.style.color = `#DCD4CF`;
      NavInfo[index].imgRef.current.style.background = `url(${ChartImagePath}) no-repeat center center`;
    }
    else if (NavInfo[index].text === '락커' && NavInfo[index].text !== nowPageKey) {
      NavInfo[index].textRef.current.style.color = `#DCD4CF`;
      NavInfo[index].imgRef.current.style.background = `url(${LockerImagePath}) no-repeat center center`;
    }
    else if (NavInfo[index].text === '보드' && NavInfo[index].text !== nowPageKey) {
      NavInfo[index].textRef.current.style.color = `#DCD4CF`;
      NavInfo[index].imgRef.current.style.background = `url(${BoardImagePath}) no-repeat center center`;
    }
    else if (NavInfo[index].text === '공지' && NavInfo[index].text !== nowPageKey) {
      NavInfo[index].textRef.current.style.color = `#DCD4CF`;
      NavInfo[index].imgRef.current.style.background = `url(${NavNoticeImagePath}) no-repeat center center`;
    }
    else if (NavInfo[index].text === '노트' && NavInfo[index].text !== nowPageKey) {
      NavInfo[index].textRef.current.style.color = `#DCD4CF`;
      NavInfo[index].imgRef.current.style.background = `url(${NoteImagePath}) no-repeat center center`;
    }
    else if (NavInfo[index].text === '설정' && NavInfo[index].text !== nowPageKey) {
      NavInfo[index].textRef.current.style.color = `#DCD4CF`;
      NavInfo[index].imgRef.current.style.background = `url(${NavSettingImagePath}) no-repeat center center`;
    }
  }

  function clickSubButton(index, idx) {

    if (NavInfo[index].destination[idx] === '/member/valid') {
      window.localStorage.removeItem('ValidMemberMembershipNames');
      window.localStorage.removeItem('ValidMemberGenderSort');
      window.localStorage.removeItem('ValidMemberBirthSort');
      window.localStorage.removeItem('ValidMemberStartSort');
      window.localStorage.removeItem('ValidMemberEndSort');
      window.localStorage.removeItem('ValidMemberClassSort');
      window.localStorage.removeItem('ValidMemberPage');
    }
    else if (NavInfo[index].destination[idx] === '/member/expired') {
      window.localStorage.removeItem('InvalidMemberMembershipNames');
      window.localStorage.removeItem('InvalidMemberGenderSort');
      window.localStorage.removeItem('InvalidMemberBirthSort');
      window.localStorage.removeItem('InvalidMemberStartSort');
      window.localStorage.removeItem('InvalidMemberEndSort');
      window.localStorage.removeItem('InvalidMemberClassSort');
      window.localStorage.removeItem('InvalidMemberPage');
    }
    
    history.push(NavInfo[index].destination[idx]);
  }

  // 파트너 회원을 포함한 소주제 표출여부 결정 //
  function decideViewSubMapComponent(data) {
    // console.log(data);
    if (data.subText === '파트너 회원') {
      if (isExistPartnerGym) return true;
      else return false;
    }
    else return true;
  }

  return (
    // location.pathname.includes('/note/') ?
    //   <WidthNavigationBar>

    //   </WidthNavigationBar>
    //   :
      <TopLevelWrapper>
        {
          NavInfo.map((data, index) => (
            <TotalWrapper key={index}>
              {/* {
              (nowPageKey === '회원' && data.text === '회원' && nowPage !== 'https://link-zone.io/member/waitting' && registrationCount > 0) &&
              <CountComponent>
                <Text style={{ marginTop: '-2px' }} fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF' cursor='default'>{registrationCount}</Text>
              </CountComponent>
            } */}
              <Button ref={data.buttonRef} onClick={() => clickButton(index)} onMouseOver={() => focusButton(index)} onMouseLeave={() => blurButton(index)} >
                <Img ref={data.imgRef} img={data.img} hoverImg={data.hoverImg} index={index} />
                <ButtonText ref={data.textRef}>{data.text}</ButtonText>
              </Button>
              {data.children.length !== 0 && data.text === nowPageKey ?
                <SubButtonWrapper ref={data.subRef}>
                  {
                    data.children.map((childData, idx) => (
                      <SubWrapper>
                        {
                          (nowPageKey === '회원' && data.text === '회원' && idx === 3 && registrationCount > 0) &&
                          <CountComponent style={{ marginRight: '-8px' }}>
                            <Text style={{ marginTop: '-2px' }} fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF' cursor='default'>{registrationCount}</Text>
                          </CountComponent>
                        }
                        {
                          decideViewSubMapComponent(childData) &&
                          <SubButton key={idx} ref={childData.btnRef} isClicked={nowPage.includes(defaultURL + data.destination[idx])} onClick={() => clickSubButton(index, idx)}>
                            {childData.subText}
                          </SubButton>
                        }
                      </SubWrapper>
                    ))
                  }
                </SubButtonWrapper>
                :
                null
              }
            </TotalWrapper>
          ))
        }
      </TopLevelWrapper>
  )
})

export default NavigationBar;

const WidthNavigationBar = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 200px;

  background-color: red;
`;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 120px;
  
  margin-right: 20px;
  background-color: #FDFCFB;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-end;
`;

const CountComponent = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: -12px;

  width: 24px;
  height: 24px;

  border-radius: 24px;
  background-color: #FF3131;

  :hover {
    cursor: default;
  }
`;

const Button = styled.div`
  display: flex;
  flex-direction: row;

  // justify-content: center;
  align-items: center;

  width: 120px;
  height: 48px;
  // gap: 25px;
  border-radius: 30px;

  margin-bottom: 8px;

  background-color: #FDFCFB;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    // background-color: #FF8B48;
  }
`;

const Img = styled.div`
  display: flex;
  flex-direction: row;

  width: 24px;
  height: 24px;

  justify-content: center;
  align-items: center;

  margin-left: 24px;
  margin-right: ${props => props.index === 0 ? `25px` : `16px`};

  background: url(${props => props.img}) no-repeat center center;
  transition: all 0.3s ease-in-out;
            
  :hover {
    cursor: pointer;
    // background: url(${props => props.hoverImg}) no-repeat center center;
    // background-color: #FF8B48;
  }
`;

const ButtonText = styled.p`
  // display: none;
  font-family: NotoSansKR-Medium;
  font-size: 18px;

  color: #FDFCFB;

  margin: 0 0 0 0;

  transition: all 0.3s ease-in-out;
`;

const SubButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 120px;
  height: 0px;

  border-radius: 24px;

  background-color: #F6F4F2;
  
  overflow: hidden;

  transition: all 0.3s ease-in-out;
`;

const SubWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-end;

  width: 100%;
  height: 48px;
`;

const SubButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 120px;
  height: 48px;

  background-color: ${props => props.isClicked ? `#FF8B48` : `#F6F4F2`};
  
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: ${props => props.isClicked ? `#FFFFFF` : `#BEBEBE`};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    color: ${props => props.isClicked ? `#FFFFFF` : `#FF8B48`};
  }
`;

// const SubText = styled.p`
//   font-family: NotoSansKR-Medium;
//   font-size: 14px;

//   color: #BEBEBE;

//   :hover {
//     cursor: pointer;
//     color: #FF8B48;
//   }
// `;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  
  :hover {
    cursor: ${props => props.hover};
  }
`;