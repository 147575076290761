import { React } from 'react';
import styled from 'styled-components';

import NoticeListView from '../../Model/Individual/NoticeModel/NoticeListView';

function NoticeListPage() {

  return(
    <Container>
      <NoticeListView/>
    </Container>
  )
}

export default NoticeListPage;

const Container = styled.div`
  display: flex;
  flex-direcion: column;

  width: 100%;
`;