import { React } from 'react';
import styled from 'styled-components';

import YoutubeButtonImage from '../../image/YoutubeButtonImage.png';

export default function YoutubeURLInput(props) {
  const { value, setValue, onPressEnter } = props;

  function handleChange(e) {
		setValue(e.target.value);
	}

	function handleFocus(e) {
    
	}

	function handleBlur(e) {
    // onPressEnter();
	}

  function onKeyUp(e) {
    // if (e.keyCode === 13) onPressEnter();
  }

  return (
    <TopLevelWrapper>
      <YoutubeImage src={YoutubeButtonImage} />
      <Input
        type='text'
        placeholder='https://www.youtube.com/watch?v='
        value={value}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyUp={onKeyUp} />
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  padding: 0px 12px 0px 12px;

  width: calc(100% - 26px);
  height: calc(100% - 2px);

  background-color: transparent;

  border: 1px solid #FF3131;
  border-radius: 12px;
`;

const Input = styled.input`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: 100%;
  height: 100%;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;
  
  border: 0px;

  margin: 0 0 0 0;
  padding: 0 0 0 0;

  outline: none;

  ::placeholder {
    color: #BDBDBD;
  }

  background-color: transparent;
  transition: all 0.3s ease-in-out;
`;

const YoutubeImage = styled.img`
  margin-right: 8px;

  width: 31px;
  height: 22px;
`;