import { React, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';

// import ListSelectBox from './ListSelectBox';
import TestListSelectBox from './TestListSelectBox';
import RangeTestInput from '../../../Component/BoardComponent/RangeTestInput';

import NormalSectionBox from './NormalSectionBox';
import WorkoutSelectBox from './WorkoutSelectBox';
// Component Import //

const ExerciseTestBox = forwardRef((props, ref) => {
  const { 
    date, 
    clickDate, 
    classTime, 
    useData, 
    workoutId,
    setWorkoutId,
    setLessonId, 
    setScheduleId, 
    setClassTime, 
    rendering 
  } = props;

  // const [workoutId, setWorkoutId] = useState(0);
  const [title, setTitle] = useState('');
  const [sectionList, setSectionList] = useState([]);
  const [scale, setScale] = useState(0);
  const [className, setClassName] = useState('');

  useImperativeHandle(ref, () => ({
    getValue: () => {
      return sectionList;
    },
    getTotalTitle: () => {
      return title;
    },
    getWorkoutId: () => {
      return workoutId;
    }
  }));

  // // 날짜에 맞게 데이터 세팅 //
  // useEffect(() => {
  //   for (var i = 0; i < useData.length; i++) {
  //     if (useData[i].classDate.clone().format('YYYY.MM.DD') === clickDate) {
  //       setTitle(useData[i].title);
  //       setWorkoutId(useData[i].workoutId);
  //       setSectionList(useData[i].sectionList);
  //     }
  //   }
  // }, [useData, clickDate])

  // 데이터 세팅 //
  useEffect(() => {
    setTitle(useData.title);

    if (useData.lessonName === undefined) setClassName(useData.title);
    else setClassName(useData.lessonName + ' ' + useData.title);
    
    setWorkoutId(useData.workoutId);
    setSectionList(useData.sectionList);
  }, [useData])

  // 컴포넌트 크기 변경시 //
  function changeScaleSize(value) {
    setScale(value);
  }

  return (
    <TopLevelWrapper>
      <TotalWrapper>
        <DateWrapper>
          <DateScheduleInfoWrapper>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#232323'>{clickDate}</Text>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FF8B48'>{'( ' + className + ' )'}</Text>
            {/* <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FF8B48'>{classTime})</Text> */}
          </DateScheduleInfoWrapper>
          {/* <TestListSelectBox date={date} setLessonId={setLessonId} setScheduleId={setScheduleId} setClassName={setClassName} setClassTime={setClassTime} rendering={rendering}/> */}
          <WorkoutSelectBox date={date} setWorkoutId={setWorkoutId}/>
        </DateWrapper>
        <ScrollWrapper>
          <TitleWrapper topMargin={0}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>제목</Text>
            <RangeTestInput defaultVal={1} changeFn={changeScaleSize}/>
          </TitleWrapper>
          <TotalTitleBox height={34 + (34 * scale / 100)} fontSize={14 + (14 * scale / 100)}>{title === '' ? '작성이 필요합니다' : title}</TotalTitleBox>
          {
            sectionList.map((data, index) => (
              <NormalSectionBox
                key={index}
                index={index}
                scale={scale}
                sectionData={data}/>
            ))
          }
        </ScrollWrapper>
      </TotalWrapper>
    </TopLevelWrapper>
  )
})

export default ExerciseTestBox;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  // width: 582px;
  width: 786px;
  // height: 813px;
  // min-height: 707px;
  max-height: 1083px;
  
  padding-top: 28px;
  padding-bottom: 28px;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  background-color: #FFFFFF;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 90.33%;
`;

const DateScheduleInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 97.71%;

  margin-top: ${props => props.topMargin}px;
`;

const TotalTitleBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: calc(97.71% - 34px);
  // min-height: 34px;
  min-height: ${props => props.height}px;

  margin-top: 8px;
  padding-right: 16px;
  padding-left: 16px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;

  font-family: NotoSansKR-Regular;
  // font-size: 14px;
  font-size: ${props => props.fontSize}px;

  color: #595959;
`;

const DateWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const ScrollWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  margin-top: 24px;

  width: 100%;
  // max-height: ${props => props.isModify ? `894px` : `1039px`};
  max-height: 894px;
  
  overflow-x: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
		border: 3px solid transparent;
		// height: 92px;
		background-color: #DADADA;
		border-radius: 200px;
	}

  &::-webkit-scrollbar-track {
    // height: 439px;
		// border: 4px solid transparent;
		background-color: #F0F0F0;
		border-radius: 200px;
	}
`;