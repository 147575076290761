import { React } from 'react';
import styled from 'styled-components';

const DietInfo = () => {
  
  return (
    <TopLevelWrapper>

    </TopLevelWrapper>
  )
}

export default DietInfo;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 306px;
  height: 396px;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  background-color: #FFFFFF;
`;