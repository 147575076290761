import { React, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

// import DataInput from '../../../Component/NoteComponent/DataInput';
// import SingleDataInput from '../../../Component/NoteComponent/SingleDataInput';
import SingleRestInput from '../../Component/Legacy/LegacySingleRestInput';
import SmallTimeInput from '../../Component/Legacy/LegacySmallTimeInput';
import IncludeSlashDataInput from '../../Component/Legacy/LegacyIncludeSlashDataInput';
import IncludeSlashSingleDataInput from '../../Component/Legacy/LegacyIncludeSlashSingleDataInput';

import UnitSelectBox from '../../../Component/NoteComponent/UnitSelectBox';

import ProgramVideoIcon from '../../../image/ProgramVideoIcon.png';
import ExerciseDeleteIcon from '../../../image/ExerciseDeleteIcon.png';
import MovementOptionIcon from '../../../image/MovementOptionIcon.png';
import CheckImage from '../../../image/WhiteCheck.png';
// import CopyIcon from '../../image/CopyIcon.png';

export default function LegacyScaleOptionMovementBox (props) {
  const {
    data,
    scaleOptionData,
    dataListLength, 
    idx,
    realIndex,
    tag,
    reps,
    division,
    target,
    targetStartY,
    targetEndY,
    clickIndex,
    changedIndex,
    setChangedIndex,
    clickDivision,
    emptyHeight,
    onChangeData,
    onChangeUnit,
    onClickRest,
    onClickMovementVideo,
    onClickMovementOption,
    onClickDeleteMovement,
  } = props;

  const [scaleCount, setScaleCount] = useState(0);
  const TopLevelRef = useRef();
  const TotalRef = useRef();

  const weightOptions = ['Kg', 'Pounds', '%RM', '%Body'];
  const distanceOptions = ['Km', 'm', 'Cm', 'inch', 'Feet'];
  const caloriesOptions = ['Kcal', 'Cal'];

  // 순서 정하기 //
  useEffect(() => {
    let dependencyIndex = data.dependencyIndex;
    let count = 0;

    for (let i = 0; i < scaleOptionData.length; i++) {
      if (scaleOptionData[i].dependencyIndex === dependencyIndex) {
        if (data.movementId === scaleOptionData[i].movementId && i === realIndex) break;
        else count += 1;
      }
    }

    setScaleCount(count);
  }, [data, scaleOptionData, realIndex])

  // 한자리 숫자 앞에 0 붙이기 //
  function numberPad(n, width) {
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
  }


  return (
    <TopLevelWrapper ref={TopLevelRef} index={target === undefined ? dataListLength - idx : null}>
      <TotalWrapper ref={TotalRef}>
        <MovementWrapper>
          <MovementTitleWrapper>
            <TextScaleButtonWrapper>
              <NumberWrapper /*onMouseDown={onComponentMouseDown}*/>
                <NumberBox>
                  <HoverPointerText fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>{numberPad(idx + 1, 2)}</HoverPointerText>
                </NumberBox>
                <HoverPointerText fontFamily='Poppins-Medium' fontSize={12} color='#4F4F4F'>{data.movementName}</HoverPointerText>
              </NumberWrapper>
              <ScaleButton>
                <HoverPointerText fontFamily='Poppins-Medium' fontSize={12} color='#4161AF'>{'Scale movement ' + String.fromCharCode(scaleCount + 65)}</HoverPointerText>
              </ScaleButton>
            </TextScaleButtonWrapper>
            <IconWrapper>
              <Icon src={ProgramVideoIcon} onClick={() => onClickMovementVideo(division, realIndex)} />
              <Icon src={MovementOptionIcon} onClick={() => onClickMovementOption(division, realIndex)} />
              <Icon src={ExerciseDeleteIcon} onClick={() => onClickDeleteMovement(division, realIndex)} />
            </IconWrapper>
          </MovementTitleWrapper>
          {
            data.isUseCount &&
            <LayerWrapper topMargin={16}>
              <ComponentWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>횟수</Text>
                {
                  tag === 'SINGLE' &&
                    <SingleCountWrapper>
                      <IncludeSlashSingleDataInput value={data.count} setValue={onChangeData} unit='REPS' column='count' division={division} index={realIndex} />
                      <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>X</Text>
                      <IncludeSlashSingleDataInput value={data.sets} setValue={onChangeData} unit='SETS' column='sets' division={division} index={realIndex} />
                    </SingleCountWrapper>
                }
                { 
                  tag === 'ForTime-Different Reps' && 
                  <DifferentRepsBox>
                    <ValueBox>
                      <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{reps}</Text>
                    </ValueBox>
                    <UnitBox>
                      <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REPS</Text>
                    </UnitBox>
                  </DifferentRepsBox>
                }
                {         
                  tag !== 'SINGLE' && tag !== 'ForTime-Different Reps' && 
                  <IncludeSlashDataInput value={tag === 'TABATA' ? 'MAX' : data.count} setValue={onChangeData} unit='REPS' column='count' division={division} index={realIndex} /> 
                }
              </ComponentWrapper>
            </LayerWrapper>
          }
          {
            data.isUseWeight &&
            <LayerWrapper topMargin={12}>
              <ComponentWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>무게</Text>
                <IncludeSlashDataInput value={data.weight} setValue={onChangeData} unit={data.weightUnit} column='weight' division={division} index={realIndex} />
              </ComponentWrapper>
              <UnitSelectBox value={data.weightUnit} setValue={onChangeUnit} options={weightOptions} column='weight' division={division} index={realIndex} />
            </LayerWrapper>
          }
          {
            data.isUseDistance &&
            <LayerWrapper topMargin={12}>
              <ComponentWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>거리</Text>
                <IncludeSlashDataInput value={data.distance} setValue={onChangeData} unit={data.distanceUnit} column='distance' division={division} index={realIndex} />
              </ComponentWrapper>
              <UnitSelectBox value={data.distanceUnit} setValue={onChangeUnit} options={distanceOptions} column='distance' division={division} index={realIndex} />
            </LayerWrapper>
          }
          {
            data.isUseHeight &&
            <LayerWrapper topMargin={12}>
              <ComponentWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>높이</Text>
                <IncludeSlashDataInput value={data.height} setValue={onChangeData} unit={data.heightUnit} column='height' division={division} index={realIndex} />
              </ComponentWrapper>
              <UnitSelectBox value={data.heightUnit} setValue={onChangeUnit} options={distanceOptions} column='height' division={division} index={realIndex} />
            </LayerWrapper>
          }
          {
            data.isUseCalories &&
            <LayerWrapper topMargin={12}>
              <ComponentWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>열량</Text>
                <IncludeSlashDataInput value={data.calories} setValue={onChangeData} unit={data.caloriesUnit} column='calories' division={division} index={realIndex} />
              </ComponentWrapper>
              <UnitSelectBox value={data.caloriesUnit} setValue={onChangeUnit} options={caloriesOptions} column='calories' division={division} index={realIndex} />
            </LayerWrapper>
          }
          {
            data.isUseTime &&
            <LayerWrapper topMargin={12}>
              <ComponentWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>시간</Text>
                <TimeInputWrapper>
                  {/* <SmallTimeInput value={parseInt(data.time / 60) > 0 ? parseInt(data.time / 60) : 0} setValue={onChangeData} unit='MIN' column='minute' division={division} index={realIndex} />
                  <SmallTimeInput value={parseInt(data.time % 60) > 0 ? parseInt(data.time % 60) : 0} setValue={onChangeData} unit='SEC' column='second' division={division} index={realIndex} /> */}
                  <SmallTimeInput value={data.minute} setValue={onChangeData} unit='MIN' column='minute' division={division} index={realIndex} />
                  <SmallTimeInput value={data.second} setValue={onChangeData} unit='SEC' column='second' division={division} index={realIndex} />
                </TimeInputWrapper>
              </ComponentWrapper>
            </LayerWrapper>
          }
          {
            data.isUseTempo &&
            <LayerWrapper topMargin={12}>
              <ComponentWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>템포</Text>
                <IncludeSlashDataInput value={data.tempo} setValue={onChangeData} unit='TEMPO' column='tempo' division={division} index={realIndex} />
              </ComponentWrapper>
            </LayerWrapper>
          }
          {
            tag === 'SINGLE' &&
            <RestComponentWrapper topMargin={12}>
              <CheckBox isClicked={data.isUseRest} onClick={() => onClickRest(division, idx)}>
                <Check src={CheckImage} />
              </CheckBox>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>쉬는 시간</Text>
              <SingleRestInput value={data.rest} setValue={onChangeData} disabled={!data.isUseRest} unit='SEC' column='rest' division={division} index={realIndex} />
            </RestComponentWrapper>
          }
        </MovementWrapper>
      </TotalWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: 100%;
  z-index: ${props => props.index};
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: 100%;

  background-color: #FFFFFF;
`;

const MovementWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: 100%;

  margin-top: 23px;
  background-color: #FFFFFF;
`;

const NumberWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;

  :hover {
    cursor: pointer;
  }
`;

const MovementTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 12px;
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;

  :hover {
    cursor: pointer;
  }
`;

const NumberBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 27px;
  height: 18px;

  border-radius: 6px;
  // background-color: #4161AF;
  background-color: #00A3FF;

  // font-family: NotoSansKR-Bold;
  // font-size: 12px;

  // color: #FFFFFF;

  margin: 0 0 0 0;
  padding: 0 0 0 0;
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;

  margin-top: ${props => props.topMargin}px;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const SingleCountWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const TextScaleButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 12px;
`;

const ScaleButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 128px;
  height: 18px;

  // border-radius: 6px;
  // background-color: #E0E0E0;

  // :hover {
  //   cursor: pointer;
  // }
`;

const HoverPointerText = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  padding: 0 0 0 0;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  padding: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const TimeInputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 24px;
`;

const RestComponentWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 7px;

  margin-top: ${props => props.topMargin}px;
`;

const CheckBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;

  border-radius: 6px;
  background-color: ${props => props.isClicked ? `#6DD49E` : `#E0E0E0`};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const Check = styled.img`
  width: 10px;
  height: 9px;
`;

const EmptyBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  min-height: ${props => props.height}px;

  // background-color: red;
  // background-color: #FFFFFF;
`;

const DifferentRepsBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding: 0px 16px 0px 16px;

  width: 286px;
  height: 34px;

  border: 1px solid #E0E0E0;
  border-radius: 15px;
  background-color: #FFFFFF;
`;

const ValueBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: 87%;
  height: 100%;

  overflow: hidden;
`;

const UnitBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-end;

  width: 13%;
`;