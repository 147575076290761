import { React, useRef, useEffect, useState } from 'react';
import styled from 'styled-components';

import LogoPath from '../../../image/CompleteSignUpLogo.png';
import CheckImage from '../../../image/WhiteCheck.png';

import ExitButtonImage from '../../../image/ExitButtonImage.svg';

function ExerciseSaveModal(props) {
  const {
    isUseSameLessonAllSave,
    setIsUseSameLessonAllSave,
    batchSaveData,
    setBatchSaveData,
    workoutId,
    lessonName,
    yesFn,
    noFn,
    ignoreFn
  } = props;

  const [isUnStorable, setIsUnStorable] = useState(false);
  const [isFirstSetting, setIsFirstSetting] = useState(true);
  const [lessonColor, setLessonColor] = useState('#FF8B48');

  const TopLevelRef = useRef();

  // Modal 창 스크롤 제어 //
  useEffect(() => {
    document.body.style = `overflow: hidden`;
    return () => document.body.style = `overflow: auto`;
  }, [])

  // // 현재 선택되어있는 수업의 색상 받아오기 //
  // useEffect(() => {
  //   if (!isFirstSetting) return;

  //   for (let i = 0; i < batchSaveData.length; i++) {
  //     if (lessonName === batchSaveData[i].name) {
  //       setLessonColor(batchSaveData[i].color);
  //       setIsFirstSetting(false);
  //       setIsUnStorable(batchSaveData[i].isExistWorkout);

  //       if (batchSaveData[i].isExistWorkout) setIsUseSameLessonAllSave(false);
  //       break;
  //     }
  //   }
  // }, [isFirstSetting, lessonName, batchSaveData])


  // // 수업 저장 적용 체크박스 클릭시 //
  // function onClickCheckBox(index) {
  //   let data = batchSaveData.slice();

  //   data[index].isClicked = !data[index].isClicked;
  //   setBatchSaveData(data);
  // }

  return (
    <Background>
      <TopLevelWrapper ref={TopLevelRef}>
        <LogoWrapper>
          <ExitButton style={{ opacity: 0, zIndex: -1 }} src={ExitButtonImage} />
          <Logo src={LogoPath} />
          <ExitButton src={ExitButtonImage} onClick={noFn} />
        </LogoWrapper>
        <ExplanationWrapper>
          <KorExplanation>디자인한 운동을 저장하시겠습니까?</KorExplanation>
        </ExplanationWrapper>
        {/* <CheckBoxTotalWrapper>
          {
            (!isUnStorable || workoutId !== 0) &&
            <CheckBoxWrapper>
              <TextWrapper>
                <CheckBox isClicked={isUseSameLessonAllSave} onClick={() => setIsUseSameLessonAllSave(!isUseSameLessonAllSave)}>
                  <Check src={CheckImage} />
                </CheckBox>
                <Text fontFamily='NotoSansKR-Bold' size={12} color='#000000'>금일 다른시간</Text>
                <Text fontFamily='NotoSansKR-Bold' size={12} color={lessonColor}>"{lessonName}"</Text>
                <Text fontFamily='NotoSansKR-Bold' size={12} color='#000000'>수업을 공통 적용하시겠습니까?</Text>
              </TextWrapper>
            </CheckBoxWrapper>
          }
          {
            batchSaveData.map((data, index) => (
              data.name !== lessonName && (workoutId === 0) && !data.isExistWorkout &&
              <CheckBoxWrapper key={index}>
                <TextWrapper>
                  <CheckBox isClicked={data.isClicked} onClick={() => onClickCheckBox(index)}>
                    <Check src={CheckImage} />
                  </CheckBox>
                  <Text fontFamily='NotoSansKR-Bold' size={12} color='#000000'>금일 다른시간</Text>
                  <Text fontFamily='NotoSansKR-Bold' size={12} color={data.color}>"{data.name}"</Text>
                  <Text fontFamily='NotoSansKR-Bold' size={12} color='#000000'>수업을 공통 적용하시겠습니까?</Text>
                </TextWrapper>
              </CheckBoxWrapper>
            ))
          }
        </CheckBoxTotalWrapper> */}
        <ButtonWrapper>
          <NoButton onClick={ignoreFn}>
            <ButtonText>아니요</ButtonText>
          </NoButton>
          <YesButton onClick={yesFn}>
            <ButtonText>예</ButtonText>
          </YesButton>
        </ButtonWrapper>
      </TopLevelWrapper>
    </Background>
  )
}

export default ExerciseSaveModal;

const Background = styled.div`
  position: fixed;
	display: flex;
	flex-direction: column;

  justify-content: center;
  align-items: center;

  top: 0;
  left: 0;

  z-index: 500;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.5);
`;

const TopLevelWrapper = styled.div`
	display: flex;
	flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  padding-bottom: 28px;

	width: 476px;
	// height: 268px;
  // height: 298px;
  
	border-radius: 50px;
  background-color: #FFFFFF;

  transition: all 0.5s ease-in-out;
`;

const LogoWrapper = styled.div`
  // position: absolute;

  display: flex;
  flex-direction: row;

  justify-content: space-around;
  // justify-content: center;
  align-items: flex-end;

  // margin-top: -270px;
  
  margin-top: -113px;
  // background-color: red;

  width: 100%;
`;

const Logo = styled.img`
  width: 200px;
  height: 200px;
`;

const ExitButton = styled.img`
  width: 20px;
  height: 20px;

  margin-bottom: 20px;

  :hover {
    cursor: pointer;
  }
`;

const ExplanationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  justify-content: center;
  align-items: center;

  // margin-top: 40px;
  // margin-top: 60px;
  margin-top: 23px;

  width: 90%;
`;

const KorExplanation = styled.pre`
  font-family: NotoSansKR-Bold;
  font-size: 25px;

  margin: 0 0 0 0;

  color: #000000;
`;

const CheckBoxTotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const CheckBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
  
  margin-top: 17px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;

  // margin-top: 17px;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.size}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
`;

const CheckBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;

  border-radius: 6px;
  background-color: ${props => props.isClicked ? `#6DD49E` : `#E0E0E0`};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const Check = styled.img`
  width: 10px;
  height: 8.5px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 14px;

  margin-top: 47px;
`;

const YesButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 155px;
  height: 45px;

  border-radius: 23px;
  background-color: #FF8B48;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background: #FF7322;
  }
`;

const NoButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 155px;
  height: 45px;

  border-radius: 23px;
  background-color: #6DD49E;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background: #2BCB67;
  }
`;

const ButtonText = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 18px;
  
  color: #FFFFFF;

  margin: 0 0 0 0;
`;