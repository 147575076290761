import { React, useState, useEffect, useRef  } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import lodash from 'lodash';

import TextArea from '../../../Component/BoardComponent/NoticeTextArea';

import FetchModule from '../../Share/Network/FetchModule';

const ExerciseNoticeView = (props) => {
  const { notepad } = props;

  return (
    <TopLevelWrapper>
      <ViewWrapper>
        <TopLineWrapper>
          <Title>메모</Title>
        </TopLineWrapper>
        <TextSection>
          <Text>{notepad.contents}</Text>
        </TextSection>
      </ViewWrapper>
    </TopLevelWrapper>
  )
}

export default ExerciseNoticeView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 378px;
  // height: 468px;

  // border-radius: 30px;
  // box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  // background-color: #FFFFFF;
`;

const ViewWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const TopLineWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;
  
  // width: 324px;
`;

const Title = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  margin: 0 0 0 0;

  color: #232323;
`;

const ModifyButton = styled.p`
  font-family: ${props => props.isClicked ? `NotoSansKR-Medium` : `NotoSansKR-Regular`};
  font-size: 14px;

  margin: 0 0 0 0;

  color: ${props => props.isClicked ? `#777777` : `#5BD98A`};

  :hover {
    cursor: pointer;
  }
`;

const TextSection = styled.div`
  display: flex;
  flex-direction: column;

  gap: 5px;
  margin-top: 8px;
  padding: 8px 16px 0 16px;

  width: 210px;
  // height: 466px;
  height: 670px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;

  overflow-y: scroll;

  &::-webkit-scrollbar {
		width: 9px;
	}

	&::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 20px #DFDFDF;
		border: 3px solid transparent;
		height: 25px;
		// background-color: #FF8B48;
		border-radius: 200px;
	}

	&::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px transparent;
		border: 4px solid transparent;
		background-color: transparent;
	}

	&::-webkit-scrollbar-track-piece {
		background-color: transparent;
	}
`;

const Text = styled.pre`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;

  margin: 0 0 0 0;
`;
