import { React } from 'react';
import styled from 'styled-components';

export default function GuestPhoneInput(props) {
	const { value, setValue, placeholder } = props;

	function handleClick(e) {
	}

	function handleChange(e) {
    let phone = e.target.value;

    const regex = /^[0-9\b -]{0,13}$/;

    if (regex.test(phone)) {

      if (phone.length === 10) setValue(phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'));
      else if (phone.length === 13) setValue(phone.replace(/-/g, '').replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3'));
      else setValue(phone);
    }
	}

	function handleFocus(e) {
	}

	function handleBlur(e) {
	}

  return (
    <Input
      type="text"
      placeholder={placeholder}
      value={value}
      onClick={handleClick}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur} />
    )
};

const Input = styled.input`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  margin: 0 0 0 0;
  padding: 0 0 0 0;

  width: 100%;
  height: 100%;

	outline: none;
  border: 0px;
  
	color: #777777;

	::placeholder {
    font-family: NotoSansKR-Regular;
    font-size: 12px;
    
    color: #777777;
	}
	
  transition: all 0.3s ease-in-out;
`;