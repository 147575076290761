import { React, useRef } from 'react';
import styled from 'styled-components';

export default function MovementDetailCommentInputForScaleOption(props) {
  const { value, setValue, disabled } = props;

  const InputRef = useRef();

  function handleChange(e) {
		setValue(e.target.value);
	}

	function handleFocus(e) {
    
	}

	function handleBlur(e) {

	}

  return (
    <TopLevelWrapper disabled={disabled}>
      <Input
        ref={InputRef}
        type='text'
        placeholder='동작에 대한 부가적인 설명을 입력해 주세요'
        value={value}
        disabled={disabled}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur} />
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: calc(100% - 26px);
  // height: 66px;
  // height: 186px;
  height: 160px;

  @media (min-height: 800px) {
    height: 100px;
  }
  @media (min-height: 1034px) {
    height: 130px;
  }
  @media (min-height: 1045px) {
    height: 150px;
  }
  @media (min-height: 1056px) {
    height: 150px;
  }
  @media (min-height: 1070px) {
    height: 160px;
  }

  gap: 8px;
  // margin-top: 8px;
  padding: 6px 12px 6px 12px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background-color: ${props => props.disabled ? `#ECECEC` : `#FFFFFF`};

  transition: all 0.3s ease-in-out;
`;

const Input = styled.textarea`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  height: 100%;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;

  border: 0px;

  margin: 0 0 0 0;
  padding: 0 0 0 0;

  outline: none;
  resize: none;

  ::placeholder {
    color: #CBCBCB;
  }

  ::disabled {
    background-color: #ECECEC;
  }

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
		border: 3px solid transparent;
		// height: 92px;
		background-color: #DADADA;
		border-radius: 200px;
	}

  &::-webkit-scrollbar-track {
    // height: 439px;
		// border: 4px solid transparent;
		background-color: #F0F0F0;
		border-radius: 200px;
	}

  transition: all 0.3s ease-in-out;
`;