import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from "react";
import styled from "styled-components";

import moment from 'moment';

import DiscountPaymentInput from "../../../Component/MemberComponent/DiscountPaymentInput";
import PaymentInput from "../../../Component/MemberComponent/PaymentInput";
import CalenderInput from "../../../Component/MemberComponent/CalenderInput";
import IntegratedLockerSelect from "../../../Component/MemberComponent/IntegratedLockerSelect";

import LockerImagePath from '../../../image/ClickLocker.png';

const AddLockerView = forwardRef((props, ref) => {
  const { isClickedAddLocker, setIsClickedAddLocker } = props;

  const [totalPrice, setTotalPrice] = useState(0);
  const [startDate, setStartDate] = useState(moment().format('YYYY.MM.DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY.MM.DD'));
	const [selectedLocker, setSelectedLocker] = useState({ id: 0, name: '선택'});

  const CardPaymentRef = useRef();
  const CashPaymentRef = useRef();
  const DiscountPaymentRef = useRef();
  const StartCalenderRef = useRef();
  const EndCalenderRef = useRef();


	useImperativeHandle(ref, () => ({
		getValue: () => {
      let finalData = {
        lockerId : selectedLocker.id,
        lockerName: selectedLocker.name,
        lockerCardPrice: CardPaymentRef.current.getValue(),
        lockerCashPrice: CashPaymentRef.current.getValue(),
        lockerDiscountPrice: DiscountPaymentRef.current.getValue(),
        lockerStartDate: StartCalenderRef.current.getValue(),
        lockerEndDate: EndCalenderRef.current.getValue()
      }
      
      return finalData;
		}
	}));

  // 결제 금액 변경시 //
  function changeValue() {
    let card = CardPaymentRef.current.getValue();
    let cash = CashPaymentRef.current.getValue();
    let discount = DiscountPaymentRef.current.getValue();

    setTotalPrice(card + cash + discount);
    // setNotReceivedPrice((totalPrice - (card + cash + discount)) > 0 ? (totalPrice - (card + cash + discount)) : 0);
  }

  // 다른 달력 클릭시 //
  function CalenderClick(className) {
    if (className === 'StartCalender') EndCalenderRef.current.closeCalender();
    else StartCalenderRef.current.closeCalender();
  }

  return (
    <TopLevelWrapper isView={isClickedAddLocker}>
      <InfoWrapper>
        <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#232323'>락커 정보</Text>
        <LayerWrapper style={{ marginTop: '26px' }}>
          <ComponentWrapper style={{ gap: '8px', marginRight: '65px' }}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={12} colo='#595959' cursor='default'>락커 선택</Text>
            <IntegratedLockerSelect defaultVal={selectedLocker} setDefaultVal={setSelectedLocker} width={236}/>
          </ComponentWrapper>
          <ComponentWrapper style={{ marginRight: `27px` }}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={12} colo='#595959' cursor='default'>락커 가격</Text>
            <CalcPaymentInputWrapper>
              <CalcPaymentInput>{totalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</CalcPaymentInput>
              <CalcPaymentUnit>원</CalcPaymentUnit>
            </CalcPaymentInputWrapper>
          </ComponentWrapper>
          <ComponentWrapper style={{ marginRight: `27px` }}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={12} colo='#595959' cursor='default'>카드 결제 금액</Text>
            <PaymentInput ref={CardPaymentRef} changeFn={changeValue} width={77} placeholder={0} disabled={false} />
          </ComponentWrapper>
          <ComponentWrapper style={{ marginRight: `27px` }}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={12} colo='#595959' cursor='default'>현금 결제 금액</Text>
            <PaymentInput ref={CashPaymentRef} changeFn={changeValue} width={77} placeholder={0} disabled={false} />
          </ComponentWrapper>
          <ComponentWrapper style={{ marginRight: `27px` }}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={12} colo='#595959' cursor='default'>할인 금액</Text>
            <DiscountPaymentInput ref={DiscountPaymentRef} changeFn={changeValue} width={77} placeholder={0} disabled={false} />
          </ComponentWrapper>
        </LayerWrapper>
        <LayerWrapper style={{ marginTop: '16px' }}>
          <ComponentWrapper style={{ marginRight: `65px` }}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={12} colo='#595959' cursor='default'>락커 시작일</Text>
            <CalenderInput ref={StartCalenderRef} disabled={false} value={startDate} setValue={setStartDate} className='StartCalender' clickFn={CalenderClick} placeholder='시작일' width={236} />
          </ComponentWrapper>
          <ComponentWrapper style={{ marginRight: `42px` }}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={12} colo='#595959' cursor='default'>락커 종료일</Text>
            <CalenderInput ref={EndCalenderRef} disabled={false} value={endDate} setValue={setEndDate} className='EndCalender' clickFn={CalenderClick} placeholder='종료일' width={236} />
          </ComponentWrapper>
          <ComponentWrapper style={{ marginRight: `16px` }}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={12} colo='#595959' cursor='default'>락커 사용 일수</Text>
            <TotalMemberShipDateWrapper>
              <TotalDateBox>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#CBCBCB' cursor='default'>{moment(endDate).diff(moment(startDate), 'days') + 1}</Text>
              </TotalDateBox>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#777777' cursor='default'>일</Text>
            </TotalMemberShipDateWrapper>
          </ComponentWrapper>
        </LayerWrapper>
      </InfoWrapper>
      <InfoDeleteWrapper>
        <InfoDeleteButton onClick={() => setIsClickedAddLocker(false)}>
          <LockerImage src={LockerImagePath}/>
          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='pointer'>락커 정보 지우기</Text>
        </InfoDeleteButton>
      </InfoDeleteWrapper>
    </TopLevelWrapper>
  )
});

export default AddLockerView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  align-items: flex-start;

  margin: 16px 0px 36px 0px;

  width: 100%;
  height: ${props => props.isView ? `246px` : `0px`};

  overflow: hidden;

  border-radius: 10px;
  background-color: #FFFFFF;

  transition: all 0.3s ease-in-out;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin: 21px 0px 0px 28px;
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;
`;

const InfoDeleteWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: flex-end;

  width: 100%;
`;

const InfoDeleteButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 140px;
  height: 28px;

  gap: 6px;

  border-radius: 10px 0px 10px 0px;
  background-color: #FF8B48;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const CalcPaymentInputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
  margin-top: 8px;
`;

const CalcPaymentInput = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  padding: 0 8px 0 8px;
  margin: 0 0 0 0;

  width: 77px;
  height: 30px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background-color: #ECECEC;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #FFFFFF;
`;

const CalcPaymentUnit = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  margin: 0 0 0 0;

  color: #777777;
`;

const TotalMemberShipDateWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 8px;
  gap: 8px;
`;

const TotalDateBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 74px;
  height: 30px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;

  padding: 0 8px 0 8px;
`;

const LockerImage = styled.img`
  width: 18px;
  height: 18px;

  object-fit: cover;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  margin: 0 0 0 0;

  color: ${props => props.color};

  :hover {
    cursor: ${props => props.cursor};
  }
`;