import { React } from 'react';
import styled from 'styled-components';
import moment from 'moment';

export default function ZoneSubscribeInfo(props) {
  const { data } = props;

  // 무제한 맴버쉽 날짜 보이기 //
  function checkNoLimitMemberShip() {
    // console.log(data);
    if (data.endDate === '9999-12-31T23:59:59') return '무제한';
    else {
      if (data.division === 'Normal') {
        if (moment(data.approvalDate).isAfter(moment())) return moment(data.approvalDate).add(30, 'days').diff(moment(data.approvalDate), 'days') + '일 (예정)';
        else if (moment(data.approvalDate).add(30, 'days').isBefore(moment(moment().format('YYYY-MM-DD')))) return '만료';
        else return moment(moment().format('YYYY-MM-DD')).diff(moment(data.approvalDate).add(30, 'days'), 'days') * -1 + "일";
      }
      else {
        if (moment().isBefore(data.startDate)) return moment(data.endDate).diff(moment(data.startDate), 'days') + '일 (예정)';
        // else if (moment(data.endDate).diff(moment(), 'days') > 0) return moment(data.endDate).diff(moment(), 'days') + '일';
        else if (moment().isBefore(data.endDate)) {
          if (moment(data.endDate).diff(moment(), 'days') > 0) return moment(data.endDate).diff(moment(), 'days') + '일';
          else return '약 ' + parseInt(moment.duration(moment(data.endDate).diff(moment())).asHours()) + '시간';
        }
        else return '만료';
      }
    }
  }

  return (
    <TopLevelWrapper>
      <Table>
        <Body>
          <Tr style={{ width: '128px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '128px' }}>
                <Text>{data.approvalDate.replaceAll('-', '.').split('T')[0]}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '110px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '110px' }}>
                <Text>{data.customerName}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '80px'}}>
            <Td>
              <TextWrapper style={{ maxWidth: '80px' }}>
                <Text>{data.division === 'Normal' ? `정기` : `쿠폰`}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '476px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '476px' }}>
                <Text>{data.itemName}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '112px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '112px' }}>
                { data.division === 'Normal' && <Text>{data.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Text> }
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '114px'}}>
            <Td>
              <TextWrapper style={{ maxWidth: '114px' }}>
                {/* {
                  data.division === 'Normal' ?
                    <Text>{moment().diff(moment(data.approvalDate).add(28, 'days'), 'days') <= 0 ? moment().diff(moment(data.approvalDate).add(28, 'days'), 'days') * -1 + "일" : '만료'}</Text>
                    :
                    <Text>{checkNoLimitMemberShip()}</Text>
                } */}
                <Text>{checkNoLimitMemberShip()}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '60px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '60px' }}>
                <Text>{data.status === 1 ? '승인' : "승인 대기"}</Text>
              </TextWrapper>
            </Td>
          </Tr>
        </Body>
      </Table>
    </TopLevelWrapper>
  )
};

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // width: 1189px;
  width: 100%;
  height: 30px;

  background: #FFFFFF;

  margin-top: 10px;

  // box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  // border: 1px solid #FFFFFF;
  // border-radius: 30px;
  
  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    // border: 1px solid #FF8B48;
  }
`;

const Table = styled.table`
  display: flex;
  flex-direction: row;

  width: 100%;

  margin-left: 28px;
`;

const Body = styled.tbody`
  display: flex;
  flex-direction: row;
`;

const Tr = styled.tr`
  display: flex;
  flex-direction: row;
`;

const Td = styled.td`
  display: flex;
  flex-direction: row;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: flex-start;

  overflow: hidden;

  max-height: 20px;
  // border: 1px solid #232323;
`;

const Text = styled.span`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;

  margin: 0 0 0 0;
`;

const RegistrationButton = styled.span`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #FFFFFF;

  margin: 0 0 0 0;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    font-family: NotoSansKR-Bold;
  }
`;

const ExitButton = styled.span`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #FFFFFF;

  margin: 0 0 0 0;
  
  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;