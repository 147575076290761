import { React, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import moment from 'moment';

import HoldingCalenderInput from '../../../../../Component/MemberComponent/MemberDetail/HoldingCalenderInput';
import ReasonInput from '../../../../../Component/MemberComponent/MemberDetail/ReasonInput';
import FetchModule from '../../../../Share/Network/FetchModule';

export default function MemberShipHoldingEditModal(props) {
  const { data, memberShipData, exitClickFn, rendering } = props;

  const history = useHistory();

  const [isViewAdminModal, setIsViewAdminModal] = useState(false);
  const [adminModalText, setAdminModalText] = useState('홀딩 가능 일수');
  const [compriseDate, setComprise] = useState([]);
  const [startValue, setStartValue] = useState(moment('2000-01-01'));
  const [endValue, setEndValue] = useState(moment('2000-01-01'));
  const [reasonValue, setReasonValue] = useState('');

  useEffect(() => {
    // document.body.style.cssText = `position: fixed; top: -${window.scrollY}px`

    // return () => {
    //   const scrollY = document.body.style.top;
    //   document.body.style.cssText = `position: ""; top: "";`
    //   window.scrollTo(0, parseInt(scrollY || '0') * -1)
    // }
    document.body.style= `overflow: hidden`;
    return () => document.body.style = `overflow: auto`
  }, [])

  // 이전 홀딩 데이터 가져오기 //
  useEffect(() => {
    if (data === undefined) return;

    setStartValue(moment(data.startDate));
    setEndValue(moment(data.endDate));
    setReasonValue(data.reason);
  }, [data])

  function holdingDateSelect(compriseDateList) {
    setComprise(compriseDateList);
  }

  // 홀딩 수정 닫기 클릭시 //
  function exitClick() {
    exitClickFn();
  }

  // 홀딩 수정 저장하기 클릭시 //
  function saveClick() {
    var startDate = startValue.format('YYYY-MM-DDTHH:mm:ss');
    var endDate = endValue.format('YYYY-MM-DDTHH:mm:ss');

    if (endDate < startDate) {
      alert('홀딩 종료날짜의 설정이 올바르지 않습니다.');
      return;
    }
    // else if (data.availableHold < (moment(endDate).diff(moment(startDate), 'days'))) {
    //   setAdminModalText('홀딩 가능 일수');
    //   setIsViewAdminModal(true);
    //   return;
    // }
    // else if (data.holdCount <= 0) {
    //   setAdminModalText('홀딩 신청 가능 횟수');
    //   setIsViewAdminModal(true);
    //   return;
    // }

    var requestData = {
      id: data.holdingId,
      storeId: 0,
      usageId: data.usageId,
      startDate: startDate,
      endDate: endDate,
      reason: reasonValue
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postData('membership/modify/holding/admin', 'POST', requestData);

        if (responseData.status === 200) {
          alert("회원권 홀딩 내역을 저장하였습니다.");
          // exitClickFn(className);
          exitClickFn();
          rendering();
        }
        // else if (responseData.status === 201) {
        //   alert('동일한 신청내역이 존재합니다!\n정보를 수정 후 다시시도 하세요!');
        // }
        // else if (responseData.status === 202) {
        //   alert('홀딩 종료날짜의 설정이 올바르지 않습니다.');
        // }
        else if (responseData.status === 401) {
          alert('로그인 후 다시시도하세요');
          history.push('/');
        }
        else alert("데이터를 불러오는데 실패하였습니다. 잠시후 다시시도해 주세요.");
      }
    )();
  }

  // // 홀딩 강제진행 클릭시 //
  // function onClickAdminSave() {
  //   var startDate = startValue.format('YYYY-MM-DDTHH:mm:ss');
  //   var endDate = endValue.format('YYYY-MM-DDTHH:mm:ss');

  //   var requestData = {
  //     storeId: window.localStorage.getItem('StoreId'),
  //     usageId: data.usageId,
  //     startDate: startDate,
  //     endDate: endDate,
  //     reason: reasonValue
  //   }
    
  //   const fetchModule = new FetchModule();
  //   (
  //     async () => {
  //       const responseData = await fetchModule.postData('membership/create/holding/admin', 'POST', requestData);

  //       if (responseData.status === 200) {
  //         alert("회원권 홀딩 내역을 저장하였습니다.");
  //         exitClickFn();
  //       }
  //       else if (responseData.status === 201) {
  //         alert('동일한 신청내역이 존재합니다!\n정보를 수정 후 다시시도 하세요!');
  //       }
  //       else if (responseData.status === 202) {
  //         alert('홀딩 종료날짜의 설정이 올바르지 않습니다.');
  //       }
  //       else if (responseData.status === 401) {
  //         alert('로그인 후 다시시도하세요');
  //         history.push('/');
  //       }
  //       else alert("데이터를 불러오는데 실패하였습니다. 잠시후 다시시도해 주세요.");
  //     }
  //   )();
  // }

  return (
    <TopLevelWrapper>
          <ModalView>
            <TotalWrapper>
              <Title>회원권 홀딩 수정</Title>
              <ComponentWrapper>
                <CalenderWrapper>
                  <HoldingCalenderInput
                    startValue={startValue}
                    setStartValue={setStartValue}
                    endValue={endValue}
                    setEndValue={setEndValue}
                    holdingDateSelect={holdingDateSelect} />
                </CalenderWrapper>
              </ComponentWrapper>
              <BottomComponentWrapper>
                <TotalTextBoxWrapper>
                  <TextBoxWrapper topMargin={24}>
                    <Text>사용가능 홀딩일수</Text>
                    <InputBox leftMargin={16}>{memberShipData.availableHold}일</InputBox>
                  </TextBoxWrapper>
                  <ExplanationWrapper>
                    <Explanation rightMargin={3}>*회원권 별 홀딩 가능 일수는 </Explanation>
                    <Explanation bold={true}>회원권 설정</Explanation>
                    <Explanation>에 명시되어있습니다.</Explanation>
                  </ExplanationWrapper>
                  <TextBoxWrapper topMargin={13}>
                    <Text>홀딩 최종 적용일</Text>
                    <InputBox leftMargin={26}>{compriseDate.length}일</InputBox>
                  </TextBoxWrapper>
                </TotalTextBoxWrapper>
                <ReasonInput value={reasonValue} setValue={setReasonValue} />
                <LayerWrapper>
                  <HoldingCountWrapper>
                    <HoldingCountText>홀딩 가능 횟수</HoldingCountText>
                    <HodingInput>{memberShipData.holdCount <= 0 ? 0 : memberShipData.holdCount}</HodingInput>
                    <HoldingCountText>회</HoldingCountText>
                  </HoldingCountWrapper>
                  <ButtonWrapper>
                    <ExitButton onClick={exitClick}>취소</ExitButton>
                    <SaveButton onClick={saveClick}>저장하기</SaveButton>
                  </ButtonWrapper>
                </LayerWrapper>
              </BottomComponentWrapper>
            </TotalWrapper>
          </ModalView>
    </TopLevelWrapper>
  )
}

const startAnimation = keyframes`
	0% { opacity: 0; }
	50% { opacity: 0.5; }
	100% { opacity: 1; }
`;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  justify-content: center;
  align-items: center;

  z-index: 1000;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.2);
	
	animation-name: ${startAnimation};
	animation-duration: 0.5s;
	animation-timing-function: ease-out;
`;

const ModalView = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 1002px;
  // height: 700px;
  height: 760px;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(0, 0, 0, 0.1);
  background-color: #FFFFFF;
`;

const AdminModalView = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 371px;
  height: 248px;

  border-radius: 30px;
  background-color: #FFFFFF;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;
`;

const AdminTotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: 84.9%;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: 0 0 16px 0;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 32px;
`;

const CalenderWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  gap: 16px;

  width: 100%;
  height: 544px;
`;

const BottomComponentWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  border-top: 2px solid #ECECEC;
`;

const TotalTextBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;
`;

const TextBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  
  justify-content: center;
  align-items: center;

  margin-top: ${props => props.topMargin}px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
`;

const Text = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;

  margin: 0 0 0 0;
`;

const InputBox = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;

  width: 211px;
  height: 30px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;

  font-family: NotoSansKR-Regular;
  font-size: 14px;
 
  color: #000000;


  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: ${props => props.leftMargin}px;

  padding: 0 0 0 8px;
  
  transition: all 0.2s ease-in-out;
`;

const ExplanationWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // margin: 13px 0 13px 0;
  margin: 13px 0 0 0;
`;

const Explanation = styled.p`
  font-family: ${props => props.bold ? `NotoSansKR-Bold` : `NotoSansKR-Regular`};
  font-size: 12px;

  color: #FF8B48;

  margin-top: 0px;
  margin-right: ${props => props.rightMargin}px;
  margin-bottom: 0px;
  margin-left: 0px;
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const HoldingCountWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 24px;
`;

const HoldingCountText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;

  margin: 0 0 0 0;
  padding: 0 0 0 0;
`;

const HodingInput = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 76px;
  height: 32px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;

  margin: 0 8px 0 8px;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #000000;
  background-color: #FFFFFF;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;

  gap: 16px;

  margin: 31px 0 0 24px;
  // margin-left: 24px;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 72px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;
  
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #777777;
    color: #777777;
  }
`;

const SaveButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 15px;
  background-color: #5BD98A;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 10px;
`;

const UnHoverText = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  padding: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const AdminButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;
  
  width: 100%;

  margin-top: 53px;

  gap: 16px;
`;

const AdminButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 15px;
  background-color: #FF3131;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #DD0000;
  }
`;