import React, { useEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";

import EditSettingMessageInput from "./EditSettingMessageInput";

export default function AutoMessageEditModal(props) {
  const { data, onClickSave, onClickExit } = props;

  const [settingText, setSettingText] = useState('');
  const [settingValues, setSettingValues] = useState([]);
  const [lastCursorPosition, setLastCursorPosition] = useState(0);

  const MessageInputRef = useRef(null);

  // 스크롤 고정 //
  useEffect(() => {
    document.body.style= `overflow: hidden`;
    return () => document.body.style = `overflow: auto`;
  }, [])

  // 데이터 세팅 //
  useEffect(() => {
    if (data === undefined) {
      setSettingText('');
      setSettingValues([]);
    }
    else {
      // console.log(data);
      if (data.division === 'NewRegistration') setSettingValues(['{시설 이름}', '{회원 이름}', '{보유 회원권}', '{운동 기간}']);
      else if (data.division === 'ReRegistration') setSettingValues(['{시설 이름}', '{회원 이름}', '{보유 회원권}', '{운동 기간}']);
      else if (data.division === 'Attendance') setSettingValues(['{시설 이름}', '{회원 이름}', '{보유 회원권}', '{운동 기간}', '{코치 이름}', '{수업 이름}', '{예약 시간}']);
      else if (data.division === 'LastAttendance') setSettingValues(['{시설 이름}', '{회원 이름}', '{보유 회원권}', '{운동 기간}']);
      else if (data.division === 'OnReserve') setSettingValues(['{시설 이름}', '{회원 이름}', '{보유 회원권}', '{운동 기간}', '{코치 이름}', '{수업 이름}', '{예약 시간}']);
      else if (data.division === 'CancelReserve') setSettingValues(['{시설 이름}', '{회원 이름}', '{보유 회원권}', '{운동 기간}', '{코치 이름}', '{수업 이름}', '{예약 시간}']);
      else if (data.division === 'BeforeReserve') setSettingValues(['{시설 이름}', '{회원 이름}', '{보유 회원권}', '{운동 기간}', '{코치 이름}', '{수업 이름}', '{예약 시간}']);
      else if (data.division === 'Absence') setSettingValues(['{시설 이름}', '{회원 이름}', '{보유 회원권}', '{운동 기간}', '{코치 이름}', '{수업 이름}', '{예약 시간}']);
      else if (data.division === 'BeExpiredMembership') setSettingValues(['{시설 이름}', '{회원 이름}', '{보유 회원권}', '{운동 기간}']);
      else if (data.division === 'BeExpiredLocker') setSettingValues(['{시설 이름}', '{회원 이름}', '{락커 번호}']);
      else if (data.division === 'ExpiredMembership') setSettingValues(['{시설 이름}', '{회원 이름}', '{보유 회원권}', '{운동 기간}']);
      else if (data.division === 'HadBeenExpiredMembership') setSettingValues(['{시설 이름}', '{회원 이름}', '{보유 회원권}']);
      else if (data.division === 'HadBeenExpiredLocker') setSettingValues(['{시설 이름}', '{회원 이름}', '{락커 번호}']);
      else if (data.division === 'ApplyMembershipHolding') setSettingValues(['{시설 이름}', '{회원 이름}', '{보유 회원권}', '{운동 기간}', '{홀딩 시작일}', '{홀딩 종료일}']);
      else if (data.division === 'StartMembershipHolding') setSettingValues(['{시설 이름}', '{회원 이름}', '{보유 회원권}', '{운동 기간}', '{홀딩 시작일}', '{홀딩 종료일}']);

      let newData = data.text.replaceAll('{store_name}', '{시설 이름}')
        .replaceAll('{user_name}', '{회원 이름}')
        .replaceAll('{membership_name}', '{보유 회원권}')
        .replaceAll('{exercise_period}', '{운동 기간}')
        .replaceAll('{locker_name}', '{락커 번호}')
        .replaceAll('{coach_name}', '{코치 이름}')
        .replaceAll('{class_name}', '{수업 이름}')
        .replaceAll('{reserve_time}', '{예약 시간}')
        // .replaceAll('{class_time}', '{수업 시간}')
        .replaceAll('{holding_start_date}', '{홀딩 시작일}')
        .replaceAll('{holding_end_date}', '{홀딩 종료일}');

      setSettingText(newData);
      setLastCursorPosition(newData.length);
    }
  }, [data])

  // 타이틀 부가설명 정리 //
  function decideTitleExplanation() {
    if (data === undefined) return '회원 신규 등록시';
    else if (data.division === 'NewRegistration') return '회원 신규 등록 시';
    else if (data.division === 'ReRegistration') return '회원권 연장 및 재등록 시';
    else if (data.division === 'Attendance') return '출석시';
    else if (data.division === 'LastAttendance') return '마지막 출석 ' + data.applyDay + ' 일 후 ' + data.applyTime + ' 시에';
    else if (data.division === 'OnReserve') return '수업 예약시';
    else if (data.division === 'CancelReserve') return '예약 취소시';
    else if (data.division === 'BeforeReserve') return '예약된 수업 시작 ' + data.applyTime + ' 시간 전';
    else if (data.division === 'Absence') return '예약한 수업 결석 시';
    else if (data.division === 'BeExpiredMembership') return '만료 예정일 ' + data.applyDay + ' 일 전 ' + data.applyTime + ' 시에';
    else if (data.division === 'BeExpiredLocker') return '락커 만료 예정일 ' + data.applyDay + ' 일 전 ' + data.applyTime + ' 시에';
    else if (data.division === 'ExpiredMembership') return '회원권 만료 ' + data.applyTime + ' 시에';
    else if (data.division === 'HadBeenExpiredMembership') return '회원권 만료 ' + data.applyDay + ' 일 후 ' + data.applyTime + ' 시에';
    else if (data.division === 'HadBeenExpiredLocker') return '락커 만료 ' + data.applyDay + ' 일 후 ' + data.applyTime + ' 시에';
    else if (data.division === 'ApplyMembershipHolding') return '회원권 홀딩시';
    else if (data.division === 'StartMembershipHolding') return '홀딩 이후 활성화 ' + data.applyDay + ' 일 전 ' + data.applyTime + ' 시에';
  }
  
  // 변수 입력 거르기 //
  function onChangeSettingText(value) {
    setSettingText(value);
  }

  // 변수 클릭시 텍스트에 추가 //
  function onClickValue(value) {
    // setSettingText(settingText + value);

    setSettingText(settingText.slice(0, lastCursorPosition) + value + settingText.slice(lastCursorPosition, settingText.length));
    MessageInputRef.current.prevFocus(value.length);
  }

  // 저장 클릭시 //
  function onClickSaveButton() {
    let testText = settingText.replaceAll('{시설 이름}', '')
                              .replaceAll('{회원 이름}', '')
                              .replaceAll('{보유 회원권}', '')
                              .replaceAll('{운동 기간}', '')
                              .replaceAll('{락커 번호}', '')
                              .replaceAll('{코치 이름}', '')
                              .replaceAll('{수업 이름}', '')
                              .replaceAll('{예약 시간}', '')
                              .replaceAll('{홀딩 시작일}', '')
                              .replaceAll('{홀딩 종료일}', '');

    
    // 이모티콘 감지
    // const emojiPattern = /[\u203C-\u3299\u1F600-\u1F64F\u1F300-\u1F5FF\u1F680-\u1F6FF\u1F700-\u1F77F\u1F780-\u1F7FF\u1F800-\u1F8FF\u1F900-\u1F9FF\u1FA00-\u1FAFF\u2600-\u26FF\u2700-\u27BF]/g;
    const emojiPattern = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
    const emojis = testText.match(emojiPattern);

    // 이모티콘이 있는경우 //
    if (emojis) {
      alert('이모티콘은 저장할 수 없습니다.');
      return;
    }

    onClickSave(settingText.replaceAll('{시설 이름}', '{store_name}')
                            .replaceAll('{회원 이름}', '{user_name}')
                            .replaceAll('{보유 회원권}', '{membership_name}')
                            .replaceAll('{운동 기간}', '{exercise_period}')
                            .replaceAll('{락커 번호}', '{locker_name}')
                            .replaceAll('{코치 이름}', '{coach_name}')
                            .replaceAll('{수업 이름}', '{class_name}')
                            .replaceAll('{예약 시간}', '{reserve_time}')
                            .replaceAll('{홀딩 시작일}', '{holding_start_date}')
                            .replaceAll('{홀딩 종료일}', '{holding_end_date}'));
  }

  return (
    <TopLevelWrapper>
      <Modal>
        <TitleWrapper>
          <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#232323' hover='default'>자동메시지 수정하기</Text>
          <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#777777' hover='default'>{decideTitleExplanation()}</Text>
        </TitleWrapper>
        <InfoBoxWrapper>
          <InfoBoxInnerWrapper>
            <SelectedInfoTextWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='default'>자동으로 입력할 변수를 선택해 주세요.</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='default'>{'예) {시설 이름}: 링코 크로스핏'}</Text>
            </SelectedInfoTextWrapper>
            {/* <ValueBoxWrapper>
              <ValueBox>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='pointer'>{'{시설 이름}'}</Text>
              </ValueBox>
              <ValueBox>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='pointer'>{'{회원 이름}'}</Text>
              </ValueBox>
              <ValueBox>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='pointer'>{'{보유 회원권}'}</Text>
              </ValueBox>
              <ValueBox>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='pointer'>{'{운동 기간}'}</Text>
              </ValueBox>
            </ValueBoxWrapper>
            <ValueBoxWrapper>
              <ValueBox>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='pointer'>{'{락커 번호}'}</Text>
              </ValueBox>
              <ValueBox>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='pointer'>{'{코치 이름}'}</Text>
              </ValueBox>
              <ValueBox>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='pointer'>{'{수업 이름}'}</Text>
              </ValueBox>
              <ValueBox>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='pointer'>{'{예약 시간}'}</Text>
              </ValueBox>
            </ValueBoxWrapper>
            <ValueBoxWrapper>
              <ValueBox>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='pointer'>{'{수업 시간}'}</Text>
              </ValueBox>
              <ValueBox>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='pointer'>{'{홀딩 시작일}'}</Text>
              </ValueBox>
              <ValueBox>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='pointer'>{'{홀딩 종료일}'}</Text>
              </ValueBox>
            </ValueBoxWrapper> */}
            <ValueBoxWrapper>
              {
                settingValues.map((data, index) => (
                  <ValueBox key={'SettingMessageValue-' + index} onClick={() => onClickValue(data)}>
                    <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='pointer'>{data}</Text>
                  </ValueBox>
                ))
              }
            </ValueBoxWrapper>
          </InfoBoxInnerWrapper>
          <DivisionLine/>
          <InfoBoxInnerWrapper>
            <InfoBoxInnerRightWrapper>
              <TitleWrapper style={{ marginTop: '26px' }}>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='default'>{'왼쪽에서 선택한 변수가 { } 안에'}</Text>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='default'>{'받는 사람에게 알맞은 값으로 자동 입력됩니다.'}</Text>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='default'>{'중괄호(‘{}’)를 직접 입력하실 수는 없습니다.'}</Text>
              </TitleWrapper>
              <EditSettingMessageInput 
                ref={MessageInputRef}
                value={settingText}  
                setValue={onChangeSettingText}
                lastCursorPosition={lastCursorPosition}
                setLastCursorPosition={setLastCursorPosition}
                placeholder='왼쪽에서 선택한 변수가 { } 안에 받는 사람에게 알맞은 값으로 자동 입력되며, 중괄호(‘{}’)를 직접 입력하실 수는 없습니다.'/>
              <TitleWrapper style={{ marginTop: '10px' }}>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='default'>90byte 초과 시 LMS로 전환됩니다.</Text>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='default'>SMS 30원 / LMS 60원</Text>
              </TitleWrapper>
            </InfoBoxInnerRightWrapper>
          </InfoBoxInnerWrapper>
        </InfoBoxWrapper>
        <ButtonWrapper>
          <ExitButton onClick={onClickExit}>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#3E4148' hover='pointer'>취소</Text>
          </ExitButton>
          <SaveButton onClick={onClickSaveButton}>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' hover='pointer'>수정하기</Text>
          </SaveButton>
        </ButtonWrapper>
      </Modal>
    </TopLevelWrapper>
  )
}

const startAnimation = keyframes`
	0% { opacity: 0; }
	50% { opacity: 0.5; }
	100% { opacity: 1; }
`;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  justify-content: center;
  align-items: center;

  z-index: 1000;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.2);

	animation-name: ${startAnimation};
	animation-duration: 0.5s;
	animation-timing-function: ease-out;
`;

const Modal = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 20px;

  width: 925px;
  height: 580px;

  border-radius: 10px;
  background-color: #FFFFFF;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: 100%;
`;

const InfoBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: 10px;

  width: 100%;
  height: 470px;
`;

const InfoBoxInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 50%;
  height: 100%;
`;

const InfoBoxInnerRightWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  padding: 0px 10px;

  width: calc(100% - 20px);
  height: 100%;
`;

const SelectedInfoTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  padding: 20px 10px 10px 10px;

  width: calc(100% - 20px);
`;

const ValueBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  
  justify-content: flex-start;
  align-items: flex-start;

  padding: 0px 10px;
  margin-top: 15px;

  width: calc(100% - 20px);

  gap: 15px;
`;

const ValueBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  padding: 0px 10px;

  height: 28px;

  border-radius: 5px;
  background-color: #F9F9F9;

  :hover {
    cursor: pointer;
  }
`;

const DivisionLine = styled.div`
  width: 1px;
  height: 100%;

  background-color: #CBCBCB;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  margin-top: 16px;

  width: 100%;

  gap: 10px;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 62px;
  height: 40px;

  border-radius: 10px;
  background-color: #F5F6F8;

  :hover {
    cursor: pointer;
  }
`;

const SaveButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 10px;
  background-color: #5BD98A;

  :hover {
    cursor: pointer;
  }
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.hover};
  }
`;