import { React, useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';

import WaittingTopView from './WaittingTopView.js';
import RejectTopView from './RejectTopView.js';
import WaittingColumnView from './WaittingColumnView.js';
import WaittingMemberInfo from './WaittingMemberInfo.js';
import InfoPagination from '../../../Component/MemberComponent/InfoPagination.js';

import FetchModule from '../../Share/Network/FetchModule.js';
import SessionStorage from '../../Share/Network/SessionStorage.js';
import OnSitePaymentModal from './OnSitePaymentModal.js';
import RegistrationCompleteModal from './RegistrationCompleteModal.js';
import NewWaittingMemberInfo from './NewWaittingMemberInfo.js';


const WaittingView = () => {
  const history = useHistory();

  const [data, setData] = useState([]);
  const [isViewOnSitePaymentModal, setIsViewOnSitePaymentModal] = useState(false);
  const [isClickedGoMemberDetailModal, setIsClickedGoMemberDetailModal] = useState(false);
  const [memberData, setMemberData] = useState(undefined);
  const [merchantUid, setMerchantUid] = useState(undefined);
  const [index, setIndex] = useState(-1);
  const [waittingCount, setWaittingCount] = useState(0);
  const [ignoreCount, setIgnoreCount] = useState(0);
  const [isWaitting, setIsWaitting] = useState(true);
  const [approvalNum, setApprovalNum] = useState(0);
  const [rerendering, setRerendering] = useState(false);
  const [isUsePaymentSystem, setIsUsePaymentSystem] = useState(false);

  const WaitRef = useRef();
  const RejectRef = useRef();

  // useEffect(() => {
  //   if (isWaitting) {
  //     RejectRef.current.setZindex(-3);
  //     WaitRef.current.setZindex(1);
  //   }
  //   else {
  //     RejectRef.current.setZindex(1);
  //     WaitRef.current.setZindex(-3);
  //   }
  // }, [isWaitting])

  useEffect(() => {
    // const fetchModule = new FetchModule();
    // (
    //   async () => {
    //     const responseData = await fetchModule.postData('member/waitting', 'POST');
    //     console.log(responseData.data);
    //     if (responseData.status === 200) {
    //       var newData = responseData.data.slice();

    //       //  // desc 진행 //
    //       //  newData.sort(function (a, b) {
    //       //   return a.createdDate > b.createdDate ? -1 : a.createdDate < b.createdDate ? 1 : 0;
    //       // })

    //       setData(newData);
    //     }
    //     else if (responseData.status === 401) {
    //       alert('로그인 후 다시시도하세요');
    //       history.push('/');
    //     }
    //   }
    // )();

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getDataVersion2('member/waiting', 'GET');
        // console.log(responseData);
        if (responseData.status === 200) {
          let newData = responseData.data.waitMembers.slice();
          setIsUsePaymentSystem(responseData.data.isUsePaymentSystem);
          setData(newData);
        }
        else if (responseData.status === 401) {
          alert('로그인 후 다시시도하세요');
          history.push('/');
        }
      }
    )();
  }, [rerendering, history])

  useEffect(() => {
    var waittingCnt = 0;
    var ignoreCnt = 0;

    for (var i = 0; i < data.length; i++) {
      if (data[i].approval === 0) waittingCnt += 1;
      else if (data[i].approval === 2) ignoreCnt += 1;
    }

    setWaittingCount(waittingCnt);
    setIgnoreCount(ignoreCnt);
  }, [data])

  function settingWaittingView(className) {
    if (className === 'Reject') {
      setIsWaitting(false);
      setApprovalNum(2);
    }
    else {
      setIsWaitting(true);
      setApprovalNum(0);
    }
  }

  // 이미 결제가 끝난 경우 //
  function alreadyCompletePayment(userId, index) {
    var gender = '';
    var birth = '';
    let genderNum = data[index].birthDay[7];
    // console.log(data[index].birthDay[0]);
    // console.log(data[index].birthDay[1]);
    // console.log(data[index].birthDay[2]);
    // console.log(data[index].birthDay[3]);
    // console.log(data[index].birthDay[4]);
    // console.log(data[index].birthDay[5]);
    // console.log(data[index].birthDay[6]);
    // console.log(data[index].birthDay[7]);
    if (genderNum === '1') {
      gender = '남성';
      birth = "19" + data[index].birthDay[0] + data[index].birthDay[1] + data[index].birthDay[2] + data[index].birthDay[3] + data[index].birthDay[4] + data[index].birthDay[5];
    }
    else if (genderNum === '2') {
      gender = '여성';
      birth = "19" + data[index].birthDay[0] + data[index].birthDay[1] + data[index].birthDay[2] + data[index].birthDay[3] + data[index].birthDay[4] + data[index].birthDay[5];
    }
    else if (genderNum === '3') {
      gender = '남성';
      birth = "20" + data[index].birthDay[0] + data[index].birthDay[1] + data[index].birthDay[2] + data[index].birthDay[3] + data[index].birthDay[4] + data[index].birthDay[5];
    }
    else if (genderNum === '4') {
      gender = '여성';
      birth = "20" + data[index].birthDay[0] + data[index].birthDay[1] + data[index].birthDay[2] + data[index].birthDay[3] + data[index].birthDay[4] + data[index].birthDay[5];
    }

    var name = data[index].userName;
    var phone = data[index].phone;
    var id = userId;
    var profile = data[index].profile;
    
    var info = {
      // storeId: SessionStorage.getStoreId(),
      storeId: window.localStorage.getItem('StoreId'),
      birthDay: birth,
      linkCoachId: id,
      name: name,
      phone: phone.replaceAll('-', ''),
      gender: gender === '남성' ? 'male' : 'female',
      profile: profile,
      memberDetail: '',
      approval: moment().format('YYYY-MM-DDTHH:mm:ss')
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postData('member/registration/' + data[index].merchantUid, 'POST', info);

        if (responseData.status === 200) {
          // console.log(responseData.data
          setMemberData(responseData.data[0]);
          (
            async () => {
              const coachResponseData = await fetchModule.postDataOfCoach('registration/modify/' + data[index].id + '/' + data[index].merchantUid + '/' + userId + '/' + 1, 'POST');

              if (coachResponseData.status === 200) {
                // alert("등록이 완료되었습니다.");
                setIsClickedGoMemberDetailModal(true);
                setRerendering(!rerendering);
              }
              else if (coachResponseData.status === 401) {
                alert('로그인 후 다시시도하세요');
                history.push('/');
              }
            }
          )();
        }
        else if (responseData.status === 401) {
          alert('로그인 후 사용해주세요');
          history.push('/');
        }
        else {
          alert("회원등록에 실패하였습니다. 잠시후 다시시도해 주세요.");
          return;
        }
      }
    )();
  }

  // 결제가 진행되지 않은 경우 //
  function notCompletePaymentYet(userId, index) {
    setIsViewOnSitePaymentModal(true);
    setMerchantUid(data[index].merchantUid);
    setIndex(index);
  }

  // // 등록하기 버튼 클릭 //
  // function onClickedAgree(userId, index) {
  //   const fetchModule = new FetchModule();
  //   (
  //     async () => {
  //       const responseData = await fetchModule.postData('sales/success/' + data[index].merchantUid, 'POST');
  //       // console.log(data[index].merchantUid);
  //       // console.log(responseData);
  //       if (responseData.status === 200) {
  //         alreadyCompletePayment(userId, index);
  //       }
  //       else if (responseData.status === 203) {
  //         notCompletePaymentYet(userId, index);
  //       }
  //       else if (responseData.status === 401) {
  //         alert('로그인 후 사용해주세요');
  //         history.push('/');
  //       }
  //     }
  //   )();
  // }

  // 등록하기 버튼 클릭시 //
  function onClickAgree(userId, index) {
    setIsViewOnSitePaymentModal(true);
    setMerchantUid(data[index].merchantUid);
    setIndex(index);
  }

  // 거절하기 버튼 클릭시 //
  function onClickedIgnore(userId, index, isIgnore) {
    var value = 2;

    if (isIgnore) value = 4;
    else value = 2;

    if (value === 2) {
      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postData('sales/success/' + data[index].merchantUid, 'POST');
          // 이미 결제가 된경우
          if (responseData.status === 200) {
            const refundData = await fetchModule.postData('sales/refund/' + data[index].merchantUid, 'POST');

            if (refundData.status === 200) {
              const ignoreData = await fetchModule.postDataOfCoach('registration/modify/' + data[index].id + '/' + data[index].merchantUid + '/' + userId + '/' + value, 'POST');
              // console.log(ignoreData);
              if (ignoreData.status === 200) {
                alert("등록이 거절되었습니다!");
                window.location.reload(true);
                // setRerendering(!rerendering);
              }
            }
          }
          // 아직 결제전
          else if (responseData.status === 203) {
            const ignoreData = await fetchModule.postDataOfCoach('registration/modify/' + data[index].id + '/' + data[index].merchantUid + '/' + userId + '/' + value, 'POST');

            if (ignoreData.status === 200) {
              alert("등록이 거절되었습니다!");
              window.location.reload(true);
              // setRerendering(!rerendering);
            }
          }
          else if (responseData.status === 401) {
            alert('로그인 후 사용해주세요');
            history.push('/');
          }
        }
      )();
    }
    else {
      const fetchModule = new FetchModule();
      (
        async () => {
          const ignoreData = await fetchModule.postDataOfCoach('registration/modify/' + data[index].id + '/' + data[index].merchantUid + '/' + userId + '/' + value, 'POST');

          if (ignoreData.status === 200) {
            alert("거절 내용이 삭제되었습니다!");
            setRerendering(!rerendering);
          }
        }
      )();
    }
  }

  // 결제 및 회원등록 마무리 후 진행되는 메서드 //
  function onCompletePayment(memberData) {

    setIsViewOnSitePaymentModal(false);
    // console.log(memberData);

    if (memberData !== undefined) {
      setMemberData(memberData);
      setIsClickedGoMemberDetailModal(true); 
    }
  }

  // 회원 정보 자세히 보기 페이지로 이동 버튼 클릭시 //
  function onClickedGoMemberDetail() {
    history.push('/member/valid/detail/' + memberData.id);
  }

  // 등록 완료 모달 닫기 버튼 클릭시 //
  function onClickModalExit() {
    setIsClickedGoMemberDetailModal(false);
    window.location.reload(true);
  }

  return (
    <TopLevelWrapper>
      { isViewOnSitePaymentModal && <OnSitePaymentModal isUsePaymentSystem={isUsePaymentSystem} userData={data[index]} merchantUid={merchantUid} setMerchantUid={setMerchantUid} rerendering={rerendering} setRerendering={setRerendering} cancel={onCompletePayment} closeFn={() => setIsViewOnSitePaymentModal(false)}/>}
      { isClickedGoMemberDetailModal && <RegistrationCompleteModal goFn={onClickedGoMemberDetail} closeFn={onClickModalExit}/> }
      <ContentWrapper>
        <WaittingTopView ref={WaitRef} isWaitting={isWaitting} waittingCnt={waittingCount} ignoreCnt={ignoreCount} settingWaittingView={settingWaittingView} />
        {/* <RejectTopView ref={RejectRef} waittingCnt={waittingCount} ignoreCnt={ignoreCount} settingWaittingView={settingWaittingView} /> */}
        {/* <WaittingColumnView /> */}
        <InfoBox>
          {/* {
            data.map((info, index) => (
              info.approval === approvalNum &&
              <WaittingMemberInfo
                key={index}
                index={index}
                data={info}
                isIgnore={approvalNum === 2}
                onClickedAgree={onClickedAgree}
                onClickedIgnore={onClickedIgnore} />
            ))
          } */}
          {
            data.map((info, index) => (
              info.approval === approvalNum &&
              <NewWaittingMemberInfo
                key={index}
                index={index}
                data={info}
                isIgnore={approvalNum === 2}
                // onClickedAgree={onClickedAgree}
                isUsePaymentSystem={isUsePaymentSystem}
                onClickedAgree={onClickAgree}
                onClickedIgnore={onClickedIgnore} />
            ))
          }
        </InfoBox>
      </ContentWrapper>
      {/* <PaginationWrapper>
        <InfoPagination />
      </PaginationWrapper> */}
    </TopLevelWrapper>
  )
}

export default WaittingView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  // margin-left: 34px;
  margin-left: 42px;
  
  transition: all 0.3s ease-in-out;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;

  width: 997px;
  min-height: 762px;

  // margin-top: 20px;
  margin-left: 26px;
  margin-bottom: 44px;
`;

const EmptyLogoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 135px;
  margin-bottom: 135px;
`;

const EmptyLogoView = styled.img`
  width: 180px;
  height: 180px;

  margin-bottom: 20px;
`;

const EmptyText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 16px;

  color: #DFDFDF;

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const PaginationWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;

  margin-top: 44px;
`;