import { React, useRef, useImperativeHandle, forwardRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import SelectBox from '../../../../Component/SettingComponent/ModalCategorySelectBox';
// import CoachSelectBox from '../../../../Component/SettingComponent/CoachSelectBox1';
import CoachSelectBox from '../../../../Component/SettingComponent/CoachSelectBox';
import ClassMaxCountInput from '../../../../Component/SettingComponent/ClassMaxCountInput';
import ColorChoice from '../../../../Component/SettingComponent/ColorChoice';
import ClassTypeInput from '../../../../Component/SettingComponent/ClassTypeInput';
import MemberShipNameInput from '../../../../Component/SettingComponent/MemberShipNameInput';
import SmallCountInput from '../../../../Component/SettingComponent/SmallCountInput';
import ClassSizeInput from '../../../../Component/SettingComponent/ClassSizeInput';

import FetchModule from '../../../Share/Network/FetchModule';

const ClassAddModal = forwardRef((props, ref) => {
  const { data, index, rendering, exitClickFn } = props;

  const history = useHistory();

  const TopLevelRef = useRef();
  const SelectCategoryRef = useRef();
  const SelectCoachRef = useRef();
  const NameRef = useRef();
  const TimeRef = useRef();
  const MaxBookedPeopleRef = useRef();
  const TypeRef = useRef();
  const SizeRef = useRef();
  const ColorRef = useRef();
  const DeductionRef = useRef();

  useImperativeHandle(ref, () => ({
		setOpacity: (val) => {
      TopLevelRef.current.style.opacity = val;
    },
		setZindex: (val) => {
      TopLevelRef.current.style.zIndex = val;
    }
	}));

  useEffect(() => {
    if (index === -1) return;

    SelectCategoryRef.current.setValue(data[index].categoryId);
    SelectCoachRef.current.setValue(data[index].teacherIds);
    NameRef.current.setValue(data[index].name);
    TimeRef.current.setValue(data[index].time);
    MaxBookedPeopleRef.current.setValue(data[index].maxBookedPeople);
    TypeRef.current.setValue(data[index].type);
    SizeRef.current.setValue(data[index].size);
    ColorRef.current.setValue(data[index].color);
    DeductionRef.current.setValue(data[index].deduction);
  }, [data, index])

  function exitClick() {
    exitClickFn('Class');
  }

  function saveClick() {
    // var storeId = window.sessionStorage.getItem('StoreId');
    var storeId = window.localStorage.getItem('StoreId');
    var categoryId = SelectCategoryRef.current.getValue().id;
    var teacherData = SelectCoachRef.current.getValue();
    var name = NameRef.current.getValue();
    var time = TimeRef.current.getValue();
    var maxBookedPeople = MaxBookedPeopleRef.current.getValue();
    var type = TypeRef.current.getValue();
    var size = SizeRef.current.getValue();
    var color = ColorRef.current.getValue();
    var deduction = DeductionRef.current.getValue();

    var teacherIdList = [];

    teacherData.map((newData) => (
      teacherIdList.push(newData.id)
    ))

    var teacherIds = '';

    for (var i = 0; i < teacherIdList.length; i++) {
      if (i === 0) teacherIds += teacherIdList[i];
      else teacherIds += ',' + teacherIdList[i];
    }

    // 이름이 공백인 경우
    if (name === '') {
      alert("이름을 입력해주세요.");
      return;
    }
    // 수업정원이 0명인 경우
    else if (maxBookedPeople === 0) {
      alert('수업 정원을 올바르게 입력해주세요');
      return;
    }
    // 수업 코치가 설정되지 않은 경우
    else if (teacherIdList.length === 0) {
      alert('수업 코치를 설정해주세요');
      return;
    }
    // 수업 카테고리가 설정되지 않은 경우
    else if (categoryId === undefined) {
      alert('수업 카테고리를 설정해주세요');
      return;
    }
    
    var newData = {
      id: index === -1 ? '' : data[index].id,
      storeId: storeId,
      categoryId: categoryId,
      teacherIds: teacherIds,
      name: name,
      time: time,
      maxBookedPeople: maxBookedPeople,
      type: type,
      size: size,
      color: color,
      deduction: deduction
    };
    
    (
      async () => {
        const fetchModule = new FetchModule();
        var data;
        // console.log(index )
        if (index === -1) data = await fetchModule.postData('lesson/create', 'POST', newData);
        else data = await fetchModule.patchData('lesson/modify', 'PATCH', newData);
        
        if (data.status === 200) {
          alert('저장하였습니다');
          rendering();
          exitClickFn('Class');
        }
        else if (data.status === 401) history.push('/');
        else {}
      }
    )();
  }

  function noneFunction() {

  }

  return (
    <TopLevelWrapper ref={TopLevelRef}>
      <Modal>
        <InfoWrapper>
          <Title>수업 추가</Title>
          <TotalWrapper>
            <SectionDivisionWrapper>
              <SectionTitle>기본 설정</SectionTitle>
              <ModelWrapper>
                <ComponentWrapper>
                  <ComponentTitle>카테고리 선택</ComponentTitle>
                  <SelectBox ref={SelectCategoryRef} isModal={true} changeVal={noneFunction}/>
                </ComponentWrapper>
                <ComponentWrapper>
                  <ComponentTitle>수업 명</ComponentTitle>
                  <MemberShipNameInput ref={NameRef} placeholder='수업 명을 입력해주세요' />
                </ComponentWrapper>
                <ComponentWrapper>
                  <ComponentTitle>수업 유형</ComponentTitle>
                  <ClassTypeInput ref={TypeRef} />
                </ComponentWrapper>
                <ComponentWrapper>
                  <ComponentTitle>수업 인원 규모</ComponentTitle>
                  <ClassSizeInput ref={SizeRef} />
                </ComponentWrapper>
              </ModelWrapper>
            </SectionDivisionWrapper>
            <SectionDivisionWrapper>
              <SectionTitle>세부 설정</SectionTitle>
              <ModelWrapper>
                <ComponentWrapper>
                  <ComponentTitle>코치 선택</ComponentTitle>
                  <CoachSelectBox ref={SelectCoachRef}/>
                </ComponentWrapper>
                <ComponentWrapper>
                  <ComponentTitle>횟수 차감 설정</ComponentTitle>
                  <ClassMaxCountInput ref={DeductionRef} />
                </ComponentWrapper>
              </ModelWrapper>
              <ComponentRowWrapper>
                <ComponentWrapper>
                  <ComponentTitle>수업 시간</ComponentTitle>
                  <SmallCountInput ref={TimeRef} unit='분' />
                </ComponentWrapper>
                <ComponentWrapper>
                  <ComponentTitle>수업 정원</ComponentTitle>
                  <SmallCountInput ref={MaxBookedPeopleRef} unit='명' />
                </ComponentWrapper>
              </ComponentRowWrapper>
              <ComponentWrapper >
                <ComponentTitle>수업 태그 색상</ComponentTitle>
                <ColorChoice ref={ColorRef} />
              </ComponentWrapper>
            </SectionDivisionWrapper>
          </TotalWrapper>
        </InfoWrapper>
        <ButtonWrapper>
          <ExitButton onClick={exitClick}>취소</ExitButton>
          <DeleteButton onClick={saveClick}>저장하기</DeleteButton>
        </ButtonWrapper>
      </Modal>
    </TopLevelWrapper>
  )
})

export default ClassAddModal;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  // opacity: 0;
  z-index: 1000;

  // width: 100vw;
  // height: 100vh;

  background-color: rgba(0, 0, 0, 0.2);
  
  transition: all 0.3s ease-in-out;
`;

const Modal = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 772px;
  width: 772px;
  height: 547px;

  // padding: 0 0 0 28px;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(0, 0, 0, 0.1);
  background-color: #FFFFFF;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 24px;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: 0 0 0 0;
`;

const SectionDivisionWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  margin-top: 24px;
`;

const SectionTitle = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;
`;

const ModelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  border-top: 1px solid #ECECEC;

  margin-top: 24px;
`;

const ComponentRowWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 24px;
`;

const ComponentTitle = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;

  margin: 0 0 0 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;

  gap: 16px;

  margin: 40px 0 0 526px;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 72px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;
  
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #777777;
    color: #777777;
  }
`;

const DeleteButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 15px;
  background-color: #5BD98A;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;