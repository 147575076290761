import { React, useRef } from 'react';
import styled from 'styled-components';

function TimeInput(props) {
	const { value, setValue, unit, division } = props;

	const InputRef = useRef();

	function handleClick(e) {
	}

	function handleChange(e) {
    var regex = /[^0-9]/g;				// 숫자가 아닌 문자열을 선택하는 정규식
    var result = e.target.value.replace(regex, "");
    
    if (result.length > 2) return;

    if (result === '') setValue(division, 0);
		else setValue(division, result);
	}

	function handleFocus(e) {
	}

	function handleBlur(e) {
		if (value === "") {
			// setDefaultPlaceholder(placeholder);
			// inputRef.current.style.borderColor = '#FF3131';
		} 
		else {
			// inputRef.current.style.borderColor = '#E8E8E8';
		}
	}

	return (
    <TopLevelWrapper>
      <Input
        ref={InputRef}
        type='text'
        placeholder=''
        value={parseInt(value)}
        onClick={handleClick}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur} />
      <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>{unit}</Text>
    </TopLevelWrapper>
  )
};

export default TimeInput;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: calc(47.66% - 34px);
  height: 34px;

  padding-left: 16px;
  padding-right: 16px;

  border: 1px solid #E0E0E0;
  border-radius: 15px;

  background-color: #FFFFFF;
`;

const Input = styled.input` 
	// font-family: NotoSansKR-Regular;
	font-family: NotoSansKR-Bold;
	font-size: 14px;
	line-height: 20px;
	
  display: flex;
  flex-direction: row;

  width: 100%;

  border: 0px;
  background-color: #FFFFFF;

  color: #595959;

	outline: none;

	::placeholder {
    font-family: NotoSansKR-Regular;
    font-size: 14px;

		color: #CBCBCB;
	}

  &:disabled {
    background-color: #ECECEC;
  }

  ::-webkit-contacts-auto-fill-button, ::-webkit-credentials-auto-fill-button {
    visibility: hidden;
    pointer-events: none;
    position: absolute;
    right: 0;
  }
  
  transition: all 0.3s ease-in-out;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;
