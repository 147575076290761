import React, { useEffect } from 'react';
import styled from 'styled-components';

export default function DeleteWarningModal(props) {
  const { onClickExit, onClickDelete } = props;

  useEffect(() => {
    document.body.style= `overflow: hidden`;
    return () => document.body.style = `overflow: auto`
  }, [])

  return (
    <BackgroundWrapper>
      <Modal>
        <InnerWrapper>
          <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#232323' cursor='default'>회원 정보 삭제</Text>
          <Text style={{ marginTop: `8px` }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' cursor='default'>회원 정보를 삭제하시겠습니까?</Text>
          <Text style={{ marginTop: `6px` }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' cursor='default'>삭제를 진행하면 되돌릴 수 없습니다.</Text>
          <ButtonWrapper>
            <ExitButton onClick={onClickExit}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#CBCBCB' cursor='pointer'>취소</Text>
            </ExitButton>
            <DeleteButton onClick={onClickDelete}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FFFFFF' cursor='pointer'>삭제하기</Text>
            </DeleteButton>
          </ButtonWrapper>
        </InnerWrapper>
      </Modal>
    </BackgroundWrapper>
  )
}

const BackgroundWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 500;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.2);
`;

const Modal = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 410px;
  height: 217px;

  border-radius: 30px;
  background-color: #FFFFFF;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 86.34%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: flex-end;

  margin-top: 40px;

  width: 100%;

  gap: 14px;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 72px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;

  :hover {
    cursor: pointer;
  }
`;

const DeleteButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 15px;
  background-color: #FF3131;

  :hover {
    cursor: pointer;
  }
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }
`;
