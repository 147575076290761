import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import lodash from 'lodash';

import MovementSearchInput from "../../Component/NoteComponent/MovementSearchInput";
import RecordMovementBox from "./RecordMovementBox";
import VideoViewModal from "./VideoViewModal";
import MovementOptionModal from "./MovementOptionModal";
import MyMovementTitleInput from "../Component/MyMovementTitleInput";

import MovementPlusImage from '../../image/MovementAdd.png';
import ProgramVideoIcon from '../../image/ProgramVideoIcon.png';
import NoneProgramVideoIcon from '../../image/GrayProgramVideoIcon.png';
import ExitButtonImage from '../../image/ExitButton.png';
import WhiteCheck from '../../image/WhiteCheck.png';
import YoutubeURLInput from "../Component/YoutubeURLInput";
import FetchModule from "../../Model/Share/Network/FetchModule";

export default function AddMovementBox(props) {
  const {
    sectionData,
    movements,
    setMovements,
    recentlySearchHistory,
    setRecentlySearchHistory,
    refreshTotalData
  } = props;

  const [isClickedAddMovement, setIsClickedAddMovement] = useState(false);
  const [isClickedAddMyMovement, setIsClickedAddMyMovement] = useState(false);

  const [searchValue, setSearchValue] = useState({ movementName: '' });
  const [recentlySearchValue, setRecentlySearchValue] = useState({ movementName: '' })
  const [divideData, setDivideData] = useState([]);
  const [myMovementName, setMyMovementName] = useState('');
  const [prevVideoLinkId, setPrevVideoLinkId] = useState('');
  const [videoLink, setVideoLink] = useState('');
  const [isClickedMyMovement, setIsClickedMyMovement] = useState(false);
  const [isClickedVideoView, setIsClickedVideoView] = useState(false);
  const [isClickedMovementOptions, setIsClickedMovementOptions] = useState(false);
  const [movementOptionData, setMovementOptionData] = useState(undefined);

  // MyMovement Option //
  const [isUseWeight, setIsUseWeight] = useState(false);
  const [isUseCount, setIsUseCount] = useState(false);
  const [isUseTime, setIsUseTime] = useState(false);
  const [isUseTempo, setIsUseTempo] = useState(false);
  const [isUseDistance, setIsUseDistance] = useState(false);
  const [isUseHeight, setIsUseHeight] = useState(false);
  const [isUseCalories, setIsUseCalories] = useState(false);
  // MyMovement Option //

  // const RecentlySearchRef = useRef();
  const RecentlyMovementRef = useRef([]);
  const SearchMovementRef = useRef([]);
  
  // 동작 검색 //
  useEffect(() => {
    if (searchValue.movementName.length < 2) return;

    let newDivideData = [];
    let newMovements = movements.slice();

    for (let i = 0; i < movements.length; i++) {
      let dataName = '';
      let valueName = '';

      if (searchValue.movementName.includes('-')) {
        dataName = newMovements[i].movementName.toLowerCase().replaceAll(' ', '');
        valueName = searchValue.movementName.toLowerCase().replaceAll(' ', '');
      }
      else {
        dataName = newMovements[i].movementName.toLowerCase().replaceAll(' ', '').replaceAll('-', '');
        valueName = searchValue.movementName.toLowerCase().replaceAll(' ', '').replaceAll('-', '');
      }

      if (dataName.includes(valueName)) {
        // 검색된 단어와 기존 단어의 유사도 계산 //
        newMovements[i].similarity = (valueName.length / dataName.length) * 100;
        newDivideData.push(newMovements[i]);
      }
      else if (newMovements[i].keyword.includes(valueName)) {
        // 검색된 단어와 기존 단어의 유사도 계산 //
        newMovements[i].similarity = (valueName.length / newMovements[i].keyword.length) * 100;
        newDivideData.push(newMovements[i]);
      }
    }

    newDivideData.sort((a, b) => b.similarity - a.similarity);

    setMyMovementName(searchValue.movementName);
    setDivideData(newDivideData);
  }, [searchValue, movements])

  // 나만의 동작 영상 링크 작성시 //
  useEffect(() => {
    let newVideoLinkId = '';

    if (videoLink.includes('https://youtu.be/')) {
      newVideoLinkId = videoLink.replace('https://youtu.be/', '');
    }
    else if (videoLink.includes('https://www.youtube.com/watch?v=')) {
      newVideoLinkId = videoLink.replace('https://www.youtube.com/watch?v=', '');
    }

    setPrevVideoLinkId(newVideoLinkId);
  }, [videoLink])

  // 검색 색상 변하기 //
  function calcColorText(movementName, value, index) {
    // '-'가 포함되어 있을 경우 //
    if (value.includes('-')) {
      let lowerMovementName = movementName.toLowerCase().replaceAll(' ', '');
      let lowerValue = value.toLowerCase().replaceAll(' ', '');
      let startIndex = lowerMovementName.indexOf(lowerValue);
      let frontCount = 0;
      let middleCount = 0;

      for (let i = 1; i < startIndex; i++) {
        if (movementName[i] === ' ') frontCount += 1;
      }

      for (let i = startIndex; i < (startIndex + frontCount + lowerValue.length); i++) {
        if (movementName[i] === ' ') middleCount += 1;
      }

      if (startIndex === -1) {
        if (index === 0) return movementName;
        else return '';
      }
      else {
        if (index === 0) return movementName.slice(0, (startIndex + frontCount));
        else if (index === 1) return movementName.slice((startIndex + frontCount), (startIndex + frontCount + lowerValue.length + middleCount));
        else return movementName.slice((startIndex + frontCount + lowerValue.length + middleCount), movementName.length);
      }
    }
    else {
      let lowerMovementName = movementName.toLowerCase().replaceAll(' ', '').replaceAll('-', '');
      let lowerValue = value.toLowerCase().replaceAll(' ', '').replaceAll('-', '');
      let startIndex = lowerMovementName.indexOf(lowerValue);
      let frontCount = 0;
      let middleCount = 0;

      for (let i = 1; i < startIndex; i++) {
        if (movementName[i] === ' ' || movementName[i] === '-') frontCount += 1;
      }

      for (let i = startIndex; i < (startIndex + frontCount + lowerValue.length); i++) {
        if (movementName[i] === ' ' || movementName[i] === '-') middleCount += 1;
      }

      if (startIndex === -1) {
        if (index === 0) return movementName;
        else return '';
      }
      else {
        if (index === 0) return movementName.slice(0, (startIndex + frontCount));
        else if (index === 1) return movementName.slice((startIndex + frontCount), (startIndex + frontCount + lowerValue.length + middleCount));
        else return movementName.slice((startIndex + frontCount + lowerValue.length + middleCount), movementName.length);
      }
    }
  }

  // 운동 영상 있는지 여부 확인후 아이콘 표출 //
  function decideProgramVideoIcon(data) {
    let newMovements = movements.slice();
    let isExist = false;

    for (let i = 0; i < newMovements.length; i++) {
      if (newMovements[i].movementName === data) {
        if (newMovements[i].videoLinkId !== '') isExist = true;
        break;
      }
    }

    return isExist;
  }


  // 전체 리스트에서 영상 미리보기 버튼 클릭시 //
  function onClickMovementVideoViewOfAllList(data, index) {
    // setPrevVideoLinkId(data.videoLinkId);
    let settingData = {
      // Movement Data Setting //
      movementId: data.movementId,
      detailComment: data.detailComment,
      movementOptions: data.movementOptions,
      isUseAvailable: data.isUseAvailable,
      linkCoachId: data.linkCoachId,
      movementName: data.movementName,
      keyword: data.keyword,
      storeId: data.storeId,
      videoLinkId: data.videoLinkId,
      // Workout Exercise Setting //
      isUseWeight: data.movementOptions.includes('WEIGHT') ? true : false,
      // isUseCount: data.movementOptions.includes('REPS') ? true : false,
      isUseCount: false,
      isUseTime: data.movementOptions.includes('TIME') ? true : false,
      isUseDistance: data.movementOptions.includes('DISTANCE') ? true : false,
      isUseTempo: false,
      isUseHeight: data.movementOptions.includes('HEIGHT') ? true : false,
      isUseCalories: data.movementOptions.includes('CALORIES') ? true : false,
      isUseRest: false,
      weight: 0,
      count: 0,
      time: 0,
      minute: 0,
      second: 0,
      tempo: 0,
      distance: 0,
      height: 0,
      calories: 0,
      rest: 0,
      sets: 1,
      weightUnit: 'Pounds',
      heightUnit: 'm',
      caloriesUnit: 'Cal',
      distanceUnit: 'm',
      divisionWeight: 'None'
    }

    setIsUseWeight(data.movementOptions.includes('WEIGHT') ? true : false);
    // setIsUseCount(data.movementOptions.includes('REPS') ? true : false);
    setIsUseCount(false);
    setIsUseTime(data.movementOptions.includes('TIME') ? true : false);
    setIsUseTempo(data.movementOptions.includes('DISTANCE') ? true : false);
    setIsUseDistance(data.movementOptions.includes('DISTANCE') ? true : false);
    setIsUseHeight(data.movementOptions.includes('HEIGHT') ? true : false);
    setIsUseCalories(data.movementOptions.includes('CALORIES') ? true : false);

    setRecentlySearchValue(settingData);
    setPrevVideoLinkId(data.videoLinkId);

    if (data.videoLinkId !== '') setVideoLink('https://www.youtube.com/watch?v=' + data.videoLinkId);
    else setVideoLink('');

    setIsClickedVideoView(true);

    // setTimeout(() => {
    //   SearchMovementRef.current[index]?.scrollIntoView({ behavior: 'smooth' });
    // }, 300)
  }

  // 최근 검색어 클릭시 //
  function onClickRecentlyData(data) {
    if (isClickedMyMovement) return;

    let newMovementList = movements.slice();

    for (let i = 0; i < newMovementList.length; i++) {
      if (newMovementList[i].movementName === data) {

        // 검색 내역 세팅
        let settingData = {
          // Movement Data Setting //
          movementId: newMovementList[i].movementId,
          detailComment: newMovementList[i].detailComment,
          movementOptions: newMovementList[i].movementOptions,
          isUseAvailable: newMovementList[i].isUseAvailable,
          linkCoachId: newMovementList[i].linkCoachId,
          movementName: newMovementList[i].movementName,
          keyword: newMovementList[i].keyword,
          storeId: newMovementList[i].storeId,
          videoLinkId: newMovementList[i].videoLinkId,
          // Workout Exercise Setting //
          isUseWeight: newMovementList[i].movementOptions.includes('WEIGHT') ? true : false,
          // isUseCount: newMovementList[i].movementOptions.includes('REPS') ? true : false,
          isUseCount: false,
          isUseTime: newMovementList[i].movementOptions.includes('TIME') ? true : false,
          isUseDistance: newMovementList[i].movementOptions.includes('DISTANCE') ? true : false,
          isUseTempo: false,
          isUseHeight: newMovementList[i].movementOptions.includes('HEIGHT') ? true : false,
          isUseCalories: newMovementList[i].movementOptions.includes('CALORIES') ? true : false,
          isUseRest: false,
          weight: 0,
          count: 0,
          time: 0,
          minute: 0,
          second: 0,
          tempo: 0,
          distance: 0,
          height: 0,
          calories: 0,
          rest: 0,
          sets: 1,
          weightUnit: 'Pounds',
          heightUnit: 'm',
          caloriesUnit: 'Cal',
          distanceUnit: 'm',
          divisionWeight: 'None'
        }

        successAddMovement(settingData);
        break;
      }
    }
  }

  // 검색된 내역 클릭시 //
  function onClickDivideData(data) {
    if (isClickedMyMovement) return;

    if (searchValue.movementName === data.movementName && searchValue.movementId !== undefined) setSearchValue({ movementName: '' });
    else {
      // 검색 내역 세팅
      let settingData = {
        // Movement Data Setting //
        id: data.id,
        detailComment: data.detailComment,
        movementId: data.movementId,
        movementOptions: data.movementOptions,
        isUseAvailable: data.isUseAvailable,
        linkCoachId: data.linkCoachId,
        movementName: data.movementName,
        keyword: data.keyword,
        storeId: data.storeId,
        videoLinkId: data.videoLinkId,
        // Workout Exercise Setting //
        isUseWeight: data.movementOptions.includes('WEIGHT') ? true : false,
        // isUseCount: data.movementOptions.includes('REPS') ? true : false,
        isUseCount: false,
        isUseTime: data.movementOptions.includes('TIME') ? true : false,
        isUseDistance: data.movementOptions.includes('DISTANCE') ? true : false,
        isUseTempo: data.movementOptions.includes('TEMPO') ? true : false,
        isUseHeight: data.movementOptions.includes('HEIGHT') ? true : false,
        isUseCalories: data.movementOptions.includes('CALORIES') ? true : false,
        isUseRest: false,
        weight: 0,
        count: 0,
        time: 0,
        minute: 0,
        second: 0,
        tempo: 0,
        distance: 0,
        height: 0,
        calories: 0,
        rest: 0,
        sets: 1,
        weightUnit: 'Pounds',
        heightUnit: 'm',
        caloriesUnit: 'Cal',
        distanceUnit: 'm',
        divisionWeight: 'None'
      }

      setSearchValue(settingData);
      // 최근 검색 내역 최신화
      let newRecentlyData = recentlySearchHistory.slice();

      let isDuplicate = false;

      for (let i = 0; i < newRecentlyData.length; i++) {
        if (newRecentlyData[i] === data.movementName) {
          isDuplicate = true;
          break;
        }
      }

      if (!isDuplicate) {
        let newString = '';

        newRecentlyData = newRecentlyData.reverse();
        newRecentlyData.push(data.movementName);

        for (let i = 0; i < newRecentlyData.length; i++) {
          if (i === 0) newString = newRecentlyData[i];
          else newString += '!@!' + newRecentlyData[i];
        }

        window.localStorage.setItem('RecentlySearchText', newString);

        newRecentlyData = newRecentlyData.reverse();
        setRecentlySearchHistory(newRecentlyData);
      }

      successAddMovement(settingData);
      setSearchValue({movementName: ''});
    }
  }


  // 최근 검색내역에서 영상 미리보기 버튼 클릭시 //
  function onClickMovementVideoViewOfRecentlyList(data, index) {
    let newMovementList = movements.slice();
    
    for (let i = 0; i < newMovementList.length; i++) {
      if (newMovementList[i].movementName === data) {

        // 검색 내역 세팅
        let settingData = {
          // Movement Data Setting //
          movementId: newMovementList[i].movementId,
          detailComment: newMovementList[i].detailComment,
          movementOptions: newMovementList[i].movementOptions,
          isUseAvailable: newMovementList[i].isUseAvailable,
          linkCoachId: newMovementList[i].linkCoachId,
          movementName: newMovementList[i].movementName,
          keyword: newMovementList[i].keyword,
          storeId: newMovementList[i].storeId,
          videoLinkId: newMovementList[i].videoLinkId,
          // Workout Exercise Setting //
          isUseWeight: newMovementList[i].movementOptions.includes('WEIGHT') ? true : false,
          // isUseCount: newMovementList[i].movementOptions.includes('REPS') ? true : false,
          isUseCount: false,
          isUseTime: newMovementList[i].movementOptions.includes('TIME') ? true : false,
          isUseDistance: newMovementList[i].movementOptions.includes('DISTANCE') ? true : false,
          isUseTempo: false,
          isUseHeight: newMovementList[i].movementOptions.includes('HEIGHT') ? true : false,
          isUseCalories: newMovementList[i].movementOptions.includes('CALORIES') ? true : false,
          isUseRest: false,
          weight: 0,
          count: 0,
          time: 0,
          minute: 0,
          second: 0,
          tempo: 0,
          distance: 0,
          height: 0,
          calories: 0,
          rest: 0,
          sets: 1,
          weightUnit: 'Pounds',
          heightUnit: 'm',
          caloriesUnit: 'Cal',
          distanceUnit: 'm',
          divisionWeight: 'None'
        }

        setIsUseWeight(newMovementList[i].movementOptions.includes('WEIGHT') ? true : false);
        // setIsUseCount(newMovementList[i].movementOptions.includes('REPS') ? true : false);
        setIsUseCount(false);
        setIsUseTime(newMovementList[i].movementOptions.includes('TIME') ? true : false);
        setIsUseTempo(newMovementList[i].movementOptions.includes('DISTANCE') ? true : false);
        setIsUseDistance(newMovementList[i].movementOptions.includes('DISTANCE') ? true : false);
        setIsUseHeight(newMovementList[i].movementOptions.includes('HEIGHT') ? true : false);
        setIsUseCalories(newMovementList[i].movementOptions.includes('CALORIES') ? true : false);

        setRecentlySearchValue(settingData);
        setPrevVideoLinkId(newMovementList[i].videoLinkId);

        if (newMovementList[i].videoLinkId !== '') setVideoLink('https://www.youtube.com/watch?v=' + newMovementList[i].videoLinkId);
        else setVideoLink('');

        setIsClickedVideoView(true);

        // setTimeout(() => {
        //   RecentlyMovementRef.current[index]?.scrollIntoView({ behavior: 'smooth' });
        // }, 300)
        break;
      }
    }

    setIsClickedVideoView(true);
  }


  // 최근 검색어 삭제 버튼 클릭시 //
  function onClickRecentlyDataDelete(index) {
    let newRecentlyData = recentlySearchHistory.slice();

    newRecentlyData.splice(index, 1);
    setRecentlySearchHistory(newRecentlyData);

    let newString = '';

    for (let i = newRecentlyData.length - 1; i >= 0; i--) {
      if (i === newRecentlyData.length - 1) newString = newRecentlyData[i];
      else newString += '!@!' + newRecentlyData[i];
    }

    window.localStorage.setItem('RecentlySearchText', newString);
  }

  // 모달 동작 추가 완료 버튼 클릭시 //
  function successAddMovement(data) {
    if (data.movementName === '') {
      alert('추가할 운동을 선택해주세요!');
      return;
    }

    data.mainSingleIndex = sectionData.mainExerciseList.length + 1;
    sectionData.mainExerciseList.push(lodash.clone(data));

    refreshTotalData();
    setIsClickedAddMovement(false);

    // if (division === 'buyin') sectionData.buyinExerciseList.push(data);
    // else if (division === 'main') {
    //   if (sectionData.tag === 'SINGLE') {
    //     data.mainSingleIndex = sectionData.mainExerciseList.length + 1;

    //     sectionData.mainExerciseList.push(lodash.clone(data));
    //     sectionData.mainExerciseList.push(lodash.clone(data));
    //     sectionData.mainExerciseList.push(lodash.clone(data));
    //   }
    //   else if (sectionData.tag === 'EMOM') {
    //     data.emomRoundIndex = roundIndex;

    //     sectionData.mainExerciseList.push(data);
    //     sectionData.emomExerciseList.push(data);

    //     setRoundIndex(undefined);
    //   }
    //   else sectionData.mainExerciseList.push(data);
    // }
    // else if (division === 'buyout') sectionData.buyoutExerciseList.push(data);
    // setIsClickedMovement(false);
  }

  // 동작 영상 모달 열기 클릭시 //
  function onClickMovementVideoViewModal(index) {
    if (sectionData.mainExerciseList[index].customVideoLinkId === undefined) setVideoLink(sectionData.mainExerciseList[index].videoLinkId);
    else setVideoLink(sectionData.mainExerciseList[index].customVideoLinkId);

    setIsClickedVideoView(true);
  }

  // 동작 옵션 모달 열기 클릭시 //
  function onClickMovementOptionModal(index) {
    setMovementOptionData(sectionData.mainExerciseList[index]);
    setIsClickedMovementOptions(true);
  }

  // 운동 복사버튼 클릭시 //
  function onClickCopyMovement(data, division) {
    let copyData = lodash.clone(data);

    sectionData.mainExerciseList.push(copyData);

    refreshTotalData();
  }

  // 동작 삭제 버튼 클릭시 //
  function onClickDeleteMovement(index) {
    sectionData.mainExerciseList.splice(index, 1);

    refreshTotalData();
  }

  // 무게, 길이, 높이, 열량, 횟수, 시간, 템포 데이터 변경시 //
  function onChangeData(index, column, value) {
    if (column === 'weight') sectionData.mainExerciseList[index].weight = value;
    else if (column === 'distance') sectionData.mainExerciseList[index].distance = value;
    else if (column === 'height') sectionData.mainExerciseList[index].height = value;
    else if (column === 'calories') sectionData.mainExerciseList[index].calories = value;
    else if (column === 'count') sectionData.mainExerciseList[index].count = value;
    else if (column === 'tempo') sectionData.mainExerciseList[index].tempo = value;
    else if (column === 'sets') sectionData.mainExerciseList[index].sets = value;
    else if (column === 'rest') sectionData.mainExerciseList[index].rest = value;
    else if (column === 'minute') {
      sectionData.mainExerciseList[index].time = (parseInt(value) * 60) + parseInt(sectionData.mainExerciseList[index].second);
      sectionData.mainExerciseList[index].minute = value;
    }
    else if (column === 'second') {
      sectionData.mainExerciseList[index].time = (parseInt(sectionData.mainExerciseList[index].minute) * 60) + parseInt(value);
      sectionData.mainExerciseList[index].second = value;
    }

    refreshTotalData();
  }

  // 무게, 길이, 높이, 열량 데이터 단위 변경시 //
  function onChangeUnit(index, division, column, value) {
    if (column === 'weight') sectionData.mainExerciseList[index].weightUnit = value;
    else if (column === 'distance') sectionData.mainExerciseList[index].distanceUnit = value;
    else if (column === 'height') sectionData.mainExerciseList[index].heightUnit = value;
    else if (column === 'calories') sectionData.mainExerciseList[index].caloriesUnit = value;

    refreshTotalData();
  }

  // 동작 추가하기 및 나만의 동작 추가하기 버튼 클릭시 //
  function onClickAddMovement() {
    if (!isClickedAddMovement) setIsClickedAddMovement(true);
    else if (!isClickedAddMyMovement) setIsClickedAddMyMovement(true);
    else {
      let movementName = myMovementName;
      let videoLinkId = '';
      let movementOptions = '';

      if (movementName === '') {
        alert('동작 이름을 설정해주세요');
        return;
      }

      if (videoLink.includes('https://www.youtube.com/watch?v=')) videoLinkId = videoLink.replace('https://www.youtube.com/watch?v=', '');
      else if (videoLink.includes('https://youtu.be/')) videoLinkId = videoLink.replace('https://youtu.be/', '');

      if (isUseWeight) movementOptions += ',WEIGHT';
      if (isUseCount) movementOptions += ',REPS';
      if (isUseTime) movementOptions += ',TIME';
      if (isUseTempo) movementOptions += ',TEMPO';
      if (isUseDistance) movementOptions += ',DISTANCE';
      if (isUseHeight) movementOptions += ',HEIGHT';
      if (isUseCalories) movementOptions += ',CALORIES';

      if (movementOptions.length !== 0) movementOptions = movementOptions.slice(1);

      let data = {
        detailComment: '',
        isUseAvailable: false,
        userId: 'None',
        movementName: myMovementName,
        movementOptions: movementOptions,
        keyword: '',
        storeId: 0,
        videoLinkId: videoLinkId,
      }
      
      const fetchModule = new FetchModule();
      (
        async () => {
          let responseData = await fetchModule.postDataOfExercise('movement/save', 'POST', data);
          // console.log(responseData) //
          if (responseData.status === 200) {
            let settingData = {
              // Movement Data Setting //
              id: 0,
              detailComment: responseData.data[0].detailComment,
              movementOptions: responseData.data[0].movementOptions,
              isUseAvailable: responseData.data[0].isUseAvailable,
              linkCoachId: responseData.data[0].linkCoachId,
              movementId: responseData.data[0].movementId,
              movementName: responseData.data[0].movementName,
              keyword: responseData.data[0].keyword,
              storeId: responseData.data[0].storeId,
              videoLinkId: responseData.data[0].videoLinkId,
              // Workout Exercise Setting //
              isUseWeight: isUseWeight,
              isUseCount: isUseCount,
              isUseTime: isUseTime,
              isUseDistance: isUseDistance,
              isUseTempo: isUseTempo,
              isUseHeight: isUseHeight,
              isUseCalories: isUseCalories,
              isUseRest: false,
              weight: 0,
              count: 0,
              time: 0,
              minute: 0,
              second: 0,
              tempo: 0,
              distance: 0,
              height: 0,
              calories: 0,
              rest: 0,
              sets: 1,
              weightUnit: 'Pounds',
              heightUnit: 'm',
              caloriesUnit: 'Cal',
              distanceUnit: 'm',
              divisionWeight: 'None'
            }

            successAddMovement(settingData);
            // 최근 검색 내역 최신화
            let newRecentlyData = recentlySearchHistory.slice();

            let isDuplicate = false;

            for (let i = 0; i < newRecentlyData.length; i++) {
              if (newRecentlyData[i] === data.movementName) {
                isDuplicate = true;
                break;
              }
            }

            if (!isDuplicate) {
              let newString = '';

              newRecentlyData = newRecentlyData.reverse();
              newRecentlyData.push(data.movementName);

              for (let i = 0; i < newRecentlyData.length; i++) {
                if (i === 0) newString = newRecentlyData[i];
                else newString += '!@!' + newRecentlyData[i];
              }

              window.localStorage.setItem('RecentlySearchText', newString);

              newRecentlyData = newRecentlyData.reverse();
              setRecentlySearchHistory(newRecentlyData);
            }
            
            let newMovements = movements.slice();

            newMovements = newMovements.concat(responseData.data);

            setMovements(newMovements);
            setSearchValue({ movementName: '' });

            setIsClickedAddMovement(false);
            setIsClickedAddMyMovement(false);
          }
          else if (responseData.status === 401) {
            alert("잘못된 권한 접근입니다.");
          }
        }
      )();
    }
  }

  // 나만의 동작 추가하기 취소 버튼 클릭시 //
  function onClickExitAddMovement() {
    // setIsClickedAddMovement(false);
    setIsClickedAddMyMovement(false);
  }

  return (
    <AddMovementBoxWrapper>
      {isClickedVideoView && <VideoViewModal data={videoLink.replace('https://www.youtube.com/watch?v=', '')} close={() => setIsClickedVideoView(!isClickedVideoView)} />}
      {isClickedMovementOptions && <MovementOptionModal data={movementOptionData} refreshTotalData={refreshTotalData} close={() => setIsClickedMovementOptions(false)} />}
      <InnerWrapper>
        <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#000000'>동작 기록하기</Text>
        <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#666666'>순위 계산에 필요한 동작 기록을 추가해주세요</Text>
        <DivisionLine style={{ marginTop: '8px' }} />
        <MovementBoxWrapper>
          {
            sectionData.mainExerciseList.map((data, index) => (
              <RecordMovementBox
                idx={index}
                data={data}

                onChangeData={onChangeData}
                onChangeUnit={onChangeUnit}

                onClickMovementOption={onClickMovementOptionModal}
                onClickMovementVideo={onClickMovementVideoViewModal}
                onClickCopyMovement={onClickCopyMovement}
                onClickDeleteMovement={onClickDeleteMovement} />
            ))
          }
        </MovementBoxWrapper>
      </InnerWrapper>
      <AddMyMovementWrapper isClickedAddMyMovement={isClickedAddMyMovement}>
        {
          isClickedAddMyMovement &&
          <MyMovementTitleWrapper>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF'>나만의 동작 추가하기</Text>
          </MyMovementTitleWrapper>
        }
        <InnerAddMyMovementWrapper isClickedAddMyMovement={isClickedAddMyMovement}>
          {
            !isClickedAddMyMovement &&
            <SearchMovementBox isClickedAddMovement={isClickedAddMovement}>
              <SearchInputWrapper>
                <MovementSearchInput value={searchValue} setValue={setSearchValue} />
              </SearchInputWrapper>
              <RecentlySearchWrapper>
                {
                  searchValue.movementName === '' ?
                    <DataWrapper>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#6DD49E'>최근 검색어({recentlySearchHistory.length})</Text>
                      {
                        recentlySearchHistory.map((data, index) => (
                          <RecentlyBox key={index} ref={(el) => (RecentlyMovementRef.current[index] = el)}>
                            <RecentlyTextWrapper onClick={() => onClickRecentlyData(data)}>
                              {/* <RecentlyText>{data}</RecentlyText> */}
                              <RecentlyText isClicked={(recentlySearchValue.movementName === data && recentlySearchValue.movementId !== undefined)}>{calcColorText(data, recentlySearchValue.movementName, 0)}</RecentlyText>
                              <SearchText>{calcColorText(data, recentlySearchValue.movementName, 1)}</SearchText>
                              <RecentlyText isClicked={(recentlySearchValue.movementName === data && recentlySearchValue.movementId !== undefined)}>{calcColorText(data, recentlySearchValue.movementName, 2)}</RecentlyText>
                            </RecentlyTextWrapper>
                            <RecentlySearchTextButtonWrapper>
                              <Icon src={decideProgramVideoIcon(data) ? ProgramVideoIcon : NoneProgramVideoIcon} onClick={() => onClickMovementVideoViewOfRecentlyList(data, index)} />
                              <DeleteButton src={ExitButtonImage} onClick={() => onClickRecentlyDataDelete(index)} />
                            </RecentlySearchTextButtonWrapper>
                          </RecentlyBox>
                        ))
                      }
                    </DataWrapper>
                    :
                    <DataWrapper>
                      {
                        divideData.length === 0 ?
                          <EmptyDataWrapper>
                            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#D0D0D0'>찾으시는 동작의 정보가 없습니다</Text>
                          </EmptyDataWrapper>
                          :
                          divideData.map((data, index) => (
                            <RecentlyBox key={index} ref={(el) => (SearchMovementRef.current[index] = el)}>
                              <RecentlyTextWrapper onClick={() => onClickDivideData(data)}>
                                <RecentlyText isClicked={(searchValue.movementName === data.movementName && searchValue.movementId !== undefined)}>{calcColorText(data.movementName, searchValue.movementName, 0)}</RecentlyText>
                                <SearchText>{calcColorText(data.movementName, searchValue.movementName, 1)}</SearchText>
                                <RecentlyText isClicked={(searchValue.movementName === data.movementName && searchValue.movementId !== undefined)}>{calcColorText(data.movementName, searchValue.movementName, 2)}</RecentlyText>
                              </RecentlyTextWrapper>
                              <DataSearchButtonWrapper>
                                <Icon src={data.videoLinkId === '' ? NoneProgramVideoIcon : ProgramVideoIcon} onClick={() => onClickMovementVideoViewOfAllList(data, index)} />
                              </DataSearchButtonWrapper>
                            </RecentlyBox>
                          ))
                      }
                    </DataWrapper>
                }
              </RecentlySearchWrapper>
            </SearchMovementBox>
          }
          {
            isClickedAddMyMovement &&
            <MyMovementBox>
              <AddMyMovementColumnWrapper style={{ marginTop: '26px' }}>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F' hover='default'>동작 이름</Text>
                <MyMovementTitleInput value={myMovementName} setValue={setMyMovementName} disabled={!isClickedAddMyMovement} placeholder={searchValue.movementName} />
              </AddMyMovementColumnWrapper>
              <AddMyMovementColumnWrapper style={{ marginTop: '28px' }}>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F' hover='default'>동작 옵션</Text>
                <MyMovementOptionButtonWrapper>
                  <MovementOptionCheckBoxWrapper>
                    <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F' hover='default'>무게</Text>
                    <MovementOptionCheckBox isClicked={isUseWeight} onClick={() => setIsUseWeight(!isUseWeight)}>
                      <MovementOptionCheck src={WhiteCheck} />
                    </MovementOptionCheckBox>
                  </MovementOptionCheckBoxWrapper>
                  <MovementOptionCheckBoxWrapper>
                    <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F' hover='default'>횟수</Text>
                    <MovementOptionCheckBox isClicked={isUseCount} onClick={() => setIsUseCount(!isUseCount)} >
                      <MovementOptionCheck src={WhiteCheck} />
                    </MovementOptionCheckBox>
                  </MovementOptionCheckBoxWrapper>
                  <MovementOptionCheckBoxWrapper>
                    <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F' hover='default'>시간</Text>
                    <MovementOptionCheckBox isClicked={isUseTime} onClick={() => setIsUseTime(!isUseTime)}>
                      <MovementOptionCheck src={WhiteCheck} />
                    </MovementOptionCheckBox>
                  </MovementOptionCheckBoxWrapper>
                  <MovementOptionCheckBoxWrapper>
                    <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F' hover='default'>템포</Text>
                    <MovementOptionCheckBox isClicked={isUseTempo} onClick={() => setIsUseTempo(!isUseTempo)}>
                      <MovementOptionCheck src={WhiteCheck} />
                    </MovementOptionCheckBox>
                  </MovementOptionCheckBoxWrapper>
                  <MovementOptionCheckBoxWrapper>
                    <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F' hover='default'>거리</Text>
                    <MovementOptionCheckBox isClicked={isUseDistance} onClick={() => setIsUseDistance(!isUseDistance)}>
                      <MovementOptionCheck src={WhiteCheck} />
                    </MovementOptionCheckBox>
                  </MovementOptionCheckBoxWrapper>
                  <MovementOptionCheckBoxWrapper>
                    <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F' hover='default'>높이</Text>
                    <MovementOptionCheckBox isClicked={isUseHeight} onClick={() => setIsUseHeight(!isUseHeight)}>
                      <MovementOptionCheck src={WhiteCheck} />
                    </MovementOptionCheckBox>
                  </MovementOptionCheckBoxWrapper>
                  <MovementOptionCheckBoxWrapper>
                    <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F' hover='default'>열량</Text>
                    <MovementOptionCheckBox isClicked={isUseCalories} onClick={() => setIsUseCalories(!isUseCalories)}>
                      <MovementOptionCheck src={WhiteCheck} />
                    </MovementOptionCheckBox>
                  </MovementOptionCheckBoxWrapper>
                </MyMovementOptionButtonWrapper>
              </AddMyMovementColumnWrapper>
              <AddMyMovementColumnWrapper style={{ marginTop: '26px' }}>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F' hover='default'>동작 영상</Text>
                <VideoLinkInputWrapper>
                  <YoutubeURLInput value={videoLink} setValue={setVideoLink} />
                </VideoLinkInputWrapper>
                <VideoComponentWrapper>
                  {
                    prevVideoLinkId === '' ?
                      <VideoPrevBox>
                        <Text fontFamily='NotoSansKR-Medium' fontSize={12} color='#232323'>동영상이 존재하지 않습니다.</Text>
                      </VideoPrevBox>
                      :
                      <VideoPrevBox>
                        <iframe
                          width="100%"
                          height="100%"
                          src={`https://www.youtube.com/embed/` + prevVideoLinkId}
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                          title="Embedded youtube" />
                      </VideoPrevBox>
                  }
                </VideoComponentWrapper>
                {/* <MyMovementTitleInput value={myMovementName} setValue={setMyMovementName} disabled={!isClickedAddMyMovement} placeholder={searchValue.movementName} /> */}
              </AddMyMovementColumnWrapper>
            </MyMovementBox>
          }
          <ButtonWrapper isClickedAddMyMovement={isClickedAddMyMovement}>
            <ExitAddMovementButton
              isClickedAddMovement={isClickedAddMyMovement}
              onClick={onClickExitAddMovement}>
              <Text style={{ whiteSpace: 'nowrap' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' hover='pointer'>취소</Text>
            </ExitAddMovementButton>
            <AddMovementButton
              isClickedAddMovement={isClickedAddMyMovement}
              onClick={onClickAddMovement}>
              <MovementPlus src={MovementPlusImage} />
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' hover='pointer'>{isClickedAddMovement ? `나만의 동작 추가하기` : `동작 추가하기`}</Text>
            </AddMovementButton>
          </ButtonWrapper>
        </InnerAddMyMovementWrapper>
      </AddMyMovementWrapper>
    </AddMovementBoxWrapper>
  )
}

const AddMovementBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 20px 0px;

  width: 100%;

  border-radius: 10px;
  background-color: #FFFFFF;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: calc(100% - 30px);
`;

const DivisionLine = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 1px;

  background-color: #E5E5E5;
`;

const MovementBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const AddMyMovementWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;
  
  width: 100%;

  border-radius: ${props => props.isClickedAddMyMovement ? '5px' : '0px'};
  background-color: ${props => props.isClickedAddMyMovement ? '#FF8B48' : '#FFFFFF'};

  transition: all 0.3s ease-in-out;
`;

const InnerAddMyMovementWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;
  
  margin: ${props => props.isClickedAddMyMovement ? '0px 0px 3px 0px' : '0px'};

  width: ${props => props.isClickedAddMyMovement ? `calc(100% - 6px)` : `calc(100% - 30px)`};

  border-bottom-left-radius: ${props => props.isClickedAddMyMovement ? `4px` : `0px`};
  border-bottom-right-radius: ${props => props.isClickedAddMyMovement ? `4px` : `0px`};
  background-color: ${props => props.isClickedAddMyMovement ? `#F9F9F9` : `#FFFFFF`};
  
  transition: all 0.3s ease-in-out;
`;

const SearchMovementBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 100%;
  height: ${props => props.isClickedAddMovement ? `463px` : `0px`};

  overflow: hidden;

  transition: all 0.3s ease-in-out;
`;


const MyMovementBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 100%;
  // height: ${props => props.isClickedAddMyMovement ? `610px` : `0px`};
  height: 570px;

  overflow: hidden;

  transition: all 0.3s ease-in-out;
`;

const AddMyMovementColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: calc(100% - 24px);

  gap: 8px;
`;

const SearchInputWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  margin-top: 14px;

  width: 100%;
`;

const MyMovementOptionButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;
  
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: 20px;
  margin-bottom:  ${props => props.isClickedAddMyMovement ? '20px' : '0px'};

  width: ${props => props.isClickedAddMyMovement ? `calc(100% - 24px)` : `100%`};

  transition: all 0.3s ease-in-out;
`;

const ExitAddMovementButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: ${props => props.isClickedAddMovement ? `12.44%` : `0%`};
  height: 36px;

  border-radius: 8px;
  background-color: #CBCBCB;

  overflow: hidden;
  
  :hover {
    cursor: pointer
  }

  transition: all 0.3s ease-in-out;
`;

const AddMovementButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: ${props => props.isClickedAddMovement ? `86.53%` : `100%`};
  height: 36px;

  border-radius: ${props => props.isClickedAddMovement ? `8px` : `12px`};
  background-color: ${props => props.isClickedAddMovement ? `#FF8B48` : `#6DD49E`};

  :hover {
    cursor: pointer
  }

  transition: all 0.3s ease-in-out;
`;

const RecentlySearchWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  height: ${props => props.isClickedMyMovement ? `0px` : `572px`};

  overflow-x: hidden;
  overflow-y: scroll;

  margin-top: 16px;

  border-bottom: 1px solid #D9D9D9;

  transition: all 0.3s ease-in-out;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
		border: 3px solid transparent;
		background-color: #DADADA;
		border-radius: 200px;
	}

  &::-webkit-scrollbar-track {
		background-color: #F0F0F0;
		border-radius: 200px;
	}
`;

const MovementPlus = styled.img`
  width: 20px;
  height: 20px;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  
  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;


const DataWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  height: 100%;
`;

const EmptyDataWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
`;

const RecentlyBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 98%;
  min-height: 33px;

  border-bottom: 1px solid #F5F5F5;
`;

const RecentlyTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  :hover {
    cursor: pointer;
    color: #FF8B48;
  }
`;

const RecentlyText = styled.pre`
  font-family: Poppins-Regular;
  font-size: 12px;

  color: ${props => props.isClicked ? `#FF8B48` : `#4F4F4F`};

  margin: 0 0 0 0;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    color: #FF8B48;
  }
`;

const SearchText = styled.pre`
  font-family: Poppins-Regular;
  font-size: 12px;

  color: #FF8B48;

  margin: 0 0 0 0;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    color: #FF8B48;
  }
`;

const RecentlySearchTextButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
`;

const DataSearchButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;

  :hover {
    cursor: pointer;
  }
`;

const DeleteButton = styled.img`
  width: 20px;
  height: 20px;

  :hover {
    cursor: pointer;
  }
`;

const MyMovementTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  margin: 12px 0px;

  width: calc(100% - 30px);
`;

const MovementOptionCheckBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  gap: 8px;
  
  width: 13.5%;
`;

const MovementOptionCheckBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 34px;

  border-radius: 4px;
  background-color: ${props => props.isClicked ? `#6DD49E` : `#E0E0E0`};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const MovementOptionCheck = styled.img`
  width: 15.75px;
  height: 13.5px;
`;

const VideoLinkInputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 100%;
  height: 36px;
`;

const VideoComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;

  gap: 8px;
  margin-top: 16px;
`;

const VideoPrevBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  overflow: hidden;

  width: 490px;
  height: 250px;

  border-radius: 5px;

  background-color: #E0E0E0;
`;
