import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import moment from "moment";

export default function HistoryContent(props) {
  const { index, data } = props;

  const [height, setHeight] = useState(0);
  const componentRef = useRef(null);

  useEffect(() => {
    const handleResize = (entries) => {
      for (let entry of entries) {
        setHeight(entry.contentRect.height); // 변경된 높이를 상태에 저장
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);
    if (componentRef.current) {
      resizeObserver.observe(componentRef.current); // ref를 사용하여 관찰 시작
    }

    // Cleanup
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <TopLevelWrapper ref={componentRef} isFirst={index === 0}>
      <HistoryTextWrapper width={93}>
        <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='default'>{moment(data.createdDate).format('YY.MM.DD HH:mm')}</Text>
      </HistoryTextWrapper>
      <DivisionLine height={height} backgroundColor='rgba(150, 150, 150, 1)' />
      <HistoryTextWrapper width={60}>
        <NoAnimationText fontFamily='NotoSansKR-Regular' fontSize={data.division === undefined ? 14 : 12} color='#595959' hover='default'>{data.division === undefined ? '문자 발송' : '자동 메세지'}</NoAnimationText>
      </HistoryTextWrapper>
      <DivisionLine height={height} backgroundColor='rgba(150, 150, 150, 1)' />
      <HistoryTextWrapper width={611}>
        <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='default'>{data.content}</Text>
      </HistoryTextWrapper>
      <DivisionLine height={height} backgroundColor='rgba(150, 150, 150, 1)' />
      <HistoryTextWrapper width={52}>
        <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='default'>{data.successCount + data.failedCount}</Text>
      </HistoryTextWrapper>
      <DivisionLine height={height} backgroundColor='rgba(150, 150, 150, 1)' />
      <HistoryTextWrapper width={44}>
        <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='default'>{data.successCount}</Text>
      </HistoryTextWrapper>
      <DivisionLine height={height} backgroundColor='rgba(150, 150, 150, 1)' />
      <HistoryTextWrapper width={44}>
        <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='default'>{data.failedCount}</Text>
      </HistoryTextWrapper>
    </TopLevelWrapper>
  );
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 100%;

  border-top: ${props => props.isFirst ? '0px' : '1px solid #969696'};
`;

const HistoryTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  padding: 10px 8px;

  width: ${props => props.width}px;
`;

const DivisionLine = styled.div`
  width: 1px;
  height: ${props => props.height}px;

  background-color: ${props => props.backgroundColor};
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;

const NoAnimationText = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.hover};
  }
`;