import React from 'react';
import styled from 'styled-components';

export default function NewDayReasonInput(props) {
  const { value, setValue } = props;

  function handleChange(e) {
		setValue(e.target.value);
  }

  function handleFocus() {
    
  }

  function handleBlur() {
    
  }

  return (
    <TopLevelWrapper>
      <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>연장 사유</Text>
      <Input 
        placeholder='연장 사유를 적어주세요'
        value={value || ''}
        onFocus={handleFocus}
        onChange={handleChange}
        onBlur={handleBlur}/>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const Input = styled.textarea`
  width: 987px;
  height: 128px;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  margin-top: 8px;
  padding: 8px 12px;

  border: 1px solid #DFDFDF;
  border-radius: 6px;
  outline: none;

  color: #333333;
  background-color: #FDFCFB;

  resize: none;

  ::placeholder {
    color: #CBCBCB;
  }
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
`;