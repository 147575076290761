import { React } from 'react';
import styled from 'styled-components';

import WaittingView from '../../Model/Individual/MemberModel/WaittingView.js';

function WaittingMemberPage() {

  return (
    <Container>
      <WaittingView/>
    </Container>
  )
}

export default WaittingMemberPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
`;