import { React } from 'react';
import styled from 'styled-components';

import DownImage from '../../image/down.png';
import UpImage from '../../image/up.png';
import NewNumberOfMonthSelectBox from './NewNumberOfMonthSelectBox';

export default function NewMemberShipValidity(props) {
  const { value, setValue, validityUnit, setValidifyUnit } = props;

	function handleChange(e) {
    var regex = /[^0-9]/g;				// 숫자가 아닌 문자열을 매칭하는 정규식
    var result = e.target.value.replace(regex, 0);	// 원래 문자열에서 숫자가 아닌 모든 문자열을 빈 문자로 변경
    // console.log(result);	

    if (e.target.value === '') setValue(0);
		else setValue(parseInt(result));
	}

	function handleFocus(e) {

	}

  function upButtonClick() {
    setValue(value + 1);
  }

  function downButtonClick(){
    if (value > 0) setValue(value - 1);
  }

  return (
    <TopLevelWrapper>
      <Input
        placeholder={0}
        value={value}
        onChange={handleChange}
        onFocus={handleFocus}/>
      <ButtonWrapper>
        <Button img={UpImage} onClick={upButtonClick}/>
        <Button img={DownImage} onClick={downButtonClick}/>
      </ButtonWrapper>
      <NewNumberOfMonthSelectBox unit={validityUnit} setUnit={setValidifyUnit}/>
    </TopLevelWrapper>
  )
}


const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 100%;

  transition: all 0.3s ease-in-out;
`;

const Input = styled.input`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: calc(35.91% - 18px);
  height: 30px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background-color: #FFFFFF;

  padding: 0 8px 0 8px;
  margin: 0 5px 0 0;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
  margin-right: 8px;
`;

const Button = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 30px;
  height: 30px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background: url(${props => props.img}) no-repeat center;
  background-color: #ECECEC;

  :hover {
    cursor: pointer;
  }
`;