import { React, useRef, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';
import AuthenticationBox from './AuthenticationBox';

const UserAuthenticationView = forwardRef((props, ref) => {
  const { clickFn } = props;

  const TopLevelRef = useRef();

  useImperativeHandle(ref, () => ({
		setOpacity: (val) => {
			TopLevelRef.current.style.opacity = val;
		},
    setZindex: (val) => {
			TopLevelRef.current.style.zIndex = val;
    }
	}))

  return (
    <TopLevelWrapper ref={TopLevelRef}>
      <TitleWrapper>
        <Title>비밀번호 입력</Title>
        <Explanation>통계 데이터 보안을 위해 회원님의 비밀번호를 다시 한번 입력해주세요.</Explanation>
      </TitleWrapper>
      <AuthenticationBox clickFn={clickFn}/>
    </TopLevelWrapper>
  )
})

export default UserAuthenticationView;

const TopLevelWrapper = styled.div`
  // position: absolute;
  display: flex;
  flex-direction: column;

  width: 1020px;

  z-index: 3;
  opacity: 1;
  
  transition: all 0.3s ease-in-out;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  gap: 4px;
  margin-left: 28px;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: 0 0 0 0;
`;

const Explanation = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #777777;

  margin: 0 0 0 0;
`;