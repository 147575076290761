import { React, useRef } from 'react';
import styled from 'styled-components';

export default function MyMovementTitleInput(props) {
	const { value, setValue, placeholder, disabled } = props;

	const InputRef = useRef();

	function handleClick(e) {
	}

	function handleChange(e) {
		setValue(e.target.value);
	}

	function handleFocus(e) {
	}

	function handleBlur(e) {
	}

	return (
    <TopLevelWrapper
      disabled={disabled}>
      <Input
        ref={InputRef}
        type='text'
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        onClick={handleClick}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur} />
    </TopLevelWrapper>
  )
};

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  width: calc(100% - 24px);
  height: 34px;

  padding-left: 12px;
  padding-right: 12px;

  // border: 1px solid #DFDFDF;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  background-color: ${props => props.disabled ? `#ECECEC` : `#FFFFFF`};
  
  transition: all 0.3s ease-in-out;
`;

const Input = styled.input` 
	font-family: NotoSansKR-Regular;
	font-size: 14px;
	line-height: 20px;
	
  display: flex;
  flex-direction: row;

  width: 100%;

  border: 0px;
  background-color: #FFFFFF;

  color: #595959;

	outline: none;

	::placeholder {
    font-family: NotoSansKR-Regular;
    font-size: 14px;

		color: #CBCBCB;
	}

  &:disabled {
    background-color: #ECECEC;
  }

  transition: all 0.3s ease-in-out;
`;