import { useRef, React, useState } from 'react';
import styled from 'styled-components';

export default function TermsTitleBox(props) {
	const { value, setValue, placeholder } = props;

  const [isFocused, setIsFocused] = useState(false);

	const InputRef = useRef();

	function handleClick(e) {

	}

	function handleChange(e) {
		setValue(e.target.value);
	}

	function handleFocus(e) {
    setIsFocused(true);
	}

	function handleBlur(e) {
    setIsFocused(false);
	}

  return (
    <TopLevelWrapper>
      <Input
        ref={InputRef}
        value={value}
        placeholder={isFocused ? '' : placeholder}
        onClick={handleClick}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur} />
    </TopLevelWrapper>
  )
};

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: calc(100% - 4px);
  height: 36px;

  border: 2px dotted #000000;
  border-radius: 5px;
  background-color: #F9F9F9;
`;

const Input = styled.input`
	font-family: NotoSansKR-Bold;
	font-size: 16px;
	
	padding: 0 0 0 0;
  margin: 0 0 0 0;

	outline: none;
	border: 0px solid #FFFFFF;
	color: #232323;

	width: 80%;
	height: 30px;

  text-align: center;

  background-color: #F9F9F9;

	::placeholder {
		color: #CBCBCB;
	}
`;