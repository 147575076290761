import { React, useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

import lodash from 'lodash';

// Component Import //
import TagSelectBox from '../../Component/Legacy/LegacyTagSelectBox';
import TagDetailSelectBox from '../../Component/Legacy/LegacyTagDetailSelectBox';
import ScoreSelectBox from '../../Component/Legacy/LegacyScoreSelectBox';
import ScoreDetailSelectBox from '../../Component/Legacy/LegacyScoreDetailSelectBox';
import TimeLimitInput from '../../Component/Legacy/LegacyTimeLimitInput';
import DescriptionTextArea from '../../Component/Legacy/LegacyDesctiptionTextArea';

import NewMovementBox from './LegacyMovementBox';

import AddMovementModal from '../../../Model/Individual/NoteModel/AddMovementModal';
import MovementOptionModal from '../../../Model/Individual/NoteModel/MovementOptionModal';
import TagExplanationModal from '../../../Model/Individual/NoteModel/TagExplanationModal';
import VideoViewModal from '../../../Model/Individual/NoteModel/VideoViewModal';
import NewEMOMMovementBox from '../../../Model/Individual/NoteModel/NewEMOMMovementBox';
import AddScaleOptionMovementModal from '../../../Model/Individual/NoteModel/AddScaleOptionMovementModal';
import ScaleOptionMovementBox from '../../../Model/Individual/NoteModel/ScaleOptionMovementBox';
import MainExerciseRoundInput from '../../../Component/NoteComponent/MainExerciseRoundInput';
import RepsInput from '../../../Component/NoteComponent/RepsInput';
import TimeInput from '../../../Component/NoteComponent/TimeInput';
import TotalTitleInputBox from '../../../Component/NoteComponent/TotalTitleInputBox';
import NoteTextArea from '../../../Component/NoteComponent/NoteTextArea';
// import EMOMTitleMovementBox from './EMOMTitleMovementBox';
// Component Import //

// Image Import //
import CheckImage from '../../../image/WhiteCheck.png';
import MovementPlusImage from '../../../image/MovementAdd.png';
import NotesMinusImage from '../../../image/NotesMinus.png';
import InfoIcon from '../../../image/InfomationIcon.png';
import RepMinusImage from '../../../image/RepMinusImage.png';
import RepPlusImage from '../../../image/RepPlusImage.png';
import NextRepsImage from '../../../image/NextRepsImage.png';
// Image Import //

export default function LegacyModifySectionBox(props) {
  const { scroll, sectionData, refreshSectionList } = props;

  const [isClickedMovement, setIsClickedMovement] = useState(false);
  const [isClickedScaleOptionMovement, setIsClickedScaleOptionMovement] = useState(false);
  const [isClickedMovementOptions, setIsClickedMovementOptions] = useState(false);
  const [isClickedTagExplanation, setIsClickedTagExplanation] = useState(false);
  const [isClickedVideoView, setIsClickedVideoView] = useState(false);
  const [isBuyinNoteView, setIsBuyinNoteView] = useState(true);
  const [isMainNoteView, setIsMainNoteView] = useState(true);
  const [isBuyoutNoteView, setIsBuyoutNoteView] = useState(true);

  const [clickedScaleOptionDivision, setClickedScaleOptionDivision] = useState('main');
  const [clickedScaleOptionIndex, setClickedScaleOptionIndex] = useState(0);
  const [clickedScaleOptionMovement, setClickedScaleOptionMovement] = useState(undefined);
  const [movementOptionData, setMovementOptionData] = useState(undefined);
  const [videoLink, setVideoLink] = useState('');
  const [division, setDivision] = useState('main');
  const [roundIndex, setRoundIndex] = useState(undefined);
  const [clickedRoundIndex, setClickedRoundIndex] = useState(undefined);
  const [lastInputRound, setLastInputRound] = useState(0);

  const [dataReps, setDataReps] = useState('');

  const [target, setTarget] = useState(undefined);
  const [targetStartY, setTargetStartY] = useState(0);
  const [targetEndY, setTargetEndY] = useState(0);
  const [clickDivision, setClickDivision] = useState(undefined);
  const [clickIndex, setClickIndex] = useState(undefined);
  const [changedIndex, setChangedIndex] = useState(undefined);
  const [emptyHeight, setEmptyHeight] = useState(0);

  const [emomTarget, setEmomTarget] = useState(undefined);
  const [emomTargetStartX, setEmomTargetStartX] = useState(0);
  const [emomTargetEndX, setEmomTargetEndX] = useState(0);
  const [emomClickIndex, setEmomClickIndex] = useState(undefined);
  const [emomChangedIndex, setEmomChangedIndex] = useState(undefined);
  const [emomEmptyWidth, setEmomEmptyWidth] = useState(0);

  // const [totalScrollY, setTotalScrollY] = useState(0);
  // const [totalScrollX, setTotalScrollX] = useState(0);
  // const [emomScrollX, setEmomScrollX] = useState(0);

  const BuyinWrapRef = useRef([]);
  const MainWrapRef = useRef([]);
  const BuyoutWrapRef = useRef([]);

  // // 전체 페이지 스크롤값 받기 //
  // useEffect(() => {
  //   const handleScroll = () => {
  //     setTotalScrollY(window.scrollY);
  //     setTotalScrollX(window.scrollX);
  //   };

  //   // 스크롤 이벤트 리스너 등록
  //   window.addEventListener('scroll', handleScroll);

  //   // 컴포넌트가 언마운트되면 이벤트 리스너 제거
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  useEffect(() => {
    if (sectionData.tag === 'ForTime-Different Reps') changeDifferentRepsData();
    else if (sectionData.tag === 'Custom') {
      // '점수 없음', '시간', '횟수', '라운드 + 횟수', '거리', '무게', '포인트', '피크와트', '최고 속도' //
      if (sectionData.scoreKeyword === 'Not Scored') sectionData.scoreKeyword = '점수 없음';
      else if (sectionData.scoreKeyword === 'Time') sectionData.scoreKeyword = '시간';
      // else if (sectionData.scoreKeyword === 'Reps') sectionData.scoreKeyword = '횟수';
      else if (sectionData.scoreKeyword === 'Reps') sectionData.scoreKeyword = '렙수';
      // else if (sectionData.scoreKeyword === 'Rounds And Reps') sectionData.scoreKeyword = '라운드 + 횟수';
      else if (sectionData.scoreKeyword === 'Rounds And Reps') sectionData.scoreKeyword = '라운드 + 렙수';
      else if (sectionData.scoreKeyword === 'Distance') sectionData.scoreKeyword = '거리';
      else if (sectionData.scoreKeyword === 'Weight') sectionData.scoreKeyword = '무게';
      else if (sectionData.scoreKeyword === 'Points') sectionData.scoreKeyword = '포인트';
      else if (sectionData.scoreKeyword === 'Peak Watts') sectionData.scoreKeyword = '피크와트';
      else if (sectionData.scoreKeyword === 'Max Speed') sectionData.scoreKeyword = '최고 속도';

      if (sectionData.scorePriority === 'heigher') sectionData.scorePriority = '높을 수록';
      else if (sectionData.scorePriority === 'lower') sectionData.scorePriority = '낮을 수록';

      refreshSectionList();
    }

    if (sectionData.buyinNote !== '') setIsBuyinNoteView(true);
    if (sectionData.mainNote !== '') setIsMainNoteView(true);
    if (sectionData.buyoutNote !== '') setIsBuyoutNoteView(true);
  }, [sectionData])

  // useEffect(() => {
  //   // EMOM인 경우에만 정리 //
  //   if (sectionData.tag !== 'EMOM') return;

  //   if (sectionData.round !== 0 && lastInputRound === 0) {
  //     setLastInputRound(sectionData.round);
  //     return;
  //   }
  //   let newEmomExerciseList = [];
  //   let newEmomScaleOptionExerciseList = [];
  //   let alreadyEmomExerciseList = sectionData.emomExerciseList.slice();
  //   let alreadyEmomScaleOptionExerciseList = sectionData.emomScaleOptionExerciseList.slice();
  //   let round = parseInt(sectionData.round);

  //   // 운동 라운드를 줄이거나 늘렸을 경우 // 
  //   if (sectionData.mainExerciseList.length * round !== alreadyEmomExerciseList.length) {
  //     // 동작을 추가한 경우 //
  //     if (sectionData.mainExerciseList.length > alreadyEmomExerciseList.length / round) {
  //       // 첫 운동 추가의 경우 //
  //       if (sectionData.mainExerciseList.length === 1) {
  //         for (let i = 0; i < round; i++) {
  //           for (let j = 0; j < sectionData.mainExerciseList.length; j++) {
  //             newEmomExerciseList.push(lodash.clone(sectionData.mainExerciseList[j]))

  //             for (let h = 0; h < sectionData.emomScaleOptionExerciseList.length; h++) {
  //               if (sectionData.emomScaleOptionExerciseList[h].dependencyIndex === j) {
  //                 let newScaleOptionExercise = lodash.clone(sectionData.emomScaleOptionExerciseList[h]);

  //                 newScaleOptionExercise.dependencyIndex = newEmomExerciseList.length - 1;
  //                 newEmomScaleOptionExerciseList.push(newScaleOptionExercise);
  //               }
  //             }
  //           }
  //         }
  //       }
  //       // 첫 운동 추가 이후 //
  //       else {
  //         // 동작이 추가된 경우 //
  //         if (lastInputRound === round) {
  //           let addCount = 0;

  //           newEmomScaleOptionExerciseList = alreadyEmomScaleOptionExerciseList

  //           for (let i = 0; i < newEmomScaleOptionExerciseList.length; i++) {
  //             if (newEmomScaleOptionExerciseList[i].dependencyIndex > sectionData.mainExerciseList.length - 1) {
  //               newEmomScaleOptionExerciseList[i].dependencyIndex += 1;
  //             }
  //           }

  //           for (let i = 0; i < sectionData.mainExerciseList.length * round; i++) {
  //             if (((i + 1) % sectionData.mainExerciseList.length === 0)) {
  //               newEmomExerciseList.push(lodash.clone(sectionData.mainExerciseList[sectionData.mainExerciseList.length - 1]));
  //               addCount += 1;
  //             }
  //             else {
  //               newEmomExerciseList.push(lodash.clone(alreadyEmomExerciseList[i - addCount]));
  //             }
  //           }
  //         }
  //         // 라운드가 증가된 경우 //
  //         else {
  //           newEmomExerciseList = lodash.clone(alreadyEmomExerciseList);
  //           newEmomScaleOptionExerciseList = lodash.clone(alreadyEmomScaleOptionExerciseList);
  //           for (let i = lastInputRound; i < round; i++) {
  //             for (let j = 0; j < sectionData.mainExerciseList.length; j++) {
  //               newEmomExerciseList.push(lodash.clone(sectionData.mainExerciseList[j]));
  //             }
  //           }

  //           for (let i = lastInputRound; i < round; i++) {
  //             for (let j = 0; j < alreadyEmomScaleOptionExerciseList.length; j++) {
  //               let newScale = lodash.clone(alreadyEmomScaleOptionExerciseList[j]);

  //               newScale.dependencyIndex += (i * sectionData.mainExerciseList.length);
  //               newEmomScaleOptionExerciseList.push(newScale);
  //             }
  //           }
  //         }
  //       }
  //     }
  //     // 동작을 삭제한 경우 //
  //     // 동작이 삭제되었거나 라운드가 감소된 경우 //
  //     else {
  //       // 라운드가 감소된 경우 //
  //       if (lastInputRound !== round) {
  //         newEmomExerciseList = lodash.clone(alreadyEmomExerciseList.slice(0, round * sectionData.mainExerciseList.length));

  //         for (let i = 0; i < alreadyEmomScaleOptionExerciseList.length; i++) {
  //           if (alreadyEmomScaleOptionExerciseList[i].dependencyIndex < alreadyEmomExerciseList.length * round) {
  //             newEmomScaleOptionExerciseList.push(alreadyEmomScaleOptionExerciseList[i]);
  //           }
  //         }
  //       }
  //       // 동작이 삭제된 경우 //
  //       else {
  //         for (let i = 0; i < alreadyEmomExerciseList.length; i++) {
  //           if (i === sectionData.mainExerciseList.length * round) break;

  //           let isExist = false;

  //           for (let j = 0; j < sectionData.mainExerciseList.length; j++) {
  //             if (alreadyEmomExerciseList[i].movementId === sectionData.mainExerciseList[j].movementId) {
  //               isExist = true;
  //               break;
  //             }
  //           }

  //           if (isExist) newEmomExerciseList.push(alreadyEmomExerciseList[i]);
  //         }

  //         if (newEmomExerciseList.length > sectionData.mainExerciseList.length * round) {
  //           newEmomExerciseList = newEmomExerciseList.slice(0, sectionData.mainExerciseList.length * round);
  //         }

  //       }
  //     }

  //     setLastInputRound(round);
  //     sectionData.emomExerciseList = newEmomExerciseList;
  //     sectionData.emomScaleOptionExerciseList = newEmomScaleOptionExerciseList;
  //   }

  //   refreshSectionList();
  // }, [lastInputRound, sectionData, sectionData.tag, sectionData.round, sectionData.mainExerciseList.length])

  useEffect(() => {
    // EMOM인 경우에만 정리 //
    if (sectionData.tag !== 'EMOM') return;

    let newRoundList = [];

    for (let i = 0; i < sectionData.round; i++) {
      newRoundList.push(i);
    }

    sectionData.roundList = newRoundList;

    refreshSectionList();
  }, [sectionData.tag, sectionData.round, sectionData.mainExerciseList.length])

  // Buyin 사용 여부 체크 //
  function onClickBuyin() {
    if (sectionData.isUseBuyin) sectionData.isUseBuyin = false;
    else sectionData.isUseBuyin = true;

    refreshSectionList();
  }

  // Buyout 사용 여부 체크 //
  function onClickBuyout() {
    if (sectionData.isUseBuyout) sectionData.isUseBuyout = false;
    else sectionData.isUseBuyout = true;

    refreshSectionList();
  }

  // Custom Exercise Title 변경시 //
  function onChangeCustomTitle(customTitle) {
    sectionData.customTitle = customTitle;

    refreshSectionList();
  }

  // Custom Exercise ScoreKeyword 변경시 //
  function onChangeScoreKeyword(scoreKeyword) {
    sectionData.scoreKeyword = scoreKeyword;

    refreshSectionList();
  }

  // Custom Exercise ScorePriority 변경시 //
  function onChangeScorePriority(scorePriority) {
    sectionData.scorePriority = scorePriority;

    refreshSectionList();
  }

  // Custom Exercise Description 변경시 //
  function onChangeDescription(description) {
    sectionData.description = description;

    refreshSectionList();
  }

  // Time Limit 사용 여부 //
  function onClickTimeLimit() {
    if (sectionData.isUseTimeLimit) sectionData.isUseTimeLimit = false;
    else sectionData.isUseTimeLimit = true;

    refreshSectionList();
  }

  // Main 운동 SINGLE인 경우 쉬는 시간 사용 여부 클릭시 //
  function onClickRest(division, index) {
    if (division === 'main') {
      if (sectionData.mainExerciseList[index].isUseRest) sectionData.mainExerciseList[index].isUseRest = false;
      else sectionData.mainExerciseList[index].isUseRest = true;
    }
    else if (division === 'main.scale') {
      if (sectionData.mainScaleOptionExerciseList[index].isUseRest) sectionData.mainScaleOptionExerciseList[index].isUseRest = false;
      else sectionData.mainScaleOptionExerciseList[index].isUseRest = true;
    }

    refreshSectionList();
  }

  // 동작 추가하기 클릭시 //
  function onClickAddMovement(division) {
    setDivision(division);
    setIsClickedMovement(true);
  }

  // EMOM 동작 추가하기 클릭시 //
  function onClickAddMovementForEMOM(division, roundIndex) {
    setRoundIndex(roundIndex);
    setDivision(division);
    setIsClickedMovement(true);
  }

  // 모달 동작 추가 완료 버튼 클릭시 //
  function successAddMovement(data) {
    if (data.movementName === '') {
      alert('추가할 운동을 선택해주세요!');
      return;
    }

    // if (sectionData.tag === 'SINGLE') {
    //   if (division === 'buyin') {
    //     data.buyinSingleIndex = sectionData.buyinExerciseList.length + 1;

    //     sectionData.buyinExerciseList.push(lodash.clone(data));
    //     sectionData.buyinExerciseList.push(lodash.clone(data));
    //     sectionData.buyinExerciseList.push(lodash.clone(data));
    //   }
    //   else if (division === 'main') {
    //     data.mainSingleIndex = sectionData.mainExerciseList.length + 1;

    //     sectionData.mainExerciseList.push(lodash.clone(data));
    //     sectionData.mainExerciseList.push(lodash.clone(data));
    //     sectionData.mainExerciseList.push(lodash.clone(data));
    //   }
    //   else if (division === 'buyout') {
    //     data.buyoutSingleIndex = sectionData.buyoutExerciseList.length + 1;

    //     sectionData.buyoutExerciseList.push(lodash.clone(data));
    //     sectionData.buyoutExerciseList.push(lodash.clone(data));
    //     sectionData.buyoutExerciseList.push(lodash.clone(data));
    //   }
    // }
    // else {
    if (division === 'buyin') sectionData.buyinExerciseList.push(data);
    else if (division === 'main') {
      if (sectionData.tag === 'SINGLE') {
        data.mainSingleIndex = sectionData.mainExerciseList.length + 1;

        sectionData.mainExerciseList.push(lodash.clone(data));
        sectionData.mainExerciseList.push(lodash.clone(data));
        sectionData.mainExerciseList.push(lodash.clone(data));
      }
      else if (sectionData.tag === 'EMOM') {
        data.emomRoundIndex = roundIndex;

        sectionData.mainExerciseList.push(data);
        sectionData.emomExerciseList.push(data);

        setRoundIndex(undefined);
      }
      else sectionData.mainExerciseList.push(data);
    }
    else if (division === 'buyout') sectionData.buyoutExerciseList.push(data);
    // }

    refreshSectionList();
    setIsClickedMovement(false);
  }

  // 무게, 길이, 높이, 열량, 횟수, 시간, 템포 데이터 변경시 //
  function onChangeData(index, division, column, value) {
    if (sectionData.tag === 'SINGLE') onChangeDataForSingle(index, division, column, value);
    else {
      if (division === 'buyin') {
        if (column === 'weight') sectionData.buyinExerciseList[index].weight = value;
        else if (column === 'distance') sectionData.buyinExerciseList[index].distance = value;
        else if (column === 'height') sectionData.buyinExerciseList[index].height = value;
        else if (column === 'calories') sectionData.buyinExerciseList[index].calories = value;
        else if (column === 'count') sectionData.buyinExerciseList[index].count = value;
        else if (column === 'tempo') sectionData.buyinExerciseList[index].tempo = value;
        else if (column === 'minute') {
          // 1.3.14부터 삭제 가능 //
          sectionData.buyinExerciseList[index].time = (parseInt(value) * 60) + parseInt(sectionData.buyinExerciseList[index].second);
          // 1.3.14부터 삭제 가능 //
          sectionData.buyinExerciseList[index].minute = value;
        }
        else if (column === 'second') {
          sectionData.buyinExerciseList[index].time = (parseInt(sectionData.buyinExerciseList[index].minute) * 60) + parseInt(value);
          sectionData.buyinExerciseList[index].second = value;
        }
      }
      else if (division === 'buyin.scale') {
        if (column === 'weight') sectionData.buyinScaleOptionExerciseList[index].weight = value;
        else if (column === 'distance') sectionData.buyinScaleOptionExerciseList[index].distance = value;
        else if (column === 'height') sectionData.buyinScaleOptionExerciseList[index].height = value;
        else if (column === 'calories') sectionData.buyinScaleOptionExerciseList[index].calories = value;
        else if (column === 'count') sectionData.buyinScaleOptionExerciseList[index].count = value;
        else if (column === 'tempo') sectionData.buyinScaleOptionExerciseList[index].tempo = value;
        else if (column === 'minute') {
          sectionData.buyinScaleOptionExerciseList[index].time = (parseInt(value) * 60) + parseInt(sectionData.buyinScaleOptionExerciseList[index].second);
          sectionData.buyinScaleOptionExerciseList[index].minute = value;
        }
        else if (column === 'second') {
          sectionData.buyinScaleOptionExerciseList[index].time = (parseInt(sectionData.buyinScaleOptionExerciseList[index].minute) * 60) + parseInt(value);
          sectionData.buyinScaleOptionExerciseList[index].second = value;
        }
      }
      else if (division === 'main') {
        if (column === 'weight') sectionData.mainExerciseList[index].weight = value;
        else if (column === 'distance') sectionData.mainExerciseList[index].distance = value;
        else if (column === 'height') sectionData.mainExerciseList[index].height = value;
        else if (column === 'calories') sectionData.mainExerciseList[index].calories = value;
        else if (column === 'count') sectionData.mainExerciseList[index].count = value;
        else if (column === 'tempo') sectionData.mainExerciseList[index].tempo = value;
        else if (column === 'sets') sectionData.mainExerciseList[index].sets = value;
        else if (column === 'rest') sectionData.mainExerciseList[index].rest = value;
        else if (column === 'minute') {
          sectionData.mainExerciseList[index].time = (parseInt(value) * 60) + parseInt(sectionData.mainExerciseList[index].second);
          sectionData.mainExerciseList[index].minute = value;
        }
        else if (column === 'second') {
          sectionData.mainExerciseList[index].time = (parseInt(sectionData.mainExerciseList[index].minute) * 60) + parseInt(value);
          sectionData.mainExerciseList[index].second = value;
        }
      }
      else if (division === 'main.scale') {
        if (column === 'weight') sectionData.mainScaleOptionExerciseList[index].weight = value;
        else if (column === 'distance') sectionData.mainScaleOptionExerciseList[index].distance = value;
        else if (column === 'height') sectionData.mainScaleOptionExerciseList[index].height = value;
        else if (column === 'calories') sectionData.mainScaleOptionExerciseList[index].calories = value;
        else if (column === 'count') sectionData.mainScaleOptionExerciseList[index].count = value;
        else if (column === 'tempo') sectionData.mainScaleOptionExerciseList[index].tempo = value;
        else if (column === 'sets') sectionData.mainScaleOptionExerciseList[index].sets = value;
        else if (column === 'rest') sectionData.mainScaleOptionExerciseList[index].rest = value;
        else if (column === 'minute') {
          sectionData.mainScaleOptionExerciseList[index].time = (parseInt(value) * 60) + parseInt(sectionData.mainScaleOptionExerciseList[index].second);
          sectionData.mainScaleOptionExerciseList[index].minute = value;
        }
        else if (column === 'second') {
          sectionData.mainScaleOptionExerciseList[index].time = (parseInt(sectionData.mainScaleOptionExerciseList[index].minute) * 60) + parseInt(value);
          sectionData.mainScaleOptionExerciseList[index].second = value;
        }
      }
      else if (division === 'main.emom') {
        if (column === 'weight') sectionData.emomExerciseList[index].weight = value;
        else if (column === 'distance') sectionData.emomExerciseList[index].distance = value;
        else if (column === 'height') sectionData.emomExerciseList[index].height = value;
        else if (column === 'calories') sectionData.emomExerciseList[index].calories = value;
        else if (column === 'count') sectionData.emomExerciseList[index].count = value;
        else if (column === 'tempo') sectionData.emomExerciseList[index].tempo = value;
        else if (column === 'sets') sectionData.emomExerciseList[index].sets = value;
        else if (column === 'rest') sectionData.emomExerciseList[index].rest = value;
        else if (column === 'minute') {
          sectionData.emomExerciseList[index].time = (parseInt(value) * 60) + parseInt(sectionData.emomExerciseList[index].second);
          sectionData.emomExerciseList[index].minute = value;
        }
        else if (column === 'second') {
          sectionData.emomExerciseList[index].time = (parseInt(sectionData.emomExerciseList[index].minute) * 60) + parseInt(value);
          sectionData.emomExerciseList[index].second = value;
        }

        for (let i = 0; i < sectionData.mainExerciseList.length; i++) {
          sectionData.mainExerciseList[i] = sectionData.emomExerciseList[i];
        }
      }
      else if (division === 'main.emom.scale') {
        if (column === 'weight') sectionData.emomScaleOptionExerciseList[index].weight = value;
        else if (column === 'distance') sectionData.emomScaleOptionExerciseList[index].distance = value;
        else if (column === 'height') sectionData.emomScaleOptionExerciseList[index].height = value;
        else if (column === 'calories') sectionData.emomScaleOptionExerciseList[index].calories = value;
        else if (column === 'count') sectionData.emomScaleOptionExerciseList[index].count = value;
        else if (column === 'tempo') sectionData.emomScaleOptionExerciseList[index].tempo = value;
        else if (column === 'sets') sectionData.emomScaleOptionExerciseList[index].sets = value;
        else if (column === 'rest') sectionData.emomScaleOptionExerciseList[index].rest = value;
        else if (column === 'minute') {
          sectionData.emomScaleOptionExerciseList[index].time = (parseInt(value) * 60) + parseInt(sectionData.emomScaleOptionExerciseList[index].second);
          sectionData.emomScaleOptionExerciseList[index].minute = value;
        }
        else if (column === 'second') {
          sectionData.emomScaleOptionExerciseList[index].time = (parseInt(sectionData.emomScaleOptionExerciseList[index].minute) * 60) + parseInt(value);
          sectionData.emomScaleOptionExerciseList[index].second = value;
        }
      }
      else if (division === 'buyout') {
        if (column === 'weight') sectionData.buyoutExerciseList[index].weight = value;
        else if (column === 'distance') sectionData.buyoutExerciseList[index].distance = value;
        else if (column === 'height') sectionData.buyoutExerciseList[index].height = value;
        else if (column === 'calories') sectionData.buyoutExerciseList[index].calories = value;
        else if (column === 'count') sectionData.buyoutExerciseList[index].count = value;
        else if (column === 'tempo') sectionData.buyoutExerciseList[index].tempo = value;
        else if (column === 'minute') {
          sectionData.buyoutExerciseList[index].time = (parseInt(value) * 60) + parseInt(sectionData.buyoutExerciseList[index].second);
          sectionData.buyoutExerciseList[index].minute = value;
        }
        else if (column === 'second') {
          sectionData.buyoutExerciseList[index].time = (parseInt(sectionData.buyoutExerciseList[index].minute) * 60) + parseInt(value);
          sectionData.buyoutExerciseList[index].second = value;
        }
      }
      else if (division === 'buyout.scale') {
        if (column === 'weight') sectionData.buyoutScaleOptionExerciseList[index].weight = value;
        else if (column === 'distance') sectionData.buyoutScaleOptionExerciseList[index].distance = value;
        else if (column === 'height') sectionData.buyoutScaleOptionExerciseList[index].height = value;
        else if (column === 'calories') sectionData.buyoutScaleOptionExerciseList[index].calories = value;
        else if (column === 'count') sectionData.buyoutScaleOptionExerciseList[index].count = value;
        else if (column === 'tempo') sectionData.buyoutScaleOptionExerciseList[index].tempo = value;
        else if (column === 'minute') {
          sectionData.buyoutScaleOptionExerciseList[index].time = (parseInt(value) * 60) + parseInt(sectionData.buyoutScaleOptionExerciseList[index].second);
          sectionData.buyoutScaleOptionExerciseList[index].minute = value;
        }
        else if (column === 'second') {
          sectionData.buyoutScaleOptionExerciseList[index].time = (parseInt(sectionData.buyoutScaleOptionExerciseList[index].minute) * 60) + parseInt(value);
          sectionData.buyoutScaleOptionExerciseList[index].second = value;
        }
      }

      refreshSectionList();
    }
  }

  // 무게, 길이, 높이, 열량, 횟수, 시간, 템포 데이터 변경시 //
  function onChangeDataForSingle(index, division, column, value) {
    if (division === 'buyin') {
      if (column === 'weight') sectionData.buyinExerciseList[index].weight = value;
      else if (column === 'distance') sectionData.buyinExerciseList[index].distance = value;
      else if (column === 'height') sectionData.buyinExerciseList[index].height = value;
      else if (column === 'calories') sectionData.buyinExerciseList[index].calories = value;
      else if (column === 'count') sectionData.buyinExerciseList[index].count = value;
      else if (column === 'tempo') sectionData.buyinExerciseList[index].tempo = value;
      else if (column === 'minute') {
        // 1.3.14부터 삭제 가능 //
        sectionData.buyinExerciseList[index].time = (parseInt(value) * 60) + parseInt(sectionData.buyinExerciseList[index].second);
        // 1.3.14부터 삭제 가능 //
        sectionData.buyinExerciseList[index].minute = value;
      }
      else if (column === 'second') {
        sectionData.buyinExerciseList[index].time = (parseInt(sectionData.buyinExerciseList[index].minute) * 60) + parseInt(value);
        sectionData.buyinExerciseList[index].second = value;
      }
    }
    else if (division === 'buyin.scale') {
      if (column === 'weight') sectionData.buyinScaleOptionExerciseList[index].weight = value;
      else if (column === 'distance') sectionData.buyinScaleOptionExerciseList[index].distance = value;
      else if (column === 'height') sectionData.buyinScaleOptionExerciseList[index].height = value;
      else if (column === 'calories') sectionData.buyinScaleOptionExerciseList[index].calories = value;
      else if (column === 'count') sectionData.buyinScaleOptionExerciseList[index].count = value;
      else if (column === 'tempo') sectionData.buyinScaleOptionExerciseList[index].tempo = value;
      else if (column === 'minute') {
        sectionData.buyinScaleOptionExerciseList[index].time = (parseInt(value) * 60) + parseInt(sectionData.buyinScaleOptionExerciseList[index].second);
        sectionData.buyinScaleOptionExerciseList[index].minute = value;
      }
      else if (column === 'second') {
        sectionData.buyinScaleOptionExerciseList[index].time = (parseInt(sectionData.buyinScaleOptionExerciseList[index].minute) * 60) + parseInt(value);
        sectionData.buyinScaleOptionExerciseList[index].second = value;
      }
    }
    else if (division === 'main') {
      let singleIndex = sectionData.mainExerciseList[index].mainSingleIndex;

      if (column === 'weight') {
        let originalWeight = sectionData.mainExerciseList[index].weight;

        for (let i = index; i < sectionData.mainExerciseList.length; i++) {
          if (sectionData.mainExerciseList[i].mainSingleIndex === singleIndex
            && sectionData.mainExerciseList[i].weight === originalWeight) {
            sectionData.mainExerciseList[i].weight = value;
          }
        }
      }
      else if (column === 'distance') {
        let originalDistance = sectionData.mainExerciseList[index].distance;

        for (let i = index; i < sectionData.mainExerciseList.length; i++) {
          if (sectionData.mainExerciseList[i].mainSingleIndex === singleIndex
            && sectionData.mainExerciseList[i].distance === originalDistance) {
            sectionData.mainExerciseList[i].distance = value;
          }
        }
      }
      else if (column === 'height') {
        let originalHeight = sectionData.mainExerciseList[index].distance;

        for (let i = index; i < sectionData.mainExerciseList.length; i++) {
          if (sectionData.mainExerciseList[i].mainSingleIndex === singleIndex
            && sectionData.mainExerciseList[i].height === originalHeight) {
            sectionData.mainExerciseList[i].height = value;
          }
        }
      }
      else if (column === 'calories') {
        let originalCalories = sectionData.mainExerciseList[index].calories;

        for (let i = index; i < sectionData.mainExerciseList.length; i++) {
          if (sectionData.mainExerciseList[i].mainSingleIndex === singleIndex
            && sectionData.mainExerciseList[i].calories === originalCalories) {
            sectionData.mainExerciseList[i].calories = value;
          }
        }
      }
      else if (column === 'count') {
        let originalCount = sectionData.mainExerciseList[index].count;

        for (let i = index; i < sectionData.mainExerciseList.length; i++) {
          if (sectionData.mainExerciseList[i].mainSingleIndex === singleIndex
            && sectionData.mainExerciseList[i].count === originalCount) {
            sectionData.mainExerciseList[i].count = value;
          }
        }
      }
      else if (column === 'tempo') {
        let originalTempo = sectionData.mainExerciseList[index].tempo;

        for (let i = index; i < sectionData.mainExerciseList.length; i++) {
          if (sectionData.mainExerciseList[i].mainSingleIndex === singleIndex
            && sectionData.mainExerciseList[i].tempo === originalTempo) {
            sectionData.mainExerciseList[i].tempo = value;
          }
        }
      }
      else if (column === 'sets') {
        let originalSets = sectionData.mainExerciseList[index].sets;

        for (let i = index; i < sectionData.mainExerciseList.length; i++) {
          if (sectionData.mainExerciseList[i].mainSingleIndex === singleIndex
            && sectionData.mainExerciseList[i].sets === originalSets) {
            sectionData.mainExerciseList[i].sets = value;
          }
        }
      }
      else if (column === 'rest') {
        let originalRest = sectionData.mainExerciseList[index].rest;

        for (let i = index; i < sectionData.mainExerciseList.length; i++) {
          if (sectionData.mainExerciseList[i].mainSingleIndex === singleIndex
            && sectionData.mainExerciseList[i].rest === originalRest) {
            sectionData.mainExerciseList[i].rest = value;
          }
        }
      }
      else if (column === 'minute') {
        let originalMinute = sectionData.mainExerciseList[index].minute;
        let originalSecond = sectionData.mainExerciseList[index].second;

        for (let i = index; i < sectionData.mainExerciseList.length; i++) {
          if (sectionData.mainExerciseList[i].mainSingleIndex === singleIndex
            && sectionData.mainExerciseList[i].minute === originalMinute
            && sectionData.mainExerciseList[i].second === originalSecond) {
            sectionData.mainExerciseList[i].time = (parseInt(value) * 60) + parseInt(sectionData.mainExerciseList[i].second);
            sectionData.mainExerciseList[i].minute = value;
          }
        }
      }
      else if (column === 'second') {
        let originalMinute = sectionData.mainExerciseList[index].minute;
        let originalSecond = sectionData.mainExerciseList[index].second;

        for (let i = index; i < sectionData.mainExerciseList.length; i++) {
          if (sectionData.mainExerciseList[i].mainSingleIndex === singleIndex
            && sectionData.mainExerciseList[i].minute === originalMinute
            && sectionData.mainExerciseList[i].second === originalSecond) {
            sectionData.mainExerciseList[i].time = (parseInt(sectionData.mainExerciseList[i].minute) * 60) + parseInt(value);
            sectionData.mainExerciseList[i].second = value;
          }
        }
      }
    }
    else if (division === 'main.scale') {
      if (column === 'weight') sectionData.mainScaleOptionExerciseList[index].weight = value;
      else if (column === 'distance') sectionData.mainScaleOptionExerciseList[index].distance = value;
      else if (column === 'height') sectionData.mainScaleOptionExerciseList[index].height = value;
      else if (column === 'calories') sectionData.mainScaleOptionExerciseList[index].calories = value;
      else if (column === 'count') sectionData.mainScaleOptionExerciseList[index].count = value;
      else if (column === 'tempo') sectionData.mainScaleOptionExerciseList[index].tempo = value;
      else if (column === 'sets') sectionData.mainScaleOptionExerciseList[index].sets = value;
      else if (column === 'rest') sectionData.mainScaleOptionExerciseList[index].rest = value;
      else if (column === 'minute') {
        sectionData.mainScaleOptionExerciseList[index].time = (parseInt(value) * 60) + parseInt(sectionData.mainScaleOptionExerciseList[index].second);
        sectionData.mainScaleOptionExerciseList[index].minute = value;
      }
      else if (column === 'second') {
        sectionData.mainScaleOptionExerciseList[index].time = (parseInt(sectionData.mainScaleOptionExerciseList[index].minute) * 60) + parseInt(value);
        sectionData.mainScaleOptionExerciseList[index].second = value;
      }
    }
    else if (division === 'main.emom') {
      if (column === 'weight') sectionData.emomExerciseList[index].weight = value;
      else if (column === 'distance') sectionData.emomExerciseList[index].distance = value;
      else if (column === 'height') sectionData.emomExerciseList[index].height = value;
      else if (column === 'calories') sectionData.emomExerciseList[index].calories = value;
      else if (column === 'count') sectionData.emomExerciseList[index].count = value;
      else if (column === 'tempo') sectionData.emomExerciseList[index].tempo = value;
      else if (column === 'sets') sectionData.emomExerciseList[index].sets = value;
      else if (column === 'rest') sectionData.emomExerciseList[index].rest = value;
      else if (column === 'minute') {
        sectionData.emomExerciseList[index].time = (parseInt(value) * 60) + parseInt(sectionData.emomExerciseList[index].second);
        sectionData.emomExerciseList[index].minute = value;
      }
      else if (column === 'second') {
        sectionData.emomExerciseList[index].time = (parseInt(sectionData.emomExerciseList[index].minute) * 60) + parseInt(value);
        sectionData.emomExerciseList[index].second = value;
      }

      for (let i = 0; i < sectionData.mainExerciseList.length; i++) {
        sectionData.mainExerciseList[i] = sectionData.emomExerciseList[i];
      }
    }
    else if (division === 'main.emom.scale') {
      if (column === 'weight') sectionData.emomScaleOptionExerciseList[index].weight = value;
      else if (column === 'distance') sectionData.emomScaleOptionExerciseList[index].distance = value;
      else if (column === 'height') sectionData.emomScaleOptionExerciseList[index].height = value;
      else if (column === 'calories') sectionData.emomScaleOptionExerciseList[index].calories = value;
      else if (column === 'count') sectionData.emomScaleOptionExerciseList[index].count = value;
      else if (column === 'tempo') sectionData.emomScaleOptionExerciseList[index].tempo = value;
      else if (column === 'sets') sectionData.emomScaleOptionExerciseList[index].sets = value;
      else if (column === 'rest') sectionData.emomScaleOptionExerciseList[index].rest = value;
      else if (column === 'minute') {
        sectionData.emomScaleOptionExerciseList[index].time = (parseInt(value) * 60) + parseInt(sectionData.emomScaleOptionExerciseList[index].second);
        sectionData.emomScaleOptionExerciseList[index].minute = value;
      }
      else if (column === 'second') {
        sectionData.emomScaleOptionExerciseList[index].time = (parseInt(sectionData.emomScaleOptionExerciseList[index].minute) * 60) + parseInt(value);
        sectionData.emomScaleOptionExerciseList[index].second = value;
      }
    }
    else if (division === 'buyout') {
      if (column === 'weight') sectionData.buyoutExerciseList[index].weight = value;
      else if (column === 'distance') sectionData.buyoutExerciseList[index].distance = value;
      else if (column === 'height') sectionData.buyoutExerciseList[index].height = value;
      else if (column === 'calories') sectionData.buyoutExerciseList[index].calories = value;
      else if (column === 'count') sectionData.buyoutExerciseList[index].count = value;
      else if (column === 'tempo') sectionData.buyoutExerciseList[index].tempo = value;
      else if (column === 'minute') {
        sectionData.buyoutExerciseList[index].time = (parseInt(value) * 60) + parseInt(sectionData.buyoutExerciseList[index].second);
        sectionData.buyoutExerciseList[index].minute = value;
      }
      else if (column === 'second') {
        sectionData.buyoutExerciseList[index].time = (parseInt(sectionData.buyoutExerciseList[index].minute) * 60) + parseInt(value);
        sectionData.buyoutExerciseList[index].second = value;
      }
    }
    else if (division === 'buyout.scale') {
      if (column === 'weight') sectionData.buyoutScaleOptionExerciseList[index].weight = value;
      else if (column === 'distance') sectionData.buyoutScaleOptionExerciseList[index].distance = value;
      else if (column === 'height') sectionData.buyoutScaleOptionExerciseList[index].height = value;
      else if (column === 'calories') sectionData.buyoutScaleOptionExerciseList[index].calories = value;
      else if (column === 'count') sectionData.buyoutScaleOptionExerciseList[index].count = value;
      else if (column === 'tempo') sectionData.buyoutScaleOptionExerciseList[index].tempo = value;
      else if (column === 'minute') {
        sectionData.buyoutScaleOptionExerciseList[index].time = (parseInt(value) * 60) + parseInt(sectionData.buyoutScaleOptionExerciseList[index].second);
        sectionData.buyoutScaleOptionExerciseList[index].minute = value;
      }
      else if (column === 'second') {
        sectionData.buyoutScaleOptionExerciseList[index].time = (parseInt(sectionData.buyoutScaleOptionExerciseList[index].minute) * 60) + parseInt(value);
        sectionData.buyoutScaleOptionExerciseList[index].second = value;
      }
    }

    refreshSectionList();
  }

  // 무게, 길이, 높이, 열량 데이터 단위 변경시 //
  function onChangeUnit(index, division, column, value) {
    if (division === 'buyin') {
      if (column === 'weight') sectionData.buyinExerciseList[index].weightUnit = value;
      else if (column === 'distance') sectionData.buyinExerciseList[index].distanceUnit = value;
      else if (column === 'height') sectionData.buyinExerciseList[index].heightUnit = value;
      else if (column === 'calories') sectionData.buyinExerciseList[index].caloriesUnit = value;
    }
    else if (division === 'buyin.scale') {
      if (column === 'weight') sectionData.buyinScaleOptionExerciseList[index].weightUnit = value;
      else if (column === 'distance') sectionData.buyinScaleOptionExerciseList[index].distanceUnit = value;
      else if (column === 'height') sectionData.buyinScaleOptionExerciseList[index].heightUnit = value;
      else if (column === 'calories') sectionData.buyinScaleOptionExerciseList[index].caloriesUnit = value;
    }
    else if (division === 'main') {
      if (column === 'weight') sectionData.mainExerciseList[index].weightUnit = value;
      else if (column === 'distance') sectionData.mainExerciseList[index].distanceUnit = value;
      else if (column === 'height') sectionData.mainExerciseList[index].heightUnit = value;
      else if (column === 'calories') sectionData.mainExerciseList[index].caloriesUnit = value;
    }
    else if (division === 'main.scale') {
      if (column === 'weight') sectionData.mainScaleOptionExerciseList[index].weightUnit = value;
      else if (column === 'distance') sectionData.mainScaleOptionExerciseList[index].distanceUnit = value;
      else if (column === 'height') sectionData.mainScaleOptionExerciseList[index].heightUnit = value;
      else if (column === 'calories') sectionData.mainScaleOptionExerciseList[index].caloriesUnit = value;
    }
    else if (division === 'main.emom') {
      if (column === 'weight') sectionData.emomExerciseList[index].weightUnit = value;
      else if (column === 'distance') sectionData.emomExerciseList[index].distanceUnit = value;
      else if (column === 'height') sectionData.emomExerciseList[index].heightUnit = value;
      else if (column === 'calories') sectionData.emomExerciseList[index].caloriesUnit = value;

      for (let i = 0; i < sectionData.mainExerciseList.length; i++) {
        sectionData.mainExerciseList[i] = sectionData.emomExerciseList[i];
      }
    }
    else if (division === 'main.emom.scale') {
      if (column === 'weight') sectionData.emomScaleOptionExerciseList[index].weightUnit = value;
      else if (column === 'distance') sectionData.emomScaleOptionExerciseList[index].distanceUnit = value;
      else if (column === 'height') sectionData.emomScaleOptionExerciseList[index].heightUnit = value;
      else if (column === 'calories') sectionData.emomScaleOptionExerciseList[index].caloriesUnit = value;
    }
    else if (division === 'buyout') {
      if (column === 'weight') sectionData.buyoutExerciseList[index].weightUnit = value;
      else if (column === 'distance') sectionData.buyoutExerciseList[index].distanceUnit = value;
      else if (column === 'height') sectionData.buyoutExerciseList[index].heightUnit = value;
      else if (column === 'calories') sectionData.buyoutExerciseList[index].caloriesUnit = value;
    }
    else if (division === 'buyout.scale') {
      if (column === 'weight') sectionData.buyoutScaleOptionExerciseList[index].weightUnit = value;
      else if (column === 'distance') sectionData.buyoutScaleOptionExerciseList[index].distanceUnit = value;
      else if (column === 'height') sectionData.buyoutScaleOptionExerciseList[index].heightUnit = value;
      else if (column === 'calories') sectionData.buyoutScaleOptionExerciseList[index].caloriesUnit = value;
    }

    refreshSectionList();
  }

  // 시간 제한 변경시 //
  function onChangeTimeLimit(division, value) {
    if (division === 'Minute') {
      let second = parseInt(sectionData.timeLimit % 60);

      sectionData.timeLimit = parseInt((value * 60) + second);
    }
    else if (division === 'Second') {
      let minute = sectionData.timeLimit - parseInt(sectionData.timeLimit % 60);

      sectionData.timeLimit = parseInt(minute) + parseInt(value);
    }

    refreshSectionList();
  }

  // 동작 옵션 모달 열기 클릭시 //
  function onClickMovementOptionModal(division, index) {
    if (division === 'buyin') setMovementOptionData(sectionData.buyinExerciseList[index]);
    else if (division === 'buyin.scale') setMovementOptionData(sectionData.buyinScaleOptionExerciseList[index]);
    else if (division === 'main') setMovementOptionData(sectionData.mainExerciseList[index]);
    else if (division === 'main.scale') setMovementOptionData(sectionData.mainScaleOptionExerciseList[index]);
    else if (division === 'main.emom') setMovementOptionData(sectionData.emomExerciseList[index]);
    else if (division === 'main.emom.scale') setMovementOptionData(sectionData.emomScaleOptionExerciseList[index]);
    else if (division === 'buyout') setMovementOptionData(sectionData.buyoutExerciseList[index]);
    else if (division === 'buyout.scale') setMovementOptionData(sectionData.buyoutScaleOptionExerciseList[index]);

    setIsClickedMovementOptions(true);
  }

  // 동작 영상 모달 열기 클릭시 //
  function onClickMovementVideoViewModal(division, index) {
    // if (division === 'buyin') setVideoLink(sectionData.buyinExerciseList[index].videoLinkId);
    // else if (division === 'buyin.scale') setVideoLink(sectionData.buyinScaleOptionExerciseList[index].videoLinkId);
    // else if (division === 'main') setVideoLink(sectionData.mainExerciseList[index].videoLinkId);
    // else if (division === 'main.scale') setVideoLink(sectionData.mainScaleOptionExerciseList[index].videoLinkId);
    // else if (division === 'main.emom') setVideoLink(sectionData.emomExerciseList[index].videoLinkId);
    // else if (division === 'main.emom.scale') setVideoLink(sectionData.emomScaleOptionExerciseList[index].videoLinkId);
    // else if (division === 'buyout') setVideoLink(sectionData.buyoutExerciseList[index].videoLinkId);
    // else if (division === 'buyout.scale') setVideoLink(sectionData.buyoutScaleOptionExerciseList[index].videoLinkId);

    // setIsClickedVideoView(true);

    if (division === 'buyin') {
      if (sectionData.buyinExerciseList[index].customVideoLinkId === undefined) setVideoLink(sectionData.buyinExerciseList[index].videoLinkId);
      else setVideoLink(sectionData.buyinExerciseList[index].customVideoLinkId);
    }
    else if (division === 'buyin.scale') {
      if (sectionData.buyinScaleOptionExerciseList[index].customVideoLinkId === undefined) setVideoLink(sectionData.buyinScaleOptionExerciseList[index].videoLinkId);
      else setVideoLink(sectionData.buyinScaleOptionExerciseList[index].customideoLinkId)
    }
    else if (division === 'main') {
      if (sectionData.mainExerciseList[index].customVideoLinkId === undefined) setVideoLink(sectionData.mainExerciseList[index].videoLinkId);
      else setVideoLink(sectionData.mainExerciseList[index].customVideoLinkId);
    }
    else if (division === 'main.scale') {
      if (sectionData.mainScaleOptionExerciseList[index].customVideoLinkId === undefined) setVideoLink(sectionData.mainScaleOptionExerciseList[index].videoLinkId);
      else setVideoLink(sectionData.mainScaleOptionExerciseList[index].customVideoLinkId);
    }
    else if (division === 'main.emom') {
      if (sectionData.emomExerciseList[index].customVideoLinkId === undefined) setVideoLink(sectionData.emomExerciseList[index].videoLinkId);
      else setVideoLink(sectionData.emomExerciseList[index].videoLinkId);
    }
    else if (division === 'main.emom.scale') {
      if (sectionData.emomScaleOptionExerciseList[index].customVideoLinkId === undefined) setVideoLink(sectionData.emomScaleOptionExerciseList[index].videoLinkId);
      else setVideoLink(sectionData.emomScaleOptionExerciseList[index].customVideoLinkId);
    }
    else if (division === 'buyout') {
      if (sectionData.emomScaleOptionExerciseList[index].customVideoLinkId === undefined) setVideoLink(sectionData.buyoutExerciseList[index].videoLinkId);
      else setVideoLink(sectionData.buyoutExerciseList[index].customVideoLinkId);
    }
    else if (division === 'buyout.scale') {
      if (sectionData.buyoutScaleOptionExerciseList[index].customVideoLinkId === undefined) setVideoLink(sectionData.buyoutScaleOptionExerciseList[index].videoLinkId);
      else setVideoLink(sectionData.buyoutScaleOptionExerciseList[index].customVideoLinkId);
    }

    setIsClickedVideoView(true);
  }

  // 동작 삭제 버튼 클릭시 //
  function onClickDeleteMovement(division, index) {
    if (division === 'buyin') sectionData.buyinExerciseList.splice(index, 1);
    else if (division === 'buyin.scale') sectionData.buyinScaleOptionExerciseList.splice(index, 1);
    else if (division === 'main') sectionData.mainExerciseList.splice(index, 1);
    else if (division === 'main.scale') sectionData.mainScaleOptionExerciseList.splice(index, 1);
    else if (division === 'main.emom') {
      // let round = parseInt(sectionData.round);

      // for (let i = 0; i < round; i++) {
      //   for (let j = 0; j < sectionData.emomScaleOptionExerciseList.length; j++) {
      //     if (round === 0) {
      //       if (sectionData.emomScaleOptionExerciseList[j].dependencyIndex === index) {
      //         sectionData.emomScaleOptionExerciseList.splice(j, 1);
      //       }
      //     }
      //     else {
      //       if (sectionData.emomScaleOptionExerciseList[j].dependencyIndex === (round * index)) {
      //         sectionData.emomScaleOptionExerciseList.splice(j, 1);
      //       }
      //     }
      //   }
      // }

      // sectionData.mainExerciseList.splice(index, 1);
      sectionData.emomExerciseList.splice(index, 1);
    }
    else if (division === 'main.emom.scale') {
      // console.log(sectionData.emomScaleOptionExerciseList);
      // console.log(sectionData)
      sectionData.emomScaleOptionExerciseList.splice(index, 1);

      // let movementId = sectionData.emomScaleOptionExerciseList[index].movementId;
      // let indexList = [];

      // for (let i = 0; i < sectionData.emomScaleOptionExerciseList.length; i++) {
      //   if (sectionData.emomScaleOptionExerciseList)
      // }

    }
    else if (division === 'buyout') sectionData.buyoutExerciseList.splice(index, 1);
    else if (division === 'buyout.scale') sectionData.buyoutScaleOptionExerciseList.splice(index, 1);

    refreshSectionList();
  }

  // 메인운동 Tag 변경시 //
  function onChangeSectionMainTag(data) {
    if (data === 'ForTime') sectionData.tag = data + '-Rounds';
    else if (data === 'Rounds') sectionData.tag = sectionData.tag.split('-')[0] + '-Rounds';
    else if (data === 'Different Reps') {
      sectionData.tag = sectionData.tag.split('-')[0] + '-Different Reps';

      if (sectionData.reps.length === 1 && sectionData.reps[0] === 0) {
        let reps = [0];
        reps.push(0);

        sectionData.reps = reps;
      }
    }
    else if (data === 'AMRAP') {
      sectionData.isUseBuyout = false;
      sectionData.emomExerciseList = [];
      sectionData.buyoutExerciseList = [];
      sectionData.tag = data;
    }
    else if (data === 'TABATA' || data === 'SINGLE') {
      sectionData.isUseBuyin = false;
      sectionData.isUseBuyout = false;
      sectionData.emomExerciseList = [];
      sectionData.buyinExerciseList = [];
      sectionData.buyoutExerciseList = [];
      sectionData.tag = data;
    }
    else if (data === 'EMOM') {
      sectionData.isUseBuyin = false;
      sectionData.isUseBuyout = false;
      sectionData.emomExerciseList = [];
      sectionData.buyinExerciseList = [];
      sectionData.buyoutExerciseList = [];
      sectionData.tag = data;
    }
    else {
      sectionData.isUseBuyin = false;
      sectionData.isUseBuyout = false;
      sectionData.emomExerciseList = [];
      sectionData.mainExerciseList = [];
      sectionData.buyinExerciseList = [];
      sectionData.buyoutExerciseList = [];
      sectionData.tag = data;
    }

    refreshSectionList();
  }

  // 메인운동 Round 변경시 //
  function onChangeSectionMainRound(data) {
    sectionData.round = data;

    refreshSectionList();
  }

  // 메인운동 ( ForTime - Different Reps ) Reps 추가 버튼 클릭시 //
  function onClickAddReps() {
    sectionData.reps.push(0);

    changeDifferentRepsData()
    refreshSectionList();
  }

  // 메인운동 ( ForTime - Different Reps ) Reps 삭제 버튼 클릭시 //
  function onClickDeleteReps() {
    sectionData.reps.splice(sectionData.reps.length - 1, 1);

    changeDifferentRepsData()
    refreshSectionList();
  }

  // 메인운동 ( ForTime - Different Reps ) Reps 변경시 //
  function onChangeRepsData(index, value) {
    sectionData.reps[index] = value;

    changeDifferentRepsData();
    refreshSectionList();
  }

  // DifferentReps Data Change //
  function changeDifferentRepsData() {

    let newReps = '';

    if (sectionData.reps.length <= 6) {
      for (var i = 0; i < sectionData.reps.length; i++) {
        if (i !== 0) newReps += ' - ';
        newReps += parseInt(sectionData.reps[i]);
      }
    }
    else {
      newReps += parseInt(sectionData.reps[0]) + ' - '
        + parseInt(sectionData.reps[1]) + ' - '
        + parseInt(sectionData.reps[2]) + ' - '
        + parseInt(sectionData.reps[3]) + ' - ... - '
        + parseInt(sectionData.reps[sectionData.reps.length - 1]);
    }

    setDataReps(newReps);
  }

  // 메인운동 AMRAP, TABATA 운동시간 변경시 //
  function onChangeExerciseTime(division, value) {
    if (division === 'Minute') {
      let second = parseInt(sectionData.exerciseTime % 60);

      sectionData.exerciseTime = parseInt((value * 60) + second);
    }
    else if (division === 'Second') {
      let minute = sectionData.exerciseTime - parseInt(sectionData.exerciseTime % 60);

      sectionData.exerciseTime = parseInt(minute) + parseInt(value);
    }

    refreshSectionList();
  }

  // 메인운동 TABATA 쉬는시간 변경시 //
  function onChangeRestTime(division, value) {
    if (division === 'Minute') {
      let second = parseInt(sectionData.restTime % 60);

      sectionData.restTime = parseInt((value * 60) + second);
    }
    else if (division === 'Second') {
      let minute = sectionData.restTime - parseInt(sectionData.restTime % 60);

      sectionData.restTime = parseInt(minute) + parseInt(value);
    }

    refreshSectionList();
  }

  // Movement Component 이동 클릭시 //
  function onMouseDown(e, clickTarget, division, idx) {
    // 좌클릭에만 반응하도록 //
    if (e.button === 0) {
      setTarget(clickTarget);
      setEmptyHeight(clickTarget.offsetHeight);
      clickTarget.style.width = clickTarget.clientWidth + 'px';
      clickTarget.style.position = 'fixed';
      clickTarget.style.zIndex = 50;

      // 마우스 커서 위치 - Movement Margin (23) - Movement Name Height / 2 //
      clickTarget.style.top = (e.clientY - (23 + 9)) + 'px';

      setTargetStartY(e.clientY - (23 + 9));
      setTargetEndY(e.clientY - (23 + 9) + clickTarget.offsetHeight);
      setClickDivision(division);
      setClickIndex(idx);
      setChangedIndex(idx);

      // console.log(division);
      if (division === 'main.emom') setClickedRoundIndex(sectionData.emomExerciseList[idx].emomRoundIndex);
    }
    // 우클릭일 경우 원상복구 // 
    else if (target !== undefined && e.button === 2) {
      clickTarget.style.top = 'auto';
      clickTarget.style.position = 'static';
      clickTarget.style.zIndex = 1;
      clickTarget.style.width = '100%';

      setTarget(undefined);
      setTargetStartY(0);
      setTargetEndY(0);
      setClickDivision(undefined);
      setClickIndex(undefined);
      setChangedIndex(undefined);

      setClickedRoundIndex(undefined);
    }
  }

  // EMOM Movement Component 이동 클릭시 //
  function onMouseDownEmom(e, clickTarget, idx) {
    // 좌클릭에만 반응 //
    if (e.button === 0) {
      let width = clickTarget.offsetWidth;
      let top = clickTarget.getBoundingClientRect().top;

      setEmomTarget(clickTarget);
      setEmomEmptyWidth(width);

      clickTarget.style.position = 'fixed';
      clickTarget.style.zIndex = 50;
      clickTarget.style.top = (top) + 'px';
      clickTarget.style.left = (e.clientX - 15) + 'px';

      setEmomTargetStartX(e.clientX - 15);
      setEmomTargetEndX(e.clientX - 15 + width);
      setEmomClickIndex(idx);
      setEmomChangedIndex(idx);
    }
    // 우클릭인 경우 원상 복구 //
    else if (emomTarget !== undefined && e.button === 2) {
      clickTarget.style.left = 'auto';
      clickTarget.style.position = 'static';
      clickTarget.style.zIndex = 1;

      setEmomTarget(undefined);
      setEmomTargetStartX(0);
      setEmomTargetEndX(0);
      setEmomClickIndex(undefined);
      setEmomChangedIndex(undefined);
    }
  }

  // 선택된 Movement Component 이동 //
  function onMouseMove(e) {
    if (target === undefined) return;

    setTargetStartY(e.clientY - (23 + 9));
    setTargetEndY(e.clientY - (23 + 9) + target.offsetHeight);
    target.style.top = (e.clientY - (23 + 9)) + 'px';
  }

  // 선택된 EMOM Movement Component 이동 //
  function onMouseMoveEmom(e) {
    if (emomTarget === undefined) return;

    setEmomTargetStartX(e.clientX - 15);
    setEmomTargetEndX(e.clientX - 15 + emomTarget.offsetWidth);
    emomTarget.style.left = (e.clientX - 15) + 'px';
  }

  // 선택된 Movement Component에서 Focus를 잃을경우 ( 마우스 클릭을 떼거나 컴포넌트 밖으로 나갈경우 ) //
  function onMouseUpAndMouseLeave(e) {
    if (target !== undefined) {
      let newList = [];

      // List의 자리변경을 위한 임시변수 //
      let swap = undefined;

      // 원래의 자신의 자리인경우 실행하지 않음 //
      if (changedIndex !== undefined) {
        if (clickDivision === 'buyin') newList = lodash.clone(sectionData.buyinExerciseList);
        else if (clickDivision === 'main') newList = lodash.clone(sectionData.mainExerciseList);
        else if (clickDivision === 'main.emom') newList = lodash.clone(sectionData.emomExerciseList);
        else if (clickDivision === 'buyout') newList = lodash.clone(sectionData.buyoutExerciseList);

        // 움직일 데이터 추출 //
        swap = newList[clickIndex];
        // 움직일 데이터 원본 삭제 //
        newList.splice(clickIndex, 1);
        // 움직인 자리에 데이터 삽입 //
        newList.splice(changedIndex, 0, swap);

        if (clickDivision === 'buyin') {
          for (let i = 0; i < sectionData.buyinScaleOptionExerciseList.length; i++) {
            if (sectionData.buyinScaleOptionExerciseList[i].dependencyIndex === clickIndex) sectionData.buyinScaleOptionExerciseList[i].dependencyIndex = changedIndex;
            else if (sectionData.buyinScaleOptionExerciseList[i].dependencyIndex === changedIndex) sectionData.buyinScaleOptionExerciseList[i].dependencyIndex = clickIndex;
          }
        }
        else if (clickDivision === 'main') {
          for (let i = 0; i < sectionData.mainScaleOptionExerciseList.length; i++) {
            if (sectionData.mainScaleOptionExerciseList[i].dependencyIndex === clickIndex) sectionData.mainScaleOptionExerciseList[i].dependencyIndex = changedIndex;
            else if (sectionData.mainScaleOptionExerciseList[i].dependencyIndex === changedIndex) sectionData.mainScaleOptionExerciseList[i].dependencyIndex = clickIndex;
          }
        }
        else if (clickDivision === 'main.emom') {
          for (let i = 0; i < sectionData.emomScaleOptionExerciseList.length; i++) {
            if (sectionData.emomScaleOptionExerciseList[i].dependencyIndex === clickIndex) sectionData.emomScaleOptionExerciseList[i].dependencyIndex = changedIndex;
            else if (sectionData.emomScaleOptionExerciseList[i].dependencyIndex === changedIndex) sectionData.emomScaleOptionExerciseList[i].dependencyIndex = clickIndex;
          }
        }
        else if (clickDivision === 'buyout') {
          for (let i = 0; i < sectionData.buyoutScaleOptionExerciseList.length; i++) {
            if (sectionData.buyoutScaleOptionExerciseList[i].dependencyIndex === clickIndex) sectionData.buyoutScaleOptionExerciseList[i].dependencyIndex = changedIndex;
            else if (sectionData.buyoutScaleOptionExerciseList[i].dependencyIndex === changedIndex) sectionData.buyoutScaleOptionExerciseList[i].dependencyIndex = clickIndex;
          }
        }

        if (clickDivision === 'buyin') sectionData.buyinExerciseList = newList;
        else if (clickDivision === 'main') newList = sectionData.mainExerciseList = newList;
        else if (clickDivision === 'main.emom') newList = sectionData.emomExerciseList = newList;
        else if (clickDivision === 'buyout') newList = sectionData.buyoutExerciseList = newList;
      }

      target.style.left = 'auto';
      target.style.position = 'static';
      target.style.zIndex = 1;
      target.style.width = '100%';

      setTarget(undefined);
      setTargetStartY(0);
      setTargetEndY(0);
      setClickDivision(undefined);
      setClickIndex(undefined);
      setChangedIndex(undefined);

      setClickedRoundIndex(undefined);
    }
  }

  // 선택된 EMOM Movement Component에서 Focus를 잃은 경우 ( 마우스 클릭을 떼거나 컴포넌트 밖으로 나갈경우 ) //
  function onMouseUpAndMouseLeaveEmom(e) {
    if (emomTarget !== undefined) {
      // 원래 자신의 자리인 경우 실행하지 않음 //
      if (emomChangedIndex !== undefined) {
        let newList = lodash.clone(sectionData.mainExerciseList);
        let mainListLength = newList.length;

        // 움직일 데이터 추출 //
        let swap = newList[emomClickIndex];
        // 움직일 데이터 원본 삭제 //
        newList.splice(emomClickIndex, 1);
        // 움직인 자리에 데이터 삽입 //
        newList.splice(emomChangedIndex, 0, swap);

        sectionData.mainExerciseList = newList;

        let emomNewList = lodash.clone(sectionData.emomExerciseList);

        for (let i = 0; i < sectionData.round; i++) {
          let emomSwap = emomNewList[emomClickIndex + (mainListLength * i)];

          emomNewList.splice(emomClickIndex + (mainListLength * i), 1);
          emomNewList.splice(emomChangedIndex + (mainListLength * i), 0, emomSwap);
        }

        sectionData.emomExerciseList = emomNewList;
      }

      emomTarget.style.top = 'auto';
      emomTarget.style.left = 'auto';
      emomTarget.style.position = 'static';
      emomTarget.style.zIndex = 1;

      setEmomTarget(undefined);
      setEmomTargetStartX(0);
      setEmomTargetEndX(0);
      setEmomClickIndex(undefined);
      setEmomChangedIndex(undefined);
    }
  }

  // 운동 복사버튼 클릭시 //
  function onClickCopyMovement(data, division) {
    let copyData = JSON.parse(JSON.stringify(data));

    if (division === 'buyin') sectionData.buyinExerciseList.push(copyData);
    else if (division === 'main') sectionData.mainExerciseList.push(copyData);
    else if (division === 'main.emom') sectionData.emomExerciseList.push(copyData);
    else if (division === 'buyout') sectionData.buyoutExerciseList.push(copyData);

    refreshSectionList();
  }

  // 운동 Note 추가 클릭시 //
  function onClickNoteState(division) {
    if (division === 'buyin') {
      if (isBuyinNoteView) {
        sectionData.buyinNote = '';
        setIsBuyinNoteView(false);
      }
      else setIsBuyinNoteView(true);
    }
    else if (division === 'main') {
      if (isMainNoteView) {
        sectionData.mainNote = '';
        setIsMainNoteView(false);
      }
      else setIsMainNoteView(true);
    }
    else if (division === 'buyout') {
      if (isBuyoutNoteView) {
        sectionData.buyoutNote = '';
        setIsBuyoutNoteView(false);
      }
      else setIsBuyoutNoteView(true);
    }
  }

  // 운동 Note 변경시 //
  function onChangeNotes(data, division) {
    if (division === 'buyin') sectionData.buyinNote = data;
    else if (division === 'main') sectionData.mainNote = data;
    else if (division === 'buyout') sectionData.buyoutNote = data;

    refreshSectionList();
  }

  // EMOM 시간 표시 //
  function viewEMOMTimeTitle(index) {
    // let multiple = index / sectionData.mainExerciseList.length;
    // let multiple = index / sectionData.round;
    let time = sectionData.exerciseTime;
    // let nowTime = time * multiple;
    // let nextTime = time * (multiple + 1);
    let nowTime = time * index;
    let nextTime = time * (index + 1);
    // console.log(index);
    if (time === 0) return '0 Min - 0 Min';
    else if (index === 0) {
      nextTime = time;

      if (nextTime % 60 === 0) return '0 Min - ' + (nextTime / 60) + ' Min';
      else return '0 Min - ' + parseInt(nextTime / 60) + ' Min ' + (nextTime % 60) + ' Sec';
    }
    // 현재 시작시간이 분단위로 나눠떨어질 경우 //
    else if (nowTime % 60 === 0) {
      // 끝나는 시간이 분단위로 나눠떨어질 경우 //
      if (nextTime % 60 === 0) return (nowTime / 60) + ' Min ' + ' - ' + (nextTime / 60) + ' Min';
      else return (nowTime / 60) + ' Min ' + ' - ' + parseInt(nextTime / 60) + ' Min ' + (nextTime % 60) + ' Sec';
    }
    else {
      // 끝나는 시간이 분단위로 나눠떨어질 경우 //
      if (nextTime % 60 === 0) return parseInt(nowTime / 60) + ' Min ' + (nowTime % 60) + ' Sec ' + ' - ' + (nextTime / 60) + ' Min';
      else return parseInt(nowTime / 60) + ' Min ' + (nowTime % 60) + ' Sec ' + ' - ' + parseInt(nextTime / 60) + ' Min ' + (nextTime % 60) + ' Sec';
    }
  }

  // EMOM 대기 안내문 표시 //
  function viewEMOMWaitMent(index) {
    // let multiple = index / sectionData.mainExerciseList.length;
    // let multiple = index / sectionData.round;
    let time = sectionData.exerciseTime;
    // let nextTime = time * multiple;
    let nextTime = time * (index + 1);

    if (time === 0) return '다음 0분이 될때까지 기다리세요';
    // else if (index === 0) {
    //   if (time % 60 === 0) return '다음 ' + (time / 60) + ' 분이 될때까지 기다리세요';
    //   else return '다음 ' + parseInt(time / 60) + ' 분 ' + (time % 60) + ' 초가 될때까지 기다리세요';
    // }
    // 끝나는 시간이 분단위로 나눠떨어질 경우 //
    else if (nextTime % 60 === 0) return '다음 ' + (nextTime / 60) + ' 분이 될때까지 기다리세요';
    else return '다음 ' + parseInt(nextTime / 60) + ' 분 ' + (nextTime % 60) + ' 초가 될때까지 기다리세요';
  }

  // 동작 스케일 옵션 클릭시 //
  function onClickMovementScaleOption(division, data, index) {
    setClickedScaleOptionDivision(division);
    setClickedScaleOptionIndex(index);
    setClickedScaleOptionMovement(data);
    setIsClickedScaleOptionMovement(true);
  }

  // 동작 스케일 옵션 추가 완료시 //
  function successAddScaleOptionMovement(division, data) {
    if (data.movementName === '') {
      alert('추가할 운동을 선택해주세요!');
      return;
    }

    if (division === 'buyin') sectionData.buyinScaleOptionExerciseList.push(data);
    else if (division === 'main') sectionData.mainScaleOptionExerciseList.push(data);
    else if (division === 'main.emom') {
      sectionData.mainScaleOptionExerciseList.push(data);

      let movementId = sectionData.emomExerciseList[data.dependencyIndex].movementId
      let indexList = [];

      for (let i = 0; i < sectionData.emomExerciseList.length; i++) {
        if (sectionData.emomExerciseList[i].movementId === movementId) indexList.push(i);
      }

      for (let i = 0; i < indexList.length; i++) {
        let tempData = lodash.clone(data);

        tempData.dependencyIndex = indexList[i];
        sectionData.emomScaleOptionExerciseList.push(tempData);
      }
    }
    else if (division === 'buyout') sectionData.buyoutScaleOptionExerciseList.push(data);

    refreshSectionList();
    setIsClickedScaleOptionMovement(false);
  }

  // 동작 스케일 옵션 취소버튼 클릭시 //
  function onClickCloseAddScaleOptionMovement() {
    setClickedScaleOptionDivision('main');
    setClickedScaleOptionIndex(0);
    setClickedScaleOptionMovement(undefined);
    setIsClickedScaleOptionMovement(false);
  }

  return (
    <TopLevelWrapper>
      {isClickedMovement && <AddMovementModal successAddMovement={successAddMovement} close={() => setIsClickedMovement(false)} />}
      {isClickedMovementOptions && <MovementOptionModal data={movementOptionData} refreshSectionList={refreshSectionList} close={() => setIsClickedMovementOptions(false)} />}
      {isClickedTagExplanation && <TagExplanationModal close={() => setIsClickedTagExplanation(!isClickedTagExplanation)} />}
      {isClickedVideoView && <VideoViewModal data={videoLink} close={() => setIsClickedVideoView(!isClickedVideoView)} />}
      {
        isClickedScaleOptionMovement &&
        <AddScaleOptionMovementModal
          index={clickedScaleOptionIndex}
          division={clickedScaleOptionDivision}
          data={clickedScaleOptionMovement}
          successAddMovement={successAddScaleOptionMovement}
          close={onClickCloseAddScaleOptionMovement} />
      }
      {
        // Buyin Setting //
        (sectionData.tag === 'ForTime-Rounds' || sectionData.tag === 'ForTime-Different Reps' || sectionData.tag === 'AMRAP') &&
        <BuyTotalWrapper>
          <BuyWrapper>
            <CheckBox isClicked={sectionData.isUseBuyin} onClick={onClickBuyin}>
              <Check src={CheckImage} />
            </CheckBox>
            <Text fontFamily='Poppins-Regular' fontSize={14} color='#4F4F4F'>Buy in</Text>
          </BuyWrapper>
          {
            sectionData.isUseBuyin &&
            <TotalMovementWrapper ref={BuyinWrapRef}>
              {
                sectionData.buyinExerciseList.map((data, idx) => (
                  // 동작 컴포넌트 //
                  <MovementBoxWrapper
                    key={idx}>
                    <NewMovementBox
                      data={data}
                      dataListLength={sectionData.buyinExerciseList.length}
                      scaleOptionExerciseList={sectionData.buyinScaleOptionExerciseList}
                      idx={idx}
                      tag='None'
                      division='buyin'
                      reps={dataReps}
                      target={target}
                      targetStartY={targetStartY}
                      targetEndY={targetEndY}
                      clickIndex={clickIndex}
                      changedIndex={changedIndex}
                      setChangedIndex={setChangedIndex}
                      clickDivision={clickDivision}
                      emptyHeight={emptyHeight}
                      onChangeData={onChangeData}
                      onChangeUnit={onChangeUnit}
                      onMouseDown={onMouseDown}
                      onClickCopyMovement={onClickCopyMovement}
                      onClickMovementVideo={onClickMovementVideoViewModal}
                      onClickMovementOption={onClickMovementOptionModal}
                      onClickDeleteMovement={onClickDeleteMovement}
                      onClickMovementScaleOption={onClickMovementScaleOption} />
                  </MovementBoxWrapper>
                ))
              }
              <AddMovementButton onClick={() => onClickAddMovement('buyin')}>
                <MovementPlus src={MovementPlusImage} />
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF'>동작 추가하기</Text>
              </AddMovementButton>
              {
                isBuyinNoteView ?
                  <NoteViewBox>
                    <NoteMinusButton onClick={() => onClickNoteState('buyin')}>
                      <HoverText style={{ marginLeft: '13px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF'>Notes</HoverText>
                      <MovementPlus style={{ marginLeft: '12px' }} src={NotesMinusImage} />
                    </NoteMinusButton>
                    <NoteTextAreaWrapper>
                      <NoteTextArea value={sectionData.buyinNote} setValue={onChangeNotes} division='buyin' placeholder='작성하신 운동 방법, 무게 세팅, 동작 수행 시 중요한 점을 작성해 주세요' />
                    </NoteTextAreaWrapper>
                  </NoteViewBox>
                  :
                  <NoteButton onClick={() => onClickNoteState('buyin')}>
                    <HoverText style={{ marginLeft: '13px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF'>Notes</HoverText>
                    <MovementPlus style={{ marginLeft: '12px' }} src={MovementPlusImage} />
                  </NoteButton>
              }
            </TotalMovementWrapper>
          }
          <DivisionLine />
        </BuyTotalWrapper>
      }
      {
        sectionData.tag === 'Custom' &&
        <CustomComponentWrapper>
          <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>운동 타이틀</Text>
          <TotalTitleInputBox value={sectionData.customTitle} setValue={onChangeCustomTitle} placeholder='작성이 필요합니다.' />
          <DivisionLine />
        </CustomComponentWrapper>
      }
      <MainComponentWrapper>
        <MainIconWrapper>
          <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>메인 운동</Text>
          <Icon src={InfoIcon} onClick={() => setIsClickedTagExplanation(true)} />
        </MainIconWrapper>
        <MainSelectBoxWrapper>
          <TagSelectBox value={sectionData.tag.split('-')[0]} setValue={onChangeSectionMainTag} />
          <TagDetailSelectBox value={sectionData.tag.split('-')[1]} setValue={onChangeSectionMainTag} disabled={sectionData.tag.split('-')[0] !== 'ForTime'} />
        </MainSelectBoxWrapper>
        {
          // Section Tag가 For Time - Rounds일 경우 //
          sectionData.tag === 'ForTime-Rounds' &&
          <MainExerciseTagWrapper>
            <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>라운드</Text>
            <MainExerciseRoundInput value={sectionData.round} setValue={onChangeSectionMainRound} />
          </MainExerciseTagWrapper>
        }
        {
          // Section Tag가 For Time - Different Reps일 경우 //
          sectionData.tag === 'ForTime-Different Reps' &&
          <MainExerciseTagWrapper>
            <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>랩 수</Text>
            <AddRepsButtonWrapper>
              <RepsButton onClick={onClickDeleteReps}>
                랩 삭제
                <RepsImage src={RepMinusImage} />
              </RepsButton>
              <RepsButton onClick={onClickAddReps}>
                랩 추가
                <RepsImage src={RepPlusImage} />
              </RepsButton>
            </AddRepsButtonWrapper>
            <RepsInputWrapper>
              {
                sectionData.reps.map((data, idx) => (
                  <RepsWrapper key={idx}>
                    <RepsInput index={idx} value={data} setValue={onChangeRepsData} />
                    {sectionData.reps.length - 1 !== idx && <NextRepsIcon src={NextRepsImage} />}
                  </RepsWrapper>
                ))
              }
            </RepsInputWrapper>
          </MainExerciseTagWrapper>
        }
        {
          // Section Tag가 AMRAP일 경우 //
          sectionData.tag === 'AMRAP' &&
          <MainExerciseTagWrapper>
            <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>운동 시간</Text>
            <TimeWrapper>
              <TimeInput value={parseInt(sectionData.exerciseTime / 60) > 0 ? parseInt(sectionData.exerciseTime / 60) : 0} setValue={onChangeExerciseTime} division='Minute' unit='분' />
              <TimeInput value={parseInt(sectionData.exerciseTime % 60) > 0 ? parseInt(sectionData.exerciseTime % 60) : 0} setValue={onChangeExerciseTime} division='Second' unit='초' />
            </TimeWrapper>
          </MainExerciseTagWrapper>
        }
        {
          // Section Tag가 EMOM일 경우 //
          sectionData.tag === 'EMOM' &&
          <MainExerciseTagWrapper>
            <TimeTextWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>라운드 / 운동 시간</Text>
              <TextInfoWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={12} color='#595959' style={{ marginRight: '4px' }}>총</Text>
                {
                  parseInt(sectionData.round * sectionData.exerciseTime / 60) > 0 &&
                  <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#6DD49E'>{parseInt(sectionData.round * sectionData.exerciseTime / 60) + '분'}</Text>
                }
                {
                  parseInt(sectionData.round * sectionData.exerciseTime % 60) > 0 &&
                  <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#6DD49E' style={{ marginLeft: '4px' }}>{parseInt(sectionData.round * sectionData.exerciseTime % 60) + '초'}</Text>
                }
                <Text fontFamily='NotoSansKR-Medium' fontSize={12} color='#595959'>동안 진행됩니다</Text>
              </TextInfoWrapper>
            </TimeTextWrapper>
            <MainExerciseRoundInput value={sectionData.round} setValue={onChangeSectionMainRound} />
            <TimeWrapper>
              <TimeInput value={parseInt(sectionData.exerciseTime / 60) > 0 ? parseInt(sectionData.exerciseTime / 60) : 0} setValue={onChangeExerciseTime} division='Minute' unit='분' />
              <TimeInput value={parseInt(sectionData.exerciseTime % 60) > 0 ? parseInt(sectionData.exerciseTime % 60) : 0} setValue={onChangeExerciseTime} division='Second' unit='초' />
            </TimeWrapper>
          </MainExerciseTagWrapper>
        }
        {
          // Section Tag가 TABATA일 경우 //
          sectionData.tag === 'TABATA' &&
          <MainExerciseTagWrapper>
            <TimeTextWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>동작 별 라운드</Text>
              <TextInfoWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={12} color='#595959' style={{ marginRight: '4px' }}>총</Text>
                {
                  parseInt(((sectionData.round * sectionData.exerciseTime) + (sectionData.round > 0 ? (sectionData.round - 1) * sectionData.restTime : 0)) / 60) > 0 &&
                  <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#6DD49E'>{parseInt(((sectionData.round * sectionData.exerciseTime) + (sectionData.round > 0 ? (sectionData.round - 1) * sectionData.restTime : 0)) / 60) + '분'}</Text>
                }
                {
                  parseInt(((sectionData.round * sectionData.exerciseTime) + (sectionData.round > 0 ? (sectionData.round - 1) : 0 * sectionData.restTime)) % 60) > 0 &&
                  <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#6DD49E' style={{ marginLeft: '4px' }}>{parseInt(((sectionData.round * sectionData.exerciseTime) + ((sectionData.round > 0 ? (sectionData.round - 1) : 0) * sectionData.restTime)) % 60) + '초'}</Text>
                }
                <Text fontFamily='NotoSansKR-Medium' fontSize={12} color='#595959'>동안 진행됩니다</Text>
              </TextInfoWrapper>
            </TimeTextWrapper>
            <MainExerciseRoundInput value={sectionData.round} setValue={onChangeSectionMainRound} />
            <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>동작 별 운동 시간</Text>
            <TimeWrapper>
              <TimeInput value={parseInt(sectionData.exerciseTime / 60) > 0 ? parseInt(sectionData.exerciseTime / 60) : 0} setValue={onChangeExerciseTime} division='Minute' unit='분' />
              <TimeInput value={parseInt(sectionData.exerciseTime % 60) > 0 ? parseInt(sectionData.exerciseTime % 60) : 0} setValue={onChangeExerciseTime} division='Second' unit='초' />
            </TimeWrapper>
            <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>동작 별 쉬는 시간</Text>
            <TimeWrapper>
              <TimeInput value={parseInt(sectionData.restTime / 60) > 0 ? parseInt(sectionData.restTime / 60) : 0} setValue={onChangeRestTime} division='Minute' unit='분' />
              <TimeInput value={parseInt(sectionData.restTime % 60) > 0 ? parseInt(sectionData.restTime % 60) : 0} setValue={onChangeRestTime} division='Second' unit='초' />
            </TimeWrapper>
          </MainExerciseTagWrapper>
        }
        {
          // Section Tag가 Custom일 경우 //
          sectionData.tag === 'Custom' &&
          <MainExerciseTagWrapper>
            <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>점수 기준</Text>
            <MainSelectBoxWrapper>
              <ScoreSelectBox value={sectionData.scoreKeyword} setValue={onChangeScoreKeyword} />
              <ScoreDetailSelectBox value={sectionData.scorePriority} setValue={onChangeScorePriority} disabled={sectionData.scoreKeyword === '점수 없음' || sectionData.scoreKeyword === '피크와트' || sectionData.scoreKeyword === '최고 속도'} />
            </MainSelectBoxWrapper>
            <DivisionLine />
            <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>운동 설명</Text>
            <DescriptionTextArea value={sectionData.description} setValue={onChangeDescription} />
            {
              isMainNoteView ?
                <NoteViewBox>
                  <NoteMinusButton onClick={() => onClickNoteState('main')}>
                    <HoverText style={{ marginLeft: '13px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF'>Notes</HoverText>
                    <MovementPlus style={{ marginLeft: '12px' }} src={NotesMinusImage} />
                  </NoteMinusButton>
                  <NoteTextAreaWrapper>
                    <NoteTextArea value={sectionData.mainNote} setValue={onChangeNotes} division='main' placeholder='작성하신 운동 방법, 무게 세팅, 동작 수행 시 중요한 점을 작성해 주세요' />
                  </NoteTextAreaWrapper>
                </NoteViewBox>
                :
                <NoteButton onClick={() => onClickNoteState('main')}>
                  <HoverText style={{ marginLeft: '13px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF'>Notes</HoverText>
                  <MovementPlus style={{ marginLeft: '12px' }} src={MovementPlusImage} />
                </NoteButton>
            }
          </MainExerciseTagWrapper>
        }
      </MainComponentWrapper>
      <DivisionLine />
      {
        (sectionData.tag !== 'Custom' && sectionData.tag !== 'EMOM') &&
        <TotalMovementWrapper ref={MainWrapRef} onMouseMove={onMouseMove} onMouseUp={onMouseUpAndMouseLeave} onMouseLeave={onMouseUpAndMouseLeave}>
          {
            sectionData.mainExerciseList.map((data, idx) => (
              // 동작 컴포넌트 //
              <MovementBoxWrapper
                key={idx}>
                <NewMovementBox
                  data={data}
                  dataListLength={sectionData.mainExerciseList.length}
                  scaleOptionExerciseList={sectionData.mainScaleOptionExerciseList}
                  idx={idx}
                  tag={sectionData.tag}
                  reps={dataReps}
                  division='main'
                  target={target}
                  targetStartY={targetStartY}
                  targetEndY={targetEndY}
                  clickIndex={clickIndex}
                  changedIndex={changedIndex}
                  setChangedIndex={setChangedIndex}
                  clickDivision={clickDivision}
                  emptyHeight={emptyHeight}
                  onChangeData={onChangeData}
                  onChangeUnit={onChangeUnit}
                  onClickRest={onClickRest}
                  onMouseDown={onMouseDown}
                  onClickCopyMovement={onClickCopyMovement}
                  onClickMovementVideo={onClickMovementVideoViewModal}
                  onClickMovementOption={onClickMovementOptionModal}
                  onClickDeleteMovement={onClickDeleteMovement}
                  onClickMovementScaleOption={onClickMovementScaleOption} />
                {/* {
                  sectionData.mainScaleOptionExerciseList.map((scaleOptionData, scaleOptionIndex) => (
                    scaleOptionData.dependencyIndex === idx &&
                    <MovementBoxWrapper key={scaleOptionIndex}>
                      <ScaleOptionMovementBox
                        data={scaleOptionData}
                        scaleOptionData={sectionData.mainScaleOptionExerciseList}
                        dataListLength={sectionData.mainExerciseList.length}
                        idx={idx}
                        realIndex={scaleOptionIndex}
                        tag={sectionData.tag}
                        reps={dataReps}
                        division='main.scale'
                        target={target}
                        emptyHeight={emptyHeight}
                        onChangeData={onChangeData}
                        onChangeUnit={onChangeUnit}
                        onClickRest={onClickRest}
                        onClickMovementVideo={onClickMovementVideoViewModal}
                        onClickMovementOption={onClickMovementOptionModal}
                        onClickDeleteMovement={onClickDeleteMovement} />
                    </MovementBoxWrapper>
                  ))
                } */}
              </MovementBoxWrapper>
            ))
          }
          <AddMovementButton onClick={() => onClickAddMovement('main')}>
            <MovementPlus src={MovementPlusImage} />
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF'>동작 추가하기</Text>
          </AddMovementButton>
          {
            isMainNoteView ?
              <NoteViewBox isEmom={sectionData.tag === "EMOM"}>
                <NoteMinusButton onClick={() => onClickNoteState('main')}>
                  <HoverText style={{ marginLeft: '13px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF'>Notes</HoverText>
                  <MovementPlus style={{ marginLeft: '12px' }} src={NotesMinusImage} />
                </NoteMinusButton>
                <NoteTextAreaWrapper>
                  <NoteTextArea value={sectionData.mainNote} setValue={onChangeNotes} division='main' />
                </NoteTextAreaWrapper>
              </NoteViewBox>
              :
              <NoteButton isEmom={sectionData.tag === "EMOM"} onClick={() => onClickNoteState('main')}>
                <HoverText style={{ marginLeft: '13px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF'>Notes</HoverText>
                <MovementPlus style={{ marginLeft: '12px' }} src={MovementPlusImage} />
              </NoteButton>
          }
          <DivisionLine />
        </TotalMovementWrapper>
      }
      {
        sectionData.tag === 'EMOM' &&
        <TotalMovementWrapper style={{ marginTop: `-8px`, marginBottom: `20px` }} onMouseMove={onMouseMove} onMouseUp={onMouseUpAndMouseLeave} onMouseLeave={onMouseUpAndMouseLeave}>
          {/* <EmomMovementWrapper onScroll={onScrollEmom} onMouseMove={onMouseMoveEmom} onMouseUp={onMouseUpAndMouseLeaveEmom} onMouseLeave={onMouseUpAndMouseLeaveEmom}>
            {
              sectionData.mainExerciseList.map((data, idx) => (
                <EMOMTitleMovementBox
                  key={idx}
                  data={data}
                  index={idx}
                  dataListLength={sectionData.mainExerciseList.length}
                  target={emomTarget}
                  targetStartX={emomTargetStartX}
                  targetEndX={emomTargetEndX}
                  clickIndex={emomClickIndex}
                  changedIndex={emomChangedIndex}
                  setChangedIndex={setEmomClickIndex}
                  emptyWidth={emomEmptyWidth}
                  onMouseDown={onMouseDownEmom}
                  onClickMovementVideo={onClickMovementVideoViewModal}
                  onClickDeleteMovement={onClickDeleteMovement} />
              ))
            }
          </EmomMovementWrapper>
          <AddMovementButton onClick={() => onClickAddMovement('main')}>
            <MovementPlus src={MovementPlusImage} />
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF'>동작 추가하기</Text>
          </AddMovementButton> */}
          {
            isMainNoteView ?
              <NoteViewBox>
                <NoteMinusButton onClick={() => onClickNoteState('main')}>
                  <HoverText style={{ marginLeft: '13px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF'>Notes</HoverText>
                  <MovementPlus style={{ marginLeft: '12px' }} src={NotesMinusImage} />
                </NoteMinusButton>
                <NoteTextAreaWrapper>
                  <NoteTextArea value={sectionData.mainNote} setValue={onChangeNotes} division='main' placeholder='작성하신 운동 방법, 무게 세팅, 동작 수행 시 중요한 점을 작성해 주세요'/>
                </NoteTextAreaWrapper>
              </NoteViewBox>
              :
              <NoteButton onClick={() => onClickNoteState('main')}>
                <HoverText style={{ marginLeft: '13px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF'>Notes</HoverText>
                <MovementPlus style={{ marginLeft: '12px' }} src={MovementPlusImage} />
              </NoteButton>
          }
          {/* {
            sectionData.emomExerciseList.map((data, idx) => (
              // data !== undefined &&
              <EmomSectionWrapper key={idx}>
                {
                  // 모래시계 모형과 기다리세요 안내문 띄우기 //
                  (idx !== 0 && idx % (sectionData.mainExerciseList.length) === 0) &&
                  <EmomWaitMentWrapper>
                    <TrapezoidShapeWrapper>
                      <TopTrapezoidShape />
                      <BottomTrapezoidShape />
                    </TrapezoidShapeWrapper>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{viewEMOMWaitMent(idx)}</Text>
                  </EmomWaitMentWrapper>
                }
                {
                  // 시간 안내표 띄우기 //
                  (idx % sectionData.mainExerciseList.length) === 0 &&
                  <EmomTimeTitleWrapper>
                    <EmomTimeTitle>
                      <Text style={{ marginTop: '2px' }} fontFamily='Poppins-Regular' fontSize={14} color='#FFFFFF'>{viewEMOMTimeTitle(idx)}</Text>
                    </EmomTimeTitle>
                  </EmomTimeTitleWrapper>
                }
                <NewEMOMMovementBox
                  data={data}
                  dataListLength={sectionData.emomExerciseList.length}
                  idx={idx}
                  tag={sectionData.tag}
                  reps={dataReps}
                  division='main.emom'
                  target={target}
                  targetStartY={targetStartY}
                  targetEndY={targetEndY}
                  clickIndex={clickIndex}
                  changedIndex={changedIndex}
                  setChangedIndex={setChangedIndex}
                  clickDivision={clickDivision}
                  emptyHeight={emptyHeight}
                  onChangeData={onChangeData}
                  onChangeUnit={onChangeUnit}
                  onClickRest={onClickRest}
                  onMouseDown={onMouseDown}
                  onClickCopyMovement={onClickCopyMovement}
                  onClickMovementVideo={onClickMovementVideoViewModal}
                  onClickMovementOption={onClickMovementOptionModal}
                  onClickDeleteMovement={onClickDeleteMovement}
                  onClickMovementScaleOption={onClickMovementScaleOption} />
                  {
                      sectionData.emomScaleOptionExerciseList.map((scaleOptionData, scaleOptionIndex) => (
                        scaleOptionData.dependencyIndex === idx &&
                        <MovementBoxWrapper key={scaleOptionIndex}>
                          <ScaleOptionMovementBox
                            data={scaleOptionData}
                            scaleOptionData={sectionData.emomScaleOptionExerciseList}
                            dataListLength={sectionData.emomExerciseList.length}
                            idx={idx}
                            realIndex={scaleOptionIndex}
                            tag={sectionData.tag}
                            reps={dataReps}
                            division='main.emom.scale'
                            target={target}
                            emptyHeight={emptyHeight}
                            onChangeData={onChangeData}
                            onChangeUnit={onChangeUnit}
                            onClickRest={onClickRest}
                            onClickMovementVideo={onClickMovementVideoViewModal}
                            onClickMovementOption={onClickMovementOptionModal}
                            onClickDeleteMovement={onClickDeleteMovement} />
                        </MovementBoxWrapper>
                      ))
                    }
              </EmomSectionWrapper>
            ))
          }
        </TotalMovementWrapper>
      } */}
          {
            sectionData.roundList.map((roundData, roundIndex) => (
              <EmomSectionWrapper key={`emom-round-` + roundIndex}>
                <EmomTimeTitleWrapper>
                  <EmomTimeTitle>
                    <Text style={{ marginTop: '2px' }} fontFamily='Poppins-Regular' fontSize={14} color='#FFFFFF'>{viewEMOMTimeTitle(roundIndex)}</Text>
                  </EmomTimeTitle>
                </EmomTimeTitleWrapper>
                {
                  sectionData.emomExerciseList.map((data, idx) => (
                    roundData === data.emomRoundIndex &&
                    <EmomSectionWrapper key={`emom-` + idx}>
                      <NewEMOMMovementBox
                        data={data}
                        dataListLength={sectionData.emomExerciseList.length}
                        idx={idx}
                        tag={sectionData.tag}
                        reps={dataReps}
                        division='main.emom'
                        target={target}
                        targetStartY={targetStartY}
                        targetEndY={targetEndY}
                        clickIndex={clickIndex}
                        changedIndex={changedIndex}
                        setChangedIndex={setChangedIndex}
                        clickDivision={clickDivision}
                        clickedRoundIndex={clickedRoundIndex}
                        emptyHeight={emptyHeight}
                        onChangeData={onChangeData}
                        onChangeUnit={onChangeUnit}
                        onClickRest={onClickRest}
                        onMouseDown={onMouseDown}
                        onClickCopyMovement={onClickCopyMovement}
                        onClickMovementVideo={onClickMovementVideoViewModal}
                        onClickMovementOption={onClickMovementOptionModal}
                        onClickDeleteMovement={onClickDeleteMovement}
                        onClickMovementScaleOption={onClickMovementScaleOption} />
                      {
                        sectionData.emomScaleOptionExerciseList.map((scaleOptionData, scaleOptionIndex) => (
                          scaleOptionData.dependencyIndex === idx &&
                          <MovementBoxWrapper key={scaleOptionIndex}>
                            <ScaleOptionMovementBox
                              data={scaleOptionData}
                              scaleOptionData={sectionData.emomScaleOptionExerciseList}
                              dataListLength={sectionData.emomExerciseList.length}
                              idx={idx}
                              realIndex={scaleOptionIndex}
                              tag={sectionData.tag}
                              reps={dataReps}
                              division='main.emom.scale'
                              target={target}
                              emptyHeight={emptyHeight}
                              onChangeData={onChangeData}
                              onChangeUnit={onChangeUnit}
                              onClickRest={onClickRest}
                              onClickMovementVideo={onClickMovementVideoViewModal}
                              onClickMovementOption={onClickMovementOptionModal}
                              onClickDeleteMovement={onClickDeleteMovement} />
                          </MovementBoxWrapper>
                        ))
                      }
                    </EmomSectionWrapper>
                  ))
                }
                <AddMovementButton onClick={() => onClickAddMovementForEMOM('main', roundData)}>
                  <MovementPlus src={MovementPlusImage} />
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF'>동작 추가하기</Text>
                </AddMovementButton>
                <EmomWaitMentWrapper>
                  <TrapezoidShapeWrapper>
                    <TopTrapezoidShape />
                    <BottomTrapezoidShape />
                  </TrapezoidShapeWrapper>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{viewEMOMWaitMent(roundIndex)}</Text>
                </EmomWaitMentWrapper>
              </EmomSectionWrapper>
            ))
          }
        </TotalMovementWrapper>
      }
      {
        // Buyout Setting //
        (sectionData.tag === 'ForTime-Rounds' || sectionData.tag === 'ForTime-Different Reps') &&
        <BuyTotalWrapper>
          <BuyWrapper>
            <CheckBox isClicked={sectionData.isUseBuyout} onClick={onClickBuyout}>
              <Check src={CheckImage} />
            </CheckBox>
            <Text fontFamily='Poppins-Regular' fontSize={14} color='#4F4F4F'>Buyout</Text>
          </BuyWrapper>
          {
            sectionData.isUseBuyout &&
            <TotalMovementWrapper ref={BuyoutWrapRef} /* onMouseMove={onMouseMove} onMouseUp={onMouseUpAndMouseLeave} onMouseLeave={onMouseUpAndMouseLeave}*/>
              {
                sectionData.buyoutExerciseList.map((data, idx) => (
                  // 동작 컴포넌트 //
                  <MovementBoxWrapper
                    key={idx}>
                    <NewMovementBox
                      data={data}
                      dataListLength={sectionData.buyoutExerciseList.length}
                      scaleOptionExerciseList={sectionData.buyoutScaleOptionExerciseList}
                      idx={idx}
                      tag='None'
                      division='buyout'
                      reps={dataReps}
                      target={target}
                      targetStartY={targetStartY}
                      targetEndY={targetEndY}
                      clickIndex={clickIndex}
                      changedIndex={changedIndex}
                      setChangedIndex={setChangedIndex}
                      clickDivision={clickDivision}
                      emptyHeight={emptyHeight}
                      onChangeData={onChangeData}
                      onChangeUnit={onChangeUnit}
                      onMouseDown={onMouseDown}
                      onClickCopyMovement={onClickCopyMovement}
                      onClickMovementVideo={onClickMovementVideoViewModal}
                      onClickMovementOption={onClickMovementOptionModal}
                      onClickDeleteMovement={onClickDeleteMovement}
                      onClickMovementScaleOption={onClickMovementScaleOption} />
                  </MovementBoxWrapper>
                ))
              }
              <AddMovementButton onClick={() => onClickAddMovement('buyout')}>
                <MovementPlus src={MovementPlusImage} />
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF'>동작 추가하기</Text>
              </AddMovementButton>
              {
                isBuyoutNoteView ?
                  <NoteViewBox>
                    <NoteMinusButton onClick={() => onClickNoteState('buyout')}>
                      <HoverText style={{ marginLeft: '13px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF'>Notes</HoverText>
                      <MovementPlus style={{ marginLeft: '12px' }} src={NotesMinusImage} />
                    </NoteMinusButton>
                    <NoteTextAreaWrapper>
                      <NoteTextArea value={sectionData.buyoutNote} setValue={onChangeNotes} division='buyout' placeholder='작성하신 운동 방법, 무게 세팅, 동작 수행 시 중요한 점을 작성해 주세요'/>
                    </NoteTextAreaWrapper>
                  </NoteViewBox>
                  :
                  <NoteButton onClick={() => onClickNoteState('buyout')}>
                    <HoverText style={{ marginLeft: '13px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF'>Notes</HoverText>
                    <MovementPlus style={{ marginLeft: '12px' }} src={MovementPlusImage} />
                  </NoteButton>
              }
            </TotalMovementWrapper>
          }
          <DivisionLine />
        </BuyTotalWrapper>
        // Buyout Setting //
      }
      {
        // Time Limit Setting //
        (sectionData.tag === 'ForTime-Rounds' || sectionData.tag === 'ForTime-Different Reps' || sectionData.tag === 'SINGLE' || sectionData.tag === 'Custom') &&
        <TimeLimitWrapper>
          <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>시간 제한</Text>
          <TimeLimitCustomWrapper gap={15}>
            <TimeLimitCustomWrapper gap={6}>
              <CheckBox isClicked={!sectionData.isUseTimeLimit} onClick={onClickTimeLimit}>
                <Check src={CheckImage} />
              </CheckBox>
              <Text fontFamily='Poppins-Medium' fontSize={14} color='#828282'>제한 없음</Text>
            </TimeLimitCustomWrapper>
            <TimeLimitCustomWrapper gap={24}>
              <TimeLimitInput disabled={!sectionData.isUseTimeLimit} value={parseInt(sectionData.timeLimit / 60) > 0 ? parseInt(sectionData.timeLimit / 60) : 0} setValue={onChangeTimeLimit} division='Minute' unit='분' />
              <TimeLimitInput disabled={!sectionData.isUseTimeLimit} value={parseInt(sectionData.timeLimit % 60) > 0 ? parseInt(sectionData.timeLimit % 60) : 0} setValue={onChangeTimeLimit} division='Second' unit='초' />
            </TimeLimitCustomWrapper>
          </TimeLimitCustomWrapper>
          <DivisionLine />
        </TimeLimitWrapper>
        // Time Limit Setting //
      }
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 97.71%;
  width: 100%;
`;

const DivisionLine = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  // height: 1px;

  margin-top: 24px;
  margin-bottom: 24px;

  border: 1px solid #ECECEC;
`;

const BuyTotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const BuyWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 100%;

  // margin-top: 24px;
  gap: 6px;
`;

const CheckBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;

  border-radius: 6px;
  background-color: ${props => props.isClicked ? `#6DD49E` : `#E0E0E0`};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const Check = styled.img`
  width: 10px;
  height: 9px;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const TotalMovementWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: 100%;
  height: auto;

  // transition: all 0.3s ease-in-out;
`;

const EmomSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const EmomMovementWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  // align-items: center;
  align-items: flex-start;

  width: 100%;
  // height: 40px;
  height: 55px;

  gap: 8px;

  overflow-x: scroll;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    width: 4px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
		border: 3px solid transparent;
		background-color: #DADADA;
		border-radius: 200px;
	}

  &::-webkit-scrollbar-track {
		background-color: #F0F0F0;
		border-radius: 200px;
	}
`;

const MainIconWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;

  :hover {
    cursor: pointer;
  }
`;

const NoteButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  margin-top: ${props => props.isEmom ? `0px` : `12px`};

  width: 91px;
  height: 28px;

  border-radius: 7px;
  // background-color: #FF7322;
  background-color: #FF8B48;

  :hover {
    cursor: pointer;
  }
`;

const NoteMinusButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 91px;
  height: 28px;

  border-radius: 15px 0px 15px 0px;
  // background-color: #FF7322;
  background-color: #FF8B48;

  :hover {
    cursor: pointer;
  }
`;

const NoteViewBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: ${props => props.isEMOM ? `0px` : `12px`};

  width: 100%;

  border: 1px solid #E0E0E0;
  border-radius: 15px;
  background-color: #FFFFFF;
`;

const NoteTextAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  margin: 8px 0px 14px 0px;

  width: 100%;
`;

const AddMovementButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
  
  // width: calc(97.71% - 2px);
  width: 100%;
  height: 34px;

  gap: 4px;
  margin-top: 24px;

  border: 1px solid #6DD49E;
  border-radius: 12px;
  background-color: #6DD49E;

  :hover {
    cursor: pointer;
  }
`;

const MovementPlus = styled.img`
  width: 20px;
  height: 20px;
`;

const CustomComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
`;

const MainComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
`;

const MainSelectBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;

  margin-top: 9px;
`;

const MainExerciseTagWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 8px;
  margin-top: 24px;

  width: 100%;
`;

const TimeWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const AddRepsButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const RepsButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 94px;
  height: 32px;

  border-radius: 6px;
  background-color: #6DD49E;

  gap: 4px;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }
`;

const RepsImage = styled.img`
  width: 20px;
  height: 20px;
`;

const RepsWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
  margin-top: 8px;
`;

const RepsInputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  flex-wrap: wrap;

  width: 100%;
`;

const NextRepsIcon = styled.img`
  width: 16px;
  height: 16px;
`;

const TimeTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const TextInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const TimeLimitWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: 100%;
  
  gap: 6px;
`;

const TimeLimitCustomWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: ${props => props.gap}px;
`;

const MovementBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const HoverText = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;

const EmomTimeTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 26px;

  width: 100%;
`;

const EmomTimeTitle = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  padding: 0px 8px 0px 8px;
  
  height: 18px;

  border-radius: 2px;
  // background-color: #6DD49E;
  background-color: #FF8B48;
`;

const EmomWaitMentWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 20px;

  width: 100%;

  gap: 4px;
`;

const TrapezoidShapeWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const TopTrapezoidShape = styled.div`
  border-top: 7px solid #66D49E;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;

  height: 0px;

  width: 3px;
`;

const BottomTrapezoidShape = styled.div`
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-bottom: 7px solid #66D49E;

  height: 0px;

  width: 3px;
`;