import { React } from 'react';
import styled from 'styled-components';
import NotReceivedInfo from './NotReceivedInfo';

const NotReceivedList = (props) => {
  const { data, selectedPartnerGym } = props;

  return (
    <TopLevelWrapper>
      <Table>
        <Head>
          <HeadTr>
            <Td style={{ minWidth: `67px`, width: `67px`, padding: `0px`/*, marginLeft: '8px'*/ }}>이름</Td>
            {/* <Td style={{ width: `67px` }}>구분</Td> */}
            {/* <Td style={{ width: `276px` }}>미수금 내역</Td> */}
            <Td style={{ minWidth: `255px`, width: `255px`, padding: `0px` }}>미수금 내역</Td>
            {/* <Td style={{ width: `91px` }}>총 결제금액</Td> */}
            <Td style={{ minWidth: `96px`, width: `96px`, padding: `0px`  }}>총 결제금액</Td>
            <Td style={{ minWidth: `91px`, width: `91px`, padding: `0px`  }}>카드</Td>
            {/* <Td style={{ width: `92px` }}>현금</Td> */}
            <Td style={{ minWidth: `91px`, width: `91px`, padding: `0px`  }}>현금</Td>
            <Td style={{ minWidth: `91px`, width: `91px`, padding: `0px`  }}>할인금액</Td>
            <Td style={{ minWidth: `93px`, width: `93px`, padding: `0px`  }}>미수금액</Td>
            <Td style={{ minWidth: `180px`, width: `180px`, padding: `0px`  }}>최초 결제</Td>
          </HeadTr>
        </Head>
      </Table>
      <Table>
        <Body>
          {
            data.map((data, index) => (
              <NotReceivedInfo key={index} data={data} selectedPartnerGym={selectedPartnerGym}/>
            ))
          }
        </Body>
      </Table>
    </TopLevelWrapper>
  )
}

export default NotReceivedList;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // margin-top: 24px;
  
  width: 100%;
`;

const Table = styled.table`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const Head = styled.thead`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 16px 0px 16px 0px;

  width: 100%;

  border-bottom: 1px solid #ECECEC;
`;

const HeadTr = styled.tr`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100%;
  // height: 32px;

  // margin-top: 16px;
  // padding-bottom: 16px;

  // border-bottom: 1px solid #ECECEC;
  // border-radius: 8px;
  // background-color: #ECECEC;
`;

const Body = styled.tbody`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  // width: 992px;
  width: 100%;
  height: 352px;

  margin-top: 16px;

  overflow-y: scroll;
  overflow-x: none;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
		border: 3px solid transparent;
		// height: 92px;
		background-color: #DADADA;
		border-radius: 200px;
	}

  &::-webkit-scrollbar-track {
    // height: 439px;
		// border: 4px solid transparent;
		background-color: #F0F0F0;
		border-radius: 200px;
	}
`;

// const BodyTr = styled.tr`
//   display: flex;
//   flex-direction: row;

//   justify-content: center;
//   align-items: center;

//   background-color: #FFFFFF;

//   transition: all 0.3s ease-in-out;

//   :hover {
//     cursor: pointer;
//     background-color: #F4F2F0;
//   }
// `;

const Td = styled.td`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  height: 36px;

  color: #595959;
`;

// const Text = styled.span`
//   font-family: NotoSansKR-Medium;
//   font-size: 14px;

//   color: #CBCBCB;

//   margin: 0 0 0 0;

//   :hover {
//     cursor: pointer;
//   }
// `;