import React from "react";
import styled from "styled-components";
import UserProfile from "../../../../Component/Normal/UserProfile";
import YellowArrow from '../../../../image/YellowArrow.png';

export default function QueuedMemberInfoBox(props) {
  const {
    memberData,
    index,
    onViewUserPhone,
    onClickMemberProfileOrText,
    onClickAddReserveOnQueue
  } = props;

  return (
    <InfoWrapper key={index}>
      <ImageWrapper onClick={() => onClickMemberProfileOrText(memberData)}>
        <UserProfile profile={memberData.profile} defaultProfile='https://picture.link-zone.org/ZoneLogo.png' />
      </ImageWrapper>
      <MemberDataWrapper>
        <ReserveNumberWrapper>
            <TextWrapper>
              <TextWrapper onClick={() => onClickMemberProfileOrText(memberData)}>
                <NameWrapper>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#777777' cursor='pointer'>{memberData.name}</Text>
                </NameWrapper>
                <PhoneWrapper>
                  <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#777777' cursor='pointer'>{onViewUserPhone(memberData.phone)}</Text>
                </PhoneWrapper>
              </TextWrapper>
            </TextWrapper>
          <ReserveNumberBox>
            <Text fontFamily='NotoSansKR-Medium' fontSize={10} color='#FFFFFF'>{'대기 ' + (index + 1)}</Text>
          </ReserveNumberBox>
        </ReserveNumberWrapper>

        <AddReserveButton onClick={() => onClickAddReserveOnQueue(memberData)}>
          <Arrow src={YellowArrow} />
        </AddReserveButton>
      </MemberDataWrapper>
    </InfoWrapper>
  );
}

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 96%;
  height: 38px;

  gap: 10px;
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 38px;
  height: 38px;

  border-radius: 2px;

  overflow: hidden;

  :hover {
    cursor: pointer;
  }
`;

const MemberDataWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: calc(100% - 32px);
  height: 38px;

  border-bottom: 1px solid #F5F5F5;
`;

const ReserveNumberWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  // gap: 4px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  gap: 4px;

  :hover {
    cursor: pointer;
  }
`;

const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 56px;
  height: 17px;

  overflow: hidden;
`;

const PhoneWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 105px;
  height: 17px;

  overflow: hidden;
`;

const AddReserveButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 23px;
  height: 23px;
  
  :hover {
    cursor: pointer;
  }
  // -webkit-transform: rotate(45deg);
`;

const Arrow = styled.img`
  width: 13px;
  height: 7px;
`;


const ReserveNumberBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 0px 5px;
  margin-top: 4px;

  height: 17px;

  border-radius: 17px;
  background-color: #FFB800;

  :hover {
    cursor: default;
  }

  transition: all 0.3s ease-in-out;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }
`;