import { React, useState } from 'react';
import styled from 'styled-components';

import LogoPath from '../../../../image/CompleteSignUpLogo.png';
import CheckImage from '../../../../image/WhiteCheck.png';

export default function LessonSaveModal(props) {
  const { onClickExit, onClickSave } = props;

  const [isClicked, setIsClicked] = useState(false);

  return (
    <TopLevelWrapper>
      <Modal>
        <LogoWrapper>
          <ExitButton style={{ opacity: 0, zIndex: -1 }} />
          <Logo src={LogoPath} />
          <ExitButton style={{ opacity: 0, zIndex: -1 }} />
        </LogoWrapper>

        <Text fontFamily='NotoSansKR-Bold' fontSize={25} color='#000000' hover='default'>신규수업을 저장하시겠습니까?</Text>
        {/* <Text style={{ marginTop: '16px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333' hover='default'>신규수업을 저장하시겠습니까?</Text> */}
        {/* <CheckBoxWrapper style={{ marginTop: '8px' }} onClick={() => setIsClicked(!isClicked)}>
          <CheckBox isClicked={isClicked}/>
          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333' hover='pointer'>예약 가능 수업 자동추가</Text>
        </CheckBoxWrapper> */}

        <CheckBoxWrapper style={{ marginTop: '16px' }} onClick={() => setIsClicked(!isClicked)}>
          <CheckBox isClicked={isClicked} style={{ marginTop: '4px' }} >
            <Check src={CheckImage} />
          </CheckBox>
          <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#333333' hover='pointer'>예약 가능 수업 자동추가</Text>
        </CheckBoxWrapper>
        {/* 
        <ButtonWrapper style={{ marginTop: '24px' }}>
          <Button backgroundColor='#FF3131' onClick={onClickExit}>
            <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#FFFFFF' hover='pointer'>취 소</Text>
          </Button>
          <Button backgroundColor='#6DD49E' onClick={() => onClickSave(isClicked)}>
            <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#FFFFFF' hover='pointer'>저 장</Text>
          </Button>
        </ButtonWrapper> */}

        <ButtonWrapper>
          <NoButton onClick={onClickExit}>
            <ButtonText>돌아가기</ButtonText>
          </NoButton>
          <YesButton onClick={() => onClickSave(isClicked)}>
            <ButtonText>저장하기</ButtonText>
          </YesButton>
        </ButtonWrapper>
      </Modal>
    </TopLevelWrapper>
  );
}

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 1000;

  background-color: rgba(0, 0, 0, 0.2);

  transition: all 0.3s ease-in-out;
`;

const Modal = styled.div`
	display: flex;
	flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  padding-bottom: 28px;

	width: 476px;
	// height: 268px;
  // height: 298px;
  
	border-radius: 50px;
  background-color: #FFFFFF;

  transition: all 0.5s ease-in-out;
`;

const LogoWrapper = styled.div`
  // position: absolute;

  display: flex;
  flex-direction: row;

  justify-content: space-around;
  // justify-content: center;
  align-items: flex-end;

  // margin-top: -270px;
  
  margin-top: -113px;
  // background-color: red;

  width: 100%;
`;

const Logo = styled.img`
  width: 200px;
  height: 200px;
`;

const ExitButton = styled.img`
  width: 20px;
  height: 20px;

  margin-bottom: 20px;

  :hover {
    cursor: pointer;
  }
`;

const ExplanationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  justify-content: center;
  align-items: center;

  // margin-top: 40px;
  // margin-top: 60px;
  margin-top: 23px;

  width: 90%;
`;

const KorExplanation = styled.pre`
  font-family: NotoSansKR-Bold;
  font-size: 25px;

  margin: 0 0 0 0;

  color: #000000;
`;
const CheckBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
`;

const CheckBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 14px;
  height: 14px;

  border: 1px solid #DFDFDF;
  border-radius: 4px;
  // background-color: ${props => props.isClicked ? '#FF8B48' : '#FFFFFF'};
  background-color: ${props => props.isClicked ? '#6DD49E' : '#FFFFFF'};
  
  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const Check = styled.img`
  width: 10px;
  height: 8.5px;
`;

// const ButtonWrapper = styled.div`
//   display: flex;
//   flex-direction: row;

//   justify-content: space-between;
//   align-items: center;

//   width: 90.33%;
// `;

const Button = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 48%;
  height: 48px;

  border-radius: 8px;
  background-color: ${props => props.backgroundColor};

  :hover {
    cursor: pointer;
  }
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.hover};
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 14px;

  margin-top: 47px;
`;

const YesButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 155px;
  height: 45px;

  border-radius: 23px;
  background-color: #FF8B48;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background: #FF7322;
  }
`;

const NoButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 155px;
  height: 45px;

  border-radius: 23px;
  background-color: #6DD49E;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background: #2BCB67;
  }
`;

const ButtonText = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 18px;
  
  color: #FFFFFF;

  margin: 0 0 0 0;
`;