import { React, useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';

// import CalenderImagePath from '../../image/Calender-gray.png';
import CalenderRightArrowImagePath from '../../../image/CalenderRightArrow.png';
import CalenderLeftArrowImagePath from '../../../image/CalenderLeftArrow.png';
import CalenderLeftArrow2ImagePath from '../../../image/CalenderLeftArrow2.png';
import CalenderRightArrow2ImagePath from '../../../image/CalenderRightArrow2.png';

const CalenderInput = forwardRef((props, ref) => {
  const { date, clickDate, dateClickFn } = props;

  const [today, setToday] = useState(date);
  const [isClicked, setIsClicked] = useState(false);
  const [dateList, setDateList] = useState([]);
  const [value, setValue] = useState(clickDate);


  const firstWeek = today.clone().startOf('month').week();
  const lastWeek = today.clone().endOf('month').week() === 1 ? 53 : today.clone().endOf('month').week();

  useImperativeHandle(ref, () => ({
    setValue: (val) => {
      setValue(val)
    },
    getValue: () => {
      return value;
    },
    closeCalender: () => {
      setIsClicked(false);
    }
  }));

  useEffect(() => {
    setToday(date);
  }, [date])

  useEffect(() => {
    let newDateList = [];

    for (let week = firstWeek; week <= lastWeek; week++) {
      for (let i = 0; i < 7; i++) {
        let days = today.clone().startOf('year').week(week).startOf('week').add(i, 'day');
        
        newDateList.push(days);
      } 
    }
    setDateList(newDateList);
  }, [isClicked, today, firstWeek, lastWeek]) 


  function dateClick(index) {
    setValue(dateList[index].format('YYYY.MM.DD'));
    dateClickFn(dateList[index].format('YYYY.MM.DD'));
  }

  return (
    <TopLevelWrapper>
        <TitleWrapper>
          <Arrow img={CalenderLeftArrow2ImagePath} onClick={() => setToday(today.clone().subtract(1, 'year'))} style={{ margin: `0 -7px 0 0` }} />
          <Arrow img={CalenderLeftArrowImagePath} onClick={() => setToday(today.clone().subtract(1, 'month'))}/>
          <Title>{today.format('YYYY년 MM월')}</Title>
          <Arrow img={CalenderRightArrowImagePath} onClick={() => setToday(today.clone().add(1, 'month'))}/>
          <Arrow img={CalenderRightArrow2ImagePath} onClick={() => setToday(today.clone().add(1, 'year'))} style={{ margin: `0 0 0 -7px` }} />
        </TitleWrapper>
        <DateColumnWrapper>
          <DateColumn style={{ width: `25px`, marginRight: `30px` }}>SUN</DateColumn>
          <DateColumn style={{ width: `30px`, marginRight: `30px` }}>MON</DateColumn>
          <DateColumn style={{ width: `23px`, marginRight: `30px` }}>TUE</DateColumn>
          <DateColumn style={{ width: `28px`, marginRight: `30px` }}>WED</DateColumn>
          <DateColumn style={{ width: `25px`, marginRight: `30px` }}>THU</DateColumn>
          <DateColumn style={{ width: `18px`, marginRight: `30px` }}>FRI</DateColumn>
          <DateColumn style={{ width: `24px`, marginRight: `28px` }}>SAT</DateColumn>
        </DateColumnWrapper>
        <DateWrapper>
          {
            dateList.map((data, index) => (
              data.format('MM') === today.format('MM') ?
              <Date key={index} isClicked={data.format('YYYY.MM.DD') === value} onClick={() => dateClick(index)}>
                  {data.format('D')}
              </Date>
              :
              <WithoutHoverDate key={index}>
                  {data.format('D')}
              </WithoutHoverDate>
            ))
          }
        </DateWrapper>
    </TopLevelWrapper>
  )
})

export default CalenderInput;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 408px;
  // height: 418px;

  padding-bottom: 28px;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  background-color: #FFFFFF;
  
  transition: all 0.3s ease-in-out;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  margin: 28px 0 0 22px;
`;

const Arrow = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  width: 24px;
  height: 24px;
  
  background: url(${props => props.img}) no-repeat center center;

  :hover {
    cursor: pointer;
  }
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const DateColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;

  // justify-content: center;
  // align-items: center;

  // gap: 38px;
  margin-top: 29px;
  margin-left: 28px;
  margin-bottom: 23px;
`;

const DateColumn = styled.p`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  font-family: Poppins-bold;
  font-size: 12px;

  color: #FF8B48;
  
  margin: 0 0 0 0;
`;

const DateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  width: 408px;
  margin-left: 8px;
`;

const Date = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  width: 44px;
  height: 44px;

  border-radius: 44px;

  margin: 5.5px 5.5px 5.5px 5.5px;

  background-color: ${props => props.isClicked ? `#FF7322` : `#FFFFFF`};
  
  font-family: Poppins-Medium;
  font-size: 14px;

  color: ${props => props.isClicked ? `#FFFFFF` : `#595959`};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #FF7322;
    color: #FFFFFF;

    font-fmaily: Poppins-Bold;
    font-size: 14px;
  }
`;

const WithoutHoverDate = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  width: 44px;
  height: 44px;

  border-radius: 44px;

  margin: 5.5px 5.5px 5.5px 5.5px;

  background-color: #FFFFFF;

  font-family: Poppins-Regular;
  font-size: 14px;

  color: #777777;

  :hover {
    cursor: default;
  }
`;