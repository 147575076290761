import React, { useRef } from "react";
import styled from "styled-components";
import moment from "moment";

import TrashImage from '../../image/TrashIcon.png';
import TermsIcon from '../../image/TermsIcon.png';

export default function TermBox(props) {
  const { 
    isClicked, 
    data,
    index,
    onClickTermsStatus,
    onClickViewTerms,
    onClickEditTerms,
    onClickDeleteTerms
  } = props;

  const DeleteRef = useRef(null);
  const StatusRef = useRef(null);

  // 몸체 클릭시 다른 함수 실행 안되게 거르기 //
  function onClickTotalBox(e) {
    if (!isClicked) {
      onClickViewTerms(data);
    }
    else {
      if (DeleteRef.current !== e.target 
        && StatusRef.current !== e.target
        && !DeleteRef.current.contains(e.target)
        && !StatusRef.current.contains(e.target)) {
        onClickEditTerms(data);
      }
    }
  }

  return (
    <TermBoxView isClicked={isClicked} onClick={onClickTotalBox} status={isClicked || data.status}>
    {
      isClicked &&
      <DeleteButtonWrapper>
        <DeleteButton ref={DeleteRef} onClick={() => onClickDeleteTerms(data)}>
          <Image width={16} height={16} src={TrashImage} />
        </DeleteButton>
      </DeleteButtonWrapper>
    }
    <ImageWrapper>
      <Image width={70} height={84} src={TermsIcon} />
    </ImageWrapper>
    <TermInfoWrapper>
      <TermTitleTextWrapper>
        <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#777777' hover='pointer'>{data.title}</Text>
      </TermTitleTextWrapper>
      <Text fontFamily='NotoSansKR-Regular' fontSize={10} color='#777777' hover='pointer'>게시일 : {moment(data.createdDate).format('YYYY.MM.DD')}</Text>
      <Text fontFamily='NotoSansKR-Regular' fontSize={10} color='#777777' hover='pointer'>수정일 : {moment(data.modifiedDate).format('YYYY.MM.DD')}</Text>
    </TermInfoWrapper>
    {
      isClicked &&
      <StatusBox>
        <SlideBoxWrappper ref={StatusRef} onClick={() => onClickTermsStatus(index)}>
          <SlideOutBox isClicked={data.status}>
            <SlideInBox isClicked={data.status} />
          </SlideOutBox>
          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#777777' cursor='default'>{data.status ? '활성' : '비활성'}</Text>
        </SlideBoxWrappper>
      </StatusBox>
    }
  </TermBoxView>
  )
}

const TermBoxView = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 150px;
  height: ${props => props.isClicked ? `206px` : `150px`};

  border-radius: 15px;

  opacity: ${props => props.status ? `1` : `0.6`};

  overflow: hidden;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const DeleteButtonWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-end;

  padding: 0px 10px;
  margin-top: 10px;

  width: calc(100% - 20px);
`;

const DeleteButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 32px;
  height: 32px;

  border: 1px solid #EC6060;
  border-radius: 10px;
  background-color: #FF9898;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #D92828;
    background-color: #FF3131;
  }
`;

const StatusBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  padding: 0px 12px;

  width: 126px;
  height: 56px;

  background-color: #FFFFFF;
`;

const SlideBoxWrappper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const SlideOutBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 70px;
  height: 32px;

  // border: ${props => props.isClicked ? '1px solid #FF8B48' : '1px solid #DFDFDF'};
  border-radius: 8px;
  background-color: ${props => props.isClicked ? '#6DD49E' : '#CBCBCB'};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const SlideInBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-left: ${props => props.isClicked ? '41px' : '4px'};
  width: 24px;
  height: 24px;

  border-radius: 6px;
  // background-color: ${props => props.isClicked ? '#FFFFFF' : '#DFDFDF'};
  background-color: #FFFFFF;

  transition: all 0.3s ease-in-out;
`;

const TermInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  padding: 2px 10px 5px 10px;

  width: calc(100% - 20px);
  height: 49px;

  background-color: #FCFCFC;
`;

const TermTitleTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  height: 21px;

  overflow: hidden;
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-end;
  align-items: center;

  width: 100%;
  height: 94px;

  background-color: #FFFFFF;
`;

const Image = styled.img`
  // width: 70px;
  // height: 84px;
  width: ${props => props.width}px;
  height: ${props => props.height}px;

  object-fit: contain;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  margin: 0 0 0 0;

  color: ${props => props.color};

  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;