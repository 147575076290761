import { React, forwardRef, useState, useRef, useImperativeHandle, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import FetchModule from '../../Model/Share/Network/FetchModule';

import SelectOnPath from '../../image/SelectOn.png';
import SelectOffPath from '../../image/SelectOff.png';
import WhitePlusPath from '../../image/RepPlusImage.png';

export default function NewClassSelectBox(props) {
	const { selectClickFn, value, setValue, options } = props;
	const history = useHistory();

	const [isClicked, setIsClicked] = useState(false);
	// const [options, setOptions] = useState([{ id : -1, name: '선택' }]);
	// const [categoryId, setCategoryId] = useState('');
	// const [defaultVal, setDefaultVal] = useState({ id: -1, name: '선택' });

	const TopLevelRef = useRef();
	const SelectRef = useRef();
	const OptionTotalRef = useRef();

	// useImperativeHandle(ref, () => ({
	// 	setValue: (val) => {
	// 		// setDefaultVal(val);
	// 		setValue(val);
	// 	},
	// 	getValue: () => {
	// 		// return defaultVal;
	// 		return value;
	// 	},
	// 	setIsClicked: () => {
	// 		SelectRef.current.style.border = '1px solid #CBCBCB';
	// 		SelectRef.current.style.height = `30px`;
	// 		SelectRef.current.style.overflow = 'hidden';
	// 		// setDefaultVal({name: '전체'});
	// 		setIsClicked(false);
	// 	}
	// }))

	// useEffect(() => {
	//   setOptions([]);

	//   const fetchModule = new FetchModule();
	//   (
	//     async () => {
	//       const data = await fetchModule.getDataVersion2('lesson/zone', 'GET');

	//       if (data.status === 200) {
	// 				let newOptions = [];

	// 				newOptions.push({ id : -1, name: '선택' });
	// 				newOptions = newOptions.concat(data.data);
	// 				console.log(newOptions);
	// 				setOptions(newOptions);
	// 			}
	//       else if (data.status === 401) history.push('/');
	//     }
	//   )();
	// }, [history])

	// 컴포넌트 밖을 클릭하면 셀렉트 창 닫기
	useEffect(() => {
		document.addEventListener("mousedown", onClickOutSide);

		return () => {
			document.removeEventListener("mousedown", onClickOutSide);
		}
	}, [])

	// 컴포넌트 밖을 클릭하면 셀렉트 창 닫기
	function onClickOutSide(e) {
		if (TopLevelRef.current !== e.target && !TopLevelRef.current.contains(e.target)) {
			SelectRef.current.style.border = '1px solid #CBCBCB';
			SelectRef.current.style.height = `30px`;
			SelectRef.current.style.overflow = 'hidden';
			OptionTotalRef.current.style.height = `30px`;
			OptionTotalRef.current.style.overflow = 'hidden';
			setIsClicked(false);
		}
	}

	function onClick() {
		selectClickFn();
		if (isClicked) {
			SelectRef.current.style.border = '1px solid #CBCBCB';
			SelectRef.current.style.height = `30px`;
			OptionTotalRef.current.style.height = `30px`;
			SelectRef.current.style.overflow = 'hidden';
			OptionTotalRef.current.style.overflow = 'hidden';
			setIsClicked(false);
		}
		else {
			SelectRef.current.style.border = '1px solid #FF8B48';
			// SelectRef.current.style.height = `${(32 * (options.length)) + 38}px`;
			// OptionTotalRef.current.style.height = `${32 * (options.length)}px`;
			// SelectRef.current.style.maxHeight = '330px';
			SelectRef.current.style.height = `${(32 * (options.length)) + 58}px`;
			OptionTotalRef.current.style.height = `${32 * (options.length) + 58}px`;
			SelectRef.current.style.maxHeight = '330px';
			OptionTotalRef.current.style.overflowY = 'scroll';
			SelectRef.current.style.overflowX = 'none';
			setIsClicked(true);
		}
	}

	// 브레이크 타임클릭을 위해 선택 컬렁 배경색 설정 //
	function onCalcBackgroundColor() {
		if (isClicked) return 'transparent';
		else if (value.id === -2) return '#E0E0E0';
		else return 'transparent';
	}

	// 브레이크 타임을 위한 선택 컬럼 호버링 배경색 설정 //
	function onCalcHoverBackgroundColor() {
		if (isClicked) return '#FF8B48';
		else if (value.id === -2) return '#E0E0E0';
		else return 'transparent';
	}

	// 수업 클릭시 //
	function optionalClick(index) {
		setValue(options[index]);
	}

	// 브레이크 타임 버튼 클릭시 //
	function onClickBreakTime() {
		let breakTime = {
			id: -2,
			name: '브레이크 타임',
			maxBookedPeople: 0,
			teacherIds: '',
			teachers: [],
			time: 0
		}

		setValue(breakTime);
	}

	// 신규 수업 개설하기 버튼 클릭시 //
	function onClickNewClass() {
		history.push('/setting');
	}

	return (
		<TopLevelWrapper ref={TopLevelRef}>
			<SelectBox
				ref={SelectRef}
				isClicked={isClicked}
				buttonPath={isClicked ? SelectOnPath : SelectOffPath}
				onClick={onClick}>
				<OptionTotalWrapper ref={OptionTotalRef}>
					{
						isClicked ?
							options.map((data, index) =>
								<OptionWrapper
									key={index} 
									isClicked={isClicked} 
									backgroundColor={onCalcBackgroundColor()} 
									hoverBackgroundColor={onCalcHoverBackgroundColor()}
									onClick={() => optionalClick(index)}>
									<Option>{data.name}</Option>
								</OptionWrapper>
							)
							:
							<OptionWrapper 
								isClicked={isClicked} 
								backgroundColor={onCalcBackgroundColor()} 
								hoverBackgroundColor={onCalcHoverBackgroundColor()}>
								<Option>
									{
										// defaultVal.name
										value.name
									}
								</Option>
							</OptionWrapper>
					}
				{
					isClicked &&
					<BreakTimeWrapper onClick={onClickBreakTime}>
						<BreakTimeText>브레이크 타임</BreakTimeText>
					</BreakTimeWrapper>
				}
				{
					isClicked &&
					<GoSettingWrapper onClick={onClickNewClass}>
						<GoSettingText>신규수업 개설하기</GoSettingText>
						<GoSettingBox>
							<GoSettingImage src={WhitePlusPath} />
						</GoSettingBox>
					</GoSettingWrapper>
				}
				</OptionTotalWrapper>
			</SelectBox>
		</TopLevelWrapper>
	)
}

const TopLevelWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 8px;
  
	// width: 282px;
	width: 588px;
	height: 30px;
`;

const SelectBox = styled.div`
	position: absolute;
	display: flex;
	flex-direction: column;
	// justify-content: center;
	align-items: flex-start;

	// width: 280px;
	width: 586px;
	height: 30px;

  z-index: 3;

	border: 1px solid #CBCBCB;
	border-radius: 8px;
	overflow: hidden;

  background: url(${props => props.buttonPath}) no-repeat 99% ${props => props.isClicked ? `3` : `55`}%;
	background-color: rgba(255, 255, 255, 1);
  transition: all 0.3s ease-in-out;

	:hover {
		cursor: pointer;
	}

	&::-webkit-scrollbar {
		width: 9px;
	}

	&::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 20px #FF8B48;
		border: 3px solid transparent;
		height: 25px;
		// background-color: #FF8B48;
		border-radius: 200px;
	}

	&::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px transparent;
		border: 4px solid transparent;
		background-color: transparent;
	}

	&::-webkit-scrollbar-track-piece {
		background-color: transparent;
	}
`;

const OptionTotalWrapper = styled.div`
	display: flex;
	flex-direction: column;

	width: 100%;
	height: 30px;

  overflow: hidden;

	:hover {
		cursor: pointer;
	}

	&::-webkit-scrollbar {
		// width: 9px;
		width: 0px;
	}

	&::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 20px #FF8B48;
		border: 3px solid transparent;
		height: 25px;
		// background-color: #FF8B48;
		border-radius: 200px;
	}

	&::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px transparent;
		border: 4px solid transparent;
		background-color: transparent;
	}

	&::-webkit-scrollbar-track-piece {
		background-color: transparent;
	}
`;

const OptionWrapper = styled.div`
	display: flex;
	flex-direction: column;

	width: 100%;

	background-color: ${props => props.backgroundColor};

	:hover {
		cursor: pointer;
		// background-color: ${props => props.isClicked ? `#FF8B48` : `transparent`};
		background-color: ${props => props.hoverBackgroundColor};
	}

  transition: all 0.3s ease-in-out;
`;

const BreakTimeWrapper = styled.div`
	display: flex;
	flex-direction: row;

	justify-content: center;
	align-items: center;

	width: 100%;
	height: 29px;
	min-height: 29px;

	background-color: #E0E0E0;

	gap: 4px;

	:hover {
		cursor: pointer;
	}
`;

const BreakTimeText = styled.p`
	font-family: NotoSansKR-Medium;
	font-size: 14px;

	color: #000000;

	margin: 0 0 0 0;
	padding: 0 0 0 0;

	:hover {
		cursor: pointer;
	}
`;

const GoSettingWrapper = styled.div`
	display: flex;
	flex-direction: row;

	justify-content: center;
	align-items: center;

	// padding: 6px 0px 6px 0px;
	// margin-bottom: 6px;
	// padding-bottom: 6px;

	width: 100%;
  // height: 32px;
	height: 29px;
	min-height: 29px;


	background-color: #FF8B48;

	gap: 4px;

	:hover {
		cursor: pointer;
	}
`;

const GoSettingText = styled.p`
	font-family: NotoSansKR-Regular;
	font-size: 14px;

	color: #FFFFFF;

	margin: 0 0 0 0;
	padding: 0 0 0 0;

	:hover {
		cursor: pointer;
	}
`;

const GoSettingBox = styled.div`
	display: flex;
	flex-direction: column;

	justify-content: center;
	align-items: center;

	width: 16px;
	height: 16px;

	border-radius: 4px;
	background-color: #FF8B48;

	:hover {
		cursor: pointer;
	}
`;

const GoSettingImage = styled.img`
	width: 16px;
	height: 16px;

	:hover {
		cursor: pointer;
	}
`;

const Option = styled.p`
	font-family: NotoSansKR-Regular;
	font-size: 14px;

	margin: 6px 0 6px 8px;
`;