import { React, useState, useEffect } from 'react';
import styled from 'styled-components';

import NewNormalMovementBox from './LegacyNormalMovementBox';
import TagExplanationModal from '../TagExplanationModal';
import VideoViewModal from '../VideoViewModal';

// Image Import //
import CheckImage from '../../../image/WhiteCheck.png';
import NextRepsImage from '../../../image/NextRepsImage.png';
import InfoIcon from '../../../image/InfomationIcon.png';
// Image Import //

export default function LegacyNormalSectionBox(props) {
  const { index, sectionData } = props;

  const [title, setTitle] = useState('');
  const [scoreKeyword, setScoreKeyword] = useState('점수 없음');
  const [scorePriority, setScorePriority] = useState('');
  const [videoLink, setVideoLink] = useState('');
  const [dataReps, setDataReps] = useState('');

  const [isClickedTagExplanation, setIsClickedTagExplanation] = useState(false);
  const [isClickedVideoView, setIsClickedVideoView] = useState(false);

  useEffect(() => {
    let newTitle = '';

    if (sectionData.tag === 'ForTime-Rounds') {
      if (sectionData.round === 1) newTitle = 'For Time';
      else newTitle = sectionData.round + ' Rounds For Time';
    }
    else if (sectionData.tag === 'ForTime-Different Reps') {
      newTitle = 'For Time ';

      for (var i = 0; i < sectionData.reps.length; i++) {
        if (i === 0) newTitle += sectionData.reps[i];
        else newTitle += '-' + sectionData.reps[i];
      }

      // DifferentReps Data Change //
      let newReps = '';

      if (sectionData.reps.length <= 6) {
        for (var i = 0; i < sectionData.reps.length; i++) {
          if (i !== 0) newReps += ' - ';
          newReps += parseInt(sectionData.reps[i]);
        }
      }
      else {
        newReps += parseInt(sectionData.reps[0]) + ' - '
          + parseInt(sectionData.reps[1]) + ' - '
          + parseInt(sectionData.reps[2]) + ' - '
          + parseInt(sectionData.reps[3]) + ' - ... - '
          + parseInt(sectionData.reps[sectionData.reps.length - 1]);
      }

      setDataReps(newReps);
    }
    else if (sectionData.tag === 'AMRAP') {
      let minute = parseInt(sectionData.exerciseTime / 60);
      let second = parseInt(sectionData.exerciseTime % 60);

      if (minute > 0) {
        if (second > 0) newTitle += minute + ' Minute ' + second + ' Second AMRAP';
        else newTitle += minute + ' Minute AMRAP';
      }
      else {
        if (second > 0) newTitle += second + ' Second AMRAP';
        else newTitle += 'AMRAP';
      }
    }
    else if (sectionData.tag === 'EMOM') {
      let minute = parseInt((sectionData.exerciseTime * sectionData.round) / 60);
      let second = parseInt((sectionData.exerciseTime * sectionData.round) % 60);

      if (minute > 0) {
        if (second > 0) newTitle += minute + ' Minute ' + second + ' Second EMOM';
        else newTitle += minute + ' Minute EMOM';
      }
      else {
        if (second > 0) newTitle += second + ' Second EMOM';
        else newTitle += 'EMOM';
      }
    }
    else if (sectionData.tag === 'TABATA') {
      let exerciseMinute = parseInt(sectionData.exerciseTime / 60);
      let exerciseSecond = parseInt(sectionData.exerciseTime % 60);
      let restMinute = parseInt(sectionData.restTime / 60);
      let restSecond = parseInt(sectionData.restTime % 60);

      newTitle += sectionData.round + ' Rounds TABATA ';

      if (exerciseMinute > 0) {
        if (exerciseSecond > 0) newTitle += exerciseMinute + ' m ' + exerciseSecond + ' s ON / ';
        else newTitle += exerciseMinute + ' m ON / ';
      }
      else {
        if (exerciseSecond > 0) newTitle += exerciseSecond + ' s ON / ';
        else newTitle += ' 0 s ON / ';
      }

      if (restMinute > 0) {
        if (restSecond > 0) newTitle += restMinute + ' m ' + restSecond + ' s OFF ';
        else newTitle += restMinute + ' m OFF ';
      }
      else {
        if (restSecond > 0) newTitle += restSecond + ' s OFF ';
        else newTitle += ' 0 s OFF ';
      }

      newTitle += 'With no break between movement'
    }
    else if (sectionData.tag === 'SINGLE') {
      if (sectionData.mainExerciseList.length === 1) newTitle += sectionData.mainExerciseList[0].movementName;
      else newTitle += 'Multiple Single';
    }
    else if (sectionData.tag === 'Custom') {
      // newTitle += sectionData.customTitle;
      newTitle += 'Custom';

      // '점수 없음', '시간', '횟수', '라운드 + 횟수', '거리', '무게', '포인트', '피크와트', '최고 속도' //
      if (sectionData.scoreKeyword === 'Not Scored') setScoreKeyword('점수 없음');
      else if (sectionData.scoreKeyword === 'Time') setScoreKeyword('시간');
      else if (sectionData.scoreKeyword === 'Reps') setScoreKeyword('렙수');
      else if (sectionData.scoreKeyword === 'Rounds And Reps') setScoreKeyword('라운드 + 렙수');
      else if (sectionData.scoreKeyword === 'Distance') setScoreKeyword('거리');
      else if (sectionData.scoreKeyword === 'Weight') setScoreKeyword('무게');
      else if (sectionData.scoreKeyword === 'Points') setScoreKeyword('포인트');
      else if (sectionData.scoreKeyword === 'Peak Watts') setScoreKeyword('피크와트');
      else if (sectionData.scoreKeyword === 'Max Speed') setScoreKeyword('최고 속도');

      if (sectionData.scorePriority === 'heigher') setScorePriority('높을 수록');
      else if (sectionData.scorePriority === 'lower') setScorePriority('낮을 수록');
    }
    // console.log(sectionData);
    // console.log(newTitle);
    setTitle(newTitle);
  }, [sectionData])

  // 동작 영상 모달 열기 클릭시 //
  function onClickMovementVideoViewModal(division, index) {
    if (division === 'buyin') {
      if (sectionData.buyinExerciseList[index].customVideoLinkId === undefined) setVideoLink(sectionData.buyinExerciseList[index].movement.videoLinkId);
      else setVideoLink(sectionData.buyinExerciseList[index].customVideoLinkId);
    }
    else if (division === 'buyin.scale') {
      if (sectionData.buyinScaleOptionExerciseList[index].customVideoLinkId === undefined) setVideoLink(sectionData.buyinScaleOptionExerciseList[index].movement.videoLinkId);
      else setVideoLink(sectionData.buyinScaleOptionExerciseList[index].customVideoLinkId);
    }
    else if (division === 'main') {
      if (sectionData.mainExerciseList[index].customVideoLinkId === undefined) setVideoLink(sectionData.mainExerciseList[index].movement.videoLinkId);
      else setVideoLink(sectionData.mainExerciseList[index].customVideoLinkId);
    }
    else if (division === 'main.scale') {
      if (sectionData.mainScaleOptionExerciseList[index].customVideoLinkId === undefined) setVideoLink(sectionData.mainScaleOptionExerciseList[index].movement.videoLinkId);
      else setVideoLink(sectionData.mainScaleOptionExerciseList[index].customVideoLinkId);
    }
    else if (division === 'main.emom') {
      if (sectionData.emomExerciseList[index].customVideoLinkId === undefined) setVideoLink(sectionData.emomExerciseList[index].movement.videoLinkId);
      else setVideoLink(sectionData.emomExerciseList[index].customVideoLinkId);
    }
    else if (division === 'main.emom.scale') {
      if (sectionData.mainScaleOptionExerciseList[index].customVideoLinkId === undefined) setVideoLink(sectionData.mainScaleOptionExerciseList[index].movement.videoLinkId);
      else setVideoLink(sectionData.mainScaleOptionExerciseList[index].customVideoLinkId);
    }
    else if (division === 'buyout') {
      if (sectionData.buyoutExerciseList[index].customVideoLinkId === undefined) setVideoLink(sectionData.buyoutExerciseList[index].movement.videoLinkId);
      else setVideoLink(sectionData.buyoutExerciseList[index].customVideoLinkId);
    }
    else if (division === 'buyout.scale') {
      if (sectionData.buyoutScaleOptionExerciseList[index].customVideoLinkId === undefined) setVideoLink(sectionData.buyoutScaleOptionExerciseList[index].movement.videoLinkId);
      else setVideoLink(sectionData.buyoutScaleOptionExerciseList[index].customVideoLinkId);
    }

    setIsClickedVideoView(true);
  }

  // // EMOM 시간 표시 //
  // function viewEMOMTimeTitle(index) {
  //   let multiple = index / sectionData.mainExerciseList.length;
  //   let time = sectionData.exerciseTime;
  //   let nowTime = time * multiple;
  //   let nextTime = time * (multiple + 1);

  //   // 현재 시작시간이 분단위로 나눠떨어질 경우 //
  //   if (nowTime % 60 === 0) {
  //     // 끝나는 시간이 분단위로 나눠떨어질 경우 //
  //     if (nextTime % 60 === 0) return (nowTime / 60) + ' Min ' + ' - ' + (nextTime / 60) + ' Min';
  //     else return (nowTime / 60) + ' Min ' + ' - ' + parseInt(nextTime / 60) + ' Min ' + (nextTime % 60) + ' Sec';
  //   }
  //   else {
  //     // 끝나는 시간이 분단위로 나눠떨어질 경우 //
  //     if (nextTime % 60 === 0) return parseInt(nowTime / 60) + ' Min ' + (nowTime % 60) + ' Sec ' + ' - ' + (nextTime / 60) + ' Min';
  //     else return parseInt(nowTime / 60) + ' Min ' + (nowTime % 60) + ' Sec ' + ' - ' + parseInt(nextTime / 60) + ' Min ' + (nextTime % 60) + ' Sec';
  //   }
  // }

  // // EMOM 대기 안내문 표시 //
  // function viewEMOMWaitMent(index) {
  //   let multiple = index / sectionData.mainExerciseList.length;
  //   let time = sectionData.exerciseTime;
  //   let nextTime = time * multiple;

  //   // 끝나는 시간이 분단위로 나눠떨어질 경우 //
  //   if (nextTime % 60 === 0) return '다음 ' + (nextTime / 60) + ' 분이 될때까지 기다리세요';
  //   else return '다음 ' + parseInt(nextTime / 60) + ' 분 ' + (nextTime % 60) + ' 초가 될때까지 기다리세요';
  // }


  // EMOM 시간 표시 //
  function viewEMOMTimeTitle(index) {
    let time = sectionData.exerciseTime;
    let nowTime = time * index;
    let nextTime = time * (index + 1);

    if (time === 0) return '0 Min - 0 Min';
    else if (index === 0) {
      nextTime = time;

      if (nextTime % 60 === 0) return '0 Min - ' + (nextTime / 60) + ' Min';
      else return '0 Min - ' + parseInt(nextTime / 60) + ' Min ' + (nextTime % 60) + ' Sec';
    }
    // 현재 시작시간이 분단위로 나눠떨어질 경우 //
    else if (nowTime % 60 === 0) {
      // 끝나는 시간이 분단위로 나눠떨어질 경우 //
      if (nextTime % 60 === 0) return (nowTime / 60) + ' Min ' + ' - ' + (nextTime / 60) + ' Min';
      else return (nowTime / 60) + ' Min ' + ' - ' + parseInt(nextTime / 60) + ' Min ' + (nextTime % 60) + ' Sec';
    }
    else {
      // 끝나는 시간이 분단위로 나눠떨어질 경우 //
      if (nextTime % 60 === 0) return parseInt(nowTime / 60) + ' Min ' + (nowTime % 60) + ' Sec ' + ' - ' + (nextTime / 60) + ' Min';
      else return parseInt(nowTime / 60) + ' Min ' + (nowTime % 60) + ' Sec ' + ' - ' + parseInt(nextTime / 60) + ' Min ' + (nextTime % 60) + ' Sec';
    }
  }

  // EMOM 대기 안내문 표시 //
  function viewEMOMWaitMent(index) {
    let time = sectionData.exerciseTime;
    let nextTime = time * (index + 1);

    if (time === 0) return '다음 0분이 될때까지 기다리세요';
    // 끝나는 시간이 분단위로 나눠떨어질 경우 //
    else if (nextTime % 60 === 0) return '다음 ' + (nextTime / 60) + ' 분이 될때까지 기다리세요';
    else return '다음 ' + parseInt(nextTime / 60) + ' 분 ' + (nextTime % 60) + ' 초가 될때까지 기다리세요';
  }


  return (
    <TopLevelWrapper>
      {isClickedTagExplanation && <TagExplanationModal close={() => setIsClickedTagExplanation(!isClickedTagExplanation)} />}
      {isClickedVideoView && <VideoViewModal data={videoLink} close={() => setIsClickedVideoView(!isClickedVideoView)} />}
      <DivisionLine />
      <SectionDivisionWrapper>
        <SectionNumberBox>{String.fromCharCode(index + 65)}</SectionNumberBox>
        <SectionTitleBox>
          <SectionTitleWrapper>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>{title}</Text>
          </SectionTitleWrapper>
        </SectionTitleBox>
      </SectionDivisionWrapper>
      {
        (sectionData.youtubeLink !== undefined && sectionData.youtubeLink !== '') &&
        <VideoViewWrapper>
          <iframe
            width="100%"
            height="100%"
            src={(sectionData.youtubeLink !== undefined && sectionData.youtubeLink !== '') ? `https://www.youtube.com/embed/` + sectionData.youtubeLink.replace('https://www.youtube.com/watch?v=', '').replace('https://youtube.com/shorts/', '').replace('https://youtu.be/', '') : ''}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube" />
        </VideoViewWrapper>
      }
      {
        (sectionData.youtubeLinks !== undefined) &&
        sectionData.youtubeLinks.map((linkData, idx) => (
          <VideoViewWrapper style={{ marginTop: '8px' }}>
            <iframe
              width="100%"
              height="100%"
              src={`https://www.youtube.com/embed/` + linkData.replace('https://www.youtube.com/watch?v=', '').replace('https://youtube.com/shorts/', '').replace('https://youtu.be/', '')}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube" />
          </VideoViewWrapper>
        ))
      }
      {
        // Time Limit Setting //
        (sectionData.tag === 'ForTime-Rounds' || sectionData.tag === 'ForTime-Different Reps' || sectionData.tag === 'SINGLE' || sectionData.tag === 'Custom') &&
        <TimeLimitWrapper>
          <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>시간 제한</Text>
          <TimeComponentWrapper>
            <CheckBox isClicked={!sectionData.isUseTimeLimit}>
              <Check src={CheckImage} />
            </CheckBox>
            <Text fontFamily='Poppins-Medium' fontSize={14} color='#828282'>제한 없음</Text>
            <TimeDataBoxWrapper>
              <TimeDataBox isClicked={!sectionData.isUseTimeLimit}>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{parseInt(sectionData.timeLimit / 60) > 0 ? parseInt(sectionData.timeLimit / 60) : 0}</Text>
                <Text fontFamily='Poppins-Medium' fontSize={14} color='#BDBDBD'>분</Text>
              </TimeDataBox>
              <TimeDataBox isClicked={!sectionData.isUseTimeLimit}>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{parseInt(sectionData.timeLimit % 60) > 0 ? parseInt(sectionData.timeLimit % 60) : 0}</Text>
                <Text fontFamily='Poppins-Medium' fontSize={14} color='#BDBDBD'>초</Text>
              </TimeDataBox>
            </TimeDataBoxWrapper>
          </TimeComponentWrapper>
          {(sectionData.tag !== 'SINGLE' && sectionData.tag !== 'Custom') && <DivisionLine />}
        </TimeLimitWrapper>
        // Time Limit Setting //
      }
      {
        // Buy in Setting //
        (sectionData.tag === 'ForTime-Rounds' || sectionData.tag === 'ForTime-Different Reps' || sectionData.tag === 'AMRAP') &&
        <BuyWrapper isAMRAP={sectionData.tag === 'AMRAP'}>
          <BuyCheckBoxWrapper>
            <CheckBox isClicked={sectionData.isUseBuyin}>
              <Check src={CheckImage} />
            </CheckBox>
            <Text fontFamily='Poppins-Regular' fontSize={14} color='#4F4F4F'>Buy in</Text>
          </BuyCheckBoxWrapper>
          {
            sectionData.buyinExerciseList.map((data, idx) => (
              <NewNormalMovementBox
                key={idx}
                data={data}
                scaleOptionExerciseList={sectionData.buyinScaleOptionExerciseList}
                idx={idx}
                tag='None'
                reps={dataReps}
                division='buyin'
                onClickMovementVideo={onClickMovementVideoViewModal} />
            ))
          }
          {
            sectionData.buyinNote !== '' &&
            <NoteViewBox>
              <NoteSection>
                <Text style={{ marginLeft: '13px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF'>Notes</Text>
              </NoteSection>
              <NoteTextAreaWrapper>
                <NoteTextWrapper>
                  <NoteText>
                    {sectionData.buyinNote}
                  </NoteText>
                </NoteTextWrapper>
              </NoteTextAreaWrapper>
            </NoteViewBox>
          }
        </BuyWrapper>
        // Buy In Setting //
      }
      {/* Main */}
      <MainComponentWrapper>
        <DivisionLine />
        <MainIconWrapper>
          <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>메인 운동</Text>
          <Icon src={InfoIcon} onClick={() => setIsClickedTagExplanation(true)} />
        </MainIconWrapper>
        <MainSelectBoxWrapper>
          <TagBox>
            {/* <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F'>{sectionData.tag.split('-')[0]}</Text> */}
            <Text fontFamily='Poppins-SemiBold' fontSize={14} color='#4F4F4F'>{sectionData.tag.split('-')[0]}</Text>
          </TagBox>
          <TagBox isEmpty={sectionData.tag.split('-')[1] === undefined}>
            {/* <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F'>{sectionData.tag.split('-')[1] === undefined ? '-' : sectionData.tag.split('-')[1]}</Text> */}
            <Text fontFamily='Poppins-SemiBold' fontSize={14} color='#4F4F4F'>{sectionData.tag.split('-')[1] === undefined ? '-' : sectionData.tag.split('-')[1]}</Text>
          </TagBox>
        </MainSelectBoxWrapper>
        {
          // Section Tag가 For Time - Rounds일 경우 //
          sectionData.tag === 'ForTime-Rounds' &&
          <MainExerciseTagWrapper>
            <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>라운드</Text>
            <RoundBox>
              <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{sectionData.round}</Text>
              <Text fontFamily='Poppins-Medium' fontSize={14} color='#BDBDBD'>R</Text>
            </RoundBox>
          </MainExerciseTagWrapper>
        }
        {
          // Section Tag가 For Time - Different Reps일 경우 //
          sectionData.tag === 'ForTime-Different Reps' &&
          <MainExerciseTagWrapper>
            <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>랩 수</Text>
            <RepsInputWrapper>
              {
                sectionData.reps.map((data, idx) => (
                  <RepsWrapper key={idx}>
                    <RepsBox>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{data}</Text>
                    </RepsBox>
                    {sectionData.reps.length - 1 !== idx && <NextRepsIcon src={NextRepsImage} />}
                  </RepsWrapper>
                ))
              }
            </RepsInputWrapper>
          </MainExerciseTagWrapper>
        }
        {
          // Section Tag가 AMRAP일 경우 //
          sectionData.tag === 'AMRAP' &&
          <MainExerciseTagWrapper>
            <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>운동 시간</Text>
            <TimeWrapper>
              <TagBox>
                <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{parseInt(sectionData.exerciseTime / 60)}</Text>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>분</Text>
              </TagBox>
              <TagBox>
                <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{parseInt(sectionData.exerciseTime % 60)}</Text>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>초</Text>
              </TagBox>
            </TimeWrapper>
          </MainExerciseTagWrapper>
        }
        {
          // Section Tag가 EMOM일 경우 //
          sectionData.tag === 'EMOM' &&
          <MainExerciseTagWrapper>
            <TimeTextWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>라운드 / 운동 시간</Text>
              <TextInfoWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={12} color='#595959' style={{ marginRight: '4px' }}>총</Text>
                {
                  parseInt(sectionData.round * sectionData.exerciseTime / 60) > 0 &&
                  <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#6DD49E'>{parseInt(sectionData.round * sectionData.exerciseTime / 60) + '분'}</Text>
                }
                {
                  parseInt(sectionData.round * sectionData.exerciseTime % 60) > 0 &&
                  <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#6DD49E' style={{ marginLeft: '4px' }}>{parseInt(sectionData.round * sectionData.exerciseTime % 60) + '초'}</Text>
                }
                <Text fontFamily='NotoSansKR-Medium' fontSize={12} color='#595959'>동안 진행됩니다</Text>
              </TextInfoWrapper>
            </TimeTextWrapper>
            <RoundBox>
              <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{sectionData.round}</Text>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>R</Text>
            </RoundBox>
            <TimeWrapper>
              <TagBox>
                <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{parseInt(sectionData.exerciseTime / 60)}</Text>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>분</Text>
              </TagBox>
              <TagBox>
                <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{parseInt(sectionData.exerciseTime % 60)}</Text>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>초</Text>
              </TagBox>
            </TimeWrapper>
          </MainExerciseTagWrapper>
        }
        {
          // Section Tag가 TABATA일 경우 //
          sectionData.tag === 'TABATA' &&
          <MainExerciseTagWrapper>
            <TimeTextWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>동작 별 라운드</Text>
              <TextInfoWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={12} color='#595959' style={{ marginRight: '4px' }}>총</Text>
                {
                  parseInt(((sectionData.round * sectionData.exerciseTime) + (sectionData.round > 0 ? (sectionData.round - 1) * sectionData.restTime : 0)) / 60) > 0 &&
                  <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#6DD49E'>{parseInt(((sectionData.round * sectionData.exerciseTime) + (sectionData.round > 0 ? (sectionData.round - 1) * sectionData.restTime : 0)) / 60) + '분'}</Text>
                }
                {
                  parseInt(((sectionData.round * sectionData.exerciseTime) + (sectionData.round > 0 ? (sectionData.round - 1) : 0 * sectionData.restTime)) % 60) > 0 &&
                  <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#6DD49E' style={{ marginLeft: '4px' }}>{parseInt(((sectionData.round * sectionData.exerciseTime) + ((sectionData.round > 0 ? (sectionData.round - 1) : 0) * sectionData.restTime)) % 60) + '초'}</Text>
                }
                <Text fontFamily='NotoSansKR-Medium' fontSize={12} color='#595959'>동안 진행됩니다</Text>
              </TextInfoWrapper>
            </TimeTextWrapper>
            <RoundBox>
              <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{sectionData.round}</Text>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>R</Text>
            </RoundBox>
            <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>동작 별 운동 시간</Text>
            <TimeWrapper>
              <TagBox>
                <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{parseInt(sectionData.exerciseTime / 60)}</Text>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>분</Text>
              </TagBox>
              <TagBox>
                <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{parseInt(sectionData.exerciseTime % 60)}</Text>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>초</Text>
              </TagBox>
            </TimeWrapper>
            <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>동작 별 쉬는 시간</Text>
            <TimeWrapper>
              <TagBox>
                <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{parseInt(sectionData.restTime / 60)}</Text>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>분</Text>
              </TagBox>
              <TagBox>
                <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{parseInt(sectionData.restTime % 60)}</Text>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>초</Text>
              </TagBox>
            </TimeWrapper>
          </MainExerciseTagWrapper>
        }
        {
          sectionData.tag === 'Custom' &&
          <MainExerciseTagWrapper>
            <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>점수 기준</Text>
            <MainSelectBoxWrapper>
              <TagBox>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>{scoreKeyword}</Text>
              </TagBox>
              <TagBox isEmpty={scoreKeyword === '점수 없음' || scoreKeyword === '피크와트' || scoreKeyword === '최고 속도'}>
                {
                  (scoreKeyword === '점수 없음' || scoreKeyword === '피크와트' || scoreKeyword === '최고 속도') ?
                    <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F'>-</Text>
                    :
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>{scorePriority}</Text>
                }
              </TagBox>
            </MainSelectBoxWrapper>
            <DivisionLine />
            <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>운동 설명</Text>
            <DescriptionBox>
              <DescriptionText>{sectionData.description}</DescriptionText>
            </DescriptionBox>
            {
              sectionData.mainNote !== '' &&
              <NoteViewBox>
                <NoteSection>
                  <Text style={{ marginLeft: '13px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF'>Notes</Text>
                </NoteSection>
                <NoteTextAreaWrapper>
                  <NoteTextWrapper>
                    <NoteText>
                      {sectionData.mainNote}
                    </NoteText>
                  </NoteTextWrapper>
                </NoteTextAreaWrapper>
              </NoteViewBox>
            }
          </MainExerciseTagWrapper>
        }
        {
          (sectionData.tag !== 'Custom' && sectionData.tag !== 'EMOM') &&
          <MainExerciseDesignWrapper>
            <DivisionLine />
            <MainExerciseTextWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>메인 운동 디자인</Text>
            </MainExerciseTextWrapper>
            {
              sectionData.mainExerciseList.map((data, idx) => (
                <NewNormalMovementBox
                  key={idx}
                  data={data}
                  scaleOptionExerciseList={sectionData.mainScaleOptionExerciseList}
                  idx={idx}
                  reps={dataReps}
                  tag={sectionData.tag}
                  division='main'
                  onClickMovementVideo={onClickMovementVideoViewModal} />
              ))
            }
            {
              sectionData.mainNote !== '' &&
              <NoteViewBox>
                <NoteSection>
                  <Text style={{ marginLeft: '13px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF'>Notes</Text>
                </NoteSection>
                <NoteTextAreaWrapper>
                  <NoteTextWrapper>
                    <NoteText>
                      {sectionData.mainNote}
                    </NoteText>
                  </NoteTextWrapper>
                </NoteTextAreaWrapper>
              </NoteViewBox>
            }
            {(sectionData.tag === 'ForTime-Rounds' || sectionData.tag === 'ForTime-Different Reps') && <DivisionLine />}
          </MainExerciseDesignWrapper>
        }
        {/* {
          sectionData.tag === 'EMOM' &&
          <MainExerciseDesignWrapper>
            <DivisionLine />
            <MainExerciseTextWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>메인 운동 디자인</Text>
            </MainExerciseTextWrapper>
            {
              sectionData.emomExerciseList.map((data, idx) => (
                <EmomSectionWrapper key={idx}>
                  {
                    // 모래시계 모형과 기다리세요 안내문 띄우기 //
                    (idx !== 0 && idx % (sectionData.mainExerciseList.length) === 0) &&
                    <EmomWaitMentWrapper>
                      <TrapezoidShapeWrapper>
                        <TopTrapezoidShape />
                        <BottomTrapezoidShape />
                      </TrapezoidShapeWrapper>
                      <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{viewEMOMWaitMent(idx)}</Text>
                    </EmomWaitMentWrapper>
                  }
                  {
                    // 시간 안내표 띄우기 //
                    (idx % sectionData.mainExerciseList.length) === 0 &&
                    <EmomTimeTitleWrapper>
                      <EmomTimeTitle>
                        <Text fontFamily='Poppins-Regular' fontSize={14} color='#FFFFFF'>{viewEMOMTimeTitle(idx)}</Text>
                      </EmomTimeTitle>
                    </EmomTimeTitleWrapper>
                  }
                  <NewNormalMovementBox
                    key={idx}
                    data={data}
                    scaleOptionExerciseList={sectionData.emomScaleOptionExerciseList}
                    idx={idx}
                    reps={dataReps}
                    tag={sectionData.tag}
                    division='main'
                    onClickMovementVideo={onClickMovementVideoViewModal} />
                </EmomSectionWrapper>
              ))
            }
            {
              sectionData.mainNote !== '' &&
              <NoteViewBox>
                <NoteSection>
                  <Text style={{ marginLeft: '13px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF'>Notes</Text>
                </NoteSection>
                <NoteTextAreaWrapper>
                  <NoteTextWrapper>
                    <NoteText>
                      {sectionData.mainNote}
                    </NoteText>
                  </NoteTextWrapper>
                </NoteTextAreaWrapper>
              </NoteViewBox>
            }
            {(sectionData.tag === 'ForTime-Rounds' || sectionData.tag === 'ForTime-Different Reps') && <DivisionLine />}
          </MainExerciseDesignWrapper>
        } */}
        {
          sectionData.tag === 'EMOM' &&
          <MainExerciseDesignWrapper>
            <DivisionLine />
            <MainExerciseTextWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>메인 운동 디자인</Text>
            </MainExerciseTextWrapper>
            {
              sectionData.mainNote !== '' &&
              <NoteViewBox>
                <NoteSection>
                  <Text style={{ marginLeft: '13px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF'>Notes</Text>
                </NoteSection>
                <NoteTextAreaWrapper>
                  <NoteTextWrapper>
                    <NoteText>
                      {sectionData.mainNote}
                    </NoteText>
                  </NoteTextWrapper>
                </NoteTextAreaWrapper>
              </NoteViewBox>
            }
            {
              sectionData.roundList.map((roundData, roundIndex) => (
                <EmomSectionWrapper key={`emom-round-` + roundIndex}>
                  {/* // 시간 안내표 띄우기 // */}
                  <EmomTimeTitleWrapper>
                    <EmomTimeTitle>
                      <Text fontFamily='Poppins-Regular' fontSize={14} color='#FFFFFF'>{viewEMOMTimeTitle(roundIndex)}</Text>
                    </EmomTimeTitle>
                  </EmomTimeTitleWrapper>
                  {
                    sectionData.emomExerciseList.map((data, idx) => (
                      roundData === data.emomRoundIndex &&
                      <NewNormalMovementBox
                        key={`emom-` + idx}
                        data={data}
                        scaleOptionExerciseList={sectionData.emomScaleOptionExerciseList}
                        idx={idx}
                        reps={dataReps}
                        tag={sectionData.tag}
                        division='main'
                        onClickMovementVideo={onClickMovementVideoViewModal} />
                    ))
                  }
                  {/* // 모래시계 모형과 기다리세요 안내문 띄우기 // */}
                  <EmomWaitMentWrapper>
                    <TrapezoidShapeWrapper>
                      <TopTrapezoidShape />
                      <BottomTrapezoidShape />
                    </TrapezoidShapeWrapper>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{viewEMOMWaitMent(roundIndex)}</Text>
                  </EmomWaitMentWrapper>
                </EmomSectionWrapper>
              ))
            }
            {(sectionData.tag === 'ForTime-Rounds' || sectionData.tag === 'ForTime-Different Reps') && <DivisionLine />}
          </MainExerciseDesignWrapper>
        }
      </MainComponentWrapper>
      {/* Main */}
      {
        // Buy out Setting //
        (sectionData.tag === 'ForTime-Rounds' || sectionData.tag === 'ForTime-Different Reps') &&
        <BuyWrapper>
          <BuyCheckBoxWrapper>
            <CheckBox isClicked={sectionData.isUseBuyout}>
              <Check src={CheckImage} />
            </CheckBox>
            <Text fontFamily='Poppins-Regular' fontSize={14} color='#4F4F4F'>Buy out</Text>
          </BuyCheckBoxWrapper>
          {
            sectionData.buyoutExerciseList.map((data, idx) => (
              <NewNormalMovementBox
                key={idx}
                data={data}
                scaleOptionExerciseList={sectionData.buyoutScaleOptionExerciseList}
                idx={idx}
                reps={dataReps}
                tag='None'
                division='buyout'
                onClickMovementVideo={onClickMovementVideoViewModal} />
            ))
          }
          {
            sectionData.buyoutNote !== '' &&
            <NoteViewBox>
              <NoteSection>
                <Text style={{ marginLeft: '13px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF'>Notes</Text>
              </NoteSection>
              <NoteTextAreaWrapper>
                <NoteTextWrapper>
                  <NoteText>
                    {sectionData.buyoutNote}
                  </NoteText>
                </NoteTextWrapper>
              </NoteTextAreaWrapper>
            </NoteViewBox>
          }
        </BuyWrapper>
        // Buy out Setting //
      }
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 97.71%;
`;

const SectionDivisionWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const SectionNumberBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 32px;
  height: 32px;

  border-radius: 6px;
  background-color: #6DD49E;

  font-family: Poppins-Bold;
  font-size: 14px;

  color: #FFFFFF;
`;

const SectionTitleBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: calc(92.21% - 24px);
  height: 32px;

  padding: 0px 12px 0px 12px;

  font-family: NotoSansKR-Medium;
  font-size: 14px;
  
  color: #333333;

  overflow: hidden;
  
  border-radius: 6px;
  background-color: #F9F9F9;
`;

const SectionTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  height: 20px;

  overflow: hidden;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  padding: 0 0 0 0;
  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const DivisionLine = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;

  margin-top: 24px;
  margin-bottom: 24px;

  border: 1px solid #ECECEC;
`;

const BuyWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  margin-top: ${props => props.isAMRAP ? `24px` : `0px`};
`;

const BuyCheckBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 100%;

  gap: 6px;
`;

const CheckBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;

  border-radius: 6px;
  background-color: ${props => props.isClicked ? `#6DD49E` : `#E0E0E0`};
`;

const Check = styled.img`
  width: 10px;
  height: 9px;
`;

const MainComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
`;

const MainSelectBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;

  margin-top: 9px;
`;

const MainIconWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
`;

const TagBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: calc(47.66% - 34px);
  height: 34px;

  margin: 0 0 0 0;
  padding: 0 16px 0 16px;

  border: 1px solid #E0E0E0;
  border-radius: 15px;
  background-color: ${props => props.isEmpty ? `#ECECEC` : `#FFFFFF`};
`;

const RoundBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: calc(100% - 34px);
  height: 34px;

  border: 1px solid #E0E0E0;
  border-radius: 15px;
  background-color: #FFFFFF;

  margin: 0 0 0 0;
  padding: 0 16px 0 16px;
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;

  :hover {
    cursor: pointer;
  }
`;

const MainExerciseTagWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 8px;
  margin-top: 24px;

  width: 100%;
`;

const MainExerciseDesignWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
`;

const MainExerciseTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
`;

const RepsInputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  flex-wrap: wrap;

  width: 100%;
`;

const RepsWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  // width: 25.29%;
  width: 25%;
  // background-color: red;
  gap: 4px;
  margin-top: 8px;
`;

const RepsBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: calc(84.61% - 34px);
  height: 34px;

  border: 1px solid #E0E0E0;
  border-radius: 15px;
  background-color: #FFFFFF;

  margin: 0 0 0 0;
  padding: 0 16px 0 16px;
`;

const NextRepsIcon = styled.img`
  width: 16px;
  height: 16px;
`;

const TimeWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const TimeTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const TextInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const TimeLimitWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: 100%;
  
  gap: 6px;

  margin-top: 24px;
`;

const TimeComponentWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 7px;

  width: 100%;

  margin-top: ${props => props.topMargin}px;
`;

const TimeDataBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  // width: 82.29%;
  width: 80%;
  height: 34px;

  margin: 0 0 0 0;
`;

const TimeDataBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: calc(47.11% - 34px);
  height: 34px;

  padding: 0 16px 0 16px;
  margin: 0 0 0 0;

  border: 1px solid #E0E0E0;
  border-radius: 15px;
  background-color: ${props => props.isClicked ? `#ECECEC` : `#FFFFFF`};
`;

const DescriptionBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  padding: 8px 16px 8px 16px;

  width: calc(100% - 34px);
  min-height: 18px;
  // height: auto;
  // min-height: 497px;

  border: 1px solid #E0E0E0;
  border-radius: 15px;
  background-color: #FFFFFF;
  // background-color: red;
`;

const DescriptionText = styled.pre`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;

  margin: 0 0 0 0;

  white-space: pre-wrap;
  // word-break: break-all;
  word-break: keep-all;
`;

const NoteSection = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 91px;
  height: 28px;

  border-radius: 15px 0px 15px 0px;
  // background-color: #FF7322;
  background-color: #FF8B48;
`;

const NoteViewBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 12px;

  width: 100%;

  border: 1px solid #E0E0E0;
  border-radius: 15px;
  background-color: #FFFFFF;
`;

const NoteTextAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  margin: 8px 0px 14px 0px;

  width: 100%;
`;

const NoteTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 92.02%;
`;

const NoteText = styled.pre`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;

  width: 100%;

  // white-space: pre-line;
  white-space: pre-wrap;
  word-wrap: break-word;
`;

const EmomSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const EmomTimeTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 26px;

  width: 100%;
`;

const EmomTimeTitle = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  padding: 0px 8px 0px 8px;
  
  height: 18px;

  border-radius: 2px;
  background-color: #6DD49E;
`;

const EmomWaitMentWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 20px;

  width: 100%;

  gap: 4px;
`;

const TrapezoidShapeWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const TopTrapezoidShape = styled.div`
  border-top: 7px solid #66D49E;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;

  height: 0px;

  width: 3px;
`;

const BottomTrapezoidShape = styled.div`
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-bottom: 7px solid #66D49E;

  height: 0px;

  width: 3px;
`;

const VideoViewWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 16px;

  // width: 494px;
  width: 100%;
  height: 278px;
  
  overflow: hidden;
`;