import { React, useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Input from '../../../Component/MemberComponent/MemberInput.js';
import GenderInput from '../../../Component/MemberComponent/GenderInput.js';
import MemberImageInput from '../../../Component/MemberComponent/MemberImageInput.js';
import AgreementCheckBox from '../../../Component/MemberComponent/AgreementCheckBox.js';
import NoteInput from '../../../Component/MemberComponent/NoteInput.js';

import FetchModule from '../../Share/Network/FetchModule';
import NoHavePhoneAlertModal from './NoHavePhoneAlertModal.js';

const MemberDefaultInfoView = forwardRef((props, ref) => {
  const { 
    isFindUserId,
    setIsFindUserId,
    isAgreementEmphasize, 
    setIsAgreementEmphasize 
  } = props;
  const history = useHistory();

  const [nameClickCount, setNameClickCount] = useState(0);
  const [birthClickCount, setBirthClickCount] = useState(0);
  const [phoneClickCount, setPhoneClickCount] = useState(0);
  const [idClickCount, setIdClickCount] = useState(0);
  // const [isFindUserId, setIsFindUserId] = useState(false);

  const [isBlankName, setIsBlankName] = useState(true);
  const [isBlankBirth, setIsBlankBirth] = useState(true);
  const [isBlankPhone, setIsBlankPhone] = useState(true);
  const [isBlankId, setIsBlankId] = useState(true);

  const [noHavePhone, setNoHavePhone] = useState(false);
  const [isViewNoHaveModal, setIsViewNoHaveModal] = useState(false);

  const NameRef = useRef();
  const BirthRef = useRef();
  const GenderRef = useRef();
  const PhoneRef = useRef();
  const IdRef = useRef();
  const AgreementRef = useRef();
  const ProfileRef = useRef();

  const NamePlanationRef = useRef();
  const BirthPlanationRef = useRef();
  const GenderPlanationRef = useRef();
  const PhonePlanationRef = useRef();
  const IdPlanationRef = useRef();
  const AgreementPlanationRef = useRef();
  const MemberDetailRef = useRef();

  useImperativeHandle(ref, () => ({
		getName: () => {
      return NameRef.current.getValue();
    },
		getBirth: () => {
      return BirthRef.current.getValue();
    },
		getGender: () => {
      return GenderRef.current.getValue();
    },
		getPhone: () => {
      return PhoneRef.current.getValue();
    },
		getId: () => {
      return IdRef.current.getValue();
    },
		getAgreement: () => {
      return AgreementRef.current.getValue();
    },
    getProfile: () => {
      return ProfileRef.current.getImgBase64();
    },
    getMemberDetail: () => {
      return MemberDetailRef.current.getValue();
    },
    getNoHavePhone: () => {
      return noHavePhone;
    },
    getIsFindUserId: () => {
      if (noHavePhone) return true;
      else return isFindUserId;
    },
    plusNameClickCount: () => {
      setNameClickCount(nameClickCount + 1);
    },
    plusBirthClickCount: () => {
      setBirthClickCount(birthClickCount + 1);
    },
    plusPhoneClickCount: () => {
      setPhoneClickCount(phoneClickCount + 1);
    },
    plusIdClickCount: () => {
      setIdClickCount(idClickCount + 1);
    },
    agreementAccent: () => {
      AgreementPlanationRef.current.style.opacity = 1;
    },
    invalidName: () => {
      setIsBlankName(false);
      NameRef.current.invalidValue();
			NamePlanationRef.current.style.opacity = 1;
    },
    invalidBirth: () => {
      setIsBlankBirth(false);
      BirthRef.current.invalidValue();
			BirthPlanationRef.current.style.opacity = 1;
    },
    invalidPhone: () => {
      setIsBlankPhone(false);
      PhoneRef.current.invalidValue();
			PhonePlanationRef.current.style.opacity = 1;
    },
    invaildId: () => {
      setIsBlankId(false);
      IdRef.current.invalidValue();
			IdPlanationRef.current.style.opacity = 1;
    },
	}));

  useEffect(() => {
    if (nameClickCount === 0) return;

		if (NameRef.current.getValue() === '' && nameClickCount > 0) {
			NamePlanationRef.current.style.opacity = 1;
      setIsBlankName(true);
		}
		else {
			NamePlanationRef.current.style.opacity = 0;
      setIsBlankName(false);
		}
  }, [nameClickCount])

  useEffect(() => {
    if (birthClickCount === 0) return;

		if (BirthRef.current.getValue() === '' && birthClickCount > 0) {
			BirthPlanationRef.current.style.opacity = 1;
      setIsBlankBirth(true);
		}
		else {
			BirthPlanationRef.current.style.opacity = 0;
      setIsBlankBirth(false);
		}
  }, [birthClickCount])
  
  useEffect(() => {
    if (phoneClickCount === 0) return;

		if (PhoneRef.current.getValue() === '' && phoneClickCount > 0) {
			PhonePlanationRef.current.style.opacity = 1;
      setIsBlankPhone(true);
		}
		else {
			PhonePlanationRef.current.style.opacity = 0;
      setIsBlankPhone(false);
		}
  }, [phoneClickCount])
  
  useEffect(() => {
    if (idClickCount === 0) return;

		if (IdRef.current.getValue() === '' && idClickCount > 0) {
			IdPlanationRef.current.style.opacity = 1;
      setIsBlankId(true);
		}
		else {
			IdPlanationRef.current.style.opacity = 0;
      setIsBlankId(false);
		}
  }, [idClickCount])

  // 전화번호 없이 진행하기 체크시 //
  useEffect(() => {
    if (!noHavePhone) return;

    setIsViewNoHaveModal(true);
  }, [noHavePhone])

  function clickCountFn(className, clickCount) {
		if (className === 'NameInput') setNameClickCount(clickCount);
		else if(className === 'BirthInput') setBirthClickCount(clickCount);
		else if(className === 'PhoneInput') setPhoneClickCount(clickCount);
		else if(className === 'IdInput') setIdClickCount(clickCount);
  }

  function onClickLinkCoachIdCheck() {
    let phone = PhoneRef.current.getValue();
    // let coachId = IdRef.current.getValue();

    if (phone.length !== 11) {
      alert('휴대폰 번호를 정확히 입력해주세요');
      return;
    }
    // else if (coachId === '') {
    //   alert('링크코치 아이디를 정확히 입력해주세요');
    //   return;
    // }

    let data = {
      phone: phone,
      userId: '',
      profile: ''
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataOfCoach('userInfo/detail/invalid/user', 'POST', data);
        
        if (responseData.status === 200) {
          ProfileRef.current.setImgBase64(responseData.data[0].profile);
          IdRef.current.setValue(responseData.data[0].userId);
          NameRef.current.setValue(responseData.data[0].name);

          let genderNum = responseData.data[0].genderNum.replaceAll('*', '');

          if (genderNum === '1' || genderNum === '3') GenderRef.current.setValue('male');
          else GenderRef.current.setValue('female');

          if (genderNum === '1' || genderNum === '2') BirthRef.current.setValue('19' + responseData.data[0].birth);
          else BirthRef.current.setValue('20' + responseData.data[0].birth);

          setIsFindUserId(true);
        }
        else if (responseData.status === 201) {
          alert('회원정보가 일치하지 않습니다.');
          setIsFindUserId(true);
        }
        else if (responseData.status === 204) {
          alert('회원정보가 존재하지 않습니다.');
          setIsFindUserId(true);
        }
        else if (responseData.status === 401) {
          alert('로그인 후 다시시도하세요');
          history.push('/');
        }
      }
    )();
  }

  function checkFn() {
    // AgreementPlanationRef.current.style.opacity = 0;
  }

  // 전화번호 없음 클릭시 //
  function onClickNoPhone() {
    if (noHavePhone) {
      setNoHavePhone(false);
      setIsFindUserId(false);
    }
    else {
      setNoHavePhone(true);
      setIsFindUserId(true);
    }
  }

  // 모달에서 전화번호 없이 진행하기 취소 클릭시 //
  function onClickExitNoHavePhone() {
    setNoHavePhone(false);
    setIsViewNoHaveModal(false);
  }

  return (
    <TopLevelWrapper>
      { isViewNoHaveModal && <NoHavePhoneAlertModal onClickYes={() => setIsViewNoHaveModal(false)} onClickNo={onClickExitNoHavePhone}/> }
        <TitleWrapper>
          <Title>기본 회원 정보</Title>
          <AgreementCheckBox 
            ref={AgreementRef} 
            // checkFn={checkFn}
            isAgreementEmphasize={isAgreementEmphasize}
            setIsAgreementEmphasize={setIsAgreementEmphasize}/>
        </TitleWrapper>
        {/* <AgreementCheckBox ref={AgreementRef} checkFn={checkFn} /> */}
      <InfoBox>
        <LayerWrapper>
        <InputWrapper>
            <PhoneTextWrapper>
              <InputTitle>휴대 전화번호</InputTitle>
              <NoHavePhoneWrapper>
                <NoHavePhoneCheckBox isClicked={noHavePhone} onClick={onClickNoPhone}/>
                <InputTitle>휴대전화 없음</InputTitle>
              </NoHavePhoneWrapper>
            </PhoneTextWrapper>
            <LinkCoachInputWrapper>
              <Input ref={PhoneRef} disabled={noHavePhone} className='PhoneInput' placeholder='- 를 제외하고 입력해주세요' type='text' clickCountFn={clickCountFn} width={207} />
              <LinkCoachIdConfirmButton onClick={onClickLinkCoachIdCheck}>확인</LinkCoachIdConfirmButton>
            </LinkCoachInputWrapper>
            <Explanation ref={PhonePlanationRef}>{isBlankName ? '필수항목을 입력해주세요' : '정확한 전화번호를 입력해주세요'}</Explanation>
          </InputWrapper>
          <RowLayerWrapper>
            <InputWrapper>
              <InputTitle>이름</InputTitle>
              <Input ref={NameRef} disabled={false} className='NameInput' placeholder='실명을 입력해주세요' type='text' clickCountFn={clickCountFn} width={126} />
              <Explanation ref={NamePlanationRef}>{isBlankName ? '필수항목을 입력해주세요' : '정확한 실명을 입력해주세요'}</Explanation>
            </InputWrapper>
            <InputWrapper>
              <InputTitle>성별</InputTitle>
              <GenderInput ref={GenderRef} />
              <Explanation ref={GenderPlanationRef}>필수 항목을 입력해주세요</Explanation>
            </InputWrapper>
          </RowLayerWrapper>
        </LayerWrapper>
        <LayerWrapper>
          <InputWrapper>
            <InputTitle>링크 코치 아이디</InputTitle>
              <Input ref={IdRef} disabled={true} className='IdInput' placeholder='전화번호로 가입여부를 확인해주세요' type='text' clickCountFn={clickCountFn} width={272} />
            <Explanation ref={IdPlanationRef}>{isBlankName ? '필수항목을 입력해주세요' : '유효한 아이디를 입력해주세요'}</Explanation>
          </InputWrapper>
          <InputWrapper>
            <InputTitle>생년월일</InputTitle>
            <Input ref={BirthRef} disabled={false} className='BirthInput' placeholder='생년월일 8자리를 입력해주세요' type='text' clickCountFn={clickCountFn} width={272} />
            <Explanation ref={BirthPlanationRef}>{isBlankName ? '필수항목을 입력해주세요' : '정확한 생년월일을 입력해주세요'}</Explanation>
          </InputWrapper>
        </LayerWrapper>
        <LayerWrapper>
          <InputWrapper>
            <InputTitle>회원 프로필 사진</InputTitle>
            <MemberImageInput ref={ProfileRef}/>
          </InputWrapper>
        </LayerWrapper>
      </InfoBox>
      <InputWrapper style={{margin: `0 0 0 28px`}}>
        <InputTitle>회원 별 수업 관련 특이사항</InputTitle>
        <NoteInput ref={MemberDetailRef} className='NoteInput' placeholder='회원에 대한 수업 시 유의해야 할 특이사항을 입력해주세요' type='text' />
      </InputWrapper>
    </TopLevelWrapper>
  )
})

export default MemberDefaultInfoView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  // align-items: flex-start;
  // align-items: center;

  width: 1020px;
  height: 394px;
  // height: 262px;

  // border-radius: 30px;
  border-radius: 10px;
  background-color: #FFFFFF;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);

  transition: all 0.3s ease-in-out;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // margin-left: 28px;
  // margin: 0 0 0 2px;
  margin-top: 20px;
`;

const Title = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: row;

  border-top: 2px solid #ECECEC;

  gap: 24px;
  margin-top: 24px;
  margin-left: 28px;
`;

const RowLayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  gap: 24px;
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: 8px;
  margin-top: 24px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const PhoneTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const NoHavePhoneWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const NoHavePhoneCheckBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 16px;
  height: 16px;

  border: 1px solid #DBDBDB;
  border-radius: 4px;
  background-color: ${props => props.isClicked? `#FF8B48` : `#FFFFFF`};

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const InputTitle = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #595959;

  margin: 0 0 0 0;
`;

const LinkCoachInputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const LinkCoachIdConfirmButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 57px;
  height: 32px;

  border-radius: 8px;
  background-color: #5BD98A;

  transition: all 0.3s ease-in-out;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  margin: 8px 0 0 8px;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }
`;

const Explanation = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #FF3131;
  opacity: 0;

  margin: 3px 0 0 0;
  
  transition: all 0.5s ease-in-out;
`;
