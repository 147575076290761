import { React, useState, useEffect } from 'react';
import styled from 'styled-components';

// import NormalSortButtonPath from '../../../image/NormalSortButton.png';
import NormalSortButtonPath from '../../../image/GrayNormalSortButton.png';
import AscSortButtonPath from '../../../image/AscSortButton.png';
import DescSortButtonPath from '../../../image/DescSortButton.png';
import MembershipSortingSelectBox from '../../../Component/MemberComponent/MembershipSortingSelectBox';

const ValidColumnView = (props) => {
  const { 
    selectedMembership,
    setSelectedMembership,
    genderSort,
    setGenderSort,
    birthSort,
    setBirthSort,
    startSort, 
    setStartSort, 
    endSort, 
    setEndSort, 
    classSort, 
    setClassSort 
  } = props;

  const [genderButtonImg, setGenderButtonImg] = useState(AscSortButtonPath);
  const [birthButtonImg, setBirthButtonImg] = useState(AscSortButtonPath);
  const [startButtonImg, setStartButtonImg] = useState(AscSortButtonPath);
  const [endButtonImg, setEndButtonImg] = useState(AscSortButtonPath);
  const [classButtonImg, setClassButtonImg] = useState(AscSortButtonPath);


  // 성별 버튼 클릭시 이미지 변경 //
  useEffect(() => {
    if (genderSort === 'normal') setGenderButtonImg(NormalSortButtonPath);
    else if (genderSort === 'asc') setGenderButtonImg(AscSortButtonPath);
    else if (genderSort === 'desc') setGenderButtonImg(DescSortButtonPath);
  }, [genderSort])

  // 생년월일 버튼 클릭시 이미지 변경 //
  useEffect(() => {
    if (birthSort === 'normal') setBirthButtonImg(NormalSortButtonPath);
    else if (birthSort === 'asc') setBirthButtonImg(AscSortButtonPath);
    else if (birthSort === 'desc') setBirthButtonImg(DescSortButtonPath);
  }, [birthSort])

  // 시작일 버튼 클릭시 이미지 변경 //
  useEffect(() => {
    if (startSort === 'normal') setStartButtonImg(NormalSortButtonPath);
    else if (startSort === 'asc') setStartButtonImg(AscSortButtonPath);
    else if (startSort === 'desc') setStartButtonImg(DescSortButtonPath);
  }, [startSort])

  // 종료일 버튼 클릭시 이미지 변경 //
  useEffect(() => {
    if (endSort === 'normal') setEndButtonImg(NormalSortButtonPath);
    else if (endSort === 'asc') setEndButtonImg(AscSortButtonPath);
    else if (endSort === 'desc') setEndButtonImg(DescSortButtonPath);
  }, [endSort])

  // 수업 버튼 클릭시 이미지 변경 //
  useEffect(() => {
    if (classSort === 'normal') setClassButtonImg(NormalSortButtonPath);
    else if (classSort === 'asc') setClassButtonImg(AscSortButtonPath);
    else if (classSort === 'desc') setClassButtonImg(DescSortButtonPath);
  }, [classSort])

  // 성별 정렬 버튼 클릭시 //
  function onClickGenderSort() {
    setBirthSort('normal');
    setStartSort('normal');
    setEndSort('normal');
    setClassSort('normal');

    if (genderSort === 'normal' || genderSort === 'desc') setGenderSort('asc');
    else if (genderSort === 'asc') setGenderSort('desc');
  }

  // 생년월일 정렬 버튼 클릭시 //
  function onClickBirthSort() {
    setGenderSort('normal');
    setStartSort('normal');
    setEndSort('normal');
    setClassSort('normal');

    if (birthSort === 'normal' || birthSort === 'desc') setBirthSort('asc');
    else if (birthSort === 'asc') setBirthSort('desc');
  }

  // 종료일 정렬 버튼 클릭시 //
  function onClickStartSort() {
    setGenderSort('normal');
    setBirthSort('normal');
    setEndSort('normal');
    setClassSort('normal');

    if (startSort === 'normal' || startSort === 'desc') setStartSort('asc');
    else if (startSort === 'asc') setStartSort('desc');
  }

  // 종료일 정렬 버튼 클릭시 //
  function onClickEndSort() {
    setGenderSort('normal');
    setBirthSort('normal');
    setStartSort('normal');
    setClassSort('normal');

    if (endSort === 'normal' || endSort === 'desc') setEndSort('asc');
    else if (endSort === 'asc') setEndSort('desc');
  }

  // 수업 정렬버튼 클릭시 //
  function onClickClassSort() {
    setGenderSort('normal');
    setBirthSort('normal');
    setStartSort('normal');
    setEndSort('normal');

    if (classSort === 'normal' || classSort === 'desc') setClassSort('asc');
    else if (classSort === 'asc') setClassSort('desc');
  }

  return (
    <TopLevelWrapper>
      <Column style={{marginRight: '47px', marginLeft: '28px'}}>이름</Column>
      <SortingWrapper>
        {/* <Column style={{marginRight: '29px'}}>성별</Column> */}
        <Column style={{marginRight: '4px'}}>성별</Column>
        <SortingButton img={genderButtonImg} onClick={() => onClickGenderSort()} style={{marginRight: '18px'}}/>
      </SortingWrapper>
      <SortingWrapper>
        {/* <Column style={{marginRight: '46px'}}>생년월일</Column> */}
        <Column style={{marginRight: '4px'}}>생년월일</Column>
        <SortingButton img={birthButtonImg} onClick={() => onClickBirthSort()} style={{marginRight: '46px'}}/>
      </SortingWrapper>
      <Column style={{marginRight: '49px'}}>핸드폰번호</Column>
      {/* <SortingWrapper style={{marginRight: '207px'}}> */}
      <SortingWrapper style={{marginRight: '182px'}}>
        <Column style={{marginRight: '4px'}}>회원권</Column>
        <MembershipSortingSelectBox 
          value={selectedMembership}
          setValue={setSelectedMembership} />
      </SortingWrapper>
      <SortingWrapper>
        <Column style={{ marginRight: '4px' }}>시작일</Column>
        <SortingButton img={startButtonImg} onClick={() => onClickStartSort()} style={{marginRight: '35px'}}/>
      </SortingWrapper>
      <SortingWrapper>
        <Column style={{ marginRight: '4px' }}>종료일</Column>
        <SortingButton img={endButtonImg} onClick={() => onClickEndSort()} style={{marginRight: '118px'}}/>
      </SortingWrapper>
      {/* <SortingWrapper style={{zIndex: -3}}>
        <Column style={{ marginRight: '4px' }}>수업</Column>
        <SortingButton img={classButtonImg} onClick={() => onClickClassSort()} style={{marginRight: '65px'}}/>
      </SortingWrapper> */}
      <Column>수강횟수</Column>
    </TopLevelWrapper>
  )
}

export default ValidColumnView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  // justify-content: center;
  
  // justify-content: center;
  align-items: center;

  width: 1020px;
  margin-top: 34px;
`;

const Column = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;
`;

const SortingWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const SortingButton = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;

  background: url(${props => props.img}) no-repeat center center;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

// const MemberShipSelect