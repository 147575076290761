import { React } from 'react';
import styled from 'styled-components';

import FetchModule from '../Network/FetchModule';

function CenterInfo(props) {
  const { data } = props;

  return (
    <TopLevelWrapper>
      <GreetingWrapper>
        <Name>{data.ownerName}님</Name>
        <Greeting>, 안녕하세요!</Greeting>
      </GreetingWrapper>
      <InfoWrapper>
        <Info>센터명 -</Info>
        <UserInfo>{data.storeName}</UserInfo>
      </InfoWrapper>
    </TopLevelWrapper>
  )
}

export default CenterInfo;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 1194px;
  // width: max(351px, 62.1875vw);
  height: 92px;
  // height: max(48px, 4.791666666666667vw);

  border-radius: 30px;
  // border-radius: max(15px, 1.5625vw);
  background: linear-gradient(270deg, #FF8B48 0%, #FFA570 100%);
`;

const GreetingWrapper = styled.div`
  display: flex;
  flex-direction: row;

  margin-left: 64px;
  // margin-left: max(21px, 3.333333333333333vw);
`;

const Name = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 18px;
  // font-size: max(14px, 0.9375vw);

  color: #FFFFFF;

  :hover {
    cursor: default;
  }
`;

const Greeting = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 18px;
  // font-size: max(14px, 0.9375vw);

  color: #FFFFFF;

  :hover {
    cursor: default;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  margin-right: 64px;
  // margin-right: max(21px, 3.333333333333333vw);
`;

const Info = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 18px;
  // font-size: max(14px, 0.9375vw);

  color: #FFFFFF;

  :hover {
    cursor: default;
  }
`;

const UserInfo = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 18px;
  // font-size: max(14px, 0.9375vw);

  margin-left: 5px;
  color: #FFFFFF;

  :hover {
    cursor: default;
  }
`;