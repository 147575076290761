import { React } from 'react';
import styled from 'styled-components';

import RegistrationView from '../../Model/Individual/MemberModel/RegistrationView.js';

function MemberRegistrationPage() {

  return (
    <Container>
      <RegistrationView/>
    </Container>
  )
}

export default MemberRegistrationPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
`;