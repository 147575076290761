import { React, useRef, useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';

import MemberShipCategorySelectBox from '../../../Component/MemberComponent/MemberShipCategorySelectBox.js';
import MemberShipSelectBox from '../../../Component/MemberComponent/MemberShipSelectBox.js';
import CalenderInput from '../../../Component/MemberComponent/CalenderInput.js';
import PaymentInput from '../../../Component/MemberComponent/PaymentInput.js';

import FetchModule from '../../Share/Network/FetchModule.js';
import LessonSelectBox from '../../../Component/MemberComponent/LessonSelectBox.js';
import MemberShipMaxCountInput from '../../../Component/SettingComponent/MemberShipMaxCountInput.js';
import MemberShipTotalMaxCountInput from '../../../Component/MemberComponent/MemberShipTotalMaxCountInput.js';
import DiscountPaymentInput from '../../../Component/MemberComponent/DiscountPaymentInput.js';
import CategorySelectBox from '../../../Component/MemberComponent/CategorySelectBox.js';

const OnSitePaymentMemberShipInfoView = forwardRef((props, ref) => {
  const { data, userData, startDate, setStartDate, endDate, setEndDate, registerClick, closeFn } = props;
  const history = useHistory();

  const [isUsing, setIsUsing] = useState(false);
  const [lessonData, setLessonData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [memberShipData, setMemberShipData] = useState(false);
  const [divisionMemberShipData, setDivisionMemberShipData] = useState(false);
  const [maxCount, setMaxCount] = useState(0);
  const [calcComplete, setCalcComplete] = useState(false);
  const [validity, setValidity] = useState(0);
  const [validityUnit, setValidityUnit] = useState('day');
  const [totalPrice, setTotalPrice] = useState(0);
  const [notReceivedPrice, setNotReceivedPrice] = useState(0);
  // const [startDate, setStartDate] = useState('');
  // const [endDate, setEndDate] = useState('');
  const [approvalDate, setApprovalDate] = useState('');
  const [prevSalesColor, setPrevSalesColor] = useState('#595959');
  const [notHavePrev, setNotHavePrev] = useState(false);

  const CategorySelectRef = useRef();
  const TotalMaxRef = useRef();
  const LessonSelectRef = useRef();
  const MemberShipSelectRef = useRef();
  const CardPaymentRef = useRef();
  const CashPaymentRef = useRef();
  const DiscountPaymentRef = useRef();
  const StartCalenderRef = useRef();
  const EndCalenderRef = useRef();
  const ApprovalCalenderRef = useRef();
  //
  useImperativeHandle(ref, () => ({
    getIsUsing: () => {
      return isUsing;
    },
    getMemberShipId: () => {
      return MemberShipSelectRef.current.getMemberShipId();
    },
    getMemberShipName: () => {
      return MemberShipSelectRef.current.getValue();
    },
    getStartDate: () => {
      return startDate;
    },
    getEndDate: () => {
      return endDate;
    },
    getMemberShipData: () => {
      return MemberShipSelectRef.current.getObjectVal();
    },
    getCardPrice: () => {
      return CardPaymentRef.current.getValue();
    },
    getCashPrice: () => {
      return CashPaymentRef.current.getValue();
    },
    getDiscountPrice: () => {
      // return (notReceivedPrice - totalPrice) > 0 ? 0 : (notReceivedPrice - totalPrice);
      return DiscountPaymentRef.current.getValue();
    },
    getApprovalDate: () => {
      return approvalDate;
    },
    getTotalMax: () => {
      return TotalMaxRef.current.getValue();
    },
    getNotReceivedPrice: () => {
      return notReceivedPrice;
    }
  }))

  useEffect(() => {
    // const fetchModule = new FetchModule();
    // (
    //   async () => {
    //     const responseData = await fetchModule.getData('lesson/', 'GET');

    //     if (responseData.status === 200) {
    //       setLessonData(responseData.data);
    //     }
    //     else if (responseData.status === 401) {
    //       alert('로그인 후 다시시도하세요');
    //       history.push('/');
    //     }
    //   }
    // )();
    // (
    //   async () => {
    //     const responseData = await fetchModule.getData('membership/', 'GET');
    //     // console.log(responseData.data);
    //     if (responseData.status === 200) {
    //       setMemberShipData(responseData.data);
    //     }
    //     else if (responseData.status === 401) {
    //       alert('로그인 후 다시시도하세요');
    //       history.push('/');
    //     }
    //   }
    // )();

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getDataVersion2('membership/', 'GET');
        // console.log(responseData.data.memberShips);
        if (responseData.status === 200) {
          setMemberShipData(responseData.data.memberShips);
          setCategoryData(responseData.data.categories);
          // setLessonData(responseData.data.lessonInfos);
        }
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }, [history])

  // useEffect(() => {
  //   if (userData?.prevSales) console.log("???");
  // }, [userData])

  useEffect(() => {
    if (startDate === '' || endDate === '') return;
    else if (calcComplete) return;

    setValidity(moment(endDate).diff(moment(startDate), 'days') - 1);
    setCalcComplete(true);
  }, [endDate, startDate, calcComplete])

  // 회원권 데이터 및 수업 데이터 로딩 완료시 데이터 분류 //
  useEffect(() => {
    if (data === undefined) return;

    // console.log(data);
    // if (lessonData.length > 0) LessonSelectRef.current.setValue({id: data.lessonId, name: data.lessonName});
    if (categoryData.length > 0) CategorySelectRef.current.setValue({ id: data.categoryId, name: data.categoryName });
    if (memberShipData) {
      let newDataList = [];

      for (let i = 0; i < memberShipData.length; i++) {
        if (memberShipData[i].categoryId === data.categoryId) newDataList.push(memberShipData[i]);
      }
      // console.log(newDataList);
      MemberShipSelectRef.current.setDefaultVal(newDataList, data.membershipName);

      // for (var i = 0; i < memberShipData.length; i++) {
      //   if (memberShipData[i].lessonId === data.lessonId) newDataList.push(memberShipData[i]);
      // }

      // MemberShipSelectRef.current.setDefaultVal(newDataList, data.membershipName);
    }

    setMaxCount(data.totalMax);
    TotalMaxRef.current.setValue(data.totalMax);
    setTotalPrice(data.totalPrice);
    // setNotReceivedPrice(data.totalPrice);
    setNotReceivedPrice(data.totalPrice - data.cardPrice);

    CardPaymentRef.current.setValue(data.cardPrice);
    CashPaymentRef.current.setValue(data.cashPrice);

    let division = 'days';

    if (data.validityUnit === 'year') division = 'year';
    else if (data.validityUnit === 'month') division = 'month';
    // console.log(data);
    // console.log("????");
    // setEndDate(moment().add(data.validity, 'days').format('YYYY.MM.DD'));
    setEndDate(moment().add(data.validity, division).subtract(1, 'days').format('YYYY.MM.DD'));
  }, [data, categoryData, memberShipData])

  useEffect(() => {
    if (isUsing) {
      // CategorySelectRef.current.setValue({ name: '선택' });
      LessonSelectRef.current.setValue({ name: '선택' });
      MemberShipSelectRef.current.setValue({ name: '선택' });
      // StartCalenderRef.current.setValue('');
      // EndCalenderRef.current.setValue('');
      // ApprovalCalenderRef.current.setValue('');
      setStartDate('');
      setEndDate('');
      setApprovalDate('');
    }
    else {
      // StartCalenderRef.current.setValue(moment().format('YYYY.MM.DD'));
      // ApprovalCalenderRef.current.setValue(moment().format('YYYY.MM.DD'));
      setStartDate(moment().format('YYYY.MM.DD'));
      setApprovalDate(moment().format('YYYY.MM.DD'));
    }
  }, [isUsing])

  // 시작날짜가 변경될때마다 회원권 종료일 자동 계산 ( 유효기간이 0이거나 시작날짜가 정해지지 않으면 무시 )
  useEffect(() => {
    // if (startDate === '' || validity === 0) return;
    if (startDate === '') return;

    // setEndDate(moment(startDate, 'YYYY.MM.DD').add(validity, 'days').format('YYYY.MM.DD'));
    let division = 'days';

    if (validityUnit === 'year') division = 'year';
    else if (validityUnit === 'month') division = 'month';

    setEndDate(moment(startDate, 'YYYY.MM.DD').add(validity, division).subtract(1, 'days').format('YYYY.MM.DD'));
    // console.log("???");
  }, [startDate, validity, validityUnit])

  // 카드 현금 할인 금액 변경시 //
  function changeValue() {
    var card = CardPaymentRef.current.getValue();
    var cash = CashPaymentRef.current.getValue();
    var discount = DiscountPaymentRef.current.getValue();

    // setTotalPrice(parseInt(card) + parseInt(cash));
    setNotReceivedPrice((totalPrice - (card + cash + discount)) > 0 ? (totalPrice - (card + cash + discount)) : 0);
  }


  // 카테고리 선택시 //
  function changeCategorySelectVal(value) {
    let newDataList = [];

    for (let i = 0; i < memberShipData.length; i++) {
      // if (memberShipData[i].lessonId === value.id) newDataList.push(memberShipData[i]);
      if (memberShipData[i].categoryId === value.id) newDataList.push(memberShipData[i]);
    }

    setDivisionMemberShipData(newDataList);
    MemberShipSelectRef.current.setData(newDataList);

    if (newDataList.length === 0) MemberShipSelectRef.current.setValue({ id: -1, name: '선택' });
    else MemberShipSelectRef.current.setValue({ id: 0, name: '선택' });

    TotalMaxRef.current.setValue('');
    setTotalPrice(0);
    CardPaymentRef.current.setValue('');
    CashPaymentRef.current.setValue('');
    DiscountPaymentRef.current.setValue('');
  }

  // 회원권 변경시 //
  function changeMemberShipSelectVal(value) {
    setMaxCount(value.totalMax);
    TotalMaxRef.current.setValue(value.totalMax);
    setTotalPrice(value.price);
    // setValidity(value.validity - 1);
    // setValidityUnit(value.validityUnit);
    // setValidity(value.validityUnit === 'day' ? value.validity - 1 : value.validity);
    setValidity(value.validity);
    setValidityUnit(value.validityUnit);
    // CardPaymentRef.current.setValue(value.price);
    // CashPaymentRef.current.setValue('');
    // DiscountPaymentRef.current.setValue('');
    CardPaymentRef.current.setValue('');
    CashPaymentRef.current.setValue('');
    DiscountPaymentRef.current.setValue('');

    setNotReceivedPrice(value.price);
  }

  function CalenderClick(className) {
    if (className === 'StartCalender') EndCalenderRef.current.closeCalender();
    else StartCalenderRef.current.closeCalender();
  }

  // 이전 결제예약 내역이 현재 신청내역과 일치하는지 확인 //
  function checkSamePurchasePlan() {
    let memberShipData = MemberShipSelectRef.current.getObjectVal();

    if (memberShipData.id === 0
      || memberShipData.id === -1) {
      alert('회원권을 선택해주세요');
      return;
    }

    if (memberShipData.autoPayment === 1 && CashPaymentRef.current.getValue() > 0) {
      alert('정기 구독회원권은 현금결제를 이용할 수 없습니다.');
      return;
    }

    // 이전에 넣었던 데이터와 현재 결제데이터가 변경점이 있는지 확인
    if (MemberShipSelectRef.current.getObjectVal().id !== data.membershipId) {
      var requestData = {
        merchantUid: data.merchantUid,
        itemName: MemberShipSelectRef.current.getObjectVal().name
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postData('sales/change/item', 'POST', requestData);

          if (responseData.status === 200) {
            registerClick();
          }
          else if (responseData.status === 401) {
            alert('로그인 후 다시시도하세요');
            history.push('/');
          }
          else {
            alert('결제내역 변경에 실패하였습니다.\n잠시후 다시시도해주세요.');
            return;
          }
        }
      )();
    }
    else {
      registerClick();
    }
  }

  // 이전 등록 회원권 정보 뷰 정리 //
  function onCalcPrevSalesInfo(className) {
    if (userData === undefined || userData.prevSales === undefined || userData.prevMemberShip === undefined) return '';

    if (className === 'MemberShipName') return userData.prevSales.itemName;
    else if (className === 'Date') return moment(userData.prevUsage.startDate).format('YY.MM.DD') + ' - ' + moment(userData.prevUsage.endDate).format('YY.MM.DD');
    else if (className === 'TotalMax') return userData.prevUsage.totalMax === -1 ? '제한없음' : userData.prevUsage.totalMax;
    else if (className === 'TotalPrice') return (parseInt(userData.prevSales.cashPrice) + parseInt(userData.prevSales.cardPrice)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    else if (className === 'CardPrice') return (parseInt(userData.prevSales.cardPrice)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    else if (className === 'CashPrice') return (parseInt(userData.prevSales.cashPrice)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    else if (className === 'NotReceivedPrice') {
      var total = parseInt(userData.prevMemberShip.price);
      var card = parseInt(userData.prevSales.cardPrice);
      var cash = parseInt(userData.prevSales.cashPrice);
      var discount = parseInt(userData.prevSales.discountPrice);

      if ((total - (card + cash + discount)) > 0) return (total - (card + cash + discount)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      else return '0';
    }
    else if (className === 'Approval') return moment(userData.prevSales.approvalDate).format('YY.MM.DD');
    else if (className === 'Validity') {
      if (moment(userData.prevUsage.endDate).diff(moment(), 'day') >= 0) return moment(userData.prevUsage.endDate).diff(moment(), 'day') + '일';
      else return '만료';
    }
  }

  // 이전 등록 회원권 정보 호버링 //
  function onMouseOverPrevSales() {
    setPrevSalesColor('#FF8B48');
  }

  // 이전 등록 회원권 정보 호버링 //
  function onMouseLeavePrevSales() {
    setPrevSalesColor('#595959');
  }

  // 이전 기록 클릭시 회원권 세팅
  function onClickPrevSales() {
    // 수업이 정상 변경 되었으면 실행 //
    if (CategorySelectRef.current.setDefaultValForId(userData.prevMemberShip.categoryId)) {
      // 회원권에 알맞는 수업 찾기 //
      for (var i = 0; i < categoryData.length; i++) {
        if (categoryData[i].id === userData.prevMemberShip.categoryId) {

          var newDataList = [];

          for (var j = 0; j < memberShipData.length; j++) {
            if (memberShipData[j].categoryId === categoryData[i].id) newDataList.push(memberShipData[j]);
          }

          setDivisionMemberShipData(newDataList);
          var result = MemberShipSelectRef.current.setDataAndSetDefaultValForId(newDataList, userData.prevMemberShip.id);

          if (newDataList.length === 0) MemberShipSelectRef.current.setValue({ id: -1, name: '선택' });

          TotalMaxRef.current.setValue(userData.prevMemberShip.totalMax);
          setTotalPrice(userData.prevMemberShip.price);
          CardPaymentRef.current.setValue(userData.prevSales.cardPrice);
          CashPaymentRef.current.setValue(userData.prevSales.cashPrice);
          DiscountPaymentRef.current.setValue(userData.prevSales.discountPrice);
          setNotReceivedPrice(userData.prevSales.notReceivedPrice);
          setValidity(userData.prevMemberShip.validity - 1);

          break;
        }
      }
    }
  }

  return (
    <TopLevelWrapper>
      <TitleWrapper>
        <Text fontFmaily='NotoSansKR-Bold' fontSize={16} color='#232323' cursor='default'>{userData === undefined ? '' : userData.userName}님의 회원권 신청 정보</Text>
      </TitleWrapper>
      <InfoBox/>
      <TitleWrapper style={{ marginTop: `16px` }}>
        <MemberShipTitle>이전 등록 회원권 정보</MemberShipTitle>
      </TitleWrapper>
      <InfoBox>
        {
          userData?.prevSales === undefined ?
            <RowWrapper>
              <EmptySalesWrapper>
                <Text fontFmaily='NotoSansKR-Regular' fontSize={14} color='#E0E0E0' cursor='default'>이전 구매하신 회원권 정보가 없습니다.</Text>
              </EmptySalesWrapper>
            </RowWrapper>
            :
            <RowWrapper onClick={onClickPrevSales} onMouseOver={onMouseOverPrevSales} onMouseLeave={onMouseLeavePrevSales}>
              <PrevSalesInfoBoxWrapper width={159} marginRight={28}>
                <Text fontFmaily='NotoSansKR-Medium' fontSize={14} color='#232323' cursor='default'>회원권</Text>
                <Text fontFmaily='NotoSansKR-Regular' fontSize={14} color={prevSalesColor} cursor='pointer'>{onCalcPrevSalesInfo('MemberShipName')}</Text>
              </PrevSalesInfoBoxWrapper>
              <PrevSalesInfoBoxWrapper width={127} marginRight={31}>
                <Text fontFmaily='NotoSansKR-Medium' fontSize={14} color='#232323' cursor='default'>기간</Text>
                <Text fontFmaily='NotoSansKR-Regular' fontSize={14} color={prevSalesColor} cursor='pointer'>{onCalcPrevSalesInfo('Date')}</Text>
              </PrevSalesInfoBoxWrapper>
              <PrevSalesInfoBoxWrapper width={55} marginRight={28}>
                <Text fontFmaily='NotoSansKR-Medium' fontSize={14} color='#232323' cursor='default'>최대수강</Text>
                <Text fontFmaily='NotoSansKR-Regular' fontSize={14} color={prevSalesColor} cursor='pointer'>{onCalcPrevSalesInfo('TotalMax')}</Text>
              </PrevSalesInfoBoxWrapper>
              <PrevSalesInfoBoxWrapper width={67} marginRight={24}>
                <Text fontFmaily='NotoSansKR-Medium' fontSize={14} color='#232323' cursor='default'>결제금액</Text>
                <Text fontFmaily='NotoSansKR-Regular' fontSize={14} color={prevSalesColor} cursor='pointer'>{onCalcPrevSalesInfo('TotalPrice')}</Text>
              </PrevSalesInfoBoxWrapper>
              <PrevSalesInfoBoxWrapper width={67} marginRight={24}>
                <Text fontFmaily='NotoSansKR-Medium' fontSize={14} color='#232323' cursor='default'>카드</Text>
                <Text fontFmaily='NotoSansKR-Regular' fontSize={14} color={prevSalesColor} cursor='pointer'>{onCalcPrevSalesInfo('CardPrice')}</Text>
              </PrevSalesInfoBoxWrapper>
              <PrevSalesInfoBoxWrapper width={67} marginRight={24}>
                <Text fontFmaily='NotoSansKR-Medium' fontSize={14} color='#232323' cursor='default'>현금</Text>
                <Text fontFmaily='NotoSansKR-Regular' fontSize={14} color={prevSalesColor} cursor='pointer'>{onCalcPrevSalesInfo('CashPrice')}</Text>
              </PrevSalesInfoBoxWrapper>
              <PrevSalesInfoBoxWrapper width={67} marginRight={24}>
                <Text fontFmaily='NotoSansKR-Medium' fontSize={14} color='#232323' cursor='default'>미수금</Text>
                <Text fontFmaily='NotoSansKR-Regular' fontSize={14} color={prevSalesColor} cursor='pointer'>{onCalcPrevSalesInfo('NotReceivedPrice')}</Text>
              </PrevSalesInfoBoxWrapper>
              <PrevSalesInfoBoxWrapper width={61} marginRight={28}>
                <Text fontFmaily='NotoSansKR-Medium' fontSize={14} color='#232323' cursor='default'>결제일</Text>
                <Text fontFmaily='NotoSansKR-Regular' fontSize={14} color={prevSalesColor} cursor='pointer'>{onCalcPrevSalesInfo('Approval')}</Text>
              </PrevSalesInfoBoxWrapper>
              <PrevSalesInfoBoxWrapper width={55}>
                <Text fontFmaily='NotoSansKR-Medium' fontSize={14} color='#232323' cursor='default'>남은 일수</Text>
                <Text fontFmaily='NotoSansKR-Regular' fontSize={14} color={prevSalesColor} cursor='pointer'>{onCalcPrevSalesInfo('Validity')}</Text>
              </PrevSalesInfoBoxWrapper>
            </RowWrapper>
        }
      </InfoBox>
      <TitleWrapper style={{ marginTop: `24px` }}>
        <MemberShipTitle>결제 정보</MemberShipTitle>
      </TitleWrapper>
      <InfoBox>
        <FirstLayerWrapper>
          <ComponentWrapper>
            <ComponentTitle>카테고리</ComponentTitle>
            <CategorySelectBox ref={CategorySelectRef} options={categoryData} disabled={isUsing} changeFn={changeCategorySelectVal} />
          </ComponentWrapper>
          <ComponentWrapper>
            <ComponentTitle>회원권</ComponentTitle>
            <MemberShipSelectBox ref={MemberShipSelectRef} disabled={isUsing} data={divisionMemberShipData} changeFn={changeMemberShipSelectVal} />
          </ComponentWrapper>
        </FirstLayerWrapper>
        <SecondLayerWrapper>
          <ComponentWrapper style={{ marginRight: `24px` }}>
            <ComponentTitle>회원권 시작일</ComponentTitle>
            <CalenderInput ref={StartCalenderRef} disabled={isUsing} value={startDate} setValue={setStartDate} className='StartCalender' clickFn={CalenderClick} placeholder='시작일' width={280} />
          </ComponentWrapper>
          <ComponentWrapper style={{ marginRight: `26px` }}>
            <ComponentTitle>회원권 종료일</ComponentTitle>
            <CalenderInput ref={EndCalenderRef} disabled={isUsing} value={endDate} setValue={setEndDate} className='EndCalender' clickFn={CalenderClick} placeholder='종료일' width={280} />
          </ComponentWrapper>
          <ComponentWrapper style={{ marginRight: `16px` }}>
            <ComponentTitle>회원권 총 일수</ComponentTitle>
            <FixedInputWrapper>
              <FixedInput>{moment(endDate).diff(moment(startDate), 'days') + 1}</FixedInput>
              <FixedUnit>일</FixedUnit>
            </FixedInputWrapper>
          </ComponentWrapper>
          <ComponentWrapper>
            <MemberShipTotalMaxCountInput ref={TotalMaxRef} noLimitCount={maxCount === -1} explanation='최대 수강 횟수' />
          </ComponentWrapper>
        </SecondLayerWrapper>
        <SecondLayerWrapper style={{ marginTop: `19px` }}>
          <ComponentWrapper style={{ marginRight: `60px` }}>
            <ComponentTitle>결제일</ComponentTitle>
            <CalenderInput ref={ApprovalCalenderRef} disabled={isUsing} value={approvalDate} setValue={setApprovalDate} className='PaymentCalender' clickFn={CalenderClick} placeholder='결제일' width={234} />
          </ComponentWrapper>
          <ComponentWrapper style={{ marginRight: `27px` }}>
            <ComponentTitle>회원권 가격</ComponentTitle>
            <CalcPaymentInputWrapper>
              <CalcPaymentInput>{totalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</CalcPaymentInput>
              <CalcPaymentUnit>원</CalcPaymentUnit>
            </CalcPaymentInputWrapper>
          </ComponentWrapper>
          <ComponentWrapper style={{ marginRight: `27px` }}>
            <ComponentTitle>카드 결제 금액</ComponentTitle>
            <PaymentInput ref={CardPaymentRef} changeFn={changeValue} width={77} placeholder={0} disabled={isUsing} />
          </ComponentWrapper>
          <ComponentWrapper style={{ marginRight: `27px` }}>
            <ComponentTitle>현금 결제 금액</ComponentTitle>
            <PaymentInput ref={CashPaymentRef} changeFn={changeValue} width={77} placeholder={0} disabled={isUsing} />
          </ComponentWrapper>
          <ComponentWrapper style={{ marginRight: `27px` }}>
            <ComponentTitle>할인 금액</ComponentTitle>
            <DiscountPaymentInput ref={DiscountPaymentRef} changeFn={changeValue} width={77} placeholder={0} disabled={isUsing} />
          </ComponentWrapper>
          <ComponentWrapper>
            <ComponentTitle>미수금 금액</ComponentTitle>
            <CalcPaymentInputWrapper>
              <NotReceivedPaymentInput>{notReceivedPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</NotReceivedPaymentInput>
              <CalcPaymentUnit>원</CalcPaymentUnit>
            </CalcPaymentInputWrapper>
          </ComponentWrapper>
        </SecondLayerWrapper>
      </InfoBox>
      <ButtonBox>
        <ExitButton onClick={closeFn}>
          <Text fontFmaily='NotoSansKR-Regular' fontSize={14} color='#CBCBCB' cursor='pointer'>취소</Text>
        </ExitButton>
        <SaveButton onClick={checkSamePurchasePlan}>
          <Text fontFmaily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='pointer'>결제 승인</Text>
        </SaveButton>
      </ButtonBox>
    </TopLevelWrapper>
  )
})

export default OnSitePaymentMemberShipInfoView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 1020px;
  height: 548px;

  border-radius: 12px;
  background-color: #FFFFFF;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);

  margin-top: 38px;
  margin-bottom: 96px;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  
  justify-content: space-between;
  align-items: center;

  // margin-left: 28px;
  width: 93%;  
`;

const MemberShipTitle = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;

  border-top: 2px solid #ECECEC;

  margin-top: 12px;
  width: 93.52%;
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 12px;

  width: 100%;
  height: 60px;

  :hover {
    cursor: pointer;
  }
`;

const EmptySalesWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
`;

const PrevSalesInfoBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-right: ${props => props.marginRight}px;

  width: ${props => props.width}px;
  // min-width: ${props => props.width}px;
  height: 48px;

  overflow: hidden;
  gap: 8px;
`;

const FirstLayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  gap: 24px;
  // margin-top: 24px;
  margin-top: 12px;
`;

const SecondLayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  // gap: 24px;
  // margin-top: 24px;
  margin-top: 14px;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ComponentTitle = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #595959;

  margin: 0 0 0 0;
`;

const CalcPaymentInputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 5px;
  margin-top: 8px;
`;

const CalcPaymentInput = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  padding: 0 8px 0 8px;
  margin: 0 0 0 0;

  width: 77px;
  height: 30px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background-color: #ECECEC;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  // color: #FFFFFF;
  color: #CBCBCB;
`;

const NotReceivedPaymentInput = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  padding: 0 8px 0 8px;
  margin: 0 0 0 0;

  width: 77px;
  height: 30px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background-color: #FFFFFF;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #FF3131;
`;

const CalcPaymentUnit = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  margin: 0 0 0 0;

  color: #777777;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 15px;
`;

const PaymentButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 150px;
  height: 32px;

  border-radius: 8px;
  background-color: #33C8FF;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  margin: 0 0 0 0;

  opacity: 0;

  :hover {
    cursor: default;
  }
`;

const SecondSaveButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 150px;
  height: 32px;

  border-radius: 8px;
  background-color: #5BD98A;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  margin: 0 0 0 0;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }
`;

const FixedInputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 8px;
`;

const FixedInput = styled.div`
  display: flex;
  flex-direction: column;

  width: 53px;
  height: 30px;

  justify-content: center;
  align-items: flex-start;

  // margin: 8px 0 0 0;
  padding: 0 8px 0 8px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background-color: #ECECEC;
  
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;
`;

const FixedUnit = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  margin: 0 0 0 8px;

  color: #777777;
`;

const Text = styled.div`
  font-family: ${props => props.fontFmaily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: ${props => props.cursor};
  }
`;

const ButtonBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: flex-end;

  margin-top: 43px;

  width: 93.52%;

  gap: 16px;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 72px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;

  :hover {
    cursor: pointer;
  }
`;

const SaveButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 15px;
  background-color: #5BD98A;

  :hover {
    cursor: pointer;
  }
`;