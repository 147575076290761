import { React, useState, useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';

import FetchModule from '../../Share/Network/FetchModule';
import MovementSearchInput from '../../../Component/NoteComponent/MovementSearchInput';

import ExitButtonImage from '../../../image/ExitButton.png';
import CheckImage from '../../../image/CheckImage.png';
import WhiteCheck from '../../../image/WhiteCheck.png';
import TextInput from '../../../Component/NoteComponent/TextInput';
import MovementDetailCommentInput from '../../../Component/NoteComponent/MovementDetailCommentInput';

import ProgramVideoIcon from '../../../image/ProgramVideoIcon.png';
import NoneProgramVideoIcon from '../../../image/GrayProgramVideoIcon.png';
import YoutubeURLInput from '../../../Component/NoteComponent/YoutubeURLInput';

function AddMovementModal(props) {
  const { successAddMovement, close } = props;

  const [movementList, setMovementList] = useState([]);
  const [divideData, setDivideData] = useState([]);
  const [value, setValue] = useState({ movementName: '' });
  const [recentlyValue, setRecentlyValue] = useState({ movementName: '' })
  const [myMovementName, setMyMovementName] = useState('');
  const [videoLink, setVideoLink] = useState('');
  const [movementDetailComment, setMovementDetailComment] = useState('');
  const [recentlySearchHistory, setRecentlySearchHistory] = useState([]);
  const [isClickedPrevVideo, setIsClickedPrevVideo] = useState(false);
  const [prevVideoLinkId, setPrevVideoLinkId] = useState('');
  const [isClickedMyMovement, setIsClickedMyMovement] = useState(false);

  // MyMovement Option //
  const [isUseWeight, setIsUseWeight] = useState(false);
  const [isUseCount, setIsUseCount] = useState(false);
  const [isUseTime, setIsUseTime] = useState(false);
  const [isUseTempo, setIsUseTempo] = useState(false);
  const [isUseDistance, setIsUseDistance] = useState(false);
  const [isUseHeight, setIsUseHeight] = useState(false);
  const [isUseCalories, setIsUseCalories] = useState(false);
  // MyMovement Option //

  const RecentlySearchRef = useRef();
  const ViewBoxRef = useRef();
  const VideoPrevBoxRef = useRef();
  const MyMovementRef = useRef();
  const RecentlyMovementRef = useRef([]);
  const SearchMovementRef = useRef([]);

  // Modal 창 스크롤 제어 //
  useEffect(() => {
    document.body.style = `overflow: hidden`;
    return () => document.body.style = `overflow: auto`;
  }, [])

  // Movement Data Setting //
  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getDataOfExercise('movement/list', 'GET');
        // console.log(responseData.data)
        if (responseData.status === 200) setMovementList(responseData.data);
      }
    )();
  }, [])

  // 저장된 최근 검색어 불러오기 //
  useEffect(() => {
    let resentlyText = window.localStorage.getItem('RecentlySearchText');
    let searchData = [];

    if (resentlyText === null || resentlyText === undefined || resentlyText === '') searchData = [];
    else searchData = resentlyText.split('!@!');

    searchData = searchData.reverse();

    setRecentlySearchHistory(searchData);
  }, [])

  // 동작 검색 //
  useEffect(() => {
    if (value.movementName.length < 2) return;

    let newDivideData = [];
    let newMovementList = movementList.slice();

    // for (var i = 0; i < movementList.length; i++) {
    //   if (newMovementList[i].movementName.toLowerCase().replaceAll(' ', '') === value.movementName.toLocaleLowerCase().replaceAll(' ', '')) {
    //     newDivideData.unshift(newMovementList[i]);
    //   }
    //   else if (newMovementList[i].movementName.toLowerCase().replaceAll(' ', '').includes(value.movementName.toLowerCase().replaceAll(' ', ''))) {
    //     newDivideData.push(newMovementList[i]);
    //   }
    //   // else if (newMovementList[i].movementName.toLowerCase().replaceAll(' ', '').includes(value.movementName.toLowerCase().replaceAll(' ', '')))
    //   else if (newMovementList[i].keyword.includes(value.movementName)) {
    //     newDivideData.push(newMovementList[i]);
    //   }
    // }
    for (let i = 0; i < movementList.length; i++) {
      let dataName = '';
      let valueName = '';

      if (value.movementName.includes('-')) {
        dataName = newMovementList[i].movementName.toLowerCase().replaceAll(' ', '');
        valueName = value.movementName.toLowerCase().replaceAll(' ', '');
      }
      else {
        dataName = newMovementList[i].movementName.toLowerCase().replaceAll(' ', '').replaceAll('-', '');
        valueName = value.movementName.toLowerCase().replaceAll(' ', '').replaceAll('-', '');
      }

      if (dataName.includes(valueName)) {
        // 검색된 단어와 기존 단어의 유사도 계산 //
        newMovementList[i].similarity = (valueName.length / dataName.length) * 100;
        newDivideData.push(newMovementList[i]);
      }
      else if (newMovementList[i].keyword.includes(valueName)) {
        // 검색된 단어와 기존 단어의 유사도 계산 //
        newMovementList[i].similarity = (valueName.length / newMovementList[i].keyword.length) * 100;
        newDivideData.push(newMovementList[i]);
      }
    }

    // newDivideData.sort((a, b) => b.similarity - a.completeRep || a.tieBreak - b.tieBreak);
    newDivideData.sort((a, b) => b.similarity - a.similarity);

    setMyMovementName(value.movementName);
    setDivideData(newDivideData);
  }, [value, movementList])

  // 나만의 동작 영상 링크 작성시 //
  useEffect(() => {
    let newVideoLinkId = '';

    if (videoLink.includes('https://youtu.be/')) {
      newVideoLinkId = videoLink.replace('https://youtu.be/', '');
    }
    else if (videoLink.includes('https://www.youtube.com/watch?v=')) {
      newVideoLinkId = videoLink.replace('https://www.youtube.com/watch?v=', '');
    }
    // console.log(videoLink);
    // console.log(newVideoLinkId);
    setPrevVideoLinkId(newVideoLinkId);
  }, [videoLink])

  /// 동작 움직임 에니메이션 //
  useEffect(() => {
    // if (isClickedMyMovement) {
    //   RecentlySearchRef.current.style.height = `0px`;

    //   VideoPrevBoxRef.current.style.marginTop = `0px`;
    //   VideoPrevBoxRef.current.style.height = `0px`;

    //   MyMovementRef.current.style.height = `632px`;
    // }
    // else {
    //   if (!isClickedPrevVideo) {
    //     RecentlySearchRef.current.style.height = `618px`;

    //     VideoPrevBoxRef.current.style.marginTop = `0px`;
    //     VideoPrevBoxRef.current.style.height = `0px`;
    //   }
    //   else {
    //     RecentlySearchRef.current.style.height = `334px`;

    //     VideoPrevBoxRef.current.style.marginTop = `16px`;
    //     VideoPrevBoxRef.current.style.height = `268px`;
    //   }

    //   MyMovementRef.current.style.height = `0px`;
    // }
    if (isClickedMyMovement) {
      RecentlySearchRef.current.style.height = `0px`;

      VideoPrevBoxRef.current.style.marginTop = `0px`;
      VideoPrevBoxRef.current.style.height = `0px`;

      // MyMovementRef.current.style.height = `722px`;
      MyMovementRef.current.style.height = `680px`;
    }
    else {
      if (!isClickedPrevVideo) {
        // RecentlySearchRef.current.style.height = `78%`;
        // RecentlySearchRef.current.style.maxHeight = `634px`;
        RecentlySearchRef.current.style.height = `73%`;
        RecentlySearchRef.current.style.maxHeight = `600px`;

        VideoPrevBoxRef.current.style.marginTop = `0px`;
        VideoPrevBoxRef.current.style.height = `0px`;
      }
      else {
        RecentlySearchRef.current.style.height = `26%`;
        RecentlySearchRef.current.style.maxHeight = `225px`;

        VideoPrevBoxRef.current.style.marginTop = `16px`;
        // VideoPrevBoxRef.current.style.height = `318px`;
        VideoPrevBoxRef.current.style.height = `387px`;
      }

      MyMovementRef.current.style.height = `0px`;
    }
  }, [isClickedMyMovement, value, isClickedPrevVideo, prevVideoLinkId, MyMovementRef, RecentlySearchRef, ViewBoxRef])

  // 최근 검색어 클릭시 //
  function onClickRecentlyData(data) {
    if (isClickedMyMovement) return;
    
    let newMovementList = movementList.slice();

    for (let i = 0; i < newMovementList.length; i++) {
      if (newMovementList[i].movementName === data) {
        // setValue(newMovementList[i]);
        // setRecentlyValue(newMovementList[i]);

        // 검색 내역 세팅
        let settingData = {
          // Movement Data Setting //
          movementId: newMovementList[i].movementId,
          detailComment: newMovementList[i].detailComment,
          movementOptions: newMovementList[i].movementOptions,
          isUseAvailable: newMovementList[i].isUseAvailable,
          linkCoachId: newMovementList[i].linkCoachId,
          movementName: newMovementList[i].movementName,
          keyword: newMovementList[i].keyword,
          storeId: newMovementList[i].storeId,
          videoLinkId: newMovementList[i].videoLinkId,
          // Workout Exercise Setting //
          isUseWeight: newMovementList[i].movementOptions.includes('WEIGHT') ? true : false,
          isUseCount: newMovementList[i].movementOptions.includes('REPS') ? true : false,
          isUseTime: newMovementList[i].movementOptions.includes('TIME') ? true : false,
          isUseDistance: newMovementList[i].movementOptions.includes('DISTANCE') ? true : false,
          isUseTempo: false,
          isUseHeight: newMovementList[i].movementOptions.includes('HEIGHT') ? true : false,
          isUseCalories: newMovementList[i].movementOptions.includes('CALORIES') ? true : false,
          isUseRest: false,
          weight: 0,
          count: 0,
          time: 0,
          minute: 0,
          second: 0,
          tempo: 0,
          distance: 0,
          height: 0,
          calories: 0,
          rest: 0,
          sets: 1,
          weightUnit: 'Pounds',
          heightUnit: 'm',
          caloriesUnit: 'Cal',
          distanceUnit: 'm',
          divisionWeight: 'None'
        }

        // setIsUseWeight(newMovementList[i].movementOptions.includes('WEIGHT') ? true : false);
        // setIsUseCount(newMovementList[i].movementOptions.includes('REPS') ? true : false);
        // setIsUseTime(newMovementList[i].movementOptions.includes('TIME') ? true : false);
        // setIsUseTempo(newMovementList[i].movementOptions.includes('DISTANCE') ? true : false);
        // setIsUseDistance(newMovementList[i].movementOptions.includes('DISTANCE') ? true : false);
        // setIsUseHeight(newMovementList[i].movementOptions.includes('HEIGHT') ? true : false);
        // setIsUseCalories(newMovementList[i].movementOptions.includes('CALORIES') ? true : false);

        // setRecentlyValue(settingData);
        // setPrevVideoLinkId(newMovementList[i].videoLinkId);

        // if (newMovementList[i].videoLinkId !== '') setVideoLink('https://www.youtube.com/watch?v=' + newMovementList[i].videoLinkId);
        // else setVideoLink('');

        // setIsClickedPrevVideo(true);

        // setTimeout(() => {
        //   RecentlyMovementRef.current[index]?.scrollIntoView({ behavior: 'smooth' });
        // }, 300)
    // let newMovementList = movementList.slice();

    // for (let i = 0; i < newMovementList.length; i++) {
    //   if (newMovementList[i].movementName === data) {
    //     setPrevVideoLinkId(newMovementList[i].videoLinkId);
    //     break;
    //   }
    // }
    
    // setIsClickedPrevVideo(true);
        successAddMovement(settingData);
        break;
      }
    }
  }

  // 최근 검색어 삭제 버튼 클릭시 //
  function onClickRecentlyDataDelete(index) {
    let newRecentlyData = recentlySearchHistory.slice();

    newRecentlyData.splice(index, 1);
    setRecentlySearchHistory(newRecentlyData);

    let newString = '';

    // newRecentlyData = newRecentlyData.reverse();

    // for (var i = 0; i < newRecentlyData.length; i++) {
    //   if (i === 0) newString = newRecentlyData[i];
    //   else newString += '!@!' + newRecentlyData[i];
    // }

    for (let i = newRecentlyData.length - 1; i >= 0; i--) {
      if (i === newRecentlyData.length - 1) newString = newRecentlyData[i];
      else newString += '!@!' + newRecentlyData[i];
    }

    window.localStorage.setItem('RecentlySearchText', newString);
  }

  // 검색된 내역 클릭시 //
  function onClickDivideData(data) {
    if (isClickedMyMovement) return;
    // console.log(data);
    // console.log(value);
    if (value.movementName === data.movementName && value.movementId !== undefined) setValue({ movementName: '' });
    else {
      // 검색 내역 세팅
      let settingData = {
        // Movement Data Setting //
        id: data.id,
        detailComment: data.detailComment,
        movementId: data.movementId,
        movementOptions: data.movementOptions,
        isUseAvailable: data.isUseAvailable,
        linkCoachId: data.linkCoachId,
        movementName: data.movementName,
        keyword: data.keyword,
        storeId: data.storeId,
        videoLinkId: data.videoLinkId,
        // Workout Exercise Setting //
        isUseWeight: data.movementOptions.includes('WEIGHT') ? true : false,
        isUseCount: data.movementOptions.includes('REPS') ? true : false,
        isUseTime: data.movementOptions.includes('TIME') ? true : false,
        isUseDistance: data.movementOptions.includes('DISTANCE') ? true : false,
        isUseTempo: data.movementOptions.includes('TEMPO') ? true : false,
        isUseHeight: data.movementOptions.includes('HEIGHT') ? true : false,
        isUseCalories: data.movementOptions.includes('CALORIES') ? true : false,
        isUseRest: false,
        weight: 0,
        count: 0,
        time: 0,
        minute: 0,
        second: 0,
        tempo: 0,
        distance: 0,
        height: 0,
        calories: 0,
        rest: 0,
        sets: 1,
        weightUnit: 'Pounds',
        heightUnit: 'm',
        caloriesUnit: 'Cal',
        distanceUnit: 'm',
        divisionWeight: 'None'
      }

      setValue(settingData);
      // 최근 검색 내역 최신화
      let newRecentlyData = recentlySearchHistory.slice();

      let isDuplicate = false;

      for (let i = 0; i < newRecentlyData.length; i++) {
        if (newRecentlyData[i] === data.movementName) {
          isDuplicate = true;
          break;
        }
      }

      if (!isDuplicate) {
        let newString = '';

        newRecentlyData = newRecentlyData.reverse();
        newRecentlyData.push(data.movementName);

        for (let i = 0; i < newRecentlyData.length; i++) {
          if (i === 0) newString = newRecentlyData[i];
          else newString += '!@!' + newRecentlyData[i];
        }

        window.localStorage.setItem('RecentlySearchText', newString);

        newRecentlyData = newRecentlyData.reverse();
        setRecentlySearchHistory(newRecentlyData);
      }

      // setIsUseWeight(data.movementOptions.includes('WEIGHT') ? true : false);
      // setIsUseCount(data.movementOptions.includes('REPS') ? true : false);
      // setIsUseTime(data.movementOptions.includes('TIME') ? true : false);
      // setIsUseTempo(data.movementOptions.includes('DISTANCE') ? true : false);
      // setIsUseDistance(data.movementOptions.includes('DISTANCE') ? true : false);
      // setIsUseHeight(data.movementOptions.includes('HEIGHT') ? true : false);
      // setIsUseCalories(data.movementOptions.includes('CALORIES') ? true : false);

      // setRecentlyValue(settingData);
      // setPrevVideoLinkId(data.videoLinkId);

      // if (data.videoLinkId !== '') setVideoLink('https://www.youtube.com/watch?v=' + data.videoLinkId);
      // else setVideoLink('');

      // setIsClickedPrevVideo(true);

      // setTimeout(() => {
      //   SearchMovementRef.current[index]?.scrollIntoView({ behavior: 'smooth' });
      // }, 300)
      successAddMovement(settingData);
    }
  }

  // 최근 검색내역에서 영상 미리보기 버튼 클릭시 //
  function onClickMovementVideoViewOfRecentlyList(data, index) {
    let newMovementList = movementList.slice();

    for (let i = 0; i < newMovementList.length; i++) {
      if (newMovementList[i].movementName === data) {
        // setPrevVideoLinkId(newMovementList[i].videoLinkId);
        // 검색 내역 세팅
        let settingData = {
          // Movement Data Setting //
          movementId: newMovementList[i].movementId,
          detailComment: newMovementList[i].detailComment,
          movementOptions: newMovementList[i].movementOptions,
          isUseAvailable: newMovementList[i].isUseAvailable,
          linkCoachId: newMovementList[i].linkCoachId,
          movementName: newMovementList[i].movementName,
          keyword: newMovementList[i].keyword,
          storeId: newMovementList[i].storeId,
          videoLinkId: newMovementList[i].videoLinkId,
          // Workout Exercise Setting //
          isUseWeight: newMovementList[i].movementOptions.includes('WEIGHT') ? true : false,
          isUseCount: newMovementList[i].movementOptions.includes('REPS') ? true : false,
          isUseTime: newMovementList[i].movementOptions.includes('TIME') ? true : false,
          isUseDistance: newMovementList[i].movementOptions.includes('DISTANCE') ? true : false,
          isUseTempo: false,
          isUseHeight: newMovementList[i].movementOptions.includes('HEIGHT') ? true : false,
          isUseCalories: newMovementList[i].movementOptions.includes('CALORIES') ? true : false,
          isUseRest: false,
          weight: 0,
          count: 0,
          time: 0,
          minute: 0,
          second: 0,
          tempo: 0,
          distance: 0,
          height: 0,
          calories: 0,
          rest: 0,
          sets: 1,
          weightUnit: 'Pounds',
          heightUnit: 'm',
          caloriesUnit: 'Cal',
          distanceUnit: 'm',
          divisionWeight: 'None'
        }

        setIsUseWeight(newMovementList[i].movementOptions.includes('WEIGHT') ? true : false);
        setIsUseCount(newMovementList[i].movementOptions.includes('REPS') ? true : false);
        setIsUseTime(newMovementList[i].movementOptions.includes('TIME') ? true : false);
        setIsUseTempo(newMovementList[i].movementOptions.includes('DISTANCE') ? true : false);
        setIsUseDistance(newMovementList[i].movementOptions.includes('DISTANCE') ? true : false);
        setIsUseHeight(newMovementList[i].movementOptions.includes('HEIGHT') ? true : false);
        setIsUseCalories(newMovementList[i].movementOptions.includes('CALORIES') ? true : false);

        setRecentlyValue(settingData);
        setPrevVideoLinkId(newMovementList[i].videoLinkId);

        if (newMovementList[i].videoLinkId !== '') setVideoLink('https://www.youtube.com/watch?v=' + newMovementList[i].videoLinkId);
        else setVideoLink('');

        setIsClickedPrevVideo(true);

        setTimeout(() => {
          RecentlyMovementRef.current[index]?.scrollIntoView({ behavior: 'smooth' });
        }, 300)
        break;
      }
    }
    
    setIsClickedPrevVideo(true);
  }

  // 전체 리스트에서 영상 미리보기 버튼 클릭시 //
  function onClickMovementVideoViewOfAllList(data, index) {
    // setPrevVideoLinkId(data.videoLinkId);
    let settingData = {
      // Movement Data Setting //
      movementId: data.movementId,
      detailComment: data.detailComment,
      movementOptions: data.movementOptions,
      isUseAvailable: data.isUseAvailable,
      linkCoachId: data.linkCoachId,
      movementName: data.movementName,
      keyword: data.keyword,
      storeId: data.storeId,
      videoLinkId: data.videoLinkId,
      // Workout Exercise Setting //
      isUseWeight: data.movementOptions.includes('WEIGHT') ? true : false,
      isUseCount: data.movementOptions.includes('REPS') ? true : false,
      isUseTime: data.movementOptions.includes('TIME') ? true : false,
      isUseDistance: data.movementOptions.includes('DISTANCE') ? true : false,
      isUseTempo: false,
      isUseHeight: data.movementOptions.includes('HEIGHT') ? true : false,
      isUseCalories: data.movementOptions.includes('CALORIES') ? true : false,
      isUseRest: false,
      weight: 0,
      count: 0,
      time: 0,
      minute: 0,
      second: 0,
      tempo: 0,
      distance: 0,
      height: 0,
      calories: 0,
      rest: 0,
      sets: 1,
      weightUnit: 'Pounds',
      heightUnit: 'm',
      caloriesUnit: 'Cal',
      distanceUnit: 'm',
      divisionWeight: 'None'
    }

    setIsUseWeight(data.movementOptions.includes('WEIGHT') ? true : false);
    setIsUseCount(data.movementOptions.includes('REPS') ? true : false);
    setIsUseTime(data.movementOptions.includes('TIME') ? true : false);
    setIsUseTempo(data.movementOptions.includes('DISTANCE') ? true : false);
    setIsUseDistance(data.movementOptions.includes('DISTANCE') ? true : false);
    setIsUseHeight(data.movementOptions.includes('HEIGHT') ? true : false);
    setIsUseCalories(data.movementOptions.includes('CALORIES') ? true : false);

    setRecentlyValue(settingData);
    setPrevVideoLinkId(data.videoLinkId);

    if (data.videoLinkId !== '') setVideoLink('https://www.youtube.com/watch?v=' + data.videoLinkId);
    else setVideoLink('');

    setIsClickedPrevVideo(true);

    setTimeout(() => {
      SearchMovementRef.current[index]?.scrollIntoView({ behavior: 'smooth' });
    }, 300)
    // setIsClickedPrevVideo(true);
  }

  // 영상 미리보기 닫기 버튼 클릭시 //
  function onClickMovementVideoViewExit() {
    // console.log(isClickedPrevVideo);
    // console.log(prevVideoLinkId);
    setIsClickedPrevVideo(false);
    setPrevVideoLinkId('');
  }

  // 나만의 동작 추가하기 체크박스 클릭시 //
  function onClickAdditionMyMovement() {
    setPrevVideoLinkId('');
    setIsClickedMyMovement(!isClickedMyMovement);
  }

  // 데이터 추가 완료 클릭시 //
  function onClickSuccess() {
    // 나만의 운동이 아닌경우 //
    if (!isClickedMyMovement) {
      // 선택된 운동이 없는경우 //
      if (value.movementId === undefined) {
        // 최근 검색운동이 선택되었는지 확인 //
        if (recentlyValue.movementId === undefined) alert('추가할 동작을 선택해주세요!');
        else {
          // console.log(recentlyValue);
          // console.log(prevVideoLinkId);
          let settingData = recentlyValue;

          settingData.isUseWeight = isUseWeight;
          settingData.isUseCount = isUseCount;
          settingData.isUseTime = isUseTime;
          settingData.isUseDistance = isUseDistance;
          settingData.isUseTempo = isUseTempo;
          settingData.isUseHeight = isUseHeight;
          settingData.isUseCalories = isUseCalories;

          if (settingData.videoLinkId !== prevVideoLinkId) settingData.customVideoLinkId = prevVideoLinkId;

          // console.log(settingData);
          successAddMovement(settingData);
        }
      }
      else {
        let settingData = value;

        settingData.isUseWeight = isUseWeight;
        settingData.isUseCount = isUseCount;
        settingData.isUseTime = isUseTime;
        settingData.isUseDistance = isUseDistance;
        settingData.isUseTempo = isUseTempo;
        settingData.isUseHeight = isUseHeight;
        settingData.isUseCalories = isUseCalories;

        if (settingData.videoLinkId !== prevVideoLinkId) settingData.customVideoLinkId = prevVideoLinkId;

        // successAddMovement(value);
        successAddMovement(settingData);
      }
    }
    else {
      let movementName = myMovementName;
      let videoLinkId = '';
      let movementOptions = '';

      if (movementName === '') {
        alert('동작 이름을 설정해주세요');
        return;
      }

      if (videoLink.includes('https://www.youtube.com/watch?v=')) videoLinkId = videoLink.replace('https://www.youtube.com/watch?v=', '');
      else if (videoLink.includes('https://youtu.be/')) videoLinkId = videoLink.replace('https://youtu.be/', '');

      if (isUseWeight) movementOptions += ',WEIGHT';
      if (isUseCount) movementOptions += ',REPS';
      if (isUseTime) movementOptions += ',TIME';
      if (isUseTempo) movementOptions += ',TEMPO';
      if (isUseDistance) movementOptions += ',DISTANCE';
      if (isUseHeight) movementOptions += ',HEIGHT';
      if (isUseCalories) movementOptions += ',CALORIES';

      if (movementOptions.length !== 0) movementOptions = movementOptions.slice(1);

      let data = {
        detailComment: movementDetailComment,
        isUseAvailable: false,
        userId: 'None',
        movementName: myMovementName,
        movementOptions: movementOptions,
        keyword: '',
        storeId: 0,
        videoLinkId: videoLinkId,
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          let responseData = await fetchModule.postDataOfExercise('movement/save', 'POST', data);
          // console.log(responseData)
          if (responseData.status === 200) {
            let settingData = {
              // Movement Data Setting //
              id: 0,
              detailComment: responseData.data[0].detailComment,
              movementOptions: responseData.data[0].movementOptions,
              isUseAvailable: responseData.data[0].isUseAvailable,
              linkCoachId: responseData.data[0].linkCoachId,
              movementId: responseData.data[0].movementId,
              movementName: responseData.data[0].movementName,
              keyword: responseData.data[0].keyword,
              storeId: responseData.data[0].storeId,
              videoLinkId: responseData.data[0].videoLinkId,
              // Workout Exercise Setting //
              isUseWeight: isUseWeight,
              isUseCount: isUseCount,
              isUseTime: isUseTime,
              isUseDistance: isUseDistance,
              isUseTempo: isUseTempo,
              isUseHeight: isUseHeight,
              isUseCalories: isUseCalories,
              isUseRest: false,
              weight: 0,
              count: 0,
              time: 0,
              minute: 0,
              second: 0,
              tempo: 0,
              distance: 0,
              height: 0,
              calories: 0,
              rest: 0,
              sets: 1,
              weightUnit: 'Pounds',
              heightUnit: 'm',
              caloriesUnit: 'Cal',
              distanceUnit: 'm',
              divisionWeight: 'None'
            }

            successAddMovement(settingData);
            // 최근 검색 내역 최신화
            let newRecentlyData = recentlySearchHistory.slice();

            let isDuplicate = false;

            for (let i = 0; i < newRecentlyData.length; i++) {
              if (newRecentlyData[i] === data.movementName) {
                isDuplicate = true;
                break;
              }
            }

            if (!isDuplicate) {
              let newString = '';

              newRecentlyData = newRecentlyData.reverse();
              newRecentlyData.push(data.movementName);

              for (let i = 0; i < newRecentlyData.length; i++) {
                if (i === 0) newString = newRecentlyData[i];
                else newString += '!@!' + newRecentlyData[i];
              }

              window.localStorage.setItem('RecentlySearchText', newString);

              newRecentlyData = newRecentlyData.reverse();
              setRecentlySearchHistory(newRecentlyData);
            }
          }
          else if (responseData.status === 401) {
            alert("잘못된 권한 접근입니다.");
          }
        }
      )();
    }
  }

  // 검색 색상 변하기 //
  function calcColorText(movementName, value, index) {
    // '-'가 포함되어 있을 경우 //
    if (value.includes('-')) {
      let lowerMovementName = movementName.toLowerCase().replaceAll(' ', '');
      let lowerValue = value.toLowerCase().replaceAll(' ', '');
      let startIndex = lowerMovementName.indexOf(lowerValue); 
      let frontCount = 0;
      let middleCount = 0;

      for (let i = 1; i < startIndex; i++) {
        if (movementName[i] === ' ') frontCount += 1;
      }

      for (let i = startIndex; i < (startIndex + frontCount + lowerValue.length); i++) {
        if (movementName[i] === ' ') middleCount += 1;
      }

      if (startIndex === -1) {
        if (index === 0) return movementName;
        else return '';
      }
      else {
        if (index === 0) return movementName.slice(0, (startIndex + frontCount));
        else if (index === 1) return movementName.slice((startIndex + frontCount), (startIndex + frontCount + lowerValue.length + middleCount));
        else return movementName.slice((startIndex + frontCount + lowerValue.length + middleCount), movementName.length);
      }
    }
    else {
      let lowerMovementName = movementName.toLowerCase().replaceAll(' ', '').replaceAll('-', '');
      let lowerValue = value.toLowerCase().replaceAll(' ', '').replaceAll('-', '');
      let startIndex = lowerMovementName.indexOf(lowerValue); 
      let frontCount = 0;
      let middleCount = 0;
  
      for (let i = 1; i < startIndex; i++) {
        if (movementName[i] === ' ' || movementName[i] === '-') frontCount += 1;
      }
  
      for (let i = startIndex; i < (startIndex + frontCount + lowerValue.length); i++) {
        if (movementName[i] === ' ' || movementName[i] === '-') middleCount += 1;
      }
  
      if (startIndex === -1) {
        if (index === 0) return movementName;
        else return '';
      }
      else {
        if (index === 0) return movementName.slice(0, (startIndex + frontCount));
        else if (index === 1) return movementName.slice((startIndex + frontCount), (startIndex + frontCount + lowerValue.length + middleCount));
        else return movementName.slice((startIndex + frontCount + lowerValue.length + middleCount), movementName.length);
      }
    }
  }

  // 운동 영상 있는지 여부 확인후 아이콘 표출 //
  function decideProgramVideoIcon(data) {
    let newMovementList = movementList.slice();
    let isExist = false;

    for (let i = 0; i < newMovementList.length; i++) {
      if (newMovementList[i].movementName === data) {
        if (newMovementList[i].videoLinkId !== '') isExist = true;
        break;
      }
    }
    
    return isExist;
  }

  return (
    <TopLevelWrapper>
      <Modal isClickedMyMovement={isClickedMyMovement}>
        <ModalTitleWrapper isClickedMyMovement={isClickedMyMovement}>
          {
            isClickedMyMovement ?
              <TitleWrapper>
                <PlusWrapper>
                  <Bar width={18} height={2} radius={2} />
                  <Bar width={2} height={18} radius={2} />
                </PlusWrapper>
                <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'>동작 추가하기</Text>
              </TitleWrapper>
              :
              <TitleWrapper>
                <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'>동작 추가하기</Text>
              </TitleWrapper>
          }
        </ModalTitleWrapper>
        <TotalWrapper>
          { !isClickedMyMovement && <MovementSearchInput value={value} setValue={setValue} disabled={isClickedMyMovement} /> }
          {
            <RecentlySearchWrapper ref={RecentlySearchRef} isClickedMyMovement={isClickedMyMovement}>
              {
                value.movementName === '' ?
                  <DataWrapper>
                    <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#6DD49E'>최근 검색어({recentlySearchHistory.length})</Text>
                    {
                      recentlySearchHistory.map((data, index) => (
                        <RecentlyBox key={index} ref={(el) => (RecentlyMovementRef.current[index] = el)}>
                          <RecentlyTextWrapper onClick={() => onClickRecentlyData(data)}>
                            {/* <RecentlyText>{data}</RecentlyText> */}
                              <RecentlyText isClicked={(recentlyValue.movementName === data && recentlyValue.movementId !== undefined)}>{calcColorText(data, recentlyValue.movementName, 0)}</RecentlyText>
                              <SearchText>{calcColorText(data, recentlyValue.movementName, 1)}</SearchText>
                              <RecentlyText isClicked={(recentlyValue.movementName === data && recentlyValue.movementId !== undefined)}>{calcColorText(data, recentlyValue.movementName, 2)}</RecentlyText>
                          </RecentlyTextWrapper>
                          <RecentlySearchTextButtonWrapper>
                            <Icon src={decideProgramVideoIcon(data) ? ProgramVideoIcon : NoneProgramVideoIcon} onClick={() => onClickMovementVideoViewOfRecentlyList(data, index)} />
                            <DeleteButton src={ExitButtonImage} onClick={() => onClickRecentlyDataDelete(index)} />
                          </RecentlySearchTextButtonWrapper>
                        </RecentlyBox>
                      ))
                    }
                  </DataWrapper>
                  :
                  <DataWrapper>
                    {
                      divideData.length === 0 ?
                        <EmptyDataWrapper>
                          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#D0D0D0'>찾으시는 동작의 정보가 없습니다</Text>
                        </EmptyDataWrapper>
                        :
                        divideData.map((data, index) => (
                          <RecentlyBox key={index} ref={(el) => (SearchMovementRef.current[index] = el)}>
                            <RecentlyTextWrapper onClick={() => onClickDivideData(data)}>
                              {/* <RecentlyText isClicked={(value.movementName === data.movementName && value.movementId !== undefined)}>{data.movementName + ' ' + data.similarity}</RecentlyText> */}
                              <RecentlyText isClicked={(value.movementName === data.movementName && value.movementId !== undefined)}>{calcColorText(data.movementName, value.movementName, 0)}</RecentlyText>
                              <SearchText>{calcColorText(data.movementName, value.movementName, 1)}</SearchText>
                              <RecentlyText isClicked={(value.movementName === data.movementName && value.movementId !== undefined)}>{calcColorText(data.movementName, value.movementName, 2)}</RecentlyText>
                            </RecentlyTextWrapper>
                            <DataSearchButtonWrapper>
                              <Icon src={data.videoLinkId === '' ? NoneProgramVideoIcon : ProgramVideoIcon} onClick={() => onClickMovementVideoViewOfAllList(data, index)} />
                              {/* {(value.movementName === data.movementName && value.movementId !== undefined) && <Check src={CheckImage} />} */}
                            </DataSearchButtonWrapper>
                          </RecentlyBox>
                        ))
                    }
                  </DataWrapper>
              }
            </RecentlySearchWrapper>
          }
          <VideoPrevBoxWrapper ref={VideoPrevBoxRef}>
            <PrevVideoExitButton onClick={onClickMovementVideoViewExit}>닫기</PrevVideoExitButton>
            {
              prevVideoLinkId === '' ?
                <VideoPrevBox>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={12} color='#232323'>동영상이 존재하지 않습니다.</Text>
                </VideoPrevBox>
                :
                <VideoPrevBox>
                  <iframe
                    width="100%"
                    height="100%"
                    src={`https://www.youtube.com/embed/` + prevVideoLinkId}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube" />
                </VideoPrevBox>
            }
            <VideoLinkInputWrapper style={{ marginTop: '14px' }}>
              <YoutubeURLInput value={videoLink} setValue={setVideoLink}/>
            </VideoLinkInputWrapper>
            <MovementLayerWrapper style={{ marginTop: '16px' }}>
                <MovementOptionCheckBoxWrapper>
                  <MovementOptionCheckText>무게</MovementOptionCheckText>
                  <MovementOptionCheckBox isClicked={isUseWeight} onClick={() => setIsUseWeight(!isUseWeight)}>
                    <MovementOptionCheck src={WhiteCheck} />
                  </MovementOptionCheckBox>
                </MovementOptionCheckBoxWrapper>
                <MovementOptionCheckBoxWrapper>
                  <MovementOptionCheckText>횟수</MovementOptionCheckText>
                  <MovementOptionCheckBox isClicked={isUseCount} onClick={() => setIsUseCount(!isUseCount)} >
                    <MovementOptionCheck src={WhiteCheck} />
                  </MovementOptionCheckBox>
                </MovementOptionCheckBoxWrapper>
                <MovementOptionCheckBoxWrapper>
                  <MovementOptionCheckText>시간</MovementOptionCheckText>
                  <MovementOptionCheckBox isClicked={isUseTime} onClick={() => setIsUseTime(!isUseTime)}>
                    <MovementOptionCheck src={WhiteCheck} />
                  </MovementOptionCheckBox>
                </MovementOptionCheckBoxWrapper>
                <MovementOptionCheckBoxWrapper>
                  <MovementOptionCheckText>템포</MovementOptionCheckText>
                  <MovementOptionCheckBox isClicked={isUseTempo} onClick={() => setIsUseTempo(!isUseTempo)}>
                    <MovementOptionCheck src={WhiteCheck} />
                  </MovementOptionCheckBox>
                </MovementOptionCheckBoxWrapper>
                <MovementOptionCheckBoxWrapper>
                  <MovementOptionCheckText>거리</MovementOptionCheckText>
                  <MovementOptionCheckBox isClicked={isUseDistance} onClick={() => setIsUseDistance(!isUseDistance)}>
                    <MovementOptionCheck src={WhiteCheck} />
                  </MovementOptionCheckBox>
                </MovementOptionCheckBoxWrapper>
                <MovementOptionCheckBoxWrapper>
                  <MovementOptionCheckText>높이</MovementOptionCheckText>
                  <MovementOptionCheckBox isClicked={isUseHeight} onClick={() => setIsUseHeight(!isUseHeight)}>
                    <MovementOptionCheck src={WhiteCheck} />
                  </MovementOptionCheckBox>
                </MovementOptionCheckBoxWrapper>
                <MovementOptionCheckBoxWrapper>
                  <MovementOptionCheckText>열량</MovementOptionCheckText>
                  <MovementOptionCheckBox isClicked={isUseCalories} onClick={() => setIsUseCalories(!isUseCalories)}>
                    <MovementOptionCheck src={WhiteCheck} />
                  </MovementOptionCheckBox>
                </MovementOptionCheckBoxWrapper>
              </MovementLayerWrapper>
          </VideoPrevBoxWrapper>
          {/* <MyMovementTextWrapper isClicked={isClickedMyMovement}>
            <CheckBox isClicked={isClickedMyMovement} onClick={onClickAdditionMyMovement}>
              <Check src={WhiteCheck} />
            </CheckBox>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#232323'>나만의 동작 추가하기</Text>
          </MyMovementTextWrapper> */}
          <MovementWrapper ref={MyMovementRef}>
            {/* <DivisionLine /> */}
            <ComponentWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>동작 옵션</Text>
              <MovementLayerWrapper>
                <MovementOptionCheckBoxWrapper>
                  <MovementOptionCheckText>무게</MovementOptionCheckText>
                  <MovementOptionCheckBox isClicked={isUseWeight} onClick={() => setIsUseWeight(!isUseWeight)}>
                    <MovementOptionCheck src={WhiteCheck} />
                  </MovementOptionCheckBox>
                </MovementOptionCheckBoxWrapper>
                <MovementOptionCheckBoxWrapper>
                  <MovementOptionCheckText>횟수</MovementOptionCheckText>
                  <MovementOptionCheckBox isClicked={isUseCount} onClick={() => setIsUseCount(!isUseCount)} >
                    <MovementOptionCheck src={WhiteCheck} />
                  </MovementOptionCheckBox>
                </MovementOptionCheckBoxWrapper>
                <MovementOptionCheckBoxWrapper>
                  <MovementOptionCheckText>시간</MovementOptionCheckText>
                  <MovementOptionCheckBox isClicked={isUseTime} onClick={() => setIsUseTime(!isUseTime)}>
                    <MovementOptionCheck src={WhiteCheck} />
                  </MovementOptionCheckBox>
                </MovementOptionCheckBoxWrapper>
                <MovementOptionCheckBoxWrapper>
                  <MovementOptionCheckText>템포</MovementOptionCheckText>
                  <MovementOptionCheckBox isClicked={isUseTempo} onClick={() => setIsUseTempo(!isUseTempo)}>
                    <MovementOptionCheck src={WhiteCheck} />
                  </MovementOptionCheckBox>
                </MovementOptionCheckBoxWrapper>
                <MovementOptionCheckBoxWrapper>
                  <MovementOptionCheckText>거리</MovementOptionCheckText>
                  <MovementOptionCheckBox isClicked={isUseDistance} onClick={() => setIsUseDistance(!isUseDistance)}>
                    <MovementOptionCheck src={WhiteCheck} />
                  </MovementOptionCheckBox>
                </MovementOptionCheckBoxWrapper>
                <MovementOptionCheckBoxWrapper>
                  <MovementOptionCheckText>높이</MovementOptionCheckText>
                  <MovementOptionCheckBox isClicked={isUseHeight} onClick={() => setIsUseHeight(!isUseHeight)}>
                    <MovementOptionCheck src={WhiteCheck} />
                  </MovementOptionCheckBox>
                </MovementOptionCheckBoxWrapper>
                <MovementOptionCheckBoxWrapper>
                  <MovementOptionCheckText>열량</MovementOptionCheckText>
                  <MovementOptionCheckBox isClicked={isUseCalories} onClick={() => setIsUseCalories(!isUseCalories)}>
                    <MovementOptionCheck src={WhiteCheck} />
                  </MovementOptionCheckBox>
                </MovementOptionCheckBoxWrapper>
              </MovementLayerWrapper>
            </ComponentWrapper>
            <ComponentWrapper style={{ marginTop: '16px' }}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>동작 이름</Text>
              <TextInput value={myMovementName} setValue={setMyMovementName} disabled={!isClickedMyMovement} placeholder={value.movementName} />
            </ComponentWrapper>
            <ComponentWrapper style={{ marginTop: '16px' }}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>동작 영상</Text>
              {/* <TextInput value={videoLink} setValue={setVideoLink} disabled={!isClickedMyMovement} placeholder='유튜브 영상 링크를 붙여넣어 주세요' /> */}
              <VideoLinkInputWrapper>
                <YoutubeURLInput value={videoLink} setValue={setVideoLink}/>
              </VideoLinkInputWrapper>
            </ComponentWrapper>
            <VideoComponentWrapper>
              {
                prevVideoLinkId === '' ?
                  <VideoPrevBox>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={12} color='#232323'>동영상이 존재하지 않습니다.</Text>
                  </VideoPrevBox>
                  :
                  <VideoPrevBox>
                    <iframe
                      width="100%"
                      height="100%"
                      src={`https://www.youtube.com/embed/` + prevVideoLinkId}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      title="Embedded youtube" />
                  </VideoPrevBox>
              }
            </VideoComponentWrapper>
            <ComponentWrapper style={{ marginTop: '16px' }}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>동작 상세 코멘트</Text>
              <MovementDetailCommentInput value={movementDetailComment} setValue={setMovementDetailComment} disabled={!isClickedMyMovement} />
            </ComponentWrapper>
          </MovementWrapper>
          <ButtonWrapper isClickedMyMovement={isClickedMyMovement}>
            <ExitButton onClick={() => close()}>취소</ExitButton>
            <SaveButton onClick={onClickSuccess}>완료</SaveButton>
          </ButtonWrapper>
        </TotalWrapper>
          <MyMovementButtonWrapper>
          {
            !isClickedMyMovement &&
            <MyMovementButton onClick={onClickAdditionMyMovement} isClickedPrevVideo={isClickedPrevVideo}>
              <PlusWrapper>
                <Bar width={14} height={2} radius={2}/>
                <Bar width={2} height={14} radius={2}/>
              </PlusWrapper>
              <HoverText fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' hover='pointer'>나만의 동작 추가하기</HoverText>
            </MyMovementButton>
          }
          </MyMovementButtonWrapper>
      </Modal>
    </TopLevelWrapper>
  )
}

export default AddMovementModal;

const startAnimation = keyframes`
	0% { opacity: 0; }
	50% { opacity: 0.5; }
	100% { opacity: 1; }
`;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 1000;

  width: 100vw;
  height: 100vh;

  // overflow-y: scroll;
  overflow-y: hidden;

  background-color: rgba(0, 0, 0, 0.2);

	animation-name: ${startAnimation};
	animation-duration: 0.5s;
	animation-timing-function: ease-out;
`;

const Modal = styled.div`
  display: flex;
  flex-direction: column;

  // justify-content: flex-start;
  // justify-content: flex-start;
  justify-content: space-between;
  align-items: center;

  // padding: 28px 0px 28px 0px;
  // padding-bottom: 28px;

  width: 480px;
  // height: 869px;
  // height: min(80%, 813px);
  // height: min(80%, 841px);
  min-height: 800px;
  // height: min(80%, 864px);
  height: 830px;
  // height: 864px;
  // min-height: 433px;
  // max-height: 813px;
  max-height: 864px;

  border-radius: 15px;
  background-color: #FFFFFF;

  overflow: hidden;

  transition: all 0.3s ease-in-out;
`;

const ModalTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 50px;
  min-height: 50px;

  background-color: ${props => props.isClickedMyMovement ? `#FF8B48` : `#6DD49E`};
  
  transition: all 0.3s ease-in-out;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  gap: 4px;

  width: 88.33%;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
  
  // margin-top: 28px;

  width: 88.33%;
  height: 100%;

  transition: all 0.3s ease-in-out;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const RecentlySearchWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  // height: ${props => props.isClickedMyMovement ? `0px` : `572px`};
  // height: 572px;

  overflow-x: hidden;
  overflow-y: scroll;

  margin-top: 16px;

  transition: all 0.3s ease-in-out;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
		border: 3px solid transparent;
		// height: 92px;
		background-color: #DADADA;
		border-radius: 200px;
	}

  &::-webkit-scrollbar-track {
    // height: 439px;
		// border: 4px solid transparent;
		background-color: #F0F0F0;
		border-radius: 200px;
	}
`;

const DataWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  height: 100%;
`;

const EmptyDataWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
`;

const RecentlyBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 98%;
  min-height: 33px;

  border-bottom: 1px solid #F5F5F5;
`;

const RecentlyTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  :hover {
    cursor: pointer;
    color: #FF8B48;
  }
`;

const RecentlyText = styled.pre`
  font-family: Poppins-Regular;
  font-size: 12px;

  color: ${props => props.isClicked ? `#FF8B48` : `#4F4F4F`};

  margin: 0 0 0 0;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    color: #FF8B48;
  }
`;

const SearchText = styled.pre`
  font-family: Poppins-Regular;
  font-size: 12px;

  color: #FF8B48;

  margin: 0 0 0 0;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    color: #FF8B48;
  }
`;

const RecentlySearchTextButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
`;

const DataSearchButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;

  :hover {
    cursor: pointer;
  }
`;

const DeleteButton = styled.img`
  width: 20px;
  height: 20px;

  :hover {
    cursor: pointer;
  }
`;

const Check = styled.img`
  width: 10px;
  height: 9px;
`;

const CheckBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;

  border-radius: 6px;
  background-color: ${props => props.isClicked ? `#6DD49E` : `#E0E0E0`};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const MyMovementTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  gap: 6px;
  margin-top: ${props => props.isClicked ? `0px` : `33px`};

  width: 100%;
`;

const VideoLinkInputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 100%;
  height: 36px;
`;

const VideoPrevBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-end;

  width: 414px;

  // margin-top: 16px;

  overflow: hidden;
  
  transition: all 0.3s ease-in-out;
`;

const PrevVideoExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 44px;
  // height: 36px;
  width: 46px;
  height: 27px;

  margin: 0 0 0 0;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;

  border-radius: 5px 5px 0px 0px;
  background-color: #ECECEC;

  :hover {
    cursor: pointer;
  }
`;

const VideoPrevBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 414px;
  height: 232px;

  background-color: #E0E0E0;
`;

const DivisionLine = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;

  margin-top: 11px;

  border: 1px solid #ECECEC;
`;

const MovementLayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 26px;

  width: 100%;
`;

const MovementWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  overflow: hidden;
  // overflow-y: scroll;
  // overflow-x: hidden;

  transition: all 0.3s ease-in-out;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
		border: 3px solid transparent;
		// height: 92px;
		background-color: #DADADA;
		border-radius: 200px;
	}

  &::-webkit-scrollbar-track {
    // height: 439px;
		// border: 4px solid transparent;
		background-color: #F0F0F0;
		border-radius: 200px;
	}
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  gap: 8px;
  // margin-top: 16px;
`;

const VideoComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;

  gap: 8px;
  margin-top: 16px;
`;

const MovementOptionCheckBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  gap: 7px;
`;

const MovementOptionCheckText = styled.div`
  font-family: Poppins-Medium;
  font-size: 14px;

  color: #4F4F4F;

  margin: 0 0 0 0;
`;

const MovementOptionCheckBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 30px;
  height: 30px;

  border-radius: 6px;
  background-color: ${props => props.isClicked ? `#6DD49E` : `#E0E0E0`};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const MovementOptionCheck = styled.img`
  width: 15.75px;
  height: 13.5px;
`;

const ButtonWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: flex-start;

  width: 100%;

  gap: 16px;

  // margin-top: ${props => props.isClickedMyMovement ? `33px` : `12px`};
  margin-top: ${props => props.isClickedMyMovement ? `20px` : `57px`};


  // @media (min-height: 800px) {
  //   margin-top: ${props => props.isClickedMyMovement ? '-40px' : '16px'};
  // }
  // @media (min-height: 1034px) {
  //   margin-top: ${props => props.isClickedMyMovement ? '-10px' : '27px'};
  // }
  // @media (min-height: 1045px) {
  //   margin-top: ${props => props.isClickedMyMovement ? '0px' : '37px'};
  // }
  // @media (min-height: 1056px) {
  //   margin-top: ${props => props.isClickedMyMovement ? `10px` : `47px`};
  // }
  // @media (min-height: 1070px) {
  //   margin-top: ${props => props.isClickedMyMovement ? `20px` : `57px`};  
  // }


  // @media (min-height: 800px) {
  //   margin-top: ${props => props.isClickedMyMovement ? '-40px' : '27px'};
  // }
  // @media (min-height: 1034px) {
  //   margin-top: ${props => props.isClickedMyMovement ? '-10px' : '27px'};
  // }
  // @media (min-height: 1045px) {
  //   margin-top: ${props => props.isClickedMyMovement ? '0px' : '37px'};
  // }
  // @media (min-height: 1056px) {
  //   margin-top: ${props => props.isClickedMyMovement ? `10px` : `47px`};
  // }
  // @media (min-height: 1070px) {
  //   margin-top: ${props => props.isClickedMyMovement ? `20px` : `57px`};  
  // }
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 72px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;

  transition: all 0.3s ease-in-out;

  :hover {
    color: #777777;
    border: 1px solid #777777;
    cursor: pointer;
  }
`;

const SaveButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 74px;
  height: 40px;

  border: 0px;
  border-radius: 15px;
  background-color: #6DD49E;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    background-color: #2BCB67;
    cursor: pointer;
  }
`;

const MyMovementButtonWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  height: 19px;
`;

const MyMovementButton = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: -13px;
  gap: 2px;

  width: 158px;
  height: 32px;

  border-radius: 0px 15px 0px 15px;
  background-color: #FF8B48;

  :hover {
    cursor: pointer;
  }


  @media (min-height: 800px) {
    margin-top: ${props => props.isClickedPrevVideo ? `-35px` : `` };
  }
  @media (min-height: 1000px) {
    margin-top: ${props => props.isClickedPrevVideo ? `-35px` : `` };
  }
  @media (min-height: 1010px) {
    margin-top: ${props => props.isClickedPrevVideo ? `-17px` : `` };
  }
  @media (min-height: 1017px) {
    margin-top: ${props => props.isClickedPrevVideo ? `-13px` : `` };
  }
`;

const PlusWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 14px;
  height: 14px;
`;

const Bar = styled.div`
  position: absolute;

  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: ${props => props.radius}px;
  background-color: #FFFFFF;
`;

// const HeightBar = styled.div`
//   position: absolute;

//   width: 2px;
//   height: 14px;

//   border-radius: 2px;
//   background-color: #FFFFFF;
// `;

const HoverText = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.hover};
  }
`;