import { React, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import FetchModule from '../../../Share/Network/FetchModule';

export default function NewLessonLockAllModal(props) {
  const { useScheduleData, allReserveLock, rendering, setRendering, cancel } = props;

  const history = useHistory();

  // 스크롤 제어 //
  useEffect(() => {
    document.body.style= `overflow: hidden`;
    return () => document.body.style = `overflow: auto`
  }, []);

  // useEffect(() => {
  //   console.log(useScheduleData);
  // }, [useScheduleData])
  
  // 전체 잠금 버튼 클릭시 //
  function onClickAllReserveLockButton() {
    let schedules = useScheduleData.slice();

    // 잠금할 스케줄이 없는경우 //
    if (schedules.length === 0) {
      alert('예약잠금을 시도할 수업이 없습니다.');
      return;
    }

    let scheduleIds = [];
    let classDate = schedules[0].classDate.replaceAll('.', '-') + 'T00:00:00';

    for (let i = 0; i < schedules.length; i++) {
      scheduleIds.push(schedules[i].scheduleId);
    }

    let requestData = {
      scheduleIds: scheduleIds,
      classDate: classDate
    }

    // 전체 잠금상태인 경우 해제 실행 //
    if (!allReserveLock) {
      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataVersion2('reserve/unlock/all', 'POST', requestData);
          
          if (responseData.status === 200) {
            setRendering(!rendering);
            alert('잠금해제가 완료되었습니다');
            cancel();
          }
          else if (responseData.status === 401) history.push('/');
        }
      )();
    }
    // 전체 잠금 상태가 아닌경우 잠금 실행 //
    else {
      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataVersion2('reserve/lock/all', 'POST', requestData);
          
          if (responseData.status === 200) {
            setRendering(!rendering);
            alert('잠금이 완료되었습니다');
            cancel();
          }
          else if (responseData.status === 401) history.push('/');
        }
      )();
    }
  }

  return (
    <TopLevelWrapper>
      <ModalView>
        <TitleWrapper>
          <Title>{allReserveLock ? `예약 전체 잠금` : `전체 잠금 해제`}</Title>
        </TitleWrapper>
        <TextWrapper>
          <Text>{allReserveLock ? '해당 날짜의 모든 수업 예약이 불가능하게 설정됩니다.' : '해당 날짜의 모든 수업 예약이 가능하게 설정됩니다.'}</Text>
          <Text>{allReserveLock ? '잠금을 진행하시겠습니까?' : '잠금을 해제하시겠습니까?'}</Text>
        </TextWrapper>
        <ButtonWrapper>
          <ExitButton onClick={cancel}>취소</ExitButton>
          <SaveButton onClick={onClickAllReserveLockButton}>확인</SaveButton>
        </ButtonWrapper>
      </ModalView>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  justify-content: center;
  align-items: center;

  z-index: 1000;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.2);
`;

const ModalView = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 371px;
  height: 248px; 

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(0, 0, 0, 0.1);

  background-color: #FFFFFF;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 315px;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: 0 0 0 0;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 315px;
  
  margin-top: 16px;
  gap: 12px;
`;

const Text = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;

  margin: 0 0 0 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  width: 315px;

  gap: 16px;

  margin-top: 52px;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 72px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #595959;
    color: #595959;
  }
`;

const SaveButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 80px;
  height: 38px;

  border-radius: 15px;
  background-color: #5BD98A;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }
`;