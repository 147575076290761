import { React } from 'react';
import styled from 'styled-components';
import MyZonePaymentColumn from './MyZonePaymentColumn';
import MyZonePaymentInfo from './MyZonePaymentInfo';

function MyZoneSubscribeInfo(props) {
  const { subscribeList } = props;

  return (
    <TopLevelWrapper>
      <LayerWrapper>
        <TextWrapper>
          <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#232323'>링크존 이용권 결제정보</Text>
          <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#777777'>링크존을 결제하신 결제내역을 확인할 수 있어요</Text>
        </TextWrapper>
        <MyZonePaymentColumn/>
        {
          subscribeList.map((data, index) => (
            <MyZonePaymentInfo key={index} data={data}/>
          ))
        }
      </LayerWrapper>
    </TopLevelWrapper>
  )
}

export default MyZoneSubscribeInfo;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100%;

  margin-top: 51px;
  padding-bottom: 51px;

  border-bottom: 1px solid #DFDFDF;
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  justify-content: flex-start;
  align-items: flex-start;
  
  width: 1191px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  justify-content: flex-start;
  align-items: flex-start;
  
  margin-left: 28px;
  
  gap: 4px;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;
  
  margin: 0 0 0 0;
  
  color: ${props => props.color};
  
  :hover {
    cursor: default;
  }
`;