// Basic Elements Import //
import React, { Component } from "react";
import styled from 'styled-components';

import MainView from '../Model/Individual/MainModel/MainView.js';

function Home() {

  return (
		<Container>
			<MainView/>
		</Container>
	);
}

export default Home;

const Container = styled.div`
	display: flex;
	flex-direction: column;

	align-items: center;

	width: 100%;
`;