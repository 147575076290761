import React, { useRef } from "react";
import styled from "styled-components";
import { cloneDeep } from 'lodash';

import CheckBox from "./CheckBox";
import MoreExplanationView from "./MoreExplanationView";
import SettingInput from "./SettingInput";
import FetchModule from "../../../Model/Share/Network/FetchModule";

export default function AttendanceClassSettingMessageBox(props) {
  const {
    isActivate,
    attendanceClass,
    setAttendanceClass,
    onClickEdit,
  } = props;

  const LastAttendanceTimerRef = useRef(null);
  const BeforeReserveTimerRef = useRef(null);

  // 변수 치환 //
  function replaceText(value) {
    return value.replaceAll('{store_name}', '{시설 이름}')
                .replaceAll('{user_name}', '{회원 이름}')
                .replaceAll('{membership_name}', '{보유 회원권}')
                .replaceAll('{exercise_period}', '{운동 기간}')
                .replaceAll('{locker_name}', '{락커 번호}')
                .replaceAll('{coach_name}', '{코치 이름}')
                .replaceAll('{class_name}', '{수업 이름}')
                .replaceAll('{reserve_time}', '{예약 시간}')
                .replaceAll('{class_time}', '{수업 시간}')
                .replaceAll('{holding_start_date}', '{홀딩 시작일}')
                .replaceAll('{holding_end_date}', '{홀딩 종료일}')
  }

  // 체크박스 클릭시 //
  function onClickCheckBox(division) {
    if (!isActivate) return;

    let newData = cloneDeep(attendanceClass);
    
    // 출석시 //
    if (division === 'Attendance') {
      newData.attendance.isUseSetting = !newData.attendance.isUseSetting;

      saveMessageSetting(newData.attendance);
    }
    // 마지막 출석 //
    else if (division === 'LastAttendance') {
      newData.lastAttendance.isUseSetting= !newData.lastAttendance.isUseSetting;

      saveMessageSetting(newData.lastAttendance);
    }
    // 예약시 //
    else if (division === 'OnReserve') {
      newData.onReserve.isUseSetting = !newData.onReserve.isUseSetting;

      saveMessageSetting(newData.onReserve);
    }
    // 예약 취소시 //
    else if (division === 'CancelReserve') {
      newData.cancelReserve.isUseSetting = !newData.cancelReserve.isUseSetting;

      saveMessageSetting(newData.cancelReserve);
    }
    // 예약 ?시간 전 //
    else if (division === 'BeforeReserve') {
      newData.beforeReserve.isUseSetting = !newData.beforeReserve.isUseSetting;

      saveMessageSetting(newData.beforeReserve);
    }
    // 결석 시 //
    else if (division === 'Absence') {
      newData.absence.isUseSetting = !newData.absence.isUseSetting;

      saveMessageSetting(newData.absence);
    }

    // setAttendanceClass(newData);
  }

  // 마지막 출석 일수 변경시 //
  function onChangeLastAttendanceDay(value) {
    if (!isActivate) return;

    if (value < 100) {
      let newData = cloneDeep(attendanceClass);

      newData.lastAttendance.applyDay = value;
  
      setAttendanceClass(newData);

      if (LastAttendanceTimerRef.current !== null) clearTimeout(LastAttendanceTimerRef.current);

      LastAttendanceTimerRef.current = setTimeout(async () => {
        saveMessageSetting(newData.lastAttendance);
      }, 3000);
    }
  }

  // 마지막 출석 시간 변경시 //
  function onChangeLastAttendanceTime(value) {
    if (!isActivate) return;

    if (value < 24) {
      let newData = cloneDeep(attendanceClass);

      newData.lastAttendance.applyTime = value;
  
      setAttendanceClass(newData);

      if (LastAttendanceTimerRef.current !== null) clearTimeout(LastAttendanceTimerRef.current);

      LastAttendanceTimerRef.current = setTimeout(() => (
        saveMessageSetting(newData.lastAttendance)
      ), 3000);
    }
  }

  // 예약된 수업 시작시간 변경시 //
  function onChangeBeforeReserveTime(value) {
    if (!isActivate) return;

    if (value < 24) {
      let newData = cloneDeep(attendanceClass);

      newData.beforeReserve.applyTime = value;
  
      setAttendanceClass(newData);

      if (BeforeReserveTimerRef.current !== null) clearTimeout(BeforeReserveTimerRef.current);

      BeforeReserveTimerRef.current = setTimeout(async () => {
        saveMessageSetting(newData.beforeReserve);
      }, 3000);
    }
  }

  // 일정 시간 경과 후 데이터 저장 //
  function saveMessageSetting(data) {
    let requestData = {
      id: data.id,
      storeId: data.storeId,
      division: data.division,
      text: data.text,
      title: data.title,
      applyDay: data.applyDay,
      applyTime: data.applyTime,
      isUseSetting: data.isUseSetting
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataVersion2('message_setting/save', 'POST', requestData);

        if (responseData.status === 200) {
          // if (data.id === 0) {
            let newData = cloneDeep(attendanceClass);

            // 출석시 //
            if (data.division === 'Attendance') newData.attendance = responseData.data;
            // 마지막 출석 //
            else if (data.division === 'LastAttendance') newData.lastAttendance = responseData.data;
            // 예약시 //
            else if (data.division === 'OnReserve') newData.onReserve = responseData.data;
            // 예약 취소시 //
            else if (data.division === 'CancelReserve') newData.cancelReserve = responseData.data;
            // 예약 ?시간 전 //
            else if (data.division === 'BeforeReserve') newData.beforeReserve = responseData.data;
            // 결석 시 //
            else if (data.division === 'Absence') newData.absence = responseData.data;

            setAttendanceClass(newData);
          // }
        }
      }
    )();
  }

  // 아무것도 하지 않기 //
  function doNothing() {}

  return (
    <TopLevelWrapper>
      <DivisionBox isActivate={isActivate}>
        <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>출석/수업</Text>
      </DivisionBox>
      <SettingBox>
        <SettingColumnBox>
          <CheckBoxWrapper onClick={() => onClickCheckBox('Attendance')}>
            <CheckBox
              isClicked={attendanceClass.attendance.isUseSetting && isActivate}
              onClickCheckBox={doNothing}

              boxWidth={15}
              boxHeight={15}
              boxRadius={5}

              imageWidth={10}
              imageHeight={7} />
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='pointer'>출석시</Text>
          </CheckBoxWrapper>
          <SettingTextWrapper>
            <TextWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='pointer'>{replaceText(attendanceClass.attendance.text)}</Text>
            </TextWrapper>
          </SettingTextWrapper>
          <ModifyButton onClick={() => onClickEdit(attendanceClass.attendance)}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FF8B48' hover='pointer'>수정하기</Text>
          </ModifyButton>
        </SettingColumnBox>

        <DivisionLine />

        <SettingColumnBox>
          <CheckBoxWrapper /*onClick={() => onClickCheckBox('LastAttendance')}*/>
            <CheckBox
              isClicked={attendanceClass.lastAttendance.isUseSetting && isActivate}
              onClickCheckBox={() => onClickCheckBox('LastAttendance')}

              boxWidth={15}
              boxHeight={15}
              boxRadius={5}

              imageWidth={10}
              imageHeight={7} />
            <CheckBoxTextWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='pointer'>마지막 출석</Text>
              <InputWrapper>
                <SettingInput
                  value={attendanceClass.lastAttendance.applyDay}
                  setValue={onChangeLastAttendanceDay}
                  placeholder={0}/>
              </InputWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='pointer'>일 후</Text>
              <InputWrapper>
                <SettingInput
                  value={attendanceClass.lastAttendance.applyTime}
                  setValue={onChangeLastAttendanceTime}
                  placeholder={0}/>
              </InputWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='pointer'>시에</Text>
            </CheckBoxTextWrapper>
          </CheckBoxWrapper>
          <SettingTextWrapper>
            <TextWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='pointer'>{replaceText(attendanceClass.lastAttendance.text)}</Text>
            </TextWrapper>
          </SettingTextWrapper>
          <ModifyButton onClick={() => onClickEdit(attendanceClass.lastAttendance)}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FF8B48' hover='pointer'>수정하기</Text>
          </ModifyButton>
        </SettingColumnBox>

        <DivisionLine />

        <SettingColumnBox>
          <CheckBoxWrapper onClick={() => onClickCheckBox('OnReserve')}>
            <CheckBox
              isClicked={attendanceClass.onReserve.isUseSetting && isActivate}
              onClickCheckBox={doNothing}

              boxWidth={15}
              boxHeight={15}
              boxRadius={5}

              imageWidth={10}
              imageHeight={7} />
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='pointer'>{attendanceClass.onReserve.title}</Text>
            <MoreExplanationViewWrapper>
              <MoreExplanationView 
                width={17}
                height={17}
                radius={17}
                text='여러 건의 수업에 한번에 예약된 경우 자동 메시지를 전송하지 않습니다.'/>
            </MoreExplanationViewWrapper>
          </CheckBoxWrapper>
          <SettingTextWrapper>
            <TextWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='pointer'>{replaceText(attendanceClass.onReserve.text)}</Text>
            </TextWrapper>
          </SettingTextWrapper>
          <ModifyButton onClick={() => onClickEdit(attendanceClass.onReserve)}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FF8B48' hover='pointer'>수정하기</Text>
          </ModifyButton>
        </SettingColumnBox>

        <DivisionLine />

        <SettingColumnBox>
          <CheckBoxWrapper onClick={() => onClickCheckBox('CancelReserve')}>
            <CheckBox
              isClicked={attendanceClass.cancelReserve.isUseSetting && isActivate}
              onClickCheckBox={doNothing}

              boxWidth={15}
              boxHeight={15}
              boxRadius={5}

              imageWidth={10}
              imageHeight={7} />
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='pointer'>{attendanceClass.cancelReserve.title}</Text>
            <MoreExplanationViewWrapper>
              <MoreExplanationView 
                width={17}
                height={17}
                radius={17}
                text='여러 건의 수업에 한 번에 예약 취소된 경우 자동 메시지를 전송하지 않습니다.'/>
            </MoreExplanationViewWrapper>
          </CheckBoxWrapper>
          <SettingTextWrapper>
            <TextWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='pointer'>{replaceText(attendanceClass.cancelReserve.text)}</Text>
            </TextWrapper>
          </SettingTextWrapper>
          <ModifyButton onClick={() => onClickEdit(attendanceClass.cancelReserve)}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FF8B48' hover='pointer'>수정하기</Text>
          </ModifyButton>
        </SettingColumnBox>

        <DivisionLine />

        <SettingColumnBox>
          <CheckBoxWrapper /*onClick={() => setIsClickedBeforeReserve(!isClickedBeforeReserve)}*/>
            <CheckBox
              isClicked={attendanceClass.beforeReserve.isUseSetting && isActivate}
              onClickCheckBox={() => onClickCheckBox('BeforeReserve')}

              boxWidth={15}
              boxHeight={15}
              boxRadius={5}

              imageWidth={10}
              imageHeight={7} />
              <CheckBoxTextWrapper>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='pointer'>예약된 수업</Text>
                <InputWrapper>
                  <SettingInput
                    value={attendanceClass.beforeReserve.applyTime}
                    setValue={onChangeBeforeReserveTime}
                    placeholder={0}/>
                </InputWrapper>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='pointer'>시간 전</Text>
              </CheckBoxTextWrapper>
          </CheckBoxWrapper>
          <SettingTextWrapper>
            <TextWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='pointer'>{replaceText(attendanceClass.beforeReserve.text)}</Text>
            </TextWrapper>
          </SettingTextWrapper>
          <ModifyButton onClick={() => onClickEdit(attendanceClass.beforeReserve)}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FF8B48' hover='pointer'>수정하기</Text>
          </ModifyButton>
        </SettingColumnBox>

        <DivisionLine />

        <SettingColumnBox>
          <CheckBoxWrapper onClick={() => onClickCheckBox('Absence')}>
            <CheckBox
              isClicked={attendanceClass.absence.isUseSetting && isActivate}
              onClickCheckBox={doNothing}

              boxWidth={15}
              boxHeight={15}
              boxRadius={5}

              imageWidth={10}
              imageHeight={7} />
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='pointer'>{attendanceClass.absence.title}</Text>
          </CheckBoxWrapper>
          <SettingTextWrapper>
            <TextWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='pointer'>{replaceText(attendanceClass.absence.text)}</Text>
            </TextWrapper>
          </SettingTextWrapper>
          <ModifyButton onClick={() => onClickEdit(attendanceClass.absence)}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FF8B48' hover='pointer'>수정하기</Text>
          </ModifyButton>
        </SettingColumnBox>
      </SettingBox>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
`;

const DivisionBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 0px 20px;

  height: 25px;

  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  background-color: ${props => props.isActivate ? `#FFB800` : `#CBCBCB`};

  transition: all 0.3s ease-in-out;
`;

const SettingBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 10px 5px;

  width: calc(100% - 10px);
  
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  background-color: #FFFFFF;
`;

const SettingColumnBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100%;
  min-height: 40px;
`;

const CheckBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  padding: 0px 5px;

  width: 304px;
  height: 100%;

  gap: 14px;

  :hover {
    cursor: pointer;
  }
`;

const CheckBoxTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 5px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 36px;
  height: 32px;
`;

const MoreExplanationViewWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-left: 170px;
`;

const SettingTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  justify-content: center;
  align-items: center;

  width: 604px;
  height: 100%;

  border-left: 1px solid #969696;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  padding: 10px 15px;

  width: calc(100% - 30px);
`;

const ModifyButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 76px;
  height: 100%;

  background-color: #FFFFFF;

  :hover {
    cursor: pointer;
  }
`;

const DivisionLine = styled.div`
  width: calc(100% - 20px);
  height: 1px;

  margin: 15px 0px;

  background-color: #D9D9D9;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;