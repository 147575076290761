const useScrollToWithAnimation = () => {
  // const containerRef = useRef(null);

  const scrollToWithAnimation = (containerRef, scrollToY, duration) => {
    const containerElement = containerRef.current;
    if (!containerElement) return;

    const start = containerElement.scrollTop;
    const change = scrollToY - start;
    const startDate = new Date().getTime();

    const animateScroll = () => {
      const currentDate = new Date().getTime();
      const elapsedTime = currentDate - startDate;
      const scrollTo = easeInOutQuad(elapsedTime, start, change, duration);
      containerElement.scrollTop = scrollTo;
      if (elapsedTime < duration) {
        requestAnimationFrame(animateScroll);
      }
    };

    const easeInOutQuad = (t, b, c, d) => {
      t /= d / 2;
      if (t < 1) return (c / 2) * t * t + b;
      t--;
      return (-c / 2) * (t * (t - 2) - 1) + b;
    };

    requestAnimationFrame(animateScroll);
  };

  return { scrollToWithAnimation };
};

export default useScrollToWithAnimation;