import { React, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

import FetchModule from '../../../Share/Network/FetchModule';

export default function ClassDeleteModal(props) {
  // const { data, index, className, exitClickFn, rendering } = props;
  const { onClickExit, onClickDelete } = props;

  const history = useHistory();

  useEffect(() => {
    document.body.style= `overflow: hidden`;
    return () => document.body.style = `overflow: auto`
  }, [])

  // function exitClick() {
  //   exitClickFn(className);
  // }

  // function deleteClick() {
  //   const fetchModule = new FetchModule();
  //   (
  //     async () => {
  //       const responseData = await fetchModule.deleteData('membership/usage/delete/' + data[index].usageId, 'DELETE');
       
  //       if (responseData.status === 200) {
  //         alert('회원권을 삭제하였습니다.');
  //         exitClickFn(className);
  //         rendering();
  //       }
  //       else if (responseData.status === 401) {
  //         alert('로그인 후 다시시도하세요');
  //         history.push('/');
  //       }
  //     }
  //   )();
  // }

  return (
    <TopLevelWrapper>
      <ModalView>
        <TotalWrapper>
          <Title>수업 일정 삭제</Title>
          <TextWrapper>
            <Explanation>수업 일정 삭제를 진행하면</Explanation>
            <ExplanationWrapper>
              <ColorExplanation>되돌릴 수 없습니다</ColorExplanation>
              <Explanation>삭제를 진행하시겠습니까?</Explanation>
            </ExplanationWrapper>
          </TextWrapper>
          <ButtonWrapper>
            <ExitButton onClick={onClickExit}>취소</ExitButton>
            <DeleteButton onClick={onClickDelete}>삭제하기</DeleteButton>
          </ButtonWrapper>
        </TotalWrapper>
      </ModalView>
    </TopLevelWrapper> 
  )
}

const startAnimation = keyframes`
	0% { opacity: 0; }
	50% { opacity: 0.5; }
	100% { opacity: 1; }
`;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  justify-content: center;
  align-items: center;

  z-index: 1000;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.2);
	
	animation-name: ${startAnimation};
	animation-duration: 0.5s;
	animation-timing-function: ease-out;
`;

const ModalView = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 401px;
  // height: 202px;
  // width: 371px;
  // height: 248px;
  width: 340px;
  height: 208px;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(0, 0, 0, 0.1);
  background-color: #FFFFFF;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 86.34%;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  // margin: 0 0 13px 28px;
  margin: 0 0 0 0;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  gap: 14px;

  margin-top: 16px;
`;

const ExplanationWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  gap 4px;
`;

const ColorExplanation = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #FF3131;
  
  margin: 0 0 0 0;
`;

const Explanation = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;
  
  margin: 0 0 0 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  // margin: 21px 0 0 194px;
  margin-top: 37px;

  gap: 14px;

  width: 100%;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 72px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;
  
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #777777;
    color: #777777;
  }
`;

const DeleteButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 15px;
  background-color: #FF3131;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;