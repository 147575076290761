import { React, useRef, useImperativeHandle, forwardRef, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import MemberShipNameInput from '../../../../Component/SettingComponent/MemberShipNameInput';
import SettingCoachGenderInput from '../../../../Component/SettingComponent/SettingCoachGenderInput';
import CoachImageInput from '../../../../Component/SettingComponent/CoachImageInput';

import FetchModule from '../../../Share/Network/FetchModule';
import FindCoachIdByPhoneInput from '../../../../Component/SettingComponent/FindCoachIdByPhoneInput';

const CoachAddModal = forwardRef((props, ref) => {
  const { data, index, exitClickFn, rendering } = props;

  const [userId, setUserId] = useState('없음');
  const [linkCoachId, setLinkCoachId] = useState('None');

  const history = useHistory();

  const TopLevelRef = useRef();
  const NameRef = useRef();
  const PhoneRef = useRef();
  const LinkCoachRef = useRef();
  const GenderRef = useRef();
  const ProfileRef = useRef();

  useImperativeHandle(ref, () => ({
		setOpacity: (val) => {
      TopLevelRef.current.style.opacity = val;
    },
		setZindex: (val) => {
      TopLevelRef.current.style.zIndex = val;
    }
	}));

  useEffect(() => {
    if (index === -1) return;

    NameRef.current.setValue(data[index].name);
    PhoneRef.current.setValue(data[index].phone);
    GenderRef.current.setValue(data[index].gender);
    // LinkCoachRef.current.setValue(data[index].linkCoachId);
    ProfileRef.current.setImgBase64(data[index].profile);
    setLinkCoachId(data[index].linkCoachId);
    // setUserId(data[index].linkCoachId === 'None' ? '없음' : data[index].linkCoachId);

    var userIdList = data[index].linkCoachId.split('@');

    if (userIdList[0].includes('kakao.')) setUserId(userIdList[0].replaceAll('kakao.', '') + '@' + userIdList[1]);
    else if (userIdList[0].includes('naver.')) setUserId(data[index].linkCoachId = userIdList[0].replaceAll('naver.', '') + '@' + userIdList[1]);
    else if (userIdList[0].includes('google.')) setUserId(data[index].linkCoachId = userIdList[0].replaceAll('google.', '') + '@' + userIdList[1]);
    else if (userIdList[0].includes('apple.')) setUserId(data[index].linkCoachId = userIdList[0].replaceAll('apple.', '') + '@' + userIdList[1]);
    else setUserId(data[index].linkCoachId === 'None' ? '없음' : data[index].linkCoachId);
  }, [data, index])

  // useEffect(() => {
  //   console.log('---------');
  //   console.log(linkCoachId);
  // }, [linkCoachId])
  function exitClick() {
    exitClickFn();
  }

  function SaveClick() {
    // var storeId = window.sessionStorage.getItem('StoreId');
    var storeId = window.localStorage.getItem('StoreId');
    var name = NameRef.current.getValue();
    var phone = PhoneRef.current.getValue();
    // var linkCoachId = LinkCoachRef.current.getValue();
    // var linkCoachId = linkCoachId;
    var gender = GenderRef.current.getValue();
    var profile = ProfileRef.current.getImgBase64();

    var regexEn = /[a-zA-Z]/;
    var regexKr = /[ㄱ-ㅎ가-힣]/;
    var regexNum = /[0-9]/;

    // 이름이 공백이거나 숫자가 들어간 경우
    if (name === '' || regexNum.test(name)) {
      alert('이름을 정확히 입력하세요');
      return;
    }
    // 전화번호에 영어나 한글이 섞여있거나 길이가 부족할 경우
    else if (phone.length !== 11 || regexEn.test(phone) || regexKr.test(phone)) {
      alert('전화번호를 정확히 입력하세요');
      return;
    }
    // // 이메일이 공백이거나 형식에 맞지 않을경우
    // else if (email === '' || !regexExp.test(email)) {
    //   alert('이메일을 정확히 입력하세요');
    //   return;
    // }
    // 링크 코치 아이디가 공백이거나 한글이 섞여있는 경우
    // else if (linkCoachId === '' || regexKr.test(linkCoachId)) {
    //   alert('링크 코치 아이디를 정확히 입력하세요');
    //   return;
    // }
    // 새로 생성일 경우에만 이미지가 선택되었는지 확인
    // else if (profile === undefined && index === -1) {
    //   alert('프로필 사진을 업로드하세요');
    //   return;
    // }

    var newData = {
      id: index === -1 ? '' : data[index].id,
      storeId: storeId,
      name: name,
      phone: phone,
      gender: gender,
      // email: email,
      linkCoachId: linkCoachId,
      profile: profile === undefined ? '' : profile
    }
    // console.log(newData);
    const fetchModule = new FetchModule();
    (
      async () => {
        var responseData;
        if (index === -1) responseData = await fetchModule.postData('coach/create', 'POST', newData);
        else responseData = await fetchModule.patchData('coach/modify/', 'PATCH', newData);

        console.log(responseData);
        if (responseData.status === 200) {
          rendering();
          exitClickFn('Class');
        }
        else if (responseData.status === 401) history.push('/');
        else {}
      }
    )();
  }

  // 링크코치 가입정보 찾기 버튼 클릭시 //
  function onClickFindLinkCoachId(phone) {
    let requestData = {
      phone: phone
    }
    
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postData('coach/find', 'POST', requestData);
        // console.log(responseData.data);
        if (responseData.status === 200) {
          NameRef.current.setValue(responseData.data[0].name);
          // LinkCoachRef.current.setValue(responseData.data[0].linkCoachId);
          GenderRef.current.setValue(responseData.data[0].gender);
          ProfileRef.current.setImgBase64(responseData.data[0].profile);

          let linkCoachId = JSON.parse(JSON.stringify(responseData.data[0].linkCoachId))
          setLinkCoachId(linkCoachId);

          let userLinkCoachId = JSON.parse(JSON.stringify(responseData.data[0].linkCoachId));
          // var userIdList = responseData.data[0].linkCoachId.split('@');
          let userIdList = userLinkCoachId.split('@');

          if (userIdList[0].includes('kakao.')) setUserId(userIdList[0].replaceAll('kakao.', '') + '@' + userIdList[1]);
          else if (userIdList[0].includes('naver.')) setUserId(responseData.data[0].linkCoachId = userIdList[0].replaceAll('naver.', '') + '@' + userIdList[1]);
          else if (userIdList[0].includes('google.')) setUserId(responseData.data[0].linkCoachId = userIdList[0].replaceAll('google.', '') + '@' + userIdList[1]);
          else if (userIdList[0].includes('apple.')) setUserId(responseData.data[0].linkCoachId = userIdList[0].replaceAll('apple.', '') + '@' + userIdList[1]);
          else setUserId(responseData.data[0].linkCoachId);

          // setUserId(responseData.data[0].linkCoachId);
        }
        else if (responseData.status === 201) alert('해당 번호의 가입정보가 없습니다.');
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }

  return (
    <TopLevelWrapper ref={TopLevelRef}>
      <Modal>
        <Title>코치 정보 추가</Title>
        <TotalWrapper>
          <ModelWrapper>
            <ComponentWrapper>
              <ComponentTitle>이름</ComponentTitle>
              <MemberShipNameInput ref={NameRef} placeholder='코치 이름을 입력해주세요' />
            </ComponentWrapper>
            <ComponentWrapper>
              <ComponentTitle>핸드폰 번호</ComponentTitle>
              {/* <MemberShipNameInput ref={PhoneRef} placeholder='-를 제외하고 입력해주세요' /> */}
              <FindCoachIdByPhoneInput ref={PhoneRef} placeholder='-를 제외하고 입력해주세요' onClickFind={onClickFindLinkCoachId}/>
            </ComponentWrapper>
            <ComponentWrapper style={{marginBottom: `140px`}}>
              <ComponentTitle>링크코치 아이디</ComponentTitle>
              {/* <MemberShipNameInput ref={LinkCoachRef} placeholder='링크코치 아이디를 입력해주세요' /> */}
              <InputModel>
                <UserIdText>{userId}</UserIdText>
              </InputModel>
            </ComponentWrapper>
          </ModelWrapper>
          <ModelWrapper>
            <ComponentWrapper>
              <ComponentTitle>성별</ComponentTitle>
              <SettingCoachGenderInput ref={GenderRef} />
            </ComponentWrapper>
            <ComponentWrapper style={{marginBottom: `84px`}}>
              <ComponentTitle>프로필 사진</ComponentTitle>
              <CoachImageInput ref={ProfileRef}/>
            </ComponentWrapper>
          </ModelWrapper>
        </TotalWrapper>
        <ButtonWrapper>
          <ExitButton onClick={exitClick}>취소</ExitButton>
          <DeleteButton onClick={SaveClick}>저장하기</DeleteButton>
        </ButtonWrapper>
      </Modal>
    </TopLevelWrapper>
  )
})

export default CoachAddModal;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 1000;

  background-color: rgba(0, 0, 0, 0.2);
  
  transition: all 0.3s ease-in-out;
`;

const Modal = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: 744px;
  height: 507px;

  padding: 0 0 0 28px;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(0, 0, 0, 0.1);
  background-color: #FFFFFF;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 24px;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: 0 0 0 0;
`;

const ModelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  // margin-top: 24px;
  
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  // height: 70px;
  margin-top: 24px;
`;

const ComponentTitle = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;
`;

const InputModel = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

	padding: 0 0 0 8px;
	margin-top: 8px;

  width: 336px;
  height: 30px;

  overflow: hidden;

	border: 1px solid #DFDFDF;
  border-radius: 8px;
`;

const UserIdText = styled.p`
	font-family: NotoSansKR-Regular;
	font-size: 14px;

  color: #000000;

  margin: 0 0 0 0;
  padding: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;

  gap: 16px;

  // margin: 26px 0 0 526px;
  margin: 0 0 0 526px;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 72px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;
  
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #777777;
    color: #777777;
  }
`;

const DeleteButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 15px;
  background-color: #5BD98A;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;