import { React, forwardRef, useImperativeHandle, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import AuthenticationInput from '../../../Component/StatisticsComponent/AuthenticationInput';

import FetchModule from '../../Share/Network/FetchModule';

const AuthenticationBox = forwardRef((props, ref) => {
  const { clickFn } = props;

  const history = useHistory();

  const InputRef = useRef ();

  function clickCount(className, clickCount) {

  }

  function onClickAccept() {
    var password = InputRef.current.getValue();

    if (password === '') {
      alert('비밀번호를 입력해주세요');
      return;
    }
    
    var data = {
      // account: window.sessionStorage.getItem('AccountId'),
      account: window.localStorage.getItem('AccountId'),
      password: password
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postData('account/check/authentication', 'POST', data);
        // console.log(responseData);
        if (responseData.status === 200) {
          clickFn();
        }
        else {
          alert('올바르지 않은 비밀번호 입니다.');
        }
        // else if (responseData.status === 401) alert('올바르지 않은 비밀번호 입니다.');
        // else if (responseData.status !== 204) alert("서버와 연결이 불안정합니다.\n잠시후 다시시도 해주세요");
      }
    )();
  }

  return (
    <TopLevelWrapper>
      <InfoWrapper>
        <Title>비밀번호</Title>
        <AuthenticationInput ref={InputRef} className='Password' placeholder='현재 사용중인 비밀번호를 입력해주세요' type='password' clickCountFn={clickCount} onClickLoginButton={onClickAccept}/>
        <AcceptButton onClick={onClickAccept}>확인</AcceptButton>
      </InfoWrapper>
    </TopLevelWrapper>
  )
})

export default AuthenticationBox;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 510px;
  height: 192px;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  background-color: #FFFFFF;

  margin-top: 38px;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;
`;

const Title = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #000000;

  margin: 0 0 0 0;
`;

const AcceptButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 15px;
  background-color: #5BD98A;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  margin: 36px 0 0 354px;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }
`;