import { React } from 'react';
import styled from 'styled-components';

export default function ProductUsageColumn() {

  return (
    <TopLevelWrapper>
      <Column style={{marginRight: '373px', marginLeft: '28px'}}>구매물품</Column>
      <Column style={{marginRight: '39px'}}>결제금액</Column>
      <Column style={{marginRight: '65px'}}>카드</Column>
      <Column style={{marginRight: '65px'}}>현금</Column>
      <Column style={{marginRight: '52px'}}>미수금</Column>
      <Column>결제일</Column>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  // justify-content: center;
  
  // justify-content: center;
  align-items: center;

  width: 1020px;
  margin-top: 34px;
  margin-bottom: 20px;
  
  transition: all 0.3s ease-in-out;
`;

const Column = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;
`;
