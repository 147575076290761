import React, { forwardRef, useImperativeHandle, useRef, useState, useEffect } from 'react';
import styled from 'styled-components';

import DefaultLogoPath from '../../image/DefaultLogo.png'

const CoachImageInput = forwardRef((props, ref) => {
	const hiddenFileInput = useRef(null);
  const FileInputRef = useRef();
	const [imgBase64, setImgBase64] = useState();
  const [imgFile, setImgFile] = useState();
  const [isUpLoad, setIsUpLoad] = useState(false);
  const [imgLocalPath, setImgLocalPath] = useState('');

  useImperativeHandle(ref, () => ({
    getImgBase64: () => {
      return imgBase64;
    },
    setImgBase64: (val) => {
      setImgBase64(val);
      if (val === '') setIsUpLoad(false);
      else setIsUpLoad(true);
    },
    getValue: () => {
      return imgLocalPath;
    },
    reset: () => {
      setImgBase64();
      // setImgFile();
      setImgLocalPath();
    }
  }));

  // useEffect(() => {
  //   console.log(isUpLoad);
  // }, [isUpLoad])

  // useEffect(() => {
  //   console.log("설마");
  //   setIsUpLoad(true);
  // }, [imgBase64])

  const handleClick = (e) => {
    hiddenFileInput.current.click();
    FileInputRef.current.style.borderColor = '#FF8B48';
	};
	
  const handleChange = (e) => { 
    var pathpoint = e.target.value.lastIndexOf('.');
    var filepoint = e.target.value.substring(pathpoint + 1, e.target.length);
    var filetype = filepoint.toLowerCase();

    if (filetype === 'jpg' || filetype === 'png' || filetype === 'jpeg') {

      // 정상적인 이미지 확장자 파일인 경우

    } 
    else {
      alert('.jpeg .jpg .png 파일만 가능합니다!');
      var parentObj = e.target.parentNode
      var node = parentObj.replaceChild(e.target.cloneNode(true), e.target);

      return false;
    }

		const reader = new FileReader();
    var tmp = e.target.value.split('\\');
    setImgLocalPath(tmp[tmp.length - 1]);
    
		reader.onloadend = () => {
			const base64 = reader.result;
      FileInputRef.current.style.borderColor = '#E8E8E8';
			if (base64){
				setImgBase64(base64.toString());
        setIsUpLoad(true);
			}
		};
		if (e.target.files[0]) {
      reader.readAsDataURL(e.target.files[0]);
      setImgFile(e.target.files[0]);
		}
	};
  
  return (
    <TopLevelWrapper>
			<TotalWrapper>
        <LogoWrapper>
          <Logo src={imgFile ? URL.createObjectURL(imgFile) : isUpLoad ? imgBase64 : DefaultLogoPath}/>
          {/* <Logo src={isUpLoad ? imgBase64 : DefaultLogoPath}/> */}
        </LogoWrapper>
        <InputWrapper>
          <input 
            type="file"
            ref={hiddenFileInput}
            onChange={handleChange}
            style={{ display: 'none' }} />
          <FileInput ref={FileInputRef}>{imgLocalPath}</FileInput>
          <InputButton onClick={handleClick}>찾아보기</InputButton>
        </InputWrapper>
      </TotalWrapper>
    </TopLevelWrapper>
  );
})

export default CoachImageInput;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  margin-bottom: 33px;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: row;

  align-items: flex-start;

  margin-top: 5px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // margin-top: 18px;
  margin-left: 32px;
  // background-color: red;
`;

const FileInput = styled.div`
  font-family: NotoSansKR-Regular;
  font-size: 14px;
  line-height: 20px;

  padding: 0 0 4px 0;

  overflow: hidden;
  width: 112px;
  height: 25px;
  border: 1px solid;
  border-width: 0 0 1px 0;
  border-color: #E8E8E8;
  color: #000000;

  ::placeholder {
    color: ${props => props.clickCount < 1 || props.value !== '' ? `#CBCBCB` : `#FF3131;`};
  }

  transition: all 0.5s ease-in-out;
`;

const InputButton = styled.div`
  display: flex;
  flex-direction: row;

  width: 45px;
  margin-left: 16px;

  font-family: NotoSansKR-Medium;
  font-size: 12px;
  color: #777777;

  :hover {
    cursor: pointer;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  // margin-left: 32px;
  margin-top: 5px;

  width: 137px;
  height: 137px;

  border-radius: 8px;

  overflow: hidden;
`;

const Logo = styled.img`
  width: 137px;
  height: 137px;

  object-fit: contain;
`;