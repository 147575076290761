import { useState, useImperativeHandle, forwardRef, useRef, React, useEffect } from 'react';
import styled from 'styled-components';

export default function TextInput(props) {
	const { value, setValue, placeholder } = props;

	const inputRef = useRef();

	function handleClick(e) {
	}

	function handleChange(e) {
		setValue(e.target.value);
	}

	function handleFocus(e) {
	}

	function handleBlur(e) {
	}

	return <Input
		ref={inputRef}
		placeholder={placeholder}
		value={value}
		onClick={handleClick}
		onChange={handleChange}
		onFocus={handleFocus}
		onBlur={handleBlur} />
}

const Input = styled.input`
	font-family: NotoSansKR-Regular;
	font-size: 14px;
	line-height: 20px;
	
  width: calc(100% - 10px);
  height: 30px;

	padding: 0 0 0 8px;
	// margin-top: 8px;

	outline: none;
	border: 1px solid #DFDFDF;
  border-radius: 8px;
	color: #000000;

	::placeholder {
		color: #DFDFDF;
	}
	
  transition: all 0.5s ease-in-out;
`;