import { React, useRef, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import HistoryView from './HistoryView.js';
import IndividualInfo from './IndividualInfo.js';
// import FetchModule from '../../../Share/Network/FetchModule.js';
import FetchModule from '../../../Share/Network/FetchModule.js';
import moment from 'moment';
import PartnerGymIndividualInfo from './PartnerGymIndividualInfo.js';
import PartnerGymHistoryView from './PartnerGymHistoryView.js';

export default function PartnerGymMemberDetailView(props) {
  const { data, memberId, division, index } = props;

  const history = useHistory();

  const [memberData, setMemberData] = useState([
                                                {name: '',
                                                birthDay: '',
                                                gender: '',
                                                linkCoachId: '',
                                                phone: '',
                                                profile: 'None'}
                                                ]);
  const [memberShipData, setMemberShipData] = useState([]);
  const [totalMemberShips, setTotalMemberShips] = useState([]);
  const [physicalData, setPhysicalData] = useState([]);
  const [lessonHistoryData, setLessonHistoryData] = useState([]);
  const [totalHistoryData, setTotalHistoryData] = useState([]);
  const [lockerData, setLockerData] = useState([]);
  const [productUsages, setProductUsages] = useState([]);
  const [rendering, setRendering] = useState(false);
  const [lockerRendering, setLockerRendering] = useState(false);

  const TopLevelRef = useRef();
  const IndividualRef = useRef();
  const HistoryRef = useRef();

  // useEffect(() => {
  //   if (index === -1) return;

  //   let requestData = {
  //     memberId: memberId
  //   }

  //   document.documentElement.scrollTop = 0;

  //   const fetchModule = new FetchModule();
	// 	(
	// 		async () => {
	// 			const responseData = await fetchModule.postData('member/partner_gym/detail/all', 'POST', requestData);
	// 			if (responseData.status === 200) {
  //         // setMemberData(responseData.data[0].memberDetail);
          
  //         let memberShips = responseData.data[0].memberShipDetails;

  //         memberShips.map((data) => ( data.historyData = [] ));

  //         let newList = responseData.data[0].memberShipHistoryDates.slice();

  //         // desc 진행 //
  //         newList.sort(function (a, b) {
  //           return a.endDate > b.endDate ? -1 : a.endDate < b.endDate ? 1 : 0;
  //         })

  //         let finalLessonHistoryData = responseData.data[0].lessonReserveDates.slice();

  //         for (let i = 0; i < finalLessonHistoryData.length; i++) {
  //           finalLessonHistoryData[i].createdDate = moment(finalLessonHistoryData[i].classDate.split('T')[0] + 'T' + finalLessonHistoryData[i].classTime.split('-')[0] + ':00').format('YYYY-MM-DDThh:mm:ss')
  //         }

  //         // desc 진행 //
  //         finalLessonHistoryData.sort(function (a, b) {
  //           return a.createdDate > b.createdDate ? -1 : a.createdDate < b.createdDate ? 1 : 0;
  //         })
  //         // console.log(finalLessonHistoryData);

  //         for (let i = 0; i < memberShips.length; i++) {
  //           for (let j = 0; j < newList.length; j++) {
  //             if (memberShips[i].usageId === newList[j].usageId) {
  //               memberShips[i].historyData.push(newList[j]);
  //             }
  //           }
  //         }

  //         setMemberShipData(memberShips);
  //         setLessonHistoryData(finalLessonHistoryData);
  //         setTotalHistoryData(newList);
	// 			}
  //       else if (responseData.status === 201) {
  //         alert('존재하지 않는 회원입니다.');
  //       }
  //       else if (responseData.status === 402) {
  //         alert('올바르지 않은 회원 내역 접근입니다.');
  //       }
	// 			else if (responseData.status === 401) {
  //         alert("로그인 후 사용하세요");
  //         history.push('/');
  //       }
	// 		}
	// 	)();
  // }, [index, data, history, rendering, memberId])

   
  // 회원 데이터 불러오기 //
  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getDataVersion2('member/detail/' + memberId, 'GET');
        // console.log(responseData);
        if (responseData.status === 200) {
          setMemberData(responseData.data);
        }
        else if (responseData.status === 201) alert('존재하지 않는 회원입니다.');
        else if (responseData.status === 202) alert('올바르지 않은 회원 내역 접근입니다.');
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }, [memberId, rendering])

  // 회원 데이터 불러오기 //
  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getDataVersion2('member/detail/membership/' + memberId, 'GET');
        
        if (responseData.status === 200) {
          // setMemberData(responseData.data);

          let memberShips = responseData.data.memberShipDetails;
          let memberShipHistories = responseData.data.memberShipHistoryDates.slice();

          memberShips.map((data) => ( data.historyData = [] ));

          // desc 진행 //
          memberShipHistories.sort(function (a, b) {
            return a.endDate > b.endDate ? -1 : a.endDate < b.endDate ? 1 : 0;
          })

          for (let i = 0; i < memberShips.length; i++) {
            for (let j = 0; j < memberShipHistories.length; j++) {
              if (memberShips[i].usageId === memberShipHistories[j].usageId) {
                memberShips[i].historyData.push(memberShipHistories[j]);
              }
            }
          }
          // console.log(memberShips);
          setMemberShipData(memberShips);
          setTotalHistoryData(memberShipHistories);
        }
        else if (responseData.status === 201) alert('존재하지 않는 회원입니다.');
        else if (responseData.status === 202) alert('올바르지 않은 회원 내역 접근입니다.');
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }, [memberId, rendering])

  // 회원권 수강내역 불러오기 //
  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getDataVersion2('member/detail/reserve/' + memberId, 'GET');
        
        if (responseData.status === 200) {
          // let memberShips = memberShipData.slice();
          let finalLessonHistoryData = responseData.data.slice();

          for (let i = 0; i < finalLessonHistoryData.length; i++) {
            finalLessonHistoryData[i].createdDate = moment(finalLessonHistoryData[i].classDate.split('T')[0] + 'T' + finalLessonHistoryData[i].classTime.split('-')[0] + ':00').format('YYYY-MM-DDThh:mm:ss')
          }

          // desc 진행 //
          finalLessonHistoryData.sort(function (a, b) {
            return a.createdDate > b.createdDate ? -1 : a.createdDate < b.createdDate ? 1 : 0;
          })

          setLessonHistoryData(finalLessonHistoryData);
        }
        else if (responseData.status === 201) alert('존재하지 않는 회원입니다.');
        else if (responseData.status === 202) alert('올바르지 않은 회원 내역 접근입니다.');
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }, [memberId, rendering])

  // 물품 구매내역 불러오기 //
  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getDataVersion2('member/detail/product/' + memberId, 'GET');
        
        if (responseData.status === 200) {
          setProductUsages(responseData.data);
        }
        else if (responseData.status === 201) alert('존재하지 않는 회원입니다.');
        else if (responseData.status === 202) alert('올바르지 않은 회원 내역 접근입니다.');
        else if (responseData.status === 401) history.push('/');
      }
    )();

  }, [])

  // 회원권 수강 내역 정리 //
  useEffect(() => {
    if (memberShipData.length === 0 || lessonHistoryData.length === 0) {
      setTotalMemberShips(memberShipData);
      return;
    }

    let memberShips = memberShipData.slice();
    let finalLessonHistoryData = lessonHistoryData.slice();

    for (let i = 0; i < memberShips.length; i++) {
      let usageHistoryCount = 0;

      for (let j = 0; j < finalLessonHistoryData.length; j++) {
        if (memberShips[i].usageId === finalLessonHistoryData[j].usageId) usageHistoryCount += 1;
      }

      memberShips[i].courseTakeCount = usageHistoryCount;
    }

    setTotalMemberShips(memberShips);
  }, [memberShipData, lessonHistoryData])

  
  return (
    <TopLevelWrapper ref={TopLevelRef}>
      {/* <IndividualInfo ref={IndividualRef} memberData={memberData} rendering={() => setRendering(!rendering)}/> */}
      <PartnerGymIndividualInfo memberData={memberData} rendering={() => setRendering(!rendering)} />
      <PartnerGymHistoryView
        ref={HistoryRef} 
        memberId={memberId}
        memberData={memberData}
        // memberShipData={memberShipData}
        memberShipData={totalMemberShips}
        holdingData={totalHistoryData}
        division={division} 
        lessonHistoryData={lessonHistoryData} 
        lockerData={lockerData}
        physicalData={physicalData} 
        productUsages={productUsages}
        setProductUsages={setProductUsages}
        rendering={() => setRendering(!rendering)} />
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  // position: absolute;
  display: flex;
  flex-direction: column;

  margin-left: 34px;
  // opacity: 0;
  // z-index: -3;
  
  transition: all 0.3s ease-in-out;
`;