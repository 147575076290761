import { React, useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';
import moment from 'moment';

import CalenderImagePath from '../../../image/Calender-gray.png';
import CalenderRightArrowImagePath from '../../../image/CalenderRightArrow.png';
import CalenderLeftArrowImagePath from '../../../image/CalenderLeftArrow.png';
import CalenderLeftArrow2ImagePath from '../../../image/CalenderLeftArrow2.png';
import CalenderRightArrow2ImagePath from '../../../image/CalenderRightArrow2.png';
import BlackExitButton from '../../../image/BlackExitButton.png';

const NewUpwardCalenderInput = forwardRef((props, ref) => {
  const { 
    value, 
    setValue, 
    className, 
    placeholder, 
    clickFn, 
    disabled, 
    width,
    isClicked,
    setIsClicked
  } = props;

  const [today, setToday] = useState(moment(value, 'YYYY.MM.DD'));
  // const [isClicked, setIsClicked] = useState(false);
  const [dateList, setDateList] = useState([]);
  // const [value, setValue] = useState('');

  const TopLevelRef = useRef();
  const CalenderRef = useRef();

  const firstWeek = today.clone().startOf('month').week();
  const lastWeek = today.clone().endOf('month').week() === 1 ? 53 : today.clone().endOf('month').week();

  useImperativeHandle(ref, () => ({
    // setValue: (val) => {
    //   setValue(val)
    // },
    getValue: () => {
      return value;
    },
    closeCalender: () => {
      setIsClicked(false);
    }
  }));

  useEffect(() => {
    setToday(moment(value, 'YYYY.MM.DD'));
  }, [value])

  useEffect(() => {
    let newDateList = [];

    for (let week = firstWeek; week <= lastWeek; week++) {
      for (let i = 0; i < 7; i++) {
        let days = today.clone().startOf('year').week(week).startOf('week').add(i, 'day');
        
        newDateList.push(days);
      } 
    }
    setDateList(newDateList);

    if (isClicked) {
      // CalenderRef.current.style.height = `405px`;
      CalenderRef.current.style.height = `${118 + 56 * (newDateList.length / 7)}px`;
      CalenderRef.current.style.border = `1px solid #FF8B48`;
      CalenderRef.current.style.zIndex = `2`;
      CalenderRef.current.style.opacity = `1`;
    }
    else {
      CalenderRef.current.style.height = `0px`;
      CalenderRef.current.style.border = `0px solid #FF8B48`;
      CalenderRef.current.style.zIndex = `-3`;
      CalenderRef.current.style.opacity = `0`;
    }
  }, [isClicked, today, firstWeek, lastWeek]) 

  // 컴포넌트 외부 클릭시 이벤트를 받기위한 리스너 연결
  useEffect(() => {
    document.addEventListener("mousedown", onClickOutSide);

    return () => {
      document.removeEventListener("mousedown", onClickOutSide);
    }
  }, [])

  // 컴포넌트 외부 클릭시 실행
  function onClickOutSide(e) {
    if (TopLevelRef.current !== e.target && !TopLevelRef.current.contains(e.target)) {
        setIsClicked(false);
    }
  }

  function inputClick() {
    if (disabled) return;
    
    setIsClicked(!isClicked);
    // setToday(moment());
    clickFn(className);
  }

  function dateClick(index) {
    setValue(dateList[index].format('YYYY.MM.DD'))
    setIsClicked(false);
  }

  return (
    <TopLevelWrapper ref={TopLevelRef}>
      <Input img={CalenderImagePath} onClick={inputClick} disabled={disabled} width={width}>
        <Text black={value !== '' ? true : false}>
          {
            value !== '' ? value : placeholder
          }
        </Text>
      </Input>
      <CalenderView ref={CalenderRef}>
        <ButtonWrapper>
          <TitleWrapper>
            <Arrow img={CalenderLeftArrow2ImagePath} onClick={() => setToday(today.clone().subtract(1, 'year'))} style={{ margin: `0 -7px 0 0` }} />
            <Arrow img={CalenderLeftArrowImagePath} onClick={() => setToday(today.clone().subtract(1, 'month'))} />
            <Title>{today.format('YYYY년 MM월')}</Title>
            <Arrow img={CalenderRightArrowImagePath} onClick={() => setToday(today.clone().add(1, 'month'))} />
            <Arrow img={CalenderRightArrow2ImagePath} onClick={() => setToday(today.clone().add(1, 'year'))} style={{ margin: `0 0 0 -7px` }} />
          </TitleWrapper>
          <ExitButton src={BlackExitButton} onClick={() => setIsClicked(false)} />
        </ButtonWrapper>
        <DateColumnWrapper>
          <DateColumn style={{ width: `25px`, marginRight: `36px` }}>SUN</DateColumn>
          <DateColumn style={{ width: `30px`, marginRight: `34px` }}>MON</DateColumn>
          <DateColumn style={{ width: `23px`, marginRight: `36px` }}>TUE</DateColumn>
          <DateColumn style={{ width: `28px`, marginRight: `35px` }}>WED</DateColumn>
          <DateColumn style={{ width: `25px`, marginRight: `42px` }}>THU</DateColumn>
          <DateColumn style={{ width: `18px`, marginRight: `40px` }}>FRI</DateColumn>
          <DateColumn style={{ width: `24px`, marginRight: `28px` }}>SAT</DateColumn>
        </DateColumnWrapper>
        <DateWrapper>
          {
            dateList.map((data, index) => (
              data.format('MM') === today.format('MM') ?
              <Date key={index} onClick={() => dateClick(index)}>
                  {data.format('D')}
              </Date>
              :
              <WithoutHoverDate key={index}>
                  {data.format('D')}
              </WithoutHoverDate>
            ))
          }
        </DateWrapper>
      </CalenderView>
    </TopLevelWrapper>
  )
})

export default NewUpwardCalenderInput;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-end;
`;

const Input = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  // align-items: center;

  // width: 280px;
  width: ${props => props.width}px;
  height: 30px;

  // margin-top: 8px;

  border: 1px solid #DFDFDF;
  border-radius: 5px;

  background: url(${props => props.img}) no-repeat 10px center;
  background-color: ${props => props.disabled ? '#ECECEC' : '#FDFCFB'};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const Text = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: ${props => props.black ? `#000000` : `#CBCBCB`};

  margin: 0 0 0 34px;
`;

const CalenderView = styled.div`
  position: absolute;

  display: flex;
  flex-direction: column;

  border: 0px solid #FF8B48;
  border-radius: 8px;
  background-color: #FFFFFF;

  margin-top: 36px;

  z-index: -30;
  opacity: 0;
  
  overflow: hidden;

  transition: all 0.3s ease-in-out;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: calc(100% - 56px);

  margin-top: 28px;
  padding: 0 28px 0 28px;
`;

const ExitButton = styled.img`
  width: 24px;
  height: 24px;

  :hover {
    cursor: pointer;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 6px;
`;

const Arrow = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  width: 24px;
  height: 24px;
  
  background: url(${props => props.img}) no-repeat center center;

  :hover {
    cursor: pointer;
  }
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const DateColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;

  // justify-content: center;
  // align-items: center;

  // gap: 38px;
  margin-top: 29px;
  margin-left: 28px;
  margin-bottom: 23px;
`;

const DateColumn = styled.p`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  font-family: Poppins-bold;
  font-size: 12px;

  color: #FF8B48;
  
  margin: 0 0 0 0;
`;

const DateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  width: 448px;
  margin-left: 8px;
`;

const Date = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  width: 48px;
  height: 48px;

  border-radius: 48px;

  margin: 0 7px 5px 7px;

  background-color: #FFFFFF;
  
  font-family: Poppins-Medium;
  font-size: 14px;

  color: #595959;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #FF7322;
    color: #FFFFFF;

    font-fmaily: Poppins-Bold;
    font-size: 14px;
  }
`;

const WithoutHoverDate = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  width: 48px;
  height: 48px;

  border-radius: 48px;

  margin: 0 7px 5px 7px;

  background-color: #FFFFFF;

  font-family: Poppins-Regular;
  font-size: 14px;

  color: #777777;

  :hover {
    cursor: default;
  }
`;