import { React, useRef, forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { useHistory } from 'react-router-dom';

import FetchModule from '../../Share/Network/FetchModule.js';

import LoginSubject from '../../Individual/AccountModel/LoginSubject.js';
import SignUpSubject from './SignUpSubject.js';

import LoginInfo from '../../Individual/AccountModel/LoginInfo.js';
import SignUpInfo1 from '../../Individual/AccountModel/SignUpInfo1.js';
import SignUpInfo2 from '../../Individual/AccountModel/SignUpInfo2.js';
import SignUpInfo3 from '../../Individual/AccountModel/SignUpInfo3.js';
import SignUpInfo4 from '../../Individual/AccountModel/SignUpInfo4.js';

import LoginBottomComponent from './LoginBottomComponent.js';
import SignUpFirstBottomComponent from './SignUpFirstBottomComponent.js';
import SignUpSecondBottomComponent from './SignUpSecondBottomComponent.js';
import SignUpThirdBottomComponent from './SignUpThirdBottomComponent.js';

const InfoView = forwardRef((props, ref) => {
	const { kakaoAddrClick, signUpClick } = props;

	const [isView, setIsView] = useState(true);

	const [first, setFirst] = useState(false);
	const [second, setSecond] = useState(false);
	const [third, setThird] = useState(false);
	const [force, setFource] = useState(false);

	const [ownerName, setOwnerName] = useState('');
	const [userId, setUserId] = useState('');
	const [userPW, setUserPW] = useState('');
	const [socialSecurityNumber, setSocialSecurityNumber] = useState('');
	const [phone, setPhone] = useState('');
	const [email, setEmail] = useState('');
	const [storeName, setStoreName] = useState('');
	const [type, setType] = useState('');
	const [tel, setTel] = useState('');
	const [address, setAddress] = useState('');
	const [logo, setLogo] = useState('');

	const [isInProgress, setIsInProgress] = useState(false);

  const history = useHistory();

	const TopLevelRef = useRef();
	const LoginSubjectRef = useRef();
	const SignUpSubjectRef = useRef();

	const LoginInfoRef = useRef();
	const SignUpInfoFirstRef = useRef();
	const SignUpInfoSecondRef = useRef();
	const SignUpInfoThirdRef = useRef();
	const SignUpInfoFourthRef = useRef();

	const LoginBottomRef = useRef();
	const SignUpFirstBottomRef = useRef();
	const SignUpSecondBottomRef = useRef();
	const SignUpThirdBottomRef = useRef();

	useImperativeHandle(ref, () => ({
		setAddress: (val) => {
			SignUpInfoFourthRef.current.setAddress(val);
		},
		getUserName: () => {
			return ownerName;
		},
		setOpacity: (val) => {
			TopLevelRef.current.style.opacity = val;
		}
	}))
	
	// useEffect(() => {
  //   setTimeout(function() {
	// 		setIsView(true);
  //   }, 1000)
	// }, [LoginInfoRef])

	// email형식 확인 정규식
	function isEmail(asValue) {
		var regExp = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,6}$/i;

		return regExp.test(asValue);
	}

	function onClickLoginCategory() {
		setIsView(false);
		setFirst(true);

		LoginSubjectRef.current.setOpacity(0);
		SignUpSubjectRef.current.setOpacity(1);
		SignUpSubjectRef.current.setNowPage(1);

		// // LoginInfoRef.current.setOpacity(0);
		// LoginInfoRef.current.setLocation(-150);
		// SignUpInfoFirstRef.current.setOpacity(1);
		// SignUpInfoFirstRef.current.setLocation(0);

		LoginBottomRef.current.setOpacity(0);
		SignUpFirstBottomRef.current.setOpacity(1);
	}

	function onClickSignUpCategory() {
		setIsView(true);
		setFirst(false);
		setSecond(false);
		setThird(false);

		LoginSubjectRef.current.setOpacity(1);
		SignUpSubjectRef.current.setOpacity(0);
		SignUpSubjectRef.current.setNowPage(0);

		// // Login window and first sign up init
		// LoginInfoRef.current.setLocation(0);
		// SignUpInfoFirstRef.current.setLocation(150);
		// SignUpInfoFirstRef.current.setOpacity(0);
		// // second sign up window init
		// SignUpInfoSecondRef.current.setLocation(150);
		// SignUpInfoSecondRef.current.setOpacity(0);
		// // third sign up window init
		// SignUpInfoThirdRef.current.setLocation(150);
		// SignUpInfoThirdRef.current.setOpacity(0);
		// // forth sign up window init
		// SignUpInfoFourthRef.current.setLocation(150);
		// SignUpInfoFourthRef.current.setOpacity(0);

		SignUpThirdBottomRef.current.setOpacity(0);
		SignUpSecondBottomRef.current.setOpacity(0);
		SignUpFirstBottomRef.current.setOpacity(0);
		LoginBottomRef.current.setOpacity(1);
	}

	function onClickNextButton() {
		var nowPage = SignUpSubjectRef.current.getNowPage();
		var undefinedCount = 0;

		if (nowPage === 1) {
			// 관리자 이름을 입력하지 않았을 경우
			if (SignUpInfoFirstRef.current.getOwnerName() === '') {
				SignUpInfoFirstRef.current.isBlankOwnerName();
				undefinedCount++;
			}
			// 아이디를 입력하지 않았을 경우
			if (SignUpInfoFirstRef.current.getUserId() === '') {
				SignUpInfoFirstRef.current.isBlankId();
				undefinedCount++;
			}
			// 아이디 중복검사를 진행하지 않았을 경우
			if (!SignUpInfoFirstRef.current.getAvailableOfId() || SignUpInfoFirstRef.current.getUserId() !== SignUpInfoFirstRef.current.getCertifiedId()) {
				SignUpInfoFirstRef.current.didNotCheckAvailableId();
				undefinedCount++;
			}
			// 패스워드를 입력하지 않았을 경우
			if (SignUpInfoFirstRef.current.getUserPw() === '') {
				SignUpInfoFirstRef.current.isBlankPassword();
				undefinedCount++;
			}

			if (undefinedCount > 0) return;

			setOwnerName(SignUpInfoFirstRef.current.getOwnerName());
			setUserId(SignUpInfoFirstRef.current.getUserId());
			setUserPW(SignUpInfoFirstRef.current.getUserPw());

			SignUpSubjectRef.current.setNowPage(2);
			SignUpInfoFirstRef.current.setLocation(-150);
			SignUpInfoFirstRef.current.setOpacity(0);

			SignUpFirstBottomRef.current.setOpacity(0);

			setFirst(false);
			setSecond(true);

			// SignUpInfoSecondRef.current.setLocation(0);
			// SignUpInfoSecondRef.current.setOpacity(1);

			SignUpSecondBottomRef.current.setOpacity(1);
		}
		else if (nowPage === 2) {
			// 주민등록번호를 입력하지 않았을때
			if (SignUpInfoSecondRef.current.getSocialSecurityNumber() === '-******') {
				SignUpInfoSecondRef.current.isBlankSocialSecurityNumber();
				undefinedCount++;
			}
			// 올바르지 않은 주민등록번호를 입력했을때
			else if (SignUpInfoSecondRef.current.getSocialSecurityNumber().length !== 14) {
				SignUpInfoSecondRef.current.isInvalidSocialSecurityNumber();
				undefinedCount++;
			}

			// 휴대폰번호를 입력하지 않았을때
			if (SignUpInfoSecondRef.current.getPhoneNumber() === '') {
				SignUpInfoSecondRef.current.isBlankPhoneNumber();
				undefinedCount++;
			}
			// 올바르지 않은 휴대폰번호를 입력했을때
			else if (SignUpInfoSecondRef.current.getPhoneNumber().length < 11) {
				SignUpInfoSecondRef.current.isInvalidPhoneNumber();
				undefinedCount++;
			}

			// 이메일을 입력하지 않았을때
			if (SignUpInfoSecondRef.current.getEmail() === '') {
				SignUpInfoSecondRef.current.isBlankEmail();
				undefinedCount++;
			}
			// 올바르지 않은 이메일을 입력했을때
			else if (!isEmail(SignUpInfoSecondRef.current.getEmail())) {
				SignUpInfoSecondRef.current.isInvalidEmail();
				undefinedCount++;
			}
			// 이메일 계정확인을 하지 않았을 경우
			else if (!SignUpInfoSecondRef.current.getIsCheckEmail()) {
				SignUpInfoSecondRef.current.didNotCheckEmail();
				undefinedCount++;
			}

			// 1개라도 걸리면 진행하지 않음.
			if (undefinedCount > 0) return;

			setSocialSecurityNumber(SignUpInfoSecondRef.current.getSocialSecurityNumber());
			setPhone(SignUpInfoSecondRef.current.getPhoneNumber());
			setEmail(SignUpInfoSecondRef.current.getEmail());

			SignUpSubjectRef.current.setNowPage(3);
			SignUpInfoSecondRef.current.setLocation(-150);
			SignUpInfoSecondRef.current.setOpacity(0);

			setSecond(false);
			setThird(true);

			// SignUpInfoThirdRef.current.setLocation(0);
			// SignUpInfoThirdRef.current.setOpacity(1);
		}
		else if (nowPage === 3) {
			if (SignUpInfoThirdRef.current.getStoreName() === '') {
				SignUpInfoThirdRef.current.isBlankStoreName();
				undefinedCount++;
			}

			if (SignUpInfoThirdRef.current.getStoreTel() === '') {
				SignUpInfoThirdRef.current.isBlankStoreTel();
				undefinedCount++;
			}

			// 1개라도 걸리면 진행하지 않음.
			if (undefinedCount > 0) return;

			setStoreName(SignUpInfoThirdRef.current.getStoreName());
			setType(SignUpInfoThirdRef.current.getStoreType());
			setTel(SignUpInfoThirdRef.current.getStoreTel());

			SignUpSubjectRef.current.setNowPage(4);
			SignUpInfoThirdRef.current.setLocation(-150);
			SignUpInfoThirdRef.current.setOpacity(0);

			SignUpSecondBottomRef.current.setOpacity(0);

			setThird(false);
			setFource(true);

			// SignUpInfoFourthRef.current.setLocation(0);
			// SignUpInfoFourthRef.current.setOpacity(1);

			SignUpThirdBottomRef.current.setOpacity(1);
		}
	}

	function onClickPrevButton() {
		var nowPage = SignUpSubjectRef.current.getNowPage();

		if (nowPage === 2) {
			SignUpSubjectRef.current.setNowPage(1);
			SignUpInfoSecondRef.current.setLocation(150);
			SignUpInfoSecondRef.current.setOpacity(0);

			SignUpSecondBottomRef.current.setOpacity(0);

			setSecond(false);
			setFirst(true);
			// SignUpInfoFirstRef.current.setLocation(0);
			// SignUpInfoFirstRef.current.setOpacity(1);

			SignUpFirstBottomRef.current.setOpacity(1);
		}
		else if (nowPage === 3) {
			SignUpSubjectRef.current.setNowPage(2);
			SignUpInfoThirdRef.current.setLocation(150);
			SignUpInfoThirdRef.current.setOpacity(0);

			setThird(false);
			setSecond(true);
			// SignUpInfoSecondRef.current.setLocation(0);
			// SignUpInfoSecondRef.current.setOpacity(1);
		}
		else if (nowPage === 4) {
			SignUpSubjectRef.current.setNowPage(3);
			SignUpInfoFourthRef.current.setLocation(150);
			SignUpInfoFourthRef.current.setOpacity(0);
			SignUpInfoFourthRef.current.reset();

			SignUpThirdBottomRef.current.setOpacity(0);

			setFource(false);
			setThird(true);
			// SignUpInfoThirdRef.current.setLocation(0);
			// SignUpInfoThirdRef.current.setOpacity(1);

			SignUpSecondBottomRef.current.setOpacity(1);
		}
	}

	function onClickLoginButton() {
		var userId = LoginInfoRef.current.getUserId();
		var userPW = LoginInfoRef.current.getUserPw();
		var blankCount = 0;

		if (userId === '') {
			LoginInfoRef.current.isBlankId();
			blankCount++;
		}

		if (userPW === '') {
			LoginInfoRef.current.isBlankPw();
			blankCount++;
		}

		if (blankCount > 0) return; 

		const data = {
			account: userId,
			password: userPW
		}

		const fetchModule = new FetchModule();
		(
			async () => {
				const responseData = await fetchModule.postData('account/asp/login', 'POST', data);

				if (responseData.status === 200) {
					window.localStorage.setItem('Token', 'Bearer ' + responseData.token);
					window.localStorage.setItem('RefreshToken', 'Bearer ' + responseData.refreshToken);
					window.localStorage.setItem('StoreId', responseData.storeId);
					window.localStorage.setItem('AccountId', userId);
					// window.sessionStorage.setItem('Token', "Bearer " + responseData.token);
					// window.sessionStorage.setItem('StoreId', responseData.storeId);
					// window.sessionStorage.setItem('AccountId', userId);
					history.push('/main');
				}
				else LoginInfoRef.current.failLogin();
			}
		)();
	}

	function onClickSignUp() {
		// var ownerName = SignUpInfoFirstRef.current.getOwnerName();
		// var userId = SignUpInfoFirstRef.current.getUserId();
		// var userPW = SignUpInfoFirstRef.current.getUserPw();
		// var socialSecurityNumber = SignUpInfoSecondRef.current.getSocialSecurityNumber();
		// var phone = SignUpInfoSecondRef.current.getPhoneNumber();
		// var email = SignUpInfoSecondRef.current.getEmail();
		// var storeName = SignUpInfoThirdRef.current.getStoreName();
		// var type = SignUpInfoThirdRef.current.getStoreType();
		// var tel = SignUpInfoThirdRef.current.getStoreTel();

		if (isInProgress) {
			alert('회원가입이 진행중입니다');
			return;
		}

		let address = SignUpInfoFourthRef.current.getAddress();
		let logo = SignUpInfoFourthRef.current.getLogo();

		if (address === '') {
			alert("주소를 입력해주세요!");
			return;
		}
		else if (logo === '') {
			alert('로고를 업로드해주세요!');
			return;
		}
		
		setIsInProgress(true);
		setAddress(address);
		setLogo(logo);

		const data = {
			loginId: userId,
			password: userPW,
			storeName: storeName,
			storeType: type,
			storeLogo: logo,
			storeAddress: address,
			storeTel: tel,
			ownerSocialSecurityNum: socialSecurityNumber,
			ownerName: ownerName,
			phone: phone,
			email: email
		}
		// console.log(data);
		const fetchModule = new FetchModule();
		(
			async () => {
				const responseData = await fetchModule.postData('account/asp/sign-up', 'POST', data);
				// console.log(responseData);
				if (responseData.status === 200) signUpClick();
				else if (responseData.status === 201) alert('사용중인 아이디 입니다.');
				else if (responseData.status === 202) alert('로고 업로드에 실패하였습니다.\n로고 파일을 확인 후 잠시 후 다시시도해 주세요!');
				else alert("회원가입에 실패하였습니다. 잠시후 다시시도해 주세요.");

				setIsInProgress(false);
			}
		)();
	}

	return (
		<TopLevelWrapper ref={TopLevelRef}>
			<DisplayWrapper>
				<InfoBox>
					<InfoWrapper>
						<TopInfo>
							<LoginSubject ref={LoginSubjectRef} onClickLoginCategory={onClickLoginCategory} />
							<SignUpSubject ref={SignUpSubjectRef} onClickSignUpCategory={onClickSignUpCategory} />
						</TopInfo>
						{ isView && <LoginInfo ref={LoginInfoRef} onClickLoginButton={() => onClickLoginButton()}/> }
						{ first && <SignUpInfo1 ref={SignUpInfoFirstRef} /> }
						{ second && <SignUpInfo2 ref={SignUpInfoSecondRef} /> }
						{ third && <SignUpInfo3 ref={SignUpInfoThirdRef} /> }
						{ force && <SignUpInfo4 ref={SignUpInfoFourthRef} kakaoAddrClick={kakaoAddrClick} /> }
						<LoginBottomComponent ref={LoginBottomRef} onClickLoginButton={onClickLoginButton} />
						<SignUpFirstBottomComponent ref={SignUpFirstBottomRef} onClickNextButton={onClickNextButton} />
						<SignUpSecondBottomComponent ref={SignUpSecondBottomRef} onClickNextButton={onClickNextButton} onClickPrevButton={onClickPrevButton} />
						<SignUpThirdBottomComponent ref={SignUpThirdBottomRef} onClickSignUp={onClickSignUp} onClickPrevButton={onClickPrevButton} />
					</InfoWrapper>
				</InfoBox>
			</DisplayWrapper>
		</TopLevelWrapper>
	)
})

export default InfoView;

const startAnimation = keyframes`
	0% { opacity: 0; }
	50% { opacity: 0.5; }
	100% { opacity: 1; }
`;

const TopLevelWrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;

	box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
	border-radius: 50px;
	// border-radius: max(30px, 2.604166666666667vw);
	
	animation-name: ${startAnimation};
	animation-duration: 0.5s;
	animation-timing-function: ease-out;
`;

const DisplayWrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	overflow: hidden;
`;

const InfoBox = styled.div`
	display: flex;
	flex-direction: column;

	justify-content: flex-start;
	align-items: center;

	width: 582px;
	// width: max(351px, 30.3125vw);
	// width: max(290px, 30.3125vw);
	height: 604px;
	// height: max(472px, 31.45833333333333vw);

	border-radius: 50px;
	// border-radius: max(14.6484375px, 2.604166666666667vw);
	background-color: rgba(255, 255, 255, 1);
`;

const InfoWrapper = styled.div`
	display: flex;
	flex-direction: column;

	width: 486px;
	// width: max(271px, 25.3125vw);
	// width: max(240px, 25.3125vw);
	height: 506px;
	// height: max(393px, 26.35416666666667vw);
`;

const TopInfo = styled.div`
	display: flex;
	flex-direction: column;

	justify-content: flex-start;

	margin-top: 54px;
	// margin-top: max(19.53125px, 2.8125vw);
`;