import { React } from 'react';
import styled from 'styled-components';

import CircleGraph from './CircleGraph';
import StatisticsChart from './StatisticsChart';

const GraphBox = (props) => {
  const { 
    today, 
    startDate, 
    endDate, 
    data, 
    yearSalesData, 
    isOpenPartnerGymSales,
    isDoughnutLoading, 
    setIsDoughnutLoading,
    isYearLoading,
    setIsYearLoading 
  } = props;

  return (
    <TopLevelWrapper>
      <CircleGraph 
        today={today} 
        startDate={startDate} 
        endDate={endDate} 
        data={data} 
        isOpenPartnerGymSales={isOpenPartnerGymSales} 
        isDoughnutLoading={isDoughnutLoading} 
        setIsDoughnutLoading={setIsDoughnutLoading}/>
      <StatisticsChart 
        today={today} 
        yearSalesData={yearSalesData} 
        isOpenPartnerGymSales={isOpenPartnerGymSales} 
        isLoading={isYearLoading} 
        setIsLoading={setIsYearLoading}/>
    </TopLevelWrapper>
  )
}

export default GraphBox;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  // justify-content: center;
  align-items: center;

  gap: 30px;
  // margin-top: 16px;
`;

