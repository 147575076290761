import { React, useEffect, useRef } from 'react';
import styled from 'styled-components';

import UnitSelectBox from '../../../Component/NoteComponent/UnitSelectBox';
import SingleRestInput from '../../../Component/NoteComponent/SingleRestInput';
import SmallTimeInput from '../../../Component/NoteComponent/SmallTimeInput';
import IncludeSlashDataInput from '../../../Component/NoteComponent/IncludeSlashDataInput';
import IncludeSlashSingleDataInput from '../../../Component/NoteComponent/IncludeSlashSingleDataInput';

import ProgramVideoIcon from '../../../image/ProgramVideoIcon.png';
import MovementOptionIcon from '../../../image/MovementOptionIcon.png';
import ExerciseDeleteIcon from '../../../image/ExerciseDeleteIcon.png';
import CheckImage from '../../../image/WhiteCheck.png';
import CopyIcon from '../../../image/CopyIcon.png';

export default function NewEMOMMovementBox (props) {
  const {
    data,
    dataListLength,
    idx,
    tag,
    reps,
    division,
    target,
    targetStartY,
    targetEndY,
    clickDivision,
    clickIndex,
    changedIndex,
    setChangedIndex,
    clickedRoundIndex,
    emptyHeight,
    onChangeData,
    onChangeUnit,
    onMouseDown,
    onClickRest,
    onClickCopyMovement,
    onClickMovementVideo,
    onClickMovementOption,
    onClickDeleteMovement,
    onClickMovementScaleOption
  } = props;

  const TopLevelRef = useRef();
  const TotalRef = useRef();
  const EmptyRef = useRef();

  const weightOptions = ['Kg', 'Pounds', '%RM', '%Body'];
  const distanceOptions = ['Km', 'm', 'Cm', 'inch', 'Feet'];
  const caloriesOptions = ['Kcal', 'Cal'];

  useEffect(() => {
    if (TotalRef === null || target === TotalRef.current || changedIndex === undefined || data.emomRoundIndex !== clickedRoundIndex) {
      TotalRef.current.style.transform = `translateY(0px)`;
      TotalRef.current.style.transition = `none`;

      return;
    }
    // 현재 컴포넌트 시작 좌표 //
    let myStartY = TotalRef.current.getBoundingClientRect().top;
    // 컴포넌트 크기 //
    let myHeight = TotalRef.current.offsetHeight;
    // 현재 컴포넌트 끝 좌표 //
    var myEndY = myEndY + myHeight;
    // 컴포넌트 움직임을 결정할 기준점 //
    let standardY = (myStartY + (myHeight / 2));
    // 타겟 컴포넌트의 기준점 ( 정중앙 ) //
    let targetStandardY = ((targetStartY + targetEndY) / 2);

    // 움직임 에니메이션 주기 //
    TotalRef.current.style.transition = `all 0.3s ease-in-out`;

    // console.log('**************');
    // console.log('idx : ' + idx);
    // console.log('clickIndex : ' + clickIndex);
    // console.log('changedIndex : ' + changedIndex);
    // console.log('targetStartY : ' + targetStartY);
    // console.log('targetEndY : ' + targetEndY);
    // console.log('절대위치 : ' + myStartY);
    // // console.log('크기 : ' + myHeight);
    // console.log('기준점 : ' + standardY);
    // console.log('**************');
    // console.log(TotalRef.current.style.transform);

    // 해당되는 컴포넌트만 반응하도록 구성 //
    if (clickDivision === division) {
      // 타겟이 움직여야할 컴포넌트보다 아래에 있을 경우 //
      if (clickIndex > idx) {
        // 가동 범위 내에 있는지 확인 //
        if (myStartY < targetStandardY || targetStandardY < myEndY) {
          // 타겟의 기준점이 움직임 기준점을 넘기면 이동
          if (targetStandardY < standardY) {
            TotalRef.current.style.transform = `translateY(${targetEndY - targetStartY}px)`;
            // if (idx === 0) console.log("?? : " + idx);
            setChangedIndex(idx);
          }
          else if (targetStandardY > standardY) {
            TotalRef.current.style.transform = `translateY(0px)`;
            // if (idx === 0) console.log("!! : " + (idx === dataListLength - 1 ? dataListLength : idx + 1));
            setChangedIndex(idx === dataListLength - 1 ? dataListLength : idx + 1);
          }
        }
      }
      // 타겟이 움직여야할 컴포넌트보다 위에 있을 경우 //
      else if (clickIndex < idx) {
        // 가동 범위 내에 있는지 확인 //
        if (myStartY < targetStandardY || targetStandardY < myEndY) {
          if (targetStandardY > standardY) {
            TotalRef.current.style.transform = `translateY(-${targetEndY - targetStartY}px)`;
            // if (idx === 0) console.log("** : " + idx);
            setChangedIndex(idx);
          }
          // 다시 위로 올라갈경우 제자리로 //
          else if (targetStandardY < standardY) {
            TotalRef.current.style.transform = `translateY(0px)`;
            // if (idx === 0) console.log("^^ : " + (idx === 0 ? 0 : idx - 1));
            setChangedIndex(idx === 0 ? 0 : idx - 1);
          }
        }
      }
    }
  }, [TotalRef, target, targetStartY, targetEndY, clickDivision, division, clickIndex, changedIndex, idx, dataListLength, data, clickedRoundIndex])

  // 한자리 숫자 앞에 0 붙이기 //
  function numberPad(n, width) {
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
  }

  function onClickScaleUption() {
    onClickMovementScaleOption(division, data, idx);
  }

  function onViewMovementName(data) {
    if (data === undefined) return '';
    else if (data.movementName === undefined) return '';
    else return data.movementName;
  }

  // 이동(동작 번호 및 동작 이름) 버튼 클릭시 //
  function onComponentMouseDown(e) {
    onMouseDown(e, TotalRef.current, division, idx);
  }

  return (
    <TopLevelWrapper ref={TopLevelRef} index={target === undefined ? dataListLength - idx : null}>
      {
        target === TotalRef.current && <EmptyBox ref={EmptyRef} height={emptyHeight} />
      }
      <TotalWrapper ref={TotalRef}>
        <MovementWrapper>
          <MovementTitleWrapper>
            <TextScaleButtonWrapper>
              <NumberWrapper onMouseDown={onComponentMouseDown}>
                <NumberBox isGreen={division === 'buyin' || division === 'buyout'}>
                  <HoverPointerText fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>{numberPad(idx + 1, 2)}</HoverPointerText>
                </NumberBox>
                <HoverPointerText fontFamily='Poppins-Medium' fontSize={12} color='#4F4F4F'>{onViewMovementName(data)}</HoverPointerText>
              </NumberWrapper>
              <ScaleButton onClick={onClickScaleUption}>
                <HoverPointerText fontFamily='Poppins-Medium' fontSize={12} color='#FFFFFF'>Scale option</HoverPointerText>
              </ScaleButton>
            </TextScaleButtonWrapper>
            <IconWrapper>
              <Icon src={ProgramVideoIcon} onClick={() => onClickMovementVideo(division, idx)} />
              <Icon style={{ width: `16px`, height: `16px` }} src={CopyIcon} onClick={() => onClickCopyMovement(data, division)} />
              <Icon src={MovementOptionIcon} onClick={() => onClickMovementOption(division, idx)} />
              <Icon src={ExerciseDeleteIcon} onClick={() => onClickDeleteMovement(division, idx)} />
            </IconWrapper>
          </MovementTitleWrapper>
          {
            data.isUseCount &&
            <LayerWrapper topMargin={16}>
              <ComponentWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>횟수</Text>
                {
                  tag === 'SINGLE' &&
                    <SingleCountWrapper>
                      <IncludeSlashSingleDataInput value={data.count} setValue={onChangeData} unit='REPS' column='count' division={division} index={idx} />
                      <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>X</Text>
                      <IncludeSlashSingleDataInput value={data.sets} setValue={onChangeData} unit='SETS' column='sets' division={division} index={idx} />
                    </SingleCountWrapper>
                }
                { 
                  tag === 'ForTime-Different Reps' && 
                  <DifferentRepsBox>
                    <ValueBox>
                      <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{reps}</Text>
                    </ValueBox>
                    <UnitBox>
                      <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REPS</Text>
                    </UnitBox>
                  </DifferentRepsBox>
                }
                {         
                  tag !== 'SINGLE' && tag !== 'ForTime-Different Reps' && 
                  <IncludeSlashDataInput value={tag === 'TABATA' ? 'MAX' : data.count} setValue={onChangeData} unit='REPS' column='count' division={division} index={idx} /> 
                }
              </ComponentWrapper>
            </LayerWrapper>
          }
          {
            data.isUseWeight &&
            <LayerWrapper topMargin={12}>
              <ComponentWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>무게</Text>
                <IncludeSlashDataInput value={data.weight} setValue={onChangeData} unit={data.weightUnit} column='weight' division={division} index={idx} />
              </ComponentWrapper>
              <UnitSelectBox value={data.weightUnit} setValue={onChangeUnit} options={weightOptions} column='weight' division={division} index={idx} />
            </LayerWrapper>
          }
          {
            data.isUseDistance &&
            <LayerWrapper topMargin={12}>
              <ComponentWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>거리</Text>
                <IncludeSlashDataInput value={data.distance} setValue={onChangeData} unit={data.distanceUnit} column='distance' division={division} index={idx} />
              </ComponentWrapper>
              <UnitSelectBox value={data.distanceUnit} setValue={onChangeUnit} options={distanceOptions} column='distance' division={division} index={idx} />
            </LayerWrapper>
          }
          {
            data.isUseHeight &&
            <LayerWrapper topMargin={12}>
              <ComponentWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>높이</Text>
                <IncludeSlashDataInput value={data.height} setValue={onChangeData} unit={data.heightUnit} column='height' division={division} index={idx} />
              </ComponentWrapper>
              <UnitSelectBox value={data.heightUnit} setValue={onChangeUnit} options={distanceOptions} column='height' division={division} index={idx} />
            </LayerWrapper>
          }
          {
            data.isUseCalories &&
            <LayerWrapper topMargin={12}>
              <ComponentWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>열량</Text>
                <IncludeSlashDataInput value={data.calories} setValue={onChangeData} unit={data.caloriesUnit} column='calories' division={division} index={idx} />
              </ComponentWrapper>
              <UnitSelectBox value={data.caloriesUnit} setValue={onChangeUnit} options={caloriesOptions} column='calories' division={division} index={idx} />
            </LayerWrapper>
          }
          {
            data.isUseTime &&
            <LayerWrapper topMargin={12}>
              <ComponentWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>시간</Text>
                <TimeInputWrapper>
                  {/* <SmallTimeInput value={parseInt(data.time / 60) > 0 ? parseInt(data.time / 60) : 0} setValue={onChangeData} unit='MIN' column='minute' division={division} index={idx} />
                  <SmallTimeInput value={parseInt(data.time % 60) > 0 ? parseInt(data.time % 60) : 0} setValue={onChangeData} unit='SEC' column='second' division={division} index={idx} /> */}
                  <SmallTimeInput value={data.minute} setValue={onChangeData} unit='MIN' column='minute' division={division} index={idx} />
                  <SmallTimeInput value={data.second} setValue={onChangeData} unit='SEC' column='second' division={division} index={idx} />
                </TimeInputWrapper>
              </ComponentWrapper>
            </LayerWrapper>
          }
          {
            data.isUseTempo &&
            <LayerWrapper topMargin={12}>
              <ComponentWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>템포</Text>
                <IncludeSlashDataInput value={data.tempo} setValue={onChangeData} unit='TEMPO' column='tempo' division={division} index={idx} />
              </ComponentWrapper>
            </LayerWrapper>
          }
          {
            tag === 'SINGLE' &&
            <RestComponentWrapper topMargin={12}>
              <CheckBox isClicked={data.isUseRest} onClick={() => onClickRest(idx)}>
                <Check src={CheckImage} />
              </CheckBox>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>쉬는 시간</Text>
              <SingleRestInput value={data.rest} setValue={onChangeData} disabled={!data.isUseRest} unit='SEC' column='rest' division={division} index={idx} />
            </RestComponentWrapper>
          }
        </MovementWrapper>
      </TotalWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: 100%;
  z-index: ${props => props.index};
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: 100%;

  background-color: #FFFFFF;
`;

const MovementWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: 100%;

  margin-top: 23px;
  background-color: #FFFFFF;
`;

const NumberWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;

  :hover {
    cursor: pointer;
  }
`;

const MovementTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 12px;
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;

  :hover {
    cursor: pointer;
  }
`;

const NumberBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 27px;
  height: 18px;

  border-radius: 6px;
  background-color: ${props => props.isGreen ? `#6DD49E` : `#FF8B48`};

  margin: 0 0 0 0;
  padding: 0 0 0 0;
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;

  margin-top: ${props => props.topMargin}px;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const SingleCountWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const HoverPointerText = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  padding: 0 0 0 0;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  padding: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const TimeInputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 24px;
`;

const RestComponentWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 7px;

  margin-top: ${props => props.topMargin}px;
`;

const CheckBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;

  border-radius: 6px;
  background-color: ${props => props.isClicked ? `#6DD49E` : `#E0E0E0`};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const Check = styled.img`
  width: 10px;
  height: 9px;
`;

const EmptyBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  min-height: ${props => props.height}px;
`;

const DifferentRepsBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding: 0px 16px 0px 16px;

  width: 286px;
  height: 34px;

  border: 1px solid #E0E0E0;
  border-radius: 15px;
  background-color: #FFFFFF;
`;

const ValueBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: 87%;
  height: 100%;

  overflow: hidden;
`;

const UnitBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-end;

  width: 13%;
`;

const TextScaleButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 12px;
`;

const ScaleButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 88px;
  height: 18px;

  border-radius: 6px;
  // background-color: #4161AF;
  background-color: #00A3FF;

  :hover {
    cursor: pointer;
  }
`;