import { React, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

function TagExplanationModal(props) {
  const { close } = props;

  // Modal 창 스크롤 제어 //
  useEffect(() => {
    document.body.style.cssText = `position: fixed; top: -${window.scrollY}px`

    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = `position: ""; top: "";`
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }
  }, [])

  return (
    <TopLevelWrapper>
      <ModalView>
        <TotalView>
          <Title>메인 운동 정보</Title>
          <ComponentWrapper>
            <TextWrapper>
              <Text fontFamily='Poppins-Bold' fontSize={14} color='#6DD49E'>Fortime</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>최대한 주어진 시간내에 많이, 빠르게 수행하는 운동입니다.</Text>
            </TextWrapper>
            <TextWrapper>
              <Text fontFamily='Poppins-Bold' fontSize={14} color='#6DD49E'>Amrap</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>주어진 시간안에 최대한 많은 라운드를 수행하는 운동입니다.</Text>
            </TextWrapper>
            <TextWrapper>
              <Text fontFamily='Poppins-Bold' fontSize={14} color='#6DD49E'>EMOM</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>매 분마다 주어진 동작을 수행하는 운동입니다.</Text>
            </TextWrapper>
            <TextWrapper>
              <Text fontFamily='Poppins-Bold' fontSize={14} color='#6DD49E'>Single</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>동작 하나를 수행하는 운동입니다.</Text>
            </TextWrapper>
            <TextWrapper>
              <Text fontFamily='Poppins-Bold' fontSize={14} color='#6DD49E'>Tabata</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>정해진 시간 안에 운동과 휴식 시간을 지켜 수행하는 운동입니다.</Text>
            </TextWrapper>
          </ComponentWrapper>
          <ButtonWrapper>
            <ExitButton onClick={() => close()}>닫기</ExitButton>
          </ButtonWrapper>
        </TotalView>
      </ModalView>
    </TopLevelWrapper>
  )
}

export default TagExplanationModal;

const startAnimation = keyframes`
	0% { opacity: 0; }
	50% { opacity: 0.5; }
	100% { opacity: 1; }
`;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  justify-content: center;
  align-items: center;

  z-index: 1000;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.2);
	
	animation-name: ${startAnimation};
	animation-duration: 0.5s;
	animation-timing-function: ease-out;
`;

const ModalView = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 422px;
  height: 463px;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(0, 0, 0, 0.1);

  background-color: #FFFFFF;
`;

const TotalView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 86.72%;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: 0 0 0 0;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  margin-top: 24px;
  gap: 21px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  gap: 16px;
  
  width: 100%;

  margin-top: 39px;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 72px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;
  
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #777777;
    color: #777777;
  }
`;

const SaveButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 74px;
  height: 40px;

  border-radius: 15px;
  background-color: #5BD98A;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    background-color: #2BCB67;
    cursor: pointer;
  }
`;
