import { React, useState, useRef, useImperativeHandle, forwardRef, useEffect } from 'react';
import styled from 'styled-components';

import WhiteExitPath from '../../../image/WhiteExit.png';
import GrayExitPath from '../../../image/GrayExit.png';
import WhiteExerciseLockPath from '../../../image/WhiteExerciseLock.png';
import GrayExerciseLockPath from '../../../image/GrayExerciseLock.png';
import WhiteExerciseUnLockPath from '../../../image/UnLock.png';
import GrayExerciseUnLockPath from '../../../image/GrayExerciseUnLock.png';
import OrangeExercisePlusPath from '../../../image/OrangeExercisePlus.png';
import WhiteExercisePlusPath from '../../../image/WhiteExercisePlus.png';

const ScheduleBox = forwardRef((props, ref) => {
  const { useData, clickDate, dateClickFn, onClickDeleteInSchedule, clickWorkoutIndex, setClickWorkoutIndex, changeTopLevelWrapperPosition } = props;

  const [value, setValue] = useState(clickDate);
  const [rendering, setRendering] = useState(false);
  const [isFirst, setIsFirst] = useState(true);

  const TotalRef = useRef([]);
  const ColumnRef = useRef([]);
  const ColumnDayRef = useRef([]);
  const ExerciseRef = useRef([]);
  const DeleteRef = useRef([]);
  const PlusImageRef = useRef([]);
  const AddExerciseRef = useRef([]);
  const AddButtonPlusImageRef = useRef([]);

  useImperativeHandle(ref, () => ({
    getValue: () => {
      return value;
    }
  }));

  // useEffect(() => {
  //   setValue(clickDate);
  // }, [clickDate])

  useEffect(() => {
    (
      async () => {
        for (let i = 0; i < useData.length; i++) {
          if (useData[i].classDate.format('YYYY.MM.DD') !== clickDate) {
            ColumnRef.current[i].style.fontFamily = 'NotoSansKR-Regular';
            ColumnRef.current[i].style.color = '#777777';

            ColumnDayRef.current[i].style.fontFamily = 'NotoSansKR-Regular';
            if (useData[i].classDate.format('YYYY.MM.DD') === clickDate) ColumnDayRef.current[i].style.color = '#FF8B48';
            else if (useData[i].classDate.format('ddd') === 'Sat') ColumnDayRef.current[i].style.color = '#4161AF';
            else if (useData[i].classDate.format('ddd') === 'Sun') ColumnDayRef.current[i].style.color = '#FF3131';
            else ColumnDayRef.current[i].style.color = '#CBCBCB';

            // ExerciseRef.current[i].style.borderColor = '#DFDFDF';
            // ExerciseRef.current[i].style.fontFamily = 'NotoSansKR-Regular';
            // ExerciseRef.current[i].style.color = '#595959';

            for (let j = 0; j < useData[i].workouts.length - 1; j++) {
              ExerciseRef.current[(i * 100) + j].style.borderColor = '#DFDFDF';
              ExerciseRef.current[(i * 100) + j].style.fontFamily = 'NotoSansKR-Regular';
              ExerciseRef.current[(i * 100) + j].style.color = '#595959';
            }
          }
          else {
            if (isFirst) {
              await changeTopLevelWrapperPosition();
              await TotalRef.current[i].scrollIntoView({ block: 'start', behavior: 'smooth' });
              await changeTopLevelWrapperPosition();

              setTimeout(() => (
                setIsFirst(false)
              ), 100)
            }
            // await changeTopLevelWrapperPosition();
            // await TotalRef.current[i].scrollIntoView({ block: 'start', behavior: 'smooth' });
            // // await TotalRef.current[i].scrollIntoView({ block: 'start' });
            // await changeTopLevelWrapperPosition();

            ColumnRef.current[i].style.fontFamily = `NotoSansKR-Medium`;
            ColumnRef.current[i].style.color = `#FF8B48`;

            ColumnDayRef.current[i].style.fontFamily = 'NotoSansKR-Medium';
            ColumnDayRef.current[i].style.color = '#FF8B48';

            for (let j = 0; j < useData[i].workouts.length - 1; j++) {
              if (j === clickWorkoutIndex) {
                ExerciseRef.current[(i * 100) + j].style.borderColor = '#FF8B48';
                ExerciseRef.current[(i * 100) + j].style.fontFamily = 'NotoSansKR-Medium';
                ExerciseRef.current[(i * 100) + j].style.color = '#232323';
              }
              else {
                ExerciseRef.current[(i * 100) + j].style.borderColor = '#DFDFDF';
                ExerciseRef.current[(i * 100) + j].style.fontFamily = 'NotoSansKR-Regular';
                ExerciseRef.current[(i * 100) + j].style.color = '#595959';
              }
            }
          }
        }
      }
    )();
  }, [clickDate, useData, isFirst, clickWorkoutIndex])

  // 마우스 호버링 시작 //
  function onMouseOver(index, workoutIndex) {
    // if (useData[index].classDate.format('YYYY.MM.DD') === clickDate || !useData[index].isClickable) return;

    // ColumnRef.current[index].style.fontFamily = `NotoSansKR-Medium`;
    // ColumnRef.current[index].style.color = `#FF8B48`;

    // ColumnDayRef.current[index].style.fontFamily = 'NotoSansKR-Medium';

    // // if (useData[index].classDate.format('YYYY.MM.DD') === value) ColumnDayRef.current[index].style.color = '#FF8B48';
    // // else if (useData[index].classDate.format('ddd') === 'Sat') ColumnDayRef.current[index].style.color = '#33C8FF';
    // // else if (useData[index].classDate.format('ddd') === 'Sun') ColumnDayRef.current[index].style.color = '#FF3131';
    // ColumnDayRef.current[index].style.color = '#FF8B48';

    // ExerciseRef.current[index].style.borderColor = '#FF8B48';
    // ExerciseRef.current[index].style.fontFamily = 'NotoSansKR-Medium';
    // ExerciseRef.current[index].style.color = '#232323';

    if (useData[index].classDate.format('YYYY.MM.DD') === clickDate && clickWorkoutIndex === workoutIndex) return;
    // ColumnRef.current[index].style.fontFamily = `NotoSansKR-Medium`;
    // ColumnRef.current[index].style.color = `#FF8B48`;

    // ColumnDayRef.current[index].style.fontFamily = 'NotoSansKR-Medium';
    // // if (useData[index].classDate.format('YYYY.MM.DD') === value) ColumnDayRef.current[index].style.color = '#FF8B48';
    // // else if (useData[index].classDate.format('ddd') === 'Sat') ColumnDayRef.current[index].style.color = '#33C8FF';
    // // else if (useData[index].classDate.format('ddd') === 'Sun') ColumnDayRef.current[index].style.color = '#FF3131';
    // ColumnDayRef.current[index].style.color = '#FF8B48';

    if (useData[index].isNone) PlusImageRef.current[(index * 100) + workoutIndex].src = WhiteExercisePlusPath;


    ExerciseRef.current[(index * 100) + workoutIndex].style.borderColor = '#FF8B48';
    ExerciseRef.current[(index * 100) + workoutIndex].style.fontFamily = 'NotoSansKR-Medium';
    ExerciseRef.current[(index * 100) + workoutIndex].style.color = '#232323';
  }

  // 마우스 호버링 끝 //
  function onMouseLeave(index, workoutIndex) {
    // if (useData[index].classDate.format('YYYY.MM.DD') === clickDate || !useData[index].isClickable) return;

    // ColumnRef.current[index].style.fontFamily = 'NotoSansKR-Regular';
    // ColumnRef.current[index].style.color = '#777777';

    // ColumnDayRef.current[index].style.fontFamily = 'NotoSansKR-Regular';

    // if (useData[index].classDate.format('YYYY.MM.DD') === clickDate) ColumnDayRef.current[index].style.color = '#FF8B48';
    // else if (useData[index].classDate.format('ddd') === 'Sat') ColumnDayRef.current[index].style.color = '#4161AF';
    // else if (useData[index].classDate.format('ddd') === 'Sun') ColumnDayRef.current[index].style.color = '#FF3131';
    // else ColumnDayRef.current[index].style.color = '#CBCBCB';

    // ExerciseRef.current[index].style.borderColor = '#DFDFDF';
    // ExerciseRef.current[index].style.fontFamily = 'NotoSansKR-Regular';
    // ExerciseRef.currnt[index].style.color = '#595959';

    if (useData[index].classDate.format('YYYY.MM.DD') === clickDate && clickWorkoutIndex === workoutIndex) return;
    // ColumnRef.current[index].style.fontFamily = 'NotoSansKR-Regular';
    // ColumnRef.current[index].style.color = '#777777';

    // ColumnDayRef.current[index].style.fontFamily = 'NotoSansKR-Regular';

    // if (useData[index].classDate.format('YYYY.MM.DD') === clickDate) ColumnDayRef.current[index].style.color = '#FF8B48';
    // else if (useData[index].classDate.format('ddd') === 'Sat') ColumnDayRef.current[index].style.color = '#4161AF';
    // else if (useData[index].classDate.format('ddd') === 'Sun') ColumnDayRef.current[index].style.color = '#FF3131';
    // else ColumnDayRef.current[index].style.color = '#CBCBCB';

    if (useData[index].isNone) PlusImageRef.current[(index * 100) + workoutIndex].src = OrangeExercisePlusPath;

    ExerciseRef.current[(index * 100) + workoutIndex].style.borderColor = '#DFDFDF';
    ExerciseRef.current[(index * 100) + workoutIndex].style.fontFamily = 'NotoSansKR-Regular';
    ExerciseRef.current[(index * 100) + workoutIndex].style.color = '#595959';
  }

  // 운동 추가버튼 호버링 //
  function onMouseOverAddExercise(index) {
    AddExerciseRef.current[index].style.width = `calc(100% - 38px)`;
    AddExerciseRef.current[index].style.backgroundColor = '#FF8B48';
    AddButtonPlusImageRef.current[index].src = WhiteExercisePlusPath;
  }

  // 운동 추가 버튼 호버링 //
  function onMouseLeaveAddExercise(index) {
    AddExerciseRef.current[index].style.width = `20px`;
    AddExerciseRef.current[index].style.backgroundColor = '#FFFCFA';
    AddButtonPlusImageRef.current[index].src = OrangeExercisePlusPath;
  }

  // 일정 Title 표시 //
  function viewTitle(data) {
    return data.lessonName + ' ' + data.title;
    // // 저장된 데이터가 있는경우 //
    // if (data.workoutId !== 0) return data.title;
    // // 저장된 데이터가 없는경우 //
    // else {
    //   // 수업 스케줄이 존재하는경우 //
    //   if (data.isClickable) return '작성이 필요합니다.';
    //   else return '수업 일정이 없습니다.';
    // }
  }

  // 일정 박스 배경색 표시 //
  function viewBackgroundColorOnExerciseBox(data, index) {
    if (data.classDate.format('YYYY.MM.DD') === clickDate && index === clickWorkoutIndex) return '#FF8B48';
    else return '#FFFFFF';
    // else {
    //   // 클릭 가능한 배경색 //
    //   if (data.isClickable) return '#FFFFFF';
    //   // 클릭 불가능한 배경색 //
    //   else return '#FDFDFD';
    //   // else return '#F2F2F2';
    // }
  }

  // 일정 박스 폰트 색상 //
  function viewFontColorOnExerciseBox(data, index) {
    // 클릭시 폰트색상 //
    if (data.classDate.format('YYYY.MM.DD') === clickDate && index === clickWorkoutIndex) return '#FFFFFF';
    else return '#595959';

    // // 클릭시 폰트색상 //
    // if (data.classDate.format('YYYY.MM.DD') === clickDate) return '#FFFFFF';
    // else {
    //   // 클릭 가능한 폰트색상 //
    //   if (data.isClickable) return '#595959';
    //   // 클릭 불가능한 폰트 색상 //
    //   // else return '#BEBEBE';
    //   else return '#CBCBCB';
    // }
  }

  // // 수업 타이틀 정렬방법 변경 //
  // function settingJustifyContent(data) {
  //   if (data.isClickable) return 'flex-start';
  //   else return 'center';
  // }

  // 수업 배경선 정리 //
  function viewBorderOnExerciseBox(data, index) {
    if (data.classDate.format('YYYY.MM.DD') === clickDate && clickWorkoutIndex === index) return '1px solid #FF8B48';
    else return '1px solid #DFDFDF';
    // else {
    //   if (data.isClickable) return '1px solid #DFDFDF';
    //   else return '';
    // }
  }

  // 날짜 별 요일 색상 변경 //
  function viewDayOfTheDateColor(data) {
    if (data.classDate.format('YYYY.MM.DD') === clickDate) return '#FF8B48';
    else if (data.classDate.format('ddd') === 'Sat') return '#33C8FF';
    else if (data.classDate.format('ddd') === 'Sun') return '#FF8B48';
    else return '#CBCBCB'
  }

  // 일정 클릭시 //
  function onClickDate(data, index) {
    // if (data.isClickable) dateClickFn(data.classDate.format('YYYY.MM.DD'));
    // else setIsViewEmptyScheduleModal(true);
    // dateClickFn(data.classDate.format('YYYY.MM.DD'));
    // if )
    dateClickFn(data);
    setClickWorkoutIndex(index);
  }

  // 운동 추가 버튼 클릭시 //
  function onClickAddExercise(data) {
    // console.log(data);
    // console.log(data.workouts.length - 1);
    dateClickFn(data, data.workouts.length - 1);
    setClickWorkoutIndex(data.workouts.length - 1);
    // onClickModifyButton();
    // if (!data.isNone) {
    //   // console.log(data.workouts.length - 1);
    //   setClickWorkoutIndex(data.workouts.length - 1);
    // }
  }

  function lockerView(data, workoutIndex) {
    // data.classDate.format('YYYY.MM.DD') === clickDate && workoutIndex === clickWorkoutIndex ? WhiteExerciseLockPath : GrayExerciseLockPath
    // 클릭되어있는 컴포넌트의 경우 //
    if (data.classDate.format('YYYY.MM.DD') === clickDate && workoutIndex === clickWorkoutIndex) {
      if (data.workouts[workoutIndex].isLock) return WhiteExerciseLockPath;
      else return WhiteExerciseUnLockPath;
    }
    // 클릭되어있지 않은 컴포넌트의 경우 //
    else {
      if (data.workouts[workoutIndex].isLock) return GrayExerciseLockPath;
      else return GrayExerciseUnLockPath;
    }
  }

  return (
    <TopLevelWrapper>
      <InfoBox>
        <Title>일정표</Title>
        <ListBox>
          {
            useData.map((data, index) => (
              <ComponentWrapper
                key={index}
                ref={element => (TotalRef.current[index] = element)}>
                {/* onMouseOver={() => onMouseOver(index)}
                onMouseLeave={() => onMouseLeave(index)}
                onClick={() => onClickDate(data)}> */}
                <ColumnWrapper>
                  <Column
                    ref={element => (ColumnRef.current[index] = element)}
                    isClicked={data.classDate.format('YYYY.MM.DD') === clickDate}>
                    {data.classDate.format('DD일')}
                  </Column>
                  <ColumnDay
                    ref={element => (ColumnDayRef.current[index] = element)}
                    // isClicked={data.classDate.format('YYYY.MM.DD') === value}
                    color={viewDayOfTheDateColor(data)}>
                    {data.classDate.format('(dd)')}
                  </ColumnDay>
                </ColumnWrapper>
                <ExerciseWrapper>
                  {
                    data.workouts.map((workoutData, workoutIndex) => (
                      data.isNone ?
                        <EmptyExerciseBox
                          ref={element => (ExerciseRef.current[(index * 100) + workoutIndex] = element)}
                          isClicked={data.classDate.format('YYYY.MM.DD') === clickDate && clickWorkoutIndex === workoutIndex}
                          onMouseOver={() => onMouseOver(index, workoutIndex)}
                          onMouseLeave={() => onMouseLeave(index, workoutIndex)}
                          onClick={() => onClickAddExercise(data)}>
                          <PlusButton ref={element => (PlusImageRef.current[(index * 100) + workoutIndex] = element)} src={data.classDate.format('YYYY.MM.DD') === clickDate && clickWorkoutIndex === workoutIndex ? WhiteExercisePlusPath : OrangeExercisePlusPath} />
                        </EmptyExerciseBox>
                        :
                        (
                          workoutData.workoutId !== 0 &&
                          <Exercise
                            ref={element => (ExerciseRef.current[(index * 100) + workoutIndex] = element)}
                            key={workoutIndex}
                            isClicked={data.classDate.format('YYYY.MM.DD') === clickDate && workoutIndex === clickWorkoutIndex}
                            border={viewBorderOnExerciseBox(workoutData, workoutIndex)}
                            backgroundColor={viewBackgroundColorOnExerciseBox(workoutData, workoutIndex)}
                            onMouseOver={() => onMouseOver(index, workoutIndex)}
                            onMouseLeave={() => onMouseLeave(index, workoutIndex)}
                            onClick={() => onClickDate(data, workoutIndex)}>
                            <ExerciseTextBox
                              isClicked={data.classDate.format('YYYY.MM.DD') === clickDate && workoutIndex === clickWorkoutIndex}
                              color={viewFontColorOnExerciseBox(workoutData, workoutIndex)}>
                              {/* justifyContent={settingJustifyContent(data)}> */}
                              {viewTitle(workoutData)}
                            </ExerciseTextBox>
                            <TotalIconWrapper>
                              <IconWrapper>
                                <LockIcon src={lockerView(data, workoutIndex)} />
                              </IconWrapper>
                              <IconWrapper 
                                ref={element => (DeleteRef.current[(index * 100) + workoutIndex] = element)}
                                onClick={() => onClickDeleteInSchedule(workoutData)}>
                                <DeleteButton src={data.classDate.format('YYYY.MM.DD') === clickDate && workoutIndex === clickWorkoutIndex ? WhiteExitPath : GrayExitPath} />
                              </IconWrapper>
                            </TotalIconWrapper>
                          </Exercise>
                        )
                    ))
                  }
                  {!data.isNone &&
                    <AddExerciseBoxWrapper>
                      <AddExerciseBox
                        ref={element => (AddExerciseRef.current[index] = element)}
                        onMouseOver={() => onMouseOverAddExercise(index)}
                        onMouseLeave={() => onMouseLeaveAddExercise(index)}
                        onClick={() => onClickAddExercise(data)}>
                        <PlusButton ref={element => (AddButtonPlusImageRef.current[index] = element)} src={OrangeExercisePlusPath} />
                      </AddExerciseBox>
                    </AddExerciseBoxWrapper>
                  }
                </ExerciseWrapper>
              </ComponentWrapper>
            ))
          }
        </ListBox>
      </InfoBox>
    </TopLevelWrapper>
  )
})

export default ScheduleBox;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 24px 0px 24px 0px;

  width: 408px;
  // height: 421px;

  border-radius: 30px;
  background-color: #FFFFFF;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const Title = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;
`;

const ListBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  gap: 8px;

  width: 360px;
  // height: 301px;
  height: 777px;

  margin-top: 25px;
  padding-top: 20px;
  border-top: 1px solid #ECECEC;

  overflow-y: scroll;
  overflow-x: none;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
		border: 3px solid transparent;
		// height: 92px;
		background-color: #DADADA;
		border-radius: 200px;
	}

  &::-webkit-scrollbar-track {
    // height: 439px;
		// border: 4px solid transparent;
		background-color: #F0F0F0;
		border-radius: 200px;
	}
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  width: 100%;

  :hover {
    cursor: pointer;
  }
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 9px;

  gap: 3px;
`;

const Column = styled.p`
  font-family: ${props => props.isClicked ? `NotoSansKR-Medium` : `NotoSansKR-Regular`};
  font-size: 12px;

  color: ${props => props.isClicked ? `#FF8B48` : `#777777`};

  margin: 0 0 0 0;

  transition: all 0.3s ease-in-out;
`;

const ColumnDay = styled.p`
  font-family: ${props => props.isClicked ? `NotoSansKR-Medium` : `NotoSansKR-Regular`};
  font-size: 12px;

  // color: ${props => props.isClicked ? `#FF8B48` : `#CBCBCB`};
  color: ${props => props.color};

  margin: 0 0 0 0;

  transition: all 0.3s ease-in-out;
`;

const ExerciseWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-right: 4px;
  
  gap: 8px;
  
  width: 294px;
`;

const Exercise = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  // width: 262px;
  // width: calc(100% - 32px);
  width: calc(100% - 23px);
  height: 36px;

  // padding: 0px 16px 0px 16px;
  padding: 0px 9px 0px 16px;

  // border: ${props => props.isClicked ? `1px solid #FF8B48` : `1px solid #DFDFDF`};
  border: ${props => props.border};
  border-radius: 15px;
  // background-color: #FFFFFF;
  background-color: ${props => props.backgroundColor};

  transition: all 0.3s ease-in-out;
`;

const EmptyExerciseBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 4px 0px 4px 0px;

  width: 298px;
  height: 28px;

  border-radius: 15px;
  background-color: ${props => props.isClicked ? `#FF8B48` : `#FFF7F2`};

  :hover {
    cursor: pointer;
    background-color: #FF8B48;
  }

  transition: all 0.3s ease-in-out;
`;

const AddExerciseBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-end;

  width: 100%;
`;

const AddExerciseBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-end;

  padding: 0px 19px 0px 19px;

  // width: 58px;
  width: 20px;
  height: 28px;

  border-radius: 15px;
  background-color: #FFFCFA;

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const ExerciseTextBox = styled.div`
  display: flex;
  flex-direction: row;

  // justify-content: flex-start;
  justify-content: ${props => props.justifyContent};
  align-items: flex-start;
  
  // width: 100%;
  width: 83.4%;
  height: 20px;

  // font-family: ${props => props.isClicked ? `NotoSansKR-Medium` : `NotoSansKR-Regular`};
  font-family: NotoSansKR-Regular;
  font-size: 14px;
  // color: ${props => props.isClicked ? `#232323` : `#595959`};
  color: ${props => props.color};

  overflow: hidden;

  transition: all 0.3s ease-in-out;
`;

const TotalIconWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  width: 16.6%;

  gap: 3px;
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;
`;

const LockIcon = styled.img`
  width: 10px;
  height: 13px;
`;

const DeleteButton = styled.img`
  width: 16px;
  height: 16px;

  :hover {
    cursor: pointer;
  }
`;

const PlusButton = styled.img`
  width: 20px;
  height: 20px;

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;