import { React, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Lottie from 'lottie-react-web';

import SalesList from './SalesList';

import LoadingAnimation from '../../../Animation/LoadingAnimation.json';

function SalesHistory(props) {
  const { 
    // today,
    selectedPartnerGym,
    isOpenPartnerGymSales, 
    calcDateText, 
    data, 
    isSalesLoading, 
    setIsSalesLoading 
  } = props;

  const history = useHistory();

  const [division, setDivision] = useState('Total');
  const [useData, setUseData] = useState([]);

  // 데이터 세팅 및 로딩 바 지우기 //
  useEffect(() => {
    let newData = [];
    
    // 전체보기일 경우 //
    if (division === 'Total') {
      newData = data;
    }
    // 링크 페이만 보기일 경우 //
    else if (division === 'LinkPay') {
      for (let i = 0; i < data.length; i++) {
        if (data[i].merchantKey === 'LinkPay') newData.push(data[i]);
      }
    }
    // 카드내역만 보기일 경우 //
    else if (division === 'Card') {
      for (let i = 0; i < data.length; i++) {
        if (data[i].cardPrice > 0) newData.push(data[i]);
      }
    }
    // 현금 내역만 보기일 경우 //
    else if (division === 'Cash') {
      for (let i = 0; i < data.length; i++) {
        if (data[i].cashPrice > 0) newData.push(data[i]);
      }
    }
    // 미수금 내역만 보기일 경우 //
    else if (division === 'Not') {
      for (let i = 0; i < data.length; i++) {
        if (data[i].notReceivedPrice > 0) newData.push(data[i]);
      }
    }

    setUseData(newData);

    setTimeout(() => {
      setIsSalesLoading(false);
    }, 1000)
  }, [data, division])

  // Sales List 클릭시 //
  function onClickSalesList(data) {
    if (data.memberId === undefined) {
      // alert('이미 탈퇴한 회원입니다.');
      alert('가입되어있지 않은 사용자입니다.');
      return;
    }

    if (selectedPartnerGym === undefined || selectedPartnerGym.isMyStore) history.push('/member/valid/detail/' + data.memberId);
    else history.push('/member/partner_gym/detail/' + data.memberId);
  }

  return (
    <TopLevelWrapper>
      <InfoBox>
        <TitleWrapper>
          {/* <Title>{today.clone().format('YYYY년 MM월 총 매출 내역')}</Title> */}
          <Title>{calcDateText() + ' 총 매출 내역 ( ' + useData.length + '건 )' }</Title>
          <DivisionWrapper>
            <DivisionText isClicked={division === 'Total'} onClick={() => setDivision('Total')}>전체</DivisionText>
            <DivisionText isClicked={division === 'LinkPay'} onClick={() => setDivision('LinkPay')}>링크페이</DivisionText>
            <DivisionText isClicked={division === 'Card'} onClick={() => setDivision('Card')}>카드</DivisionText>
            <DivisionText isClicked={division === 'Cash'} onClick={() => setDivision('Cash')}>현금</DivisionText>
            <DivisionText isClicked={division === 'Not'} onClick={() => setDivision('Not')}>미수금</DivisionText>
          </DivisionWrapper>
        </TitleWrapper>
        {
          isSalesLoading ?
            <AnimationBox>
              <LottieWrapper>
                <Lottie options={{ animationData: LoadingAnimation }} />
              </LottieWrapper>
            </AnimationBox>
            :
            <TotalInfoBox>
              {
                isOpenPartnerGymSales ?
                  <SalesList data={useData} onClickSalesList={onClickSalesList} />
                  :
                  <NotOpenTextWrapper>
                    <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#444444' cursor='default'>이 업체는 파트너 체육관에 매출을 비공개 합니다</Text>
                    <Text fontFamily='NotoSansKR-Regular' fontSize={15} color='#444444' cursor='default'>링크존 설정에서 “파트너 체육관”에서 권한 설정해 주세요</Text>
                  </NotOpenTextWrapper>
              }
            </TotalInfoBox>
        }
      </InfoBox>
    </TopLevelWrapper>
  )
}

export default SalesHistory;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 24px 0px 24px 0px;

  width: 1020px;
  // height: 544px;

  margin-top: 38px;
  margin-bottom: 483px;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  background-color: #FFFFFF;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 97.25%;
  width: 94.5%;
  // height: 544px;
`;

const AnimationBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  z-index: 5;
  
  width: 100%;
  height: 439px;

  // background-color: rgba(0, 0, 0, 0.2);
  background-color: #FFFFFF;
`;

const TotalInfoBox = styled.div`
  display: flex;
  flex-drection: row;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 437px;
`;

const NotOpenTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const LottieWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 80px;
  height: 80px;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const Title = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #232323;

  // margin: 28px 0 0 13px;
  margin: 0px;
  padding: 0px;
`;

const DivisionWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 17px;
`;

const DivisionText = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: ${props => props.isClicked ? `#232323` : `#D9D9D9`};

  margin: 0px;
  padding: 0px;

  :hover {
    cursor: pointer;
    color: #232323;
  }

  transition: all 0.3s ease-in-out;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }
`;
