
import { forwardRef, useImperativeHandle, useState, useRef, useEffect } from "react";
import styled from 'styled-components';

const SocialSecurityInput = forwardRef((props, ref) => {
	const {className, onChangeValue} = props;
	const [value, setValue] = useState('');
	const [clickCount, setClickCount] = useState(0);

	const inputRef = useRef();

	useImperativeHandle(ref, () => ({
		setValue: (val) => {
			setValue(val);
		},
		getValue: () => {
			return value;
		},
		focus: () => {
			inputRef.current.focus();
		},
		isBold: () => {
			inputRef.current.style.borderColor = '#FF3131';
		}
	}));

	useEffect(() => {
		if (value === '' && clickCount > 0) {
			inputRef.current.style.borderColor = '#FF3131';
		}
		else if (clickCount > 0) {
			inputRef.current.style.borderColor = '#FF8B48';
		}
	}, [value, clickCount])

	function handleChange(e) {
		const curValue = e.target.value
		const newValue = curValue.replace(/[^0-9]/g, '')

		if (newValue.length < 2) {
			setValue(newValue);
			if (newValue !== '') onChangeValue(className)
		}
	}

	function handleFocus(e) {
		inputRef.current.style.borderColor = '#FF8B48';
		setValue('');
	}

	function handleBlur(e) {
		setClickCount(clickCount + 1);
		if (value === "") {
		}
		else { }// do nothing
	}

	return (
		<CustomInput
			ref={inputRef}
			type='text' 
			value={value || ''}
			onChange={handleChange}
			onFocus={handleFocus}
			onBlur={handleBlur} />
	)
})

export default SocialSecurityInput;

const CustomInput = styled.input`
	width: 22px;
	// width: max(20px, 1.145833333333333vw);
	// width: max(17.78px, 1.145833333333333vw);
	
	padding-top: 7px;
	// padding-top: max(5px, 0.3645833333333333vw);
	// padding-top: max(4.4px, 0.3645833333333333vw);
	padding-right: 0px;
	padding-bottom: 6px;
	// padding-bottom: max(5px, 0.3125vw);
	// padding-bottom: max(4.4px, 0.3125vw);
	padding-left: 0px;

	font-family: Poppins-Medium;
	font-size: 18px;
	// font-size: max(14px, 0.9375vw);
  color: #000000;
  text-align: center;

  border: 1px solid #CBCBCB;
  border-radius: 8px;
	// border-radius: max(5px, 0.4166666666666667vw);
	
  transition: all 0.3s ease-in-out;

	:focus {
		outline: none;
		border: 1px solid #FF8B48;
	}
`;