import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

import BlackPencelIcon from '../../../image/BlackPencel.svg';
import BlackExitIcon from '../../../image/BlackExit.svg';
import WhitePencelIcon from '../../../image/WhitePencel.svg';
import WhiteExitIcon from '../../../image/WhiteExit.svg';

export default function CategoryBox(props) {
  const { 
    data, 

    // 움직임 //
    index,
    dataListLength,
    target,
    targetStartY,
    targetEndY,
    emptyHeight,
    clickIndex,
    changedIndex,
    setChangedIndex,
    clickDivision,
    isNotClick,
    setIsNotClick,
    onMouseDown,
    // 움직임 //

    setClickLessonData, 
    isClickedLessonEdit, 
    onClickEditLessonButton, 
    onClickDeletedLesson 
  } = props;
  
  const [pencelIcon, setPencelIcon] = useState(BlackPencelIcon);
  const [exitIcon, setExitIcon] = useState(BlackExitIcon);

  const TotalRef = useRef();
  const EmptyRef = useRef();
  const PencelRef = useRef();
  const ExitRef = useRef();

  // useEffect(() => {
  //   console.log('------');
  //   console.log('TotalRef');
  //   console.log(TotalRef.current);
  //   console.log('target');
  //   console.log(target);
  //   console.log(target === TotalRef.current && target !== null);
  // }, [TotalRef, target])

  // 움직임 정리 //
  useEffect(() => {
    if (TotalRef === null 
      || target === TotalRef.current 
      || changedIndex === undefined
      || targetStartY === 0
      || targetEndY === 0) {
      TotalRef.current.style.transform = `translateY(0px)`;
      TotalRef.current.style.transition = `none`;
      
      return;
    }

    // 현재 컴포넌트 시작 좌표 //
    var myStartY = TotalRef.current.getBoundingClientRect().top;
    // 컴포넌트 크기 //
    // var myHeight = TotalRef.current.offsetHeight;
    // let myHeight = 67;
    let myHeight = 52;
    // 현재 컴포넌트 끝 좌표 //
    var myEndY = myEndY + myHeight;
    // 컴포넌트 움직임을 결정할 기준점 //
    var standardY = (myStartY + (myHeight / 2));
    // 타겟 컴포넌트의 기준점 ( 정중앙 ) //
    var targetStandardY = ((targetStartY + targetEndY) / 2);

    // 움직임 에니메이션 주기 //
    TotalRef.current.style.transition = `all 0.3s ease-in-out`;

    // console.log('**************');
    // console.log('index : ' + index);
    // console.log('clickIndex : ' + clickIndex);
    // console.log('changedIndex : ' + changedIndex);
    // console.log('targetStartY : ' + targetStartY);
    // console.log('targetEndY : ' + targetEndY);
    // console.log('절대위치 : ' + myStartY);
    // // console.log('크기 : ' + myHeight);
    // console.log('기준점 : ' + standardY);
    // console.log('**************');
    // console.log(TotalRef.current.style.transform);

    // 타겟이 움직여야할 컴포넌트보다 아래에 있을 경우 //
    if (clickIndex > index) {
      // 가동 범위 내에 있는지 확인 //
      if (myStartY < targetStandardY || targetStandardY < myEndY) {
        // 타겟의 기준점이 움직임 기준점을 넘기면 이동
        if (targetStandardY < standardY) {
          TotalRef.current.style.transform = `translateY(${targetEndY - targetStartY + 15}px)`;
          // if (idx === 0) console.log("?? : " + idx);
          setChangedIndex(index);
        }
        else if (targetStandardY > standardY) {
          TotalRef.current.style.transform = `translateY(0px)`;
          // if (idx === 0) console.log("!! : " + (idx === dataListLength - 1 ? dataListLength : idx + 1));
          setChangedIndex(index === dataListLength - 1 ? dataListLength : index + 1);
        }
      }
    }
    // 타겟이 움직여야할 컴포넌트보다 위에 있을 경우 //
    else if (clickIndex < index) {
      // 가동 범위 내에 있는지 확인 //
      if (myStartY < targetStandardY || targetStandardY < myEndY) {
        if (targetStandardY > standardY) {
          TotalRef.current.style.transform = `translateY(-${targetEndY - targetStartY + 15}px)`;
          // if (idx === 0) console.log("** : " + idx);
          setChangedIndex(index);
        }
        // 다시 위로 올라갈경우 제자리로 //
        else if (targetStandardY < standardY) {
          TotalRef.current.style.transform = `translateY(0px)`;
          // if (idx === 0) console.log("^^ : " + (idx === 0 ? 0 : idx - 1));
          setChangedIndex(index === 0 ? 0 : index - 1);
        }
      }
    }
  }, [TotalRef, target, targetStartY, targetEndY, clickDivision, clickIndex, changedIndex, index, dataListLength])

  // 카테고리 수정 클릭시 흰색 오버링 안풀리는 버그 수정 //
  useEffect(() => {
    if (isClickedLessonEdit) {
      setPencelIcon(BlackPencelIcon);
      setExitIcon(BlackExitIcon);
    }
  }, [isClickedLessonEdit])

  // useEffect(() => {
  //   console.log(emptyHeight);
  // }, [emptyHeight])
  // 마우스 호버링시 //
  function onMouseOver() {
    if (isClickedLessonEdit) return;

    setPencelIcon(WhitePencelIcon);
    setExitIcon(WhiteExitIcon);
  }

  // 마우스 호버링시 //
  function onMouseLeave() {
    if (isClickedLessonEdit) return;

    setPencelIcon(BlackPencelIcon);
    setExitIcon(BlackExitIcon);
  }

  // 카테고리 클릭시 //
  function onClickLesson(e) {
    // if (e.target === PencelRef.current || e.target === ExitRef.current || isClickedLessonEdit) return;
    
    if (e.target === PencelRef.current || e.target === ExitRef.current) return;
    if (isNotClick) return;
    
    setClickLessonData(data);
  }

  // 카테고리 수정 클릭시 //
  function onClickEditLesson() {
    if (isNotClick) return;

    onClickEditLessonButton(data);
  }

  // 이동(동작 번호 및 동작 이름) 버튼 클릭시 //
  function onComponentMouseDown(e) {
    onMouseDown(e, TotalRef.current, index);
  }

  return (
    <TopLevelWrapper>
      { (target === TotalRef.current && target !== undefined) && <EmptyBox ref={EmptyRef} height={emptyHeight} /> }
      <LessonBoxView ref={TotalRef} onMouseDown={onComponentMouseDown} onClick={onClickLesson} isHide={data.isHide} hoverColor={isClickedLessonEdit ? '' : data.color} isUnvisible={true} onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
        <TextWrapper>
          <Text fontFmaily='NotoSansKR-Medium' fontSize={14} cursor='pointer'>{data.name}</Text>
        </TextWrapper>
        <StatusWrapper>
          {
            data.isHide && <Text fontFmaily='NotoSansKR-Medium' fontSize={14} cursor='default'>비공개</Text>
          }
          <ImageWrapper>
            <Image ref={PencelRef} src={pencelIcon} onClick={onClickEditLesson}/>
            <Image ref={ExitRef} src={exitIcon} onClick={() => onClickDeletedLesson(data)}/>
          </ImageWrapper>
        </StatusWrapper>
      </LessonBoxView>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  justify-content: center;
  align-items: center;

  width: 495px;
  // height: 52px;
  // min-height: 52px; 
`;

const LessonBoxView = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding: 0px 16px 0px 16px;
  margin: 7.5px 0px 7.5px 0px;

  width: 463px;
  height: 52px;
  min-height: 52px; 

  color: #333333;

  border-radius: 12px;
  background-color: ${props => props.isHide ? `#F2F2F2` : `#FFFFFF`};
  box-shadow: 0px 10px 10px rgba(0, 54, 71, 0.02);

  transition: all 0.3s ease-in-out;

  :hover {
    color: ${props => props.isUnvisible ? `#333333` : `#FFFFFF`};
    background-color: ${props => props.hoverColor};
    cursor: pointer;
  }
`;

const EmptyBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: ${props => props.height === 0 ? `0px 0px 0px 0px` : `7.5px 0px 7.5px 0px`};

  width: 463px;
  // height: 52px;
  // min-height: 52px;
  height: ${props => props.height}px;
  min-height: ${props => props.height}px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 77.75%;

  overflow: hidden;
`;

const Text = styled.div`
  font-family: ${props => props.fontFmaily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }
`;

const StatusWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 10px;
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 6px;
`;

const Image = styled.img`
  width: 20px;
  height: 20px;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;