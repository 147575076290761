import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import NewClassSizeInput from '../../../Component/SettingComponent/NewClassSizeInput';
import NewClassTypeInput from '../../../Component/SettingComponent/NewClassTypeInput';
import NewCoachSelectBox from '../../../Component/SettingComponent/NewCoachSelectBox';
import NewColorChoice from '../../../Component/SettingComponent/NewColorChoice';
import NewCountInput from '../../../Component/SettingComponent/NewCountInput';
import NewLessonNameInput from '../../../Component/SettingComponent/NewLessonNameInput';

export default function LessonEditBox(props) {
  const { clickEditLessonData, isClickedLessonEdit, onClickExitAddLessonEdit, onSave } = props;

  const [isLessonUnVisible, setIsLessonUnVisible] = useState(false);
  const [id, setId] = useState(-1);
  const [lessonName, setLessonName] = useState('');
  const [lessonType, setLessonType] = useState('Offline');
  const [lessonSize, setLessonSize] = useState('Group');
  const [maxBookedCount, setMaxBookedCount] = useState(0);
  const [deduction, setDeduction] = useState(1);
  const [teacherData, setTeacherData] = useState([]);
  const [teacherId, setTeacherId] = useState(-1);
  const [time, setTime] = useState(0);
  const [selectedColor, setSelectedColor] = useState('#F4A1A9');

  const [translateX, setTranslateX] = useState(495);

  // 수정 및 추가 버튼 클릭시 오른쪽에서 날라오기 에니매이션 //
  useEffect(() => {
    if (isClickedLessonEdit) setTranslateX(-495);
    else setTranslateX(60);
  }, [isClickedLessonEdit])

  // 데이터 수정 및 추가시 세팅 //
  useEffect(() => {
    
    // 수정이 아닌 추가일 경우 처음 데이터 세팅 //
    if (clickEditLessonData === undefined) {
      setId(-1);
      // setIsLessonUnVisible(false);
      setIsLessonUnVisible(true);
      setLessonName('');
      setLessonType('Offline');
      setLessonSize('Group');
      setMaxBookedCount(0);
      setTeacherData([]);
      setTeacherId(-1);
      setTime(0);
      setDeduction(1);
      setSelectedColor('#F4A1A9');
    }
    // 수정일 경우 기존 데이터 세팅 //
    else {
      setId(clickEditLessonData.id);
      setIsLessonUnVisible(clickEditLessonData.isHide);
      setLessonName(clickEditLessonData.name);
      setLessonType(clickEditLessonData.type);
      setLessonSize(clickEditLessonData.size);
      setMaxBookedCount(clickEditLessonData.maxBookedPeople);
      setTeacherId(clickEditLessonData.teacherIds);
      setTime(clickEditLessonData.time);
      setDeduction(clickEditLessonData.deduction);
      setSelectedColor(clickEditLessonData.color);
    }
    // console.log(clickEditLessonData);
  }, [clickEditLessonData])

  // 저장버튼 클릭시 //
  function onClickSave() {

    var teacherIdList = [];

    teacherData.map((newData) => (
      teacherIdList.push(newData.id)
    ))

    var teacherIds = '';

    for (var i = 0; i < teacherIdList.length; i++) {
      if (i === 0) teacherIds += teacherIdList[i];
      else teacherIds += ',' + teacherIdList[i];
    }

    if (teacherIds === '') {
      alert('수업을 진행할 코치를 선택해주세요!');
      return;
    }
    else if (lessonName === '') {
      alert('수업 이름을 설정해주세요');
      return;
    }

    var requestData = {
      id: id === -1 ? undefined : id,
      storeId: 0,
      categoryId: 0,
      teacherIds: teacherIds,
      name: lessonName,
      time: time,
      maxBookedPeople: maxBookedCount,
      type: lessonType,
      size: lessonSize,
      deduction: deduction,
      color: selectedColor,
      isHide: isLessonUnVisible
    }

    // console.log(requestData);
    onSave(requestData);
  }

  return (
    <TopLevelWrapper translateX={translateX}>
      <InfoView>
        <Text fontFmaily='NotoSansKR-Bold' fontSize={16} color='#232323' cursor='default'>수업 추가하기</Text>
        <ComponentTotalWrapper>
          <ComponentWrapper>
            <ComponentTitleWrapper>
              <Text fontFmaily='NotoSansKR-Regular' fontSize={12} color='#595959' cursor='default'>수업 명</Text>
              <UnVisibleWrapper>
                <Text fontFmaily='NotoSansKR-Regular' fontSize={12} color='#595959' cursor='default'>수업 비공개</Text>
                <UnVisibleCheckBox isClicked={isLessonUnVisible} onClick={() => setIsLessonUnVisible(!isLessonUnVisible)}/>
              </UnVisibleWrapper>
            </ComponentTitleWrapper>
            <NewLessonNameInput value={lessonName} setValue={setLessonName} placeholder='수업명을 입력해주세요'/>
          </ComponentWrapper>

          {/* <ComponentWrapper>
            <Text fontFmaily='NotoSansKR-Regular' fontSize={12} color='#595959' cursor='default'>수업 유형</Text>
            <NewClassTypeInput lessonType={lessonType} setLessonType={setLessonType}/>
          </ComponentWrapper> */}

          <ComponentRowWrapper>
            <ComponentHalfWrapper> 
              <Text fontFmaily='NotoSansKR-Regular' fontSize={12} color='#595959' cursor='default'>수업 차감 횟수</Text>
              <NewCountInput value={deduction} setValue={setDeduction} unit='회'/>
            </ComponentHalfWrapper>

            <ComponentHalfWrapper> 
              <Text fontFmaily='NotoSansKR-Regular' fontSize={12} color='#595959' cursor='default'>수업 정원</Text>
              <NewCountInput value={maxBookedCount} setValue={setMaxBookedCount} unit='명'/>
            </ComponentHalfWrapper>
          </ComponentRowWrapper>

          {/* <ComponentRowWrapper>
            <ComponentHalfWrapper>
              <Text fontFmaily='NotoSansKR-Regular' fontSize={12} color='#595959' cursor='default'>코치</Text>
              <NewCoachSelectBox value={teacherData} setValue={setTeacherData} teacherId={teacherId} setTeacherId={setTeacherId}/>
            </ComponentHalfWrapper>
            <ComponentHalfWrapper> 
              <Text fontFmaily='NotoSansKR-Regular' fontSize={12} color='#595959' cursor='default'>수업 정원</Text>
              <NewCountInput value={maxBookedCount} setValue={setMaxBookedCount} unit='명'/>
            </ComponentHalfWrapper>
          </ComponentRowWrapper> */}

          <ComponentWrapper>
            <Text fontFmaily='NotoSansKR-Regular' fontSize={12} color='#595959' cursor='default'>코치</Text>
            <NewCoachSelectBox value={teacherData} setValue={setTeacherData} teacherId={teacherId} setTeacherId={setTeacherId} />
          </ComponentWrapper>
          <ComponentWrapper>
            <Text fontFmaily='NotoSansKR-Regular' fontSize={12} color='#595959' cursor='default'>수업 태그 색상</Text>
            <NewColorChoice selectedColor={selectedColor} setSelectedColor={setSelectedColor}/>
          </ComponentWrapper>
        </ComponentTotalWrapper>
        <ButtonWrapper>
          <ExitButton onClick={onClickExitAddLessonEdit}>
            <Text fontFmaily='NotoSansKR-Regular' fontSize={14} color='#CBCBCB' cursor='pointer'>취소</Text>
          </ExitButton>
          <SaveButton onClick={onClickSave}>
            <Text fontFmaily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='pointer'>저장하기</Text>
          </SaveButton>
        </ButtonWrapper>
      </InfoView>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 495px;
  min-width: 495px;
  max-width: 495px;
  // height: 523px;
  height: 454px;
  
  z-index: 3;

  border-radius: 30px;
  background-color: #FFFFFF;
  // box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);

  transform: translateX(${props => props.translateX}px);
  transition: all 0.3s ease-in-out;
`;

const InfoView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 92.12%;
`;

const ComponentTotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 14px;

  width: 100%;

  gap: 12px;
`;

const ComponentRowWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  gap: 8px;
`;

const ComponentHalfWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 49.34%;

  gap: 8px;
`;

const ComponentTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const UnVisibleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 10px;
`;

const UnVisibleCheckBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 14px;
  height: 14px;

  border: 1px solid #DFDFDF;
  border-radius: 4px;
  background-color: ${props => props.isClicked ? '#FF8B48' : '#FFFFFF'};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const Text = styled.div`
  font-family: ${props => props.fontFmaily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: flex-end;

  margin-top: 58px;

  width: 100%;

  gap: 16px;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 72px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;

  :hover {
    cursor: pointer;
  }
`;

const SaveButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 15px;
  background-color: #5BD98A;

  :hover {
    cursor: pointer;
  }
`;