import { React, useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import FetchModule from '../../Share/Network/FetchModule.js';
import AccountInput from '../../../Component/AccountComponent/AccountInput.js';

const SearchId = () => {
  const history = useHistory();

  const [ownerClickCount, setOwnerClickCount] = useState(0);
  const [emailClickCount, setEmailClickCount] = useState(0);
  const [invalidEmail, setInvalidEmail] = useState(true);
  const [planationText, setPlanationText] = useState('필수항목을 입력해주세요');
  const [sendExplanation, setSendExplanation] = useState('해당 주소로 이메일이 전송되었습니다');

  const OwnerRef = useRef();
  const EmailRef = useRef();

  const OwnerPlanationRef = useRef();
  const EmailPlanationRef = useRef();
  const TotalPlanationRef = useRef();

  useEffect(() => {
    if (invalidEmail) setPlanationText('필수항목을 입력해주세요');
    else setPlanationText('올바른 이메일 형식이 아닙니다');
  }, [invalidEmail])

  useEffect(() => {
		if (ownerClickCount === 0 && emailClickCount === 0) return;

		if (OwnerRef.current.getValue() === '' && ownerClickCount > 0) OwnerPlanationRef.current.style.opacity = 1;
    else OwnerPlanationRef.current.style.opacity = 0;

		if (EmailRef.current.getValue() === '' && emailClickCount > 0) EmailPlanationRef.current.style.opacity = 1;
		else EmailPlanationRef.current.style.opacity = 0;
    // else PlanationRef.current.style.color = '#FF8B48';
	}, [ownerClickCount, emailClickCount])

  // email형식 확인 정규식
	function isEmail(asValue) {
		var regExp = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,6}$/i;

		return regExp.test(asValue);
	}

  // Input 컴포넌트 클릭시
  function clickCountFn(className, clickCount) {
    if (className === 'OwnerInput') setOwnerClickCount(clickCount);
    else if (className === 'EmailInput') setEmailClickCount(clickCount);
  }

  // 이메일 전송 클릭시
  function sendingButtonClick() {	
    var ownerName = OwnerRef.current.getValue();
    var email = EmailRef.current.getValue();
    var invalidCount = 0;

		if (ownerName === '') { 
			OwnerRef.current.plusClickCount();
			OwnerRef.current.setDefaultPlaceholder('반드시 성함을 입력해주세요');
			OwnerPlanationRef.current.style.opacity = 1;
			
			invalidCount += 1;
		} 
	
		if (email === '') { 
			EmailRef.current.plusClickCount();
			EmailRef.current.setDefaultPlaceholder('가입시 입력하신 이메일 주소를 적어주세요');
			EmailPlanationRef.current.style.opacity = 1;
			
			invalidCount += 1;
		} 
    else if (!isEmail(email)) {
      setInvalidEmail(false);
			EmailRef.current.setDefaultPlaceholder('가입시 입력하신 이메일 주소를 적어주세요');
			EmailPlanationRef.current.style.opacity = 1;
			
			invalidCount += 1;
    }

    if (invalidCount > 0) return;

    var requestData = { 
      email: email,
      ownerName: ownerName
    }

    // const fetchModule = new FetchModule();
		// (
		// 	async () => {
		// 		const responseData = await fetchModule.postData('identifier/asp/identified/' + ownerName + '/' + email, 'POST');
				 
		// 		if (responseData.status === 200) setSendExplanation('해당 주소로 이메일이 전송되었습니다');
		// 		else setSendExplanation('올바르지 않은 정보입니다.');
		// 	}
		// )();

    const fetchModule = new FetchModule();
		(
			async () => {
				const responseData = await fetchModule.postData('identifier/find-id', 'POST', requestData);
				//  console.log(responseData);
				if (responseData.status === 200) {
          localStorage.setItem('UserId', responseData.data[0]);
          history.push('/find-id');
        }
				else setSendExplanation('올바르지 않은 정보입니다.');
			}
		)();

    TotalPlanationRef.current.style.opacity = 1;
  }

  return (
    <TopLevelWrapper>
      <InfoBox>
        <InfoWrapper>
          <Title>아이디 찾기</Title>
          <InputInfo>관리자 성함</InputInfo>
          <AccountInput ref={OwnerRef} className='OwnerInput' placeholder='반드시 성함을 입력해주세요' type='text' clickCountFn={clickCountFn} />
          <AccountExplanation ref={OwnerPlanationRef}>필수항목을 입력해주세요</AccountExplanation>
          <InputInfo>이메일</InputInfo>
          <AccountInput ref={EmailRef} className='EmailInput' placeholder='가입시 입력하신 이메일 주소를 적어주세요' type='text' clickCountFn={clickCountFn} />
          <AccountExplanation ref={EmailPlanationRef}>{planationText}</AccountExplanation>
          <SendingButton onClick={sendingButtonClick}>
            {/* <SendingButtonText>인증 메일 전송</SendingButtonText> */}
            <SendingButtonText>아이디 확인</SendingButtonText>
          </SendingButton>
          <TotalExplanationWrapper>
            <TotalExplanation ref={TotalPlanationRef}>{sendExplanation}</TotalExplanation>
          </TotalExplanationWrapper>
        </InfoWrapper>
      </InfoBox>
    </TopLevelWrapper>
  )
}

export default SearchId;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 582px;
  // width: max(351px, 30.3125vw);
  height: 455px;
  // height: max(338px, 23.69791666666667vw);

  border-radius: 50px;
  // border-radius: max(30px, 2.604166666666667vw);
  background-color: #FFFFFF;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 468px;
  // width: max(271px, 24.375vw);

  // height: 377px;

  // margin: 54px 0 0 0;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 24px;
  // font-size: max(18px, 1.25vw);

  color: #000000;

  // margin: 0 0 38px 0;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 37px;
  margin-left: 0px;
`;

const InputInfo = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;
  // font-size: max(12px, 0.7291666666666667vw);

  color: #000000;

  margin: 19px 0 0 0;
  // margin-top: max(11px, 0.9895833333333333vw);
  // margin-right: 0px;
  // margin-bottom: 0px;
  // margin-left: 0px;
`;

const AccountExplanation = styled.p`
	font-family: NotoSansKR-Regular;
	font-size: 12px;
  // font-size: max(12px, 0.625vw);
	// line-height: 17.38px;

	color: #FF3131;

	margin: 4px 0 0 0;
	opacity: 0;
  transition: all 0.5s ease-in-out;
`;

const SendingButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 468px;
  // width: max(271px, 24.375vw);
  height: 46px;
  // height: max(28px, 2.395833333333333vw);

  margin-top: 40px;
  // margin-top: max(13px, 2.083333333333333vw);

  background-color: #FF8B48;
  border-radius: 23px;

  transition: all 0.5s ease-in-out;

	:hover {
		cursor: pointer;
		background-color: #FF7322;
	}
`;

const SendingButtonText = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 14px;
  // font-size: max(12px, 0.7291666666666667vw);

  color: #FFFFFF;

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;

const TotalExplanationWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const TotalExplanation = styled.p`
	font-family: NotoSansKR-Regular;
	// font-size: max(12px, 0.625vw);
  font-size: 12px;
	// line-height: 17.38px;

	color: #FF8B48;

	margin: 20px 0 0 0;
  // margin-top: max(8px, 1.041666666666667vw);
  // margin-right: 0px;
  // margin-bottom: 0px;
  // margin-left: 0px;

	opacity: 0;
  transition: all 0.5s ease-in-out;
`;
