import { React, useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';

import FetchModule from '../../Share/Network/FetchModule.js';
import SignUpPWInput from '../../../Component/AccountComponent/SignUpPwInput.js';

import IdentifierPageLogoPath from '../../../image/SearchIdentifierLogo.png';

const PermuteView = forwardRef((props, ref) => {
  const { permuteButtonClick } = props;
  const [pwClickCount, setPwClickCount] = useState(0);
  const [confirmClickCount, setConfirmClickCount] = useState(0);
  const [planationText, setPlanationText] = useState('필수항목을 입력해주세요');
  const history = useHistory();
  const TopLevelRef = useRef();
  const PwRef = useRef();
  const ConfirmRef = useRef();

  const PwPlanationRef = useRef();
  const ConfirmPlanationRef = useRef();

  useImperativeHandle(ref, () => ({
    setOpacity: () => {
      TopLevelRef.current.style.opacity = 0;
    }
  }));

  useEffect(() => {
    const fetchModule = new FetchModule();
		(
			async () => {
				const responseData = await fetchModule.postData('identifier/asp/permute/' + sessionStorage.getItem("PermuteToken"), 'POST');
      	// console.log("ResponseData : " + responseData);
				 
				if (responseData.status !== 200) {
          alert("올바르지 않은 접근입니다.");
          history.push("/");
        }
			}
		)();
  }, [])

  useEffect(() => {
		if (pwClickCount === 0 && confirmClickCount === 0) return;

		if (PwRef.current.getValue() === '' && pwClickCount > 0) PwPlanationRef.current.style.opacity = 1;
    else PwPlanationRef.current.style.opacity = 0;

		if (ConfirmRef.current.getValue() === '' && confirmClickCount > 0) ConfirmPlanationRef.current.style.opacity = 1;
		else ConfirmPlanationRef.current.style.opacity = 0;
	}, [pwClickCount, confirmClickCount])


  // Input 컴포넌트 클릭시
  function clickCountFn(className, clickCount) {
    if (className === 'PWInput') setPwClickCount(clickCount);
    else if (className === 'ConfirmInput') setConfirmClickCount(clickCount);
  }

  // 변경버튼 클릭시
  function changeButtonClick() {	
    var password = PwRef.current.getValue();
    var confirmPassword = ConfirmRef.current.getValue();
    var invalidCount = 0;

		if (password === '') { 
			PwRef.current.plusClickCount();
			PwRef.current.setDefaultPlaceholder('새롭게 사용하실 비밀번호를 입력해주세요');
			// PwPlanationRef.current.style.opacity = 1;
			
			invalidCount += 1;
		} 
	
		if (confirmPassword === '') { 
			ConfirmRef.current.plusClickCount();
			ConfirmRef.current.setDefaultPlaceholder('비밀번호를 다시한번 더 확인해주세요');
			// ConfirmPlanationRef.current.style.opacity = 1;
			
			invalidCount += 1;
		} 

    if (password !== confirmPassword) {
      ConfirmRef.current.setValue('');
      ConfirmRef.current.setDefaultPlaceholder('위에 변경하신 비밀번호와 일치하지 않습니다');

      invalidCount += 1;
    }

    if (invalidCount > 0) return;

    const fetchModule = new FetchModule();
		(
			async () => {
				const responseData = await fetchModule.postData('identifier/asp/permute/' + sessionStorage.getItem("PermuteToken") + '/' + password, 'POST');
      	// console.log("ResponseData : " + responseData);
				 
				if (responseData.status === 200) permuteButtonClick();
        else alert("비밀번호 변경에 실패하였습니다.\n잠시후 다시시도해주세요.");
			}
		)();
    
    // if (isSuccess) permuteButtonClick();
    // else alert("비밀번호 변경에 실패하였습니다.\n잠시후 다시시도해주세요.");
  }

  return (
    <TopLevelWrapper ref={TopLevelRef}>
      <TopTitleWrapper onClick={() => history.push('/')}>
        <Logo src={IdentifierPageLogoPath}/>
        <TopTitle>Link Zone</TopTitle>
      </TopTitleWrapper>
      <InfoBox>
        <InfoWrapper>  
          <Title>비밀번호 변경</Title>
          <InputInfo>새로운 비밀번호</InputInfo>
          <SignUpPWInput ref={PwRef} className='PWInput' placeholder='사용하실 비밀번호를 입력해주세요' type='password' clickCountFn={clickCountFn} />
          <AccountExplanation ref={PwPlanationRef}>필수항목을 입력해주세요</AccountExplanation>
          <InputInfo>비밀번호 확인</InputInfo>
          <SignUpPWInput ref={ConfirmRef} className='ConfirmInput' placeholder='비밀번호를 한번 더 확인해주세요' type='password' clickCountFn={clickCountFn} />
          <AccountExplanation ref={ConfirmPlanationRef}>{planationText}</AccountExplanation>
          <SendingButton onClick={changeButtonClick}>
            <SendingButtonText>비밀번호 변경</SendingButtonText>
          </SendingButton>
        </InfoWrapper> 
      </InfoBox>
    </TopLevelWrapper>
  )
})

export default PermuteView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  opacity: 1;
  transition: all 0.5s ease-in-out;
`;

const TopTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;

  margin-bottom: 40px;
  // margin-bottom: max(20px, 2.083333333333333vw);

  :hover {
    cursor: pointer;
  }
`;

const Logo = styled.img`
  width: 78px;
  // width: max(40px, 4.0625vw);
  height: 78px;
  // height: max(40px, 4.0625vw);

  margin-right: 20px;
  // margin-right: max(8px, 1.041666666666667vw);
`;

const TopTitle = styled.p`
  font-family: Poppins-SemiBold;
  font-size: 36px;
  // font-size: max(24px, 1.875vw);
  
  color: #FF8B48;

  margin: 0 0 0 0;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 582px;
  // width: max(351px, 30.3125vw);
  height: 455px;
  // height: max(394px, 23.69791666666667vw);

  border-radius: 50px;
  // border-radius: max(30px, 2.604166666666667vw);
  background-color: #FFFFFF;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 468px;
  // width: max(271px, 24.375vw);
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 24px;
  // font-size: max(18px, 1.25vw);

  color: #000000;

  // margin: 0 0 38px 0;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 38px;
  // margin-bottom: max(31px, 1.979166666666667vw);
  margin-left: 0px;
`;

const InputInfo = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;
  // font-size: max(12px, 0.7291666666666667vw);

  color: #000000;

  margin: 19px 0 0 0;
  // margin-top: max(11px, 0.9895833333333333vw);
  // margin-right: 0px;
  // margin-bottom: 0px;
  // margin-left: 0px;
`;

const AccountExplanation = styled.p`
	font-family: NotoSansKR-Regular;
	font-size: 12px;
  // font-size: max(12px, 0.625vw);
	// line-height: 17.38px;

	color: #FF3131;

	margin: 4px 0 0 0;
	opacity: 0;
  transition: all 0.5s ease-in-out;
`;

const SendingButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 468px;
  // width: max(271px, 24.375vw);
  height: 46px;
  // height: max(28px, 2.395833333333333vw);

  margin-top: 40px;
  // margin-top: max(36px, 2.083333333333333vw);

  background-color: #FF8B48;
  border-radius: 23px;

  transition: all 0.5s ease-in-out;

	:hover {
		cursor: pointer;
		background-color: #FF7322;
	}
`;

const SendingButtonText = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 14px;
  // font-size: max(12px, 0.7291666666666667vw);

  color: #FFFFFF;

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;