import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";

import WhiteSelectOffPath from '../../image/WhiteSelectOff.png';
import SelectOnPath from '../../image/SelectOn.png';
import SelectOffPath from '../../image/SelectOff.png';

export default function TagSelectBox(props) {
  const { value, setValue } = props;

  const [isClicked, setIsClicked] = useState(false);
  
  const TotalRef = useRef();
  const SelectListRef = useRef();

  const options = [
    {name: 'ForTime'},
    {name: 'AMRAP'},
    {name: 'SINGLE'},
    {name: 'EMOM'},
    {name: 'TABATA'},
    {name: 'Custom'},
  ] 

	// // 이미 저장된 수업은 먼저 세팅해주기 //
	// useEffect(() => {
	// 	if (options.length === 0) return;
  //   else if (originalLessonId === 0) {
  //     setValue(options[0]);
  //     return;
  //   }
		
	// 	options.map((data) => {
	// 		if (data.id === originalLessonId) {
	// 			setValue(data);
	// 			return;
	// 		}
	// 	})
	// }, [options, originalLessonId])

  // 컴포넌트 밖을 클릭하면 셀렉트 창 닫기
  useEffect(() => {
    document.addEventListener("mousedown", onClickOutSide);

    return () => {
      document.removeEventListener("mousedown", onClickOutSide);
    }
  }, [onClickOutSide])

  // 컴포넌트 밖을 클릭하면 셀렉트 창 닫기
  function onClickOutSide(e) {
    if (TotalRef.current !== e.target && !TotalRef.current.contains(e.target)) {
			// SelectListRef.current.style.height = `0px`;
			// SelectListRef.current.style.border = '0px solid #FFFFFF';
			// SelectListRef.current.style.overflow = 'hidden';
      setIsClicked(false);
    }
  }

  // // 셀렉트 박스 색상 결정 //
  // function decideSelectBoxBackgroundColor() {
  //   return value.color;
  // }

  // 셀렉트 박스 클릭시 //
  function onClickSelectBox() {
		if (isClicked) {
			// SelectListRef.current.style.height = `0px`;
			// SelectListRef.current.style.border = '0px solid #FFFFFF';
			// SelectListRef.current.style.overflow = 'hidden';
			setIsClicked(false);
		}
		else {
			// SelectListRef.current.style.backgroundColor = '#FFFFFF';
			// SelectListRef.current.style.border = '1px solid #FF8B48';
			// SelectListRef.current.style.height = 32 * (options.length) > 500 ? `500px` : (32 * (options.length) + 'px');
			// SelectListRef.current.style.maxHeight = `500px`;
			// SelectListRef.current.style.overflowY = 'scroll';
			// SelectListRef.current.style.overflowX = 'none';
			setIsClicked(true);
		}
  }

  // 수업 선택시 //
  function onClickSelectList(data) {
    setValue(data);

    // SelectListRef.current.style.height = `0px`;
    // SelectListRef.current.style.border = '0px solid #FFFFFF';
    // SelectListRef.current.style.overflow = 'hidden';
    setIsClicked(false);
  }

  return (
    <SelectBoxWrapper ref={TotalRef}>
      <SeletedBox onClick={onClickSelectBox}>
          <SelectBoxTextWrapper>
            <Text fontFamily='Poppins-Medium' fontSize={14} color='#000000' hover='pointer'>{value.name}</Text>
          </SelectBoxTextWrapper>
        <ArrowImage src={SelectOffPath} isClicked={isClicked}/>
      </SeletedBox>
      <WillSelectList ref={SelectListRef} isClicked={isClicked}>
        {
          options.map((data, index) => (
            <SelectListTextWrapper 
              key={'SelectTag-' + index}
              isClicked={isClicked}
              onClick={() => onClickSelectList(data)}>
              {/* <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#000000' hover='pointer'>{data.name}</Text> */}
              {data.name}
            </SelectListTextWrapper>
          ))
        }
      </WillSelectList>
    </SelectBoxWrapper>
  )
}

const SelectBoxWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  height: 100%;
`;

const SeletedBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding: 0px 15px;

  width: calc(100% - 32px);
  height: calc(100% - 2px);

  border: 1px solid #CBCBCB;
  border-radius: 30px;
  background-color: #FFFFFF;

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const ArrowImage = styled.img`
  width: 24px;
  height: 24px;

  transform: ${props => props.isClicked ? `rotate(-180deg)` : `rotate(0deg)`};

  transition: all 0.3s ease-in-out;
`;

const WillSelectList = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 37px;

  width: 100%;
  height: ${props => props.isClicked ? `204px` : `0px`};

  border: ${props => props.isClicked ? `1px solid #FF8B48` : `0px`};
  border-radius: 6px;


	overflow: hidden;

	:hover {
		cursor: pointer;
	}

	&::-webkit-scrollbar {
		// width: 9px;
		width: 0px;
	}

	&::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 20px #FF8B48;
		border: 3px solid transparent;
		height: 25px;
		// background-color: #FF8B48;
		border-radius: 200px;
	}

	&::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px transparent;
		border: 4px solid transparent;
		background-color: transparent;
	}

	&::-webkit-scrollbar-track-piece {
		background-color: transparent;
	}

  transition: all 0.3s ease-in-out;
`;

const SelectBoxTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 80%;
  height: 21px;

  overflow: hidden;
`;

const SelectListTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  padding: 0px 10px;

  width: calc(100% - 20px);
  min-height: 34px;

  overflow: hidden;
  
  background-color: #FFFFFF;

  font-family: Poppins-Medium;
  font-size: 14px;

  color: #4F4F4F;

  :hover {
    cursor: pointer;
    color: #FFFFFF;
    background-color: #FF8B48;
  }

  transition: all 0.3s ease-in-out;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  
  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;