import { React, useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';

import FetchModule from '../../Share/Network/FetchModule';

import LeftArrow from '../../../image/Left.png'//왼쪽 화살표
import RightArrow from '../../../image/Right.png'//우측 화살표
import TestClassSelectBox from '../../../Component/NoteComponent/TestClassSelectBox';
import ScheduleBox from './ScheduleBox';
import CalenderView from './CalenderView';
import ExerciseSaveModal from './ExerciseSaveModal';
import RankingBox from './RankingBox';
import RankingExerciseBox from './RankingExerciseBox';
import LessonSelectBox from '../../../Component/NoteComponent/LessonSelectBox';
import ScheduleSelectBox from '../../../Component/NoteComponent/ScheduleSelectBox';
import RankingScheduleBox from './RankingScheduleBox';

export default function NewRankingTotalView() {
  const history = useHistory();

  const [data, setData] = useState([]);
  const [date, setDate] = useState(moment());
  const [rendering, setRendering] = useState(false);
  
  const [lessonId, setLessonId] = useState(-1);
  const [scheduleId, setScheduleId] = useState(-1);

  const [selectedLesson, setSelectedLesson] = useState(undefined);
  const [selectedScheduleData, setSelectedScheduleData] = useState(undefined);
  const [rankingList, setRankingList] = useState([]);
  const [nowSection, setNowSection] = useState(0);
  const [nowClickLinkCoachId, setNowClickLinkCoachId] = useState('');
  const [isClickedDetail, setIsClickedDetail] = useState(false);
  const [buttonText, setButtonText] = useState('');
  const [isClickedModify, setIsClickedModify] = useState(false);
  const [isViewConfirm, setIsViewConfirm] = useState(false);
  const [lessonName, setLessonName] = useState('');
  const [clickDate, setClickDate] = useState(date.clone().format('YYYY.MM.DD'));
  const [clickWorkoutIndex, setClickWorkoutIndex] = useState(0);
  const [dateList, setDateList] = useState([]);
  const [useData, setUseData] = useState([{
    classDate: moment(),
    workouts: [{
      workoutId: 0,
      scheduleId: 0,
      storeId: parseInt(window.localStorage.getItem('StoreId')),
      classDate: moment(),
      title: '작성이 필요합니다.',
      sectionList: []
    }]
  }]);

  const firstWeek = date.clone().startOf('month').week();
  const lastWeek = date.clone().endOf('month').week() === 1 ? 53 : date.clone().endOf('month').week();

  const TopLevelRef = useRef();
  const ExerciseRef = useRef();
  const ClassSelectRef = useRef();
  const ScheduleRef = useRef();
  const CalenderRef = useRef();

  // workout Data에 따라 버튼 텍스트 변경 //
  useEffect(() => {
    for (let i = 0; i < useData.length; i++) {
      if (useData[i].classDate.format('YYYY.MM.DD') === clickDate) {
        if (isClickedModify) setButtonText('저장하기');
        else {
          if (useData[i].workoutId === 0) setButtonText('추가하기');
          else setButtonText('수정하기');
        }
        break;
      }
    }
  }, [isClickedModify, clickDate, useData])

  useEffect(() => {
    let newDateList = [];

    for (let week = firstWeek; week <= lastWeek; week++) {
      for (let i = 0; i < 7; i++) {
        let days = date.clone().startOf('year').week(week).startOf('week').add(i, 'day');

        if (days.format('MM') === date.format('MM')) newDateList.push(days);
      }
    }
    setDateList(newDateList);
  }, [date, firstWeek, lastWeek])

  // 새로운 버전의 일정표 데이터 불러오기 //
  useEffect(() => {
    let requestData = {
      year: date.clone().format('YYYY'),
      month: date.clone().format('MM')
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataOfExerciseVersion2('workout/title_list', 'POST', requestData);
        // let responseData2 = await fetchModule.postDataOfExerciseVersion2('workout/ranking', 'POST', requestData);
        // console.log('-----ver1----')
        // console.log(responseData.data);
        // console.log('-----ver2----');
        // console.log(responseData2data);
        if (responseData.status === 200) {
          setData(responseData.data);
        }
      }
    )();
  }, [history, date, rendering])

  // 데이터를 
  useEffect(() => {
    if (dateList.length === 0) return;
    
    let workoutData = data.slice();
    let newData = [];

    // 데이터가 없으면 1개 넣고 시작 //
    if (workoutData.length === 0) {

      let tmpData = {
        workoutId: 0,
        scheduleId: 0,
        storeId: parseInt(window.localStorage.getItem('StoreId')),
        classDate: dateList[0],
        title: '',
        lessonName: '',
        sectionList: []
      }

      let tempDateData = {
        isNone: true,
        classDate: dateList[0],
        workouts: [tmpData]
      }

      newData.push(tempDateData);
    }

    for (let i = 0; i < dateList.length; i++) {
      let isNone = true;

      let tempDateData = {
        isNone: false,
        classDate: dateList[i],
        workouts: []
      }

      for (let j = 0; j < workoutData.length; j++) {
        if (dateList[i].format('MM.DD') === moment(workoutData[j].classDate).format('MM.DD')) {
          isNone = false;

          workoutData[j].classDate = moment(workoutData[j].classDate);
          tempDateData.workouts.push(workoutData[j]);
        }
      }

      if (isNone) {
        let tmpEmptyData = {
          workoutId: 0,
          scheduleId: 0,
          // isClickable: isClickable,
          storeId: parseInt(window.localStorage.getItem('StoreId')),
          classDate: dateList[i],
          title: '',
          lessonName: '',
          sectionList: []
        }
        
        let tempEmptyDateData = {
          isNone: true,
          classDate: dateList[i],
          workouts: [tmpEmptyData]
        }

        newData.push(tempEmptyDateData);
      }
      else {
        newData.push(tempDateData);
      }
    }

    newData.sort(function (a, b) {
      return a.classDate < b.classDate ? -1 : a.classDate > b.classDate ? 1 : 0;
    })

    setUseData(newData);
  }, [dateList, data])

  // useEffect(() => {

  //   // if (scheduleId === -1) return;

  //   let data = {
  //     // scheduleId: scheduleId,
  //     month: date.clone().format('MM'),
  //     year: date.clone().format('YYYY')
  //   }

  //   const fetchModule = new FetchModule();
  //   (
  //     async () => {
  //       // var responseData = await fetchModule.postDataOfExercise('workout/ranking', 'POST', data);
  //       let responseData = await fetchModule.postDataOfExerciseVersion2('workout/ranking', 'POST', data);
  //       // console.log(responseData);
  //       if (responseData.status === 200) {
  //         let workoutData = responseData.data.slice();

  //         // 운동 데이터 정리 //
  //         for (let i = 0; i < workoutData.length; i++) {

  //           workoutData[i].sectionList.sort(function (a, b) {
  //             return a.sectionIndex < b.sectionIndex ? -1 : a.sectionIndex > b.sectionIndex ? 1 : 0;
  //           })

  //           for (let j = 0; j < workoutData[i].sectionList.length; j++) {
  //             workoutData[i].sectionList[j].buyinExerciseList = [];
  //             workoutData[i].sectionList[j].mainExerciseList = [];
  //             workoutData[i].sectionList[j].emomExerciseList = [];
  //             workoutData[i].sectionList[j].buyoutExerciseList = [];
  //             workoutData[i].sectionList[j].reps = workoutData[i].sectionList[j].reps.split('-');

  //             // Section Record Data 정리 //
  //             for (let u = 0; u < workoutData[i].sectionList[j].sectionRecordList.length; u++) {

  //               workoutData[i].sectionList[j].sectionRecordList.sort(function (a, b) {
  //                 return a.sectionIndex < b.sectionIndex ? -1 : a.sectionIndex > b.sectionIndex ? 1 : 0;
  //               })

  //               workoutData[i].sectionList[j].sectionRecordList[u].buyinExerciseList = [];
  //               workoutData[i].sectionList[j].sectionRecordList[u].mainExerciseList = [];
  //               workoutData[i].sectionList[j].sectionRecordList[u].emomExerciseList = [];
  //               workoutData[i].sectionList[j].sectionRecordList[u].buyoutExerciseList = [];


  //               for (let h = 0; h < workoutData[i].sectionList[j].sectionRecordList[u].exerciseList.length; h++) {
                  
  //                 workoutData[i].sectionList[j].sectionRecordList[u].exerciseList.sort(function (a, b) {
  //                   return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
  //                 })

  //                 workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].movementId = workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].movement.movementId;
  //                 workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].movementName = workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].movement.movementName;
  //                 workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].videoLinkId = workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].movement.videoLinkId;

  //                 // 무게 구분 데이터 사용할 경우 //
  //                 if (workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionWeight !== undefined
  //                   && workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionWeight !== ''
  //                   && workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionWeight !== 'None') {
  //                   workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].weight = workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionWeight;
  //                 }
  //                 else workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionWeight = '';

  //                 // 거리 구분 데이터 사용할 경우 //
  //                 if (workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionDistance !== undefined
  //                   && workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionDistance !== ''
  //                   && workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionDistance !== 'None') {
  //                   workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].distance = workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionDistance;
  //                 }
  //                 else workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionDistance = '';

  //                 // 높이 구분 데이터 사용할 경우 //
  //                 if (workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionHeight !== undefined
  //                   && workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionHeight !== ''
  //                   && workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionHeight !== 'None') {
  //                   workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].height = workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionHeight;
  //                 }
  //                 else workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionHeight = '';

  //                 // 칼로리 구분 데이터 사용할 경우 //
  //                 if (workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionCalories !== undefined
  //                   && workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionCalories !== ''
  //                   && workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionCalories !== 'None') {
  //                   workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].calories = workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionCalories;
  //                 }
  //                 else workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionCalories = '';

  //                 // 템포 구분 데이터 사용할 경우 //
  //                 if (workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionTempo !== undefined
  //                   && workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionTempo !== ''
  //                   && workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionTempo !== 'None') {
  //                   workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].tempo = workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionTempo;
  //                 }
  //                 else workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionTempo = '';

  //                 // Sets 구분 데이터 사용할 경우 //
  //                 if (workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionSets !== undefined
  //                   && workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionSets !== ''
  //                   && workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionSets !== 'None') {
  //                   workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].sets = workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionSets;
  //                 }
  //                 else workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionSets = '';

  //                 if (workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].division === 'Buyin') {
  //                   workoutData[i].sectionList[j].sectionRecordList[u].buyinExerciseList.push(workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h]);
  //                 }
  //                 else if (workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].division === 'Main') {
  //                   workoutData[i].sectionList[j].sectionRecordList[u].mainExerciseList.push(workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h]);
  //                 }
  //                 else if (workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].division === 'Main.Emom') {
  //                   workoutData[i].sectionList[j].sectionRecordList[u].emomExerciseList.push(workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h]);
  //                 }
  //                 else if (workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].division === 'Buyout') {
  //                   workoutData[i].sectionList[j].sectionRecordList[u].buyoutExerciseList.push(workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h]);
  //                 }
  //               }
  //             }

  //             for (let h = 0; h < workoutData[i].sectionList[j].exerciseList.length; h++) {
  //               workoutData[i].sectionList[j].exerciseList[h].movementId = workoutData[i].sectionList[j].exerciseList[h].movement.movementId;
  //               workoutData[i].sectionList[j].exerciseList[h].movementName = workoutData[i].sectionList[j].exerciseList[h].movement.movementName;
  //               workoutData[i].sectionList[j].exerciseList[h].videoLinkId = workoutData[i].sectionList[j].exerciseList[h].movement.videoLinkId;

  //               // // 무게 구분 데이터 사용할 경우 //
  //               // if (workoutData[i].sectionList[j].exerciseList[h].divisionWeight !== ''
  //               //   && workoutData[i].sectionList[j].exerciseList[h].divisionWeight !== 'None') {
  //               //   workoutData[i].sectionList[j].exerciseList[h].weight = workoutData[i].sectionList[j].exerciseList[h].divisionWeight;
  //               // }
  //               // else workoutData[i].sectionList[j].exerciseList[h].divisionWeight = '';

  //               // // 거리 구분 데이터 사용할 경우 //
  //               // if (workoutData[i].sectionList[j].exerciseList[h].divisionDistance !== ''
  //               //   && workoutData[i].sectionList[j].exerciseList[h].divisionDistance !== 'None') {
  //               //   workoutData[i].sectionList[j].exerciseList[h].distance = workoutData[i].sectionList[j].exerciseList[h].divisionDistance;
  //               // }
  //               // else workoutData[i].sectionList[j].exerciseList[h].divisionDistance = '';

  //               // // 높이 구분 데이터 사용할 경우 //
  //               // if (workoutData[i].sectionList[j].exerciseList[h].divisionHeight !== ''
  //               //   && workoutData[i].sectionList[j].exerciseList[h].divisionHeight !== 'None') {
  //               //   workoutData[i].sectionList[j].exerciseList[h].height = workoutData[i].sectionList[j].exerciseList[h].divisionHeight;
  //               // }
  //               // else workoutData[i].sectionList[j].exerciseList[h].divisionHeight = '';

  //               // // 칼로리 구분 데이터 사용할 경우 //
  //               // if (workoutData[i].sectionList[j].exerciseList[h].divisionCalories !== ''
  //               //   && workoutData[i].sectionList[j].exerciseList[h].divisionCalories !== 'None') {
  //               //     workoutData[i].sectionList[j].exerciseList[h].calories = workoutData[i].sectionList[j].exerciseList[h].divisionCalories;
  //               // }
  //               // else workoutData[i].sectionList[j].exerciseList[h].divisionCalories = '';

  //               // // 템포 구분 데이터 사용할 경우 //
  //               // if (workoutData[i].sectionList[j].exerciseList[h].divisionTempo !== ''
  //               //   && workoutData[i].sectionList[j].exerciseList[h].divisionTempo !== 'None') {
  //               //     workoutData[i].sectionList[j].exerciseList[h].tempo = workoutData[i].sectionList[j].exerciseList[h].divisionTempo;
  //               // }
  //               // else workoutData[i].sectionList[j].exerciseList[h].divisionTempo = '';

  //               // // Sets 구분 데이터 사용할 경우 //
  //               // if (workoutData[i].sectionList[j].exerciseList[h].divisionSets !== '' 
  //               //   && workoutData[i].sectionList[j].exerciseList[h].divisionSets !== 'None') {
  //               //     workoutData[i].sectionList[j].exerciseList[h].sets = workoutData[i].sectionList[j].exerciseList[h].divisionSets;
  //               // }
  //               // else workoutData[i].sectionList[j].exerciseList[h].divisionSets = '';

  //               if (workoutData[i].sectionList[j].exerciseList[h].division === 'Buyin') {
  //                 workoutData[i].sectionList[j].buyinExerciseList.push(workoutData[i].sectionList[j].exerciseList[h]);
  //               }
  //               else if (workoutData[i].sectionList[j].exerciseList[h].division === 'Main') {
  //                 workoutData[i].sectionList[j].mainExerciseList.push(workoutData[i].sectionList[j].exerciseList[h]);
  //               }
  //               else if (workoutData[i].sectionList[j].exerciseList[h].division === 'Main.Emom') {
  //                 workoutData[i].sectionList[j].emomExerciseList.push(workoutData[i].sectionList[j].exerciseList[h]);
  //               }
  //               else if (workoutData[i].sectionList[j].exerciseList[h].division === 'Buyout') {
  //                 workoutData[i].sectionList[j].buyoutExerciseList.push(workoutData[i].sectionList[j].exerciseList[h]);
  //               }
  //             }

  //             // exerciseIndex.asc 진행 //
  //             workoutData[i].sectionList[j].buyinExerciseList.sort(function (a, b) {
  //               return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
  //             })
  //             // exerciseIndex.asc 진행 //
  //             workoutData[i].sectionList[j].mainExerciseList.sort(function (a, b) {
  //               return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
  //             })
  //             // exerciseIndex.asc 진행 //
  //             workoutData[i].sectionList[j].emomExerciseList.sort(function (a, b) {
  //               return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
  //             })
  //             // exerciseIndex.asc 진행 //
  //             workoutData[i].sectionList[j].buyoutExerciseList.sort(function (a, b) {
  //               return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
  //             })
  //           }
  //         }

  //         setData(workoutData);
  //       }
  //       else if (responseData.status === 204) setData([]);
  //       else if (responseData.status === 401) history.push('/');
  //     }
  //   )();
  // }, [history, date, /*scheduleId,*/ rendering])

  // useEffect(() => {
  //   if (dateList.length === 0 || selectedScheduleData === undefined) return;

  //   var newData = data.slice();

  //   if (newData.length === 0) { // 데이터가 없으면 1개 넣고 시작
  //     let isClickable = false;

  //     // 반복날짜에 해당하는지 체크 //
  //     if (selectedScheduleData.applyWeek === 'All' || selectedScheduleData.applyWeek.includes(dateList[0].format('ddd'))) {
  //       // 반복 주차에 해당하는지 확인 //
  //       if (selectedScheduleData.repeatWeek === '0' || selectedScheduleData.repeatWeek.includes(getWeekNumber(dateList[0].format('YYYY-MM-DD')).toString())) {
  //         isClickable = true;
  //       }
  //     }

  //     var tmpData = {
  //       workoutId: 0,
  //       scheduleId: 0,
  //       isClickable: isClickable,
  //       storeId: parseInt(window.localStorage.getItem('StoreId')),
  //       classDate: dateList[0],
  //       title: '',
  //       sectionList: []
  //     }
  //   }

  //   for (var i = 0; i < dateList.length; i++) {
  //     var isNone = true;

  //     for (var j = 0; j < newData.length; j++) {
  //       if (dateList[i].format('MM.DD') === moment(newData[j].classDate).format('MM.DD')) {
  //         isNone = false;
  //         newData[j].isClickable = true;
  //         newData[j].classDate = moment(newData[j].classDate);
  //         break;
  //       }
  //     }

  //     if (isNone) {
  //       let isClickable = false;
  //       // 반복날짜에 해당하는지 체크 //
  //       if (selectedScheduleData.applyWeek === 'All' || selectedScheduleData.applyWeek.includes(dateList[i].format('ddd'))) {
  //         // 반복 주차에 해당하는지 확인 //
  //         if (selectedScheduleData.repeatWeek === '0' || selectedScheduleData.repeatWeek.includes(getWeekNumber(dateList[i].format('YYYY-MM-DD')).toString())) {
  //           isClickable = true;
  //         }
  //       }

  //       tmpData = {
  //         workoutId: 0,
  //         scheduleId: 0,
  //         isClickable: isClickable,
  //         storeId: parseInt(window.localStorage.getItem('StoreId')),
  //         classDate: dateList[i],
  //         title: '',
  //         sectionList: []
  //       }
  //       newData.push(tmpData);
  //     }
  //   }

  //   newData.sort(function (a, b) {
  //     return a.classDate < b.classDate ? -1 : a.classDate > b.classDate ? 1 : 0;
  //   })

  //   setUseData(newData);
  // }, [dateList, data])
  // useEffect(() => {
  //   if (dateList.length === 0) return;
    
  //   let workoutData = data.slice();
  //   let newData = [];

  //   // 데이터가 없으면 1개 넣고 시작 //
  //   if (workoutData.length === 0) {

  //     let tmpData = {
  //       workoutId: 0,
  //       scheduleId: 0,
  //       storeId: parseInt(window.localStorage.getItem('StoreId')),
  //       classDate: dateList[0],
  //       title: '',
  //       lessonName: '',
  //       sectionList: []
  //     }

  //     let tempDateData = {
  //       isNone: true,
  //       classDate: dateList[0],
  //       workouts: [tmpData]
  //     }

  //     newData.push(tempDateData);
  //   }

  //   for (let i = 0; i < dateList.length; i++) {
  //     let isNone = true;

  //     let tempDateData = {
  //       isNone: false,
  //       classDate: dateList[i],
  //       workouts: []
  //     }

  //     for (let j = 0; j < workoutData.length; j++) {
  //       if (dateList[i].format('MM.DD') === moment(workoutData[j].classDate).format('MM.DD')) {
  //         isNone = false;

  //         workoutData[j].classDate = moment(workoutData[j].classDate);
  //         tempDateData.workouts.push(workoutData[j]);
  //       }
  //     }

  //     if (isNone) {
  //       let tmpEmptyData = {
  //         workoutId: 0,
  //         scheduleId: 0,
  //         // isClickable: isClickable,
  //         storeId: parseInt(window.localStorage.getItem('StoreId')),
  //         classDate: dateList[i],
  //         title: '',
  //         lessonName: '',
  //         sectionList: []
  //       }
        
  //       let tempEmptyDateData = {
  //         isNone: true,
  //         classDate: dateList[i],
  //         workouts: [tmpEmptyData]
  //       }

  //       newData.push(tempEmptyDateData);
  //     }
  //     else {
  //       newData.push(tempDateData);
  //     }
  //   }

  //   newData.sort(function (a, b) {
  //     return a.classDate < b.classDate ? -1 : a.classDate > b.classDate ? 1 : 0;
  //   })

  //   setUseData(newData);
  // }, [dateList, data])


  // // 순위 정리 //
  // useEffect(() => {
  //   // console.log(useData);
  //   // console.log(clickDate);
    
  //   let userRankingList = [];

  //   for (let i = 0; i < useData.length; i++) {
  //     if (useData[i].classDate.format('YYYY.MM.DD') === clickDate) {
  //       // setSectionData(useData[i].sectionList);
  //       if (useData[i].workouts[clickWorkoutIndex].sectionList[nowSection] === undefined) {
  //         setRankingList([]);
  //         return;
  //       }

  //       let tmpList = [];
  //       // let totalScore = 0;

  //       // Custom인 경우 순위 따로 산출 //
  //       if (useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].tag === 'Custom') {
  //         // 순위표 구성을 위한 커스텀 기준데이터 확인 //
  //         // 점수 산출 방법이 없을 경우 ( 입력 순서대로 오름차순 )//
  //         if (useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].scoreKeyword === 'Not Scored') {
  //           let totalScore = 0;
  //           tmpList = useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList.slice();

  //           tmpList.sort(function (a, b) {
  //             return a.createdDate < b.createdDate ? -1 : a.createdDate < b.createdDate ? 1 : 0;
  //           })

  //           totalScore = tmpList.length;

  //           for (let j = 0; j < tmpList.length; j++) {
  //             totalScore -= j;

  //             // 이미 저장된 내역 확인 Flag //
  //             let isExist = false;
  //             // 이미 저장된 유저 점수가 있는지 확인 //
  //             for (let x = 0; x < userRankingList.length; x++) {
  //               // 이미 점수가 있으면 기존점수에 + 하기 //
  //               if (userRankingList[x].linkCoachId === tmpList[j].linkCoachId) {
  //                 totalScore += totalScore;
  //                 // 저장 플래그 //
  //                 isExist = true;
  //               }
  //             }

  //             // 저장된게 없으면 //
  //             if (!isExist) {
  //               let rankingInfo = {
  //                 linkCoachId: useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[j].linkCoachId,
  //                 userName: useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[j].userName,
  //                 userProfile: useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[j].userProfile,
  //                 createdDate: useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[j].createdDate,

  //                 totalScore: totalScore
  //               }

  //               userRankingList.push(rankingInfo);
  //             }
  //           }
  //         }
  //         // 시간 기준 점수 산출일 경우 //
  //         else if (useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].scoreKeyword === 'Time') {
  //           // 높은 점수 기준 //
  //           if (useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].scorePriority === 'heigher') {
  //             for (let j = 0; j < useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList.length; j++) {
  //               let totalScore = 0;
                
  //               totalScore = useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[j].recordTime;

  //               // 이미 저장된 내역 확인 Flag //
  //               let isExist = false;
  //               // 이미 저장된 유저 점수가 있는지 확인 //
  //               for (let x = 0; x < userRankingList.length; x++) {
  //                 // 이미 점수가 있으면 기존점수에 + 하기 //
  //                 if (userRankingList[x].linkCoachId === useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[j].linkCoachId) {
  //                   totalScore += totalScore;
  //                   // 저장 플래그 //
  //                   isExist = true;
  //                 }
  //               }

  //               // 저장된게 없으면 //
  //               if (!isExist) {
  //                 let rankingInfo = {
  //                   linkCoachId: useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[j].linkCoachId,
  //                   userName: useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[j].userName,
  //                   userProfile: useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[j].userProfile,
  //                   createdDate: useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[j].createdDate,

  //                   totalScore: totalScore
  //                 }

  //                 userRankingList.push(rankingInfo);
  //               }
  //             }
  //           }
  //           // 낮은 점수 기준 //
  //           else {
  //             for (let j = 0; j < useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList.length; j++) {
  //               let totalScore = 0;
                
  //               if (useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[j].recordTime === 0
  //                 || useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[j].success === 'Not Done') {
  //                 totalScore = -50000;
  //               }
  //               else {
  //                 totalScore = (useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[j].recordTime * -1);
  //               }

  //               // 이미 저장된 내역 확인 Flag //
  //               let isExist = false;
  //               // 이미 저장된 유저 점수가 있는지 확인 //
  //               for (let x = 0; x < userRankingList.length; x++) {
  //                 // 이미 점수가 있으면 기존점수에 + 하기 //
  //                 if (userRankingList[x].linkCoachId === useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[j].linkCoachId) {
  //                   totalScore += totalScore;
  //                   // 저장 플래그 //
  //                   isExist = true;
  //                 }
  //               }

  //               // 저장된게 없으면 //
  //               if (!isExist) {
  //                 let rankingInfo = {
  //                   linkCoachId: useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[j].linkCoachId,
  //                   userName: useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[j].userName,
  //                   userProfile: useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[j].userProfile,
  //                   createdDate: useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[j].createdDate,

  //                   totalScore: totalScore
  //                 }

  //                 userRankingList.push(rankingInfo);
  //               }
  //             }
  //           }
  //         }
  //         // 횟수, 무게, 거리, 포인트 기준 점수 산출일 경우 //
  //         else if (useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].scoreKeyword === 'Reps'
  //           || useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].scoreKeyword === 'Weight'
  //           || useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].scoreKeyword === 'Distance'
  //           || useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].scoreKeyword === 'Points'
  //           || useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].scoreKeyword === 'Peak Watts'
  //           || useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].scoreKeyword === 'Max Speed') {
  //           // 높은 점수 기준 //
  //           if (useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].scorePriority === 'heigher') {
  //             for (let j = 0; j < useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList.length; j++) {
  //               let totalScore = (useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[j].customKeywordValue);

  //               // 이미 저장된 내역 확인 Flag //
  //               let isExist = false;
  //               // 이미 저장된 유저 점수가 있는지 확인 //
  //               for (let x = 0; x < userRankingList.length; x++) {
  //                 // 이미 점수가 있으면 기존점수에 + 하기 //
  //                 if (userRankingList[x].linkCoachId === useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[j].linkCoachId) {
  //                   totalScore += totalScore;
  //                   // 저장 플래그 //
  //                   isExist = true;
  //                 }
  //               }

  //               // 저장된게 없으면 //
  //               if (!isExist) {
  //                 let rankingInfo = {
  //                   linkCoachId: useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[j].linkCoachId,
  //                   userName: useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[j].userName,
  //                   userProfile: useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[j].userProfile,
  //                   createdDate: useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[j].createdDate,

  //                   totalScore: totalScore
  //                 }

  //                 userRankingList.push(rankingInfo);
  //               }
  //             }
  //           }
  //           // 낮은 점수 기준 //
  //           else {
  //             for (let j = 0; j < useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList.length; j++) {
  //               let totalScore = 0;

  //               if (useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[j].customKeywordValue === 0
  //                 || useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[j].success === 'Not Done') {
  //                 totalScore = -50000;
  //               }
  //               else {
  //                 totalScore = (useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[j].customKeywordValue * -1);
  //               }
  //               // let totalScore = (useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[j].customKeywordValue * -1);

  //               // 이미 저장된 내역 확인 Flag //
  //               let isExist = false;
  //               // 이미 저장된 유저 점수가 있는지 확인 //
  //               for (let x = 0; x < userRankingList.length; x++) {
  //                 // 이미 점수가 있으면 기존점수에 + 하기 //
  //                 if (userRankingList[x].linkCoachId === useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[j].linkCoachId) {
  //                   totalScore += totalScore;
  //                   // 저장 플래그 //
  //                   isExist = true;
  //                 }
  //               }

  //               // 저장된게 없으면 //
  //               if (!isExist) {
  //                 let rankingInfo = {
  //                   linkCoachId: useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[j].linkCoachId,
  //                   userName: useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[j].userName,
  //                   userProfile: useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[j].userProfile,
  //                   createdDate: useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[j].createdDate,

  //                   totalScore: totalScore
  //                 }

  //                 userRankingList.push(rankingInfo);
  //               }
  //             }
  //           }
  //         }
  //         // 라운드 횟수 기준 점수 산출일 경우 //
  //         else if (useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].scoreKeyword === 'Rounds And Reps') {
  //           let totalScore = 0;

  //           // 높은 점수 기준 //
  //           if (useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].scorePriority === 'heigher') {
  //             for (let j = 0; j < useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList.length; j++) {
  //               totalScore = 0;

  //               let round = (useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[j].customLastRound * 100);
  //               let reps = (useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[j].customLastReps);

  //               // console.log('-------');
  //               // // console.log(useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[j].linkCoachId);
  //               // console.log(useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[j].userName);
  //               // console.log('round : ' + round);
  //               // console.log('reps : ' + reps);
  //               // console.log('totalScore : ' + totalScore);

  //               totalScore += (round + reps);

  //               // 이미 저장된 내역 확인 Flag //
  //               let isExist = false;
  //               // 이미 저장된 유저 점수가 있는지 확인 //
  //               for (let x = 0; x < userRankingList.length; x++) {
  //                 // 이미 점수가 있으면 기존점수에 + 하기 //
  //                 if (userRankingList[x].linkCoachId === useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[j].linkCoachId) {

  //                   totalScore += totalScore;
  //                   // 저장 플래그 //
  //                   isExist = true;
  //                 }
  //               }

  //               // 저장된게 없으면 //
  //               if (!isExist) {
  //                 let rankingInfo = {
  //                   linkCoachId: useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[j].linkCoachId,
  //                   userName: useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[j].userName,
  //                   userProfile: useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[j].userProfile,
  //                   createdDate: useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[j].createdDate,

  //                   totalScore: totalScore
  //                 }
  //                 userRankingList.push(rankingInfo);
  //               }
  //             }
  //           }
  //           // 낮은 점수 기준 //
  //           else {
  //             for (let j = 0; j < useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList.length; j++) {
  //               let round = (useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[j].customLastRound * 100);
  //               let reps = (useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[j].customLastReps);

  //               totalScore = 0;

  //               if (useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[j].success === 'Not Done') {
  //                 totalScore = -50000;
  //               }
  //               else {
  //                 totalScore += ((round + reps) * -1);
  //               }

  //               // 이미 저장된 내역 확인 Flag //
  //               let isExist = false;
  //               // 이미 저장된 유저 점수가 있는지 확인 //
  //               for (let x = 0; x < userRankingList.length; x++) {
  //                 // 이미 점수가 있으면 기존점수에 + 하기 //
  //                 if (userRankingList[x].linkCoachId === useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[j].linkCoachId) {
  //                   totalScore += totalScore;
  //                   // 저장 플래그 //
  //                   isExist = true;
  //                 }
  //               }

  //               // 저장된게 없으면 //
  //               if (!isExist) {
  //                 let rankingInfo = {
  //                   linkCoachId: useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[j].linkCoachId,
  //                   userName: useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[j].userName,
  //                   userProfile: useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[j].userProfile,
  //                   createdDate: useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[j].createdDate,

  //                   totalScore: totalScore
  //                 }

  //                 userRankingList.push(rankingInfo);
  //               }
  //             }
  //           }
  //         }
  //       }
  //       // Custom이 아닌경우 점수로 순위 산출 //
  //       else {
  //         for (let h = 0; h < useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList.length; h++) {
  //           // var round = 0;
  //           // var roundOfCount = 0;
  //           // var weight = 0;
  //           // var reps = 0;
  //           // var calories = 0;
  //           // var distance = 0;
  //           // var height = 0;
  //           // var timeRemaining = 0;
  //           // var successScore = 0;
  //           // 점수 구성 항목 //
  //           let round = 0;
  //           let roundOfCount = 0;
  //           let weight = 0;
  //           let reps = 0;
  //           let calories = 0;
  //           let distance = 0;
  //           let height = 0;
  //           let time = useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[h].recordTime;
  //           let timeRemaining = 0;
  //           let successScore = 0;

  //           let lastMovementBonus = 1;
  //           let lastRound = useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[h].completeRound * 10000;
  //           let lastReps = useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[h].lastRound;

  //           for (let j = 0; j < useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[h].exerciseList.length; j++) {
  //             // console.log(workoutData.sectionList[nowSection].sectionRecordList[i].exerciseList[j].exerciseIndex);
  //             if (useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[h].lastExerciseId === useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[h].exerciseList[j].exerciseId) {
  //               lastMovementBonus = (useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[h].exerciseList[j].exerciseIndex * 10000);
  //               break;
  //             }
  //           }

  //           // // 운동 내역 전체 점수 부여 ( 아예 진행하지 않은 운동은 점수 부여 제외 ) //
  //           // if (useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[h].success !== 'Not Done') {

  //             for (let z = 0; z < useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[h].exerciseList.length; z++) {
  //               // 무게 전부 더하기 //
  //               weight += useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[h].exerciseList[z].weight;
  //               // 횟수 전부 더하기 //
  //               reps += parseInt(useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[h].exerciseList[z].count);
  //               // 칼로리 전부 더하기 //
  //               calories += useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[h].exerciseList[z].calories;
  //               // 거리 전부 더하기 //
  //               distance += useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[h].exerciseList[z].distance;
  //               // 높이 전부 더하기 //
  //               height += useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[h].exerciseList[z].height;
  //             }

  //             // let roundOfCountList = parseInt(useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[h].roundOfCount.split('-'));
  //             let roundOfCountList = useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[h].roundOfCount.split('-')

  //             for (let z = 0; z < roundOfCountList.length; z++) {
  //               if (roundOfCountList[z] !== '') roundOfCount += parseInt(roundOfCountList[z]);
  //             }
  //             // console.log(roundOfCountList);
  //             // console.log(roundOfCount);
  //             // 제한시간이 설정되어있는 경우 ( 제한시간 - 진행한 시간 ) 으로 추산 // 
  //             if (useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].isUseTimeLimit) {
  //               timeRemaining += useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].timeLimit - useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[h].recordTime;
  //             }

  //             // 운동을 끝까지 완료한 경우 //
  //             if (useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[h].success === 'Success') {
  //               // 진행을 완료하였으면 10점 //
  //               // successScore += 10;
  //               successScore += 50000000;
  //               // 진행한 라운드 가산점 ( 운동 성공 => 전체 라운드 * 운동 개수 ) //
  //               round = useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].round * useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].exerciseList.length;
  //             }
  //             // 운동이 중간에 종료된 경우 //
  //             else {
  //               // 마지막 진행한 운동 찾기 //
  //               let division = 'Buyin';
  //               let index = 0;

  //               // 마지막 운동 찾기 //
  //               for (let z = 0; z < useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[h].exerciseList.length; z++) {
  //                 if (useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[h].lastExerciseId === useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[h].exerciseList[z].exerciseId) {
  //                   division = useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[h].exerciseList[z].division;
  //                   index = useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[h].exerciseList[z].exerciseIndex;
  //                 }
  //               }

  //               // 진행한 라운드 가산점 ( 운동 중도 포기 => 완벽히 이행한 라운드 * 운동 개수 + 마지막 운동 개수) //
  //               round = useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[h].completeRound * useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].exerciseList.length;
                
  //               // console.log('i : ' + useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[h].userName);
  //               // console.log('round : ' + round);
  //               // 이행하지 못하고 남은 라운드 //
  //               let remainingRound = useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].round - useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[h].completeRound;

  //               // Buyin에서 멈췄을 경우 //
  //               if (division === 'Buyin') {
  //                 round += (remainingRound + ((index * 0.1) * useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[h].lastRound)) * 0.3
  //               }
  //               // Main에서 멈췄을 경우 //
  //               else if (division === 'main') {
  //                 round += (remainingRound + ((index * 0.1) * useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[h].lastRound)) * 0.6
  //               }
  //               // Buyout에서 멈췄을 경우 //
  //               else if (division === 'Buyout') {
  //                 round += (remainingRound + ((index * 0.1) * useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[h].lastRound)) * 0.9
  //               }
  //             }
  //           // }

  //           // 이미 저장된 내역 확인 Flag //
  //           let isExist = false;
  //           // 이미 저장된 유저 점수가 있는지 확인 //
  //           for (let x = 0; x < userRankingList.length; x++) {
  //             // 이미 점수가 있으면 기존점수에 + 하기 //
  //             if (userRankingList[x].linkCoachId === useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[h].linkCoachId) {
  //               // userRankingList[x].round += (round * 1000000);
  //               // userRankingList[x].roundOfCount += (roundOfCount * 1000000);
  //               // userRankingList[x].weight += (weight * 100000)
  //               // userRankingList[x].reps += reps * 10000;
  //               // userRankingList[x].calories = calories * 1000;
  //               // userRankingList[x].distance = distance * 100;
  //               // userRankingList[x].height = height * 10;
  //               // userRankingList[x].timeRemaining += timeRemaining * 1;
  //               // userRankingList[x].recordTime = time;
  //               // userRankingList[x].successScore += successScore;
  //               // userRankingList[x].lastRound = lastRound;
  //               // userRankingList[x].lastMovementBonus = lastMovementBonus;
  //               // userRankingList[x].lastReps = lastReps;

  //               // userRankingList[x].totalScore += ((round * 10000000) + (roundOfCount * 1000000) + (weight * 100000) + (reps * 10000) + (calories * 1000) + (distance * 100) + (height * 10) + timeRemaining * 1 + successScore + lastRound + (lastMovementBonus * lastReps)) - time;
  //               // // 저장 플래그 //
  //               // isExist = true;

  //               if (useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[h].success === 'Success') {
  //                 userRankingList[x].round += (round * 10000000);
  //                 userRankingList[x].roundOfCount += (roundOfCount * 1000000);
  //                 userRankingList[x].weight += (weight * 100000)
  //                 userRankingList[x].reps += reps * 10000;
  //                 userRankingList[x].calories = calories * 1000;
  //                 userRankingList[x].distance = distance * 100;
  //                 userRankingList[x].height = height * 10;
  //                 userRankingList[x].timeRemaining += timeRemaining * 1;
  //                 userRankingList[x].recordTime = time;
  //                 userRankingList[x].successScore += successScore;
  //                 userRankingList[x].lastRound = lastRound;
  //                 userRankingList[x].lastMovementBonus = lastMovementBonus;
  //                 userRankingList[x].lastReps = lastReps;
  
  //                 userRankingList[x].totalScore += ((round * 10000000) + (roundOfCount * 1000000) + (weight * 100000) + (reps * 10000) + (calories * 1000) + (distance * 100) + (height * 10) + timeRemaining * 1 + successScore + lastRound + (lastMovementBonus + lastReps)) - time;
  //                 // 저장 플래그 //
  //                 isExist = true;
  //               }
  //               else if (useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[h].success === 'Not Success') {
  //                 // userRankingList[x].round += (round * 7);
  //                 // userRankingList[x].roundOfCount += (roundOfCount * 6);
  //                 // userRankingList[x].weight += (weight * 5)
  //                 // userRankingList[x].reps += reps * 4;
  //                 // userRankingList[x].calories = calories * 3;
  //                 // userRankingList[x].distance = distance * 2;
  //                 // userRankingList[x].height = height;
  //                 // userRankingList[x].timeRemaining += timeRemaining * 1;
  //                 // userRankingList[x].recordTime = time;
  //                 // userRankingList[x].successScore += successScore;
  //                 // userRankingList[x].lastRound = lastRound;
  //                 // userRankingList[x].lastMovementBonus = lastMovementBonus;
  //                 // userRankingList[x].lastReps = lastReps;
  
  //                 // userRankingList[x].totalScore += ((round * 7) + (roundOfCount * 6) + (weight * 5) + (reps * 4) + (calories * 3) + (distance * 2) + (height) + timeRemaining * 1 + successScore + lastRound + (lastMovementBonus * lastReps)) - time;
  //                 userRankingList[x].round += (round * 1000000);
  //                 userRankingList[x].roundOfCount += (roundOfCount * 100000);
  //                 userRankingList[x].weight += (weight * 10000)
  //                 userRankingList[x].reps += reps * 1000;
  //                 userRankingList[x].calories = calories * 100;
  //                 userRankingList[x].distance = distance * 10;
  //                 userRankingList[x].height = height;
  //                 userRankingList[x].timeRemaining += timeRemaining * 1;
  //                 userRankingList[x].recordTime = time;
  //                 userRankingList[x].successScore += successScore;
  //                 userRankingList[x].lastRound = lastRound;
  //                 userRankingList[x].lastMovementBonus = lastMovementBonus;
  //                 userRankingList[x].lastReps = lastReps;
  
  //                 userRankingList[x].totalScore += ((round * 100000) + (roundOfCount * 100000) + (weight * 10000) + (reps * 1000) + (calories * 100) + (distance * 10) + (height) + timeRemaining * 1 + successScore + lastRound + (lastMovementBonus + lastReps)) - time;
  //                 // 저장 플래그 //
  //                 isExist = true;
  //               }
  //               else if (useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[h].success === 'Not Done') {
  //                 userRankingList[x].round += 0;
  //                 userRankingList[x].roundOfCount += 0;
  //                 userRankingList[x].weight += 0;
  //                 userRankingList[x].reps += 0;
  //                 userRankingList[x].calories = 0;
  //                 userRankingList[x].distance = 0;
  //                 userRankingList[x].height = 0;
  //                 userRankingList[x].timeRemaining += 0;
  //                 userRankingList[x].recordTime = 0;
  //                 userRankingList[x].successScore += 0;
  //                 userRankingList[x].lastRound = 0;
  //                 userRankingList[x].lastMovementBonus = 0;
  //                 userRankingList[x].lastReps = 0;
  
  //                 userRankingList[x].totalScore += 0;
  //               }
  //             }
  //           }

  //           // 저장된게 없으면 //
  //           if (!isExist) {

  //             let rankingInfo = {
  //               linkCoachId: useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[h].linkCoachId,
  //               userName: useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[h].userName,
  //               userProfile: useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[h].userProfile,
  //               createdDate: useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[h].createdDate,
  //               round: (round * 10000000),
  //               roundOfCount: (roundOfCount * 1000000),
  //               weight: (weight * 100000),
  //               reps: reps * 10000,
  //               calories: calories * 1000,
  //               distance: distance * 100,
  //               height: height * 10,
  //               timeRemaining: timeRemaining * 1,
  //               recordTime: time,
  //               successScore: successScore,
  //               lastRound: lastRound,
  //               lastMovementBonus: lastMovementBonus,
  //               lastReps: lastReps,
  //               totalScore: ((round * 10000000) + (roundOfCount * 1000000) + (weight * 100000) + (reps * 10000) + (calories * 1000) + (distance * 100) + (height * 10) + timeRemaining + successScore + lastRound + (lastMovementBonus + lastReps)) - time
  //             }

  //             // let rankingInfo = {
  //             //   linkCoachId: useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[h].linkCoachId,
  //             //   userName: useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[h].userName,
  //             //   userProfile: useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[h].userProfile,
  //             //   createdDate: useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[h].createdDate,
  //             //   round: 0,
  //             //   roundOfCount: 0,
  //             //   weight: 0,
  //             //   reps: 0,
  //             //   calories: 0,
  //             //   distance: 0,
  //             //   height: 0,
  //             //   timeRemaining: 0,
  //             //   recordTime: time,
  //             //   successScore: successScore,
  //             //   lastRound: lastRound,
  //             //   lastMovementBonus: lastMovementBonus,
  //             //   lastReps: lastReps,
  //             //   totalScore: 0
  //             // }
              
  //             if (useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[h].success === 'Success') {

  //               rankingInfo = {
  //                 linkCoachId: useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[h].linkCoachId,
  //                 userName: useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[h].userName,
  //                 userProfile: useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[h].userProfile,
  //                 createdDate: useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[h].createdDate,
  //                 round: (round * 10000000),
  //                 roundOfCount: (roundOfCount * 1000000),
  //                 weight: (weight * 100000),
  //                 reps: reps * 10000,
  //                 calories: calories * 1000,
  //                 distance: distance * 100,
  //                 height: height * 10,
  //                 timeRemaining: timeRemaining * 1,
  //                 recordTime: time,
  //                 successScore: successScore,
  //                 lastRound: lastRound,
  //                 lastMovementBonus: lastMovementBonus,
  //                 lastReps: lastReps,
  //                 totalScore: ((round * 10000000) + (roundOfCount * 1000000) + (weight * 100000) + (reps * 10000) + (calories * 1000) + (distance * 100) + (height * 10) + timeRemaining + successScore + lastRound + (lastMovementBonus + lastReps)) - time
  //               }
  //             }
  //             else if (useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[h].success === 'Not Success') {
               
  //               // rankingInfo = {
  //               //   linkCoachId: useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[h].linkCoachId,
  //               //   userName: useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[h].userName,
  //               //   userProfile: useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[h].userProfile,
  //               //   createdDate: useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[h].createdDate,
  //               //   round: (round * 7),
  //               //   roundOfCount: (roundOfCount * 6),
  //               //   weight: (weight * 5),
  //               //   reps: reps * 4,
  //               //   calories: calories * 3,
  //               //   distance: distance * 2,
  //               //   height: height,
  //               //   timeRemaining: timeRemaining * 1,
  //               //   recordTime: time,
  //               //   successScore: successScore,
  //               //   lastRound: lastRound,
  //               //   lastMovementBonus: lastMovementBonus,
  //               //   lastReps: lastReps,
  //               //   totalScore: ((round * 7) + (roundOfCount * 6) + (weight * 5) + (reps * 4) + (calories * 3) + (distance * 2) + (height) + timeRemaining + successScore + lastRound + (lastMovementBonus * lastReps)) - time
  //               // }
  //               rankingInfo = {
  //                 linkCoachId: useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[h].linkCoachId,
  //                 userName: useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[h].userName,
  //                 userProfile: useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[h].userProfile,
  //                 createdDate: useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[h].createdDate,
  //                 round: (round * 1000000),
  //                 roundOfCount: (roundOfCount * 100000),
  //                 weight: (weight * 10000),
  //                 reps: reps * 1000,
  //                 calories: calories * 100,
  //                 distance: distance * 10,
  //                 height: height,
  //                 timeRemaining: timeRemaining,
  //                 recordTime: time,
  //                 successScore: successScore,
  //                 lastRound: lastRound,
  //                 lastMovementBonus: lastMovementBonus,
  //                 lastReps: lastReps,
  //                 totalScore: ((round * 1000000) + (roundOfCount * 100000) + (weight * 10000) + (reps * 1000) + (calories * 100) + (distance * 10) + (height) + timeRemaining + successScore + lastRound + (lastMovementBonus + lastReps)) - time
  //               }
  //             }
  //             else if (useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[h].success === 'Not Done') {
  //               rankingInfo = {
  //                 linkCoachId: useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[h].linkCoachId,
  //                 userName: useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[h].userName,
  //                 userProfile: useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[h].userProfile,
  //                 createdDate: useData[i].workouts[clickWorkoutIndex].sectionList[nowSection].sectionRecordList[h].createdDate,
  //                 round: 0,
  //                 roundOfCount: 0,
  //                 weight: 0,
  //                 reps: 0,
  //                 calories: 0,
  //                 distance: 0,
  //                 height: 0,
  //                 timeRemaining: 0,
  //                 recordTime: 0,
  //                 successScore: 0,
  //                 lastRound: 0,
  //                 lastMovementBonus: 0,
  //                 lastReps: 0,
  //                 totalScore: 0
  //               }
  //             }

  //             // console.log(rankingInfo);
  //             userRankingList.push(rankingInfo);
  //           }
  //         }
  //       }
  //     }
  //   }

  //   // userRankingList.sort(function (a, b) {
  //   //   return a.totalScore > b.totalScore ? -1 : a.totalScore < b.totalScore ? 1 : 0;
  //   // })

  //   userRankingList.sort((a, b) => b.totalScore - a.totalScore || moment(a.createdDate).isBefore(b.createdDate));

  //   for(let i = 0; i < userRankingList.length; i++) {
  //     // console.log(userRankingList[i].totalScore);
  //     if (i !== 0) {
  //       if (userRankingList[i].totalScore === userRankingList[i - 1].totalScore) userRankingList[i].rank = userRankingList[i - 1].rank;
  //       else userRankingList[i].rank = i + 1;
  //     }
  //     else userRankingList[i].rank = i + 1;
  //   }
  //   // console.log(userRankingList);
  //   setRankingList(userRankingList);
  // }, [useData, clickDate, nowSection, clickWorkoutIndex])

  // // 저장 모달에 띄울 수업 이름 저장 //
  // useEffect(() => {
  //   // setLessonName(ClassSelectRef.current.getValue().className);
  //   // setWorkoutId(ExerciseRef.current.getWorkoutId());
  // }, [isViewConfirm, ClassSelectRef, ExerciseRef])

  // 몇주차인지 계산하기 //
  function getWeekNumber(date) {
    const standard = new Date(date);

    const currentDate = standard.getDate();
    const startOfMonth = new Date(standard.setDate(1));
    const weekDay = startOfMonth.getDay();

    return parseInt(((weekDay - 1) + currentDate) / 7) + 1;
  }

  // 스케줄 변경 가능여부 확인 //
  function checkAvailableChangeScheduleData() {
    if (isClickedModify) {
      let exerciseData = ExerciseRef.current.getValue();

      if (exerciseData.length === 0) {
        setIsClickedModify(false);
        setIsClickedDetail(false);
        return true;
      }
      else return false;
    }
    else {
      setIsClickedDetail(false);
      return true;
    }
  }

  // 일정표내의 날짜 클릭시 //
  function dateClick(value, index) {
    setIsClickedDetail(false);

    setClickDate(value.classDate.format('YYYY.MM.DD'));
    console.log(value);
    console.log(index);
    console.log(value.workouts[index]);

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getDataOfExerciseVersion2('ranking/' + value.workouts[index].workoutId, 'GET');

        console.log(responseData);
      }
    )();
    // ScheduleRef.current.setValue(value.classDate.format('YYYY.MM.DD'));
  }

  // 월 단위 날짜 변경 클릭시 //
  function onClickDateArrow(division) {
    setIsClickedDetail(false);
    if (isClickedModify) setIsViewConfirm(true);
    else {
      if (division === 'Prev') setDate(date.clone().subtract(1, 'month'));
      else if (division === 'Next') setDate(date.clone().add(1, 'month'));
    }
  }

  // 자세히 보기 클릭시 //
  function onClickDetailView(data) {
    setNowClickLinkCoachId(data.linkCoachId);
  }

  // 스크롤 애니메이션를 위한 UI제어 //
  function changeTopLevelWrapperPosition() {
    if (TopLevelRef.current.style.position === 'fixed') {
      TopLevelRef.current.style.position = 'relative';
    }
    else {
      TopLevelRef.current.style.position = 'fixed';
      TopLevelRef.current.style.top = '0px';
    }
  }

  return (
    <TopLevelWrapper ref={TopLevelRef}>
      <TopBarWrapper>
        <TextWrapper>
          <Title>순위표</Title>
          <Explanation>운동 프로그램 별 수행 순위를 확인할 수 있어요</Explanation>
        </TextWrapper>
      </TopBarWrapper>
      <TopButtonWrapper>
        <LeftWrapper>
          <DateButtonWrapper>
            <ArrowWrapper onClick={() => onClickDateArrow('Prev')}>
              <ArrowImage src={LeftArrow} />
            </ArrowWrapper>
            <DateText>{date.clone().format('YYYY년 MM월')}</DateText>
            <ArrowWrapper onClick={() => onClickDateArrow('Next')} >
              <ArrowImage src={RightArrow} />
            </ArrowWrapper>
          </DateButtonWrapper>
        </LeftWrapper>
      </TopButtonWrapper>
      <MainView>
        <LeftComponent>
          <RankingScheduleBox
            ref={ScheduleRef}
            clickDate={clickDate}
            useData={useData}
            dateClickFn={dateClick}
            clickWorkoutIndex={clickWorkoutIndex}
            setClickWorkoutIndex={setClickWorkoutIndex}
            changeTopLevelWrapperPosition={changeTopLevelWrapperPosition} />
        </LeftComponent>
        {/* {
          isClickedDetail ?
            <RankingExerciseBox
              clickDate={clickDate}
              useData={useData}
              rankingList={rankingList}
              nowSection={nowSection}
              setNowSection={setNowSection}
              setIsClickedDetail={setIsClickedDetail}
              nowClickLinkCoachId={nowClickLinkCoachId}
              clickWorkoutIndex={clickWorkoutIndex}
              changeTopLevelWrapperPosition={changeTopLevelWrapperPosition} />
            :
            <RankingBox
              useData={useData}
              clickDate={clickDate}
              rankingList={rankingList}
              setRankingList={setRankingList}
              isClickedDetail={isClickedDetail}
              setIsClickedDetail={setIsClickedDetail}
              nowSection={nowSection}
              setNowSection={setNowSection}
              clickWorkoutIndex={clickWorkoutIndex}
              onClickDetailView={onClickDetailView} />
        } */}
      </MainView>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  // margin-left: 34px;
  margin-left: 42px;

  // background-color: red;
  transition: all 0.3s ease-in-out;
`;

const TopBarWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-left: 28px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: 0 0 0 0;
`;

const Explanation = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #777777;

  margin: 0 0 0 0;
`;

const TopButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;

  margin-top: 38px;
`;

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 30px;
`;

const DateButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 205px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;

  gap: 37px;
`;

const ArrowWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 22px;
  height: 22px;

  :hover { 
    cursor: pointer;
  }
`;

const ArrowImage = styled.img`
  display: flex;
  flex-direction: row;

  width: 9px;
  height: 15px;
`;

const DateText = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #595959;

  margin: 0 0 0 0;
`;

const MainView = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: flex-start;

  gap: 30px;
  margin-top: 16px;
  margin-bottom: 255px;
`;

const LeftComponent = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  gap: 30px;
`;

const ScheduleSelectBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 408px;

  gap: 16px;
`;