import { React, useRef, useState, useEffect } from "react";
import styled, { keyframes }  from "styled-components";
import { useHistory, useLocation } from 'react-router-dom';
import moment from "moment";

import FetchModule from '../../../../Share/Network/FetchModule.js';
import CalenderInput from '../../../../../Component/LockerComponent/LockerCalenderInput';
import CountDaysInput from "../../../../../Component/LockerComponent/CountDaysInput.js";
import LockerSelectBox from "../../../../../Component/MemberComponent/LockerSelectBox.js";
import UnitInput from "../../../../../Component/LockerComponent/UnitInput.js";


export default function LockerUsageAddModal(props) {
  const { memberName, lockerRendering, onClickExit } = props;

  const history = useHistory();
  const location = useLocation();

  const [memberId, setMemberId] = useState(0);
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [dayCount, setDayCount] = useState(endDate.diff(startDate, 'days'));
  const [cardPrice, setCardPrice] = useState(0);
  const [cashPrice, setCashPrice] = useState(0);
  const [option, setOption] = useState([]);
  const [selectOption, setSelectOption] = useState(undefined);

  const StartCalenderRef = useRef();
  const EndCalenderRef = useRef();

  // Scroll Fixed //
  useEffect(() => {
    document.body.style= `overflow: hidden`;
    return () => document.body.style = `overflow: auto`;
  }, [])

  // MemberId Setting //
  useEffect(() => {
    setMemberId(parseInt(location.pathname.split('/')[4]));
  }, [location])

  // Locker Data API Call //
  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postData('locker/available', 'POST');
        
        if (responseData.status === 200) {
          setOption(responseData.data);
        }
        else if (responseData.status === 401) {
          alert('로그인 후 다시시도하세요');
          history.push('/');
        }
      }
    )();
  }, [])

  // EndDate Reset //
  useEffect(() => {
    if (startDate > endDate) {
      alert("사용 종료일이 시작일보다 빠르게 설정할 수 없습니다.");
      setEndDate(startDate);
      return;
    }

    setDayCount(moment(endDate.format('YYYY-MM-DD 23:59:59')).diff(startDate, 'days') + 1);
  }, [startDate, endDate])

  function CalenderClick(className) {
    if (className === 'StartCalender') EndCalenderRef.current.closeCalender();
    else StartCalenderRef.current.closeCalender();
  }

  // 적용일수 변환시 //
  function changeDayCount(value) {
    if (value > 0) setEndDate(startDate.clone().add(value - 1, 'days'));
    else if (value === 0) setEndDate(startDate);

    setDayCount(value);
  }

  // 저장하기 버튼 클릭시 //
  function onClickSave() {
    
    if (selectOption === undefined) {
      alert('사용을 시작할 락커를 설정해주세요!');
      return;
    }
    var storeId = parseInt(window.localStorage.getItem('StoreId'));
    var lockerId = selectOption.id;
    var approvalDate = moment().format('YYYY-MM-DDTHH:mm:ss');
    var requestStartDate = startDate === '' ? '' : startDate.format('YYYY-MM-DD') + 'T' + approvalDate.split('T')[1];
    var requestEndDate = endDate === '' ? '' : endDate.format('YYYY-MM-DD') + 'T23:59:59';
    let card = parseInt(cardPrice === '' ? 0 : cardPrice.toString().replaceAll(',', ''));
    let cash = parseInt(cashPrice === '' ? 0 : cashPrice.toString().replaceAll(',', ''));

    if (startDate === '') {
      alert('시작일을 선택해 주세요');
      // setText('시작일을 선택해 주세요');
      // setIsViewCustomAlert(true);
      return;
    }
    else if (endDate === '') {
      alert('종료일을 선택해 주세요');
      // setText('종료일을 선택해 주세요');
      // setIsViewCustomAlert(true);
      return;
    }
    else if (memberId === 0 || memberId === undefined) {
      alert('사용할 회원을 선택해주세요');
      // setText('사용할 회원을 선택해주세요');
      // setIsViewCustomAlert(true);
      return;
    }

    var data = {
      storeId: storeId,
      lockerId: lockerId,
      memberId: memberId,
      startDate: requestStartDate,
      endDate: requestEndDate,
      approvalDate: approvalDate,
      cardPrice: card,
      cashPrice: cash
    } 
    
    const fetchModule = new FetchModule();
		(
			async () => {
				const responseData = await fetchModule.postDataVersion2('locker/start', 'POST', data);
        
				if (responseData.status === 200) {
          alert("락커 등록이 완료되었습니다!");
          lockerRendering();
          onClickExit();
				}
        else if (responseData.status === 204) {
          if (responseData.error === 'Locker Already Using') {
            alert("이미 사용중인 락커입니다.");
            // setText('이미 사용중인 락커입니다.');
            // setIsViewCustomAlert(true);
            return;
          }
          else if (responseData.error === 'Invalid Using') {
            alert("사용이 불가능한 락커입니다.");
            // setText('사용이 불가능한 락커입니다.');
            // setIsViewCustomAlert(true);
            return;
          }
          else if (responseData.error === 'Invalid User Data') {
            alert("올바르지 않은 회원 정보입니다.");
            // setText('올바르지 않은 회원 정보입니다.');
            // setIsViewCustomAlert(true);
            return;
          }
        }
				else if (responseData.status === 401) {
          alert("로그인 후 사용하세요");
          history.push('/');
        }
			}
		)();
  }
  
  return (
    <TopLevelWrapper>
      <ModalView>
        <InnerWrapper>
          <TitleWrapper>
            <Title>락커 추가</Title>
          </TitleWrapper>
          <InputWrapper>
            <IndividualWrapper>
              <Text>락커 선택</Text>
              <LockerSelectBox options={option} changeFn={setSelectOption}/>
            </IndividualWrapper>
            <IndividualWrapper>
              <Text>회원 이름</Text>
              <NameBox>
                <Name>{memberName}</Name>
              </NameBox>
            </IndividualWrapper>
            <IndividualWrapper>
              <Text>시작일</Text>
              {/* <CalenderInput ref={StartCalenderRef} value={startDate} setValue={setStartDate} disabled={false} className='StartCalender' clickFn={CalenderClick} placeholder='시작일' width={209} /> */}
              <CalenderInput ref={StartCalenderRef} value={startDate} setValue={setStartDate} disabled={false} className='StartCalender' clickFn={CalenderClick} placeholder='시작일' width={183} />
            </IndividualWrapper>
            <IndividualWrapper>
              <Text>종료일</Text>
              {/* <CalenderInput ref={EndCalenderRef} value={endDate} setValue={setEndDate} disabled={false} className='EndCalender' clickFn={CalenderClick} placeholder='종료일' width={209} /> */}
              <CalenderInput ref={EndCalenderRef} value={endDate} setValue={setEndDate} disabled={false} className='EndCalender' clickFn={CalenderClick} placeholder='종료일' width={183} />
            </IndividualWrapper>
            <IndividualWrapper>
              <Text>적용 일수</Text>
              {/* <CountDaysInput value={dayCount} setValue={changeDayCount} /> */}
              <UnitInput value={dayCount} setValue={changeDayCount} unit='일'/>
            </IndividualWrapper>
            <IndividualWrapper>
              <Text>카드</Text>
              <UnitInput value={cardPrice} setValue={setCardPrice} unit='원'/>
            </IndividualWrapper>
            <IndividualWrapper>
              <Text>현금</Text>
              <UnitInput value={cashPrice} setValue={setCashPrice} unit='원'/>
            </IndividualWrapper>
          </InputWrapper>
          <ButtonWrapper>
            <ExitButton onClick={onClickExit}>취소</ExitButton>
            <SaveButton onClick={onClickSave}>저장하기</SaveButton>
          </ButtonWrapper>
        </InnerWrapper>
      </ModalView>
    </TopLevelWrapper>
  )
}

const startAnimation = keyframes`
	0% { opacity: 0; }
	50% { opacity: 0.5; }
	100% { opacity: 1; }
`;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  justify-content: center;
  align-items: center;

  z-index: 1000;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.2);
	
	animation-name: ${startAnimation};
	animation-duration: 0.5s;
	animation-timing-function: ease-out;
`;

const ModalView = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 371px;
  width: 323px;
  // height: 337px;
  height: 456px;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(0, 0, 0, 0.1);

  background-color: #FFFFFF;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 84.9%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: 0 0 0 0;
`;

const NameBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  padding: 0px 8px 0px 8px;

  width: 167px;
  height: 32px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background: #F4F4F4;
`;

const Name = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  // margin-top: 24px;
  margin-top: 32px;

  width: 100%;

  gap: 16px;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 72px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;
  
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #777777;
    color: #777777;
  }
`;

const SaveButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 15px;
  background-color: #5BD98A;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  gap: 13px;

  width: 100%;

  margin-top: 15px;
`;

const IndividualWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const Text = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;

  margin: 0 0 0 0;
`;