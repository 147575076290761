import { React, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import FetchModule from '../Network/FetchModule';
import StoreSearchInput from '../../../Component/Normal/StoreSearchInput';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function AdminLoginChangeModal(props) {
  const { close } = props;

  const [stores, setStores] = useState([]);
  const [useStores, setUseStores] = useState([]);
  const [value, setValue] = useState('');

  const TopLevelRef = useRef();

  const history = useHistory();

  // Modal 창 스크롤 제어 //
  useEffect(() => {
    document.body.style = `overflow: hidden`;
    return () => document.body.style = `overflow: auto`;
  }, [])

  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getDataVersion2('store/all_list', 'GET');

        // console.log(responseData);
        if (responseData.status === 200) {
          setStores(responseData.data);
        }
        // else if (responseData.status === 401) history.push('/');
      }
    )();
  }, [])

  
  // 검색 기능 포함 ( 한글 검색 알고리즘 최신화 ) //
  useEffect(() => {
    if (value === '') {
      setUseStores(stores);
      return;
    }
    
    let storeNameList = [];
    let ownerNameList = [];
    let phoneList = [];

    for (let i = 0; i < stores.length; i++) {
      if (stores[i].storeName.toLowerCase().includes(value.toLowerCase())) storeNameList.push(stores[i]);
      else if (stores[i].ownerName.toLowerCase().includes(value.toLowerCase())) ownerNameList.push(stores[i]);
      else if (stores[i].phone.includes(value)) phoneList.push(stores[i]);
    }

    if (ownerNameList.length === 0 && storeNameList === 0 && phoneList.length === 0) {
      for (let i = 0; i < stores.length; i++) {
        if (match(value.toLowerCase(), stores[i].storeName.toLowerCase())) storeNameList.push(stores[i]);
        else if (match(value.toLowerCase(), stores[i].ownerName.toLowerCase())) ownerNameList.push(stores[i]);
      }
    }

    storeNameList.concat(ownerNameList);

    let totalList = storeNameList.concat(phoneList);
    
    setUseStores(totalList);
  }, [stores, value, match()])

  // 입력된 글자가 한글인지 확인 //
  function is_kr(value) {
    if (value === undefined) return false;
    
    return ((('ㄱ'.charCodeAt() <= value.charCodeAt()) && (value.charCodeAt() <= 'ㅎ'.charCodeAt()))
            || (('가'.charCodeAt() <= value.charCodeAt()) && value.charCodeAt() <= '힣'.charCodeAt()));
  }

  // 한글 글자 유니코드 추출하여 검색 조건 확인 //
  function kr_num(value) {
    return value.charCodeAt() - '가'.charCodeAt();
  }

  // 초성 중성 종성 구분하여 연산 //
  function kr_list(value) {
    let consonant_list = ['ㄱ', 'ㄲ', 'ㄴ', 'ㄷ', 'ㄸ', 'ㄹ', 'ㅁ', 'ㅂ', 'ㅃ', 'ㅅ'
                        , 'ㅆ', 'ㅇ', 'ㅈ', 'ㅉ', 'ㅊ', 'ㅋ', 'ㅌ', 'ㅍ', 'ㅎ'];

    let res = [value];

    let kr_num_s = kr_num(value);

    if (kr_num_s > 0) {
      // 초성 + 중성일 경우 //
      if (kr_num_s % 28 !== 0) {
        res.push(String.fromCharCode(((parseInt(kr_num_s / 28) * 28) + '가'.charCodeAt())));
      }
      
      res.push(consonant_list[parseInt(kr_num_s / 588)])
    }

    return res;
  }

  // 한글, 영어 구분하여 검색 처리 //
  function eq_kr(value, data) {
    if (is_kr(value) && is_kr(data)) {
      return kr_list(data).includes(value);
    }
    else return value === data;
  }

  // 받침 확인하기 //
  function eq_kr_pos(value, data, nextData) {
    let consonant_list = ['ㄱ', 'ㄲ', 'ㄴ', 'ㄷ', 'ㄸ', 'ㄹ', 'ㅁ', 'ㅂ', 'ㅃ', 'ㅅ'
                        , 'ㅆ', 'ㅇ', 'ㅈ', 'ㅉ', 'ㅊ', 'ㅋ', 'ㅌ', 'ㅍ', 'ㅎ'];

    let kr_pos = [
      [0, 0], [1, 0], [2, 1], [2, 9], [4, 2], [1, 12], [2, 18],
      [7, 3], [8, 5], [1, 0], [2, 6], [3, 7], [4, 9], [5, 16],
      [6, 17], [7, 18], [16, 6], [17, 7], [1, 9], [19, 9], [20, 10],
      [21, 11], [22, 12], [23, 14], [24, 15], [25, 16], [26, 17], [27, 18]
    ]

    if (!(is_kr(value) && is_kr(data) && is_kr(nextData))) return false;
    if (kr_num(value) < 0) return false;
    
    let s_pos = String.fromCharCode(value.charCodeAt() - kr_pos[kr_num(value) % 28][0])
    let s_pos_consonant = consonant_list[kr_pos[kr_num(value) % 28][1]]

    return (s_pos === data) && (kr_list(nextData).includes(s_pos_consonant));
  }

  // 일치하는 글자가 존재하는지 확인 //
  function match(value, data) {
    if (value === undefined || data === undefined) return false;

    let valueSize = value.length;
    let dataSize = data.length;

    if (dataSize < valueSize) return false;

    for (let i = 0; i < (dataSize - valueSize + 1); i++) {
      let res = true;

      for (let j = 0; j < valueSize; j++) {
        if (j === (valueSize - 1)) {
          if (!eq_kr(value[j], data[i + j])) {
            if (i + j + 1 < dataSize) {
              if (eq_kr_pos(value[j], data[i + j], data[i + j + 1])) {
                break;
              }
            }
            res = false;
            break;
          }
        }
        else {
          if (value[j] !== data[i + j]) {
            res = false;
            break;
          }
        }
      }

      if (res) return true;
    }
    
    return false;
  }

  // 로그인 변경할 업체 클릭시 //
  function onClickStoreInfo(data) {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getDataVersion2('store/change/auth/' + data.storeId, 'GET');

        if (responseData.status === 200) {
					window.localStorage.setItem('Token', 'Bearer ' + responseData.data.accessToken);
					window.localStorage.setItem('RefreshToken', 'Bearer ' + responseData.data.refreshToken);
					window.localStorage.setItem('StoreId', responseData.data.storeId);
          window.location.reload();
        }
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }

	return (
    <Background>
      <TopLevelWrapper ref={TopLevelRef}>
        <ExplanationWrapper>
          <KorExplanation>Link Zone 관리자 업체수 - {stores.length} 개</KorExplanation>
        </ExplanationWrapper>
        <SearchInputWrapper>
          <StoreSearchInput value={value} setValue={setValue}/>
        </SearchInputWrapper>
        <ScrollView>
          {
            useStores.map((data, index) => (
              <StoreInfoBox key={index} onClick={() => onClickStoreInfo(data)}>
                <StoreInfoWrapper>
                  <LogoWrapper>
                    <Logo src={data.storeLogo}/>
                  </LogoWrapper>
                  <ColumnTextWrapper>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>{data.storeName}</Text>
                    <RowTextWrapper>
                      <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#3F3F3F'>{data.ownerName}</Text>
                      <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#3F3F3F'>{data.phone}</Text>
                    </RowTextWrapper>
                  </ColumnTextWrapper>
                </StoreInfoWrapper>
              </StoreInfoBox>
            ))
          }
        </ScrollView>
        <ButtonWrapper>
          <YesButton onClick={close}>
            <ButtonText>닫기</ButtonText>
          </YesButton>
        </ButtonWrapper>
      </TopLevelWrapper>
    </Background>
  )
}

const Background = styled.div`
  position: fixed;
	display: flex;
	flex-direction: column;

  justify-content: center;
  align-items: center;

  top: 0;
  left: 0;

  z-index: 500;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.5);
`;

const TopLevelWrapper = styled.div`
	display: flex;
	flex-direction: column;

  justify-content: center;
  align-items: center;

	width: 500px;
	height: 700px;
  
	border-radius: 50px;
  background-color: #FFFFFF;

  transition: all 0.5s ease-in-out;
`;

const ExplanationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  justify-content: center;
  align-items: flex-start;

  width: 90%;
  // height: 100px;
`;

const SearchInputWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 16px;

  width: 90.33%;
`;

const ScrollView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 24px;

  width: 90.33%;
  // height: 500px;
  height: 460px;

  gap: 12px;

  overflow-y: scroll;
  overflow-x: none;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
		border: 3px solid transparent;
		background-color: #DADADA;
		border-radius: 200px;
	}

  &::-webkit-scrollbar-track {
		background-color: #F0F0F0;
		border-radius: 200px;
	}
`;

const StoreInfoBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 97%;
  min-height: 80px;
  max-height: 80px;

  border-radius: 16px;
  // background-color: #DBDBDB;
  border: 1px solid #DFDFDF;
  background-color: #FFFFFF;

  :hover {
    cursor: pointer;
  }
`;

const StoreInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 95%;
  // height: 80%;

  // background-color: red;
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 70px;
  height: 70px;

  border-radius: 12px;

  overflow: hidden;
`;

const Logo = styled.img`
  width: 100%;
  height 100%;
`;

const ColumnTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  gap: 8px;

  margin-left: 12px;
`;

const RowTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  margin: 0 0 0 0;

  color: ${props => props.color};
`;

const KorExplanation = styled.pre`
  font-family: NotoSansKR-Bold;
  font-size: 25px;

  margin: 0 0 0 0;

  color: #000000;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 14px;

  margin-top: 50px;
`;

const YesButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // width: 155px;
  width: 324px;
  height: 45px;

  border-radius: 23px;
  background-color: #FF8B48;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background: #FF7322;
  }
`;

const ButtonText = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 18px;
  
  color: #FFFFFF;

  margin: 0 0 0 0;
`;