import { React, useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import FetchModule from '../../Model/Share/Network/FetchModule';

import SelectOnPath from '../../image/SelectOn.png';
import SelectOffPath from '../../image/SelectOff.png';
import moment from 'moment';

export default function ScheduleSelectBox(props) {
  const { date, selectedLesson, defaultVal, setDefaultVal, checkAvailableChangeScheduleData } = props;

  const history = useHistory();

  const [isClicked, setIsClicked] = useState(false);
  const [options, setOptions] = useState([]);
  const [finalOptions, setFinalOptions] = useState([]);
  const [totalOptions, setTotalOptions] = useState([]);
  const [scheduleData, setScheduleData] = useState([]);
  const [isUndefinedDefaultVal, setIsUndefinedDefaultVal] = useState(false);
  // const [defaultVal, setDefaultVal] = useState({ id: -1, name: '선택' });

  const TopLevelRef = useRef();
  const SelectRef = useRef();

  // Schedule Data API호출
  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getData('schedule/', 'GET');
        // console.log(responseData.data);
        if (responseData.status === 200) setScheduleData(responseData.data);
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }, [history])
  // Schedule Data를 사용 가능한 Data로 가공
  useEffect(() => {
    var tmp = scheduleData.slice();
    var newScheduleData = [];

    for (var i = 0; i < tmp.length; i++) {
      var endPoint = moment(tmp[i].classDate.split('T')[0]);

      while (tmp[i].endDate.split('T')[0] > endPoint.format('YYYY-MM-DD')) {
        if (endPoint.diff(date, 'days') >= 31) break;

        // // 수강하는 요일에 맞게 거르기 //
        // if (tmp[i].applyWeek.includes(endPoint.format('ddd')) || tmp[i].applyWeek === 'All') {
        //   // 수강하는 주차에 맞게 거르기 //
        //   if (tmp[i].repeatWeek.includes(getWeekNumber(endPoint.format('YYYY-MM-DD')).toString()) || tmp[i].repeatWeek.includes('0')) {
            var colSpan = 1;
            var rowSpan = 1;

            // 세로부분 합칠 크기 지정 ( 시간에 맞게 계산 )
            colSpan = parseInt(tmp[i].classTime.split('-')[1].split(':')[0]) - parseInt(tmp[i].classTime.split('-')[0].split(':')[0]);

            var newData = {
              classId: tmp[i].classId,
              classDate: endPoint.format('YYYY.MM.DD'),
              className: tmp[i].className,
              classTime: tmp[i].classTime,
              color: tmp[i].color,
              endDate: tmp[i].endDate,
              lessonId: tmp[i].lessonId,
              scheduleId: tmp[i].scheduleId,
              teacherName: tmp[i].teacherName,
              applyWeek: tmp[i].applyWeek,
              repeatWeek: tmp[i].repeatWeek,
              rowSpan: rowSpan,
              colSpan: colSpan,
              maxBookedPeople: tmp[i].maxBookedPeople,
              deduction: tmp[i].deduction
            }

            
            if (endPoint.diff(date, 'days') >= 0) newScheduleData.push(newData);
        //   }
        // }
        endPoint = endPoint.clone().add(1, 'day');
      }
    }

    newScheduleData.sort(function (a, b) {
      return a.classDate < b.classDate ? -1 : a.classDate > b.classDate ? 1 : 0;
    })

    let tmpData = [];

    for (i = 0; i < newScheduleData.length; i++) {
      if (newScheduleData[i].classDate === date.clone().format('YYYY.MM.DD')) {
        tmpData.push(newScheduleData[i]);
      }
    }
    
    tmpData.sort(function (a, b) {
      return a.classTime < b.classTime ? -1 : a.classTime > b.classTime ? 1 : 0;
    })
    // console.log(newData);
    // console.log(scheduleData);
    // console.log(date.format('YYYY.MM.DD'));
    setTotalOptions(newScheduleData);
    setOptions(tmpData);
  }, [scheduleData, date])


  // 수업 변경시 마다 강제로 스케줄 데이터 조정 //
  useEffect(() => {
    setDefaultVal(undefined);
  }, [selectedLesson])

  // 선택된 수업에 맞게 스케줄 데이터 정리 //
  useEffect(() => {
    if (options.length === 0 || selectedLesson === undefined) return;

    let newOptions = [];

    for (let i = 0; i < options.length; i++) {
      if (options[i].classId === selectedLesson.id) newOptions.push(options[i]);
    }

    // if (newOptions.length > 0) {
    //   setDefaultVal(newOptions[0]);
    //   setIsUndefinedDefaultVal(false);
    // }
    // else {
    //   // for (let i = 0; i < totalOptions.length; i++) {
    //   //   if (totalOptions[i].classId === selectedLesson.id) {
    //   //     setDefaultVal(totalOptions[i]);
    //   //     setIsUndefinedDefaultVal(true);
    //   //     break;
    //   //   }
    //   // }

    //   setDefaultVal(undefined);
    // }
    // console.log(newOptions);
    if (newOptions.length > 0 && (defaultVal === undefined || defaultVal.classId !== newOptions[0].classId)) {
      setDefaultVal(newOptions[0]);
      // setIsUndefinedDefaultVal(false);
    }
    else if (newOptions.length === 0) {
      // setDefaultVal(undefined);
      // console.log('!!!');
      // console.log(defaultVal);
      setDefaultVal(undefined);
      // if (defaultVal === undefined) {
      //   for (let i = 0; i < totalOptions.length; i++) {
      //     if (totalOptions[i].classId === selectedLesson.id) {
      //       setDefaultVal(totalOptions[i]);
      //       break;
      //     }
      //   }
      // }
      // else setDefaultVal(undefined)
      // setIsUndefinedDefaultVal(true);
    }
    // else {
    //   if (newOptions[0].scheduleId === defaultVal.scheduleId) setIsUndefinedDefaultVal(false);
    //   else setIsUndefinedDefaultVal(true);
    //   // setIsUndefinedDefaultVal(false);
    // }

    // if (newOptions.length > 0) {
    //   setDefaultVal(newOptions[0]);
    //   setIsUndefinedDefaultVal(false);
    // }
    // else {
    //     // for (let i = 0; i < totalOptions.length; i++) {
    //     //   if (totalOptions[i].classId === selectedLesson.id) {
    //     //     setDefaultVal(totalOptions[i]);
    //     //     break;
    //     //   }
    //     // }
    //     setIsUndefinedDefaultVal(true);
    // }
    setFinalOptions(newOptions);
  }, [options, selectedLesson, defaultVal/*, totalOptions*/])

  // 컴포넌트 밖을 클릭하면 셀렉트 창 닫기
  useEffect(() => {
    document.addEventListener("mousedown", onClickOutSide);

    return () => {
      document.removeEventListener("mousedown", onClickOutSide);
    }
  }, [])

  // 컴포넌트 밖을 클릭하면 셀렉트 창 닫기
  function onClickOutSide(e) {
    if (TopLevelRef.current !== e.target && !TopLevelRef.current.contains(e.target)) {
      SelectRef.current.style.border = '1px solid #CBCBCB';
      SelectRef.current.style.height = `38px`;
      SelectRef.current.style.overflow = 'hidden';
      setIsClicked(false);
    }
  }

  // 몇주차인지 계산하기 //
  function getWeekNumber(date) {
    const standard = new Date(date);

    const currentDate = standard.getDate();
    const startOfMonth = new Date(standard.setDate(1));
    const weekDay = startOfMonth.getDay();

    return parseInt(((weekDay - 1) + currentDate) / 7) + 1;
  }

  function onClick() {
    if (defaultVal === undefined || finalOptions.length === 0 || finalOptions.length === 1) return;

    if (isClicked) {
      SelectRef.current.style.border = '1px solid #CBCBCB';
      SelectRef.current.style.height = `38px`;
      SelectRef.current.style.overflow = 'hidden';
      setIsClicked(false);
    }
    else {
      SelectRef.current.style.border = '1px solid #FF8B48';

      if (finalOptions.length === 1) SelectRef.current.style.height = `38px`;
      else SelectRef.current.style.height = (32 * (finalOptions.length)) > 500 ? `500px` : (32 * (finalOptions.length) + 'px');
      // if ((44 + (32 * (finalOptions.length))) > 500)  SelectRef.current.style.height = `500px`;
      // else SelectRef.current.style.height = (44 + (32 * (finalOptions.length)) + 'px');

      SelectRef.current.style.maxHeight = `500px`;
      SelectRef.current.style.overflowY = 'scroll';
      SelectRef.current.style.overflowX = 'none';
      setIsClicked(true);
    }
  }

  function optionalClick(index) {
    if (checkAvailableChangeScheduleData()) setDefaultVal(finalOptions[index]);
    else alert("디자인된 운동을 저장 후 실행해 주세요!");
  }

  return (
    <TopLevelWrapper ref={TopLevelRef}>
      <SelectBox
        ref={SelectRef}
        isClicked={isClicked}
        buttonPath={isClicked ? SelectOnPath : SelectOffPath}
        onClick={onClick}>
        {
          isClicked ?
            <TotalOptionWrapper>
              {/* <OptionWrapper>
                <Option>{(isUndefinedDefaultVal || defaultVal === undefined) ? `수업 일정이 없습니다` : defaultVal.classTime}</Option>
              </OptionWrapper>
              <DivisionLine/> */}
              {
                finalOptions.map((data, index) =>
                  <OptionWrapper key={index} onClick={() => optionalClick(index)}>
                    <Option>{data.classTime}</Option>
                  </OptionWrapper>)
              }
            </TotalOptionWrapper>
            :
            <TotalOptionWrapper>
              <OptionWrapper>
                <Option>
                  {
                    defaultVal === undefined ? '수업 일정이 없습니다' : defaultVal.classTime
                    // (isUndefinedDefaultVal || defaultVal === undefined) ? `수업 일정이 없습니다` : defaultVal.classTime
                  }
                </Option>
              </OptionWrapper>
            </TotalOptionWrapper>
        }
      </SelectBox>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
	display: flex;
	flex-direction: column;

	width: 196px;
	height: 40px;
`;

const SelectBox = styled.div`
	position: absolute;

	display: flex;
	flex-direction: column;

  justify-content: center;
	align-items: flex-start;

	width: 194px;
	height: 38px;

  z-index: ${props => props.isClicked ? 5 : 0};

	border: 1px solid #CBCBCB;
	border-radius: 15px;
	overflow: hidden;

  background: url(${props => props.buttonPath}) no-repeat 96% ${props => props.isClicked ? `3` : `55`}%;
	background-color: rgba(255, 255, 255, 1);
  transition: all 0.3s ease-in-out;

	:hover {
		cursor: pointer;
	}

	&::-webkit-scrollbar {
		width: 9px;
	}

	&::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 20px #FF8B48;
		border: 3px solid transparent;
		height: 25px;
		// background-color: #FF8B48;
		border-radius: 200px;
	}

	&::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px transparent;
		border: 4px solid transparent;
		background-color: transparent;
	}

	&::-webkit-scrollbar-track-piece {
		background-color: transparent;
	}
`;

const TotalOptionWrapper = styled.div`
	display: flex;
	flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  margin-left: 16px;

	width: calc(100% - 16px);
`;

const OptionWrapper = styled.div`
	display: flex;
	flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  // margin-left: 16px;

	// width: calc(100% - 16px);
  width: 100%;
  min-height: 32px;

	:hover {
		cursor: pointer;
    color: #FF8B48;
	}

  transition: all 0.3s ease-in-out;
`;

const DivisionLine = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // margin: 8px 0px 8px 0px;

  width: 100%;
  height: 1px;

  background-color: #FF8B48;
`;

const Option = styled.p`
	font-family: NotoSansKR-Medium;
	font-size: 14px;

  // color: #595959;

  margin: 0 0 0 0;
	// margin: 6px 0 6px 8px;
`;