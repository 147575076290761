import { React, useState, useRef, forwardRef, useImperativeHandle, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';

import LockerMemberSearchInput from '../../../Component/LockerComponent/LockerMemberSearchInput';
import LockerStartInput from '../../../Component/LockerComponent/LockerStartInput';
import CalenderInput from '../../../Component/LockerComponent/LockerCalenderInput';
import CountDaysInput from '../../../Component/LockerComponent/CountDaysInput';
import UnitInput from '../../../Component/LockerComponent/UnitInput';

const LockerStartModal = forwardRef((props, ref) => {
  const { exitClick, saveClick } = props;

  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  // const [dayCount, setDayCount] = useState(endDate.diff(startDate, 'days'));
  const [dayCount, setDayCount] = useState(1);
  const [cardPrice, setCardPrice] = useState(0);
  const [cashPrice, setCashPrice] = useState(0);

  const MemberRef = useRef();
  const StartCalenderRef = useRef();
  const EndCalenderRef = useRef();
  const PriceRef = useRef();

  useImperativeHandle(ref, () => ({
		getMemberData: () => {
      return MemberRef.current.getValue();
    },
		getStartDate: () => {
      return StartCalenderRef.current.getValue();
    },
		getEndDate: () => {
      return EndCalenderRef.current.getValue();
    },
		getPrice: () => {
      return PriceRef.current.getValue();
    },
		getCard: () => {
      return cardPrice;
    },
		getCash: () => {
      return cashPrice;
    },
	}));

  // useEffect(() => {
  //   if (dayCount > 0) setEndDate(startDate.clone().add(dayCount - 1, 'days'));
  // }, [dayCount])

  // Modal Page Scroll Controll //
  useEffect(() => {
    document.body.style= `overflow: hidden`;
    return () => document.body.style = `overflow: auto`;
  }, [])
  
  useEffect(() => {
    if (startDate > endDate) {
      alert("사용 종료일이 시작일보다 빠르게 설정할 수 없습니다.");
      setEndDate(startDate);
      setDayCount(1);
    }
    else {
      let calcEndDate = moment(endDate.format('YYYY-MM-DDT23:59:59'));
      let dayCount = calcEndDate.diff(startDate, 'days');

      if (dayCount > 0) {
        setDayCount(dayCount + 1);
      }
    }
  }, [startDate, endDate])

  function CalenderClick(className) {
    if (className === 'StartCalender') EndCalenderRef.current.closeCalender();
    else StartCalenderRef.current.closeCalender();
  }

  function onSetDayCount(value) {
    if (value > 0) {
      setEndDate(startDate.clone().add(value - 1, 'days'));
    }
    else setEndDate(startDate);

    setDayCount(value);
  }

  return (
    <TopLevelWrapper>
      <ModalView>
        <TotalWrapper>
        <Title>사용 시작</Title>
        <ComponentWrapper>
          <LockerMemberSearchInput ref={MemberRef}/>
            <InputWrapper>
              <IndividualWrapper>
                <Text>적용 일수</Text>
                <CountDaysInput value={dayCount} setValue={onSetDayCount}/>
              </IndividualWrapper>
              <IndividualWrapper>
                <Text>시작일</Text>
                <CalenderInput ref={StartCalenderRef} value={startDate} setValue={setStartDate} disabled={false} className='StartCalender' clickFn={CalenderClick} placeholder='시작일' width={209} />
              </IndividualWrapper>
              <IndividualWrapper>
                <Text>종료일</Text>
                <CalenderInput ref={EndCalenderRef} value={endDate} setValue={setEndDate} disabled={false} className='EndCalender' clickFn={CalenderClick} placeholder='종료일' width={209} />
              </IndividualWrapper>
              <IndividualWrapper>
                <Text>카드</Text>
                <UnitInput value={cardPrice} setValue={setCardPrice} unit='원'/>
              </IndividualWrapper>
              <IndividualWrapper>
                <Text>현금</Text>
                <UnitInput value={cashPrice} setValue={setCashPrice} unit='원'/>
              </IndividualWrapper>
            </InputWrapper>
          </ComponentWrapper>
          <ButtonWrapper>
            <ExitButton onClick={exitClick}>취소</ExitButton>
            <SaveButton onClick={saveClick}>저장하기</SaveButton>
          </ButtonWrapper>
        </TotalWrapper>
      </ModalView>
    </TopLevelWrapper>
  )
})

export default LockerStartModal;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 100;

  width: 100vw;
  height: 100vh;

  overflow-y: scroll;

  background-color: rgba(0, 0, 0, 0.2);
`;

const ModalView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 323px;
  // height: 477px;
  height: 569px;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(0, 0, 0, 0.1);
  background-color: #FFFFFF;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: 0 0 0 0;
`;

const ComponentWrapper = styled.div`
  display: flex;
  // flex-direction: row;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 36px;
  margin-top: 15px;
  
  // width: 618px;
  // width: 91.69139465875371%;
  width: 100%;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  gap: 13px;

  width: 100%;
`;

const IndividualWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const Text = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;

  margin: 0 0 0 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  gap: 16px;
  margin-top: 36px;
  // margin-left: 428px;

  width: 100%;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 72px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;

  transition: all 0.2s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #777777;
    color: #777777;
  }
`;

const SaveButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 15px;
  background-color: #5BD98A;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }
`;