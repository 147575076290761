class LocalStorage {

    getToken() {
      return window.localStorage.getItem('Token');
    }
    
    getRefreshToken() {
      return window.localStorage.getItem('RefreshToken');
    }
  
    getStoreId() {
      return window.localStorage.getItem('StoreId');
    }
  }
  
  export default new LocalStorage();