import { React } from 'react';
import styled from 'styled-components';
import moment from 'moment';

const NoticeInfo = (props) => {
  const { data, index, clickFn } = props;

  return (
    <TopLevelWrapper onClick={() => clickFn(index)}>
      <TextWrapper>
        <Title>{data.title}</Title>
      </TextWrapper>
      <TextWrapper>
        <CreatedDate>{moment(data.createdDate).format('YYYY.MM.DD')}</CreatedDate>
        <CommentCountText>· 댓글 {data.commentCount + data.replyCount} 개</CommentCountText>
      </TextWrapper>
    </TopLevelWrapper>
  )
}

export default NoticeInfo;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-itmes: center;

  width: 1003px;
  height: 28px;

  margin: 0 0 0 0;
  padding: 0 8px 0 8px;

  background-color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #F4F2F0;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
`;

const Title = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  margin: 0 0 0 0;

  color: #595959;
`;

const CommentCountText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  margin: 0 0 0 0;

  // color: #FF8B48;
  color: #777777;
`;

const CreatedDate = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  margin: 0 0 0 0;

  color: #777777;
`;