import { React, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { isMobile, isTablet } from 'react-device-detect';

// Email Link전송시 session storage에 토큰을 저장후 redirection을 진행하기 위한 페이지

const EmailCertificationPage = ({match}) => {
  const history = useHistory();

  useEffect(() => {
    if (isTablet || isMobile) {
      // alert('https://m.link-zone.io/certification/' + match.params.certificationString);
      window.location.href = 'https://m.link-zone.io/certification/' + match.params.certificationString;
      return;
    }
    else {
      window.sessionStorage.setItem("CertificationString", match.params.certificationString);
      history.push('/certification');
    }
  }, [])

  return <div/>
}

export default EmailCertificationPage;