import { React, useEffect, useState } from 'react';
import styled from 'styled-components';
import EMOMMovementBox from './EMOMMovementBox';

export default function EMOMSectionView(props) {
  const { round, time, sectionData, refreshSectionList } = props;

  const [exerciseData, setExerciseData] = useState([]);

  useEffect(() => {
    let emomExerciseList = [];

    for (let i = 0; i < round; i++) {
      for (let j = 0; j < sectionData.mainExerciseList.length; j++) {
        emomExerciseList.push(sectionData.mainExerciseList[j]);
      }
    }

    sectionData.emomExerciseList = emomExerciseList;
    console.log(sectionData.emomExerciseList);
    refreshSectionList();
  }, [sectionData.mainExerciseList, round, time])

  return (
    <TopLevelWrapper>
      {
        sectionData.emomExerciseList.map((data, index) => (
          <EMOMMovementBox
            key={index}
            data={data}
            idx={index}
            dataListLength={sectionData.emomExerciseList.length}/>
        ))
      }
    </TopLevelWrapper>
  )
}

{/* <MovementBox
data={data}
dataListLength={sectionData.emomExerciseList.length}
idx={idx}
tag={sectionData.tag}
reps={dataReps}
division='main'
target={target}
targetStartY={targetStartY}
targetEndY={targetEndY}
clickIndex={clickIndex}
changedIndex={changedIndex}
setChangedIndex={setChangedIndex}
clickDivision={clickDivision}
emptyHeight={emptyHeight}
onChangeData={onChangeData}
onChangeUnit={onChangeUnit}
onClickRest={onClickRest}
onMouseDown={onMouseDown}
onClickCopyMovement={onClickCopyMovement}
onClickMovementVideo={onClickMovementVideoViewModal}
onClickMovementOption={onClickMovementOptionModal}
onClickDeleteMovement={onClickDeleteMovement} /> */}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;


