import { React, useState, useEffect, useRef, forwardRef, useImperativeHandle, memo } from 'react';
import styled, { keyframes } from 'styled-components';

import CalenderRightArrowImagePath from '../../../../image/CalenderRightArrow.png';
import CalenderLeftArrowImagePath from '../../../../image/CalenderLeftArrow.png';
import CalenderLeftArrow2ImagePath from '../../../../image/CalenderLeftArrow2-1.png';
import CalenderRightArrow2ImagePath from '../../../../image/CalenderRightArrow2-1.png';

import DeleteButton from '../../../../image/CategoryDeleteButton.png';
import moment from 'moment';

export default function AttendanceCalenderBox(props) {
  const { lessonHistory } = props;

  const [today, setToday] = useState(moment());
  const [isClicked, setIsClicked] = useState(false);
  const [dateList, setDateList] = useState([]);
  // const [value, setValue] = useState();

  const firstWeek = today.clone().startOf('month').week();
  const lastWeek = today.clone().endOf('month').week() === 1 ? 53 : today.clone().endOf('month').week();

  useEffect(() => {
    let newDateList = [];

    for (let week = firstWeek; week <= lastWeek; week++) {
      for (let i = 0; i < 7; i++) {
        let days = today.clone().startOf('year').week(week).startOf('week').add(i, 'day');

        newDateList.push(days);
      }
    }
    setDateList(newDateList);
  }, [isClicked, today, firstWeek, lastWeek])

  // useEffect(() => {
  //   // console.log(dateList);
  //     for (let i = 0; i < dateList.length; i++) {
  //       console.log(dateList[i].format('YYYY.MM.DD'))
  //     }
  // }, [dateList])

  // 출석 여부 확인 //
  function decideAttendanceState(data) {
    let isExist = false;

    for (let i = 0; i < lessonHistory.length; i++) {
      let classDate = moment(lessonHistory[i].classDate)
      if (data.format('YYYY.MM.DD') === classDate.format('YYYY.MM.DD')
        && (lessonHistory[i].status === 1 || lessonHistory[i].status === 2)
        && today.format('MM') === classDate.format('MM')) {
        isExist = true;
        break;
      }
    }

    return isExist;
  }

  return (
    <Box>
      <TitleWrapper>
        <LayerWrapper>
          <Arrow width={12.46} height={11} onClick={() => setToday(today.clone().subtract(1, 'year'))}>
            <Image src={CalenderLeftArrow2ImagePath}/>
          </Arrow>
          <Arrow width={7} height={11} onClick={() => setToday(today.clone().subtract(1, 'month'))}>
            <Image src={CalenderLeftArrowImagePath}/>
          </Arrow>
          <Title>{today.format('YYYY년 MM월')}</Title>
          <Arrow width={7} height={11} onClick={() => setToday(today.clone().add(1, 'month'))}>
            <Image src={CalenderRightArrowImagePath}/>
          </Arrow>
          <Arrow width={12.46} height={11} onClick={() => setToday(today.clone().add(1, 'year'))}>
            <Image src={CalenderRightArrow2ImagePath}/>
          </Arrow>
        </LayerWrapper>
        <Text fontFamily='NotoSansKR-Bold' fontSize={10} color='#232323' cursor='default'>출석 확인 도장</Text>
      </TitleWrapper>
      <DateColumnWrapper>
        <DateColumn style={{ width: `21px`, marginRight: `21px` }}>SUN</DateColumn>
        <DateColumn style={{ width: `25px`, marginRight: `19px` }}>MON</DateColumn>
        <DateColumn style={{ width: `19px`, marginRight: `21px` }}>TUE</DateColumn>
        <DateColumn style={{ width: `24px`, marginRight: `18px` }}>WED</DateColumn>
        <DateColumn style={{ width: `22px`, marginRight: `22px` }}>THU</DateColumn>
        <DateColumn style={{ width: `15px`, marginRight: `22px` }}>FRI</DateColumn>
        <DateColumn style={{ width: `20px`, marginRight: `16px` }}>SAT</DateColumn>
      </DateColumnWrapper>
      <DateWrapper>
        {
          dateList.map((data, index) => (
            data.format('MM') === today.format('MM') ?
              <Date key={index} isClicked={decideAttendanceState(data)}>
                {data.format('D')}
              </Date>
              :
              <WithoutHoverDate key={index}>
                {data.format('D')}
              </WithoutHoverDate>
          ))
        }
      </DateWrapper>
    </Box>
  )
}


const Box = styled.div`
  display: flex;
  flex-direction: column;

  width: 301px;

  padding-bottom: 14px;

  border-radius: 8px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  background-color: #FFFFFF;
  
  transition: all 0.3s ease-in-out;
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  gap: 4px;

  // margin-top: 18px;

  // width: 100%;
`;


const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: 18px;
  padding: 0px 14px 0px 14px;

  width: calc(100% - 28px);
`;

const Arrow = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  margin-top: 1px;
  
  width: ${props => props.width}px;
  height: ${props => props.height}px;

  :hover {
    cursor: pointer;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 12px;

  color: #232323;

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const DateColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;

  // justify-content: center;
  // align-items: center;

  // gap: 38px;
  margin-top: 16px;
  margin-left: 14px;
  margin-bottom: 24px;
`;

const DateColumn = styled.p`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  font-family: Poppins-bold;
  font-size: 10px;

  color: #FF8B48;
  
  margin: 0 0 0 0;
`;

const DateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  // width: 408px;
  width: 290px;
  margin-left: 6px;
`;

const Date = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  // width: 44px;
  // height: 44px;
  width: 23px;
  height: 23px;

  // border-radius: 44px;
  border-radius: 23px;

  // margin: 5.5px 5.5px 5.5px 5.5px;
  margin: 7.5px 9px 7.5px 9px;

  background-color: ${props => props.isClicked ? `#FF7322` : `#FFFFFF`};
  
  font-family: Poppins-Medium;
  font-size: 14px;

  color: ${props => props.isClicked ? `#FFFFFF` : `#595959`};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    // background-color: #FF7322;
    // color: #FFFFFF;

    // font-fmaily: Poppins-Bold;
    // font-size: 14px;
  }
`;

const WithoutHoverDate = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  // width: 44px;
  // height: 44px;
  width: 23px;
  height: 23px;

  // border-radius: 44px;
  border-radius: 23px;

  // margin: 5.5px 5.5px 5.5px 5.5px;
  margin: 7.5px 9px 7.5px 9px;

  background-color: #FFFFFF;

  font-family: Poppins-Regular;
  font-size: 14px;

  color: #777777;

  :hover {
    cursor: default;
  }
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  padding: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }
`;