import { React } from 'react';
import styled from 'styled-components';

export default function NewLessonNameInput(props) {
	const { value, setValue, placeholder } = props;

	function handleChange(e) {
		setValue(e.target.value);
	}

	function handleFocus(e) {
		// if (changed) {
		// 	// do nothing
		// } else {
		// 	setValue("");
		// }
	}

	function handleBlur(e) {
	}

	return (
    <TopLevelWrapper>
      <Input
        type='text'
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur} />
    </TopLevelWrapper>
  ) 
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const Input = styled.input`
	font-family: NotoSansKR-Regular;
	font-size: 14px;
	line-height: 20px;
	
	padding: 0 0 0 8px;

  width: 100%;
  height: 30px;

	outline: none;

  border: 1px solid #DFDFDF;
  border-radius: 8px;

	color: #000000;

  :: placeholder {
    color: #DFDFDF;
  }
	
  transition: all 0.5s ease-in-out;
`;