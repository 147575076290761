import { React, useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';

import moment from 'moment';

import HoldingAndExtendHistoryInfoView from './HoldingAndExtendHistoryInfoView';
import RefundInfoView from './RefundInfoView';

import MemberShipHistoryInfoButtonImagePath from '../../../../image/MemberShipHistoryInfoButton.png';
import MemberShipHistoryInfoWhiteButtonImagePath from '../../../../image/MemberShipHistoryInfoWhiteButton.png';
import MemberShipHistoryInfoGrayButtonImagePath from '../../../../image/MemberShipHistoryInfoGrayButton.png';
import OrangeTrashIcon from '../../../../image/OrangeTrashIcon.png';

const MemberShipHistoryInfoView = forwardRef((props, ref) => {
  const { 
    data, 
    index, 
    size,
    modifyClick, 
    holdingClick, 
    extensionClick, 
    refundClick, 
    deleteClick,

    onClickHoldingAndExtendButton, 
    onClickHoldingEdit,
    onClickDeleteHoldingHistory,
    onClickModifyExtend,
    onClickDeleteExtend,
    onClickRefundDelete,
    onClickDeleteExpectedUsage
  } = props;

  const [isClicked, setIsClicked] = useState(false);
  const [buttonImage, setButtonImage] = useState(MemberShipHistoryInfoButtonImagePath);
  const [fontColor, setFontColor] = useState('#595959');
  const [border, setBorder] = useState('1px solid #FFFFFF');
  const [hoverBorder, setHoverBorder] = useState('1px solid #FFFFFF');
  const [backgroundColor, setBackgroundColor] = useState('#FFFFFF');

  const OptionBoxRef = useRef();
  const OptionButtonRef = useRef();
  const OptionEditRef = useRef();
  const OptionHoldRef = useRef();
  const OptionExtendRef = useRef();
  const OptionDeleteRef = useRef();
  
  const ExpectedDeleteRef = useRef();

  useImperativeHandle(ref, () => ({
    setIsClicked: () => {
      setIsClicked(false);
    },
    getButtonRef: () => {
      return OptionButtonRef.current;
    },
    getBoxRef: () => {
      return OptionBoxRef.current;
    }
  }))

  useEffect(() => {
    if (data.isExpectedMemberShip) return;

    if (isClicked) {
      // OptionBoxRef.current.style.height = `142px`;
      // OptionBoxRef.current.style.height = `108px`;
      OptionBoxRef.current.style.height = `176px`;
      OptionBoxRef.current.style.border = `1px solid #FF8B48`;
    }
    else {
      OptionBoxRef.current.style.height = `0px`;
      OptionBoxRef.current.style.border = `0px solid #FF8B48`;
    }
  }, [isClicked, data])

  useEffect(() => {
    document.addEventListener("mousedown", onClickOutSide);

    return () => {
      document.removeEventListener("mousedown", onClickOutSide);
    }
  }, [])

  useEffect(() => {
    let startDate = moment(data.startDate);
    let endDate = moment(data.endDate);
    
    // 환불 데이터의 경우 //
    if (data.isRefund) {
      setFontColor('#777777');
      setBorder('1px solid #F8F8F8');
      setHoverBorder('1px solid #FF8B48');
      setBackgroundColor('#F8F8F8');
      setButtonImage(MemberShipHistoryInfoButtonImagePath);
    }
    else if (data.isExpectedMemberShip) {
      setFontColor('#777777');
      setBorder('1px solid #FDECC1');
      setHoverBorder('1px solid #FF8B48');
      setBackgroundColor('#FDECC1');
      setButtonImage(MemberShipHistoryInfoButtonImagePath);
    }
    // 결제 예정 데이터 정리 //
    else if (data.isExpectedMemberShip) {
      setFontColor('#777777');
      setBorder('1px solid #F8F8F8');
      setHoverBorder('1px solid #FF8B48');
      setBackgroundColor('#F8F8F8');
      setButtonImage(MemberShipHistoryInfoWhiteButtonImagePath);
    }
    // 사용 예정의 데이터인 경우 //
    else if (moment().isBefore(startDate)) {
      setFontColor('#FFFFFF');
      setBorder('1px solid #FFA800');
      setHoverBorder('1px solid #FFA800');
      setBackgroundColor('#FFA800');
      setButtonImage(MemberShipHistoryInfoWhiteButtonImagePath);
    }
    // 사용 기한이 끝나지 않은 경우 //
    else if (moment().isBefore(endDate)) {
      if (data.totalMax !== -1 && data.totalMax <= data.courseTakeCount) {
        setFontColor('#777777');
        setBorder('1px solid #F8F8F8');
        setHoverBorder('1px solid #FF8B48');
        setBackgroundColor('#F8F8F8');
        setButtonImage(MemberShipHistoryInfoButtonImagePath);
      }
      else {
        setFontColor('#595959');
        setBorder('1px solid #FFFFFF');
        setHoverBorder('1px solid #FF8B48');
        setBackgroundColor('#FFFFFF');
        setButtonImage(MemberShipHistoryInfoButtonImagePath);
      }
    }
    // 예정된 기한이 지난경우 //
    else {
      setFontColor('#777777');
      setBorder('1px solid #F8F8F8');
      // setHoverBorder('1px solid #595959');
      setHoverBorder('1px solid #FF8B48');
      setBackgroundColor('#F8F8F8');
      // setButtonImage(MemberShipHistoryInfoGrayButtonImagePath);
      setButtonImage(MemberShipHistoryInfoButtonImagePath);
    }
  }, [data])

  function onClickOutSide(e) {
    if (OptionBoxRef.current !== e.target
      && OptionButtonRef.current !== e.target
      && OptionEditRef.current !== e.target
      && OptionHoldRef.current !== e.target
      && OptionExtendRef.current !== e.target
      && OptionDeleteRef.current !== e.target) {
      setIsClicked(false);
    }
    // if (OptionBoxRef.current !== e.target 
    //   && OptionButtonRef.current !== e.target
    //   && OptionEditRef.current !== e.target
    //   && OptionHoldRef.current !== e.target
    //   && OptionExtendRef.current !== e.target) {
    //   setIsClicked(false);
    // }
  }

  // 마우스 호버링
  function mouseOver() {
    OptionButtonRef.current.style.opacity = 1;
  }

  // 마우스 호버링
  function mouseLeave() {
    OptionButtonRef.current.style.opacity = 0;
  }

  // 남은일수에 맞춰 텍스트 정리 //
  function remainDaysView() {
    // moment(data.endDate).diff(moment(new Date()), 'days') > 0 ? moment(data.endDate).diff(moment(new Date()), 'days') + '일' : moment(data.endDate).diff(moment(new Date()), 'days') === 0 ? 'D-Day' : '만료' 
    // console.log(data);
    if (data.isRefund) return '환불';
    else if (data.isExpectedMemberShip) return '결제예정';
    else if (data.totalMax === data.courseTakeCount) return '만료';
    else if (moment().isBefore(data.startDate)) return '예정';
    else if (moment(moment(data.endDate).format('YYYY-MM-DD')).diff(moment(moment().format('YYYY-MM-DD')), 'days') > 0) {
      return moment(moment(data.endDate).format('YYYY-MM-DD')).diff(moment(moment().format('YYYY-MM-DD')), 'days') + '일';
    }
    else if (moment(moment(data.endDate).format('YYYY-MM-DD')).diff(moment(moment().format('YYYY-MM-DD')), 'days') === 0) {
      return 'D-Day'
    }

    return '만료';
  }

  // 몸체 눌렀을때 켜지기 //
  function onClickBody(e) {
    if (e.target === OptionButtonRef.current || OptionBoxRef.current.contains(e.target)) return;

    modifyClick(index);
  }

  return (
    <TopLevelWrapper>
      <MemberShipComponent 
        zIndex={20 + size - index}
        border={border} 
        hoverBorder={hoverBorder} 
        backgroundColor={backgroundColor} 
        onMouseOver={mouseOver} 
        onMouseLeave={mouseLeave} 
        onClick={onClickBody}>
      <Table>
        <Body>
          <Tr style={{ width: '103px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '103px' }}>
                <Text color={fontColor}>{data.memberShipName}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '156px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '127px' }}>
                <Text color={fontColor}>
                  {data.startDate.slice(2, 10).replaceAll('-', '.')} - {data.endDate.slice(2, 10).replaceAll('-', '.')}
                </Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '83px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '53px' }}>
                <Text color={fontColor}>{data.totalMax === -1 ? '제한없음' : data.totalMax}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '84px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '84px' }}>
                <Text color={fontColor}>{data.courseTakeCount}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '91px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '62px' }}>
                {/* <Text>{data.totalPrice}</Text> */}
                <Text color={fontColor}>{(data.cardPrice + data.cashPrice).toLocaleString('ko-KR')}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '91px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '62px' }}>
                <Text color={fontColor}>{data.cardPrice.toLocaleString('ko-KR')}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '91px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '62px' }}>
                <Text color={fontColor}>{data.cashPrice.toLocaleString('ko-KR')}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '91px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '62px' }}>
                <Text color={fontColor}>{data.notReceivedPrice.toLocaleString('ko-KR')}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '93px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '93px' }}>
                <Text color={fontColor}>{data.approvalDate.slice(2, 10).replaceAll('-', '.')}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '67px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '67px' }}>
                {/* <Text>{moment(data.endDate).diff(moment(new Date()), 'days') > 0 ? moment(data.endDate).diff(moment(new Date()), 'days') + '일' : moment(data.endDate).diff(moment(new Date()), 'days') === 0 ? 'D-Day' : '만료' }</Text> */}
                <Text color={fontColor}>{remainDaysView()}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr>
              {
                data.isExpectedMemberShip ?
                  <Td>
                    <IconWrapper ref={OptionButtonRef} onClick={() => onClickDeleteExpectedUsage(data)}>
                      <Icon ref={OptionBoxRef} src={OrangeTrashIcon} />
                    </IconWrapper>
                  </Td>
                  :
                  <Td>
                    <OptionButton ref={OptionButtonRef} src={buttonImage} onClick={() => setIsClicked(!isClicked)} />
                    <OptionWrapper ref={OptionBoxRef}>
                      <Option ref={OptionEditRef} onMouseDown={() => modifyClick(index)}>수정</Option>
                      <Option ref={OptionHoldRef} onMouseDown={() => holdingClick(index)}>홀딩</Option>
                      <Option ref={OptionExtendRef} onMouseDown={() => extensionClick(index)}>연장</Option>
                      <Option ref={OptionExtendRef} onMouseDown={() => refundClick(index)}>환불</Option>
                      <Option ref={OptionDeleteRef} onMouseDown={() => deleteClick(index)}>삭제</Option>
                    </OptionWrapper>
                  </Td>
            }
            {/* <Td>
              <OptionButton ref={OptionButtonRef} src={buttonImage} onClick={() => setIsClicked(!isClicked)} />
              <OptionWrapper ref={OptionBoxRef}>
                <Option ref={OptionEditRef} onMouseDown={() => modifyClick(index)}>수정</Option>
                <Option ref={OptionHoldRef} onMouseDown={() => holdingClick(index)}>홀딩</Option>
                <Option ref={OptionExtendRef} onMouseDown={() => extensionClick(index)}>연장</Option>
                <Option ref={OptionExtendRef} onMouseDown={() => refundClick(index)}>환불</Option>
                <Option ref={OptionDeleteRef} onMouseDown={() => deleteClick(index)}>삭제</Option>
              </OptionWrapper>
            </Td> */}
          </Tr>
        </Body>
      </Table>
      </MemberShipComponent>
      {
        data.isRefund &&
        <ExpertComponentWrapper zIndex={data.historyData.length + 1}>
          <RefundInfoView 
            data={data}
            onClickDeleteRefund={onClickRefundDelete}
            onClickModifyRefund={() => refundClick(index)}/>
        </ExpertComponentWrapper>
      }
      {
        data.historyData.map((innerData, index) => (
          <ExpertComponentWrapper key={index} zIndex={data.historyData.length - index}>
            <HoldingAndExtendHistoryInfoView 
              data={innerData} 
              onClickHoldingAndExtendButton={onClickHoldingAndExtendButton} 
              onClickHoldingEdit={onClickHoldingEdit}
              onClickDeleteHoldingHistory={onClickDeleteHoldingHistory}
              onClickModifyExtend={onClickModifyExtend}
              onClickDeleteExtend={onClickDeleteExtend} />
          </ExpertComponentWrapper>
        ))
      }
    </TopLevelWrapper>
  )
});

export default MemberShipHistoryInfoView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 1018px;

  // height: 
  // display: flex;
  // flex-direction: column;

  // justify-content: center;
  // align-items: flex-start;

  // width: 1018px;
  // height: 50px;

  // box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);

  // border: ${props => props.border};
  // border-radius: 30px;
  // background-color: ${props => props.backgroundColor};
    
  // transition: all 0.3s ease-in-out;

  // :hover {
  //   cursor: pointer;
  //   border: ${props => props.hoverBorder};
  // }
`;

const MemberShipComponent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: 1018px;
  height: 50px;

  // box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  box-shadow: 0px 10px 50px rgba(240, 232, 227, 0.5);

  z-index: ${props => props.zIndex};

  border: ${props => props.border};
  border-radius: 30px;
  background-color: ${props => props.backgroundColor};
    
  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: ${props => props.hoverBorder};
  }
`;

const Table = styled.table`
  display: flex;
  flex-direction: row;
`;

const Body = styled.tbody`
  display: flex;
  flex-direction: row;
    
  margin-left: 28px;
`;

const Tr = styled.tr`
  display: flex;
  flex-direction: row;
`;

const Td = styled.td`
  display: flex;
  flex-direction: row;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: flex-start;

  overflow: hidden;

  max-height: 20px;
`;

const Text = styled.span`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  padding: 0 0 0 0;
`;

const OptionButton = styled.img`
  opacity: 0;
    
  transition: all 0.3s ease-in-out;
`;

const OptionWrapper = styled.div`
  position: absolute;

  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  overflow: hidden;
    
  z-index: 10;

  margin-top: 40px;
  margin-left: -20px;

  border-radius: 8px;

  transition: all 0.2s ease-in-out;
`;

const Option = styled.div`
  display: flex;
  flex-direction: column;;

  justify-content: center;
  align-items: center;

  width: 58px;
  height: 38px;

  margin: 0 0 0 0;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #FF8B48;
  background-color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    color: #FFFFFF;
    background-color: #FF8B48;
  }
`;

const ExpertComponentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;

  z-index: ${props => props.zIndex};
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 24px;
  height: 24px;

  opacity: 0;

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const Icon = styled.img`
  width: 100%;
  height: 100%;
`;