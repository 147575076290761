import { React, useRef } from 'react';
import styled from 'styled-components';

const MemberShipInfoBox = (props) => {
  const { data, index, modifyClickFn, deleteClickFn } = props;

  const TopLevelRef = useRef();
  const ButtonRef = useRef();

  function mouseOver() {
    TopLevelRef.current.style.border = `1px solid #FF8B48`;
    ButtonRef.current.style.opacity = 1;
  }

  function mouseLeave() {
    TopLevelRef.current.style.border = `1px solid #DFDFDF`;
    ButtonRef.current.style.opacity = 0;
  }

  function modifyClick() {
    modifyClickFn(index);
  }

  function deleteClick() {
    deleteClickFn(index)
  }

  return (
    <TopLevelWrapper ref={TopLevelRef} onMouseOver={mouseOver} onMouseLeave={mouseLeave}>
      <TextWrapper>
        <Text>{data.name}</Text>
        <ButtonWrapper ref={ButtonRef}>
          <Button color='#595959' onClick={modifyClick}>수정</Button>
          <Button color='#FF3131' onClick={deleteClick}>삭제</Button>
        </ButtonWrapper>
      </TextWrapper>
      <InfoWrapper>
        <InfoBox>
          <InfoText>{data.totalMax === -1 ? '무제한' : data.totalMax + '회'}</InfoText>
        </InfoBox>
        <InfoBox>
          <InfoText>{data.validity}일</InfoText>
        </InfoBox>
        <InfoBox>
          <InfoText>{data.price}원</InfoText>
        </InfoBox>
      </InfoWrapper>
    </TopLevelWrapper>
  )
}

export default MemberShipInfoBox;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 450px;
  height: 67px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;

  margin: 8px 0 0 0;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 95%;

  margin-top: 10px;
`;

const Text = styled.div`
  display: flex;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;

  margin: 0 0 0 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  gap: 8px;

  opacity: 0;

  transition: all 0.3s ease-in-out;
`;

const Button = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  // :hover {
  //   cursor: pointer;
  // }
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;
  
  gap: 4px;
  margin: 5px 0 10px 0;

  width: 95%;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  border-radius: 8px;
  background-color: #CBCBCB;
`;

const InfoText = styled.div`
  font-fmaily: NotoSansKR-Regular;
  font-size: 12px;

  color: #FFFFFF;

  margin: 3px 8px 4px 8px;
`;