import { React, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import FetchModule from '../../Share/Network/FetchModule';

const ExpiredMemberInfo = (props) => {
  const { data, nowPage } = props;

  const [isExistNotReceived, setIsExistNotReceived] = useState(false);

  const history = useHistory();

  // const SendRef = useRef();
  const DeleteRef = useRef();

  useEffect(() => {
    if (data.notReceivedPrice === 0 || data.notReceivedPrice === undefined) setIsExistNotReceived(false);
    else setIsExistNotReceived(true);
  }, [data])

  function mouseOver() {
    // SendRef.current.style.color = `#FF8B48`;
    DeleteRef.current.style.color = `#FF8B48`;
  }

  function mouseLeave() {
    // SendRef.current.style.color = `#FFFFFF`;
    DeleteRef.current.style.color = `#FFFFFF`;
  }

  function onClickInvalidMemberInfo(e) {
    // if (SendRef.current !== e.target && DeleteRef.current !== e.target) {
    if (DeleteRef.current !== e.target) {
      window.localStorage.setItem('InvalidMemberPage', nowPage);
      // history.push('/member/valid/detail/' + data.memberId);
      history.push('/member/expired/detail/' + data.memberId);
    }
  }
  
  // 문자발송 버튼 클릭시
  function onClickSendMessageButton() {
    var text = data.name + '님!, 지금까지 이용하셨던 ' + data.memberShipName + '이(가) 만료되었어요!\n빠른 결제로 Link Coach와 함께 더 건강한 하루를 만들어보아요! :)';

    var requestData = {
      userId: data.linkCoachId,
      data: text,
      location: 'Reservation',
      jsonData: ''
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataOfCoach('push_notification/send', 'POST', requestData);
        
        if (responseData.status === 200) {
          alert("푸시알림을 전송하였습니다!");
        }
        else if (responseData.status === 204) {
          // alert("Link Coach를 탈퇴한 회원입니다!");
          alert('Link Coach에 가입되어있지 않은 회원입니다!');
        }
      }
    )();
  }

  // 회원권 삭제 클릭시
  function onClickMemberInfoDelete() {
    
    const fetchModule = new FetchModule();
		(
			async () => {
				const responseData = await fetchModule.postData('member/delete/' + data.memberId, 'POST');
        // console.log(responseData);
				if (responseData.status === 200) {
          // const coachResponseData = await fetchModule.postDataOfCoach('registration/zone/delete/' + window.sessionStorage.getItem('StoreId') + '/' + data.linkCoachId, 'POST');
          const coachResponseData = await fetchModule.postDataOfCoach('registration/zone/delete/' + window.localStorage.getItem('StoreId') + '/' + data.linkCoachId, 'POST');
          // console.log(coachResponseData);
          if (coachResponseData.status === 200) {
            alert("회원 정보가 삭제되었습니다.");
            history.push('/member/valid');
          }
				}
        else if (responseData.status === 201) {
          alert("유효한 회원정보가 아닙니다.");
          history.push('/member/valid');
        }
        else if (responseData.status === 202) {
          alert("현재 유효한 회원권이 존재합니다.\n삭제후 다시시도해 주세요.");
        }
        else if (responseData.status === 204) {
          alert("회원정보가 존재하지 않습니다.");
          history.push('/member/valid');
        }
				else if (responseData.status === 401) {
          alert("로그인 후 사용하세요");
          history.push('/');
        }
			}
		)();
  }

  // 전화번호 뷰 정리 //
  function selectPhoneView() {
    if (data.phone === '') return '없음';
    else {
      return data.phone[0]
        + data.phone[1]
        + data.phone[2]
        + '-'
        + data.phone[3]
        + data.phone[4]
        + data.phone[5]
        + data.phone[6]
        + '-' +
        + data.phone[7]
        + data.phone[8]
        + data.phone[9]
        + data.phone[10];
    }
  }

  return (
    <TopLevelWrapper onMouseOver={mouseOver} onMouseLeave={mouseLeave} onClick={onClickInvalidMemberInfo}>
      <Table>
        <Body>
        <Tr style={{ width: '71px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '52px' }}>
                <Text isExistNotReceived={isExistNotReceived}>{data.name}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '70px' }} /*style={{ width: '55px' }}*/>
            <Td>
              <TextWrapper style={{ maxWidth: '26px' }}>
                <Text isExistNotReceived={isExistNotReceived}>{data.gender === 'male' ? '남성' : '여성'}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '122px' }} /*style={{ width: '98px' }}*/>
            <Td>
              <TextWrapper style={{ maxWidth: '70px' }}>
                <Text isExistNotReceived={isExistNotReceived}>{data.birthDay[0] 
                      + data.birthDay[1] 
                      + data.birthDay[2] 
                      + data.birthDay[3]
                      + '.' 
                      + data.birthDay[4]
                      + data.birthDay[5]
                      + '.'
                      + data.birthDay[6]
                      + data.birthDay[7]}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '114px'}}>
            <Td>
              <TextWrapper style={{ maxWidth: '114px' }}>
                {/* <Text>{data.phone[0] 
                      + data.phone[1]
                      + data.phone[2]
                      + '-'
                      + data.phone[3]
                      + data.phone[4]
                      + data.phone[5]
                      + data.phone[6]
                      + '-' +
                      + data.phone[7]
                      + data.phone[8]
                      + data.phone[9]
                      + data.phone[10]}</Text> */}
                  <Text isExistNotReceived={isExistNotReceived}>{selectPhoneView()}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '244px' }} /*style={{ width: '246px', backgroundColor: 'red'   }} */>
            <Td>
              <TextWrapper style={{ maxWidth: '218px' }}>
                <Text isExistNotReceived={isExistNotReceived}>{data.memberShipName}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          {/* <Tr style={{ width: '98px' }}> */}
          <Tr style={{ width: '98px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '70px' }}>
                <Text isExistNotReceived={isExistNotReceived}>{data.startDate.split('T')[0].replaceAll('-', '.')}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '98px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '70px' }}>
                <Text isExistNotReceived={isExistNotReceived}>{data.endDate.split('T')[0].replaceAll('-', '.')}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          {/* <Tr style={{ width: '115px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '93px' }}>
                <Text>{data.categoryName}</Text>
              </TextWrapper>
            </Td>
          </Tr> */}
          {/* <Tr style={{ width: '93px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '93px' }}>
                <Text isExistNotReceived={isExistNotReceived}>{data.categoryName}</Text>
              </TextWrapper>
            </Td>
          </Tr> */}
          <Tr style={{ width: '100px', marginRight: `10px`}}>
            <Td>
              {/* <SendButton  onClick={() => onClickSendMessageButton()}>문자발송</SendButton> */}
            </Td>
          </Tr>
          <Tr>
            <Td>
              <SendButton ref={DeleteRef} onClick={() => onClickMemberInfoDelete()}>삭제</SendButton>
            </Td>
          </Tr>
        </Body>
      </Table>
    </TopLevelWrapper>
  )
};

export default ExpiredMemberInfo;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  // justify-content: space-around;
  // justify-content: center;
  align-items: center;

  width: 1018px;
  height: 50px;

  background: #FFFFFF;

  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  border: 1px solid #FFFFFF;
  border-radius: 30px;
  
  margin-bottom: 8px;
  
  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #FF8B48;
  }
`;

const Table = styled.table`
  display: flex;
  flex-direction: row;
`;

const Body = styled.tbody`
  display: flex;
  flex-direction: row;
  
  margin-left: 28px;
`;

const Tr = styled.tr`
  display: flex;
  flex-direction: row;
`;

const Td = styled.td`
  display: flex;
  flex-direction: row;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  overflow: hidden;

  max-height: 20px;
  // border: 1px solid #232323;
`;

const Text = styled.span`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: ${props => props.isExistNotReceived ? `#FF8B48` : `#595959`};

  margin: 0 0 0 0;
`;

const SendButton = styled.span`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    font-family: NotoSansKR-Bold;
  }
`;