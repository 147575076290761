import { React, useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import CalenderInput from '../../../../Component/ClassManageComponent/CalenderInput.js';
import ReasonInput from '../../../../Component/ClassManageComponent/ReasonInput.js';
import SingleTimeInput from '../../../../Component/ClassManageComponent/SingleTimeInput.js';
import SessionStorage from '../../../Share/Network/SessionStorage.js';

import FetchModule from '../../../Share/Network/FetchModule';

function ReservationLockModal(props) {
  const { data, cancel } = props;

  const history = useHistory();

  const [isClicked, setIsClicked] = useState(false);

  const CalenderRef = useRef();
  const StartRef = useRef();
  const EndRef = useRef();
  const ReasonRef = useRef();

  // 스크롤 제어 //
  useEffect(() => {
    document.body.style= `overflow: hidden`;
    return () => document.body.style = `overflow: auto`
  }, [])

  function CalenderClick(className) {
  }

  function onClickSaveButton() {
    var lockDate = CalenderRef.current.getValue();

    var scheduleId = isClicked ? -1 : data.scheduleId;
    // var storeId = parseInt(SessionStorage.getStoreId());
    var storeId = parseInt(window.localStorage.getItem('StoreId'));
    var startTime = StartRef.current.getValue();
    var endTime = EndRef.current.getValue();
    var reason = ReasonRef.current.getValue();

    if (lockDate === '') {
      alert("잠금 날짜를 선택해주세요");
      return;
    }
    else if (!isClicked) {
      if (startTime === '') {
        alert("잠금 시작시간을 설정해주세요");
        return;
      }
      else if (endTime === '') {
        alert("잠금 종료시간을 설정해주세요");
        return;
      }
    }
    else if (reason === '') {
      alert("잠금사유를 적어주세요");
      return;
    }

    var requestData = {
      scheduleId: scheduleId,
      storeId: storeId,
      startDate: lockDate.replaceAll('.', '-') + 'T' + (isClicked ? `00:00` : startTime) + ':00',
      endDate: lockDate.replaceAll('.', '-') + 'T' +  (isClicked ? `23:59` : endTime) + ":00",
      reason: reason
    }
    
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postData('reserve_lock/create', 'POST', requestData);
        
        if (responseData.status === 200) {
          alert("잠금설정이 완료되었습니다.");
          cancel();
        }
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }

  return (
    <TopLevelWrapper>
      <ModalView>
        <TitleWrapper>
          <Title>예약 잠금</Title>
        </TitleWrapper>
        <TotalWrapper>
          <LayerWrapper>
            <ComponentWrapper>
              <ComponentTitleWrapper>
                <ComponentTitle>예약 잠금 날짜 설정</ComponentTitle>
                <DayButtonWrapper>
                  <AllDayButton isClicked={isClicked} onClick={() => setIsClicked(!isClicked)}/>
                  <ComponentTitle>수업 전체 잠금</ComponentTitle>
                </DayButtonWrapper>
              </ComponentTitleWrapper>
              <CalenderInput ref={CalenderRef} className='StartCalender' clickFn={CalenderClick} placeholder='시작일' width='280px'/>
            </ComponentWrapper>
            <TimeWrapper>
              <ComponentWrapper>
                <ComponentTitle>잠금 시작시간</ComponentTitle>
                <SingleTimeInput ref={StartRef} placeholder='00:00' disabled={isClicked}/>
              </ComponentWrapper>
              <ComponentWrapper>
                <ComponentTitle>잠금 종료시간</ComponentTitle>
                <SingleTimeInput ref={EndRef} placeholder='00:00' disabled={isClicked}/>
              </ComponentWrapper>
            </TimeWrapper>
          </LayerWrapper>
          <LayerWrapper>
            <ComponentWrapper>
              <ComponentTitle>잠금사유</ComponentTitle>
              <ReasonInput ref={ReasonRef} placeholder='예약 잠금 사유를 40자 이내로 입력해주세요'/>
            </ComponentWrapper>
          </LayerWrapper>
          <LayerWrapper>
            <ExplanationWrapper>
              <Explanation>예약 잠금 설정이 완료되면 회원들에게 일괄적으로 푸시알림이 전송됩니다.</Explanation>
            </ExplanationWrapper>
          </LayerWrapper>
          <ButtonWrapper>
            <ExitButton onClick={cancel}>취소</ExitButton>
            <SaveButton onClick={onClickSaveButton}>저장하기</SaveButton>
          </ButtonWrapper>
        </TotalWrapper>
      </ModalView>
    </TopLevelWrapper>
  )
}

export default ReservationLockModal;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  justify-content: center;
  align-items: center;

  z-index: 1000;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.2);
`;

const ModalView = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 644px;
  height: 349px;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(0, 0, 0, 0.1);

  background-color: #FFFFFF;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 588px;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: 0 0 0 0;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  gap: 24px;
  margin-top: 16px;
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  gap: 24px;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const ComponentTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 282px;
`;

const DayButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
`;

const AllDayButton = styled.div`
  display: flex;
  flex-direction: row;

  width: 14px;
  height: 14px;

  border: 1px solid #DFDFDF;
  border-radius: 4px;
  background-color: ${props => props.isClicked ? `#FF8B48` : `#FFFFFF` };
  
  transition: all 0.3s ease-in-out;
`;

const ComponentTitle = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #595959;

  margin: 0 0 0 0;
`;

const TimeWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const ExplanationWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 588px;
`;

const Explanation = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #FF8B48;

  margin: 0 0 0 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  width: 588px;

  gap: 16px;

  margin: 32px 0 0 0;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 72px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #595959;
    color: #595959;
  }
`;

const SaveButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 15px;
  background-color: #5BD98A;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }
`;