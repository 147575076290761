import { forwardRef, React, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import AccountInput from '../../../Component/AccountComponent/AccountInput.js';
import AccountPasswordInput from '../../../Component/AccountComponent/AccountPasswordInput.js';

const LoginInfo = forwardRef((props, ref) => {
	const { onClickLoginButton } = props;

	const history = useHistory();
	const [idClickCount, setIdClickCount] = useState(0);
	const [pwClickCount, setPwClickCount] = useState(0);

	const TopLevelRef = useRef();
	const IdRef = useRef();
	const PWRef = useRef();
	const PlanationRef = useRef();

	useImperativeHandle(ref, () => ({
		getUserId: () => {
			return IdRef.current.getValue();
		},
		getUserPw: () => {
			return PWRef.current.getValue();
		},
		isBlankId: () => {
			IdRef.current.setDefaultPlaceholder('올바르지 않은 아이디 입니다.');
			IdRef.current.plusClickCount();
			PlanationRef.current.style.color = '#FF3131';
		},
		isBlankPw: () => {
			PWRef.current.setDefaultPlaceholder('올바르지 않은 비밀번호 입니다.');
			PWRef.current.plusClickCount();
			PlanationRef.current.style.color = '#FF3131';
		},
		failLogin: () => {
			IdRef.current.setValue('');
			PWRef.current.setValue('');
			IdRef.current.setDefaultPlaceholder('올바르지 않은 아이디 입니다.');
			PWRef.current.setDefaultPlaceholder('올바르지 않은 비밀번호 입니다.');
			PlanationRef.current.style.color = '#FF3131';
		},
		setOpacity: (val) => {
			TopLevelRef.current.style.opacity = val;
			if (val === 0) TopLevelRef.current.style.zIndex = -1;
			else TopLevelRef.current.style.zIndex = 1;
		},
		setLocation: (val) => {
			TopLevelRef.current.style.transform = `translateX(${val}%)`;
			setIdClickCount(0);
			setPwClickCount(0);
			IdRef.current.reset('사용자의 아이디를 입력해주세요');
			PWRef.current.reset('사용자의 패스워드를 입력해주세요');
			PlanationRef.current.style.color = '#FF8B48';
		}
	}));

	useEffect(() => {
		if (idClickCount === 0 && pwClickCount === 0) return;

		if (IdRef.current.getValue() === '' && idClickCount > 0) PlanationRef.current.style.color = '#FF3131';
		else if (PWRef.current.getValue() === '' && pwClickCount > 0) PlanationRef.current.style.color = '#FF3131';
		else PlanationRef.current.style.color = '#FF8B48';
	}, [idClickCount, pwClickCount])

	function clickCountFn(className, clickCount) {
		if (className === 'IdInput') setIdClickCount(clickCount);
		else setPwClickCount(clickCount);
	}

	return (
		<TopLevelWrapper ref={TopLevelRef}>
			<InputInfo>아이디</InputInfo>
			<AccountInput ref={IdRef} className='IdInput' placeholder='사용자의 아이디를 입력해주세요' type='text' clickCountFn={clickCountFn} />
			<InputInfo>비밀번호</InputInfo>
			<AccountPasswordInput ref={PWRef} className='PWInput' placeholder='사용자의 패스워드를 입력해주세요' type='password' clickCountFn={clickCountFn} onClickLoginButton={onClickLoginButton}/>
			<AccountExplanation ref={PlanationRef} onClick={() => history.push('/identifier')}>아이디/비밀번호를 잊어버리셨나요?</AccountExplanation>
		</TopLevelWrapper>
	)
})

export default LoginInfo;

const TopLevelWrapper = styled.div`
	position: absolute;
	display: flex;
	flex-direction: column; 
	justify-content: flex-start;

	z-index: 1;
	width: 468px;
	// width: max(271px, 24.375vw);
	// width: max(250px, 24.375vw);
	height: 281px;
	// height: max(149.5px, 14.63541666666667vw);

	opacity: 1;

	margin-top: 130px;
	// margin-top: max(80px, 6.770833333333333vw);
	// margin-top: max(73.5px, 7.1875vw);

	background-color: rgba(255, 255, 255, 1);
  transition: all 0.3s ease-in-out;
`;

const InputInfo = styled.p`
	font-family: NotoSansKR-Regular;
	font-size: 14px;
	// font-size: max(12px, 0.7291666666666667vw);
	// line-height: 20px;

	margin: 40px 0 0 0;
	// margin-top: max(31.73px, 2.083333333333333vw);
	// margin-right: 0px;
	// margin-bottom: 0px;
	// margin-left: 0px;

	cursor: default;
`;

const AccountExplanation = styled.p`
	font-family: NotoSansKR-Regular;
	font-size: 12px;
	// font-size: max(12px, 0.625vw);
	// line-height: 17.38px;

	color: ${props => props.bold ? `#FF3131` : `#FF8B48`};

	margin: 4px 0 0 0;

	:hover {
		cursor: pointer;
	}
	
  transition: all 0.5s ease-in-out;
`;

