import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import styled from "styled-components";

import FetchModule from "../../../../Share/Network/FetchModule";

import GrayExitPath from '../../../../../image/GrayExit.png';
import LeftArrow from '../../../../../image/WhiteLeftArrow.png';
import RightArrow from '../../../../../image/WhiteRightArrow.png';
import PaymentInput from "../../../../../Component/MemberComponent/PaymentInput";
import DiscountPaymentInput from "../../../../../Component/MemberComponent/DiscountPaymentInput";
import ProductCountInput from "../../../../../Component/MemberComponent/ProductCountInput";

const ProductSalesAddModal = forwardRef((props, ref) => {
  const { clickUsageData, onRegist, onClickExit } = props;

  const [title, setTitle] = useState('구입 물품 선택');
  const [producs, setProducts] = useState([]);
  const [nowPage, setNowPage] = useState(1);
  const [page, setPage] = useState(1);
  const [modalWidth, setModalWidth] = useState(280);
  const [modalHeight, setModalHeight] = useState(342);
  const [productViewWidth, setProductViewWidth] = useState(200);
  const [productViewHeight, setProductViewHeight] = useState(240);
  const [productSalesWidth, setProductSalesWidth] = useState(0);
  const [productSalesHeight, setProductSalesHeight] = useState(0);
  const [clickData, setClickData] = useState(undefined);
  const [count, setCount] = useState(1);
  const [notReceivedPrice, setNotReceivedPrice] = useState(0);

  const ModalRef = useRef();
  const ProductRef = useRef();
  const CardPaymentRef = useRef();
  const CashPaymentRef = useRef();
  const DiscountPaymentRef = useRef();

  useImperativeHandle(ref, () => ({
    getProductData: () => {
      return clickData;
    },
    getProductId: () => {
      return clickData.id;
    },
    getProductName: () => {
      return clickData.name;
    },
    getCount: () => {
      return count;
    },
    getCardPrice: () => {
      return CardPaymentRef.current.getValue();
    },
    getCashPrice: () => {
      return CashPaymentRef.current.getValue();
    },
    getDiscountPrice: () => {
      return DiscountPaymentRef.current.getValue();
    },
    getNotReceivedPrice: () => {
      return notReceivedPrice;
    },
    // setClickData: (value) => {
    //   setClickData(value);
    //   setTitle('물품 추가하기')
      
    //   setModalWidth(424);
    //   setModalHeight(321);
  
    //   setProductViewWidth(0);
    //   setProductViewHeight(0);

    //   setProductSalesHeight(252);
    // }
  }))

  // 스크롤 제어 //
  useEffect(() => {
    document.body.style = `overflow: hidden`;
    return () => document.body.style = `overflow: auto`
  }, [])

  // API Call //
  useEffect(() => {
    // if (clickUsageData !== undefined) return;

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getIndividualData('product/', 'GET');

        if (responseData.status === 200) {
          let list = responseData.data.slice();
          let page = Math.ceil(list.length / 8);
          let finalList = [];

          if (clickUsageData === undefined) {
            for (let i = 0; i < page; i++) {
              let newList = list.slice((i * 8), (i * 8) + 8);
              finalList.push({ list: newList });
            }
  
            if (list.length >= 5) {
              setModalWidth(872);
              setModalHeight(592);
  
              setProductViewWidth(872);
              setProductViewHeight(504);
            }
            if (list.length >= 4) {
              setModalWidth(872);
              setProductViewWidth(872);
            }
            else if (list.length >= 3) {
              setModalWidth(648);
              setProductViewWidth(648);
            }
            else if (list.length >= 2) {
              setModalWidth(424);
              setProductViewWidth(424);
            }
            else {
              setModalWidth(342);
              setProductViewWidth(200);
            }
  
            setPage(page);
            setProducts(finalList);
  
            // ModalRef.current.style.opacity = 1;
            // ModalRef.current.style.transition = `all 0.3s ease-in-out`;
            setTimeout(() => (
              ModalRef.current.style.opacity = 1,
              ModalRef.current.style.transition = `all 0.3s ease-in-out`
            ), 100)
          }
          else {
            let saledProduct = undefined;
            
            for (let i = 0; i < list.length; i++) {
              if (list[i].id === clickUsageData.productId) {
                saledProduct = list[i];
                break;
              }
            }
            
            setClickData(saledProduct);

            CardPaymentRef.current.setValue(clickUsageData.cardPrice);
            CashPaymentRef.current.setValue(clickUsageData.cashPrice);
            DiscountPaymentRef.current.setValue(clickUsageData.discountPrice);
            
            setNotReceivedPrice(clickUsageData.notReceivedPrice);
            setCount(clickUsageData.count);

            setTitle('물품 구매내역 수정')
            
            setModalWidth(424);
            setModalHeight(321);

            setProductViewWidth(0);
            setProductViewHeight(0);

            setProductSalesHeight(252);

            setTimeout(() => (
              ModalRef.current.style.opacity = 1,
              ModalRef.current.style.transition = `all 0.3s ease-in-out`
            ), 300)
          }
         
          // setProducts(responseData.data);
        }
      }
    )();
  }, [clickUsageData])

  useEffect(() => {
    if (clickData === undefined) return;
    
    let card = CardPaymentRef.current.getValue();
    let cash = CashPaymentRef.current.getValue();
    let discount = DiscountPaymentRef.current.getValue();
    
    setNotReceivedPrice(((clickData.price * count) - (card + cash + discount)) > 0 ? ((clickData.price * count) - (card + cash + discount)) : 0);
  }, [clickData, count])

  // 상품 클릭 후 사진이 있는지 확인 //
  function onCalcIsExistProductView() {
    if (clickData === undefined) return true;
    else if (clickData.picture === '') return true;
    else return false;
  }

  // 상품 클릭 후 사진표출 계산 //
  function onCalcProductView() {
    if (clickData === undefined) return '';
    else if (clickData.picture === '') return 'https://picture.link-zone.org/DefaultLogo.png';
    else return clickData.picture;
  }

  // 상품 클릭 후 이름 표출 계산 //
  function onCalcProductName() {
    if (clickData === undefined) return '';
    else return clickData.name;
  }

  // 상품 클릭 후 재고개수 표출 계산 //
  function onCalcProductInventory() {
    if (clickData === undefined) return '0';
    else return clickData.inventory.toLocaleString('ko-KR');
  }

  // 상품 클릭 후 가격 표출 계산 //
  function onCalcProductPrice() {
    if (clickData === undefined) return '0';
    else return clickData.price.toLocaleString('ko-KR');
  }

  // 상품 클릭 후 상품의 원 가격 * 개수 표출 계산 //
  function onCalcProductSalePrice() {
    if (clickData === undefined) return '0';
    else return (clickData.price * count).toLocaleString('ko-KR');
  }

  // 상품 클릭 후 상품의 결제 후 미수금 가격 표출 계산 //
  function onCalcProductNotReceivedPrice() {
    if (clickData === undefined) return '0';
    else return notReceivedPrice.toLocaleString('ko-KR');
  }

  // 다음페이지 버튼 클릭시 //
  function onClickNextPage() {
    if (nowPage < page) {
      ProductRef.current.style.transform = `translateX(-${(productViewWidth + 40) * nowPage}px)`;
      setNowPage(nowPage + 1);
    }
  }

  // 이전페이지 버튼 클릭시 //
  function onClickPrevPage() {
    if (nowPage > 1) {
      ProductRef.current.style.transform = `translateX(-${((productViewWidth + 40) * (nowPage - 2))}px)`;
      setNowPage(nowPage - 1);
    }
  }

  // 물품 클릭시 //
  function onClickProduct(data) {
    setClickData(data);
    setTitle('물품 추가하기')
    // setNotReceivedPrice(data.price * count);
    
    setModalWidth(424);
    setModalHeight(321);

    setProductViewWidth(0);
    setProductViewHeight(0);

    // setProductSalesWidth(456);
    setProductSalesHeight(252);
  }

  // 금액 입력시 미수금 정리 // 
  function changeValue() {
    if (clickData === undefined) return;

    let card = CardPaymentRef.current.getValue();
    let cash = CashPaymentRef.current.getValue();
    let discount = DiscountPaymentRef.current.getValue();

    // setTotalPrice(parseInt(card) + parseInt(cash));
    setNotReceivedPrice(((clickData.price * count) - (card + cash + discount)) > 0 ? ((clickData.price * count) - (card + cash + discount)) : 0);
  }

  // 저장하기 클릭시 //
  function onClickSave() {
    // console.log(clickData);
    // console.log(clickData);

    // if (clickData.inventory < 1 || clickData.inventory < count) {
    //   alert('재고가 부족합니다');
    //   return;
    // }

    onRegist();
  }

  return (
    <BackgroundWrapper>
      <Modal ref={ModalRef} width={modalWidth} height={modalHeight}>
        <InnerWrapper>
          <TitleWrapper>
            <Text fontFamily='NotoSansKR-Bold' fontSize={20} color="#000000" hover='default'>{title}</Text>
            {
              clickData === undefined &&
              <TitleExitButtonWrapper onClick={onClickExit}>
                <TitleExitButton src={GrayExitPath} />
              </TitleExitButtonWrapper>
            }
          </TitleWrapper>
          <ProductSelectView width={productViewWidth} height={productViewHeight}>
            {
              (clickData === undefined && page > 1) &&
              <ButtonWrapper>
                <Button onClick={onClickPrevPage} isView={nowPage > 1} style={{ marginLeft: '-70px' }} >
                  <ArrowImage style={{ marginLeft: '-4px' }} src={LeftArrow} />
                </Button>
                <Button onClick={onClickNextPage} isView={nowPage < page} style={{ marginRight: '-70px' }} >
                  <ArrowImage style={{ marginRight: '-4px' }} src={RightArrow} />
                </Button>
              </ButtonWrapper>
            }
            <TotalProductView width={productViewWidth} height={productViewHeight}>
              <ProductViewWrapper ref={ProductRef}>
                {
                  producs.map((data, index) => (
                    <ProductView key={index} width={productViewWidth}>
                      {
                        data.list.map((innerData, innerIndex) => (
                          <ProductBoxView key={innerIndex} onClick={() => onClickProduct(innerData)}>
                            <ProductImageWrapper>
                              <ProductImage isEmpty={innerData.picture === ''} src={innerData.picture === '' ? 'https://picture.link-zone.org/DefaultLogo.png' : innerData.picture} />
                            </ProductImageWrapper>
                            <ProductInfoBox>
                              <ProductInfoWrapper>
                                <ProductTextWrapper width={60} alignItems='flex-start'>
                                  <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF'>{innerData.name}</Text>
                                </ProductTextWrapper>
                                <ProductTextWrapper width={30} alignItems='flex-end'>
                                  <Text fontFamily='NotoSansKR-Medium' fontSize={15} color='#FFFFFF'>{innerData.inventory.toLocaleString('ko-KR')}개</Text>
                                </ProductTextWrapper>
                              </ProductInfoWrapper>
                              <ProductInfoWrapper>
                                <ProductTextWrapper width={20} alignItems='flex-start'>
                                  <Text fontFamily='NotoSansKR-Regular' fontSize={15} color='#FFFFFF'>가격</Text>
                                </ProductTextWrapper>
                                <ProductTextWrapper width={70} alignItems='flex-end'>
                                  <Text fontFamily='NotoSansKR-Medium' fontSize={15} color='#FFFFFF'>{innerData.price.toLocaleString('ko-KR')}원</Text>
                                </ProductTextWrapper>
                              </ProductInfoWrapper>
                            </ProductInfoBox>
                          </ProductBoxView>
                        ))
                      }
                    </ProductView>
                  ))
                }
              </ProductViewWrapper>
            </TotalProductView>
          </ProductSelectView>
          <ProductSalesView width={productSalesWidth} height={productSalesHeight}>
            <ProductBoxView>
              <ProductImageWrapper>
                <ProductImage isEmpty={onCalcIsExistProductView()} src={onCalcProductView()} />
              </ProductImageWrapper>
              <ProductInfoBox>
                <ProductInfoWrapper>
                  <ProductTextWrapper width={60} alignItems='flex-start'>
                    <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF'>{onCalcProductName()}</Text>
                  </ProductTextWrapper>
                  <ProductTextWrapper width={30} alignItems='flex-end'>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={15} color='#FFFFFF'>{onCalcProductInventory()}개</Text>
                  </ProductTextWrapper>
                </ProductInfoWrapper>
                <ProductInfoWrapper>
                  <ProductTextWrapper width={20} alignItems='flex-start'>
                    <Text fontFamily='NotoSansKR-Regular' fontSize={15} color='#FFFFFF'>가격</Text>
                  </ProductTextWrapper>
                  <ProductTextWrapper width={70} alignItems='flex-end'>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={15} color='#FFFFFF'>{onCalcProductPrice()}원</Text>
                  </ProductTextWrapper>
                </ProductInfoWrapper>
              </ProductInfoBox>
            </ProductBoxView>
            <ProductSalesInfoBox>
              <LayerWrapper>
                <ComponentWrapper>
                  <ComponentTitle>물품 가격</ComponentTitle>
                  <CalcPaymentInputWrapper>
                    <CalcPaymentInput>{onCalcProductSalePrice()}</CalcPaymentInput>
                    <CalcPaymentUnit>원</CalcPaymentUnit>
                  </CalcPaymentInputWrapper>
                </ComponentWrapper>
                <ComponentWrapper>
                  <ComponentTitle>구매 개수</ComponentTitle>
                  <ProductCountInput value={count} setValue={setCount} width={77}/>
                </ComponentWrapper>
              </LayerWrapper>
              <LayerWrapper style={{ marginTop: `8px` }}>
                <ComponentWrapper>
                  <ComponentTitle>카드 결제 금액</ComponentTitle>
                  <PaymentInput ref={CardPaymentRef} changeFn={changeValue} width={77} placeholder={0} disabled={false} />
                </ComponentWrapper>
                <ComponentWrapper>
                  <ComponentTitle>현금 결제 금액</ComponentTitle>
                  <PaymentInput ref={CashPaymentRef} changeFn={changeValue} width={77} placeholder={0} disabled={false} />
                </ComponentWrapper>
              </LayerWrapper>
              <LayerWrapper>
                <ComponentWrapper>
                  <ComponentTitle>할인 금액</ComponentTitle>
                  <DiscountPaymentInput ref={DiscountPaymentRef} changeFn={changeValue} width={77} placeholder={0} disabled={false} />
                </ComponentWrapper>
                <ComponentWrapper>
                  <ComponentTitle>미수금 금액</ComponentTitle>
                  <CalcPaymentInputWrapper>
                    <NotReceivedPaymentInput>{onCalcProductNotReceivedPrice()}</NotReceivedPaymentInput>
                    <CalcPaymentUnit>원</CalcPaymentUnit>
                  </CalcPaymentInputWrapper>
                </ComponentWrapper>
              </LayerWrapper>
              <SaveButtonWrapper>
                <ExitButton onClick={onClickExit}>
                  <Text fontFmaily='NotoSansKR-Regular' fontSize={14} color='#CBCBCB' cursor='pointer'>취소</Text>
                </ExitButton>
                <SaveButton onClick={onClickSave}>      
                  <Text fontFmaily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='pointer'>저장하기</Text>
                </SaveButton>
              </SaveButtonWrapper>
            </ProductSalesInfoBox>
          </ProductSalesView>
        </InnerWrapper>
      </Modal>
    </BackgroundWrapper>
  )
})

export default ProductSalesAddModal;

const BackgroundWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 1000;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.2);
`;

const Modal = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  padding 16px 40px 26px 40px;

  opacity: 0;

  // min-width: 280px;
  // max-width: 956px;
  // min-width: 200px;
  // max-width: 876px;
  width: ${props => props.width}px;

  // min-height: 342px;
  // max-height: 592px;
  // min-height: 300px;
  // max-height: 592px;
  height: ${props => props.height}px;

  border-radius: 20px;
  background-color: #FFFFFF;

  // overflow: hidden;

  // transition: all 0.5s ease-in-out;
`;

const InnerWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  // justify-content: flex-start;
  // align-items: flex-start;
  justify-content: center;
  align-items: center;

  // gap: 21px;
  gap: 16px;

  // width: 91.21%;

  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const TitleExitButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;

  :hover {
    cursor: pointer
  }
`;

const TitleExitButton = styled.img`
  width: 100%;
  height: 100%;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const Button = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 52px;
  height: 52px;

  border-radius: 52px;
  background-color: #FF8B48;

  opacity: ${props => props.isView ? 1 : 0};
  
  :hover {
    cursor: ${props => props.isView ? `pointer` : `default`};
  }
`;

const ArrowImage = styled.img`
  width: 14px;
  height: 24px;
`;

const ProductSelectView = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: ${props => props.width}px;
  height: ${props => props.height}px;

  overflow: hidden;
`;

const TotalProductView = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: ${props => props.width}px;
  height: ${props => props.height}px;

  overflow: hidden;
`;

const ProductViewWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 40px;

  transition: all 0.5s ease-in-out;
`;

const ProductView = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  flex-wrap: wrap;

  gap: 24px;

  // min-width: 200px;
  // max-width: 872px;
  
  width: ${props => props.width}px;

  min-height: 240px;
  max-height: 504px;
`;

const ProductBoxView = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 200px;
  height: 240px;

  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.10);
  border-radius: 24px 24px 10px 10px;
  background-color: #FFFFFF;

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const ProductSalesView = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  // width: ${props => props.width}px;
  width: 456px;
  height: ${props => props.height}px;

  overflow: hidden;

  transition: all 0.3s ease-in-out;
`;

const ProductSalesInfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 244px;
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ComponentTitle = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #595959;

  margin: 0 0 0 0;
`;

const CalcPaymentInputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 5px;
  margin-top: 8px;
`;

const CalcPaymentInput = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  padding: 0 8px 0 8px;
  margin: 0 0 0 0;

  width: 77px;
  height: 30px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background-color: #ECECEC;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;
`;

const CalcPaymentUnit = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  margin: 0 0 0 0;

  color: #777777;
`;

const NotReceivedPaymentInput = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  padding: 0 8px 0 8px;
  margin: 0 0 0 0;

  width: 77px;
  height: 30px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background-color: #FFFFFF;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #FF3131;
`;

const ProductImageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  // height: 198px;
  height: 179px;

  overflow: hidden;

  border-radius: 24px 24px 0px 0px;
`;

const ProductImage = styled.img`
  width: 100%;
  height: 100%;

  object-fit: ${props => props.isEmpty ? `contains` : `scale-down`};
  // object-fit: cover;
`;

const ProductInfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  // height: 66px;
  height: 60px;

  gap: 4px;

  border-radius: 0px 0px 10px 10px;
  background-color: #000000;
`;

const ProductInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 86.36%;
  height: 25px;
`;

const ProductTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  // justify-content: center;
  justify-content: flex-start;
  align-items: ${props => props.alignItems};

  width: ${props => props.width}%;
  height: 100%;

  overflow: hidden;
`;

const SaveButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: flex-start;

  margin-top: 20px;

  width: 100%;

  gap: 16px;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 72px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;

  :hover {
    cursor: pointer;
  }
`;

const SaveButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 15px;
  background-color: #5BD98A;

  :hover {
    cursor: pointer;
  }
`;

const Text = styled.p`
	font-family: ${props => props.fontFamily};
	font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  padding: 0 0 0 0;

  :hover {
    cursor: ${props => props.hover};
  }
`;
