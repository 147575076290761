import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

import BlackArrow from '../../../image/BlackDownArrow.png';
import OrangeArrow from '../../../image/OrangeDownArrow.png';

export default function SelectStatusBox(props) {
  const {
    isClickedValidSorting,
    isClickedExpectedSorting,
    setIsClickedValidSorting,
    setIsClickedExpectedSorting
  } = props;

  const [isClicked, setIsClicked] = useState(false);

  const ButtonRef = useRef(null);
  const SelectBoxRef = useRef(null);

  // 컴포넌트 외부 클릭시 이벤트를 받기위한 리스너 연결
  useEffect(() => {
    document.addEventListener("mousedown", onClickOutSide);

    return () => {
      document.removeEventListener("mousedown", onClickOutSide);
    }
  }, [])

  // 컴포넌트 외부 클릭시 실행
  function onClickOutSide(e) {
    if (SelectBoxRef.current !== e.target 
      && !SelectBoxRef.current.contains(e.target)
      && ButtonRef.current !== e.target 
      && !ButtonRef.current.contains(e.target)) {
        setIsClicked(false);
    }
  }

  return (
    <StatusBoxWrapper>
      <StatusBox ref={ButtonRef} isClicked={isClicked} onClick={() => setIsClicked(!isClicked)}>
        <Text fontFamily='NotoSansKR-Regular' fontSize={12} color={isClicked ? `#FF8B48` : `#3E4148`} hover='pointer'>상태</Text>
        {
          (!isClickedValidSorting && !isClickedExpectedSorting) &&
          <ArrowWrapper isClicked={isClicked}>
            <Arrow src={BlackArrow} opacity={isClicked ? 0 : 1} />
            <Arrow src={OrangeArrow} opacity={isClicked ? 1 : 0} />
          </ArrowWrapper>
        }
        {
          (isClickedValidSorting || isClickedExpectedSorting) &&
          <Text fontFamily='NotoSansKR-Regular' fontSize={12} color={isClicked ? `#FF8B48` : `#3E4148`} hover='pointer'>:</Text>
        }
        {
          isClickedValidSorting &&
          <Text fontFamily='NotoSansKR-Regular' fontSize={12} color={isClicked ? `#FF8B48` : `#3E4148`} hover='pointer'>{isClickedExpectedSorting ? `유효,` : `유효`}</Text>
        }
        {
          isClickedExpectedSorting &&
          <Text fontFamily='NotoSansKR-Regular' fontSize={12} color={isClicked ? `#FF8B48` : `#3E4148`} hover='pointer'>예정</Text>
        }
      </StatusBox>
      <SelectBox ref={SelectBoxRef} isClicked={isClicked}>
        <Option onClick={() => setIsClickedValidSorting(!isClickedValidSorting)}>
          <CheckBox isClicked={isClickedValidSorting} />
          <Text style={{ marginTop: '-2px' }} fontFamily='NotoSansKR-Regular' fontSize={14} color='$595959' hover='pointer'>유효</Text>
        </Option>
        <Option onClick={() => setIsClickedExpectedSorting(!isClickedExpectedSorting)}>
          <CheckBox isClicked={isClickedExpectedSorting} />
          <Text style={{ marginTop: '-2px' }} fontFamily='NotoSansKR-Regular' fontSize={14} color='$595959' hover='pointer'>예정</Text>
        </Option>
      </SelectBox>
    </StatusBoxWrapper>
  )
}

const StatusBoxWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const StatusBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  padding: 0px 4px;

  height: 20px;

  gap: 4px;

  border: ${props => props.isClicked ? `1px solid #FF8B48` : `1px solid #DBDDE4`};
  border-radius: 5px;
  background-color: ${props => props.isClicked ? `#FFFFFF` : `#F5F6F8`};

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const ArrowWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 10px;
  height: 6px;

  transition: all 0.3s ease-in-out;

  transform: ${props => props.isClicked ? `rotateZ(-180deg)` : `rotateZ(0deg)`};
`;

const Arrow = styled.img`
  position: absolute;

  width: 10px;
  height: 6px;

  opacity: ${props => props.opacity};

  transition: all 0.3s ease-in-out;
`;

const SelectBox = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  margin-top: 26px;

  width: 88px;
  height: ${props => props.isClicked ? `70px` : `0px`};

  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  border-radius: 10px;
  background-color: #FFFFFF;

  overflow: hidden;

  transition: all 0.3s ease-in-out;
`;

const Option = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  padding: 0px 0px 0px 6px;

  width: calc(100% - 6px);
  height: 35px;

  gap: 6px;
  
  :hover {
    cursor: pointer;
  }
`;

const CheckBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 16px;
  height: 16px;

  border: ${props => props.isClicked ? `1px solid #FF8B48` : `1px solid #969696`};
  border-radius: 5px;
  background-color: ${props => props.isClicked ? `#FF8B48` : `#FFFFFF`};
  
  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;