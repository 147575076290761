import { React, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import moment from 'moment';

import FetchModule from '../../Share/Network/FetchModule';
import OnSitePaymentMemberShipInfoView from './OnSitePaymentMemberShipInfoView';
import RegistrationLoading from './RegistrationLoading.js';
import WaitingPagePaymentModal from './WaitingPagePaymentModal.js';
import LinkPayMemberShipInfoView from './LinkPayMemberShipInfoView.js';
// 하하 //
const OnSitePaymentModal = (props) => {
  const { 
    isUsePaymentSystem,
    userData, 
    merchantUid, 
    rerendering, 
    setRerendering, 
    cancel, 
    closeFn 
  } = props;

  const history = useHistory();

  const [data, setData] = useState(undefined);
  const [isClicked, setIsClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [productName, setProductName] = useState('');
  const [productCoach, setProductCoach] = useState('');
  const [productValidity, setProductValidity] = useState(0);
  const [productPrice, setProductPrice] = useState(0);
  const [productCash, setProductCash] = useState(0);
  const [productDiscountPrice, setProductDiscountPrice] = useState(0);
  const [productNotReceivedPrice, setProductNotReceivedPrice] = useState(0);
  const [isLinkPay, setIsLinkPay] = useState(false);

  const [totalRegistData, setTotalRegistData] = useState(undefined);

  const InfoViewRef = useRef();

  // 스크롤 제어 //
  useEffect(() => {
    document.body.style= `overflow: hidden`;
    return () => document.body.style = `overflow: auto`;
  }, [])

  useEffect(() => {
    if (merchantUid === undefined) return;

    setIsLinkPay(merchantUid.split('-')[2] === '1');

    if (merchantUid.split('-')[2] === '0') {
      let requestData = {
        merchantUid: merchantUid
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postData('sales/merchant_auto_data/', 'POST', requestData);
          
          if (responseData.status === 200) {
            setData(responseData.data[0])
          }
          else if (responseData.status === 401) {
            alert('로그인 후 다시시도하세요');
            history.push('/');
          }
        }
      )();
    }
    else { 
      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postData('sales/merchant_data/' + merchantUid, 'POST');
         
          if (responseData.status === 200) {
            setData(responseData.data[0])
          }
          else if (responseData.status === 401) {
            alert('로그인 후 다시시도하세요');
            history.push('/');
          }
        }
      )();
    }
  }, [merchantUid])

  // useEffect(() => {
  //   console.log('======')
  //   console.log(userData);
  // }, [userData])

  // 등록하기 버튼 클릭시 //
  function onClickRegistrationButton() {
    var membershipData = InfoViewRef.current.getMemberShipData();
    var cardPrice = InfoViewRef.current.getCardPrice();
    var cashPrice = InfoViewRef.current.getCashPrice();
    var discountPrice = InfoViewRef.current.getDiscountPrice();
    var notReceivedPrice = InfoViewRef.current.getNotReceivedPrice();
    var memberShipId = InfoViewRef.current.getMemberShipId();
    var memberShipName = InfoViewRef.current.getMemberShipName();
    
    if (memberShipId === undefined) {
      alert('회원권을 선택해주세요!');
      return;
    }

    let checkStartDate = moment(startDate.replaceAll('.', '-') + 'T00:00:00');
    let checkEndDate = moment(endDate.replaceAll('.', '-') + 'T23:59:59');

    if (checkEndDate.isBefore(checkStartDate)) {
      alert('종료일자를 올바르게 입력해주세요');
      return;
    }
    
    setProductName(InfoViewRef.current.getMemberShipName());
    setProductCoach('');
    setProductValidity(membershipData.validity);
    setProductPrice(cardPrice);
    setProductCash(cashPrice);
    setProductDiscountPrice(discountPrice);
    setProductNotReceivedPrice(notReceivedPrice);


    let name = userData.userName;
    let birthDay = (userData.birthDay.slice(7, 8) === '1' || userData.birthDay.slice(7, 8) === '2') ? '19' + userData.birthDay.slice(0, 6) : '20' + userData.birthDay.slice(0, 6);
    let gender = (userData.birthDay.slice(7, 8) === '1' || userData.birthDay.slice(7, 8) === '3') ? 'male' : 'female';
    let phone = userData.phone;
    let id = userData.userId;
    // let memberShipName = productName;
    let profile = userData.profile;
    let totalMax = InfoViewRef.current.getTotalMax();
    // let notReceivedPrice = InfoViewRef.current.getNotReceivedPrice();
    // let memberShipId = InfoViewRef.current.getMemberShipId();

    let registData = {
      name: name,
      birthDay: birthDay,
      gender: gender,
      phone: phone,
      linkCoachId: id,
      storeId: parseInt(window.localStorage.getItem('StoreId')),
      memberShipName: memberShipName,
      profile: profile,
      memberDetail: '',
      approval: moment().format('YYYY-MM-DDTHH:mm:ss'),
      totalMax: totalMax,
      startDate: moment(startDate).format('YYYY-MM-DDTHH:mm:ss'),
      endDate: moment(endDate).format('YYYY-MM-DDTHH:mm:ss'),
      memberShipId: memberShipId,
      cardPrice: cardPrice,
      cashPrice: cashPrice,
      discount: discountPrice,
      notReceived: notReceivedPrice
      // merchantUid: merchant_uid
    }

    // 링크 페이 결제의 경우 //
    if (isUsePaymentSystem && userData.isUseLinkPay && isLinkPay) {
      setTotalRegistData(registData);
      onClickPaymentOnPayple(registData);
    }
    // 일반 결제의 경우 //
    else {
      setTotalRegistData(registData);
      setIsClicked(true);
    }
  }

   // 링크페이 결제자의 신청을 승인시 //
   function onClickPaymentOnPayple(totalRegistData) {
    if (isLoading) {
      alert('결제를 진행중입니다!');
      return;
    }
    
    setIsLoading(true);
    
    // setTimeout(() => {
    //   setIsLoading(false);
    // }, 3000);
    
    totalRegistData.merchantUid = merchantUid;

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataVersion2('member/registration/waiting/linkpay', 'POST', totalRegistData);

        // console.log(responseData);
        if (responseData.status === 200) {
          // onRegist(merchant_uid);
          cancel(responseData.data);
        }
        else if (responseData.status === 201) {
          alert('링크코치 가입자가 아닙니다.');
          setIsLoading(false);
          return;
        }
        else if (responseData.status === 202) {
          alert('데이터가 존재하지 않습니다');
          setIsLoading(false);
          return;
        }
        else if (responseData.status === 203) {
          alert('데이터가 존재하지 않습니다');
          setIsLoading(false);
          return;
        }
        else if (responseData.status === 204) {
          alert('회원님의 카드가 등록되어있지 않습니다.\n등록 후 사용해주세요');
          setIsLoading(false);
          return;
        }
        else if (responseData.status === 205) {
          alert('결제중 에러가 발생하였습니다.\n잠시 후 다시시도해 주세요');
          setIsLoading(false);
          return;
        }
        else {
          alert(responseData.error);
          setIsLoading(false);
          return;
        }
      }
    )();
  }

  // function cancelFunction(merchant_uid) {
  //   var name = userData.userName;
  //   var birthDay = (userData.birthDay.slice(7, 8) === '1' || userData.birthDay.slice(7, 8) === '2') ? '19' + userData.birthDay.slice(0, 6) : '20' + userData.birthDay.slice(0, 6);
  //   var gender = (userData.birthDay.slice(7, 8) === '1' || userData.birthDay.slice(7, 8) === '3') ? 'male' : 'female';
  //   var phone = userData.phone;
  //   var id = userData.userId;
  //   var memberShipName = productName;
  //   var profile = userData.profile;
  //   var totalMax = InfoViewRef.current.getTotalMax();
  //   var notReceivedPrice = InfoViewRef.current.getNotReceivedPrice();
  //   var memberShipId = InfoViewRef.current.getMemberShipId();

  //   var requestData = {
  //     name: name,
  //     birthDay: birthDay,
  //     gender: gender,
  //     phone: phone,
  //     linkCoachId: id,
  //     // storeId: SessionStorage.getStoreId(),
  //     storeId: window.localStorage.getItem('StoreId'),
  //     memberShipName: memberShipName,
  //     profile: profile,
  //     memberDetail: '',
  //     approval: moment().format('YYYY-MM-DDTHH:mm:ss'),
  //     totalMax: totalMax,
  //     startDate: moment(startDate).format('YYYY-MM-DDTHH:mm:ss'),
  //     endDate: moment(endDate).format('YYYY-MM-DDTHH:mm:ss'),
  //     memberShipId: memberShipId
  //   }
  //   // console.log(requestData);
  //   const fetchModule = new FetchModule();
  //   (
  //     async () => {
  //       const responseData = await fetchModule.postData('member/registration/' + merchant_uid, 'POST', requestData);

  //       if (responseData.status === 200) {
  //         (
  //           async () => {
  //             const coachResponseData = await fetchModule.postDataOfCoach('registration/modify/' + userData.id + '/' + merchant_uid + '/' + id + '/' + 1, 'POST');
             
  //             if (coachResponseData.status === 200) {
  //               // alert("등록이 완료되었습니다.");
  //               setIsLoading(false);
  //               cancel(responseData.data[0]);
  //               setRerendering(!rerendering);
  //             }
  //             else if (coachResponseData.status === 401) {
  //               setIsLoading(false);
  //               alert('로그인 후 다시시도하세요');
  //               history.push('/');
  //             }
  //           }
  //         )();
  //       }
  //       else if (responseData.status === 401) {
  //         setIsLoading(false);
  //         alert('로그인 후 사용해주세요');
  //         history.push('/');
  //       }
  //       else {
  //         setIsLoading(false);
  //         alert("회원등록에 실패하였습니다. 잠시후 다시시도해 주세요.");
  //         return;
  //       } 
  //     }
  //   )();

  //   setIsClicked(false);
  //   // cancel();
  //   closeF ㅁ();
  // }
  function cancelFunction(merchant_uid) {
    var name = userData.userName;
    var birthDay = (userData.birthDay.slice(7, 8) === '1' || userData.birthDay.slice(7, 8) === '2') ? '19' + userData.birthDay.slice(0, 6) : '20' + userData.birthDay.slice(0, 6);
    var gender = (userData.birthDay.slice(7, 8) === '1' || userData.birthDay.slice(7, 8) === '3') ? 'male' : 'female';
    var phone = userData.phone;
    var id = userData.userId;
    var memberShipName = productName;
    var profile = userData.profile;
    var totalMax = InfoViewRef.current.getTotalMax();
    var notReceivedPrice = InfoViewRef.current.getNotReceivedPrice();
    var memberShipId = InfoViewRef.current.getMemberShipId();

    var requestData = {
      name: name,
      birthDay: birthDay,
      gender: gender,
      phone: phone,
      linkCoachId: id,
      storeId: window.localStorage.getItem('StoreId'),
      memberShipName: memberShipName,
      profile: profile,
      memberDetail: '',
      approval: moment().format('YYYY-MM-DDTHH:mm:ss'),
      totalMax: totalMax,
      startDate: moment(startDate).format('YYYY-MM-DDTHH:mm:ss'),
      endDate: moment(endDate).format('YYYY-MM-DDTHH:mm:ss'),
      memberShipId: memberShipId,
      merchantUid: merchant_uid
    }
    // console.log(startDate);
    // console.log(endDate);
    // console.log(moment(startDate).format('YYYY.MM.DD HH:mm:ss'));
    // console.log(moment(endDate).format('YYYY.MM.DD HH:mm:ss'));
    // console.log(moment().format('YYYY.MM.DD HH:mm:ss'));
    // console.log(requestData);
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataVersion2('member/registration', 'POST', requestData);

        if (responseData.status === 200) {
          setIsLoading(false);
          cancel(responseData.data);
          setRerendering(!rerendering);
        }
        else if (responseData.status === 201) {
          alert('구매 내역이 존재하지 않습니다');
          return;
        }
        else if (responseData.status === 202) {
          alert('아직 결제가 진행되지 않은 건 입니다');
          return;
        }
        else if (responseData.status === 401) {
          setIsLoading(false);
          alert('로그인 후 사용해주세요');
          history.push('/');
        }
        else {
          setIsLoading(false);
          alert("회원등록에 실패하였습니다. 잠시후 다시시도해 주세요.");
          return;
        } 
      }
    )();

    setIsClicked(false);
    closeFn();
  }

  function getApprovalDate() {
    return moment(InfoViewRef.current.getApprovalDate()).format('YYYY-MM-DDTHH:mm:ss');
  }

  // 결제 종료 후 모달창 끄기 //
  function onCompletePaymentForLinkpay(memberData) {
    setIsLoading(false);
    cancel(memberData);
    setRerendering(!rerendering);
    setIsClicked(false);
    closeFn();
  }

  return (
    <TopLevelWrapper>
      { isLoading && <RegistrationLoading/> }
      {/* {
        isClicked &&
        <PaymentModal
          name={productName}
          coach={productCoach}
          validity={productValidity}
          cashPrice={productCash}
          price={productPrice}
          discount={productDiscountPrice}
          notReceived={productNotReceivedPrice}
          merchantUid={merchantUid}
          onRegist={cancelFunction}
          cancel={() => setIsClicked(false)} 
          setIsLoading={setIsLoading}
          getApprovalDate={getApprovalDate}/>
      } */}
      {
        isClicked &&
        <WaitingPagePaymentModal
          isUsePaymentSystem={isUsePaymentSystem}
          name={productName}
          coach={productCoach}
          validity={productValidity}
          cashPrice={productCash}
          price={productPrice}
          discount={productDiscountPrice}
          notReceived={productNotReceivedPrice}
          merchantUid={merchantUid}
          totalRegistData={totalRegistData}
          onRegist={cancelFunction}
          // cancel={() => setIsClicked(false)} 
          cancel={onCompletePaymentForLinkpay} 
          setIsLoading={setIsLoading}
          getApprovalDate={getApprovalDate}/>
      }
      {
        (isUsePaymentSystem && userData.isUseLinkPay && isLinkPay) ?
          <LinkPayMemberShipInfoView
            ref={InfoViewRef} 
            data={data} 
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            userData={userData}
            registerClick={onClickRegistrationButton}   
            closeFn={closeFn}/>
          :
          <OnSitePaymentMemberShipInfoView 
            ref={InfoViewRef} 
            data={data} 
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            userData={userData}
            registerClick={onClickRegistrationButton}   
            closeFn={closeFn}/>
      }
    </TopLevelWrapper> 
  )
}//

export default  OnSitePaymentModal;

const startAnimation = keyframes`
	0% { opacity: 0; }
	50% { opacity: 0.5; }
	100% { opacity: 1; }
`;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  // justify-content: center;
  justify-content: flex-start;
  align-items: center;

  z-index: 1000;

  width: 100vw;
  height: 100vh;

  overflow: scroll;

  background-color: rgba(0, 0, 0, 0.2);
	
	animation-name: ${startAnimation};
	animation-duration: 0.5s;
	animation-timing-function: ease-out;

  &::-webkit-scrollbar {
    height: 2px;
    width: 1px;
  }

  &::-webkit-scrollbar-thumb {
		border: 2px solid transparent;
		background-color: #DADADA;
		border-radius: 200px;
	}

  &::-webkit-scrollbar-track {
		background-color: #F0F0F0;
		border-radius: 200px;
	}
`;