import React, { useEffect, useState } from "react";
import styled from "styled-components";

import LegacyRankingBox from './Legacy/LegacyRankingBox';
import FetchModule from "../../Model/Share/Network/FetchModule";
import { organizationRanking, calcLegacyRanking } from "../Transaction/Transaction";
import UserProfile from '../../Component/Normal/UserProfile';

import FirstPlaceImg from '../../image/FirstPlace.png';
import SecondPlaceImg from '../../image/SecondPlace.png';
import ThirdPlaceImg from '../../image/ThirdPlace.png';
import RightImg from '../../image/NotAvailableRight.png';
import DefaultProfile from '../../image/DefaultProfile.png';


export default function RankingBox(props) {
  const {
    width,
    maxHeight,
    clickRankingData,
    nowSection,
    setNowSection,
    onClickRankingBox,
    onClickLegacyRankingBox
  } = props;

  const [legacyRankingData, setLegacyRankingData] = useState([]);
  const [legacyUseData, setLegacyUseData] = useState([]);

  const [rankingData, setRankingData] = useState([]);
  const [finalRank, setFinalRank] = useState([]);
  const [division, setDivision] = useState('Total');

  // useEffect(() => {
  //   console.log(clickRankingData);
  //   // console.log(clickRankingData.sectionList);
  // }, [clickRankingData])

  // Ranking Data API Call //
  useEffect(() => {
    if (clickRankingData === undefined) {
      setRankingData([]);
      return;
    }

    // 신버전 랭킹 데이터 불러오기 //
    if (clickRankingData.wodVersion === 2) {
      const fetchModule = new FetchModule();
      (
        async () => {
          let responseData = await fetchModule.getDataOfExerciseVersion2('record/' + clickRankingData.workoutId, 'GET');
          // console.log(responseData.data);
          if (responseData.status === 200) {

            setRankingData(responseData.data.sectionRank);
          }
          // else if (responseData.status === 204) setLegacyUseData([]);
        }
      )();
    }
    else {
      // setIsLoading(true);
      // console.log(clic)
      let requestData = {
        workoutId: clickRankingData.workoutId
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          let responseData = await fetchModule.postDataOfExerciseVersion2('workout/tmp/ranking', 'POST', requestData);
          // console.log(responseData);
          if (responseData.status === 200) {
            let workoutData = responseData.data.slice();

            setLegacyUseData(organizationRanking(workoutData));
          }
          else if (responseData.status === 204) setLegacyUseData([]);
        }
      )();
    }
  }, [clickRankingData])

  // 옛날버전 랭킹 산정 //
  useEffect(() => {
    if (legacyUseData.length === 0) {
      setLegacyRankingData([]);
      return;
    }

    let legacyRanking = calcLegacyRanking(legacyUseData, nowSection);

    setLegacyRankingData(legacyRanking);
  }, [legacyUseData, nowSection])

  // 새로운 버전 랭킹 구분 정리 //
  useEffect(() => {
    if (rankingData.length === 0 || nowSection === undefined) {
      setFinalRank([]);
      return;
    }

    let newList = [];

    if (division === 'Total') {
      newList = rankingData[nowSection].sectionRecords.slice();
    }
    for (let i = 0; i < rankingData[nowSection].sectionRecords.length; i++) {
      if (rankingData[nowSection].sectionRecords[i].gender === division) newList.push(rankingData[nowSection].sectionRecords[i]);
    }
    // console.log(newList);
    setFinalRank(newList);
  }, [rankingData, nowSection, division])


  // 한자리 숫자 앞에 0 붙이기 //
  function numberPad(n, width) {
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
  }

  // 점수 보여주기 //
  function onViewFinalScoreText(data) {
    if (data.tag === 'ForTime') {
      if (data.success === 'Success') {
        let min = parseInt(data.recordTime / 60);
        let sec = parseInt(data.recordTime % 60);

        return (
          <RowTextWrapper>
            <Text style={{ marginRight: 6 }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{numberPad(min, 2) + ':' + numberPad(sec, 2)}</Text>
            <Text style={{ marginRight: 6 }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#BDBDBD'>Time</Text>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.scoreText}</Text>
          </RowTextWrapper>
        )
      }
      else {
        return (
          <RowTextWrapper>
            <Text style={{ marginRight: 2 }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.completeRound}</Text>
            <Text style={{ marginRight: 6 }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#BDBDBD'>R</Text>
            <Text style={{ marginRight: 2 }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.lastRound}</Text>
            <Text style={{ marginRight: 6 }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#BDBDBD'>Reps</Text>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.scoreText}</Text>
          </RowTextWrapper>
        )
      }
    }
    else if (data.tag === 'AMRAP') {
      return (
        <RowTextWrapper>
          <Text style={{ marginRight: 2 }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.completeRound}</Text>
          <Text style={{ marginRight: 6 }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#BDBDBD'>R</Text>
          <Text style={{ marginRight: 2 }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.lastRound}</Text>
          <Text style={{ marginRight: 6 }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#BDBDBD'>Reps</Text>
          <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.scoreText}</Text>
        </RowTextWrapper>
      )
    }
    else if (data.tag === 'SINGLE') {
      return (
        <RowTextWrapper>
          <Text ellipsizeMode='tail' numberOfLines={1} fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.scoreText}</Text>
        </RowTextWrapper>
      )
    }
    else if (data.tag === 'EMOM' || data.tag === 'TABATA') {
      return (
        <RowTextWrapper >
          <Text ellipsizeMode='tail' numberOfLines={1} fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.scoreText}</Text>
        </RowTextWrapper>
      )
    }
    else if (data.tag === 'Custom') {
      if (data.scoreKeyword === 'Not Scored') {
        return (
          <RowTextWrapper>
            <NotScoredBox isSuccess={data.success === 'Success'}>
              <Text ellipsizeMode='tail' numberOfLines={1} fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF'>{data.success === 'Success' ? '운동 완료' : '운동 미수행'}</Text>
            </NotScoredBox>
          </RowTextWrapper>
        )
      }
      else if (data.scoreKeyword === 'Time') {
        let min = parseInt(data.recordTime / 60);
        let sec = parseInt(data.recordTime % 60);

        return (
          <RowTextWrapper>
            <Text style={{ marginRight: 6 }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{numberPad(min, 2) + ':' + numberPad(sec, 2)}</Text>
            <Text style={{ marginRight: 6 }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#BDBDBD'>Time</Text>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.scoreText}</Text>
          </RowTextWrapper>
        )
      }
      else if (data.scoreKeyword === 'Rounds And Reps') {
        return (
          <RowTextWrapper>
            <Text style={{ marginRight: 2 }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.customLastRound}</Text>
            <Text style={{ marginRight: 6 }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#BDBDBD'>R</Text>
            <Text style={{ marginRight: 2 }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.customLastReps}</Text>
            <Text style={{ marginRight: 6 }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#BDBDBD'>Reps</Text>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.scoreText}</Text>
          </RowTextWrapper>
        )
      }
      else if (data.scoreKeyword === 'Reps'
        || data.scoreKeyword === 'Distance'
        || data.scoreKeyword === 'Weight'
        || data.scoreKeyword === 'Points'
        || data.scoreKeyword === 'Peak Watts'
        || data.scoreKeyword === 'Max Speed'
      ) {
        return (
          <RowTextWrapper>
            <Text style={{ marginRight: 6 }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.customKeywordValue}</Text>
            <Text style={{ marginRight: 6 }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#BDBDBD'>{data.customKeywordUnit}</Text>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.scoreText}</Text>
          </RowTextWrapper>
        )
      }
    }
  }

  // // 옛날버전 랭킹 자세히보기 클릭시 //
  // function onClickViewRankingDetail(data) {
  //   for (let i = 0; i < legacyUseData[0].sectionList.length; i++) {
  //     if (legacyUseData[0].sectionList[i].linkCoachId === data.linkCoachId) {
  //       onClickRankingBox(legacyUseData[0].sectionList[i]);
  //       return;
  //     }
  //   }
  // }

  return (
    <TotalRankingBoxWrapper width={width} height={maxHeight}>
      {
        (clickRankingData !== undefined) &&
        <RankingBoxWrapper>
          <WodSectionButtonWrapper>
            {
              clickRankingData.sectionList.map((data, index) => (
                <WodSectionButton isClicked={nowSection === index} onClick={() => setNowSection(index)}>
                  <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF' hover='pointer'>{String.fromCharCode(index + 65)}</Text>
                </WodSectionButton>
              ))
            }
          </WodSectionButtonWrapper>
          {
            clickRankingData.wodVersion === 2 ?
              <RankingViewBox style={{ marginTop: '57px' }}>
                <DivisionWrapper>
                  <TotalDivisionBox isClicked={division === 'Total'} onClick={() => setDivision('Total')}>
                    <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF' hover='pointer'>통합</Text>
                  </TotalDivisionBox>
                  <FemaleDivisionBox isClicked={division === 'Female'} onClick={() => setDivision('Female')}>
                    <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF' hover='pointer'>여성</Text>
                  </FemaleDivisionBox>
                  <MaleDivisionBox isClicked={division === 'Male'} onClick={() => setDivision('Male')}>
                    <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF' hover='pointer'>남성</Text>
                  </MaleDivisionBox>
                </DivisionWrapper>
                <NewPrevRankingViewInnerWrapper>
                  <RankingInfoTextWrapper>
                    <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#666666' hover='default'>전체 순위</Text>
                  </RankingInfoTextWrapper>
                  <TotalRankingViewWrapper>
                  {
                    finalRank.map((data, index) => (
                      <RankingViewWrapper key={index}>
                        <RankingNumberBoxWrapper>
                          {
                            (index >= 0 && index < 3) ?
                              <RankingLeaderBoxWrapper>
                                <RankingLeaderBox src={index === 1 ? FirstPlaceImg : index === 2 ? SecondPlaceImg : ThirdPlaceImg} />
                                <RankingImageTextWrapper>
                                  <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF'>{index + 1}</Text>
                                </RankingImageTextWrapper>
                              </RankingLeaderBoxWrapper>
                              :
                              <RankingNumberBox>
                                <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF'>{index + 1}</Text>
                              </RankingNumberBox>
                          }
                        </RankingNumberBoxWrapper>
                        <RankingClickBox onClick={() => onClickRankingBox(data)}>
                          <InfoBox>
                            <LeftWrapper>
                              <ProfileWrapper>
                                <UserProfile profile={data.userProfile} defaultProfile={DefaultProfile} />
                              </ProfileWrapper>
                              <InfoTextWrapper>
                                <InfoRowTextWrapper>
                                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>{data.userName}</Text>
                                  {
                                    (data.derivationSectionId !== undefined) &&
                                    <ScaleBox>
                                      <Text fontFamily='Poppins-Medium' fontSize={12} color='#FFFFFF'>{String.fromCharCode(nowSection + 65) + '-' + (data.scaleSectionIndex + 1)}</Text>
                                    </ScaleBox>
                                  }
                                </InfoRowTextWrapper>
                                <ScoreTextWrapper>
                                  <Text style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap'}} fontFamily='NotoSansKR-Regular' fontSize={12} color='#DBDBDB'>{onViewFinalScoreText(data)}</Text>
                                </ScoreTextWrapper>
                              </InfoTextWrapper>
                            </LeftWrapper>
                            <DetailButton src={RightImg} />
                          </InfoBox>
                        </RankingClickBox>
                      </RankingViewWrapper>
                    ))
                  }
                  </TotalRankingViewWrapper>
                </NewPrevRankingViewInnerWrapper>
              </RankingViewBox>
              :
              <RankingViewBox style={{ marginTop: '26px' }}>
                <PrevRankingViewInnerWrapper>
                  <LegacyRankingBox
                    // sectionData={legacyUseData[0].sectionList}
                    sectionData={legacyUseData.length > 0 ? legacyUseData[0].sectionList : null}
                    rankingList={legacyRankingData}
                    onClickRankingBox={onClickLegacyRankingBox} />
                </PrevRankingViewInnerWrapper>
              </RankingViewBox>
          }
        </RankingBoxWrapper>
      }
    </TotalRankingBoxWrapper>
  )
}


const TotalRankingBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  // margin-bottom: 30px;

  width: ${props => props.width};
  min-width: ${props => props.width};
  height: ${props => props.height}px;
  min-height: ${props => props.height}px;

  transition: all 0.3s ease-in-out;
`;

const RankingBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 100%;

  overflow-x: hidden;
  
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);

  transition: all 0.3s ease-in-out;
`;

const WodSectionButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-item: center;

  padding: 0px 20px;
  margin-top: 12px;

  width: calc(100% - 40px);

  gap: 8px;
`;

const WodSectionButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 34px;
  height: 34px;

  border-radius: 6px;
  background-color: ${props => props.isClicked ? '#6DD49E' : '#CBCBCB'};

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const RankingViewBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 100%;
  height: calc(100% - 57px);
  // height: ${props => props.height}px;
  // min-height: ${props => props.height}px;

  // overflow-x: hidden;
  // overflow-y: scroll;
  overflow: hidden;

  outline: none;

  background-color: #F9F9F9;
`;

const DivisionWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100%;

  background-color: #FFFFFF;
`;

const TotalDivisionBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 33.33%;
  height: 31px;

  border-top-left-radius: 15px;
  border-top-right-radius: 15px;

  background-color: ${props => props.isClicked ? '#FFB800' : '#E0E0E0'};

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const FemaleDivisionBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 33.33%;
  height: 31px;

  border-top-left-radius: 15px;
  border-top-right-radius: 15px;

  background-color: ${props => props.isClicked ? '#FF8B48' : '#E0E0E0'};

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const MaleDivisionBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 33.33%;
  height: 31px;

  border-top-left-radius: 15px;
  border-top-right-radius: 15px;

  background-color: ${props => props.isClicked ? '#4D92EF' : '#E0E0E0'};

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const PrevRankingViewInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 100%;  

  overflow-x: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
		border: 0px solid transparent;
		background-color: #D9D9D9;
		border-radius: 2px;
	}

  &::-webkit-scrollbar-track {
		// background-color: #F9F9F9;
		border-radius: 200px;
    opacity: 0;
	}
`;

const NewPrevRankingViewInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  padding: 10px 0px 15px 0px;

  width: 96%;
  // min-height: 600px;

  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  overflow-x: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
		border: 0px solid transparent;
		background-color: #D9D9D9;
		border-radius: 2px;
	}

  &::-webkit-scrollbar-track {
		// background-color: #F9F9F9;
		border-radius: 200px;
    opacity: 0;
	}

  background-color: #F9F9F9;
`;

const RankingInfoTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: 100%;  
`;

const ScoreTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 320px;
  height: 20px;

  overflow: hidden;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  
  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;


const TotalRankingViewWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  margin-top: 16px;

  width: 100%;
  height: 100%;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
		border: 0px solid transparent;
		background-color: #D9D9D9;
		border-radius: 2px;
	}

  &::-webkit-scrollbar-track {
		// background-color: #F9F9F9;
		border-radius: 200px;
    opacity: 0;
	}

  gap: 20px;
`;

const RankingViewWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  width: 97.71%;
  height: 64px;
`;

const RankingNumberBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const RankingLeaderBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const RankingLeaderBox = styled.img`
  width: 40px;
  height: 40px;
`;

const RankingImageTextWrapper = styled.div`
  // position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // margin-top: -10px;
  margin-top: -36px;
`;

const RankingNumberBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;

  border-radius: 12px;
  background-color: #E0E0E0;
`;

const RankingClickBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: calc(90.66% - 2px);
  height: 62px;

  border: 1px solid #E0E0E0;
  border-radius: 12px;
  background-color: #FFFFFF;

  :hover {
    cursor: pointer;
    background-color: #F9F9F9;
  }

  transition: all 0.3s ease-in-out;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 95.7%;
`;

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const ProfileWrapper = styled.div`
  width: 40px;
  height: 40px;

  border-radius: 40px;

  overflow: hidden;
`;

const InfoTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 3px;
`;

const InfoRowTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

// const ScaleBox = styled.div`
//   display: flex;
//   flex-direction: column;

//   justify-content: center;
//   align-items: center;

//   width: 45px;
//   height: 18px;

//   border-radius: 6px;
//   background-color: #4161AF;
// `;

const DetailButton = styled.img`
  width: 9.6px;
  height: 17.7px;
`;

const RowTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 100%;
`;


const NotScoredBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  padding: 0px 10px;

  height: 21px;

  border-radius: 6px;
  background-color: ${props => props.isSuccess ? '#6DD49E' : '#D92828'};
`;

const ScaleBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-left: 4px;
  padding: 0px 10px;

  height: 16px;

  border-radius: 5px;
  background-color: #4D92EF;
`;
