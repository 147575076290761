import { React, useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import lodash from 'lodash';

import TextArea from '../../../Component/BoardComponent/NoticeTextArea';

import FetchModule from '../../Share/Network/FetchModule';

const NoticeView = (props) => {
  const { notepad, setNotepad, workoutId, clickDate } = props;

  const history = useHistory();

  const [isClicked, setIsClicked] = useState(false);

  const TextRef = useRef();

  function modifyButtonClick() {
    if (isClicked) {
      
      // 운동이 등록되어 있지 않을경우 //
      if (workoutId === 0) {
          alert('운동을 먼저 등록해주세요!');
          setIsClicked(!isClicked);
          return;
      }

      var contents = TextRef.current.getValue();

      if (contents === '') {
        setIsClicked(!isClicked);
        return;
      }

      var requestData = {
        id: notepad.id === 0 ? undefined : notepad.id,
        workoutId: workoutId,
        contents: contents
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataOfExercise('notepad/save', 'POST', requestData);
          // console.log(requestData);
          if (responseData.status === 200) {
            setNotepad(responseData.data[0]);
          }
          else if (responseData.status === 201) {
            alert("메모내용이 존재하지 않습니다!");
          }
          else if (responseData.status === 401) history.push('/');
        }
      )();
    }

    setIsClicked(!isClicked);
  }

  return (
    <TopLevelWrapper>
      <ViewWrapper>
        <TopLineWrapper>
          <Title>메모</Title>
          <ModifyButton isClicked={isClicked} onClick={modifyButtonClick}>{isClicked ? '완료' : '수정'}</ModifyButton>
        </TopLineWrapper>
        {
          isClicked ?
          <TextSection>
            <TextArea ref={TextRef} defaultValue={notepad.contents}/>
          </TextSection>
          :
          <TextSection>
            <Text>{notepad.contents}</Text>
          </TextSection>
        }
      </ViewWrapper>
    </TopLevelWrapper>
  )
}

export default NoticeView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 378px;
  height: 468px;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  background-color: #FFFFFF;
`;

const ViewWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const TopLineWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;
  
  width: 324px;
`;

const Title = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  margin: 0 0 0 0;

  color: #232323;
`;

const ModifyButton = styled.p`
  font-family: ${props => props.isClicked ? `NotoSansKR-Medium` : `NotoSansKR-Regular`};
  font-size: 14px;

  margin: 0 0 0 0;

  color: ${props => props.isClicked ? `#777777` : `#5BD98A`};

  :hover {
    cursor: pointer;
  }
`;

const TextSection = styled.div`
  display: flex;
  flex-direction: column;

  gap: 5px;
  // margin: 8px 0 8px 16px;
  margin-top: 8px;
  padding: 8px 16px 0 16px;

  width: 298px;
  height: 374px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;

  overflow-y: scroll;

  &::-webkit-scrollbar {
		width: 9px;
	}

	&::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 20px #DFDFDF;
		border: 3px solid transparent;
		height: 25px;
		// background-color: #FF8B48;
		border-radius: 200px;
	}

	&::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px transparent;
		border: 4px solid transparent;
		background-color: transparent;
	}

	&::-webkit-scrollbar-track-piece {
		background-color: transparent;
	}
`;

const Text = styled.pre`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;

  margin: 0 0 0 0;
`;
