import { React, useRef, useEffect, useCallback } from 'react';
import styled from 'styled-components';

export default function NewTextArea(props) {
	const { value, setValue, placeholder, checkURL } = props;

  const TextRef = useRef();

  useEffect(() => {
    TextRef.current.style.height = 'auto';
    TextRef.current.style.height = TextRef.current.scrollHeight + 'px';
  }, [])

  const handleResizeHeight = useCallback(() => {
    TextRef.current.style.height = 'auto';
    TextRef.current.style.height = TextRef.current.scrollHeight + 'px';
  }, []);


	function handleChange(e) {
		// console.log(e.target.value);
		setValue(e.target.value);
	}

	function handleFocus(e) {
	}

	function handleBlur(e) {
	}

	function handlePaste(e) {
		checkURL();
	}

	function handleKeyPress(e) {
		if (e.key === 'Enter' || e.key === ' ') {
			checkURL();
		}
	}
	
	return (
		<Input
			ref={TextRef}
			type="text"
			placeholder={placeholder}
			value={value}
			onPaste={handlePaste}
			onChange={handleChange}
			onFocus={handleFocus}
			onBlur={handleBlur}
      onInput={handleResizeHeight} 
			onKeyPress={handleKeyPress}/>
	)
};

const Input = styled.textarea`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

	padding: 8px 8px 8px 8px;
  // width: 556px;
	width: calc(100% - 18px);
	min-height: 269px;

  resize: none;
	overflow: visible;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #595959;

  color: #595959;
  border: 1px solid #DFDFDF;
	border-radius: 8px;

	outline: none;

	::placeholder {
		color: #CBCBCB;
	}
`;