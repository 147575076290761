import { React, useRef, useState, useEffect } from 'react';
import styled from 'styled-components';

import TimeInput from '../../../../Component/ClassManageComponent/TimeInput';
import WeekClick from '../../../../Component/ClassManageComponent/WeekClick';

import DeleteButton from '../../../../image/CategoryDeleteButton.png';

import FetchModule from '../../../Share/Network/FetchModule';
import { useHistory } from 'react-router-dom';

function BreakTimeSettingModal(props) {
  const { isOver, date, cancel } = props;

  const history = useHistory();

  const [rendering, setRendering] = useState(false);
	const [breakTimeData, setBreakTimeData] = useState([]);
	const [useBreakTimeData, setUseBreakTimeData] = useState([]);

  const TimeRef = useRef();
  const WeekRef = useRef();

  // 스크롤 제어 //
  useEffect(() => {
    document.body.style= `overflow: hidden`;
    return () => document.body.style = `overflow: auto`
  }, [])

	useEffect(() => {
		const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getData('breaktime/', 'GET');
        
        if (responseData.status === 200) setBreakTimeData(responseData.data);
        else if (responseData.status === 204) setBreakTimeData([]);
        else if (responseData.status === 401) history.push('/');
        // else if (responseData.status !== 204) alert("서버와 연결이 불안정합니다.\n잠시후 다시시도 해주세요");
      }
    )();
	}, [history, rendering])

  useEffect(() => {
    var newData = breakTimeData.slice();

    for (var i = 0; i < breakTimeData.length; i++) {
      if (newData[i].applyWeek === 'All') newData[i].applyWeek = '매일';
      else {
        var tmp = newData[i].applyWeek.split(',');
        var newApplyWeek = '';
        
        for (var j = 0; j < tmp.length; j++) {
          if (tmp[j] === 'Sun') tmp[j] = '일';
          else if (tmp[j] === 'Mon') tmp[j] = '월';
          else if (tmp[j] === 'Tue') tmp[j] = '화';
          else if (tmp[j] === 'Wed') tmp[j] = '수';
          else if (tmp[j] === 'Thu') tmp[j] = '목';
          else if (tmp[j] === 'Fri') tmp[j] = '금';
          else if (tmp[j] === 'Sat') tmp[j] = '토';

          if (j !== (tmp.length - 1)) newApplyWeek += (tmp[j] + ' ');
          else newApplyWeek += tmp[j];
        }

        newData[i].applyWeek = newApplyWeek;
      }
    }

    setUseBreakTimeData(newData);
  }, [breakTimeData])

  function onClickAddButton() {
    var time = TimeRef.current.getValue();
    var week = WeekRef.current.getValue();

    var data = {
      // storeId: window.sessionStorage.getItem('StoreId'),
      storeId: window.localStorage.getItem('StoreId'),
      startTime: time.split('-')[0],
      endTime: time.split('-')[1],
      applyWeek: week
    }
    
		const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postData('breaktime/create/', 'POST', data);
        
        if (responseData.status === 200) setRendering(!rendering);
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }

  function onClickDeleteButton(breakTimeId) { 
		const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.deleteData('breaktime/delete/' + breakTimeId, 'DELETE');
        
        if (responseData.status === 200) setRendering(!rendering);
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }

  return (
    <TopLevelWrapper>
      <ModalView>
        <TitleWrapper>
          <Title>브레이크타임 설정</Title>
          <DateText>{date.format('YYYY.MM.DD')}</DateText>
        </TitleWrapper>
        <TotalWrapper>
          <AddWrapper>
            <LayerWrapper>
              <ComponentWrapper>
                <ComponentTitle>시간</ComponentTitle>
                <TimeInput ref={TimeRef} placeholder="00:00 - 00:00" />
              </ComponentWrapper>
              <ComponentWrapper>
                <ComponentTitle>적용 요일</ComponentTitle>
                <WeekClick ref={WeekRef}/>
              </ComponentWrapper>
            </LayerWrapper>
            <ButtonWrapper>
              <AddButton onClick={onClickAddButton}>추가하기</AddButton>
            </ButtonWrapper>
          </AddWrapper>
          <ListWrapper>
            <ComponentTitle>브레이크타임 목록</ComponentTitle>
            <ListView>
              {
                breakTimeData.map((data, index) => (
                  <ListInfo key={index}>
                    <Text>{data.startTime} - {data.endTime}</Text>
                    <DeleteButtonWrapper>
                      <Text>{data.applyWeek}</Text>
                      <BreakDeleteButton src={DeleteButton} onClick={() => onClickDeleteButton(data.id)}/>
                    </DeleteButtonWrapper>
                  </ListInfo>
                ))
              }
            </ListView>
            <ButtonWrapper>
              <ExitButton onClick={cancel}>취소</ExitButton>
              <SaveButton onClick={cancel}>저장하기</SaveButton>
            </ButtonWrapper>
          </ListWrapper>
        </TotalWrapper>
      </ModalView>
    </TopLevelWrapper>
  )
}

export default BreakTimeSettingModal;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  justify-content: center;
  align-items: center;

  z-index: 1000;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.2);
`;

const ModalView = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 644px;
  height: 525px; 

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(0, 0, 0, 0.1);

  background-color: #FFFFFF;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 588px;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: 0 0 0 0;
`;

const DateText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  gap: 24px;
  margin-top: 16px;
`;

const AddWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  padding: 0 0 24px 0;

  border-bottom: 1px solid #ECECEC;
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  gap: 24px;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const ComponentTitle = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #595959;

  margin: 0 0 0 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  width: 588px;

  gap: 16px;

  margin-top: 32px;
`;

const AddButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  
  width: 100px;
  height: 40px;

  border-radius: 15px;
  background-color: #FF8B48;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  :hover {
    cursor: pointer;
  }
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 24px;
`;

const ListView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
  
  margin-top: 8px;

  width: 588px;
  height: 152px;

  overflow-y: scroll;
	overflow-x: hidden;

	&::-webkit-scrollbar {
    width: 2px;
	}

	&::-webkit-scrollbar-thumb {
		border: 3px solid transparent;
		background-color: #DADADA;
		border-radius: 200px;
	}

	&::-webkit-scrollbar-track {
		background-color: #F0F0F0;
		border-radius: 200px;
	}
`;

const ListInfo = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding: 0 8px 0 8px;

  width: 562px;
  height: 30px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background-color: #FFFFFF;
`;

const Text = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #777777;

  margin: 0 0 0 0;
`;

const DeleteButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 16px;
`;

const BreakDeleteButton = styled.img`
  width: 16px;
  height: 16px;

  :hover {
    cursor: pointer;
  }
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 72px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #595959;
    color: #595959;
  }
`;

const SaveButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 15px;
  background-color: #5BD98A;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }
`;