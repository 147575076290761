import React, { useRef } from 'react';
import styled from 'styled-components';
import moment from 'moment';

export default function PartnerGymRefundInfoView(props) {
  const { data } = props;

  const ComponentRef = useRef();

  // 이행 일수 계산 후 표출 //
  function decidePriceView() {
    if (data.refundCardPrice === 0) return '현금 - ' + data.refundCashPrice.toLocaleString('ko-KR') + ' 원';
    else if (data.refundCashPrice === 0) return '카드 - ' + data.refundCardPrice.toLocaleString('ko-KR') + ' 원';
    else return '카드 - ' + data.refundCardPrice.toLocaleString('ko-KR') + ' 원, 현금 - ' + data.refundCashPrice.toLocaleString('ko-KR') + ' 원';
  }

  return (
    <TopLevelWrapper>
      <ComponentBox ref={ComponentRef}>
        <TotalTextWrapper>
          <TextWrapper width={79}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FFFFFF' cursor='pointer'>환불 완료</Text>
          </TextWrapper>
          <TextWrapper width={95}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FFFFFF' cursor='pointer'>{moment(data.refundApprovalDate).format('YYYY.MM.DD')}</Text>
          </TextWrapper>
          <TextWrapper width={217}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FFFFFF' cursor='pointer'>{decidePriceView()}</Text>
          </TextWrapper>
          <TextWrapper width={385}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FFFFFF' cursor='pointer'>{data.reason}</Text>
          </TextWrapper>
        </TotalTextWrapper>
      </ComponentBox>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: flex-start;

  width: 100%;
`;

const ComponentBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: -35px;

  width: 88.62%;
  height: 87px;
  
  border-radius: 0px 0px 12px 12px;
  box-shadow: 0px 1px 30px rgba(0, 0, 0, 0.1);
  background-color: #FB0000;
  // background-color: ${props => props.backgroundColor};


  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const TotalTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  margin-left: 16px;

  margin-top: 50px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  margin-right: 8px;

  width: ${props => props.width}px;
  height: 20px;

  overflow: hidden;
`;

const TotalIconWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 12px;
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 24px;
  height: 24px;

  opacity: 0;

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const Icon = styled.img`
  width: 100%;
  height: 100%;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }
`;