import { React, useRef, useImperativeHandle, forwardRef, useState } from 'react';
import styled from 'styled-components';

const SignUpSubject = forwardRef((props, ref) => {
	const { onClickSignUpCategory } = props;

	const TopLevelRef = useRef();
	const FirstPageRef = useRef();
	const SecondPageRef = useRef();
	const ThirdPageRef = useRef();
	const FourthPageRef = useRef();

	const [nowPage, setNowPage] = useState(0);

	useImperativeHandle(ref, () => ({
		setOpacity: (val) => {
			TopLevelRef.current.style.opacity = val;
			if (val === 0) TopLevelRef.current.style.zIndex = -1;
			else TopLevelRef.current.style.zIndex = 1;
		},
		setNowPage: (val) => { // refectoring...
			if (val === 1) {
				FirstPageRef.current.style.backgroundColor = '#FF8B48';
				SecondPageRef.current.style.backgroundColor = '#FDFCFB';
				ThirdPageRef.current.style.backgroundColor = '#FDFCFB';
				FourthPageRef.current.style.backgroundColor = '#FDFCFB';
			}
			else if (val === 2) {
				FirstPageRef.current.style.backgroundColor = '#FF8B48';
				SecondPageRef.current.style.backgroundColor = '#FF8B48';
				ThirdPageRef.current.style.backgroundColor = '#FDFCFB';
				FourthPageRef.current.style.backgroundColor = '#FDFCFB';
			}
			else if (val === 3) {
				FirstPageRef.current.style.backgroundColor = '#FF8B48';
				SecondPageRef.current.style.backgroundColor = '#FF8B48';
				ThirdPageRef.current.style.backgroundColor = '#FF8B48';
				FourthPageRef.current.style.backgroundColor = '#FDFCFB';
			}
			else if (val === 4) {
				FirstPageRef.current.style.backgroundColor = '#FF8B48';
				SecondPageRef.current.style.backgroundColor = '#FF8B48';
				ThirdPageRef.current.style.backgroundColor = '#FF8B48';
				FourthPageRef.current.style.backgroundColor = '#FF8B48';
			}
			setNowPage(val);
		},
		getNowPage: () => {
			return nowPage;
		}
	}));

	return (
		<TopLevelWrapper ref={TopLevelRef}>
			<CategoryWrapper>
				<Category>회원 가입</Category>
				<PaginationWrapper>
					<Pagination ref={FirstPageRef}/>
					<Pagination ref={SecondPageRef}/>
					<Pagination ref={ThirdPageRef}/>
					<Pagination ref={FourthPageRef}/>
				</PaginationWrapper>
			</CategoryWrapper>
			<CategoryExplanationWrapper>
				<CategoryExplanation>이미 계정이 있으신가요?</CategoryExplanation>
				<CategoryExplanation className='SignUp' onClick={onClickSignUpCategory}>로그인</CategoryExplanation>
			</CategoryExplanationWrapper>
		</TopLevelWrapper>
	)
})

export default SignUpSubject;

const TopLevelWrapper = styled.div`
	position: absolute;
	display: flex;
	flex-direction: column;
	
	opacity: 0;
	z-index: -1;

  transition: all 0.5s ease-in-out;
`;

const CategoryWrapper = styled.div`
	display: flex;
	flex-direction: row;

	align-items: center;
`;

const Category = styled.p`
	font-family: NotoSansKR-Bold;
	font-size: 24px;
	// font-size: max(18px, 1.25vw);
	// line-height: 34.75px;
 
	margin: 0 0 0 0;
	cursor: default;
`;

const PaginationWrapper = styled.div`
	display: flex;
	flex-direction: row;

	margin: 0 0 0 262px;
	// margin-top: 0px;
	// margin-right: 0px;
	// margin-bottom: 0px;
	// margin-left: max(92.7734375px, 13.64583333333333vw);
	// margin-left: max(92.7734375px, 12.08333333333333vw);
	// margin-left: max(99px, 13.64583333333333vw);

	gap: 4px;
	// gap: max(3px, 0.2083333333333333vw);
`;

const Pagination = styled.div`
	width: 25px;
	// width: max(19px, 1.302083333333333vw);
	height: 8px;
	// height: max(6px, 0.4166666666666667vw);

	border-radius: 2px;
	background-color: #FDFCFB;
	
  transition: all 0.5s ease-in-out;
`;

const CategoryExplanationWrapper = styled.div`
	display: flex;
	flex-direction: row;

	margin-top: 13px;
	// margin-top: max(2px, 0.6770833333333333);
`;

const CategoryExplanation = styled.p`
	font-family: ${props => props.className === 'SignUp' ? `NotoSansKR-Medium` : `NotoSansKR-Regular`}; 
	font-size: 14px;
	// font-size: max(12px, 0.7291666666666667vw);
	// line-height: 20.27px;
	color: ${props => props.className === 'SignUp' ? `#FF8B48` : `#777777`};

	margin: 0 8px 0 0;

	:hover {
		cursor: ${props => props.className === 'SignUp' ? `pointer` : `default`};
	}
`;