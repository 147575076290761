import { React } from 'react';
import styled from 'styled-components';

import RankingTotalView from '../../Model/Individual/NoteModel/RankingTotalView';
import NewRankingTotalView from '../../Model/Individual/NoteModel/NewRankingTotalView';

function RankingPage () {
  return (
    <Container>
      <RankingTotalView/>
      {/* <NewRankingTotalView/> */}
    </Container>
  )
}

export default RankingPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
`;