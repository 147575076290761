import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import AvailableReserveLessonSelectBox from '../../../Component/SettingComponent/AvailableReserveLessonSelectBox';
import MemberShipMaxCountInput from '../../../Component/SettingComponent/MemberShipMaxCountInput';
import NewLessonNameInput from '../../../Component/SettingComponent/NewLessonNameInput';
import NewMemberShipValidity from '../../../Component/SettingComponent/NewMemberShipValidity';
import NewPriceSettingInput from '../../../Component/SettingComponent/NewPriceSettingInput';
import FetchModule from '../../Share/Network/FetchModule';
import AvailableReservePartnerLessonSelectBox from '../../../Component/SettingComponent/AvailableReservePartnerGymLessonSelectBox';
import SubscribeSelectBox from '../../../Component/SettingComponent/SubscribeSelectBox';

export default function MemberShipEditBox(props) {
  const { 
    myStore,
    followStores,
    isClickedMemberShipEdit, 
    clickLessonData,
    clickEditMemberShipData, 
    lessonData, 
    onClickExitAddMemberShipEdit,
    onSaveMemberShip
   } = props;

  const [translateX, setTranslateX] = useState(-1100);

  const [id, setId] = useState(-1);
  const [isMemberShipUnVisible, setIsMemberShipUnVisible] = useState(false);
  const [memberShipName, setMemberShipName] = useState('');

  const [availableLessonData, setAvailableLessonData] = useState([]);
  const [availableLessonIds, setAvailableLessonIds] = useState(-1);
  const [availablePartnerGymLessonData, setAvailablePartnerGymLessonData] = useState([]);

  const [isValidityUnVisible, setIsValidityUnVisible] = useState(false);
  const [validityUnit, setValidityUnit] = useState({ value: '일' });
  const [validity, setValidity] = useState(0);
  const [isUseSubscribe, setIsUseSubscribe] = useState(0);

  const [isPriceUnVisible, setIsPriceUnVisible] = useState(false);
  const [price, setPrice] = useState(0);

  const [originalMembershipData, setOriginalMembershipData] = useState(undefined);

  const TotalMaxRef = useRef();
  const WeekMaxRef = useRef();
  const DayMaxRef = useRef();
  const HoldRef = useRef();
  const HoldCountRef = useRef();
  const AutoRef = useRef();
  const AvailablePartnerRef = useRef([]);

  // 회원권 수정 및 추가 클릭시 크기 변화 //
  useEffect(() => {
    if (isClickedMemberShipEdit) setTranslateX(-510);
    else setTranslateX(-1100);
  }, [isClickedMemberShipEdit])

  // 회원권 데이터 세팅 //
  useEffect(() => {
    // 회원권 추가 클릭시 기본 데이터 세팅 //
    if (clickEditMemberShipData === undefined) {
      setId(-1);
      setIsMemberShipUnVisible(true);
      setMemberShipName('');
      setAvailableLessonData([]);
      setAvailableLessonIds(-1);
      setAvailablePartnerGymLessonData([]);
      setIsValidityUnVisible(false);
      setValidityUnit({value: '일'});
      setValidity(0);
      setIsPriceUnVisible(false);
      setPrice(0);
      setIsUseSubscribe(0);

      TotalMaxRef.current.reset(true);
      WeekMaxRef.current.reset(true);
      DayMaxRef.current.reset(true);
      HoldRef.current.reset(false);
      HoldCountRef.current.reset(false);
      // AutoRef.current.reset();
    }
    // 회원권 수정 클릭시 회원권 데이터 세팅 //
    else {
      setId(clickEditMemberShipData.id);
      setIsMemberShipUnVisible(clickEditMemberShipData.isHideAll);
      setMemberShipName(clickEditMemberShipData.name);
      setAvailableLessonData([]);
      // console.log('lessonId : ' + clickEditMemberShipData.availableReserveLessonIds);

      setAvailableLessonIds(clickEditMemberShipData.availableReserveLessonIds);
      setAvailablePartnerGymLessonData(clickEditMemberShipData.partnerGymAvailableLessons);
      setIsValidityUnVisible(clickEditMemberShipData.isHideValidity);

      if (clickEditMemberShipData.validityUnit === 'month') {
        setValidityUnit({value: '개월'});
        // setValidity(parseInt(clickEditMemberShipData.validity / 30));
        setValidity(clickEditMemberShipData.validity);
      }
      else if (clickEditMemberShipData.validityUnit === 'year') {
        setValidityUnit({value: '년'});
        // setValidity(parseInt((clickEditMemberShipData.validity / 30) / 12));
        setValidity(clickEditMemberShipData.validity);
      }
      else {
        setValidityUnit({value: '일'});
        setValidity(clickEditMemberShipData.validity);
      }
      setIsPriceUnVisible(clickEditMemberShipData.isHidePrice);
      setPrice(clickEditMemberShipData.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));
      setIsUseSubscribe(clickEditMemberShipData.autoPayment);

      TotalMaxRef.current.setValue(clickEditMemberShipData.totalMax);
      WeekMaxRef.current.setValue(clickEditMemberShipData.weekMax);
      DayMaxRef.current.setValue(clickEditMemberShipData.dayMax);
      HoldRef.current.setValue(clickEditMemberShipData.availableHold);
      HoldCountRef.current.setValue(clickEditMemberShipData.holdCount);
      // AutoRef.current.setValue(clickEditMemberShipData.autoPayment);

      setOriginalMembershipData(clickEditMemberShipData);
    }
  }, [clickEditMemberShipData])

  // 저장 클릭시 //
  function onClickSave() {

    let lessonIdList = [];

    availableLessonData.map((newData) => (
      lessonIdList.push(newData.id)
    ))

    let lessonIds = '';

    for (let i = 0; i < lessonIdList.length; i++) {
      if (i === 0) lessonIds += lessonIdList[i];
      else lessonIds += ',' + lessonIdList[i];
    }

    if (lessonIds === '') {
      alert('예약가능 수업을 설정해주세요!');
      return;
    }
    else if (memberShipName === '') {
      alert('회원권 이름을 설정해주세요!');
      return;
    }

    let finalValidityUnit = 'day';
    let finalValidity = validity;

    if (validityUnit.value === '일') finalValidityUnit = 'day';
    else if (validityUnit.value === '개월') {
      // finalValidity = finalValidity * 30;
      finalValidityUnit = 'month';
    }
    else {
      // finalValidity = (finalValidity * 30 * 12);
      finalValidityUnit = 'year';
    }
    
    let partnerGymAvailableLessons = [];

    for (let i = 0; i < followStores.length; i++) {
      let lessonData = AvailablePartnerRef.current[i].getValue();

      for (let j = 0; j < lessonData.length; j++) {
        let partnerGymLessonData = {
          followStoreId: lessonData[j].storeId,
          followLessonId: lessonData[j].id 
        }

        partnerGymAvailableLessons.push(partnerGymLessonData);
      }
    }


    if (originalMembershipData !== undefined) {
      if (originalMembershipData.autoPayment === 1) {
        if (originalMembershipData.validity !== finalValidity
          || originalMembershipData.validityUnit !== finalValidityUnit) {
          alert('승인된 정기 구독권은 수업 일수를 변경할 수 없습니다');
          return;
        }
        else if (originalMembershipData.price !== parseInt(price.toString().replaceAll(',', ''))) {
          alert('승인된 정기 구독권은 결제 금액을 변경할 수 없습니다');
          return;
        }
      }
      else if (originalMembershipData.autoPayment === 2) {
        alert('구독권 심사중에는 데이터를 변경할 수 없습니다');
        return;
      }  
    }

    let requestData = {
      id: id === -1 ? undefined : id,
      // lessonId: clickLessonData.id,
      lessonId: 0,
      categoryId: clickLessonData.id,
      storeId: 0,
      name: memberShipName,
      price: parseInt(price.toString().replaceAll(',', '')),
      validity: finalValidity,
      validityUnit: finalValidityUnit,
      availableReserveLessonIds: lessonIds,
      totalMax: TotalMaxRef.current.getValue(),
      weekMax: WeekMaxRef.current.getValue(),
      dayMax: DayMaxRef.current.getValue(),
      availableHold: HoldRef.current.getValue(),
      holdCount: HoldCountRef.current.getValue(),
      autoPayment: isUseSubscribe,
      isHideValidity: isValidityUnVisible,
      isHidePrice: isPriceUnVisible,
      isHideAll: isMemberShipUnVisible,
      partnerGymAvailableLessons: partnerGymAvailableLessons
    }
    
    let isSuccess = onSaveMemberShip(requestData);

    if (isSuccess) {
      // 저장한 데이터 세팅 초기화 //
      setTimeout(() => {
        setId(-1);
        setIsMemberShipUnVisible(true);
        setMemberShipName('');
        setAvailableLessonData([]);
        setAvailableLessonIds(-1);
        setAvailablePartnerGymLessonData([]);
        setIsValidityUnVisible(false);
        setValidityUnit({ value: '일' });
        setValidity(0);
        setIsPriceUnVisible(false);
        setPrice(0);
        setIsUseSubscribe(0);

        TotalMaxRef.current.reset(true);
        WeekMaxRef.current.reset(true);
        DayMaxRef.current.reset(true);
        HoldRef.current.reset(false);
        HoldCountRef.current.reset(false);
      }, 500);
      // 저장한 데이터 세팅 초기화 //
    }
  }

  // 예약가능 수업 텍스트 결정 //
  function onCalcAvailableLessonText(data) {
    if (data === undefined || data.storeName === undefined || data.storeName === '') return '예약 가능 수업';
    else if (data.partnerId === 0) return '[' + data.storeName + '] 예약 가능 수업';
    else return '[' + data.storeName + '] 파트너 체육관 예약 가능 수업';
  }

  // 예약가능 수업 좌측 로고 결정 //
  function onCalcAvailableLessonStoreLogo(data) {
    if (data === undefined || data.storeName === '') return 'https://picture.link-zone.org/DefaultLogo.png';
    else return data.storeLogo;
  }

  return (
    <TopLevelWrapper translateX={translateX}>
      <InfoView>
        <Text fontFmaily='NotoSansKR-Bold' fontSize={16} color='#232323' cursor='default'>회원권 추가하기</Text>
        <ComponentTotalWrapper>
          <ComponentWrapper>
            <ComponentTitleWrapper>
              <Text fontFmaily='NotoSansKR-Regular' fontSize={12} color='#595959' cursor='default'>회원권 명</Text>
              <UnVisibleWrapper>
                <Text fontFmaily='NotoSansKR-Regular' fontSize={12} color='#595959' cursor='default'>회원권 비공개</Text>
                <UnVisibleCheckBox isClicked={isMemberShipUnVisible} onClick={() => setIsMemberShipUnVisible(!isMemberShipUnVisible)} />
              </UnVisibleWrapper>
            </ComponentTitleWrapper>
            <NewLessonNameInput value={memberShipName} setValue={setMemberShipName} placeholder='회원권명을 입력해주세요' />
          </ComponentWrapper>
          <ComponentWrapper>
            <Text fontFmaily='NotoSansKR-Regular' fontSize={12} color='#595959' cursor='default'>{onCalcAvailableLessonText(myStore)}</Text>
            <AvailableReserveLessonSelectBox
              storeId={myStore.storeId}
              storeLogo={onCalcAvailableLessonStoreLogo(myStore)}
              membershipId={id}
              lessonData={lessonData}
              value={availableLessonData}
              setValue={setAvailableLessonData}
              lessonId={availableLessonIds}
              setLessonId={setAvailableLessonIds} />
          </ComponentWrapper>
          {
            followStores.map((data, index) => (
              <ComponentWrapper key={index}>
                <Text fontFmaily='NotoSansKR-Regular' fontSize={12} color='#595959' cursor='default'>{onCalcAvailableLessonText(data)}</Text>
                <AvailableReservePartnerLessonSelectBox
                  ref={(el) => (AvailablePartnerRef.current[index] = el)}
                  storeId={data.followStoreId}
                  storeLogo={onCalcAvailableLessonStoreLogo(data)}
                  membershipId={id}
                  lessonData={lessonData}
                  // value={availablePartnerGymLessonData}
                  // setValue={setAvailablePartnerGymLessonData}
                  lessonId={availableLessonIds}
                  availablePartnerGymLessonData={availablePartnerGymLessonData} />
              </ComponentWrapper>
            ))
          }
          <ComponentRowWrapper>
            <ComponentLeftWrapper>
              <ComponentWrapper>
                <ComponentTitleWrapper>
                  <Text fontFmaily='NotoSansKR-Regular' fontSize={12} color='#595959' cursor='default'>수업 일수</Text>
                  <UnVisibleWrapper>
                    <Text fontFmaily='NotoSansKR-Regular' fontSize={12} color='#595959' cursor='default'>수업 일수 비공개</Text>
                    <UnVisibleCheckBox isClicked={isValidityUnVisible} onClick={() => setIsValidityUnVisible(!isValidityUnVisible)} />
                  </UnVisibleWrapper>
                </ComponentTitleWrapper>
                <NewMemberShipValidity
                  value={validity}
                  setValue={setValidity}
                  validityUnit={validityUnit}
                  setValidifyUnit={setValidityUnit} />
              </ComponentWrapper>
              <ComponentWrapper>
                <MemberShipMaxCountInput ref={DayMaxRef} explanation='일일 사용 가능 횟수' unit='회'/>
              </ComponentWrapper>
              <ComponentWrapper>
                <MemberShipMaxCountInput ref={HoldRef} explanation='최대 홀딩 가능 일수' unit='일'/>
              </ComponentWrapper>
              {/* <ComponentWrapper>
                <MemberShipMaxCountInput ref={AutoRef} explanation='자동결제 유지기간' isRegularPayment={true} unit='회'/>
              </ComponentWrapper> */}
              <ComponentWrapper>
                <SubscribeSelectBox isUsePaymentSystem={myStore.isUsePaymentSystem === 'Yes'} isUseSubscribe={isUseSubscribe} setIsUseSubscribe={setIsUseSubscribe}/>
              </ComponentWrapper>
            </ComponentLeftWrapper>
            <ComponentRightWrapper>
              <ComponentWrapper>
                <MemberShipMaxCountInput ref={TotalMaxRef} explanation='총 예약 가능 횟수' unit='회'/>
              </ComponentWrapper>
              <ComponentWrapper>
                <MemberShipMaxCountInput ref={WeekMaxRef} explanation='주간 수업 가능 한도' unit='회'/>
              </ComponentWrapper>
              <ComponentWrapper>
                <MemberShipMaxCountInput ref={HoldCountRef} explanation='홀딩 신청 가능 횟수' unit='회'/>
              </ComponentWrapper>
              <ComponentWrapper>
                <ComponentTitleWrapper>
                  <Text fontFmaily='NotoSansKR-Regular' fontSize={12} color='#595959' cursor='default'>가격 설정</Text>
                  {/* <UnVisibleWrapper>
                    <UnVisibleCheckBox isClicked={isPriceUnVisible} onClick={() => setIsPriceUnVisible(!isPriceUnVisible)} />
                    <Text fontFmaily='NotoSansKR-Regular' fontSize={12} color='#595959' cursor='default'>가격 숨기기</Text>
                  </UnVisibleWrapper> */}
                </ComponentTitleWrapper>
                <NewPriceSettingInput value={price} setValue={setPrice} placeholder={0}/>
              </ComponentWrapper>
            </ComponentRightWrapper>
          </ComponentRowWrapper>
        </ComponentTotalWrapper>
        <ButtonWrapper>
          <ExitButton onClick={onClickExitAddMemberShipEdit}>
            <Text fontFmaily='NotoSansKR-Regular' fontSize={14} color='#CBCBCB' cursor='pointer'>취소</Text>
          </ExitButton>
          <SaveButton onClick={onClickSave}>
            <Text fontFmaily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='pointer'>저장하기</Text>
          </SaveButton>
        </ButtonWrapper>
      </InfoView>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 18px 0px 18px 0px;

  min-width: 495px;
  max-width: 495px;
  // height: 585px;
  
  z-index: 3;

  // overflow: hidden;

  border-radius: 30px;
  background-color: #FFFFFF;
  // box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  filter: drop-shadow(0px 1px 8px rgba(0, 0, 0, 0.10));

  transform: translateX(${props => props.translateX}px);
  transition: all 0.3s ease-in-out;
`;

const InfoView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 92.12%;
`;

const ComponentTotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 14px;

  width: 100%;

  gap: 12px;
`;

const ComponentRowWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  width: 100%;
`;

const ComponentLeftWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 54.2%;

  gap: 12px;
`;

const ComponentRightWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 41.6%;

  gap: 12px;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  gap: 8px;
`;

const ComponentTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const UnVisibleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
`;

const UnVisibleCheckBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 14px;
  height: 14px;

  border: 1px solid #DFDFDF;
  border-radius: 4px;
  background-color: ${props => props.isClicked ? '#FF8B48' : '#FFFFFF'};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const Text = styled.div`
  font-family: ${props => props.fontFmaily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: flex-end;

  margin-top: 72px;

  width: 100%;

  gap: 16px;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 72px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;

  :hover {
    cursor: pointer;
  }
`;

const SaveButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 15px;
  background-color: #5BD98A;

  :hover {
    cursor: pointer;
  }
`;