import { React, useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';

import MemberSearchInput from '../Component/MemberSearchInput';
import UserProfile from '../../Component/Normal/UserProfile';

import DefaultLogo from '../../image/DefaultProfile.png';
import MemberPlusBox from '../Component/MemberPlusBox';


export default function OneOnOneMemberSelectModal(props) {
  const { 
    members,
    setMemberIndex,
    setIsViewSelectedMemberModal
  } = props;

  const [finalMembers, setFinalMembers] = useState([]);
  const [value, setValue] = useState('');

  const ModalRef = useRef(null);

  // Modal 창 스크롤 제어 //
  useEffect(() => {
    document.body.style= `overflow: hidden`;
    return () => document.body.style = `overflow: auto`
  }, [])

  // 검색 기능 포함 ( 한글 검색 알고리즘 최신화 ) //
  useEffect(() => {
    let nameList = [];
    let phoneList = [];

    for (let i = 0; i < members.length; i++) {
      if (members[i].name.toLowerCase().includes(value.toLowerCase())) nameList.push(members[i]);
      else if (members[i].phone.includes(value)) phoneList.push(members[i]);
    }

    if (nameList.length === 0 && phoneList.length === 0) {
      for (let i = 0; i < members.length; i++) {
        if (match(value.toLowerCase(), members[i].name.toLowerCase())) nameList.push(members[i]);
      }
    }


    let totalList = nameList.concat(phoneList);

    setFinalMembers(totalList);
  }, [members, value, match()])

  // 입력된 글자가 한글인지 확인 //
  function is_kr(value) {
    if (value === undefined) return false;
    
    return ((('ㄱ'.charCodeAt() <= value.charCodeAt()) && (value.charCodeAt() <= 'ㅎ'.charCodeAt()))
            || (('가'.charCodeAt() <= value.charCodeAt()) && value.charCodeAt() <= '힣'.charCodeAt()));
  }

  // 한글 글자 유니코드 추출하여 검색 조건 확인 //
  function kr_num(value) {
    return value.charCodeAt() - '가'.charCodeAt();
  }

  // 초성 중성 종성 구분하여 연산 //
  function kr_list(value) {
    var consonant_list = ['ㄱ', 'ㄲ', 'ㄴ', 'ㄷ', 'ㄸ', 'ㄹ', 'ㅁ', 'ㅂ', 'ㅃ', 'ㅅ'
                        , 'ㅆ', 'ㅇ', 'ㅈ', 'ㅉ', 'ㅊ', 'ㅋ', 'ㅌ', 'ㅍ', 'ㅎ'];

    var res = [value];

    var kr_num_s = kr_num(value);

    if (kr_num_s > 0) {
      // 초성 + 중성일 경우 //
      if (kr_num_s % 28 !== 0) {
        res.push(String.fromCharCode(((parseInt(kr_num_s / 28) * 28) + '가'.charCodeAt())));
      }
      
      res.push(consonant_list[parseInt(kr_num_s / 588)])
    }

    return res;
  }

  // 한글, 영어 구분하여 검색 처리 //
  function eq_kr(value, data) {
    if (is_kr(value) && is_kr(data)) {
      return kr_list(data).includes(value);
    }
    else return value === data;
  }

  // 받침 확인하기 //
  function eq_kr_pos(value, data, nextData) {
    var consonant_list = ['ㄱ', 'ㄲ', 'ㄴ', 'ㄷ', 'ㄸ', 'ㄹ', 'ㅁ', 'ㅂ', 'ㅃ', 'ㅅ'
                        , 'ㅆ', 'ㅇ', 'ㅈ', 'ㅉ', 'ㅊ', 'ㅋ', 'ㅌ', 'ㅍ', 'ㅎ'];

    var kr_pos = [
      [0, 0], [1, 0], [2, 1], [2, 9], [4, 2], [1, 12], [2, 18],
      [7, 3], [8, 5], [1, 0], [2, 6], [3, 7], [4, 9], [5, 16],
      [6, 17], [7, 18], [16, 6], [17, 7], [1, 9], [19, 9], [20, 10],
      [21, 11], [22, 12], [23, 14], [24, 15], [25, 16], [26, 17], [27, 18]
    ]

    if (!(is_kr(value) && is_kr(data) && is_kr(nextData))) return false;
    if (kr_num(value) < 0) return false;
    
    var s_pos = String.fromCharCode(value.charCodeAt() - kr_pos[kr_num(value) % 28][0])
    var s_pos_consonant = consonant_list[kr_pos[kr_num(value) % 28][1]]

    return (s_pos === data) && (kr_list(nextData).includes(s_pos_consonant));
  }

  // 일치하는 글자가 존재하는지 확인 //
  function match(value, data) {
    if (value === undefined || data === undefined) return false;

    var valueSize = value.length;
    var dataSize = data.length;

    if (dataSize < valueSize) return false;

    for (var i = 0; i < (dataSize - valueSize + 1); i++) {
      var res = true;

      for (var j = 0; j < valueSize; j++) {
        if (j === (valueSize - 1)) {
          if (!eq_kr(value[j], data[i + j])) {
            if (i + j + 1 < dataSize) {
              if (eq_kr_pos(value[j], data[i + j], data[i + j + 1])) {
                break;
              }
            }
            res = false;
            break;
          }
        }
        else {
          if (value[j] !== data[i + j]) {
            res = false;
            break;
          }
        }
      }

      if (res) return true;
    }
    
    return false;
  }

  // 배경 클릭시 모달 닫기 //
  function onClickBackground(e) {
    if (e.target === ModalRef.current || ModalRef.current.contains(e.target)) return;

    setIsViewSelectedMemberModal(false);
  }


  // 회원 플러스 버튼 클릭시 //
  function onClickPlusButton(data) {
    setMemberIndex(data.index);
    setIsViewSelectedMemberModal(false);
  }

  return (
    <TopLevelWrapper onClick={onClickBackground}>
      <ModalView ref={ModalRef}>
        <TotalView>
          <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#232323' cursor='default'>유효 회원 찾기</Text>
          <Text style={{ marginTop: '12px', marginLeft: '10px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#777777' cursor='default'>1:1 운동 디자인</Text>
          <MemberSearchInput value={value} setValue={setValue}/>
        </TotalView>
        <MemberListWrapper>
          {
            finalMembers.map((data, index) => 
              <MembeInfoBox key={'MemberInfo-' + index}>
                <ProfileWrapper>
                  <UserProfile profile={data.profile} defaultProfile={DefaultLogo}/>
                </ProfileWrapper>
                <InfoWrapper>
                  <NameWrapper>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#777777' cursor='default'>{data.name}</Text>
                  </NameWrapper>
                  <PhoneWrapper>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#777777' cursor='default'>{data.phone}</Text>
                  </PhoneWrapper>
                  <MemberPlusBox data={data} onClick={onClickPlusButton}/>
                </InfoWrapper>
              </MembeInfoBox>
            )
          }
        </MemberListWrapper>
      </ModalView>
    </TopLevelWrapper>
  )
}

const startAnimation = keyframes`
	0% { opacity: 0; }
	50% { opacity: 0.5; }
	100% { opacity: 1; }
`;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  justify-content: flex-start;
  align-items: flex-end;

  z-index: 1000;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.2);

  animation-name: ${startAnimation};
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
`;

const ModalView = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 156px;
  margin-right: 94px;

  width: 412px;
  height: 564px;

  border-radius: 10px;
  background-color: #FFFFFF;
`;

const TotalView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 372px;
`;

const MemberListWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 10px;

  width: 374px;
  height: 420px;

  overflow-x: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
		width: 9px;
	}

	&::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 20px #DFDFDF;
		border: 3px solid transparent;
		height: 25px;
		// background-color: #FF8B48;
		border-radius: 200px;
	}

	&::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px transparent;
		border: 4px solid transparent;
		background-color: transparent;
	}

	&::-webkit-scrollbar-track-piece {
		background-color: transparent;
	}
`;

const MembeInfoBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: spave-between;
  align-items: center;

  width: 364px;

  gap: 10px;
`;

const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 37px;
  height: 37px;

  border-radius: 2px;
  
  overflow: hidden;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  padding: 10px 0px;

  width: 312px;

  gap: 10px;

  border-bottom: 1px solid #D9D9D9;
`;

const NameWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 46px;
  height: 20px;

  overflow: hidden;
`;

const PhoneWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 228px;
  height: 20px;

  overflow: hidden;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }
`;
