import { React, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

import FetchModule from '../../../../Share/Network/FetchModule';

export default function MemberShipRefundDeleteModal(props) {
  const { data, rendering, onClickExit } = props;

  useEffect(() => {
    document.body.style= `overflow: hidden`;
    return () => document.body.style = `overflow: auto`
  }, [])

  function deleteClick() {
    if (data.merchantUids.split('-')[2] === '1') {
      alert('링크페이 환불내역은 삭제할 수 없습니다');
      return;  
    }

    let requestData = {
      merchantUid: data.merchantUids
    }

		const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataVersion2('sales/refund/delete', 'POST', requestData);
        
        if (responseData.status === 200) {
          rendering();
          onClickExit();
        }
        else {
          alert(responseData.error);
          onClickExit();
        }
      }
    )();
  }

  return (
    <TopLevelWrapper>
      <ModalView>
        <Title>환불 내역 삭제</Title>
        <TextWrapper>
          <Explanation>환불 내역 삭제를 진행합니다,</Explanation>
          <Explanation>환불 내역 삭제를 진행하면 다시 되돌릴 수 없습니다.</Explanation>
        </TextWrapper>
        <ButtonWrapper>
          <ExitButton onClick={onClickExit}>취소</ExitButton>
          <CancelButton onClick={deleteClick}>진행하기</CancelButton>
        </ButtonWrapper>
      </ModalView>
    </TopLevelWrapper>
  )
}

const startAnimation = keyframes`
	0% { opacity: 0; }
	50% { opacity: 0.5; }
	100% { opacity: 1; }
`;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  justify-content: center;
  align-items: center;

  z-index: 1000;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.2);
	
	animation-name: ${startAnimation};
	animation-duration: 0.5s;
	animation-timing-function: ease-out;
`;

const ModalView = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: 371px;
  height: 248px;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(0, 0, 0, 0.1);
  background-color: #FFFFFF;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: 0 0 16px 28px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin-left: 28px;
  
  gap: 14px;
`;

const Explanation = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;
  
  margin: 0 0 0 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;

  gap: 16px;

  margin: 53px 0 0 153px;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 72px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;
  
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #777777;
    color: #777777;
  }
`;

const CancelButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 15px;
  background-color: #FF3131;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;