import { React, useState, useEffect } from 'react';
import styled from 'styled-components';
import Lottie from 'lottie-react-web';

import LoadingAnimation from '../../../Animation/LoadingAnimation.json';
import AttendanceRankingMemberList from './AttendanceRankingMemberList';

import Attendance1ImagePath from '../../../image/Attendance1.png';
import Attendance2ImagePath from '../../../image/Attendance2.png';
import LeftArrow from '../../../image/Left.png'//왼쪽 화살표
import RightArrow from '../../../image/Right.png'//우측 화살표
import NotAvailableLeftArrow from '../../../image/NotAvailableLeft.png';
import NotAvailableRightArrow from '../../../image/NotAvailableRight.png';
import moment from 'moment';
import WeeklyAbsenteeMemberList from './WeeklyAbsenteeMemberList';


export default function MemberAttendanceBox(props) {
  const {
    today,
    salesData,
    startDate,
    endDate,
    calcDateText,

    attendanceTotal,
    attendanceRanking,
    totalMonthAttendanceCount,

    allMembers,
    selectedPartnerGym,
    isOpenPartnerGymSales,
    isWeekAttendanceLoading, 
    setIsWeekAttendanceLoading,
    isMonthAttendanceLoading,
    setIsMonthAttendanceLoading,
    // isLoadingAllMember,
    // isReregistLoading,
    // setIsReregistLoading,
    // isExpiredLoading,
    // setIsExpiredLoading
  } = props;

  const [weeklyDate, setWeeklyDate] = useState([]);
  const [weeklyIndex, setWeeklyIndex] = useState(0);
  const [weeklyValidMemberCount, setWeeklyValidMemberCount] = useState(0);
  const [weeklyAttendanceCount, setWeeklyAttendanceCount] = useState(0);
  const [absentee, setAbsentee] = useState([]);

  // 매 주차 첫번째날 뽑기 //
  useEffect(() => {

    let start = moment(today.format('YYYY-MM-') + '01T00:00:00');
    let end = moment(start).add(1, 'month').subtract(1, 'second');

    if (!moment().isAfter(end)) end = moment(moment().format('YYYY-MM-DDT23:59:59'));

    if (startDate.format('YYYY-MM-DDTHH:mm:ss') === start.format('YYYY-MM-DDTHH:mm:ss')
      && endDate.format('YYYY-MM-DDTHH:mm:ss') === end.format('YYYY-MM-DDTHH:mm:ss')) {
      let newList = [];

      newList.push(startDate.clone());

      let nextStandardDate = startDate.clone().add(7, 'days');

      while (true) {
        if (startDate.clone().format('MM') === nextStandardDate.clone().format('MM')) {
          newList.push(nextStandardDate.clone());
          nextStandardDate.add(7, 'days');
        }
        else break;
      }

      let newCompleteList = [];

      for (let i = 0; i < newList.length; i++) {
        for (let j = 0; j < 7; j++) {
          if (newList[i].clone().subtract(j, 'day').format('dd') === 'Mo') {

            let data = {
              startDate: newList[i].clone().subtract(j, 'day'),
              endDate: newList[i].clone().subtract(j, 'day').add(7, 'days').subtract(1, 'seconds')
            };

            newCompleteList.push(data);
            break;
          }
        }
      }

      setWeeklyDate(newCompleteList);
    }
    else {
      let data = {
        startDate: startDate,
        endDate: endDate
      }

      setWeeklyDate([data]);
    }
  }, [startDate, endDate])

  // 선택된 주간 마다 데이터 변경 //
  useEffect(() => {
    if (weeklyDate.length === 0) return;

    let isLastest = true;

    let validMemberCount = 0;
    let attendanceCount = 0;
    let absentee = [];
    // console.log(weeklyDate);
    // console.log('-----');
    if (weeklyDate[weeklyIndex].startDate.isBefore(moment())) {
      // 주간 유효회원중 출석한 회원 고르기 //
      for (let i = 0; i < attendanceTotal.length; i++) {
        // 주간에 유효회원인지 확인 //
        if (moment(attendanceTotal[i].endDate).isAfter(weeklyDate[weeklyIndex].endDate)
          || moment(attendanceTotal[i].endDate).isSame(weeklyDate[weeklyIndex].endDate)) {
          validMemberCount += 1;
          let isExist = false;

          for (let j = 0; j < attendanceTotal[i].reserveAttendances.length; j++) {
            if ((moment(attendanceTotal[i].reserveAttendances[j].classDate).isAfter(weeklyDate[weeklyIndex].startDate)
              || moment(attendanceTotal[i].reserveAttendances[j].classDate).isSame(weeklyDate[weeklyIndex].startDate))
              && (moment(attendanceTotal[i].reserveAttendances[j].classDate).isBefore(weeklyDate[weeklyIndex].endDate))) {
              isExist = true;
              break;
            }
          }

          if (!isExist) {
            // if (attendanceTotal[i].name === '박진형89') {
            //   console.log(moment(attendanceTotal[i].reserveAttendances[0].classDate).format('YYYY-MM-DD HH:mm:ss'))
            //   console.log(weeklyDate[weeklyIndex].startDate.format('YYYY-MM-DD HH:mm:ss'))
            //   console.log(weeklyDate[weeklyIndex].endDate.format('YYYY-MM-DD HH:mm:ss'))
            //   console.log(moment(attendanceTotal[i].reserveAttendances[0].classDate).isAfter(weeklyDate[weeklyIndex].startDate));
            //   console.log(moment(attendanceTotal[i].reserveAttendances[0].classDate).isSame(weeklyDate[weeklyIndex].startDate));
            //   console.log(moment(attendanceTotal[i].reserveAttendances[0].classDate).isBefore(weeklyDate[weeklyIndex].endDate));
            // }
            absentee.push(attendanceTotal[i]);
          }
          else attendanceCount += 1;
        }
      }
    }

    if (isLastest) {
      // console.log(startDate);
      // console.log(endDate);
      // console.log(absentee);
      // absentee.map((data, index) => (console.log(data.name)));
      setWeeklyValidMemberCount(validMemberCount);
      setAbsentee(absentee);
      setWeeklyAttendanceCount(attendanceCount);

      setTimeout(() => {
        setIsWeekAttendanceLoading(false);
      }, 500)
    }

    return () => {
      isLastest = false;
    }
  }, [isWeekAttendanceLoading, attendanceTotal, weeklyDate, weeklyIndex])

  // 주간 출석률 계산 //
  function decideWeeklyAttendanceRate() {
    // Math.round(weeklyAttendanceCount / (weeklyValidMemberCount) * 100);

    if (weeklyValidMemberCount === 0) return '미산정';
    else if (weeklyAttendanceCount === 0) return '0%';
    else return Math.round(weeklyAttendanceCount / (weeklyValidMemberCount) * 100) + '%';
  }

  // 이전 주차 클릭시 //
  function onClickPrevWeeklyDate() {
    if (weeklyIndex > 0) setWeeklyIndex(weeklyIndex - 1);
  }

  // 다음 주차 클릭시 //
  function onClickNextWeeklyDate() {
    if (weeklyIndex < weeklyDate.length - 1) setWeeklyIndex(weeklyIndex + 1);
  }

  return (
    <TopLevelWrapper>
      <InfoBox>
        <Title>{calcDateText() + ' 주간 출석 현황'}</Title>
        {
          isWeekAttendanceLoading ?
            <AnimationBox>
              <LottieWrapper>
                <Lottie options={{ animationData: LoadingAnimation }} />
              </LottieWrapper>
            </AnimationBox>
            :
            <InnerInfoBox>
              {
                isOpenPartnerGymSales ?
                  <InfoView>
                    <TopUIWrapper>
                      <WeekAttendanceIcon src={Attendance1ImagePath} />
                      <WeekAttendancePersent>
                        <PersentText>주간 출석률</PersentText>
                        <PersentNumber>{decideWeeklyAttendanceRate()}</PersentNumber>
                      </WeekAttendancePersent>
                      <DivisionLine width={102}/>
                      <ResultBox width={131}>
                        <ResultText style={{ marginRight: `3px` }}>주간 미출석자</ResultText>
                        <ResultNumber style={{ marginRight: `3px` }}>{absentee.length}명</ResultNumber>
                      </ResultBox>
                    </TopUIWrapper>
                    <ListTitleWrapper>
                      <ListTitle>주간 미출석자</ListTitle>
                      <WeeklySelectWrapper>
                        <WeeklySelectButton onClick={onClickPrevWeeklyDate}>
                          <WeeklySelectButtonImage src={weeklyIndex === 0 ? NotAvailableLeftArrow : LeftArrow}/>
                        </WeeklySelectButton>
                        <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#595959' cursor='default'>{calcDateText() + ' ' + (weeklyDate.length > 1 ? ((weeklyIndex + 1) + '주차') : '')}</Text>
                        <WeeklySelectButton onClick={onClickNextWeeklyDate}>
                          <WeeklySelectButtonImage src={weeklyIndex === weeklyDate.length - 1 ? NotAvailableRightArrow : RightArrow}/>
                        </WeeklySelectButton>
                      </WeeklySelectWrapper>
                    </ListTitleWrapper>
                    <WeeklyAbsenteeMemberList data={absentee} selectedPartnerGym={selectedPartnerGym}/>
                  </InfoView>
                  :
                  <NotOpenTextWrapper>
                    <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#444444' cursor='default'>이 업체는 파트너 체육관에 매출을 비공개 합니다</Text>
                    <Text fontFamily='NotoSansKR-Regular' fontSize={15} color='#444444' cursor='default'>링크존 설정에서 “파트너 체육관”에서 권한 설정해 주세요</Text>
                  </NotOpenTextWrapper>
              }
            </InnerInfoBox>
        }
      </InfoBox>

      <BoxDivisionLine />

      <InfoBox>
        <Title>{calcDateText() + ' 월간 출석 현황'}</Title>
        {
          isMonthAttendanceLoading ?
            <AnimationBox>
              <LottieWrapper>
                <Lottie options={{ animationData: LoadingAnimation }} />
              </LottieWrapper>
            </AnimationBox>
            :
            <InnerInfoBox>
              {
                isOpenPartnerGymSales ?
                  <InfoView>
                    <TopUIWrapper>
                      <WeekAttendanceIcon src={Attendance2ImagePath} />
                      <MonthAverageAttendancePersent>
                        <PersentText>월간 출석률</PersentText>
                        <PersentNumber>{Math.round((totalMonthAttendanceCount / attendanceRanking.length) * 100)}%</PersentNumber>
                      </MonthAverageAttendancePersent>
                      <DivisionLine width={106}/>
                      <ResultBox width={127}>
                        <ResultText style={{ marginRight: `3px` }}>월간</ResultText>
                        <ResultNumber style={{ marginRight: `3px` }}>{totalMonthAttendanceCount}명</ResultNumber>
                        <ResultText>출석</ResultText>
                      </ResultBox>
                    </TopUIWrapper>
                    <ListTitleWrapper>
                      <ListTitle>월간 출석 순위</ListTitle>
                    </ListTitleWrapper>
                    <AttendanceRankingMemberList data={attendanceRanking} selectedPartnerGym={selectedPartnerGym}/>
                  </InfoView>
                  :
                  <NotOpenTextWrapper>
                    <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#444444' cursor='default'>이 업체는 파트너 체육관에 매출을 비공개 합니다</Text>
                    <Text fontFamily='NotoSansKR-Regular' fontSize={15} color='#444444' cursor='default'>링크존 설정에서 “파트너 체육관”에서 권한 설정해 주세요</Text>
                  </NotOpenTextWrapper>
              }
            </InnerInfoBox>
        }
      </InfoBox>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 1020px;
  height: 448px;

  gap: 28px;
  margin-top: 38px;

  border-radius: 30px;
  box-shadow: 0 40px 50px rgba(240, 232, 227, 0.5);
  background-color: #FFFFFF;
`;

const BoxDivisionLine = styled.div`
  diplay: flex;
  flex-direction: row;

  width: 1px;
  height: 392px;

  background-color: #ECECEC;
  // border: 1px solid #ECECEC;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-conetent: center;
  align-items: flex-start;

  width: 44.5%;
  // height: 77.67%;
`;

const InnerInfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  // width: 454px;
  // width: 44.5%;
  height: 364px;
`;

const InfoView = styled.div`
  display: flex;
  flex-direction: column;

  justify-conetent: center;
  align-items: flex-start;
`;

const Title = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;
`;

const AnimationBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  z-index: 5;
  
  width: 100%;
  // height: 100%;
  height: 364px;

  // background-color: rgba(0, 0, 0, 0.2);
  background-color: #FFFFFF;
`;

const LottieWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 80px;
  height: 80px;
`;

const TopUIWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 24px;
`;

const WeekAttendanceIcon = styled.img`
  width: 48px;
  height: 48px;

  margin-right: 8px;
`;

const WeekAttendancePersent = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 133px;
  // width: 140px;
  height: 28px;

  border-radius: 8px;
  background-color: #6DD2A6;
`;

const MonthAverageAttendancePersent = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 133px;
  // width: 141px;
  height: 28px;

  border-radius: 8px;
  background-color: #FFA800;
`;

const PersentText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #FFFFFF;

  margin: 0 3px 0 0;
`;

const PersentNumber = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 14px;

  color: #FFFFFF;

  margin: 0 0 0 0;
`;

const DivisionLine = styled.div`
  display: flex;
  flex-direction: row;

  // width: 120px;
  width: ${props => props.width}px;
  height: 1px;

  margin: 0 16px 0 16px;
  border: 1px solid #ECECEC;
`;

const ResultBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // width: 113px;
  width: ${props => props.width}px;
  height: 28px;

  border-radius: 8px;
  background-color: #ECECEC;
`;

const ResultText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #777777;

  margin: 0 0 0 0;
`;

const ResultNumber = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 14px;

  color: #777777;

  margin: 0 0 0 0;
`;

const ListTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: 24px;

  width: 100%;
`;

const ListTitle = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #595959;

  // margin: 24px 0 0 0;
  margin: 0 0 0 0;
`;

const WeeklySelectWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const WeeklySelectButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 16px;
  height: 16px;
  border: 1px solid #DFDFDF;
  border-radius: 17px;

  background-color: #FFFFFF;

  :hover {
    cursor: pointer;
  }
`;

const WeeklySelectButtonImage = styled.img`
  width: 10px;
  height: 10px;

  object-fit: contain;

  :hover {
    cursor: pointer;
  }
`;


const PriceTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
  
  gap: 4px;
`;

const NotOpenTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }
`;