import { React } from 'react';
import styled from 'styled-components';

import UnitSelectBox from '../Component/UnitSelectBox';
import IncludeSlashDataInput from '../Component/IncludeSlashDataInput';
import IncludeSlashSingleDataInput from '../Component/IncludeSlashSingleDataInput';
import SmallTimeInput from '../Component/SmallTimeInput';
import SingleRestInput from '../Component/SingleRestInput';

import ProgramVideoIcon from '../../image/ProgramVideoIcon.png';
import ExerciseDeleteIcon from '../../image/ExerciseDeleteIcon.png';
import MovementOptionIcon from '../../image/MovementOptionIcon.png';
import CheckImage from '../../image/WhiteCheck.png';
import CopyIcon from '../../image/CopyIcon.png';
import ResponsiveTimeInput from '../Component/ResponsiveTimeInput';

export default function RecordMovementBox(props) {
  const {
    data,
    
    idx,
    tag,
    division,
    
    onChangeData,
    onChangeUnit,
    onClickRest,

    onClickCopyMovement,
    onClickMovementVideo,
    onClickMovementOption,
    onClickDeleteMovement
  } = props;
  

  const weightOptions = ['Kg', 'Pounds', '%RM', '%Body'];
  const distanceOptions = ['Km', 'm', 'Cm', 'inch', 'Feet'];
  const caloriesOptions = ['Kcal', 'Cal'];

  const fontSize = 14;

  // 횟수 //
  const countInputBorderWidth = 2;
  const countInputPadding = 32;
  const countInputMaxWidth = 450;
  const countInputMinWidth = 200;

  // 무게 //
  const weightInputBorderWidth = 2;
  const weightInputPadding = 32;
  const weightInputMaxWidth = 346;
  const weightInputMinWidth = 96;

  // 거리 //
  const distanceInputBorderWidth = 2;
  const distanceInputPadding = 32;
  const distanceInputMaxWidth = 346;
  const distanceInputMinWidth = 96;
  
  // 높이 //
  const heightInputBorderWidth = 2;
  const heightInputPadding = 32;
  const heightInputMaxWidth = 346;
  const heightInputMinWidth = 96;

  // 열량 //
  const caloriesInputBorderWidth = 2;
  const caloriesInputPadding = 32;
  const caloriesInputMaxWidth = 346;
  const caloriesInputMinWidth = 96;

  // 시간 (분) //
  const minInputBorderWidth = 2;
  const minInputPadding = 32;
  const minInputMaxWidth = 221;
  const minInputMinWidth = 96;

  // 시간 (초) //
  const secInputBorderWidth = 2;
  const secInputPadding = 32;
  const secInputMaxWidth = 221;
  const secInputMinWidth = 96;

  // 템포 //
  const tempoInputBorderWidth = 2;
  const tempoInputPadding = 32;
  const tempoInputMaxWidth = 450;
  const tempoInputMinWidth = 200;

  // 한자리 숫자 앞에 0 붙이기 //
  function numberPad(n, width) {
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
  }

  // 횟수 컴포넌트 길이 계산 //
  function decideCountWidth(value) {
    let calcWidth = (fontSize * 0.6) * (value.toString().length + 4) + countInputBorderWidth + countInputPadding;
    
    if (calcWidth > countInputMaxWidth) return countInputMaxWidth;
    else if (calcWidth < countInputMinWidth) return countInputMinWidth;
    else return calcWidth;
  }

  // 무게 컴포넌트 길이 계산 //
  function decideWeightWidth(value) {
    let weightUnitLength = 4;

    if (data.weightUnit === 'Pounds') weightUnitLength = 2;
    else if (data.weightUnit === 'Kg') weightUnitLength = 1;
    else weightUnitLength = 1;

    let calcWidth = (fontSize * 0.6) * (value.toString().length + weightUnitLength) + weightInputBorderWidth + weightInputPadding;
    
    if (calcWidth > weightInputMaxWidth) return weightInputMaxWidth;
    else if (calcWidth < weightInputMinWidth) return weightInputMinWidth;
    else return calcWidth;
  }

  // 거리 컴포넌트 길이 계산 //
  function decideDistanceWidth(value) {
    let distanceUnitLength = 2;

    if (data.distanceUnit === 'Km') distanceUnitLength = 2;
    else if (data.distanceUnit === 'm') distanceUnitLength = 1;
    else if (data.distanceUnit === 'Cm') distanceUnitLength = 2;
    else if (data.distanceUnit === 'inch') distanceUnitLength = 4;

    let calcWidth = (fontSize * 0.6) * (value.toString().length + distanceUnitLength) + distanceInputBorderWidth + distanceInputPadding;
    
    if (calcWidth > distanceInputMaxWidth) return distanceInputMaxWidth;
    else if (calcWidth < distanceInputMinWidth) return distanceInputMinWidth;
    else return calcWidth;
  }

  // 높이 컴포넌트 길이 계산 //
  function decideHeightWidth(value) {
    let heightUnitLength = 2;

    if (data.heightUnit === 'Km') heightUnitLength = 2;
    else if (data.heightUnit === 'm') heightUnitLength = 1;
    else if (data.heightUnit === 'Cm') heightUnitLength = 2;
    else if (data.heightUnit === 'inch') heightUnitLength = 4;

    let calcWidth = (fontSize * 0.6) * (value.toString().length + heightUnitLength) + heightInputBorderWidth + heightInputPadding;
    
    if (calcWidth > heightInputMaxWidth) return heightInputMaxWidth;
    else if (calcWidth < heightInputMinWidth) return heightInputMinWidth;
    else return calcWidth;
  }

  // 열량 컴포넌트 길이 계산 //
  function decideCaloriesWidth(value) {
    let caloriesUnitLength = 4;

    if (data.caloriesUnit === 'Kcal') caloriesUnitLength = 4;
    else if (data.caloriesUnit === 'cal') caloriesUnitLength = 3;

    let calcWidth = (fontSize * 0.6) * (value.toString().length + caloriesUnitLength) + caloriesInputBorderWidth + caloriesInputPadding;
    
    if (calcWidth > caloriesInputMaxWidth) return caloriesInputMaxWidth;
    else if (calcWidth < caloriesInputMinWidth) return caloriesInputMinWidth;
    else return calcWidth;
  }

  // 시간(분) 컴포넌트 길이 계산 //
  function decideMinWidth(value) {
    let calcWidth = (fontSize * 0.6) * (value.toString().length + 3) + minInputBorderWidth + minInputPadding;
    
    if (calcWidth > minInputMaxWidth) return minInputMaxWidth;
    else if (calcWidth < minInputMinWidth) return minInputMinWidth;
    else return calcWidth;
  }

  // 시간(초) 컴포넌트 길이 계산 //
  function decideSecWidth(value) {
    let calcWidth = (fontSize * 0.6) * (value.toString().length + 3) + secInputBorderWidth + secInputPadding;
    
    if (calcWidth > secInputMaxWidth) return secInputMaxWidth;
    else if (calcWidth < secInputMinWidth) return secInputMinWidth;
    else return calcWidth;
  }

  // 템포 컴포넌트 길이 계산 //
  function decideTempoWidth(value) {
    let calcWidth = (fontSize * 0.6) * (value.toString().length + 5) + tempoInputBorderWidth + tempoInputPadding;
    
    if (calcWidth > tempoInputMaxWidth) return tempoInputMaxWidth;
    else if (calcWidth < tempoInputMinWidth) return tempoInputMinWidth;
    else return calcWidth;
  }

  return (
    <TopLevelWrapper>
      <TotalWrapper >
        <MovementWrapper>
          <MovementTitleWrapper>
            <TextScaleButtonWrapper>
              <NumberWrapper >
                <NumberBox>
                  <HoverPointerText fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>{numberPad(idx + 1, 2)}</HoverPointerText>
                </NumberBox>
                <HoverPointerText fontFamily='Poppins-Medium' fontSize={12} color='#4F4F4F'>{data.movementName}</HoverPointerText>
              </NumberWrapper>
            </TextScaleButtonWrapper>
            <IconWrapper>
              <Icon src={ProgramVideoIcon} onClick={() => onClickMovementVideo(idx)} />
              <Icon style={{ width: `16px`, height: `16px` }} src={CopyIcon} onClick={() => onClickCopyMovement(data)} />
              <Icon src={MovementOptionIcon} onClick={() => onClickMovementOption(idx)} />
              <Icon src={ExerciseDeleteIcon} onClick={() => onClickDeleteMovement(idx)} />
            </IconWrapper>
          </MovementTitleWrapper>
          {
            data.isUseCount &&
            <LayerWrapper topMargin={16}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>횟수</Text>
              <ComponentWrapper>
                {
                  tag === 'SINGLE' &&
                  <SingleCountWrapper>
                    <IncludeSlashSingleDataInput value={data.count} setValue={onChangeData} unit='REPS' column='count' index={idx} />
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>X</Text>
                    <IncludeSlashSingleDataInput value={data.sets} setValue={onChangeData} unit='SETS' column='sets' index={idx} />
                  </SingleCountWrapper>
                }
                {
                  tag !== 'SINGLE' &&
                  <NoUnitInputWrapper width={decideCountWidth(data.count)}>
                    <IncludeSlashDataInput value={tag === 'TABATA' ? 'MAX' : data.count} setValue={onChangeData} unit='REPS' column='count' index={idx} />
                  </NoUnitInputWrapper>
                }
              </ComponentWrapper>
            </LayerWrapper>
          }
          {
            data.isUseWeight &&
            <LayerWrapper topMargin={12}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>무게</Text>
              <ComponentWrapper>
                <InputWrapper width={decideWeightWidth(data.weight)}>
                  <IncludeSlashDataInput value={data.weight} setValue={onChangeData} unit={data.weightUnit} column='weight' index={idx} />
                </InputWrapper>
                <UnitSelectBox value={data.weightUnit} setValue={onChangeUnit} options={weightOptions} column='weight' index={idx} />
              </ComponentWrapper>
            </LayerWrapper>
          }
          {
            data.isUseDistance &&
            <LayerWrapper topMargin={12}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>거리</Text>
              <ComponentWrapper>
                <InputWrapper width={decideDistanceWidth(data.distance)}>
                  <IncludeSlashDataInput value={data.distance} setValue={onChangeData} unit={data.distanceUnit} column='distance' index={idx} />
                </InputWrapper>
                <UnitSelectBox value={data.distanceUnit} setValue={onChangeUnit} options={distanceOptions} column='distance' index={idx} />
              </ComponentWrapper>
            </LayerWrapper>
          }
          {
            data.isUseHeight &&
            <LayerWrapper topMargin={12}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>높이</Text>
              <ComponentWrapper>
                <InputWrapper width={decideHeightWidth(data.height)}>
                  <IncludeSlashDataInput value={data.height} setValue={onChangeData} unit={data.heightUnit} column='height' index={idx} />
                </InputWrapper>
                <UnitSelectBox value={data.heightUnit} setValue={onChangeUnit} options={distanceOptions} column='height' index={idx} />
              </ComponentWrapper>
            </LayerWrapper>
          }
          {
            data.isUseCalories &&
            <LayerWrapper topMargin={12}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>열량</Text>
              <ComponentWrapper>
                <InputWrapper width={decideCaloriesWidth(data.calories)}>
                  <IncludeSlashDataInput value={data.calories} setValue={onChangeData} unit={data.caloriesUnit} column='calories' index={idx} />
                </InputWrapper>
                <UnitSelectBox value={data.caloriesUnit} setValue={onChangeUnit} options={caloriesOptions} column='calories' index={idx} />
              </ComponentWrapper>
            </LayerWrapper>
          }
          {
            data.isUseTime &&
            <LayerWrapper topMargin={12}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>시간</Text>
              <ComponentWrapper>
                <TimeInputWrapper>
                  <TimeInnerInputWrapper width={decideMinWidth(data.minute)}>
                    <ResponsiveTimeInput value={data.minute} setValue={onChangeData} unit='MIN' column='minute' index={idx} />
                  </TimeInnerInputWrapper>
                  <TimeInnerInputWrapper width={decideSecWidth(data.second)}>
                    <ResponsiveTimeInput value={data.second} setValue={onChangeData} unit='SEC' column='second' index={idx} />
                  </TimeInnerInputWrapper>
                </TimeInputWrapper>
              </ComponentWrapper>
            </LayerWrapper>
          }
          {
            data.isUseTempo &&
            <LayerWrapper topMargin={12}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>템포</Text>
              <ComponentWrapper>
                <NoUnitInputWrapper width={decideTempoWidth(data.tempo)}>
                  <IncludeSlashDataInput value={data.tempo} setValue={onChangeData} unit='TEMPO' column='tempo' index={idx} />
                </NoUnitInputWrapper>
              </ComponentWrapper>
            </LayerWrapper>
          }
          {
            tag === 'SINGLE' &&
            <RestComponentWrapper topMargin={12}>
              <CheckBox isClicked={data.isUseRest} onClick={() => onClickRest(division, idx)}>
                <Check src={CheckImage} />
              </CheckBox>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>쉬는 시간</Text>
              <SingleRestInput value={data.rest} setValue={onChangeData} disabled={!data.isUseRest} unit='SEC' column='rest' index={idx} />
            </RestComponentWrapper>
          }
        </MovementWrapper>
      </TotalWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  padding-bottom: 18px;

  width: 100%;
  z-index: ${props => props.index};

  border-bottom: 1px solid #CBCBCB;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: 100%;
  background-color: #FFFFFF;
`;

const MovementWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: 100%;

  margin-top: 23px;
  background-color: #FFFFFF;
`;

const NumberWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;

  :hover {
    cursor: pointer;
  }
`;

const MovementTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 12px;
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;

  :hover {
    cursor: pointer;
  }
`;

const NumberBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 27px;
  height: 18px;

  border-radius: 6px;
  background-color: #FF8B48;

  margin: 0 0 0 0;
  padding: 0 0 0 0;
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;

  margin-top: ${props => props.topMargin}px;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const NoUnitInputWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 200px;
  width: ${props => props.width}px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 118px;
  // width: 96px;
  width: ${props => props.width}px;
`;

const SingleCountWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const TextScaleButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 12px;
`;

const HoverPointerText = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  padding: 0 0 0 0;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  padding: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const TimeInputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;

  // width: ${props => props.width}px;
`;

const TimeInnerInputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;

  width: ${props => props.width}px;
`;


const RestComponentWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 7px;

  margin-top: ${props => props.topMargin}px;
`;

const CheckBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;

  border-radius: 6px;
  background-color: ${props => props.isClicked ? `#6DD49E` : `#E0E0E0`};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const Check = styled.img`
  width: 10px;
  height: 9px;
`;

const EmptyBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  min-height: ${props => props.height}px;
`;