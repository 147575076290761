import { React, useRef, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import SecondPasswordInput from '../../../Component/StatisticsComponent/SecondPasswordInput';
import SecondInput from '../../../Component/StatisticsComponent/SecondInput';
import FetchModule from '../../Share/Network/FetchModule';

export default function SecondAuthenticationBox(props) {
  const { clickFn } = props;

  const [shaking, setShaking] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed, setIsFailed] = useState(false);


  const [prevShaking, setPrevShaking] = useState(false);
  const [newCheckShaking, setNewCheckShaking] = useState(false);

  const [isWritePrevPw, setIsWritePrevPw] = useState(false);
  const [isWriteNewPw, setIsWriteNewPw] = useState(false);
  const [isSuccessChangePw, setIsSuccessChangePw] = useState(false);
  const [isCheckNewPw, setIsCheckNewPw] = useState(true);
  const [isValidPrevPw, setIsValidPrevPw] = useState(true);

  const [isClickedChangePw, setIsClickedChangePw] = useState(false);

  const [nowFocused, setNowFocused] = useState('');
  const [isBlur, setIsBlur] = useState(true);
 
  const FirstRef = useRef();
  const SecondRef = useRef();
  const ThirdRef = useRef();
  const FourseRef = useRef();

  const PrevFirstRef = useRef();
  const PrevSecondRef = useRef();
  const PrevThirdRef = useRef();
  const PrevFourseRef = useRef();

  const NewFirstRef = useRef();
  const NewSecondRef = useRef();
  const NewThirdRef = useRef();
  const NewFourseRef = useRef();

  const NewCheckFirstRef = useRef();
  const NewCheckSecondRef = useRef();
  const NewCheckThirdRef = useRef();
  const NewCheckFourseRef = useRef();

  useEffect(() => {
    const handleKeyDown = (event) => {
      setIsFailed(false);

      if (event.keyCode === 8) {
        if (!isClickedChangePw) {
          let fourse = FourseRef.current.getValue();
          let third = ThirdRef.current.getValue();
          let second = SecondRef.current.getValue();
          let first = FirstRef.current.getValue();
  
          if (fourse !== '') {
            FourseRef.current.setValue('');
            FourseRef.current.focus();
          }
          else if (third !== '') {
            ThirdRef.current.setValue('');
            ThirdRef.current.focus();
          }
          else if (second !== '') {
            SecondRef.current.setValue('');
            SecondRef.current.focus();
          }
          else if (first !== '') {
            FirstRef.current.setValue('');
            FirstRef.current.focus();
          }
        }
        else {
          let prevFirst = PrevFirstRef.current.getValue();
          let prevSecond = PrevSecondRef.current.getValue();
          let prevThird = PrevThirdRef.current.getValue();
          let prevFourse = PrevFourseRef.current.getValue();

          let newFirst = NewFirstRef.current.getValue();
          let newSecond = NewSecondRef.current.getValue();
          let newThird = NewThirdRef.current.getValue();
          let newFourse = NewFourseRef.current.getValue();

          let newCheckFirst = NewCheckFirstRef.current.getValue();
          let newCheckSecond = NewCheckSecondRef.current.getValue();
          let newCheckThird = NewCheckThirdRef.current.getValue();
          let newCheckFourse = NewCheckFourseRef.current.getValue();
  
          setIsValidPrevPw(true);
          setIsCheckNewPw(true);

          if (nowFocused.includes('NewCheck') && !isBlur) {
            if (newCheckFourse !== '') {
              NewCheckFourseRef.current.setValue('');
              NewCheckFourseRef.current.focus();
            }
            else if (newCheckThird !== '') {
              NewCheckThirdRef.current.setValue('');
              NewCheckThirdRef.current.focus();
            }
            else if (newCheckSecond !== '') {
              NewCheckSecondRef.current.setValue('');
              NewCheckSecondRef.current.focus();
            }
            else if (newCheckFirst !== '') {
              NewCheckFirstRef.current.setValue('');
              NewCheckFirstRef.current.focus();
            }
          }
          else if (nowFocused.includes('New') && !isBlur) {
            if (newFourse !== '') {
              setIsWriteNewPw(false);
  
              NewFourseRef.current.setValue('');
              NewFourseRef.current.focus();
            }
            else if (newThird !== '') {
              NewThirdRef.current.setValue('');
              NewThirdRef.current.focus();
            }
            else if (newSecond !== '') {
              NewSecondRef.current.setValue('');
              NewSecondRef.current.focus();
            }
            else if (newFirst !== '') {
              NewFirstRef.current.setValue('');
              NewFirstRef.current.focus();
            }
          }
          else if (nowFocused.includes('Prev') && !isBlur) {
            if (prevFourse !== '') {
              setIsWritePrevPw(false);
  
              PrevFourseRef.current.setValue('');
              PrevFourseRef.current.focus();
            }
            else if (prevThird !== '') {
              PrevThirdRef.current.setValue('');
              PrevThirdRef.current.focus();
            }
            else if (prevSecond !== '') {
              PrevSecondRef.current.setValue('');
              PrevSecondRef.current.focus();
            }
            else if (prevFirst !== '') {
              PrevFirstRef.current.setValue('');
              PrevFirstRef.current.focus();
            }
          }
          else {
            if (newCheckFourse !== '') {
              NewCheckFourseRef.current.setValue('');
              NewCheckFourseRef.current.focus();
            }
            else if (newCheckThird !== '') {
              NewCheckThirdRef.current.setValue('');
              NewCheckThirdRef.current.focus();
            }
            else if (newCheckSecond !== '') {
              NewCheckSecondRef.current.setValue('');
              NewCheckSecondRef.current.focus();
            }
            else if (newCheckFirst !== '') {
              NewCheckFirstRef.current.setValue('');
              NewCheckFirstRef.current.focus();
            }
            else if (newFourse !== '') {
              setIsWriteNewPw(false);
  
              NewFourseRef.current.setValue('');
              NewFourseRef.current.focus();
            }
            else if (newThird !== '') {
              NewThirdRef.current.setValue('');
              NewThirdRef.current.focus();
            }
            else if (newSecond !== '') {
              NewSecondRef.current.setValue('');
              NewSecondRef.current.focus();
            }
            else if (newFirst !== '') {
              NewFirstRef.current.setValue('');
              NewFirstRef.current.focus();
            }
            else if (prevFourse !== '') {
              setIsWritePrevPw(false);
  
              PrevFourseRef.current.setValue('');
              PrevFourseRef.current.focus();
            }
            else if (prevThird !== '') {
              PrevThirdRef.current.setValue('');
              PrevThirdRef.current.focus();
            }
            else if (prevSecond !== '') {
              PrevSecondRef.current.setValue('');
              PrevSecondRef.current.focus();
            }
            else if (prevFirst !== '') {
              PrevFirstRef.current.setValue('');
              PrevFirstRef.current.focus();
            }
          }
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, [isClickedChangePw, nowFocused])

  // 비밀번호 변경 및 돌아가기 클릭시 //
  useEffect(() => {
    if (!isClickedChangePw) {
      PrevFirstRef.current.reset();
      PrevSecondRef.current.reset();
      PrevThirdRef.current.reset();
      PrevFourseRef.current.reset();

      NewFirstRef.current.reset();
      NewSecondRef.current.reset();
      NewThirdRef.current.reset();
      NewFourseRef.current.reset();

      NewCheckFirstRef.current.reset();
      NewCheckSecondRef.current.reset();
      NewCheckThirdRef.current.reset();
      NewCheckFourseRef.current.reset();

      setIsWritePrevPw(false);
      setIsWriteNewPw(false);
      setIsSuccessChangePw(false);
      setIsCheckNewPw(true);
      setIsValidPrevPw(true);
    }
    else {
      FirstRef.current.reset();
      SecondRef.current.reset();
      ThirdRef.current.reset();
      FourseRef.current.reset();
    }
  }, [isClickedChangePw])

  // 글자 입력시 //
  function onChangeValue(className, number) {
    if (className === 'First') SecondRef.current.focus();
    else if (className === 'Second') ThirdRef.current.focus();
    else if (className === 'Third') FourseRef.current.focus();
    else if (className === 'Fourse') {
      let first = FirstRef.current.getValue();
      let second = SecondRef.current.getValue();
      let third = ThirdRef.current.getValue();
      let fourse = number;
      
      // console.log("???");
      // console.log(first);
      // console.log(second);
      // console.log(third);
      // console.log(fourse);
      if (first !== ''
        && second !== ''
        && third !== ''
        && fourse !== '') {
          onCheckSecondPassword(first + second + third + fourse);
        }
    }
  }

  // 비밀번호 변경용 글자 입력시 //
  function onChangeNewValue(className, number) {
    if (className === 'PrevFirst') PrevSecondRef.current.focus();
    else if (className === 'PrevSecond') PrevThirdRef.current.focus();
    else if (className === 'PrevThird') PrevFourseRef.current.focus();
    else if (className === 'PrevFourse') {
      setIsWritePrevPw(true);
      NewFirstRef.current.focus();
    }
    else if (className === 'NewFirst') NewSecondRef.current.focus();
    else if (className === 'NewSecond') NewThirdRef.current.focus();
    else if (className === 'NewThird') NewFourseRef.current.focus();
    else if (className === 'NewFourse') {
      setIsWriteNewPw(true);
      NewCheckFirstRef.current.focus();
    }
    else if (className === 'NewCheckFirst') NewCheckSecondRef.current.focus();
    else if (className === 'NewCheckSecond') NewCheckThirdRef.current.focus();
    else if (className === 'NewCheckThird') NewCheckFourseRef.current.focus();
    else if (className === 'NewCheckFourse') {
      let prevFirst = PrevFirstRef.current.getValue();
      let prevSecond = PrevSecondRef.current.getValue();
      let prevThird = PrevThirdRef.current.getValue();
      let prevFourse = PrevFourseRef.current.getValue();

      let newFirst = NewFirstRef.current.getValue();
      let newSecond = NewSecondRef.current.getValue();
      let newThird = NewThirdRef.current.getValue();
      let newFourse = NewFourseRef.current.getValue();

      let newCheckFirst = NewCheckFirstRef.current.getValue();
      let newCheckSecond = NewCheckSecondRef.current.getValue();
      let newCheckThird = NewCheckThirdRef.current.getValue();
      let newCheckFourse = number;

      if (prevFirst !== ''
        && prevSecond !== ''
        && prevThird !== ''
        && prevFourse !== ''
        && newFirst !== ''
        && newSecond !== ''
        && newThird !== ''
        && newFourse !== ''
        && newCheckFirst !== ''
        && newCheckSecond !== ''
        && newCheckThird !== ''
        && newCheckFourse !== '') {
          let prevPw = prevFirst + prevSecond + prevThird + prevFourse;
          let newPw = newFirst + newSecond + newThird + newFourse;
          let newCheckPw = newCheckFirst + newCheckSecond + newCheckThird + newCheckFourse;

          onChangeSecondPassword(prevPw, newPw, newCheckPw);
        }
    }
  }

  // 버튼 텍스트 결정 //
  function decideButtonText() {
    if (isClickedChangePw) {
      if (!isCheckNewPw) return '재입력 비밀번호가 일치하지 않습니다';
      else if (!isValidPrevPw) return '기존 비밀번호가 일치하지 않습니다';
      else if (!isWritePrevPw) return '기존 비밀번호를 입력해 주세요';
      else if (!isWriteNewPw) return '새로운 비밀번호를 입력해주세요';
      else if (isSuccessChangePw) return '비밀번호 변경이 완료되었습니다';
      else return '새로운 비밀번호를 확인해주세요';
    }
    else {
      if (isFailed) return '비밀번호가 일치하지 않습니다';
      else if (isSuccess) return '올바른 비밀번호';
      else return '비밀번호를 입력해 주세요';
    }
  }
  
  // 버튼 배경색 결정 //
  function decideButtonBackground() {
    if (isClickedChangePw) return 'linear-gradient(90deg, #FF8B48 50%, #F95D02 94.27%)';
    else {
      if (isFailed) return 'linear-gradient(90deg, #D40303 29.14%, #B80000 94.27%)';
      else if (isSuccess) return 'linear-gradient(90deg, #1DBA58 29.94%, #5BD98A 94.27%)';
      else return 'linear-gradient(90deg, #FF8B48 50%, #F95D02 94.27%)';
    }
  }

  // Input Wrapper Margin 결정 //
  function decideInputTopMargin(className) {
    if (!isClickedChangePw) return 0;
    else if (className === 'Prev') return 20;
    else return 18;
  }

  // 2차 비밀번호 맞는지 확인
  function onCheckSecondPassword(secondPw) {
    let requestData = {
      secondPw: secondPw
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postData('second_auth/check', 'POST', requestData);
        // console.log("-----Year Sales");
        // console.log(responseData);
        if (responseData.status === 200) {
          setIsSuccess(true);
          setIsFailed(false);

          setTimeout(() => {
            clickFn();
          }, 500)
        }
        else if (responseData.status === 201) {
          setIsFailed(true);
          setShaking(true);

          setTimeout(() => {
            setShaking(false);
          }, 500)

          setTimeout(() => {  
            FourseRef.current.reset();
            ThirdRef.current.reset();
            SecondRef.current.reset();
            FirstRef.current.reset();
            FirstRef.current.focus();

            setIsFailed(false);
          }, 1500)
        }
        // else if (responseData.status === 401) history.push('/');
      }
    )();
  }

  // 2차 비밀번호 변경 //
  function onChangeSecondPassword(prevPw, newPw, newCheckPw) {
    if (newPw !== newCheckPw) {
      setIsCheckNewPw(false);

      setNewCheckShaking(true);

      setTimeout(() => {
        setNewCheckShaking(false);
      }, 500)

      return;
    }
    else if (prevPw.length < 4) {
      setIsCheckNewPw(true);
      setIsWritePrevPw(false);

      return;
    }
    else if (newPw.length < 4) {
      setIsCheckNewPw(true);
      setIsWriteNewPw(false);

      return;
    }
    else setIsCheckNewPw(true);

    let requestData = {
      prevSecondPw: prevPw,
      newSecondPw: newPw
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postData('second_auth/change', 'POST', requestData);
        // console.log("-----Year Sales");
        // console.log(responseData);
        if (responseData.status === 200) {
          setIsSuccessChangePw(true);
          setIsValidPrevPw(true);

          setTimeout(() => {
            setIsClickedChangePw(false);
          }, 1000)
        }
        else if (responseData.status === 201) {
          setIsSuccessChangePw(false);
          setIsValidPrevPw(false);

          setPrevShaking(true);

          setTimeout(() => {
            setPrevShaking(false);
          }, 500)
        }
        // else if (responseData.status === 401) history.push('/');
      }
    )();
  }

  // 비밀번호 입력 및 변경 버튼 클릭시 //
  function onClickButton() {
    if (isClickedChangePw) {
      let prevFirst = PrevFirstRef.current.getValue();
      let prevSecond = PrevSecondRef.current.getValue();
      let prevThird = PrevThirdRef.current.getValue();
      let prevFourse = PrevFourseRef.current.getValue();

      let newFirst = NewFirstRef.current.getValue();
      let newSecond = NewSecondRef.current.getValue();
      let newThird = NewThirdRef.current.getValue();
      let newFourse = NewFourseRef.current.getValue();

      let newCheckFirst = NewCheckFirstRef.current.getValue();
      let newCheckSecond = NewCheckSecondRef.current.getValue();
      let newCheckThird = NewCheckThirdRef.current.getValue();
      let newCheckFourse = NewCheckFourseRef.current.getValue();
      
      let prevPw = prevFirst + prevSecond + prevThird + prevFourse;
      let newPw = newFirst + newSecond + newThird + newFourse;
      let newCheckPw = newCheckFirst + newCheckSecond + newCheckThird + newCheckFourse;

      onChangeSecondPassword(prevPw, newPw, newCheckPw);
    }
    else {
      let first = FirstRef.current.getValue();
      let second = SecondRef.current.getValue();
      let third = ThirdRef.current.getValue();
      let fourse = FourseRef.current.getValue();
      
      onCheckSecondPassword(first + second + third + fourse);
    }
  }

  return (
    <TopLevelWrapper isClickedChangePw={isClickedChangePw}>
      <PasswordInputWrapper isClickedChangePw={isClickedChangePw} shaking={shaking}>
        <SecondPasswordInput 
          ref={FirstRef} 
          className='First' 
          onChangeValue={onChangeValue}/>
        <SecondPasswordInput 
          ref={SecondRef} 
          className='Second'
          onChangeValue={onChangeValue}/>
        <SecondPasswordInput 
          ref={ThirdRef} 
          className='Third'
          onChangeValue={onChangeValue}/>
        <SecondPasswordInput 
          ref={FourseRef} 
          className='Fourse'
          onChangeValue={onChangeValue}/>
      </PasswordInputWrapper>
      <InputTotalWrapper topMargin={decideInputTopMargin('Prev')} isClickedChangePw={isClickedChangePw}>
        <ChangeTextWrapper>
          <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#232323' cursor='default'>기존 비밀번호</Text>
          <Text fontFamily='NotoSansKR-Medium' fontSize={12} color='#232323' cursor='default'>초기 비밀번호 0000</Text>
        </ChangeTextWrapper>
        <InputWrapper shaking={prevShaking}>
          <SecondInput
            ref={PrevFirstRef}
            className='PrevFirst'
            onChangeValue={onChangeNewValue} 
            setNowFocused={setNowFocused}
            setIsBlur={setIsBlur}/>
          <SecondInput
            ref={PrevSecondRef}
            className='PrevSecond'
            onChangeValue={onChangeNewValue}  
            setNowFocused={setNowFocused}
            setIsBlur={setIsBlur}/>
          <SecondInput
            ref={PrevThirdRef}
            className='PrevThird'
            onChangeValue={onChangeNewValue}  
            setNowFocused={setNowFocused}
            setIsBlur={setIsBlur}/>
          <SecondInput
            ref={PrevFourseRef}
            className='PrevFourse'
            onChangeValue={onChangeNewValue}  
            setNowFocused={setNowFocused}
            setIsBlur={setIsBlur}/>
        </InputWrapper>
      </InputTotalWrapper>
      <InputTotalWrapper topMargin={decideInputTopMargin('New')} isClickedChangePw={isClickedChangePw}>
        <ChangeTextWrapper>
          <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#232323' cursor='default'>새로운 비밀번호</Text>
        </ChangeTextWrapper>
        <InputWrapper>
          <SecondInput
            ref={NewFirstRef}
            className='NewFirst'
            onChangeValue={onChangeNewValue}  
            setNowFocused={setNowFocused}
            setIsBlur={setIsBlur}/>
          <SecondInput
            ref={NewSecondRef}
            className='NewSecond'
            onChangeValue={onChangeNewValue}  
            setNowFocused={setNowFocused}
            setIsBlur={setIsBlur}/>
          <SecondInput
            ref={NewThirdRef}
            className='NewThird'
            onChangeValue={onChangeNewValue}  
            setNowFocused={setNowFocused}
            setIsBlur={setIsBlur}/>
          <SecondInput
            ref={NewFourseRef}
            className='NewFourse'
            onChangeValue={onChangeNewValue}  
            setNowFocused={setNowFocused}
            setIsBlur={setIsBlur}/>
        </InputWrapper>
      </InputTotalWrapper>
      <InputTotalWrapper topMargin={decideInputTopMargin('NewCheck')} isClickedChangePw={isClickedChangePw}>
        <ChangeTextWrapper>
          <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#232323' cursor='default'>새로운 비밀번호 입력</Text>
        </ChangeTextWrapper>
        <InputWrapper shaking={newCheckShaking}>
          <SecondInput
            ref={NewCheckFirstRef}
            className='NewCheckFirst'
            onChangeValue={onChangeNewValue}  
            setNowFocused={setNowFocused}
            setIsBlur={setIsBlur}/>
          <SecondInput
            ref={NewCheckSecondRef}
            className='NewCheckSecond'
            onChangeValue={onChangeNewValue}  
            setNowFocused={setNowFocused}
            setIsBlur={setIsBlur}/>
          <SecondInput
            ref={NewCheckThirdRef}
            className='NewCheckThird'
            onChangeValue={onChangeNewValue}  
            setNowFocused={setNowFocused}
            setIsBlur={setIsBlur}/>
          <SecondInput
            ref={NewCheckFourseRef}
            className='NewCheckFourse'
            onChangeValue={onChangeNewValue}  
            setNowFocused={setNowFocused}
            setIsBlur={setIsBlur}/>
        </InputWrapper>
      </InputTotalWrapper>
      <Button isClickedChangePw={isClickedChangePw} background={decideButtonBackground()} onClick={onClickButton}>
        <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='pointer'>{decideButtonText()}</Text>
      </Button>
      <ChangeButtonWrapper>
        <Text onClick={() => setIsClickedChangePw(!isClickedChangePw)} fontFamily='NotoSansKR-Regular' fontSize={14} color='#FF8B48' cursor='pointer'>{isClickedChangePw ? '돌아가기' : '2차 비밀번호 변경'}</Text>
      </ChangeButtonWrapper>
    </TopLevelWrapper>
  )
}

const shake = keyframes`
  0%, 100% {
    transform: translateX(0);
  }
  20%, 50%, 80% {
    transform: translateX(-4px);
  }
  40%, 60% {
    transform: translateX(4px);
  }
`;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  margin-top: 32px;

  width: 370px;
  // height: 204px;
  height: ${props => props.isClickedChangePw ? 463 : 204}px;

  border-radius: 30px;
  background-color: #FFFFFF;
  filter: drop-shadow(0px 1px 8px rgba(0, 0, 0, 0.10));

  transition: all 0.3s ease-in-out;
`;

const PasswordInputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: ${props => props.isClickedChangePw ? `0px` : `30px`};

  width: 314px;
  height: ${props => props.isClickedChangePw ? `0px` : `65px`};
  
  gap: 18px;

  // animation: ${({ shaking }) => (shaking ? shake : 'none')} 0.5s ease-in-out;
  animation: ${props => props.shaking ? shake : 'none'} 0.5s ease-in-out;

  opacity: ${props => props.isClickedChangePw ? 0 : 1};
  overflow: hidden;

  transition: all 0.3s ease-in-out;
`;

const InputTotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 0px 4px 0px 4px;
  margin-top: ${props => props.topMargin}px;

  width: 314px;
  height: ${props => props.isClickedChangePw ? 101 : 0}px;

  opacity: ${props => props.isClickedChangePw ? 1 : 0};
  
  gap: 12px;

  overflow: hidden;

  transition: all 0.3s ease-in-out;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 314px;
  
  gap: 18px;

  animation: ${props => props.shaking ? shake : 'none'} 0.5s ease-in-out;

  transition: all 0.3s ease-in-out;
`;

const Button = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: ${props => props.isClickedChangePw ? `23px` : `27px`};

  width: 314px;
  height: 40px;
  
  border-radius: 15px;
  // background: linear-gradient(90deg, #FF8B48 50%, #F95D02 94.27%);
  background: ${props => props.background};

  transition: all 0.5s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const ChangeButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-end;

  margin-top: 12px;

  width: 314px;
`;

const ChangeTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 100%;

  gap: 12px;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }
`;