import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";

import WhiteSelectOffPath from '../../image/WhiteSelectOff.png';
import SelectOnPath from '../../image/SelectOn.png';
import SelectOffPath from '../../image/SelectOff.png';

export default function CustomScoreDetailSelectBox(props) {
  const { value, setValue, disabled } = props;

  const [isClicked, setIsClicked] = useState(false);

  const TotalRef = useRef();
  const SelectListRef = useRef();

  const options = ['높을 수록', '낮을 수록'];

  // 컴포넌트 밖을 클릭하면 셀렉트 창 닫기
  useEffect(() => {
    document.addEventListener("mousedown", onClickOutSide);

    return () => {
      document.removeEventListener("mousedown", onClickOutSide);
    }
  }, [onClickOutSide])

  // 옆에 띄워줄 영어 텍스트 결정 //
  function decideEnglishText() {
    if (value === '높을 수록') return 'heigher';
    else if (value === '낮을 수록') return 'lower';
  }

  // 컴포넌트 밖을 클릭하면 셀렉트 창 닫기
  function onClickOutSide(e) {
    if (TotalRef.current !== e.target && !TotalRef.current.contains(e.target)) {
      setIsClicked(false);
    }
  }

  // 셀렉트 박스 클릭시 //
  function onClickSelectBox() {
    if (disabled) return;

    if (isClicked) {
      setIsClicked(false);
    }
    else {
      setIsClicked(true);
    }
  }

  // 수업 선택시 //
  function onClickSelectList(data) {
    setValue(data);
    setIsClicked(false);
  }

  return (
    <SelectBoxWrapper ref={TotalRef}>
      <SeletedBox disabled={disabled} onClick={onClickSelectBox}>
        <SelectBoxTextWrapper>
          <Text fontFamily='Poppins-Medium' fontSize={14} color='#000000' hover='pointer'>{disabled ? `-` : value}</Text>
          { !disabled && <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='rgba(79, 79, 79, 0.20)' hover='pointer'>{decideEnglishText()}</Text> }
        </SelectBoxTextWrapper>
        { !disabled && <ArrowImage src={SelectOffPath} isClicked={isClicked} /> }
      </SeletedBox>
      <WillSelectList ref={SelectListRef} isClicked={isClicked}>
        {
          options.map((data, index) => (
            <SelectListTextWrapper
              key={'CustomTagDetail-' + index}
              isClicked={isClicked}
              onClick={() => onClickSelectList(data)}>
              {data}
            </SelectListTextWrapper>
          ))
        }
      </WillSelectList>
    </SelectBoxWrapper>
  )
}

const SelectBoxWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  height: 100%;
`;

const SeletedBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding: 0px 15px;

  width: calc(100% - 32px);
  height: calc(100% - 2px);

  border: 1px solid #CBCBCB;
  border-radius: 30px;
  // background-color: #FFFFFF;
	background-color: ${props => props.disabled ? `#ECECEC` : `rgba(255, 255, 255, 1)`};

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const ArrowImage = styled.img`
  width: 24px;
  height: 24px;

  transform: ${props => props.isClicked ? `rotate(-180deg)` : `rotate(0deg)`};

  transition: all 0.3s ease-in-out;
`;

const WillSelectList = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  margin-top: 37px;

  width: 100%;
  height: ${props => props.isClicked ? `68px` : `0px`};

  border: ${props => props.isClicked ? `1px solid #FF8B48` : `0px`};
  border-radius: 6px;


	overflow: hidden;

	:hover {
		cursor: pointer;
	}

	&::-webkit-scrollbar {
		// width: 9px;
		width: 0px;
	}

	&::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 20px #FF8B48;
		border: 3px solid transparent;
		height: 25px;
		// background-color: #FF8B48;
		border-radius: 200px;
	}

	&::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px transparent;
		border: 4px solid transparent;
		background-color: transparent;
	}

	&::-webkit-scrollbar-track-piece {
		background-color: transparent;
	}

  transition: all 0.3s ease-in-out;
`;

const SelectBoxTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 80%;
  height: 21px;

  gap: 4px;

  overflow: hidden;
`;

const SelectListTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  padding: 0px 10px;

  width: calc(100% - 20px);
  min-height: 34px;

  overflow: hidden;
  
  background-color: #FFFFFF;

  font-family: Poppins-Medium;
  font-size: 14px;

  color: #4F4F4F;

  :hover {
    cursor: pointer;
    color: #FFFFFF;
    background-color: #FF8B48;
  }

  transition: all 0.3s ease-in-out;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  
  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;