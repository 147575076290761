import { React } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import TopBarLogoPath from '../../../image/TopBarLogo.png';
// import ProfilePath from '../../../image/Profile.png';
// import HoverNoticePath from '../../../image/Notice.png';
// import NotHoverNoticePath from '../../../image/NotHoverNotice.png';
// import HoverSettingPath from '../../../image/Setting.png';
// import NotHoverSettingPath from '../../../image/NotHoverSetting.png';

function TopBar() {
	const history = useHistory();

  return (
    <TopLevelWrapper>
      <TitleWrapper>
        <Logo src={TopBarLogoPath}/>
        <Title onClick={() => history.push('/main')}>Link Zone</Title>
      </TitleWrapper>
    </TopLevelWrapper>
  );
}

export default TopBar;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 92px;
  
  background-color: #FFFFFF;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  :hover {
    cursor: pointer;
  }
`;

const Logo = styled.img`
  width: 72px;
  height: 72px;
`;

const Title = styled.p`
  font-family: Poppins-SemiBold;
  font-size: 24px;
  color: #FF8B48;

  margin: 0 0 0 16px;
`;
