import { React, useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';

export default function NewClassTypeInput(props) {
  const {lessonType, setLessonType} = props;

  return(
    <TopLevelWrapper>
      <Button isClicked={lessonType === 'Offline'} onClick={() => setLessonType('Offline')}>
        <ButtonText isClicked={lessonType === 'Offline'}>오프라인</ButtonText>
      </Button>
      <Button isClicked={lessonType === 'Online'} onClick={() => setLessonType('Online')}>
        <ButtonText isClicked={lessonType === 'Online'}>온라인</ButtonText>
      </Button>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direcion: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const Button = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: calc(49.34% - 2px);
  height: 30px;

  border: ${props => props.isClicked ? `1px solid #FF8B48` : `1px solid #DFDFDF`};
  border-radius: 8px;

  background-color: ${props => props.isClicked ? `#FF8B48` : `#FFFFFF`};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const ButtonText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: ${props => props.isClicked ? `#FFFFFF` : `#CBCBCB`};

  margin: 0 0 0 0;
  
  transition: all 0.3s ease-in-out;
`;