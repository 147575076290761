import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import UserProfile from '../../Component/Normal/UserProfile';
import ResultSectionBox from './RenewalResultSectionBoxInRankingDetail';

import FirstPlaceImg from '../../image/FirstPlace.png';
import SecondPlaceImg from '../../image/SecondPlace.png';
import ThirdPlaceImg from '../../image/ThirdPlace.png';
import DefaultLogo from '../../image/DefaultProfile.png';

export default function RenewalRankingDetailView(props) {
  const {
    isClickedEdit,
    data,
    editData,
    setEditData,
    userName,
    selectedRank,
    nowClickedIndex,
    setNowClickedIndex
  } = props;

  // 섹션 순서 텍스트 결정 //
  function decideSectionText(data, index) {
    if (data.isScale) return String.fromCharCode(index + 65) + '-' + (data.scaleSectionIndex + 1);
    else return String.fromCharCode(index + 65)
  }

  // 섹션 클릭시 색상 변경 //
  function decideClickedSectionButtonBackgroundColor(data, index) {
    if (data.isScale) return nowClickedIndex === index ? '#4D92EF' : '#CBCBCB';
    else return nowClickedIndex === index ? '#6DD49E' : '#CBCBCB';
  }

  // 성공여부 색상 결정 //
  function decideSuccessBackgroundColor(data) {
    if (data.isScale) return data.success === 'Not Done' ? '#CBCBCB' : '#4D92EF';
    else return data.success === 'Not Done' ? '#CBCBCB' : '#6DD49E';
  }

  // 한자리 숫자 앞에 0 붙이기 //
  function numberPad(n, width) {
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
  }

  // 이름 하단 텍스트 결정 //
  function onViewFinalScoreText(data) {
    if (data.tag === 'ForTime') {
      if (data.success === 'Success') {
        let min = parseInt(data.recordTime / 60);
        let sec = parseInt(data.recordTime % 60);

        return (
          <RowTextWrapper>
            <Text style={{ marginRight: 6 }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{numberPad(min, 2) + ':' + numberPad(sec, 2)}</Text>
            <Text style={{ marginRight: 6 }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#BDBDBD'>Time</Text>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.scoreText}</Text>
          </RowTextWrapper>
        )
      }
      else {
        return (
          <RowTextWrapper>
            <Text style={{ marginRight: 2 }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.completeRound}</Text>
            <Text style={{ marginRight: 6 }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#BDBDBD'>R</Text>
            <Text style={{ marginRight: 2 }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.lastRound}</Text>
            <Text style={{ marginRight: 6 }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#BDBDBD'>Reps</Text>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.scoreText}</Text>
          </RowTextWrapper>
        )
      }
    }
    else if (data.tag === 'AMRAP') {
      return (
        <RowTextWrapper>
          <Text style={{ marginRight: 2 }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.completeRound}</Text>
          <Text style={{ marginRight: 6 }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#BDBDBD'>R</Text>
          <Text style={{ marginRight: 2 }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.lastRound}</Text>
          <Text style={{ marginRight: 6 }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#BDBDBD'>Reps</Text>
          <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.scoreText}</Text>
        </RowTextWrapper>
      )
    }
    else if (data.tag === 'SINGLE') {
      return (
        <RowTextWrapper>
          <Text ellipsizeMode='tail' numberOfLines={1} fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.scoreText}</Text>
        </RowTextWrapper>
      )
    }
    else if (data.tag === 'EMOM' || data.tag === 'TABATA') {
      return (
        <RowTextWrapper >
          <Text ellipsizeMode='tail' numberOfLines={1} fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.scoreText}</Text>
        </RowTextWrapper>
      )
    }
    else if (data.tag === 'Custom') {
      if (data.scoreKeyword === 'Not Scored') {
        return (
          <RowTextWrapper>
            <NotScoredBox isSuccess={data.success === 'Success'}>
              <Text ellipsizeMode='tail' numberOfLines={1} fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF'>{data.success === 'Success' ? '운동 완료' : '운동 미수행'}</Text>
            </NotScoredBox>
          </RowTextWrapper>
        )
      }
      else if (data.scoreKeyword === 'Time') {
        let min = parseInt(data.recordTime / 60);
        let sec = parseInt(data.recordTime % 60);

        return (
          <RowTextWrapper>
            <Text style={{ marginRight: 6 }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{numberPad(min, 2) + ':' + numberPad(sec, 2)}</Text>
            <Text style={{ marginRight: 6 }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#BDBDBD'>Time</Text>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.scoreText}</Text>
          </RowTextWrapper>
        )
      }
      else if (data.scoreKeyword === 'Rounds And Reps') {
        return (
          <RowTextWrapper>
            <Text style={{ marginRight: 2 }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.customLastRound}</Text>
            <Text style={{ marginRight: 6 }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#BDBDBD'>R</Text>
            <Text style={{ marginRight: 2 }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.customLastReps}</Text>
            <Text style={{ marginRight: 6 }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#BDBDBD'>Reps</Text>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.scoreText}</Text>
          </RowTextWrapper>
        )
      }
      else if (data.scoreKeyword === 'Reps'
        || data.scoreKeyword === 'Distance'
        || data.scoreKeyword === 'Weight'
        || data.scoreKeyword === 'Points'
        || data.scoreKeyword === 'Peak Watts'
        || data.scoreKeyword === 'Max Speed'
      ) {
        return (
          <RowTextWrapper>
            <Text style={{ marginRight: 6 }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.customKeywordValue}</Text>
            <Text style={{ marginRight: 6 }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#BDBDBD'>{data.customKeywordUnit}</Text>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.scoreText}</Text>
          </RowTextWrapper>
        )
      }
    }
  }


  return (
    <TopLevelWrapper>
        <TotalWrapper>
          <WorkoutTitleBox>
            <TitleWrapper>
              <Text ellipsizeMode='tail' numberOfLines={1} fontFamily='NotoSansKR-Bold' fontSize={14} color='#333333'>{data.title}</Text>
            </TitleWrapper>
            <Text ellipsizeMode='tail' numberOfLines={1} fontFamily='NotoSansKR-Regular' fontSize={14} color='#BDBDBD'>{moment(data.classDate).format('YYYY.MM.DD(ddd)')}</Text>
          </WorkoutTitleBox>
          <DivisionLine />
          <SectionLineWrapper>
            {
              data.completeSectionList.map((data, index) => (
                <SectionNumberBoxWrapper key={index}>
                  <SectionNumberBox
                    onClick={() => setNowClickedIndex(index)}
                    backgroundColor={decideClickedSectionButtonBackgroundColor(data, index)}
                    isScale={data.isScale}>
                    <Text fontFamily={data.isScale ? `Poppins-Bold` : `NotoSansKR-Bold`} fontSize={14} color='#FFFFFF'>{decideSectionText(data, index)}</Text>
                  </SectionNumberBox>
                  <SectionDoneBox
                    isScale={data.isScale}
                    backgroundColor={decideSuccessBackgroundColor(data)}
                    isSuccess={data.success === 'Success' || data.success === 'Not Success'}>
                    <Text fontFamily='Poppins-Bold' fontSize={8} color='#FFFFFF'>Done</Text>
                  </SectionDoneBox>
                </SectionNumberBoxWrapper>
              ))
            }
          </SectionLineWrapper>
          <DivisionLine />
          <RankingViewWrapper>
            {
              selectedRank !== undefined &&
              <RankingView>
                {
                  (selectedRank.rank <= 3 && selectedRank.rank > 0) ?
                    <RankingLeaderBoxWrapper>
                      <DefaultLogoView src={selectedRank.rank === 1 ? FirstPlaceImg : selectedRank.rank === 2 ? SecondPlaceImg : ThirdPlaceImg} />
                      <RankingImageTextWrapper >
                        <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF'>{selectedRank.rank}</Text>
                      </RankingImageTextWrapper>
                    </RankingLeaderBoxWrapper>
                    :
                    <RankingNumberBox size={40} round={12}>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF'>{selectedRank.rank}</Text>
                    </RankingNumberBox>
                }
                <InfoBox>
                  <InfoInnerWrapper>
                    <ProfileTextWrapper>
                      {
                        selectedRank.userProfile !== '' ?
                          <ProfileWrapper>
                            <UserProfile profile={selectedRank.userProfile} defaultProfile='https://picture.link-zone.org/NoneImage.png' />
                          </ProfileWrapper>
                          :
                          <NoneProfileWrapper>
                            <DefaultLogoView src={DefaultLogo} />
                          </NoneProfileWrapper>
                      }
                      <TextWrapper>
                        <RowTextWrapper style={{ marginBottom: 4 }}>
                          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>{selectedRank.userName}</Text>
                          {
                            (selectedRank.derivationSectionId !== undefined) &&
                            <ScaleBox>
                              <Text fontFamily='Poppins-Medium' fontSize={12} color='#FFFFFF'>{String.fromCharCode(nowClickedIndex + 65) + '-' + (selectedRank.scaleSectionIndex + 1)}</Text>
                            </ScaleBox>
                          }
                        </RowTextWrapper>
                        <ScoreTextWrapper>
                        {
                          onViewFinalScoreText(selectedRank)
                        }
                        </ScoreTextWrapper>
                      </TextWrapper>
                    </ProfileTextWrapper>
                  </InfoInnerWrapper>
                </InfoBox>
              </RankingView>
            }
          </RankingViewWrapper>
          {
            data.completeSectionList.map((data, index) => (
              nowClickedIndex === index &&
              <ResultSectionBox
                key={index}
                sectionData={data}
                editData={editData}
                setEditData={setEditData}
                index={index}
                userName={userName}
                isClickedEdit={isClickedEdit}/>
            ))
          }
        </TotalWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  background-color: #F9F9F9;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const WorkoutTitleBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 90.33%;

  padding: 18px 0px 18px 0px;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 60%;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.hover};
  }
`;

const DivisionLine = styled.div`
  width: 90.33%;
  height: 1px;

  background-color: #E0E0E0;
`;

const SectionLineWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  justify-content: flex-start;
  align-items: flex-start;

  width: 90.33%;
`;

const SectionNumberBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 8px;
  margin-bottom: 8px;
  margin-right: 8px;

  :hover {
    cursor: pointer;
  }
`;

const SectionNumberBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isScale ? `43px` : `32px`};
  height: 32px;

  border-radius: 5px;
  background-color: ${props => props.backgroundColor};
`;

const SectionDoneBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 4px;

  width: ${props => props.isScale ? `43px` : `32px`};
  height: 16px;

  border-radius: 5px;
  background-color: ${props => props.backgroundColor};
`;


const RankingViewWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 4px;

  width: 90.33%;
  // background-color: red;
`;

const RankingView = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  margin-top: 12px;

  width: 100%;
`;

const RankingNumberBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.size}px;
  height: ${props => props.size}px;

  border-radius: ${props => props.round}px;
  background-color: #E0E0E0;
`;

const RankingLeaderBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const RankingImageTextWrapper = styled.div`
  // position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // margin-top: -16px;
  margin-top: -37px;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 87.16%;
  width: 86%;
  height: 71px;

  border-radius: 12px;
  background-color: #FFFFFF;
`;

const InfoInnerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 94%;
`;

const ProfileTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const ScoreTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 280px;
  height: 20px;

  overflow: hidden;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-left: 8px;

  width: 74%;
`;

const RowTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 100%;
`;

const NotScoredBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  padding: 0px 10px;

  height: 21px;

  border-radius: 6px;
  background-color: ${props => props.isSuccess ? '#6DD49E' : '#D92828'};
`;

const ScaleBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-left: 4px;
  padding: 0px 10px;

  height: 16px;

  border-radius: 5px;
  background-color: #4D92EF;
`;

const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;

  border-radius: 40px;

  overflow: hidden;
`;

const NoneProfileWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;

  border-radius: 40px;

  overflow: hidden;
`;

const DefaultLogoView = styled.img`
  width: 40px;
  height: 40px;
`;