import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import MemberShipAndClassView from './MemberShipAndClassView.js';
import SystemSettingView from './SystemSettingView.js';
import CoachSettingView from './CoachSettingView.js';
import NewMemberShipAndClassView from './NewMemberShipAndClassView.js';
import ClassView from './ClassView.js';
import ProductSettingView from './ProductSettingView.js';
function SettingView() {
  // const [nowClicked, setNowClicked] = useState('MemberShip');
  const [nowClicked, setNowClicked] = useState('Class');
  const [topLevelScrollY, setTopLevelScrollY] = useState(0);
  const [isClickAddProduct, setIsClickAddProduct] = useState(false);

  const TopLevelRef = useRef();
  const MemberShipAndClassRef = useRef();
  const SystemRef = useRef();
  const CoachRef = useRef();

  function AddCoachButtonClick() {
    CoachRef.current.setIsClicked(true);
  }

  function saveSystemButtonClick() {
    SystemRef.current.save();
  }

  function onClickAddProductButton() {
    setIsClickAddProduct(true);
  }

  return (
    <TopLevelWrapper ref={TopLevelRef}>
      <TopBarWrapper>
         <TextWrapper>
           <Title>설정</Title>
           <Explanation>서비스의 원활한 이용을 위한 센터별 맞춤 설정을 할 수 있어요</Explanation>
         </TextWrapper>
      </TopBarWrapper>
      <TotalButtonWrapper>
        <ButtonWrapper>
          {/* <MemberShipButton nowClicked={nowClicked} onClick={() => setNowClicked('MemberShip')}>회원권, 수업</MemberShipButton> */}
          <ClassButton nowClicked={nowClicked} onClick={() => setNowClicked('Class')}>수업</ClassButton>
          <MemberShipButton nowClicked={nowClicked} onClick={() => setNowClicked('MemberShip')}>회원권</MemberShipButton>
          <SystemButton nowClicked={nowClicked} onClick={() => setNowClicked('System')}>시스템</SystemButton>
          <CoachButton nowClicked={nowClicked} onClick={() => setNowClicked('Coach')}>코치</CoachButton>
          <ProductButton nowClicked={nowClicked} onClick={() => setNowClicked('Product')}>상품</ProductButton>
        </ButtonWrapper>
        <SaveButton view={nowClicked === 'System'} onClick={saveSystemButtonClick}>저장하기</SaveButton>
        <CoachButtonWrapper view={nowClicked === 'Coach'}>
          <CoachControlButton onClick={AddCoachButtonClick}>코치 추가</CoachControlButton>
        </CoachButtonWrapper>
        <CoachButtonWrapper view={nowClicked === 'Product'}>
          <CoachControlButton onClick={onClickAddProductButton}>물품 추가</CoachControlButton>
        </CoachButtonWrapper>
      </TotalButtonWrapper>
      {/* { nowClicked === 'MemberShip' && <MemberShipAndClassView ref={MemberShipAndClassRef}/> } */}
      { nowClicked === 'Class' && <ClassView/>}
      { nowClicked === 'MemberShip' && <NewMemberShipAndClassView/>}
      {/* { nowClicked === 'MemberShip' && <ClassView/>} */}
      { nowClicked === 'System' && <SystemSettingView ref={SystemRef} onClick={saveSystemButtonClick}/> }
      { nowClicked === 'Coach' && <CoachSettingView ref={CoachRef}/> }
      { nowClicked === 'Product' && <ProductSettingView isClickAddProduct={isClickAddProduct} setIsClickAddProduct={setIsClickAddProduct}/> }
    </TopLevelWrapper>
  )
}

export default SettingView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;
 
  // width: 100%;
  
  // margin-left: 34px;
  margin-left: 42px;

  transition: all 0.3s ease-in-out;
`;

const TopBarWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-left: 28px;

  // gap: 540px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: 0 0 0 0;
`;

const Explanation = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #777777;

  margin: 0 0 0 0;
`;

const TotalButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  gap: 8px;

  margin: 28px 0 16px 0;
`;

const MemberShipButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 67px;
  // width: 98px;
  height: 38px;

  border: ${props => props.nowClicked === 'MemberShip' ? `1px solid #FF8B48` : `1px solid #DFDFDF`};
  border-radius: 15px;
  background-color: ${props => props.nowClicked === 'MemberShip' ? `#FF8B48` : `#FFFFFF`};

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: ${props => props.nowClicked === 'MemberShip' ? `#FFFFFF` : `#CBCBCB`};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #FF8B48;
    font-family: NotoSansKR-Medium;
    color: #FFFFFF;
  }
`;

const ClassButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 67px;
  height: 38px;

  border: ${props => props.nowClicked === 'Class' ? `1px solid #FF8B48` : `1px solid #DFDFDF`};
  border-radius: 15px;
  background-color: ${props => props.nowClicked === 'Class' ? `#FF8B48` : `#FFFFFF`};

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: ${props => props.nowClicked === 'Class' ? `#FFFFFF` : `#CBCBCB`};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #FF8B48;
    font-family: NotoSansKR-Medium;
    color: #FFFFFF;
  }
`;

const SystemButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 65px;
  height: 38px;

  border: ${props => props.nowClicked === 'System' ? `1px solid #FF8B48` : `1px solid #DFDFDF`};
  border-radius: 15px;
  background-color: ${props => props.nowClicked === 'System' ? `#FF8B48` : `#FFFFFF`};

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: ${props => props.nowClicked === 'System' ? `#FFFFFF` : `#CBCBCB`};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #FF8B48;
    font-family: NotoSansKR-Medium;
    color: #FFFFFF;
  }
`;

const CoachButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 52px;
  height: 38px;

  border: ${props => props.nowClicked === 'Coach' ? `1px solid #FF8B48` : `1px solid #DFDFDF`};
  border-radius: 15px;
  background-color: ${props => props.nowClicked === 'Coach' ? `#FF8B48` : `#FFFFFF`};

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: ${props => props.nowClicked === 'Coach' ? `#FFFFFF` : `#CBCBCB`};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #FF8B48;
    font-family: NotoSansKR-Medium;
    color: #FFFFFF;
  }
`;

const ProductButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 52px;
  height: 38px;

  border: ${props => props.nowClicked === 'Product' ? `1px solid #FF8B48` : `1px solid #DFDFDF`};
  border-radius: 15px;
  background-color: ${props => props.nowClicked === 'Product' ? `#FF8B48` : `#FFFFFF`};

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: ${props => props.nowClicked === 'Product' ? `#FFFFFF` : `#CBCBCB`};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #FF8B48;
    font-family: NotoSansKR-Medium;
    color: #FFFFFF;
  }
`;

const SaveButton = styled.div`
  display: ${props => props.view ? `flex` : `none`};
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 15px;
  background-color: #5BD98A;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }
`;

const CoachButtonWrapper = styled.div`
  display: ${props => props.view ? `flex` : `none`};
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 16px;

  transition: all 0.3s ease-in-out;
`;

const CoachControlButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // width: 98px;
  width: 100px;
  // height: 38px;
  height: 40px;

  // border: 1px solid #DFDFDF;
  border-radius: 15px;
  // background-color: #FFFFFF;
  background-color: #5BD98A;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  // color: #CBCBCB;
  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
    // border: 1px solid #777777;
    // color: #777777;
  }
`;