import { React, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import WhiteTrashIcon from '../../../image/WhiteTrashIcon.png';
import UserProfile from '../../../Component/Normal/UserProfile';

const CoachSettingInfoBox = (props) => {
  const { data, index, modifyClickFn, deleteClickFn } = props;

  const [userId, setUserId] = useState();

  const ModifyButtonRef = useRef();
  const DeleteButtonRef = useRef();

  // useEffect(() => {
  //   var userIdList = data.linkCoachId.split('@');

  //   if (userIdList[0].includes('kakao.')) setUserId(userIdList[0].replaceAll('kakao.', '') + '@' + userIdList[1]);
  //   else if (userIdList[0].includes('naver.')) setUserId(data.linkCoachId = userIdList[0].replaceAll('naver.', '') + '@' + userIdList[1]);
  //   else if (userIdList[0].includes('google.')) setUserId(data.linkCoachId = userIdList[0].replaceAll('google.', '') + '@' + userIdList[1]);
  //   else if (userIdList[0].includes('apple.')) setUserId(data.linkCoachId = userIdList[0].replaceAll('apple.', '') + '@' + userIdList[1]);
  //   else setUserId(data.linkCoachId);
  // }, [])
  useEffect(() => {
    let userIdList = data.linkCoachId.split('@');

    if (userIdList[0].includes('kakao.')) setUserId(userIdList[0].replaceAll('kakao.', '') + '@' + userIdList[1]);
    else if (userIdList[0].includes('naver.')) setUserId(userIdList[0].replaceAll('naver.', '') + '@' + userIdList[1]);
    else if (userIdList[0].includes('google.')) setUserId(userIdList[0].replaceAll('google.', '') + '@' + userIdList[1]);
    else if (userIdList[0].includes('apple.')) setUserId(userIdList[0].replaceAll('apple.', '') + '@' + userIdList[1]);
    else setUserId(data.linkCoachId);
  }, [])

  function onMouseOver() {
    DeleteButtonRef.current.style.backgroundColor = '#FF3131';
    // ModifyButtonRef.current.style.fontFamily = `NotoSansKR-Medium`;
    // ModifyButtonRef.current.style.color = `#FF8B48`;
    // DeleteButtonRef.current.style.fontFamily = `NotoSansKR-Medium`;
    // DeleteButtonRef.current.style.color = `#FF8B48`;
  }

  function onMouseLeave() {
    DeleteButtonRef.current.style.backgroundColor = '#CBCBCB';
    // ModifyButtonRef.current.style.fontFamily = `NotoSansKR-Regular`;
    // ModifyButtonRef.current.style.color = `#CBCBCB`;
    // DeleteButtonRef.current.style.fontFamily = `NotoSansKR-Regular`;
    // DeleteButtonRef.current.style.color = `#CBCBCB`;
  }

  function onClickInfoBox(e) {
    if (DeleteButtonRef.current.contains(e.target)) deleteClickFn(index);
    else modifyClickFn(index);
  }
  
  return (
    <TopLevelWrapper>
      <ImageWrapper>
        {/* <ImageView src={data.profile === '' ? 'https://picture.link-zone.org/ZoneLogo.png' : data.profile}/> */}
        <UserProfile profile={data.profile} defaultProfile='https://picture.link-zone.org/ZoneLogo.png'/>
      </ImageWrapper>
      <InfoBox onMouseOver={onMouseOver} onMouseLeave={onMouseLeave} onClick={onClickInfoBox}>
        <DeleteBoxWrapper>
          <DeleteBox ref={DeleteButtonRef}>
            <DeleteImage src={WhiteTrashIcon}/>
          </DeleteBox>
        </DeleteBoxWrapper>
        <InnerInfoBox>
          <TopColumnWrapper>
            <TitleWrapper>
              <NameWrapper>
                <Name>{data.name}</Name>
              </NameWrapper>
              <Title>코치</Title>
            </TitleWrapper>
            <ButtonWrapper>
              {/* <Button ref={ModifyButtonRef} onClick={() => modifyClickFn(index)}>수정</Button>
            <Button ref={DeleteButtonRef} onClick={() => deleteClickFn(index)}>삭제</Button> */}
            </ButtonWrapper>
          </TopColumnWrapper>
          <TotalInfoWrapper>
            <InfoWrapper>
              <Column>성별</Column>
              {/* <Column>이메일</Column> */}
              <Column>전화번호</Column>
              <Column>링크코치 아이디</Column>
            </InfoWrapper>
            <InfoWrapper>
              <Info>{data.gender === 'Male' ? '남성' : '여성'}</Info>
              {/* <Info>{data.email}</Info> */}
              <Info>{data.phone.slice(0, 3) + '-' + data.phone.slice(3, 7) + '-' + data.phone.slice(7, 11)}</Info>
              <Info>{userId}</Info>
            </InfoWrapper>
          </TotalInfoWrapper>
          <AuthBoxWrapper>
            {
              data.isMemberAuth &&
              <AuthBox backgroundColor='#FF8B48'>
                <Text style={{ marginTop: '-2px'}} fontFamily='NotoSansKR-Medium' fontSize={12} color='#FFFFFF'>회원 관리</Text>
              </AuthBox>
            }
            {
              data.isExerciseAuth &&
              <AuthBox backgroundColor='#6DD49E'>
                <Text style={{ marginTop: '-2px'}} fontFamily='NotoSansKR-Medium' fontSize={12} color='#FFFFFF'>운동 관리</Text>
              </AuthBox>
            }
            {
              data.isSalesAuth &&
              <AuthBox backgroundColor='#FFB800'>
                <Text style={{ marginTop: '-2px'}} fontFamily='NotoSansKR-Medium' fontSize={12} color='#FFFFFF'>매출 관리</Text>
              </AuthBox>
            }
          </AuthBoxWrapper>
        </InnerInfoBox>
      </InfoBox>
    </TopLevelWrapper>
  )
}

export default CoachSettingInfoBox;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 16px;
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 199px;
  height: 199px;
  
  overflow: hidden;
  
  border-radius: 24px;
  // filter: drop-shadow(0px 40px 50px rgba(240, 232, 227, 0.5));
`;

const ImageView = styled.img`
  width: 199px;
  height: 199px;
  
  border-radius: 24px;

  object-fit: cover;
  // object-fit: contain;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 278px;
  height: 197px;

  border: 1px solid #FFFFFF;
  border-radius: 15px;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  background-color: #FFFFFF;

  // overflow: hidden;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #FF8B48;
  }
`;

const DeleteBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-end;

  margin-top: -1px;

  width: calc(100% + 1px);
`;

const DeleteBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 38px;
  height: 30px;

  border-radius: 0px 15px 0px 15px;
  background-color: #CBCBCB;

  transition: all 0.3s ease-in-out;
`;

const DeleteImage = styled.img`
  width: 20px;
  height: 20px;
`;

const InnerInfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 80%;
`;

const TopColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  // width: 80%;
  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
`;

const NameWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 188px;
  height: 23px;

  overflow: hidden;
`;

const Name = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #000000;
  
  margin: 0 0 0 0;
`;

const Title = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 16px;

  color: #777777;
  
  margin: 0 0 0 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const Button = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;

  margin: 0 0 0 0;
  
  transition: all 0.3s ease-in-out;
`;

const TotalInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: 110px;
  // height: 76px;

  gap: 8px;
  margin-top: 16px;

  overflow: hidden;
`;

const Column = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;

  margin: 0 0 0 0;
`;

const Info = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #595959;

  margin: 0 0 0 0;

  white-space: nowrap;
`;

const AuthBoxWrapper = styled.div`
  display: flex;
  flex-direcrtion: row;

  // justify-content: space-between;
  justify-content: flex-start;
  align-items: center;

  margin-top: 12px;

  width: 100%;

  gap: 8px;
`;

const AuthBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 69px;
  height: 20px;

  border-radius: 5px;
  background-color: ${props => props.backgroundColor};
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
`;