import { React, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import NoticeList from './NoticeList';
import InfoPagination from '../../../Component/MemberComponent/InfoPagination';

import FetchModule from '../../Share/Network/FetchModule';

function NoticeListView() {
  const history = useHistory();

  const [data, setData] = useState([]);
  const [nowPage, setNowPage] = useState(0);
  const [useData, setUseData] = useState([]);

  useEffect(() => {

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postData('notice/', 'POST');
        // const responseData = await fetchModule.getData('notice/', 'GET');
        // console.log(responseData.data);
        if (responseData.status === 200) setData(responseData.data);
        else if (responseData.status === 401) history.push('/');
        // else if (responseData.status !== 204) {}
      }
    )();
  }, [history])

  useEffect(() => {
    let newDataList = data.slice((nowPage * 10), (nowPage * 10) + 10);
    setUseData(newDataList);
  }, [nowPage, data])

  function addNoticeClick() {
    history.push('/notice/create');
  }
  
  function onClickNextPage() {
    if (nowPage + 1 < Math.ceil(data.length/10)) setNowPage(nowPage + 1);
  }

  function onClickPrevPage() {
    if ((nowPage - 1) >= 0) setNowPage(nowPage - 1);  
  }

  return (
    <TopLevelWrapper>
      <TopBarWrapper>
        <TextWrapper>
          <Title>공지</Title>
          <Explanation>일정, 회원관리 등을 공지하고 확인할 수 있어요</Explanation>
        </TextWrapper>
        <ButtonWrapper>
          <AddButton onClick={addNoticeClick}>공지 추가</AddButton>
        </ButtonWrapper>
      </TopBarWrapper>
      <NoticeList data={useData}/>
      <PaginationWrapper>
        <InfoPagination 
          nowPage={nowPage} 
          totalPage={Math.ceil(data.length/10)}
          nextClickFn={onClickNextPage}
          prevClickFn={onClickPrevPage}/>
      </PaginationWrapper>
    </TopLevelWrapper>
  )
}

export default NoticeListView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  // margin-left: 34px;
  margin-left: 42px;

  transition: all 0.3s ease-in-out;
`;

const TopBarWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 992px;

  // gap: 674px;
  margin-left: 28px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: 0 0 0 0;
`;

const Explanation = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #777777;

  margin: 0 0 0 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 16px;
`;

const AddButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 15px;
  background-color: #5BD98A;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }
`;

const PaginationWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;

  margin-bottom: 96px;
`;