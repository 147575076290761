import { React } from 'react';
import styled from 'styled-components';

import Input from '../../Component/MySettingPageComponent/InfoInput';
import InputView from '../../Component/MySettingPageComponent/InputView';
import PasswordInputView from '../../Component/MySettingPageComponent/PasswordInputView';

import WhitePencelIcon from '../../image/WhitePencel.svg';

export default function MyAccountBox(props) {
  const { 
    id, 
    email, 
    owenrName, 
    setOwnerName, 
    phone, 
    setPhone, 
    isClicked,
    onClickModify
  } = props;

  return (
    <TopLevelWrapper>
      <TitleWrapper>
        <TitleBox backgroundColor='#FF8B48'>
          <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF' hover='default'>계정 정보</Text>
        </TitleBox>
        <ModifyButton isClicked={isClicked} onClick={onClickModify}>
          { !isClicked && <PencelImage src={WhitePencelIcon}/> }
          <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF' hover='pointer'>{isClicked ? `저장하기` : `수정하기`}</Text>
        </ModifyButton>
      </TitleWrapper>
      <InfoView>
        {
          isClicked ?
            <InfoWrapper>
              <LayerWrapper topMargin={0}>
                <Input column='관리자 성함' defaultVal={owenrName} setVal={setOwnerName} width={346}/>
                <InputView isClicked={isClicked} column='아이디' content={id} />
                <PasswordInputView isClicked={isClicked} column='패스워드' content='●●●●●●●●●●' /> 
              </LayerWrapper>
              <LayerWrapper topMargin={28}>
                <InputView isClicked={isClicked} column='이메일 계정' content={email} />
                <Input column='휴대전화 번호' defaultVal={phone} setVal={setPhone} width={346}/>
                <SubscribeWrapper/>
              </LayerWrapper>
            </InfoWrapper>
            :
            <InfoWrapper>
              <LayerWrapper topMargin={0}>
                <InputView column='관리자 성함' content={owenrName}/>
                <InputView column='아이디' content={id} />
                <InputView column='패스워드' content='●●●●●●●●●●' />
              </LayerWrapper>
              <LayerWrapper topMargin={28}>
                <InputView column='이메일 계정' content={email} />
                <InputView column='휴대전화 번호' content={phone} />
                <SubscribeWrapper>
                </SubscribeWrapper>
              </LayerWrapper>
            </InfoWrapper>
        }
      </InfoView>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 36px;

  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const InfoView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 1191px;
  width: 100%;
  height: 192px;

  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  background-color: #FFFFFF;
`;

const TitleBox = styled.p`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 2px 20px;
  margin: 0 0 0 0;

  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  background-color: ${props => props.backgroundColor};
`;

const ModifyButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 82px;
  height: 24px;

  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  
  background-color: ${props => props.isClicked ? `#6DD49E` : `#CBCBCB`};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 1135px;
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;

  margin-top: ${props => props.topMargin}px;
`;

const SubscribeWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-end;

  margin-top: 25px;

  width: 365px;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  margin: 0 0 0 0;

  color: ${props => props.color};

  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;

const PencelImage = styled.img`
  width: 20px;
  height: 20px;

  :hover {
    cursor: pointer;
  }
`;

const SubscribeButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 99px;
  height: 32px;

  border-radius: 8px;
  background-color: #5BD98A;

  font-family: NotoSansKR-Bold;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }
`;