import React from 'react';
import styled from 'styled-components';

export default function RegistrationCompleteModal(props) {
  const { goFn, closeFn } = props;

  return (
    <TopLevelWrapper>
      <Modal>
        <ComponentWrapper>
          <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#232323' hover='default'>회원 등록 완료</Text>
          <Text style={{ marginTop: `16px` }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='default'>회원 등록이 완료되었습니다.</Text>
          <Text style={{ marginTop: `10px` }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='default'>회원 정보 페이지로 이동하시겠습니까?</Text>
          <ButtonWrapper>
            <ExitButton onClick={closeFn}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#CBCBCB' hover='pointer'>닫기</Text>
            </ExitButton>
            <GoButton onClick={goFn}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' hover='pointer'>이동하기</Text>
            </GoButton>
          </ButtonWrapper>
        </ComponentWrapper>
      </Modal>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 1000;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.3);

  transition: all 0.3s ease-in-out;
`;

const Modal = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 371px;
  height: 248px;

  border-radius: 30px;
  background-color: #FFFFFF;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 84.9%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  margin-top: 58px;

  gap: 16px;

  width: 100%;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 72px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;

  :hover {
    cursor: pointer;
  }
`;

const GoButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 15px;
  background-color: #6DD49E;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }

  transition: all 0.3s ease-in-out;
`;


const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.hover};
  }
`;
