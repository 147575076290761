import React from "react";
import styled from "styled-components";

export default function ExerciseTimeInput(props) {
  const { min, setMin, sec, setSec } = props;
  
  
	function handleClick(e) {
	}

	function handleChangeMin(e) {
    let regex = /[^0-9]/g;				// 숫자가 아닌 문자열을 선택하는 정규식
    let result = e.target.value.replace(regex, "");
    
    if (result === '') setMin(0);
		else setMin(result);
	}

	function handleChangeSec(e) {
    let regex = /[^0-9]/g;				// 숫자가 아닌 문자열을 선택하는 정규식
    let result = e.target.value.replace(regex, "");
    
    if (result === '') setSec(0);
		else setSec(result);
	}


	function handleFocus(e) {
	}

	function handleBlur(e) {
	}
  
  return (
    <TotalInputWrapper>
      <InputWrapper >
        <Input
          type='text'
          placeholder={0}
          value={min}
          onChange={handleChangeMin} />
      </InputWrapper>
      <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F'>분</Text>
      <InputWrapper>
        <Input
          type='text'
          placeholder={0}
          value={sec}
          onChange={handleChangeSec}/>
      </InputWrapper>
      <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F'>초</Text>
    </TotalInputWrapper>
  )
}


const TotalInputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  // padding: 0px 10px;

  // width: calc(100% - 22px);
  width: 100%;
  height: 34px;

  gap: 4px;

  // border: 1px solid #CBCBCB;
  // border-radius: 30px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding: 0px 10px;

  width: calc(45% - 22px);
  height: 34px;

  border: 1px solid #CBCBCB;
  border-radius: 30px;

  transition: all 0.3s ease-in-out;
`;

const Input = styled.input` 
	font-family: Poppins-Medium;
	font-size: 14px;
	line-height: 20px;
	
  display: flex;
  flex-direction: row;

  width: 100%;

  border: 0px;
  background-color: #FFFFFF;

  color: #595959;

	outline: none;

	::placeholder {
    font-family: NotoSansKR-Regular;
    font-size: 14px;

		color: #CBCBCB;
	}

  &:disabled {
    color: #FFFFFF;
    background-color: #CBCBCB;
  }

  transition: all 0.3s ease-in-out;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  
  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;