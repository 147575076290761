import React, { useState } from "react";
import styled from "styled-components";

// Image Import //
// import PlusImage from '../../image/PlusButton.png';
import TrashImage from '../../image/TrashIcon.png';
// import AdditionExerciseButtonImage from '../../image/AdditionExerciseButton.png';
import YoutubeButtonImage from '../../image/YoutubeButtonImage.png';
import YoutubeVideoURLInput from "../../Component/NoteComponent/YoutubeVideoURLInput";
import NoteTextArea from "../../Component/NoteComponent/NoteTextArea";
import SingleAddMovementBox from "./SingleAddMovementBox";
import MovementViewBox from "./MovementViewBox";
// import LockingIcon from '../../image/Lock.png';
// import UnLockingIcon from '../../image/UnLock.png';
// Image Import //

export default function TodayWodViewBox(props) {
  const { 
    sectionData, 
    movements,
    recentlySearchHistory,
    setRecentlySearchHistory,
    refreshTotalData 
  } = props;

  // 한자리 숫자 앞에 0 붙이기 //
  function numberPad(n, width) {
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
  }

  // For Time Timecap 계산 //
  function decideTimeCap() {
    let min = parseInt(sectionData.timeLimit / 60);
    let sec = parseInt(sectionData.timeLimit % 60);

    if (min > 0 && sec > 0) return 'Timecap ' + min + 'min ' + sec + 'sec';
    else if (min > 0) return 'Timecap ' + min + 'min';
    else if (sec > 0) return 'Timecap ' + sec + 'sec';
    else return 'Timecap 0sec'; 
  }

  // TABATA ON OFF 계산 //
  function decideTABATAOnOff(division) {
    if (division === 'ON') {
      let min = parseInt(sectionData.exerciseTime / 60);
      let sec = parseInt(sectionData.exerciseTime % 60);
  
      if (min > 0 && sec > 0) return min + ':' + numberPad(sec, 2);
      else if (min > 0) return min + ':00'
      else if (sec > 0) return '0:' + numberPad(sec, 2);
      else return '0:00'; 
    }
    else {
      let min = parseInt(sectionData.restTime / 60);
      let sec = parseInt(sectionData.restTime % 60);
  
      if (min > 0 && sec > 0) return min + ':' + numberPad(sec, 2);
      else if (min > 0) return min + ':00'
      else if (sec > 0) return '0:' + numberPad(sec, 2);
      else return '0:00'; 
    }
  }

  return (
    <TodayWodBoxWrapper>
    {
      sectionData.tag === 'EMOM' &&
      <TABATASectionWrapper>
        <TABATARoundBox>
          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>{sectionData.round + 'R'}</Text>
        </TABATARoundBox>
        <EMOMOnBox backgroundColor='#6DD49E'>
          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF'>{'ON ' + decideTABATAOnOff('ON')}</Text>
        </EMOMOnBox>
      </TABATASectionWrapper>
    }
      {
        sectionData.tag === 'TABATA' &&
        <TABATASectionWrapper>
          <TABATARoundBox>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>{sectionData.round + 'R'}</Text>
          </TABATARoundBox>
          <TABATAOnOffBox backgroundColor='#FF8B48'>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF'>{'ON ' + decideTABATAOnOff('ON')}</Text>
          </TABATAOnOffBox>
          <TABATAOnOffBox backgroundColor='#6DD49E'>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF'>{'REST ' + decideTABATAOnOff('OFF')}</Text>
          </TABATAOnOffBox>
        </TABATASectionWrapper>
      }
      {
        (sectionData.youtubeLink !== undefined && sectionData.youtubeLink !== '') &&
        <VideoViewWrapper>
          <iframe
            width="100%"
            height="100%"
            src={(sectionData.youtubeLink !== undefined && sectionData.youtubeLink !== '') ? `https://www.youtube.com/embed/` + sectionData.youtubeLink.replace('https://www.youtube.com/watch?v=', '').replace('https://youtube.com/shorts/', '').replace('https://youtu.be/', '') : ''}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube" />
        </VideoViewWrapper>
      }
      {
        (sectionData.youtubeLinks !== undefined) &&
        sectionData.youtubeLinks.map((linkData, idx) => (
          <VideoViewWrapper key={'Video-' + idx} style={{ marginTop: '8px' }}>
            <iframe
              width="100%"
              height="100%"
              src={`https://www.youtube.com/embed/` + linkData.replace('https://www.youtube.com/watch?v=', '').replace('https://youtube.com/shorts/', '').replace('https://youtu.be/', '')}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube" />
          </VideoViewWrapper>
        ))
      }
      { (sectionData.youtubeLink !== undefined && sectionData.youtubeLink !== '') && <DivisionLine style={{ margin: '28px 0px 8px 0px'}}/> }
      <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#666666' hover='default'>오늘의 와드</Text>
      {
        sectionData.tag !== 'SINGLE' &&
        <NoteViewBox style={{ margin: '20px 0px' }}>
         <ProgramSectionBox>
           <Text fontFamily='Poppins-Medium' fontSize={14} color='#FFFFFF' hover='default'>Program</Text>
         </ProgramSectionBox>
         <NoteTextAreaWrapper>
           <PreText fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>{sectionData.mainProgram}</PreText>
         </NoteTextAreaWrapper>
         { 
          sectionData.isUseTimeLimit && 
          <TimeLimitBar>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF'>{decideTimeCap()}</Text>
          </TimeLimitBar> 
          }
       </NoteViewBox>
      }
      {
        sectionData.tag === 'SINGLE' &&
        <SingleWodBox>
          <MovementViewBox
            sectionData={sectionData}
            movements={movements}
            recentlySearchHistory={recentlySearchHistory}
            setRecentlySearchHistory={setRecentlySearchHistory}
            refreshTotalData={refreshTotalData}/>
        </SingleWodBox>
      }
      {
        sectionData.mainNote !== '' &&
        <NoteViewBox style={sectionData.tag === 'SINGLE' ? {margin: '20px 0px'} : { marginBottom: '20px' }}>
          <NoteSectionBox>
            <Text fontFamily='Poppins-Medium' fontSize={14} color='#FFFFFF' hover='default'>Notes</Text>
          </NoteSectionBox>
          <NoteTextAreaWrapper>
            <PreText fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>{sectionData.mainNote}</PreText>
          </NoteTextAreaWrapper>
        </NoteViewBox>
      }
    </TodayWodBoxWrapper>
  )
}

const TodayWodBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justifyo-content: flex-start;
  align-items: flex-start;

  margin-top: 20px;

  width: 100%;
`;

const YoutubeWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-end;

  margin-top: 8px;
  // margin-bottom: ${props => (props.isExistAdditionLinks || props.isViewVideo) ? '8px' : '20px'};

  width: 100%;
  height: ${props => props.isViewVideo ? '278px' : '36px'};

  transition: all 0.3s ease-in-out;
`;

const AdditionYoutubeWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-end;

  margin-bottom: 20px;

  width: 97.71%;
  // width: 100%;
  // height: 36px;

  transition: all 0.3s ease-in-out;
`;

const TranslateButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: ${props => props.width};
  height: ${props => props.isViewVideo ? '100%' : 'calc(100% - 2px)'};

  border: ${props => props.isViewVideo ? `none` : `1px solid #FF3131`};
  border-radius: ${props => props.isViewVideo ? `0px` : `12px`};
  background-color: ${props => props.isClicked ? `#FFFFFF` : `#FF3131`};

  :hover {
    cursor: pointer;
  }

  overflow: hidden;

  transition: all 0.3s ease-in-out;
`;

const AdditionTranslateButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: ${props => props.width};
  height: 36px;

  border: 1px solid #FF3131;
  border-radius: 12px;
  background-color: ${props => props.isClicked ? `#FFFFFF` : `#FF3131`};

  :hover {
    cursor: pointer;
  }

  overflow: hidden;

  transition: all 0.3s ease-in-out;
`;

const YoutubeImage = styled.img`
  margin-right: 8px;

  width: 31px;
  height: 22px;
`;

const VideoTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: ${props => props.width};
  height: ${props => props.height};

  overflow: hidden;

  transition: all 0.3s ease-in-out;
`;

const VideoURLWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: ${props => props.width};
  height: ${props => props.height};

  opacity: ${props => props.isViewVideo ? `0` : `1`};
  overflow: hidden;

  transition: all 0.3s ease-in-out;
`;

const AdditionVideoURLWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: ${props => props.width};
  height: ${props => props.height};

  overflow: hidden;

  transition: all 0.3s ease-in-out;
`;

const VideoViewWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: ${props => props.width};
  // height: ${props => props.height};

  width: 100%;
  height: 253px;
  
  border-radius: 10px;

  opacity: 1;
  overflow: hidden;

  transition: all 0.3s ease-in-out;
`;

const VideoDeleteWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 16px 16px 0px 0px;

  width: 32px;
  height: 32px;

  border-radius: 32px;
  background-color: rgba(0, 0, 0, 0.50);

  :hover {
    cursor: pointer;
  }
`;

const TrashIcon = styled.img`
  width: 20px;
  height: 20px;
`;

const PlusWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 2px;

  width: 15px;
  height: 15px;
`;

const Stick = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: ${props => props.radius}px;
  background-color: #FFFFFF;
`;

const ProgramSectionBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 92px;
  height: 23px;

  border-radius: 4px 0px 4px 0px;
  background-color: #969696;
`;

const NoteSectionBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 71px;
  height: 23px;

  border-radius: 4px 0px 4px 0px;
  background-color: #FF8B48;
`;

const SingleWodBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-left: -15px;

  width: calc(100% + 30px);

  border-bottom: 1px solid #E0E0E0;
  background-color: #FFFFFF;
`;

const NoteViewBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  border: 1px solid #E0E0E0;
  border-radius: 4px;
  background-color: #FFFFFF;
`;

const NoteTextAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  margin: 8px 15px 14px 15px;

  width: calc(100% - 30px);
`;

const DivisionLine = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 1px;

  background-color: #E5E5E5;
`;

const TimeLimitBar = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 24px;

  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: #FF3131;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  
  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;

const PreText = styled.pre`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  width: 100%;

  white-space: pre-wrap;
  word-wrap: break-word;
`;

const TABATASectionWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  margin-top: -15px;
  margin-bottom: 10px; 
  width: 100%;

  // margin-top: 5px;
`;

const TABATARoundBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 18%;
  height: 31px;

  border-radius: 6px;
  background-color: #F9F9F9;
`;

const TABATAOnOffBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 40%;
  height: 31px;

  border-radius: 6px;
  background-color: ${props => props.backgroundColor};
`;

const EMOMOnBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 80%;
  height: 31px;

  border-radius: 6px;
  background-color: ${props => props.backgroundColor};
`;
