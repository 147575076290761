import { React, useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import moment from 'moment';

import CalenderImagePath from '../../../image/Calender-gray.png';
import CalenderRightArrowImagePath from '../../../image/CalenderRightArrow.png';
import CalenderLeftArrowImagePath from '../../../image/CalenderLeftArrow.png';
import CalenderLeftArrow2ImagePath from '../../../image/CalenderLeftArrow2.png';
import CalenderRightArrow2ImagePath from '../../../image/CalenderRightArrow2.png';

import CalenderExitButton from '../../../image/CalenderExitButton.png';

export default function CalenderModal(props) {
  const { startDate, setStartDate, endDate, setEndDate, onClickExitButton } = props;

  const [isFirst, setIsFirst] = useState(true);
  const [today, setToday] = useState(moment());
  const [startValue, setStartValue] = useState(startDate);
  const [endValue, setEndValue] = useState(endDate);

  const [startDateList, setStartDateList] = useState([]);
  const [endDateList, setEndDateList] = useState([]);
  const [compriseDateList, setCompriseList] = useState([]);
  const [clickCount, setClickCount] = useState(0);

  const startFirstWeek = today.clone().startOf('month').week();
  const startLastWeek = today.clone().endOf('month').week() === 1 ? 53 : today.clone().endOf('month').week();

  const endFirstWeek = today.clone().add(1, 'month').startOf('month').week();
  const endLastWeek = today.clone().add(1, 'month').endOf('month').week() === 1 ? 53 : today.clone().add(1, 'month').endOf('month').week();

  // 스크롤 제어 //
  useEffect(() => {
    document.body.style = `overflow: hidden`;
    return () => document.body.style = `overflow: auto`;
  }, [])

  // 시작날짜 및 종료날짜 변경시 //
  useEffect(() => {
    setStartValue(startDate);
    setEndValue(endDate);
  }, [startDate, endDate])

  useEffect(() => {
    let newStartDateList = [];
    let newEndDateList = [];

    for (let week = startFirstWeek; week <= startLastWeek; week++) {
      for (let i = 0; i < 7; i++) {
        let days = today.clone().startOf('year').week(week).startOf('week').add(i, 'day');

        newStartDateList.push(days);
      }
    }

    for (let week = endFirstWeek; week <= endLastWeek; week++) {
      for (let i = 0; i < 7; i++) {
        let days = today.clone().add(1, 'month').startOf('year').week(week).startOf('week').add(i, 'day');

        newEndDateList.push(days);
      }
    }

    setStartDateList(newStartDateList);
    setEndDateList(newEndDateList);
  }, [today, startFirstWeek, startLastWeek, endFirstWeek, endLastWeek])

  useEffect(() => {
    if (startValue.format('YYYY-MM-DD') === '2000-01-01') return;

    if (startValue.format('YYYY-MM-DD') !== today.format('YYYY-MM-DD')
      && startValue.format('YYYY-MM-DD') !== '2000-01-01'
      && isFirst) {
      setToday(startValue);
      setIsFirst(false);
      return;
    }

    let newDateList = [];
    let calcDate = moment(startValue.clone().format('YYYY-MM-DDT23:59:59.99999'));

    while (calcDate < endValue.clone().add(1, 'day')) {
      newDateList.push(calcDate.format('YY.MM.DD'));
      calcDate = calcDate.add(1, 'day');
    }

    if (clickCount === 0) setClickCount(clickCount + 1);

    setCompriseList(newDateList);
  }, [startValue, endValue, today, clickCount, isFirst])

  // useEffect(() => {
  //   // holdingDateSelect(compriseDateList);
  //   console.log(compriseDateList);
  // }, [compriseDateList, holdingDateSelect])

  function dateClick(division, index) {
    if (division === 'first') {
      if (clickCount === 0) {
        setStartValue(startDateList[index]);
        setClickCount(clickCount + 1);
      }
      else {
        if (startDateList[index].isAfter(moment())) {
          alert('금일까지만 조회 가능합니다.');
        }
        else if (startDateList[index].format('YYYY.MM.DD') < startValue.format('YYYY.MM.DD')) {
          setEndValue(startValue);
          setStartValue(startDateList[index]);
        }
        else {
          setEndValue(startDateList[index]);
        }
      }
      // else if (startDateList[index].format('YYYY.MM.DD') < startValue.format('YYYY.MM.DD')) {
      //   setEndValue(startValue);
      //   setStartValue(startDateList[index]);
      // }
      // else {
      //   setEndValue(startDateList[index]);
      // }
    }
    else {
      if (clickCount === 0) {
        setStartValue(endDateList[index]);
        setClickCount(clickCount + 1);
      }
      else {
        if (endDateList[index].isAfter(moment())) {
          alert('금일까지의 데이터만 조회 가능합니다.');
        }
        else if (endDateList[index].format('YYYY.MM.DD') < startValue.format('YYYY.MM.DD')) {
          setEndValue(startValue);
          setStartValue(endDateList[index]);
        }
        else {
          setEndValue(endDateList[index]);
        }
      }
      // else if (endDateList[index].format('YYYY.MM.DD') < startValue.format('YYYY.MM.DD')) {
      //   setEndValue(startValue);
      //   setStartValue(endDateList[index]);
      // }
      // else {
      //   setEndValue(endDateList[index]);
      // }
    }
  }

  function onClickCalenderExit(division) {
    if (division === 'start') {
      setStartValue(moment('2000-01-01'));
      setEndValue(moment('2000-01-01'));
      setCompriseList([]);
      setClickCount(0);
    }
    else {
      setEndValue(moment('2000-01-01'));
    }
  }

  // 적용하기 버튼 클릭시 //
  function onClickSave() {
    setStartDate(moment(startValue.format('YYYY-MM-DDT00:00:00')));
    setEndDate(moment(endValue.format('YYYY-MM-DDT23:59:59.99999')));
    onClickExitButton();
  }

  return (
    <Background>
      <Modal>
        <InnerWrapper>
          <TitleWrapper>
            <CustomText fontFamily='NotoSansKR-Bold' fontSize={16} color='#000000' cursor='pointer'>통계값 기한 설정</CustomText>
          </TitleWrapper>
          <InfoWrapper>
            <CalenderWrapper>
              <InputWrapper>
                <Text>통계 시작일</Text>
                <Input img={CalenderImagePath}>
                  {startValue.format('YYYY.MM.DD')}
                  {startValue.format('YYYY.MM.DD') !== '2000.01.01' && <CalenderExit onClick={() => onClickCalenderExit('start')} src={CalenderExitButton} />}
                </Input>
              </InputWrapper>
              <CalenderView>
                <CalenderTitleWrapper>
                  <Arrow img={CalenderLeftArrow2ImagePath} onClick={() => setToday(today.clone().subtract(1, 'year'))} style={{ margin: `0 -7px 0 0` }} />
                  <Arrow img={CalenderLeftArrowImagePath} onClick={() => setToday(today.clone().subtract(1, 'month'))} />
                  <Title>{today.format('YYYY년 MM월')}</Title>
                  <Arrow img={CalenderRightArrowImagePath} onClick={() => setToday(today.clone().add(1, 'month'))} />
                  <Arrow img={CalenderRightArrow2ImagePath} onClick={() => setToday(today.clone().add(1, 'year'))} style={{ margin: `0 0 0 -7px` }} />
                </CalenderTitleWrapper>
                <DateColumnWrapper>
                  <DateColumn style={{ width: `25px`, marginRight: `36px` }}>SUN</DateColumn>
                  <DateColumn style={{ width: `30px`, marginRight: `34px` }}>MON</DateColumn>
                  <DateColumn style={{ width: `23px`, marginRight: `36px` }}>TUE</DateColumn>
                  <DateColumn style={{ width: `28px`, marginRight: `35px` }}>WED</DateColumn>
                  <DateColumn style={{ width: `25px`, marginRight: `42px` }}>THU</DateColumn>
                  <DateColumn style={{ width: `18px`, marginRight: `40px` }}>FRI</DateColumn>
                  <DateColumn style={{ width: `24px`, marginRight: `28px` }}>SAT</DateColumn>
                </DateColumnWrapper>
                <TotalDateWrapper>
                  {
                    startDateList.map((data, index) => (
                      data.format('MM') === today.format('MM') ?
                        <DateWrapper key={index} isRight={startValue.format('YY.MM.DD') === data.format('YY.MM.DD')} isLeft={endValue.format('YY.MM.DD') === data.format('YY.MM.DD')} isComprise={compriseDateList.includes(data.format('YY.MM.DD'))}>
                          <Date isClicked={data.format('YY.MM.DD') === startValue.format('YY.MM.DD') || data.format('YY.MM.DD') === endValue.format('YY.MM.DD')} isComprise={compriseDateList.includes(data.format('YY.MM.DD'))} onClick={() => dateClick('first', index)}>
                            {data.format('D')}
                          </Date>
                        </DateWrapper>
                        :
                        <WithoutHoverDate key={index}>
                          {data.format('D')}
                        </WithoutHoverDate>
                    ))
                  }
                </TotalDateWrapper>
              </CalenderView>
            </CalenderWrapper>
            <CalenderWrapper>
              <InputWrapper>
                <Text>통계 종료일</Text>
                <Input img={CalenderImagePath}>
                  {endValue.format('YYYY.MM.DD')}
                  {endValue.format('YYYY.MM.DD') !== '2000.01.01' && <CalenderExit onClick={() => onClickCalenderExit('end')} src={CalenderExitButton} />}
                </Input>
              </InputWrapper>
              <CalenderView>
                <CalenderTitleWrapper>
                  <Arrow img={CalenderLeftArrow2ImagePath} onClick={() => setToday(today.clone().subtract(1, 'year'))} style={{ margin: `0 -7px 0 0` }} />
                  <Arrow img={CalenderLeftArrowImagePath} onClick={() => setToday(today.clone().subtract(1, 'month'))} />
                  <Title>{today.clone().add(1, 'month').format('YYYY년 MM월')}</Title>
                  <Arrow img={CalenderRightArrowImagePath} onClick={() => setToday(today.clone().add(1, 'month'))} />
                  <Arrow img={CalenderRightArrow2ImagePath} onClick={() => setToday(today.clone().add(1, 'year'))} style={{ margin: `0 0 0 -7px` }} />
                </CalenderTitleWrapper>
                <DateColumnWrapper>
                  <DateColumn style={{ width: `25px`, marginRight: `36px` }}>SUN</DateColumn>
                  <DateColumn style={{ width: `30px`, marginRight: `34px` }}>MON</DateColumn>
                  <DateColumn style={{ width: `23px`, marginRight: `36px` }}>TUE</DateColumn>
                  <DateColumn style={{ width: `28px`, marginRight: `35px` }}>WED</DateColumn>
                  <DateColumn style={{ width: `25px`, marginRight: `42px` }}>THU</DateColumn>
                  <DateColumn style={{ width: `18px`, marginRight: `40px` }}>FRI</DateColumn>
                  <DateColumn style={{ width: `24px`, marginRight: `28px` }}>SAT</DateColumn>
                </DateColumnWrapper>
                <TotalDateWrapper>
                  {
                    endDateList.map((data, index) => (
                      data.format('MM') === today.clone().add(1, 'month').format('MM') ?
                        <DateWrapper key={index} isRight={startValue.format('YY.MM.DD') === data.format('YY.MM.DD')} isLeft={endValue.format('YY.MM.DD') === data.format('YY.MM.DD')} isComprise={compriseDateList.includes(data.format('YY.MM.DD'))}>
                          <Date isClicked={startValue.format('YY.MM.DD') === data.format('YY.MM.DD') || endValue.format('YY.MM.DD') === data.format('YY.MM.DD')} isComprise={compriseDateList.includes(data.format('YY.MM.DD'))} onClick={() => dateClick('second', index)}>
                            {data.format('D')}
                          </Date>
                        </DateWrapper>
                        :
                        <WithoutHoverDate key={index}>
                          {data.format('D')}
                        </WithoutHoverDate>
                    ))
                  }
                </TotalDateWrapper>
              </CalenderView>
            </CalenderWrapper>
          </InfoWrapper>
          <ButtonWrapper>
            <ExitButton onClick={onClickExitButton}>취소</ExitButton>
            <SaveButton onClick={onClickSave}>
              <CustomText fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='pointer'>적용하기</CustomText>
            </SaveButton>
          </ButtonWrapper>
        </InnerWrapper>
      </Modal>
    </Background>
  )
}

const startAnimation = keyframes`
	0% { opacity: 0; }
	50% { opacity: 0.5; }
	100% { opacity: 1; }
`;

const Background = styled.div`
  position: fixed;
	display: flex;
	flex-direction: column;

  justify-content: center;
  align-items: center;

  top: 0;
  left: 0;

  z-index: 500;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.5);
	
	animation-name: ${startAnimation};
	animation-duration: 0.5s;
	animation-timing-function: ease-out;
`;

const Modal = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 1002px;
  // height: 639px;
  height: 670px;

  border-radius: 30px;
  background-color: #FFFFFF;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 94%;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: flex-start;

  margin-top: 14px;

  gap: 32px;

  height: 514px;
`;

const CalenderWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 16px;

  margin-bottom: 16px;
`;

const Text = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;

  margin: 0 0 0 0;
`;

const Input = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  // width: 280px;
  width: 179px;
  height: 30px;

  margin-top: 8px;
  padding-left: 40px;
  padding-right: 6px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;

  background: url(${props => props.img}) no-repeat 10px center;
  background-color: ${props => props.disabled ? '#ECECEC' : '#FFFFFF'};

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;

  transition: all 0.2s ease-in-out;
`;

const CalenderView = styled.div`
  // position: absolute;

  display: flex;
  flex-direction: column;

  // border: 1px solid #DFDFDF;
  border-radius: 8px;
  background-color: #FFFFFF;

  // z-index: -30;
  
  transition: all 0.3s ease-in-out;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
`;

const CalenderTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  margin: 28px 0 0 22px;
`;

const Arrow = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  width: 24px;
  height: 24px;
  
  background: url(${props => props.img}) no-repeat center center;

  :hover {
    cursor: pointer;
  }
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const DateColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;

  // justify-content: center;
  // align-items: center;

  // gap: 38px;
  margin-top: 29px;
  margin-left: 28px;
  margin-bottom: 23px;
`;

const DateColumn = styled.p`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  font-family: Poppins-bold;
  font-size: 12px;

  color: #FF8B48;
  
  margin: 0 0 0 0;
`;

const TotalDateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  width: 448px;
  margin-left: 8px;
`;

const DateWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 48px;
  height: 48px;

  // margin: 0 0 8px 0;
  padding: 0 7px 0 7px;

  margin: ${props => props.isRight ? `0 0 8px 7px` : props.isLeft ? `0 7px 8px 0` : `0 0 8px 0`};
  padding: ${props => props.isRight ? `0 7px 0 0` : props.isLeft ? `0 0 0 7px` : `0 7px 0 7px`};

  border-radius: ${props => props.isRight ? `48px 0 0 48px` : props.isLeft ? `0 48px 48px 0` : `0 0 0 0`};
  background-color: ${props => props.isComprise ? `#ECECEC` : `#FFFFFF`};
`;

const Date = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  width: 48px;
  height: 48px;

  border-radius: 48px;

  // margin: 0 7px 5px 7px;
  // margin: 0 0 5px 0;

  // background-color: #FFFFFF;
  background-color: ${props => props.isClicked ? `#FF7322` : props.isComprise ? `#ECECEC` : `#FFFFFF`};
  
  font-family: Poppins-Medium;
  font-size: 14px;

  // color: #595959;
  color: ${props => props.isClicked ? `#FFFFFF` : `#595959`};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #FF7322;
    color: #FFFFFF;

    font-fmaily: Poppins-Bold;
    font-size: 14px;
  }
`;

const WithoutHoverDate = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  width: 48px;
  height: 48px;

  border-radius: 48px;

  margin: 0 7px 5px 7px;

  background-color: #FFFFFF;

  font-family: Poppins-Regular;
  font-size: 14px;

  color: #777777;

  :hover {
    cursor: default;
  }
`;

const CalenderExit = styled.img`
  width: 20px;
  height: 20px;

  :hover {
    cursor: pointer;
  }
`;

const CustomText = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.hover};
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  margin-top: 28px;

  width: 100%;

  gap: 16px;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 72px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #DFDFDF;

  :hover {
    cursor: pointer;
    color: #777777;
    border: 1px solid #777777;
  }

  transition: all 0.3s ease-in-out;
`;

const SaveButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 98px;
  height: 38px;

  border-radius: 15px;
  background-color: #6DD49E;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }

  transition: all 0.3s ease-in-out;
`;