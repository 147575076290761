import { React } from 'react';
import styled from 'styled-components';

import GrayLogoPath from '../../../image/GrayLogo.png';

function MakingView() {

  return (
    <TopLevelWrapper>
      <LogoView src={GrayLogoPath}/>
      <Text>현재 준비중인 페이지입니다.</Text>
    </TopLevelWrapper>
  )
}

export default MakingView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 1020px;

  margin-top: 72px;
`;

const LogoView = styled.img`
  width: 200px;
  height: 200px;
`;

const Text = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 20px;

  color: #BBBAB9;

  margin: 0 0 0 0;
`;