import { React, useState, useImperativeHandle, forwardRef, useRef } from 'react';
import styled from 'styled-components';

const SecondInput = forwardRef((props, ref) => {
  const { className, onChangeValue, setNowFocused, setIsBlur } = props;

  const [value, setValue] = useState('');

  const InputRef = useRef();

	useImperativeHandle(ref, () => ({
		setValue: (val) => {
			setValue(val);
		},
		getValue: () => {
      return value;
		},
    reset: () => {
      setValue('');
    },
    focus: () => {
      InputRef.current.focus();
    }
	}));

  // 값 변경시 //
  function handleChange(e) {
    let regex = /[^0-9]/g;				// 숫자가 아닌 문자열을 매칭하는 정규식
    let result = e.target.value.replace(regex, "");	// 원래 문자열에서 숫자가 아닌 모든 문자열을 빈 문자로 변경

    if (result !== '' && result.length < 2) {
      setValue(result);
      onChangeValue(className, result);
    }
  }

  // 입력창에 포커스 걸릴시 //
  function handleFocus() {
    setNowFocused(className);
    setIsBlur(false);
  }

  // border 색상 변경 //
  function decideBorder() {
    if (value === '') return '4px solid #FF8B48';
    else return '4px solid #FFE5D6';
  }

  return (
    <TopLevelWrapper border={decideBorder()}>
      <Input
        ref={InputRef} 
        value={value} 
        onChange={handleChange} 
        onFocus={handleFocus}
        size={1}/>
    </TopLevelWrapper>
  )
})

export default SecondInput;

const TopLevelWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 57px;
  height: 57px;

  // border: 4px solid #FF8B48;
  border: ${props => props.border};
  border-radius: 15px;
  background-color: #FFFFFF;

  overflow: hidden;

  transition: all 0.3s ease-in-out;
`;

const Input = styled.input`
	font-family: NotoSansKR-Regular;
	font-size: 38px;
	line-height: 20px;
	
	padding: 0 0 0 0;

	width: 40%;
  height: 100%;

	outline: none;
  
	color: #FC6A16;
  border: 0px;

	// ::placeholder {
	// 	color: ${props => props.clickCount < 1 || props.value !== '' ? `#CBCBCB` : `#FF3131;`};
	// }

  &:focus {
    outline: none;
  }
`;

const PasswordView = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  background-color: #FC6A16;

  transition: all 0.3s ease-in-out;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }
`;