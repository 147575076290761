import { React } from 'react';
import styled from 'styled-components';

const NoticeViewBox = (props) => {
  const { data } = props;

  return (
    <TopLevelWrapper>
      <ViewBox>
        <DetailWrapper>
          <ComponentWrapper>
            <Title>제목</Title>
            <TitleBox>{data.title}</TitleBox>
          </ComponentWrapper>
          <ComponentWrapper>
            <Title>내용</Title>
            <DetailBox dangerouslySetInnerHTML={{__html: data.details}}>
            </DetailBox>
          </ComponentWrapper>
          <ComponentWrapper>
            <Title>첨부파일</Title>
          </ComponentWrapper>
        </DetailWrapper>
      </ViewBox>
    </TopLevelWrapper>
  )
}

export default NoticeViewBox;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 1020px; 
  // height: 300px;

  margin-top: 35px;
  margin-bottom: 166px;
  padding-top: 27px;
  padding-bottom: 28px;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  background-color: #FFFFFF;
`;

const ViewBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const DetailWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 24px;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 8px;
`;

const Title = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;

  cursor: default;
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: 948px;
  height: 30px;

  padding: 0 8px 0 8px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background-color: #FFFFFF;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #232323;

  cursor: default;
`;

const DetailBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: 948px;
  min-height: 311px;

  padding: 8px 8px 8px 8px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background-color: #FFFFFF;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #232323;

  cursor: default;
`;

const FileWrapper = styled.div`

`;

