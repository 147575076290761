import { React } from 'react';
import styled from 'styled-components';

import SearchImage from '../../image/SearchImage.png';

export default function SearchInput(props) {
  const { value, setValue, placeholder } = props;

  function handleClick(e) {
  }

  function handleChange(e) {
    setValue(e.target.value);
  }

  function handleFocus(e) {
  }

  function handleBlur(e) {
  }

  return (
    <TopLevelWrapper>
      <Icon src={SearchImage}/>
      <Input
        value={value}
        onClick={handleClick}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        placeholder={placeholder} />
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 100%;
	height: 32px;

  border-radius: 16px;
  background-color: #F2F2F2;
`;

const Icon = styled.img`
  margin: 0px 8px 0px 8px;

  width: 24px;
  height: 24px;
`;

const Input = styled.input`
	font-family: NotoSansKR-Regular;
	font-size: 14px;
	
	// padding: 0 8px 0 8px;

	outline: none;
	border: 0px;
	// border-radius: 8px;
	// color: #CBCBCB;
  color: #232323;

	width: 320px;
	height: 100%;

  background-color: transparent;

	::placeholder {
		color: #CBCBCB;
	}
`;