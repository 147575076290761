import React, { useEffect, useState, useRef, useMemo } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Doughnut } from 'react-chartjs-2';
import Lottie from 'lottie-react-web';
import moment from 'moment';

import FetchModule from '../../Share/Network/FetchModule';

import LoadingAnimation from '../../../Animation/LoadingAnimation.json';
import EmptySalesImage from '../../../image/EmptySalesImage.png';
import NotOpenSalesImage from '../../../image/NotOpenSalesImage.png';

const CircleGraph = (props) => {
  const {
    today,
    startDate,
    endDate,
    data,
    isOpenPartnerGymSales,
    isDoughnutLoading,
    setIsDoughnutLoading
  } = props;

  const history = useHistory();

  const [isEmptySales, setIsEmptySales] = useState(false);
  const [topSalesName, setTopSalesName] = useState('항목 없음');
  const [topSalesPrice, setTopSalesPrice] = useState(0);
  const [totalSalesPrice, setTotalSalesPrice] = useState(0);
  const [lastMonthSales, setLastMonthSales] = useState([]);
  const [lastMonthTotalPrice, setLastMonthTotalPrice] = useState(0);
  const [priceToString, setPriceToString] = useState('');
  const [rateOfChange, setRateOfChange] = useState(0);

  // const [chartData, setChartData] = useState();
  const [chartData, setChartData] = useState({
    datasets: [{
      label: ['', '', '', ''],
      data: [30, 60, 90, 120],
      backgroundColor: [
        '#53B38F',
        '#FF8B48',
        '#65C5FA',
        '#ECECEC'
      ],
      hoverBackgroundColor: [
        '#53B38F',
        '#FF8B48',
        '#65C5FA',
        '#ECECEC'
      ],
      borderColor: [
        '#53B38F',
        '#FF8B48',
        '#65C5FA',
        '#ECECEC'
      ],
      hoverBorderColor: [
        '#53B38F',
        '#FF8B48',
        '#65C5FA',
        '#ECECEC'
      ],
      borderWidth: 1,
    }]
  })

  useEffect(() => {
    if (data.length === 0) {
      // setChartData(newChartData);
      setTopSalesName('항목 없음');
      setTopSalesPrice(0);
      setTotalSalesPrice(0);
      setIsEmptySales(true);


      setTimeout(() => (
        setIsDoughnutLoading(false)
      ), 1300)

      return;
    }
    // const newChartData = chartData;
    const newChartData = {
      datasets: [{
        label: ['', '', '', ''],
        data: [0, 0, 0, 0],
        backgroundColor: [
          '#53B38F',
          '#FF8B48',
          '#65C5FA',
          '#ECECEC'
        ],
        hoverBackgroundColor: [
          '#53B38F',
          '#FF8B48',
          '#65C5FA',
          '#ECECEC'
        ],
        borderColor: [
          '#53B38F',
          '#FF8B48',
          '#65C5FA',
          '#ECECEC'
        ],
        borderWidth: 1,
      }]
    };

    // if (data.length === 0) {
    //   // setChartData(newChartData);
    //   setTopSalesName('항목 없음');
    //   setTopSalesPrice(0);
    //   setTotalSalesPrice(0);
    //   return;
    // } 

    var newDataList = [];

    for (var i = 0; i < data.length; i++) {
      var index = -1;

      if (newDataList.length === 0) {
        var newData = {
          itemName: data[i].itemName,
          price: data[i].cardPrice + data[i].cashPrice
        }

        newDataList.push(newData);
      }
      else {
        for (var j = 0; j < newDataList.length; j++) {
          if (newDataList[j].itemName === data[i].itemName) {
            index = j;
          }
        }

        if (index === -1) {
          newData = {
            itemName: data[i].itemName,
            price: data[i].cardPrice + data[i].cashPrice
          }

          newDataList.push(newData);
        }
        else {
          newDataList[index].price += data[i].cardPrice + data[i].cashPrice;
        }
      }
    }

    newDataList.sort(function (a, b) {
      return a.price > b.price ? -1 : a.price < b.price ? 1 : 0;
    })

    var otherData = {
      membershipName: '기타',
      price: 0
    }

    for (var k = 3; k < newDataList.length; k++) {
      otherData.price += newDataList[k].price;
    }

    setTopSalesName(newDataList[0].itemName);
    setTopSalesPrice(newDataList[0].price);

    var totalPrice = 0;

    for (var v = 0; v < newDataList.length; v++) {
      totalPrice += newDataList[v].price;
    }

    setTotalSalesPrice(totalPrice);

    var newDataSetList = [];
    var newLabelList = [];

    if (newDataList.length === 0) {
      // newChartData[0].datasets[0].data.push(0);
      newChartData.datasets[0].data[0] = 0;
      newChartData.datasets[0].data[1] = 0;
      newChartData.datasets[0].data[2] = 0;
      newChartData.datasets[0].data[3] = 0;

      newChartData.datasets[0].label[0] = 0;
      newChartData.datasets[0].label[1] = 0;
      newChartData.datasets[0].label[2] = 0;
      newChartData.datasets[0].label[3] = 0;
    }
    else if (newDataList.length === 1) {
      // newChartData[0].datasets[0].data.push(newDataList[0].price);

      // newChartData[0].datasets[0].label.push(newDataList[0].itemName);
      newChartData.datasets[0].data[0] = newDataList[0].price;
      newChartData.datasets[0].data[1] = 0;
      newChartData.datasets[0].data[2] = 0;
      newChartData.datasets[0].data[3] = 0;

      newChartData.datasets[0].label[0] = newDataList[0].itemName;
      newChartData.datasets[0].label[1] = 0;
      newChartData.datasets[0].label[2] = 0;
      newChartData.datasets[0].label[3] = 0;

    }
    else if (newDataList.length === 2) {
      // newChartData[0].datasets[0].data.push(newDataList[0].price);
      // newChartData[0].datasets[0].data.push(newDataList[1].price);

      // newChartData[0].datasets[0].label.push(newDataList[0].itemName);
      // newChartData[0].datasets[0].label.push(newDataList[1].itemName);

      newChartData.datasets[0].data[0] = newDataList[0].price;
      newChartData.datasets[0].data[1] = newDataList[1].price;
      newChartData.datasets[0].data[2] = 0;
      newChartData.datasets[0].data[3] = 0;

      newChartData.datasets[0].label[0] = newDataList[0].itemName;
      newChartData.datasets[0].label[1] = newDataList[1].itemName;
      newChartData.datasets[0].label[2] = 0;
      newChartData.datasets[0].label[3] = 0;
    }
    else if (newDataList.length === 3) {
      // newChartData[0].datasets[0].data.push(newDataList[0].price);
      // newChartData[0].datasets[0].data.push(newDataList[1].price);
      // newChartData[0].datasets[0].data.push(newDataList[2].price);

      // newChartData[0].datasets[0].label.push(newDataList[0].itemName);
      // newChartData[0].datasets[0].label.push(newDataList[1].itemName);
      // newChartData[0].datasets[0].label.push(newDataList[2].itemName);

      newChartData.datasets[0].data[0] = newDataList[0].price;
      newChartData.datasets[0].data[1] = newDataList[1].price;
      newChartData.datasets[0].data[2] = newDataList[2].price;
      newChartData.datasets[0].data[3] = 0;

      newChartData.datasets[0].label[0] = newDataList[0].itemName;
      newChartData.datasets[0].label[1] = newDataList[1].itemName;
      newChartData.datasets[0].label[2] = newDataList[2].itemName;
      newChartData.datasets[0].label[3] = 0;
    }
    else if (newDataList.length >= 4) {
      // newChartData[0].datasets[0].data.push(newDataList[0].price);
      // newChartData[0].datasets[0].data.push(newDataList[1].price);
      // newChartData[0].datasets[0].data.push(newDataList[2].price);
      // newChartData[0].datasets[0].data.push(otherData.price);

      // newChartData[0].datasets[0].label.push(newDataList[0].itemName);
      // newChartData[0].datasets[0].label.push(newDataList[1].itemName);
      // newChartData[0].datasets[0].label.push(newDataList[2].itemName);
      // newChartData[0].datasets[0].label.push(otherData.membershipName);


      newChartData.datasets[0].data[0] = newDataList[0].price;
      newChartData.datasets[0].data[1] = newDataList[1].price;
      newChartData.datasets[0].data[2] = newDataList[2].price;
      newChartData.datasets[0].data[3] = otherData.price;

      newChartData.datasets[0].label[0] = newDataList[0].itemName;
      newChartData.datasets[0].label[1] = newDataList[1].itemName;
      newChartData.datasets[0].label[2] = newDataList[2].itemName;
      newChartData.datasets[0].label[3] = otherData.membershipName;
    }

    // newChartData.datasets[0].data = newDataSetList;
    // newChartData.datasets[0].label = newLabelList;

    setChartData(newChartData);
    setIsEmptySales(false);

    setTimeout(() => (
      setIsDoughnutLoading(false)
    ), 1300)

    // console.log("=============");
    // console.log(newChartData[0]);
  }, [data]);

  // useEffect(() => {
  //   console.log(chartData);
  // }, [chartData])
  // useEffect(() => {
  //   setTimeout(() => (
  //     setIsDoughnutLoading(false)
  //   ), 1300)
  // }, [chartData])

  // 전월 매출내역 호출
  useEffect(() => {
    // const fetchModule = new FetchModule();
    // (
    //   async () => {
    //     const responseData = await fetchModule.getIndividualData('sales/' + today.clone().subtract(1, 'month').format('YYYY-MM') + '-01T00:00:00', 'GET');

    //     if (responseData.status === 200) setLastMonthSales(responseData.data);
    //     else if (responseData.status === 401) history.push('/');
    //     else if (responseData.status === 204) setLastMonthSales([]);
    //   }
    // )();
    let requestData = {
      // standardMonth: today.clone().subtract(1, 'month').format('YYYY-MM') + '-01T00:00:00'
      startDate: startDate.clone().subtract(1, 'month').format('YYYY-MM-DDTHH:mm:ss'),
      // endDate: endDate.clone().subtract(1, 'month').format('YYYY-MM-DDTHH:mm:ss')
      endDate: endDate.clone().subtract(1, 'month').format('YYYY-MM-DDT23:59:59')
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataVersion2('sales/history/month', 'POST', requestData);
        // console.log('-----Sales');
        // console.log(responseData);
        if (responseData.status === 200) setLastMonthSales(responseData.data);
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }, [startDate, endDate])

  useEffect(() => {
    if (lastMonthSales.length === 0) setLastMonthTotalPrice(0);
    else {
      let totalPrice = 0;

      for (let i = 0; i < lastMonthSales.length; i++) {
        totalPrice += lastMonthSales[i].cashPrice + lastMonthSales[i].cardPrice;
      }

      setLastMonthTotalPrice(totalPrice);
    }
  }, [lastMonthSales])

  useEffect(() => {
    let hanA = ["", "일", "이", "삼", "사", "오", "육", "칠", "팔", "구", "십"];
    let danA = ["", "십", "백", "천", "", "십", "백", "천", "", "십", "백", "천", "", "십", "백", "천"];
    let stringPrice = totalSalesPrice.toString();
    let result = "";
    let isNegative = false;

    if (totalSalesPrice < 0) {
      isNegative = true;
      stringPrice = (totalSalesPrice * -1).toString();
    }
    else isNegative = false;

    for (let i = 0; i < stringPrice.length; i++) {
      let str = "";
      let han = hanA[stringPrice.charAt(stringPrice.length - (i + 1))];

      if (han !== "") str += han + danA[i];
      if (i === 4) str += "만";
      if (i === 8) str += "억";
      if (i === 12) str += "조";

      result = str + result;
    }

    if (stringPrice !== 0) {
      if (isNegative) result = "-" + result + "원";
      else result = result + "원";
    }

    setPriceToString(result);
  }, [totalSalesPrice])

  useEffect(() => {
    // 금월 매출이 0원일 경우
    if (totalSalesPrice === 0) setRateOfChange(0);
    // 전월 매출이 0원인 경우
    else if (lastMonthTotalPrice === 0) setRateOfChange(100);
    // 전월 금월 매출이 존재할경우
    else setRateOfChange(Math.round((totalSalesPrice - lastMonthTotalPrice) / lastMonthTotalPrice * 100));
  }, [totalSalesPrice, lastMonthTotalPrice])

  // 통계 날짜 텍스트 정리 //
  function calcDateText() {
    let start = moment(today.format('YYYY-MM-') + '01T00:00:00');
    let end = moment(start).add(1, 'month').subtract(1, 'second');

    if (!moment().isAfter(end)) end = moment(moment().format('YYYY-MM-DDT23:59:59'));

    if (startDate.format('YYYY-MM-DDTHH:mm:ss') === start.format('YYYY-MM-DDTHH:mm:ss')
      && endDate.format('YYYY-MM-DDTHH:mm:ss') === end.format('YYYY-MM-DDTHH:mm:ss')) return today.clone().format('YYYY년 MM월');
    else {
      return startDate.format('YY년 MM월 DD일') + ' - ' + endDate.format('MM월 DD일');
    }
  }


  return (
    <TopLevelWrapper>
      <InfoBox>
        {/* <Title>{today.clone().format('YYYY년 MM월 총 매출')}</Title> */}
        <Title>{calcDateText() + ' 총 매출'}</Title>
        {
          isDoughnutLoading ?
            <AnimationBox>
              <LottieWrapper>
                <Lottie options={{ animationData: LoadingAnimation }} />
              </LottieWrapper>
            </AnimationBox>
            :
            <TotalInfoView>
              {
                isOpenPartnerGymSales ?
                  <InfoView>
                    {
                      isEmptySales ?
                        <ChartWrapper>
                          <EmptySalesChart src={EmptySalesImage} />
                        </ChartWrapper>
                        :
                        <ChartWrapper>
                          <TotalPriceViewWrapper>
                            <TotalPriceView>{totalSalesPrice.toLocaleString('ko-KR')}원</TotalPriceView>
                            <TotalPriceText>{priceToString}</TotalPriceText>
                          </TotalPriceViewWrapper>
                          <DoughnutWrapper>
                            <Doughnut data={chartData} redraw={false} />
                          </DoughnutWrapper>
                        </ChartWrapper>
                    }
                    {
                      isEmptySales ?
                        <EmptySubInfoBox>
                          <TextWrapper>
                            <Text fontFamily='NotoSansKR-Black' fontSize={15} fontColor='#FF8B48'>이 구간에는 매출이 존재하지 않습니다</Text>
                          </TextWrapper>
                        </EmptySubInfoBox>
                        :
                        <SubInfoBox>
                          <TextWrapper>
                            <InfoText>{topSalesName}</InfoText>
                            <Explanation>({totalSalesPrice === 0 || topSalesPrice === 0 ? 0 : Math.ceil((topSalesPrice / totalSalesPrice) * 100)}%)</Explanation>
                          </TextWrapper>
                          <TextWrapper>
                            <InfoText>{totalSalesPrice.toLocaleString('ko-KR')}원</InfoText>
                            {/* <Explanation>(전월 대비 {Math.ceil((totalSalesPrice / lastMonthTotalPrice) * 100)}% 상승)</Explanation> */}
                            <Explanation>(전월 대비 {rateOfChange > 0 ? rateOfChange + `% 상승` : rateOfChange * -1 + `% 감소`})</Explanation>
                          </TextWrapper>
                        </SubInfoBox>
                    }
                  </InfoView>
                  :
                  <InfoView>
                    <ChartWrapper>
                      <EmptySalesChart src={NotOpenSalesImage} />
                    </ChartWrapper>
                    <EmptySubInfoBox>
                      <TextWrapper>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={15} fontColor='#444444'>이 업체는 파트너 체육관에 매출을 비공개 합니다</Text>
                      </TextWrapper>
                    </EmptySubInfoBox>
                  </InfoView>
              }
            </TotalInfoView>
        }
      </InfoBox>
    </TopLevelWrapper>
  )
}

export default React.memo(CircleGraph);

const TopLevelWrapper = styled.div`
  display: flex;
  felx-direction: column;

  justify-content: center;
  align-items: center;

  width: 408px;
  height: 574px;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  background-color: #FFFFFF;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-conetent: center;
  align-items: flex-start;

  width: 90.33%;
  height: 90.24%;
`;

const TotalInfoView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 
`;

const InfoView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const Title = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;
`;

const ChartWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 352px;
  height: 400px;

  margin-top: 24px;
  border-top: 1px solid #DFDFDF;
`;

const EmptySalesChart = styled.img`
  width: 100%;
`;

const TotalPriceViewWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  gap: 4px;

  // width: 214px;
  // height: 214px;

  margin-top: 10px;
  // margin-top: 74px;


  border-radius: 214px;
  background-color: #FFFFFF;
`;

const DoughnutWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
`;

const AnimationBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  z-index: 5;
  
  width: 100%;
  height: 100%;

  // background-color: rgba(0, 0, 0, 0.2);
  background-color: #FFFFFF;
`;

const LottieWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 80px;
  height: 80px;
`;

const TotalPriceView = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // width: 158px;
  width: 130px;
  height: 40px;

  border-radius: 15px;
  background-color: #ECECEC;

  font-family: NotoSansKR-Bold;
  font-size: 14px;

  color: #777777;
`;

const TotalPriceText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #777777;

  margin: 0 0 0 0;
`;

const SubInfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: 350px;
  height: 64px;

  gap: 8px;

  border: 1px solid #E3E3E3;
  border-radius: 12px;
  background-color: #FFFFFF;
`;

const EmptySubInfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 350px;
  height: 64px;

  gap: 8px;

  border: 1px solid #E3E3E3;
  border-radius: 12px;
  background-color: #FFFFFF;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-left: 12px;
`;

const InfoText = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 12px;

  color: #000000;

  margin: 0 0 0 0;
`;

const Explanation = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #777777;

  margin: 0 0 0 3px;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.fontColor};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.hover};
  }
`;