import React, { useEffect, useState } from "react";
import styled from "styled-components";

import TrashImage from '../../image/TrashIcon.png';
import ProvisionButton from "./ProvisionButton";
import TermsTitleBox from "./TermsTitleBox";
import moment from "moment";

export default function TermsEditModal(props) {
  const {
    storeName,
    clickedTerms,
    provisions,
    defaultProvisions,
    onClickExit,
    onClickSave
  } = props;

  const [id, setId] = useState(undefined);
  const [title, setTitle] = useState('');
  const [provisionKeys, setProvisionKeys] = useState([]);
  const [includeProvisions, setIncludeProvisions] = useState([]);
  // const [status, setStatus] = useS

  // 데이터 정리 //
  useEffect(() => {
    if (clickedTerms === undefined) {
      setId(undefined);
      setTitle('');
      setProvisionKeys([]);
      setIncludeProvisions([]);
    }
    else {
      setId(clickedTerms.id);
      setTitle(clickedTerms.title);
      setProvisionKeys(clickedTerms.provisionKeys);

      let newIncludeProvisions = [];

      provisions.map((data) => {
        if (clickedTerms.provisionKeys.includes(data.id)) {
          newIncludeProvisions.push(data);
        }
      })

      setIncludeProvisions(newIncludeProvisions);
    }
  }, [clickedTerms])

  // 약관 버튼 클릭시 //
  function onClickProvisionsButton(data) {
    let keys = provisionKeys.slice();
    let index = provisionKeys.findIndex(i => i === data.id);

    // 이미 선택된 약관인 경우 //
    if (index > -1) {
      keys.splice(index, 1);
      let newIncludeProvisions = [];
      
      provisions.map((data) => {
        if (keys.includes(data.id)) {
          newIncludeProvisions.push(data);
        }
      });

      setProvisionKeys(keys);
      setIncludeProvisions(newIncludeProvisions);
    }
    // 처음 선택된 약관인 경우 //
    else {
      let newIncludeProvisions = [];

      keys.push(data.id);

      for (let i = 0; i < keys.length; i++) {
        for (let j = 0; j < provisions.length; j++) {
          if (keys[i] === provisions[j].id) newIncludeProvisions.push(provisions[j]);
        }
      }
      
      setProvisionKeys(keys);
      setIncludeProvisions(newIncludeProvisions);
    }
  }

  // 저장하기 버튼 클릭시 //
  function onClickSaveButton() {
    let data = {
      id: id,
      title: title,
      provisionKeys: provisionKeys,
      status: id === undefined ? false : clickedTerms.status
    }

    onClickSave(data);
  }

  return (
    <Background>
      <Modal>
        <InfoWrapper>
          <TopLineWrapper>
            <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#232323' hover='default'>{id === undefined ? `약관 작성하기` : `약관 수정하기`}</Text>
            <TopLineButtonWrapper>
              <DeleteButton onClick={onClickExit}>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#3E4148' hover='pointer'>닫기</Text>
              </DeleteButton>
              <SaveButton onClick={onClickSaveButton}>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' hover='pointer'>저장하기</Text>
              </SaveButton>
            </TopLineButtonWrapper>
          </TopLineWrapper>
          <ComponentView>
            <TermsView>
              <TermsScroll>
                <TermsWrapper>
                  <TermsTitleBox value={title} setValue={setTitle} placeholder='계약서 템플릿 이름' />
                  <TermsTitle>
                    <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#232323' hover='default'>[센터 이름] 회원가입 계약서</Text>
                  </TermsTitle>

                  <DivisionLine style={{ margin: '10px 10px 14px 10px', padding: '0px' }} width='calc(100% - 20px)' backgroundColor='#F9F9F9' />

                  <TermsInfoWrapper>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#232323' hover='default'>회원 정보</Text>
                    <MemberInfoWrapper>
                      <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#232323' hover='default'>이름</Text>
                      <MemberInfoBox />
                      <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#232323' hover='default'>성별</Text>
                      <MemberInfoBox />
                      <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#232323' hover='default'>연락처</Text>
                      <MemberInfoBox />
                    </MemberInfoWrapper>
                    <DivisionLine style={{ margin: '10px 10px 14px 10px', padding: '0px' }} width='100%' backgroundColor='#F9F9F9' />

                    <ProvisionInfoWrapper>
                      <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#232323' hover='default'>이용약관</Text>
                      {
                        includeProvisions.map((data, index) => (
                          <ProvisionInfoWrapper key={'DefaultProvision-' + index}>
                            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#595959' hover='default'>{data.title}</Text>
                            <PreText fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='default'>{data.text}</PreText>
                          </ProvisionInfoWrapper>
                        ))
                      }
                      {
                        defaultProvisions.map((data, index) => (
                          <ProvisionInfoWrapper key={'DefaultProvision-' + index}>
                            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#595959' hover='default'>{data.title}</Text>
                            <PreText fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='default'>{data.text}</PreText>
                          </ProvisionInfoWrapper>
                        ))
                      }
                    </ProvisionInfoWrapper>

                    <DivisionLine style={{ margin: '20px 0px 10px 0px', padding: '0px' }} width='100%' backgroundColor='#F9F9F9' />
                    
                    <SignWrapper>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#595959' hover='default'>{moment().format('YYYY년 MM월 DD일') + ' [서명 날짜]'}</Text>
                      <SignBoxWrapper>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#595959' hover='default'>회원 이름</Text>
                        <SignBox>
                          <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#595959' hover='default'>서명</Text>
                        </SignBox>
                      </SignBoxWrapper>
                    </SignWrapper>
                    <StoreNameWrapper>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#595959' hover='default'>{'[' + storeName + ']'}</Text>
                    </StoreNameWrapper>
                  </TermsInfoWrapper>
                </TermsWrapper>
              </TermsScroll>
            </TermsView>
            <TermsValueView>
              <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#232323' hover='default'>계약서에 표기할 항목</Text>
              <ProvisionsWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#232323' hover='default'>약관 설정</Text>
                <DivisionLine style={{ margin: '12px 0px 20px 0px' }} width='262px' backgroundColor='#DBDBDB' />
                <ProvisionsButtonWrapper>
                  {
                    provisions.map((data, index) => (
                      <ProvisionButton
                        key={'ProvisionButton-' + index}
                        data={data}
                        keys={provisionKeys}
                        onClickProvisionsButton={onClickProvisionsButton} />
                    ))
                  }
                </ProvisionsButtonWrapper>
              </ProvisionsWrapper>
            </TermsValueView>
          </ComponentView>
        </InfoWrapper>
      </Modal>
    </Background>
  )
}

const Background = styled.div`
  position: fixed;
	display: flex;
	flex-direction: column;

  justify-content: center;
  align-items: center;

  top: 0;
  left: 0;

  z-index: 500;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.5);
`;

const Modal = styled.div`
	display: flex;
	flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 1132px;
  min-height: 320px;
  height: 86%;

  border-radius: 10px;
  background-color: #FFFFFF;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: calc(100% - 40px);
  height: calc(100% - 20px);
`;

const TopLineWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const TopLineButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 10px;
`;

const DeleteButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 62px;
  height: 40px;

  border-radius: 10px;
  background-color: #F5F6F8;

  :hover {
    cursor: pointer;
  }
`;

const SaveButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 10px;
  background-color: #5BD98A;

  :hover {
    cursor: pointer;
  }
`;

const ComponentView = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  margin-top: 20px;

  width: 100%;
  height: calc(100% - 60px);
`;

const TermsView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  padding: 20px 10px 20px 10px;

  // width: 776px;
  width: 756px;
  height: calc(100% - 40px);

  border-radius: 10px;
  background-color: #F9F9F9;
`;

const TermsScroll = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 752px;
  height: 100%;

  overflow-x: hidden;
  overflow-y: scroll;

	&::-webkit-scrollbar {
		width: 9px;
	}

	&::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 20px #DFDFDF;
		border: 3px solid transparent;
		height: 25px;
		// background-color: #FF8B48;
		border-radius: 200px;
	}

	&::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px transparent;
		border: 4px solid transparent;
		background-color: transparent;
	}

	&::-webkit-scrollbar-track-piece {
		background-color: transparent;
	}
`;

const TermsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  padding: 10px 10px;

  width: 712px;

  background-color: #FFFFFF;
`;

const TermsTitle = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  padding: 8px 10px;
  margin-top: 10px;

  width: calc(100% - 20px);

  background-color: #F9F9F9;
`;

const TermsInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  padding: 0px 10px;

  width: calc(100% - 20px);
`;

const MemberInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 18px;

  width: 100%;

  gap: 4px;
`;

const MemberInfoBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 86px;
  height: 16px;

  border: 2px dotted #000000;
  background-color: #FFFFFF;
`;

const TermsValueView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  padding: 20px 10px;

  // width: 302px;
  width: 282px;

  border: 2px solid #DBDBDB;
  border-radius: 10px;
  background-color: #FFFFFF;
`;

const ProvisionsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  padding: 0px 10px;
  margin-top: 24px;

  width: calc(100% - 10px);
`;

const DivisionLine = styled.div`
  width: ${props => props.width};
  height: 2px;

  background-color: ${props => props.backgroundColor};
`;

const ProvisionsButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  flex-wrap: wrap;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  gap: 10px;

`;

const ProvisionInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  flex-wrap: wrap;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 24px;
`;

const SignWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const SignBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 20px;
`;

const SignBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 146px;
  height: 36px;

  border: 1px solid #595959;
  border-radius: 5px;
  background-color: #FFFFFF;
`;

const StoreNameWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // margin-top: 20px;
  margin: 20px 0px 60px 0px;

  width: 100%;
`;

const PreText = styled.pre`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  margin: 0 0 0 0;

  white-space: pre-wrap;

  color: ${props => props.color};

  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  margin: 0 0 0 0;

  color: ${props => props.color};

  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;