import { React, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import moment from 'moment';

import MemberShipInfoEditView from './MemberShipInfoEditView.js';
import FetchModule from '../../../../Share/Network/FetchModule.js';

export default function MemberShipEditModal(props) {
  const { className, exitClickFn, data, index, userData } = props;

  const [isLinkPay, setIsLinkPay] = useState(false);
  const [originalApprovalDate, setOriginalApprovalDate] = useState('');
  const [originalCardPrice, setOriginalCardPrice] = useState('');

  const history = useHistory();

  const EditRef = useRef();

  useEffect(() => {
    document.body.style= `overflow: hidden`;
    return () => document.body.style = `overflow: auto`;
  }, [])

  useEffect(() => {
    // 링크페이로 결제한 데이터의 경우 //
    if (data[index].merchantUid.split('-')[2] === '1') {
      setOriginalApprovalDate(moment(data[index].approvalDate).format('YYYY.MM.DD'));
      setOriginalCardPrice(data[index].cardPrice);
      setIsLinkPay(true);
    }

  }, [])

  function exitClick() {
    exitClickFn(className);
  }

  function saveClick() {
    let id = data[index].usageId;
    let storeId = window.localStorage.getItem('StoreId');
    let memberId = data[index].memberId;
    let merchantUids = data[index].merchantUids;
    let memberShipId = EditRef.current.getMemberShipId();
    let startDate = EditRef.current.getStartDate();
    let endDate = EditRef.current.getEndDate();
    let approvalDate = EditRef.current.getApprovalDate();
    let cashPrice = EditRef.current.getCashPrice();
    let cardPrice = EditRef.current.getCardPrice();
    let discountPrice = EditRef.current.getDiscountPrice();
    let notReceivedPrice = EditRef.current.getNotReceivedPrice();
    let totalMax = EditRef.current.getTotalMaxCount();

    if (memberShipId === 0 || memberShipId === -1) {
      alert('회원권을 선택해 주세요!');
      return;
    }

    // 링크페이로 결제한 내역의 경우 결제일과 카드결제금액은 변경할 수 없음 //
    if (isLinkPay) {
      if (originalApprovalDate !== approvalDate) {
        alert('링크페이 결제내역은 결제일을 변경할 수 없습니다');
        EditRef.current.setApprovalDate(originalApprovalDate);
        return;
      }
      else if (originalCardPrice !== cardPrice) {
        alert('링크페이 결제내역은 카드결제내역을 변경할 수 없습니다');
        EditRef.current.setCardPrice(originalCardPrice);
        return;
      }
    }

    let requestData = {
      id: id,
      storeId: storeId,
      memberId: memberId,
      merchantUid: merchantUids,
      memberShipId: memberShipId,
      startDate: startDate.replaceAll('.', '-') + 'T00:00:00',
      endDate: endDate.replaceAll('.', '-') + 'T23:59:59',
      approvalDate: approvalDate.replaceAll('.', '-') + 'T00:00:00',
      cashPrice: cashPrice,
      cardPrice: cardPrice,
      discountPrice: discountPrice,
      notReceivedPrice: notReceivedPrice,
      totalMax: parseInt(totalMax)
      // 미수금 넣기 //
    }
    
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.patchData('membership/usage/modify', 'PATCH', requestData);
       
        if (responseData.status === 200) {
          alert('저장이 완료되었습니다.');
          exitClickFn(className);
        }
        else if (responseData.status === 401) {
          alert('로그인 후 다시시도하세요');
          history.push('/');
        }
        else alert("데이터를 불러오는데 실패하였습니다. 잠시후 다시시도해 주세요.");
      }
    )();
  }

  return (
    <TopLevelWrapper>
      <ModalView>
        <MemberShipInfoEditView ref={EditRef} data={data} index={index} userData={userData}/>
        <ButtonWrapper>
          <ExitButton onClick={exitClick}>취소</ExitButton>
          <SaveButton onClick={saveClick}>저장하기</SaveButton>
        </ButtonWrapper>
      </ModalView>
    </TopLevelWrapper>
  )
}

// export default MemberShipEditModal;

const startAnimation = keyframes`
	0% { opacity: 0; }
	50% { opacity: 0.5; }
	100% { opacity: 1; }
`;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  // justify-content: center;
  justify-content: flex-start;
  align-items: center;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 1000;
  
  padding-top: 15px;

  width: 100vw;
  // height: 100vh;
  height: calc(100vh - 15px);

  overflow-y: scroll;

  background-color: rgba(0, 0, 0, 0.2);

	animation-name: ${startAnimation};
	animation-duration: 0.5s;
	animation-timing-function: ease-out;
`;

const ModalView = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  // margin-top: -300px;

  width: 1020px;
  // height: 573px;
  // height: 548px;
  height: 564px;

  // overflow: scroll;

  border-radius: 12px;
  box-shadow: 0px 40px 50px rgba(0, 0, 0, 0.1);
  background-color: #FFFFFF;

  
  // ::-webkit-scrollbar {
  //   display: none;
  // }

  // -ms-overflow-style: none;  /* IE and Edge */
  // scrollbar-width: none;  /* Firefox */
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;

  gap: 16px;

  margin: 36px 0 28px 802px;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 72px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;
  
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #777777;
    color: #777777;
  }
`;

const SaveButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 15px;
  background-color: #5BD98A;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }
`;