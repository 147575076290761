import { React, useRef, useState } from 'react';
import styled from 'styled-components';

import StatisticsView from './StatisticsView';
import UserAuthenticationView from './UserAuthenticationView';
import SecondUserAuthenticationView from './SecondUserAuthenticationView';

function TotalStatisticsView() {
  const [isPassAuthorization, setIsPassAuthorization] = useState(false);

  const AuthRef = useRef();
  const StatisticsRef = useRef();

  function SuccessFn() {
    setIsPassAuthorization(true);
    // AuthRef.current.setOpacity(0);
    // AuthRef.current.setZindex(-3);
    // StatisticsRef.current.setOpacity(1);
    // StatisticsRef.current.setZindex(3);
  }

  return (
    <TopLevelWrapper>
      {/* { !isPassAuthorization && <UserAuthenticationView ref={AuthRef} clickFn={SuccessFn}/> } */}
      { !isPassAuthorization && <SecondUserAuthenticationView ref={AuthRef} clickFn={SuccessFn}/> }
      { isPassAuthorization && <StatisticsView ref={StatisticsRef}/> }
      {/* <StatisticsView ref={StatisticsRef}/> */}
    </TopLevelWrapper>
  )
}

export default TotalStatisticsView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin-left: 42px;
`;
