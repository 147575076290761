import { React, useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './NoticeCreateBox.css';
import Lottie from 'lottie-react-web';

import TitleInput from '../../../Component/NoticeComponent/TitleInput';
import CommentBox from './CommentBox';
import CommentWriteBox from './CommentWriteBox';

import LoadingAnimation from '../../../Animation/LoadingAnimation.json';

const NoticeViewBox = forwardRef((props, ref) => {
  const { data, title, defaultVal, noticeId, rendering, isLoading } = props;

  const [value, setValue] = useState(data.details);
  const [totalLength, setTotalLength] = useState(0);
  const [name, setName] = useState(undefined);
  const [commentId, setCommentId] = useState(-1);

  const TitleRef = useRef();
  const CommentRef = useRef();
  const InfoRef = useRef();

  const modules = {
    toolbar: {
      container: "#toolbar",
    }
  };

  const formats = [
    "size",
    "underline",
    "italic",
    "bold",
    "align",
    "color",
    "background",
    "image"
  ];

  useImperativeHandle(ref, () => ({
    getTitle: () => {
      return TitleRef.current.getValue();
    },
    getValue: () => {
      return value;
    },
  }))

  useImperativeHandle(InfoRef, () => ({
    setName: (val) => {
      setName(val);
    },
    setCommentId: (val) => {
      setCommentId(val);
    },
  }))

  useEffect(() => {
    setValue(defaultVal);
  }, [defaultVal])

  // useEffect(() => {
  //   console.log(name);
  // }, [name])

  useEffect(() => {
    // console.log(data);
    var count = data.commentList.length;

    for (var i = 0; i < data.commentList.length; i++) {
      count += data.commentList[i].replyList.length;
    }

    setTotalLength(count);
  }, [data])

  function handleChange(value) {
    setValue(value);
  }

  return (
    <TopLevelWrapper>
      {
        isLoading ?
          <LoadingView>
            <LottieWrapper>
              <Lottie options={{ animationData: LoadingAnimation }} />
            </LottieWrapper>
          </LoadingView>
          :
          <ViewWrapper>
            <ComponentWrapper>
              <Title>제목</Title>
              <TitleInput ref={TitleRef} placeholder="50자 이내의 제목을 입력해주세요" defaultValue={title} />
            </ComponentWrapper>
            <ComponentWrapper style={{ marginTop: `24px` }}>
              <Title>내용</Title>
              <QuillWrapper className="text-editor">
                <QuillNoneToolbar id="toolbar" />
                <ReactQuill
                  modules={modules}
                  formats={formats}
                  theme='snow'
                  placeholder='내용을 입력해주세요'
                  style={{
                    width: `964px`,
                    // height: `287px`,
                    border: `1px solid #DFDFDF !Important`,
                    borderRadius: `30px`,
                    fontFamily: 'NotoSansKR-Regular',
                    fontSize: '14px',
                    color: '#232323',
                  }}
                  readOnly={true}
                  value={value || ''}
                  onChange={handleChange} />
              </QuillWrapper>
            </ComponentWrapper>
            <CommentBoxWrapper>
              <CommentTitleWrapper>
                <CommentTitle>댓글</CommentTitle>
                <CommentCount>{totalLength}</CommentCount>
              </CommentTitleWrapper>
              {
                data.commentList.map((commentData, index) => (
                  <CommentBox key={index} data={commentData} isLast={data.commentList.length === index + 1} rendering={rendering} commentRef={CommentRef} infoRef={InfoRef} />
                ))
              }
            </CommentBoxWrapper>
            <CommentWriteBox noticeId={noticeId} rendering={rendering} commentRef={CommentRef} name={name} commentId={commentId} />
          </ViewWrapper>
      }
    </TopLevelWrapper>
  )
})

export default NoticeViewBox;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 1020px;
  min-height: 535px;

  margin-top: 38px;
  margin-bottom: 96px;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  background-color: #FFFFFF;
`;

const LoadingView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  border-radius: 30px;
  background-color: #FFFFFF;
`;

const ViewWrapper = styled.div`
  diplay: flex;
  flex-direction: column;

  justify-content: flex-start
  align-items: flex-start;
  
  margin-top: 26px;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;
`;

const Title = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  margin: 0 0 0 0;

  color: #232323;
`;

const QuillWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  margin-top: 16px;

  // border: none !Important;
  // width: 964px;
  // height: 320px;
`;

const QuillNoneToolbar = styled.div`
  display: none;
`;

const CommentBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 38px;
  margin-bottom: 24px;
`

const CommentTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
`;

const CommentTitle = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;
`;

const CommentCount = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #777777;

  margin: 0 0 0 0;
`;

const LottieWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 60px;
  height: 60px;
`;