import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

function RulerPage() {
  const CanvasRef = useRef();

  useEffect(() => {
    const canvas = CanvasRef.current;

    if (canvas) {
      const ctx = canvas.getContext('2d');

      canvas.width = 21000;
      canvas.height = 60;
  
      for (var i = 0; i < 3000; i++) {
        ctx.strokeStyle = '#BDBDBD';
        // ctx.strokeStyle = 'red';
        ctx.beginPath();
        ctx.moveTo((i * 7), 0);
        ctx.lineTo((i * 7), i % 5 === 0 ? 25 : 17);
  
        ctx.stroke();
      }
    }
  }, [CanvasRef]);

  return (
    <Contanier>
      <canvas ref={CanvasRef}/>
    </Contanier>
  )
}

export default RulerPage;

const Contanier = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  top: 0;
  left: 0;

  justify-content: flex-start;
  align-items: flex-start;

  background-color: blue;
`;