import { React, useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';

import lodash from 'lodash';

import MonthlyColumn from './MonthlyColumn';

const MonthlyClass = forwardRef((props, ref) => {
  const { date, useScheduleData } = props;

  const [today, setToday] = useState(date);
  const [dateList, setDateList] = useState([]);
  const [isClicked, setIsClicked] = useState(false);

  const [useDateList, setUseDateList] = useState([]);
  // const [useScheduleData, setUseScheduleData] = useState([]);
  const [totalData, setTotalData] = useState([[]]);

  const firstWeek = today.clone().startOf('month').week();
  const lastWeek = today.clone().endOf('month').week() === 1 ? 53 : today.clone().endOf('month').week();

  const TopLevelRef = useRef();

  useImperativeHandle(ref, () => ({
    visible: () => {
			TopLevelRef.current.style.opacity = 1;
			TopLevelRef.current.style.zIndex = 1;
		},
		unVisible: () => {
			TopLevelRef.current.style.opacity = 0;
			TopLevelRef.current.style.zIndex = -3;
		}
  }))

  useEffect(() => {
    setToday(date);
  }, [date])
  
  useEffect(() => {
    let newDateList = [];

    for (let week = firstWeek; week <= lastWeek; week++) {
      for (let i = 0; i < 7; i++) {
        let days = today.clone().startOf('year').week(week).startOf('week').add(i, 'day');
        
        var newData = {
          date: days,
          className: '',
          count: 0
        }

        newDateList.push(newData);
      } 
    }
    console.log(newDateList);
    
    for (let i = 0; i < newDateList.length; i++) {
      console.log(newDateList[i].date.format('YYYY.MM.DD'));
    }

    setDateList(newDateList);
  }, [isClicked, today, firstWeek, lastWeek]) 
  
  // // 스케줄 정보 시간 정리
  // useEffect(() => {
  //   var tmp = scheduleData.slice();
  //   var newScheduleData = [];

  //   for (var i = 0; i < tmp.length; i++) {
  //     if (tmp[i].term !== -1) {
  //       var endPoint = moment(tmp[i].classDate.split('T')[0]);
  //       while (tmp[i].endDate.split('T')[0] > endPoint.format('YYYY-MM-DD')) {
          
  //         if (endPoint.diff(date, 'days') >= 30) break;

  //         var colSpan = 1;

  //         // 세로부분 합칠 크기 지정 ( 시간에 맞게 계산 )
  //         // colSpan = parseInt(tmp[i].classTime.split('-')[1].split(':')[0]) - parseInt(tmp[i].classTime.split('-')[0].split(':')[0]);
         
  //         var newData = {
  //           classId: tmp[i].classId,
  //           classDate: endPoint.format('YYYY.MM.DD'),
  //           className: tmp[i].className,
  //           classTime: tmp[i].classTime,
  //           color: tmp[i].color,
  //           endDate: tmp[i].endDate,
  //           lessonId: tmp[i].lessonId,
  //           scheduleId: tmp[i].scheduleId,
  //           teacherName: tmp[i].teacherName,
  //           term: tmp[i].term,
  //           colSpan: colSpan
  //         }
  //         newScheduleData.push(newData);
  //         endPoint = endPoint.clone().add(7 * tmp[i].term, 'day');
  //       }
  //     }
  //   }

  //   setUseScheduleData(newScheduleData);
  // }, [scheduleData])


  useEffect(() => {
    var newDateList = lodash.cloneDeep(dateList);

    for (var i = 0; i < useScheduleData.length; i++) {
      for (var j = 0; j < newDateList.length; j++) {
        if (useScheduleData[i].classDate === newDateList[j].date.format('YYYY.MM.DD')) {
          // console.log(newDateList[j]);
          if (newDateList[j].className === '') {
            newDateList[j].className = useScheduleData[i].className;
          }
          else {
            newDateList[j].count += 1;
          }
        }
      }
    }

    setUseDateList(newDateList);
  }, [useScheduleData, dateList])

  return (
    <TopLevelWrapper ref={TopLevelRef}>
      <MonthlyColumn />
      <DateWrapper>
        {
          useDateList.map((data, index) => (
            data.date.format('MM') === today.format('MM') ?
              <Date key={index} notHover={false}>
                <TopComponent>
                  {data.date.format('D 일')}
                </TopComponent>
                <BottomComponent>
                  <TextWrapper>
                    {
                      data.className === '' ? 
                        <ClassName>수업 없음</ClassName>
                      :
                        <ClassName>{data.className}</ClassName>  
                    }
                    { data.className !== '' && <Count isView={data.count > 0}>외 {data.count}개 수업</Count> }
                  </TextWrapper>
                </BottomComponent>
              </Date>
              :
              <Date key={index} notHover={true}>
                <TopComponent>
                  {data.date.format('D 일')}
                </TopComponent>
                <BottomComponent>
                  <TextWrapper>
                    {
                      data.className === '' ?
                        <ClassName>수업 없음</ClassName>
                        :
                        <ClassName>{data.className}</ClassName>
                    }
                    { data.className !== '' && <Count isView={data.count > 0}>외 {data.count}개 수업</Count> }
                  </TextWrapper>
                </BottomComponent>
              </Date>
          ))
        }
      </DateWrapper>
      {/* <MonthlyColumn/>
      <DateWrapper>
          {
            useDateList.map((data, index) => (
              data.date.format('MM') === today.format('MM') ?
                <Date key={index}>
                  <TopComponent>
                    {data.date.format('D 일')}
                  </TopComponent>
                  <BottomComponent>
                    <TextWrapper>
                      <ClassName>{data.className}</ClassName>
                      <Count isView={data.count > 0}>외 {data.count}개 수업</Count>
                    </TextWrapper>
                  </BottomComponent>
                </Date>
                :
                null
            ))
          }
        </DateWrapper> */}
    </TopLevelWrapper>
  )
})

export default MonthlyClass;

const TopLevelWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 1020px;
  // height: 449px;
  // height: 505px;

  padding-top: 28px;
  padding-bottom: 28px;

  margin-top: 138px;
  margin-bottom: 96px;

  opacity: 0;
  z-index: -3;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  background-color: #FFFFFF;

  transition: all 0.3s ease-in-out;
`;

const DateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  width: 964px;

  gap: 9px;
  // margin-left: 8px;
`;

const Date = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 128px;
  height: 71px;

  border: 1px solid #ECECEC;
  border-radius: 15px;

  background-color: #FFFFFF;
  
  font-family: Poppins-Medium;
  font-size: 14px;

  color: #595959;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: ${props => props.notHover ? `default` : `pointer`};
    border: ${props => props.notHover ? `1px solid #ECECEC` : `1px solid #FF8B48`};
    // background-color: #FF7322;
    // color: #FFFFFF;

    // font-fmaily: Poppins-Bold;
    // font-size: 14px;
  }
`;

const TopComponent = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 112px;
  height: 20px;

  padding-left: 14px;

  border: 1px solid #ECECEC;
  border-radius: 15px 15px 0px 0px;
  background-color: #ECECEC;

  font-family: NotoSansKR-Medium;
  font-size: 10px;

  color: #777777;
`;

const BottomComponent = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: 112px;
  height: 51px;

  padding-left: 14px;

  border: 1px solid #ECECEC;
  border-radius: 0px 0px 15px 15px;
  background-color: #FFFFFF;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 103px;
  height: 35px;

  gap: 4px;
`;

const ClassName = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 12px;

  color: #232323;

  margin: 0 0 0 0;

  width: 100%;

  overflow: hidden;
`;

const Count = styled.p`
  // display: ${props => props.isView ? `flex` : `none`};
  font-family: NotoSansKR-Regular;
  font-size: 10px;

  color: #777777;

  margin: 0 0 0 0;
`;

const WithoutHoverDate = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  width: 44px;
  height: 44px;

  border-radius: 44px;

  margin: 5.5px 5.5px 5.5px 5.5px;

  background-color: #FFFFFF;

  font-family: Poppins-Regular;
  font-size: 14px;

  color: #777777;

  :hover {
    cursor: default;
  }
`;