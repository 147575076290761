import { React, useState, forwardRef, useImperativeHandle, useRef } from 'react';
import styled from 'styled-components';

import DownImage from '../../image/down.png';
import UpImage from '../../image/up.png';

const SmallCountInput = forwardRef((props, ref) => {
  const { unit } = props;
  const [dateVal, setDateVal] = useState(0); 
	const [changed, setChanged] = useState(false);

  const InputRef = useRef();

  useImperativeHandle(ref, () => ({
    setValue: (val) => {
      setDateVal(val);
    },
		getValue: () => {
			return dateVal;
		}
	}));

	function handleChange(e) {
    var regex = /[^0-9]/g;				// 숫자가 아닌 문자열을 매칭하는 정규식
    var result = e.target.value.replace(regex, "");	// 원래 문자열에서 숫자가 아닌 모든 문자열을 빈 문자로 변경
    
    if (result === "") setDateVal(0);
		else setDateVal(parseInt(result));
		setChanged(true);
	}

	function handleFocus(e) {

	}

  function upButtonClick() {
    setDateVal(dateVal + 1);
  }

  function downButtonClick(){
    if (dateVal > 0) setDateVal(dateVal - 1);
  }

  return (
    <TopLevelWrapper>
      <Input
      	ref={InputRef}
        placeholder={0}
        value={dateVal}
        onChange={handleChange}
        onFocus={handleFocus}/>
      <ButtonWrapper>
        <Button img={UpImage} onClick={upButtonClick}/>
        <Button img={DownImage} onClick={downButtonClick}/>
      </ButtonWrapper>
      <Text>{unit}</Text>
    </TopLevelWrapper>
  )
})

export default SmallCountInput;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
  
  margin-top: 8px;
  margin-right: 8px;

  transition: all 0.3s ease-in-out;
`;

const Input = styled.input`
  display: flex;
  flex-direction: row;

  align-items: center;

  width: 67px;
  height: 30px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background-color: #FFFFFF;

  padding: 0 0 0 8px;
  margin: 0 4px 0 0;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
  margin-right: 8px;
`;

const Button = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 30px;
  height: 30px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background: url(${props => props.img}) no-repeat center;
  background-color: #ECECEC;

  :hover {
    cursor: pointer;
  }
`;

const Text = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #777777;

  margin: 0 0 0 0;
`;