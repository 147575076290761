import { React, useState, useRef, useEffect, useImperativeHandle, forwardRef } from 'react';
import styled from 'styled-components';

// import DefaultLogo from 
import BlackPlusIcon from '../../image/BlackPlus.svg';
import CheckImage from '../../image/CheckImage.png';

const AvailableReservePartnerLessonSelectBox = forwardRef((props, ref) => {
  const { storeId, storeLogo, membershipId, /*value, setValue,*/ lessonData, availablePartnerGymLessonData,/* lessonId, setLessonId*/ } = props;
  // const history = useHistory();

	const [isClicked, setIsClicked] = useState(false);
  // const [options, setOptions] = useState([]);
  const [options, setOptions] = useState(lessonData);
  const [value, setValue] = useState([]);

  const TopLevelRef = useRef();
	const SelectRef = useRef();

  useImperativeHandle(ref, () => ({
    getValue: () => {
      return value;
    }
  }))

  useEffect(() => {
    let newData = [];

    for (let i = 0; i < lessonData.length; i++) {
      if (lessonData[i].storeId === storeId) newData.push(lessonData[i]);
    }
    // console.log(storeId);
    setOptions(newData);
  }, [storeId, lessonData])

	useEffect(() => {
		if (options.length === 0 || availablePartnerGymLessonData === undefined) return;
    
    // let lessonIdList = lessonId.split(',');
    // let lessonIdList = [];
    
    let lessonList = availablePartnerGymLessonData.slice();

    let newValue = [];
    
    for (let i = 0; i < lessonList.length; i++) {
      // 해당 업체 데이터만 체크 //
      if (lessonList[i].followStoreId === storeId) {
        for (let j = 0; j < options.length; j++) {
          if (lessonList[i].followLessonId === options[j].id) {
            newValue.push(options[j]);
            break;
          }
        }
      }
    }

    // for (let i = 0; i < lessonIdList.length; i++) {
    //   if (options[options.findIndex(j => j.id === parseInt(lessonIdList[i]))] !== undefined) {
    //     // console.log(">??>");
    //     newValue.push(options[options.findIndex(j => j.id === parseInt(lessonIdList[i]))]);
    //   }
    // }

    // for (let i = 0; i < lessonIdList.length; i++) {
    //   if (options[options.findIndex(j => j.id === parseInt(lessonIdList[i]))] !== undefined) {
    //     newValue.push(options[options.findIndex(j => j.id === parseInt(lessonIdList[i]))]);
    //   }
    // }
    // console.log(newValue);
    setValue(newValue);
	}, [options, /*membershipId,*/ availablePartnerGymLessonData, storeId])

  // 컴포넌트 밖을 클릭하면 셀렉트 창 닫기
  useEffect(() => {
    document.addEventListener("mousedown", onClickOutSide);

    return () => {
      document.removeEventListener("mousedown", onClickOutSide);
    }
  }, [])

  // 컴포넌트 밖을 클릭하면 셀렉트 창 닫기
  function onClickOutSide(e) {
    if (TopLevelRef.current !== e.target && !TopLevelRef.current.contains(e.target)) {
      SelectRef.current.style.border = '0px';
      SelectRef.current.style.height = `0px`;
      SelectRef.current.style.overflow = 'hidden';
      setIsClicked(false);
    }
  }

	function onClick() {
		if (isClicked) {
			SelectRef.current.style.border = '0px';
			SelectRef.current.style.height = `0px`;
			SelectRef.current.style.overflow = 'hidden';
			setIsClicked(false);
		}
		else {
			SelectRef.current.style.border = '1px solid #FF8B48';
			SelectRef.current.style.height = (32 * (options.length + 1 )) > 130 ? `130px` : ((32 * (options.length + 1)) + 'px');
			SelectRef.current.style.overflowY = 'scroll';
			SelectRef.current.style.overflowX = 'none';
			setIsClicked(true);
		}
	}

  // 코치 클릭시
	function optionalClick(index) {
    // 입력시 중복확인 => 존재하면 삭제, 존재하지 않으면 추가
    let newDefaultVal = value.slice();

    if (newDefaultVal.includes(options[index])) {
      let deletedIndex = 0;

      newDefaultVal.filter(function(item, idx) {
        // 두개가 같으면
        if (options[index] === item) {
          deletedIndex = idx;
          return;
        }
      })

      newDefaultVal.splice(deletedIndex, 1);
      setValue(newDefaultVal);
    }
    else {
      newDefaultVal.push(options[index]);
      setValue(newDefaultVal);
      // setLessonId(-1);
    }
	}

	return (
    <TopLevelWrapper ref={TopLevelRef}>
      <DefaultView 
        isClicked={isClicked}
        onClick={onClick}>
          <Logo src={storeLogo}/>
        <OptionWrapper>
          <ColorBoxWrapper>
            {
              value.map((data, index) => (
                <ColorMapper key={index}>
                  { index < 3 && <ColorBox color={data.color}>{index === 0 || index === 1 ? data.name : `+` + (value.length - 2)}</ColorBox> }
                </ColorMapper>
              ))
            }
          </ColorBoxWrapper>
        </OptionWrapper>
        <ArrowImage src={BlackPlusIcon}/>
      </DefaultView>
      <SelectBox
        ref={SelectRef}
        isClicked={isClicked}>
        {
          options.map((data, index) =>
            <OptionWrapper key={index} onClick={() => optionalClick(index)}>
              <Option isClicked={value.includes(data)}>{data.name}</Option>
              { value.includes(data) && <Check src={CheckImage}/> }
            </OptionWrapper>
          )
        }
      </SelectBox>
    </TopLevelWrapper>
  )
})

export default AvailableReservePartnerLessonSelectBox;

const TopLevelWrapper = styled.div`
	display: flex;
	flex-direction: column;
	
	// width: 344px;
  width: 100%;
	height: 32px;
`;

const DefaultView = styled.div`
  display: flex;
	flex-direction: row;

  justify-content: center;
  align-items: center;

  // padding: 0 8px 0 8px;
  padding: 0 8px 0 0;

	// width: 328px;
  // width: calc(100% - 18px);
  width: calc(100% - 10px);
	height: 30px;
  
  z-index: 3;

	border: 1px solid #CBCBCB;
	border-radius: 8px;

  gap: 12px;

  overflow: hidden;

	background-color: rgba(255, 255, 255, 1);
  // background-color: red;
  
  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const Logo = styled.img`
  width: 32px;
  height: 32px;

  object-fit: contain;
`;

const ArrowImage = styled.img`
  width: 24px;
  height: 24px;

  transition: all 0.3s ease-in-out;

  // transform: ${props => props.isClicked ? `rotateZ(-180deg)` : `rotateZ(0deg)`};

  :hover {
    cursor: pointer;
  }
`;

const Check = styled.img`
  width: 12px;
  height: 9px;
`;

const SelectBox = styled.div`
	position: absolute;
	display: flex;
	flex-direction: column;

	justify-content: flex-start;
	align-items: flex-start;

  margin-top: 40px;

  // width: calc(45.45% - 18px);
  width: calc(92.12% - 18px);
	height: 0px;

  padding: 0 8px 0 8px;

  z-index: 5;

  border: 0px;
	border-radius: 8px;
	overflow: hidden;

	background-color: rgba(255, 255, 255, 1);

  transition: all 0.3s ease-in-out;

	:hover {
		cursor: pointer;
	}

	&::-webkit-scrollbar {
		width: 9px;
	}

	&::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 20px #FF8B48;
		border: 3px solid transparent;
		height: 25px;
		border-radius: 200px;
	}

	&::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px transparent;
		border: 4px solid transparent;
		background-color: transparent;
	}

	&::-webkit-scrollbar-track-piece {
		background-color: transparent;
	}
`;

const OptionWrapper = styled.div`
	display: flex;
	flex-direction: row;
  
  justify-content: space-between;
  align-items: center;

	width: 100%;

	:hover {
		cursor: pointer;
	}
`;

const Option = styled.p`
	font-family: NotoSansKR-Regular;
	font-size: 14px;

  color: ${props => props.isClicked ? `#FF8B48` : `#555555`};

  margin: 6px 0 6px 0;
  padding: 0 0 0 0;
`;

const ColorBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
`;

const ColorMapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const ColorBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: flex-start;

  padding: 0 8px 0 8px;

  height: 24px;

  border-radius: 6px;
  // background-color: #FF8B48;
  background-color: ${props => props.color};

	font-family: NotoSansKR-Regular;
	font-size: 14px;

  color: #FFFFFF;
`;