import { React, useState, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';

import ColorCheckImg from '../../image/ColorCheck.png';

export default function NewColorChoice(props) {
  const { selectedColor, setSelectedColor } = props;

  const colorList = [
    // {
    //   color: `#F4A1A9`
    // },
    {
      color: `#DC3E4D`
    },
    {
      color: `#FF8B48`
    },
    {
      color: `#FFCF71`
    },
    {
      color: `#F7A9E1`
    },
    {
      color: `#F99F9F`
    },
    {
      color: `#F17DB4`
    },
    {
      color: `#9A9CE9`
    },
    {
      color: `#80C0FB`
    },
    {
      color: `#33C8FF`
    },
    {
      color: `#5BD98A`
    },
    {
      color: `#0BB68C`
    }
  ];

  return (
    <TopLevelWrapper>
      <ColorWrapper>
        {
          colorList.map((data, index) =>
            <Color
              key={index}
              color={data.color}
              img={data.color === selectedColor && ColorCheckImg}
              onClick={() => setSelectedColor(data.color)} />)
        }
      </ColorWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const ColorWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;

  background-color: #FFFFFF;
`;

const Color = styled.div`
  width: 32px;
  height: 46px;

  border-radius: 6px;
  background: url(${props => props.img}) no-repeat center center;
  background-color: ${props => props.color};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;