import { React, useRef, useState, useEffect } from "react";
import styled, { keyframes }  from "styled-components";
import { useHistory } from 'react-router-dom';

import DayCountInput from '../../../../../Component/MemberComponent/MemberDetail/DayCountInput.js';
import MemberShipSearchInput from '../../../../../Component/MemberComponent/MemberDetail/MemberSearchInput.js';

import FetchModule from '../../../../Share/Network/FetchModule.js';
import DayReasonInput from '../../../../../Component/MemberComponent/MemberDetail/DayReasonInput.js';
import CountInput from '../../../../../Component/MemberComponent/MemberDetail/CountInput.js';
import moment from "moment";


export default function MemberShipExtendModifyModal(props) {
  const { data, onClickExit } = props;

  const history = useHistory();

  const [reason, setReason] = useState('');
  const [extendsDays, setExtendsDays] = useState(0);
  const [extendsCount, setExtendsCount] = useState(0);

  // Scroll Fixed //
  useEffect(() => {
    document.body.style= `overflow: hidden`;
    return () => document.body.style = `overflow: auto`
  }, [])

  // Default Data Setting //
  useEffect(() => {
    setExtendsDays(moment(data.endDate).diff(moment(data.startDate), 'days') + 1);
    setExtendsCount(data.plusTotalMax);
    setReason(data.reason);
  }, [data])

  // 저장하기 버튼 클릭시 //
  function onClickSave() {
    var requestData = {
      extendId: data.extendId,
      usageId: data.usageId,
      reason: reason,
      days: extendsDays,
      plusTotalMax: extendsCount
    }

    // console.log(requestData);
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postData('membership/extends/modify', 'POST', requestData);

        if (responseData.status === 200) {
          alert('회원권 연장내역 수정이 적용되었습니다!');
          onClickExit();
        }
        else if (responseData.status === 204) {
          alert('회원권 정보가 존재하지 않습니다.');
        }
        else if (responseData.status === 401) {
          alert('로그인 후 다시시도하세요');
          history.push('/');
        }
      }
    )();
  }

  return (
    <TopLevelWrapper>
      <ModalView>
        <InnerWrapper>
          <TitleWrapper>
            <Title>회원권 연장</Title>
            <SubTitleWrapper>
              <SubTitle>{data.memberShipName}</SubTitle>
            </SubTitleWrapper>
          </TitleWrapper>
          <ViewWrapper>
            <DateCountWrapper>
              <DayCountInput value={extendsDays} setValue={setExtendsDays} />
              <CountInput value={extendsCount} setValue={setExtendsCount} />
              <DayReasonInput value={reason} setValue={setReason} />
            </DateCountWrapper>
          </ViewWrapper>
          <ButtonWrapper>
            <ExitButton onClick={onClickExit}>취소</ExitButton>
            <SaveButton onClick={onClickSave}>저장하기</SaveButton>
          </ButtonWrapper>
        </InnerWrapper>
      </ModalView>
    </TopLevelWrapper>
  )
}

const startAnimation = keyframes`
	0% { opacity: 0; }
	50% { opacity: 0.5; }
	100% { opacity: 1; }
`;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  justify-content: center;
  align-items: center;

  z-index: 1000;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.2);
	
	animation-name: ${startAnimation};
	animation-duration: 0.5s;
	animation-timing-function: ease-out;
`;

const ModalView = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 300px;
  // height: 354px;
  height: 379px;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(0, 0, 0, 0.1);

  background-color: #FFFFFF;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 79.33%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-end;

  gap: 8px;

  margin-bottom: 27px;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: 0 0 0 0;
`;

const SubTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  max-width: 152px;
  width: 152px;
  height: 20px;

  overflow: hidden;
`;

const SubTitle = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 12px;

  color: #777777;

  margin: 0 0 0 0;
`;

const ViewWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  gap: 36px;
`;

const DateCountWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  // gap: 30px;
  gap: 23px;

  // margin: 0 0 0 28px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  width: 100%;

  gap: 16px;

  margin-top: 24px;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 72px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;
  
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #777777;
    color: #777777;
  }
`;

const SaveButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 15px;
  background-color: #5BD98A;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;