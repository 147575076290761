import { React, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';

// import ReplyDeleteModal from './ReplyDeleteModal';

import RedTrash from '../../image/RedTrash.png';
import DefaultLogo from '../../image/DefaultLogo.png';
import FetchModule from '../../Model/Share/Network/FetchModule';
import RankingCommentDeleteModal from '../../Model/Individual/NoteModel/RankingCommentDeleteModal';

export default function RankingReplyBox(props) {
  const { data, isLast, setComments,  onClickReplyViewButton } = props;

  const history = useHistory();

  const [deleteClick, setDeleteClick] = useState(false);

  const TopLevelRef = useRef();
  const RightRef = useRef();

  // 답글 삭제 클릭시 //
  function replyDelete() {
    
    var requestData = {
      replyId: data.id
    } 
    
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataOfExercise('reply/delete_zone', 'POST', requestData);
        
        if (responseData.status === 200) {
          setComments(responseData.data);
          setDeleteClick(false);
        }
        else if (responseData.status === 201) {
          alert("이미 삭제된 답글입니다");
          setDeleteClick(false);
        }
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }

  return (
    <TopLevelWrapper ref={TopLevelRef} isLast={isLast}>
      { deleteClick && <RankingCommentDeleteModal isComment={false} deleteFn={replyDelete} exitClickFn={() => setDeleteClick(false)} />}
      <LeftWrapper>
        <Profile  src={data.userProfile === '' ? DefaultLogo : data.userProfile}/>
      </LeftWrapper>
      <MemberInfoWrapper ref={RightRef}>
        <NameWrapper>
          <NameInfoWrapper>
            <Name>{data.userName}</Name>
            <Info>{data.storeId !== 0 ? '코치' : '회원'}</Info>
          </NameInfoWrapper>
          <ReplyDeleteButton onClick={() => setDeleteClick(true)}>
            <TrashImage src={RedTrash}/>
          </ReplyDeleteButton>
        </NameWrapper>
        <TextWrapper>
          <Text>{data.text}</Text>
        </TextWrapper>
        <BottomWrapper>
          <Date>{moment(data.createdDate).format('YYYY.MM.DD HH:mm')}</Date>
          {/* <ReplyButton onClick={onClickReplyViewButton}>답글 달기</ReplyButton> */}
          {/* <ReplyDeleteButton onClick={() => setDeleteClick(true)}>답글 삭제</ReplyDeleteButton> */}
        </BottomWrapper>
      </MemberInfoWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  // width: 964px;
  // padding: 0 0 16px 0;
  padding: 0 0 0 26px;
  width: calc(94% - 26px);

  gap: 10px;
  // border-bottom: 1px solid #DFDFDF;
  // border-left: ${props => props.isLast ? `2px solid #FFFFFF` : `2px solid #DFDFDF`};
`;

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 24px;
  // background-color: red;
`;

const LineWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // width: 100%;
  height: 100%;

  // background-color: red;
`;


const Line = styled.div`
  display: flex;

  width: 18px;
  height: 28px;

  border-left: 2px solid #DFDFDF;
  border-bottom: 2px solid #DFDFDF;
  border-radius: 0 0 0 8px;
`;

const MemberInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;
  
  margin-top: 12px;

  width: calc(100% - 24px);
`;

const Profile = styled.img`
  width: 24px;
  height: 24px;

  border-radius: 24px;

  margin-top: 12px;
`;

const NameWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const NameInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
`;

const Name = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #828282;

  margin: 0 0 0 0;
`;

const Info = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #828282;

  margin: 0 0 0 0;
`;

const State = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 12px;

  color: #FF8B48;

  margin: 0 0 0 0;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 12px;
`;

const Text = styled.pre`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #828282;

  margin: 0 0 0 0;
`;

const BottomWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 16px;
  margin-top: 8px;
`;

const Date = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 10px;

  color: #828282;

  margin: 0 0 0 0;
`;

const ReplyButton = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #777777;

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;

const TrashImage = styled.img`
  width: 17px;
  height: 17px;
`;

const ReplyDeleteButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  :hover {
    cursor: pointer;
  }
`;
