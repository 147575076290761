import { React, useEffect, useState } from 'react';
import styled from 'styled-components';
import MyMemberPaymentColumn from './MyMemberPaymentColumn';
import MyMemberPaymentInfo from './MyMemberPaymentInfo';

import SortingModule from '../../Share/Normal/SortingModule';

function MyZoneMemberPaymentView(props) {
  const { salesList, onClickRefundButton } = props;

  const [dataList, setDataList] = useState(new Array);
  const [pageMax, setPageMax] = useState(1);
  const [nowPage, setNowPage] = useState(0);

  const [sortingData, setSortingData] = useState(new Array);

  const [approvalSort, setApprovalSort] = useState('normal');
  const [approvalDateSort, setApprovalDateSort] = useState('normal');
  
  // 회원 정보 소팅
  useEffect(() => {
    var newData = salesList.slice();
    
    if (approvalSort === 'asc') newData = SortingModule.dataSort(newData, 'status', 'asc', 'status');
    else if (approvalSort === 'desc') newData = SortingModule.dataSort(newData, 'status', 'desc', 'status');
    else if (approvalDateSort === 'asc') newData = SortingModule.dataSort(newData, 'approvalDate', 'asc', 'approvalDate');
    else if (approvalDateSort === 'desc') newData = SortingModule.dataSort(newData, 'approvalDate', 'desc', 'approvalDate');
    
    setSortingData(newData);
  }, [salesList, approvalSort, approvalDateSort])

  useEffect(() => {
    setPageMax(Math.ceil(sortingData.length / 10) === 0 ? 1 : Math.ceil(sortingData.length / 10));

    setDataList(sortingData.slice(nowPage * 10, (nowPage * 10) + 9));
  }, [sortingData, nowPage])

  function onClickPrevButton() {
    if (nowPage !== 0) setNowPage(nowPage - 1);
  }

  function onClickNextButton() {
    if (nowPage + 1 !== pageMax) {
      setNowPage(nowPage + 1);
    }
  }

  // 컬럼 정렬버튼 클릭시
  function sortButtonFn(division, sort) {
    if (division === 'status') {
      setApprovalSort(sort);
      setApprovalDateSort('normal');
    }
    else if (division === 'approvalDate') {
      setApprovalSort('normal');
      setApprovalDateSort(sort);
    }
  }

  return (
    <TopLevelWrapper>
      <LayerWrapper>
        <TextWrapper>
          <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#232323'>회원 이용권 결제정보</Text>
          <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#777777'>링크존을 이용한 회원들의 결제 정보를 확인할 수 있어요</Text>
        </TextWrapper>
        <MyMemberPaymentColumn sortButtonFn={sortButtonFn} />
        {
          dataList.map((data, index) => (
            <MyMemberPaymentInfo key={index} data={data} onClickRefundButton={onClickRefundButton}/> 
          ))
        }
        <Pagination>
          <Button isFinished={nowPage + 1 === 1} onClick={onClickPrevButton}>이전</Button>
          <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#232323'>{nowPage + 1}/{pageMax}</Text>
          <Button isFinished={nowPage + 1 === pageMax} onClick={onClickNextButton}>다음</Button>
        </Pagination>
      </LayerWrapper>
    </TopLevelWrapper>
  )
}

export default MyZoneMemberPaymentView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100%;

  margin-top: 51px;
  margin-bottom: 242px;
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  justify-content: flex-start;
  align-items: flex-start;
  
  width: 1191px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  justify-content: flex-start;
  align-items: flex-start;
  
  margin-left: 28px;
  
  gap: 4px;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;
  
  margin: 0 0 0 0;
  
  color: ${props => props.color};
  
  :hover {
    cursor: default;
  }
`;

const Pagination = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 44px;
  gap: 12px;

  width: 100%;
`;

const Button = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  margin: 0 0 0 0;

  color: ${props => props.isFinished ? `#A7A7A7` : `#232323`};

  :hover {
    cursor: pointer;
  }
`;