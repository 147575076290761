import { useState, useImperativeHandle, forwardRef, useRef, React, useEffect } from 'react';
import styled from 'styled-components';

const InfoInput = forwardRef((props, ref) => {
	const { column, defaultVal, setVal, className, type, width} = props;
	const [value, setValue] = useState(defaultVal);
	const [changed, setChanged] = useState(false);

	const inputRef = useRef();

	useImperativeHandle(ref, () => ({
		setValue: (val) => {
			setValue(val);
			setChanged(true);
		},
		getValue: () => {
			if (changed)
				return value;
			else
				return "";
		}
	}));

	function handleClick(e) {

	}

	function handleChange(e) {
		setValue(e.target.value);
		setVal(e.target.value);
		setChanged(true);
	}

	function handleFocus(e) {
		if (changed) {
			// do nothing
		} else {
			setValue("");
		}
	}

	function handleBlur(e) {
		if (value === "") {
			// setDefaultPlaceholder(placeholder);
			// inputRef.current.style.borderColor = '#FF3131';
		} 
		else {
			// inputRef.current.style.borderColor = '#E8E8E8';
		}
	}

  return (
    <TopLevelWrapper>
      <Column>{column}</Column>
      <Input
        ref={inputRef}
        type={type}
        value={value}
				width={width}
        className={`${className} ${changed ? "changed" : ""}`}
        onClick={handleClick}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur} />
    </TopLevelWrapper>
  )
});

export default InfoInput;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;
`;

const Column = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #595959;

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  } 
`;

const Input = styled.input`
	font-family: NotoSansKR-Regular;
	font-size: 14px;
	
	margin-top: 8px;
	padding: 0 8px 0 8px;

	outline: none;
	border: 1px solid #E8E8E8;
	border-radius: 8px;
	color: #000000;

	width: ${props => props.width}px;
	height: 30px;

	::placeholder {
		color: #CBCBCB;
	}
`;