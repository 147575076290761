import React from "react";
import styled, { keyframes } from "styled-components";

import Lottie from 'lottie-react-web';

import LoadingAnimation from '../../../Animation/LoadingAnimation.json';

export default function NoticeUploadView() {

  return (
    <TopLevelWrapper>
      <LottieWrapper>
        <Lottie options={{ animationData: LoadingAnimation }} />
      </LottieWrapper>
    </TopLevelWrapper>
  );
}

const startAnimation = keyframes`
	0% { opacity: 0; }
	50% { opacity: 0.5; }
	100% { opacity: 1; }
`;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  justify-content: center;
  align-items: center;

  z-index: 1000;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.2);
	
	animation-name: ${startAnimation};
	animation-duration: 0.5s;
	animation-timing-function: ease-out;
`;

const LottieWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 60px;
  height: 60px;
`;