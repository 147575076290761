import { React, useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import lodash from 'lodash';
import Lottie from 'lottie-react-web';

// import TimeDivisionLine from './TimeDivisionLine';
import TimeDivisionLine from '../../../Component/MainComponent/TimeDivisionLine.js';
import NewTimeDivisionLine from '../../../Component/MainComponent/NewTimeDivisionLine.js';
import WeeklyColumn from './WeeklyColumn';

import EmptySign from '../../../image/EmptySign.png';
import PlusPath from '../../../image/ClassAddPlus.png';
import WhiteTrashIcon from '../../../image/WhiteTrashIcon.png';

import LoadingAnimation from '../../../Animation/LoadingAnimation.json';

const WeeklyClass = forwardRef((props, ref) => {
  const {
    today,
    isLoading,
    setIsLoading,
    scheduleData,
    breakTimeData,
    setClickDate,
    onClickDeleteButton,
    onClickScheduleDetail,
    onClickPlusColumn
  } = props;


  const conversionRate = 0.5;

  const [useScheduleData, setUseScheduleData] = useState([]);
  const [totalData, setTotalData] = useState([]);
  const [startDay, setStartDay] = useState(moment());
  const [minHeight, setMinHeight] = useState(644)

  const TopLevelRef = useRef();
  const ColumnRef = useRef();
  const ComponentRef = useRef([]);
  const InnerComponentRef = useRef([]);
  const DeleteTotalRef = useRef([]);
  const DeleteBackgroundRef = useRef([]);
  const DeleteButtonRef = useRef([]);
  const FirstButtonWrapperRef = useRef([]);
  const FirstButtonRef = useRef([]);
  const ButtonWrapperRef = useRef([]);
  const ButtonRef = useRef([]);

  useImperativeHandle(ref, () => ({
    visible: () => {
      TopLevelRef.current.style.opacity = 1;
      TopLevelRef.current.style.zIndex = 1;
    },
    unVisible: () => {
      TopLevelRef.current.style.opacity = 0;
      TopLevelRef.current.style.zIndex = -3;
    }
  }))

  // 주간 처음 날짜 빼오기 //
  useEffect(() => {
    for (let i = 0; i < 7; i++) {
      if (today.clone().subtract(i, 'day').format('dd') === 'Su') {
        setStartDay(today.clone().subtract(i, 'day'));
        break;
      }
    }
  }, [today])

  // Schedule Data를 사용 가능한 Data로 가공
  useEffect(() => {
    let tmp = scheduleData.slice();
    let newScheduleData = [];

    for (let i = 0; i < tmp.length; i++) {

      let endPoint = moment(tmp[i].classDate.split('T')[0]);

      // while (tmp[i].endDate.split('T')[0] > endPoint.format('YYYY-MM-DD')) {
      while (endPoint.isBefore(moment(tmp[i].endDate))) {
        // console.log("endPoint : " + endPoint.format('YYYY-MM-DD'));
        // console.log("today : " + date.format("YYYY-MM-DD"));
        // console.log(endPoint.diff(date, 'days') >= 30);

        // if (endPoint.diff(startDay, 'days') >= 6) break;
        if (endPoint.diff(startDay, 'days') >= 7) break;


        if (tmp[i].repeatWeek.includes(getWeekNumber(endPoint.format('YYYY-MM-DD')).toString()) || tmp[i].repeatWeek.includes('0')) {

          if (tmp[i].applyWeek.includes(endPoint.format('ddd')) || tmp[i].applyWeek === 'All') {
            // let colSpan = 1;
            // let rowSpan = 1;

            // 세로부분 합칠 크기 지정 ( 시간에 맞게 계산 )
            // colSpan = parseInt(tmp[i].classTime.split('-')[1].split(':')[0]) - parseInt(tmp[i].classTime.split('-')[0].split(':')[0]);

            let newData = {
              classId: tmp[i].classId,
              classDate: endPoint.format('YYYY.MM.DD'),
              className: tmp[i].className,
              classTime: tmp[i].classTime,
              color: tmp[i].color,
              endDate: tmp[i].endDate,
              lessonId: tmp[i].lessonId,
              scheduleId: tmp[i].scheduleId,
              teacherName: tmp[i].teacherName,
              applyWeek: tmp[i].applyWeek,
              repeatWeek: tmp[i].repeatWeek,
              // rowSpan: rowSpan,
              // colSpan: colSpan,
              maxBookedPeople: tmp[i].maxBookedPeople,
              deduction: tmp[i].deduction,
            }
            newScheduleData.push(newData);
          }
        }

        endPoint = endPoint.clone().add(1, 'day');
      }
    }

    newScheduleData.sort(function (a, b) {
      return a.classDate < b.classDate ? -1 : a.classDate > b.classDate ? 1 : 0;
    })

    let newData = [];

    for (let i = 0; i < newScheduleData.length; i++) {
      for (let j = 0; j < 7; j++) {
        if (newScheduleData[i].classDate === startDay.clone().add(j, 'day').format('YYYY.MM.DD')) {
          newData.push(newScheduleData[i]);
        }
      }
    }

    // breaktime 데이터 가공
    for (let z = 0; z < breakTimeData.length; z++) {
      for (let y = 0; y < 7; y++) {
        // if (startDay.clone().add(y, 'day').format('YYYY-MM-DD') === '2023-09-01') {

        //   console.log('----------');
        //   console.log(startDay.clone().add(y, 'day').format('YYYY.MM.DD'));
        //   // console.log(breakTimeData[z].breakTimeId);
        //   // console.log(breakTimeData[z].classDate);
        //   // console.log(breakTimeData[z].endDate);
        //   console.log(breakTimeData[z])
        //   console.log(moment(breakTimeData[z].classDate.split('T')[0] + 'T' + breakTimeData[z].startTime).format('YYYY-MM-DD HH:mm:ss'));
        //   console.log(startDay.clone().add(y, 'day').format('YYYY-MM-DDTHH:mm:ss'))
        //   console.log(moment(breakTimeData[z].classDate.split('T')[0]).format('YYYY.MM.DD') === (startDay.clone().add(y, 'day').format('YYYY.MM.DD')));
        //   console.log('\n');
        // }
        if (moment(breakTimeData[z].classDate).isBefore(moment(startDay.clone().add(y, 'day').format('YYYY-MM-DDT') + breakTimeData[z].startTime)) && moment(breakTimeData[z].endDate).isAfter(startDay.clone().add(y, 'day'))) {
        // if (moment(breakTimeData[z].classDate.split('T')[0]).format('YYYY.MM.DD') === (startDay.clone().add(y, 'day').format('YYYY.MM.DD')) && moment(breakTimeData[z].endDate).isAfter(startDay.clone().add(y, 'day'))) {
          if (breakTimeData[z].repeatWeek.includes(getWeekNumber(startDay.clone().add(y, 'day').format('YYYY-MM-DD'))) || breakTimeData[z].repeatWeek === '0') {  
            if (breakTimeData[z].applyWeek.includes(startDay.clone().add(y, 'day').format('ddd')) || breakTimeData[z].applyWeek === 'All') {
              let breakData = {
                breakTimeId: breakTimeData[z].breakTimeId,
                classId: -1,
                lessonId: -1,
                classDate: startDay.clone().add(y, 'day').format('YYYY.MM.DD'),
                // color: '#ECECEC',
                color: '#BDBDBD',
                classTime: breakTimeData[z].startTime + '-' + breakTimeData[z].endTime,
                className: '브레이크 타임',
                // className: 'Open Gym',
                applyWeek: breakTimeData[z].applyWeek
              }
              // console.log(breakData);
              newData.push(breakData);
            }
          }
        }
      }
    }

    newData.sort(function (a, b) {
      // console.log('-------------');
      // console.log(a.classTime);
      // console.log(b.classTime);
      // console.log(a.classTime < b.classTime ? -1 : a.classTime > b.classTime ? 1 : 0);
      return a.classTime < b.classTime ? -1 : a.classTime > b.classTime ? 1 : 0;
    })

    // TimeDivisionLine에서 사용할 데이터 가공
    for (let i = 0; i < newData.length; i++) {
      tmp = newData[i].classTime.split('-');
      let splitTmp = tmp[0].split(':');
      let Time = '';

      if (parseInt(splitTmp[0]) === 24) {
        newData[i].startTime = (parseInt(splitTmp[0]) - 12) + ':' + splitTmp[1] + ' AM';
        Time = (parseInt(splitTmp[0]) - 12) + 'AM';
      }
      else if (parseInt(splitTmp[0]) > 12) {
        newData[i].startTime = (parseInt(splitTmp[0]) - 12) + ':' + splitTmp[1] + ' PM';
        Time = (parseInt(splitTmp[0]) - 12) + ' PM';
      }
      else if (parseInt(splitTmp[0]) === 12) {
        newData[i].startTime = (parseInt(splitTmp[0])) + ':' + splitTmp[1] + ' PM';
        Time = (parseInt(splitTmp[0])) + ' PM';
      }
      else {
        newData[i].startTime = splitTmp[0] + ':' + splitTmp[1] + ' AM';
        Time = parseInt(splitTmp[0]) + ' AM';
      }

      tmp = newData[i].classTime.split('-');
      splitTmp = tmp[1].split(':');

      if (parseInt(splitTmp[0]) === 24) {
        newData[i].endTime = (parseInt(splitTmp[0]) - 12) + ':' + splitTmp[i] + 'AM';
      }
      else if (parseInt(splitTmp[0]) > 12) {
        newData[i].endTime = (parseInt(splitTmp[0]) - 12) + ':' + splitTmp[1] + ' PM';
        // 끝나는 시간 소팅을 위해 쓰는 변수 //
      }
      else if (parseInt(splitTmp[0]) === 12) {
        newData[i].endTime = (parseInt(splitTmp[0])) + ':' + splitTmp[1] + ' PM';
      }
      else {
        newData[i].endTime = splitTmp[0] + ':' + splitTmp[1] + ' AM';
      }

      newData[i].end = parseFloat(splitTmp[0] + '.' + splitTmp[1]);

      if (i !== 0 && newData[i - 1].startTime.split(':')[0] === newData[i].startTime.split(':')[0]) {
        newData[i] = Object.assign(newData[i], { isNeedNewDivide: false })
        newData[i] = Object.assign(newData[i], { viewTime: Time })
      }
      else {
        newData[i] = Object.assign(newData[i], { isNeedNewDivide: true })
        newData[i] = Object.assign(newData[i], { viewTime: Time })
      }
    }

    let newReserve = [];

    for (let h = 0; h < newData.length; h++) {
      newReserve.push(new Array());
    }
    
    setUseScheduleData(newData);
  }, [scheduleData, breakTimeData, startDay])

  // 주간 시간표 데이터 정리 //
  useEffect(() => {
    let tmpData = lodash.cloneDeep(useScheduleData);
    let newTotalData = [];
    let startTimeList = [];
    
    tmpData.sort(function (a, b) {
      return a.classDate < b.classDate ? -1 : a.classDate > b.classDate ? 1 : 0;
    })

    // for (let i = 0; i < tmpData.length; i++) {
    //   startTimeList.push(tmpData[i].startTime);
    // }

    for (let i = 0; i < tmpData.length; i++) {
      let data = {
        classDate: tmpData[i].classDate,
        startTime: tmpData[i].startTime,
        endTime: tmpData[i].endTime
      }

      startTimeList.push(data);
    }

    // console.log('Prev StartTimeList.size : ' + startTimeList.length);
    // console.log(startTimeList);
    // const set = new Set(startTimeList);

    // let finalStartTime = [...set];

    let finalStartTime = [];

    for (let i = 0; i < startTimeList.length; i++) {
      let isExist = false;

      for (let j = 0; j < finalStartTime.length; j++) {
        if (startTimeList[i].startTime === finalStartTime[j].startTime) {
          isExist = true;
          break;
        }
      }

      if (!isExist) {
        finalStartTime.push(startTimeList[i]);
      }
    }
    // let finalStartTime = [...new Set(startTimeList.map(JSON.stringify))].map(JSON.parse);
    // console.log('After StartTimeList.size : ' + finalStartTime.length);
    // console.log(finalStartTime);

    // finalStartTime.sort(function (a, b) {
    //   let aTime = a.split(' ')[0];
    //   let aState = a.split(' ')[1];

    //   let bTime = b.split(' ')[0];
    //   let bState = b.split(' ')[1];

    //   if (aState === 'AM' && bState === 'PM') return -1;
    //   else if (aState === 'PM' && bState === 'AM') return 1;
    //   else {
    //     let aHour = aTime.split(':')[0];
    //     let aMinute = aTime.split(':')[1];
    //     let aConvertToMinute = 0;

    //     if (aState === 'PM' && parseInt(aHour) === 12) aConvertToMinute = parseInt(aMinute);
    //     else aConvertToMinute = (parseInt(aHour) * 60) + parseInt(aMinute);

    //     let bHour = bTime.split(':')[0];
    //     let bMinute = bTime.split(':')[1];
    //     let bConvertToMinute = 0;

    //     if (bState === 'PM' && parseInt(bHour) === 12) bConvertToMinute = parseInt(bMinute);
    //     else bConvertToMinute = (parseInt(bHour) * 60) + parseInt(bMinute);

    //     if (aConvertToMinute > bConvertToMinute) return 1;
    //     else return -1;
    //   }
    // })

    finalStartTime.sort(function (a, b) {
      let aTime = a.startTime.split(' ')[0];
      let aState = a.startTime.split(' ')[1];

      let bTime = b.startTime.split(' ')[0];
      let bState = b.startTime.split(' ')[1];

      if (aState === 'AM' && bState === 'PM') return -1;
      else if (aState === 'PM' && bState === 'AM') return 1;
      else {
        let aHour = aTime.split(':')[0];
        let aMinute = aTime.split(':')[1];
        let aConvertToMinute = 0;

        if (aState === 'PM' && parseInt(aHour) === 12) aConvertToMinute = parseInt(aMinute);
        else aConvertToMinute = (parseInt(aHour) * 60) + parseInt(aMinute);

        let bHour = bTime.split(':')[0];
        let bMinute = bTime.split(':')[1];
        let bConvertToMinute = 0;

        if (bState === 'PM' && parseInt(bHour) === 12) bConvertToMinute = parseInt(bMinute);
        else bConvertToMinute = (parseInt(bHour) * 60) + parseInt(bMinute);

        if (aConvertToMinute > bConvertToMinute) return 1;
        else return -1;
      }
    })

    // for (let i = 0; i < finalStartTime.length; i++) {
    //   let newDataList = [];

    //   for (let j = 0; j < tmpData.length; j++) {
    //     if (finalStartTime[i] === tmpData[j].startTime) newDataList.push(tmpData[j]);
    //   }

    //   newTotalData.push(newDataList);
    // // }
    // console.log(finalStartTime);
    // console.log(tmpData);
    for (let i = 0; i < finalStartTime.length; i++) {
      // console.log(finalStartTime);
      let newDataList = [];

      // let startTimeNum = parseInt(finalStartTime[i].startTime.split(':')[0]);
      // let endTimeNum = parseInt(finalStartTime[i].endTime.split(':')[0]);

      for (let j = 0; j < tmpData.length; j++) {
        // let tmpStartTimeNum = parseInt(tmpData[j].startTime.split(':')[0]);
        // let tmpEndTimeNum = parseInt(tmpData[j].endTime.split(':')[0]);

        // 시작날짜가 같을경우 //
        if (finalStartTime[i].startTime === tmpData[j].startTime) {
          // newDataList.push(tmpData[j]);

          // 중복검사 //
          let isExist = false;

          for (let h = 0; h < newTotalData.length; h++) {
            let isInnerExist = false;
            
            for (let z = 0; z < newTotalData[h].length; z++) {
              if (newTotalData[h][z].classDate === tmpData[j].classDate
                && newTotalData[h][z].classTime === tmpData[j].classTime
                && newTotalData[h][z].className === tmpData[j].className) {
                  // console.log('???')
                  // console.log('???')
                  isExist = true;
                  isInnerExist = true;
                  break;
                }
            }

            if (isInnerExist) break;
          }

          if (!isExist) {
            newDataList.push(tmpData[j]);
          }
        }
        // // 시작시간과 끝나는시간이 겹치는 친구가 먼저 //
        // else if (startTimeNum <= tmpStartTimeNum && tmpEndTimeNum <= tmpEndTimeNum) {
        //   let isExist = false;

        //   for (let h = 0; h < newDataList.length; h++) {
        //     if (newDataList[h].classDate === tmpData[j].classDate
        //       && newDataList[h].classTime === tmpData[j].classTime
        //       && newDataList[h].className === tmpData[j].className) {
        //         isExist = true;
        //         break;
        //     }

        //   }

        //   if (!isExist) {
        //     let totalExist = false;

        //     for (let h = 0; h < newTotalData.length; h++) {
        //       let isInnerExist = false;
        //       for (let z = 0; z < newTotalData[h].length; z++) {
        //         if (newTotalData[h][z].classDate === tmpData[j].classDate
        //           && newTotalData[h][z].classTime === tmpData[j].classTime
        //           && newTotalData[h][z].className === tmpData[j].className) {
        //             totalExist = true;
        //             isInnerExist = true;
        //             break;
        //           }
        //       }

        //       if (isInnerExist) break;
        //     }

        //     if (!totalExist) newDataList.push(tmpData[j]);
        //   }
        // }
        // 끝나는 시간이 같은 경우 //
        else if (finalStartTime[i].endTime === tmpData[j].endTime) {

          let isExist = false;

          for (let h = 0; h < newDataList.length; h++) {
            if (newDataList[h].classDate === tmpData[j].classDate
              && newDataList[h].classTime === tmpData[j].classTime
              && newDataList[h].className === tmpData[j].className) {
                isExist = true;
                break;
            }

          }

          if (!isExist) {
            let totalExist = false;

            for (let h = 0; h < newTotalData.length; h++) {
              let isInnerExist = false;
              for (let z = 0; z < newTotalData[h].length; z++) {
                if (newTotalData[h][z].classDate === tmpData[j].classDate
                  && newTotalData[h][z].classTime === tmpData[j].classTime
                  && newTotalData[h][z].className === tmpData[j].className) {
                    totalExist = true;
                    isInnerExist = true;
                    break;
                  }
              }

              if (isInnerExist) break;
            }

            if (!totalExist) {
              // console.log(tmpData[j]);
              newDataList.push(tmpData[j]);
            }
          }
        }
      }
      // console.log(newDataList);

      if (newDataList.length !== 0) newTotalData.push(newDataList);
      // newTotalData.push(newDataList);
    }
    // console.log(newTotalData);

    // 겹치는 시간표 처리 //
    for (let i = 0; i < newTotalData.length; i++) {
      let isNeedDivide = false;
      let dateCheckList = [];

      for (let j = 0; j < newTotalData.length; j++) {
        let isExist = false;

        for (let h = 0; h < dateCheckList.length; h++) {
          // 동일 날짜가 겹치는 부분이 있는경우 체크 //
          if (newTotalData[j].classDate === dateCheckList[h]) isExist = true;
        }

        // 동일 날짜가 겹치는 부분이 있는경우 겹치기 처리 //
        if (isExist) {
          isNeedDivide = true;
          break;
        }
        // 없는경우 검사 리스트에 데이터 추가 //
        else dateCheckList.push(newTotalData[j].classDate);
      }

      // 데이터의 크기가 일정 이상이거나 시간대별 동일 날짜가 겹치는 경우가 있을때 //
      if (newTotalData[i].length > 7 || isNeedDivide) {
        // 분해한 데이터 담을 리스트 //
        let tmpTotalData = [];

        // 날짜별 분해 데이터 담을 리스트 //
        let tmpDivideData = [];

        // 최종 분해 후 재 결합한 데이터 //
        let finalTotalData = [];

        // 최종 분해 후 재 결합한 데이터 //
        let finalDivideData = [];

        // 분해할 데이터 Deep Copy //
        let tmpData = lodash.cloneDeep(newTotalData[i]);

        // 수업을 날짜별로 정렬 //
        tmpData.sort((a, b) => new Date(a.classDate) - new Date(b.classDate));

        for (let j = 0; j < tmpData.length; j++) {
          tmpDivideData = [];

          let isExist = false;

          for (let h = 0; h < tmpTotalData.length; h++) {
            if (tmpTotalData[h][0].classDate === tmpData[j].classDate) {
              tmpTotalData[h].push(tmpData[j]);
              isExist = true;
            }
          }

          // 자리를 찾지 못했으면 리스트 뒤에 추가해주기 //
          if (!isExist) {
            tmpDivideData.push(tmpData[j]);
            tmpTotalData.push(tmpDivideData);
          }
        }

        // 일찍 끝나는 순으로 정렬 //
        for (let j = 0; j < tmpTotalData.length; j++) {
          tmpTotalData[j].sort((a, b) => a.end - b.end);
        }

        // index 최대 길이 구하기 //
        let columnMaxLength = 0;

        for (let j = 0; j < tmpTotalData.length; j++) {
          if (tmpTotalData[j].length > columnMaxLength) columnMaxLength = tmpTotalData[j].length;
        }

        for (let j = 0; j < columnMaxLength; j++) {
          finalDivideData = [];

          for (let h = 0; h < tmpTotalData.length; h++) {
            if (tmpTotalData[h].length > j) {
              finalDivideData.push(tmpTotalData[h][j]);
            }
          }

          finalTotalData.push(finalDivideData);
        }

        newTotalData.splice(i, 1);

        for (let j = 0; j < finalTotalData.length; j++) {
          if (j !== 0) {
            for (let h = 0; h < finalTotalData[j].length; h++) {
              finalTotalData[j][h].isNeedNewDivide = false;
            }
          }
          newTotalData.splice(i + j, 0, finalTotalData[j]);
        }
      }
    }
    // 겹치는 시간표 처리 //

    // 남은 칸 수업없음으로 채우기 //
    for (let i = 0; i < newTotalData.length; i++) {
      for (let j = 0; j < 7; j++) {

        // 데이터 앞에 부족한 부분 채우기 //
        for (let h = 0; h < newTotalData[i].length; h++) {
          if (newTotalData[i].length === 7) break;

          let classDate = startDay.clone().add(j, 'day').format('YYYY.MM.DD');
          let newData = {
            classId: -2,
            classDate: classDate,
            className: '수업없음',
            classTime: ' ',
            teacherName: ' ',
            isNeedNewDivide: newTotalData[i][0].isNeedNewDivide,
            viewTime: newTotalData[i][0].viewTime
          }

          if (classDate === newTotalData[i][h].classDate) break;
          else if (classDate < newTotalData[i][h].classDate) {
            newTotalData[i].push(newData);
            break;
          }
        }
      }

      // 데이터 앞의 부족한 부분을 채우고 칸을 다 못채운 경우에만 해당 //
      if (newTotalData[i].length < 7) {
        while (true) {
          if (newTotalData[i].length === 7) break;

          let classDate = startDay.clone().add(newTotalData[i].length, 'day').format('YYYY.MM.DD');
          // console.log(newTotalData[i]);
          let newData = {
            classId: -2,
            classDate: classDate,
            className: '수업없음',
            classTime: ' ',
            teacherName: ' ',
            isNeedNewDivide: newTotalData[i][0].isNeedNewDivide,
            viewTime: newTotalData[i][0].viewTime
          }

          newTotalData[i].push(newData);
        }
      }

      newTotalData[i].sort(function (a, b) {
        return a.classDate < b.classDate ? -1 : a.classDate > b.classDate ? 1 : 0;
      })
    }

    setTotalData(newTotalData);
    // console.log('----------');
    // console.log(newTotalData);
    // console.log(newTotalData);
    setTimeout(() => {
      setIsLoading(false);
    }, 100);

  }, [useScheduleData, startDay])

  useEffect(() => {
    if (!isLoading) {
      setMinHeight(ColumnRef.current.clientHeight);
    }
  }, [isLoading, ColumnRef])

  // 몇주차인지 계산하기 //
  function getWeekNumber(date) {
    const standard = new Date(date);

    const currentDate = standard.getDate();
    const startOfMonth = new Date(standard.setDate(1));
    const weekDay = startOfMonth.getDay();

    return parseInt(((weekDay - 1) + currentDate) / 7) + 1;
  }

  // 호버링 배경색 컬러 코드에서 RGB값으로 변경 //
  function hexToRGB(hex, alpha) {
    let r = parseInt(hex.slice(1, 3), 16);
    let g = parseInt(hex.slice(3, 5), 16);
    let b = parseInt(hex.slice(5, 7), 16);

    if (alpha) return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ")";
    else return 'rgba(' + r + ', ' + g + ', ' + b + ")";
  }

  // 비어있는칸 플러스 버튼 클릭시 //
  function onClickPlusColumnBox(outData, innerData) {
    let settingTime = '';

    for (let i = 0; i < outData.length; i++) {
      if (outData[i].classId !== -1 && outData[i].classId !== -2) {
        settingTime = outData[i].classTime;
        break;
      }
    }

    onClickPlusColumn(innerData.classDate, settingTime, moment(innerData.classDate).format('ddd,'));
  }

  // 수업시간의 차이로 인한 윗쪽 빈칸 플러스 버튼 클릭시 //
  function onClickTopPlusColumnBox(outData, innerData) {
    let settingStartTime = '';
    let settingEndTime = innerData.classTime.split('-')[0];
    let originalClassTime = innerData.classTime;

    for (let i = 0; i < outData.length; i++) {
      if (outData[i].classId !== -1 && outData[i].classId !== -2 && outData[i].classTime !== originalClassTime) {
        // settingTime = outData[i].classTime;
        settingStartTime = outData[i].classTime.split('-')[0];
        break;
      }
    }

    let settingTime = settingStartTime + '-' + settingEndTime;
    
    onClickPlusColumn(innerData.classDate, settingTime, moment(innerData.classDate).format('ddd,'));
  }
  // 수업시간의 차이로 인한 아랫쪽 빈칸 플러스 버튼 클릭시 //
  function onClickBottomPlusColumnBox(outData, innerData) {
    let settingStartTime = innerData.classTime.split('-')[1];
    let settingEndTime = '';
    let originalClassTime = innerData.classTime;

    for (let i = 0; i < outData.length; i++) {
      if (outData[i].classId !== -1 && outData[i].classId !== -2 && outData[i].classTime !== originalClassTime) {
        settingEndTime = outData[i].classTime.split('-')[1];
        break;
      }
    }

    let settingTime = settingStartTime + '-' + settingEndTime;
    
    onClickPlusColumn(innerData.classDate, settingTime, moment(innerData.classDate).format('ddd,'));
  }

  // 맨 위에 칸 플러스 버튼 클릭시 //
  function onClickFirstPlusColumnBox(innerData) {
    onClickPlusColumn(innerData.classDate, '', moment(innerData.classDate).format('ddd,'));
  }

  // 수업 정보 컴포넌트 사이에 추가버튼을 띄울지 결정 //
  function selectBetweenButtonView(outerIndex, innerIndex) {
    // 마지막 컴포넌트는 버튼 추가 X //
    if (totalData.length === outerIndex + 1) return true;
    else {
      // 본 컴포넌트 아래에 수업없음이라 추가 컴포넌트가 존재하는 경우 //
      if (totalData[outerIndex + 1][innerIndex].className === '수업없음') return false;
      else return true;
    }
  }

  // 첫줄 추가 버튼 호버링 시작 시 //
  function onMouseEnterOfFirstButton(index) {
    ComponentRef.current[index].style.transform = 'translate(0px, 6px)';

    FirstButtonWrapperRef.current[index].style.marginTop = `-14px`;

    FirstButtonRef.current[index].style.height = `18px`;
    FirstButtonRef.current[index].style.backgroundColor = `#FF8B48`;
    FirstButtonRef.current[index].style.opacity = '1';
  }

  // 첫줄 추가 버튼 호버링 끝 //
  function onMouseLeaveOfFirstButton(index) {
    ComponentRef.current[index].style.transform = 'translate(0px, 0px)';

    FirstButtonWrapperRef.current[index].style.marginTop = `-8px`;

    FirstButtonRef.current[index].style.height = `8px`;

    FirstButtonRef.current[index].style.backgroundColor = `#FFFFFF`;
    FirstButtonRef.current[index].style.opacity = '0';
  }

  // 마우스 호버링 시작시 //
  function onMouseEnter(outerIndex, innerIndex, topMargin) {
    ComponentRef.current[(outerIndex * 7) + innerIndex].style.transform = 'translate(0px, -6px)';

    if (totalData.length > (outerIndex + 1)) ComponentRef.current[((outerIndex + 1) * 7) + innerIndex].style.transform = 'translate(0px, 6px)';

    // ButtonWrapperRef.current[(outerIndex * 7) + innerIndex].style.marginTop = `63px`;
    // ButtonWrapperRef.current[(outerIndex * 7) + innerIndex].style.marginTop = (topMargin - 2.5) + 'px';
    ButtonWrapperRef.current[(outerIndex * 7) + innerIndex].style.marginTop = (topMargin - 3) + 'px';

    ButtonRef.current[(outerIndex * 7) + innerIndex].style.height = `18px`;
    ButtonRef.current[(outerIndex * 7) + innerIndex].style.backgroundColor = `#FF8B48`;
    ButtonRef.current[(outerIndex * 7) + innerIndex].style.opacity = '1';
  }

  // 마우스 호버링 끝날시 //
  function onMouseLeave(outerIndex, innerIndex, topMargin) {
    ComponentRef.current[(outerIndex * 7) + innerIndex].style.transform = 'translate(0px, 0px)';

    if (totalData.length > (outerIndex + 1)) ComponentRef.current[((outerIndex + 1) * 7) + innerIndex].style.transform = 'translate(0px, 0px)';

    // ButtonWrapperRef.current[(outerIndex * 7) + innerIndex].style.marginTop = `68px`;
    ButtonWrapperRef.current[(outerIndex * 7) + innerIndex].style.marginTop = (topMargin + 2) + 'px';


    ButtonRef.current[(outerIndex * 7) + innerIndex].style.height = `8px`;
    ButtonRef.current[(outerIndex * 7) + innerIndex].style.backgroundColor = `#FFFFFF`;
    ButtonRef.current[(outerIndex * 7) + innerIndex].style.opacity = '0';
  }

  // 수업 컴포넌트 개별 호버링 시 //
  function onMouseEnterTimeComponent(outerIndex, innerIndex) {
    DeleteTotalRef.current[(outerIndex * 7) + innerIndex].style.opacity = `1`;

    InnerComponentRef.current[(outerIndex * 7) + innerIndex].style.border = '2px solid ' + totalData[outerIndex][innerIndex].color;
    // InnerComponentRef.current[(outerIndex * 7) + innerIndex].style.backgroundColor = hexToRGB(totalData[outerIndex][innerIndex].color, 0.2);
  }

  // 수업 컴포넌트 개별 호버링시 //
  function onMouseLeaveTimeComponent(outerIndex, innerIndex) {
    DeleteTotalRef.current[(outerIndex * 7) + innerIndex].style.opacity = `0`;

    InnerComponentRef.current[(outerIndex * 7) + innerIndex].style.border = '1px solid ' + totalData[outerIndex][innerIndex].color;
    // InnerComponentRef.current[(outerIndex * 7) + innerIndex].style.backgroundColor = '#FFFFFF';
  }

  // 수업삭제 버튼 호버링시 //
  function onMouseEnterDeleteButton(outerIndex, innerIndex) {
    // DeleteBackgroundRef.current[(outerIndex * 7) + innerIndex].style.margin = '-300px -300px 0px 0px';
    DeleteBackgroundRef.current[(outerIndex * 7) + innerIndex].style.width = '125px';
    // DeleteBackgroundRef.current[(outerIndex * 7) + innerIndex].style.width = '600px';
    // DeleteBackgroundRef.current[(outerIndex * 7) + innerIndex].style.height = '68px';
    DeleteBackgroundRef.current[(outerIndex * 7) + innerIndex].style.height = `100%`;
    // DeleteBackgroundRef.current[(outerIndex * 7) + innerIndex].style.height = '600px';
    DeleteBackgroundRef.current[(outerIndex * 7) + innerIndex].style.borderRadius = '4px';
    // DeleteBackgroundRef.current[(outerIndex * 7) + innerIndex].style.borderRadius = '600px';
  }

  // 수업삭제 버튼 호버리시 //
  function onMouseLeaveDeleteButton(outerIndex, innerIndex) {
    DeleteBackgroundRef.current[(outerIndex * 7) + innerIndex].style.margin = '0px';
    DeleteBackgroundRef.current[(outerIndex * 7) + innerIndex].style.width = '20px';
    DeleteBackgroundRef.current[(outerIndex * 7) + innerIndex].style.height = '17px';
    // DeleteBackgroundRef.current[(outerIndex * 7) + innerIndex].style.borderRadius = '0px 4px 0px 4px';
    DeleteBackgroundRef.current[(outerIndex * 7) + innerIndex].style.borderRadius = '15px 0px 0px 90px';
  }


  // 주간 데이터 클릭시
  function onClickWeeklyScheduleBox(e, outerIndex, innerIndex, data) {
    if (DeleteButtonRef.current[(outerIndex * 7) + innerIndex].contains(e.target)) return;

    if (data.className !== '브레이크 타임' && data.className !== '수업없음') {
      setClickDate(data.classDate);
      onClickScheduleDetail(data.scheduleId, data);
      // console.log(data);
    }
  }

  // 수업 삭제 버튼 클릭시 //
  function onClickDeletedClass(data) {
    onClickDeleteButton(data);
  }

  // 수업 시간에 따른 수업 추가 버튼 크기 계산 //
  function onCalcNoneTDHeight(data) {
    let finalStartTime = 0;
    let finalEndTime = 0;

    for (let i = 0; i < data.length; i++) {
      if (data[i].className !== '수업없음') {
        let startTime = data[i].classTime.split('-')[0];
        let endTime = data[i].classTime.split('-')[1];

        let startTimeHour = parseInt(startTime.split(':')[0]);
        let startTimeMinute = parseInt(startTime.split(':')[1]);

        let endTimeHour = parseInt(endTime.split(':')[0]);
        let endTimeMinute = parseInt(endTime.split(':')[1]);

        let startValue = 0;
        let endValue = 0;

        // if (startTimeMinute > 50) startValue = startTimeHour + 0.83;
        // else if (startTimeMinute > 40) startValue = startTimeHour + 0.66;
        // else if (startTimeMinute > 30) startValue = startTimeHour + 0.5;
        // else if (startTimeMinute > 20) startValue = startTimeHour + 0.33;
        // else if (startTimeMinute > 10) startValue = startTimeHour + 0.16;
        // else startValue = startTimeHour;

        if (startTimeMinute >= 55) startValue = startTimeHour + 0.91;
        else if (startTimeMinute >= 50) startValue = startTimeHour + 0.83;
        else if (startTimeMinute >= 45) startValue = startTimeHour + 0.75;
        else if (startTimeMinute >= 40) startValue = startTimeHour + 0.66;
        else if (startTimeMinute >= 35) startValue = startTimeHour + 0.58;
        else if (startTimeMinute >= 30) startValue = startTimeHour + 0.5;
        else if (startTimeMinute >= 25) startValue = startTimeHour + 0.41;
        else if (startTimeMinute >= 20) startValue = startTimeHour + 0.33;
        else if (startTimeMinute >= 15) startValue = startTimeHour + 0.25;
        else if (startTimeMinute >= 10) startValue = startTimeHour + 0.16;
        else if (startTimeMinute >= 5) startValue = startTimeHour + 0.08;
        else startValue = startTimeHour;


        // if (endTimeMinute > 50) endValue = endTimeHour + 0.83;
        // else if (endTimeMinute > 40) endValue = endTimeHour + 0.66;
        // else if (endTimeMinute > 30) endValue = endTimeHour + 0.5;
        // else if (endTimeMinute > 20) endValue = endTimeHour + 0.33;
        // else if (endTimeMinute > 10) endValue = endTimeHour + 0.16;
        // else endValue = endTimeHour;

        if (endTimeMinute >= 55) endValue = endTimeHour + 0.91;
        else if (endTimeMinute >= 50) endValue = endTimeHour + 0.83;
        else if (endTimeMinute >= 45) endValue = endTimeHour + 0.75;
        else if (endTimeMinute >= 40) endValue = endTimeHour + 0.66;
        else if (endTimeMinute >= 35) endValue = endTimeHour + 0.58;
        else if (endTimeMinute >= 30) endValue = endTimeHour + 0.5;
        else if (endTimeMinute >= 25) endValue = endTimeHour + 0.41;
        else if (endTimeMinute >= 20) endValue = endTimeHour + 0.33;
        else if (endTimeMinute >= 15) endValue = endTimeHour + 0.25;
        else if (endTimeMinute >= 10) endValue = endTimeHour + 0.16;
        else if (endTimeMinute >= 5) endValue = endTimeHour + 0.08;
        else endValue = endTimeHour;

        if (finalStartTime === 0 && finalEndTime === 0) {
          finalStartTime = startValue;
          finalEndTime = endValue;
        }
        else {
          if (finalStartTime > startValue) finalStartTime = startValue;
          if (finalEndTime < endValue) finalEndTime = endValue;
        }
      }
    }

    let finalRate = finalEndTime - finalStartTime;

    // if (finalRate < 2) return 60;
    if (finalRate < 1.5) return 60;
    else {
      return ((((finalRate - 1) * conversionRate) + 1) * 66) - 4;
    }
  }

  // 수업 시간에 따른 크기 변환 계산 //
  function onCalcTDHeight(data) {
    let startTime = data.classTime.split('-')[0];
    let endTime = data.classTime.split('-')[1];

    let startTimeHour = parseInt(startTime.split(':')[0]);
    let startTimeMinute = parseInt(startTime.split(':')[1]);

    let endTimeHour = parseInt(endTime.split(':')[0]);
    let endTimeMinute = parseInt(endTime.split(':')[1]);

    let startValue = 0;
    let endValue = 0;

    // if (startTimeMinute > 50) startValue = startTimeHour + 0.83;
    // else if (startTimeMinute > 40) startValue = startTimeHour + 0.66;
    // else if (startTimeMinute > 30) startValue = startTimeHour + 0.5;
    // else if (startTimeMinute > 20) startValue = startTimeHour + 0.33;
    // else if (startTimeMinute > 10) startValue = startTimeHour + 0.16;
    // else startValue = startTimeHour;

    if (startTimeMinute >= 55) startValue = startTimeHour + 0.91;
    else if (startTimeMinute >= 50) startValue = startTimeHour + 0.83;
    else if (startTimeMinute >= 45) startValue = startTimeHour + 0.75;
    else if (startTimeMinute >= 40) startValue = startTimeHour + 0.66;
    else if (startTimeMinute >= 35) startValue = startTimeHour + 0.58;
    else if (startTimeMinute >= 30) startValue = startTimeHour + 0.5;
    else if (startTimeMinute >= 25) startValue = startTimeHour + 0.41;
    else if (startTimeMinute >= 20) startValue = startTimeHour + 0.33;
    else if (startTimeMinute >= 15) startValue = startTimeHour + 0.25;
    else if (startTimeMinute >= 10) startValue = startTimeHour + 0.16;
    else if (startTimeMinute >= 5) startValue = startTimeHour + 0.08;
    else startValue = startTimeHour;

    // if (endTimeMinute > 50) endValue = endTimeHour + 0.83;
    // else if (endTimeMinute > 40) endValue = endTimeHour + 0.66;
    // else if (endTimeMinute > 30) endValue = endTimeHour + 0.5;
    // else if (endTimeMinute > 20) endValue = endTimeHour + 0.33;
    // else if (endTimeMinute > 10) endValue = endTimeHour + 0.16;
    // else endValue = endTimeHour;

    if (endTimeMinute >= 55) endValue = endTimeHour + 0.91;
    else if (endTimeMinute >= 50) endValue = endTimeHour + 0.83;
    else if (endTimeMinute >= 45) endValue = endTimeHour + 0.75;
    else if (endTimeMinute >= 40) endValue = endTimeHour + 0.66;
    else if (endTimeMinute >= 35) endValue = endTimeHour + 0.58;
    else if (endTimeMinute >= 30) endValue = endTimeHour + 0.5;
    else if (endTimeMinute >= 25) endValue = endTimeHour + 0.41;
    else if (endTimeMinute >= 20) endValue = endTimeHour + 0.33;
    else if (endTimeMinute >= 15) endValue = endTimeHour + 0.25;
    else if (endTimeMinute >= 10) endValue = endTimeHour + 0.16;
    else if (endTimeMinute >= 5) endValue = endTimeHour + 0.08;
    else endValue = endTimeHour;

    // if (data.classDate === '2023.08.01' && data.classTime === '14:30-16:00') {
    //   console.log('--------------');
    //   console.log((startValue));
    //   console.log((endValue));
    //   console.log((endValue - startValue) * 66);
    // }
    
    let finalRate = endValue - startValue;

    // if (finalRate < 1) return 66;
    if (finalRate < 1.5) return 66;
    else return (((finalRate - 1) * conversionRate) + 1) * 66;
  }

  // 수업 시간에 따른 크기 변환 ( 호버링 전용 ) 계산 //
  function onCalcHoverTDHeight(data) {
    let startTime = data.classTime.split('-')[0];
    let endTime = data.classTime.split('-')[1];

    let startTimeHour = parseInt(startTime.split(':')[0]);
    let startTimeMinute = parseInt(startTime.split(':')[1]);

    let endTimeHour = parseInt(endTime.split(':')[0]);
    let endTimeMinute = parseInt(endTime.split(':')[1]);

    let startValue = 0;
    let endValue = 0;

    // if (startTimeMinute > 50) startValue = startTimeHour + 0.83;
    // else if (startTimeMinute > 40) startValue = startTimeHour + 0.66;
    // else if (startTimeMinute > 30) startValue = startTimeHour + 0.5;
    // else if (startTimeMinute > 20) startValue = startTimeHour + 0.33;
    // else if (startTimeMinute > 10) startValue = startTimeHour + 0.16;
    // else startValue = startTimeHour;

    if (startTimeMinute >= 55) startValue = startTimeHour + 0.91;
    else if (startTimeMinute >= 50) startValue = startTimeHour + 0.83;
    else if (startTimeMinute >= 45) startValue = startTimeHour + 0.75;
    else if (startTimeMinute >= 40) startValue = startTimeHour + 0.66;
    else if (startTimeMinute >= 35) startValue = startTimeHour + 0.58;
    else if (startTimeMinute >= 30) startValue = startTimeHour + 0.5;
    else if (startTimeMinute >= 25) startValue = startTimeHour + 0.41;
    else if (startTimeMinute >= 20) startValue = startTimeHour + 0.33;
    else if (startTimeMinute >= 15) startValue = startTimeHour + 0.25;
    else if (startTimeMinute >= 10) startValue = startTimeHour + 0.16;
    else if (startTimeMinute >= 5) startValue = startTimeHour + 0.08;
    else startValue = startTimeHour;


    // if (endTimeMinute > 50) endValue = endTimeHour + 0.83;
    // else if (endTimeMinute > 40) endValue = endTimeHour + 0.66;
    // else if (endTimeMinute > 30) endValue = endTimeHour + 0.5;
    // else if (endTimeMinute > 20) endValue = endTimeHour + 0.33;
    // else if (endTimeMinute > 10) endValue = endTimeHour + 0.16;
    // else endValue = endTimeHour;

    if (endTimeMinute >= 55) endValue = endTimeHour + 0.91;
    else if (endTimeMinute >= 50) endValue = endTimeHour + 0.83;
    else if (endTimeMinute >= 45) endValue = endTimeHour + 0.75;
    else if (endTimeMinute >= 40) endValue = endTimeHour + 0.66;
    else if (endTimeMinute >= 35) endValue = endTimeHour + 0.58;
    else if (endTimeMinute >= 30) endValue = endTimeHour + 0.5;
    else if (endTimeMinute >= 25) endValue = endTimeHour + 0.41;
    else if (endTimeMinute >= 20) endValue = endTimeHour + 0.33;
    else if (endTimeMinute >= 15) endValue = endTimeHour + 0.25;
    else if (endTimeMinute >= 10) endValue = endTimeHour + 0.16;
    else if (endTimeMinute >= 5) endValue = endTimeHour + 0.08;
    else endValue = endTimeHour;

    let finalRate = endValue - startValue;

    if (finalRate < 1.5) return 64;
    else return ((((finalRate - 1) * conversionRate) + 1) * 66) - 2;
  }

  // 같은 시간대에 있는 수업이 너무 긴경우 아랫쪽에 수업 추가 버튼을 생성할지 말지 결정 //
  function onCalcUpAdditionalButtonHeight(weeklyData, data) {
    let minimumStartTime = 0;

    for (let i = 0; i < weeklyData.length; i++) {
      if (weeklyData[i].className !== '수업없음') {
        let startTime = weeklyData[i].classTime.split('-')[0];

        let startTimeHour = parseInt(startTime.split(':')[0]);
        let startTimeMinute = parseInt(startTime.split(':')[1]);

        let startValue = 0;

        // if (startTimeMinute > 50) startValue = startTimeHour + 0.83;
        // else if (startTimeMinute > 40) startValue = startTimeHour + 0.66;
        // else if (startTimeMinute > 30) startValue = startTimeHour + 0.5;
        // else if (startTimeMinute > 20) startValue = startTimeHour + 0.33;
        // else if (startTimeMinute > 10) startValue = startTimeHour + 0.16;
        // else startValue = startTimeHour;

        if (startTimeMinute >= 55) startValue = startTimeHour + 0.91;
        else if (startTimeMinute >= 50) startValue = startTimeHour + 0.83;
        else if (startTimeMinute >= 45) startValue = startTimeHour + 0.75;
        else if (startTimeMinute >= 40) startValue = startTimeHour + 0.66;
        else if (startTimeMinute >= 35) startValue = startTimeHour + 0.58;
        else if (startTimeMinute >= 30) startValue = startTimeHour + 0.5;
        else if (startTimeMinute >= 25) startValue = startTimeHour + 0.41;
        else if (startTimeMinute >= 20) startValue = startTimeHour + 0.33;
        else if (startTimeMinute >= 15) startValue = startTimeHour + 0.25;
        else if (startTimeMinute >= 10) startValue = startTimeHour + 0.16;
        else if (startTimeMinute >= 5) startValue = startTimeHour + 0.08;
        else startValue = startTimeHour;

        if (minimumStartTime === 0) {
          minimumStartTime = startValue;
        }
        else {
          if (minimumStartTime > startValue) minimumStartTime = startValue;
        }
      }
    }

    let startTime = data.classTime.split('-')[0];

    let startTimeHour = parseInt(startTime.split(':')[0]);
    let startTimeMinute = parseInt(startTime.split(':')[1]);

    let startValue = 0;

    // if (startTimeMinute > 50) startValue = startTimeHour + 0.83;
    // else if (startTimeMinute > 40) startValue = startTimeHour + 0.66;
    // else if (startTimeMinute > 30) startValue = startTimeHour + 0.5;
    // else if (startTimeMinute > 20) startValue = startTimeHour + 0.33;
    // else if (startTimeMinute > 10) startValue = startTimeHour + 0.16;
    // else startValue = startTimeHour;

    if (startTimeMinute >= 55) startValue = startTimeHour + 0.91;
    else if (startTimeMinute >= 50) startValue = startTimeHour + 0.83;
    else if (startTimeMinute >= 45) startValue = startTimeHour + 0.75;
    else if (startTimeMinute >= 40) startValue = startTimeHour + 0.66;
    else if (startTimeMinute >= 35) startValue = startTimeHour + 0.58;
    else if (startTimeMinute >= 30) startValue = startTimeHour + 0.5;
    else if (startTimeMinute >= 25) startValue = startTimeHour + 0.41;
    else if (startTimeMinute >= 20) startValue = startTimeHour + 0.33;
    else if (startTimeMinute >= 15) startValue = startTimeHour + 0.25;
    else if (startTimeMinute >= 10) startValue = startTimeHour + 0.16;
    else if (startTimeMinute >= 5) startValue = startTimeHour + 0.08;
    else startValue = startTimeHour;

    // if (data.classDate === '2023.08.05' && data.classTime === '15:00-17:30') {
    //   console.log('-------start-------');
    //   console.log('Min : ' + (minimumStartTime));
    //   console.log('Start : ' + (startValue));
    //   console.log('Total : ' + (startValue - minimumStartTime));
    // }

    let finalRate = startValue - minimumStartTime;

    // if ((finalRate) === 0) return 0;
    // else if (finalRate < 0.5) return 0;
    // else if (finalRate < 4) return ((finalRate) * 66) - 8 - 6;
    // // Margin Top : 6px 부분 빼기 //
    // else return ((finalRate) * 64) - 8 - 6;

    // if ((finalRate) === 0) return 0;
    // else if (finalRate < 0.5) return 0;
    // // Margin Top : 6px 부분 빼기 //
    // else return ((((finalRate - 1) * 0.5) + 1) * 64) - 8 - 6;

    if ((finalRate) === 0) return 0;
    else if (finalRate < 0.5) return 0;
    // Margin Top : 6px 부분 빼기 //
    else return ((finalRate * conversionRate) * 64) - 8 - 6;
  }

  // 같은 시간대에 있는 수업이 너무 긴경우 아랫쪽에 수업 추가 버튼을 생성할지 말지 결정 //
  function onCalcDownAdditionalButtonHeight(weeklyData, data) {
    let maximumEndTime = 0;

    for (let i = 0; i < weeklyData.length; i++) {
      if (weeklyData[i].className !== '수업없음') {
        let endTime = weeklyData[i].classTime.split('-')[1];

        let endTimeHour = parseInt(endTime.split(':')[0]);
        let endTimeMinute = parseInt(endTime.split(':')[1]);

        let endValue = 0;

        // if (endTimeMinute > 50) endValue = endTimeHour + 0.83;
        // else if (endTimeMinute > 40) endValue = endTimeHour + 0.66;
        // else if (endTimeMinute > 30) endValue = endTimeHour + 0.5;
        // else if (endTimeMinute > 20) endValue = endTimeHour + 0.33;
        // else if (endTimeMinute > 10) endValue = endTimeHour + 0.16;
        // else endValue = endTimeHour;

        if (endTimeMinute >= 55) endValue = endTimeHour + 0.91;
        else if (endTimeMinute >= 50) endValue = endTimeHour + 0.83;
        else if (endTimeMinute >= 45) endValue = endTimeHour + 0.75;
        else if (endTimeMinute >= 40) endValue = endTimeHour + 0.66;
        else if (endTimeMinute >= 35) endValue = endTimeHour + 0.58;
        else if (endTimeMinute >= 30) endValue = endTimeHour + 0.5;
        else if (endTimeMinute >= 25) endValue = endTimeHour + 0.41;
        else if (endTimeMinute >= 20) endValue = endTimeHour + 0.33;
        else if (endTimeMinute >= 15) endValue = endTimeHour + 0.25;
        else if (endTimeMinute >= 10) endValue = endTimeHour + 0.16;
        else if (endTimeMinute >= 5) endValue = endTimeHour + 0.08;
        else endValue = endTimeHour;

        if (maximumEndTime === 0) {
          maximumEndTime = endValue;
        }
        else {
          if (maximumEndTime < endValue) maximumEndTime = endValue;
        }
      }
    }

    let endTime = data.classTime.split('-')[1];

    let endTimeHour = parseInt(endTime.split(':')[0]);
    let endTimeMinute = parseInt(endTime.split(':')[1]);

    let endValue = 0;


    // if (endTimeMinute > 50) endValue = endTimeHour + 0.83;
    // else if (endTimeMinute > 40) endValue = endTimeHour + 0.66;
    // else if (endTimeMinute > 30) endValue = endTimeHour + 0.5;
    // else if (endTimeMinute > 20) endValue = endTimeHour + 0.33;
    // else if (endTimeMinute > 10) endValue = endTimeHour + 0.16;
    // else endValue = endTimeHour;

    if (endTimeMinute >= 55) endValue = endTimeHour + 0.91;
    else if (endTimeMinute >= 50) endValue = endTimeHour + 0.83;
    else if (endTimeMinute >= 45) endValue = endTimeHour + 0.75;
    else if (endTimeMinute >= 40) endValue = endTimeHour + 0.66;
    else if (endTimeMinute >= 35) endValue = endTimeHour + 0.58;
    else if (endTimeMinute >= 30) endValue = endTimeHour + 0.5;
    else if (endTimeMinute >= 25) endValue = endTimeHour + 0.41;
    else if (endTimeMinute >= 20) endValue = endTimeHour + 0.33;
    else if (endTimeMinute >= 15) endValue = endTimeHour + 0.25;
    else if (endTimeMinute >= 10) endValue = endTimeHour + 0.16;
    else if (endTimeMinute >= 5) endValue = endTimeHour + 0.08;
    else endValue = endTimeHour;


    let finalRate = maximumEndTime - endValue;

    // if (data.classDate === '2023.08.22' && data.classTime === '11:30-12:20') {
    //   console.log('-------End-------');
    //   console.log('Max : ' + (maximumEndTime));
    //   console.log('End : ' + (endValue));
    //   console.log('Total : ' + (maximumEndTime - endValue));
    //   console.log((((((finalRate - 1) * conversionRate) + 1) * 64) - 8 - 6))
    // }

    // if (finalRate === 0) return 0;
    // else if (finalRate < 0.5) return 0;
    // else if (finalRate < 4) return ((finalRate) * 66) - 8 - 6;
    // // Margin Top : 6px 부분 빼기 //
    // else return ((finalRate) * 64) - 8 - 6;

    // if (finalRate === 0) return 0;
    // else if (finalRate < 0.5) return 0;
    // // Margin Top : 6px 부분 빼기 //
    // else return (((((finalRate - 1) * 0.5) + 1) * 64) - 8 - 6);

    
    if (finalRate === 0) return 0;
    else if (finalRate < 0.5) return 0;
    // Margin Top : 6px 부분 빼기 //
    else return (((finalRate * conversionRate) * 64) - 8 - 6);
  }

  return (
    <TopLevelWrapper ref={TopLevelRef}>
      <WeeklyColumn today={startDay} standardDate={today} />
      {
        isLoading ?
          <LoadingWrapper minHeight={minHeight} >
            <LottieWrapper>
              <Lottie options={{ animationData: LoadingAnimation }} />
            </LottieWrapper>
          </LoadingWrapper>
          :
          <ColumnWrapper ref={ColumnRef}>
            {
              totalData.length !== 0 ?
                <TableWrapper>
                  <TimeDivisionLineWrapper>
                    {
                      totalData.map((data, index) => (
                        // <TimeDivisionLine key={index} scheduleData={data} isMain={false} />
                        <NewTimeDivisionLine
                          key={index} 
                          index={index} 
                          conversionRate={conversionRate}
                          totalData={totalData} />
                      ))
                    }
                  </TimeDivisionLineWrapper>
                  <Table>
                    <Tbody>
                      {
                        totalData.map((data, index) => (
                          <Tr key={index}>
                            {
                              data.map((innerData, idx) => (
                                innerData.className === '수업없음' ?
                                  <ComponentWrapper key={idx} /*ref={(element) => TimeDeleteRef.current[(index * 7) + index] = element}*/>
                                    <TDWrapper ref={(element) => ComponentRef.current[(index * 7) + idx] = element}>
                                      <NoneClassTd
                                        height={onCalcNoneTDHeight(data)}
                                        backgroundColor={hexToRGB('#ECECEC', 0.8)}
                                        onClick={() => onClickPlusColumnBox(data, innerData)}>
                                        <Plus src={PlusPath} />
                                      </NoneClassTd>
                                    </TDWrapper>
                                    <BetweenEmptyBox ref={(element) => ButtonRef.current[(index * 7) + idx] = element} />
                                  </ComponentWrapper>
                                  :
                                  <ComponentWrapper
                                    key={idx}>
                                    {
                                      index === 0 &&
                                      <FirstPlusButtonWrapper
                                        ref={(element) => FirstButtonWrapperRef.current[idx] = element}
                                        onMouseEnter={() => onMouseEnterOfFirstButton(idx)}
                                        onMouseLeave={() => onMouseLeaveOfFirstButton(idx)}>
                                        <FirstPlusButton
                                          ref={(element) => FirstButtonRef.current[idx] = element}
                                          onClick={() => onClickFirstPlusColumnBox(innerData)}>
                                          <BetweenPlusWidth />
                                          <BetweenPlusHeight />
                                        </FirstPlusButton>
                                      </FirstPlusButtonWrapper>
                                    }
                                    {
                                      onCalcUpAdditionalButtonHeight(data, innerData) !== 0 &&
                                      <TDWrapper>
                                        <NoneClassTd
                                          style={{ marginBottom: '6px' }}
                                          height={onCalcUpAdditionalButtonHeight(data, innerData)}
                                          backgroundColor={hexToRGB('#ECECEC', 0.8)}
                                          onClick={() => onClickTopPlusColumnBox(data, innerData)}>
                                          <Plus src={PlusPath} />
                                        </NoneClassTd>
                                      </TDWrapper>
                                    }
                                    <TDWrapper
                                      ref={(element) => ComponentRef.current[(index * 7) + idx] = element}
                                      onMouseEnter={() => onMouseEnterTimeComponent(index, idx)}
                                      onMouseLeave={() => onMouseLeaveTimeComponent(index, idx)}>
                                      <Td
                                        ref={(element) => InnerComponentRef.current[(index * 7) + idx] = element}
                                        onClick={(e) => onClickWeeklyScheduleBox(e, index, idx, innerData)}
                                        isValid={innerData.className !== '수업없음'}
                                        height={onCalcTDHeight(innerData)}
                                        hoverHeight={onCalcHoverTDHeight(innerData)}
                                        color={innerData.className === '수업없음' ? '#ECECEC' : innerData.color}
                                        // hoverBackColor={innerData.className === '수업없음' ? '#FFFFFF' : hexToRGB(innerData.color, 0.2)}
                                        hoverBackColor={hexToRGB(innerData.color, 0.8)}
                                        backgroundColor={innerData.color}>
                                        <DeleteButtonWrapper ref={(element) => DeleteTotalRef.current[(index * 7) + idx] = element}>
                                          <DeleteBackground
                                            ref={(element) => DeleteBackgroundRef.current[(index * 7) + idx] = element}
                                            backgroundColor={hexToRGB('#FF3131', 0.4)}>
                                            <DeleteButton
                                              ref={(element) => DeleteButtonRef.current[(index * 7) + idx] = element}
                                              onMouseEnter={() => onMouseEnterDeleteButton(index, idx)}
                                              onMouseLeave={() => onMouseLeaveDeleteButton(index, idx)}
                                              onClick={() => onClickDeletedClass(innerData, 'Component')}>
                                              <DeleteIcon src={WhiteTrashIcon} />
                                            </DeleteButton>
                                          </DeleteBackground>
                                        </DeleteButtonWrapper>
                                        <Text>
                                          {/* <ClassName color={innerData.className === '수업없음' ? '#BDBDBD' : '#232323'}>{innerData.className + console.log(innerData)}</ClassName> */}
                                          <ClassNameWrapper>
                                            <ClassName color={innerData.className === '수업없음' ? '#BDBDBD' : '#232323'}>{innerData.className}</ClassName>
                                          </ClassNameWrapper>
                                          <ClassTimeWrapper>
                                            <ClassTime>{innerData.classTime}</ClassTime>
                                          </ClassTimeWrapper>
                                          <CoachNameWrapper>
                                            <CoachName>{innerData.teacherName}</CoachName>
                                          </CoachNameWrapper>
                                        </Text>
                                      </Td>
                                    </TDWrapper>
                                    {
                                      onCalcDownAdditionalButtonHeight(data, innerData) !== 0 &&
                                      <TDWrapper>
                                        <NoneClassTd
                                          style={{ marginTop: '6px' }}
                                          height={onCalcDownAdditionalButtonHeight(data, innerData)}
                                          backgroundColor={hexToRGB('#ECECEC', 0.8)}
                                          onClick={() => onClickBottomPlusColumnBox(data, innerData)}>
                                          <Plus src={PlusPath} />
                                        </NoneClassTd>
                                      </TDWrapper>
                                    }
                                    {
                                      (onCalcDownAdditionalButtonHeight(data, innerData) === 0 && selectBetweenButtonView(index, idx)) ?
                                        <BetweenPlusButtonWrapper
                                          ref={(element) => ButtonWrapperRef.current[(index * 7) + idx] = element}
                                          marginTop={onCalcTDHeight(innerData)}
                                          onMouseEnter={() => onMouseEnter(index, idx, onCalcTDHeight(innerData))}
                                          onMouseLeave={() => onMouseLeave(index, idx)}>
                                          <BetweenPlusButton
                                            ref={(element) => ButtonRef.current[(index * 7) + idx] = element}
                                            onClick={() => onClickPlusColumnBox(data, innerData)}>
                                            <BetweenPlusWrapper>
                                              <BetweenPlusWidth />
                                              <BetweenPlusHeight />
                                            </BetweenPlusWrapper>
                                          </BetweenPlusButton>
                                        </BetweenPlusButtonWrapper>
                                        :
                                        <BetweenEmptyBox />
                                    }
                                  </ComponentWrapper>
                              ))
                            }
                          </Tr>
                        ))
                      }
                    </Tbody>
                  </Table>
                </TableWrapper>
                :
                <EmptyClassInfoBox>
                  <EmptyImage src={EmptySign} />
                  <EmptyText>수업이 존재하지 않습니다</EmptyText>
                </EmptyClassInfoBox>
            }
          </ColumnWrapper>
      }
    </TopLevelWrapper>
  )
})

export default WeeklyClass;

const TopLevelWrapper = styled.div`
  // position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 1020px;
  // height: 449px;
  // height: 686px;

  margin-top: 28px;

  // padding-top: 28px;
  // padding-bottom: 28px;

  // margin-top: 138px;
  // margin-bottom: 96px;

	// opacity: 1;
	// z-index: -3;

  // border-radius: 30px;
  // box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  // background-color: #FFFFFF;
  
  // transition: all 0.3s ease-in-out;
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 974px;
  // min-height: 644px;
  min-height: ${props => props.minHeight}px;
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;
  
  width: 974px;
  height: auto;
  // height: 644px;
  
  // overflow-y: scroll;
  // // overflow-x: hidden;
  // overflow-x: scroll;

  // &::-webkit-scrollbar {
  //   width: 4px;
  // }

  // &::-webkit-scrollbar-thumb {
  //   border: 3px solid transparent;
  //   background-color: #DADADA;
  //   border-radius: 2px;
  // }

  // &::-webkit-scrollbar-track {
  //   height: 439px;
  //   //border: 4px solid transparent;
  //   background-color: #FFFFFF;
  //   border-radius: 2px;
  // }
`;

const TimeDivisionLineWrapper = styled.div`
	display: flex;
	flex-direction: column;

  justify-content: flex-start;
  align-items: center;
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 94.36%;
  // height: 100%;
  // width: 921px;
  // height: 407px;
`;

const Tbody = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  // height: 100%;

  gap: 8px;
  // gap: 10px;
`;

const Tr = styled.div` 
  display: flex;
  flex-direction: row;

  justify-content: center;
  // align-items: center;
  align-items: flex-start;

  // width: 100%;
  // height: 10.04%;
  
  width: 911px;
  // height: 68px;

  gap: 6px;
  margin: 0 0 0 0;
  // padding: 1px 0 0 0;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  transition: all 0.3s ease-in-out;
`;

const TDWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  transition: all 0.1s ease-in-out;
`;

const Td = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  // justify-content: center;
  justify-content: flex-start;
  align-items: center;

  // width: 123px;
  // height: 66px;

  // 원본 크기 //
  // width: 125px;
  // height: 68px;

  width: 123px;
  // height: 66px;
  height: ${props => props.height}px;

  border: 1px solid ${props => props.color};
  border-radius: 4px;
  // background-color: #FFFFFF;
  background-color: ${props => props.backgroundColor};
  // background-color: blue;
  
  // transition: all 0.3s ease-in-out;

  :hover {
    width: 121px;
    // height: 64px;
    height: ${props => props.hoverHeight}px;

    border: 2px solid ${props => props.color};
    cursor: ${props => props.isValid ? `pointer` : `default`};
    background-color: ${props => props.hoverBackColor};
  }
`;

const DeleteButtonWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-end;

  margin-top: -2px;
  width: 125px;
  // height: 68px;
  // height: 100%;
  height: calc(100% + 4px);

  z-index: 5;
  opacity: 0;

  overflow: hidden;
  // background-color: yellow;
  background-color: transparent;
`;

const DeleteBackground = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-end;

  z-index: 3;

  // width: 125px;
  // height: 68px;
  width: 20px;
  height: 17px;

  // border-radius: 0px 4px 0px 4px;
  border-radius: 15px 0px 0px 90px;
  // background-color: #FF3131;
  background-color: ${props => props.backgroundColor};

  transition: all 0.3s ease-in-out;
`;

const DeleteButton = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 20px;
  height: 17px;

  border-radius: 0px 4px 0px 4px;
  background-color: #FF3131;

  z-index: 6;

  :hover {
    cursor: pointer;
  }

  transition: none;
`;

const DeleteIcon = styled.img`
  width: 12px;
  height: 12px;
`;

const BetweenEmptyBox = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 68px;

  width: 50px;
  height: 8px;

  opacity: 0;
  background-color: #FFFFFF;
`;

const BetweenPlusButtonWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // margin-top: 68px;
  margin-top: ${props => props.marginTop}px;

  width: 125px;

  :hover {
    cursor: pointer;
  }

  transition: all 0.1s ease-in-out;
`;

const BetweenPlusButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 50px;
  height: 8px;

  opacity: 0;

  border-radius: 4px;
  background-color: #FFFFFF;
  // background-color: red;

  transition: all 0.1s ease-in-out;
`;

const BetweenPlusWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 12px;
  height: 100%;
`;

const BetweenPlusWidth = styled.div`
  position: absolute;

  width: 7px;
  height: 1px;

  border-radius: 3px;
  background-color: #FFFFFF;
`;

const BetweenPlusHeight = styled.div`
  position: absolute;

  width: 1px;
  height: 7px;

  border-radius: 3px;
  background-color: #FFFFFF;
`;

const FirstPlusButtonWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: -8px;

  width: 125px;

  :hover {
    cursor: pointer;
  }

  transition: all 0.1s ease-in-out;
`;

const FirstPlusButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 50px;
  height: 8px;

  opacity: 0;

  border-radius: 4px;
  background-color: #FFFFFF;

  transition: all 0.1s ease-in-out;
`;

const NoneClassTd = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 125px;
  // height: 68px;

  // width: 116px;
  width: 117px;
  // height: 60px;
  height: ${props => props.height}px;

  border: 4px solid #FFFFFF;
  border-radius: 4px;
  background-color: ${props => props.backgroundColor};
  
  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 4px solid #FF8B48;
    background-color: #FF8B48;
  }
`;

const Plus = styled.img`
  width: 20px;
  height: 20px;
`;

const Text = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  margin-top: 2px;
  margin-left: -16px;

  // width: 103px;
  width: 93px;
  height: 50px;

  z-index: 4;

  // margin: 0 0 0 0;
  // background-color: red;
`;

const ClassName = styled.p`
  // font-family: NotoSansKR-Medium;
  font-family: NotoSansKR-Bold;
  font-size: 12px;

  // color: ${props => props.color};
  color: #FFFFFF;

  margin: 0 0 0 0;

  width: 100%;
  min-height: 17px;

  overflow: hidden;
`;

const ClassTime = styled.p`
  font-family: Poppins-Regular;
  font-size: 10px;

  // color: #777777;
  color: #FFFFFF;

  margin: 0 0 0 0;
`;

const ClassNameWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 110px;
  height: 17px;

  overflow: hidden;
`;

const ClassTimeWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 110px;
  height: 16px;

  overflow: hidden;
`;

const CoachNameWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 110px;
  height: 15px;

  overflow: hidden;
`;

const CoachName = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 10px;

  // color: #777777;
  color: #FFFFFF;

  margin: 0 0 0 0;
`;

const EmptyClassInfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 974px;
  height: 644px;
`;

const EmptyImage = styled.img`
  width: 148px;
  height: 62px;
`;

const EmptyText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;

  margin: 8px 0 0 0;

  :hover {
    cursor: default;
  }
`;

const LottieWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 60px;
  height: 60px;
`;