import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";

import EditRatioIcon from '../../../image/EditRatioIcon.png';
import WhiteCameraIcon from '../../../image/WhiteCameraIcon.png';
import WhiteExitIcon from '../../../image/WhiteExitIcon.png';
import LeftArrow from '../../../image/WhiteLeftArrow.png';
import RightArrow from '../../../image/WhiteRightArrow.png';
import WhiteImageIcon from '../../../image/WhiteImageIcon.png';
import OrangeImageIcon from '../../../image/OrangeImageIcon.png';

export default function ImageEditModal(props) {
  const { 
    picture,
    setPicture,
    pictures,
    setPictures,
    pictureIndex,
    setPictureIndex,
    setNoEditPictureWidth,
    setNoEditPictureHeight,
    onClickExit,
    onClickPrevPicture,
    onClickNextPicture,
    onClickDeletePicture
  } = props;

  const [pictureWidth, setPictureWidth] = useState(0);
  const [pictureHeight, setPictureHeight] = useState(0);
  const [isClickedFixedRatioButton, setIsClickedFixedRatioButton] = useState(false);

  const ratios = {
    square: { width: 1, height: 1 },
    portrait: { width: 4, height: 5 },
    landscape: { width: 16, height: 9 },
  };

  // First : Original
  // Second : 1:1
  // Third : 4:5
  // Force : 16:9
  const [isFirstHover, setIsFirstHover] = useState(false);
  const [isSecondHover, setIsSecondHover] = useState(false);
  const [isThirdHover, setIsThirdHover] = useState(false);
  const [isFourceHover, setIsFourceHover] = useState(false);

  const ModalRef = useRef(null);
  const RatioOptionRef = useRef(null);
  const RatioButtonRef = useRef(null);
  const FileInputRef = useRef();
  const hiddenFileInput = useRef(null);
  const canvasRef = useRef(null);
  
  useEffect(() => {
    if (picture === '') return;
    
    let img = document.createElement('img');
    img.src = picture.edit;
    
    img.onload = () => {
      let maxWidth = 720;
      let maxHeight = 720;

      if (img.width > maxWidth || img.height > maxHeight) {
        // 가로가 더 길면 가로에 맞추기 //
        if (img.width > img.height) {
          let finalWidth = maxWidth;
          let finalHeight = (img.height * finalWidth) / img.width;
  
          setPictureWidth(finalWidth);
          setPictureHeight(finalHeight);
        }
        // 세로가 더 길면 세로에 맞추기 //
        else {
          let finalHeight = maxHeight;
          let finalWidth = (img.width * finalHeight) / img.height;
  
          setPictureWidth(finalWidth);
          setPictureHeight(finalHeight);
        }
      }
      else {
        setPictureWidth(img.width);
        setPictureHeight(img.height);
      }

    }
  }, [picture])

  // 모달 밖 사진 크기 조절 //
  useEffect(() => {
    let resizeWidth = 0;
    let resizeHeight = 0;

    if (pictureWidth < 600) {
      resizeWidth = pictureWidth;
      resizeHeight = pictureHeight;
    }
    else {
      resizeWidth = 600;
      resizeHeight = (600 * pictureHeight) / pictureWidth;
    }


    let finalHeight = (resizeHeight * 368) / resizeWidth;

    setNoEditPictureWidth(368);
    setNoEditPictureHeight(finalHeight);
  }, [pictureWidth, pictureHeight])

  // 컴포넌트 외부 클릭시 이벤트를 받기위한 리스너 연결
  useEffect(() => {
    document.addEventListener("mousedown", onClickOutSide);

    return () => {
      document.removeEventListener("mousedown", onClickOutSide);
    }
  }, [])

  // 컴포넌트 외부 클릭시 실행
  function onClickOutSide(e) {
    if (ModalRef.current !== e.target && !ModalRef.current.contains(e.target)) {
      onClickExit();
    }
  }

  const handleClick = e => {
    if (pictures.length >= 10) {
      alert('사진은 최대 10장까지 업로드 가능합니다.');
      return;
    }

    hiddenFileInput.current.click();
  };

  const handleChange = e => {
    var pathpoint = e.target.value.lastIndexOf('.');
    var filepoint = e.target.value.substring(pathpoint + 1, e.target.length);
    var filetype = filepoint.toLowerCase();

    if (filetype === 'jpg' || filetype === 'png' || filetype === 'jpeg') {

      // 정상적인 이미지 확장자 파일인 경우
      const reader = new FileReader();
      // setImgLocalPath(e.target.value);
      var tmp = e.target.value.split('\\');
      // setImgLocalPath(tmp[tmp.length - 1]);

      reader.onloadend = () => {
        const base64 = reader.result;
        const naturalImage = new Image();

        naturalImage.src = reader.result.toString();
        naturalImage.onload = function (e) {

          let naturalWidth = e.target.naturalWidth;
          let naturalHeight = e.target.naturalHeight;

          let resizeWidth = 0;
          let resizeHeight = 0;

          if (naturalWidth < 600) {
            resizeWidth = naturalWidth;
            resizeHeight = naturalHeight;
          }
          else {
            resizeWidth = 600;
            resizeHeight = (600 * naturalHeight) / naturalWidth;
          }


          let finalHeight = (resizeHeight * 368) / resizeWidth;

          setPictureWidth(368);
          setPictureHeight(finalHeight);

          setPicture({ original: base64.toString(), edit: base64.toString(), division: 'Original' });
          setPictures([...pictures, { original: base64.toString(), edit: base64.toString(), division: 'Original' }]);
          setPictureIndex(pictures.length);
        }
      };
      if (e.target.files[0]) {
        reader.readAsDataURL(e.target.files[0]);
      }
    }
    else {
      alert('.jpeg .jpg .png 파일만 가능합니다!');
    }
  };

  // // 비율 변경 클릭시 //
  // function onClickFixedRatio(division) {
  //   let modifyPictures = [...pictures];
  //   console.log(division);
  //   if (division === 'Original') {
  //     let editData = modifyPictures[pictureIndex].original;

  //     modifyPictures[pictureIndex].edit = editData;
  //     modifyPictures[pictureIndex].division = 'Original';

  //     let img = document.createElement('img');
  //     img.src = editData;
      
  //     img.onload = () => {
  //       let maxWidth = 720;
  //       let maxHeight = 720;
  
  //       if (img.width > maxWidth || img.height > maxHeight) {
  //         // 가로가 더 길면 가로에 맞추기 //
  //         if (img.width > img.height) {
  //           let finalWidth = maxWidth;
  //           let finalHeight = (img.height * finalWidth) / img.width;
    
  //           setPictureWidth(finalWidth);
  //           setPictureHeight(finalHeight);
  //         }
  //         // 세로가 더 길면 세로에 맞추기 //
  //         else {
  //           let finalHeight = maxHeight;
  //           let finalWidth = (img.width * finalHeight) / img.height;
    
  //           setPictureWidth(finalWidth);
  //           setPictureHeight(finalHeight);
  //         }
  //       }
  //       else {
  //         setPictureWidth(img.width);
  //         setPictureHeight(img.height);
  //       }
  //     }

      
  //   }
  //   else if (division === '1:1') {
  //     let editData = modifyPictures[pictureIndex].original;

  //     modifyPictures[pictureIndex].edit = editData;
  //     modifyPictures[pictureIndex].division = 'Original';

  //     let img = document.createElement('img');
  //     img.src = editData;
      
  //     img.onload = () => {
  //       let maxWidth = 720;
  //       let maxHeight = 720;
  
  //       if (img.width > maxWidth || img.height > maxHeight) {
  //         // 가로가 더 길면 가로에 맞추기 //
  //         if (img.width > img.height) {
  //           let finalWidth = maxWidth;
  //           let finalHeight = (img.height * finalWidth) / img.width;
    
  //           setPictureWidth(finalWidth);
  //           setPictureHeight(finalHeight);
  //         }
  //         // 세로가 더 길면 세로에 맞추기 //
  //         else {
  //           let finalHeight = maxHeight;
  //           let finalWidth = (img.width * finalHeight) / img.height;
    
  //           setPictureWidth(finalWidth);
  //           setPictureHeight(finalHeight);
  //         }
  //       }
  //       else {
  //         setPictureWidth(img.width);
  //         setPictureHeight(img.height);
  //       }
  //     }
  //   }
  //   else if (division === '4:5') {

  //   }
  //   else if (division === '16:9') {

  //   }
  // }

  const resizeImage = async (ratioKey) => { 

    if (ratioKey === 'Original') {
      let modifyPictures = [...pictures];

      modifyPictures[pictureIndex].edit = modifyPictures[pictureIndex].original;
      modifyPictures[pictureIndex].division = 'Original';

      setPicture({ original: modifyPictures[pictureIndex].original, edit: modifyPictures[pictureIndex].edit, division: 'Original' });
      setPictures(modifyPictures);
    }
    else {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      
      // let img = document.createElement('img');
      let img = new Image();
      img.crossOrigin = "anonymous"
      // img.src = picture.original;
  
      if (picture.original.startsWith('https://')) img.src = picture.original + "?original" + (new Date().getTime());
      else img.src = picture.original;
      
      img.onload = () => {
        let maxWidth = 720;
        let maxHeight = 720;
        
        const imgRatio = img.width / img.height;
        const targetRatio = ratios[ratioKey].width / ratios[ratioKey].height;
        
        let finalWidth, finalHeight;
  
        // 비율에 맞게 크기 조정
        if (imgRatio > targetRatio) {
          finalWidth = maxWidth;
          finalHeight = maxWidth / targetRatio;
        } 
        else {
          finalHeight = maxHeight;
          finalWidth = maxHeight * targetRatio;
        }
        
        canvas.width = finalWidth;
        canvas.height = finalHeight;
        
        // 이미지 중앙 정렬
        const xOffset = (finalWidth - img.width) / 2;
        const yOffset = (finalHeight - img.height) / 2;
        
        ctx.clearRect(0, 0, finalWidth, finalHeight);
        ctx.drawImage(img, xOffset, yOffset, img.width, img.height);
        
        // 캔버스 데이터를 base64 문자열로 변환
        const editedImageURL = canvas.toDataURL();
        
        let modifyPictures = [...pictures];
        
        modifyPictures[pictureIndex].edit = editedImageURL;
        modifyPictures[pictureIndex].division = ratioKey;
        
        setPicture({ original: modifyPictures[pictureIndex].original, edit: modifyPictures[pictureIndex].edit, division: ratioKey });
        setPictures(modifyPictures);
      };
    }

    setIsClickedFixedRatioButton(false);
  };

  function onClickModal(e) {
    if (RatioButtonRef.current !== e.target 
      && RatioOptionRef.current !== e.target
      && !RatioButtonRef.current.contains(e.target)
      && !RatioButtonRef.current.contains(e.target)) {
      setIsClickedFixedRatioButton(false);
    }
  }

  return (
    <TopLevelWrapper>
      <TotalModal ref={ModalRef} onClick={onClickModal}>
        <StatusBar>
          <ExitButton onClick={onClickExit}>
            <Img width={12} height={17} src={LeftArrow}/>
          </ExitButton>
          <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF' cursor='default'>사진 편집</Text>
          <EmptyComponent width={28} height={28}/>
        </StatusBar>
        <canvas ref={canvasRef} style={{ display: 'none' }}/>
        <PictureZone>
          <TotalImageWrapper>
            <ImageWrapper width={pictureWidth} height={pictureHeight}>
              <Img width={pictureWidth} height={pictureHeight} src={picture.edit}/>
            </ImageWrapper>
            <DeleteButtonWrapper>
              <DeleteButton onClick={onClickDeletePicture}>
                <Img width={14} height={14} src={WhiteExitIcon} />
              </DeleteButton>
            </DeleteButtonWrapper>
          </TotalImageWrapper>
          <MoveButtonWrapper>
            <MoveButton isView={pictureIndex > 0} onClick={onClickPrevPicture}>
              <Img width={10} height={12} src={LeftArrow}/>
            </MoveButton>
            <MoveButton isView={pictureIndex < pictures.length - 1} onClick={onClickNextPicture}>
              <Img width={10} height={12} src={RightArrow}/>
            </MoveButton>
          </MoveButtonWrapper>
        </PictureZone>
        <AdditionButtonWrapper>
          <FixedRatioOptionBox ref={RatioOptionRef} isClicked={isClickedFixedRatioButton}>
            <FixedRatioOption 
              onMouseOver={() => setIsFirstHover(true)} 
              onMouseLeave={() => setIsFirstHover(false)}
              onClick={() => resizeImage('Original')}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color={(isFirstHover || picture.division === 'Original') ? '#FF8B48' : '#FFFFFF'} cursor='pointer'>원본</Text>
              <RatioIconWrapper>
                <OriginalImageIcon src={WhiteImageIcon} width={13} height={13} isHover={(!isFirstHover || picture.division === 'Original')}/>
                <OriginalImageIcon src={OrangeImageIcon} width={13} height={13} isHover={(isFirstHover || picture.division === 'Original')}/>
              </RatioIconWrapper>
            </FixedRatioOption>
            <DivisionLine/>
            <FixedRatioOption
              onMouseOver={() => setIsSecondHover(true)} 
              onMouseLeave={() => setIsSecondHover(false)}
              onClick={() => resizeImage('square')}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color={(isSecondHover || picture.division === 'square') ? '#FF8B48' : '#FFFFFF'} cursor='pointer'>1:1</Text>
              <RatioIconWrapper>
                <RatioIcon width={10} height={10} isHover={(isSecondHover || picture.division === 'square')}/>
              </RatioIconWrapper>
            </FixedRatioOption>
            <DivisionLine/>
            <FixedRatioOption
              onMouseOver={() => setIsThirdHover(true)} 
              onMouseLeave={() => setIsThirdHover(false)}
              onClick={() => resizeImage('portrait')}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color={(isThirdHover || picture.division === 'portrait') ? '#FF8B48' : '#FFFFFF'} cursor='pointer'>4:5</Text>
              <RatioIconWrapper>
                <RatioIcon width={5} height={10} isHover={(isThirdHover || picture.division === 'portrait')}/>
              </RatioIconWrapper>
            </FixedRatioOption>
            <DivisionLine/>
            <FixedRatioOption
              onMouseOver={() => setIsFourceHover(true)} 
              onMouseLeave={() => setIsFourceHover(false)}
              onClick={() => resizeImage('landscape')}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color={(isFourceHover || picture.division === 'landscape') ? '#FF8B48' : '#FFFFFF'} cursor='pointer'>16:9</Text>
              <RatioIconWrapper>
                <RatioIcon width={17} height={9} isHover={(isFourceHover || picture.division === 'landscape')}/>
              </RatioIconWrapper>
            </FixedRatioOption>
          </FixedRatioOptionBox>
          <FixedRatioButton ref={RatioButtonRef} onClick={() => setIsClickedFixedRatioButton(!isClickedFixedRatioButton)}>
            <Img width={17} height={17} src={EditRatioIcon}/>
          </FixedRatioButton>

          <input
            type="file"
            ref={hiddenFileInput}
            onChange={handleChange}
            style={{ display: 'none' }} />
            
          <AddtionPictureButton ref={FileInputRef} onClick={handleClick}>
            <Img width={20} height={20} src={WhiteCameraIcon}/>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='default'>사진 추가</Text>
          </AddtionPictureButton>
        </AdditionButtonWrapper>
      </TotalModal>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 1000;

  background-color: rgba(0, 0, 0, 0.2);
`;

const TotalModal = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;
  
  width: 720px;
  height: 760px;

  border-radius: 10px;
  background-color: #000000;

  overflow: hidden;
`;

const StatusBar = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;
  
  padding: 0px 8px;

  width: calc(100% - 16px);
  height: 41px;

  background-color: #1A1A1A;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 28px;
  height: 28px;

  :hover {
    cursor: pointer;
  }
`;

const EmptyComponent = styled.div`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;

const Img = styled.img`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;

const PictureZone = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 720px;
`;

const TotalImageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;

const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.width}px;
  height: ${props => props.height}px;

  // transition: all 0.3s ease-in-out;
`;

const DeleteButtonWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: flex-start;

  padding: 12px;

  width: calc(100% - 24px);
  height: calc(100% - 24px);
`;

const MoveButtonWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding: 0px 16px;

  width: calc(100% - 32px);
`;

const MoveButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 34px;
  height: 34px;

  border-radius: 34px;
  background-color: #191919;

  opacity: ${props => props.isView ? 1 : 0};

  :hover {
    cursor: ${props => props.isView ? `pointer` : `default`};
  }

  transition: all 0.3s ease-in-out;
`;

const AdditionButtonWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  padding: 0px 18px;

  margin-top: 705px;

  width: calc(100% - 36px);
  height: 55px;
`;

const DeleteButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 24px;
  height: 24px;

  border-radius: 24px;
  background-color: rgba(0, 0, 0, 0.6);

  :hover {
    cursor: pointer;
  }
`;

const FixedRatioButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 34px;
  height: 34px;

  border-radius: 34px;
  background-color: #191919;

  :hover {
    cursor: pointer;
  }
`;

const AddtionPictureButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 103px;
  height: 34px;

  gap: 4px;

  border-radius: 5px;
  background-color: #191919;

  :hover {
    cursor: pointer;
  }
`;

const FixedRatioOptionBox = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: -140px;

  width: 80px;
  height: 123px;

  z-index: ${props => props.isClicked ? '3' : '-1'};
  opacity: ${props => props.isClicked ? '1' : '0'};

  border-radius: 10px;
  background-color: #191919;

  overflow: hidden;

  transition: all 0.3s ease-in-out;
`;

const FixedRatioOption = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  // padding-left: 10px;
  padding: 0px 8px 0px 10px;

  width: calc(100% - 18px);
  height: 30px;

  :hover {
    cursor: pointer;
  }
`;

const RatioIconWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 20px;
`;

const OriginalImageIcon = styled.img`
  position: absolute;

  width: ${props => props.width}px;
  height: ${props => props.height}px;

  opacity: ${props => props.isHover ? 1 : 0};

  transition: all 0.3s ease-in-out;
`;

const RatioIcon = styled.div`
  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border: ${props => props.isHover ? '1px solid #FF8B48' : '1px solid #FFFFFF'};
  border-radius: 2px;

  transition: all 0.3s ease-in-out;
`;

const DivisionLine = styled.div`
  width: 100%;
  height: 1px;

  background-color: #FFFFFF;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }

  transition: all 0.3s ease-in-out;
`;