import { React, useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';
import lodash from 'lodash';

// Component Import //
import TotalTitleInputBox from '../../../Component/NoteComponent/TotalTitleInputBox';
import LessonSelectBox from '../../../Component/NoteComponent/LessonSelectBox';
import NewModifySectionBox from './NewModifySectionBox';
import WodLoadingModal from './WodLoadingModal';
import NewNormalSectionBox from './NewNormalSectionBox';
import useScrollToWithAnimation from '../../Share/Normal/useScrollToWithAnimation';
import YoutubeVideoURLInput from '../../../Component/NoteComponent/YoutubeVideoURLInput';
// Component Import //

// Image Import //
import PlusImage from '../../../image/PlusButton.png';
import TrashImage from '../../../image/TrashIcon.png';
import AdditionExerciseButtonImage from '../../../image/AdditionExerciseButton.png';
import YoutubeButtonImage from '../../../image/YoutubeButtonImage.png';
import LockingIcon from '../../../image/Lock.png';
import UnLockingIcon from '../../../image/UnLock.png';
// Image Import //

const NewExerciseBox = forwardRef((props, ref) => {
  const {
    isClickedModify,
    clickDate,
    clickWorkoutIndex,
    useData,
    selectedLesson,
    setSelectedLesson,
    onClickModifyButton,
  } = props;

  const { scrollToWithAnimation } = useScrollToWithAnimation();

  const [workoutId, setWorkoutId] = useState(0);
  const [title, setTitle] = useState('');
  const [sectionList, setSectionList] = useState([]);
  const [scroll, setScroll] = useState(0);
  const [nowSection, setNowSection] = useState(0);
  const [lastBottomMargin, setLastBottomMargin] = useState(0);
  const [isClickedWodLoading, setIsClickedWodLoading] = useState(false);
  const [originalLessonId, setOriginalLessonId] = useState(0);
  const [isLock, setIsLock] = useState(false);
  const [storeId, setStoreId] = useState(0);
  const [isClickedAdditionLink, setIsClickedAdditionLink] = useState(false);
  const [linkValue, setLinkValue] = useState('');

  const ScrollRef = useRef(null);
  const SectionRef = useRef([]);

  const EmptySection = {
    title: '',
    isUseTimeLimit: false,
    timeLimit: 0,
    isUseBuyin: false,
    buyinExerciseList: [],
    buyinScaleOptionExerciseList: [],
    // tag: 'ForTime-Rounds',
    tag: 'Custom',
    round: 1,
    roundList: [],
    reps: [0],
    exerciseTime: 0,
    restTime: 0,
    mainExerciseList: [],
    mainScaleOptionExerciseList: [],
    emomExerciseList: [],
    emomScaleOptionExerciseList: [],
    isUseBuyout: false,
    buyoutExerciseList: [],
    buyoutScaleOptionExerciseList: [],
    customTitle: '',
    scoreKeyword: '점수 없음',
    scorePriority: '높을 수록',
    description: '',
    buyinNote: '',
    mainNote: '',
    buyoutNote: '',
    isViewVideo: false,
    isClickedYoutubeButton: false,
    youtubeLink: '',
    youtubeLinks: []
  }

  useImperativeHandle(ref, () => ({
    getValue: () => {
      for (let i = 0; i < sectionList.length; i++) {
        // '점수 없음', '시간', '횟수', '라운드 + 횟수', '거리', '무게', '포인트', '피크와트', '최고 속도' //
        if (sectionList[i].scoreKeyword === '점수 없음') sectionList[i].scoreKeyword = 'Not Scored';
        else if (sectionList[i].scoreKeyword === '시간') sectionList[i].scoreKeyword = 'Time';
        else if (sectionList[i].scoreKeyword === '렙수') sectionList[i].scoreKeyword = 'Reps';
        else if (sectionList[i].scoreKeyword === '라운드 + 렙수') sectionList[i].scoreKeyword = 'Rounds And Reps';
        else if (sectionList[i].scoreKeyword === '거리') sectionList[i].scoreKeyword = 'Distance';
        else if (sectionList[i].scoreKeyword === '무게') sectionList[i].scoreKeyword = 'Weight';
        else if (sectionList[i].scoreKeyword === '포인트') sectionList[i].scoreKeyword = 'Points';
        else if (sectionList[i].scoreKeyword === '피크와트') sectionList[i].scoreKeyword = 'Peak Watts';
        else if (sectionList[i].scoreKeyword === '최고 속도') sectionList[i].scoreKeyword = 'Max Speed';

        if (sectionList[i].scorePriority === '높을 수록') sectionList[i].scorePriority = 'heigher';
        else if (sectionList[i].scorePriority === '낮을 수록') sectionList[i].scorePriority = 'lower';

        // // Exercise Weight에 ('/')가 포함되어있는지 확인 //
        // // Buyin 확인 //
        // for (let j = 0; j < sectionList[i].buyinExerciseList.length; j++) {
        //   // Buyin 운동중 무게가 구분데이터를 사용하는지 확인 //
        //   if (typeof (sectionList[i].buyinExerciseList[j].weight) === 'string' && sectionList[i].buyinExerciseList[j].weight.includes('/')) {
        //     sectionList[i].buyinExerciseList[j].divisionWeight = sectionList[i].buyinExerciseList[j].weight;
        //     sectionList[i].buyinExerciseList[j].weight = 0;
        //   }
        //   // Buyin 운동중 거리가 구분데이터를 사용하는지 확인 //
        //   if (typeof (sectionList[i].buyinExerciseList[j].distance) === 'string' && sectionList[i].buyinExerciseList[j].distance.includes('/')) {
        //     sectionList[i].buyinExerciseList[j].divisionDistance = sectionList[i].buyinExerciseList[j].distance;
        //     sectionList[i].buyinExerciseList[j].distance = parseFloat(sectionList[i].buyinExerciseList[j].distance.split('/')[0]);
        //   }
        //   // Buyin 운동중 높이가 구분데이터를 사용하는지 확인 //
        //   if (typeof (sectionList[i].buyinExerciseList[j].height) === 'string' && sectionList[i].buyinExerciseList[j].height.includes('/')) {
        //     sectionList[i].buyinExerciseList[j].divisionHeight = sectionList[i].buyinExerciseList[j].height;
        //     sectionList[i].buyinExerciseList[j].height = parseFloat(sectionList[i].buyinExerciseList[j].height.split('/')[0]);
        //   }
        //   // Buyin 운동중 칼로리가 구분데이터를 사용하는지 확인 //
        //   if (typeof (sectionList[i].buyinExerciseList[j].calories) === 'string' && sectionList[i].buyinExerciseList[j].calories.includes('/')) {
        //     sectionList[i].buyinExerciseList[j].divisionCalories = sectionList[i].buyinExerciseList[j].calories;
        //     sectionList[i].buyinExerciseList[j].calories = parseFloat(sectionList[i].buyinExerciseList[j].calories.split('/')[0]);
        //   }
        //   // Buyin 운동중 템포가 구분데이터를 사용하는지 확인 //
        //   if (typeof (sectionList[i].buyinExerciseList[j].tempo) === 'string' && sectionList[i].buyinExerciseList[j].tempo.includes('/')) {
        //     sectionList[i].buyinExerciseList[j].divisionTempo = sectionList[i].buyinExerciseList[j].tempo;
        //     sectionList[i].buyinExerciseList[j].tempo = parseInt(sectionList[i].buyinExerciseList[j].tempo.split('/')[0]);
        //   }
        // }

        // // Buyin Scale Option 확인 //
        // for (let j = 0; j < sectionList[i].buyinScaleOptionExerciseList.length; j++) {
        //   // Buyin 운동중 무게가 구분데이터를 사용하는지 확인 //
        //   if (typeof (sectionList[i].buyinScaleOptionExerciseList[j].weight) === 'string' && sectionList[i].buyinScaleOptionExerciseList[j].weight.includes('/')) {
        //     sectionList[i].buyinScaleOptionExerciseList[j].divisionWeight = sectionList[i].buyinScaleOptionExerciseList[j].weight;
        //     sectionList[i].buyinScaleOptionExerciseList[j].weight = 0;
        //   }
        //   // Buyin 운동중 거리가 구분데이터를 사용하는지 확인 //
        //   if (typeof (sectionList[i].buyinScaleOptionExerciseList[j].distance) === 'string' && sectionList[i].buyinScaleOptionExerciseList[j].distance.includes('/')) {
        //     sectionList[i].buyinScaleOptionExerciseList[j].divisionDistance = sectionList[i].buyinScaleOptionExerciseList[j].distance;
        //     sectionList[i].buyinScaleOptionExerciseList[j].distance = parseFloat(sectionList[i].buyinScaleOptionExerciseList[j].distance.split('/')[0]);
        //   }
        //   // Buyin 운동중 높이가 구분데이터를 사용하는지 확인 //
        //   if (typeof (sectionList[i].buyinScaleOptionExerciseList[j].height) === 'string' && sectionList[i].buyinScaleOptionExerciseList[j].height.includes('/')) {
        //     sectionList[i].buyinScaleOptionExerciseList[j].divisionHeight = sectionList[i].buyinScaleOptionExerciseList[j].height;
        //     sectionList[i].buyinScaleOptionExerciseList[j].height = parseFloat(sectionList[i].buyinScaleOptionExerciseList[j].height.split('/')[0]);
        //   }
        //   // Buyin 운동중 칼로리가 구분데이터를 사용하는지 확인 //
        //   if (typeof (sectionList[i].buyinScaleOptionExerciseList[j].calories) === 'string' && sectionList[i].buyinScaleOptionExerciseList[j].calories.includes('/')) {
        //     sectionList[i].buyinScaleOptionExerciseList[j].divisionCalories = sectionList[i].buyinScaleOptionExerciseList[j].calories;
        //     sectionList[i].buyinScaleOptionExerciseList[j].calories = parseFloat(sectionList[i].buyinScaleOptionExerciseList[j].calories.split('/')[0]);
        //   }
        //   // Buyin 운동중 템포가 구분데이터를 사용하는지 확인 //
        //   if (typeof (sectionList[i].buyinScaleOptionExerciseList[j].tempo) === 'string' && sectionList[i].buyinScaleOptionExerciseList[j].tempo.includes('/')) {
        //     sectionList[i].buyinScaleOptionExerciseList[j].divisionTempo = sectionList[i].buyinScaleOptionExerciseList[j].tempo;
        //     sectionList[i].buyinScaleOptionExerciseList[j].tempo = parseInt(sectionList[i].buyinScaleOptionExerciseList[j].tempo.split('/')[0]);
        //   }
        // }

        // // EMOM인 경우 데이터 따로 구성을 위해 검사 //
        // if (sectionList[i].tag === "EMOM") {
        //   // EMOM 확인 //
        //   for (let j = 0; j < sectionList[i].emomExerciseList.length; j++) {
        //     // 메인 운동중 무게가 구분데이터를 사용하는지 확인 //
        //     if (typeof (sectionList[i].emomExerciseList[j].weight) === 'string' && sectionList[i].emomExerciseList[j].weight.includes('/')) {
        //       sectionList[i].emomExerciseList[j].divisionWeight = sectionList[i].emomExerciseList[j].weight;
        //       sectionList[i].emomExerciseList[j].weight = 0;
        //     }
        //     // 메인 운동중 거리가 구분데이터를 사용하는지 확인 //
        //     if (typeof (sectionList[i].emomExerciseList[j].distance) === 'string' && sectionList[i].emomExerciseList[j].distance.includes('/')) {
        //       sectionList[i].emomExerciseList[j].divisionDistance = sectionList[i].emomExerciseList[j].distance;
        //       sectionList[i].emomExerciseList[j].distance = parseFloat(sectionList[i].emomExerciseList[j].distance.split('/')[0]);
        //     }
        //     // 메인 운동중 높이가 구분데이터를 사용하는지 확인 //
        //     if (typeof (sectionList[i].emomExerciseList[j].height) === 'string' && sectionList[i].emomExerciseList[j].height.includes('/')) {
        //       sectionList[i].emomExerciseList[j].divisionHeight = sectionList[i].emomExerciseList[j].height;
        //       sectionList[i].emomExerciseList[j].height = parseFloat(sectionList[i].emomExerciseList[j].height.split('/')[0]);
        //     }
        //     // 메인 운동중 칼로리가 구분데이터를 사용하는지 확인 //
        //     if (typeof (sectionList[i].emomExerciseList[j].calories) === 'string' && sectionList[i].emomExerciseList[j].calories.includes('/')) {
        //       sectionList[i].emomExerciseList[j].divisionCalories = sectionList[i].emomExerciseList[j].calories;
        //       sectionList[i].emomExerciseList[j].calories = parseFloat(sectionList[i].emomExerciseList[j].calories.split('/')[0]);
        //     }
        //     // 메인 운동중 템포가 구분데이터를 사용하는지 확인 //
        //     if (typeof (sectionList[i].emomExerciseList[j].tempo) === 'string' && sectionList[i].emomExerciseList[j].tempo.includes('/')) {
        //       sectionList[i].emomExerciseList[j].divisionTempo = sectionList[i].emomExerciseList[j].tempo;
        //       sectionList[i].emomExerciseList[j].tempo = parseInt(sectionList[i].emomExerciseList[j].tempo.split('/')[0]);
        //     }
        //     // 메인 운동중 Single일 경우만 해당 Sets가 구분데이터를 사용하는지 확인 //
        //     if (typeof (sectionList[i].emomExerciseList[j].sets) === 'string' && sectionList[i].emomExerciseList[j].sets.includes('/')) {
        //       sectionList[i].emomExerciseList[j].divisionSets = sectionList[i].emomExerciseList[j].sets;
        //       sectionList[i].emomExerciseList[j].sets = parseInt(sectionList[i].emomExerciseList[j].sets.split('/')[0]);
        //     }
        //   }

        //   // EMOM Scale Option 확인 //
        //   for (let j = 0; j < sectionList[i].emomScaleOptionExerciseList.length; j++) {
        //     // 메인 운동중 무게가 구분데이터를 사용하는지 확인 //
        //     if (typeof (sectionList[i].emomScaleOptionExerciseList[j].weight) === 'string' && sectionList[i].emomScaleOptionExerciseList[j].weight.includes('/')) {
        //       sectionList[i].emomScaleOptionExerciseList[j].divisionWeight = sectionList[i].emomScaleOptionExerciseList[j].weight;
        //       sectionList[i].emomScaleOptionExerciseList[j].weight = 0;
        //     }
        //     // 메인 운동중 거리가 구분데이터를 사용하는지 확인 //
        //     if (typeof (sectionList[i].emomScaleOptionExerciseList[j].distance) === 'string' && sectionList[i].emomScaleOptionExerciseList[j].distance.includes('/')) {
        //       sectionList[i].emomScaleOptionExerciseList[j].divisionDistance = sectionList[i].emomScaleOptionExerciseList[j].distance;
        //       sectionList[i].emomScaleOptionExerciseList[j].distance = parseFloat(sectionList[i].emomScaleOptionExerciseList[j].distance.split('/')[0]);
        //     }
        //     // 메인 운동중 높이가 구분데이터를 사용하는지 확인 //
        //     if (typeof (sectionList[i].emomScaleOptionExerciseList[j].height) === 'string' && sectionList[i].emomScaleOptionExerciseList[j].height.includes('/')) {
        //       sectionList[i].emomScaleOptionExerciseList[j].divisionHeight = sectionList[i].emomScaleOptionExerciseList[j].height;
        //       sectionList[i].emomScaleOptionExerciseList[j].height = parseFloat(sectionList[i].emomScaleOptionExerciseList[j].height.split('/')[0]);
        //     }
        //     // 메인 운동중 칼로리가 구분데이터를 사용하는지 확인 //
        //     if (typeof (sectionList[i].emomScaleOptionExerciseList[j].calories) === 'string' && sectionList[i].emomScaleOptionExerciseList[j].calories.includes('/')) {
        //       sectionList[i].emomScaleOptionExerciseList[j].divisionCalories = sectionList[i].emomScaleOptionExerciseList[j].calories;
        //       sectionList[i].emomScaleOptionExerciseList[j].calories = parseFloat(sectionList[i].emomScaleOptionExerciseList[j].calories.split('/')[0]);
        //     }
        //     // 메인 운동중 템포가 구분데이터를 사용하는지 확인 //
        //     if (typeof (sectionList[i].emomScaleOptionExerciseList[j].tempo) === 'string' && sectionList[i].emomScaleOptionExerciseList[j].tempo.includes('/')) {
        //       sectionList[i].emomScaleOptionExerciseList[j].divisionTempo = sectionList[i].emomScaleOptionExerciseList[j].tempo;
        //       sectionList[i].emomScaleOptionExerciseList[j].tempo = parseInt(sectionList[i].emomScaleOptionExerciseList[j].tempo.split('/')[0]);
        //     }
        //     // 메인 운동중 Single일 경우만 해당 Sets가 구분데이터를 사용하는지 확인 //
        //     if (typeof (sectionList[i].emomScaleOptionExerciseList[j].sets) === 'string' && sectionList[i].emomScaleOptionExerciseList[j].sets.includes('/')) {
        //       sectionList[i].emomScaleOptionExerciseList[j].divisionSets = sectionList[i].emomScaleOptionExerciseList[j].sets;
        //       sectionList[i].emomScaleOptionExerciseList[j].sets = parseInt(sectionList[i].emomScaleOptionExerciseList[j].sets.split('/')[0]);
        //     }
        //   }
        // }
        // // 그 외의 경우 //
        // else {
        //   // Main 확인 //
        //   for (let j = 0; j < sectionList[i].mainExerciseList.length; j++) {
        //     // 메인 운동중 무게가 구분데이터를 사용하는지 확인 //
        //     if (typeof (sectionList[i].mainExerciseList[j].weight) === 'string' && sectionList[i].mainExerciseList[j].weight.includes('/')) {
        //       sectionList[i].mainExerciseList[j].divisionWeight = sectionList[i].mainExerciseList[j].weight;
        //       sectionList[i].mainExerciseList[j].weight = 0;
        //     }
        //     // 메인 운동중 거리가 구분데이터를 사용하는지 확인 //
        //     if (typeof (sectionList[i].mainExerciseList[j].distance) === 'string' && sectionList[i].mainExerciseList[j].distance.includes('/')) {
        //       sectionList[i].mainExerciseList[j].divisionDistance = sectionList[i].mainExerciseList[j].distance;
        //       sectionList[i].mainExerciseList[j].distance = parseFloat(sectionList[i].mainExerciseList[j].distance.split('/')[0]);
        //     }
        //     // 메인 운동중 높이가 구분데이터를 사용하는지 확인 //
        //     if (typeof (sectionList[i].mainExerciseList[j].height) === 'string' && sectionList[i].mainExerciseList[j].height.includes('/')) {
        //       sectionList[i].mainExerciseList[j].divisionHeight = sectionList[i].mainExerciseList[j].height;
        //       sectionList[i].mainExerciseList[j].height = parseFloat(sectionList[i].mainExerciseList[j].height.split('/')[0]);
        //     }
        //     // 메인 운동중 칼로리가 구분데이터를 사용하는지 확인 //
        //     if (typeof (sectionList[i].mainExerciseList[j].calories) === 'string' && sectionList[i].mainExerciseList[j].calories.includes('/')) {
        //       sectionList[i].mainExerciseList[j].divisionCalories = sectionList[i].mainExerciseList[j].calories;
        //       sectionList[i].mainExerciseList[j].calories = parseFloat(sectionList[i].mainExerciseList[j].calories.split('/')[0]);
        //     }
        //     // 메인 운동중 템포가 구분데이터를 사용하는지 확인 //
        //     if (typeof (sectionList[i].mainExerciseList[j].tempo) === 'string' && sectionList[i].mainExerciseList[j].tempo.includes('/')) {
        //       sectionList[i].mainExerciseList[j].divisionTempo = sectionList[i].mainExerciseList[j].tempo;
        //       sectionList[i].mainExerciseList[j].tempo = parseInt(sectionList[i].mainExerciseList[j].tempo.split('/')[0]);
        //     }
        //     // 메인 운동중 Single일 경우만 해당 Sets가 구분데이터를 사용하는지 확인 //
        //     if (typeof (sectionList[i].mainExerciseList[j].sets) === 'string' && sectionList[i].mainExerciseList[j].sets.includes('/')) {
        //       sectionList[i].mainExerciseList[j].divisionSets = sectionList[i].mainExerciseList[j].sets;
        //       sectionList[i].mainExerciseList[j].sets = parseInt(sectionList[i].mainExerciseList[j].sets.split('/')[0]);
        //     }
        //     // // 시간에 '/', '~', '-', '.', '@'가 안들어가 있는경우 이전버전 호환을 위해 변환 //
        //     // if ()
        //   }

        //   // Main Scale Option 확인 //
        //   for (let j = 0; j < sectionList[i].mainScaleOptionExerciseList.length; j++) {
        //     // 메인 운동중 무게가 구분데이터를 사용하는지 확인 //
        //     if (typeof (sectionList[i].mainScaleOptionExerciseList[j].weight) === 'string' && sectionList[i].mainScaleOptionExerciseList[j].weight.includes('/')) {
        //       sectionList[i].mainScaleOptionExerciseList[j].divisionWeight = sectionList[i].mainScaleOptionExerciseList[j].weight;
        //       sectionList[i].mainScaleOptionExerciseList[j].weight = 0;
        //     }
        //     // 메인 운동중 거리가 구분데이터를 사용하는지 확인 //
        //     if (typeof (sectionList[i].mainScaleOptionExerciseList[j].distance) === 'string' && sectionList[i].mainScaleOptionExerciseList[j].distance.includes('/')) {
        //       sectionList[i].mainScaleOptionExerciseList[j].divisionDistance = sectionList[i].mainScaleOptionExerciseList[j].distance;
        //       sectionList[i].mainScaleOptionExerciseList[j].distance = parseFloat(sectionList[i].mainScaleOptionExerciseList[j].distance.split('/')[0]);
        //     }
        //     // 메인 운동중 높이가 구분데이터를 사용하는지 확인 //
        //     if (typeof (sectionList[i].mainScaleOptionExerciseList[j].height) === 'string' && sectionList[i].mainScaleOptionExerciseList[j].height.includes('/')) {
        //       sectionList[i].mainScaleOptionExerciseList[j].divisionHeight = sectionList[i].mainScaleOptionExerciseList[j].height;
        //       sectionList[i].mainScaleOptionExerciseList[j].height = parseFloat(sectionList[i].mainScaleOptionExerciseList[j].height.split('/')[0]);
        //     }
        //     // 메인 운동중 칼로리가 구분데이터를 사용하는지 확인 //
        //     if (typeof (sectionList[i].mainScaleOptionExerciseList[j].calories) === 'string' && sectionList[i].mainScaleOptionExerciseList[j].calories.includes('/')) {
        //       sectionList[i].mainScaleOptionExerciseList[j].divisionCalories = sectionList[i].mainScaleOptionExerciseList[j].calories;
        //       sectionList[i].mainScaleOptionExerciseList[j].calories = parseFloat(sectionList[i].mainScaleOptionExerciseList[j].calories.split('/')[0]);
        //     }
        //     // 메인 운동중 템포가 구분데이터를 사용하는지 확인 //
        //     if (typeof (sectionList[i].mainScaleOptionExerciseList[j].tempo) === 'string' && sectionList[i].mainScaleOptionExerciseList[j].tempo.includes('/')) {
        //       sectionList[i].mainScaleOptionExerciseList[j].divisionTempo = sectionList[i].mainScaleOptionExerciseList[j].tempo;
        //       sectionList[i].mainScaleOptionExerciseList[j].tempo = parseInt(sectionList[i].mainScaleOptionExerciseList[j].tempo.split('/')[0]);
        //     }
        //     // 메인 운동중 Single일 경우만 해당 Sets가 구분데이터를 사용하는지 확인 //
        //     if (typeof (sectionList[i].mainScaleOptionExerciseList[j].sets) === 'string' && sectionList[i].mainScaleOptionExerciseList[j].sets.includes('/')) {
        //       sectionList[i].mainScaleOptionExerciseList[j].divisionSets = sectionList[i].mainScaleOptionExerciseList[j].sets;
        //       sectionList[i].mainScaleOptionExerciseList[j].sets = parseInt(sectionList[i].mainScaleOptionExerciseList[j].sets.split('/')[0]);
        //     }
        //   }
        // }

        // // Buyout 확인 //
        // for (let j = 0; j < sectionList[i].buyoutExerciseList.length; j++) {
        //   // Buyout 운동중 무게가 구분데이터를 사용하는지 확인 //
        //   if (typeof (sectionList[i].buyoutExerciseList[j].weight) === 'string' && sectionList[i].buyoutExerciseList[j].weight.includes('/')) {
        //     sectionList[i].buyoutExerciseList[j].divisionWeight = sectionList[i].buyoutExerciseList[j].weight;
        //     sectionList[i].buyoutExerciseList[j].weight = 0;
        //   }
        //   // Buyout 운동중 거리가 구분데이터를 사용하는지 확인 //
        //   if (typeof (sectionList[i].buyoutExerciseList[j].distance) === 'string' && sectionList[i].buyoutExerciseList[j].distance.includes('/')) {
        //     sectionList[i].buyoutExerciseList[j].divisionDistance = sectionList[i].buyoutExerciseList[j].distance;
        //     sectionList[i].buyoutExerciseList[j].distance = parseFloat(sectionList[i].buyoutExerciseList[j].distance.split('/')[0]);
        //   }
        //   // Buyout 운동중 높이가 구분데이터를 사용하는지 확인 //
        //   if (typeof (sectionList[i].buyoutExerciseList[j].height) === 'string' && sectionList[i].buyoutExerciseList[j].height.includes('/')) {
        //     sectionList[i].buyoutExerciseList[j].divisionHeight = sectionList[i].buyoutExerciseList[j].height;
        //     sectionList[i].buyoutExerciseList[j].height = parseFloat(sectionList[i].buyoutExerciseList[j].height.split('/')[0]);
        //   }
        //   // Buyout 운동중 칼로리가 구분데이터를 사용하는지 확인 //
        //   if (typeof (sectionList[i].buyoutExerciseList[j].calories) === 'string' && sectionList[i].buyoutExerciseList[j].calories.includes('/')) {
        //     sectionList[i].buyoutExerciseList[j].divisionCalories = sectionList[i].buyoutExerciseList[j].calories;
        //     sectionList[i].buyoutExerciseList[j].calories = parseFloat(sectionList[i].buyoutExerciseList[j].calories.split('/')[0]);
        //   }
        //   // Buyout 운동중 템포가 구분데이터를 사용하는지 확인 //
        //   if (typeof (sectionList[i].buyoutExerciseList[j].tempo) === 'string' && sectionList[i].buyoutExerciseList[j].tempo.includes('/')) {
        //     sectionList[i].buyoutExerciseList[j].divisionTempo = sectionList[i].buyoutExerciseList[j].tempo;
        //     sectionList[i].buyoutExerciseList[j].tempo = parseInt(sectionList[i].buyoutExerciseList[j].tempo.split('/')[0]);
        //   }
        // }

        // // Buyout 확인 //
        // for (let j = 0; j < sectionList[i].buyoutScaleOptionExerciseList.length; j++) {
        //   // Buyout 운동중 무게가 구분데이터를 사용하는지 확인 //
        //   if (typeof (sectionList[i].buyoutScaleOptionExerciseList[j].weight) === 'string' && sectionList[i].buyouScaleOptiontExerciseList[j].weight.includes('/')) {
        //     sectionList[i].buyoutScaleOptionExerciseList[j].divisionWeight = sectionList[i].buyoutScaleOptionExerciseList[j].weight;
        //     sectionList[i].buyoutScaleOptionExerciseList[j].weight = 0;
        //   }
        //   // Buyout 운동중 거리가 구분데이터를 사용하는지 확인 //
        //   if (typeof (sectionList[i].buyoutScaleOptionExerciseList[j].distance) === 'string' && sectionList[i].buyoutScaleOptionExerciseList[j].distance.includes('/')) {
        //     sectionList[i].buyoutScaleOptionExerciseList[j].divisionDistance = sectionList[i].buyoutScaleOptionExerciseList[j].distance;
        //     sectionList[i].buyoutScaleOptionExerciseList[j].distance = parseFloat(sectionList[i].buyoutScaleOptionExerciseList[j].distance.split('/')[0]);
        //   }
        //   // Buyout 운동중 높이가 구분데이터를 사용하는지 확인 //
        //   if (typeof (sectionList[i].buyoutScaleOptionExerciseList[j].height) === 'string' && sectionList[i].buyoutScaleOptionExerciseList[j].height.includes('/')) {
        //     sectionList[i].buyoutScaleOptionExerciseList[j].divisionHeight = sectionList[i].buyoutScaleOptionExerciseList[j].height;
        //     sectionList[i].buyoutScaleOptionExerciseList[j].height = parseFloat(sectionList[i].buyoutScaleOptionExerciseList[j].height.split('/')[0]);
        //   }
        //   // Buyout 운동중 칼로리가 구분데이터를 사용하는지 확인 //
        //   if (typeof (sectionList[i].buyoutScaleOptionExerciseList[j].calories) === 'string' && sectionList[i].buyoutScaleOptionExerciseList[j].calories.includes('/')) {
        //     sectionList[i].buyoutScaleOptionExerciseList[j].divisionCalories = sectionList[i].buyoutScaleOptionExerciseList[j].calories;
        //     sectionList[i].buyoutScaleOptionExerciseList[j].calories = parseFloat(sectionList[i].buyoutScaleOptionExerciseList[j].calories.split('/')[0]);
        //   }
        //   // Buyout 운동중 템포가 구분데이터를 사용하는지 확인 //
        //   if (typeof (sectionList[i].buyoutScaleOptionExerciseList[j].tempo) === 'string' && sectionList[i].buyoutScaleOptionExerciseList[j].tempo.includes('/')) {
        //     sectionList[i].buyoutScaleOptionExerciseList[j].divisionTempo = sectionList[i].buyoutScaleOptionExerciseList[j].tempo;
        //     sectionList[i].buyoutScaleOptionExerciseList[j].tempo = parseInt(sectionList[i].buyoutScaleOptionExerciseList[j].tempo.split('/')[0]);
        //   }
        // }

        // Buyin 확인 //
        for (let j = 0; j < sectionList[i].buyinExerciseList.length; j++) {
          // Buyin 운동중 무게가 구분데이터를 사용하는지 확인 //
          if (typeof (sectionList[i].buyinExerciseList[j].weight) === 'string' && (sectionList[i].buyinExerciseList[j].weight.includes('/')
            || sectionList[i].buyinExerciseList[j].weight.includes('~')
            || sectionList[i].buyinExerciseList[j].weight.includes('-')
            || sectionList[i].buyinExerciseList[j].weight.includes('@')
            || sectionList[i].buyinExerciseList[j].weight.includes('.'))) {
            sectionList[i].buyinExerciseList[j].divisionWeight = sectionList[i].buyinExerciseList[j].weight;
            sectionList[i].buyinExerciseList[j].weight = 0;
          }
          // Buyin 운동중 거리가 구분데이터를 사용하는지 확인 //
          if (typeof (sectionList[i].buyinExerciseList[j].distance) === 'string' && (sectionList[i].buyinExerciseList[j].distance.includes('/')
            || sectionList[i].buyinExerciseList[j].distance.includes('~')
            || sectionList[i].buyinExerciseList[j].distance.includes('-')
            || sectionList[i].buyinExerciseList[j].distance.includes('@')
            || sectionList[i].buyinExerciseList[j].distance.includes('.'))) {
            sectionList[i].buyinExerciseList[j].divisionDistance = sectionList[i].buyinExerciseList[j].distance;
            sectionList[i].buyinExerciseList[j].distance = 0;
            // sectionList[i].buyinExerciseList[j].distance = parseFloat(sectionList[i].buyinExerciseList[j].distance.split('/')[0]);
          }
          // Buyin 운동중 높이가 구분데이터를 사용하는지 확인 //
          if (typeof (sectionList[i].buyinExerciseList[j].height) === 'string' && (sectionList[i].buyinExerciseList[j].height.includes('/')
            || sectionList[i].buyinExerciseList[j].height.includes('~')
            || sectionList[i].buyinExerciseList[j].height.includes('-')
            || sectionList[i].buyinExerciseList[j].height.includes('@')
            || sectionList[i].buyinExerciseList[j].height.includes('.'))) {
            sectionList[i].buyinExerciseList[j].divisionHeight = sectionList[i].buyinExerciseList[j].height;
            sectionList[i].buyinExerciseList[j].height = 0;
            // sectionList[i].buyinExerciseList[j].height = parseFloat(sectionList[i].buyinExerciseList[j].height.split('/')[0]);
          }
          // Buyin 운동중 칼로리가 구분데이터를 사용하는지 확인 //
          if (typeof (sectionList[i].buyinExerciseList[j].calories) === 'string' && (sectionList[i].buyinExerciseList[j].calories.includes('/')
            || sectionList[i].buyinExerciseList[j].calories.includes('~')
            || sectionList[i].buyinExerciseList[j].calories.includes('-')
            || sectionList[i].buyinExerciseList[j].calories.includes('@')
            || sectionList[i].buyinExerciseList[j].calories.includes('.'))) {
            sectionList[i].buyinExerciseList[j].divisionCalories = sectionList[i].buyinExerciseList[j].calories;
            sectionList[i].buyinExerciseList[j].calories = 0;
            // sectionList[i].buyinExerciseList[j].calories = parseFloat(sectionList[i].buyinExerciseList[j].calories.split('/')[0]);
          }
          // Buyin 운동중 템포가 구분데이터를 사용하는지 확인 //
          if (typeof (sectionList[i].buyinExerciseList[j].tempo) === 'string' && (sectionList[i].buyinExerciseList[j].tempo.includes('/')
            || sectionList[i].buyinExerciseList[j].tempo.includes('~')
            || sectionList[i].buyinExerciseList[j].tempo.includes('-')
            || sectionList[i].buyinExerciseList[j].tempo.includes('@')
            || sectionList[i].buyinExerciseList[j].tempo.includes('.'))) {
            sectionList[i].buyinExerciseList[j].divisionTempo = sectionList[i].buyinExerciseList[j].tempo;
            sectionList[i].buyinExerciseList[j].tempo = 0;
            // sectionList[i].buyinExerciseList[j].tempo = parseInt(sectionList[i].buyinExerciseList[j].tempo.split('/')[0]);
          }
        }

        // Buyin Scale Option 확인 //
        for (let j = 0; j < sectionList[i].buyinScaleOptionExerciseList.length; j++) {
          // Buyin 운동중 무게가 구분데이터를 사용하는지 확인 //
          if (typeof (sectionList[i].buyinScaleOptionExerciseList[j].weight) === 'string' && (sectionList[i].buyinScaleOptionExerciseList[j].weight.includes('/')
            || sectionList[i].buyinScaleOptionExerciseList[j].weight.includes('~')
            || sectionList[i].buyinScaleOptionExerciseList[j].weight.includes('-')
            || sectionList[i].buyinScaleOptionExerciseList[j].weight.includes('@')
            || sectionList[i].buyinScaleOptionExerciseList[j].weight.includes('.'))) {
            sectionList[i].buyinScaleOptionExerciseList[j].divisionWeight = sectionList[i].buyinScaleOptionExerciseList[j].weight;
            sectionList[i].buyinScaleOptionExerciseList[j].weight = 0;
          }
          // Buyin 운동중 거리가 구분데이터를 사용하는지 확인 //
          if (typeof (sectionList[i].buyinScaleOptionExerciseList[j].distance) === 'string' && (sectionList[i].buyinScaleOptionExerciseList[j].distance.includes('/')
            || sectionList[i].buyinScaleOptionExerciseList[j].distance.includes('~')
            || sectionList[i].buyinScaleOptionExerciseList[j].distance.includes('-')
            || sectionList[i].buyinScaleOptionExerciseList[j].distance.includes('@')
            || sectionList[i].buyinScaleOptionExerciseList[j].distance.includes('.'))) {
            sectionList[i].buyinScaleOptionExerciseList[j].divisionDistance = sectionList[i].buyinScaleOptionExerciseList[j].distance;
            sectionList[i].buyinScaleOptionExerciseList[j].distance = 0;
            // sectionList[i].buyinScaleOptionExerciseList[j].distance = parseFloat(sectionList[i].buyinScaleOptionExerciseList[j].distance.split('/')[0]);
          }
          // Buyin 운동중 높이가 구분데이터를 사용하는지 확인 //
          if (typeof (sectionList[i].buyinScaleOptionExerciseList[j].height) === 'string' && (sectionList[i].buyinScaleOptionExerciseList[j].height.includes('/')
            || sectionList[i].buyinScaleOptionExerciseList[j].height.includes('~')
            || sectionList[i].buyinScaleOptionExerciseList[j].height.includes('-')
            || sectionList[i].buyinScaleOptionExerciseList[j].height.includes('@')
            || sectionList[i].buyinScaleOptionExerciseList[j].height.includes('.'))) {
            sectionList[i].buyinScaleOptionExerciseList[j].divisionHeight = sectionList[i].buyinScaleOptionExerciseList[j].height;
            sectionList[i].buyinScaleOptionExerciseList[j].height = 0;
            // sectionList[i].buyinScaleOptionExerciseList[j].height = parseFloat(sectionList[i].buyinScaleOptionExerciseList[j].height.split('/')[0]);
          }
          // Buyin 운동중 칼로리가 구분데이터를 사용하는지 확인 //
          if (typeof (sectionList[i].buyinScaleOptionExerciseList[j].calories) === 'string' && (sectionList[i].buyinScaleOptionExerciseList[j].calories.includes('/')
            || sectionList[i].buyinScaleOptionExerciseList[j].calories.includes('~')
            || sectionList[i].buyinScaleOptionExerciseList[j].calories.includes('-')
            || sectionList[i].buyinScaleOptionExerciseList[j].calories.includes('@')
            || sectionList[i].buyinScaleOptionExerciseList[j].calories.includes('.'))) {
            sectionList[i].buyinScaleOptionExerciseList[j].divisionCalories = sectionList[i].buyinScaleOptionExerciseList[j].calories;
            sectionList[i].buyinScaleOptionExerciseList[j].calories = 0;
            // sectionList[i].buyinScaleOptionExerciseList[j].calories = parseFloat(sectionList[i].buyinScaleOptionExerciseList[j].calories.split('/')[0]);
          }
          // Buyin 운동중 템포가 구분데이터를 사용하는지 확인 //
          if (typeof (sectionList[i].buyinScaleOptionExerciseList[j].tempo) === 'string' && (sectionList[i].buyinScaleOptionExerciseList[j].tempo.includes('/')
            || sectionList[i].buyinScaleOptionExerciseList[j].tempo.includes('~')
            || sectionList[i].buyinScaleOptionExerciseList[j].tempo.includes('-')
            || sectionList[i].buyinScaleOptionExerciseList[j].tempo.includes('@')
            || sectionList[i].buyinScaleOptionExerciseList[j].tempo.includes('.'))) {
            sectionList[i].buyinScaleOptionExerciseList[j].divisionTempo = sectionList[i].buyinScaleOptionExerciseList[j].tempo;
            sectionList[i].buyinScaleOptionExerciseList[j].tempo = 0;
            // sectionList[i].buyinScaleOptionExerciseList[j].tempo = parseInt(sectionList[i].buyinScaleOptionExerciseList[j].tempo.split('/')[0]);
          }
        }

        // EMOM인 경우 데이터 따로 구성을 위해 검사 //
        if (sectionList[i].tag === "EMOM") {
          // EMOM 확인 //
          for (let j = 0; j < sectionList[i].emomExerciseList.length; j++) {
            // 메인 운동중 무게가 구분데이터를 사용하는지 확인 //
            if (typeof (sectionList[i].emomExerciseList[j].weight) === 'string' && (sectionList[i].emomExerciseList[j].weight.includes('/')
              || sectionList[i].emomExerciseList[j].weight.includes('~')
              || sectionList[i].emomExerciseList[j].weight.includes('-')
              || sectionList[i].emomExerciseList[j].weight.includes('@')
              || sectionList[i].emomExerciseList[j].weight.includes('.'))) {
              sectionList[i].emomExerciseList[j].divisionWeight = sectionList[i].emomExerciseList[j].weight;
              sectionList[i].emomExerciseList[j].weight = 0;
            }
            // 메인 운동중 거리가 구분데이터를 사용하는지 확인 //
            if (typeof (sectionList[i].emomExerciseList[j].distance) === 'string' && (sectionList[i].emomExerciseList[j].distance.includes('/')
              || sectionList[i].emomExerciseList[j].distance.includes('~')
              || sectionList[i].emomExerciseList[j].distance.includes('-')
              || sectionList[i].emomExerciseList[j].distance.includes('@')
              || sectionList[i].emomExerciseList[j].distance.includes('.'))) {
              sectionList[i].emomExerciseList[j].divisionDistance = sectionList[i].emomExerciseList[j].distance;
              sectionList[i].emomExerciseList[j].distance = 0;
              // sectionList[i].emomExerciseList[j].distance = parseFloat(sectionList[i].emomExerciseList[j].distance.split('/')[0]);
            }
            // 메인 운동중 높이가 구분데이터를 사용하는지 확인 //
            if (typeof (sectionList[i].emomExerciseList[j].height) === 'string' && (sectionList[i].emomExerciseList[j].height.includes('/')
              || sectionList[i].emomExerciseList[j].height.includes('~')
              || sectionList[i].emomExerciseList[j].height.includes('-')
              || sectionList[i].emomExerciseList[j].height.includes('@')
              || sectionList[i].emomExerciseList[j].height.includes('.'))) {
              sectionList[i].emomExerciseList[j].divisionHeight = sectionList[i].emomExerciseList[j].height;
              sectionList[i].emomExerciseList[j].height = 0;
              // sectionList[i].emomExerciseList[j].height = parseFloat(sectionList[i].emomExerciseList[j].height.split('/')[0]);
            }
            // 메인 운동중 칼로리가 구분데이터를 사용하는지 확인 //
            if (typeof (sectionList[i].emomExerciseList[j].calories) === 'string' && (sectionList[i].emomExerciseList[j].calories.includes('/')
              || sectionList[i].emomExerciseList[j].calories.includes('~')
              || sectionList[i].emomExerciseList[j].calories.includes('-')
              || sectionList[i].emomExerciseList[j].calories.includes('@')
              || sectionList[i].emomExerciseList[j].calories.includes('.'))) {
              sectionList[i].emomExerciseList[j].divisionCalories = sectionList[i].emomExerciseList[j].calories;
              sectionList[i].emomExerciseList[j].calories = 0;
              // sectionList[i].emomExerciseList[j].calories = parseFloat(sectionList[i].emomExerciseList[j].calories.split('/')[0]);
            }
            // 메인 운동중 템포가 구분데이터를 사용하는지 확인 //
            if (typeof (sectionList[i].emomExerciseList[j].tempo) === 'string' && (sectionList[i].emomExerciseList[j].tempo.includes('/')
              || sectionList[i].emomExerciseList[j].tempo.includes('~')
              || sectionList[i].emomExerciseList[j].tempo.includes('-')
              || sectionList[i].emomExerciseList[j].tempo.includes('@')
              || sectionList[i].emomExerciseList[j].tempo.includes('.'))) {
              sectionList[i].emomExerciseList[j].divisionTempo = sectionList[i].emomExerciseList[j].tempo;
              sectionList[i].emomExerciseList[j].tempo = 0;
              // sectionList[i].emomExerciseList[j].tempo = parseInt(sectionList[i].emomExerciseList[j].tempo.split('/')[0]);
            }
          }

          // EMOM Scale Option 확인 //
          for (let j = 0; j < sectionList[i].emomScaleOptionExerciseList.length; j++) {
            // 메인 운동중 무게가 구분데이터를 사용하는지 확인 //
            if (typeof (sectionList[i].emomScaleOptionExerciseList[j].weight) === 'string' && (sectionList[i].emomScaleOptionExerciseList[j].weight.includes('/')
              || sectionList[i].emomScaleOptionExerciseList[j].weight.includes('~')
              || sectionList[i].emomScaleOptionExerciseList[j].weight.includes('-')
              || sectionList[i].emomScaleOptionExerciseList[j].weight.includes('@')
              || sectionList[i].emomScaleOptionExerciseList[j].weight.includes('.'))) {
              sectionList[i].emomScaleOptionExerciseList[j].divisionWeight = sectionList[i].emomScaleOptionExerciseList[j].weight;
              sectionList[i].emomScaleOptionExerciseList[j].weight = 0;
            }
            // 메인 운동중 거리가 구분데이터를 사용하는지 확인 //
            if (typeof (sectionList[i].emomScaleOptionExerciseList[j].distance) === 'string' && (sectionList[i].emomScaleOptionExerciseList[j].distance.includes('/')
              || sectionList[i].emomScaleOptionExerciseList[j].distance.includes('~')
              || sectionList[i].emomScaleOptionExerciseList[j].distance.includes('-')
              || sectionList[i].emomScaleOptionExerciseList[j].distance.includes('@')
              || sectionList[i].emomScaleOptionExerciseList[j].distance.includes('.'))) {
              sectionList[i].emomScaleOptionExerciseList[j].divisionDistance = sectionList[i].emomScaleOptionExerciseList[j].distance;
              sectionList[i].emomScaleOptionExerciseList[j].distance = 0;
            }
            // 메인 운동중 높이가 구분데이터를 사용하는지 확인 //
            if (typeof (sectionList[i].emomScaleOptionExerciseList[j].height) === 'string' && (sectionList[i].emomScaleOptionExerciseList[j].height.includes('/')
              || sectionList[i].emomScaleOptionExerciseList[j].height.includes('~')
              || sectionList[i].emomScaleOptionExerciseList[j].height.includes('-')
              || sectionList[i].emomScaleOptionExerciseList[j].height.includes('@')
              || sectionList[i].emomScaleOptionExerciseList[j].height.includes('.'))) {
              sectionList[i].emomScaleOptionExerciseList[j].divisionHeight = sectionList[i].emomScaleOptionExerciseList[j].height;
              sectionList[i].emomScaleOptionExerciseList[j].height = 0;
              // sectionList[i].emomScaleOptionExerciseList[j].height = parseFloat(sectionList[i].emomScaleOptionExerciseList[j].height.split('/')[0]);
            }
            // 메인 운동중 칼로리가 구분데이터를 사용하는지 확인 //
            if (typeof (sectionList[i].emomScaleOptionExerciseList[j].calories) === 'string' && (sectionList[i].emomScaleOptionExerciseList[j].calories.includes('/')
              || sectionList[i].emomScaleOptionExerciseList[j].calories.includes('~')
              || sectionList[i].emomScaleOptionExerciseList[j].calories.includes('-')
              || sectionList[i].emomScaleOptionExerciseList[j].calories.includes('@')
              || sectionList[i].emomScaleOptionExerciseList[j].calories.includes('.'))) {
              sectionList[i].emomScaleOptionExerciseList[j].divisionCalories = sectionList[i].emomScaleOptionExerciseList[j].calories;
              sectionList[i].emomScaleOptionExerciseList[j].calories = 0;
              // sectionList[i].emomScaleOptionExerciseList[j].calories = parseFloat(sectionList[i].emomScaleOptionExerciseList[j].calories.split('/')[0]);
            }
            // 메인 운동중 템포가 구분데이터를 사용하는지 확인 //
            if (typeof (sectionList[i].emomScaleOptionExerciseList[j].tempo) === 'string' && (sectionList[i].emomScaleOptionExerciseList[j].tempo.includes('/')
              || sectionList[i].emomScaleOptionExerciseList[j].tempo.includes('~')
              || sectionList[i].emomScaleOptionExerciseList[j].tempo.includes('-')
              || sectionList[i].emomScaleOptionExerciseList[j].tempo.includes('@')
              || sectionList[i].emomScaleOptionExerciseList[j].tempo.includes('.'))) {
              sectionList[i].emomScaleOptionExerciseList[j].divisionTempo = sectionList[i].emomScaleOptionExerciseList[j].tempo;
              sectionList[i].emomScaleOptionExerciseList[j].tempo = 0;
              // sectionList[i].emomScaleOptionExerciseList[j].tempo = parseInt(sectionList[i].emomScaleOptionExerciseList[j].tempo.split('/')[0]);
            }
          }

          // EMOM에서 다루는 Main 데이터 수정 //
          // Main 확인 //
          for (let j = 0; j < sectionList[i].mainExerciseList.length; j++) {
            // 메인 운동중 무게가 구분데이터를 사용하는지 확인 //
            if (typeof (sectionList[i].mainExerciseList[j].weight) === 'string' && (sectionList[i].mainExerciseList[j].weight.includes('/')
              || sectionList[i].mainExerciseList[j].weight.includes('~')
              || sectionList[i].mainExerciseList[j].weight.includes('-')
              || sectionList[i].mainExerciseList[j].weight.includes('@')
              || sectionList[i].mainExerciseList[j].weight.includes('.'))) {
              sectionList[i].mainExerciseList[j].divisionWeight = sectionList[i].mainExerciseList[j].weight;
              sectionList[i].mainExerciseList[j].weight = 0;
            }
            // 메인 운동중 거리가 구분데이터를 사용하는지 확인 //
            if (typeof (sectionList[i].mainExerciseList[j].distance) === 'string' && (sectionList[i].mainExerciseList[j].distance.includes('/')
              || sectionList[i].mainExerciseList[j].distance.includes('~')
              || sectionList[i].mainExerciseList[j].distance.includes('-')
              || sectionList[i].mainExerciseList[j].distance.includes('@')
              || sectionList[i].mainExerciseList[j].distance.includes('.'))) {
              sectionList[i].mainExerciseList[j].divisionDistance = sectionList[i].mainExerciseList[j].distance;
              sectionList[i].mainExerciseList[j].distance = 0;
              // sectionList[i].mainExerciseList[j].distance = parseFloat(sectionList[i].mainExerciseList[j].distance.split('/')[0]);
            }
            // 메인 운동중 높이가 구분데이터를 사용하는지 확인 //
            if (typeof (sectionList[i].mainExerciseList[j].height) === 'string' && (sectionList[i].mainExerciseList[j].height.includes('/')
              || sectionList[i].mainExerciseList[j].height.includes('~')
              || sectionList[i].mainExerciseList[j].height.includes('-')
              || sectionList[i].mainExerciseList[j].height.includes('@')
              || sectionList[i].mainExerciseList[j].height.includes('.'))) {
              sectionList[i].mainExerciseList[j].divisionHeight = sectionList[i].mainExerciseList[j].height;
              sectionList[i].mainExerciseList[j].height = 0;
              // sectionList[i].mainExerciseList[j].height = parseFloat(sectionList[i].mainExerciseList[j].height.split('/')[0]);
            }
            // 메인 운동중 칼로리가 구분데이터를 사용하는지 확인 //
            if (typeof (sectionList[i].mainExerciseList[j].calories) === 'string' && (sectionList[i].mainExerciseList[j].calories.includes('/')
              || sectionList[i].mainExerciseList[j].calories.includes('~')
              || sectionList[i].mainExerciseList[j].calories.includes('-')
              || sectionList[i].mainExerciseList[j].calories.includes('@')
              || sectionList[i].mainExerciseList[j].calories.includes('.'))) {
              sectionList[i].mainExerciseList[j].divisionCalories = sectionList[i].mainExerciseList[j].calories;
              sectionList[i].mainExerciseList[j].calories = 0;
              // sectionList[i].mainExerciseList[j].calories = parseFloat(sectionList[i].mainExerciseList[j].calories.split('/')[0]);
            }
            // 메인 운동중 템포가 구분데이터를 사용하는지 확인 //
            if (typeof (sectionList[i].mainExerciseList[j].tempo) === 'string' && (sectionList[i].mainExerciseList[j].tempo.includes('/')
              || sectionList[i].mainExerciseList[j].tempo.includes('~')
              || sectionList[i].mainExerciseList[j].tempo.includes('-')
              || sectionList[i].mainExerciseList[j].tempo.includes('@')
              || sectionList[i].mainExerciseList[j].tempo.includes('.'))) {
              sectionList[i].mainExerciseList[j].divisionTempo = sectionList[i].mainExerciseList[j].tempo;
              sectionList[i].mainExerciseList[j].tempo = 0;
              // sectionList[i].mainExerciseList[j].tempo = parseInt(sectionList[i].mainExerciseList[j].tempo.split('/')[0]);
            }
            // 메인 운동중 Single일 경우만 해당 Sets가 구분데이터를 사용하는지 확인 //
            if (typeof (sectionList[i].mainExerciseList[j].sets) === 'string' && (sectionList[i].mainExerciseList[j].sets.includes('/')
              || sectionList[i].mainExerciseList[j].sets.includes('~')
              || sectionList[i].mainExerciseList[j].sets.includes('-')
              || sectionList[i].mainExerciseList[j].sets.includes('@')
              || sectionList[i].mainExerciseList[j].sets.includes('.'))) {
              sectionList[i].mainExerciseList[j].divisionSets = sectionList[i].mainExerciseList[j].sets;
              sectionList[i].mainExerciseList[j].sets = 0;
              // sectionList[i].mainExerciseList[j].sets = parseInt(sectionList[i].mainExerciseList[j].sets.split('/')[0]);
            }
            // 메인 운동중 Single일 경우만 해당 Rest가 구분데이터를 사용하는지 확인 //
            if (typeof (sectionList[i].mainExerciseList[j].rest) === 'string' && (sectionList[i].mainExerciseList[j].rest.includes('/')
              || sectionList[i].mainExerciseList[j].rest.includes('~')
              || sectionList[i].mainExerciseList[j].rest.includes('-')
              || sectionList[i].mainExerciseList[j].rest.includes('@')
              || sectionList[i].mainExerciseList[j].rest.includes('.'))) {
              sectionList[i].mainExerciseList[j].divisionRest = sectionList[i].mainExerciseList[j].rest;
              sectionList[i].mainExerciseList[j].rest = 0;
              // sectionList[i].mainExerciseList[j].rest = parseInt(sectionList[i].mainExerciseList[j].rest.split('/')[0]);
            }
          }

          // Main Scale Option 확인 //
          for (let j = 0; j < sectionList[i].mainScaleOptionExerciseList.length; j++) {
            // 메인 운동중 무게가 구분데이터를 사용하는지 확인 //
            if (typeof (sectionList[i].mainScaleOptionExerciseList[j].weight) === 'string' && (sectionList[i].mainScaleOptionExerciseList[j].weight.includes('/')
              || sectionList[i].mainScaleOptionExerciseList[j].weight.includes('~')
              || sectionList[i].mainScaleOptionExerciseList[j].weight.includes('-')
              || sectionList[i].mainScaleOptionExerciseList[j].weight.includes('@')
              || sectionList[i].mainScaleOptionExerciseList[j].weight.includes('.'))) {
              sectionList[i].mainScaleOptionExerciseList[j].divisionWeight = sectionList[i].mainScaleOptionExerciseList[j].weight;
              sectionList[i].mainScaleOptionExerciseList[j].weight = 0;
            }
            // 메인 운동중 거리가 구분데이터를 사용하는지 확인 //
            if (typeof (sectionList[i].mainScaleOptionExerciseList[j].distance) === 'string' && (sectionList[i].mainScaleOptionExerciseList[j].distance.includes('/')
              || sectionList[i].mainScaleOptionExerciseList[j].distance.includes('~')
              || sectionList[i].mainScaleOptionExerciseList[j].distance.includes('-')
              || sectionList[i].mainScaleOptionExerciseList[j].distance.includes('@')
              || sectionList[i].mainScaleOptionExerciseList[j].distance.includes('.'))) {
              sectionList[i].mainScaleOptionExerciseList[j].divisionDistance = sectionList[i].mainScaleOptionExerciseList[j].distance;
              sectionList[i].mainScaleOptionExerciseList[j].distance = 0
              // sectionList[i].mainScaleOptionExerciseList[j].distance = parseFloat(sectionList[i].mainScaleOptionExerciseList[j].distance.split('/')[0]);
            }
            // 메인 운동중 높이가 구분데이터를 사용하는지 확인 //
            if (typeof (sectionList[i].mainScaleOptionExerciseList[j].height) === 'string' && (sectionList[i].mainScaleOptionExerciseList[j].height.includes('/')
              || sectionList[i].mainScaleOptionExerciseList[j].height.includes('~')
              || sectionList[i].mainScaleOptionExerciseList[j].height.includes('-')
              || sectionList[i].mainScaleOptionExerciseList[j].height.includes('@')
              || sectionList[i].mainScaleOptionExerciseList[j].height.includes('.'))) {
              sectionList[i].mainScaleOptionExerciseList[j].divisionHeight = sectionList[i].mainScaleOptionExerciseList[j].height;
              sectionList[i].mainScaleOptionExerciseList[j].height = 0;
              // sectionList[i].mainScaleOptionExerciseList[j].height = parseFloat(sectionList[i].mainScaleOptionExerciseList[j].height.split('/')[0]);
            }
            // 메인 운동중 칼로리가 구분데이터를 사용하는지 확인 //
            if (typeof (sectionList[i].mainScaleOptionExerciseList[j].calories) === 'string' && (sectionList[i].mainScaleOptionExerciseList[j].calories.includes('/')
              || sectionList[i].mainScaleOptionExerciseList[j].calories.includes('~')
              || sectionList[i].mainScaleOptionExerciseList[j].calories.includes('-')
              || sectionList[i].mainScaleOptionExerciseList[j].calories.includes('@')
              || sectionList[i].mainScaleOptionExerciseList[j].calories.includes('.'))) {
              sectionList[i].mainScaleOptionExerciseList[j].divisionCalories = sectionList[i].mainScaleOptionExerciseList[j].calories;
              sectionList[i].mainScaleOptionExerciseList[j].calories = 0;
              // sectionList[i].mainScaleOptionExerciseList[j].calories = parseFloat(sectionList[i].mainScaleOptionExerciseList[j].calories.split('/')[0]);
            }
            // 메인 운동중 템포가 구분데이터를 사용하는지 확인 //
            if (typeof (sectionList[i].mainScaleOptionExerciseList[j].tempo) === 'string' && (sectionList[i].mainScaleOptionExerciseList[j].tempo.includes('/')
              || sectionList[i].mainScaleOptionExerciseList[j].tempo.includes('~')
              || sectionList[i].mainScaleOptionExerciseList[j].tempo.includes('-')
              || sectionList[i].mainScaleOptionExerciseList[j].tempo.includes('@')
              || sectionList[i].mainScaleOptionExerciseList[j].tempo.includes('.'))) {
              sectionList[i].mainScaleOptionExerciseList[j].divisionTempo = sectionList[i].mainScaleOptionExerciseList[j].tempo;
              sectionList[i].mainScaleOptionExerciseList[j].tempo = 0;
              // sectionList[i].mainScaleOptionExerciseList[j].tempo = parseInt(sectionList[i].mainScaleOptionExerciseList[j].tempo.split('/')[0]);
            }
            // 메인 운동중 Single일 경우만 해당 Sets가 구분데이터를 사용하는지 확인 //
            if (typeof (sectionList[i].mainScaleOptionExerciseList[j].sets) === 'string' && (sectionList[i].mainScaleOptionExerciseList[j].sets.includes('/')
              || sectionList[i].mainScaleOptionExerciseList[j].sets.includes('~')
              || sectionList[i].mainScaleOptionExerciseList[j].sets.includes('-')
              || sectionList[i].mainScaleOptionExerciseList[j].sets.includes('@')
              || sectionList[i].mainScaleOptionExerciseList[j].sets.includes('.'))) {
              sectionList[i].mainScaleOptionExerciseList[j].divisionSets = sectionList[i].mainScaleOptionExerciseList[j].sets;
              sectionList[i].mainScaleOptionExerciseList[j].sets = 0;
              // sectionList[i].mainScaleOptionExerciseList[j].sets = parseInt(sectionList[i].mainScaleOptionExerciseList[j].sets.split('/')[0]);
            }
            // 메인 운동중 Single일 경우만 해당 Rest가 구분데이터를 사용하는지 확인 //
            if (typeof (sectionList[i].mainScaleOptionExerciseList[j].rest) === 'string' && (sectionList[i].mainScaleOptionExerciseList[j].rest.includes('/')
              || sectionList[i].mainScaleOptionExerciseList[j].rest.includes('~')
              || sectionList[i].mainScaleOptionExerciseList[j].rest.includes('-')
              || sectionList[i].mainScaleOptionExerciseList[j].rest.includes('@')
              || sectionList[i].mainScaleOptionExerciseList[j].rest.includes('.'))) {
              sectionList[i].mainScaleOptionExerciseList[j].divisionRest = sectionList[i].mainScaleOptionExerciseList[j].rest;
              sectionList[i].mainScaleOptionExerciseList[j].rest = 0;
              // sectionList[i].mainExerciseList[j].rest = parseInt(sectionList[i].mainExerciseList[j].rest.split('/')[0]);
            }
          }
          // EMOM에서 다루는 Main 데이터 수정 //
        }
        // 그 외의 경우 //
        else {
          // Main 확인 //
          for (let j = 0; j < sectionList[i].mainExerciseList.length; j++) {
            // 메인 운동중 무게가 구분데이터를 사용하는지 확인 //
            if (typeof (sectionList[i].mainExerciseList[j].weight) === 'string' && (sectionList[i].mainExerciseList[j].weight.includes('/')
              || sectionList[i].mainExerciseList[j].weight.includes('~')
              || sectionList[i].mainExerciseList[j].weight.includes('-')
              || sectionList[i].mainExerciseList[j].weight.includes('@')
              || sectionList[i].mainExerciseList[j].weight.includes('.'))) {
              sectionList[i].mainExerciseList[j].divisionWeight = sectionList[i].mainExerciseList[j].weight;
              sectionList[i].mainExerciseList[j].weight = 0;
            }
            // 메인 운동중 거리가 구분데이터를 사용하는지 확인 //
            if (typeof (sectionList[i].mainExerciseList[j].distance) === 'string' && (sectionList[i].mainExerciseList[j].distance.includes('/')
              || sectionList[i].mainExerciseList[j].distance.includes('~')
              || sectionList[i].mainExerciseList[j].distance.includes('-')
              || sectionList[i].mainExerciseList[j].distance.includes('@')
              || sectionList[i].mainExerciseList[j].distance.includes('.'))) {
              sectionList[i].mainExerciseList[j].divisionDistance = sectionList[i].mainExerciseList[j].distance;
              sectionList[i].mainExerciseList[j].distance = 0;
              // sectionList[i].mainExerciseList[j].distance = parseFloat(sectionList[i].mainExerciseList[j].distance.split('/')[0]);
            }
            // 메인 운동중 높이가 구분데이터를 사용하는지 확인 //
            if (typeof (sectionList[i].mainExerciseList[j].height) === 'string' && (sectionList[i].mainExerciseList[j].height.includes('/')
              || sectionList[i].mainExerciseList[j].height.includes('~')
              || sectionList[i].mainExerciseList[j].height.includes('-')
              || sectionList[i].mainExerciseList[j].height.includes('@')
              || sectionList[i].mainExerciseList[j].height.includes('.'))) {
              sectionList[i].mainExerciseList[j].divisionHeight = sectionList[i].mainExerciseList[j].height;
              sectionList[i].mainExerciseList[j].height = 0;
              // sectionList[i].mainExerciseList[j].height = parseFloat(sectionList[i].mainExerciseList[j].height.split('/')[0]);
            }
            // 메인 운동중 칼로리가 구분데이터를 사용하는지 확인 //
            if (typeof (sectionList[i].mainExerciseList[j].calories) === 'string' && (sectionList[i].mainExerciseList[j].calories.includes('/')
              || sectionList[i].mainExerciseList[j].calories.includes('~')
              || sectionList[i].mainExerciseList[j].calories.includes('-')
              || sectionList[i].mainExerciseList[j].calories.includes('@')
              || sectionList[i].mainExerciseList[j].calories.includes('.'))) {
              sectionList[i].mainExerciseList[j].divisionCalories = sectionList[i].mainExerciseList[j].calories;
              sectionList[i].mainExerciseList[j].calories = 0;
              // sectionList[i].mainExerciseList[j].calories = parseFloat(sectionList[i].mainExerciseList[j].calories.split('/')[0]);
            }
            // 메인 운동중 템포가 구분데이터를 사용하는지 확인 //
            if (typeof (sectionList[i].mainExerciseList[j].tempo) === 'string' && (sectionList[i].mainExerciseList[j].tempo.includes('/')
              || sectionList[i].mainExerciseList[j].tempo.includes('~')
              || sectionList[i].mainExerciseList[j].tempo.includes('-')
              || sectionList[i].mainExerciseList[j].tempo.includes('@')
              || sectionList[i].mainExerciseList[j].tempo.includes('.'))) {
              sectionList[i].mainExerciseList[j].divisionTempo = sectionList[i].mainExerciseList[j].tempo;
              sectionList[i].mainExerciseList[j].tempo = 0;
              // sectionList[i].mainExerciseList[j].tempo = parseInt(sectionList[i].mainExerciseList[j].tempo.split('/')[0]);
            }
            // 메인 운동중 Single일 경우만 해당 Sets가 구분데이터를 사용하는지 확인 //
            if (typeof (sectionList[i].mainExerciseList[j].sets) === 'string' && (sectionList[i].mainExerciseList[j].sets.includes('/')
              || sectionList[i].mainExerciseList[j].sets.includes('~')
              || sectionList[i].mainExerciseList[j].sets.includes('-')
              || sectionList[i].mainExerciseList[j].sets.includes('@')
              || sectionList[i].mainExerciseList[j].sets.includes('.'))) {
              sectionList[i].mainExerciseList[j].divisionSets = sectionList[i].mainExerciseList[j].sets;
              sectionList[i].mainExerciseList[j].sets = 0;
              // sectionList[i].mainExerciseList[j].sets = parseInt(sectionList[i].mainExerciseList[j].sets.split('/')[0]);
            }
            // 메인 운동중 Single일 경우만 해당 Rest가 구분데이터를 사용하는지 확인 //
            if (typeof (sectionList[i].mainExerciseList[j].rest) === 'string' && (sectionList[i].mainExerciseList[j].rest.includes('/')
              || sectionList[i].mainExerciseList[j].rest.includes('~')
              || sectionList[i].mainExerciseList[j].rest.includes('-')
              || sectionList[i].mainExerciseList[j].rest.includes('@')
              || sectionList[i].mainExerciseList[j].rest.includes('.'))) {
              sectionList[i].mainExerciseList[j].divisionRest = sectionList[i].mainExerciseList[j].rest;
              sectionList[i].mainExerciseList[j].rest = 0;
              // sectionList[i].mainExerciseList[j].rest = parseInt(sectionList[i].mainExerciseList[j].rest.split('/')[0]);
            }
          }

          // Main Scale Option 확인 //
          for (let j = 0; j < sectionList[i].mainScaleOptionExerciseList.length; j++) {
            // 메인 운동중 무게가 구분데이터를 사용하는지 확인 //
            if (typeof (sectionList[i].mainScaleOptionExerciseList[j].weight) === 'string' && (sectionList[i].mainScaleOptionExerciseList[j].weight.includes('/')
              || sectionList[i].mainScaleOptionExerciseList[j].weight.includes('~')
              || sectionList[i].mainScaleOptionExerciseList[j].weight.includes('-')
              || sectionList[i].mainScaleOptionExerciseList[j].weight.includes('@')
              || sectionList[i].mainScaleOptionExerciseList[j].weight.includes('.'))) {
              sectionList[i].mainScaleOptionExerciseList[j].divisionWeight = sectionList[i].mainScaleOptionExerciseList[j].weight;
              sectionList[i].mainScaleOptionExerciseList[j].weight = 0;
            }
            // 메인 운동중 거리가 구분데이터를 사용하는지 확인 //
            if (typeof (sectionList[i].mainScaleOptionExerciseList[j].distance) === 'string' && (sectionList[i].mainScaleOptionExerciseList[j].distance.includes('/')
              || sectionList[i].mainScaleOptionExerciseList[j].distance.includes('~')
              || sectionList[i].mainScaleOptionExerciseList[j].distance.includes('-')
              || sectionList[i].mainScaleOptionExerciseList[j].distance.includes('@')
              || sectionList[i].mainScaleOptionExerciseList[j].distance.includes('.'))) {
              sectionList[i].mainScaleOptionExerciseList[j].divisionDistance = sectionList[i].mainScaleOptionExerciseList[j].distance;
              sectionList[i].mainScaleOptionExerciseList[j].distance = 0
              // sectionList[i].mainScaleOptionExerciseList[j].distance = parseFloat(sectionList[i].mainScaleOptionExerciseList[j].distance.split('/')[0]);
            }
            // 메인 운동중 높이가 구분데이터를 사용하는지 확인 //
            if (typeof (sectionList[i].mainScaleOptionExerciseList[j].height) === 'string' && (sectionList[i].mainScaleOptionExerciseList[j].height.includes('/')
              || sectionList[i].mainScaleOptionExerciseList[j].height.includes('~')
              || sectionList[i].mainScaleOptionExerciseList[j].height.includes('-')
              || sectionList[i].mainScaleOptionExerciseList[j].height.includes('@')
              || sectionList[i].mainScaleOptionExerciseList[j].height.includes('.'))) {
              sectionList[i].mainScaleOptionExerciseList[j].divisionHeight = sectionList[i].mainScaleOptionExerciseList[j].height;
              sectionList[i].mainScaleOptionExerciseList[j].height = 0;
              // sectionList[i].mainScaleOptionExerciseList[j].height = parseFloat(sectionList[i].mainScaleOptionExerciseList[j].height.split('/')[0]);
            }
            // 메인 운동중 칼로리가 구분데이터를 사용하는지 확인 //
            if (typeof (sectionList[i].mainScaleOptionExerciseList[j].calories) === 'string' && (sectionList[i].mainScaleOptionExerciseList[j].calories.includes('/')
              || sectionList[i].mainScaleOptionExerciseList[j].calories.includes('~')
              || sectionList[i].mainScaleOptionExerciseList[j].calories.includes('-')
              || sectionList[i].mainScaleOptionExerciseList[j].calories.includes('@')
              || sectionList[i].mainScaleOptionExerciseList[j].calories.includes('.'))) {
              sectionList[i].mainScaleOptionExerciseList[j].divisionCalories = sectionList[i].mainScaleOptionExerciseList[j].calories;
              sectionList[i].mainScaleOptionExerciseList[j].calories = 0;
              // sectionList[i].mainScaleOptionExerciseList[j].calories = parseFloat(sectionList[i].mainScaleOptionExerciseList[j].calories.split('/')[0]);
            }
            // 메인 운동중 템포가 구분데이터를 사용하는지 확인 //
            if (typeof (sectionList[i].mainScaleOptionExerciseList[j].tempo) === 'string' && (sectionList[i].mainScaleOptionExerciseList[j].tempo.includes('/')
              || sectionList[i].mainScaleOptionExerciseList[j].tempo.includes('~')
              || sectionList[i].mainScaleOptionExerciseList[j].tempo.includes('-')
              || sectionList[i].mainScaleOptionExerciseList[j].tempo.includes('@')
              || sectionList[i].mainScaleOptionExerciseList[j].tempo.includes('.'))) {
              sectionList[i].mainScaleOptionExerciseList[j].divisionTempo = sectionList[i].mainScaleOptionExerciseList[j].tempo;
              sectionList[i].mainScaleOptionExerciseList[j].tempo = 0;
              // sectionList[i].mainScaleOptionExerciseList[j].tempo = parseInt(sectionList[i].mainScaleOptionExerciseList[j].tempo.split('/')[0]);
            }
            // 메인 운동중 Single일 경우만 해당 Sets가 구분데이터를 사용하는지 확인 //
            if (typeof (sectionList[i].mainScaleOptionExerciseList[j].sets) === 'string' && (sectionList[i].mainScaleOptionExerciseList[j].sets.includes('/')
              || sectionList[i].mainScaleOptionExerciseList[j].sets.includes('~')
              || sectionList[i].mainScaleOptionExerciseList[j].sets.includes('-')
              || sectionList[i].mainScaleOptionExerciseList[j].sets.includes('@')
              || sectionList[i].mainScaleOptionExerciseList[j].sets.includes('.'))) {
              sectionList[i].mainScaleOptionExerciseList[j].divisionSets = sectionList[i].mainScaleOptionExerciseList[j].sets;
              sectionList[i].mainScaleOptionExerciseList[j].sets = 0;
              // sectionList[i].mainScaleOptionExerciseList[j].sets = parseInt(sectionList[i].mainScaleOptionExerciseList[j].sets.split('/')[0]);
            }
            // 메인 운동중 Single일 경우만 해당 Rest가 구분데이터를 사용하는지 확인 //
            if (typeof (sectionList[i].mainScaleOptionExerciseList[j].rest) === 'string' && (sectionList[i].mainScaleOptionExerciseList[j].rest.includes('/')
              || sectionList[i].mainScaleOptionExerciseList[j].rest.includes('~')
              || sectionList[i].mainScaleOptionExerciseList[j].rest.includes('-')
              || sectionList[i].mainScaleOptionExerciseList[j].rest.includes('@')
              || sectionList[i].mainScaleOptionExerciseList[j].rest.includes('.'))) {
              sectionList[i].mainScaleOptionExerciseList[j].divisionRest = sectionList[i].mainScaleOptionExerciseList[j].rest;
              sectionList[i].mainScaleOptionExerciseList[j].rest = 0;
              // sectionList[i].mainExerciseList[j].rest = parseInt(sectionList[i].mainExerciseList[j].rest.split('/')[0]);
            }
          }
        }

        // Buyout 확인 //
        for (let j = 0; j < sectionList[i].buyoutExerciseList.length; j++) {
          // Buyout 운동중 무게가 구분데이터를 사용하는지 확인 //
          if (typeof (sectionList[i].buyoutExerciseList[j].weight) === 'string' && (sectionList[i].buyoutExerciseList[j].weight.includes('/')
            || sectionList[i].buyoutExerciseList[j].weight.includes('~')
            || sectionList[i].buyoutExerciseList[j].weight.includes('-')
            || sectionList[i].buyoutExerciseList[j].weight.includes('@')
            || sectionList[i].buyoutExerciseList[j].weight.includes('.'))) {
            sectionList[i].buyoutExerciseList[j].divisionWeight = sectionList[i].buyoutExerciseList[j].weight;
            sectionList[i].buyoutExerciseList[j].weight = 0;
          }
          // Buyout 운동중 거리가 구분데이터를 사용하는지 확인 //
          if (typeof (sectionList[i].buyoutExerciseList[j].distance) === 'string' && (sectionList[i].buyoutExerciseList[j].distance.includes('/')
            || sectionList[i].buyoutExerciseList[j].distance.includes('~')
            || sectionList[i].buyoutExerciseList[j].distance.includes('-')
            || sectionList[i].buyoutExerciseList[j].distance.includes('@')
            || sectionList[i].buyoutExerciseList[j].distance.includes('.'))) {
            sectionList[i].buyoutExerciseList[j].divisionDistance = sectionList[i].buyoutExerciseList[j].distance;
            sectionList[i].buyoutExerciseList[j].distance = 0;
            // sectionList[i].buyoutExerciseList[j].distance = parseFloat(sectionList[i].buyoutExerciseList[j].distance.split('/')[0]);
          }
          // Buyout 운동중 높이가 구분데이터를 사용하는지 확인 //
          if (typeof (sectionList[i].buyoutExerciseList[j].height) === 'string' && (sectionList[i].buyoutExerciseList[j].height.includes('/')
            || sectionList[i].buyoutExerciseList[j].height.includes('~')
            || sectionList[i].buyoutExerciseList[j].height.includes('-')
            || sectionList[i].buyoutExerciseList[j].height.includes('@')
            || sectionList[i].buyoutExerciseList[j].height.includes('.'))) {
            sectionList[i].buyoutExerciseList[j].divisionHeight = sectionList[i].buyoutExerciseList[j].height;
            sectionList[i].buyoutExerciseList[j].height = 0;
            // sectionList[i].buyoutExerciseList[j].height = parseFloat(sectionList[i].buyoutExerciseList[j].height.split('/')[0]);
          }
          // Buyout 운동중 칼로리가 구분데이터를 사용하는지 확인 //
          if (typeof (sectionList[i].buyoutExerciseList[j].calories) === 'string' && (sectionList[i].buyoutExerciseList[j].calories.includes('/')
            || sectionList[i].buyoutExerciseList[j].calories.includes('~')
            || sectionList[i].buyoutExerciseList[j].calories.includes('-')
            || sectionList[i].buyoutExerciseList[j].calories.includes('@')
            || sectionList[i].buyoutExerciseList[j].calories.includes('.'))) {
            sectionList[i].buyoutExerciseList[j].divisionCalories = sectionList[i].buyoutExerciseList[j].calories;
            sectionList[i].buyoutExerciseList[j].calories = 0;
            // sectionList[i].buyoutExerciseList[j].calories = parseFloat(sectionList[i].buyoutExerciseList[j].calories.split('/')[0]);
          }
          // Buyout 운동중 템포가 구분데이터를 사용하는지 확인 //
          if (typeof (sectionList[i].buyoutExerciseList[j].tempo) === 'string' && (sectionList[i].buyoutExerciseList[j].tempo.includes('/')
            || sectionList[i].buyoutExerciseList[j].tempo.includes('~')
            || sectionList[i].buyoutExerciseList[j].tempo.includes('-')
            || sectionList[i].buyoutExerciseList[j].tempo.includes('@')
            || sectionList[i].buyoutExerciseList[j].tempo.includes('.'))) {
            sectionList[i].buyoutExerciseList[j].divisionTempo = sectionList[i].buyoutExerciseList[j].tempo;
            sectionList[i].buyoutExerciseList[j].tempo = 0;
            // sectionList[i].buyoutExerciseList[j].tempo = parseInt(sectionList[i].buyoutExerciseList[j].tempo.split('/')[0]);
          }
        }

        // Buyout 확인 //
        for (let j = 0; j < sectionList[i].buyoutScaleOptionExerciseList.length; j++) {
          // Buyout 운동중 무게가 구분데이터를 사용하는지 확인 //
          if (typeof (sectionList[i].buyouScaleOptiontExerciseList[j].weight) === 'string' && (sectionList[i].buyouScaleOptiontExerciseList[j].weight.includes('/')
            || sectionList[i].buyouScaleOptiontExerciseList[j].weight.includes('~')
            || sectionList[i].buyouScaleOptiontExerciseList[j].weight.includes('-')
            || sectionList[i].buyouScaleOptiontExerciseList[j].weight.includes('@')
            || sectionList[i].buyouScaleOptiontExerciseList[j].weight.includes('.'))) {
            sectionList[i].buyoutScaleOptionExerciseList[j].divisionWeight = sectionList[i].buyoutScaleOptionExerciseList[j].weight;
            sectionList[i].buyoutScaleOptionExerciseList[j].weight = 0;
          }
          // Buyout 운동중 거리가 구분데이터를 사용하는지 확인 //
          if (typeof (sectionList[i].buyouScaleOptiontExerciseList[j].distance) === 'string' && (sectionList[i].buyoutScaleOptionExerciseList[j].distance.includes('/')
            || sectionList[i].buyoutScaleOptionExerciseList[j].distance.includes('~')
            || sectionList[i].buyoutScaleOptionExerciseList[j].distance.includes('-')
            || sectionList[i].buyoutScaleOptionExerciseList[j].distance.includes('@')
            || sectionList[i].buyoutScaleOptionExerciseList[j].distance.includes('.'))) {
            sectionList[i].buyoutScaleOptionExerciseList[j].divisionDistance = sectionList[i].buyoutScaleOptionExerciseList[j].distance;
            sectionList[i].buyoutScaleOptionExerciseList[j].distance = 0;
            // sectionList[i].buyoutScaleOptionExerciseList[j].distance = parseFloat(sectionList[i].buyoutScaleOptionExerciseList[j].distance.split('/')[0]);
          }
          // Buyout 운동중 높이가 구분데이터를 사용하는지 확인 //
          if (typeof (sectionList[i].buyouScaleOptiontExerciseList[j].height) === 'string' && (sectionList[i].buyoutScaleOptionExerciseList[j].height.includes('/')
            || sectionList[i].buyoutScaleOptionExerciseList[j].height.includes('~')
            || sectionList[i].buyoutScaleOptionExerciseList[j].height.includes('-')
            || sectionList[i].buyoutScaleOptionExerciseList[j].height.includes('@')
            || sectionList[i].buyoutScaleOptionExerciseList[j].height.includes('.'))) {
            sectionList[i].buyoutScaleOptionExerciseList[j].divisionHeight = sectionList[i].buyoutScaleOptionExerciseList[j].height;
            sectionList[i].buyoutScaleOptionExerciseList[j].height = 0;
            // sectionList[i].buyoutScaleOptionExerciseList[j].height = parseFloat(sectionList[i].buyoutScaleOptionExerciseList[j].height.split('/')[0]);
          }
          // Buyout 운동중 칼로리가 구분데이터를 사용하는지 확인 //
          if (typeof (sectionList[i].buyouScaleOptiontExerciseList[j].calories) === 'string' && (sectionList[i].buyoutScaleOptionExerciseList[j].calories.includes('/')
            || sectionList[i].buyoutScaleOptionExerciseList[j].calories.includes('~')
            || sectionList[i].buyoutScaleOptionExerciseList[j].calories.includes('-')
            || sectionList[i].buyoutScaleOptionExerciseList[j].calories.includes('@')
            || sectionList[i].buyoutScaleOptionExerciseList[j].calories.includes('.'))) {
            sectionList[i].buyoutScaleOptionExerciseList[j].divisionCalories = sectionList[i].buyoutScaleOptionExerciseList[j].calories;
            sectionList[i].buyoutScaleOptionExerciseList[j].calories = 0;
            // sectionList[i].buyoutScaleOptionExerciseList[j].calories = parseFloat(sectionList[i].buyoutScaleOptionExerciseList[j].calories.split('/')[0]);
          }
          // Buyout 운동중 템포가 구분데이터를 사용하는지 확인 //
          if (typeof (sectionList[i].buyouScaleOptiontExerciseList[j].tempo) === 'string' && (sectionList[i].buyoutScaleOptionExerciseList[j].tempo.includes('/')
            || sectionList[i].buyoutScaleOptionExerciseList[j].tempo.includes('~')
            || sectionList[i].buyoutScaleOptionExerciseList[j].tempo.includes('-')
            || sectionList[i].buyoutScaleOptionExerciseList[j].tempo.includes('@')
            || sectionList[i].buyoutScaleOptionExerciseList[j].tempo.includes('.'))) {
            sectionList[i].buyoutScaleOptionExerciseList[j].divisionTempo = sectionList[i].buyoutScaleOptionExerciseList[j].tempo;
            sectionList[i].buyoutScaleOptionExerciseList[j].tempo = 0;
            // sectionList[i].buyoutScaleOptionExerciseList[j].tempo = parseInt(sectionList[i].buyoutScaleOptionExerciseList[j].tempo.split('/')[0]);
          }
        }

        if (sectionList[i].tag === 'Custom') {
          if (sectionList[i].title === '') sectionList[i].title = 'Custom';
        }
      }

      return sectionList;
    },
    getIsLock: () => {
      return isLock;
    },
    getTotalTitle: () => {
      return title;
    },
    getWorkoutId: () => {
      return workoutId;
    },
    getStoreId: () => {
      return storeId;
    }
  }));

  // 날짜에 맞게 데이터 세팅 //
  useEffect(() => {
    let EmptySection = {
      title: '',
      isUseTimeLimit: false,
      timeLimit: 0,
      isUseBuyin: false,
      buyinExerciseList: [],
      buyinScaleOptionExerciseList: [],
      // tag: 'ForTime-Rounds',
      tag: 'Custom',
      round: 1,
      roundList: [],
      reps: [0],
      exerciseTime: 0,
      restTime: 0,
      mainExerciseList: [],
      mainScaleOptionExerciseList: [],
      emomExerciseList: [],
      emomScaleOptionExerciseList: [],
      isUseBuyout: false,
      buyoutExerciseList: [],
      buyoutScaleOptionExerciseList: [],
      customTitle: '',
      scoreKeyword: '점수 없음',
      scorePriority: '높을 수록',
      description: '',
      buyinNote: '',
      mainNote: '',
      buyoutNote: '',
      isViewVideo: false,
      isClickedYoutubeButton: false,
      youtubeLink: '',
      youtubeLinks: []
    }

    for (let i = 0; i < useData.length; i++) {
      if (useData[i].classDate.clone().format('YYYY.MM.DD') === clickDate) {

        if (useData[i].workouts[clickWorkoutIndex] === undefined) {
          setTitle('');
          setWorkoutId(0);
          setOriginalLessonId(0);
          setIsLock(false);
          setStoreId(0);
        }
        else {
          setTitle(useData[i].workouts[clickWorkoutIndex].title);
          setWorkoutId(useData[i].workouts[clickWorkoutIndex].workoutId);
          setOriginalLessonId(useData[i].workouts[clickWorkoutIndex].lessonId);
          setIsLock(useData[i].workouts[clickWorkoutIndex].isLock);
          setStoreId(useData[i].workouts[clickWorkoutIndex].storeId);

          // 저장된 데이터가 없을경우 //
          if (useData[i].workouts[clickWorkoutIndex].sectionList.length === 0) {
            useData[i].workouts[clickWorkoutIndex].sectionList.push(EmptySection);
            setSectionList(useData[i].workouts[clickWorkoutIndex].sectionList);
          }
          else {
            let newSectionList = useData[i].workouts[clickWorkoutIndex].sectionList;

            for (let j = 0; j < newSectionList.length; j++) {
              if (newSectionList[j].youtubeLink !== '') newSectionList[j].isViewVideo = true;
              else newSectionList[j].isViewVideo = false;

              if (newSectionList[j].youtubeLinks === undefined) newSectionList[j].youtubeLinks = [];
            }

            setSectionList(newSectionList);
          }
        }
      }
    }
  }, [useData, clickDate, clickWorkoutIndex])

  // 스크롤 제어를 위한 컴포넌트 크기 조정 //
  useEffect(() => {
    if (sectionList.length === 0) return;

    let newIndex = 0;

    for (let i = 0; i < sectionList.length; i++) {
      if (SectionRef.current[i]?.offsetTop <= (scroll + 348 + 24 + 64)) {
        newIndex = i;
      }
    }
    setNowSection(newIndex);

    let height = SectionRef.current[sectionList.length - 1]?.clientHeight === undefined ? 0 : SectionRef.current[sectionList.length - 1]?.clientHeight;

    if (height >= 894) setLastBottomMargin(0);
    else setLastBottomMargin(894 - height);

  }, [SectionRef, sectionList, scroll])


  // 스크롤 움직임 이벤트 확인 // 
  function onScrollExerciseView(e) {
    setScroll(e.target.scrollTop);
  }

  // 섹션 이동 버튼 클릭시 //
  async function onClickSectionButton(index) {
    if (index === 0) scrollToWithAnimation(ScrollRef, -100, 500);
    else {
      let scrollRefY = ScrollRef.current.getBoundingClientRect().top;
      let sectionY = SectionRef.current[index]?.getBoundingClientRect().top;

      scrollToWithAnimation(ScrollRef, (sectionY - scrollRefY + scroll), 500);
    }
  }

  // 섹션 추가 버튼 클릭시 //
  function onClickSectionPlus() {
    let newSectionList = sectionList.slice();

    newSectionList.push(EmptySection);

    setSectionList(newSectionList);

    setTimeout(() => {
      onClickSectionButton(newSectionList.length - 1);
    }, 10)
  }

  // 섹션 삭제 버튼 클릭시 //
  function onClickSectionDelete() {
    let newSectionList = sectionList.slice();

    newSectionList.splice(nowSection, 1);

    setSectionList(newSectionList);
  }

  // 섹션 데이터 최신화 메서드 //
  function refreshSectionList() {
    let newSectionList = sectionList.slice();

    setSectionList(newSectionList);
  }

  // 프로그램 가져오기 완료 버튼 클릭시 //
  function onClickCompleteWodLoading(data) {
    setTitle(data.title);
    // setWorkoutId(data.workoutId);
    setWorkoutId(0);
    let newData = lodash.clone(data);

    newData.workoutId = undefined;

    for (let i = 0; i < newData.sectionList.length; i++) {
      newData.sectionList[i].sectionId = undefined;

      for (let j = 0; j < newData.sectionList[i].buyinExerciseList.length; j++) {
        newData.sectionList[i].buyinExerciseList[j].exerciseId = undefined;
      }

      for (let j = 0; j < newData.sectionList[i].buyinScaleOptionExerciseList.length; j++) {
        newData.sectionList[i].buyinScaleOptionExerciseList[j].exerciseId = undefined;
      }

      for (let j = 0; j < newData.sectionList[i].mainExerciseList.length; j++) {
        newData.sectionList[i].mainExerciseList[j].exerciseId = undefined;
      }

      for (let j = 0; j < newData.sectionList[i].mainScaleOptionExerciseList.length; j++) {
        newData.sectionList[i].mainScaleOptionExerciseList[j].exerciseId = undefined;
      }

      for (let j = 0; j < newData.sectionList[i].emomExerciseList.length; j++) {
        newData.sectionList[i].emomExerciseList[j].exerciseId = undefined;
      }

      for (let j = 0; j < newData.sectionList[i].emomScaleOptionExerciseList.length; j++) {
        newData.sectionList[i].emomScaleOptionExerciseList[j].exerciseId = undefined;
      }

      for (let j = 0; j < newData.sectionList[i].buyoutExerciseList.length; j++) {
        newData.sectionList[i].buyoutExerciseList[j].exerciseId = undefined;
      }

      for (let j = 0; j < newData.sectionList[i].buyoutScaleOptionExerciseList.length; j++) {
        newData.sectionList[i].buyoutScaleOptionExerciseList[j].exerciseId = undefined;
      }
    }
    // setSectionList(data.sectionList);
    setSectionList(newData.sectionList);
    setIsClickedWodLoading(false);
  }

  // Total Video Wrapper Width 계산 //
  function onCalcTranslateWidth(data) {
    if (data.isViewVideo) return '100%';
    else if (data.isClickedYoutubeButton) return `calc(100% - 2px)`;
    else return '100px';
  }

  // Video버튼 Width 계산 //
  function onCalcButtonWidth(data) {
    if (data.isClickedYoutubeButton || data.isViewVideo) return '0px';
    else return '42px';
  }

  // VideoURL 입력창 Width 계산 //
  function onCalcInputWidth(data) {
    // console.log(data);
    if (data.isViewVideo) return '0px';
    else if (data.isClickedYoutubeButton) return '451px';
    else return '0px';
  }

  // Video버튼과 입력창 높이 계산 //
  function onCalcButtonAndInputHeight(data) {
    if (data.isViewVideo) return '0px';
    else return '20px';
  }

  // Video View Width 계산 //
  function onCalcVideoViewWidth(isViewVideo) {
    // if (isViewVideo) return '494px';
    if (isViewVideo) return '100%';
    else return '0px';
  }

  // Video View Height 계산 //
  function onCalcVideoViewHeight(isViewVideo) {
    // if (isViewVideo) return '278px';
    if (isViewVideo) return '100%';
    else return '0px';
  }


  // Section에 Youtube링크 버튼을 클릭 시 //
  function onClickYoutubeButton(data) {
    if (!data.isClickedYoutubeButton) {
      data.isClickedYoutubeButton = true;
      refreshSectionList();
    }
    // else {
    //   data.isClickedYoutubeButton = false;
    //   refreshSectionList();
    // }
  }

  // Sectuin Youtube Link 입력시 //
  function onChangeYoutbeLink(value, index) {
    sectionList[index].youtubeLink = value;

    let url = value;
    let isFail = false;

    if (!url.includes('https://youtu.be/') && !url.includes('https://www.youtube.com/watch?v=')) {
      isFail = true;
    }
    else if (url.includes('https://youtu.be/')
      && (url.split('https://youtu.be/')[0] !== '' || url.split('https://youtu.be/')[1] === '')) {
      isFail = true;
    }
    else if (url.includes('https://www.youtube.com/watch?v=')
      && (url.split('https://www.youtube.com/watch?v=')[0] !== '' || url.split('https://www.youtube.com/watch?v=')[1] === '')) {
      isFail = true;
    }


    if (!isFail) sectionList[index].isViewVideo = true;

    refreshSectionList();
  }

  // Section Youtube Link에서 엔터 입력시 //
  function onPressEnterInYoutubeLinkInput(index) {
    let url = sectionList[index].youtubeLink;

    if (!url.includes('https://youtu.be/') && !url.includes('https://www.youtube.com/watch?v=')) {
      alert('올바른 영상링크를 기입해주세요');
      return;
    }
    else if (url.includes('https://youtu.be/')
      && (url.split('https://youtu.be/')[0] !== '' || url.split('https://youtu.be/')[1] === '')) {
      alert('올바른 영상링크를 기입해주세요');
      return;
    }
    else if (url.includes('https://www.youtube.com/watch?v='
      && (url.split('https://www.youtube.com/watch?v=')[0] !== '' || url.split('https://www.youtube.com/watch?v=')[1] === ''))) {
      alert('올바른 영상링크를 기입해주세요');
      return;
    }

    sectionList[index].isViewVideo = true;

    refreshSectionList();
  }

  // 영상 삭제 버튼 클릭시 //
  function onClickDeletedVideo(data) {
    data.youtubeLink = '';
    data.isViewVideo = false;

    refreshSectionList();
  }

  /// 추가링크 함수 ///

  // Total Video Wrapper Width 계산 //
  function onCalcAdditionTranslateWidth() {
    if (isClickedAdditionLink) return `calc(100% - 2px)`;
    else return '69px';
  }

  // Video버튼 Width 계산 //
  function onCalcAdditionButtonWidth() {
    // if (data.isClickedYoutubeButton || data.isViewVideo) return '0px';
    // else return '42px';
    if (isClickedAdditionLink) return '0px';
    else return '15px';
  }

  // VideoURL 입력창 Width 계산 //
  function onCalcAdditionInputWidth() {
    if (isClickedAdditionLink) return '451px';
    else return '0px';
  }

  // Video버튼과 입력창 높이 계산 //
  function onCalcAdditionButtonAndInputHeight() {
    // if (data.isViewVideo) return '0px';
    // else return '20px';
    return '20px';
  }

  // Sectuin Youtube Link 입력시 //
  function onChangeAdditionYoutbeLink(value, index) {
    // sectionList[index].youtubeLink = value;

    setLinkValue(value);

    let url = value;
    let isFail = false;

    if (!url.includes('https://youtu.be/') && !url.includes('https://www.youtube.com/watch?v=')) {
      isFail = true;
    }
    else if (url.includes('https://youtu.be/')
      && (url.split('https://youtu.be/')[0] !== '' || url.split('https://youtu.be/')[1] === '')) {
      isFail = true;
    }
    else if (url.includes('https://www.youtube.com/watch?v=')
      && (url.split('https://www.youtube.com/watch?v=')[0] !== '' || url.split('https://www.youtube.com/watch?v=')[1] === '')) {
      isFail = true;
    }


    if (!isFail) {
      let isExist = false;

      for (let i = 0; i < sectionList[index].youtubeLinks.length; i++) {
        if (sectionList[index].youtubeLinks[i].includes(value) || value.includes(sectionList[index].youtubeLinks[i])) {
          isExist = true;
          break;
        }
      }

      if (!isExist) {
        sectionList[index].youtubeLinks.push(value);
        setLinkValue('');
        setIsClickedAdditionLink(false);
        refreshSectionList();
      }
    }
  }

  // Section Youtube Link에서 엔터 입력시 //
  function onPressEnterInAdditionYoutubeLinkInput(index) {
    let url = linkValue;

    if (!url.includes('https://youtu.be/') && !url.includes('https://www.youtube.com/watch?v=')) {
      alert('올바른 영상링크를 기입해주세요');
      return;
    }
    else if (url.includes('https://youtu.be/')
      && (url.split('https://youtu.be/')[0] !== '' || url.split('https://youtu.be/')[1] === '')) {
      alert('올바른 영상링크를 기입해주세요');
      return;
    }
    else if (url.includes('https://www.youtube.com/watch?v='
      && (url.split('https://www.youtube.com/watch?v=')[0] !== '' || url.split('https://www.youtube.com/watch?v=')[1] === ''))) {
      alert('올바른 영상링크를 기입해주세요');
      return;
    }

    // sectionList[index].isViewVideo = true;
    sectionList[index].youtubeLinks.push(url);
    setLinkValue('');
    setIsClickedAdditionLink(false);
    refreshSectionList();
  }

  // 영상 삭제 버튼 클릭시 //
  function onClickDeletedAdditionVideo(data, index) {
    let newLinks = data.youtubeLinks.slice();

    newLinks.splice(index, 1);
    data.youtubeLinks = newLinks;
    refreshSectionList();
    // data.youtubeLink = '';
    // data.isViewVideo = false;

    // refreshSectionList();
  }

  /// 추가링크 함수 ///

  return (
    <Container>
      {isClickedWodLoading && <WodLoadingModal standardDate={clickDate} completeFn={onClickCompleteWodLoading} closeFn={() => setIsClickedWodLoading(false)} />}
      {
        isClickedModify ?
          <TopLevelWrapper>
            <TotalWrapper>
              <TopDateWrapper>
                <Text style={{ marginTop: '28px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#232323'>{clickDate}</Text>
                <HistoryBox onClick={() => setIsClickedWodLoading(true)}>
                  <HoverText fontFamily='Poppins-SemiBold' fontSize={14} color='#FFFFFF'>HISTORY</HoverText>
                </HistoryBox>
              </TopDateWrapper>
              <SelectBoxWrapper>
                <LessonSelectBox defaultVal={selectedLesson} setDefaultVal={setSelectedLesson} originalLessonId={originalLessonId} />
              </SelectBoxWrapper>
              <TitleWrapper topMargin={24}>
                <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>제목</Text>
              </TitleWrapper>
              <TotalTitleInputBox value={title} setValue={setTitle} placeholder={clickDate} />
              <SectionWrapper>
                {
                  sectionList.map((data, index) => (
                    <SectionBox key={index} nowSection={nowSection === index} onClick={() => onClickSectionButton(index)}>{String.fromCharCode(index + 65)}</SectionBox>
                  ))
                }
                {
                  sectionList.length < 26 &&
                  <SectionFunctionBox backgroundColor='#FF8B48' hoverBackgroundColor='#FF7322' onClick={onClickSectionPlus}>
                    <Icon src={PlusImage} />
                  </SectionFunctionBox>
                }
                <LockBox isClicked={isLock} onClick={() => setIsLock(!isLock)}>
                  <LockIcon src={isLock ? LockingIcon : UnLockingIcon} />
                </LockBox>
                {
                  sectionList.length > 0 &&
                  <SectionFunctionBox backgroundColor='#FF3131' hoverBackgroundColor='#DD0000' onClick={onClickSectionDelete}>
                    <Icon src={TrashImage} />
                  </SectionFunctionBox>
                }
              </SectionWrapper>
              <ScrollWrapper
                ref={ScrollRef}
                onScroll={onScrollExerciseView}
                isModify={isClickedModify}>
                {
                  sectionList.map((data, index) => (
                    <ModifySectionBoxWrapper
                      ref={element => (SectionRef.current[index] = element)}
                      key={index}>
                      <SectionDivisionBoxWrapper>
                        <SectionDivisionBox>
                          <Text fontFamily='Poppins-Bold' fontSize={14} color='#FFFFFF'>{String.fromCharCode(index + 65)}</Text>
                          <Text fontFamily='Poppins-Bold' fontSize={12} color='#FFFFFF'>Session</Text>
                        </SectionDivisionBox>
                      </SectionDivisionBoxWrapper>
                      <YoutubeWrapper isExistAdditionLinks={data.youtubeLinks.length !== 0} isViewVideo={data.isViewVideo}>
                        <TranslateButton
                          isClicked={data.isClickedYoutubeButton}
                          isViewVideo={data.isViewVideo}
                          width={onCalcTranslateWidth(data)}
                          onClick={() => onClickYoutubeButton(data)}>
                          {
                            !data.isViewVideo && <YoutubeImage src={YoutubeButtonImage} />
                          }
                          <VideoTextWrapper width={onCalcButtonWidth(data)} height={onCalcButtonAndInputHeight(data)}>
                            <HoverText fontFamily='Poppins-Medium' fontSize={14} color='#FFFFFF'>VIDEO</HoverText>
                          </VideoTextWrapper>
                          <VideoURLWrapper
                            isViewVideo={data.isViewVideo}
                            width={onCalcInputWidth(data)}
                            height={onCalcButtonAndInputHeight(data)}>
                            <YoutubeVideoURLInput index={index} value={data.youtubeLink} setValue={onChangeYoutbeLink} onPressEnter={onPressEnterInYoutubeLinkInput} />
                          </VideoURLWrapper>
                          <VideoViewWrapper
                            isViewVideo={data.isViewVideo}
                            width={onCalcVideoViewWidth(data.isViewVideo)}
                            height={onCalcVideoViewHeight(data.isViewVideo)}>
                            <iframe
                              width="100%"
                              height="100%"
                              src={(data.youtubeLink !== undefined && data.youtubeLink !== '') ? `https://www.youtube.com/embed/` + data.youtubeLink.replace('https://www.youtube.com/watch?v=', '').replace('https://youtu.be/', '') : ''}
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                              title="Embedded youtube" />
                            <VideoDeleteWrapper onClick={() => onClickDeletedVideo(data)}>
                              <TrashIcon src={TrashImage} />
                            </VideoDeleteWrapper>
                          </VideoViewWrapper>
                        </TranslateButton>
                      </YoutubeWrapper>
                      {
                        data.isViewVideo &&
                        <AdditionYoutubeWrapper>
                          {
                            data.youtubeLinks.map((linkData, idx) => (
                              <VideoViewWrapper
                                key={idx}
                                isViewVideo={true}
                                style={{ marginBottom: '8px' }}
                                width='100%'
                                height='278px'>
                                <iframe
                                  width="100%"
                                  height="100%"
                                  src={(linkData !== undefined && linkData !== '') ? `https://www.youtube.com/embed/` + linkData.replace('https://www.youtube.com/watch?v=', '').replace('https://youtu.be/', '') : ''}
                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                  allowFullScreen
                                  title="Embedded youtube" />
                                <VideoDeleteWrapper onClick={() => onClickDeletedAdditionVideo(data, idx)}>
                                  <TrashIcon src={TrashImage} />
                                </VideoDeleteWrapper>
                              </VideoViewWrapper>
                            ))
                          }
                          <AdditionTranslateButton
                            isClicked={isClickedAdditionLink}
                            isViewVideo={true}
                            width={onCalcAdditionTranslateWidth(data)}
                            onClick={() => setIsClickedAdditionLink(true)}>
                            <YoutubeImage src={YoutubeButtonImage} />
                            <VideoTextWrapper width={onCalcAdditionButtonWidth(data)} height={onCalcAdditionButtonAndInputHeight(data)}>
                              <PlusWrapper>
                                <Stick width={14} height={2} radius={2} />
                                <Stick width={2} height={14} radius={2} />
                              </PlusWrapper>
                            </VideoTextWrapper>
                            <AdditionVideoURLWrapper
                              width={onCalcAdditionInputWidth(data)}
                              height={onCalcAdditionButtonAndInputHeight(data)}>
                              <YoutubeVideoURLInput index={index} value={linkValue} setValue={onChangeAdditionYoutbeLink} onPressEnter={onPressEnterInAdditionYoutubeLinkInput} />
                            </AdditionVideoURLWrapper>
                          </AdditionTranslateButton>
                        </AdditionYoutubeWrapper>
                      }
                      <NewModifySectionBox
                        scroll={scroll}
                        sectionData={data}
                        refreshSectionList={refreshSectionList} />
                    </ModifySectionBoxWrapper>
                  ))
                }
                <LastBottomMarginComponent height={lastBottomMargin} />
              </ScrollWrapper>
            </TotalWrapper>
            <BottomSaveButtonWrapper>
              <BottomSaveButton onClick={onClickModifyButton}>
                <HoverText fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF'>저장하기</HoverText>
              </BottomSaveButton>
            </BottomSaveButtonWrapper>
          </TopLevelWrapper>
          :
          (title === '' || title === '작성이 필요합니다.') ?
            <EmptyBox>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#232323'>{clickDate}</Text>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#232323'>새로운 운동 디자인 하기</Text>
              <AdditionExerciseButtonWrapper onClick={onClickModifyButton}>
                <AdditionExerciseButton src={AdditionExerciseButtonImage} />
              </AdditionExerciseButtonWrapper>
            </EmptyBox>
            :
            <TopLevelWrapper style={{ paddingTop: `28px` }}>
              <TotalWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#232323'>{clickDate}</Text>
                <ScrollWrapper isModify={isClickedModify}>
                  <TitleWrapper topMargin={0}>
                    <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>제목</Text>
                  </TitleWrapper>
                  <TotalTitleBox>{title}</TotalTitleBox>
                  {
                    sectionList.map((data, index) => (
                      <NewNormalSectionBox
                        key={index}
                        index={index}
                        sectionData={data} />
                    ))
                  }
                </ScrollWrapper>
              </TotalWrapper>
            </TopLevelWrapper>
      }
    </Container>
  )
})

export default NewExerciseBox;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;
`;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 582px;
  // height: 813px;
  min-height: 707px;
  // max-height: 1083px;
  max-height: 1190px;
  
  // padding-top: 28px;
  // padding-bottom: 28px;
  padding-bottom: 58px;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  background-color: #FFFFFF;
`;

const TopDateWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  width: 100%;
`;

const HistoryBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-right: -28px;

  width: 100px;
  height: 38px;

  border-radius: 0px 19px 0px 30px;
  background-color: #E0E0E0;

  :hover {
    cursor: pointer;
    background-color: #FF8B48;
  }

  transition: all 0.3s ease-in-out;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 90.33%;
`;

const EmptyBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 582px;
  height: 298px;

  gap: 4px;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  background-color: #FFFFFF;
`;

const AdditionExerciseButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 12px;

  :hover {
    cursor: pointer;
  }
`;

const AdditionExerciseButton = styled.img`
  width: 100px;
  height: 100px;
`;

const ClassSettingButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 13px;

  width: 155px;
  height: 45px;

  border-radius: 23px;
  background-color: #6DD49E;

  :hover {
    cursor: pointer;
  }
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const SelectBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 24px;

  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 97.71%;

  margin-top: ${props => props.topMargin}px;
`;

const GetProgramButton = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 12px;

  color: #FF8B48;

  margin: 0 0 0 0;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    color: #FF7322;
  }
`;

const TotalTitleBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: calc(97.71% - 34px);
  min-height: 34px;

  margin-top: 8px;
  padding-right: 16px;
  padding-left: 16px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  flex-wrap: wrap;

  width: 100%;

  margin-top: 24px;
  // margin-bottom: 24px;

  gap: 8px;
`;

const SectionBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 32px;
  height: 32px;

  border-radius: 6px;
  background-color: ${props => props.nowSection ? `#6DD49E` : `#E0E0E0`};

  font-family: Poppins-Bold;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: ${props => props.nowSection ? `#6DD49E` : `#D7D7D7`};
  }
`;

const LockBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 32px;
  height: 32px;

  border-radius: 6px;
  background-color: ${props => props.isClicked ? `#FF3131` : `#E0E0E0`};

  font-family: Poppins-Bold;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    // background-color: #FF8B48;
  }
`;

const SectionFunctionBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 32px;
  height: 32px;

  border-radius: 6px;
  background-color: ${props => props.backgroundColor};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: ${props => props.hoverBackgroundColor};
  }
`;

const LockIcon = styled.img`
  width: 13px;
  height: 19px;
  
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;
`;

const ScrollWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  margin-top: 24px;

  width: 100%;
  max-height: ${props => props.isModify ? `894px` : `1039px`};
  
  overflow-x: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
		border: 3px solid transparent;
		// height: 92px;
		background-color: #DADADA;
		border-radius: 200px;
	}

  &::-webkit-scrollbar-track {
    // height: 439px;
		// border: 4px solid transparent;
		background-color: #F0F0F0;
		border-radius: 200px;
	}
`;

const ModifySectionBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  margin-bottom: ${props => props.lastBottomMargin}px;
`;

const SectionDivisionBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  margin-bottom: 24px;
`;

const SectionDivisionBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 90px;
  height: 32px;

  gap: 19px;
  padding: 0 11px 0 11px;

  border-radius: 6px;
  background-color: #6DD49E;
`;

const YoutubeWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-end;

  // margin-bottom: 20px;
  margin-bottom: ${props => (props.isExistAdditionLinks || props.isViewVideo) ? '8px' : '20px'};

  width: 97.71%;
  // width: 100%;
  // height: 36px;
  height: ${props => props.isViewVideo ? '278px' : '36px'};

  transition: all 0.3s ease-in-out;
`;

const AdditionYoutubeWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-end;

  margin-bottom: 20px;

  width: 97.71%;
  // width: 100%;
  // height: 36px;

  transition: all 0.3s ease-in-out;
`;

const TranslateButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // width: ${props => props.isClicked ? `calc(100% - 2px)` : `100px`};
  width: ${props => props.width};
  // height: 36px;
  // height: ${props => props.isViewVideo ? '278px' : '36px'};
  height: ${props => props.isViewVideo ? '100%' : 'calc(100% - 2px)'};

  // gap: 8px;

  border: ${props => props.isViewVideo ? `none` : `1px solid #FF3131`};
  border-radius: ${props => props.isViewVideo ? `0px` : `12px`};
  background-color: ${props => props.isClicked ? `#FFFFFF` : `#FF3131`};

  :hover {
    cursor: pointer;
  }

  overflow: hidden;

  transition: all 0.3s ease-in-out;
`;

const AdditionTranslateButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: ${props => props.width};
  height: 36px;

  border: 1px solid #FF3131;
  border-radius: 12px;
  background-color: ${props => props.isClicked ? `#FFFFFF` : `#FF3131`};

  :hover {
    cursor: pointer;
  }

  overflow: hidden;

  transition: all 0.3s ease-in-out;
`;

const YoutubeImage = styled.img`
  margin-right: 8px;

  width: 31px;
  height: 22px;
`;

const VideoTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  // width: ${props => props.isClicked ? `0px` : `42px`};
  width: ${props => props.width};
  // height: 20px;
  height: ${props => props.height};

  overflow: hidden;

  transition: all 0.3s ease-in-out;
`;

const VideoURLWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  // width: ${props => props.isClicked ? `451px` : `0px`};
  width: ${props => props.width};
  // height: 20px;
  height: ${props => props.height};

  opacity: ${props => props.isViewVideo ? `0` : `1`};
  overflow: hidden;

  transition: all 0.3s ease-in-out;
`;

const AdditionVideoURLWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: ${props => props.width};
  height: ${props => props.height};

  overflow: hidden;

  transition: all 0.3s ease-in-out;
`;

const VideoViewWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-end;

  // width: 494px;
  width: ${props => props.width};
  // height: 278px;
  height: ${props => props.height};
  
  opacity: ${props => props.isViewVideo ? `1` : `0`};
  overflow: hidden;

  transition: all 0.3s ease-in-out;
`;

const VideoDeleteWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 16px 16px 0px 0px;

  width: 32px;
  height: 32px;

  border-radius: 32px;
  background-color: rgba(0, 0, 0, 0.50);

  :hover {
    cursor: pointer;
  }
`;

const TrashIcon = styled.img`
  width: 20px;
  height: 20px;
`;

const LastBottomMarginComponent = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  min-height: ${props => props.height}px;

  background-color: #FFFFFF;
`;

const BottomSaveButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-end;

  margin-top: 15px;

  width: 90.33%;
`;

const BottomSaveButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 15px;
  background-color: #5BD98A;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  margin: 0 0 0 0;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }
`;

const HoverText = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;

const PlusWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 2px;

  width: 15px;
  height: 15px;
`;

const Stick = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: ${props => props.radius}px;
  background-color: #FFFFFF;
`;