import { React } from 'react';
import styled from 'styled-components';

import SearchImgPath from '../../image/SearchIcon.png';

export default function NewSearchInput(props) {
	const { placeholder, value, setValue } = props;
  
	function handleChange(e) {
		setValue(e.target.value);
	}

	function handleFocus(e) {
    setValue('');
	}

  return (
    <TopLevelWrapper>
      <Input
        img={SearchImgPath}
        type='text'
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        onFocus={handleFocus} />
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const Input = styled.input`
	font-family: NotoSansKR-Regular;
	font-size: 12px;

  padding: 0 0 0 40px;
  // width: 225px;
  width: calc(100% - 42px);
  // height: 30px;
  height: 34px;

	outline: none;
  border: 1px solid #DFDFDF;
  border-radius: 8px;
	color: #000000;

  background: url(${props => props.img}) no-repeat 10px center;

	::placeholder {
    font-family: NotoSansKR-Regular;
    font-size: 12px;
    
		color: ${props => props.changedColor ? `#FF3131` : `#CBCBCB`};
	}
	
  transition: all 0.3s ease-in-out;
`;