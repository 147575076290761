import React from "react";
import styled from "styled-components";

export default function WodTitleInputBox(props) {
  const { value, setValue, placeholder } = props;
  
  
	function handleClick(e) {
	}

	function handleChange(e) {
    setValue(e.target.value);
	}

	function handleFocus(e) {
	}

	function handleBlur(e) {
	}
  
  return (
    <WodTitleInputWrapper>
      <Input
        type='text'
        placeholder={placeholder}
        value={value}
        onClick={handleClick}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur} />
    </WodTitleInputWrapper>
  )
}

const WodTitleInputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  padding: 0px 10px;

  width: calc(100% - 22px);
  height: 34px;

  border: 1px solid #CBCBCB;
  border-radius: 10px;
`;

const Input = styled.input` 
	font-family: NotoSansKR-Regular;
	font-size: 14px;
	line-height: 20px;
	
  display: flex;
  flex-direction: row;

  width: 100%;

  border: 0px;
  background-color: #FFFFFF;

  color: #595959;

	outline: none;

	::placeholder {
    font-family: NotoSansKR-Regular;
    font-size: 14px;

		color: #CBCBCB;
	}

  &:disabled {
    background-color: #ECECEC;
  }

  transition: all 0.3s ease-in-out;
`;