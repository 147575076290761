import { React, useState, useRef, forwardRef, useImperativeHandle, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import FetchModule from '../../Model/Share/Network/FetchModule';

import SearchImgPath from '../../image/SearchIcon.png';
import SearchInputCheck from '../../image/MemberShipSearchInputCheck.png';

const LockerMemberSearchInput = forwardRef((props, ref) => {
	const { className, placeholder, type } = props;

  const history = useHistory();

	const [value, setValue] = useState('');
  const [dataList, setDataList] = useState([]);
  const [data, setData] = useState([]);
  const [index, setIndex] = useState(-1);
	const [changed, setChanged] = useState(false);
	const [changedPlaceholderColor, setChangedPlaceholderColor] = useState(false);
	const [clickCount, setClickCount] = useState(0);
	const [defaultPlaceholder, setDefaultPlaceholder] = useState(placeholder);

	const inputRef = useRef();

	useImperativeHandle(ref, () => ({
		setValue: (val) => {
			setValue(val);
			setChanged(true);
		},
		getValue: () => {
      if (index === -1) return 'None';
      else return dataList[index];
		},
    getDataList: () => {
      return dataList;
    },
		plusClickCount: () => {
			setClickCount(clickCount + 1)
		},
		getClickCount: () => {
			return clickCount;
		},
		setDefaultPlaceholder: (val) => {
			setDefaultPlaceholder(val)
			setChangedPlaceholderColor(true);
			inputRef.current.style.borderColor = '#FF3131';
		},
		reset: (val) => {
			setClickCount(0);
			setDefaultPlaceholder(val)
			inputRef.current.style.borderColor = '#E8E8E8';
		}
	}));

  // useEffect(() => {
  //   document.body.style.cssText = `position: fixed; top: -${window.scrollY}px`

  //   return () => {
  //     const scrollY = document.body.style.top;
  //     document.body.style.cssText = `position: ""; top: "";`
  //     window.scrollTo(0, parseInt(scrollY || '0') * -1)
  //   }
  // }, [dataList])

  useEffect(() => {

    // const fetchModule = new FetchModule();
		// (
		// 	async () => {
		// 		// const responseData = await fetchModule.getData('member/alive/', 'GET');
    //     const responseData = await fetchModule.postData('member/alive', 'POST');
    //     console.log(responseData.data);
    //     if (responseData.status === 200) {
    //       var tmpList = responseData.data.slice();

    //       for (var i = 0; i < tmpList.length; i++) {
    //         tmpList[i] = Object.assign(tmpList[i], { isChecked: false })
    //       }
    //       setData(tmpList);
    //       setDataList(tmpList);
    //     }
    //     else if (responseData.status === 204) {}
    //     else if (responseData.status === 401) history.push('/');
    //     else alert("회원정보를 불러오는데 실패하였습니다.\n잠시후 다시시도 해주세요");
		// 	}
		// )();
    const fetchModule = new FetchModule();
		(
			async () => {
				const responseData = await fetchModule.getDataVersion2('locker/available/member/', 'GET');
        
        if (responseData.status === 200) {
          var tmpList = responseData.data.slice();

          for (var i = 0; i < tmpList.length; i++) {
            tmpList[i] = Object.assign(tmpList[i], { isChecked: false })
          }
          setData(tmpList);
          setDataList(tmpList);
        }
        else if (responseData.status === 204) {}
        else if (responseData.status === 401) history.push('/');
        else alert("회원정보를 불러오는데 실패하였습니다.\n잠시후 다시시도 해주세요");
			}
		)();
  }, [history])

  // useEffect(() => {
    
  //   // var tmpList = data.slice();

  //   // for (var i = 0; i < tmpList.length; i++) {
  //   //   tmpList[i] = Object.assign(tmpList[i], {isChecked: false})
  //   // }
  //   console.log(data);
  //   setDataList(data);
  // }, [data])
	function handleClick(index) {
    var tmpList = dataList.slice();

		for (var i = 0; i < dataList.length; i++) {
      tmpList[i].isChecked = false;
    }

    tmpList[index].isChecked = true;

    setDataList(tmpList);
    setIndex(index);
	}

	function handleChange(e) {
		setValue(e.target.value);

    var indexList = [];
    var tmp = [];
    for (var i = 0; i < data.length; i++) {
      if (data[i].name.indexOf(e.target.value) > -1) {
        indexList.push(i);
      }
      else if (data[i].phone.indexOf(e.target.value) > -1){
        indexList.push(i);
      }
    }

    if (indexList.length !== 0) {
      for (i = 0; i < indexList.length; i++) {
        tmp.push(data[indexList[i]]);
      }
      // console.log(tmp)
      setDataList(tmp);
    }
    else {
      setDataList(data);
    }
	}

	function handleFocus(e) {
		setChangedPlaceholderColor(false);
		if (changed) {
			// do nothing
		} else {
			setValue("");
		}
	}

	function handleBlur(e) {
		setClickCount(clickCount + 1);
		inputRef.current.style.borderColor = '#E8E8E8';
	}
  
  return (
    <TopLevelWrapper>
      <SearchWrapper>
        <Input
          ref={inputRef}
          img={SearchImgPath}
          clickCount={clickCount}
          type={type}
          placeholder='회원의 이름이나 전화번호를 검색해주세요'
          value={'' || value}
          changedColor={changedPlaceholderColor}
          className={`${className} ${changed ? "changed" : ""}`}
          // onClick={handleClick}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur} />
        <InfoWrapper>
          {
            dataList.map((data, index) => (
              <InfoBox key={index} onClick={() => handleClick(index)}>
                <Table>
                  <Body>
                    <Tr>
                      <Td>
                        <TextWrapper style={{ width: `57px` }}>
                          <Name isChecked={data.isChecked}>{data.name}</Name>
                        </TextWrapper>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <TextWrapper style={{ width: `73px`, marginLeft: `10px` }}>
                          <Phone isChecked={data.isChecked}>{data.phone}</Phone>
                        </TextWrapper>
                      </Td>
                    </Tr>
                  </Body>
                </Table>
                <CheckImg src={SearchInputCheck} opacity={data.isChecked ? 1 : 0}/>
              </InfoBox>
            ))
          }
        </InfoWrapper>
      </SearchWrapper>
    </TopLevelWrapper>
  )
})

export default LockerMemberSearchInput;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: flex-start;

  // margin-top: 15px;
`;

const SearchWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // margin: 0 0 0 16px;
`;

const Input = styled.input`
	font-family: NotoSansKR-Regular;
	font-size: 12px;
	line-height: 20px;

  padding: 0 0 0 40px;
  width: 223px;
  height: 30px;

	outline: none;
  border: 1px solid #DFDFDF;
  border-radius: 8px;
	color: #000000;

  background: url(${props => props.img}) no-repeat 8px center;

	::placeholder {
		color: ${props => props.changedColor ? `#FF3131` : `#CBCBCB`};
	}
	
  transition: all 0.3s ease-in-out;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 265px;
  height: 124px;

  overflow-y: scroll;

  margin-top: 16px;

  &::-webkit-scrollbar {
		width: 9px;
	}

	&::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 20px #DFDFDF;
		border: 3px solid transparent;
		height: 25px;
		// background-color: #FF8B48;
		border-radius: 200px;
	}

	&::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px transparent;
		border: 4px solid transparent;
		background-color: transparent;
	}

	&::-webkit-scrollbar-track-piece {
		background-color: transparent;
	}
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  // width: 253px;
  width: 100%;
  height: 32px;
  
  border-bottom: 1px solid #F5F5F5;

  :hover {
    cursor: pointer;
  }
`;

const Table = styled.table`
  display: flex;
  flex-direction: row;

  height: 32px;
`;

const Body = styled.tbody`
  display: flex;
  flex-direction: row;
  
  // margin-left: 28px;
`;

const Tr = styled.tr`
  display: flex;
  flex-direction: row;
`;

const Td = styled.td`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  overflow: hidden;

  max-height: 20px;
  // border: 1px solid #232323;
`;

const Name = styled.span`
  font-family: NotoSansKR-Medium;
  font-size: 12px;

  color:  ${props => props.isChecked ? `#FF8B48` : `#595959`};

  margin: 0 0 0 0;

  transition: all 0.2s ease-in-out;
`;

const Phone = styled.span`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: ${props => props.isChecked ? `#FF8B48` : `#777777`};

  margin: 0 0 0 0;

  transition: all 0.2s ease-in-out;
`;

const CheckImg = styled.img`
  width: 12px;
  height: 9px;

  // margin: 11px 0 0 97px;

  opacity: ${props => props.opacity};

  transition: all 0.2s ease-in-out;
`;