import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import ProvisionTitleInput from "./ProvisionTitleInput";
import ProvisionTextInput from "./ProvisionTextInput";

export default function ProvisionEditModal(props) {
  const { 
    data, 
    onClickSave,
    onClickExit
  } = props;

  const [title, setTitle] = useState('');
  const [text, setText] = useState('');

  // 데이터 세팅 //
  useEffect(() => {
    if (data !== undefined && data.id !== undefined) {
      setTitle(data.title);
      setText(data.text);
    }
  }, [data])

  // 저장 클릭시 //
  function onClickSaveButton() {
    let newData = {
      id: data.id,
      title: title,
      text: text
    }

    onClickSave(newData);
  }

  return (
    <Background>
      <Modal>
        <TotalWrapper>
          <Text style={{ marginTop: '8px' }} fontFamily='NotoSansKR-Bold' fontSize={16} color='#232323' cursor='default'>약관 수정하기</Text>
          <InfoWrapper>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' cursor='default'>규정 제목</Text>
            {
              data.isDefault ?
                <DefaultProvisionTitleInputWrapper>
                  <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' cursor='default'>{data.title}</Text>
                </DefaultProvisionTitleInputWrapper>
                :
                <TitleInputWrapper>
                  <ProvisionTitleInput value={title} setValue={setTitle} placeholder='제목을 입력해주세요' />
                </TitleInputWrapper>
            }
          </InfoWrapper>
          <InfoWrapper style={{ marginTop: '10px' }}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' cursor='default'>규정 내용</Text>
            <TextAreaWrapper>
              <ProvisionTextInput value={text} setValue={setText} placeholder='규정을 입력해주세요'/>
            </TextAreaWrapper>
          </InfoWrapper>
          <TotalButtonWrapper>
            <ButtonWrapper>
              <ExitButton onClick={onClickExit}>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#3E4148' cursor='pointer'>취소</Text>
              </ExitButton>
              <SaveButton onClick={onClickSaveButton}>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='pointer'>저장하기</Text>
              </SaveButton>
            </ButtonWrapper>
          </TotalButtonWrapper>
        </TotalWrapper>
      </Modal>
    </Background>
  )
}

const startAnimation = keyframes`
	0% { opacity: 0; }
	50% { opacity: 0.5; }
	100% { opacity: 1; }
`;

const Background = styled.div`
  position: fixed;
	display: flex;
	flex-direction: column;

  justify-content: center;
  align-items: center;

  top: 0;
  left: 0;

  z-index: 500;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.5);

	animation-name: ${startAnimation};
	animation-duration: 0.3s;
	animation-timing-function: ease-out;
`;

const Modal = styled.div`
	display: flex;
	flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 630px;
  height: 520px;

  border-radius: 10px;
  background-color: #FFFFFF;
`;

const TotalWrapper = styled.div`
	display: flex;
	flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 590px;
  height: 498px;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 28px;

  width: 100%;

  gap: 10px;
`;

const TitleInputWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  height: 42px;
`;

const DefaultProvisionTitleInputWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  padding: 0px 10px;

  width: calc(100% - 22px);
  height: 40px;

  border: 1px solid #969696;
  border-radius: 10px;
  background-color: #F9F9F9;
`;

const TextAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  height: 262px;
`;

const TotalButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  margin-top: 16px;

  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 10px;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 62px;
  height: 40px;

  border-radius: 10px;
  background-color: #F5F6F8;

  :hover {
    cursor: pointer;
  }
`;

const SaveButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 10px;
  background-color: #5BD98A;

  :hover {
    cursor: pointer;
  }
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  margin: 0 0 0 0;

  color: ${props => props.color};

  :hover {
    cursor: ${props => props.cursor};
  }

  transition: all 0.3s ease-in-out;
`;