import { React } from 'react';
import styled from 'styled-components';

import ZoneSubscribeColumn from './ZoneSubscribeColumn';
import ZoneSubscribeInfo from './ZoneSubscribeInfo';

export default function ZoneSubscribeBox(props) {
  const { subscribeList } = props;

  return (
    <TopLevelWrapper>
      <TitleWrapper>
        <TitleBox backgroundColor='#F4A1A9'>
          <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF' hover='default'>링크존 이용권 결제정보</Text>
        </TitleBox>
      </TitleWrapper>
      <LayerWrapper>
        <ZoneSubscribeColumn/>
        {
          subscribeList.map((data, index) => (
            <ZoneSubscribeInfo key={index} data={data}/>
          ))
        }
      </LayerWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;

  margin-top: 30px;
  padding-bottom: 343px;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const TitleBox = styled.p`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 2px 20px;
  margin: 0 0 0 0;

  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  background-color: ${props => props.backgroundColor};
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  justify-content: flex-start;
  align-items: flex-start;
  
  padding: 14px 0px;

  // width: 1191px;
  width: 100%;

  border-radius: 10px;
  background-color: #FFFFFF;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  justify-content: flex-start;
  align-items: flex-start;
  
  margin-left: 28px;
  
  gap: 4px;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;
  
  margin: 0 0 0 0;
  
  color: ${props => props.color};
  
  :hover {
    cursor: default;
  }
`;