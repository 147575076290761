import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

export default function FindIdPage() {
  const history = useHistory();
  // const [userId, setUserId]
  // 로그인하기 클릭시 //
  function onClickGoLogin() {
    history.push('/');
  }

  // 비밀번호 찾기 클릭시 //
  function onClickGoFindPassword() {
    history.push('/identifier')
  }

  return (
    <TopLevelWrapper>
      <LogoWrapper>
        <Image src='https://picture.link-zone.org/ZoneLogo.png'/>
      </LogoWrapper>
      <TitleWrapper>
        <Text fontFmaily='Poppins-SemiBold' fontSize={32} color='#151617' cursor='default'>Link Zone</Text>
      </TitleWrapper>
      <ExplanationWrapper>
        <Text fontFmaily='NotoSansKR-Regular' fontSize={18} color='#151617' cursor='default'>쉽고 간편한 피트니스 회원 관리 프로그램</Text>
      </ExplanationWrapper>
      <TextWrapper>
        <Text fontFmaily='NotoSansKR-Regular' fontSize={18} color='#000000' cursor='default'>안녕하세요 회원님!</Text>
        <LayerWrapper>
         <Text fontFmaily='NotoSansKR-Regular' fontSize={18} color='#000000' cursor='default'>회원님의 아이디는 </Text>
         <Text fontFmaily='NotoSansKR-Regular' fontSize={18} color='#FF8B48' cursor='default'>{localStorage.getItem('UserId')}</Text>
         <Text fontFmaily='NotoSansKR-Regular' fontSize={18} color='#000000' cursor='default'> 로 확인되었습니다.</Text>
        </LayerWrapper>
      </TextWrapper>
      <ButtonWrapper>
        <GoLoginButton onClick={onClickGoLogin}>
          <Text fontFmaily='NotoSansKR-Bold' fontSize={20} color='#FFFFFF' cursor='pointer'>로그인하기</Text>
        </GoLoginButton>
        <GoFindPasswordButton onClick={onClickGoFindPassword}>
          <Text fontFmaily='NotoSansKR-Regular' fontSize={20} color='#777777' cursor='pointer'>비밀번호 찾기</Text>
        </GoFindPasswordButton>
      </ButtonWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100vh;
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 150px;
  height: 150px;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;

  object-fit: cover;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 16px;
`;

const ExplanationWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 8px;

  opacity: 0.7;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 32px;
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  aling-items: center;

  margin-top: 32px;

  gap: 16px;
`;

const GoLoginButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 200px;
  height: 52px;

  border-radius: 18px;
  background-color: #FF8B48;

  :hover {
    cursor: pointer;
    background-color: #FF7322;
  }

  transition: all 0.3s ease-in-out;
`;

const GoFindPasswordButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 200px;
  height: 52px;

  border-radius: 18px;
  background-color: #F6F6F6;

  :hover {
    cursor: pointer;
    background-color: #E5E5E5;
  }

  transition: all 0.3s ease-in-out;
`;

const Text = styled.p`
  font-family: ${props => props.fontFmaily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }
`;