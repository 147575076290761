import { React, useEffect, useRef } from 'react';
import styled from 'styled-components';

import LogoPath from '../../../image/CompleteSignUpLogo.png';

function SubscribePushModal(props) {
  const { yesFn } = props;

  const TopLevelRef = useRef();

  // Modal 창 스크롤 제어 //
  useEffect(() => {
    document.body.style.cssText = `position: fixed; top: -${window.scrollY}px`

    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = `position: ""; top: "";`
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }
  }, [])

	return (
    <Background>
      <TopLevelWrapper ref={TopLevelRef}>
        <LogoWrapper>
          <Logo src={LogoPath} />
        </LogoWrapper>
        <ExplanationWrapper>
          <KorExplanation>Link Zone을 이용하시려면</KorExplanation>
          <KorExplanation>정기 구독을 진행해주세요</KorExplanation>
        </ExplanationWrapper>
        <ButtonWrapper>
          <YesButton onClick={yesFn}>
            <ButtonText>진행하기</ButtonText>
          </YesButton>
        </ButtonWrapper>
      </TopLevelWrapper>
    </Background>
  )
}
 
export default SubscribePushModal;

const Background = styled.div`
  position: fixed;
	display: flex;
	flex-direction: column;

  justify-content: center;
  align-items: center;

  top: 0;
  left: 0;

  z-index: 500;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.5);
`;

const TopLevelWrapper = styled.div`
	display: flex;
	flex-direction: column;

  justify-content: flex-start;
  align-items: center;

	width: 476px;
	height: 298px;
  
	border-radius: 50px;
  background-color: #FFFFFF;

  transition: all 0.5s ease-in-out;
`;

const LogoWrapper = styled.div`
  position: absolute;

  display: flex;
  flex-direction: column;

  // margin-top: -300px;
  margin-top: -100px;
`;

const Logo = styled.img`
  width: 200px;
  height: 200px;
`;

const ExplanationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  justify-content: center;
  align-items: flex-start;

  margin-top: 109px;

  width: 90%;
  // height: 100px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  margin: 0 0 0 0;

  color: ${props => props.color};
`;

const KorExplanation = styled.pre`
  font-family: NotoSansKR-Bold;
  font-size: 25px;

  margin: 0 0 0 0;

  color: #000000;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 14px;

  margin-top: 50px;
`;

const YesButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // width: 155px;
  width: 324px;
  height: 45px;

  border-radius: 23px;
  background-color: #FF8B48;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background: #FF7322;
  }
`;

const ButtonText = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 18px;
  
  color: #FFFFFF;

  margin: 0 0 0 0;
`;