import { React } from 'react';
import styled from 'styled-components';

const InfoPagination = (props) => {
  const { bottomMargin, nowPage, totalPage, nextClickFn, prevClickFn } = props;

  return (
    <TopLevelWrapper /*bottomMargin={bottomMargin}*/>
      <Button onClick={prevClickFn}>이전</Button>
      <Page>{nowPage + 1}/{totalPage}</Page>
      <Button onClick={nextClickFn}>다음</Button>
    </TopLevelWrapper>
  )
}

export default InfoPagination;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  margin-bottom: 96px;
  // margin-bottom: ${props => props.bottomMargin}px;
`;

const Button = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #A7A7A7;

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
    color: #232323;
  }

  transition: all 0.3s ease-in-out;
`;

const Page = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #232323;

  margin: 0 10px 0 10px;
`;