import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import Lottie from 'lottie-react-web';

import FirstPlaceImg from '../../../image/FirstPlace.png';
import SecondPlaceImg from '../../../image/SecondPlace.png';
import ThirdPlaceImg from '../../../image/ThirdPlace.png';
import RightImg from '../../../image/NotAvailableRight.png';
import DefaultProfile from '../../../image/DefaultProfile.png';

import LoadingAnimation from '../../../Animation/LoadingAnimation.json';
import UserProfile from '../../../Component/Normal/UserProfile';
import FetchModule from '../../../Model/Share/Network/FetchModule';

export default function LegacyRankingBox(props) {
  const {
    sectionData,

    useData,
    clickDate,
    isLoading,
    setIsLoading,
    rankingList,
    setIsClickedDetail,
    nowSection,
    setNowSection,
    onClickDetailView,

    onClickRankingBox
  } = props;

  

  // 섹션 이동 버튼 클릭시 //
  async function onClickSectionButton(index) {
    setNowSection(index);
  }

  // // 자세히보기 클릭시 //
  // function onClickDetailBox(data) {
  //   console.log(data);
  //   // onClickDetailView(data);
  //   // setIsClickedDetail(true);
  // }

  //  useEffect(() => {
  //   console.log(rankingList);
  //   if (rankingList.length === 0) setIsLoading(false);
  //  }, [rankingList])

  // 렌더링 완료 캐치 //
  function onLoadMemberInfo() {
    // setTimeout(() => {
    //   setIsLoading(false);
    // }, 900)
  }

  // 점수 보여주기 //
  function onViewFinalScoreText(data) {
    // return data.createdDate.split('T')[0];
    if (data.finalScoreText === '' || data.finalScoreText === undefined) return data.createdDate.split('T')[0];
    else return data.finalScoreText;
  }

  return (
    <TopLevelWrapper>
      {
        isLoading &&
        <LoadingView>
          <LottieWrapper>
            <Lottie options={{ animationData: LoadingAnimation }} />
          </LottieWrapper>
        </LoadingView>
      }
      <TotalWrapper onLoad={onLoadMemberInfo}>
        <TitleWrapper topMargin={10}>
          <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#666666'>순위표</Text>
        </TitleWrapper>
        {/* <SectionWrapper>
          {
            sectionData.map((data, index) => (
              <SectionBox key={index} nowSection={nowSection === index} onClick={() => onClickSectionButton(index)}>{String.fromCharCode(index + 65)}</SectionBox>
            ))
          }
        </SectionWrapper> */}
        {
          rankingList.length === 0 ?
            <EmptyBoxWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={12} color='#232323'>운동을 진행한 회원이 존재하지 않습니다!</Text>
            </EmptyBoxWrapper>
            :
            <ScrollWrapper>
              {
                rankingList.map((data, index) => (
                  <RankingViewWrapper key={index}>
                    <RankingNumberBoxWrapper>
                      {
                        (data.rank > 0 && data.rank <= 3) ?
                          <RankingLeaderBoxWrapper>
                            <RankingLeaderBox src={data.rank === 1 ? FirstPlaceImg : data.rank === 2 ? SecondPlaceImg : ThirdPlaceImg} />
                            <RankingImageTextWrapper>
                              <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF'>{data.rank}</Text>
                            </RankingImageTextWrapper>
                          </RankingLeaderBoxWrapper>
                          :
                          <RankingNumberBox>
                            <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF'>{data.rank}</Text>
                          </RankingNumberBox>
                      }
                    </RankingNumberBoxWrapper>
                    <RankingClickBox onClick={() => onClickRankingBox(data)}>
                      <InfoBox>
                        <LeftWrapper>
                          <ProfileWrapper>
                            <UserProfile profile={data.userProfile} defaultProfile={DefaultProfile}/>
                          </ProfileWrapper>
                          <InfoTextWrapper>
                            <InfoRowTextWrapper>
                              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>{data.userName}</Text>
                              {
                                (data.minusPointCount !== undefined && data.minusPointCount !== 0) &&
                                <ScaleBox>
                                  <Text fontFamily='Poppins-Medium' fontSize={12} color='#FFFFFF'>Scale</Text>
                                </ScaleBox>
                              }
                            </InfoRowTextWrapper>
                            <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#DBDBDB'>{onViewFinalScoreText(data)}</Text>
                          </InfoTextWrapper>
                        </LeftWrapper>
                        <DetailButton src={RightImg} />
                      </InfoBox>
                    </RankingClickBox>
                  </RankingViewWrapper>
                ))
              }
            </ScrollWrapper>
        }
      </TotalWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;
  
  width: 100%;
  // height: 820px;

  // padding-top: 28px;
  // padding-bottom: 28px;

  // border-radius: 30px;
  // box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  // background-color: #FFFFFF;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 90.33%;
`;

const ScrollWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 24px;
  gap: 12px;

  width: 100%;
  // height: 691px;

  // overflow-x: hidden;
  // overflow-y: scroll;

  // &::-webkit-scrollbar {
  //   width: 4px;
  // }

  // &::-webkit-scrollbar-thumb {
	// 	border: 3px solid transparent;
	// 	// height: 92px;
	// 	background-color: #DADADA;
	// 	border-radius: 200px;
	// }

  // &::-webkit-scrollbar-track {
  //   // height: 439px;
	// 	// border: 4px solid transparent;
	// 	background-color: #F0F0F0;
	// 	border-radius: 200px;
	// }
`;

const EmptyBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // margin-top: 24px;

  width: 100%;
  height: 691px;

  background-color: #F9F9F9;
  // background-color: #FFFFFF;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 97.71%;

  margin-top: ${props => props.topMargin}px;
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  flex-wrap: wrap;

  width: 100%;

  margin-top: 24px;

  gap: 8px;
`;

const SectionBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 32px;
  height: 32px;

  border-radius: 6px;
  background-color: ${props => props.nowSection ? `#6DD49E` : `#E0E0E0`};

  font-family: Poppins-Bold;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: ${props => props.nowSection ? `#6DD49E` : `#D7D7D7`};
  }
`;

const RankingViewWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  width: 97.71%;
  height: 64px;
`;

const RankingNumberBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const RankingLeaderBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const RankingLeaderBox = styled.img`
  width: 40px;
  height: 40px;
`;

const RankingImageTextWrapper = styled.div`
  // position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // margin-top: -10px;
  margin-top: -36px;
`;

const RankingNumberBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;

  border-radius: 12px;
  background-color: #E0E0E0;
`;

const RankingClickBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: calc(90.66% - 2px);
  height: 62px;

  border: 1px solid #E0E0E0;
  border-radius: 12px;
  background-color: #FFFFFF;

  :hover {
    cursor: pointer;
    background-color: #F9F9F9;
  }

  transition: all 0.3s ease-in-out;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 95.7%;
`;

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const ProfileWrapper = styled.div`
  width: 40px;
  height: 40px;

  border-radius: 6px;

  overflow: hidden;
`;

const InfoTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 3px;
`;

const InfoRowTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const ScaleBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 45px;
  height: 18px;

  border-radius: 6px;
  background-color: #4161AF;
`;

const DetailButton = styled.img`
  width: 9.6px;
  height: 17.7px;
`;

const LoadingView = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 28px;

  width: 582px;
  height: 820px;

  border-radius: 30px;
  background-color: #FFFFFF;
`;

const LottieWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 60px;
  height: 60px;
`;