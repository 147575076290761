import { React, useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';

import FetchModule from '../../../Share/Network/FetchModule';
import CustomAlert from '../../../Share/Normal/CustomAlert';

import MemberShipHistoryInfoButtonImagePath from '../../../../image/MemberShipHistoryInfoButton.png';
import MemberShipHistoryInfoWhiteButtonImagePath from '../../../../image/MemberShipHistoryInfoWhiteButton.png';
import MemberShipHistoryInfoGrayButtonImagePath from '../../../../image/MemberShipHistoryInfoGrayButton.png';
import LockerUsageDeleteWarningModal from './Modal/LockerUsageDeleteWaringModal';

const LockerHistoryInfoView = (props) => {
  const { data, onClickLockerModify, onClickLockerExtends, onClickLockerExit, lockerRendering } = props;
  
  const [isViewCustomAlert, setIsViewCustomAlert] = useState(false);
  const [isViewDeleteWarningModal, setIsViewDeleteWarningModal] = useState(false);
  const [text, setText] = useState('');
  const [isClicked, setIsClicked] = useState(false);
  const [status, setStatus] = useState('사용중');
  const [buttonImage, setButtonImage] = useState(MemberShipHistoryInfoButtonImagePath);
  const [fontColor, setFontColor] = useState('#595959');
  const [border, setBorder] = useState('1px solid #FFFFFF');
  const [hoverBorder, setHoverBorder] = useState('1px solid #FFFFFF');
  const [backgroundColor, setBackgroundColor] = useState('#FFFFFF');

  const PushMessageButtonRef = useRef();

  const OptionBoxRef = useRef(null);
  const OptionButtonRef = useRef(null);
  const OptionExitRef = useRef(null);
  const OptionPushMessageRef = useRef(null);
  const OptionDeleteRef = useRef(null);
  const OptionExtendRef = useRef(null);

  useEffect(() => {
    if (OptionBoxRef.current === null) return;

    if (isClicked) {
      // if (data.status === '사용중') OptionBoxRef.current.style.height = `108px`;
      // if (data.status === '사용중') OptionBoxRef.current.style.height = `72px`;
      // // else OptionBoxRef.current.style.height = `72px`;
      // else OptionBoxRef.current.style.height = `36px`;

      if (data.status === '사용중') OptionBoxRef.current.style.height = `108px`;
      else OptionBoxRef.current.style.height = `72px`;

      OptionBoxRef.current.style.border = `1px solid #FF8B48`;
    }
    else {
      OptionBoxRef.current.style.height = `0px`;
      OptionBoxRef.current.style.border = `0px solid #FF8B48`;
    }
  }, [isClicked, data])

  useEffect(() => {
    document.addEventListener("mousedown", onClickOutSide);

    return () => {
      document.removeEventListener("mousedown", onClickOutSide);
    }
  }, [])

  useEffect(() => {
    if (data.status === '사용 종료') {
      setStatus(data.status);
      setFontColor('#777777');
      setBorder('1px solid #F8F8F8');
      // setHoverBorder('1px solid #595959');
      setHoverBorder('1px solid #FF8B48');
      setBackgroundColor('#F8F8F8');
      // setButtonImage(MemberShipHistoryInfoGrayButtonImagePath);
      setButtonImage(MemberShipHistoryInfoButtonImagePath);
    }
    else if (data.status === '결제 예정') {
      setStatus(data.status);
      setFontColor('#777777');
      setBorder('1px solid #FDECC1');
      // setHoverBorder('1px solid #595959');
      setHoverBorder('1px solid #FF8B48');
      setBackgroundColor('#FDECC1');
      // setButtonImage(MemberShipHistoryInfoGrayButtonImagePath);
      setButtonImage(MemberShipHistoryInfoButtonImagePath);
    }
    else {
      // 사용 기한이 끝나지 않은 경우 //
      if (moment().isBefore(moment(data.scheduledEndDate))) {
        setStatus(data.status);
        setFontColor('#595959');
        setBorder('1px solid #FFFFFF');
        setHoverBorder('1px solid #FF8B48');
        setBackgroundColor('#FFFFFF');
        setButtonImage(MemberShipHistoryInfoButtonImagePath);
      }
      // 예정된 기한이 지난경우 //
      else {
        setStatus('사용일 초과');
        setFontColor('#FFFFFF');
        setBorder('1px solid #F48080');
        setHoverBorder('1px solid #F48080');
        setBackgroundColor('#F48080');
        setButtonImage(MemberShipHistoryInfoWhiteButtonImagePath);
      }
    }
  }, [data])

  function onClickOutSide(e) {
    if (OptionBoxRef.current !== e.target 
      && OptionButtonRef.current !== e.target
      && OptionExitRef.current !== e.target
      && OptionPushMessageRef.current !== e.target
      && OptionExtendRef.current !== e.target
      && OptionDeleteRef.current !== e.target) {
      setIsClicked(false);
    }
  }

  function mouseOver() {
    if (!data.isExpectedLocker) OptionButtonRef.current.style.opacity = 1;
    // if (data.status === '사용중') OptionButtonRef.current.style.opacity = 1;
    // else PushMessageButtonRef.current.style.opacity = 1;
  }

  function mouseLeave() {
    if (!data.isExpectedLocker) OptionButtonRef.current.style.opacity = 0;
    // if (data.status === '사용중') OptionButtonRef.current.style.opacity = 0;
    // else PushMessageButtonRef.current.style.opacity = 0;
  }

  // 문자발송 버튼 클릭시
  function onClickSendMessageButton() {
    if (data.status === '사용 종료') {
      // alert("이미 사용을 종료하였습니다!");
      setText('이미 사용을 종료하였습니다!');
      setIsViewCustomAlert(true);
      return;
    }

    // let text = data.userName + '님!, 지금까지 이용하셨던 락커 ' + data.lockerName + '이(가) 만료되었어요!\n빠른 결제로 Link Coach와 함께 더 건강한 하루를 만들어보아요! :)';
    let text = data.userName + '님, 지금까지 이용하셨던 락커 ' + data.lockerName + '이(가) 만료되었어요';

    let requestData = {
      userId: data.linkCoachId,
      data: text,
      // 추후 Locker관련 페이지가 만들어지면 변경 //
      location: '',
      jsonData: ''
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataOfCoach('push_notification/send', 'POST', requestData);

        if (responseData.status === 200) {
          // alert("푸시알림을 전송하였습니다!");
          setText('푸시알림을 전송하였습니다!');
          setIsViewCustomAlert(true);
        }
        else if (responseData.status === 204) {
          // alert("Link Coach를 탈퇴한 회원입니다!");
          setText('Link Coach를 탈퇴한 회원입니다!');
          setIsViewCustomAlert(true);
        }
      }
    )();
  }

  // 내역 삭제 클릭시 //
  function onClickDeleteLocker() {
    // console.log(data);
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataVersion2('locker/history/delete/' + data.usageId, 'POST');

        if (responseData.status === 200) {
          setIsViewDeleteWarningModal(false);
          lockerRendering();
          alert('내역 삭제가 완료되었습니다');
        }
      }
    )();
  }

  // 새로 보여줘야하는 사용일수 표시 //
  function calcUseDate() {
    if (data.isExit) {
      return (moment(moment(data.endDate).format('YYYY-MM-DD')).diff(moment(moment(data.startDate).format('YYYY-MM-DD')), 'day') + 1) + '일';
    }
    else {
      if (moment(data.endDate).isAfter(moment())) return (moment(moment(data.endDate).format('YYYY-MM-DD')).diff(moment(moment(data.startDate).format('YYYY-MM-DD')), 'day') + 1) + '일';
      else return (moment(moment().format('YYYY-MM-DD')).diff(moment(moment(data.startDate).format('YYYY-MM-DD')), 'day') + 1) + '일';
    }
  }

  // 결제 진행된 금액 표시 //
  function calcPaymentState() {
    if (data.cardPrice !== 0) {
      if (data.cashPrice !== 0) return '카드 - ' + data.cardPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '원, 현금 - ' + data.cashPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '원';
      else return '카드 - ' + data.cardPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '원';
    }
    else if (data.cashPrice !== 0) return '현금 - ' + data.cashPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '원';
    else return '무료';
  }


  // 사용 기간 표기 //
  function calcUsedDate() {
    if (data.isExit) return data.startDate.split('T')[0].replaceAll('-', '.').slice(2, 11) + ' - ' + data.endDate.split('T')[0].replaceAll('-', '.').slice(2, 11);
    else return data.startDate.split('T')[0].replaceAll('-', '.').slice(2, 11) + ' - ' + data.scheduledEndDate.split('T')[0].replaceAll('-', '.').slice(2, 11);
  }

  // 옵션 버튼 클릭시 //
  function onClickLockerOption() {
    if (!data.isExpectedLocker) setIsClicked(!isClicked); 
  }

  return (
    <TopLevelWrapper border={border} hoverBorder={hoverBorder} backgroundColor={backgroundColor} onMouseOver={mouseOver} onMouseLeave={mouseLeave}>
      { isViewCustomAlert && <CustomAlert text={text} closeFn={() => setIsViewCustomAlert(false)}/> }
      { isViewDeleteWarningModal && <LockerUsageDeleteWarningModal onClickExit={() => setIsViewDeleteWarningModal(false)} onClickDelete={onClickDeleteLocker}/> }
      <Table>
        <Body>
          {/* <Tr style={{ width: '108px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '24px' }}>
                <Text>{data.lockerName}</Text>
              </TextWrapper>
            </Td>
          </Tr> */}
          <Tr style={{ width: '81px', marginRight: '12px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '24px' }}>
                <Text color={fontColor}>{data.lockerName}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          {/* <Tr style={{ width: '207px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '127px' }}>
                <Text>{data.startDate.split('T')[0].replaceAll('-', '.').s
                lice(2, 11) + ' - ' + data.scheduledEndDate.split('T')[0].replaceAll('-', '.').slice(2, 11)}</Text>
              </TextWrapper>
            </Td>
          </Tr> */}
          <Tr style={{ width: '151px', marginRight: `12px` }}>
            <Td>
              <TextWrapper style={{ maxWidth: '127px' }}>
                {/* <Text>{data.startDate.split('T')[0].replaceAll('-', '.').slice(2, 11) + ' - ' + data.scheduledEndDate.split('T')[0].replaceAll('-', '.').slice(2, 11)}</Text> */}
                <Text color={fontColor}>{calcUsedDate()}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          {/* <Tr style={{ width: '217px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '127px' }}>
                {
                  checkOverDate() ?
                    <RedText>{redTextCalc()}</RedText>
                    :
                    <Text>없음</Text>
                }
              </TextWrapper>
            </Td>
          </Tr> */}
          <Tr style={{ width: '80px', marginRight: `12px` }}>
            <Td>
              {/* <TextWrapper style={{ maxWidth: '127px' }}>
                {
                  checkOverDate() ?
                    <RedText>{redTextCalc()}</RedText>
                    :
                    <Text>없음</Text>
                }
              </TextWrapper> */}
              <TextWrapper style={{ maxWidth: '127px' }}>
                <Text color={fontColor}>{calcUseDate()}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          {/* <Tr style={{ width: '170px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '55px' }}>
                <Text>{data.status}</Text>
              </TextWrapper>
            </Td>
          </Tr> */}
          <Tr style={{ width: '70px', marginRight: '8px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '70px' }}>
                {/* <Text color={fontColor}>{data.status}</Text> */}
                <Text color={fontColor}>{status}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          {/* <Tr style={data.status === '사용중' ? { width: '496px', marginRight: '26px' } : { width: '464px', marginRight: '26px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '464px' }}>
                <Text color={fontColor}>{calcPaymentState()}</Text>
              </TextWrapper>
            </Td>
          </Tr>  */}
          {/* <Tr style={data.status === '사용중' ? { width: '248px', marginRight: '8px' } : { width: '248px', marginRight: '8px' }}> */}
          <Tr style={{ width: '248px', marginRight: '8px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '464px' }}>
                <Text color={fontColor}>{calcPaymentState()}</Text>
              </TextWrapper>
            </Td>
          </Tr> 
          {/* <Tr style={data.status === '사용중' ? { width: '253px', marginRight: '8px' } : { width: '220px', marginRight: '8px' }}> */}
          <Tr style={{ width: '253px', marginRight: '8px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '253px'}}>
                <Text color={fontColor}>{moment(data.approvalDate).format('YY.MM.DD')}</Text>
              </TextWrapper>
            </Td>
          </Tr> 
          <Tr>
            <Td>
              <OptionButton ref={OptionButtonRef} src={buttonImage} onClick={onClickLockerOption} />
              <OptionWrapper ref={OptionBoxRef}>
                { data.status === '사용중' && <Option ref={OptionExtendRef} onMouseDown={() => onClickLockerExtends(data)}>사용 연장</Option> }
                { data.status === '사용중' && <Option ref={OptionExitRef} onMouseDown={() => onClickLockerExit(data)}>사용 종료</Option> }
                { data.status !== '사용중' && <Option ref={OptionDeleteRef} onMouseDown={() => setIsViewDeleteWarningModal(true)}>내역 삭제</Option> }
                <Option ref={OptionExtendRef} onMouseDown={() => onClickLockerModify(data)}>정보 수정</Option>
                {/* { <Option ref={OptionPushMessageRef} onMouseDown={onClickSendMessageButton}>문자 발송</Option> } */}
              </OptionWrapper>
            </Td>
          </Tr>
          {/* {
            data.status === '사용중' ?
              <Tr>
                <Td>
                  <OptionButton ref={OptionButtonRef} src={buttonImage} onClick={() => setIsClicked(!isClicked)} />
                  <OptionWrapper ref={OptionBoxRef}>
                    <Option ref={OptionExtendRef} onMouseDown={() => onClickLockerExtends(data)}>사용 연장</Option>
                    <Option ref={OptionExitRef} onMouseDown={() => onClickLockerExit(data)}>사용 종료</Option>
                    <Option ref={OptionPushMessageRef} onMouseDown={onClickSendMessageButton}>문자 발송</Option>
                  </OptionWrapper>
                </Td>
              </Tr>
              :
              <Tr style={{ width: '62px' }}>
                <Td>
                  <TextWrapper style={{ maxWidth: '62px' }}>
                    <Button ref={PushMessageButtonRef} onClick={onClickSendMessageButton}>문자발송</Button>
                  </TextWrapper>
                </Td>
              </Tr>
          } */}
        </Body>
      </Table>
    </TopLevelWrapper>
  )
};

export default LockerHistoryInfoView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  // justify-content: space-around;
  // justify-content: center;
  align-items: center;

  width: 1018px;
  height: 50px;

  // background: #FFFFFF;

  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  // border: 1px solid #FFFFFF;
  border: ${props => props.border};
  border-radius: 30px;
  // background-color: #FFFFFF;
  background-color: ${props => props.backgroundColor};
  
  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    // border: 1px solid #FF8B48;
    border: ${props => props.hoverBorder};
  }
`;

const Table = styled.table`
  display: flex;
  flex-direction: row;
`;

const Body = styled.tbody`
  display: flex;
  flex-direction: row;
  
  margin-left: 28px;
`;

const Tr = styled.tr`
  display: flex;
  flex-direction: row;
`;

const Td = styled.td`
  display: flex;
  flex-direction: row;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: flex-start;

  overflow: hidden;

  max-height: 20px;
  // border: 1px solid #232323;
`;

const Text = styled.span`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  // color: #595959;
  color: ${props => props.color};

  margin: 0 0 0 0;
`;

const RedText = styled.span`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #FF3131;

  margin: 0 0 0 0;
`;

const Button = styled.p`
  opacity: 0;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #FF8B48;

  margin: 0 0 0 0;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const OptionButton = styled.img`
  opacity: 0;
    
  transition: all 0.3s ease-in-out;
`;

const OptionWrapper = styled.div`
    position: absolute;

    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    overflow: hidden;
    height: 0px;
    
    z-index: 10;

    margin-top: 40px;
    // margin-left: -20px;
    margin-left: -30px;

    border-radius: 8px;

    transition: all 0.2s ease-in-out;
  `;

const Option = styled.div`
    display: flex;
    flex-direction: column;;

    justify-content: center;
    align-items: center;

    // width: 58px;
    width: 74px;
    height: 38px;

    margin: 0 0 0 0;

    font-family: NotoSansKR-Regular;
    font-size: 14px;

    color: #FF8B48;
    background-color: #FFFFFF;

    transition: all 0.3s ease-in-out;

    :hover {
      cursor: pointer;
      color: #FFFFFF;
      background-color: #FF8B48;
    }
  `;