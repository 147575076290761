import React, { useState } from "react";
import styled from "styled-components";
import moment from "moment";

import UserProfile from "../../../../Component/Normal/UserProfile";
import WhiteCheck from '../../../../image/WhiteCheck.png';

export default function ReservedMemberInfoBox(props) {
  const {
    memberData,
    index,
    totalLength,
    onViewUserPhone,
    onCalcButtonText,
    onClickMemberProfileOrText,
    onClickMemberExceptButton,
    onClickAttendanceButton
  } = props;

  const [isPlusButtonHover, setIsPlusButtonHover] = useState(false);

  return (
    <InfoWrapper key={index}>
      <ImageWrapper onClick={() => onClickMemberProfileOrText(memberData)}>
        <UserProfile profile={memberData.profile} defaultProfile='https://picture.link-zone.org/ZoneLogo.png' />
      </ImageWrapper>
      <MemberDataWrapper>
        <ReserveNumberWrapper>
          <TotalTextWrapper onClick={() => onClickMemberProfileOrText(memberData)}>
            <TextWrapper>
              <TextWrapper>
                <NameWrapper>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#777777' cursor='pointer'>{memberData.name}</Text>
                </NameWrapper>
                <PhoneWrapper>
                  <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#777777' cursor='pointer'>{onViewUserPhone(memberData.phone)}</Text>
                </PhoneWrapper>
              </TextWrapper>
            </TextWrapper>
            <TextWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={10} color='#777777'>{'신청 시간 : ' + moment(memberData.createdDate).format('YY.MM.DD - HH:mm')}</Text>
            </TextWrapper>
          </TotalTextWrapper>
          <ReserveNumberBox>
            <Text fontFamily='NotoSansKR-Medium' fontSize={10} color='#FFFFFF'>{'예약 ' + (totalLength - index)}</Text>
          </ReserveNumberBox>
        </ReserveNumberWrapper>

        <AttendanceCheckBox isComplete={(memberData.status === 1 || memberData.status === 2)} onClick={() => onClickAttendanceButton(memberData)}>
          <Text fontFamily='NotoSansKR-Medium' fontSize={10} color='#FFFFFF'>{onCalcButtonText(memberData)}</Text>
          {
            (memberData.status === 1 || memberData.status === 2) &&
            <AttendanceCheck>
              <CheckImage src={WhiteCheck} />
            </AttendanceCheck>
          }
        </AttendanceCheckBox>

        <ReserveExitButton
          onMouseOver={() => setIsPlusButtonHover(true)}
          onMouseLeave={() => setIsPlusButtonHover(false)}
          onClick={() => onClickMemberExceptButton(index)}>
          <PlusImage>
            <PlusWidth width={10} height={2} border={2} backgroundColor={isPlusButtonHover ? '#FFFFFF' : '#D92828'} />
            <PlusWidth width={2} height={10} border={2} backgroundColor={isPlusButtonHover ? '#FFFFFF' : '#D92828'} />
          </PlusImage>
        </ReserveExitButton>
      </MemberDataWrapper>
    </InfoWrapper>
  );
}

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 96%;
  height: 38px;

  gap: 10px;
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 38px;
  height: 38px;

  border-radius: 2px;

  overflow: hidden;

  :hover {
    cursor: pointer;
  }
`;

const MemberDataWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: calc(100% - 32px);
  height: 38px;

  border-bottom: 1px solid #F5F5F5;
`;

const ReserveNumberWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  // gap: 4px;
`;

const TotalTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 4px;

  :hover {
    cursor: pointer;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  gap: 4px;

  :hover {
    cursor: pointer;
  }
`;

const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 56px;
  height: 17px;

  overflow: hidden;
`;

const PhoneWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 105px;
  height: 17px;

  overflow: hidden;
`;

const ReserveExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;

  border-radius: 20px;
  background-color: #FFFFFF;

  :hover {
    cursor: pointer;
    background-color: #D92828;
  }

  transition: all 0.3s ease-in-out;
`;

const PlusImage = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 10px;
  height: 10px;
  
  -webkit-transform: rotate(45deg);
`;

const PlusWidth = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: ${props => props.radius}px;
  background-color: ${props => props.backgroundColor};

  transition: all 0.3s ease-in-out;
`;


const ReserveNumberBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 0px 5px;

  height: 17px;

  border-radius: 17px;
  background-color: #CBCBCB;

  :hover {
    cursor: default;
  }

  transition: all 0.3s ease-in-out;
`;

const AttendanceCheckBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // padding: ${props => props.isComplete ? '0px 4px' : '0px 6px'};
  // padding: 0px 6px;
  padding: 0px 6px 1px 6px;
  // width: 54px;
  height: 17px;

  gap: 4px;

  border-radius: 17px;
  background-color: ${props => props.isComplete ? `#6DD49E` : `#FF8B48`};

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const AttendanceCheck = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 1px;

  width: 12px;
  height: 12px;

  border-radius: 12px;
  background-color: #95DEB8;

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const CheckImage = styled.img`
  width: 6px;
  height: 6px;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }
`;