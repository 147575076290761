import React from 'react';
import styled from 'styled-components';

function TimeDivisionLine (props) {
  const { isMain, scheduleData } = props;

  return (
    <TopLevelWrapper>
      {
          scheduleData.isNeedNewDivide ?
          <TextWrapper >
            <TimeText>{scheduleData.viewTime}</TimeText>
            <TextTimeLine isNeedNewDivide={scheduleData.isNeedNewDivide} isMain={isMain}/>
          </TextWrapper>
          :
          <TimeLine/>
      }
    </TopLevelWrapper>
  )
}

export default React.memo(TimeDivisionLine);

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  justify-content: center;
  align-items: center;

  width: 38px;
  // background-color: red;

  margin-right: 14px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  
  // width: 33px;
`;

const TimeText = styled.p`
  font-family: Poppins-Regular;
  font-size: 12px;

  color: #777777;
  margin: 0 0 0 0;
`;

const TextTimeLine = styled.div`
  width: 2px;
  // height: ${props => props.index === 1 ? `50px` : `62px`};
  height: ${props => props.isMain ? `62px` : `57px`};

  border-right: 2px solid #ECECEC;
`;


const TimeLine = styled.div`
  width: 2px;
  // height: ${props => props.isNeedNewDivide ? `50px` : `80px`};
  height: 76px;

  border-right: 2px solid #ECECEC;
`;