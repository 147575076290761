import { useRef, React } from 'react';
import styled from 'styled-components';

import SearchImgPath from '../../../image/SearchIcon.png';

export default function SearchInput(props) {
	const { value, setValue, placeholder } = props;
  
	const inputRef = useRef();

	function handleClick(e) {
	}

	function handleChange(e) {
		setValue(e.target.value);
	}

	function handleFocus(e) {
	}

	function handleBlur(e) {
	}

	return <Input
		ref={inputRef}
    img={SearchImgPath}
		placeholder={placeholder}
		value={value}
		onClick={handleClick}
		onChange={handleChange}
		onFocus={handleFocus}
		onBlur={handleBlur}/>
}

const Input = styled.input`
	font-family: NotoSansKR-Regular;
	font-size: 12px;
	line-height: 20px;

  padding: 0 0 0 40px;
  width: 363px;
  height: 34px;

	outline: none;
  border: 1px solid #DFDFDF;
  border-radius: 8px;
	color: #000000;

  background: url(${props => props.img}) no-repeat 10px center;

	::placeholder {
    color: #CBCBCB;
	}
	
  transition: all 0.3s ease-in-out;
`;