import { useState, useImperativeHandle, forwardRef, useRef, React, useEffect } from 'react';
import styled from 'styled-components';

const KakaoAddressInput = forwardRef((props, ref) => {
	const { className, placeholder, type, clickCountFn, clicked } = props;
	const [value, setValue] = useState('');
	const [changed, setChanged] = useState(false);
	const [clickCount, setClickCount] = useState(0);
	const [defaultPlaceholder, setDefaultPlaceholder] = useState(placeholder);

	const inputRef = useRef();

	useImperativeHandle(ref, () => ({
		setValue: (val) => {
			setValue(val);
			setChanged(true);
			if (val !== undefined) inputRef.current.style.borderColor = '#E8E8E8';
		},
		getValue: () => {
			if (changed)
				return value;
			else
				return "";
		},
		plusClickCount: () => {
			setClickCount(clickCount + 1)
		},
		getClickCount: () => {
			return clickCount;
		},
		setDefaultPlaceholder: (val) => {
			setDefaultPlaceholder(val)	
			inputRef.current.style.borderColor = '#FF3131';
		},
		reset: (val) => {
			setClickCount(0);
			setDefaultPlaceholder(val)
			inputRef.current.style.borderColor = '#E8E8E8';
		}
	}));

	useEffect(() => {
		clickCountFn(className, clickCount);
	}, [clickCount, value])

	function handleClick(e) {
		inputRef.current.style.borderColor = '#FF8B48';
		setDefaultPlaceholder('');
	}

	function handleChange(e) {
		setValue(e.target.value);
		setChanged(true);
	}

	function handleFocus(e) {
		clicked();
	}

	function handleBlur(e) {
		setClickCount(clickCount + 1);
		if (value === undefined) {
			setDefaultPlaceholder(placeholder);
			inputRef.current.style.borderColor = '#FF3131';
		} 
		else {
			inputRef.current.style.borderColor = '#E8E8E8';
		}
	}

	return <Input
		ref={inputRef}
		clickCount={clickCount}
		type={type}
		placeholder={defaultPlaceholder}
		value={value}
		className={`${className} ${changed ? "changed" : ""}`}
		onClick={handleClick}
		onChange={handleChange}
		onFocus={handleFocus}
		onBlur={handleBlur} />
});

export default KakaoAddressInput;

const Input = styled.input`
	font-family: NotoSansKR-Regular;
	font-size: 14px;
	// font-size: max(12px, 0.7291666666666667vw);
	// line-height: 20px;
	
	padding: 0 0 4px 0;
	margin-top: 16px;
	// margin-top: max(12px, 0.8333333333333333vw);

	outline: none;
	border-width: 0 0 1px 0;
	border-color: #E8E8E8;
	color: #000000;

	::placeholder {
		color: ${props => props.clickCount < 1 || props.value !== '' ? `#CBCBCB` : `#FF3131;`};
	}
	
  transition: all 0.5s ease-in-out;
`;