import React, { useState } from "react";
import styled from "styled-components";

export default function MoreExplanationView(props) {
  const { width, height, radius, text } = props;

  const [isHover, setIsHover] = useState(false);

  return (
    <TopLevelWrapper>
      <SearchBubbleWrapper isHover={isHover}>
        <SearchBubble>
          <SearchBubbleInnerWrapper>
            <Text fontFamily='NotoSansKR-Bold' fontSize={8} color='#FF8B48' hover='default'>{text}</Text>
          </SearchBubbleInnerWrapper>
        </SearchBubble>
        <SearchBubbleTriangle/>
      </SearchBubbleWrapper>
      <QuestionMark 
        width={width} 
        height={height} 
        radius={radius}
        isHover={isHover}
        onMouseOver={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}>
        <Text fontFamily='NotoSansKR-Bold' fontSize={10} color='#FFFFFF' hover='cursor'>?</Text>
      </QuestionMark>
    </TopLevelWrapper>
  );
}

const TopLevelWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;
`;

const QuestionMark = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border: ${props => props.isHover ? 'rgb(255, 139, 72, 1)' : 'rgb(255, 139, 72, 0.5)'};
  border-radius: ${props => props.radius}px;
  background-color: ${props => props.isHover ? 'rgb(255, 139, 72, 1)' : 'rgb(255, 139, 72, 0.5)'};

  :hover {
    cursor: pointer;
  }
  
  transition: all 0.3s ease-in-out;
`;

const SearchBubbleWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-left: -162px;

  opacity: ${props => props.isHover ? 1 : 0};

  transition: all 0.3s ease-in-out;
`;

const SearchBubble = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 144px;
  height: 40px;

  border-radius: 5px;
  background-color: #FDF6F2;
`;

const SearchBubbleTriangle = styled.div`
  border-bottom: 10px solid transparent;
  border-top: 10px solid transparent;
  border-left: 10px solid #FDF6F2;
  border-right: 10px solid transparent;
`;

const SearchBubbleInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 137px;
  height: 100%;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;