import { React, useState } from 'react';
import styled from 'styled-components';

export default function RefundReasonInput(props) {
  const { value, setValue, placeholder} = props;
	// // const [value, setValue] = useState('');
	// const [changed, setChanged] = useState(false);

	function handleChange(e) {
		setValue(e.target.value);
		// setChanged(true);
	}

	function handleFocus(e) {
    // setValue("");
		// if (changed) {
		// 	// do nothing
		// } else {
		// 	setValue("");
		// }
	}

	function handleBlur(e) {

	}

  return(
    <TopLevelWrapper>
      <Text>사유</Text>
      <Input
        placeholder={placeholder}
        value={value || ''}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur} />
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 12px;
`;

const Text = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;

  margin: 0 0 0 0;
`;

const Input = styled.textarea`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;
  
	width: 297px;
	height: 73px;

	padding: 6px 8px 6px 8px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;

  font-fmaily: NotoSansKR-Regular;
  font-size: 14px;
  line-height: 20.27px;

	color: #000000;
  
  resize: none;
	outline: none;

  ::placeholder {
		color: #CBCBCB;
	}
`;