class SortingModule {
  dataSort(data, division, state, keyword) {
    let returnData = data.slice();

    // repValidRankData.sort((a, b) => b.completeRep - a.completeRep || a.tieBreak - b.tieBreak);
    // console.log(keyword);
    // console.log(division);
    // console.log(returnData);
    if (state === 'default') {
      // returnData.sort(function (a, b) {
      //   return a[keyword] - b[keyword];
      // });
      returnData.sort((a, b) => a[keyword] - b[keyword] || (a['name'] < b['name'] ? -1 : a['name'] > b['name'] ? 1 : 0));
    }
    else if (state === 'asc') {
      // returnData.sort(function (a, b) {
      //   return a[division] < b[division] ? -1 : a[division] > b[division] ? 1 : 0;
      // });
      returnData.sort((a, b) => (a[division] < b[division] ? -1 : a[division] > b[division] ? 1 : 0) || (a['name'] < b['name'] ? -1 : a['name'] > b['name'] ? 1 : 0));
    }
    else if (state === 'desc') {
      // returnData.sort(function (a, b) {
      //   return a[division] > b[division] ? -1 : a[division] < b[division] ? 1 : 0;
      // });
      returnData.sort((a, b) => (a[division] > b[division] ? -1 : a[division] < b[division] ? 1 : 0) || (a['name'] < b['name'] ? -1 : a['name'] > b['name'] ? 1 : 0));
    }
    
    return returnData;
  }
}

export default new SortingModule();