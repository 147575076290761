import { React, useRef } from 'react';
import styled from 'styled-components';
import moment from 'moment';

function HoldingHistoryInfoView(props) {
  const {
    data,
    onClickHoldingAndExtendButton,
    onClickHoldingEdit,
    onClickDeleteHoldingHistory,
    onClickModifyExtend,
    onClickDeleteExtend
  } = props;

  const CancelRef = useRef();
  const DeleteRef = useRef();

  function onClickComponent(e) {
    if (e.target === CancelRef.current || e.target === DeleteRef.current) return;

    onClickHoldingAndExtendButton(data);
  }

  function mouseOver() {
    // if (data.status !== '연장 추가') {
    CancelRef.current.style.opacity = 1;
    DeleteRef.current.style.opacity = 1;
    // }
  }

  function mouseLeave() {
    // if (data.status !== '연장 추가') {
    CancelRef.current.style.opacity = 0;
    DeleteRef.current.style.opacity = 0;
    // }
  }

  return (
    <TopLevelWrapper onClick={onClickComponent} onMouseOver={mouseOver} onMouseLeave={mouseLeave}>
      <Table>
        <Body>
          <Tr style={{ width: '186px', marginRight: `8px` }}>
            <Td>
              <TextWrapper style={{ maxWidth: '186px' }}>
                <Text>{moment(data.startDate).format('YY.MM.DD') + " - " + moment(data.endDate).format('YY.MM.DD')}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '136px', marginRight: `8px` }}>
            <Td>
              <TextWrapper style={{ maxWidth: '136px' }}>
                <Text>{(moment(data.endDate).diff(data.startDate, 'day') + 1)}일</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '312px', marginRight: '8px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '312px' }}>
                <Text>{data.memberShipName}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          {
            data.status === '연장 추가' ?
              // <Tr style={{ width: '223px', marginRight: `8px` }}>
              <Tr style={{ width: '147px', marginRight: `8px` }}>
                <Td>
                  <TextWrapper style={{ maxWidth: '223px' }}>
                    <Text>{data.status}</Text>
                  </TextWrapper>
                </Td>
              </Tr>
              :
              // <Tr style={{ width: '223px', marginRight: `8px` }}>
              <Tr style={{ width: '147px', marginRight: `8px` }}>
                <Td>
                  <TextWrapper style={{ maxWidth: '223px' }}>
                    <Text>{moment(data.startDate).format('YYYY.MM.DD') > moment().format('YYYY.MM.DD') ? '홀딩 예정' : moment(data.endDate).format('YYYY.MM.DD HH:mm:ss') > moment().format('YYYY.MM.DD HH:mm:ss') ? '홀딩 진행중' : '홀딩 종료'}</Text>
                  </TextWrapper>
                </Td>
              </Tr>
          }
          <Tr style={{ width: '60px', marginRight: `16px` }}>
            <Td>
              <TextWrapper style={{ maxWidth: '60px' }}>
                {
                  data.status === '연장 추가' ?
                    <CancelButton ref={CancelRef} onClick={() => onClickModifyExtend(data)}>연장 수정</CancelButton>
                    :
                    <CancelButton ref={CancelRef} onClick={() => onClickHoldingEdit(data)}>홀딩 수정</CancelButton>
                }
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '60px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '60px' }}>
                {
                  data.status === '연장 추가' ?
                    <CancelButton ref={DeleteRef} onClick={() => onClickDeleteExtend(data)}>연장 삭제</CancelButton>
                    :
                    <CancelButton ref={DeleteRef} onClick={() => onClickDeleteHoldingHistory(data)}>내역 삭제</CancelButton>
                }
              </TextWrapper>
            </Td>
          </Tr>
        </Body>
      </Table>
    </TopLevelWrapper>
  )
}

export default HoldingHistoryInfoView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  // justify-content: space-around;
  // justify-content: center;
  align-items: center;

  width: 1018px;
  height: 50px;

  background: #FFFFFF;

  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  border: 1px solid #FFFFFF;
  border-radius: 30px;
  
  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #FF8B48;
  }
`;

const Table = styled.table`
  display: flex;
  flex-direction: row;
`;

const Body = styled.tbody`
  display: flex;
  flex-direction: row;
  
  margin-left: 28px;
`;

const Tr = styled.tr`
  display: flex;
  flex-direction: row;
`;

const Td = styled.td`
  display: flex;
  flex-direction: row;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: flex-start;

  overflow: hidden;

  max-height: 20px;
  // border: 1px solid #232323;
`;

const Text = styled.span`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;

  margin: 0 0 0 0;
  padding: 0 0 0 0;
`;

const OptionButton = styled.img`
  opacity: 0;
  
  transition: all 0.3s ease-in-out;
`;

const OptionWrapper = styled.div`
  position: absolute;

  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  overflow: hidden;

  // margin-top: 200px;
  // margin-left: 960px;
  margin-top: 40px;
  margin-left: -20px;
  
  z-index: 5;

  // border: 1px solid #FF8B48;
  border-radius: 8px;
  // background-color: #FFFFFF;

  transition: all 0.2s ease-in-out;
`;

const CancelButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #FF8B48;
  background-color: #FFFFFF;

  opacity: 0;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;