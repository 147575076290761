import { React, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Lottie from 'lottie-react-web';

import DataBar from './DataBar';

import FetchModule from '../../Share/Network/FetchModule';

import LoadingAnimation from '../../../Animation/LoadingAnimation.json';

const StatisticsChart = (props) => {
  const { today, yearSalesData, isOpenPartnerGymSales, isLoading, setIsLoading } = props;

  const history = useHistory();

  const monthlyDate = ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'];

  // const [yearSalesData, setYearSalesData] = useState([]);
  const [usedData, setUsedData] = useState([]);
  // const [date, setDate] = useState(undefined);
  // const [isOpenPartnerGymSales, setIsOpenPartnerGymSales] = useState(false);

  const [maximum, setMaximum] = useState(0);

  // const [isLoading, setIsLoading] = useState(false);

  // useEffect(() => {
  //   if (date !== undefined && today.format('YYYY') === date.format('YYYY')) return;

  //   setIsLoading(true);

  //   // const fetchModule = new FetchModule();
  //   // (
  //   //   async () => {
  //   //     const responseData = await fetchModule.getIndividualData('sales/all/' + today.clone().format('YYYY'), 'GET');
  //   //     console.log("-----Year Sales");
  //   //     console.log(responseData);
  //   //     if (responseData.status === 200) setYearSalesData(responseData.data);
  //   //     else if (responseData.status === 401) history.push('/');
  //   //     else if (responseData.status !== 204) setYearSalesData([]);
  //   //   }
  //   // )();

  //   let requestData = {
  //     standardYear: today.format('YYYY') + '-01-01T00:00:00'
  //   }

  //   const fetchModule = new FetchModule();
  //   (
  //     async () => {
  //       const responseData = await fetchModule.postDataVersion2('sales/history/year', 'POST', requestData);
  //       // console.log("-----Year Sales");
  //       // console.log(responseData);
  //       if (responseData.status === 200) setYearSalesData(responseData.data);
  //       else if (responseData.status === 401) history.push('/');
  //       else if (responseData.status !== 204) setYearSalesData([]);
  //     }
  //   )();

  //   setDate(today);
  //   setUsedData([]);
  // }, [history, today, date])

  useEffect(() => {
    let one = { totalPrice: 0, cashPrice: 0, cardPrice: 0, discountPrice: 0, notReceived: 0 };
    let two = { totalPrice: 0, cashPrice: 0, cardPrice: 0, discountPrice: 0, notReceived: 0 };
    let three = { totalPrice: 0, cashPrice: 0, cardPrice: 0, discountPrice: 0, notReceived: 0 };
    let four = { totalPrice: 0, cashPrice: 0, cardPrice: 0, discountPrice: 0, notReceived: 0 };
    let five = { totalPrice: 0, cashPrice: 0, cardPrice: 0, discountPrice: 0, notReceived: 0 };
    let six = { totalPrice: 0, cashPrice: 0, cardPrice: 0, discountPrice: 0, notReceived: 0 };
    let seven = { totalPrice: 0, cashPrice: 0, cardPrice: 0, discountPrice: 0, notReceived: 0 };
    let eight = { totalPrice: 0, cashPrice: 0, cardPrice: 0, discountPrice: 0, notReceived: 0 };
    let nine = { totalPrice: 0, cashPrice: 0, cardPrice: 0, discountPrice: 0, notReceived: 0 };
    let ten = { totalPrice: 0, cashPrice: 0, cardPrice: 0, discountPrice: 0, notReceived: 0 };
    let eleven = { totalPrice: 0, cashPrice: 0, cardPrice: 0, discountPrice: 0, notReceived: 0 };
    let twelve = { totalPrice: 0, cashPrice: 0, cardPrice: 0, discountPrice: 0, notReceived: 0 };

    let maxPrice = 0;

    for (let i = 0; i < yearSalesData.length; i++) {
      if(yearSalesData[i].isLinkPaySettlementPayment) continue;
      
      if (yearSalesData[i].approvalDate.split('T')[0].split('-')[1] === '01') {
        // one.totalPrice += yearSalesData[i].originalPrice;
        one.totalPrice += (yearSalesData[i].cashPrice + yearSalesData[i].cardPrice + yearSalesData[i].notReceivedPrice);
        one.cashPrice += yearSalesData[i].cashPrice;
        one.cardPrice += yearSalesData[i].cardPrice;
        // one.discountPrice += yearSalesData[i].discountPrice;
        one.notReceived += yearSalesData[i].notReceivedPrice;
        // one.notReceived += yearSalesData[i].originalPrice - (yearSalesData[i].cashPrice + yearSalesData[i].cardPrice) > 0 ? yearSalesData[i].originalPrice - (yearSalesData[i].cashPrice + yearSalesData[i].cardPrice) : 0;

        // if (one.totalPrice > maxPrice) maxPrice = one.totalPrice;
        if (one.cashPrice + one.cardPrice + one.notReceived > maxPrice) maxPrice = one.cashPrice + one.cardPrice + one.notReceived;
      }
      else if (yearSalesData[i].approvalDate.split('T')[0].split('-')[1] === '02') {
        // two.totalPrice += yearSalesData[i].originalPrice;
        two.totalPrice += (yearSalesData[i].cashPrice + yearSalesData[i].cardPrice + yearSalesData[i].notReceivedPrice);
        two.cashPrice += yearSalesData[i].cashPrice;
        two.cardPrice += yearSalesData[i].cardPrice;
        // two.discountPrice += yearSalesData[i].discountPrice;
        two.notReceived += yearSalesData[i].notReceivedPrice;
        // two.notReceived += yearSalesData[i].originalPrice - (yearSalesData[i].cashPrice + yearSalesData[i].cardPrice) > 0 ? yearSalesData[i].originalPrice - (yearSalesData[i].cashPrice + yearSalesData[i].cardPrice) : 0;

        // if (two.totalPrice > maxPrice) maxPrice = two.totalPrice;
        if (two.cashPrice + two.cardPrice + two.notReceived > maxPrice) maxPrice = two.cashPrice + two.cardPrice + two.notReceived;
      }
      else if (yearSalesData[i].approvalDate.split('T')[0].split('-')[1] === '03') {
        // three.totalPrice += yearSalesData[i].originalPrice;
        three.totalPrice += (yearSalesData[i].cashPrice + yearSalesData[i].cardPrice + yearSalesData[i].notReceivedPrice);
        three.cashPrice += yearSalesData[i].cashPrice;
        three.cardPrice += yearSalesData[i].cardPrice;
        // three.discountPrice += yearSalesData[i].discountPrice;
        three.notReceived += yearSalesData[i].notReceivedPrice;
        // three.notReceived += yearSalesData[i].originalPrice - (yearSalesData[i].cashPrice + yearSalesData[i].cardPrice) > 0 ? yearSalesData[i].originalPrice - (yearSalesData[i].cashPrice + yearSalesData[i].cardPrice) : 0;

        // if (three.totalPrice > maxPrice) maxPrice = three.totalPrice;
        if (three.cashPrice + three.cardPrice + three.notReceived > maxPrice) maxPrice = three.cashPrice + three.cardPrice + three.notReceived;
      }
      else if (yearSalesData[i].approvalDate.split('T')[0].split('-')[1] === '04') {
        // four.totalPrice += yearSalesData[i].originalPrice;
        four.totalPrice += (yearSalesData[i].cashPrice + yearSalesData[i].cardPrice + yearSalesData[i].notReceivedPrice);
        four.cashPrice += yearSalesData[i].cashPrice;
        four.cardPrice += yearSalesData[i].cardPrice;
        // four.discountPrice += yearSalesData[i].discountPrice;
        four.notReceived += yearSalesData[i].notReceivedPrice;
        // four.notReceived += yearSalesData[i].originalPrice - (yearSalesData[i].cashPrice + yearSalesData[i].cardPrice) > 0 ? yearSalesData[i].originalPrice - (yearSalesData[i].cashPrice + yearSalesData[i].cardPrice) : 0;

        // if (four.totalPrice > maxPrice) maxPrice = four.totalPrice;
        if (four.cashPrice + four.cardPrice + four.notReceived > maxPrice) maxPrice = four.cashPrice + four.cardPrice + four.notReceived;
      }
      else if (yearSalesData[i].approvalDate.split('T')[0].split('-')[1] === '05') {
        // five.totalPrice += yearSalesData[i].originalPrice;
        five.totalPrice += (yearSalesData[i].cashPrice + yearSalesData[i].cardPrice + yearSalesData[i].notReceivedPrice);
        five.cashPrice += yearSalesData[i].cashPrice;
        five.cardPrice += yearSalesData[i].cardPrice;
        // five.discountPrice += yearSalesData[i].discountPrice;
        five.notReceived += yearSalesData[i].notReceivedPrice;
        // five.notReceived += yearSalesData[i].originalPrice - (yearSalesData[i].cashPrice + yearSalesData[i].cardPrice) > 0 ? yearSalesData[i].originalPrice - (yearSalesData[i].cashPrice + yearSalesData[i].cardPrice) : 0;

        // if (five.totalPrice > maxPrice) maxPrice = five.totalPrice;
        if (five.cashPrice + five.cardPrice + five.notReceived > maxPrice) maxPrice = five.cashPrice + five.cardPrice + five.notReceived;
      }
      else if (yearSalesData[i].approvalDate.split('T')[0].split('-')[1] === '06') {
        // six.totalPrice += yearSalesData[i].originalPrice;
        six.totalPrice += (yearSalesData[i].cashPrice + yearSalesData[i].cardPrice + yearSalesData[i].notReceivedPrice);
        six.cashPrice += yearSalesData[i].cashPrice;
        six.cardPrice += yearSalesData[i].cardPrice;
        // six.discountPrice += yearSalesData[i].discountPrice;
        six.notReceived += yearSalesData[i].notReceivedPrice;
        // six.notReceived += yearSalesData[i].originalPrice - (yearSalesData[i].cashPrice + yearSalesData[i].cardPrice) > 0 ? yearSalesData[i].originalPrice - (yearSalesData[i].cashPrice + yearSalesData[i].cardPrice) : 0;

        // if (six.totalPrice > maxPrice) maxPrice = six.totalPrice;
        if (six.cashPrice + six.cardPrice + six.notReceived > maxPrice) maxPrice = six.cashPrice + six.cardPrice + six.notReceived;
      }
      else if (yearSalesData[i].approvalDate.split('T')[0].split('-')[1] === '07') {
        // seven.totalPrice += yearSalesData[i].originalPrice;
        seven.totalPrice += (yearSalesData[i].cashPrice + yearSalesData[i].cardPrice + yearSalesData[i].notReceivedPrice);
        seven.cashPrice += yearSalesData[i].cashPrice;
        seven.cardPrice += yearSalesData[i].cardPrice;
        // seven.discountPrice += yearSalesData[i].discountPrice;
        seven.notReceived += yearSalesData[i].notReceivedPrice;
        // seven.notReceived += yearSalesData[i].originalPrice - (yearSalesData[i].cashPrice + yearSalesData[i].cardPrice) > 0 ? yearSalesData[i].originalPrice - (yearSalesData[i].cashPrice + yearSalesData[i].cardPrice) : 0;

        // if (seven.totalPrice > maxPrice) maxPrice = seven.totalPrice;
        if (seven.cashPrice + seven.cardPrice + seven.notReceived > maxPrice) maxPrice = seven.cashPrice + seven.cardPrice + seven.notReceived;
      }
      else if (yearSalesData[i].approvalDate.split('T')[0].split('-')[1] === '08') {
        // eight.totalPrice += yearSalesData[i].originalPrice;
        eight.totalPrice += (yearSalesData[i].cashPrice + yearSalesData[i].cardPrice + yearSalesData[i].notReceivedPrice);
        eight.cashPrice += yearSalesData[i].cashPrice;
        eight.cardPrice += yearSalesData[i].cardPrice;
        // eight.discountPrice += yearSalesData[i].discountPrice;
        eight.notReceived += yearSalesData[i].notReceivedPrice;
        // eight.notReceived += yearSalesData[i].originalPrice - (yearSalesData[i].cashPrice + yearSalesData[i].cardPrice) > 0 ? yearSalesData[i].originalPrice - (yearSalesData[i].cashPrice + yearSalesData[i].cardPrice) : 0;

        // if (eight.totalPrice > maxPrice) maxPrice = eight.totalPrice;
        if (eight.cashPrice + eight.cardPrice + eight.notReceived > maxPrice) maxPrice = eight.cashPrice + eight.cardPrice + eight.notReceived;
      }
      else if (yearSalesData[i].approvalDate.split('T')[0].split('-')[1] === '09') {
        // nine.totalPrice += yearSalesData[i].originalPrice;
        nine.totalPrice += (yearSalesData[i].cashPrice + yearSalesData[i].cardPrice + yearSalesData[i].notReceivedPrice);
        nine.cashPrice += yearSalesData[i].cashPrice;
        nine.cardPrice += yearSalesData[i].cardPrice;
        // nine.discountPrice += yearSalesData[i].discountPrice;
        nine.notReceived += yearSalesData[i].notReceivedPrice;
        // nine.notReceived += yearSalesData[i].originalPrice - (yearSalesData[i].cashPrice + yearSalesData[i].cardPrice) > 0 ? yearSalesData[i].originalPrice - (yearSalesData[i].cashPrice + yearSalesData[i].cardPrice) : 0;
        // if (nine.totalPrice > maxPrice) maxPrice = nine.totalPrice;
        if (nine.cashPrice + nine.cardPrice + nine.notReceived > maxPrice) maxPrice = nine.cashPrice + nine.cardPrice + nine.notReceived;
      }
      else if (yearSalesData[i].approvalDate.split('T')[0].split('-')[1] === '10') {
        // ten.totalPrice += yearSalesData[i].originalPrice;
        ten.totalPrice += (yearSalesData[i].cashPrice + yearSalesData[i].cardPrice + yearSalesData[i].notReceivedPrice);
        ten.cashPrice += yearSalesData[i].cashPrice;
        ten.cardPrice += yearSalesData[i].cardPrice;
        // ten.discountPrice += yearSalesData[i].discountPrice;
        ten.notReceived += yearSalesData[i].notReceivedPrice;
        // ten.notReceived += yearSalesData[i].originalPrice - (yearSalesData[i].cashPrice + yearSalesData[i].cardPrice) > 0 ? yearSalesData[i].originalPrice - (yearSalesData[i].cashPrice + yearSalesData[i].cardPrice) : 0;

        // if (ten.totalPrice > maxPrice) maxPrice = ten.totalPrice;
        if (ten.cashPrice + ten.cardPrice + ten.notReceived > maxPrice) maxPrice = ten.cashPrice + ten.cardPrice + ten.notReceived;
      }
      else if (yearSalesData[i].approvalDate.split('T')[0].split('-')[1] === '11') {
        // eleven.totalPrice += yearSalesData[i].originalPrice;
        eleven.totalPrice += (yearSalesData[i].cashPrice + yearSalesData[i].cardPrice + yearSalesData[i].notReceivedPrice);
        eleven.cashPrice += yearSalesData[i].cashPrice;
        eleven.cardPrice += yearSalesData[i].cardPrice;
        // eleven.discountPrice += yearSalesData[i].discountPrice;
        eleven.notReceived += yearSalesData[i].notReceivedPrice;
        // eleven.notReceived += yearSalesData[i].originalPrice - (yearSalesData[i].cashPrice + yearSalesData[i].cardPrice) > 0 ? yearSalesData[i].originalPrice - (yearSalesData[i].cashPrice + yearSalesData[i].cardPrice) : 0;

        // if (eleven.totalPrice > maxPrice) maxPrice = eleven.totalPrice;
        if (eleven.cashPrice + eleven.cardPrice + eleven.notReceived > maxPrice) maxPrice = eleven.cashPrice + eleven.cardPrice + eleven.notReceived;
      }
      else if (yearSalesData[i].approvalDate.split('T')[0].split('-')[1] === '12') {
        // twelve.totalPrice += yearSalesData[i].originalPrice;
        twelve.totalPrice += (yearSalesData[i].cashPrice + yearSalesData[i].cardPrice + yearSalesData[i].notReceivedPrice);
        twelve.cashPrice += yearSalesData[i].cashPrice;
        twelve.cardPrice += yearSalesData[i].cardPrice;
        // twelve.discountPrice += yearSalesData[i].discountPrice;
        twelve.notReceived += yearSalesData[i].notReceivedPrice;
        // twelve.notReceived += yearSalesData[i].originalPrice - (yearSalesData[i].cashPrice + yearSalesData[i].cardPrice) > 0 ? yearSalesData[i].originalPrice - (yearSalesData[i].cashPrice + yearSalesData[i].cardPrice) : 0;

        // if (twelve.totalPrice > maxPrice) maxPrice = twelve.totalPrice;
        if (twelve.cashPrice + twelve.cardPrice + twelve.notReceived > maxPrice) maxPrice = twelve.cashPrice + twelve.cardPrice + twelve.notReceived;
      }
    }

    let newData = [];

    newData.push(one);
    newData.push(two);
    newData.push(three);
    newData.push(four);
    newData.push(five);
    newData.push(six);
    newData.push(seven);
    newData.push(eight);
    newData.push(nine);
    newData.push(ten);
    newData.push(eleven);
    newData.push(twelve);

    // console.log(one);
    // console.log(two);
    // console.log(three);
    // console.log(four);
    // console.log(five);
    // console.log(six);
    // console.log(seven);
    // console.log(eight);
    // console.log(nine);
    // console.log(ten);
    // console.log(eleven);
    // console.log(twelve);
    // console.log(newData);
    setUsedData(newData);
    setMaximum((parseInt(maxPrice) + parseInt(maxPrice * 0.1)));

    setTimeout(() => (
      setIsLoading(false)
    ), 1000)
  }, [yearSalesData])

  return (
    <TopLevelWrapper>
      <InfoBox>
        <Title>{today.clone().format('YYYY년')} 월 별 매출 비교</Title>
        {
          isLoading ?
            <AnimationBox>
              <LottieWrapper>
                <Lottie options={{ animationData: LoadingAnimation }} />
              </LottieWrapper>
            </AnimationBox>
            :
            <TotalInfoView>
              {
                isOpenPartnerGymSales ?
                  <InfoView>
                    <ChartWrapper>
                      <TotalChartWrapper>
                        <MonthlyDateWrapper>
                          {
                            monthlyDate.map((data, index) => (
                              <MonthlyDateText key={index}>{data}</MonthlyDateText>
                            ))
                          }
                        </MonthlyDateWrapper>
                        <ChartView>
                          {
                            usedData.map((data, index) => (
                              <DataBar key={index} data={data} maximum={maximum} />
                            ))
                          }
                        </ChartView>
                      </TotalChartWrapper>
                    </ChartWrapper>
                  </InfoView>
                  :
                  <NotOpenPartnerGymSalesBox>
                    <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#444444' cursor='default'>이 업체는 파트너 체육관에 매출을 비공개 합니다</Text>
                    <Text fontFamily='NotoSansKR-Regular' fontSize={15} color='#444444' cursor='default'>링크존 설정에서 “파트너 체육관”에서 권한 설정해 주세요</Text>
                  </NotOpenPartnerGymSalesBox>
              }
            </TotalInfoView>
        }
      </InfoBox>
    </TopLevelWrapper>
  )
}

export default StatisticsChart;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 582px;
  height: 574px;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  background-color: #FFFFFF;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-conetent: center;
  align-items: flex-start;

  width: 90.37%;
  height: 90.24%;
`;

const TotalInfoView = styled.div`
  display: flex;
  flex-direction: column;

  justify-conetent: center;
  align-items: center;
  
  width: 100%;
  height: 100%;
`;

const NotOpenPartnerGymSalesBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
`;

const InfoView = styled.div`
  display: flex;
  flex-direction: column;

  justify-conetent: center;
  align-items: center;

  // background-color: red;
`;

const AnimationBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  z-index: 5;
  
  width: 100%;
  height: 100%;

  // background-color: rgba(0, 0, 0, 0.2);
  background-color: #FFFFFF;
`;

const LottieWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 80px;
  height: 80px;
`;

const Title = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;
`;

const ChartWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 526px;
  height: 474px;

  margin-top: 24px;
  border-top: 1px solid #DFDFDF;
`;

const TotalChartWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 24px;

  width: 526px;
  height: 450px;
`;

const MonthlyDateWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  gap: 18px;
  margin-right: 43px;
`;

const MonthlyDateText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;

  margin: 0 0 0 0;
`;

const ChartView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 450px;
  height: 440px;

  gap: 21px;

  border-left: 1px solid #CBCBCB;
  border-bottom: 1px solid #CBCBCB;
  // background-color: red;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }
`;

// const BarWrapper = styled.div`
//   display: flex;
//   flex-direction: row;

//   overflow: hidden;
//   border-radius: 0px 5px 5px 0px;
// `;

// const DataBar = styled.div`
//   display: flex;
//   flex-direction: row;

//   justify-content: flex-start;
//   align-items: center;

//   width: ${props => props.width}px;
//   height: 22px;

//   background-color: ${props => props.color};
// `;

// const DataText = styled.p`
//   font-family: NotoSansKR-Bold;
//   font-size: 10px;

//   color: #FFFFFF;

//   margin: 0 0 0 8px;
// `;

// const DataPercent = styled.p`
//   font-family: NotoSansKR-Medium;
//   font-size: 10px;

//   color: #FFFFFF;

//   margin: 0 0 0 3px;
// `;