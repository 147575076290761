import { React, forwardRef, useState, useRef, useImperativeHandle, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';

import FetchModule from '../../Model/Share/Network/FetchModule';

import SelectOnPath from '../../image/SelectOn.png';
import SelectOffPath from '../../image/SelectOff.png';

const WodLoadingClassSelectBox = forwardRef((props, ref) => {
	const { date, setScheduleId, rendering, checkAvailableChangeScheduleData } = props;
  const history = useHistory();

	const [isClicked, setIsClicked] = useState(false);
	const [scheduleData, setScheduleData] = useState([]);
  const [options, setOptions] = useState([]);
	const [categoryId, setCategoryId] = useState('');
	const [defaultVal, setDefaultVal] = useState({ name: '' });

	const SelectRef = useRef();
	const OptionWrapperRef = useRef([]);

	useImperativeHandle(ref, () => ({
		setValue: (val) => {
			setCategoryId(val);
		},
		getValue: () => {
			return defaultVal;
		}
	}))
  
	// Schedule Data API호출
  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getData('schedule/', 'GET');
        // console.log(responseData.data);
        if (responseData.status === 200) setScheduleData(responseData.data);
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }, [history])

  // Schedule Data를 사용 가능한 Data로 가공
  useEffect(() => {
    var tmp = scheduleData.slice();
    var newScheduleData = [];

    for (var i = 0; i < tmp.length; i++) {
        var endPoint = moment(tmp[i].classDate.split('T')[0]);

        while (tmp[i].endDate.split('T')[0] > endPoint.format('YYYY-MM-DD')) {
          if (endPoint.diff(date, 'days') >= 30) break;

					// 이거 대안을 찾아야함 ( 평일 주말에 따라 표출되는 Option값이 달라서 에러가 생김 )
          // if (tmp[i].applyWeek.includes(endPoint.format('ddd')) || tmp[i].applyWeek === 'All') {
            var colSpan = 1;
            var rowSpan = 1;

            // 세로부분 합칠 크기 지정 ( 시간에 맞게 계산 )
            colSpan = parseInt(tmp[i].classTime.split('-')[1].split(':')[0]) - parseInt(tmp[i].classTime.split('-')[0].split(':')[0]);

            var newData = {
              classId: tmp[i].classId,
              classDate: endPoint.format('YYYY.MM.DD'),
              className: tmp[i].className,
              classTime: tmp[i].classTime,
              color: tmp[i].color,
              endDate: tmp[i].endDate,
              lessonId: tmp[i].lessonId,
              scheduleId: tmp[i].scheduleId,
              teacherName: tmp[i].teacherName,
              applyWeek: tmp[i].applyWeek,
              rowSpan: rowSpan,
              colSpan: colSpan,
              maxBookedPeople: tmp[i].maxBookedPeople,
              deduction: tmp[i].deduction
            }
            newScheduleData.push(newData);
          // }
          endPoint = endPoint.clone().add(1, 'day');
        }
    }

    newScheduleData.sort(function (a, b) {
      return a.classDate < b.classDate ? -1 : a.classDate > b.classDate ? 1 : 0;
    })
		// console.log(newScheduleData);

    var newData = [];

    for (i = 0; i < newScheduleData.length; i++) {

      if (newScheduleData[i].classDate === date.clone().format('YYYY.MM.DD')) {
        newData.push(newScheduleData[i]);
      }
    }

		newData.sort(function (a, b) {
      return a.classTime < b.classTime ? -1 : a.classTime > b.classTime ? 1 : 0;
    })
		
    // setUseScheduleData(newData);
		if (newData.length !== 0) setScheduleId(newData[0].scheduleId);
		setOptions(newData);
  }, [scheduleData, date])
  

  useEffect(() => {
    if (options.length === 0) return;

		if (options.findIndex(i => i.id === categoryId) === -1 ) setDefaultVal(options[0]);
		else setDefaultVal(options[options.findIndex(i => i.id === categoryId)]);
  }, [options, categoryId])

	function onClick() {
		if (options.length === 0) return;
		
		if (isClicked) {
			SelectRef.current.style.border = '1px solid #CBCBCB';
			SelectRef.current.style.height = `38px`;
			// SelectRef.current.style.height = `26px`;
			SelectRef.current.style.overflow = 'hidden';
			setIsClicked(false);
		}
		else {
			SelectRef.current.style.border = '1px solid #FF8B48';
			SelectRef.current.style.height = (38 * (options.length)) > 400 ? `400px` : ((38 * (options.length)) + 'px');
			SelectRef.current.style.overflowY = 'scroll';
			SelectRef.current.style.overflowX = 'none';
			setIsClicked(true);
		}
	}

	function optionalClick(index) {
		setDefaultVal(options[index]);
		setScheduleId(options[index].scheduleId);
		rendering();
    // if (checkAvailableChangeScheduleData()) {
    //   setDefaultVal(options[index]);
    //   setScheduleId(options[index].scheduleId);
    //   rendering();
    // }
    // else {
    //   alert("디자인된 운동을 저장 후 실행해 주세요!");
    // }
	}

	return (
		<TopLevelWrapper>
			<SelectBox
				ref={SelectRef}
				isClicked={isClicked}
				buttonPath={isClicked ? SelectOnPath : SelectOffPath}
				onClick={onClick}>
				{
					isClicked ?
						options.map((data, index) =>
							<OptionWrapper 
								key={index} 
								ref={element => (OptionWrapperRef.current[index] = element)}
								onClick={() => optionalClick(index)}>
								<ClassNameOption 
									isClicked={isClicked}>
										{data.className}
								</ClassNameOption>
								<ClassTimeOption 
									isClicked={isClicked}>
										{data.classTime}
								</ClassTimeOption>
							</OptionWrapper>
						)
						:
						<OptionWrapper>
							<ClassNameOption isClicked={isClicked}>{options.length === 0 ? '일정 없음' : defaultVal.className}</ClassNameOption>
							<ClassTimeOption isClicked={isClicked}>{options.length === 0 ? '' : defaultVal.classTime}</ClassTimeOption>
						</OptionWrapper>
				}
			</SelectBox>
		</TopLevelWrapper>
	)
})

export default WodLoadingClassSelectBox;

const TopLevelWrapper = styled.div`
  position: relative;
	display: flex;
	flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
  
	width: 100%;
	height: 40px;
`;

const SelectBox = styled.div`
	position: absolute;

	display: flex;
	flex-direction: column;

	justify-content: flex-start;
	align-items: flex-start;

	// padding: 6px 0px 6px 16px;
  padding: 0px 0px 0px 16px;

	// width: 392px;
	// height: 26px;
  width: calc(100% - 18px);
  height: 38px;

  z-index: 3;

	border: 1px solid #CBCBCB;
	// border-radius: 15px;
	border-radius: 8px;
	overflow: hidden;

  background: url(${props => props.buttonPath}) no-repeat 96% ${props => props.isClicked ? `1` : `55`}%;
	background-color: rgba(255, 255, 255, 1);
  transition: all 0.3s ease-in-out;

	:hover {
		cursor: pointer;
	}

	&::-webkit-scrollbar {
		width: 9px;
	}

	&::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 20px #FF8B48;
		border: 3px solid transparent;
		height: 25px;
		// background-color: #FF8B48;
		border-radius: 200px;
	}

	&::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px transparent;
		border: 4px solid transparent;
		background-color: transparent;
	}

	&::-webkit-scrollbar-track-piece {
		background-color: transparent;
	}
`;

const OptionWrapper = styled.div`
	display: flex;
	flex-direction: row;

	justify-content: flex-start;
	align-items: center;

	width: 100%;
	min-height: 38px;
	// min-height: 100%;

	:hover {
		cursor: pointer;
		color: #FF8B48;
	}
`;

const ClassNameOption = styled.p`
	display: flex;
	flex-direction: column;

	justify-content: flex-start;
	align-items: flex-start;

	font-family: NotoSansKR-Medium;
	font-size: 14px;

	margin: 0 0 0 0;

	// width: calc(96% - 16px);
	width: calc(40% - 16px);
	// height: 38px;
	height: 20px;

	overflow: hidden;

  transition: all 0.3s ease-in-out;
`;


const ClassTimeOption = styled.p`
	display: flex;
	flex-direction: column;

	justify-content: flex-start;
	align-items: flex-start;

	font-family: NotoSansKR-Medium;
	font-size: 14px;

	margin: 0 0 0 0;

	// width: calc(96% - 16px);
	width: calc(30% - 16px);
	// height: 38px;

	overflow: hidden;

  transition: all 0.3s ease-in-out;

`;