import React, { useState } from "react";
import styled from "styled-components";

export default function UserProfile(props) {
  const { profile, defaultProfile } = props;

  const [isErrorProfile, setIsErrorProfile] = useState(false);
  
  // 사진 불러오기 에러시 //
  function onError() {
    setIsErrorProfile(true);
  }

  // 화면에 표시할 사진 결정 //
  function decideUserProfile() {
    if (isErrorProfile || profile === undefined || profile === '') return defaultProfile;
    else return profile;
  }

  return (
    <Profile src={decideUserProfile()} onError={onError}/>
  );
}


const Profile = styled.img`
  width: 100%;
  height: 100%;

  object-fit: contain;
`;