import { React, useState } from 'react';
import styled from 'styled-components';

const RangeInput = (props) => {
  const { defaultVal, changeFn } = props;

  const [value, setValue] = useState(defaultVal);

  function handleChange(e) {
    setValue(e.target.value);
    changeFn(e.target.value);
  }

  return (
    <TopLevelWrapper>
      <Text>폰트 크기</Text>
      <Input 
        type='range'
        min={14}
        max={44}
        value={value || ''}
        onChange={handleChange}/>
    </TopLevelWrapper>
  )
}

export default RangeInput;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const Text = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  margin: 0 0 0 0;

  color: #777777;
`;

const Input = styled.input`
  width: 88px;
  height: 8px;

  border-radius: 8px;
  background-color: #ECECEC;

  -webkit-appearance: none;

  ::-webkit-slider-thumb {
    -webkit-appearance: none;

    width: 12px;
    height: 12px;
    border-radius: 12px;

    background-color: #FF8B48;

    :hover {
      cursor: pointer;
    }
  }
`;