import { React, useRef } from 'react';
import styled from 'styled-components';

export default function ModifyCustomInput(props) {
	const { value, setValue, unit, index } = props;

	const InputRef = useRef();

	function handleClick(e) {
	}

	function handleChange(e) {
    let regex = /[^0-9]/g;				// 숫자가 아닌 문자열을 선택하는 정규식
    let result = e.target.value.replace(regex, "");
    
    if (result === '') setValue(0, index);
		else setValue(parseInt(result), index);
	}

	function handleFocus(e) {
	}

	function handleBlur(e) {
	}

	return (
    <TopLevelWrapper>
      <Input
        ref={InputRef}
        type='text'
        placeholder=''
        value={value}
        onClick={handleClick}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur} />
      <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F'>{unit}</Text>
    </TopLevelWrapper>
  )
};

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding: 0px 15px;

  // width: calc(48% - 32px);
  width: calc(100% - 32px);
  height: 35px;

  border-radius: 12px;
  border: 1px solid #CBCBCB;
  // background-color: #F6F6F6;
  background-color: #FFFFFF;
  
  // gap: 8px;

  transition: all 0.3s ease-in-out;
`;

const Input = styled.input` 
	font-family: Poppins-Medium;
	font-size: 14px;
	line-height: 20px;
	
  display: flex;
  flex-direction: row;

  margin: -1px 0px 0px -2px;
  // width: 100%;
  width: 140px;

  border: 0px;
  // background-color: #F6F6F6;
  background-color: #FFFFFF;

  color: #595959;

	outline: none;

	::placeholder {
    font-family: Poppins-Medium;
    font-size: 14px;

		color: #CBCBCB;
	}

  &:disabled {
    background-color: #ECECEC;
  }

  transition: all 0.3s ease-in-out;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;
