import { React, useState } from 'react';
import styled from 'styled-components';

export default function ContentInput(props) {
  const { value, setValue, placeholder } = props;

  const [byte, setByte] = useState(0);

  function handleChange(e) {
    let text = e.target.value
    let byte = checkValueByte(text);

    if (byte <= 2000) {
      setByte(byte);
      setValue(text);
    }
  }

  function checkValueByte(str) {
    //UTF8 기준 Byte Length 계산
    let byteLength = 0;

    for (let i = 0, strLength = str.length; i < strLength; i++) {
      let code = str.charCodeAt(i);
      if (code < 0x0080) {
        //[0x0000, 0x007F]
        byteLength += 1;
      }
      else if (code < 0x0800) {
        //[0x0080, 0x07FF]
        byteLength += 2;
      }
      else if (code < 0xD800) {
        //[0x0800, 0xD7FF]
        byteLength += 3;
      }
      else if (code < 0xDC00) {
        //[0xD800, 0xDBFF]
        let codeNext = str.charCodeAt(++i);

        if (i < strLength && codeNext >= 0xDC00 && codeNext <= 0xDFFF) {
          //followed by [0xDC00, 0xDFFF]
          byteLength += 4;
        }
        else {
          throw new Error("UCS-2 String malformed");
        }
      }
      else if (code < 0xE000) {
        //[0xDC00, 0xDFFF]
        throw new Error("UCS-2 String malformed");
      }
      else {
        //[0xE000, 0xFFFF]
        byteLength += 3;
      }
    }
    return byteLength;
  }


  return (
    <TopLevelWrapper>
      <Input
        placeholder={placeholder}
        value={value}
        onChange={handleChange} />
      <ByteWrapper>
        <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#000000' hover='default'>{value === '' ? 0 : byte}</Text>
        <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#000000' hover='default'>/</Text>
        <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FF8B48' hover='default'>2000 Byte</Text>
      </ByteWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  padding: 8px 8px 8px 8px;

  width: calc(100% - 18px);
  height: 287px;

  overflow: hidden;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background-color: #FFFFFF;
`;

const Input = styled.textarea`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: calc(100% - 2px);
  height: calc(100% - 2px);

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #232323;

  padding: 0px 0px 0px 0px;
  border: 0px;

  outline: none;
  resize: none;
	overflow: visible;

  transition: all 0.3s ease-in-out;

  ::placeholder {
    color: #CBCBCB;
  }
  
`;

const ByteWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: flex-start;

  width: 100%;

  gap: 4px;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.hover};
  }
`;