import { React, useRef, useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { useHistory, useLocation } from 'react-router-dom';
import moment from "moment";

import CalenderInput from '../../../../../Component/MemberComponent/CalenderInput';
import IntegratedLockerSelect from "../../../../../Component/MemberComponent/IntegratedLockerSelect.js";
import PaymentInput from "../../../../../Component/MemberComponent/PaymentInput.js";
import DiscountPaymentInput from "../../../../../Component/MemberComponent/DiscountPaymentInput.js";
import FetchModule from "../../../../Share/Network/FetchModule.js";


export default function LockerUsageModifyModal(props) {
  const { data, lockerRendering, onClickExit } = props;

  const history = useHistory();
  const location = useLocation();

  const [memberId, setMemberId] = useState(0);
  const [startDate, setStartDate] = useState(moment().format('YYYY.MM.DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY.MM.DD'));
	const [selectedLocker, setSelectedLocker] = useState({ id: 0, name: '선택'});
  const [totalPrice, setTotalPrice] = useState(0);

  const CardPaymentRef = useRef();
  const CashPaymentRef = useRef();
  const DiscountPaymentRef = useRef();
  const StartCalenderRef = useRef();
  const EndCalenderRef = useRef();

  // Scroll Fixed //
  useEffect(() => {
    document.body.style = `overflow: hidden`;
    return () => document.body.style = `overflow: auto`;
  }, [])

  // MemberId Setting //
  useEffect(() => {
    setMemberId(parseInt(location.pathname.split('/')[4]));
  }, [location])

  // // Locker Data API Call //
  // useEffect(() => {
  //   const fetchModule = new FetchModule();
  //   (
  //     async () => {
  //       const responseData = await fetchModule.postData('locker/available', 'POST');

  //       if (responseData.status === 200) {
  //         setOption(responseData.data);
  //       }
  //       else if (responseData.status === 401) {
  //         alert('로그인 후 다시시도하세요');
  //         history.push('/');
  //       }
  //     }
  //   )();
  // }, [])

  // 데이터 초기 세팅 //
  useEffect(() => {
    setSelectedLocker({
      id: data.lockerId,
      name: data.lockerName
    })

    CardPaymentRef.current.setValue(data.cardPrice);
    CashPaymentRef.current.setValue(data.cashPrice);
    DiscountPaymentRef.current.setValue(data.discountPrice);

    setStartDate(moment(data.startDate).format('YYYY.MM.DD'));
    setEndDate(moment(data.endDate).format('YYYY.MM.DD'));
  }, [data])

  // EndDate Reset //
  useEffect(() => {
    if (startDate > endDate) {
      alert("사용 종료일이 시작일보다 빠르게 설정할 수 없습니다.");
      setEndDate(startDate);
      return;
    }

    // setDayCount(moment(endDate.format('YYYY-MM-DD 23:59:59')).diff(startDate, 'days') + 1);
  }, [startDate, endDate])

  function CalenderClick(className) {
    if (className === 'StartCalender') EndCalenderRef.current.closeCalender();
    else StartCalenderRef.current.closeCalender();
  }

  // // 적용일수 변환시 //
  // function changeDayCount(value) {
  //   if (value > 0) setEndDate(startDate.clone().add(value - 1, 'days'));
  //   else if (value === 0) setEndDate(startDate);

  //   setDayCount(value);
  // }

  // 저장하기 버튼 클릭시 //
  function onClickSave() {
    if (selectedLocker.id === 0) {
      alert('사용을 시작할 락커를 설정해주세요!');
      return;
    }

    let lockerId = selectedLocker.id;
    let usageId = data.usageId;
    let cardPrice = CardPaymentRef.current.getValue();
    let cashPrice = CashPaymentRef.current.getValue();
    let discountPrice = DiscountPaymentRef.current.getValue();
    let startDate = StartCalenderRef.current.getValue();
    let endDate = EndCalenderRef.current.getValue();

    if (startDate === '') {
      alert('시작일을 선택해 주세요');
      return;
    }
    else if (endDate === '') {
      alert('종료일을 선택해 주세요');
      return;
    }
    else if (memberId === 0 || memberId === undefined) {
      alert('사용할 회원을 선택해주세요');
      return;
    }

    let requestData = {
      usageId: usageId,
      lockerId: lockerId,
      startDate: moment(startDate).format('YYYY-MM-DDTHH:mm:ss'),
      endDate: moment(endDate).format('YYYY-MM-DDT23:59:59'),
      cardPrice: cardPrice,
      cashPrice: cashPrice,
      discountPrice: discountPrice,
    }
    
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataVersion2('locker/modify', 'POST', requestData);

        if (responseData.status === 200) {
          alert("락커 정보 변경이 완료되었습니다!");
          lockerRendering();
          onClickExit();
        }
        else if (responseData.status === 201) {
          alert('데이터가 존재하지 않습니다.');
          return;
        }
        else if (responseData.status === 202) {
          alert('삭제된 락커는 사용하실 수 없습니다.');
          return;
        }
        else if (responseData.status === 203) {
          alert('사용이 불가능한 락커입니다.');
          return;
        }
        else if (responseData.status === 204) {
          alert('이미 사용중인 락커입니다');
          return;
        }
        else if (responseData.status === 401) {
          alert("로그인 후 사용하세요");
          history.push('/');
        }
      }
    )();
  }

  // 결제 금액 변경시 //
  function changeValue() {
    let card = CardPaymentRef.current.getValue();
    let cash = CashPaymentRef.current.getValue();
    let discount = DiscountPaymentRef.current.getValue();

    setTotalPrice(card + cash + discount);
    // setNotReceivedPrice((totalPrice - (card + cash + discount)) > 0 ? (totalPrice - (card + cash + discount)) : 0);
  }

  return (
    <TopLevelWrapper>
      <ModalView>
        <InnerWrapper>
          <TitleWrapper>
            <Title>락커 정보</Title>
          </TitleWrapper>
          <InputWrapper style={{ marginTop: '26px' }}>
            <IndividualWrapper style={{ gap: '8px', marginRight: '65px' }}>
              <CustomText fontFamily='NotoSansKR-Regular' fontSize={12} colo='#595959' cursor='default'>락커 선택</CustomText>
              <IntegratedLockerSelect defaultVal={selectedLocker} setDefaultVal={setSelectedLocker} width={236}/>
            </IndividualWrapper>
            <IndividualWrapper style={{ marginRight: `27px` }}>
              <CustomText fontFamily='NotoSansKR-Regular' fontSize={12} colo='#595959' cursor='default'>락커 가격</CustomText>
              <CalcPaymentInputWrapper>
                <CalcPaymentInput>{totalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</CalcPaymentInput>
                <CalcPaymentUnit>원</CalcPaymentUnit>
              </CalcPaymentInputWrapper>
            </IndividualWrapper>
            <IndividualWrapper style={{ marginRight: `27px` }}>
              <CustomText fontFamily='NotoSansKR-Regular' fontSize={12} colo='#595959' cursor='default'>카드 결제 금액</CustomText>
              <PaymentInput ref={CardPaymentRef} changeFn={changeValue} width={77} placeholder={0} disabled={false} />
            </IndividualWrapper>
            <IndividualWrapper style={{ marginRight: `27px` }}>
              <CustomText fontFamily='NotoSansKR-Regular' fontSize={12} colo='#595959' cursor='default'>현금 결제 금액</CustomText>
              <PaymentInput ref={CashPaymentRef} changeFn={changeValue} width={77} placeholder={0} disabled={false} />
            </IndividualWrapper>
            <IndividualWrapper>
              <CustomText fontFamily='NotoSansKR-Regular' fontSize={12} colo='#595959' cursor='default'>할인 금액</CustomText>
              <DiscountPaymentInput ref={DiscountPaymentRef} changeFn={changeValue} width={77} placeholder={0} disabled={false} />
            </IndividualWrapper>
          </InputWrapper>
          <InputWrapper style={{ marginTop: '16px' }}>
            <IndividualWrapper style={{ gap: '8px', marginRight: `65px` }}>
              <CustomText fontFamily='NotoSansKR-Regular' fontSize={12} colo='#595959' cursor='default'>시작일</CustomText>
              <CalenderInput ref={StartCalenderRef} value={startDate} setValue={setStartDate} disabled={false} className='StartCalender' clickFn={CalenderClick} placeholder='시작일' width={236} />
            </IndividualWrapper>
            <IndividualWrapper style={{ gap: '8px', marginRight: `42px` }}>
              <CustomText fontFamily='NotoSansKR-Regular' fontSize={12} colo='#595959' cursor='default'>종료일</CustomText>
              <CalenderInput ref={EndCalenderRef} value={endDate} setValue={setEndDate} disabled={false} className='EndCalender' clickFn={CalenderClick} placeholder='종료일' width={236} />
            </IndividualWrapper>
            <IndividualWrapper style={{ gap: '8px', marginRight: `16px` }}>
              <CustomText fontFamily='NotoSansKR-Regular' fontSize={12} colo='#595959' cursor='default'>적용 일수</CustomText>
              <UsageDaysBoxWrapper>
                <UsageDaysBox>
                  <CustomText fontFamily='NotoSansKR-Regular' fontSize={14} color='#CBCBCB' cursor='default'>{moment(endDate).diff(moment(startDate), 'days') + 1}</CustomText>
                </UsageDaysBox>
                  <CustomText fontFamily='NotoSansKR-Regular' fontSize={12} color='#777777' cursor='default'>일</CustomText>
              </UsageDaysBoxWrapper>
            </IndividualWrapper>
          </InputWrapper>
          <ButtonWrapper>
            <ExitButton onClick={onClickExit}>취소</ExitButton>
            <SaveButton onClick={onClickSave}>저장하기</SaveButton>
          </ButtonWrapper>
        </InnerWrapper>
      </ModalView>
    </TopLevelWrapper>
  )
}

const startAnimation = keyframes`
	0% { opacity: 0; }
	50% { opacity: 0.5; }
	100% { opacity: 1; }
`;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  justify-content: center;
  align-items: center;

  z-index: 1000;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.2);
	
	animation-name: ${startAnimation};
	animation-duration: 0.5s;
	animation-timing-function: ease-out;
`;

const ModalView = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 897px;
  height: 284px;

  border-radius: 10px;

  background-color: #FFFFFF;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  // width: 84.9%;
  // width: 90.33%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: 0 0 0 0;
`;

const NameBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  padding: 0px 8px 0px 8px;

  width: 167px;
  height: 32px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background: #F4F4F4;
`;

const Name = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  // margin-top: 24px;
  margin-top: 32px;

  width: 100%;

  gap: 16px;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 72px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;
  
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #777777;
    color: #777777;
  }
`;

const SaveButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 15px;
  background-color: #5BD98A;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100%;

  // margin-top: 15px;
`;

const IndividualWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
`;

const UsageDaysBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const UsageDaysBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  padding: 0px 8px 0px 8px;

  width: 74px;
  height: 32px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background-color: #FFFFFF;
`;

const Text = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;

  margin: 0 0 0 0;
`;


const CustomText = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }
`;

const CalcPaymentInputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
  margin-top: 8px;
`;

const CalcPaymentInput = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  padding: 0 8px 0 8px;
  margin: 0 0 0 0;

  width: 77px;
  height: 30px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background-color: #ECECEC;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #FFFFFF;
`;

const CalcPaymentUnit = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  margin: 0 0 0 0;

  color: #777777;
`;