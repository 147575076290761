import { React, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

import FetchModule from '../../../../Share/Network/FetchModule';

const MemberShipDeleteModal = (props) => {
  const { data, index, className, exitClickFn, rendering } = props;

  const history = useHistory();

  useEffect(() => {
    // document.body.style.cssText = `position: fixed; top: -${window.scrollY}px`

    // return () => {
    //   const scrollY = document.body.style.top;
    //   document.body.style.cssText = `position: ""; top: "";`
    //   window.scrollTo(0, parseInt(scrollY || '0') * -1)
    // }
    document.body.style= `overflow: hidden`;
    return () => document.body.style = `overflow: auto`
  }, [])

  function exitClick() {
    exitClickFn(className);
  }

  function deleteClick() {
    // const fetchModule = new FetchModule();
    // (
    //   async () => {
    //     const responseData = await fetchModule.deleteData('membership/usage/delete/' + data[index].usageId, 'DELETE');
       
    //     if (responseData.status === 200) {
    //       alert('회원권을 삭제하였습니다.');
    //       exitClickFn(className);
    //       rendering();
    //     }
    //     else if (responseData.status === 401) {
    //       alert('로그인 후 다시시도하세요');
    //       history.push('/');
    //     }
    //   }
    // )();
    let requestData = {
      usageId: data[index].usageId
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataVersion2('membership/usage/delete/', 'POST', requestData);
       
        if (responseData.status === 200) {
          alert('회원권을 삭제하였습니다.');
          exitClickFn(className);
          rendering();
        }
        else if (responseData.status === 401) {
          alert('로그인 후 다시시도하세요');
          history.push('/');
        }
        else {
          alert(responseData.error);
          return;
        }
      }
    )();
  }

  return (
    <TopLevelWrapper>
      <ModalView>
        <TotalWrapper>
          <Title>회원권 삭제</Title>
          <TextWrapper>
            <Explanation>해당 회원권으로 예약이나 수강한 내역이 모두 삭제되며</Explanation>
            <ExplanationWrapper>
              <Explanation>결제내역은</Explanation>
              <ColorExplanation>전액 환불</ColorExplanation>
              <Explanation>진행 후 회원권이 삭제됩니다.</Explanation>
            </ExplanationWrapper>
            <Explanation>정말 삭제하시겠습니까?</Explanation>
          </TextWrapper>
          <ButtonWrapper>
            <ExitButton onClick={exitClick}>취소</ExitButton>
            <DeleteButton onClick={deleteClick}>삭제하기</DeleteButton>
          </ButtonWrapper>
        </TotalWrapper>
      </ModalView>
    </TopLevelWrapper> 
  )
}

export default MemberShipDeleteModal;

const startAnimation = keyframes`
	0% { opacity: 0; }
	50% { opacity: 0.5; }
	100% { opacity: 1; }
`;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  justify-content: center;
  align-items: center;

  z-index: 1000;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.2);
	
	animation-name: ${startAnimation};
	animation-duration: 0.5s;
	animation-timing-function: ease-out;
`;

const ModalView = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 401px;
  // height: 202px;
  width: 371px;
  height: 248px;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(0, 0, 0, 0.1);
  background-color: #FFFFFF;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 86.34%;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  // margin: 0 0 13px 28px;
  margin: 0 0 0 0;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  gap: 14px;

  margin-top: 16px;
`;

const ExplanationWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  gap 4px;
`;

const ColorExplanation = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #FF3131;
  
  margin: 0 0 0 0;
`;

const Explanation = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;
  
  margin: 0 0 0 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  // margin: 21px 0 0 194px;
  margin-top: 37px;

  gap: 14px;

  width: 100%;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 72px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;
  
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #777777;
    color: #777777;
  }
`;

const DeleteButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 15px;
  background-color: #FF3131;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;