import { React, useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';

import CalenderModal from '../NoteModel/CalenderModal';
import MemberListView from './MemberListView';
import ExerciseBox from './ExerciseBox';
import ExerciseTestBox from './ExerciseTestBox';
import NoticeView from './NoticeView';
import StopWatchView from './StopWatchView';
import MemberListModal from './MemberListModal';

import FetchModule from '../../Share/Network/FetchModule';

import LeftArrow from '../../../image/Left.png'//왼쪽 화살표
import RightArrow from '../../../image/Right.png'//우측 화살표
import CalenderBasic from '../../../image/calendarB.png'//캘린더 기본
import CalenderHover from '../../../image/calendarH.png'

export default function BoardView() {

  const history = useHistory();

  const [data, setData] = useState({
    workoutId: 0,
    scheduleId: 0,
    storeId: parseInt(window.localStorage.getItem('StoreId')),
    classDate: moment(),
    title: '작성이 필요합니다.',
    sectionList: []
  });
  const [date, setDate] = useState(moment());
  const [dateList, setDateList] = useState([]);
  const [clickDate, setClickDate] = useState(date.clone().format('YYYY.MM.DD'));
  const [workoutId, setWorkoutId] = useState(0);
  const [memberAddClick, setMemberAddClick] = useState(false);
  const [calenderClick, setCalenderClick] = useState(false);
  const [scheduleId, setScheduleId] = useState(-1);
  const [classTime, setClassTime] = useState(undefined);
  const [memberList, setMemberList] = useState([]);
  const [lessonId, setLessonId] = useState(-1);
  const [rendering, setRendering] = useState(false);
  const [memberListRendering, setMemberListRendering] = useState(false);

  const [useData, setUseData] = useState([{
    workoutId: 0,
    scheduleId: 0,
    storeId: parseInt(window.localStorage.getItem('StoreId')),
    classDate: moment(),
    title: '작성이 필요합니다.',
    sectionList: []
  }]);

  const [notepad, setNotepad] = useState({
    id: 0,
    workoutId: 0,
    contents: '',
  });

  const ExerciseRef = useRef();
  const CalenderButtonRef = useRef();
  const CalenderRef = useRef();
  const MemberListRef = useRef();

  const firstWeek = date.clone().startOf('month').week();
  const lastWeek = date.clone().endOf('month').week() === 1 ? 53 : date.clone().endOf('month').week();

  // Calendar Date Setting //
  useEffect(() => {
    let newDateList = [];

    for (let week = firstWeek; week <= lastWeek; week++) {
      for (let i = 0; i < 7; i++) {
        let days = date.clone().startOf('year').week(week).startOf('week').add(i, 'day');

        if (days.format('MM') === date.format('MM')) newDateList.push(days);
      }
    }
    setClickDate(date.clone().format('YYYY.MM.DD'));
    setDateList(newDateList);
  }, [date, firstWeek, lastWeek])

  // Workout API Call //
  useEffect(() => {
    if (workoutId === 0) {
      setData({
        workoutId: 0,
        scheduleId: 0,
        storeId: parseInt(window.localStorage.getItem('StoreId')),
        classDate: moment(),
        title: '작성이 필요합니다.',
        sectionList: []
      });

      return;
    }

    let data = {
      workoutId: workoutId
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        let responseData = await fetchModule.postDataOfExerciseVersion2('workout/board_workout/', 'POST', data);
        console.log(responseData.data);
        if (responseData.status === 200) {
          let workoutData = responseData.data;

          // 운동 데이터 정리 //
          workoutData.sectionList.sort(function (a, b) {
            return a.sectionIndex < b.sectionIndex ? -1 : a.sectionIndex > b.sectionIndex ? 1 : 0;
          })

          for (let j = 0; j < workoutData.sectionList.length; j++) {
            workoutData.sectionList[j].buyinExerciseList = [];
            workoutData.sectionList[j].buyinScaleExerciseList = [];
            workoutData.sectionList[j].mainExerciseList = [];
            workoutData.sectionList[j].mainScaleExerciseList = [];
            workoutData.sectionList[j].emomExerciseList = [];
            workoutData.sectionList[j].emomScaleExerciseList = [];
            workoutData.sectionList[j].buyoutExerciseList = [];
            workoutData.sectionList[j].buyoutScaleExerciseList = [];
            workoutData.sectionList[j].reps = workoutData.sectionList[j].reps.split('-');

            for (let h = 0; h < workoutData.sectionList[j].exerciseList.length; h++) {
              workoutData.sectionList[j].exerciseList[h].movementId = workoutData.sectionList[j].exerciseList[h].movement.movementId;
              workoutData.sectionList[j].exerciseList[h].movementName = workoutData.sectionList[j].exerciseList[h].movement.movementName;
              workoutData.sectionList[j].exerciseList[h].videoLinkId = workoutData.sectionList[j].exerciseList[h].movement.videoLinkId;

              // 무게 구분 데이터 사용할 경우 //
              if (workoutData.sectionList[j].exerciseList[h].divisionWeight !== ''
                && workoutData.sectionList[j].exerciseList[h].divisionWeight !== 'None') {
                workoutData.sectionList[j].exerciseList[h].weight = workoutData.sectionList[j].exerciseList[h].divisionWeight;
              }
              // 거리 구분 데이터 사용할 경우 //
              if (workoutData.sectionList[j].exerciseList[h].divisionDistance !== ''
                && workoutData.sectionList[j].exerciseList[h].divisionDistance !== 'None') {
                workoutData.sectionList[j].exerciseList[h].distance = workoutData.sectionList[j].exerciseList[h].divisionDistance;
              }
              // 높이 구분 데이터 사용할 경우 //
              if (workoutData.sectionList[j].exerciseList[h].divisionHeight !== ''
                && workoutData.sectionList[j].exerciseList[h].divisionHeight !== 'None') {
                workoutData.sectionList[j].exerciseList[h].height = workoutData.sectionList[j].exerciseList[h].divisionHeight;
              }
              // 칼로리 구분 데이터 사용할 경우 //
              if (workoutData.sectionList[j].exerciseList[h].divisionCalories !== ''
                && workoutData.sectionList[j].exerciseList[h].divisionCalories !== 'None') {
                workoutData.sectionList[j].exerciseList[h].calories = workoutData.sectionList[j].exerciseList[h].divisionCalories;
              }
              // 템포 구분 데이터 사용할 경우 //
              if (workoutData.sectionList[j].exerciseList[h].divisionTempo !== ''
                && workoutData.sectionList[j].exerciseList[h].divisionTempo !== 'None') {
                workoutData.sectionList[j].exerciseList[h].tempo = workoutData.sectionList[j].exerciseList[h].divisionTempo;
              }
              // Sets 구분 데이터 사용할 경우 //
              if (workoutData.sectionList[j].exerciseList[h].divisionSets !== ''
                && workoutData.sectionList[j].exerciseList[h].divisionSets !== 'None') {
                workoutData.sectionList[j].exerciseList[h].sets = workoutData.sectionList[j].exerciseList[h].divisionSets;
              }

              if (workoutData.sectionList[j].exerciseList[h].division === 'Buyin') {
                workoutData.sectionList[j].buyinExerciseList.push(workoutData.sectionList[j].exerciseList[h]);
              }
              else if (workoutData.sectionList[j].exerciseList[h].division === 'Buyin.Scale') {
                workoutData.sectionList[j].buyinScaleExerciseList.push(workoutData.sectionList[j].exerciseList[h]);
              }
              else if (workoutData.sectionList[j].exerciseList[h].division === 'Main') {
                workoutData.sectionList[j].mainExerciseList.push(workoutData.sectionList[j].exerciseList[h]);
              }
              else if (workoutData.sectionList[j].exerciseList[h].division === 'Main.Scale') {
                workoutData.sectionList[j].mainScaleExerciseList.push(workoutData.sectionList[j].exerciseList[h]);
              }
              else if (workoutData.sectionList[j].exerciseList[h].division === 'Main.Emom') {
                workoutData.sectionList[j].emomExerciseList.push(workoutData.sectionList[j].exerciseList[h]);
              }
              else if (workoutData.sectionList[j].exerciseList[h].division === 'Main.Emom.Scale') {
                workoutData.sectionList[j].emomScaleExerciseList.push(workoutData.sectionList[j].exerciseList[h]);
              }
              else if (workoutData.sectionList[j].exerciseList[h].division === 'Buyout') {
                workoutData.sectionList[j].buyoutExerciseList.push(workoutData.sectionList[j].exerciseList[h]);
              }
              else if (workoutData.sectionList[j].buyoutScaleExerciseList[h].division === 'Buyout.Scale') {
                workoutData.sectionList[j].buyoutScaleExerciseList.push(workoutData.sectionList[j].exerciseList[h]);
              }
            }

            // exerciseIndex.asc 진행 //
            workoutData.sectionList[j].buyinExerciseList.sort(function (a, b) {
              return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
            })
            // exerciseIndex.asc 진행 //
            workoutData.sectionList[j].buyinScaleExerciseList.sort(function (a, b) {
              return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
            })
            // exerciseIndex.asc 진행 //
            workoutData.sectionList[j].mainExerciseList.sort(function (a, b) {
              return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
            })
            // exerciseIndex.asc 진행 //
            workoutData.sectionList[j].mainScaleExerciseList.sort(function (a, b) {
              return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
            })
            // exerciseIndex.asc 진행 //
            workoutData.sectionList[j].emomExerciseList.sort(function (a, b) {
              return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
            })
            // exerciseIndex.asc 진행 //
            workoutData.sectionList[j].emomScaleExerciseList.sort(function (a, b) {
              return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
            })
            // exerciseIndex.asc 진행 //
            workoutData.sectionList[j].buyoutExerciseList.sort(function (a, b) {
              return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
            })
            // exerciseIndex.asc 진행 //
            workoutData.sectionList[j].buyoutScaleExerciseList.sort(function (a, b) {
              return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
            })
          }

          setData(workoutData);
        }
        // else if (responseData.status === 204) setData([]);
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }, [workoutId])

  // Alive Member API Call (Light Ver...) //
  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        // var responseData = await fetchModule.getData('member/alive/light/', 'GET');
        let responseData = await fetchModule.postData('member/alive/', 'POST');

        if (responseData.status === 200) {
          // console.log(responseData);
          setMemberList(responseData.data);
        }
        else if (responseData.status === 204) { }
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }, [history])

  // // Workout Data Setting //
  // useEffect(() => {
  //   if (dateList.length === 0) return;

  //   var newData = data.slice();

  //   if (newData.length === 0) { // 데이터가 없으면 1개 넣고 시작
  //     var tmpData = {
  //       workoutId: 0,
  //       scheduleId: 0,
  //       storeId: parseInt(window.localStorage.getItem('StoreId')),
  //       classDate: dateList[0],
  //       title: '',
  //       sectionList: []
  //     }
  //   }

  //   for (var i = 0; i < dateList.length; i++) {
  //     var isNone = true;

  //     for (var j = 0; j < newData.length; j++) {
  //       if (dateList[i].format('MM.DD') === moment(newData[j].classDate).format('MM.DD')) {
  //         isNone = false;
  //         newData[j].classDate = moment(newData[j].classDate);
  //         break;
  //       }
  //     }

  //     if (isNone) {
  //       tmpData = {
  //         workoutId: 0,
  //         scheduleId: 0,
  //         storeId: parseInt(window.localStorage.getItem('StoreId')),
  //         classDate: dateList[i],
  //         title: '',
  //         sectionList: []
  //       }
  //       newData.push(tmpData);
  //     }
  //   }

  //   newData.sort(function (a, b) {
  //     return a.classDate < b.classDate ? -1 : a.classDate > b.classDate ? 1 : 0;
  //   })

  //   setUseData(newData);
  // }, [dateList, data])

  // 운동 메모 불러오기 //
  useEffect(() => {
    if (workoutId === 0) return;

    let requestData = {
      workoutId: workoutId
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        let responseData = await fetchModule.postDataOfExercise('notepad/', 'POST', requestData);

        if (responseData.status === 200) {
          if (responseData.data[0] !== null) setNotepad(responseData.data[0]);
        }
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }, [workoutId])


  function onMouseOver() {
    CalenderButtonRef.current.style.border = `1px solid #777777`;
    CalenderButtonRef.current.style.background = `url(${CalenderHover}) no-repeat center center`;
  }

  function onMouseLeave() {
    CalenderButtonRef.current.style.border = `1px solid #DFDFDF`;
    CalenderButtonRef.current.style.background = `url(${CalenderBasic}) no-repeat center center`;
  }

  function dateClick(value) {
    // console.log(value);
    // setClickDate(value.format('YYYY.MM.DD'));
    setClickDate(value);
    setDate(moment(value));
    if (calenderClick) CalenderRef.current.setValue(value);
  }

  // Member Reserve Add Button Click //
  function onClickAddMemberSave(memberId) {

    let index = -1;

    for (var i = 0; i < memberList.length; i++) {
      if (memberList[i].memberId === memberId) index = i;
    }
    
    let requestData = {
      scheduleId: scheduleId,
      lessonId: lessonId,
      userId: memberList[index].memberId,
      classDate: date.format('YYYY-MM-DD') + "T00:00:00",
      classTime: classTime,
      status: 0
    }

    // console.log(requestData);
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postData('reserve/create/manage', 'POST', requestData);
        
        if (responseData.status === 200) setMemberListRendering(!memberListRendering);
        else if (responseData.status === 401) history.push('/');
      }
    )();

    setMemberAddClick(false);
  }


  return (
    <TopLevelWrapper>
      {calenderClick && <CalenderModal ref={CalenderRef} clickDate={clickDate} date={date} dateClickFn={dateClick} exitFn={setCalenderClick} />}
      {memberAddClick && <MemberListModal ref={MemberListRef} memberData={memberList} saveClick={onClickAddMemberSave} exitClick={() => setMemberAddClick(false)} />}
      <TopBarWrapper>
        <TextWrapper>
          <Title>보드</Title>
          <Explanation>센터의 일정이나 계획을 간편하게 입력할 수 있어요</Explanation>
        </TextWrapper>
      </TopBarWrapper>
      <TopButtonWrapper>
        <LeftWrapper>
          <DateButtonWrapper>
            <ArrowWrapper onClick={() => setDate(date.clone().subtract(1, 'day'))} >
              <ArrowImage src={LeftArrow} />
            </ArrowWrapper>
            <DateText>{date.clone().format('YYYY년 MM월 DD일')}</DateText>
            <ArrowWrapper onClick={() => setDate(date.clone().add(1, 'day'))} >
              <ArrowImage src={RightArrow} />
            </ArrowWrapper>
          </DateButtonWrapper>
          <TodayButton onClick={() => setDate(moment())}>오늘</TodayButton>
          <CalenderButton ref={CalenderButtonRef} img={CalenderBasic} onMouseOver={onMouseOver} onMouseLeave={onMouseLeave} onClick={() => setCalenderClick(!calenderClick)} />
        </LeftWrapper>
        <RightWrapper>
          <Button onClick={() => window.open('https://link-zone.io/full/board/' + window.localStorage.getItem('Token'), '', '_self',)}>전체화면 보기</Button>
          {/* <Button onClick={() => window.open('http://localhost:3000/full/board/' + window.localStorage.getItem('Token'), '', '_self',)}>전체화면 보기</Button> */}
        </RightWrapper>
      </TopButtonWrapper>
      <MainView>
        <ComponentWrapper>
          <MemberListView date={date} memberListRendering={memberListRendering} scheduleId={scheduleId} classTime={classTime} setMemberAddClick={setMemberAddClick} />
          {/* <ExerciseBox useData={useData} clickDate={clickDate} date={date} setLessonId={setLessonId} setScheduleId={setScheduleId} setClassTime={setClassTime} rendering={() => setRendering(!rendering)}/> */}
          <ExerciseTestBox
            ref={ExerciseRef}
            clickDate={clickDate}
            // useData={useData}
            useData={data}
            date={date}
            classTime={classTime}
            workoutId={workoutId}
            setWorkoutId={setWorkoutId}
            setLessonId={setLessonId}
            setScheduleId={setScheduleId}
            setClassTime={setClassTime}
            rendering={() => setRendering(!rendering)} />
        </ComponentWrapper>
        <ComponentWrapper style={{ marginBottom: `96px` }}>
          <StopWatchView />
          <NoticeView
            notepad={notepad}
            setNotepad={setNotepad}
            workoutId={workoutId}
            clickDate={clickDate} />
        </ComponentWrapper>
      </MainView>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin-left: 42px;
`;

const TopBarWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-left: 28px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: 0 0 0 0;
`;

const Explanation = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #777777;

  margin: 0 0 0 0;
`;

const TopButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;

  margin-top: 38px;
`;

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const RightWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 16px;
`;

const DateButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 205px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;

  gap: 20px;
`;

const ArrowWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 22px;
  height: 22px;

  :hover { 
    cursor: pointer;
  }
`;

const ArrowImage = styled.img`
  display: flex;
  flex-direction: row;

  width: 9px;
  height: 15px;
`;

const TodayButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 52px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #CBCBCB;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #777777;
    color: #777777;
  }
`;

const CalenderButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 38px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;
  background: url(${props => props.img}) no-repeat center center;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const DateText = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #595959;

  margin: 0 0 0 0;
`;

const Button = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 127px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #CBCBCB;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #777777;
    color: #777777;
  }
`;

const MainView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  gap: 30px;
  margin-top: 16px;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: flex-start;

  gap: 30px;
`;