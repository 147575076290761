import { React } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

export default function WeeklyAbsenteeMemberList(props) {
  const { data, selectedPartnerGym } = props;

  const history = useHistory();

  // 전화번호 표시 //
  function calcPhone(viewData) {
    if (viewData.phone === '') return '없음';
    else if (viewData.phone.length < 11) return '없음';
    else return viewData.phone.slice(0,3) + '-' + viewData.phone.slice(3,7) + '-' + viewData.phone.slice(7, 11);
  }

  // 회원 정보 클릭시 //
  function onClickMemberInfo(data) {
    if (selectedPartnerGym === undefined || selectedPartnerGym.isMyStore) history.push('/member/valid/detail/' + data.memberId);
    else history.push('/member/partner_gym/detail/' + data.memberId);
    // () => history.push('/member/valid/detail/' + data.memberId)
  }

  return (
    <TopLevelWrapper>
      <Table>
        <Head>
          <HeadTr>
            <Td style={{ width: `68px`, marginLeft: '8px' }}>이름</Td>
            <Td style={{ width: `55px` }}>성별</Td>
            <Td style={{ width: `98px` }}>생년월일</Td>
            <Td style={{ width: `114px` }}>핸드폰번호</Td>
            <Td style={{ width: `103px` }}>회원권</Td>
          </HeadTr>
        </Head>
      </Table>
      <Table>
        <Body>
          {
            data.map((data, index) => (
              <BodyTr key={index} onClick={() => onClickMemberInfo(data)}>
                <Td style={{ width: `60px`, marginLeft: '8px', overflow: 'hidden' , whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                  {data.name}
                </Td>
                <Td style={{ width: `56px`, marginLeft: '8px' }}>
                  {data.gender === 'male' ? '남성' : '여성'}
                </Td>
                <Td style={{ width: `98px` }}>
                  {data.birthDay[0]
                    + data.birthDay[1]
                    + data.birthDay[2]
                    + data.birthDay[3]
                    + '.'
                    + data.birthDay[4]
                    + data.birthDay[5]
                    + '.'
                    + data.birthDay[6]
                    + data.birthDay[7]}
                </Td>
                <Td style={{ width: `114px` }}>
                  {calcPhone(data)}
                </Td>
                <Td style={{ width: `80px`, overflow: 'hidden' , whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                  {data.membershipName}
                </Td>
              </BodyTr>
            ))
          }
        </Body>
      </Table>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Table = styled.table`
  display: flex;
  flex-direction: column;
`;

const Head = styled.thead`

`;

const Body = styled.tbody`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  // width: 464px;
  width: 454px;
  height: 200px;

  overflow-y: scroll;
  overflow-x: none;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
		border: 3px solid transparent;
		// height: 92px;
		background-color: #DADADA;
		border-radius: 200px;
	}

  &::-webkit-scrollbar-track {
    // height: 439px;
		// border: 4px solid transparent;
		background-color: #F0F0F0;
		border-radius: 200px;
	}
`;

const HeadTr = styled.tr`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  height: 32px;

  margin-top: 16px;
  border-radius: 8px;
  background-color: #ECECEC;
`;

const BodyTr = styled.tr`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  background-color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #F4F2F0;
  }
`;

const Td = styled.td`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  height: 36px;

  color: #595959;
`;