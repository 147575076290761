import { React } from 'react';
import styled from 'styled-components';

const SalesList = (props) => {
  const { data, onClickSalesList } = props;

  return (
    <TopLevelWrapper>
      <Table>
        <Head>
          {/* <HeadTr>
            <Td style={{ width: `67px`, minWidth: `67px`, padding: `0px` }}>이름</Td>
            <Td style={{ width: `351px`, minWidth: `351px`, padding: `0px` }}>결제 회원권</Td>
            <Td style={{ width: `91px`, minWidth: `91px`, padding: `0px` }}>결제금액</Td>
            <Td style={{ width: `91px`, minWidth: `91px`, padding: `0px` }}>카드</Td>
            <Td style={{ width: `91px`, minWidth: `91px`, padding: `0px` }}>현금</Td>
            <Td style={{ width: `91px`, minWidth: `91px`, padding: `0px` }}>할인금액</Td>
            <Td style={{ width: `91px`, minWidth: `91px`, padding: `0px` }}>미수금</Td>
            <Td style={{ width: `91px`, minWidth: `91px`, padding: `0px` }}>결제일</Td>
          </HeadTr> */}
          <HeadTr>
            <Td style={{ width: `67px`, minWidth: `67px`, padding: `0px` }}>이름</Td>
            <Td style={{ width: `260px`, minWidth: `260px`, padding: `0px` }}>결제 회원권</Td>
            <Td style={{ width: `91px`, minWidth: `91px`, padding: `0px` }}>결제금액</Td>
            <Td style={{ width: `91px`, minWidth: `91px`, padding: `0px` }}>링크페이</Td>
            <Td style={{ width: `91px`, minWidth: `91px`, padding: `0px` }}>카드</Td>
            <Td style={{ width: `91px`, minWidth: `91px`, padding: `0px` }}>현금</Td>
            <Td style={{ width: `91px`, minWidth: `91px`, padding: `0px` }}>할인금액</Td>
            <Td style={{ width: `91px`, minWidth: `91px`, padding: `0px` }}>미수금</Td>
            <Td style={{ width: `91px`, minWidth: `91px`, padding: `0px` }}>결제일</Td>
          </HeadTr>
        </Head>
      </Table>
      <Table>
        <Body>
          {
            data.map((data, index) => (
              <BodyTr key={index} onClick={() => onClickSalesList(data)}>
                <Td isRefund={data.isRefund === undefined ? false : data.isRefund} style={{ width: `59px`, padding: `0`, marginRight: `8px`, overflow: 'hidden' , whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
                  {data.name}
                </Td>
                {/* <Td isRefund={data.isRefund === undefined ? false : data.isRefund} style={{ width: `351px`, padding: `0` }}>
                 {data.itemName}
                </Td> */}
                <Td isRefund={data.isRefund === undefined ? false : data.isRefund} style={{ width: `260px`, padding: `0` }}>
                 {data.itemName}
                </Td>
                <Td isRefund={data.isRefund === undefined ? false : data.isRefund} style={{ width: `91px`, padding: `0` }}>
                  {(data.cardPrice + data.cashPrice).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </Td>
                <Td isRefund={data.isRefund === undefined ? false : data.isRefund} style={{ width: `91px`, padding: `0` }}>
                  {data.merchantKey === 'LinkPay' ? data.cardPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}
                </Td>
                <Td isRefund={data.isRefund === undefined ? false : data.isRefund} style={{ width: `91px`, padding: `0`}}>
                  {data.merchantKey !== 'LinkPay' ? data.cardPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}
                </Td>
                <Td isRefund={data.isRefund === undefined ? false : data.isRefund} style={{ width: `91px`, padding: `0` }}>
                  {data.cashPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </Td>
                {/* <Td style={{ width: `91px`, padding: `0` }}>
                  {data.originalPrice - (data.cashPrice + data.cardPrice) > 0 ? (data.originalPrice - (data.cashPrice + data.cardPrice)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}
                </Td> */}
                <Td isRefund={data.isRefund === undefined ? false : data.isRefund} style={{ width: `91px`, padding: `0` }}>
                  {data.discountPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </Td>
                <Td isRefund={data.isRefund === undefined ? false : data.isRefund} style={{ width: `91px`, padding: `0` }}>
                  {data.notReceivedPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </Td>
                <Td isRefund={data.isRefund === undefined ? false : data.isRefund} style={{ width: `75px`, padding: `0` }}>
                  {data.approvalDate.split('T')[0][2]
                  + data.approvalDate.split('T')[0][3]
                  + '.'
                  + data.approvalDate.split('T')[0][5]
                  + data.approvalDate.split('T')[0][6]
                  + '.'
                  + data.approvalDate.split('T')[0][8]
                  + data.approvalDate.split('T')[0][9]}
                </Td>
              </BodyTr>
            ))
          }
        </Body>
      </Table>
    </TopLevelWrapper>
  )
}

export default SalesList;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const Table = styled.table`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

`;
const Head = styled.thead`
  display: flex;
  flex-direction: column;
  
  justify-content: center;
  align-items: center;

  width: 100%;
`;

const Body = styled.tbody`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  // width: 992px;
  width: 100%;
  height: 352px;

  margin-top: 16px;

  overflow-y: scroll;
  overflow-x: none;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
		border: 3px solid transparent;
		// height: 92px;
		background-color: #DADADA;
		border-radius: 200px;
	}

  &::-webkit-scrollbar-track {
    // height: 439px;
		// border: 4px solid transparent;
		background-color: #F0F0F0;
		border-radius: 200px;
	}
`;

const HeadTr = styled.tr`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 32px;

  margin-top: 16px;
  padding-bottom: 16px;

  border-bottom: 1px solid #ECECEC;
  // border-radius: 8px;
  // background-color: #ECECEC;
`;

const BodyTr = styled.tr`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  background-color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #F4F2F0;
  }
`;

const Td = styled.td`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  height: 36px;

  color: ${props => props.isRefund ? `#FF3131` : `#595959`};
`;