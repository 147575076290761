import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';

import { Document, Page, pdfjs } from 'react-pdf';

import NextPDF from '../../../image/NextPDF.png';
import PrevPDF from '../../../image/PrevPDF.png';
import moment from 'moment';

export default function CenterSubscriptionTermsView(props) {
  const { terms, setIsClickedModify } = props;

  const [isFirst, setIsFirst] = useState(true);
  const [totalNumberPage, setTotalNumberPage] = useState(0);
  const [nowPage, setNowPage] = useState(1);
  const [height, setHeight] = useState('auto');
  const [isClickedFold, setIsClickedFold] = useState(false);

  const PDFRef = useRef();

  // PDF Viewer Setting //
  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, [])

  // 로딩 완료시 총 페이지 수 받아오기 //
  function onLoadSuccess({numPages}) {
    if (!isFirst) return;

    setTotalNumberPage(numPages);

    if (numPages !== 0) setNowPage(1);

    setIsFirst(false);
  }

  // 가입 약관 이전 버튼 클릭시 //
  function onClickPrev() {
    if (nowPage === 1) return;
    
    setHeight(PDFRef.current.clientHeight + 'px');
    setNowPage(nowPage - 1);
  }

  // 가입 약관 다음 버튼 클릭시 //
  function onClickNext() {
    if (nowPage === totalNumberPage) return;
    
    setHeight(PDFRef.current.clientHeight + 'px');
    setNowPage(nowPage + 1);
  }

  // 접기, 펼치기 버튼 클릭시 //
  function onClickFold() {
    setIsClickedFold(!isClickedFold);

    if (!isClickedFold) {
      setIsFirst(true);
      setNowPage(0);
      setTotalNumberPage(0);
    }
  }

  return (
    <TopLevelWrapper>
      <InfoView>
        <TitleWrapper>
          <SubWrapper>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#232323'>센터 가입 약관</Text>
            <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>{terms.termsUrl === '' ? '( 없음 )' : '( ' + moment(terms.registrationDate).format('YYYY.MM.DD HH:mm:ss') + ' )'}</Text>
          </SubWrapper>
          <RightButtonWrapper>
            <ButtonWrapper onClick={onClickFold}>
              <HoverText fontFamily='NotoSansKR-Regular' fontSize={14} color='#FF8B48'>{isClickedFold ? `펼치기` : `접기`}</HoverText>
            </ButtonWrapper>
            <UploadButton onClick={() => setIsClickedModify(true)}>
              <HoverText fontFamily='NotoSansKR-Regular' fontSize={12} color='#777777'>수정하기</HoverText>
            </UploadButton>
          </RightButtonWrapper>
        </TitleWrapper>
        {
          !isClickedFold &&
          <InfoWrapper>
            {
              terms.termsUrl !== '' ?
                <ViewWrapper ref={PDFRef} height={height}>
                  <Document
                    // file={{ url: 'https://link-picture.s3.ap-northeast-2.amazonaws.com/event/FETOnline/%5BFET_LinkCoach%5Dfinal_ScorecardOQEvent1.pdf' }}
                    file={{ url: terms.termsUrl }}
                    onLoadSuccess={onLoadSuccess}>
                    <Page pageNumber={nowPage} />
                  </Document>
                  <PDFButtonWrapper>
                    {
                      nowPage > 1 ?
                        <PDFButton onClick={onClickPrev}>
                          <Image src={PrevPDF} />
                        </PDFButton>
                        :
                        <EmptyButton />
                    }
                    {
                      (nowPage !== totalNumberPage) ?
                        <PDFButton onClick={onClickNext}>
                          <Image src={NextPDF} />
                        </PDFButton>
                        :
                        <EmptyButton />
                    }
                  </PDFButtonWrapper>
                </ViewWrapper>
                :
                <EmptyViewWrapper>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>등록된 센터 약관이 없습니다</Text>
                </EmptyViewWrapper>
            }
          </InfoWrapper>
        }
      </InfoView>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 34px;

  width: 100%;
`;

const InfoView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 28px 0px 28px 0px;

  width: 1191px;

  border-radius: 30px;
  background-color: #FFFFFF;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  width: 1135px;
`;

const SubWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  :hover {
    cursor: pointer;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 24px;
  border-top: 1px solid #ECECEC;

  width: 1135px;
  // height: ${props => props.height};
  // max-height: 1369px;

  // overflow: hidden;
  // transition: all 0.3s ease-in-out;
  // // background-color: #BDBDBD;
`;

const ViewWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 24px;

  width: 100%;
  height: ${props => props.height};
  max-height: 1369px;
`;

const EmptyViewWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  
  width: 100%;
  height: 100px;
`;

const PDFButtonWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 1135px;
`;

const PDFButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 80px;
  height: 80px;

  :hover {
    cursor: pointer;
  }
`;

const EmptyButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 80px;
  height: 80px;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
`;

const HoverText = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const UploadButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // margin-top: 24px;

  // width: 1135px;
  width: 85px;
  height: 30px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const RightButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  aling-items: center;

  gap: 14px;
`;