import { React } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import NoticeInfo from './NoticeInfo';

const Notice = (props) => {
  const { data } = props;

  const history = useHistory();

  function clickDetailsFn(index) {
    // console.log(data[index]);
    history.push('/notice/details/' + data[index].noticeId);
  }

  return (
    <TopLevelWrapper>
      <TotalView>
        {
          data.map((data, index) => (
            <NoticeInfo key={index} data={data} index={index} clickFn={clickDetailsFn}/>
          ))
        }
      </TotalView>
    </TopLevelWrapper>
  )
}

export default Notice;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;
  
  width: 1019px;

  transition: all 0.3s ease-in-out;
`;

const TotalView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 4px;

  width: 980px;
  height: 380px;
`;