import { forwardRef, React, useImperativeHandle, useRef } from 'react';
import styled, { keyframes } from 'styled-components';

import LinkManagerLogoPath from '../../../image/LinkManagerLogo.png';

const LogoView = forwardRef((props, ref) => {
	const TopLevelRef = useRef();

	useImperativeHandle(ref, () => ({
		setOpacity: (val) => {
			if (val === 0) {
				TopLevelRef.current.style.opacity = val;
			}
			else {
				TopLevelRef.current.style.transform = val;
			}
		}
	}))

	return (
		<TopLevelWrapper ref={TopLevelRef}>
			<BackgroundShape>
				<LogoWrapper>
					<Logo src={LinkManagerLogoPath} />
				</LogoWrapper>
				<MainName>Link Zone</MainName>
				<Explanation>쉽고 간편한 피트니스 회원 관리 프로그램</Explanation>
			</BackgroundShape>
		</TopLevelWrapper>
	)
});

export default LogoView;

const startAnimation = keyframes`
	0% { transform: translateY(1500px); }
	100% { transform: translateY(0px); }
`;

const TopLevelWrapper = styled.div`
	display: flex;
	flex-direction: column;
	animation-name: ${startAnimation};
	animation-duration: 0.5s;
	animation-timing-function: ease-out;
`;

const BackgroundShape = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	width: 480px;
	// width: max(210px, 25vw);
	height: 842px;
	// height: max(164.453125px, 43.85416666666667vw);

	margin-top: 238px;
	// margin-top: 12.39583333333333vw;
	margin-right: 132px;
	// margin-right: 6.875vw;

	border-radius: 50px 50px 0px 0px;
	box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);

	background-color: #FF8B48;
`;

const LogoWrapper = styled.div`
	display: flex;
	flex-direction: column;

	margin-top: 87px;
	// margin-top: max(30px, 4.53125vw);
`;

const Logo = styled.img`
	width: 250px;
	// width: max(68px, 13.02083333333333vw);
	height: 250px;
	// height: max(68px, 13.02083333333333vw);
`;

const MainName = styled.p`
	font-family: Poppins-Light;
	font-size: 50px;
	// font-size: max(13.671875px, 2.604166666666667vw);
	// line-height: 75px;
	color: rgba(255, 255, 255, 1);
		
	margin: 0 0 0 0;

	cursor: default;
`;

const Explanation = styled.p`
	font-family: NotoSansKR-Regular;
	font-size: 20px;
	// font-size: max(10px, 1.041666666666667vw);
	// line-height: 29px;
	color: #FFFFFF;
	opacity: 0.7;

	margin: 16px 0 0 0;
	// margin-top: max(4px, 0.8333333333333333vw);
	// margin-right: 0px;
	// margin-bottom: 0px;
	// margin-left: 0px;
		
	cursor: default;
`;
