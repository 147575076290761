import { React, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import parse from 'html-react-parser';

import NoticeInfo from './NoticeInfo';

import FetchModule from '../../Share/Network/FetchModule';

const NoticeList = (props) => {
  const { data } = props;

  const history = useHistory();

  function clickDetailsFn(index) {
    // console.log(data[index]);
    history.push('/notice/details/' + data[index].noticeId);
  }
  
  return (
    <TopLevelWrapper>
      <TotalView>
        {
          data.map((data, index) => (
            <NoticeInfo key={index} data={data} index={index} clickFn={clickDetailsFn}/>
          ))
        }
      </TotalView>
    </TopLevelWrapper>
  )
}

export default NoticeList;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 1020px;
  height: 428px;

  margin-top: 28px;
  margin-bottom: 44px;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  background-color: #FFFFFF;
`;

const TotalView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 4px;

  width: 980px;
  height: 380px;
`;
