import { React, useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';

import CalenderModal from '../NoteModel/CalenderModal';
import FullMemberListView from './FullMemberListView';
import FullExerciseBox from './FullExerciseBox';
import FullNoticeView from './FullNoticeView';
import FullStopWatchView from './FullStopWatchView';
import BoardSettingModeSelect from '../../../Component/BoardComponent/BoardSettingModeSelect';
import MemberListModal from './MemberListModal';

import FetchModule from '../../Share/Network/FetchModule';

import LeftArrow from '../../../image/Left.png'//왼쪽 화살표
import RightArrow from '../../../image/Right.png'//우측 화살표
import CalenderBasic from '../../../image/calendarB.png'//캘린더 기본
import CalenderHover from '../../../image/calendarH.png'
import FullExerciseTestBox from './FullExerciseTestBox';

export default function FullBoardView() {

  const history = useHistory();

  const [data, setData] = useState({
    workoutId: 0,
    scheduleId: 0,
    storeId: parseInt(window.localStorage.getItem('StoreId')),
    classDate: moment(),
    title: '작성이 필요합니다.',
    sectionList: []
  });
  const [date, setDate] = useState(moment());
  const [dateList, setDateList] = useState([]);
  const [clickDate, setClickDate] = useState(date.clone().format('YYYY.MM.DD'));
  const [workoutId, setWorkoutId] = useState(0);
  const [memberAddClick, setMemberAddClick] = useState(false);
  const [calenderClick, setCalenderClick] = useState(false);
  const [memberList, setMemberList] = useState([]);
  const [scheduleId, setScheduleId] = useState(-1);
  const [classTime, setClassTime] = useState(undefined);
  const [lessonId, setLessonId] = useState(-1);
  const [rendering, setRendering] = useState(false);
  const [memberListRendering, setMemberListRendering] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);
  const [mode, setMode] = useState('수업');

  const [useData, setUseData] = useState([{
    workoutId: 0,
    scheduleId: 0,
    storeId: parseInt(window.localStorage.getItem('StoreId')),
    classDate: moment(),
    title: '작성이 필요합니다.',
    sectionList: []
  }]);

  const [notepad, setNotepad] = useState({
    id: 0,
    workoutId: 0,
    contents: '',
  });

  const CalenderButtonRef = useRef();
  const CalenderRef = useRef();
  const MemberListRef = useRef();

  const TopLevelRef = useRef();

  const firstWeek = date.clone().startOf('month').week();
  const lastWeek = date.clone().endOf('month').week() === 1 ? 53 : date.clone().endOf('month').week();
  
  // Alive Member API Call (Light Ver...) //
  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        var responseData = await fetchModule.getData('member/alive/light/', 'GET');
        
        if (responseData.status === 200) {
          // console.log(responseData);
          setMemberList(responseData.data);
        }
        else if (responseData.status === 204) {}
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }, [date])
  
  // useEffect(() => {
  //   let newDateList = [];

  //   for (let week = firstWeek; week <= lastWeek; week++) {
  //     for (let i = 0; i < 7; i++) {
  //       let days = date.clone().startOf('year').week(week).startOf('week').add(i, 'day');
        
  //       if (days.format('MM') === date.format('MM')) newDateList.push(days);
  //     } 
  //   }
  //   setClickDate(date.clone().format('YYYY.MM.DD'));
  //   setDateList(newDateList);
  // }, [date, firstWeek, lastWeek]) 

  // // Workout API Call //
  // useEffect(() => {
  //   if (scheduleId === -1) return;

  //   var data = {
  //     scheduleId: scheduleId,
  //     year: date.clone().format('YYYY'),
  //     month: date.clone().format('MM')
  //   }

  //   const fetchModule = new FetchModule();
  //   (
  //     async () => {
  //       var responseData = await fetchModule.postDataOfExercise('workout/', 'POST', data);
  //       // console.log(responseData);
  //       if (responseData.status === 200) {
  //         var workoutData = responseData.data.slice();

  //         // 운동 데이터 정리 //
  //         for (var i = 0; i < workoutData.length; i++) {

  //           workoutData[i].sectionList.sort(function (a, b) {
  //             return a.sectionIndex < b.sectionIndex ? -1 : a.sectionIndex > b.sectionIndex ? 1 : 0;
  //           })

  //           for (var j = 0; j < workoutData[i].sectionList.length; j++) {
  //             workoutData[i].sectionList[j].buyinExerciseList = [];
  //             workoutData[i].sectionList[j].mainExerciseList = [];
  //             workoutData[i].sectionList[j].buyoutExerciseList = [];
  //             workoutData[i].sectionList[j].reps = workoutData[i].sectionList[j].reps.split('-');

  //             for (var h = 0; h < workoutData[i].sectionList[j].exerciseList.length; h++) {
  //               workoutData[i].sectionList[j].exerciseList[h].movementId = workoutData[i].sectionList[j].exerciseList[h].movement.movementId;
  //               workoutData[i].sectionList[j].exerciseList[h].movementName = workoutData[i].sectionList[j].exerciseList[h].movement.movementName;
  //               workoutData[i].sectionList[j].exerciseList[h].videoLinkId = workoutData[i].sectionList[j].exerciseList[h].movement.videoLinkId;

  //               if (workoutData[i].sectionList[j].exerciseList[h].division === 'Buyin') {
  //                 workoutData[i].sectionList[j].buyinExerciseList.push(workoutData[i].sectionList[j].exerciseList[h]);
  //               }
  //               else if (workoutData[i].sectionList[j].exerciseList[h].division === 'Main') {
  //                 workoutData[i].sectionList[j].mainExerciseList.push(workoutData[i].sectionList[j].exerciseList[h]);
  //               }
  //               else if (workoutData[i].sectionList[j].exerciseList[h].division === 'Buyout') {
  //                 workoutData[i].sectionList[j].buyoutExerciseList.push(workoutData[i].sectionList[j].exerciseList[h]);
  //               }
  //             }

  //             // exerciseIndex.asc 진행 //
  //             workoutData[i].sectionList[j].buyinExerciseList.sort(function (a, b) {
  //               return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
  //             })
  //             // exerciseIndex.asc 진행 //
  //             workoutData[i].sectionList[j].mainExerciseList.sort(function (a, b) {
  //               return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
  //             })
  //             // exerciseIndex.asc 진행 //
  //             workoutData[i].sectionList[j].buyoutExerciseList.sort(function (a, b) {
  //               return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
  //             })
  //           }
  //         }

  //         setData(workoutData);
  //       }
  //       else if (responseData.status === 204) setData([]);
  //       else if (responseData.status === 401) history.push('/');
  //     }
  //   )();
  // }, [history, date, scheduleId, rendering])

  // Workout API Call //
  useEffect(() => {
    if (workoutId === 0) return;

    let data = {
      workoutId: workoutId
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        let responseData = await fetchModule.postDataOfExerciseVersion2('workout/board_workout/', 'POST', data);
        // console.log(responseData);
        if (responseData.status === 200) {
          let workoutData = responseData.data;

          // 운동 데이터 정리 //
          workoutData.sectionList.sort(function (a, b) {
            return a.sectionIndex < b.sectionIndex ? -1 : a.sectionIndex > b.sectionIndex ? 1 : 0;
          })

          for (let j = 0; j < workoutData.sectionList.length; j++) {
            workoutData.sectionList[j].buyinExerciseList = [];
            workoutData.sectionList[j].buyinScaleExerciseList = [];
            workoutData.sectionList[j].mainExerciseList = [];
            workoutData.sectionList[j].mainScaleExerciseList = [];
            workoutData.sectionList[j].emomExerciseList = [];
            workoutData.sectionList[j].emomScaleExerciseList = [];
            workoutData.sectionList[j].buyoutExerciseList = [];
            workoutData.sectionList[j].buyoutScaleExerciseList = [];
            workoutData.sectionList[j].reps = workoutData.sectionList[j].reps.split('-');

            for (let h = 0; h < workoutData.sectionList[j].exerciseList.length; h++) {
              workoutData.sectionList[j].exerciseList[h].movementId = workoutData.sectionList[j].exerciseList[h].movement.movementId;
              workoutData.sectionList[j].exerciseList[h].movementName = workoutData.sectionList[j].exerciseList[h].movement.movementName;
              workoutData.sectionList[j].exerciseList[h].videoLinkId = workoutData.sectionList[j].exerciseList[h].movement.videoLinkId;

              // 무게 구분 데이터 사용할 경우 //
              if (workoutData.sectionList[j].exerciseList[h].divisionWeight !== ''
                && workoutData.sectionList[j].exerciseList[h].divisionWeight !== 'None') {
                workoutData.sectionList[j].exerciseList[h].weight = workoutData.sectionList[j].exerciseList[h].divisionWeight;
              }
              // 거리 구분 데이터 사용할 경우 //
              if (workoutData.sectionList[j].exerciseList[h].divisionDistance !== ''
                && workoutData.sectionList[j].exerciseList[h].divisionDistance !== 'None') {
                workoutData.sectionList[j].exerciseList[h].distance = workoutData.sectionList[j].exerciseList[h].divisionDistance;
              }
              // 높이 구분 데이터 사용할 경우 //
              if (workoutData.sectionList[j].exerciseList[h].divisionHeight !== ''
                && workoutData.sectionList[j].exerciseList[h].divisionHeight !== 'None') {
                workoutData.sectionList[j].exerciseList[h].height = workoutData.sectionList[j].exerciseList[h].divisionHeight;
              }
              // 칼로리 구분 데이터 사용할 경우 //
              if (workoutData.sectionList[j].exerciseList[h].divisionCalories !== ''
                && workoutData.sectionList[j].exerciseList[h].divisionCalories !== 'None') {
                workoutData.sectionList[j].exerciseList[h].calories = workoutData.sectionList[j].exerciseList[h].divisionCalories;
              }
              // 템포 구분 데이터 사용할 경우 //
              if (workoutData.sectionList[j].exerciseList[h].divisionTempo !== ''
                && workoutData.sectionList[j].exerciseList[h].divisionTempo !== 'None') {
                workoutData.sectionList[j].exerciseList[h].tempo = workoutData.sectionList[j].exerciseList[h].divisionTempo;
              }
              // Sets 구분 데이터 사용할 경우 //
              if (workoutData.sectionList[j].exerciseList[h].divisionSets !== ''
                && workoutData.sectionList[j].exerciseList[h].divisionSets !== 'None') {
                workoutData.sectionList[j].exerciseList[h].sets = workoutData.sectionList[j].exerciseList[h].divisionSets;
              }

              if (workoutData.sectionList[j].exerciseList[h].division === 'Buyin') {
                workoutData.sectionList[j].buyinExerciseList.push(workoutData.sectionList[j].exerciseList[h]);
              }
              else if (workoutData.sectionList[j].exerciseList[h].division === 'Buyin.Scale') {
                workoutData.sectionList[j].buyinScaleExerciseList.push(workoutData.sectionList[j].exerciseList[h]);
              }
              else if (workoutData.sectionList[j].exerciseList[h].division === 'Main') {
                workoutData.sectionList[j].mainExerciseList.push(workoutData.sectionList[j].exerciseList[h]);
              }
              else if (workoutData.sectionList[j].exerciseList[h].division === 'Main.Scale') {
                workoutData.sectionList[j].mainScaleExerciseList.push(workoutData.sectionList[j].exerciseList[h]);
              }
              else if (workoutData.sectionList[j].exerciseList[h].division === 'Main.Emom') {
                workoutData.sectionList[j].emomExerciseList.push(workoutData.sectionList[j].exerciseList[h]);
              }
              else if (workoutData.sectionList[j].exerciseList[h].division === 'Main.Emom.Scale') {
                workoutData.sectionList[j].emomScaleExerciseList.push(workoutData.sectionList[j].exerciseList[h]);
              }
              else if (workoutData.sectionList[j].exerciseList[h].division === 'Buyout') {
                workoutData.sectionList[j].buyoutExerciseList.push(workoutData.sectionList[j].exerciseList[h]);
              }
              else if (workoutData.sectionList[j].buyoutScaleExerciseList[h].division === 'Buyout.Scale') {
                workoutData.sectionList[j].buyoutScaleExerciseList.push(workoutData.sectionList[j].exerciseList[h]);
              }
            }

            // exerciseIndex.asc 진행 //
            workoutData.sectionList[j].buyinExerciseList.sort(function (a, b) {
              return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
            })
            // exerciseIndex.asc 진행 //
            workoutData.sectionList[j].buyinScaleExerciseList.sort(function (a, b) {
              return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
            })
            // exerciseIndex.asc 진행 //
            workoutData.sectionList[j].mainExerciseList.sort(function (a, b) {
              return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
            })
            // exerciseIndex.asc 진행 //
            workoutData.sectionList[j].mainScaleExerciseList.sort(function (a, b) {
              return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
            })
            // exerciseIndex.asc 진행 //
            workoutData.sectionList[j].emomExerciseList.sort(function (a, b) {
              return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
            })
            // exerciseIndex.asc 진행 //
            workoutData.sectionList[j].emomScaleExerciseList.sort(function (a, b) {
              return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
            })
            // exerciseIndex.asc 진행 //
            workoutData.sectionList[j].buyoutExerciseList.sort(function (a, b) {
              return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
            })
            // exerciseIndex.asc 진행 //
            workoutData.sectionList[j].buyoutScaleExerciseList.sort(function (a, b) {
              return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
            })
          }

          setData(workoutData);
        }
        // else if (responseData.status === 204) setData([]);
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }, [workoutId])

  // 운동 메모 불러오기 //
  useEffect(() => {
    if (workoutId === 0) return;

    var requestData = {
      workoutId: workoutId
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        var responseData = await fetchModule.postDataOfExercise('notepad/', 'POST', requestData);
        
        if (responseData.status === 200) {
          if (responseData.data[0] !== null) setNotepad(responseData.data[0]);
        }
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }, [workoutId])

  // 화면 크기 변환 감지 //
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // 화면 크기 변환 감지시 FullScreen 여부 체크 및 저장 //
  function handleResize(e) {
    var isInFullScreen = (document.fullscreenElement && document.fullscreenElement !== null) ||
    (document.webkitFullscreenElement && document.webkitFullscreenElement !== null) ||
    (document.mozFullScreenElement && document.mozFullScreenElement !== null) ||
    (document.msFullscreenElement && document.msFullscreenElement !== null);

    setFullScreen(isInFullScreen);
  }

  // Calender Hovering //
  function onMouseOver() {
    CalenderButtonRef.current.style.border = `1px solid #777777`;
    CalenderButtonRef.current.style.background = `url(${CalenderHover}) no-repeat center center`;
  }

  // Calender Hovering //
  function onMouseLeave() {
    CalenderButtonRef.current.style.border = `1px solid #DFDFDF`;
    CalenderButtonRef.current.style.background = `url(${CalenderBasic}) no-repeat center center`;
  }
  
  // 날짜 변경 클릭시 //
  function dateClick(value) {
    setClickDate(value);

    if (calenderClick) CalenderRef.current.setValue(value);
  }

  // 전체화면, 일반화면 전환 버튼 클릭시 //
  function changFullScreenMode() {
    var isInFullScreen = (document.fullscreenElement && document.fullscreenElement !== null) ||
    (document.webkitFullscreenElement && document.webkitFullscreenElement !== null) ||
    (document.mozFullScreenElement && document.mozFullScreenElement !== null) ||
    (document.msFullscreenElement && document.msFullscreenElement !== null);

    if (isInFullScreen) {
      const docu = document;
      if (docu.exitFullscreen) docu.exitFullscreen();
      else if (docu.mozCancelFullScreen) docu.mozCancelFullScreen();
      else if (docu.webkitExitFullscreen) docu.webkitExitFullscreen();
      else if (docu.msExitFullscreen) docu.msExitFullscreen();
    }
    else {
      const docuElem = document.documentElement;

      if (docuElem.requestFullscreen) docuElem.requestFullscreen();
      else if (docuElem.webkitRequestFullscreen) docuElem.webkitRequestFullscreen();
      else if (docuElem.mozRequestFullScreen) docuElem.mozRequestFullScreen();
      else if (docuElem.msRequestFullScreen) docuElem.msRequestFullScreen();
    }
    setFullScreen(!isInFullScreen);
  }

  // 수업 - 스톱워치 설정 클릭시 //
  function changeSelecValue(value) {
    setMode(value);
  }
  
  // Member Reserve Add Button Click //
  function onClickAddMemberSave(index) {
    
    var requestData = {
      scheduleId: scheduleId,
      lessonId: lessonId,
      userId: memberList[index].memberId,
      classDate: date.format('YYYY-MM-DD') + "T00:00:00",
      classTime: classTime,
      status: 0
    }
    
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postData('reserve/create/manage', 'POST', requestData);
        
        if (responseData.status === 200) setMemberListRendering(!memberListRendering);
        else if (responseData.status === 401) history.push('/');
      }
    )();

    setMemberAddClick(false);
  }

  return (
    <TopLevelWrapper ref={TopLevelRef}>
      {calenderClick && <CalenderModal ref={CalenderRef} clickDate={clickDate} date={date} dateClickFn={dateClick} exitFn={setCalenderClick}/>}
      {memberAddClick && <MemberListModal ref={MemberListRef} memberData={memberList} saveClick={onClickAddMemberSave} exitClick={() => setMemberAddClick(false)}/>}
      <TopButtonWrapper>
        <LeftWrapper>
          <BoardSettingModeSelect changeValueFn={changeSelecValue}/>
          <DateButtonWrapper>
            <ArrowWrapper onClick={() => setDate(date.clone().subtract(1, 'day'))}>
              <ArrowImage src={LeftArrow}/>
            </ArrowWrapper>
            <DateText>{date.clone().format('YYYY년 MM월 DD일')}</DateText>
            <ArrowWrapper onClick={() => setDate(date.clone().add(1, 'day'))} >
              <ArrowImage src={RightArrow}/>
            </ArrowWrapper>
          </DateButtonWrapper>
          <TodayButton onClick={() => setDate(moment())}>오늘</TodayButton>
          <CalenderButton ref={CalenderButtonRef} img={CalenderBasic} onMouseOver={onMouseOver} onMouseLeave={onMouseLeave} onClick={() => setCalenderClick(!calenderClick)} />
        </LeftWrapper>
        <RightWrapper>
          <Button onClick={changFullScreenMode}>{fullScreen ? `일반화면 전환` : `전체화면 전환`}</Button>
          <Button onClick={() => window.close()}>페이지 닫기</Button>
        </RightWrapper>
      </TopButtonWrapper>
      <MainView>
        {
          mode === '수업' ?
            <ComponentWrapper style={{ marginBottom: `96px` }}>
              <FullMemberListView date={date} scheduleId={scheduleId} classTime={classTime} memberListRendering={memberListRendering} setMemberAddClick={setMemberAddClick}/>
              {/* <FullExerciseBox useData={useData} clickDate={clickDate} date={date} setScheduleId={setScheduleId} setLessonId={setLessonId} setClassTime={setClassTime} rendering={() => setRendering(!rendering)} /> */}
              <FullExerciseTestBox useData={data} clickDate={clickDate} date={date} setScheduleId={setScheduleId} workoutId={workoutId} setWorkoutId={setWorkoutId} setLessonId={setLessonId} classTime={classTime} setClassTime={setClassTime} rendering={() => setRendering(!rendering)} />
              <FullNoticeView 
                notepad={notepad} 
                setNotepad={setNotepad}
                workoutId={workoutId}  
                clickDate={clickDate} />
            </ComponentWrapper>
            :
            <ComponentWrapper style={{ marginBottom: `96px` }}>
              {/* <FullExerciseBox useData={useData} clickDate={clickDate} date={date} setScheduleId={setScheduleId} setLessonId={setLessonId} setClassTime={setClassTime} rendering={() => setRendering(!rendering)} /> */}
              <FullExerciseTestBox useData={data} clickDate={clickDate} date={date} setScheduleId={setScheduleId} workoutId={workoutId} setWorkoutId={setWorkoutId} setLessonId={setLessonId} classTime={classTime} setClassTime={setClassTime} rendering={() => setRendering(!rendering)} />
              <FullStopWatchView />
            </ComponentWrapper>
        }
      </MainView>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  // width: 100vw;
`;

const TopButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  // width: 100%;

  // margin-top: 38px;
  margin-top: 1.979166666666667vw;
`;

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // gap: 8px;
  gap: 0.4166666666666667vw;
`;

const RightWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // gap: 16px;
  gap: 0.8333333333333333vw;
`;

const DateButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // width: 205px;
  width: 10.67708333333333vw;
  // height: 38px;
  height: 1.979166666666667vw;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;

  // gap: 20px;
  gap: 1.041666666666667vw;
`;

const ArrowWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 22px;
  width: 1.145833333333333vw;
  // height: 22px;
  height: 1.145833333333333vw;

  :hover { 
    cursor: pointer;
  }
`;

const ArrowImage = styled.img`
  display: flex;
  flex-direction: row;

  // width: 22px;
  // width: 1.145833333333333vw;
  width: 0.46875vw;
  // height: 22px;
  // height: 1.145833333333333vw;
  height: 0.78125vw;
`;

const TodayButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // width: 52px;
  width: 2.708333333333333vw;
  // height: 38px;
  height: 1.979166666666667vw;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;

  font-family: NotoSansKR-Medium;
  // font-size: 14px;
  font-size: 0.7291666666666667vw;

  color: #CBCBCB;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #777777;
    color: #777777;
  }
`;

const CalenderButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // width: 38px;
  width: 1.979166666666667vw;
  // height: 38px;
  height: 1.979166666666667vw;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;
  background: url(${props => props.img}) no-repeat center center;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const DateText = styled.p`
  font-family: NotoSansKR-Medium;
  // font-size: 14px;
  font-size: 0.7291666666666667vw;

  color: #595959;

  margin: 0 0 0 0;
`;

const Button = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // width: 127px;
  width: 6.614583333333333vw;
  // height: 38px;
  height: 1.979166666666667vw;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;

  font-family: NotoSansKR-Medium;
  // font-size: 14px;
  font-size: 0.7291666666666667vw;

  color: #CBCBCB;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #777777;
    color: #777777;
  }
`;

const MainView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  // gap: 30px;
  gap: 1.5625vw;
  // margin-top: 16px;
  margin-top: 0.8333333333333333vw;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: flex-start;

  // gap: 30px;
  gap: 1.5625vw;
`;