import React, { useRef, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { CustomView, isBrowser, isTablet, BrowserView, MobileView } from 'react-device-detect';

// Model Import //
import LogoView from '../../Model/Individual/AccountModel/LogoView.js';
import InfoView from '../../Model/Individual/AccountModel/InfoView.js';
import CompleteSignUp from '../../Model/Individual/AccountModel/CompleteSignUp.js';
import KakaoAddress from '../../Model/Share/KakaoComponent/KakaoAddress.js';
import MobileLogoView from '../../Model/Individual/AccountModel/MobileLogoView.js';
import FetchModule from '../../Model/Share/Network/FetchModule.js';

function AccountPage() {
  const [isViewAddress, setIsViewAddress] = useState(false);

  const KakaoAddressRef = useRef();
  const InfoViewRef = useRef();
  const LogoViewRef = useRef();
  const MobileLogoViewRef = useRef();
  const CompleteRef = useRef();

  const history = useHistory();

  useEffect(() => {
    // alert(window.localStorage.getItem('Token'));
    // if (window.localStorage.getItem('Token') !== 'null') history.push('/main');
    
    // if (window.localStorage.getItem('StoreId') !== null) history.push('/main');

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getData('store/', 'GET');
        // console.log(responseData);
        if (responseData.status === 200 || responseData.status === 201 || responseData.status === 202 || responseData.status === 203) {
          history.push('/main');
				}
        // // 201 : 구독권이 존재하지 않을시, 202 : 구독권 기간이 만료되었을시 //
        // else if (responseData.status === 201 || responseData.status === 202) {
        //   setData(responseData.data[0]);
        //   // console.log(nowPage);
        //   // if (nowPage !== defaultURL + '/mypage') history.push('/mypage');
        //   if (nowPage !== defaultURL + '/mypage') setIsViewSubscribeModal(true);
        // }
        // // 203 : 시작 기념 쿠폰 (3개월 혹은 6개월)을 수령하지 않았을 경우 //
        // else if (responseData.status === 203) {
        //   setIsViewReceiveModal(true);
        //   setData(responseData.data[0]);

        // }
        else if (responseData.status === 401) {
          window.localStorage.removeItem('StoreId');
          window.localStorage.removeItem('Token');
          window.localStorage.removeItem('RefreshToken');
          window.localStorage.removeItem('AccountId');
        }
        // else alert('서버와 연결이 불안정합니다. 잠시후 다시시도해 주세요.');
      }
    )();

    // setTimeout(() => {
    //   setIsViewAddress(true);
    //   KakaoAddressRef.current?.setOpacity(0);
    // }, 5000)
    // setIsViewAddress(true);
  }, [])

  
  // useEffect(() => {
  //   console.log(isViewAddress);
  // }, [isViewAddress])

  function kakaoAddrClick() {
    KakaoAddressRef.current.setOpacity(1);
  }

  function exitClick() {
    KakaoAddressRef.current.setOpacity(0);
    InfoViewRef.current.setAddress(KakaoAddressRef.current.getFullAddress());
  }

  function signUpClick() {
    CompleteRef.current.setUserName(InfoViewRef.current.getUserName());
    InfoViewRef.current.setOpacity(0);
    LogoViewRef.current.setOpacity(0);
    // MobileLogoViewRef.current.setOpacity(0);
    CompleteRef.current.setOpacity(1);
  }

  return (
    <Container>
      <CompleteSignUp ref={CompleteRef}/>
      {/* <CustomView condition={isBrowser && !isTablet}> */}
        <BrowserViewWrapper>
          <LogoView ref={LogoViewRef} />
          <InfoView ref={InfoViewRef} kakaoAddrClick={kakaoAddrClick} signUpClick={signUpClick} />
        </BrowserViewWrapper>
      {/* </CustomView> */}
      {/* <MobileView>
        <MobileViewWrapper>
          <MobileLogoView ref={MobileLogoViewRef} />
          <InfoView ref={InfoViewRef} kakaoAddrClick={kakaoAddrClick} signUpClick={signUpClick} />
        </MobileViewWrapper>
      </MobileView> */}
			{/* {isViewAddress && <KakaoAddress ref={KakaoAddressRef} exitClick={exitClick}/>} */}
      <KakaoAddress ref={KakaoAddressRef} exitClick={exitClick}/>
    </Container>
  )
}

export default AccountPage;

const Container = styled.div`
  position: fixed;
  display: flex;

  justify-content: center;
  // align-items: flex-start;
  align-items: center;

  top: 0;
  left: 0;

  width: 100vw;
  // min-width: 1194px;
  height: 100vh;

  overflow: scroll;
  // gap: 132px;

  background-color: #FDFCFB;
`;

const MobileViewWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-bottom: 90px;
`;

const BrowserViewWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;