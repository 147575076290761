import { React, useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import ClassSelect from '../../../../Component/ClassManageComponent/ClassSelect.js';
import TimeInput from '../../../../Component/ClassManageComponent/TimeInput.js';
import RepeatSelect from '../../../../Component/ClassManageComponent/RepeatSelect.js';

import FetchModule from '../../../Share/Network/FetchModule';
import moment from 'moment';
import RepeatWeekSetting from '../../../../Component/ClassManageComponent/RepeatWeekSetting.js';
import NewCalenderInput from '../../../../Component/ClassManageComponent/NewCalenderInput.js';
import NewClassSelectBox from '../../../../Component/ClassManageComponent/NewClassSelectBox.js';

import WhiteTrashIcon from '../../../../image/WhiteTrashIcon.png';

function ClassAddModal(props) {
  const { date, scheduleData, scheduleId, onClickDeleteButton, cancel, options, defaultClassTime, defaultClassDate, defaultApplyWeek } = props;

  const history = useHistory();

  const [isClicked, setIsClicked] = useState(true);
  const [selectedLesson, setSelectedLesson] = useState({ id: -1, name: '선택', time: 0, maxBookedPeople: 0  });
  const [coachNames, setCoachNames] = useState([]);

  const [startDate, setStartDate] = useState(moment().format('YYYY.MM.DD'));
  const [originalStartDate, setOriginalStartDate] = useState(moment().format('YYYY-MM-DDTHH:mm:ss'));
  const [endDate, setEndDate] = useState('');

  const ClassRef = useRef();
  const StartCalenderRef = useRef();
  const TimeRef = useRef();
  const RepeatRef = useRef();
  const RepeatWeekRef = useRef();
  const EndCalenderRef = useRef();

  // 스크롤 제어 //
  useEffect(() => {
    document.body.style = `overflow: hidden`;
    return () => document.body.style = `overflow: auto`;
  }, [])

  // 수업 및 스케줄 정보 세팅 //
  useEffect(() => {
    if (scheduleId === -1) {
      
      if (defaultClassTime !== '') TimeRef.current.setValue(defaultClassTime);
      if (defaultClassDate !== '') StartCalenderRef.current.setValue(defaultClassDate.replaceAll('.', '-'));
      if (defaultApplyWeek !== '') RepeatRef.current.setValue(defaultApplyWeek);

      return;
    }

    for (var i = 0; i < scheduleData.length; i++) {
      if (scheduleData[i].scheduleId === scheduleId) {
        // ClassRef.current.setValue({ id: scheduleData[i].classId, name: scheduleData[i].className });

        for (let j = 0; j < options.length; j++) {
          if (scheduleData[i].classId === options[j].id) {
            setSelectedLesson(options[j]);
            break;
          }
        }

        // StartCalenderRef.current.setValue(scheduleData[i].classDate.split('T')[0]);
        StartCalenderRef.current.setValue(date.format('YYYY-MM-DD'));
        setOriginalStartDate(scheduleData[i].classDate);
        TimeRef.current.setValue(scheduleData[i].classTime);
        RepeatRef.current.setValue(scheduleData[i].applyWeek);
        RepeatWeekRef.current.setValue(scheduleData[i].repeatWeek);

        if (scheduleData[i].endDate === '9999-12-31T00:00:00' || scheduleData[i].endDate === '9999-12-31T23:59:59') setIsClicked(true);
        else {
          setIsClicked(false);
          EndCalenderRef.current.setValue(scheduleData[i].endDate.split('T')[0]);
        }
      }
    }
  }, [scheduleData, scheduleId, options, date, defaultClassTime, defaultClassDate, defaultApplyWeek])

  // 수업 시작일과 종료일 검사 //
  useEffect(() => {
    if (startDate === '' || endDate === '') return;

    if (endDate < startDate) {
      alert('종료일을 올바르게 설정해주세요!');
      setEndDate(startDate);
      return;
    }

  }, [startDate, endDate])

  // 수업 담당 코치 뷰 정리 //
  useEffect(() => {
    if (selectedLesson.id === -1) return;

    let coachNames = [];
    let nameLength = 0;
    
    for (let i = 0; i < selectedLesson.teachers.length; i++) {
      // 설정된 코치가 1개이면 무조건 넣기 //
      if (selectedLesson.teachers.length === 1) coachNames.push(selectedLesson.teachers[i]);
      // 설정된 코치가 1개 이상인 경우 //
      else {
        // 111은 글자가 들어갈 수 있는 최대 크기 //
        // 글자 하나당 14px이라고 가정 후 계산
        if ((nameLength + selectedLesson.teachers[i].length) * 14 < 111) {
          coachNames.push(selectedLesson.teachers[i]);
          nameLength += selectedLesson.teachers[i].length;
        }
        else {
          if (selectedLesson.teachers.length - i !== 0) coachNames.push('+' + (selectedLesson.teachers.length - i));
          break;
        }
      }
    }

    setCoachNames(coachNames);
  }, [selectedLesson])

  function CalenderClick(className) {
    if (className === 'StartCalender') EndCalenderRef.current.closeCalender();
    else StartCalenderRef.current.closeCalender();
  }

  function onClickSaveButton() {
    // var storeId = window.sessionStorage.getItem('StoreId');
    let storeId = window.localStorage.getItem('StoreId');
    // var lessonId = ClassRef.current.getValue().id;
    let lessonId = selectedLesson.id;
    // var classTime = ClassRef.current.getValue();
    let classDate = StartCalenderRef.current.getValue();
    let classTime = TimeRef.current.getValue();
    let applyWeek = RepeatRef.current.getValue();
    let repeatWeek = RepeatWeekRef.current.getValue();
    let endDate = isClicked ? '9999-12-31' : EndCalenderRef.current.getValue();

    // if (term === '1주마다') term = 1;
    // else if (term === '2주마다') term = 2;
    // else if (term === '3주마다') term = 3;
    // else if (term === '4주마다') term = 4;
    // else if (term === '없음') term = 0;

    if (lessonId === undefined || lessonId === -1) {
      alert('수업을 선택해 주세요');
      return;
    }
    else if (classTime === "") {
      alert('수업 시간을 설정해주세요');
      return;
    }
    else if ((classTime.split('-')[0] > classTime.split('-')[1]) || classTime.length !== 11) {
      alert('잘못된 시간설정입니다.');
      return;
    }
    else if (classDate === '') {
      alert('수업 일시를 설정해주세요');
      return;
    }
    else if (applyWeek === '') {
      alert("반복 개설 일정을 선택해주세요!");
      return;
    }
    else if (repeatWeek === '') {
      alert('반복 주 개설 일정을 선택해주세요');
      return;
    }
    // 끝나는 날짜가 과거이거나 같을경우 //
    else if (classDate >= endDate) {
      endDate = moment(classDate).clone().add(1, 'days').format('YYYY.MM.DD');
    }

    // 브레이크 타임 추가인 경우 //
    if (selectedLesson.id === -2) {
      let requestData = {
        classTime: classTime,
        classDate: classDate.replaceAll('.', '-') + 'T' + '00:00:00',
        applyWeek: applyWeek,
        repeatWeek: repeatWeek,
        endDate: endDate.replaceAll('.', '-') + 'T23:59:59'
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataVersion2('breaktime/save', 'POST', requestData);
          // console.log(responseData);
          if (responseData.status === 200) cancel();
          else if (responseData.status === 201) {
            alert('동일 시간대 같은 수업이 존재합니다');
            return;
          }
          else if (responseData.status === 401) history.push('/');
        }
      )();
    }
    // 신규 등록
    else if (scheduleId === -1) {
      var data = {
        storeId: storeId,
        lessonId: lessonId,
        classTime: classTime,
        classDate: classDate.replaceAll('.', '-') + 'T' + classTime.split('-')[0] + ':00',
        applyWeek: applyWeek,
        repeatWeek: repeatWeek,
        endDate: endDate.replaceAll('.', '-') + 'T23:59:59'
      }
      
      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postData('schedule/create/', 'POST', data);
          // console.log(responseData);
          if (responseData.status === 200) cancel();
          else if (responseData.status === 201) {
            alert('동일 시간대 같은 수업이 존재합니다');
            return;
          }
          else if (responseData.status === 401) history.push('/');
        }
      )();
    }
    // 데이터 수정
    else {
      let data = {
        scheduleId: scheduleId,
        lessonId: lessonId,
        classTime: classTime,
        // startDate: classDate.replaceAll('.', '-') + 'T' + classTime.split('-')[0] + ':00',
        startDate: originalStartDate,
        classDate: date.format('YYYY-MM-DDT00:00:00'),
        changeApplyWeek: date.format('ddd,'),
        repeatWeek: repeatWeek,
        endDate: endDate.replaceAll('.', '-') + 'T23:59:59'
      }
      // console.log(data);
      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataVersion2('schedule/modify', 'POST', data);

          if (responseData.status === 200) cancel();
          else if (responseData.status === 401) history.push('/');
        }
      )();
    }
  }

  // Select Box 클릭시 다른 Select Box 끄기
  function selectClickFn(className) {
    if (className === 'category') {
      // ClassRef.current.setIsClicked();
      // CoachSelectRef.current.setIsClicked();
      StartCalenderRef.current.closeCalender();
      EndCalenderRef.current.closeCalender();
    }
    else if (className === 'lesson') {
      // CategoryRef.current.setIsClicked();
      // CoachSelectRef.current.setIsClicked();
      StartCalenderRef.current.closeCalender();
      EndCalenderRef.current.closeCalender();
    }
    // else if (className === 'coach') {
    //   CategoryRef.current.setIsClicked();
    //   ClassRef.current.setIsClicked();
    //   StartCalenderRef.current.closeCalender();
    //   EndCalenderRef.current.closeCalender();
    // }
    else if (className === 'start') {
      // CategoryRef.current.setIsClicked();
      // ClassRef.current.setIsClicked();
      // CoachSelectRef.current.setIsClicked();
      EndCalenderRef.current.closeCalender();
    }
    else if (className === 'end') {
      // CategoryRef.current.setIsClicked();
      // ClassRef.current.setIsClicked();
      // CoachSelectRef.current.setIsClicked();
      StartCalenderRef.current.closeCalender();
    }
  }

  // 삭제버튼 클릭시
  function onClickDelete() {
    let deletedData = {
      scheduleId: scheduleId,
      classDate: date.format('YYYY-MM-DD')
      // changeApplyWeek: date.format('ddd,'),
      // deletedDate: date.format('YYYY-MM-DDT00:00:00')
    }
    
    onClickDeleteButton(deletedData, 'DetailModal');
    // let requestData = {
    //   scheduleId: scheduleId,
    //   changeApplyWeek: date.format('ddd,'),
    //   deletedDate: date.format('YYYY-MM-DDT00:00:00')
    // }
    
    // const fetchModule = new FetchModule();
    // (
    //   async () => {
    //     const responseData = await fetchModule.postDataVersion2('schedule/delete', 'POST', requestData);

    //     if (responseData.status === 200) cancel();
    //     else if (responseData.status === 401) history.push('/');
    //   }
    // )();
  }

  // 브레이크 타임 합산으로 인한 컬럼 배경색 변경 //
  function onCalcLessonInfoColumnBackground() {
    if (selectedLesson.id === -2) return '#E0E0E0';
    else return '#FFFFFF';
  }

  return (
    <TopLevelWrapper>
      <ModalView>
        <TitleWrapper>
          {
            scheduleId === -1 ?
              <Title>수업 추가</Title>
              :
              <Title>수업 정보</Title>
          }
          {
            scheduleId === -1 ?
              <DateText>{date.format('YYYY.MM.DD')}</DateText>
              :
              // <DeleteText onClick={onClickDeletButton}>수업 정보 삭제</DeleteText>
              <DeleteButtonWrapper>
                <DeletedButton onClick={onClickDelete}>
                  <DeleteImage src={WhiteTrashIcon}/>
                </DeletedButton>
              </DeleteButtonWrapper>
          }
        </TitleWrapper>
        <TotalWrapper>
          <LayerWrapper>
            <ComponentWrapper>
              <ComponentTitle>수업</ComponentTitle>
              {/* <ClassSelect ref={ClassRef} value={selectedLesson} setValue={setSelectedLesson} selectClickFn={() => selectClickFn('lesson')}/> */}
              <NewClassSelectBox value={selectedLesson} setValue={setSelectedLesson} options={options} selectClickFn={() => selectClickFn('lesson')}/>
            </ComponentWrapper>
          </LayerWrapper>
          <LayerWrapper>
            <ComponentWrapper>
              <ComponentTitle>코치</ComponentTitle>
              <TeacherBox backgroundColor={onCalcLessonInfoColumnBackground()}>
                {
                  coachNames.map((data, index) => (
                    <TeacherView key={index}>
                      <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FFFFFF'>{data}</Text>
                    </TeacherView>
                  ))
                }
              </TeacherBox>
            </ComponentWrapper>
            {/* <ComponentWrapper>
              <ComponentTitle>수업 시간</ComponentTitle>
              <LessonInfoBox backgroundColor={onCalcLessonInfoColumnBackground()}>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#CBCBCB'>{selectedLesson.time}</Text>
                <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#777777'>분</Text>
              </LessonInfoBox>
            </ComponentWrapper> */}
            <ComponentWrapper>
              <ComponentTitle>수업 정원</ComponentTitle>
              <LessonInfoBox backgroundColor={onCalcLessonInfoColumnBackground()}>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#CBCBCB'>{selectedLesson.maxBookedPeople}</Text>
                <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#777777'>명</Text>
              </LessonInfoBox>
            </ComponentWrapper>
          </LayerWrapper>
          <DivisionLine/>
          <LayerWrapper>
            <ComponentWrapper>
              <ComponentTitle>수업일시</ComponentTitle>
              {/* <CalenderInput ref={StartCalenderRef} selectClickFn={() => selectClickFn('start')} className='StartCalender' clickFn={CalenderClick} placeholder='시작일' width='280px' /> */}
              <NewCalenderInput ref={StartCalenderRef} value={startDate} setValue={setStartDate} selectClickFn={() => selectClickFn('start')} className='StartCalender' clickFn={CalenderClick} placeholder='시작일' width='280px' disabled={scheduleId !== -1}/>
            </ComponentWrapper>
            <ComponentWrapper>
              <ComponentTitle>시간</ComponentTitle>
              <TimeInput ref={TimeRef} placeholder="00:00 - 00:00" />
            </ComponentWrapper>
          </LayerWrapper>
          <LayerWrapper>
            <ComponentWrapper>
              <ComponentTitle>반복 개설 일정</ComponentTitle>
              <RepeatSelect ref={RepeatRef} scheduleId={scheduleId} date={date}/>
            </ComponentWrapper>
            <ComponentWrapper>
              <LimitWrapper>
                <ComponentTitleWrapper>
                  <ComponentTitle>반복 주 개설 일정</ComponentTitle>
                  <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#BDBDBD' cursor='default'>[수업이 격주일 경우]</Text>
                </ComponentTitleWrapper>
              </LimitWrapper>
              <RepeatWeekSetting ref={RepeatWeekRef}/>
            </ComponentWrapper>
          </LayerWrapper>
          <LayerWrapper>
            <ComponentWrapper>
              <LimitWrapper>
                <ComponentTitle>반복 개설 종료 일자</ComponentTitle>
                <LimitButtonWrapper>
                  <LimitButton isClicked={isClicked} onClick={() => setIsClicked(!isClicked)} />
                  <LimitText>제한없음</LimitText>
                </LimitButtonWrapper>
              </LimitWrapper>
              {/* <CalenderInput ref={EndCalenderRef} className='EndCalender' selectClickFn={() => selectClickFn('end')} clickFn={CalenderClick} placeholder='종료일' width='280px' disabled={isClicked} /> */}
              <NewCalenderInput ref={EndCalenderRef} value={endDate} setValue={setEndDate} className='EndCalender' selectClickFn={() => selectClickFn('end')} clickFn={CalenderClick} placeholder='종료일' width='280px' disabled={isClicked} />
            </ComponentWrapper>
          </LayerWrapper>
        </TotalWrapper>
        <ButtonWrapper>
          <ExitButton onClick={cancel}>취소</ExitButton>
          <SaveButton onClick={onClickSaveButton}>저장하기</SaveButton>
        </ButtonWrapper>
      </ModalView>
    </TopLevelWrapper>
  )
}

export default ClassAddModal;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  justify-content: center;
  align-items: center;

  z-index: 1000;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.2);
`;

const ModalView = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 18px 0px 24px 0px;

  width: 644px;
  // height: 446px;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(0, 0, 0, 0.1);

  background-color: #FFFFFF;
`;

const DivisionLine = styled.div`
  display: flex;
  flex-direction: column;

  margin: 10px 0px 10px 0px;

  width: 588px;
  height: 1px;

  background-color: #ECECEC;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 588px;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: 0 0 0 0;
`;

const DateText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const DeleteButtonWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 52px;
  height: 24px;
`;

const DeletedButton = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: -23px -56px 0px 0px;

  width: 52px;
  height: 37px;

  border-radius: 0px 30px 0px 18px;
  background-color: #FF3131;

  :hover {
    cursor: pointer;
  }
`;

const DeleteImage = styled.img`
  width: 26px;
  height: 26px;
`;

const DeleteText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #FF3131;

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 24px;
  margin-top: 16px;
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  gap: 24px;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const ComponentTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
`;

const ComponentTitle = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #595959;

  margin: 0 0 0 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  width: 588px;

  gap: 16px;

  margin: 32px 0 0 0;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 72px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #595959;
    color: #595959;
  }
`;

const LimitWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const LimitButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
`;

const LimitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 16px;
  height: 16px;

  border: 1px solid #DFDFDF;
  border-radius: 4px;
  background-color: ${props => props.isClicked ? `#FF8B48` : `#FFFFFF`};

  transition: all 0.3s ease-in-out;

  :hover{
    cursor: pointer;
  }
`;

const LimitText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #777777;

  margin: 0 0 0 0;
`;

const SaveButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 15px;
  background-color: #5BD98A;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }
`;

const TeacherBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  padding: 0px 8px 0px 8px;
  margin-top: 8px;

  gap: 4px;

  // width: 178px;
  width: 264px;
  height: 30px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  // background-color: #FFFFFF;
  background-color: ${props => props.backgroundColor};

  :hover {
    cursor: default;
  }
`;

const TeacherView = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  // align-items: center;
  align-items: flex-start;

  padding: 0px 8px 0px 8px;

  max-width: 116px;
  height: 24px;

  border-radius: 6px;
  background-color: #FF8B48;
`;

const LessonInfoBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  padding: 0px 8px 0px 8px;
  margin-top: 8px;

  gap: 4px;

  // width: 154px;
  width: 264px;
  height: 30px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  // background-color: #FFFFFF;
  background-color: ${props => props.backgroundColor};

  :hover {
    cursor: default;
  }
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }
`;