import { React, useRef, useEffect } from 'react';
import styled from 'styled-components';

import LogoPath from '../../../image/CompleteSignUpLogo.png';

function CustomAlert(props) {
  const { text, closeFn } = props;

  const TopLevelRef = useRef();

  // Modal Page Scroll Controll //
  useEffect(() => {
    document.body.style= `overflow: hidden`;
    return () => document.body.style = `overflow: auto`;
  }, [])

	return (
    <Background>
      <TopLevelWrapper ref={TopLevelRef}>
        <LogoWrapper>
          <Logo src={LogoPath} />
        </LogoWrapper>
        <ExplanationWrapper>
          <KorExplanation>{text}</KorExplanation>
        </ExplanationWrapper>
        <GoHomeButton onClick={closeFn}>
          <ButtonText>확인</ButtonText>
        </GoHomeButton>
      </TopLevelWrapper>
    </Background>
  )
}
 
export default CustomAlert;

const Background = styled.div`
  position: fixed;
	display: flex;
	flex-direction: column;

  justify-content: center;
  align-items: center;

  top: 0;
  left: 0;

  z-index: 500;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.5);
`;

const TopLevelWrapper = styled.div`
	// position: absolute;
	display: flex;
	flex-direction: column;

  justify-content: center;
  align-items: center;

	width: 454px;
  // width: max(454px, 23.64583333333333vw);
	height: 268px;
  // height: max(268px, 13.95833333333333vw);
  
	border-radius: 50px;
  // border-radius: max(30px, 2.604166666666667vw);
	// box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  background-color: #FFFFFF;

  // opacity: 0;

  transition: all 0.5s ease-in-out;
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: -160px;
  // margin-top: min(-110px, -9.375vw);
`;

const Logo = styled.img`
  width: 200px;
  // width: max(152px, 13.02083333333333vw);
  height: 200px;
  // height: max(152px, 13.02083333333333vw);
`;

const ExplanationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  justify-content: center;
  align-items: center;

  width: 90%;
  // width: 232px;
  // width: max(151px, 12.08333333333333vw);

  // margin-top: 30px;
  // margin-top: max(30px, 1.5625vw);
`;

const KorExplanation = styled.pre`
  font-family: NotoSansKR-Medium;
  font-size: 18px;
  // font-size: max(25px, 1.302083333vw);

  margin: 0 0 0 0;

  color: #232323;
`;

const GoHomeButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 282px;
  height: 36px;

  background-color: #FF8B48;
  border-radius: 23px;

  margin-top: 34px;
  // margin-top: max(34px, 2.916666666666667vw);

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background: #FF7322;
  }
`;

const ButtonText = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 18px;
  // font-size: max(12px, 0.9375vw);
  color: #FFFFFF;

  margin: 0 0 0 0;
`;