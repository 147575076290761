import React, { useState, useEffect } from "react";
import styled from "styled-components";

import ProductBox from "./ProductBox";
import FetchModule from "../../Share/Network/FetchModule";
import ProductAddModal from "./Modal/ProductAddModal";
import DeleteConfirmModal from "./Modal/DeleteConfirmModal";

export default function ProductSettingView(props) {
  const { isClickAddProduct, setIsClickAddProduct } = props;
  
  const [isClickedDelete, setIsClickedDelete] = useState(false);
  const [clickData ,setClickData] = useState(undefined);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    // let list = [];

    // for (let i = 0; i < 30; i++) {
    //   let data = {
    //     id: i,
    //     name: '게토레이',
    //     inventory: 3000,
    //     price: 34000,
    //     picture: ''
    //   }

    //   list.push(data);
    // }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getIndividualData('product/', 'GET');
        
        if (responseData.status === 200) {
          // console.log(responseData);
          setProducts(responseData.data);
        }
        // else if (responseData.status === 401) history.push('/');
      }
    )();
    
    // setProducts(list);
  }, [])

  // 저장버튼 클릭시 //
  function onClickSaveButton(name, price, inventory, picture) {
    let requestData = {
      id: clickData === undefined ? -1 : clickData.id,
      name: name,
      price: price,
      inventory: inventory,
      picture: picture
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postData('product/save/', 'POST', requestData);
        
        if (responseData.status === 200) {
          setProducts(responseData.data);
          setClickData(undefined);
          setIsClickAddProduct(false);
        }
        // else if (responseData.status === 401) history.push('/');
      }
    )();
  }

  // 물품 정보보기 클릭시 //
  function onClickProduct(data) {
    setClickData(data);
    setIsClickAddProduct(true);
  }

  // 물품 정보보기 클릭시 //
  function onClickExitProduct() {
    setClickData(undefined);
    setIsClickAddProduct(false);
  }

  // 물품 삭제 클릭시 //
  function onClickProductDelete(data) {
    setClickData(data);
    setIsClickedDelete(true);
  }

  // 물품 삭제 경고모달에서 삭제하기 클릭시 //
  function onClickDeleteYes() {
    let requestData = {
      productId: clickData.id
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postData('product/delete/', 'POST', requestData);
        
        if (responseData.status === 200) {
          setProducts(responseData.data);
          setIsClickedDelete(false);
          setClickData(undefined);
        }
        // else if (responseData.status === 401) history.push('/');
      }
    )();
  }

  // 물품 삭제 경고모달에서 취소 클릭시 //
  function onClickDeleteNo() {
    setIsClickedDelete(false);
    setClickData(undefined);
  }

  return (
    <TopLevelWrapper>
      { isClickAddProduct && <ProductAddModal data={clickData} onClickSaveButton={onClickSaveButton} onClickExitButton={onClickExitProduct}/> }
      { isClickedDelete && <DeleteConfirmModal title='상품 삭제' itemName={clickData === undefined ? 'None' : clickData.name} clickNoFn={onClickDeleteNo} clickYesFn={onClickDeleteYes}/> }
      {
        products.map((data, index) => (
          <ProductBox key={index} data={data} onClickProduct={onClickProduct} onClickProductDelete={onClickProductDelete}/>
        ))
      }
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  flex-wrap: wrap;

  margin-bottom: 200px;

  gap: 46px;

  width: 1020px;
  min-height: 500px;
`;
