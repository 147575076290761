import { useState, useImperativeHandle, forwardRef, useRef, React, useEffect } from 'react';
import styled from 'styled-components';

import SearchImgPath from '../../image/SearchIcon.png';

export default function StoreSearchInput(props) {
	const { value, setValue } = props;


	function handleClick(e) {
	}

	function handleChange(e) {
    setValue(e.target.value);
	}

	function handleFocus(e) {
	}

	function handleBlur(e) {
	}

	return <Input
    img={SearchImgPath}
		placeholder='업체 이름이나 대표자 이름, 전화번호 검색'
		value={value}
		onClick={handleClick}
		onChange={handleChange}
		onFocus={handleFocus}
		onBlur={handleBlur}/>
}

const Input = styled.input`
	font-family: NotoSansKR-Regular;
	font-size: 12px;
	line-height: 20px;

  padding: 0 0 0 40px;
  width: calc(100% - 42px);
  height: 38px;

	outline: none;
  border: 1px solid #DFDFDF;
  border-radius: 15px;
	color: #000000;

  background: url(${props => props.img}) no-repeat 10px center;

	::placeholder {
		// color: ${props => props.clickCount < 1 || props.value !== '' ? `#CBCBCB` : `#FF3131;`};
		color: ${props => props.changedColor ? `#FF3131` : `#CBCBCB`};
	}
	
  transition: all 0.3s ease-in-out;
`;