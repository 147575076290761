import React, { useState, useRef } from 'react';
import styled from 'styled-components';

import BlackPencelIcon from '../../../image/BlackPencel.svg';
import BlackExitIcon from '../../../image/BlackExit.svg';

export default function MemberShipInfoBox(props) {
  const { data, isClickedMemberShipEdit, onClickEditMemberShipButton, onClickDeletedMemberShip } = props;

  const PencelRef = useRef();
  const ExitRef = useRef();

  // 회원권 정보 클릭시 //
  function onClickEditMemberShip(e) {
    // if (isClickedMemberShipEdit || ExitRef.current === e.target) return;
    if (ExitRef.current === e.target) return;

    onClickEditMemberShipButton(data);
  }

  return (
    <TopLevelWrapper isHideAll={data.isHideAll} onClick={onClickEditMemberShip}>
      <Text fontFmaily='NotoSansKR-Medium' fontSize={14} color={data.isHideAll ? '#969696' : '#333333'} cursor='pointer'>{data.name}</Text>
      <StatusWrapper>
        {
          data.isHideAll &&
          <Text fontFmaily='NotoSansKR-Medium' fontSize={14} color='#333333' cursor='pointer'>비공개</Text>
        }
      {/* <Text fontFmaily='NotoSansKR-Medium' fontSize={14} color='#333333' cursor='pointer'>비공개</Text> */}
      <ImageWrapper>
        {/* <Image ref={PencelRef} src={BlackPencelIcon} /> */}
        <Image ref={ExitRef} src={BlackExitIcon} onClick={() => onClickDeletedMemberShip(data)}/>
      </ImageWrapper>
      </StatusWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  min-width: 100%;
  // height: 20px;
  
  background-color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    // background-color: #F5F5F5;
  }
`;

const StatusWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 10px;
`;

const Text = styled.div`
  font-family: ${props => props.fontFmaily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 6px;
`;

const Image = styled.img`
  width: 20px;
  height: 20px;

  :hover {
    cursor: pointer;
  }
`;