import { React, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import FetchModule from '../../Share/Network/FetchModule';

import DefaultLogoPath from '../../../image/DefaultLogo.png';
import UserProfile from '../../../Component/Normal/UserProfile';

export default function MemberInfo(props) {
  const { today } = props;

  const history = useHistory();
  // const size = useWindowSize();

  const [reserveInfo, setReserveInfo] = useState([]);
  const [attendanceInfo, setAttendanceInfo] = useState([]);
  const [newInfo, setNewInfo] = useState([]);
  const [expiredInfo, setExpiredInfo] = useState([]);
  // const [windowSize, setWindowSize] = useState(size);

  // // 금일 예약 회원 정보 불러오기 //
  // useEffect(() => {
  //   (
  //     async () => {
  //       const fetchModule = new FetchModule();
  //       const responseData = await fetchModule.getIndividualData('reserve/all_list/', 'GET');
  //       // console.log("======");
  //       // console.log(responseData.data);
  //       if (responseData.status === 200) {
  //         setReserveInfo(responseData.data);
	// 			}
  //       else if (responseData.status === 204) {}
  //       else if (responseData.status === 401) {
  //         // window.localStorage.setItem('Token', null);
  //         // window.localStorage.setItem('RefreshToken', null);
  //         // window.localStorage.setItem('StoreId', null);
  //         // window.localStorage.setItem('AccountId', null);
  //         // history.push('/');
  //       }
  //     }
  //   )();
  // }, [history])

  // // 금월 신규 등록자 명단 불러오기 //
  // useEffect(() => {
  //   (
  //     async () => {
  //       const fetchModule = new FetchModule();
  //       // const responseData = await fetchModule.getData('member/new/', 'GET');
  //       const responseData = await fetchModule.postData('member/new', 'POST');
  //       // console.log(responseData);
  //       if (responseData.status === 200) {
  //         setNewInfo(responseData.data);
	// 			}
  //       else if (responseData.status === 204) {}
  //       // else if (responseData.status === 401) history.push('/');
  //     }
  //   )();
  // }, [history])

  // // 만료 회원 불러오기 //
  // useEffect(() => {
  //   (
  //     async () => {
  //       const fetchModule = new FetchModule();
  //       const responseData = await fetchModule.getIndividualData('member/expired_main/', 'GET');
        
  //       if (responseData.status === 200) {
  //         setExpiredInfo(responseData.data);
	// 			}
  //       else if (responseData.status === 204) {}
  //       // else if (responseData.status === 401) history.push('/');
  //     }
  //   )();
  // }, [history])

  // 회원 정보 전체 불러오기 //
  useEffect(() => {
    let requestData = {
      standardDate: today.format('YYYY-MM-DDT23:59:59')
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        // const responseData = await fetchModule.getDataVersion2('member/today/status', 'GET');
        const responseData = await fetchModule.postDataVersion2('member/today/status', 'POST', requestData);
        // console.log(responseData.data);
        if (responseData.status === 200) {
          // setExpiredInfo(responseData.data);
          setReserveInfo(responseData.data.reserveInfos);
          setAttendanceInfo(responseData.data.attendanceInfos);
          setNewInfo(responseData.data.newInfos);
          setExpiredInfo(responseData.data.expiredInfos);
				}
        else if (responseData.status === 204) {}
        // else if (responseData.status === 401) history.push('/');
      }
    )();
  }, [history, today])

  // 오늘 예약한 회원, 신규 등록회원 클릭시 //
  function onClickMemberImage(data) {
    history.push('/member/valid/detail/' + data.memberId);
  }

  // // 만료회원 클릭시 //
  // function onClickExpiredMemberImage(data) {
  //   console.log(data);
  //   // history.push('/member/valid/detail/' + data.memberId);
  //   history.push('/member/valid/detail/' + data.id);
  // }

  

  return (
    <TopLevelWrapper>
      <TitleWrapper>
        <Title>회원 정보</Title>
        <ExplanationWrapper>
          <Explanation>예약, 신규, 만료 회원 목록을 확인하세요</Explanation>
          <Go onClick={() => history.push('/member/valid')}>자세히 보기</Go>
        </ExplanationWrapper>
      </TitleWrapper>
      <TotalInfoWrapper>
        <SubText>오늘 예약한 회원 ({reserveInfo.length})</SubText>
        <MemberWrapper>
          {
            reserveInfo.map((data, index) => (
              <InfoWrapper key={index} onClick={() => history.push('/member/valid/detail/' + data.memberId + '/course')}>
                <MemberImage>
                  <UserProfile profile={data.profile} defaultProfile={DefaultLogoPath}/>
                </MemberImage>
                <MemberName>{data.name}</MemberName>
              </InfoWrapper>
            ))
          }
        </MemberWrapper>
      </TotalInfoWrapper>
      <TotalInfo2Wrapper>
        <SubText>오늘 출석한 회원 ({attendanceInfo.length})</SubText>
        <MemberWrapper>
          {
            attendanceInfo.map((data, index) => (
              <InfoWrapper key={index} onClick={() => onClickMemberImage(data)}>
                {/* <MemberImage src={data.profile === '' ? DefaultLogoPath : data.profile}/> */}
                <MemberImage>
                  <UserProfile profile={data.profile} defaultProfile={DefaultLogoPath}/>
                </MemberImage>
                <MemberName>{data.name}</MemberName>
              </InfoWrapper>
            ))
          }
        </MemberWrapper>
      </TotalInfo2Wrapper>
      <TotalInfo2Wrapper>
        <SubText>신규 등록 회원 ({newInfo.length})</SubText>
        <MemberWrapper>
          {
            newInfo.map((data, index) => (
              <InfoWrapper key={index} onClick={() => onClickMemberImage(data)}>
                {/* <MemberImage src={data.profile === '' ? DefaultLogoPath : data.profile}/> */}
                <MemberImage>
                  <UserProfile profile={data.profile} defaultProfile={DefaultLogoPath}/>
                </MemberImage>
                <MemberName>{data.name}</MemberName>
              </InfoWrapper>
            ))
          }
        </MemberWrapper>
      </TotalInfo2Wrapper>
      <TotalInfo2Wrapper>
        <SubText>만료 회원 ({expiredInfo.length})</SubText>
        <MemberWrapper>
          {
            expiredInfo.map((data, index) => (
              <InfoWrapper key={index} onClick={() => onClickMemberImage(data)}>
                {/* <MemberImage src={data.profile === '' ? DefaultLogoPath : data.profile}/> */}
                <MemberImage>
                  <UserProfile profile={data.profile} defaultProfile={DefaultLogoPath}/>
                </MemberImage>
                <MemberName>{data.name}</MemberName>
              </InfoWrapper>
            ))
          }
        </MemberWrapper>
      </TotalInfo2Wrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  justify-content: center;
  align-items: center;

  width: 404px;
  // width: max(351px, 21.04166666666667vw);
  // height: 592px;
  height: 767px;

  border-radius: 30px;
  background-color: #FFFFFF;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;
  // font-size: max(16px, 0.8333333333333333vw);
  color: #232323;

  margin: 0 0 0 0;
`;

const ExplanationWrapper = styled.div`
  display: flex;
  flex-direction: row;

  margin-top: 4px;

  gap: 93px;
  // gap: max(40px, 4.84375vw);
`;

const Explanation = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;
  // font-size: max(12px, 0.625vw);

  color: #777777;
  
  margin: 0 0 0 0;
`;

const Go = styled.p`
  font-family: NotoSansKr-Medium;
  font-size: 12px;
  // font-size: max(12px, 0.625vw);

  color: #FF8B48;

  margin: 0 0 0 0;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    color: #FF7322;
  }
`;

const SubText = styled.p`
  font-family: NotoSansKr-Medium;
  font-size: 14px;
  // font-size: max(14px, 0.7291666666666667vw);

  color: #232323;

  margin: 24px 0 0 0;
`;

const TotalInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 348px;
  // width: max(295px, 18.125vw);
  height: 164px;

  margin-top: 24px;

  // overflow: hidden;

  border-top: 2px solid #ECECEC;
  // border-bottom: 2px solid #ECECEC;
`;

const TotalInfo2Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 348px;
  // width: max(295px, 18.125vw);
  // height: 164px; -> border-bottom 2px 빠진거 채움
  height: 166px;
  // margin-top: 24px;
  overflow: hidden;

  // border-top: 2px solid #ECECEC;
  // border-bottom: 2px solid #ECECEC;
`;

const MemberWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  // margin-top: 24px;
  padding-top: 24px;
  padding-left: 16px;
  padding-right: 16px;
  gap: 12px;

  height: 96px;
  // height: 120px;

  overflow-x: scroll;
  overflow-y: hidden;
  // background-color: red;
  &::-webkit-scrollbar {
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
		border: 2px solid transparent;
		background-color: #DADADA;
		border-radius: 200px;
	}

  &::-webkit-scrollbar-track {
		background-color: #F0F0F0;
		border-radius: 200px;
	}
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
`;

// const MemberImage = styled.img`
//   width: 48px;
//   height: 48px;

//   border-radius: 48px;
  
//   :hover {
//     cursor: pointer;
//     filter: drop-shadow(0px 0px 8px #FF8B48);
//   }

//   transition: all 0.3s ease-in-out;
// `;

const MemberImage = styled.div`
  width: 48px;
  height: 48px;

  border-radius: 48px;
  
  overflow: hidden;

  :hover {
    cursor: pointer;
    filter: drop-shadow(0px 0px 8px #FF8B48);
  }

  transition: all 0.3s ease-in-out;
`;

const MemberName = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 10px;
  // font-size: max(10px, 0.5208333333333333vw);

  margin: 10px 0 24px 0;

  overflow:hidden;
  white-space : nowrap;
  text-overflow: ellipsis;
`;

