import React, { useState } from "react";
import styled from "styled-components";

export default function MemberPlusBox(props) {
  const { data, onClick } = props;

  const [isHover, setIsHover] = useState(false);

  return (
    <PlusBox 
      isHover={isHover} 
      onMouseOver={() => setIsHover(true)} 
      onMouseLeave={() => setIsHover(false)}
      onClick={() => onClick(data)}>
      <Plus isHover={isHover} width={10} height={2} />
      <Plus isHover={isHover} width={2} height={10} />
    </PlusBox>
  );
}

const PlusBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;

  border-radius: 20px;
  background-color: ${props => props.isHover ? `#6DD49E` : `#FFFFFF`};

  :hover {
    cursor: pointer;
  }
	
  transition: all 0.3s ease-in-out;
`;

const Plus = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.width}px;
  height: ${props => props.height}px;

  background-color: ${props => props.isHover ? `#FFFFFF` : `#6DD49E`};

  :hover {
    cursor: pointer;
  }
	
  transition: all 0.3s ease-in-out;
`;
