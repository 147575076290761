import { React, useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import FetchModule from '../../Share/Network/FetchModule';

export default function WorkoutSelectBox(props) {
	const { date, setWorkoutId } = props;
	const history = useHistory();

	const [isClicked, setIsClicked] = useState(false);
	const [options, setOptions] = useState([]);
	const [defaultVal, setDefaultVal] = useState({ name: '' });
	const [clickIndex, setClickIndex] = useState(-1);

  const TopLevelRef = useRef();
	const SelectRef = useRef();

	// Schedule Data API호출
	useEffect(() => {
    let requestData = {
      classDate: date.format('YYYY-MM-DDT00:00:00')
    }

		const fetchModule = new FetchModule();
		(
			async () => {
				const responseData = await fetchModule.postDataOfExerciseVersion2('workout/board_workout/list', 'POST', requestData);
        // console.log(responseData.data);
				if (responseData.status === 200) {
          setOptions(responseData.data);

          if (responseData.data.length > 0) {
            setDefaultVal(responseData.data[0]);
            setWorkoutId(responseData.data[0].workoutId);
            setClickIndex(0);
          }
					else {
						setDefaultVal({ name: '' });
						setWorkoutId(0);
						setClickIndex(-1);
					}
        }
				else if (responseData.status === 401) history.push('/');
			}
		)();
	}, [date])


  // 컴포넌트 밖을 클릭하면 셀렉트 창 닫기
  useEffect(() => {
    document.addEventListener("mousedown", onClickOutSide);

    return () => {
      document.removeEventListener("mousedown", onClickOutSide);
    }
  }, [onClickOutSide])

  // 컴포넌트 밖을 클릭하면 셀렉트 창 닫기
  function onClickOutSide(e) {
    if (TopLevelRef.current !== e.target && !TopLevelRef.current.contains(e.target)) {
			SelectRef.current.style.border = '0px solid #CBCBCB';
			SelectRef.current.style.height = `0px`;
			SelectRef.current.style.overflow = 'hidden';
      setIsClicked(false);
    }
  }

	function onClick() {
		if (isClicked) {
			SelectRef.current.style.border = '0px solid #CBCBCB';
			SelectRef.current.style.height = `0px`;
			SelectRef.current.style.overflow = 'hidden';
			setIsClicked(false);
		}
		else {
      if (options.length === 0) alert('저장된 수업 내용이 없습니다.');
      else {
        SelectRef.current.style.border = '1px solid #FF8B48';
        // SelectRef.current.style.height = `240px`;
        SelectRef.current.style.height = 32 * (options.length) > 500 ? `500px` : (40 * (options.length) + 'px');
        SelectRef.current.style.overflowY = 'scroll';
        SelectRef.current.style.overflowX = 'none';
        setIsClicked(true);
      }
		}
	}

	function optionalClick(index) {
		setDefaultVal(options[index]);
    setWorkoutId(options[index].workoutId);
    setClickIndex(index);
	}

	return (
		<TopLevelWrapper ref={TopLevelRef}>
			<ListButton onClick={onClick}>목록</ListButton>
			<SelectBox
				ref={SelectRef}
				isClicked={isClicked}
				onClick={onClick}>
				{
					isClicked &&
					options.map((data, index) =>
						<OptionWrapper key={index} onClick={() => optionalClick(index)}>
							<Option isClicked={clickIndex === index}>{data.lessonName + ' ' + data.title}</Option>
						</OptionWrapper>
					)
				}
			</SelectBox>
		</TopLevelWrapper>
	)
}

const TopLevelWrapper = styled.div`
	display: flex;
	flex-direction: column;
  
  justify-content: flex-start;
  align-items: flex-end;
	// width: 143px;
	// height: 38px;
`;

const SelectBox = styled.div`
	position: absolute;
	display: flex;
	flex-direction: column;
	// justify-content: center;
	align-items: flex-start;

	width: 234px;
	height: 0px;
  margin-top: 20px;

  z-index: 3;

	border: 0px solid #CBCBCB;
	border-radius: 15px;
	overflow: hidden;

	background-color: rgba(255, 255, 255, 1);
  transition: all 0.3s ease-in-out;

	:hover {
		cursor: pointer;
	}

	&::-webkit-scrollbar {
		width: 9px;
	}

	&::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 20px #FF8B48;
		border: 3px solid transparent;
		height: 25px;
		// background-color: #FF8B48;
		border-radius: 200px;
	}

	&::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px transparent;
		border: 4px solid transparent;
		background-color: transparent;
	}

	&::-webkit-scrollbar-track-piece {
		background-color: transparent;
	}
`;

const OptionWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;

	:hover {
		cursor: pointer;
	}
`;

const Option = styled.p`
	font-family: NotoSansKR-Medium;
	font-size: 14px;

	margin: 10px 0 10px 16px;

  color: ${props => props.isClicked ? '#FF8B48' : '#595959'};
`;

const ListButton = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #777777;

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;