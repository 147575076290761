import { React, useState, useEffect } from 'react';
import styled from 'styled-components';

import { loadTossPayments } from '@tosspayments/payment-sdk';

import FetchModule from '../../Share/Network/FetchModule.js';
import moment from 'moment';

import ProductLogo from '../../../image/ProductLogo.png';
import ExitButtonImg from '../../../image/BlackDeleteButton.png';
import TossLogoImg from '../../../image/TossLogo.png';

function PaymentModal(props) {
  const { name, validity, price, cashPrice, discount, notReceived, merchantUid, onRegist, cancel, saveRegistData, getApprovalDate, setIsLoading } = props;

  const [isInProgress, setIsInProgress] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
    document.body.style= `overflow: hidden`;
    return () => document.body.style = `overflow: auto`;
  }, [])

  useEffect(() => {
    let storeId = localStorage.getItem('StoreId');

    if (storeId === '1') setIsAdmin(true);
  }, [])

  function onClickOnSitePayment() {
    let approvalDate = getApprovalDate();

    let requestData = {
      merchantUid: merchantUid,
      cardPrice: price,
      cashPrice: cashPrice,
      discount: discount,
      notReceived: notReceived,
      approvalDate: approvalDate
    }
    // console.log(requestData);
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataVersion2('sales/onSite/effectiveness', 'POST', requestData);

        if (responseData.status === 200) {
          // console.log('Effect Success');
          onRegist(responseData.data);
        }
        else if (responseData.status === 201) {
          alert('올바르지 않은 요청입니다');
          return;
        }
        else if (responseData.status === 202) {
          alert('이미 결제가 진행된 건 입니다.');
          return;
        }
        else if (responseData.status === 204) {
          alert('결제 내역이 존재하지 않습니다.');
          return;
        }
        // cancel();
      }
    )();
  }

  function callback(response) {
    const { success, error_msg, imp_uid, merchant_uid, pay_method, paid_amount, status } = response;

    if (success) {
      console.log('error_msg : ' + error_msg);
      console.log('imp_uid : ' + imp_uid);
      console.log('merchant_uid : ' + merchant_uid);
      console.log('pay_method : ' + pay_method);
      console.log('paid_amount : ' + paid_amount);
      console.log('status : ' + status);

      var requestData = {
        imp_uid: imp_uid,
        merchant_uid: merchant_uid
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postData('sales/effectiveness', 'POST', requestData);

          // console.log(responseData);
          if (responseData.status === 200) {
            onRegist(merchant_uid);
          }
          else {
            cancel();
          }
        }
      )();
    }
    else {
      console.log('error_msg : ' + error_msg);
      console.log('imp_uid : ' + imp_uid);
      console.log('merchant_uid : ' + merchant_uid);
      console.log('pay_method : ' + pay_method);
      console.log('paid_amount : ' + paid_amount);
      console.log('status : ' + status);
    }

    setIsClicked(false);
  }

  // 일반결제 버튼 클릭시 ( 페이플 ) //
  function onClickPaymentOnPayple() {
    if (isInProgress) {
      alert('결제를 진행중입니다!');
      return;
    }

    setIsInProgress(true);

    const obj = {};

    obj.PCD_PAY_TYPE = 'card';
    // obj.PCD_PAY_WORK = 'PAY'
    obj.PCD_PAY_WORK = 'CERT';
    obj.PCD_CARD_VER = '02';
    obj.PCD_PAYER_AUTHTYPE = 'sms';
    // obj.PCD_RST_URL = process.env.REACT_APP_TEST_HOSTNAME + '/subscribe/success';
    // obj.PCD_RST_URL = 'https://link-soft.io/';

    obj.PCD_PAY_GOODS = name;
    obj.PCD_PAY_TOTAL = price;
    obj.PCD_PAY_OID = merchantUid;


    obj.callbackFunction = getResult;
    // obj.clientKey = 'test_DF55F29DA654A8CBC0F0A9DD4B556486';
    obj.clientKey = '0F44C92BFB07F1BE60C5235A7254316D';
    
    window.PaypleCpayAuthCheck(obj);
  }

  // 결제 이후 콜백 //
  function getResult(res) {
    if (res.PCD_PAY_RST === 'success' && res.PCD_PAY_CODE === '0000') {
      let authKey = res.PCD_AUTH_KEY;
      let reqKey = res.PCD_PAY_REQKEY;
      let billingKey = res.PCD_PAYER_ID;
      let merchant_uid = res.PCD_PAY_OID;
      let approval = res.PCD_PAY_TIME;
      let cardPrice = parseInt(res.PCD_PAY_TOTAL);
      let approval_date = approval.slice(0, 4) + '-' + approval.slice(4, 6) + '-' + approval.slice(6, 8) + 'T' + approval.slice(8, 10) + ':' + approval.slice(10, 12) + ':' + approval.slice(12, 14);


      let requestData = {
        merchantUid: merchant_uid,
        approvalDate: approval_date,
        cardPrice: cardPrice,
        division: 'Normal',
        authKey: authKey,
        reqKey: reqKey,
        billingKey: billingKey
      }
      // console.log(requestData);
      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataVersion2('sales/payple/effectiveness', 'POST', requestData);

          // console.log(responseData);
          if (responseData.status === 200) {
            onRegist(merchant_uid);
          }
          else if (responseData.status === 201) {
            alert('내역이 존재하지 않습니다');
            cancel();
          }
          else if (responseData.status === 202) {
            alert('데이터가 올바르지 않습니다');
            cancel();
          }
          else if (responseData.status === 203) {
            alert(responseData.error);
            cancel();
          } 
          else {
            cancel();
          }
        }
      )();
    }
    else {
      console.log('[' + res.PCD_PAY_CODE + ']' + ' ' + res.PCD_PAY_MSG);
      alert('[' + res.PCD_PAY_CODE + ']' + ' ' + res.PCD_PAY_MSG);
    }

    setIsInProgress(false);
  }

  return (
    <TopLevelWrapper>
      <ModalView isClicked={isClicked} isAdmin={isAdmin}>
        <TotalWrapper>
          <TitleLineWrapper>
            <Title>상품 정보</Title>
            <ExitButton src={ExitButtonImg} onClick={() => cancel()} />
          </TitleLineWrapper>
          <ProductInfoBox>
            {/* <Logo src={ProductLogo} /> */}
            <Logo src='https://picture.link-zone.org/ZoneLogo.png' />
            <TextWrapper>
              <TextEng>Link Zone</TextEng>
              <TextKor>{name}</TextKor>
              <TextExplanation>{validity} 일</TextExplanation>
            </TextWrapper>
          </ProductInfoBox>
          <PriceWrapper>
            <Title>최종 결제 금액</Title>
            <LayerWrapper style={{ marginTop: `24px` }}>
              <TextNormal style={{ color: `#595959` }}>결제 금액</TextNormal>
              <DivisionLine />
              <TextNormal style={{ color: `#595959` }}>{(parseInt(price) + parseInt(cashPrice)).toLocaleString('ko-KR')}원</TextNormal>
            </LayerWrapper>
            <LayerWrapper style={{ marginTop: `8px` }}>
              <TextBold style={{ color: `#232323` }}>최종 금액</TextBold>
              <DivisionLine />
              <TextBold style={{ color: `#FF8B48` }}>{(parseInt(price) + parseInt(cashPrice)).toLocaleString('ko-KR')}원</TextBold>
            </LayerWrapper>
          </PriceWrapper>
          {/* <ButtonWrapper>
            <NormalPayment onClick={onClickPayment}>일반 결제</NormalPayment>
            <RegularPayment onClick={onClickRegularPayment}>정기 결제</RegularPayment>
          </ButtonWrapper> */}
          {
            isAdmin &&
            <OnSiteWrapper>
              <NormalPayment onClick={onClickPaymentOnPayple}>일반 결제</NormalPayment>
            </OnSiteWrapper>
          }
          <OnSiteWrapper isAdmin={isAdmin}>
            <OnSitePayment onClick={onClickOnSitePayment}>현장 결제</OnSitePayment>
          </OnSiteWrapper>
        </TotalWrapper>
      </ModalView>
    </TopLevelWrapper>
  )
}

export default PaymentModal;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 1100;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.3);

  transition: all 0.3s ease-in-out;
`;

const ModalView = styled.div`
  display: ${props => props.isClicked ? `none` : `flex`};
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 378px;
  // height: 416px;
  height: ${props => props.isAdmin ? `454px` : `416px`};
  // height: 454px;

  border-radius: 30px;
  background-color: #FFFFFF;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  // width: 322px;
`;

const TitleLineWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const Title = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;
`;

const ExitButton = styled.img`
  width: 16px;
  height: 16px;

  :hover {
    cursor: pointer;
  }
`;

const ProductInfoBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  gap: 16px;
  margin: 24px 0 0 0;
  padding: 0 16px 0 16px;

  width: 290px;
  height: 100px;

  border-radius: 15px;
  background-color: #FF8B48;
`;

const Logo = styled.img`
  width: 60px;
  height: 60px;

  margin: 0 0 0 0;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const TextEng = styled.p`
  font-family: Poppins-Bold;
  font-size: 14px;

  color: #FFFFFF;

  margin: 0 0 0 0;
`;

const TextKor = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  margin: 0 0 0 0;
`;

const TextExplanation = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 12px;

  color: rgba(255, 255, 255, 0.5);

  margin: 0 0 0 0;
`;

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin: 24px 0 0 0;
  padding: 24px 0 0 0;

  width: 322px;

  border-top: 1px solid #ECECEC;
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const TextBold = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 14px;

  // color: #232323;

  margin: 0 0 0 0;
`;

const TextNormal = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  // color: #232323;

  margin: 0 0 0 0;
`;

const DivisionLine = styled.div`
  width: 160px;
  height: 1px;

  border-top: 1px dashed #ECECEC;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 12px;
  margin-top: 36px;
`;

const OnSiteWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // margin-top: 12px;
  margin-top: ${props => props.isAdmin ? `12px` : `36px`};

  width: 100%;
`;

const OnSitePayment = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // width: 290px;
  width: 100%;
  height: 38px;

  border: 1px solid #E0E0E0;
  border-radius: 15px;
  background-color: #FFFFFF;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #BDBDBD;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #232323;
    color: #232323;
  }
`;

const NormalPayment = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // width: 153px;
  width: 100%;
  height: 38px;

  border: 1px solid #E0E0E0;
  border-radius: 15px;
  background-color: #FFFFFF;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #BDBDBD;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #232323;
    color: #232323;
  }
`;

const RegularPayment = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 155px;
  height: 40px;

  border-radius: 15px;
  background-color: #5BD98A;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;
  
  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }
`;

const TossLogo = styled.img`
  width: 55.38px;
  height: 32px;
`;