import { React, useRef, useImperativeHandle, forwardRef } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';

import CompletePermuteLogoPath from '../../../image/CompletePermuteLogo.png';

const CompletePermuteView = forwardRef((props, ref) => {
  const TopLevelRef = useRef();

  const history = useHistory();

  useImperativeHandle(ref, () => ({
		setOpacity: () => {
      TopLevelRef.current.style.zIndex = 3;
      TopLevelRef.current.style.opacity = 1;
		}
	}));

  return (
    <TopLevelWrapper ref={TopLevelRef}>
      <InfoBox>
        <Logo src={CompletePermuteLogoPath}/>
        <InfoWrapper>
          <Title>{sessionStorage.getItem('name')} 님의 비밀번호가</Title>
          <Title>재설정 되었습니다.</Title>  
         <LoginButton onClick={() => history.push('/')}>
            <LoginButtonText>로그인하기</LoginButtonText>
          </LoginButton>
        </InfoWrapper> 
      </InfoBox>
    </TopLevelWrapper>
  )
});

export default CompletePermuteView;

const TopLevelWrapper = styled.div`
  position: absolute;
  
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  opacity: 0;
  z-index: -1;

  transition: all 0.5s ease-in-out;
`;

const Logo = styled.img`
  width: 120px;
  height: 120px;

  margin-top: -115px;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 582px;
  width: max(351px, 30.3125vw);
  height: 288px;

  border-radius: 50px;
  background-color: #FFFFFF;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: 32px;
  // width: 468px;
`;

const Title = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 18px;

  color: #000000;

  // margin: 54px 0 0 57px;
  margin: 0 0 0 0;
`;

const LoginButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 135px;
  height: 46px;

  margin-top: 40px;

  background-color: #FF8B48;
  border-radius: 23px;

  transition: all 0.5s ease-in-out;

	:hover {
		cursor: pointer;
		background-color: #FF7322;
	}
`;

const LoginButtonText = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 14px;

  color: #FFFFFF;
  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;
