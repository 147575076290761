import { React, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
// Component Import //
import RankNormalSectionBox from './RankNormalSectionBox';
// Component Import //

// Image Import //
import FirstPlaceImg from '../../../image/FirstPlace.png';
import SecondPlaceImg from '../../../image/SecondPlace.png';
import ThirdPlaceImg from '../../../image/ThirdPlace.png';
import DefaultProfile from '../../../image/DefaultProfile.png';
import moment from 'moment';
import UserProfile from '../../../Component/Normal/UserProfile';
// Image Import //

function RankingExerciseBox(props) {
  const {
    isClickedModify,
    clickDate,
    useData,
    nowSection,
    setNowSection,
    rankingList,
    nowClickLinkCoachId,
    setIsClickedDetail,
    clickWorkoutIndex,
    changeTopLevelWrapperPosition
  } = props;

  const [workoutId, setWorkoutId] = useState(0);
  const [title, setTitle] = useState('');
  const [index, setIndex] = useState(0);
  const [sectionRecordData, setSectionRecordData] = useState(undefined);
  const [sectionList, setSectionList] = useState([]);

  const BottomRef = useRef();

  // // 날짜에 맞게 데이터 세팅 //
  // useEffect(() => {
  //   for (let i = 0; i < useData.length; i++) {
  //     if (useData[i].classDate.clone().format('YYYY.MM.DD') === clickDate) {
  //       setTitle(useData[i].workouts[clickWorkoutIndex].title);
  //       setWorkoutId(useData[i].workouts[clickWorkoutIndex].workoutId);
  //       setSectionList(useData[i].workouts[clickWorkoutIndex].sectionList);
  //       break;
  //     }
  //   }
  // }, [useData, clickDate, clickWorkoutIndex])

  // 날짜에 맞게 데이터 세팅 //
  useEffect(() => {
    for (let i = 0; i < useData.length; i++) {
      if (moment(useData[i].classDate).clone().format('YYYY.MM.DD') === clickDate) {
        setTitle(useData[i].title);
        setWorkoutId(useData[i].workoutId);
        setSectionList(useData[i].sectionList);
        break;
      }
    }
  }, [useData, clickDate, clickWorkoutIndex])

  // 띄워줄 섹션 저장 데이터 세팅 //
  useEffect(() => {

    if (sectionList.length === 0) return;

    for (let i = 0; i < sectionList[nowSection].sectionRecordList.length; i++) {
      if (sectionList[nowSection].sectionRecordList[i].linkCoachId === nowClickLinkCoachId) {
        setSectionRecordData(sectionList[nowSection].sectionRecordList[i]);
        // console.log(sectionList[nowSection].sectionRecordList[i])
        break;
      }
    }
  }, [sectionList, nowSection, nowClickLinkCoachId])

  // 보여줄 데이터 인덱스 확인 //
  useEffect(() => {
    for (let i = 0; i < rankingList.length; i++) {
      if (rankingList[i].linkCoachId === nowClickLinkCoachId) {
        setIndex(i);
        return;
      }
    }
  }, [rankingList, nowClickLinkCoachId])

  // 섹션 이동 버튼 클릭시 //
  async function onClickSectionButton(index) {
    let isExist = false;

    for (let i = 0; i < sectionList[index].sectionRecordList.length; i++) {
      if (sectionList[index].sectionRecordList[i].linkCoachId === nowClickLinkCoachId) {
        isExist = true;
        break;
      }
    }

    if (isExist) setNowSection(index);
    else alert('진행하지 않은 운동입니다!');
  }

  // 스크롤 하단 조정 //
  function scrollIntoBottom() {
    changeTopLevelWrapperPosition();
    BottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    changeTopLevelWrapperPosition();
  }

  // 점수 보여주기 //
  function onViewFinalScoreText(data) {
    // return data.createdDate.split('T')[0];
    if (rankingList[index].finalScoreText === '' || rankingList[index].finalScoreText === undefined) return rankingList[index].createdDate.split('T')[0];
    else return rankingList[index].finalScoreText;
  }

  return (
    <TopLevelWrapper>
      <TotalWrapper>
        <RankingWrapper>
          <LeftWrapper>
            {
              index < 3 ?
                <RankingLeaderBoxWrapper>
                  <RankingLeaderBox src={index === 0 ? FirstPlaceImg : index === 1 ? SecondPlaceImg : ThirdPlaceImg} />
                  <RankingImageTextWrapper>
                    <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF'>{index + 1}</Text>
                  </RankingImageTextWrapper>
                </RankingLeaderBoxWrapper>
                :
                <RankingNumberBox>
                  <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF'>{index + 1}</Text>
                </RankingNumberBox>
            }
            {
              rankingList[index] !== undefined && 
                <ProfileWrapper /*src={rankingList[index].userProfile === '' ? DefaultProfile : rankingList[index].userProfile}*/>
                  <UserProfile profile={rankingList[index].userProfile} defaultProfile={DefaultProfile}/>
                </ProfileWrapper>
            }
            {rankingList[index] !== undefined &&
              <TextWrapper>
                <InfoRowTextWrapper>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>{rankingList[index].userName}</Text>
                  {
                    (rankingList[index].minusPointCount !== undefined && rankingList[index].minusPointCount !== 0) &&
                    <ScaleBox>
                      <Text fontFamily='Poppins-Medium' fontSize={12} color='#FFFFFF'>Scale</Text>
                    </ScaleBox>
                  }
                </InfoRowTextWrapper>
                {/* <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>{rankingList[index].userName}</Text> */}
                <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#DBDBDB'>{onViewFinalScoreText()}</Text>
              </TextWrapper>
            }
          </LeftWrapper>
          <GobackRankButton onClick={() => setIsClickedDetail(false)}>순위표로 돌아가기</GobackRankButton>
        </RankingWrapper>
        <DivisionLine />
        <SectionWrapper>
          {
            sectionList.map((data, index) => (
              <SectionBox key={index} nowSection={nowSection === index} onClick={() => onClickSectionButton(index)}>{String.fromCharCode(index + 65)}</SectionBox>
            ))
          }
        </SectionWrapper>
        <ScrollWrapper isModify={isClickedModify}>
          <TitleWrapper topMargin={0}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>제목</Text>
          </TitleWrapper>
          <TotalTitleBox>{title === '' ? '작성이 필요합니다' : title}</TotalTitleBox>
          {
            sectionList[nowSection] !== undefined &&
            <RankNormalSectionBox
              index={nowSection}
              sectionList={sectionList[nowSection]}
              sectionRecordData={sectionRecordData}
              scrollIntoBottom={scrollIntoBottom} />
          }
          <div ref={BottomRef} />
        </ScrollWrapper>
      </TotalWrapper>
    </TopLevelWrapper>
  )
}

export default RankingExerciseBox;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 582px;
  height: 820px;
  
  padding-top: 28px;
  padding-bottom: 28px;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  background-color: #FFFFFF;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 90.33%;
`;

const RankingWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  width: 100%;
`;

const RankingLeaderBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const RankingLeaderBox = styled.img`
  width: 40px;
  height: 40px;
`;

const RankingImageTextWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: -10px;
`;

const RankingNumberBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;

  border-radius: 12px;
  background-color: #E0E0E0;
`;

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 8px;
`;

const ProfileWrapper = styled.div`
  width: 40px;
  height: 40px;

  border-radius: 6px;

  overflow: hidden;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 3px;
`;

const InfoRowTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const ScaleBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 45px;
  height: 18px;

  border-radius: 6px;
  background-color: #4161AF;
`;

const GobackRankButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  font-family: NotoSansKR-Bold;
  font-size: 14px;

  color: #FF8B48;

  :hover {
    cursor: pointer;
  }
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  flex-wrap: wrap;

  width: 100%;

  // margin-top: 24px;

  gap: 8px;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 97.71%;

  margin-top: ${props => props.topMargin}px;
`;

const TotalTitleBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: calc(97.71% - 34px);
  min-height: 34px;

  margin-top: 8px;
  padding-right: 16px;
  padding-left: 16px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;
`;

const SectionBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 32px;
  height: 32px;

  border-radius: 6px;
  background-color: ${props => props.nowSection ? `#6DD49E` : `#E0E0E0`};

  font-family: Poppins-Bold;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: ${props => props.nowSection ? `#6DD49E` : `#D7D7D7`};
  }
`;

const ScrollWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  margin-top: 24px;

  width: 100%;
  // max-height: ${props => props.isModify ? `894px` : `1039px`};
  max-height: 663px;
  
  overflow-x: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
		border: 3px solid transparent;
		// height: 92px;
		background-color: #DADADA;
		border-radius: 200px;
	}

  &::-webkit-scrollbar-track {
    // height: 439px;
		// border: 4px solid transparent;
		background-color: #F0F0F0;
		border-radius: 200px;
	}
`;

const DivisionLine = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;

  margin-top: 24px;
  margin-bottom: 24px;

  border: 1px solid #ECECEC;
`;