import { React, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import FetchModule from '../../Share/Network/FetchModule';

import CardIcon from '../../../image/CardIcon.png';

export default function BillingInfoModal(props) {
  const { data, onClickExit } = props;

  const [cardName, setCardName] = useState('');
  const [cardNum, setCardNum] = useState('');
  const [suspensionCode, setSuspensionCode] = useState('');
  const [suspensionReason, setSuspensionReason] = useState('');

  const history = useHistory();

  useEffect(() => {
    document.body.style = `overflow: hidden`;
    return () => document.body.style = `overflow: auto`;
  }, [])

  useEffect(() => {
    setCardName(data.cardName === undefined ? '' : data.cardName);
    setCardNum(data.cardNum === undefined ? '' : data.cardNum);
    setSuspensionCode(data.suspensionCode === undefined ? '' : data.suspensionCode);
    setSuspensionReason(data.suspensionReason === undefined ? '' : data.suspensionReason);
  }, [data])

  // 정기결제 클릭시 페이플 버전 // 
  function onClickRegularPaymentOnPayple() {
    const obj = {};

    obj.PCD_PAY_TYPE = 'card'
    obj.PCD_PAY_WORK = 'AUTH'
    // obj.PCD_PAY_WORK = 'PAY'
    obj.PCD_CARD_VER = '01';
    obj.PCD_PAYER_AUTHTYPE = 'sms';
    // obj.PCD_RST_URL = process.env.REACT_APP_TEST_HOSTNAME + '/subscribe/success';
    // obj.PCD_RST_URL = 'https://link-soft.io/';

    // obj.PCD_PAY_GOODS = 'Link-Zone 정기 구독권 (1개월)';
    // obj.PCD_PAY_TOTAL = 100;
    // obj.PCD_PAY_OID = responseData.data[0].nextMerchantUid;


    obj.callbackFunction = getResult;
    obj.clientKey = '0F44C92BFB07F1BE60C5235A7254316D';
    // obj.clientKey = 'test_DF55F29DA654A8CBC0F0A9DD4B556486';
    
    window.PaypleCpayAuthCheck(obj);
  }


  // 결제 이후 콜백 //
  function getResult(res) {
    if (res.PCD_PAY_RST === 'success' && (res.PCD_PAY_CODE === '0000' || res.PCD_PAY_CODE === 'CDAU1000')) {
      let requestData = {
        billingKey: res.PCD_PAYER_ID,
        cardName: res.PCD_PAY_CARDNAME,
        cardNum: res.PCD_PAY_CARDNUM
      }
      // console.log(requestData);
      // console.log(res);
      // console.log(res.PCD_PAYER_ID);
      // console.log(res.PCD_PAYER_NO);
      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataVersion2('billing/registry', 'POST', requestData);

          if (responseData.status === 200) {
            alert('카드번호 등록이 완료되었습니다!');
            onClickExit();
          }
          else {
            // alert('이미 등록된 정보입니다\n새로고침 후 다시시도해 주세요');
            onClickExit();
          }
          // else if (responseData.status === 201) {
          //   alert('이미 등록된 정보입니다\n새로고침 후 다시시도해 주세요');
          //   onClickExit();
          // }
        }
      )();
    }
    else {
      if (res.PCD_PAY_CODE === undefined) {
        console.log(res.PCD_PAY_MSG);
        alert(res.PCD_PAY_MSG);
      }
      else {
        console.log('[' + res.PCD_PAY_CODE + ']' + ' ' + res.PCD_PAY_MSG);
        alert('[' + res.PCD_PAY_CODE + ']' + ' ' + res.PCD_PAY_MSG);
      }
    }
  }


  return (
    <TopLevelWrapper>
      <ModalView>
        <TitleWrapper>
          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>현재 등록된 카드</Text>
        </TitleWrapper>
        <InfoBox>
          <Image src={CardIcon}/>
          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>{cardName} / {cardNum}</Text>
        </InfoBox>
        <TitleWrapper>
          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>결제 가능 여부</Text>
        </TitleWrapper>
        <InfoBox>
          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>{suspensionCode === '' ? '가능' : suspensionReason}</Text>
        </InfoBox>
        <ButtonWrapper>
          <Button backgroundColor='#CBCBCB' onClick={onClickExit}>
            <Text fontFamily='NotoSansKR-Black' fontSize={18} color='#FFFFFF'>닫기</Text>
          </Button>
          <Button backgroundColor='#6DD49E' onClick={onClickRegularPaymentOnPayple}>
            <Text fontFamily='NotoSansKR-Black' fontSize={18} color='#FFFFFF'>카드 재등록</Text>
          </Button>
        </ButtonWrapper>
      </ModalView>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 1000;
  
  width: 100vw;
  height: 100vh;

  overflow-y: scroll;

  background-color: rgba(0, 0, 0, 0.2);
`;

const ModalView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 407px;
  height: 278px;

  border-radius: 10px;
  background-color: #F9F9F9;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  padding: 0px 10px;
  margin-top: 16px;

  width: calc(90.33% - 10px);
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  padding: 0px 10px;
  margin-top: 16px;

  width: calc(90.33% - 10px);
  height: 33px;

  gap: 10px;

  background-color: #FFFFFF;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: 53px;

  width: 90.33%;
`;

const Button = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 48.5%;
  height: 43px;

  border-radius: 10px;
  background-color: ${props => props.backgroundColor};

  :hover {
    cursor: pointer;
  }
`;

const Image = styled.img`
  width: 18px;
  height: 18px;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;
  
  margin: 0 0 0 0;
  
  color: ${props => props.color};
  
  transition: all 0.3s ease-in-out;

  :hover {
    cursor: ${props => props.hover};
  }
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: 0 0 0 0;
`;

const SubTitleWrpper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const SubTitle = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 12px;

  color: #FF8B48;

  margin: 0 0 0 0;
`;

const ExitButton = styled.img`
  display: flex;
  flex-direction: row;
  
  justify-content: center;
  align-items: center;

  width: 16px;
  height: 16px;

  :hover {
    cursor: pointer;
  }
`;

// const ExitButton = styled.p`
//   font-family: NotoSansKR-Regular;
//   font-size: 12px;

//   color: #000000;

//   margin: 0 0 0 0;

//   :hover {
//     cursor: pointer;
//   }
// `;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 322px;

  // margin-bottom: 24px;

  border-bottom: 1px solid #ECECEC;
`;

const LayerTitle = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #232323;

  margin: 24px 0 0 0;
`;

const ZoneView = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 322px;
  height: 100px;

  gap: 16px;
  margin-top: 24px;
  margin-bottom: 24px;

  border-radius: 15px;
  background-color: #FF8B48;
`;

const ViewTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const ViewTextEng = styled.p`
  font-family: Poppins-Bold;
  font-size: 14px;

  color: #FFFFFF;

  margin: 0 0 0 0;
`;

const ViewTextKor = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 12px;

  color: #FFFFFF;

  margin: 0 0 0 0;
`;

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: 8px;
  margin-top: 24px;
`;

const PriceLayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const PriceText = styled.div`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: ${props => props.red ? `#FF3131` : `#595959`};

  margin: 0 0 0 0;
`;

const PriceLine = styled.div`
  width: ${props => props.width}px;
  height: 1px;

  border: 1px dashed #ECECEC;
`;

const CouponButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 320px;
  height: 38px;

  border: 1px solid #FF8B48;
  border-radius: 15px;
  background-color: #FFFFFF;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FF8B48;

  margin-top: 24px;
  margin-bottom: 24px;

  :hover {
    cursor: pointer;
  }
`;

const PaymentMethodWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  gap: 8px;
  margin-top: 24px;
  margin-bottom: 24px;
`;

const PaymentMethodLayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const PaymentMethod = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 155px;
  height: 38px;

  gap: 8px;

  border: ${props => props.isClicked ? `1px solid #FF8B48` : `1px solid #ECECEC`};
  border-radius: 15px;

  background: url(${props => props.img}) no-repeat center center;
  background-color: #FFFFFF;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #777777;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #FF8B48;
    color: #FF8B48;
  }
`;

const TotalPriceText = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 14px;

  color: ${props => props.color};

  margin: 0 0 0 0;
`;

const PaymentButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 322px;
  height: 40px;

  border-radius: 15px;
  background-color: #FF8B48;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  margin-top: 24px;
  margin-bottom: 24px;

  :hover {
    cursor: pointer;
  }
`;