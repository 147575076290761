import { React } from 'react';
import styled from 'styled-components';

import MemberDetailView from '../../Model/Individual/MemberModel/MemberDetail/MemberDetailView';

function MemberDetailPage({match}) {

  return (
    <Container>
      <MemberDetailView memberId={match.params.memberId} division={match.params.division}/>
    </Container>
  )
}

export default MemberDetailPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
`;