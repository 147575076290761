import moment from "moment";

export function makeUpWorkout(workoutData) {
  // 운동 데이터 정리 //
  for (let i = 0; i < workoutData.length; i++) {

    workoutData[i].sectionList.sort(function (a, b) {
      return a.sectionIndex < b.sectionIndex ? -1 : a.sectionIndex > b.sectionIndex ? 1 : 0;
    })
    
    if (workoutData[i].wodVersion === 2) {
      for (let j = 0; j < workoutData[i].sectionList.length; j++) {
        workoutData[i].sectionList[j].mainExerciseList = [];
        workoutData[i].sectionList[j].reps = workoutData[i].sectionList[j].reps.split('-');

        if (workoutData[i].sectionList[j].scoreKeyword === 'Not Scored') workoutData[i].sectionList[j].scoreKeyword = '점수 없음';
        else if (workoutData[i].sectionList[j].scoreKeyword === 'Time') workoutData[i].sectionList[j].scoreKeyword = '시간';
        else if (workoutData[i].sectionList[j].scoreKeyword === 'Reps') workoutData[i].sectionList[j].scoreKeyword = '렙수';
        else if (workoutData[i].sectionList[j].scoreKeyword === 'Rounds And Reps') workoutData[i].sectionList[j].scoreKeyword = '라운드 + 렙수';
        else if (workoutData[i].sectionList[j].scoreKeyword === 'Distance') workoutData[i].sectionList[j].scoreKeyword = '거리';
        else if (workoutData[i].sectionList[j].scoreKeyword === 'Weight') workoutData[i].sectionList[j].scoreKeyword = '무게';
        else if (workoutData[i].sectionList[j].scoreKeyword === 'Points') workoutData[i].sectionList[j].scoreKeyword = '포인트';
        else if (workoutData[i].sectionList[j].scoreKeyword === 'Peak Watts') workoutData[i].sectionList[j].scoreKeyword = '피크와트';
        else if (workoutData[i].sectionList[j].scoreKeyword === 'Max Speed') workoutData[i].sectionList[j].scoreKeyword = '최고 속도';
        
        if (workoutData[i].sectionList[j].scorePriority === 'heigher') workoutData[i].sectionList[j].scorePriority = '높을 수록';
        else if (workoutData[i].sectionList[j].scorePriority === 'lower') workoutData[i].sectionList[j].scorePriority = '낮을 수록';

        if (workoutData[i].sectionList[j].divisionRound !== undefined
          && workoutData[i].sectionList[j].divisionRound !== ''
          && workoutData[i].sectionList[j].divisionRound !== 'None') {
            workoutData[i].sectionList[j].round = workoutData[i].sectionList[j].divisionRound;
        }

        for (let h = 0; h < workoutData[i].sectionList[j].exerciseList.length; h++) {

          workoutData[i].sectionList[j].exerciseList[h].movementId = workoutData[i].sectionList[j].exerciseList[h].movement.movementId;
          workoutData[i].sectionList[j].exerciseList[h].movementName = workoutData[i].sectionList[j].exerciseList[h].movement.movementName;
          workoutData[i].sectionList[j].exerciseList[h].videoLinkId = workoutData[i].sectionList[j].exerciseList[h].movement.videoLinkId;

          // 무게 구분 데이터 사용할 경우 //
          if (workoutData[i].sectionList[j].exerciseList[h].divisionWeight !== ''
            && workoutData[i].sectionList[j].exerciseList[h].divisionWeight !== 'None') {
            workoutData[i].sectionList[j].exerciseList[h].weight = workoutData[i].sectionList[j].exerciseList[h].divisionWeight;
          }
          // 거리 구분 데이터 사용할 경우 //
          if (workoutData[i].sectionList[j].exerciseList[h].divisionDistance !== ''
            && workoutData[i].sectionList[j].exerciseList[h].divisionDistance !== 'None') {
            workoutData[i].sectionList[j].exerciseList[h].distance = workoutData[i].sectionList[j].exerciseList[h].divisionDistance;
          }
          // 높이 구분 데이터 사용할 경우 //
          if (workoutData[i].sectionList[j].exerciseList[h].divisionHeight !== ''
            && workoutData[i].sectionList[j].exerciseList[h].divisionHeight !== 'None') {
            workoutData[i].sectionList[j].exerciseList[h].height = workoutData[i].sectionList[j].exerciseList[h].divisionHeight;
          }
          // 칼로리 구분 데이터 사용할 경우 //
          if (workoutData[i].sectionList[j].exerciseList[h].divisionCalories !== ''
            && workoutData[i].sectionList[j].exerciseList[h].divisionCalories !== 'None') {
            workoutData[i].sectionList[j].exerciseList[h].calories = workoutData[i].sectionList[j].exerciseList[h].divisionCalories;
          }
          // 템포 구분 데이터 사용할 경우 //
          if (workoutData[i].sectionList[j].exerciseList[h].divisionTempo !== ''
            && workoutData[i].sectionList[j].exerciseList[h].divisionTempo !== 'None') {
            workoutData[i].sectionList[j].exerciseList[h].tempo = workoutData[i].sectionList[j].exerciseList[h].divisionTempo;
          }
          // Sets 구분 데이터 사용할 경우 //
          if (workoutData[i].sectionList[j].exerciseList[h].divisionSets !== ''
            && workoutData[i].sectionList[j].exerciseList[h].divisionSets !== 'None') {
            workoutData[i].sectionList[j].exerciseList[h].sets = workoutData[i].sectionList[j].exerciseList[h].divisionSets;
          }
          // Rest 구분 데이터 사용할 경우 //
          if (workoutData[i].sectionList[j].exerciseList[h].divisionRest !== ''
            && workoutData[i].sectionList[j].exerciseList[h].divisionRest !== 'None') {
            workoutData[i].sectionList[j].exerciseList[h].rest = workoutData[i].sectionList[j].exerciseList[h].divisionRest;
          }

          workoutData[i].sectionList[j].mainExerciseList.push(workoutData[i].sectionList[j].exerciseList[h]);
        }

        // exerciseIndex.asc 진행 //
        workoutData[i].sectionList[j].mainExerciseList.sort(function (a, b) {
          return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
        })

        if (workoutData[i].sectionList[j].derivationSectionId !== undefined) {
          for (let h = 0; h < workoutData[i].sectionList.length; h++) {
            if (workoutData[i].sectionList[h].sectionId === workoutData[i].sectionList[j].derivationSectionId) {
              if (workoutData[i].sectionList[h].scaleSections === undefined) workoutData[i].sectionList[h].scaleSections = [];

              workoutData[i].sectionList[h].scaleSections.push(workoutData[i].sectionList[j]);
            }
          }
        }
      }

      let newSections = [];

      for (let j = 0; j < workoutData[i].sectionList.length; j++) {
        if (workoutData[i].sectionList[j].derivationSectionId === undefined) {
          newSections.push(workoutData[i].sectionList[j]);
        }
      }

      for (let j = 0; j < workoutData[i].sectionList.length; j++) {
        if (workoutData[i].sectionList[j].scaleSections === undefined) workoutData[i].sectionList[j].scaleSections = [];
        if (workoutData[i].sectionList[j].youtubeLink !== '') workoutData[i].sectionList[j].isViewVideo = true;
        else workoutData[i].sectionList[j].isViewVideo = false;
      }

      workoutData[i].sectionList = newSections;
    }
    else {
      for (let j = 0; j < workoutData[i].sectionList.length; j++) {
        workoutData[i].sectionList[j].buyinExerciseList = [];
        workoutData[i].sectionList[j].buyinScaleOptionExerciseList = [];
        workoutData[i].sectionList[j].mainExerciseList = [];
        workoutData[i].sectionList[j].mainScaleOptionExerciseList = [];
        workoutData[i].sectionList[j].emomExerciseList = [];
        workoutData[i].sectionList[j].emomScaleOptionExerciseList = [];
        workoutData[i].sectionList[j].buyoutExerciseList = [];
        workoutData[i].sectionList[j].buyoutScaleOptionExerciseList = [];
        workoutData[i].sectionList[j].reps = workoutData[i].sectionList[j].reps.split('-');

        for (let h = 0; h < workoutData[i].sectionList[j].exerciseList.length; h++) {

          workoutData[i].sectionList[j].exerciseList[h].movementId = workoutData[i].sectionList[j].exerciseList[h].movement.movementId;
          workoutData[i].sectionList[j].exerciseList[h].movementName = workoutData[i].sectionList[j].exerciseList[h].movement.movementName;
          workoutData[i].sectionList[j].exerciseList[h].videoLinkId = workoutData[i].sectionList[j].exerciseList[h].movement.videoLinkId;

          // 무게 구분 데이터 사용할 경우 //
          if (workoutData[i].sectionList[j].exerciseList[h].divisionWeight !== ''
            && workoutData[i].sectionList[j].exerciseList[h].divisionWeight !== 'None') {
            workoutData[i].sectionList[j].exerciseList[h].weight = workoutData[i].sectionList[j].exerciseList[h].divisionWeight;
          }
          // 거리 구분 데이터 사용할 경우 //
          if (workoutData[i].sectionList[j].exerciseList[h].divisionDistance !== ''
            && workoutData[i].sectionList[j].exerciseList[h].divisionDistance !== 'None') {
            workoutData[i].sectionList[j].exerciseList[h].distance = workoutData[i].sectionList[j].exerciseList[h].divisionDistance;
          }
          // 높이 구분 데이터 사용할 경우 //
          if (workoutData[i].sectionList[j].exerciseList[h].divisionHeight !== ''
            && workoutData[i].sectionList[j].exerciseList[h].divisionHeight !== 'None') {
            workoutData[i].sectionList[j].exerciseList[h].height = workoutData[i].sectionList[j].exerciseList[h].divisionHeight;
          }
          // 칼로리 구분 데이터 사용할 경우 //
          if (workoutData[i].sectionList[j].exerciseList[h].divisionCalories !== ''
            && workoutData[i].sectionList[j].exerciseList[h].divisionCalories !== 'None') {
            workoutData[i].sectionList[j].exerciseList[h].calories = workoutData[i].sectionList[j].exerciseList[h].divisionCalories;
          }
          // 템포 구분 데이터 사용할 경우 //
          if (workoutData[i].sectionList[j].exerciseList[h].divisionTempo !== ''
            && workoutData[i].sectionList[j].exerciseList[h].divisionTempo !== 'None') {
            workoutData[i].sectionList[j].exerciseList[h].tempo = workoutData[i].sectionList[j].exerciseList[h].divisionTempo;
          }
          // Sets 구분 데이터 사용할 경우 //
          if (workoutData[i].sectionList[j].exerciseList[h].divisionSets !== ''
            && workoutData[i].sectionList[j].exerciseList[h].divisionSets !== 'None') {
            workoutData[i].sectionList[j].exerciseList[h].sets = workoutData[i].sectionList[j].exerciseList[h].divisionSets;
          }
          // Rest 구분 데이터 사용할 경우 //
          if (workoutData[i].sectionList[j].exerciseList[h].divisionRest !== ''
            && workoutData[i].sectionList[j].exerciseList[h].divisionRest !== 'None') {
            workoutData[i].sectionList[j].exerciseList[h].rest = workoutData[i].sectionList[j].exerciseList[h].divisionRest;
          }

          if (workoutData[i].sectionList[j].exerciseList[h].division === 'Buyin') {
            workoutData[i].sectionList[j].buyinExerciseList.push(workoutData[i].sectionList[j].exerciseList[h]);
          }
          else if (workoutData[i].sectionList[j].exerciseList[h].division === 'Buyin.Scale') {
            workoutData[i].sectionList[j].buyinScaleOptionExerciseList.push(workoutData[i].sectionList[j].exerciseList[h]);
          }
          else if (workoutData[i].sectionList[j].exerciseList[h].division === 'Main') {
            workoutData[i].sectionList[j].mainExerciseList.push(workoutData[i].sectionList[j].exerciseList[h]);
          }
          else if (workoutData[i].sectionList[j].exerciseList[h].division === 'Main.Scale') {
            workoutData[i].sectionList[j].mainScaleOptionExerciseList.push(workoutData[i].sectionList[j].exerciseList[h]);
          }
          else if (workoutData[i].sectionList[j].exerciseList[h].division === 'Main.Emom') {
            workoutData[i].sectionList[j].emomExerciseList.push(workoutData[i].sectionList[j].exerciseList[h]);
          }
          else if (workoutData[i].sectionList[j].exerciseList[h].division === 'Main.Emom.Scale') {
            workoutData[i].sectionList[j].emomScaleOptionExerciseList.push(workoutData[i].sectionList[j].exerciseList[h]);
          }
          else if (workoutData[i].sectionList[j].exerciseList[h].division === 'Buyout') {
            workoutData[i].sectionList[j].buyoutExerciseList.push(workoutData[i].sectionList[j].exerciseList[h]);
          }
          else if (workoutData[i].sectionList[j].exerciseList[h].division === 'Buyout.Scale') {
            workoutData[i].sectionList[j].buyoutScaleOptionExerciseList.push(workoutData[i].sectionList[j].exerciseList[h]);
          }
        }

        // exerciseIndex.asc 진행 //
        workoutData[i].sectionList[j].buyinExerciseList.sort(function (a, b) {
          return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
        })
        // exerciseIndex.asc 진행 //
        workoutData[i].sectionList[j].buyinScaleOptionExerciseList.sort(function (a, b) {
          return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
        })
        // exerciseIndex.asc 진행 //
        workoutData[i].sectionList[j].mainExerciseList.sort(function (a, b) {
          return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
        })
        // exerciseIndex.asc 진행 //
        workoutData[i].sectionList[j].mainScaleOptionExerciseList.sort(function (a, b) {
          return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
        })
        // exerciseIndex.asc 진행 //
        workoutData[i].sectionList[j].emomExerciseList.sort(function (a, b) {
          return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
        })
        // exerciseIndex.asc 진행 //
        workoutData[i].sectionList[j].emomScaleOptionExerciseList.sort(function (a, b) {
          return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
        })
        // exerciseIndex.asc 진행 //
        workoutData[i].sectionList[j].buyoutExerciseList.sort(function (a, b) {
          return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
        })
        // exerciseIndex.asc 진행 //
        workoutData[i].sectionList[j].buyoutScaleOptionExerciseList.sort(function (a, b) {
          return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
        })
      }
    }
  }

  return workoutData;
}

export function willSaveWorkout(sectionList) {

  for (let i = 0; i < sectionList.length; i++) {
    // '점수 없음', '시간', '횟수', '라운드 + 횟수', '거리', '무게', '포인트', '피크와트', '최고 속도' //
    if (sectionList[i].scoreKeyword === '점수 없음') sectionList[i].scoreKeyword = 'Not Scored';
    else if (sectionList[i].scoreKeyword === '시간') sectionList[i].scoreKeyword = 'Time';
    else if (sectionList[i].scoreKeyword === '렙수') sectionList[i].scoreKeyword = 'Reps';
    else if (sectionList[i].scoreKeyword === '라운드 + 렙수') sectionList[i].scoreKeyword = 'Rounds And Reps';
    else if (sectionList[i].scoreKeyword === '거리') sectionList[i].scoreKeyword = 'Distance';
    else if (sectionList[i].scoreKeyword === '무게') sectionList[i].scoreKeyword = 'Weight';
    else if (sectionList[i].scoreKeyword === '포인트') sectionList[i].scoreKeyword = 'Points';
    else if (sectionList[i].scoreKeyword === '피크와트') sectionList[i].scoreKeyword = 'Peak Watts';
    else if (sectionList[i].scoreKeyword === '최고 속도') sectionList[i].scoreKeyword = 'Max Speed';

    if (sectionList[i].scorePriority === '높을 수록') sectionList[i].scorePriority = 'heigher';
    else if (sectionList[i].scorePriority === '낮을 수록') sectionList[i].scorePriority = 'lower';
    
    // Section의 Round중 구분자가 들어간 경우 //
    if (typeof (sectionList[i].round) === 'string' 
      && (sectionList[i].round.includes('/')
        || sectionList[i].round.includes('~')
        || sectionList[i].round.includes('-')
        || sectionList[i].round.includes('@')
        || sectionList[i].round.includes('.'))) {
          sectionList[i].divisionRound = sectionList[i].round;
          sectionList[i].round = 0;
    }

    if (sectionList[i].buyinExerciseList !== undefined) {
      // Buyin 확인 //
      for (let j = 0; j < sectionList[i].buyinExerciseList.length; j++) {
        // Buyin 운동중 무게가 구분데이터를 사용하는지 확인 //
        if (typeof (sectionList[i].buyinExerciseList[j].weight) === 'string' && (sectionList[i].buyinExerciseList[j].weight.includes('/')
          || sectionList[i].buyinExerciseList[j].weight.includes('~')
          || sectionList[i].buyinExerciseList[j].weight.includes('-')
          || sectionList[i].buyinExerciseList[j].weight.includes('@')
          || sectionList[i].buyinExerciseList[j].weight.includes('.'))) {
          sectionList[i].buyinExerciseList[j].divisionWeight = sectionList[i].buyinExerciseList[j].weight;
          sectionList[i].buyinExerciseList[j].weight = 0;
        }
        // Buyin 운동중 거리가 구분데이터를 사용하는지 확인 //
        if (typeof (sectionList[i].buyinExerciseList[j].distance) === 'string' && (sectionList[i].buyinExerciseList[j].distance.includes('/')
          || sectionList[i].buyinExerciseList[j].distance.includes('~')
          || sectionList[i].buyinExerciseList[j].distance.includes('-')
          || sectionList[i].buyinExerciseList[j].distance.includes('@')
          || sectionList[i].buyinExerciseList[j].distance.includes('.'))) {
          sectionList[i].buyinExerciseList[j].divisionDistance = sectionList[i].buyinExerciseList[j].distance;
          sectionList[i].buyinExerciseList[j].distance = 0;
          // sectionList[i].buyinExerciseList[j].distance = parseFloat(sectionList[i].buyinExerciseList[j].distance.split('/')[0]);
        }
        // Buyin 운동중 높이가 구분데이터를 사용하는지 확인 //
        if (typeof (sectionList[i].buyinExerciseList[j].height) === 'string' && (sectionList[i].buyinExerciseList[j].height.includes('/')
          || sectionList[i].buyinExerciseList[j].height.includes('~')
          || sectionList[i].buyinExerciseList[j].height.includes('-')
          || sectionList[i].buyinExerciseList[j].height.includes('@')
          || sectionList[i].buyinExerciseList[j].height.includes('.'))) {
          sectionList[i].buyinExerciseList[j].divisionHeight = sectionList[i].buyinExerciseList[j].height;
          sectionList[i].buyinExerciseList[j].height = 0;
          // sectionList[i].buyinExerciseList[j].height = parseFloat(sectionList[i].buyinExerciseList[j].height.split('/')[0]);
        }
        // Buyin 운동중 칼로리가 구분데이터를 사용하는지 확인 //
        if (typeof (sectionList[i].buyinExerciseList[j].calories) === 'string' && (sectionList[i].buyinExerciseList[j].calories.includes('/')
          || sectionList[i].buyinExerciseList[j].calories.includes('~')
          || sectionList[i].buyinExerciseList[j].calories.includes('-')
          || sectionList[i].buyinExerciseList[j].calories.includes('@')
          || sectionList[i].buyinExerciseList[j].calories.includes('.'))) {
          sectionList[i].buyinExerciseList[j].divisionCalories = sectionList[i].buyinExerciseList[j].calories;
          sectionList[i].buyinExerciseList[j].calories = 0;
          // sectionList[i].buyinExerciseList[j].calories = parseFloat(sectionList[i].buyinExerciseList[j].calories.split('/')[0]);
        }
        // Buyin 운동중 템포가 구분데이터를 사용하는지 확인 //
        if (typeof (sectionList[i].buyinExerciseList[j].tempo) === 'string' && (sectionList[i].buyinExerciseList[j].tempo.includes('/')
          || sectionList[i].buyinExerciseList[j].tempo.includes('~')
          || sectionList[i].buyinExerciseList[j].tempo.includes('-')
          || sectionList[i].buyinExerciseList[j].tempo.includes('@')
          || sectionList[i].buyinExerciseList[j].tempo.includes('.'))) {
          sectionList[i].buyinExerciseList[j].divisionTempo = sectionList[i].buyinExerciseList[j].tempo;
          sectionList[i].buyinExerciseList[j].tempo = 0;
          // sectionList[i].buyinExerciseList[j].tempo = parseInt(sectionList[i].buyinExerciseList[j].tempo.split('/')[0]);
        }
      }
    }

    if (sectionList[i].buyinScaleOptionExerciseList !== undefined) {
      // Buyin Scale Option 확인 //
      for (let j = 0; j < sectionList[i].buyinScaleOptionExerciseList.length; j++) {
        // Buyin 운동중 무게가 구분데이터를 사용하는지 확인 //
        if (typeof (sectionList[i].buyinScaleOptionExerciseList[j].weight) === 'string' && (sectionList[i].buyinScaleOptionExerciseList[j].weight.includes('/')
          || sectionList[i].buyinScaleOptionExerciseList[j].weight.includes('~')
          || sectionList[i].buyinScaleOptionExerciseList[j].weight.includes('-')
          || sectionList[i].buyinScaleOptionExerciseList[j].weight.includes('@')
          || sectionList[i].buyinScaleOptionExerciseList[j].weight.includes('.'))) {
          sectionList[i].buyinScaleOptionExerciseList[j].divisionWeight = sectionList[i].buyinScaleOptionExerciseList[j].weight;
          sectionList[i].buyinScaleOptionExerciseList[j].weight = 0;
        }
        // Buyin 운동중 거리가 구분데이터를 사용하는지 확인 //
        if (typeof (sectionList[i].buyinScaleOptionExerciseList[j].distance) === 'string' && (sectionList[i].buyinScaleOptionExerciseList[j].distance.includes('/')
          || sectionList[i].buyinScaleOptionExerciseList[j].distance.includes('~')
          || sectionList[i].buyinScaleOptionExerciseList[j].distance.includes('-')
          || sectionList[i].buyinScaleOptionExerciseList[j].distance.includes('@')
          || sectionList[i].buyinScaleOptionExerciseList[j].distance.includes('.'))) {
          sectionList[i].buyinScaleOptionExerciseList[j].divisionDistance = sectionList[i].buyinScaleOptionExerciseList[j].distance;
          sectionList[i].buyinScaleOptionExerciseList[j].distance = 0;
          // sectionList[i].buyinScaleOptionExerciseList[j].distance = parseFloat(sectionList[i].buyinScaleOptionExerciseList[j].distance.split('/')[0]);
        }
        // Buyin 운동중 높이가 구분데이터를 사용하는지 확인 //
        if (typeof (sectionList[i].buyinScaleOptionExerciseList[j].height) === 'string' && (sectionList[i].buyinScaleOptionExerciseList[j].height.includes('/')
          || sectionList[i].buyinScaleOptionExerciseList[j].height.includes('~')
          || sectionList[i].buyinScaleOptionExerciseList[j].height.includes('-')
          || sectionList[i].buyinScaleOptionExerciseList[j].height.includes('@')
          || sectionList[i].buyinScaleOptionExerciseList[j].height.includes('.'))) {
          sectionList[i].buyinScaleOptionExerciseList[j].divisionHeight = sectionList[i].buyinScaleOptionExerciseList[j].height;
          sectionList[i].buyinScaleOptionExerciseList[j].height = 0;
          // sectionList[i].buyinScaleOptionExerciseList[j].height = parseFloat(sectionList[i].buyinScaleOptionExerciseList[j].height.split('/')[0]);
        }
        // Buyin 운동중 칼로리가 구분데이터를 사용하는지 확인 //
        if (typeof (sectionList[i].buyinScaleOptionExerciseList[j].calories) === 'string' && (sectionList[i].buyinScaleOptionExerciseList[j].calories.includes('/')
          || sectionList[i].buyinScaleOptionExerciseList[j].calories.includes('~')
          || sectionList[i].buyinScaleOptionExerciseList[j].calories.includes('-')
          || sectionList[i].buyinScaleOptionExerciseList[j].calories.includes('@')
          || sectionList[i].buyinScaleOptionExerciseList[j].calories.includes('.'))) {
          sectionList[i].buyinScaleOptionExerciseList[j].divisionCalories = sectionList[i].buyinScaleOptionExerciseList[j].calories;
          sectionList[i].buyinScaleOptionExerciseList[j].calories = 0;
          // sectionList[i].buyinScaleOptionExerciseList[j].calories = parseFloat(sectionList[i].buyinScaleOptionExerciseList[j].calories.split('/')[0]);
        }
        // Buyin 운동중 템포가 구분데이터를 사용하는지 확인 //
        if (typeof (sectionList[i].buyinScaleOptionExerciseList[j].tempo) === 'string' && (sectionList[i].buyinScaleOptionExerciseList[j].tempo.includes('/')
          || sectionList[i].buyinScaleOptionExerciseList[j].tempo.includes('~')
          || sectionList[i].buyinScaleOptionExerciseList[j].tempo.includes('-')
          || sectionList[i].buyinScaleOptionExerciseList[j].tempo.includes('@')
          || sectionList[i].buyinScaleOptionExerciseList[j].tempo.includes('.'))) {
          sectionList[i].buyinScaleOptionExerciseList[j].divisionTempo = sectionList[i].buyinScaleOptionExerciseList[j].tempo;
          sectionList[i].buyinScaleOptionExerciseList[j].tempo = 0;
          // sectionList[i].buyinScaleOptionExerciseList[j].tempo = parseInt(sectionList[i].buyinScaleOptionExerciseList[j].tempo.split('/')[0]);
        }
      }
    }

    // EMOM인 경우 데이터 따로 구성을 위해 검사 //
    if (sectionList[i].tag === "EMOM") {

      if (sectionList[i].emomExerciseList !== undefined) {
        // EMOM 확인 //
        for (let j = 0; j < sectionList[i].emomExerciseList.length; j++) {
          // 메인 운동중 무게가 구분데이터를 사용하는지 확인 //
          if (typeof (sectionList[i].emomExerciseList[j].weight) === 'string' && (sectionList[i].emomExerciseList[j].weight.includes('/')
            || sectionList[i].emomExerciseList[j].weight.includes('~')
            || sectionList[i].emomExerciseList[j].weight.includes('-')
            || sectionList[i].emomExerciseList[j].weight.includes('@')
            || sectionList[i].emomExerciseList[j].weight.includes('.'))) {
            sectionList[i].emomExerciseList[j].divisionWeight = sectionList[i].emomExerciseList[j].weight;
            sectionList[i].emomExerciseList[j].weight = 0;
          }
          // 메인 운동중 거리가 구분데이터를 사용하는지 확인 //
          if (typeof (sectionList[i].emomExerciseList[j].distance) === 'string' && (sectionList[i].emomExerciseList[j].distance.includes('/')
            || sectionList[i].emomExerciseList[j].distance.includes('~')
            || sectionList[i].emomExerciseList[j].distance.includes('-')
            || sectionList[i].emomExerciseList[j].distance.includes('@')
            || sectionList[i].emomExerciseList[j].distance.includes('.'))) {
            sectionList[i].emomExerciseList[j].divisionDistance = sectionList[i].emomExerciseList[j].distance;
            sectionList[i].emomExerciseList[j].distance = 0;
            // sectionList[i].emomExerciseList[j].distance = parseFloat(sectionList[i].emomExerciseList[j].distance.split('/')[0]);
          }
          // 메인 운동중 높이가 구분데이터를 사용하는지 확인 //
          if (typeof (sectionList[i].emomExerciseList[j].height) === 'string' && (sectionList[i].emomExerciseList[j].height.includes('/')
            || sectionList[i].emomExerciseList[j].height.includes('~')
            || sectionList[i].emomExerciseList[j].height.includes('-')
            || sectionList[i].emomExerciseList[j].height.includes('@')
            || sectionList[i].emomExerciseList[j].height.includes('.'))) {
            sectionList[i].emomExerciseList[j].divisionHeight = sectionList[i].emomExerciseList[j].height;
            sectionList[i].emomExerciseList[j].height = 0;
            // sectionList[i].emomExerciseList[j].height = parseFloat(sectionList[i].emomExerciseList[j].height.split('/')[0]);
          }
          // 메인 운동중 칼로리가 구분데이터를 사용하는지 확인 //
          if (typeof (sectionList[i].emomExerciseList[j].calories) === 'string' && (sectionList[i].emomExerciseList[j].calories.includes('/')
            || sectionList[i].emomExerciseList[j].calories.includes('~')
            || sectionList[i].emomExerciseList[j].calories.includes('-')
            || sectionList[i].emomExerciseList[j].calories.includes('@')
            || sectionList[i].emomExerciseList[j].calories.includes('.'))) {
            sectionList[i].emomExerciseList[j].divisionCalories = sectionList[i].emomExerciseList[j].calories;
            sectionList[i].emomExerciseList[j].calories = 0;
            // sectionList[i].emomExerciseList[j].calories = parseFloat(sectionList[i].emomExerciseList[j].calories.split('/')[0]);
          }
          // 메인 운동중 템포가 구분데이터를 사용하는지 확인 //
          if (typeof (sectionList[i].emomExerciseList[j].tempo) === 'string' && (sectionList[i].emomExerciseList[j].tempo.includes('/')
            || sectionList[i].emomExerciseList[j].tempo.includes('~')
            || sectionList[i].emomExerciseList[j].tempo.includes('-')
            || sectionList[i].emomExerciseList[j].tempo.includes('@')
            || sectionList[i].emomExerciseList[j].tempo.includes('.'))) {
            sectionList[i].emomExerciseList[j].divisionTempo = sectionList[i].emomExerciseList[j].tempo;
            sectionList[i].emomExerciseList[j].tempo = 0;
            // sectionList[i].emomExerciseList[j].tempo = parseInt(sectionList[i].emomExerciseList[j].tempo.split('/')[0]);
          }
        }
      }

      if (sectionList[i].emomScaleOptionExerciseList !== undefined) {
        // EMOM Scale Option 확인 //
        for (let j = 0; j < sectionList[i].emomScaleOptionExerciseList.length; j++) {
          // 메인 운동중 무게가 구분데이터를 사용하는지 확인 //
          if (typeof (sectionList[i].emomScaleOptionExerciseList[j].weight) === 'string' && (sectionList[i].emomScaleOptionExerciseList[j].weight.includes('/')
            || sectionList[i].emomScaleOptionExerciseList[j].weight.includes('~')
            || sectionList[i].emomScaleOptionExerciseList[j].weight.includes('-')
            || sectionList[i].emomScaleOptionExerciseList[j].weight.includes('@')
            || sectionList[i].emomScaleOptionExerciseList[j].weight.includes('.'))) {
            sectionList[i].emomScaleOptionExerciseList[j].divisionWeight = sectionList[i].emomScaleOptionExerciseList[j].weight;
            sectionList[i].emomScaleOptionExerciseList[j].weight = 0;
          }
          // 메인 운동중 거리가 구분데이터를 사용하는지 확인 //
          if (typeof (sectionList[i].emomScaleOptionExerciseList[j].distance) === 'string' && (sectionList[i].emomScaleOptionExerciseList[j].distance.includes('/')
            || sectionList[i].emomScaleOptionExerciseList[j].distance.includes('~')
            || sectionList[i].emomScaleOptionExerciseList[j].distance.includes('-')
            || sectionList[i].emomScaleOptionExerciseList[j].distance.includes('@')
            || sectionList[i].emomScaleOptionExerciseList[j].distance.includes('.'))) {
            sectionList[i].emomScaleOptionExerciseList[j].divisionDistance = sectionList[i].emomScaleOptionExerciseList[j].distance;
            sectionList[i].emomScaleOptionExerciseList[j].distance = 0;
          }
          // 메인 운동중 높이가 구분데이터를 사용하는지 확인 //
          if (typeof (sectionList[i].emomScaleOptionExerciseList[j].height) === 'string' && (sectionList[i].emomScaleOptionExerciseList[j].height.includes('/')
            || sectionList[i].emomScaleOptionExerciseList[j].height.includes('~')
            || sectionList[i].emomScaleOptionExerciseList[j].height.includes('-')
            || sectionList[i].emomScaleOptionExerciseList[j].height.includes('@')
            || sectionList[i].emomScaleOptionExerciseList[j].height.includes('.'))) {
            sectionList[i].emomScaleOptionExerciseList[j].divisionHeight = sectionList[i].emomScaleOptionExerciseList[j].height;
            sectionList[i].emomScaleOptionExerciseList[j].height = 0;
            // sectionList[i].emomScaleOptionExerciseList[j].height = parseFloat(sectionList[i].emomScaleOptionExerciseList[j].height.split('/')[0]);
          }
          // 메인 운동중 칼로리가 구분데이터를 사용하는지 확인 //
          if (typeof (sectionList[i].emomScaleOptionExerciseList[j].calories) === 'string' && (sectionList[i].emomScaleOptionExerciseList[j].calories.includes('/')
            || sectionList[i].emomScaleOptionExerciseList[j].calories.includes('~')
            || sectionList[i].emomScaleOptionExerciseList[j].calories.includes('-')
            || sectionList[i].emomScaleOptionExerciseList[j].calories.includes('@')
            || sectionList[i].emomScaleOptionExerciseList[j].calories.includes('.'))) {
            sectionList[i].emomScaleOptionExerciseList[j].divisionCalories = sectionList[i].emomScaleOptionExerciseList[j].calories;
            sectionList[i].emomScaleOptionExerciseList[j].calories = 0;
            // sectionList[i].emomScaleOptionExerciseList[j].calories = parseFloat(sectionList[i].emomScaleOptionExerciseList[j].calories.split('/')[0]);
          }
          // 메인 운동중 템포가 구분데이터를 사용하는지 확인 //
          if (typeof (sectionList[i].emomScaleOptionExerciseList[j].tempo) === 'string' && (sectionList[i].emomScaleOptionExerciseList[j].tempo.includes('/')
            || sectionList[i].emomScaleOptionExerciseList[j].tempo.includes('~')
            || sectionList[i].emomScaleOptionExerciseList[j].tempo.includes('-')
            || sectionList[i].emomScaleOptionExerciseList[j].tempo.includes('@')
            || sectionList[i].emomScaleOptionExerciseList[j].tempo.includes('.'))) {
            sectionList[i].emomScaleOptionExerciseList[j].divisionTempo = sectionList[i].emomScaleOptionExerciseList[j].tempo;
            sectionList[i].emomScaleOptionExerciseList[j].tempo = 0;
            // sectionList[i].emomScaleOptionExerciseList[j].tempo = parseInt(sectionList[i].emomScaleOptionExerciseList[j].tempo.split('/')[0]);
          }
        }
      }

      // EMOM에서 다루는 Main 데이터 수정 //
      // Main 확인 //
      for (let j = 0; j < sectionList[i].mainExerciseList.length; j++) {
        // 메인 운동중 무게가 구분데이터를 사용하는지 확인 //
        if (typeof (sectionList[i].mainExerciseList[j].weight) === 'string' && (sectionList[i].mainExerciseList[j].weight.includes('/')
          || sectionList[i].mainExerciseList[j].weight.includes('~')
          || sectionList[i].mainExerciseList[j].weight.includes('-')
          || sectionList[i].mainExerciseList[j].weight.includes('@')
          || sectionList[i].mainExerciseList[j].weight.includes('.'))) {
          sectionList[i].mainExerciseList[j].divisionWeight = sectionList[i].mainExerciseList[j].weight;
          sectionList[i].mainExerciseList[j].weight = 0;
        }
        // 메인 운동중 거리가 구분데이터를 사용하는지 확인 //
        if (typeof (sectionList[i].mainExerciseList[j].distance) === 'string' && (sectionList[i].mainExerciseList[j].distance.includes('/')
          || sectionList[i].mainExerciseList[j].distance.includes('~')
          || sectionList[i].mainExerciseList[j].distance.includes('-')
          || sectionList[i].mainExerciseList[j].distance.includes('@')
          || sectionList[i].mainExerciseList[j].distance.includes('.'))) {
          sectionList[i].mainExerciseList[j].divisionDistance = sectionList[i].mainExerciseList[j].distance;
          sectionList[i].mainExerciseList[j].distance = 0;
          // sectionList[i].mainExerciseList[j].distance = parseFloat(sectionList[i].mainExerciseList[j].distance.split('/')[0]);
        }
        // 메인 운동중 높이가 구분데이터를 사용하는지 확인 //
        if (typeof (sectionList[i].mainExerciseList[j].height) === 'string' && (sectionList[i].mainExerciseList[j].height.includes('/')
          || sectionList[i].mainExerciseList[j].height.includes('~')
          || sectionList[i].mainExerciseList[j].height.includes('-')
          || sectionList[i].mainExerciseList[j].height.includes('@')
          || sectionList[i].mainExerciseList[j].height.includes('.'))) {
          sectionList[i].mainExerciseList[j].divisionHeight = sectionList[i].mainExerciseList[j].height;
          sectionList[i].mainExerciseList[j].height = 0;
          // sectionList[i].mainExerciseList[j].height = parseFloat(sectionList[i].mainExerciseList[j].height.split('/')[0]);
        }
        // 메인 운동중 칼로리가 구분데이터를 사용하는지 확인 //
        if (typeof (sectionList[i].mainExerciseList[j].calories) === 'string' && (sectionList[i].mainExerciseList[j].calories.includes('/')
          || sectionList[i].mainExerciseList[j].calories.includes('~')
          || sectionList[i].mainExerciseList[j].calories.includes('-')
          || sectionList[i].mainExerciseList[j].calories.includes('@')
          || sectionList[i].mainExerciseList[j].calories.includes('.'))) {
          sectionList[i].mainExerciseList[j].divisionCalories = sectionList[i].mainExerciseList[j].calories;
          sectionList[i].mainExerciseList[j].calories = 0;
          // sectionList[i].mainExerciseList[j].calories = parseFloat(sectionList[i].mainExerciseList[j].calories.split('/')[0]);
        }
        // 메인 운동중 템포가 구분데이터를 사용하는지 확인 //
        if (typeof (sectionList[i].mainExerciseList[j].tempo) === 'string' && (sectionList[i].mainExerciseList[j].tempo.includes('/')
          || sectionList[i].mainExerciseList[j].tempo.includes('~')
          || sectionList[i].mainExerciseList[j].tempo.includes('-')
          || sectionList[i].mainExerciseList[j].tempo.includes('@')
          || sectionList[i].mainExerciseList[j].tempo.includes('.'))) {
          sectionList[i].mainExerciseList[j].divisionTempo = sectionList[i].mainExerciseList[j].tempo;
          sectionList[i].mainExerciseList[j].tempo = 0;
          // sectionList[i].mainExerciseList[j].tempo = parseInt(sectionList[i].mainExerciseList[j].tempo.split('/')[0]);
        }
        // 메인 운동중 Single일 경우만 해당 Sets가 구분데이터를 사용하는지 확인 //
        if (typeof (sectionList[i].mainExerciseList[j].sets) === 'string' && (sectionList[i].mainExerciseList[j].sets.includes('/')
          || sectionList[i].mainExerciseList[j].sets.includes('~')
          || sectionList[i].mainExerciseList[j].sets.includes('-')
          || sectionList[i].mainExerciseList[j].sets.includes('@')
          || sectionList[i].mainExerciseList[j].sets.includes('.'))) {
          sectionList[i].mainExerciseList[j].divisionSets = sectionList[i].mainExerciseList[j].sets;
          sectionList[i].mainExerciseList[j].sets = 0;
          // sectionList[i].mainExerciseList[j].sets = parseInt(sectionList[i].mainExerciseList[j].sets.split('/')[0]);
        }
        // 메인 운동중 Single일 경우만 해당 Rest가 구분데이터를 사용하는지 확인 //
        if (typeof (sectionList[i].mainExerciseList[j].rest) === 'string' && (sectionList[i].mainExerciseList[j].rest.includes('/')
          || sectionList[i].mainExerciseList[j].rest.includes('~')
          || sectionList[i].mainExerciseList[j].rest.includes('-')
          || sectionList[i].mainExerciseList[j].rest.includes('@')
          || sectionList[i].mainExerciseList[j].rest.includes('.'))) {
          sectionList[i].mainExerciseList[j].divisionRest = sectionList[i].mainExerciseList[j].rest;
          sectionList[i].mainExerciseList[j].rest = 0;
          // sectionList[i].mainExerciseList[j].rest = parseInt(sectionList[i].mainExerciseList[j].rest.split('/')[0]);
        }
      }

      // Scale Section 데이터 정리 //
      if (sectionList[i].scaleSections !== undefined) {
        for (let j = 0; j < sectionList[i].scaleSections.length; j++) {

          // '점수 없음', '시간', '횟수', '라운드 + 횟수', '거리', '무게', '포인트', '피크와트', '최고 속도' //
          if (sectionList[i].scaleSections[j].scoreKeyword === '점수 없음') sectionList[i].scaleSections[j].scoreKeyword = 'Not Scored';
          else if (sectionList[i].scaleSections[j].scoreKeyword === '시간') sectionList[i].scaleSections[j].scoreKeyword = 'Time';
          else if (sectionList[i].scaleSections[j].scoreKeyword === '렙수') sectionList[i].scaleSections[j].scoreKeyword = 'Reps';
          else if (sectionList[i].scaleSections[j].scoreKeyword === '라운드 + 렙수') sectionList[i].scaleSections[j].scoreKeyword = 'Rounds And Reps';
          else if (sectionList[i].scaleSections[j].scoreKeyword === '거리') sectionList[i].scaleSections[j].scoreKeyword = 'Distance';
          else if (sectionList[i].scaleSections[j].scoreKeyword === '무게') sectionList[i].scaleSections[j].scoreKeyword = 'Weight';
          else if (sectionList[i].scaleSections[j].scoreKeyword === '포인트') sectionList[i].scaleSections[j].scoreKeyword = 'Points';
          else if (sectionList[i].scaleSections[j].scoreKeyword === '피크와트') sectionList[i].scaleSections[j].scoreKeyword = 'Peak Watts';
          else if (sectionList[i].scaleSections[j].scoreKeyword === '최고 속도') sectionList[i].scaleSections[j].scoreKeyword = 'Max Speed';

          if (sectionList[i].scaleSections[j].scorePriority === '높을 수록') sectionList[i].scaleSections[j].scorePriority = 'heigher';
          else if (sectionList[i].scaleSections[j].scorePriority === '낮을 수록') sectionList[i].scaleSections[j].scorePriority = 'lower';

          if (typeof (sectionList[i].scaleSections[j].round) === 'string'
            && (sectionList[i].scaleSections[j].round.includes('/')
              || sectionList[i].scaleSections[j].round.includes('~')
              || sectionList[i].scaleSections[j].round.includes('-')
              || sectionList[i].scaleSections[j].round.includes('@')
              || sectionList[i].scaleSections[j].round.includes('.'))) {
            sectionList[i].scaleSections[j].divisionRound = sectionList[i].scaleSections[j].round;
            sectionList[i].scaleSections[j].round = 0;
          }

          for (let h = 0; h < sectionList[i].scaleSections[j].mainExerciseList.length; h++) {
            // 메인 운동중 무게가 구분데이터를 사용하는지 확인 //
            if (typeof (sectionList[i].scaleSections[j].mainExerciseList[h].weight) === 'string' && (sectionList[i].scaleSections[j].mainExerciseList[h].weight.includes('/')
              || sectionList[i].scaleSections[j].mainExerciseList[h].weight.includes('~')
              || sectionList[i].scaleSections[j].mainExerciseList[h].weight.includes('-')
              || sectionList[i].scaleSections[j].mainExerciseList[h].weight.includes('@')
              || sectionList[i].scaleSections[j].mainExerciseList[h].weight.includes('.'))) {
              sectionList[i].scaleSections[j].mainExerciseList[h].divisionWeight = sectionList[i].scaleSections[j].mainExerciseList[h].weight;
              sectionList[i].scaleSections[j].mainExerciseList[h].weight = 0;
            }
            // 메인 운동중 거리가 구분데이터를 사용하는지 확인 //
            if (typeof (sectionList[i].scaleSections[j].mainExerciseList[h].distance) === 'string' && (sectionList[i].scaleSections[j].mainExerciseList[h].distance.includes('/')
              || sectionList[i].scaleSections[j].mainExerciseList[h].distance.includes('~')
              || sectionList[i].scaleSections[j].mainExerciseList[h].distance.includes('-')
              || sectionList[i].scaleSections[j].mainExerciseList[h].distance.includes('@')
              || sectionList[i].scaleSections[j].mainExerciseList[h].distance.includes('.'))) {
              sectionList[i].scaleSections[j].mainExerciseList[h].divisionDistance = sectionList[i].scaleSections[j].mainExerciseList[h].distance;
              sectionList[i].scaleSections[j].mainExerciseList[h].distance = 0;
            }
            // 메인 운동중 높이가 구분데이터를 사용하는지 확인 //
            if (typeof (sectionList[i].scaleSections[j].mainExerciseList[h].height) === 'string' && (sectionList[i].scaleSections[j].mainExerciseList[h].height.includes('/')
              || sectionList[i].scaleSections[j].mainExerciseList[h].height.includes('~')
              || sectionList[i].scaleSections[j].mainExerciseList[h].height.includes('-')
              || sectionList[i].scaleSections[j].mainExerciseList[h].height.includes('@')
              || sectionList[i].scaleSections[j].mainExerciseList[h].height.includes('.'))) {
              sectionList[i].scaleSections[j].mainExerciseList[h].divisionHeight = sectionList[i].scaleSections[j].mainExerciseList[h].height;
              sectionList[i].scaleSections[j].mainExerciseList[h].height = 0;
            }
            // 메인 운동중 칼로리가 구분데이터를 사용하는지 확인 //
            if (typeof (sectionList[i].scaleSections[j].mainExerciseList[h].calories) === 'string' && (sectionList[i].scaleSections[j].mainExerciseList[h].calories.includes('/')
              || sectionList[i].scaleSections[j].mainExerciseList[h].calories.includes('~')
              || sectionList[i].scaleSections[j].mainExerciseList[h].calories.includes('-')
              || sectionList[i].scaleSections[j].mainExerciseList[h].calories.includes('@')
              || sectionList[i].scaleSections[j].mainExerciseList[h].calories.includes('.'))) {
              sectionList[i].scaleSections[j].mainExerciseList[h].divisionCalories = sectionList[i].scaleSections[j].mainExerciseList[h].calories;
              sectionList[i].scaleSections[j].mainExerciseList[h].calories = 0;
            }
            // 메인 운동중 템포가 구분데이터를 사용하는지 확인 //
            if (typeof (sectionList[i].scaleSections[j].mainExerciseList[h].tempo) === 'string' && (sectionList[i].scaleSections[j].mainExerciseList[h].tempo.includes('/')
              || sectionList[i].scaleSections[j].mainExerciseList[h].tempo.includes('~')
              || sectionList[i].scaleSections[j].mainExerciseList[h].tempo.includes('-')
              || sectionList[i].scaleSections[j].mainExerciseList[h].tempo.includes('@')
              || sectionList[i].scaleSections[j].mainExerciseList[h].tempo.includes('.'))) {
              sectionList[i].scaleSections[j].mainExerciseList[h].divisionTempo = sectionList[i].scaleSections[j].mainExerciseList[h].tempo;
              sectionList[i].scaleSections[j].mainExerciseList[h].tempo = 0;
            }
            // 메인 운동중 Single일 경우만 해당 Sets가 구분데이터를 사용하는지 확인 //
            if (typeof (sectionList[i].scaleSections[j].mainExerciseList[h].sets) === 'string' && (sectionList[i].scaleSections[j].mainExerciseList[h].sets.includes('/')
              || sectionList[i].scaleSections[j].mainExerciseList[h].sets.includes('~')
              || sectionList[i].scaleSections[j].mainExerciseList[h].sets.includes('-')
              || sectionList[i].scaleSections[j].mainExerciseList[h].sets.includes('@')
              || sectionList[i].scaleSections[j].mainExerciseList[h].sets.includes('.'))) {
              sectionList[i].scaleSections[j].mainExerciseList[h].divisionSets = sectionList[i].scaleSections[j].mainExerciseList[h].sets;
              sectionList[i].scaleSections[j].mainExerciseList[h].sets = 0;
            }
            // 메인 운동중 Single일 경우만 해당 Rest가 구분데이터를 사용하는지 확인 //
            if (typeof (sectionList[i].scaleSections[j].mainExerciseList[h].rest) === 'string' && (sectionList[i].scaleSections[j].mainExerciseList[h].rest.includes('/')
              || sectionList[i].scaleSections[j].mainExerciseList[h].rest.includes('~')
              || sectionList[i].scaleSections[j].mainExerciseList[h].rest.includes('-')
              || sectionList[i].scaleSections[j].mainExerciseList[h].rest.includes('@')
              || sectionList[i].scaleSections[j].mainExerciseList[h].rest.includes('.'))) {
              sectionList[i].scaleSections[j].mainExerciseList[h].divisionRest = sectionList[i].scaleSections[j].mainExerciseList[h].rest;
              sectionList[i].scaleSections[j].mainExerciseList[h].rest = 0;
            }
          }
        }
      }


      if (sectionList[i].mainScaleOptionExerciseList !== undefined) {
        // Main Scale Option 확인 //
        for (let j = 0; j < sectionList[i].mainScaleOptionExerciseList.length; j++) {
          // 메인 운동중 무게가 구분데이터를 사용하는지 확인 //
          if (typeof (sectionList[i].mainScaleOptionExerciseList[j].weight) === 'string' && (sectionList[i].mainScaleOptionExerciseList[j].weight.includes('/')
            || sectionList[i].mainScaleOptionExerciseList[j].weight.includes('~')
            || sectionList[i].mainScaleOptionExerciseList[j].weight.includes('-')
            || sectionList[i].mainScaleOptionExerciseList[j].weight.includes('@')
            || sectionList[i].mainScaleOptionExerciseList[j].weight.includes('.'))) {
            sectionList[i].mainScaleOptionExerciseList[j].divisionWeight = sectionList[i].mainScaleOptionExerciseList[j].weight;
            sectionList[i].mainScaleOptionExerciseList[j].weight = 0;
          }
          // 메인 운동중 거리가 구분데이터를 사용하는지 확인 //
          if (typeof (sectionList[i].mainScaleOptionExerciseList[j].distance) === 'string' && (sectionList[i].mainScaleOptionExerciseList[j].distance.includes('/')
            || sectionList[i].mainScaleOptionExerciseList[j].distance.includes('~')
            || sectionList[i].mainScaleOptionExerciseList[j].distance.includes('-')
            || sectionList[i].mainScaleOptionExerciseList[j].distance.includes('@')
            || sectionList[i].mainScaleOptionExerciseList[j].distance.includes('.'))) {
            sectionList[i].mainScaleOptionExerciseList[j].divisionDistance = sectionList[i].mainScaleOptionExerciseList[j].distance;
            sectionList[i].mainScaleOptionExerciseList[j].distance = 0
            // sectionList[i].mainScaleOptionExerciseList[j].distance = parseFloat(sectionList[i].mainScaleOptionExerciseList[j].distance.split('/')[0]);
          }
          // 메인 운동중 높이가 구분데이터를 사용하는지 확인 //
          if (typeof (sectionList[i].mainScaleOptionExerciseList[j].height) === 'string' && (sectionList[i].mainScaleOptionExerciseList[j].height.includes('/')
            || sectionList[i].mainScaleOptionExerciseList[j].height.includes('~')
            || sectionList[i].mainScaleOptionExerciseList[j].height.includes('-')
            || sectionList[i].mainScaleOptionExerciseList[j].height.includes('@')
            || sectionList[i].mainScaleOptionExerciseList[j].height.includes('.'))) {
            sectionList[i].mainScaleOptionExerciseList[j].divisionHeight = sectionList[i].mainScaleOptionExerciseList[j].height;
            sectionList[i].mainScaleOptionExerciseList[j].height = 0;
            // sectionList[i].mainScaleOptionExerciseList[j].height = parseFloat(sectionList[i].mainScaleOptionExerciseList[j].height.split('/')[0]);
          }
          // 메인 운동중 칼로리가 구분데이터를 사용하는지 확인 //
          if (typeof (sectionList[i].mainScaleOptionExerciseList[j].calories) === 'string' && (sectionList[i].mainScaleOptionExerciseList[j].calories.includes('/')
            || sectionList[i].mainScaleOptionExerciseList[j].calories.includes('~')
            || sectionList[i].mainScaleOptionExerciseList[j].calories.includes('-')
            || sectionList[i].mainScaleOptionExerciseList[j].calories.includes('@')
            || sectionList[i].mainScaleOptionExerciseList[j].calories.includes('.'))) {
            sectionList[i].mainScaleOptionExerciseList[j].divisionCalories = sectionList[i].mainScaleOptionExerciseList[j].calories;
            sectionList[i].mainScaleOptionExerciseList[j].calories = 0;
            // sectionList[i].mainScaleOptionExerciseList[j].calories = parseFloat(sectionList[i].mainScaleOptionExerciseList[j].calories.split('/')[0]);
          }
          // 메인 운동중 템포가 구분데이터를 사용하는지 확인 //
          if (typeof (sectionList[i].mainScaleOptionExerciseList[j].tempo) === 'string' && (sectionList[i].mainScaleOptionExerciseList[j].tempo.includes('/')
            || sectionList[i].mainScaleOptionExerciseList[j].tempo.includes('~')
            || sectionList[i].mainScaleOptionExerciseList[j].tempo.includes('-')
            || sectionList[i].mainScaleOptionExerciseList[j].tempo.includes('@')
            || sectionList[i].mainScaleOptionExerciseList[j].tempo.includes('.'))) {
            sectionList[i].mainScaleOptionExerciseList[j].divisionTempo = sectionList[i].mainScaleOptionExerciseList[j].tempo;
            sectionList[i].mainScaleOptionExerciseList[j].tempo = 0;
            // sectionList[i].mainScaleOptionExerciseList[j].tempo = parseInt(sectionList[i].mainScaleOptionExerciseList[j].tempo.split('/')[0]);
          }
          // 메인 운동중 Single일 경우만 해당 Sets가 구분데이터를 사용하는지 확인 //
          if (typeof (sectionList[i].mainScaleOptionExerciseList[j].sets) === 'string' && (sectionList[i].mainScaleOptionExerciseList[j].sets.includes('/')
            || sectionList[i].mainScaleOptionExerciseList[j].sets.includes('~')
            || sectionList[i].mainScaleOptionExerciseList[j].sets.includes('-')
            || sectionList[i].mainScaleOptionExerciseList[j].sets.includes('@')
            || sectionList[i].mainScaleOptionExerciseList[j].sets.includes('.'))) {
            sectionList[i].mainScaleOptionExerciseList[j].divisionSets = sectionList[i].mainScaleOptionExerciseList[j].sets;
            sectionList[i].mainScaleOptionExerciseList[j].sets = 0;
            // sectionList[i].mainScaleOptionExerciseList[j].sets = parseInt(sectionList[i].mainScaleOptionExerciseList[j].sets.split('/')[0]);
          }
          // 메인 운동중 Single일 경우만 해당 Rest가 구분데이터를 사용하는지 확인 //
          if (typeof (sectionList[i].mainScaleOptionExerciseList[j].rest) === 'string' && (sectionList[i].mainScaleOptionExerciseList[j].rest.includes('/')
            || sectionList[i].mainScaleOptionExerciseList[j].rest.includes('~')
            || sectionList[i].mainScaleOptionExerciseList[j].rest.includes('-')
            || sectionList[i].mainScaleOptionExerciseList[j].rest.includes('@')
            || sectionList[i].mainScaleOptionExerciseList[j].rest.includes('.'))) {
            sectionList[i].mainScaleOptionExerciseList[j].divisionRest = sectionList[i].mainScaleOptionExerciseList[j].rest;
            sectionList[i].mainScaleOptionExerciseList[j].rest = 0;
            // sectionList[i].mainExerciseList[j].rest = parseInt(sectionList[i].mainExerciseList[j].rest.split('/')[0]);
          }
        }
      }
      // EMOM에서 다루는 Main 데이터 수정 //
    }
    // 그 외의 경우 //
    else {
      // Main 확인 //
      for (let j = 0; j < sectionList[i].mainExerciseList.length; j++) {
        // 메인 운동중 무게가 구분데이터를 사용하는지 확인 //
        if (typeof (sectionList[i].mainExerciseList[j].weight) === 'string' && (sectionList[i].mainExerciseList[j].weight.includes('/')
          || sectionList[i].mainExerciseList[j].weight.includes('~')
          || sectionList[i].mainExerciseList[j].weight.includes('-')
          || sectionList[i].mainExerciseList[j].weight.includes('@')
          || sectionList[i].mainExerciseList[j].weight.includes('.'))) {
          sectionList[i].mainExerciseList[j].divisionWeight = sectionList[i].mainExerciseList[j].weight;
          sectionList[i].mainExerciseList[j].weight = 0;
        }
        // 메인 운동중 거리가 구분데이터를 사용하는지 확인 //
        if (typeof (sectionList[i].mainExerciseList[j].distance) === 'string' && (sectionList[i].mainExerciseList[j].distance.includes('/')
          || sectionList[i].mainExerciseList[j].distance.includes('~')
          || sectionList[i].mainExerciseList[j].distance.includes('-')
          || sectionList[i].mainExerciseList[j].distance.includes('@')
          || sectionList[i].mainExerciseList[j].distance.includes('.'))) {
          sectionList[i].mainExerciseList[j].divisionDistance = sectionList[i].mainExerciseList[j].distance;
          sectionList[i].mainExerciseList[j].distance = 0;
          // sectionList[i].mainExerciseList[j].distance = parseFloat(sectionList[i].mainExerciseList[j].distance.split('/')[0]);
        }
        // 메인 운동중 높이가 구분데이터를 사용하는지 확인 //
        if (typeof (sectionList[i].mainExerciseList[j].height) === 'string' && (sectionList[i].mainExerciseList[j].height.includes('/')
          || sectionList[i].mainExerciseList[j].height.includes('~')
          || sectionList[i].mainExerciseList[j].height.includes('-')
          || sectionList[i].mainExerciseList[j].height.includes('@')
          || sectionList[i].mainExerciseList[j].height.includes('.'))) {
          sectionList[i].mainExerciseList[j].divisionHeight = sectionList[i].mainExerciseList[j].height;
          sectionList[i].mainExerciseList[j].height = 0;
          // sectionList[i].mainExerciseList[j].height = parseFloat(sectionList[i].mainExerciseList[j].height.split('/')[0]);
        }
        // 메인 운동중 칼로리가 구분데이터를 사용하는지 확인 //
        if (typeof (sectionList[i].mainExerciseList[j].calories) === 'string' && (sectionList[i].mainExerciseList[j].calories.includes('/')
          || sectionList[i].mainExerciseList[j].calories.includes('~')
          || sectionList[i].mainExerciseList[j].calories.includes('-')
          || sectionList[i].mainExerciseList[j].calories.includes('@')
          || sectionList[i].mainExerciseList[j].calories.includes('.'))) {
          sectionList[i].mainExerciseList[j].divisionCalories = sectionList[i].mainExerciseList[j].calories;
          sectionList[i].mainExerciseList[j].calories = 0;
          // sectionList[i].mainExerciseList[j].calories = parseFloat(sectionList[i].mainExerciseList[j].calories.split('/')[0]);
        }
        // 메인 운동중 템포가 구분데이터를 사용하는지 확인 //
        if (typeof (sectionList[i].mainExerciseList[j].tempo) === 'string' && (sectionList[i].mainExerciseList[j].tempo.includes('/')
          || sectionList[i].mainExerciseList[j].tempo.includes('~')
          || sectionList[i].mainExerciseList[j].tempo.includes('-')
          || sectionList[i].mainExerciseList[j].tempo.includes('@')
          || sectionList[i].mainExerciseList[j].tempo.includes('.'))) {
          sectionList[i].mainExerciseList[j].divisionTempo = sectionList[i].mainExerciseList[j].tempo;
          sectionList[i].mainExerciseList[j].tempo = 0;
          // sectionList[i].mainExerciseList[j].tempo = parseInt(sectionList[i].mainExerciseList[j].tempo.split('/')[0]);
        }
        // 메인 운동중 Single일 경우만 해당 Sets가 구분데이터를 사용하는지 확인 //
        if (typeof (sectionList[i].mainExerciseList[j].sets) === 'string' && (sectionList[i].mainExerciseList[j].sets.includes('/')
          || sectionList[i].mainExerciseList[j].sets.includes('~')
          || sectionList[i].mainExerciseList[j].sets.includes('-')
          || sectionList[i].mainExerciseList[j].sets.includes('@')
          || sectionList[i].mainExerciseList[j].sets.includes('.'))) {
          sectionList[i].mainExerciseList[j].divisionSets = sectionList[i].mainExerciseList[j].sets;
          sectionList[i].mainExerciseList[j].sets = 0;
          // sectionList[i].mainExerciseList[j].sets = parseInt(sectionList[i].mainExerciseList[j].sets.split('/')[0]);
        }
        // 메인 운동중 Single일 경우만 해당 Rest가 구분데이터를 사용하는지 확인 //
        if (typeof (sectionList[i].mainExerciseList[j].rest) === 'string' && (sectionList[i].mainExerciseList[j].rest.includes('/')
          || sectionList[i].mainExerciseList[j].rest.includes('~')
          || sectionList[i].mainExerciseList[j].rest.includes('-')
          || sectionList[i].mainExerciseList[j].rest.includes('@')
          || sectionList[i].mainExerciseList[j].rest.includes('.'))) {
          sectionList[i].mainExerciseList[j].divisionRest = sectionList[i].mainExerciseList[j].rest;
          sectionList[i].mainExerciseList[j].rest = 0;
          // sectionList[i].mainExerciseList[j].rest = parseInt(sectionList[i].mainExerciseList[j].rest.split('/')[0]);
        }
      }
      
      // Scale Section 데이터 정리 //
      if (sectionList[i].scaleSections !== undefined) {
        for (let j = 0; j < sectionList[i].scaleSections.length; j++) {

          // '점수 없음', '시간', '횟수', '라운드 + 횟수', '거리', '무게', '포인트', '피크와트', '최고 속도' //
          if (sectionList[i].scaleSections[j].scoreKeyword === '점수 없음') sectionList[i].scaleSections[j].scoreKeyword = 'Not Scored';
          else if (sectionList[i].scaleSections[j].scoreKeyword === '시간') sectionList[i].scaleSections[j].scoreKeyword = 'Time';
          else if (sectionList[i].scaleSections[j].scoreKeyword === '렙수') sectionList[i].scaleSections[j].scoreKeyword = 'Reps';
          else if (sectionList[i].scaleSections[j].scoreKeyword === '라운드 + 렙수') sectionList[i].scaleSections[j].scoreKeyword = 'Rounds And Reps';
          else if (sectionList[i].scaleSections[j].scoreKeyword === '거리') sectionList[i].scaleSections[j].scoreKeyword = 'Distance';
          else if (sectionList[i].scaleSections[j].scoreKeyword === '무게') sectionList[i].scaleSections[j].scoreKeyword = 'Weight';
          else if (sectionList[i].scaleSections[j].scoreKeyword === '포인트') sectionList[i].scaleSections[j].scoreKeyword = 'Points';
          else if (sectionList[i].scaleSections[j].scoreKeyword === '피크와트') sectionList[i].scaleSections[j].scoreKeyword = 'Peak Watts';
          else if (sectionList[i].scaleSections[j].scoreKeyword === '최고 속도') sectionList[i].scaleSections[j].scoreKeyword = 'Max Speed';

          if (sectionList[i].scaleSections[j].scorePriority === '높을 수록') sectionList[i].scaleSections[j].scorePriority = 'heigher';
          else if (sectionList[i].scaleSections[j].scorePriority === '낮을 수록') sectionList[i].scaleSections[j].scorePriority = 'lower';

          // Section의 Round중 구분자가 들어간 경우 //
          if (typeof (sectionList[i].scaleSections[j].round) === 'string'
            && (sectionList[i].scaleSections[j].round.includes('/')
              || sectionList[i].scaleSections[j].round.includes('~')
              || sectionList[i].scaleSections[j].round.includes('-')
              || sectionList[i].scaleSections[j].round.includes('@')
              || sectionList[i].scaleSections[j].round.includes('.'))) {
            sectionList[i].scaleSections[j].divisionRound = sectionList[i].scaleSections[j].round;
            sectionList[i].scaleSections[j].round = 0;
          }

          for (let h = 0; h < sectionList[i].scaleSections[j].mainExerciseList.length; h++) {
            // 메인 운동중 무게가 구분데이터를 사용하는지 확인 //
            if (typeof (sectionList[i].scaleSections[j].mainExerciseList[h].weight) === 'string' && (sectionList[i].scaleSections[j].mainExerciseList[h].weight.includes('/')
              || sectionList[i].scaleSections[j].mainExerciseList[h].weight.includes('~')
              || sectionList[i].scaleSections[j].mainExerciseList[h].weight.includes('-')
              || sectionList[i].scaleSections[j].mainExerciseList[h].weight.includes('@')
              || sectionList[i].scaleSections[j].mainExerciseList[h].weight.includes('.'))) {
              sectionList[i].scaleSections[j].mainExerciseList[h].divisionWeight = sectionList[i].scaleSections[j].mainExerciseList[h].weight;
              sectionList[i].scaleSections[j].mainExerciseList[h].weight = 0;
            }
            // 메인 운동중 거리가 구분데이터를 사용하는지 확인 //
            if (typeof (sectionList[i].scaleSections[j].mainExerciseList[h].distance) === 'string' && (sectionList[i].scaleSections[j].mainExerciseList[h].distance.includes('/')
              || sectionList[i].scaleSections[j].mainExerciseList[h].distance.includes('~')
              || sectionList[i].scaleSections[j].mainExerciseList[h].distance.includes('-')
              || sectionList[i].scaleSections[j].mainExerciseList[h].distance.includes('@')
              || sectionList[i].scaleSections[j].mainExerciseList[h].distance.includes('.'))) {
              sectionList[i].scaleSections[j].mainExerciseList[h].divisionDistance = sectionList[i].scaleSections[j].mainExerciseList[h].distance;
              sectionList[i].scaleSections[j].mainExerciseList[h].distance = 0;
            }
            // 메인 운동중 높이가 구분데이터를 사용하는지 확인 //
            if (typeof (sectionList[i].scaleSections[j].mainExerciseList[h].height) === 'string' && (sectionList[i].scaleSections[j].mainExerciseList[h].height.includes('/')
              || sectionList[i].scaleSections[j].mainExerciseList[h].height.includes('~')
              || sectionList[i].scaleSections[j].mainExerciseList[h].height.includes('-')
              || sectionList[i].scaleSections[j].mainExerciseList[h].height.includes('@')
              || sectionList[i].scaleSections[j].mainExerciseList[h].height.includes('.'))) {
              sectionList[i].scaleSections[j].mainExerciseList[h].divisionHeight = sectionList[i].scaleSections[j].mainExerciseList[h].height;
              sectionList[i].scaleSections[j].mainExerciseList[h].height = 0;
            }
            // 메인 운동중 칼로리가 구분데이터를 사용하는지 확인 //
            if (typeof (sectionList[i].scaleSections[j].mainExerciseList[h].calories) === 'string' && (sectionList[i].scaleSections[j].mainExerciseList[h].calories.includes('/')
              || sectionList[i].scaleSections[j].mainExerciseList[h].calories.includes('~')
              || sectionList[i].scaleSections[j].mainExerciseList[h].calories.includes('-')
              || sectionList[i].scaleSections[j].mainExerciseList[h].calories.includes('@')
              || sectionList[i].scaleSections[j].mainExerciseList[h].calories.includes('.'))) {
              sectionList[i].scaleSections[j].mainExerciseList[h].divisionCalories = sectionList[i].scaleSections[j].mainExerciseList[h].calories;
              sectionList[i].scaleSections[j].mainExerciseList[h].calories = 0;
            }
            // 메인 운동중 템포가 구분데이터를 사용하는지 확인 //
            if (typeof (sectionList[i].scaleSections[j].mainExerciseList[h].tempo) === 'string' && (sectionList[i].scaleSections[j].mainExerciseList[h].tempo.includes('/')
              || sectionList[i].scaleSections[j].mainExerciseList[h].tempo.includes('~')
              || sectionList[i].scaleSections[j].mainExerciseList[h].tempo.includes('-')
              || sectionList[i].scaleSections[j].mainExerciseList[h].tempo.includes('@')
              || sectionList[i].scaleSections[j].mainExerciseList[h].tempo.includes('.'))) {
              sectionList[i].scaleSections[j].mainExerciseList[h].divisionTempo = sectionList[i].scaleSections[j].mainExerciseList[h].tempo;
              sectionList[i].scaleSections[j].mainExerciseList[h].tempo = 0;
            }
            // 메인 운동중 Single일 경우만 해당 Sets가 구분데이터를 사용하는지 확인 //
            if (typeof (sectionList[i].scaleSections[j].mainExerciseList[h].sets) === 'string' && (sectionList[i].scaleSections[j].mainExerciseList[h].sets.includes('/')
              || sectionList[i].scaleSections[j].mainExerciseList[h].sets.includes('~')
              || sectionList[i].scaleSections[j].mainExerciseList[h].sets.includes('-')
              || sectionList[i].scaleSections[j].mainExerciseList[h].sets.includes('@')
              || sectionList[i].scaleSections[j].mainExerciseList[h].sets.includes('.'))) {
              sectionList[i].scaleSections[j].mainExerciseList[h].divisionSets = sectionList[i].scaleSections[j].mainExerciseList[h].sets;
              sectionList[i].scaleSections[j].mainExerciseList[h].sets = 0;
            }
            // 메인 운동중 Single일 경우만 해당 Rest가 구분데이터를 사용하는지 확인 //
            if (typeof (sectionList[i].scaleSections[j].mainExerciseList[h].rest) === 'string' && (sectionList[i].scaleSections[j].mainExerciseList[h].rest.includes('/')
              || sectionList[i].scaleSections[j].mainExerciseList[h].rest.includes('~')
              || sectionList[i].scaleSections[j].mainExerciseList[h].rest.includes('-')
              || sectionList[i].scaleSections[j].mainExerciseList[h].rest.includes('@')
              || sectionList[i].scaleSections[j].mainExerciseList[h].rest.includes('.'))) {
              sectionList[i].scaleSections[j].mainExerciseList[h].divisionRest = sectionList[i].scaleSections[j].mainExerciseList[h].rest;
              sectionList[i].scaleSections[j].mainExerciseList[h].rest = 0;
            }
          }
        }
      }

      if (sectionList[i].mainScaleOptionExerciseList !== undefined) {
        // Main Scale Option 확인 //
        for (let j = 0; j < sectionList[i].mainScaleOptionExerciseList.length; j++) {
          // 메인 운동중 무게가 구분데이터를 사용하는지 확인 //
          if (typeof (sectionList[i].mainScaleOptionExerciseList[j].weight) === 'string' && (sectionList[i].mainScaleOptionExerciseList[j].weight.includes('/')
            || sectionList[i].mainScaleOptionExerciseList[j].weight.includes('~')
            || sectionList[i].mainScaleOptionExerciseList[j].weight.includes('-')
            || sectionList[i].mainScaleOptionExerciseList[j].weight.includes('@')
            || sectionList[i].mainScaleOptionExerciseList[j].weight.includes('.'))) {
            sectionList[i].mainScaleOptionExerciseList[j].divisionWeight = sectionList[i].mainScaleOptionExerciseList[j].weight;
            sectionList[i].mainScaleOptionExerciseList[j].weight = 0;
          }
          // 메인 운동중 거리가 구분데이터를 사용하는지 확인 //
          if (typeof (sectionList[i].mainScaleOptionExerciseList[j].distance) === 'string' && (sectionList[i].mainScaleOptionExerciseList[j].distance.includes('/')
            || sectionList[i].mainScaleOptionExerciseList[j].distance.includes('~')
            || sectionList[i].mainScaleOptionExerciseList[j].distance.includes('-')
            || sectionList[i].mainScaleOptionExerciseList[j].distance.includes('@')
            || sectionList[i].mainScaleOptionExerciseList[j].distance.includes('.'))) {
            sectionList[i].mainScaleOptionExerciseList[j].divisionDistance = sectionList[i].mainScaleOptionExerciseList[j].distance;
            sectionList[i].mainScaleOptionExerciseList[j].distance = 0
            // sectionList[i].mainScaleOptionExerciseList[j].distance = parseFloat(sectionList[i].mainScaleOptionExerciseList[j].distance.split('/')[0]);
          }
          // 메인 운동중 높이가 구분데이터를 사용하는지 확인 //
          if (typeof (sectionList[i].mainScaleOptionExerciseList[j].height) === 'string' && (sectionList[i].mainScaleOptionExerciseList[j].height.includes('/')
            || sectionList[i].mainScaleOptionExerciseList[j].height.includes('~')
            || sectionList[i].mainScaleOptionExerciseList[j].height.includes('-')
            || sectionList[i].mainScaleOptionExerciseList[j].height.includes('@')
            || sectionList[i].mainScaleOptionExerciseList[j].height.includes('.'))) {
            sectionList[i].mainScaleOptionExerciseList[j].divisionHeight = sectionList[i].mainScaleOptionExerciseList[j].height;
            sectionList[i].mainScaleOptionExerciseList[j].height = 0;
            // sectionList[i].mainScaleOptionExerciseList[j].height = parseFloat(sectionList[i].mainScaleOptionExerciseList[j].height.split('/')[0]);
          }
          // 메인 운동중 칼로리가 구분데이터를 사용하는지 확인 //
          if (typeof (sectionList[i].mainScaleOptionExerciseList[j].calories) === 'string' && (sectionList[i].mainScaleOptionExerciseList[j].calories.includes('/')
            || sectionList[i].mainScaleOptionExerciseList[j].calories.includes('~')
            || sectionList[i].mainScaleOptionExerciseList[j].calories.includes('-')
            || sectionList[i].mainScaleOptionExerciseList[j].calories.includes('@')
            || sectionList[i].mainScaleOptionExerciseList[j].calories.includes('.'))) {
            sectionList[i].mainScaleOptionExerciseList[j].divisionCalories = sectionList[i].mainScaleOptionExerciseList[j].calories;
            sectionList[i].mainScaleOptionExerciseList[j].calories = 0;
            // sectionList[i].mainScaleOptionExerciseList[j].calories = parseFloat(sectionList[i].mainScaleOptionExerciseList[j].calories.split('/')[0]);
          }
          // 메인 운동중 템포가 구분데이터를 사용하는지 확인 //
          if (typeof (sectionList[i].mainScaleOptionExerciseList[j].tempo) === 'string' && (sectionList[i].mainScaleOptionExerciseList[j].tempo.includes('/')
            || sectionList[i].mainScaleOptionExerciseList[j].tempo.includes('~')
            || sectionList[i].mainScaleOptionExerciseList[j].tempo.includes('-')
            || sectionList[i].mainScaleOptionExerciseList[j].tempo.includes('@')
            || sectionList[i].mainScaleOptionExerciseList[j].tempo.includes('.'))) {
            sectionList[i].mainScaleOptionExerciseList[j].divisionTempo = sectionList[i].mainScaleOptionExerciseList[j].tempo;
            sectionList[i].mainScaleOptionExerciseList[j].tempo = 0;
            // sectionList[i].mainScaleOptionExerciseList[j].tempo = parseInt(sectionList[i].mainScaleOptionExerciseList[j].tempo.split('/')[0]);
          }
          // 메인 운동중 Single일 경우만 해당 Sets가 구분데이터를 사용하는지 확인 //
          if (typeof (sectionList[i].mainScaleOptionExerciseList[j].sets) === 'string' && (sectionList[i].mainScaleOptionExerciseList[j].sets.includes('/')
            || sectionList[i].mainScaleOptionExerciseList[j].sets.includes('~')
            || sectionList[i].mainScaleOptionExerciseList[j].sets.includes('-')
            || sectionList[i].mainScaleOptionExerciseList[j].sets.includes('@')
            || sectionList[i].mainScaleOptionExerciseList[j].sets.includes('.'))) {
            sectionList[i].mainScaleOptionExerciseList[j].divisionSets = sectionList[i].mainScaleOptionExerciseList[j].sets;
            sectionList[i].mainScaleOptionExerciseList[j].sets = 0;
            // sectionList[i].mainScaleOptionExerciseList[j].sets = parseInt(sectionList[i].mainScaleOptionExerciseList[j].sets.split('/')[0]);
          }
          // 메인 운동중 Single일 경우만 해당 Rest가 구분데이터를 사용하는지 확인 //
          if (typeof (sectionList[i].mainScaleOptionExerciseList[j].rest) === 'string' && (sectionList[i].mainScaleOptionExerciseList[j].rest.includes('/')
            || sectionList[i].mainScaleOptionExerciseList[j].rest.includes('~')
            || sectionList[i].mainScaleOptionExerciseList[j].rest.includes('-')
            || sectionList[i].mainScaleOptionExerciseList[j].rest.includes('@')
            || sectionList[i].mainScaleOptionExerciseList[j].rest.includes('.'))) {
            sectionList[i].mainScaleOptionExerciseList[j].divisionRest = sectionList[i].mainScaleOptionExerciseList[j].rest;
            sectionList[i].mainScaleOptionExerciseList[j].rest = 0;
            // sectionList[i].mainExerciseList[j].rest = parseInt(sectionList[i].mainExerciseList[j].rest.split('/')[0]);
          }
        }
      }
    }

    if (sectionList[i].buyoutExerciseList !== undefined) {
      // Buyout 확인 //
      for (let j = 0; j < sectionList[i].buyoutExerciseList.length; j++) {
        // Buyout 운동중 무게가 구분데이터를 사용하는지 확인 //
        if (typeof (sectionList[i].buyoutExerciseList[j].weight) === 'string' && (sectionList[i].buyoutExerciseList[j].weight.includes('/')
          || sectionList[i].buyoutExerciseList[j].weight.includes('~')
          || sectionList[i].buyoutExerciseList[j].weight.includes('-')
          || sectionList[i].buyoutExerciseList[j].weight.includes('@')
          || sectionList[i].buyoutExerciseList[j].weight.includes('.'))) {
          sectionList[i].buyoutExerciseList[j].divisionWeight = sectionList[i].buyoutExerciseList[j].weight;
          sectionList[i].buyoutExerciseList[j].weight = 0;
        }
        // Buyout 운동중 거리가 구분데이터를 사용하는지 확인 //
        if (typeof (sectionList[i].buyoutExerciseList[j].distance) === 'string' && (sectionList[i].buyoutExerciseList[j].distance.includes('/')
          || sectionList[i].buyoutExerciseList[j].distance.includes('~')
          || sectionList[i].buyoutExerciseList[j].distance.includes('-')
          || sectionList[i].buyoutExerciseList[j].distance.includes('@')
          || sectionList[i].buyoutExerciseList[j].distance.includes('.'))) {
          sectionList[i].buyoutExerciseList[j].divisionDistance = sectionList[i].buyoutExerciseList[j].distance;
          sectionList[i].buyoutExerciseList[j].distance = 0;
          // sectionList[i].buyoutExerciseList[j].distance = parseFloat(sectionList[i].buyoutExerciseList[j].distance.split('/')[0]);
        }
        // Buyout 운동중 높이가 구분데이터를 사용하는지 확인 //
        if (typeof (sectionList[i].buyoutExerciseList[j].height) === 'string' && (sectionList[i].buyoutExerciseList[j].height.includes('/')
          || sectionList[i].buyoutExerciseList[j].height.includes('~')
          || sectionList[i].buyoutExerciseList[j].height.includes('-')
          || sectionList[i].buyoutExerciseList[j].height.includes('@')
          || sectionList[i].buyoutExerciseList[j].height.includes('.'))) {
          sectionList[i].buyoutExerciseList[j].divisionHeight = sectionList[i].buyoutExerciseList[j].height;
          sectionList[i].buyoutExerciseList[j].height = 0;
          // sectionList[i].buyoutExerciseList[j].height = parseFloat(sectionList[i].buyoutExerciseList[j].height.split('/')[0]);
        }
        // Buyout 운동중 칼로리가 구분데이터를 사용하는지 확인 //
        if (typeof (sectionList[i].buyoutExerciseList[j].calories) === 'string' && (sectionList[i].buyoutExerciseList[j].calories.includes('/')
          || sectionList[i].buyoutExerciseList[j].calories.includes('~')
          || sectionList[i].buyoutExerciseList[j].calories.includes('-')
          || sectionList[i].buyoutExerciseList[j].calories.includes('@')
          || sectionList[i].buyoutExerciseList[j].calories.includes('.'))) {
          sectionList[i].buyoutExerciseList[j].divisionCalories = sectionList[i].buyoutExerciseList[j].calories;
          sectionList[i].buyoutExerciseList[j].calories = 0;
          // sectionList[i].buyoutExerciseList[j].calories = parseFloat(sectionList[i].buyoutExerciseList[j].calories.split('/')[0]);
        }
        // Buyout 운동중 템포가 구분데이터를 사용하는지 확인 //
        if (typeof (sectionList[i].buyoutExerciseList[j].tempo) === 'string' && (sectionList[i].buyoutExerciseList[j].tempo.includes('/')
          || sectionList[i].buyoutExerciseList[j].tempo.includes('~')
          || sectionList[i].buyoutExerciseList[j].tempo.includes('-')
          || sectionList[i].buyoutExerciseList[j].tempo.includes('@')
          || sectionList[i].buyoutExerciseList[j].tempo.includes('.'))) {
          sectionList[i].buyoutExerciseList[j].divisionTempo = sectionList[i].buyoutExerciseList[j].tempo;
          sectionList[i].buyoutExerciseList[j].tempo = 0;
          // sectionList[i].buyoutExerciseList[j].tempo = parseInt(sectionList[i].buyoutExerciseList[j].tempo.split('/')[0]);
        }
      }
    }


    if (sectionList[i].buyoutScaleOptionExerciseList !== undefined) {
      // Buyout 확인 //
      for (let j = 0; j < sectionList[i].buyoutScaleOptionExerciseList.length; j++) {
        // Buyout 운동중 무게가 구분데이터를 사용하는지 확인 //
        if (typeof (sectionList[i].buyouScaleOptiontExerciseList[j].weight) === 'string' && (sectionList[i].buyouScaleOptiontExerciseList[j].weight.includes('/')
          || sectionList[i].buyouScaleOptiontExerciseList[j].weight.includes('~')
          || sectionList[i].buyouScaleOptiontExerciseList[j].weight.includes('-')
          || sectionList[i].buyouScaleOptiontExerciseList[j].weight.includes('@')
          || sectionList[i].buyouScaleOptiontExerciseList[j].weight.includes('.'))) {
          sectionList[i].buyoutScaleOptionExerciseList[j].divisionWeight = sectionList[i].buyoutScaleOptionExerciseList[j].weight;
          sectionList[i].buyoutScaleOptionExerciseList[j].weight = 0;
        }
        // Buyout 운동중 거리가 구분데이터를 사용하는지 확인 //
        if (typeof (sectionList[i].buyouScaleOptiontExerciseList[j].distance) === 'string' && (sectionList[i].buyoutScaleOptionExerciseList[j].distance.includes('/')
          || sectionList[i].buyoutScaleOptionExerciseList[j].distance.includes('~')
          || sectionList[i].buyoutScaleOptionExerciseList[j].distance.includes('-')
          || sectionList[i].buyoutScaleOptionExerciseList[j].distance.includes('@')
          || sectionList[i].buyoutScaleOptionExerciseList[j].distance.includes('.'))) {
          sectionList[i].buyoutScaleOptionExerciseList[j].divisionDistance = sectionList[i].buyoutScaleOptionExerciseList[j].distance;
          sectionList[i].buyoutScaleOptionExerciseList[j].distance = 0;
          // sectionList[i].buyoutScaleOptionExerciseList[j].distance = parseFloat(sectionList[i].buyoutScaleOptionExerciseList[j].distance.split('/')[0]);
        }
        // Buyout 운동중 높이가 구분데이터를 사용하는지 확인 //
        if (typeof (sectionList[i].buyouScaleOptiontExerciseList[j].height) === 'string' && (sectionList[i].buyoutScaleOptionExerciseList[j].height.includes('/')
          || sectionList[i].buyoutScaleOptionExerciseList[j].height.includes('~')
          || sectionList[i].buyoutScaleOptionExerciseList[j].height.includes('-')
          || sectionList[i].buyoutScaleOptionExerciseList[j].height.includes('@')
          || sectionList[i].buyoutScaleOptionExerciseList[j].height.includes('.'))) {
          sectionList[i].buyoutScaleOptionExerciseList[j].divisionHeight = sectionList[i].buyoutScaleOptionExerciseList[j].height;
          sectionList[i].buyoutScaleOptionExerciseList[j].height = 0;
          // sectionList[i].buyoutScaleOptionExerciseList[j].height = parseFloat(sectionList[i].buyoutScaleOptionExerciseList[j].height.split('/')[0]);
        }
        // Buyout 운동중 칼로리가 구분데이터를 사용하는지 확인 //
        if (typeof (sectionList[i].buyouScaleOptiontExerciseList[j].calories) === 'string' && (sectionList[i].buyoutScaleOptionExerciseList[j].calories.includes('/')
          || sectionList[i].buyoutScaleOptionExerciseList[j].calories.includes('~')
          || sectionList[i].buyoutScaleOptionExerciseList[j].calories.includes('-')
          || sectionList[i].buyoutScaleOptionExerciseList[j].calories.includes('@')
          || sectionList[i].buyoutScaleOptionExerciseList[j].calories.includes('.'))) {
          sectionList[i].buyoutScaleOptionExerciseList[j].divisionCalories = sectionList[i].buyoutScaleOptionExerciseList[j].calories;
          sectionList[i].buyoutScaleOptionExerciseList[j].calories = 0;
          // sectionList[i].buyoutScaleOptionExerciseList[j].calories = parseFloat(sectionList[i].buyoutScaleOptionExerciseList[j].calories.split('/')[0]);
        }
        // Buyout 운동중 템포가 구분데이터를 사용하는지 확인 //
        if (typeof (sectionList[i].buyouScaleOptiontExerciseList[j].tempo) === 'string' && (sectionList[i].buyoutScaleOptionExerciseList[j].tempo.includes('/')
          || sectionList[i].buyoutScaleOptionExerciseList[j].tempo.includes('~')
          || sectionList[i].buyoutScaleOptionExerciseList[j].tempo.includes('-')
          || sectionList[i].buyoutScaleOptionExerciseList[j].tempo.includes('@')
          || sectionList[i].buyoutScaleOptionExerciseList[j].tempo.includes('.'))) {
          sectionList[i].buyoutScaleOptionExerciseList[j].divisionTempo = sectionList[i].buyoutScaleOptionExerciseList[j].tempo;
          sectionList[i].buyoutScaleOptionExerciseList[j].tempo = 0;
          // sectionList[i].buyoutScaleOptionExerciseList[j].tempo = parseInt(sectionList[i].buyoutScaleOptionExerciseList[j].tempo.split('/')[0]);
        }
      }
    }
  }

  return sectionList;
}

// 옛날버전 랭킹에서 사용할 데이터 정리 //
export function organizationRanking(workoutData) {
  // 운동 데이터 정리 //
  for (let i = 0; i < workoutData.length; i++) {
  
    workoutData[i].sectionList.sort(function (a, b) {
      return a.sectionIndex < b.sectionIndex ? -1 : a.sectionIndex > b.sectionIndex ? 1 : 0;
    })

    for (let j = 0; j < workoutData[i].sectionList.length; j++) {
      workoutData[i].sectionList[j].buyinExerciseList = [];
      workoutData[i].sectionList[j].buyinScaleExerciseList = [];
      workoutData[i].sectionList[j].mainExerciseList = [];
      workoutData[i].sectionList[j].mainScaleExerciseList = [];
      workoutData[i].sectionList[j].emomExerciseList = [];
      workoutData[i].sectionList[j].emomScaleExerciseList = [];
      workoutData[i].sectionList[j].buyoutExerciseList = [];
      workoutData[i].sectionList[j].buyoutScaleExerciseList = [];
      workoutData[i].sectionList[j].reps = workoutData[i].sectionList[j].reps.split('-');

      // Section Record Data 정리 //
      for (let u = 0; u < workoutData[i].sectionList[j].sectionRecordList.length; u++) {

        workoutData[i].sectionList[j].sectionRecordList.sort(function (a, b) {
          return a.sectionIndex < b.sectionIndex ? -1 : a.sectionIndex > b.sectionIndex ? 1 : 0;
        })

        workoutData[i].sectionList[j].sectionRecordList[u].buyinExerciseList = [];
        workoutData[i].sectionList[j].sectionRecordList[u].buyinScaleExerciseList = [];
        workoutData[i].sectionList[j].sectionRecordList[u].mainExerciseList = [];
        workoutData[i].sectionList[j].sectionRecordList[u].mainScaleExerciseList = [];
        workoutData[i].sectionList[j].sectionRecordList[u].emomExerciseList = [];
        workoutData[i].sectionList[j].sectionRecordList[u].emomScaleExerciseList = [];
        workoutData[i].sectionList[j].sectionRecordList[u].buyoutExerciseList = [];
        workoutData[i].sectionList[j].sectionRecordList[u].buyoutScaleExerciseList = [];


        for (let h = 0; h < workoutData[i].sectionList[j].sectionRecordList[u].exerciseList.length; h++) {

          if (workoutData[i].sectionList[j].tag === 'EMOM') workoutData[i].sectionList[j].sectionRecordList[u].roundList = workoutData[i].sectionList[j].roundList;

          workoutData[i].sectionList[j].sectionRecordList[u].exerciseList.sort(function (a, b) {
            return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
          })

          workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].movementId = workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].movement.movementId;
          workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].movementName = workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].movement.movementName;
          workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].videoLinkId = workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].movement.videoLinkId;

          // 무게 구분 데이터 사용할 경우 //
          if (workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionWeight !== undefined
            && workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionWeight !== ''
            && workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionWeight !== 'None') {
            workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].weight = workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionWeight;
          }
          else workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionWeight = '';

          // 거리 구분 데이터 사용할 경우 //
          if (workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionDistance !== undefined
            && workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionDistance !== ''
            && workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionDistance !== 'None') {
            workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].distance = workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionDistance;
          }
          else workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionDistance = '';

          // 높이 구분 데이터 사용할 경우 //
          if (workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionHeight !== undefined
            && workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionHeight !== ''
            && workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionHeight !== 'None') {
            workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].height = workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionHeight;
          }
          else workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionHeight = '';

          // 칼로리 구분 데이터 사용할 경우 //
          if (workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionCalories !== undefined
            && workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionCalories !== ''
            && workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionCalories !== 'None') {
            workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].calories = workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionCalories;
          }
          else workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionCalories = '';

          // 템포 구분 데이터 사용할 경우 //
          if (workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionTempo !== undefined
            && workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionTempo !== ''
            && workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionTempo !== 'None') {
            workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].tempo = workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionTempo;
          }
          else workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionTempo = '';

          // Sets 구분 데이터 사용할 경우 //
          if (workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionSets !== undefined
            && workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionSets !== ''
            && workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionSets !== 'None') {
            workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].sets = workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionSets;
          }
          else workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].divisionSets = '';

          if (workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].division === 'Buyin') {
            workoutData[i].sectionList[j].sectionRecordList[u].buyinExerciseList.push(workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h]);
          }
          else if (workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].division === 'Buyin.Scale') {
            workoutData[i].sectionList[j].sectionRecordList[u].buyinScaleExerciseList.push(workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h]);
          }
          else if (workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].division === 'Main') {
            workoutData[i].sectionList[j].sectionRecordList[u].mainExerciseList.push(workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h]);
          }
          else if (workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].division === 'Main.Scale') {
            workoutData[i].sectionList[j].sectionRecordList[u].mainScaleExerciseList.push(workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h]);
          }
          else if (workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].division === 'Main.Emom') {
            // workoutData[i].sectionList[j].sectionRecordList[u].emomExerciseList.push(workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h]);
            let exerciseData = workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h];

            for (let h = 0; h < workoutData[i].sectionList[j].exerciseList.length; h++) {
              if (exerciseData.exerciseId === workoutData[i].sectionList[j].exerciseList[h].exerciseId) {
                exerciseData.emomRoundIndex = workoutData[i].sectionList[j].exerciseList[h].emomRoundIndex;
                break;
              }
            }

            workoutData[i].sectionList[j].sectionRecordList[u].emomExerciseList.push(exerciseData);
          }
          else if (workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].division === 'Main.Emom.Scale') {
            workoutData[i].sectionList[j].sectionRecordList[u].emomScaleExerciseList.push(workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h]);
          }
          else if (workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].division === 'Buyout') {
            workoutData[i].sectionList[j].sectionRecordList[u].buyoutExerciseList.push(workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h]);
          }
          else if (workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h].division === 'Buyout.Scale') {
            workoutData[i].sectionList[j].sectionRecordList[u].buyoutScaleExerciseList.push(workoutData[i].sectionList[j].sectionRecordList[u].exerciseList[h]);
          }
        }
      }

      for (let h = 0; h < workoutData[i].sectionList[j].exerciseList.length; h++) {
        workoutData[i].sectionList[j].exerciseList[h].movementId = workoutData[i].sectionList[j].exerciseList[h].movement.movementId;
        workoutData[i].sectionList[j].exerciseList[h].movementName = workoutData[i].sectionList[j].exerciseList[h].movement.movementName;
        workoutData[i].sectionList[j].exerciseList[h].videoLinkId = workoutData[i].sectionList[j].exerciseList[h].movement.videoLinkId;

        if (workoutData[i].sectionList[j].exerciseList[h].division === 'Buyin') {
          workoutData[i].sectionList[j].buyinExerciseList.push(workoutData[i].sectionList[j].exerciseList[h]);
        }
        else if (workoutData[i].sectionList[j].exerciseList[h].division === 'Buyin.Scale') {
          workoutData[i].sectionList[j].buyinScaleExerciseList.push(workoutData[i].sectionList[j].exerciseList[h]);
        }
        else if (workoutData[i].sectionList[j].exerciseList[h].division === 'Main') {
          workoutData[i].sectionList[j].mainExerciseList.push(workoutData[i].sectionList[j].exerciseList[h]);
        }
        else if (workoutData[i].sectionList[j].exerciseList[h].division === 'Main.Scale') {
          workoutData[i].sectionList[j].mainScaleExerciseList.push(workoutData[i].sectionList[j].exerciseList[h]);
        }
        else if (workoutData[i].sectionList[j].exerciseList[h].division === 'Main.Emom') {
          workoutData[i].sectionList[j].emomExerciseList.push(workoutData[i].sectionList[j].exerciseList[h]);
        }
        else if (workoutData[i].sectionList[j].exerciseList[h].division === 'Main.Emom.Scale') {
          workoutData[i].sectionList[j].emomScaleExerciseList.push(workoutData[i].sectionList[j].exerciseList[h]);
        }
        else if (workoutData[i].sectionList[j].exerciseList[h].division === 'Buyout') {
          workoutData[i].sectionList[j].buyoutExerciseList.push(workoutData[i].sectionList[j].exerciseList[h]);
        }
        else if (workoutData[i].sectionList[j].exerciseList[h].division === 'Buyout.Scale') {
          workoutData[i].sectionList[j].buyoutScaleExerciseList.push(workoutData[i].sectionList[j].exerciseList[h]);
        }
      }

      // exerciseIndex.asc 진행 //
      workoutData[i].sectionList[j].buyinExerciseList.sort(function (a, b) {
        return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
      })
      // exerciseIndex.asc 진행 //
      workoutData[i].sectionList[j].buyinScaleExerciseList.sort(function (a, b) {
        return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
      })
      // exerciseIndex.asc 진행 //
      workoutData[i].sectionList[j].mainExerciseList.sort(function (a, b) {
        return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
      })
      // exerciseIndex.asc 진행 //
      workoutData[i].sectionList[j].mainScaleExerciseList.sort(function (a, b) {
        return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
      })
      // exerciseIndex.asc 진행 //
      workoutData[i].sectionList[j].emomExerciseList.sort(function (a, b) {
        return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
      })
      // exerciseIndex.asc 진행 //
      workoutData[i].sectionList[j].emomScaleExerciseList.sort(function (a, b) {
        return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
      })
      // exerciseIndex.asc 진행 //
      workoutData[i].sectionList[j].buyoutExerciseList.sort(function (a, b) {
        return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
      })
      // exerciseIndex.asc 진행 //
      workoutData[i].sectionList[j].buyoutScaleExerciseList.sort(function (a, b) {
        return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
      })
    }
  }

  return workoutData;
}

// 옛날버전 순위 산출 //
export function calcLegacyRanking(useData, nowSection) {
  let tag = '';
  let scoreKeyword = '';
  let userRankingList = [];

  for (let i = 0; i < useData.length; i++) {

    if (useData[i].sectionList[nowSection] === undefined) {
      return userRankingList;
    }

    tag = useData[i].sectionList[nowSection].tag;
    scoreKeyword = useData[i].sectionList[nowSection].scoreKeyword;

    let tmpList = [];

    // Custom인 경우 순위 따로 산출 //
    if (useData[i].sectionList[nowSection].tag === 'Custom') {
      let finalScoreText = '';

      // 순위표 구성을 위한 커스텀 기준데이터 확인 //
      // 점수 산출 방법이 없을 경우 ( 입력 순서대로 오름차순 )//
      if (useData[i].sectionList[nowSection].scoreKeyword === 'Not Scored') {
        let totalScore = 0;
        finalScoreText = '점수 기준 없음';
        tmpList = useData[i].sectionList[nowSection].sectionRecordList.slice();

        tmpList.sort(function (a, b) {
          return a.createdDate < b.createdDate ? -1 : a.createdDate < b.createdDate ? 1 : 0;
        })

        totalScore = tmpList.length;

        for (let j = 0; j < tmpList.length; j++) {
          totalScore -= j;

          // 이미 저장된 내역 확인 Flag //
          let isExist = false;
          // 이미 저장된 유저 점수가 있는지 확인 //
          for (let x = 0; x < userRankingList.length; x++) {
            // 이미 점수가 있으면 기존점수에 + 하기 //
            if (userRankingList[x].linkCoachId === tmpList[j].linkCoachId) {
              totalScore += totalScore;
              // 저장 플래그 //
              isExist = true;
            }
          }

          // 저장된게 없으면 //
          if (!isExist) {
            let rankingInfo = {
              linkCoachId: useData[i].sectionList[nowSection].sectionRecordList[j].linkCoachId,
              userName: useData[i].sectionList[nowSection].sectionRecordList[j].userName,
              userProfile: useData[i].sectionList[nowSection].sectionRecordList[j].userProfile,
              createdDate: useData[i].sectionList[nowSection].sectionRecordList[j].createdDate,
              finalScoreText: finalScoreText,
              totalScore: totalScore
            }

            userRankingList.push(rankingInfo);
          }
        }
      }
      // 시간 기준 점수 산출일 경우 //
      else if (useData[i].sectionList[nowSection].scoreKeyword === 'Time') {

        // 높은 점수 기준 //
        if (useData[i].sectionList[nowSection].scorePriority === 'heigher') {
          for (let j = 0; j < useData[i].sectionList[nowSection].sectionRecordList.length; j++) {
            let totalScore = 0;

            totalScore = useData[i].sectionList[nowSection].sectionRecordList[j].recordTime;

            // 이미 저장된 내역 확인 Flag //
            let isExist = false;
            // 이미 저장된 유저 점수가 있는지 확인 //
            for (let x = 0; x < userRankingList.length; x++) {
              // 이미 점수가 있으면 기존점수에 + 하기 //
              if (userRankingList[x].linkCoachId === useData[i].sectionList[nowSection].sectionRecordList[j].linkCoachId) {
                totalScore += totalScore;
                // 저장 플래그 //
                isExist = true;
              }
            }

            // 저장된게 없으면 //
            if (!isExist) {

              let totalText = ''
              let min = 0;
              let sec = 0;
              let decimalPoint = (useData[i].sectionList[nowSection].sectionRecordList[j].recordTime % 1).toFixed(2);

              min = parseInt(useData[i].sectionList[nowSection].sectionRecordList[j].recordTime / 60);

              if (decimalPoint !== '0.00') sec = parseInt(useData[i].sectionList[nowSection].sectionRecordList[j].recordTime % 60) + parseFloat(decimalPoint);
              else sec = parseInt(useData[i].sectionList[nowSection].sectionRecordList[j].recordTime % 60);

              if (sec === 0) {
                if (min < 10) totalText = '0' + min + ':00';
                else totalText = min + ':00';
              }
              else {
                if (min < 10) {
                  if (sec < 10) totalText = min + ':0' + sec;
                  else totalText = min + ':' + sec;
                }
                else {
                  if (sec < 10) totalText = min + ':0' + sec;
                  else totalText = min + ':' + sec;
                }
              }

              finalScoreText = totalText;

              let rankingInfo = {
                linkCoachId: useData[i].sectionList[nowSection].sectionRecordList[j].linkCoachId,
                userName: useData[i].sectionList[nowSection].sectionRecordList[j].userName,
                userProfile: useData[i].sectionList[nowSection].sectionRecordList[j].userProfile,
                createdDate: useData[i].sectionList[nowSection].sectionRecordList[j].createdDate,
                finalScoreText: finalScoreText,
                totalScore: totalScore
              }

              userRankingList.push(rankingInfo);
            }
          }
        }
        // 낮은 점수 기준 //
        else {
          for (let j = 0; j < useData[i].sectionList[nowSection].sectionRecordList.length; j++) {
            let totalScore = 0;

            if (useData[i].sectionList[nowSection].sectionRecordList[j].recordTime === 0
              || useData[i].sectionList[nowSection].sectionRecordList[j].success === 'Not Done') {
              totalScore = -50000;
            }
            else {
              totalScore = (useData[i].sectionList[nowSection].sectionRecordList[j].recordTime * -1);
            }

            // 이미 저장된 내역 확인 Flag //
            let isExist = false;
            // 이미 저장된 유저 점수가 있는지 확인 //
            for (let x = 0; x < userRankingList.length; x++) {
              // 이미 점수가 있으면 기존점수에 + 하기 //
              if (userRankingList[x].linkCoachId === useData[i].sectionList[nowSection].sectionRecordList[j].linkCoachId) {
                totalScore += totalScore;
                // 저장 플래그 //
                isExist = true;
              }
            }

            // 저장된게 없으면 //
            if (!isExist) {


              let totalText = ''
              let min = 0;
              let sec = 0;
              let decimalPoint = (useData[i].sectionList[nowSection].sectionRecordList[j].recordTime % 1).toFixed(2);

              min = parseInt(useData[i].sectionList[nowSection].sectionRecordList[j].recordTime / 60);

              if (decimalPoint !== '0.00') sec = parseInt(useData[i].sectionList[nowSection].sectionRecordList[j].recordTime % 60) + parseFloat(decimalPoint);
              else sec = parseInt(useData[i].sectionList[nowSection].sectionRecordList[j].recordTime % 60);

              if (sec === 0) {
                if (min < 10) totalText = '0' + min + ':00';
                else totalText = min + ':00';
              }
              else {
                if (min < 10) {
                  if (sec < 10) totalText = min + ':0' + sec;
                  else totalText = min + ':' + sec;
                }
                else {
                  if (sec < 10) totalText = min + ':0' + sec;
                  else totalText = min + ':' + sec;
                }
              }

              finalScoreText = totalText;

              let rankingInfo = {
                linkCoachId: useData[i].sectionList[nowSection].sectionRecordList[j].linkCoachId,
                userName: useData[i].sectionList[nowSection].sectionRecordList[j].userName,
                userProfile: useData[i].sectionList[nowSection].sectionRecordList[j].userProfile,
                createdDate: useData[i].sectionList[nowSection].sectionRecordList[j].createdDate,
                finalScoreText: finalScoreText,
                totalScore: totalScore
              }

              userRankingList.push(rankingInfo);
            }
          }
        }
      }
      // 횟수, 무게, 거리, 포인트 기준 점수 산출일 경우 //
      else if (useData[i].sectionList[nowSection].scoreKeyword === 'Reps'
        || useData[i].sectionList[nowSection].scoreKeyword === 'Weight'
        || useData[i].sectionList[nowSection].scoreKeyword === 'Distance'
        || useData[i].sectionList[nowSection].scoreKeyword === 'Points'
        || useData[i].sectionList[nowSection].scoreKeyword === 'Peak Watts'
        || useData[i].sectionList[nowSection].scoreKeyword === 'Max Speed') {

        let finalScoreText = '';
        
        // 높은 점수 기준 //
        if (useData[i].sectionList[nowSection].scorePriority === 'heigher') {
          for (let j = 0; j < useData[i].sectionList[nowSection].sectionRecordList.length; j++) {
            let totalScore = 0;

            if (useData[i].sectionList[nowSection].scoreKeyword === 'Weight') {
              if (useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordUnit === 'Kg') totalScore = (useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordValue * 2.205);
              else totalScore = useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordValue;
            }
            else if (useData[i].sectionList[nowSection].scoreKeyword === 'Distance') {
              if (useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordUnit === 'Km') totalScore = (useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordValue / 1000);
              else if (useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordUnit === 'cm') totalScore = (useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordValue * 100);
              else if (useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordUnit === 'inch') totalScore = (useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordValue * 39.37);
              else if (useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordUnit === 'Feet') totalScore = (useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordValue * 3.281);
              else totalScore = useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordValue;
            }
            else totalScore = useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordValue;

            if (useData[i].sectionList[nowSection].scoreKeyword === 'Weight') finalScoreText = useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordValue + ' ' + (useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordUnit === 'Pounds' ? 'lb' : useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordUnit);
            else if (useData[i].sectionList[nowSection].scoreKeyword === 'Distance') finalScoreText = useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordValue + ' ' + useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordUnit;
            else finalScoreText = useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordValue + ' ' + useData[i].sectionList[nowSection].scoreKeyword;

            // 이미 저장된 내역 확인 Flag //
            let isExist = false;
            // 이미 저장된 유저 점수가 있는지 확인 //
            for (let x = 0; x < userRankingList.length; x++) {
              // 이미 점수가 있으면 기존점수에 + 하기 //
              if (userRankingList[x].linkCoachId === useData[i].sectionList[nowSection].sectionRecordList[j].linkCoachId) {
                totalScore += totalScore;
                // 저장 플래그 //
                isExist = true;
              }
            }

            // 저장된게 없으면 //
            if (!isExist) {
              let rankingInfo = {
                linkCoachId: useData[i].sectionList[nowSection].sectionRecordList[j].linkCoachId,
                userName: useData[i].sectionList[nowSection].sectionRecordList[j].userName,
                userProfile: useData[i].sectionList[nowSection].sectionRecordList[j].userProfile,
                createdDate: useData[i].sectionList[nowSection].sectionRecordList[j].createdDate,
                finalScoreText: finalScoreText,
                totalScore: totalScore
              }

              userRankingList.push(rankingInfo);
            }
          }
        }
        // 낮은 점수 기준 //
        else {
          for (let j = 0; j < useData[i].sectionList[nowSection].sectionRecordList.length; j++) {
            let totalScore = 0;

            if (useData[i].sectionList[nowSection].scoreKeyword === 'Weight') {
              if (useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordUnit === 'Kg') totalScore = (useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordValue * 2.205);
              else totalScore = useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordValue;
            }
            else if (useData[i].sectionList[nowSection].scoreKeyword === 'Distance') {
              if (useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordUnit === 'Km') totalScore = (useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordValue / 1000);
              else if (useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordUnit === 'cm') totalScore = (useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordValue * 100);
              else if (useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordUnit === 'inch') totalScore = (useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordValue * 39.37);
              else if (useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordUnit === 'Feet') totalScore = (useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordValue * 3.281);
              else totalScore = useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordValue;
            }
            else totalScore = useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordValue;

            if (useData[i].sectionList[nowSection].scoreKeyword === 'Weight') finalScoreText = useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordValue + ' ' + (useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordUnit === 'Pounds' ? 'lb' : useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordUnit);
            else if (useData[i].sectionList[nowSection].scoreKeyword === 'Distance') finalScoreText = useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordValue + ' ' + useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordUnit;
            else finalScoreText = useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordValue + ' ' + useData[i].sectionList[nowSection].scoreKeyword;

            if (useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordValue === 0
              || useData[i].sectionList[nowSection].sectionRecordList[j].success === 'Not Done') {
              totalScore = -50000;
            }
            else {
              totalScore = (useData[i].sectionList[nowSection].sectionRecordList[j].customKeywordValue * -1);
            }

            // 이미 저장된 내역 확인 Flag //
            let isExist = false;
            // 이미 저장된 유저 점수가 있는지 확인 //
            for (let x = 0; x < userRankingList.length; x++) {
              // 이미 점수가 있으면 기존점수에 + 하기 //
              if (userRankingList[x].linkCoachId === useData[i].sectionList[nowSection].sectionRecordList[j].linkCoachId) {
                totalScore += totalScore;
                // 저장 플래그 //
                isExist = true;
              }
            }

            // 저장된게 없으면 //
            if (!isExist) {
              let rankingInfo = {
                linkCoachId: useData[i].sectionList[nowSection].sectionRecordList[j].linkCoachId,
                userName: useData[i].sectionList[nowSection].sectionRecordList[j].userName,
                userProfile: useData[i].sectionList[nowSection].sectionRecordList[j].userProfile,
                createdDate: useData[i].sectionList[nowSection].sectionRecordList[j].createdDate,
                finalScoreText: finalScoreText,
                totalScore: totalScore
              }

              userRankingList.push(rankingInfo);
            }
          }
        }
      }
      // 라운드 횟수 기준 점수 산출일 경우 //
      else if (useData[i].sectionList[nowSection].scoreKeyword === 'Rounds And Reps') {
        let totalScore = 0;

        // 높은 점수 기준 //
        if (useData[i].sectionList[nowSection].scorePriority === 'heigher') {
          for (let j = 0; j < useData[i].sectionList[nowSection].sectionRecordList.length; j++) {
            totalScore = 0;

            let round = (useData[i].sectionList[nowSection].sectionRecordList[j].customLastRound * 100);
            let reps = (useData[i].sectionList[nowSection].sectionRecordList[j].customLastReps);

            finalScoreText = useData[i].sectionList[nowSection].sectionRecordList[j].customLastRound + ' Rounds ' + useData[i].sectionList[nowSection].sectionRecordList[j].customLastReps + ' Reps';

            totalScore += (round + reps);

            // 이미 저장된 내역 확인 Flag //
            let isExist = false;
            // 이미 저장된 유저 점수가 있는지 확인 //
            for (let x = 0; x < userRankingList.length; x++) {
              // 이미 점수가 있으면 기존점수에 + 하기 //
              if (userRankingList[x].linkCoachId === useData[i].sectionList[nowSection].sectionRecordList[j].linkCoachId) {

                totalScore += totalScore;
                // 저장 플래그 //
                isExist = true;
              }
            }

            // 저장된게 없으면 //
            if (!isExist) {
              let rankingInfo = {
                linkCoachId: useData[i].sectionList[nowSection].sectionRecordList[j].linkCoachId,
                userName: useData[i].sectionList[nowSection].sectionRecordList[j].userName,
                userProfile: useData[i].sectionList[nowSection].sectionRecordList[j].userProfile,
                createdDate: useData[i].sectionList[nowSection].sectionRecordList[j].createdDate,
                finalScoreText: finalScoreText,
                totalScore: totalScore
              }
              userRankingList.push(rankingInfo);
            }
          }
        }
        // 낮은 점수 기준 //
        else {
          for (let j = 0; j < useData[i].sectionList[nowSection].sectionRecordList.length; j++) {
            let round = (useData[i].sectionList[nowSection].sectionRecordList[j].customLastRound * 100);
            let reps = (useData[i].sectionList[nowSection].sectionRecordList[j].customLastReps);

            finalScoreText = useData[i].sectionList[nowSection].sectionRecordList[j].customLastRound + ' Rounds ' + useData[i].sectionList[nowSection].sectionRecordList[j].customLastReps + ' Reps';

            totalScore = 0;

            if (useData[i].sectionList[nowSection].sectionRecordList[j].success === 'Not Done') {
              totalScore = -50000;
            }
            else {
              totalScore += ((round + reps) * -1);
            }

            // 이미 저장된 내역 확인 Flag //
            let isExist = false;
            // 이미 저장된 유저 점수가 있는지 확인 //
            for (let x = 0; x < userRankingList.length; x++) {
              // 이미 점수가 있으면 기존점수에 + 하기 //
              if (userRankingList[x].linkCoachId === useData[i].sectionList[nowSection].sectionRecordList[j].linkCoachId) {
                totalScore += totalScore;
                // 저장 플래그 //
                isExist = true;
              }
            }

            // 저장된게 없으면 //
            if (!isExist) {
              let rankingInfo = {
                linkCoachId: useData[i].sectionList[nowSection].sectionRecordList[j].linkCoachId,
                userName: useData[i].sectionList[nowSection].sectionRecordList[j].userName,
                userProfile: useData[i].sectionList[nowSection].sectionRecordList[j].userProfile,
                createdDate: useData[i].sectionList[nowSection].sectionRecordList[j].createdDate,
                finalScoreText: finalScoreText,
                totalScore: totalScore
              }

              userRankingList.push(rankingInfo);
            }
          }
        }
      }
    }
    // Custom이 아닌경우 점수로 순위 산출 //
    else {
      for (let h = 0; h < useData[i].sectionList[nowSection].sectionRecordList.length; h++) {
        // 점수 구성 항목 //
        let round = 0;
        let roundOfCount = 0;
        let weight = 0;
        let reps = 0;
        let calories = 0;
        let distance = 0;
        let height = 0;
        let time = useData[i].sectionList[nowSection].sectionRecordList[h].recordTime;
        let timeRemaining = 0;
        let successScore = 0;
        let finalScoreText = '';
        let minusPointCount = 0;

        let lastMovementBonus = 1;
        let lastExerciseIndex = 0;
        let lastRound = useData[i].sectionList[nowSection].sectionRecordList[h].completeRound * 10000;
        let lastReps = useData[i].sectionList[nowSection].sectionRecordList[h].lastRound;

        if (useData[i].sectionList[nowSection].tag === 'AMRAP' || useData[i].sectionList[nowSection].sectionRecordList[h].success !== 'Success') {
          for (let j = 0; j < useData[i].sectionList[nowSection].sectionRecordList[h].exerciseList.length; j++) {
            if (useData[i].sectionList[nowSection].sectionRecordList[h].lastExerciseId === useData[i].sectionList[nowSection].sectionRecordList[h].exerciseList[j].exerciseId) {
              lastExerciseIndex = j;
              lastMovementBonus = (useData[i].sectionList[nowSection].sectionRecordList[h].exerciseList[j].exerciseIndex * 100);
              break;
            }
          }
        }

        let buyinExceptIndexs = [];
        let mainExceptIndexs = [];
        let emomExceptIndexs = [];
        let buyoutExceptIndexs = [];
        
        // buyin Scale 진행 내역 찾기 //
        for (let z = 0; z < useData[i].sectionList[nowSection].sectionRecordList[h].buyinScaleExerciseList.length; z++) {
          if (useData[i].sectionList[nowSection].sectionRecordList[h].buyinScaleExerciseList[z].isProgress) {
            // 순위 산출을 위한 단위 통일 //
            let weightUnitScale = useData[i].sectionList[nowSection].sectionRecordList[h].buyinScaleExerciseList[z].weightUnit === 'Kg' ? 2.2 : 1;
            let caloriesUnitScale = useData[i].sectionList[nowSection].sectionRecordList[h].buyinScaleExerciseList[z].caloriesUnit === 'Kcal' ? 1000 : 1;
            let distanceUnitScale = 1;
            let heightUnitScale = 1;

            if (useData[i].sectionList[nowSection].sectionRecordList[h].buyinScaleExerciseList[z].heightUnit === 'Km') distanceUnitScale = 1000;
            else if (useData[i].sectionList[nowSection].sectionRecordList[h].buyinScaleExerciseList[z].heightUnit === 'inch') distanceUnitScale = (1 / 39.37);
            else if (useData[i].sectionList[nowSection].sectionRecordList[h].buyinScaleExerciseList[z].heightUnit === 'Feet') distanceUnitScale = (1 / 3.281);
            else if (useData[i].sectionList[nowSection].sectionRecordList[h].buyinScaleExerciseList[z].heightUnit === 'inch') distanceUnitScale = (1 / 100);

            if (useData[i].sectionList[nowSection].sectionRecordList[h].buyinScaleExerciseList[z].heightUnit === 'Km') heightUnitScale = 1000;
            else if (useData[i].sectionList[nowSection].sectionRecordList[h].buyinScaleExerciseList[z].heightUnit === 'inch') heightUnitScale = (1 / 39.37);
            else if (useData[i].sectionList[nowSection].sectionRecordList[h].buyinScaleExerciseList[z].heightUnit === 'Feet') heightUnitScale = (1 / 3.281);
            else if (useData[i].sectionList[nowSection].sectionRecordList[h].buyinScaleExerciseList[z].heightUnit === 'inch') heightUnitScale = (1 / 100);

            // 무게 전부 더하기 //
            weight += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].buyinScaleExerciseList[z].weight) * weightUnitScale * 0.5);
            // 횟수 전부 더하기 //
            reps += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].buyinScaleExerciseList[z].count === '' ? 0 : useData[i].sectionList[nowSection].sectionRecordList[h].buyinScaleExerciseList[z].count) * 0.5);
            // 칼로리 전부 더하기 //
            calories += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].buyinScaleExerciseList[z].calories) * caloriesUnitScale * 0.5);
            // 거리 전부 더하기 //
            distance += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].buyinScaleExerciseList[z].distance) * distanceUnitScale * 0.5);
            // 높이 전부 더하기 //
            height += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].buyinScaleExerciseList[z].height) * heightUnitScale * 0.5);

            buyinExceptIndexs.push(useData[i].sectionList[nowSection].sectionRecordList[h].buyinScaleExerciseList[z].dependencyIndex);
          }
        }
        
        // main Scale 진행 내역 찾기 //
        for (let z = 0; z < useData[i].sectionList[nowSection].sectionRecordList[h].mainScaleExerciseList.length; z++) {
          if (useData[i].sectionList[nowSection].sectionRecordList[h].mainScaleExerciseList[z].isProgress) {
            // 순위 산출을 위한 단위 통일 //
            let weightUnitScale = useData[i].sectionList[nowSection].sectionRecordList[h].mainScaleExerciseList[z].weightUnit === 'Kg' ? 2.2 : 1;
            let caloriesUnitScale = useData[i].sectionList[nowSection].sectionRecordList[h].mainScaleExerciseList[z].caloriesUnit === 'Kcal' ? 1000 : 1;
            let distanceUnitScale = 1;
            let heightUnitScale = 1;

            if (useData[i].sectionList[nowSection].sectionRecordList[h].mainScaleExerciseList[z].heightUnit === 'Km') distanceUnitScale = 1000;
            else if (useData[i].sectionList[nowSection].sectionRecordList[h].mainScaleExerciseList[z].heightUnit === 'inch') distanceUnitScale = (1 / 39.37);
            else if (useData[i].sectionList[nowSection].sectionRecordList[h].mainScaleExerciseList[z].heightUnit === 'Feet') distanceUnitScale = (1 / 3.281);
            else if (useData[i].sectionList[nowSection].sectionRecordList[h].mainScaleExerciseList[z].heightUnit === 'inch') distanceUnitScale = (1 / 100);

            if (useData[i].sectionList[nowSection].sectionRecordList[h].mainScaleExerciseList[z].heightUnit === 'Km') heightUnitScale = 1000;
            else if (useData[i].sectionList[nowSection].sectionRecordList[h].mainScaleExerciseList[z].heightUnit === 'inch') heightUnitScale = (1 / 39.37);
            else if (useData[i].sectionList[nowSection].sectionRecordList[h].mainScaleExerciseList[z].heightUnit === 'Feet') heightUnitScale = (1 / 3.281);
            else if (useData[i].sectionList[nowSection].sectionRecordList[h].mainScaleExerciseList[z].heightUnit === 'inch') heightUnitScale = (1 / 100);

            // 무게 전부 더하기 //
            weight += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].mainScaleExerciseList[z].weight) * weightUnitScale * 0.5);
            // 횟수 전부 더하기 //
            reps += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].mainScaleExerciseList[z].count === '' ? 0 : useData[i].sectionList[nowSection].sectionRecordList[h].mainScaleExerciseList[z].count) * 0.5);
            // 칼로리 전부 더하기 //
            calories += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].mainScaleExerciseList[z].calories) * caloriesUnitScale * 0.5);
            // 거리 전부 더하기 //
            distance += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].mainScaleExerciseList[z].distance) * distanceUnitScale * 0.5);
            // 높이 전부 더하기 //
            height += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].mainScaleExerciseList[z].height) * heightUnitScale * 0.5);

            mainExceptIndexs.push(useData[i].sectionList[nowSection].sectionRecordList[h].mainScaleExerciseList[z].dependencyIndex);
          }
        }

        // emom Scale 진행 내역 찾기 //
        for (let z = 0; z < useData[i].sectionList[nowSection].sectionRecordList[h].emomScaleExerciseList.length; z++) {
          if (useData[i].sectionList[nowSection].sectionRecordList[h].emomScaleExerciseList[z].isProgress) {
            // 순위 산출을 위한 단위 통일 //
            let weightUnitScale = useData[i].sectionList[nowSection].sectionRecordList[h].emomScaleExerciseList[z].weightUnit === 'Kg' ? 2.2 : 1;
            let caloriesUnitScale = useData[i].sectionList[nowSection].sectionRecordList[h].emomScaleExerciseList[z].caloriesUnit === 'Kcal' ? 1000 : 1;
            let distanceUnitScale = 1;
            let heightUnitScale = 1;

            if (useData[i].sectionList[nowSection].sectionRecordList[h].emomScaleExerciseList[z].heightUnit === 'Km') distanceUnitScale = 1000;
            else if (useData[i].sectionList[nowSection].sectionRecordList[h].emomScaleExerciseList[z].heightUnit === 'inch') distanceUnitScale = (1 / 39.37);
            else if (useData[i].sectionList[nowSection].sectionRecordList[h].emomScaleExerciseList[z].heightUnit === 'Feet') distanceUnitScale = (1 / 3.281);
            else if (useData[i].sectionList[nowSection].sectionRecordList[h].emomScaleExerciseList[z].heightUnit === 'inch') distanceUnitScale = (1 / 100);

            if (useData[i].sectionList[nowSection].sectionRecordList[h].emomScaleExerciseList[z].heightUnit === 'Km') heightUnitScale = 1000;
            else if (useData[i].sectionList[nowSection].sectionRecordList[h].emomScaleExerciseList[z].heightUnit === 'inch') heightUnitScale = (1 / 39.37);
            else if (useData[i].sectionList[nowSection].sectionRecordList[h].emomScaleExerciseList[z].heightUnit === 'Feet') heightUnitScale = (1 / 3.281);
            else if (useData[i].sectionList[nowSection].sectionRecordList[h].emomScaleExerciseList[z].heightUnit === 'inch') heightUnitScale = (1 / 100);

            // 무게 전부 더하기 //
            weight += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].emomScaleExerciseList[z].weight) * weightUnitScale * 0.5);
            // 횟수 전부 더하기 //
            reps += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].emomScaleExerciseList[z].count === '' ? 0 : useData[i].sectionList[nowSection].sectionRecordList[h].emomScaleExerciseList[z].count) * 0.5);
            // 칼로리 전부 더하기 //
            calories += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].emomScaleExerciseList[z].calories) * caloriesUnitScale * 0.5);
            // 거리 전부 더하기 //
            distance += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].emomScaleExerciseList[z].distance) * distanceUnitScale * 0.5);
            // 높이 전부 더하기 //
            height += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].emomScaleExerciseList[z].height) * heightUnitScale * 0.5);

            emomExceptIndexs.push(useData[i].sectionList[nowSection].sectionRecordList[h].emomScaleExerciseList[z].dependencyIndex);
          }
        }

        // buyout Scale 진행 내역 찾기 //
        for (let z = 0; z < useData[i].sectionList[nowSection].sectionRecordList[h].buyoutScaleExerciseList.length; z++) {
          if (useData[i].sectionList[nowSection].sectionRecordList[h].buyoutScaleExerciseList[z].isProgress) {
            // 순위 산출을 위한 단위 통일 //
            let weightUnitScale = useData[i].sectionList[nowSection].sectionRecordList[h].buyoutScaleExerciseList[z].weightUnit === 'Kg' ? 2.2 : 1;
            let caloriesUnitScale = useData[i].sectionList[nowSection].sectionRecordList[h].buyoutScaleExerciseList[z].caloriesUnit === 'Kcal' ? 1000 : 1;
            let distanceUnitScale = 1;
            let heightUnitScale = 1;

            if (useData[i].sectionList[nowSection].sectionRecordList[h].buyoutScaleExerciseList[z].heightUnit === 'Km') distanceUnitScale = 1000;
            else if (useData[i].sectionList[nowSection].sectionRecordList[h].buyoutScaleExerciseList[z].heightUnit === 'inch') distanceUnitScale = (1 / 39.37);
            else if (useData[i].sectionList[nowSection].sectionRecordList[h].buyoutScaleExerciseList[z].heightUnit === 'Feet') distanceUnitScale = (1 / 3.281);
            else if (useData[i].sectionList[nowSection].sectionRecordList[h].buyoutScaleExerciseList[z].heightUnit === 'inch') distanceUnitScale = (1 / 100);

            if (useData[i].sectionList[nowSection].sectionRecordList[h].buyoutScaleExerciseList[z].heightUnit === 'Km') heightUnitScale = 1000;
            else if (useData[i].sectionList[nowSection].sectionRecordList[h].buyoutScaleExerciseList[z].heightUnit === 'inch') heightUnitScale = (1 / 39.37);
            else if (useData[i].sectionList[nowSection].sectionRecordList[h].buyoutScaleExerciseList[z].heightUnit === 'Feet') heightUnitScale = (1 / 3.281);
            else if (useData[i].sectionList[nowSection].sectionRecordList[h].buyoutScaleExerciseList[z].heightUnit === 'inch') heightUnitScale = (1 / 100);

            // 무게 전부 더하기 //
            weight += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].buyoutScaleExerciseList[z].weight) * weightUnitScale * 0.5);
            // 횟수 전부 더하기 //
            reps += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].buyoutScaleExerciseList[z].count === '' ? 0 : useData[i].sectionList[nowSection].sectionRecordList[h].buyoutScaleExerciseList[z].count) * 0.5);
            // 칼로리 전부 더하기 //
            calories += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].buyoutScaleExerciseList[z].calories) * caloriesUnitScale * 0.5);
            // 거리 전부 더하기 //
            distance += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].buyoutScaleExerciseList[z].distance) * distanceUnitScale * 0.5);
            // 높이 전부 더하기 //
            height += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].buyoutScaleExerciseList[z].height) * heightUnitScale * 0.5);

            buyoutExceptIndexs.push(useData[i].sectionList[nowSection].sectionRecordList[h].buyoutScaleExerciseList[z].dependencyIndex);
          }
        }

        minusPointCount = (buyinExceptIndexs.length + mainExceptIndexs.length + emomExceptIndexs.length + buyoutExceptIndexs.length);

        // buyin 진행 내역 찾기 //
        for (let z = 0; z < useData[i].sectionList[nowSection].sectionRecordList[h].buyinExerciseList.length; z++) {
          if (!buyinExceptIndexs.includes(useData[i].sectionList[nowSection].sectionRecordList[h].buyinExerciseList[z].exerciseIndex - 1)) {
            // 순위 산출을 위한 단위 통일 //
            let weightUnitScale = useData[i].sectionList[nowSection].sectionRecordList[h].buyinExerciseList[z].weightUnit === 'Kg' ? 2.2 : 1;
            let caloriesUnitScale = useData[i].sectionList[nowSection].sectionRecordList[h].buyinExerciseList[z].caloriesUnit === 'Kcal' ? 1000 : 1;
            let distanceUnitScale = 1;
            let heightUnitScale = 1;

            if (useData[i].sectionList[nowSection].sectionRecordList[h].buyinExerciseList[z].heightUnit === 'Km') distanceUnitScale = 1000;
            else if (useData[i].sectionList[nowSection].sectionRecordList[h].buyinExerciseList[z].heightUnit === 'inch') distanceUnitScale = (1 / 39.37);
            else if (useData[i].sectionList[nowSection].sectionRecordList[h].buyinExerciseList[z].heightUnit === 'Feet') distanceUnitScale = (1 / 3.281);
            else if (useData[i].sectionList[nowSection].sectionRecordList[h].buyinExerciseList[z].heightUnit === 'inch') distanceUnitScale = (1 / 100);

            if (useData[i].sectionList[nowSection].sectionRecordList[h].buyinExerciseList[z].heightUnit === 'Km') heightUnitScale = 1000;
            else if (useData[i].sectionList[nowSection].sectionRecordList[h].buyinExerciseList[z].heightUnit === 'inch') heightUnitScale = (1 / 39.37);
            else if (useData[i].sectionList[nowSection].sectionRecordList[h].buyinExerciseList[z].heightUnit === 'Feet') heightUnitScale = (1 / 3.281);
            else if (useData[i].sectionList[nowSection].sectionRecordList[h].buyinExerciseList[z].heightUnit === 'inch') heightUnitScale = (1 / 100);

            // 무게 전부 더하기 //
            weight += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].buyinExerciseList[z].weight) * weightUnitScale);
            // 횟수 전부 더하기 //
            reps += parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].buyinExerciseList[z].count === '' ? 0 : useData[i].sectionList[nowSection].sectionRecordList[h].buyinExerciseList[z].count);
            // 칼로리 전부 더하기 //
            calories += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].buyinExerciseList[z].calories) * caloriesUnitScale);
            // 거리 전부 더하기 //
            distance += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].buyinExerciseList[z].distance) * distanceUnitScale);
            // 높이 전부 더하기 //
            height += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].buyinExerciseList[z].height) * heightUnitScale);
          }
        }

        // main 진행 내역 찾기 //
        for (let z = 0; z < useData[i].sectionList[nowSection].sectionRecordList[h].mainExerciseList.length; z++) {
          if (!mainExceptIndexs.includes(useData[i].sectionList[nowSection].sectionRecordList[h].mainExerciseList[z].exerciseIndex - 1)) {
            // 순위 산출을 위한 단위 통일 //
            let weightUnitScale = useData[i].sectionList[nowSection].sectionRecordList[h].mainExerciseList[z].weightUnit === 'Kg' ? 2.2 : 1;
            let caloriesUnitScale = useData[i].sectionList[nowSection].sectionRecordList[h].mainExerciseList[z].caloriesUnit === 'Kcal' ? 1000 : 1;
            let distanceUnitScale = 1;
            let heightUnitScale = 1;

            if (useData[i].sectionList[nowSection].sectionRecordList[h].mainExerciseList[z].heightUnit === 'Km') distanceUnitScale = 1000;
            else if (useData[i].sectionList[nowSection].sectionRecordList[h].mainExerciseList[z].heightUnit === 'inch') distanceUnitScale = (1 / 39.37);
            else if (useData[i].sectionList[nowSection].sectionRecordList[h].mainExerciseList[z].heightUnit === 'Feet') distanceUnitScale = (1 / 3.281);
            else if (useData[i].sectionList[nowSection].sectionRecordList[h].mainExerciseList[z].heightUnit === 'inch') distanceUnitScale = (1 / 100);

            if (useData[i].sectionList[nowSection].sectionRecordList[h].mainExerciseList[z].heightUnit === 'Km') heightUnitScale = 1000;
            else if (useData[i].sectionList[nowSection].sectionRecordList[h].mainExerciseList[z].heightUnit === 'inch') heightUnitScale = (1 / 39.37);
            else if (useData[i].sectionList[nowSection].sectionRecordList[h].mainExerciseList[z].heightUnit === 'Feet') heightUnitScale = (1 / 3.281);
            else if (useData[i].sectionList[nowSection].sectionRecordList[h].mainExerciseList[z].heightUnit === 'inch') heightUnitScale = (1 / 100);

            // 무게 전부 더하기 //
            weight += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].mainExerciseList[z].weight) * weightUnitScale);
            // 횟수 전부 더하기 //
            reps += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].mainExerciseList[z].count === '' ? 0 : useData[i].sectionList[nowSection].sectionRecordList[h].mainExerciseList[z].count));
            // 칼로리 전부 더하기 //
            calories += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].mainExerciseList[z].calories) * caloriesUnitScale);
            // 거리 전부 더하기 //
            distance += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].mainExerciseList[z].distance) * distanceUnitScale);
            // 높이 전부 더하기 //
            height += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].mainExerciseList[z].height) * heightUnitScale);
          }
        }

        // emom 진행 내역 찾기 //
        for (let z = 0; z < useData[i].sectionList[nowSection].sectionRecordList[h].emomExerciseList.length; z++) {
          if (!emomExceptIndexs.includes(useData[i].sectionList[nowSection].sectionRecordList[h].emomExerciseList[z].exerciseIndex - 1)) {
            // 순위 산출을 위한 단위 통일 //
            let weightUnitScale = useData[i].sectionList[nowSection].sectionRecordList[h].emomExerciseList[z].weightUnit === 'Kg' ? 2.2 : 1;
            let caloriesUnitScale = useData[i].sectionList[nowSection].sectionRecordList[h].emomExerciseList[z].caloriesUnit === 'Kcal' ? 1000 : 1;
            let distanceUnitScale = 1;
            let heightUnitScale = 1;

            if (useData[i].sectionList[nowSection].sectionRecordList[h].emomExerciseList[z].heightUnit === 'Km') distanceUnitScale = 1000;
            else if (useData[i].sectionList[nowSection].sectionRecordList[h].emomExerciseList[z].heightUnit === 'inch') distanceUnitScale = (1 / 39.37);
            else if (useData[i].sectionList[nowSection].sectionRecordList[h].emomExerciseList[z].heightUnit === 'Feet') distanceUnitScale = (1 / 3.281);
            else if (useData[i].sectionList[nowSection].sectionRecordList[h].emomExerciseList[z].heightUnit === 'inch') distanceUnitScale = (1 / 100);

            if (useData[i].sectionList[nowSection].sectionRecordList[h].emomExerciseList[z].heightUnit === 'Km') heightUnitScale = 1000;
            else if (useData[i].sectionList[nowSection].sectionRecordList[h].emomExerciseList[z].heightUnit === 'inch') heightUnitScale = (1 / 39.37);
            else if (useData[i].sectionList[nowSection].sectionRecordList[h].emomExerciseList[z].heightUnit === 'Feet') heightUnitScale = (1 / 3.281);
            else if (useData[i].sectionList[nowSection].sectionRecordList[h].emomExerciseList[z].heightUnit === 'inch') heightUnitScale = (1 / 100);

            // 무게 전부 더하기 //
            weight += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].emomExerciseList[z].weight) * weightUnitScale);
            // 횟수 전부 더하기 //
            reps += parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].emomExerciseList[z].count === '' ? 0 : useData[i].sectionList[nowSection].sectionRecordList[h].emomExerciseList[z].count);
            // 칼로리 전부 더하기 //
            calories += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].emomExerciseList[z].calories) * caloriesUnitScale);
            // 거리 전부 더하기 //
            distance += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].emomExerciseList[z].distance) * distanceUnitScale);
            // 높이 전부 더하기 //
            height += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].emomExerciseList[z].height) * heightUnitScale);
          }
        }

        // buyout 진행 내역 찾기 //
        for (let z = 0; z < useData[i].sectionList[nowSection].sectionRecordList[h].buyoutExerciseList.length; z++) {
          if (!buyoutExceptIndexs.includes(useData[i].sectionList[nowSection].sectionRecordList[h].buyoutExerciseList[z].exerciseIndex - 1)) {
            // 순위 산출을 위한 단위 통일 //
            let weightUnitScale = useData[i].sectionList[nowSection].sectionRecordList[h].buyoutExerciseList[z].weightUnit === 'Kg' ? 2.2 : 1;
            let caloriesUnitScale = useData[i].sectionList[nowSection].sectionRecordList[h].buyoutExerciseList[z].caloriesUnit === 'Kcal' ? 1000 : 1;
            let distanceUnitScale = 1;
            let heightUnitScale = 1;

            if (useData[i].sectionList[nowSection].sectionRecordList[h].buyoutExerciseList[z].heightUnit === 'Km') distanceUnitScale = 1000;
            else if (useData[i].sectionList[nowSection].sectionRecordList[h].buyoutExerciseList[z].heightUnit === 'inch') distanceUnitScale = (1 / 39.37);
            else if (useData[i].sectionList[nowSection].sectionRecordList[h].buyoutExerciseList[z].heightUnit === 'Feet') distanceUnitScale = (1 / 3.281);
            else if (useData[i].sectionList[nowSection].sectionRecordList[h].buyoutExerciseList[z].heightUnit === 'inch') distanceUnitScale = (1 / 100);

            if (useData[i].sectionList[nowSection].sectionRecordList[h].buyoutExerciseList[z].heightUnit === 'Km') heightUnitScale = 1000;
            else if (useData[i].sectionList[nowSection].sectionRecordList[h].buyoutExerciseList[z].heightUnit === 'inch') heightUnitScale = (1 / 39.37);
            else if (useData[i].sectionList[nowSection].sectionRecordList[h].buyoutExerciseList[z].heightUnit === 'Feet') heightUnitScale = (1 / 3.281);
            else if (useData[i].sectionList[nowSection].sectionRecordList[h].buyoutExerciseList[z].heightUnit === 'inch') heightUnitScale = (1 / 100);

            // 무게 전부 더하기 //
            weight += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].buyoutExerciseList[z].weight) * weightUnitScale);
            // 횟수 전부 더하기 //
            reps += parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].buyoutExerciseList[z].count === '' ? 0 : useData[i].sectionList[nowSection].sectionRecordList[h].buyoutExerciseList[z].count);
            // 칼로리 전부 더하기 //
            calories += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].buyoutExerciseList[z].calories) * caloriesUnitScale);
            // 거리 전부 더하기 //
            distance += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].buyoutExerciseList[z].distance) * distanceUnitScale);
            // 높이 전부 더하기 //
            height += (parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].buyoutExerciseList[z].height) * heightUnitScale);
          }
        }


        let roundOfCountList = useData[i].sectionList[nowSection].sectionRecordList[h].roundOfCount.split('-')

        for (let z = 0; z < roundOfCountList.length; z++) {
          if (roundOfCountList[z] !== '') roundOfCount += parseInt(roundOfCountList[z]);
        }
        
        // 제한시간이 설정되어있는 경우 ( 제한시간 - 진행한 시간 ) 으로 추산 // 
        if (useData[i].sectionList[nowSection].isUseTimeLimit) {
          timeRemaining += useData[i].sectionList[nowSection].timeLimit - useData[i].sectionList[nowSection].sectionRecordList[h].recordTime;
        }

        // 운동을 끝까지 완료한 경우 //
        if (useData[i].sectionList[nowSection].sectionRecordList[h].success === 'Success') {
          // 진행을 완료 가산점 //
          if (minusPointCount === 0) successScore += 70000000;
          else successScore += 10000000;

          // 진행한 라운드 가산점 ( 운동 성공 => 전체 라운드 * 운동 개수 ) //
          round = useData[i].sectionList[nowSection].round * useData[i].sectionList[nowSection].exerciseList.length;

          if (tag.includes('ForTime-')) {
            let totalText = ''
            let min = 0;
            let sec = 0;
            let decimalPoint = (useData[i].sectionList[nowSection].sectionRecordList[h].recordTime % 1).toFixed(2);
      
            min = parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].recordTime / 60);
      
            if (decimalPoint !== '0.00') sec = parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].recordTime % 60) + parseFloat(decimalPoint);
            else sec = parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].recordTime % 60);
      
            if (sec === 0) {
              if (min < 10) totalText = min + ':00';
              else totalText = min + ':00';
            }
            else {
              if (min < 10) {
                if (sec < 10) totalText = min + ':0' + sec;
                else totalText = min + ':' + sec;
              }
              else {
                if (sec < 10) totalText = min + ':0' + sec;
                else totalText = min + ':' + sec;
              }
            }

            finalScoreText = totalText;
          }
          
        }
        // 운동이 중간에 종료된 경우 //
        else {
          // 마지막 진행한 운동 찾기 //
          let division = 'Buyin';
          let index = 0;

          // 마지막 운동 찾기 //
          for (let z = 0; z < useData[i].sectionList[nowSection].sectionRecordList[h].exerciseList.length; z++) {
            if (useData[i].sectionList[nowSection].sectionRecordList[h].lastExerciseId === useData[i].sectionList[nowSection].sectionRecordList[h].exerciseList[z].exerciseId) {
              division = useData[i].sectionList[nowSection].sectionRecordList[h].exerciseList[z].division;
              index = useData[i].sectionList[nowSection].sectionRecordList[h].exerciseList[z].exerciseIndex;
              break;
            }
          }

          // 진행한 라운드 가산점 ( 운동 중도 포기 => 완벽히 이행한 라운드 * 운동 개수 + 마지막 운동 개수) //
          round = useData[i].sectionList[nowSection].sectionRecordList[h].completeRound * useData[i].sectionList[nowSection].exerciseList.length;

          // 이행하지 못하고 남은 라운드 //
          let remainingRound = useData[i].sectionList[nowSection].round - useData[i].sectionList[nowSection].sectionRecordList[h].completeRound;
          
          // Buyin에서 멈췄을 경우 //
          if (division === 'Buyin') {
            round += (remainingRound + ((index * 0.1) * useData[i].sectionList[nowSection].sectionRecordList[h].lastRound)) * 0.3;
          }
          // Main에서 멈췄을 경우 //
          else if (division === 'Main') {
            round += (remainingRound + ((index * 0.1) * useData[i].sectionList[nowSection].sectionRecordList[h].lastRound)) * 0.6;
          }
          // Buyout에서 멈췄을 경우 //
          else if (division === 'Buyout') {
            round += (remainingRound + ((index * 0.1) * useData[i].sectionList[nowSection].sectionRecordList[h].lastRound)) * 0.9;
          }

          if (tag.includes('ForTime-')) {
            let completeReps = 0;
            
            if (division === 'Buyin') {
              for (let z = 0; z < useData[i].sectionList[nowSection].sectionRecordList[h].buyinExerciseList.length; z++) {
                if (useData[i].sectionList[nowSection].sectionRecordList[h].buyinExerciseList[z].exerciseIndex < index) {
                  completeReps += useData[i].sectionList[nowSection].sectionRecordList[h].buyinExerciseList[z].count;
                }
              }
            }
            else if (division === 'Main') {
              for (let z = 0; z < useData[i].sectionList[nowSection].sectionRecordList[h].mainExerciseList.length; z++) {
                if (useData[i].sectionList[nowSection].sectionRecordList[h].mainExerciseList[z].exerciseIndex < index) {
                  completeReps += useData[i].sectionList[nowSection].sectionRecordList[h].mainExerciseList[z].count;
                }
              }
            }
            else if (division === 'Buyout') {
              for (let z = 0; z < useData[i].sectionList[nowSection].sectionRecordList[h].buyoutExerciseList.length; z++) {
                if (useData[i].sectionList[nowSection].sectionRecordList[h].buyoutExerciseList[z].exerciseIndex < index) {
                  completeReps += useData[i].sectionList[nowSection].sectionRecordList[h].buyoutExerciseList[z].count;
                }
              }
            }

            completeReps += parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].lastRound);
            finalScoreText += useData[i].sectionList[nowSection].sectionRecordList[h].completeRound + ' Rounds ' + completeReps + ' Reps';
          }
        }

        if (tag === 'SINGLE') {
          let weightTotalText = '';

          for (let z = 0; z < useData[i].sectionList[nowSection].sectionRecordList[h].mainExerciseList.length; z++) {
            if (z !== useData[i].sectionList[nowSection].sectionRecordList[h].mainExerciseList.length - 1) {
              weightTotalText += parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].mainExerciseList[z].weight) + ' - ';
            }
            else {
              weightTotalText += parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].mainExerciseList[z].weight) + ' ' + (useData[i].sectionList[nowSection].sectionRecordList[h].exerciseList[z].weightUnit === 'Pounds' ? 'lb' : useData[i].sectionList[nowSection].sectionRecordList[h].exerciseList[z].weightUnit);
            }
          }

          finalScoreText = weightTotalText;
        }
        else if (tag === 'AMRAP') {
          // 마지막 진행한 운동 찾기 //
          let division = 'Buyin';
          let index = 0;

          // 마지막 운동 찾기 //
          for (let z = 0; z < useData[i].sectionList[nowSection].sectionRecordList[h].exerciseList.length; z++) {
            if (useData[i].sectionList[nowSection].sectionRecordList[h].lastExerciseId === useData[i].sectionList[nowSection].sectionRecordList[h].exerciseList[z].exerciseId) {
              division = useData[i].sectionList[nowSection].sectionRecordList[h].exerciseList[z].division;
              index = useData[i].sectionList[nowSection].sectionRecordList[h].exerciseList[z].exerciseIndex;
              break;
            }
          }

          let completeReps = 0;

          if (division === 'Buyin') {
            for (let z = 0; z < useData[i].sectionList[nowSection].sectionRecordList[h].buyinExerciseList.length; z++) {
              if (useData[i].sectionList[nowSection].sectionRecordList[h].buyinExerciseList[z].exerciseIndex < index) {
                completeReps += parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].buyinExerciseList[z].count);
              }
            }
          }
          else if (division === 'Main') {
            for (let z = 0; z < useData[i].sectionList[nowSection].sectionRecordList[h].mainExerciseList.length; z++) {
              if (useData[i].sectionList[nowSection].sectionRecordList[h].mainExerciseList[z].exerciseIndex < index) {
                completeReps += parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].mainExerciseList[z].count);
              }
            }
          }
          else if (division === 'Buyout') {
            for (let z = 0; z < useData[i].sectionList[nowSection].sectionRecordList[h].buyoutExerciseList.length; z++) {
              if (useData[i].sectionList[nowSection].sectionRecordList[h].buyoutExerciseList[z].exerciseIndex < index) {
                completeReps += parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].buyoutExerciseList[z].count);
              }
            }
          }

          completeReps += parseInt(useData[i].sectionList[nowSection].sectionRecordList[h].lastRound);
          finalScoreText += useData[i].sectionList[nowSection].sectionRecordList[h].completeRound + ' Rounds ' + completeReps + ' Reps';
        }
        else if (tag === 'EMOM' || tag === 'TABATA') {
          finalScoreText += useData[i].sectionList[nowSection].sectionRecordList[h].roundOfCount;
        }

        let minusPercent = 1;

        if (minusPointCount !== 0) minusPercent = 0.4;

        for (let o = 0; o < minusPointCount; o++) {
          if (minusPercent > 0.1) minusPercent -= 0.1;
        }

        if (minusPercent === 1) successScore += 40000000;

        // 이미 저장된 내역 확인 Flag //
        let isExist = false;
        // 이미 저장된 유저 점수가 있는지 확인 //
        for (let x = 0; x < userRankingList.length; x++) {
          // 이미 점수가 있으면 기존점수에 + 하기 //
          if (userRankingList[x].linkCoachId === useData[i].sectionList[nowSection].sectionRecordList[h].linkCoachId) {

            if (useData[i].sectionList[nowSection].sectionRecordList[h].success === 'Success') {
              // 저장 플래그 //
              isExist = true;
            }
            else if (useData[i].sectionList[nowSection].sectionRecordList[h].success === 'Not Success') {
              // 저장 플래그 //
              isExist = true;
            }
            else if (useData[i].sectionList[nowSection].sectionRecordList[h].success === 'Not Done') {
            }
          }
        }

        // 저장된게 없으면 //
        if (!isExist) {

          let totalScore = ((round * 10000000) + (roundOfCount * 1000000) + (weight * 100000) + (reps * 10000) + (calories * 1000) + (distance * 100) + (height * 10) + timeRemaining + successScore + lastRound + (lastMovementBonus + lastReps)) - time;
          let rankingInfo = {
            linkCoachId: useData[i].sectionList[nowSection].sectionRecordList[h].linkCoachId,
            userName: useData[i].sectionList[nowSection].sectionRecordList[h].userName,
            userProfile: useData[i].sectionList[nowSection].sectionRecordList[h].userProfile,
            createdDate: useData[i].sectionList[nowSection].sectionRecordList[h].createdDate,
            round: (round * 10000000),
            roundOfCount: (roundOfCount * 1000000),
            weight: (weight * 100000),
            reps: reps * 10000,
            calories: calories * 1000,
            distance: distance * 100,
            height: height * 10,
            timeRemaining: timeRemaining * 1,
            recordTime: time,
            successScore: successScore,
            lastRound: lastRound,
            lastMovementBonus: lastMovementBonus,
            lastReps: lastReps,
            minusPointCount: minusPointCount,
            minusPercent: minusPercent,
            finalScoreText: finalScoreText,
            totalScore: (totalScore * minusPercent)
          }

          if (useData[i].sectionList[nowSection].sectionRecordList[h].success === 'Success') {
            let successTotalScore = ((round * 3000000) + (roundOfCount * 300000) + (weight * 30000) + (reps * 3000) + (calories * 300) + (distance * 30) + (height) + timeRemaining + successScore + lastRound + (lastMovementBonus + lastReps)) - time;

            rankingInfo = {
              linkCoachId: useData[i].sectionList[nowSection].sectionRecordList[h].linkCoachId,
              userName: useData[i].sectionList[nowSection].sectionRecordList[h].userName,
              userProfile: useData[i].sectionList[nowSection].sectionRecordList[h].userProfile,
              createdDate: useData[i].sectionList[nowSection].sectionRecordList[h].createdDate,
              round: (round * 3000000),
              roundOfCount: (roundOfCount * 300000),
              weight: (weight * 30000),
              reps: reps * 3000,
              calories: calories * 300,
              distance: distance * 30,
              height: height,
              timeRemaining: timeRemaining * 1,
              recordTime: time,
              successScore: successScore,
              lastRound: lastRound,
              lastMovementBonus: lastMovementBonus,
              lastReps: lastReps,
              minusPointCount: minusPointCount,
              minusPercent: minusPercent,
              finalScoreText: finalScoreText,
              totalScore: (successTotalScore * minusPercent)
            }
          }
          else if (useData[i].sectionList[nowSection].sectionRecordList[h].success === 'Not Success') {
            let notSuccessTotalScore = ((round * 1000000) + (roundOfCount * 100000) + (weight * 10000) + (reps * 1000) + (calories * 100) + (distance * 10) + (height) + timeRemaining + successScore + lastRound + (lastMovementBonus + lastReps)) - time;

            rankingInfo = {
              linkCoachId: useData[i].sectionList[nowSection].sectionRecordList[h].linkCoachId,
              userName: useData[i].sectionList[nowSection].sectionRecordList[h].userName,
              userProfile: useData[i].sectionList[nowSection].sectionRecordList[h].userProfile,
              createdDate: useData[i].sectionList[nowSection].sectionRecordList[h].createdDate,
              round: (round * 1000000),
              roundOfCount: (roundOfCount * 100000),
              weight: (weight * 10000),
              reps: reps * 1000,
              calories: calories * 100,
              distance: distance * 10,
              height: height,
              timeRemaining: timeRemaining,
              recordTime: time,
              successScore: successScore,
              lastRound: lastRound,
              lastMovementBonus: lastMovementBonus,
              lastReps: lastReps,
              minusPointCount: minusPointCount,
              minusPercent: minusPercent,
              finalScoreText: finalScoreText,
              totalScore: (notSuccessTotalScore * minusPercent)
            }
          }
          else if (useData[i].sectionList[nowSection].sectionRecordList[h].success === 'Not Done') {
            rankingInfo = {
              linkCoachId: useData[i].sectionList[nowSection].sectionRecordList[h].linkCoachId,
              userName: useData[i].sectionList[nowSection].sectionRecordList[h].userName,
              userProfile: useData[i].sectionList[nowSection].sectionRecordList[h].userProfile,
              createdDate: useData[i].sectionList[nowSection].sectionRecordList[h].createdDate,
              round: 0,
              roundOfCount: 0,
              weight: 0,
              reps: 0,
              calories: 0,
              distance: 0,
              height: 0,
              timeRemaining: 0,
              recordTime: 0,
              successScore: 0,
              lastRound: 0,
              lastMovementBonus: 0,
              lastReps: 0,
              minusPointCount: 0,
              minusPercent: 0,
              totalScore: 0,
              finalScoreText: 'DNF'
            }
          }

          // console.log(rankingInfo);
          userRankingList.push(rankingInfo);
        }
      }
    }
  }

  if (scoreKeyword === 'Not Scored' && tag === 'Custom') {
    for (let i = 0; i < userRankingList.length; i++) {
      userRankingList[i].rank = 0;
    }
  }
  else {
    userRankingList.sort((a, b) => b.totalScore - a.totalScore || moment(a.createdDate).isBefore(b.createdDate));

    for (let i = 0; i < userRankingList.length; i++) {
      if (i !== 0) {
        if (userRankingList[i].totalScore === userRankingList[i - 1].totalScore) userRankingList[i].rank = userRankingList[i - 1].rank;
        else userRankingList[i].rank = i + 1;
      }
      else userRankingList[i].rank = i + 1;
    }
  }


  // console.log(userRankingList);
  // setRankingList(userRankingList);
  return userRankingList;
}
