import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import AliveMembershipSelectBox from '../../../../../Component/MemberComponent/AliveMembershipSelectBox';
import AliveScheduleSelectBox from '../../../../../Component/MemberComponent/AliveScheduleSelectBos';
import CalenderInput from '../../../../../Component/MemberComponent/CalenderInput';
import FetchModule from '../../../../Share/Network/FetchModule';

export default function CourseHistoryAddModal(props) {
  const { memberId, rendering, onClickClose } = props;
  
  const [membershipValue, setMembershipValue] = useState({
    lessonId: 0,
    membershipName: '선택',
    lessonAndScheduleInfos: []
  })
  const [scheduleValue, setScheduleValue] = useState({
    scheduleId: 0,
    className: '선택',
    classTime: '',
  })
  const [membershipOption, setMembershipOption] = useState([]);
  const [scheduleOption, setScheduleOption] = useState([]);
  const [classDate, setClassDate] = useState(moment().format('YYYY.MM.DD'));

  // 화면 스크롤 제어 //
  useEffect(() => {
    document.body.style= `overflow: hidden`;
    return () => document.body.style = `overflow: auto`;
  }, [])

  // MemberShip Data API Call //
  useEffect(() => {
    let requestData = {
      // memberId: 1026
      memberId: memberId
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataVersion2('membership/alive/', 'POST', requestData);
        // console.log(responseData);
        if (responseData.status === 200) {
          setMembershipOption(responseData.data);
        }
        else alert("데이터를 불러오는데 실패하였습니다. 잠시후 다시시도해 주세요.");
      }
    )();
  }, [memberId])

  // 등록 회원권에 관련해서 데이터 정리 //
  useEffect(() => {
    let scheduleOptions = [];
    let scheduleData = membershipValue.lessonAndScheduleInfos.slice();
    // console.log('=============')
    for (let i = 0; i < scheduleData.length; i++) {
      // console.log(scheduleData[i])
      // 수업은 설정되어있지만 스케줄 설정이 안되어있는 경우 //
      if (scheduleData[i].classDate === undefined) continue;

      // console.log(scheduleData[i])
      // console.log(scheduleData[i].applyWeek);
      // console.log(moment(classDate).format('ddd'));
      // console.log((scheduleData[i].applyWeek.includes(moment(classDate).format('ddd')) || scheduleData[i].applyWeek === 'All'));
      // console.log(moment(scheduleData[i].classDate).format('YYYY.MM.DD'));
      // console.log(moment(classDate).format('YYYY.MM.DD'));
      // console.log(moment(scheduleData[i].classDate).format('YYYY.MM.DD') <= moment(classDate).format('YYYY.MM.DD'));
      // console.log(moment(scheduleData[i].endDate).format('YYYY.MM.DD'));
      // console.log(moment(classDate).format('YYYY.MM.DD'));
      // console.log(moment(scheduleData[i].endDate).format('YYYY.MM.DD') >= moment(classDate).format('YYYY.MM.DD'));
      // 개설기간안에 들어있을 경우
      if (moment(scheduleData[i].classDate).format('YYYY.MM.DD') <= moment(classDate).format('YYYY.MM.DD')
        && moment(scheduleData[i].endDate).format('YYYY.MM.DD') >= moment(classDate).format('YYYY.MM.DD')
        && (scheduleData[i].applyWeek.includes(moment(classDate).format('ddd')) || scheduleData[i].applyWeek === 'All')) {
          scheduleOptions.push(scheduleData[i]);
        }
    }
    
    setScheduleValue({
      scheduleId: 0,
      className: '선택',
      classTime: '',
    });

    setScheduleOption(scheduleOptions);
  }, [membershipValue, classDate])

  function CalenderClick(className) {

  }

  // 추가하기 클릭시 //
  function onClickSave() {
    // console.log(membershipValue);
    // if (membershipValue.lessonId === 0) {
    //   alert('회원님의 회원권을 선택해주세요!');
    //   return;
    // }
    if (membershipValue.membershipName === '선택') {
      alert('회원님의 회원권을 선택해주세요!');
      return;
    }
    else if (scheduleValue.scheduleId === 0) {
      alert('추가할 수업을 선택해주세요!');
      return;
    }

    let requestData = {
      classDate: moment(classDate).format('YYYY-MM-DDT00:00:00'),
      classTime: scheduleValue.classTime,
      lessonId: scheduleValue.lessonId,
      status: 1,
      userId: membershipValue.userId,
      scheduleId: scheduleValue.scheduleId,
      usageId: membershipValue.usageId
    }
    // console.log(requestData);
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postData('reserve/create/course', 'POST', requestData);
        
        if (responseData.status === 200) {
          rendering();
          onClickClose();
        }
        else if (responseData.status === 201) {
          alert('이미 수강한 시간입니다!');
          return;
        }
        else if (responseData.status === 202) {
          alert('예약 가능 횟수를 초과하였습니다!');
          return;
        }
        else alert("서버연결에 실패하였습니다. 잠시후 다시시도해 주세요.");
      }
    )();
  }

  return (
    <BackgroundWrapper>
      <Modal>
        <TotalWrapper>
          <AliveMembershipSelectBoxWrapper>
            <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>등록 회원권</Text>
            <AliveMembershipSelectBox
              value={membershipValue}
              setValue={setMembershipValue} 
              options={membershipOption}/>
          </AliveMembershipSelectBoxWrapper>
          <TwiceLineWrapper>
            <LessonSelectBoxWrapper style={{ gap: '0px' }}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>수강일</Text>
              <CalenderInput className='ClassCalender' value={classDate} setValue={setClassDate} clickFn={CalenderClick} placeholder='수강일' width={230}/>
            </LessonSelectBoxWrapper>
            <LessonSelectBoxWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>수업</Text>
              <AliveScheduleSelectBox value={scheduleValue} setValue={setScheduleValue} options={scheduleOption}/>
            </LessonSelectBoxWrapper>
          </TwiceLineWrapper>
          <ButtonWrapper>
            <ExitButton onClick={onClickClose}>
              <HoverText fontFamily='NotoSansKR-Regular' fontSize={14} color='#CBCBCB'>취소</HoverText>
            </ExitButton>
            <SaveButton onClick={onClickSave}>
              <HoverText fontFamily='NotoSansKR-Regular' fontSize={14} color='#FFFFFF'>추가하기</HoverText>
            </SaveButton>
          </ButtonWrapper>
        </TotalWrapper>
      </Modal>
    </BackgroundWrapper>
  )
}

const BackgroundWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  
  z-index: 5;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.2);
`;

const Modal = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  
  margin-top: -260px;
  
  width: 532px;
  height: 244px;
  
  border-radius: 30px;
  background-color: #FFFFFF;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 89.84%;
`;

const AliveMembershipSelectBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  
  gap: 8px;
`;

const TwiceLineWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  margin-top: 14px;
  
  width: 100%;
`;

const LessonSelectBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 48.11%;
  
  gap: 8px;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};
  
  margin: 0 0 0 0;
  
  :hover {
    cursor: default;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: flex-end;
  
  margin-top: 32px;
  
  gap: 16px;

  width: 100%;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 72px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;
  
  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const SaveButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  
  width: 100px;
  height: 40px;
  
  border-radius: 15px;
  background-color: #6DD49E;
  
  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }
  
  transition: all 0.3s ease-in-out;
`;

const HoverText = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};
  
  margin: 0 0 0 0;
  
  :hover {
    cursor: pointer;
  }
`;