import { React, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import lodash from 'lodash';
import Lottie from 'lottie-react-web';

// import TimeDivisionLine from './TimeDivisionLine';
import TimeDivisionLine from '../../../Component/MainComponent/TimeDivisionLine.js';
import WeeklyColumn from './WeeklyColumn';
import EmptySign from '../../../image/EmptySign.png';

import PlusPath from '../../../image/ClassAddPlus.png';
import WhiteTrashIcon from '../../../image/WhiteTrashIcon.png';
import NewTimeDivisionLine from '../../../Component/MainComponent/NewTimeDivisionLine.js';

export default function ImprovedWeeklyClass(props) {
  const {
    today,
    isLoading,
    setIsLoading,
    scheduleData,
    breakTimeData,
    setClickDate,
    onClickDeleteButton,
    onClickScheduleDetail,
    onClickPlusColumn
  } = props;

  // 컴포넌트 크기 비율 ( 0.5 배 ) //
  // const conversionRate = 0.5;
  const conversionRate = 1;

  const [useScheduleData, setUseScheduleData] = useState([]);
  const [totalData, setTotalData] = useState([]);
  const [startDay, setStartDay] = useState(moment());
  const [minHeight, setMinHeight] = useState(644);

  const [totalClassTimeInfo, setTotalClassTimeInfo] = useState({
    fastestStartTime: '',
    fastestStartValue: 0,
    lastestEndTime: '',
    lastestEndValue: 0
  })

  const TopLevelRef = useRef();
  const ColumnRef = useRef();
  const ComponentRef = useRef([]);
  const InnerComponentRef = useRef([]);
  const DeleteTotalRef = useRef([]);
  const DeleteBackgroundRef = useRef([]);
  const DeleteButtonRef = useRef([]);
  const FirstButtonWrapperRef = useRef([]);
  const FirstButtonRef = useRef([]);
  const ButtonWrapperRef = useRef([]);
  const ButtonRef = useRef([]);

  // 주간 처음 날짜 빼오기 //
  useEffect(() => {
    for (let i = 0; i < 7; i++) {
      if (today.clone().subtract(i, 'day').format('dd') === 'Su') {
        setStartDay(today.clone().subtract(i, 'day'));
        break;
      }
    }
  }, [today])

  // Schedule Data를 사용 가능한 Data로 가공
  useEffect(() => {
    let tmp = scheduleData.slice();
    let newScheduleData = [];

    for (let i = 0; i < tmp.length; i++) {

      let endPoint = moment(tmp[i].classDate.split('T')[0]);

      while (endPoint.isBefore(moment(tmp[i].endDate))) {
        if (endPoint.diff(startDay, 'days') >= 7) break;


        if (tmp[i].repeatWeek.includes(getWeekNumber(endPoint.format('YYYY-MM-DD')).toString()) || tmp[i].repeatWeek.includes('0')) {

          if (tmp[i].applyWeek.includes(endPoint.format('ddd')) || tmp[i].applyWeek === 'All') {
            let newData = {
              classId: tmp[i].classId,
              classDate: endPoint.format('YYYY.MM.DD'),
              className: tmp[i].className,
              classTime: tmp[i].classTime,
              color: tmp[i].color,
              endDate: tmp[i].endDate,
              lessonId: tmp[i].lessonId,
              scheduleId: tmp[i].scheduleId,
              teacherName: tmp[i].teacherName,
              applyWeek: tmp[i].applyWeek,
              repeatWeek: tmp[i].repeatWeek,
              maxBookedPeople: tmp[i].maxBookedPeople,
              deduction: tmp[i].deduction,
            }
            newScheduleData.push(newData);
          }
        }

        endPoint = endPoint.clone().add(1, 'day');
      }
    }

    newScheduleData.sort(function (a, b) {
      return a.classDate < b.classDate ? -1 : a.classDate > b.classDate ? 1 : 0;
    })

    let newData = [];

    for (let i = 0; i < newScheduleData.length; i++) {
      for (let j = 0; j < 7; j++) {
        if (newScheduleData[i].classDate === startDay.clone().add(j, 'day').format('YYYY.MM.DD')) {
          newData.push(newScheduleData[i]);
        }
      }
    }

    // breaktime 데이터 가공
    for (let z = 0; z < breakTimeData.length; z++) {
      for (let y = 0; y < 7; y++) {
        if (moment(breakTimeData[z].classDate).isBefore(moment(startDay.clone().add(y, 'day').format('YYYY-MM-DDT') + breakTimeData[z].startTime)) && moment(breakTimeData[z].endDate).isAfter(startDay.clone().add(y, 'day'))) {
          if (breakTimeData[z].repeatWeek.includes(getWeekNumber(startDay.clone().add(y, 'day').format('YYYY-MM-DD'))) || breakTimeData[z].repeatWeek === '0') {
            if (breakTimeData[z].applyWeek.includes(startDay.clone().add(y, 'day').format('ddd')) || breakTimeData[z].applyWeek === 'All') {
              let breakData = {
                breakTimeId: breakTimeData[z].breakTimeId,
                classId: -1,
                lessonId: -1,
                classDate: startDay.clone().add(y, 'day').format('YYYY.MM.DD'),
                color: '#BDBDBD',
                classTime: breakTimeData[z].startTime + '-' + breakTimeData[z].endTime,
                className: '브레이크 타임',
                applyWeek: breakTimeData[z].applyWeek
              }

              newData.push(breakData);
            }
          }
        }
      }
    }

    newData.sort(function (a, b) {
      return a.classTime < b.classTime ? -1 : a.classTime > b.classTime ? 1 : 0;
    })

    // TimeDivisionLine에서 사용할 데이터 가공
    for (let i = 0; i < newData.length; i++) {
      tmp = newData[i].classTime.split('-');
      let splitTmp = tmp[0].split(':');
      let Time = '';

      if (parseInt(splitTmp[0]) === 24) {
        newData[i].startTime = (parseInt(splitTmp[0]) - 12) + ':' + splitTmp[1] + ' AM';
        Time = (parseInt(splitTmp[0]) - 12) + 'AM';
      }
      else if (parseInt(splitTmp[0]) > 12) {
        newData[i].startTime = (parseInt(splitTmp[0]) - 12) + ':' + splitTmp[1] + ' PM';
        Time = (parseInt(splitTmp[0]) - 12) + ' PM';
      }
      else if (parseInt(splitTmp[0]) === 12) {
        newData[i].startTime = (parseInt(splitTmp[0])) + ':' + splitTmp[1] + ' PM';
        Time = (parseInt(splitTmp[0])) + ' PM';
      }
      else {
        newData[i].startTime = splitTmp[0] + ':' + splitTmp[1] + ' AM';
        Time = parseInt(splitTmp[0]) + ' AM';
      }

      tmp = newData[i].classTime.split('-');
      splitTmp = tmp[1].split(':');

      if (parseInt(splitTmp[0]) === 24) {
        newData[i].endTime = (parseInt(splitTmp[0]) - 12) + ':' + splitTmp[i] + 'AM';
      }
      else if (parseInt(splitTmp[0]) > 12) {
        newData[i].endTime = (parseInt(splitTmp[0]) - 12) + ':' + splitTmp[1] + ' PM';
        // 끝나는 시간 소팅을 위해 쓰는 변수 //
      }
      else if (parseInt(splitTmp[0]) === 12) {
        newData[i].endTime = (parseInt(splitTmp[0])) + ':' + splitTmp[1] + ' PM';
      }
      else {
        newData[i].endTime = splitTmp[0] + ':' + splitTmp[1] + ' AM';
      }

      newData[i].end = parseFloat(splitTmp[0] + '.' + splitTmp[1]);

      if (i !== 0 && newData[i - 1].startTime.split(':')[0] === newData[i].startTime.split(':')[0]) {
        newData[i] = Object.assign(newData[i], { isNeedNewDivide: false })
        newData[i] = Object.assign(newData[i], { viewTime: Time })
      }
      else {
        newData[i] = Object.assign(newData[i], { isNeedNewDivide: true })
        newData[i] = Object.assign(newData[i], { viewTime: Time })
      }
    }

    let newReserve = [];

    for (let h = 0; h < newData.length; h++) {
      newReserve.push(new Array());
    }

    setUseScheduleData(newData);
  }, [scheduleData, breakTimeData, startDay])

  // 주간 시간표 데이터 정리 //
  useEffect(() => {
    let schedules = lodash.clone(useScheduleData);
    let totalTableData = [];

    schedules.sort(function (a, b) {
      return a.classDate < b.classDate ? -1 : a.classDate > b.classDate ? 1 : 0;
    })

    // schedules.sort((a, b) => (a.classDate - b.classDate || a.classTime - b.classTime || b.className - a.className));

    // event2ValidRank.sort((a, b) => b.firstExercise - a.firstExercise || a.tieBreak - b.tieBreak);

    for (let i = 0; i < schedules.length; i++) {
      let isDateExist = false;

      for (let j = 0; j < totalTableData.length; j++) {
        if (schedules[i].classDate === totalTableData[j].classDate) {
          totalTableData[j].scheduleList.push(schedules[i]);
          isDateExist = true;
          break;
        }
      }

      if (!isDateExist) {
        let tableData = {
          classDate: schedules[i].classDate,
          scheduleList: [schedules[i]]
        }

        totalTableData.push(tableData);
      }
    }

    // console.log(totalTableData);

    let fastestStartTime = '';
    let fastestStartValue = 0;
    let lastestEndTime = '';
    let lastestEndValue = 0;

    // 각 타임 테이블의 최초 시작시간과 마지막 수업 끝나는 시간 계산 //
    for (let i = 0; i < totalTableData.length; i++) {
      for (let j = 0; j < totalTableData[i].scheduleList.length; j++) {
        let startTime = totalTableData[i].scheduleList[j].classTime.split('-')[0];
        let endTime = totalTableData[i].scheduleList[j].classTime.split('-')[1];

        let startTimeHour = parseInt(startTime.split(':')[0]);
        let startTimeMinute = parseInt(startTime.split(':')[1]);

        let endTimeHour = parseInt(endTime.split(':')[0]);
        let endTimeMinute = parseInt(endTime.split(':')[1]);

        let startValue = getTimeValue(startTimeHour, startTimeMinute);
        let endValue = getTimeValue(endTimeHour, endTimeMinute);

        if (j === 0) {
          totalTableData[i].fastestStartTime = totalTableData[i].scheduleList[j].classTime.split('-')[0];
          totalTableData[i].fastestStartValue = startValue;
          totalTableData[i].lastestEndTime = totalTableData[i].scheduleList[j].classTime.split('-')[1];
          totalTableData[i].lastestEndValue = endValue;

          fastestStartTime = totalTableData[i].scheduleList[j].classTime.split('-')[0];
          fastestStartValue = startValue;
          lastestEndTime = totalTableData[i].scheduleList[j].classTime.split('-')[1];
          lastestEndValue = endValue;
        }
        else if (totalTableData[i].fastestStartValue > startValue) {
          totalTableData[i].fastestStartTime = totalTableData[i].scheduleList[j].classTime.split('-')[0];
          totalTableData[i].fastestStartValue = startValue;
        }
        else if (totalTableData[i].lastestEndValue < endValue) {
          totalTableData[i].lastestEndTime = totalTableData[i].scheduleList[j].classTime.split('-')[1];
          totalTableData[i].lastestEndValue = endValue;
        }
      }
    }

    // 한 주에서 가장 일찍 시작하는 시간과 가장 늦게 끝나는 시간 찾기 //
    for (let i = 0; i < totalTableData.length; i++) {
      if (i === 0) {
        fastestStartTime = totalTableData[i].fastestStartTime;
        fastestStartValue = totalTableData[i].fastestStartValue;
        lastestEndTime = totalTableData[i].lastestEndTime;
        lastestEndValue = totalTableData[i].lastestEndValue;
      }

      if (fastestStartValue > totalTableData[i].fastestStartValue) {
        fastestStartTime = totalTableData[i].fastestStartTime;
        fastestStartValue = totalTableData[i].fastestStartValue;
      }

      if (lastestEndValue < totalTableData[i].lastestEndValue) {
        lastestEndTime = totalTableData[i].lastestEndTime;
        lastestEndValue = totalTableData[i].lastestEndValue;
      }
    }

    let classTotalInfo = {
      fastestStartTime: fastestStartTime,
      fastestStartValue: fastestStartValue,
      lastestEndTime: lastestEndTime,
      lastestEndValue: lastestEndValue
    }


    // 컴포넌트 크기 계산 //
    for (let y = 0; y < totalTableData.length; y++) {
      for (let x = 0; x < totalTableData[y].scheduleList.length; x++) {
        let startTime = totalTableData[y].scheduleList[x].classTime.split('-')[0];
        let endTime = totalTableData[y].scheduleList[x].classTime.split('-')[1];

        let startTimeHour = parseInt(startTime.split(':')[0]);
        let startTimeMinute = parseInt(startTime.split(':')[1]);

        let endTimeHour = parseInt(endTime.split(':')[0]);
        let endTimeMinute = parseInt(endTime.split(':')[1]);

        let startValue = getTimeValue(startTimeHour, startTimeMinute);
        let endValue = getTimeValue(endTimeHour, endTimeMinute);

        let height = 76 - 6 - 2;
        let hoverHeight = 76 - 6 - 4;

        // 같은 시간대 수업이 몇개 겹치는지 체크 //
        let maxDuplicateCount = 0;
        // 같이 시작했지만 다르게 끝나는 수업이 몇개인지 체크 //
        let duplicateAnotherEndCount = 0;
        // 같이 시작했지만 다르게 끝나는 수업의 시간단위 //
        let duplicateTotalAnotherEndValue = 0;

        // 계산중인 컴포넌트에는 몇개가 겹쳐있는지 체크 //
        let maxDuplicateCountOnMyComponent = 0;
        // 계산중인 컴포넌트에는 다르게 끝나는 수업이 몇개인지 체크 //
        let duplicateAnotherEndCountOnMyComponent = 0;
        // 계산중인 컴포넌트에서 다르게 끝나는 수업의 시간 단위 //
        let duplicateTotalAnotherEndValueOnMyComponent = 0;

        // 같은 시간에 시작해서 끝나는 수업이 겹친게 있는지 확인 //
        for (let i = 0; i < totalTableData.length; i++) {
          let duplicateCheck = 0;
          let duplicateCheckOnMyComponent = 0;
          let duplicateCheckAnotherEndTime = 0;
          let duplicateCheckAnotherEndTimeValue = 0;
          let duplicateCheckAnotherOnMyComponent = 0;
          let duplicateCheckAnotherEndTimeValueOnMyComponent = 0;

          // 계산하고 있는 컴포넌트와는 다른날짜만 확인 //
          if (totalTableData[i].classDate !== totalTableData[y].scheduleList[x].classDate) {
            for (let j = 0; j < totalTableData[i].scheduleList.length; j++) {
              // 시작시간과 끝나는 시간이 같은경우 계산 //
              if (totalTableData[i].scheduleList[j].classTime === totalTableData[y].scheduleList[x].classTime) {
                duplicateCheck += 1;
              }
              // else {
              //   let dupStartTime = totalTableData[i].scheduleList[j].classTime.split('-')[0];
              //   let dupEndTime = totalTableData[i].scheduleList[j].classTime.split('-')[1];

              //   let dupStartTimeHour = parseInt(dupStartTime.split(':')[0]);
              //   let dupEndTimeHour = parseInt(dupEndTime.split(':')[0]);

              //   if (startTimeHour === dupStartTimeHour) {
              //     duplicateCheck += 1;
              //   }
              // }
              // // 시작시간은 같지만 끝나는 시간이 다른경우 계산 //
              // else if (totalTableData[i].scheduleList[j].startTime === totalTableData[y].scheduleList[x].startTime) {
              //   let anotherStartTime = totalTableData[i].scheduleList[j].classTime.split('-')[0];
              //   let anotherEndTime = totalTableData[i].scheduleList[j].classTime.split('-')[1];

              //   let anotherStartTimeHour = parseInt(anotherStartTime.split(':')[0]);
              //   let anotherStartTimeMinute = parseInt(anotherStartTime.split(':')[1]);

              //   let anotherEndTimeHour = parseInt(anotherEndTime.split(':')[0]);
              //   let anotherEndTimeMinute = parseInt(anotherEndTime.split(':')[1]);

              //   let anotherStartValue = getTimeValue(anotherStartTimeHour, anotherStartTimeMinute);
              //   let anotherEndValue = getTimeValue(anotherEndTimeHour, anotherEndTimeMinute);

              //   duplicateCheckAnotherEndTime += 1;
              //   duplicateCheckAnotherEndTimeValue += Math.round(anotherEndValue - anotherStartValue);
              // }
            }
          }
          // 날짜가 같은 데이터에서는 내꺼가 겹쳐있는지 확인 //
          else {
            for (let j = 0; j < totalTableData[i].scheduleList.length; j++) {
              // 같은 시간 시작 같은시간 종료 데이터 찾기 //
              if (totalTableData[i].scheduleList[j].classTime === totalTableData[y].scheduleList[x].classTime) {
                duplicateCheckOnMyComponent += 1;
              }
              // else {
              //   let dupStartTime = totalTableData[i].scheduleList[j].classTime.split('-')[0];
              //   let dupEndTime = totalTableData[i].scheduleList[j].classTime.split('-')[1];

              //   let dupStartTimeHour = parseInt(dupStartTime.split(':')[0]);
              //   let dupEndTimeHour = parseInt(dupEndTime.split(':')[0]);

              //   if (startTimeHour === dupStartTimeHour) {
              //     duplicateCheckOnMyComponent += 1;
              //   }
              // }
              // // 같은 시간 시작 다른 시간 종료 데이터 찾기 //
              // else if (totalTableData[i].scheduleList[j].startTime === totalTableData[y].scheduleList[x].startTime) {
              //   let anotherStartTime = totalTableData[i].scheduleList[j].classTime.split('-')[0];
              //   let anotherEndTime = totalTableData[i].scheduleList[j].classTime.split('-')[1];

              //   let anotherStartTimeHour = parseInt(anotherStartTime.split(':')[0]);
              //   let anotherStartTimeMinute = parseInt(anotherStartTime.split(':')[1]);

              //   let anotherEndTimeHour = parseInt(anotherEndTime.split(':')[0]);
              //   let anotherEndTimeMinute = parseInt(anotherEndTime.split(':')[1]);

              //   let anotherStartValue = getTimeValue(anotherStartTimeHour, anotherStartTimeMinute);
              //   let anotherEndValue = getTimeValue(anotherEndTimeHour, anotherEndTimeMinute);

              //   duplicateCheckAnotherOnMyComponent += 1;
              //   duplicateCheckAnotherEndTimeValueOnMyComponent += Math.round(anotherEndValue - anotherStartValue);
              // }
            }

            // 비교 날짜 컴포넌트 겹치는 개수 저장 //
            maxDuplicateCountOnMyComponent = duplicateCheckOnMyComponent;

            // duplicateAnotherEndCountOnMyComponent = duplicateCheckAnotherOnMyComponent === 0 ? 1 : duplicateAnotherEndCountOnMyComponent;
            // duplicateTotalAnotherEndValueOnMyComponent = duplicateCheckAnotherEndTimeValueOnMyComponent === 0 ? 1 : duplicateCheckAnotherEndTimeValueOnMyComponent;
          }

          if (duplicateCheck > maxDuplicateCount) maxDuplicateCount = duplicateCheck;
          // if (duplicateCheckAnotherEndTime > duplicateAnotherEndCount) {
          //   duplicateAnotherEndCount = duplicateCheckAnotherEndTime;
          //   duplicateTotalAnotherEndValue = duplicateCheckAnotherEndTimeValue;
          // }
        }

        let finalRate = endValue - startValue;

        // 비율계산은 겹쳐진 개수 / 계산중인 컴포넌트의 겹처진 개수 //
        let duplicateRate = (maxDuplicateCount / maxDuplicateCountOnMyComponent);

        // let anotherDuplicateRate = (duplicateAnotherEndCount / duplicateAnotherEndCountOnMyComponent);

        // let anotherDuplicateHeightRate = (duplicateTotalAnotherEndValue / duplicateTotalAnotherEndValueOnMyComponent);

        if (maxDuplicateCount === 0 || maxDuplicateCountOnMyComponent === 0) duplicateRate = 0;
        // if (duplicateAnotherEndCount === 0 || duplicateAnotherEndCountOnMyComponent === 0) anotherDuplicateRate = 0;
        // if (duplicateTotalAnotherEndValue === 0 || duplicateTotalAnotherEndValueOnMyComponent === 0) anotherDuplicateHeightRate = 0;

        // anotherDuplicateRate = 0;
        // anotherDuplicateHeightRate = 0;

        if (duplicateRate < 1) {

          // if (anotherDuplicateRate < 1) {
            if (finalRate < 1) {
              height = 76 - 6 - 2;
              hoverHeight = 76 - 6 - 4;
            }
            else {
              height = ((Math.round(finalRate) * conversionRate) * 76) - 6 - 2;
              hoverHeight = ((Math.round(finalRate) * conversionRate) * 76) - 6 - 4;
            }
          // }
          // else {
          //   if (finalRate < 1) {
          //     height = 76 + (anotherDuplicateRate / anotherDuplicateHeightRate * 76) - 6 - 2;
          //     hoverHeight = 76 + (anotherDuplicateRate / anotherDuplicateHeightRate * 76) - 6 - 4;
          //   }
          //   else {
          //     height = ((Math.round(finalRate) * conversionRate) * 76) + (anotherDuplicateRate / anotherDuplicateHeightRate * 76) - 6 - 2;
          //     hoverHeight = ((Math.round(finalRate) * conversionRate) * 76) + (anotherDuplicateRate / anotherDuplicateHeightRate * 76) - 6 - 4;
          //   }
          // }
        }
        else {
          // if (anotherDuplicateRate < 1) {
            if (finalRate < 1) {
              height = (duplicateRate * 76) - 6 - 2;
              hoverHeight = (duplicateRate * 76) - 6 - 4; 
            }
            else {
              height = (duplicateRate * ((Math.round(finalRate) * conversionRate) * 76)) - 6 - 2;
              hoverHeight = (duplicateRate * ((Math.round(finalRate) * conversionRate) * 76)) - 6 - 4;
            }
          // }
          // else {
          //   if (finalRate < 1) {
          //     height = (duplicateRate * 76) + (anotherDuplicateRate / anotherDuplicateHeightRate * 76) - 6 - 2;
          //     hoverHeight = (duplicateRate * 76) + (anotherDuplicateRate / anotherDuplicateHeightRate * 76) - 6 - 4;
          //   }
          //   else {
          //     height = (duplicateRate * ((Math.round(finalRate) * conversionRate) * 76)) + (anotherDuplicateRate / anotherDuplicateHeightRate * 76) - 6 - 2;
          //     hoverHeight = (duplicateRate * ((Math.round(finalRate) * conversionRate) * 76)) + (anotherDuplicateRate / anotherDuplicateHeightRate * 76) - 6 - 4;
          //   }
          // }
        }

        totalTableData[y].scheduleList[x].height = height;
        totalTableData[y].scheduleList[x].hoverHeight = hoverHeight;
        totalTableData[y].scheduleList[x].finalRate = finalRate;
        totalTableData[y].scheduleList[x].duplicateRate = duplicateRate;
        // totalTableData[y].scheduleList[x].anotherDuplicateRate = anotherDuplicateRate;
        // totalTableData[y].scheduleList[x].anotherDuplicateHeightRate = anotherDuplicateHeightRate;
      }
    }
    // 컴포넌트 크기 계산 //
    // console.log(totalTableData)
    setTotalClassTimeInfo(classTotalInfo);
    setTotalData(totalTableData);

    // console.log(totalTableData);
    setTimeout(() => {
      setIsLoading(false);
    }, 100);
  }, [useScheduleData, startDay, conversionRate])

  useEffect(() => {
    if (!isLoading) {
      setMinHeight(ColumnRef.current.clientHeight);
    }
  }, [isLoading, ColumnRef])

  // 몇주차인지 계산하기 //
  function getWeekNumber(date) {
    const standard = new Date(date);

    const currentDate = standard.getDate();
    const startOfMonth = new Date(standard.setDate(1));
    const weekDay = startOfMonth.getDay();

    return parseInt(((weekDay - 1) + currentDate) / 7) + 1;
  }

  // 호버링 배경색 컬러 코드에서 RGB값으로 변경 //
  function hexToRGB(hex, alpha) {
    let r = parseInt(hex.slice(1, 3), 16);
    let g = parseInt(hex.slice(3, 5), 16);
    let b = parseInt(hex.slice(5, 7), 16);

    if (alpha) return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ")";
    else return 'rgba(' + r + ', ' + g + ', ' + b + ")";
  }

  // 비어있는칸 플러스 버튼 클릭시 //
  function onClickPlusColumnBox(outData, innerData) {
    let settingTime = '';

    for (let i = 0; i < outData.length; i++) {
      if (outData[i].classId !== -1 && outData[i].classId !== -2) {
        settingTime = outData[i].classTime;
        break;
      }
    }

    onClickPlusColumn(innerData.classDate, settingTime, moment(innerData.classDate).format('ddd,'));
  }

  // 수업시간의 차이로 인한 윗쪽 빈칸 플러스 버튼 클릭시 //
  function onClickTopPlusColumnBox(outData, innerData) {
    let settingStartTime = '';
    let settingEndTime = innerData.classTime.split('-')[0];
    let originalClassTime = innerData.classTime;

    for (let i = 0; i < outData.length; i++) {
      if (outData[i].classId !== -1 && outData[i].classId !== -2 && outData[i].classTime !== originalClassTime) {
        // settingTime = outData[i].classTime;
        settingStartTime = outData[i].classTime.split('-')[0];
        break;
      }
    }

    let settingTime = settingStartTime + '-' + settingEndTime;

    onClickPlusColumn(innerData.classDate, settingTime, moment(innerData.classDate).format('ddd,'));
  }
  // 수업시간의 차이로 인한 아랫쪽 빈칸 플러스 버튼 클릭시 //
  function onClickBottomPlusColumnBox(outData, innerData) {
    let settingStartTime = innerData.classTime.split('-')[1];
    let settingEndTime = '';
    let originalClassTime = innerData.classTime;

    for (let i = 0; i < outData.length; i++) {
      if (outData[i].classId !== -1 && outData[i].classId !== -2 && outData[i].classTime !== originalClassTime) {
        settingEndTime = outData[i].classTime.split('-')[1];
        break;
      }
    }

    let settingTime = settingStartTime + '-' + settingEndTime;

    onClickPlusColumn(innerData.classDate, settingTime, moment(innerData.classDate).format('ddd,'));
  }

  // 맨 위에 칸 플러스 버튼 클릭시 //
  function onClickFirstPlusColumnBox(innerData) {
    onClickPlusColumn(innerData.classDate, '', moment(innerData.classDate).format('ddd,'));
  }

  // 수업 정보 컴포넌트 사이에 추가버튼을 띄울지 결정 //
  function selectBetweenButtonView(outerIndex, innerIndex) {
    // 마지막 컴포넌트는 버튼 추가 X //
    if (totalData.length === outerIndex + 1) return true;
    else {
      // // 본 컴포넌트 아래에 수업없음이라 추가 컴포넌트가 존재하는 경우 //
      // if (totalData[outerIndex + 1][innerIndex].className === '수업없음') return false;
      if (totalData[outerIndex + 1].scheduleList[innerIndex].className === '수업없음') return false;
      else return true;
    }
  }

  // 첫줄 추가 버튼 호버링 시작 시 //
  function onMouseEnterOfFirstButton(index) {
    ComponentRef.current[index].style.transform = 'translate(0px, 6px)';

    FirstButtonWrapperRef.current[index].style.marginTop = `-14px`;

    FirstButtonRef.current[index].style.height = `18px`;
    FirstButtonRef.current[index].style.backgroundColor = `#FF8B48`;
    FirstButtonRef.current[index].style.opacity = '1';
  }

  // 첫줄 추가 버튼 호버링 끝 //
  function onMouseLeaveOfFirstButton(index) {
    ComponentRef.current[index].style.transform = 'translate(0px, 0px)';

    FirstButtonWrapperRef.current[index].style.marginTop = `-8px`;

    FirstButtonRef.current[index].style.height = `8px`;

    FirstButtonRef.current[index].style.backgroundColor = `#FFFFFF`;
    FirstButtonRef.current[index].style.opacity = '0';
  }

  // 마우스 호버링 시작시 //
  function onMouseEnter(outerIndex, innerIndex, topMargin) {
    ComponentRef.current[(outerIndex * 7) + innerIndex].style.transform = 'translate(0px, -6px)';

    if (totalData.length > (outerIndex + 1)) ComponentRef.current[((outerIndex + 1) * 7) + innerIndex].style.transform = 'translate(0px, 6px)';

    // ButtonWrapperRef.current[(outerIndex * 7) + innerIndex].style.marginTop = `63px`;
    // ButtonWrapperRef.current[(outerIndex * 7) + innerIndex].style.marginTop = (topMargin - 2.5) + 'px';
    ButtonWrapperRef.current[(outerIndex * 7) + innerIndex].style.marginTop = (topMargin - 3) + 'px';

    ButtonRef.current[(outerIndex * 7) + innerIndex].style.height = `18px`;
    ButtonRef.current[(outerIndex * 7) + innerIndex].style.backgroundColor = `#FF8B48`;
    ButtonRef.current[(outerIndex * 7) + innerIndex].style.opacity = '1';
  }

  // 마우스 호버링 끝날시 //
  function onMouseLeave(outerIndex, innerIndex, topMargin) {
    ComponentRef.current[(outerIndex * 7) + innerIndex].style.transform = 'translate(0px, 0px)';

    if (totalData.length > (outerIndex + 1)) ComponentRef.current[((outerIndex + 1) * 7) + innerIndex].style.transform = 'translate(0px, 0px)';

    // ButtonWrapperRef.current[(outerIndex * 7) + innerIndex].style.marginTop = `68px`;
    ButtonWrapperRef.current[(outerIndex * 7) + innerIndex].style.marginTop = (topMargin + 2) + 'px';


    ButtonRef.current[(outerIndex * 7) + innerIndex].style.height = `8px`;
    ButtonRef.current[(outerIndex * 7) + innerIndex].style.backgroundColor = `#FFFFFF`;
    ButtonRef.current[(outerIndex * 7) + innerIndex].style.opacity = '0';
  }

  // 수업 컴포넌트 개별 호버링 시 //
  function onMouseEnterTimeComponent(outerIndex, innerIndex) {
    DeleteTotalRef.current[(outerIndex * 7) + innerIndex].style.opacity = `1`;

    // InnerComponentRef.current[(outerIndex * 7) + innerIndex].style.border = '2px solid ' + totalData[outerIndex][innerIndex].color;
    InnerComponentRef.current[(outerIndex * 7) + innerIndex].style.border = '2px solid ' + totalData[outerIndex].scheduleList[innerIndex].color;
  }

  // 수업 컴포넌트 개별 호버링시 //
  function onMouseLeaveTimeComponent(outerIndex, innerIndex) {
    DeleteTotalRef.current[(outerIndex * 7) + innerIndex].style.opacity = `0`;

    InnerComponentRef.current[(outerIndex * 7) + innerIndex].style.border = '1px solid ' + totalData[outerIndex].scheduleList[innerIndex].color;
    // InnerComponentRef.current[(outerIndex * 7) + innerIndex].style.backgroundColor = '#FFFFFF';
  }

  // 수업삭제 버튼 호버링시 //
  function onMouseEnterDeleteButton(outerIndex, innerIndex) {
    // DeleteBackgroundRef.current[(outerIndex * 7) + innerIndex].style.margin = '-300px -300px 0px 0px';
    DeleteBackgroundRef.current[(outerIndex * 7) + innerIndex].style.width = '125px';
    // DeleteBackgroundRef.current[(outerIndex * 7) + innerIndex].style.width = '600px';
    // DeleteBackgroundRef.current[(outerIndex * 7) + innerIndex].style.height = '68px';
    DeleteBackgroundRef.current[(outerIndex * 7) + innerIndex].style.height = `100%`;
    // DeleteBackgroundRef.current[(outerIndex * 7) + innerIndex].style.height = '600px';
    DeleteBackgroundRef.current[(outerIndex * 7) + innerIndex].style.borderRadius = '4px';
    // DeleteBackgroundRef.current[(outerIndex * 7) + innerIndex].style.borderRadius = '600px';
  }

  // 수업삭제 버튼 호버리시 //
  function onMouseLeaveDeleteButton(outerIndex, innerIndex) {
    DeleteBackgroundRef.current[(outerIndex * 7) + innerIndex].style.margin = '0px';
    DeleteBackgroundRef.current[(outerIndex * 7) + innerIndex].style.width = '20px';
    DeleteBackgroundRef.current[(outerIndex * 7) + innerIndex].style.height = '17px';
    // DeleteBackgroundRef.current[(outerIndex * 7) + innerIndex].style.borderRadius = '0px 4px 0px 4px';
    DeleteBackgroundRef.current[(outerIndex * 7) + innerIndex].style.borderRadius = '15px 0px 0px 90px';
  }


  // 주간 데이터 클릭시
  function onClickWeeklyScheduleBox(e, outerIndex, innerIndex, data) {
    if (DeleteButtonRef.current[(outerIndex * 7) + innerIndex].contains(e.target)) return;

    if (data.className !== '브레이크 타임' && data.className !== '수업없음') {
      setClickDate(data.classDate);
      onClickScheduleDetail(data.scheduleId);
    }
  }

  // 수업 삭제 버튼 클릭시 //
  function onClickDeletedClass(data) {
    onClickDeleteButton(data);
  }

  // 시간과 분단위 데이터 받아서 소수점으로 변환 //
  function getTimeValue(hour, minute) {

    if (hour === undefined || minute === undefined) return 0;

    let value = 0;

    if (minute >= 55) value = hour + 0.91;
    else if (minute >= 50) value = hour + 0.83;
    else if (minute >= 45) value = hour + 0.75;
    else if (minute >= 40) value = hour + 0.66;
    else if (minute >= 35) value = hour + 0.58;
    else if (minute >= 30) value = hour + 0.5;
    else if (minute >= 25) value = hour + 0.41;
    else if (minute >= 20) value = hour + 0.33;
    else if (minute >= 15) value = hour + 0.25;
    else if (minute >= 10) value = hour + 0.16;
    else if (minute >= 5) value = hour + 0.08;
    else value = hour;

    // value = hour + (minute / 60);

    return value;
  }

  // 수업 시간에 따른 수업 추가 버튼 크기 계산 //
  function onCalcNoneTDHeight(data) {
    let finalStartTime = 0;
    let finalEndTime = 0;

    for (let i = 0; i < data.length; i++) {
      if (data[i].className !== '수업없음') {
        let startTime = data[i].classTime.split('-')[0];
        let endTime = data[i].classTime.split('-')[1];

        let startTimeHour = parseInt(startTime.split(':')[0]);
        let startTimeMinute = parseInt(startTime.split(':')[1]);

        let endTimeHour = parseInt(endTime.split(':')[0]);
        let endTimeMinute = parseInt(endTime.split(':')[1]);

        let startValue = 0;
        let endValue = 0;

        // if (startTimeMinute > 50) startValue = startTimeHour + 0.83;
        // else if (startTimeMinute > 40) startValue = startTimeHour + 0.66;
        // else if (startTimeMinute > 30) startValue = startTimeHour + 0.5;
        // else if (startTimeMinute > 20) startValue = startTimeHour + 0.33;
        // else if (startTimeMinute > 10) startValue = startTimeHour + 0.16;
        // else startValue = startTimeHour;

        if (startTimeMinute >= 55) startValue = startTimeHour + 0.91;
        else if (startTimeMinute >= 50) startValue = startTimeHour + 0.83;
        else if (startTimeMinute >= 45) startValue = startTimeHour + 0.75;
        else if (startTimeMinute >= 40) startValue = startTimeHour + 0.66;
        else if (startTimeMinute >= 35) startValue = startTimeHour + 0.58;
        else if (startTimeMinute >= 30) startValue = startTimeHour + 0.5;
        else if (startTimeMinute >= 25) startValue = startTimeHour + 0.41;
        else if (startTimeMinute >= 20) startValue = startTimeHour + 0.33;
        else if (startTimeMinute >= 15) startValue = startTimeHour + 0.25;
        else if (startTimeMinute >= 10) startValue = startTimeHour + 0.16;
        else if (startTimeMinute >= 5) startValue = startTimeHour + 0.08;
        else startValue = startTimeHour;


        // if (endTimeMinute > 50) endValue = endTimeHour + 0.83;
        // else if (endTimeMinute > 40) endValue = endTimeHour + 0.66;
        // else if (endTimeMinute > 30) endValue = endTimeHour + 0.5;
        // else if (endTimeMinute > 20) endValue = endTimeHour + 0.33;
        // else if (endTimeMinute > 10) endValue = endTimeHour + 0.16;
        // else endValue = endTimeHour;

        if (endTimeMinute >= 55) endValue = endTimeHour + 0.91;
        else if (endTimeMinute >= 50) endValue = endTimeHour + 0.83;
        else if (endTimeMinute >= 45) endValue = endTimeHour + 0.75;
        else if (endTimeMinute >= 40) endValue = endTimeHour + 0.66;
        else if (endTimeMinute >= 35) endValue = endTimeHour + 0.58;
        else if (endTimeMinute >= 30) endValue = endTimeHour + 0.5;
        else if (endTimeMinute >= 25) endValue = endTimeHour + 0.41;
        else if (endTimeMinute >= 20) endValue = endTimeHour + 0.33;
        else if (endTimeMinute >= 15) endValue = endTimeHour + 0.25;
        else if (endTimeMinute >= 10) endValue = endTimeHour + 0.16;
        else if (endTimeMinute >= 5) endValue = endTimeHour + 0.08;
        else endValue = endTimeHour;

        if (finalStartTime === 0 && finalEndTime === 0) {
          finalStartTime = startValue;
          finalEndTime = endValue;
        }
        else {
          if (finalStartTime > startValue) finalStartTime = startValue;
          if (finalEndTime < endValue) finalEndTime = endValue;
        }
      }
    }

    let finalRate = finalEndTime - finalStartTime;

    // if (finalRate < 2) return 60;
    if (finalRate < 1.5) return 60;
    else {
      return ((((finalRate - 1) * conversionRate) + 1) * 66) - 4;
    }
  }

  // 수업 시간에 따른 크기 변환 계산 //
  function onCalcTDHeight(data) {
    // let startTime = data.classTime.split('-')[0];
    // let endTime = data.classTime.split('-')[1];

    // let startTimeHour = parseInt(startTime.split(':')[0]);
    // let startTimeMinute = parseInt(startTime.split(':')[1]);

    // let endTimeHour = parseInt(endTime.split(':')[0]);
    // let endTimeMinute = parseInt(endTime.split(':')[1]);

    // let startValue = getTimeValue(startTimeHour, startTimeMinute);
    // let endValue = getTimeValue(endTimeHour, endTimeMinute);

    // // 같은 시간대 수업이 몇개 겹치는지 체크 //
    // let maxDuplicateCount = 0;
    // // 같이 시작했지만 다르게 끝나는 수업이 몇개인지 체크 //
    // let duplicateAnotherEndCount = 0;
    // // 같이 시작했지만 다르게 끝나는 수업의 시간단위 //
    // let duplicateTotalAnotherEndValue = 0;

    // // 계산중인 컴포넌트에는 몇개가 겹쳐있는지 체크 //
    // let maxDuplicateCountOnMyComponent = 0;
    // // 계산중인 컴포넌트에는 다르게 끝나는 수업이 몇개인지 체크 //
    // let duplicateAnotherEndCountOnMyComponent = 0;
    // // 계산중인 컴포넌트에서 다르게 끝나는 수업의 시간 단위 //
    // let duplicateTotalAnotherEndValueOnMyComponent = 0;

    // // 같은 시간에 시작해서 끝나는 수업이 겹친게 있는지 확인 //
    // for (let i = 0; i < totalData.length; i++) {
    //   let duplicateCheck = 0;
    //   let duplicateCheckOnMyComponent = 0;
    //   let duplicateCheckAnotherEndTime = 0;
    //   let duplicateCheckAnotherEndTimeValue = 0;
    //   let duplicateCheckAnotherOnMyComponent = 0;
    //   let duplicateCheckAnotherEndTimeValueOnMyComponent = 0;

    //   // 계산하고 있는 컴포넌트와는 다른날짜만 확인 //
    //   if (totalData[i].classDate !== data.classDate) {
    //     for (let j = 0; j < totalData[i].scheduleList.length; j++) {
    //       // 시작시간과 끝나는 시간이 같은경우 계산 //
    //       if (totalData[i].scheduleList[j].classTime === data.classTime) {
    //         duplicateCheck += 1;
    //       }
    //       // 시작시간은 같지만 끝나는 시간이 다른경우 계산 //
    //       else if (totalData[i].scheduleList[j].startTime === data.startTime) {
    //         let anotherStartTime = totalData[i].scheduleList[j].classTime.split('-')[0];
    //         let anotherEndTime = totalData[i].scheduleList[j].classTime.split('-')[1];

    //         let anotherStartTimeHour = parseInt(anotherStartTime.split(':')[0]);
    //         let anotherStartTimeMinute = parseInt(anotherStartTime.split(':')[1]);

    //         let anotherEndTimeHour = parseInt(anotherEndTime.split(':')[0]);
    //         let anotherEndTimeMinute = parseInt(anotherEndTime.split(':')[1]);

    //         let anotherStartValue = getTimeValue(anotherStartTimeHour, anotherStartTimeMinute);
    //         let anotherEndValue = getTimeValue(anotherEndTimeHour, anotherEndTimeMinute);

    //         duplicateCheckAnotherEndTime += 1;
    //         duplicateCheckAnotherEndTimeValue += Math.round(anotherEndValue - anotherStartValue);
    //       }
    //     }
    //   }
    //   // 날짜가 같은 데이터에서는 내꺼가 겹쳐있는지 확인 //
    //   else {
    //     for (let j = 0; j < totalData[i].scheduleList.length; j++) {
    //       // 같은 시간 시작 같은시간 종료 데이터 찾기 //
    //       if (totalData[i].scheduleList[j].classTime === data.classTime) {
    //         duplicateCheckOnMyComponent += 1;
    //       }
    //       // 같은 시간 시작 다른 시간 종료 데이터 찾기 //
    //       else if (totalData[i].scheduleList[j].startTime === data.startTime) {
    //         let anotherStartTime = totalData[i].scheduleList[j].classTime.split('-')[0];
    //         let anotherEndTime = totalData[i].scheduleList[j].classTime.split('-')[1];

    //         let anotherStartTimeHour = parseInt(anotherStartTime.split(':')[0]);
    //         let anotherStartTimeMinute = parseInt(anotherStartTime.split(':')[1]);

    //         let anotherEndTimeHour = parseInt(anotherEndTime.split(':')[0]);
    //         let anotherEndTimeMinute = parseInt(anotherEndTime.split(':')[1]);

    //         let anotherStartValue = getTimeValue(anotherStartTimeHour, anotherStartTimeMinute);
    //         let anotherEndValue = getTimeValue(anotherEndTimeHour, anotherEndTimeMinute);

    //         duplicateCheckAnotherOnMyComponent += 1;
    //         duplicateCheckAnotherEndTimeValueOnMyComponent += Math.round(anotherEndValue - anotherStartValue);
    //       }
    //     }

    //     // 비교 날짜 컴포넌트 겹치는 개수 저장 //
    //     maxDuplicateCountOnMyComponent = duplicateCheckOnMyComponent;

    //     duplicateAnotherEndCountOnMyComponent = duplicateCheckAnotherOnMyComponent === 0 ? 1 : duplicateAnotherEndCountOnMyComponent;
    //     duplicateTotalAnotherEndValueOnMyComponent = duplicateCheckAnotherEndTimeValueOnMyComponent === 0 ? 1 : duplicateCheckAnotherEndTimeValueOnMyComponent;
    //   }

    //   if (duplicateCheck > maxDuplicateCount) maxDuplicateCount = duplicateCheck;
    //   if (duplicateCheckAnotherEndTime > duplicateAnotherEndCount) {
    //     duplicateAnotherEndCount = duplicateCheckAnotherEndTime;
    //     duplicateTotalAnotherEndValue = duplicateCheckAnotherEndTimeValue;
    //   }
    // }

    // let finalRate = endValue - startValue;

    // // 비율계산은 겹쳐진 개수 / 계산중인 컴포넌트의 겹처진 개수 //
    // let duplicateRate = (maxDuplicateCount / maxDuplicateCountOnMyComponent);

    // let anotherDuplicateRate = (duplicateAnotherEndCount / duplicateAnotherEndCountOnMyComponent);

    // let anotherDuplicateHeightRate = (duplicateTotalAnotherEndValue / duplicateTotalAnotherEndValueOnMyComponent);

    // if (maxDuplicateCount === 0 || maxDuplicateCountOnMyComponent === 0) duplicateRate = 0;
    // if (duplicateAnotherEndCount === 0 || duplicateAnotherEndCountOnMyComponent === 0) anotherDuplicateRate = 0;
    // if (duplicateTotalAnotherEndValue === 0 || duplicateTotalAnotherEndValueOnMyComponent === 0) anotherDuplicateHeightRate = 0;

    // anotherDuplicateRate = 0;
    // anotherDuplicateHeightRate = 0;

    // if (duplicateRate < 1) {

    //   if (anotherDuplicateRate < 1) {
    //     if (finalRate < 1) return 76 - 6 - 2;
    //     else return ((Math.round(finalRate) * conversionRate) * 76) - 6 - 2;
    //   }
    //   else {
    //     if (finalRate < 1) return 76 + (anotherDuplicateRate / anotherDuplicateHeightRate * 76) - 6 - 2;
    //     else return ((Math.round(finalRate) * conversionRate) * 76) + (anotherDuplicateRate / anotherDuplicateHeightRate * 76) - 6 - 2;
    //   }
    // }
    // else {
    //   if (anotherDuplicateRate < 1) {
    //     if (finalRate < 1) return (duplicateRate * 76) - 6 - 2;
    //     else return (duplicateRate * ((Math.round(finalRate) * conversionRate) * 76)) - 6 - 2;
    //   }
    //   else {
    //     if (finalRate < 1) return (duplicateRate * 76) + (anotherDuplicateRate / anotherDuplicateHeightRate * 76) - 6 - 2;
    //     else return (duplicateRate * ((Math.round(finalRate) * conversionRate) * 76)) + (anotherDuplicateRate / anotherDuplicateHeightRate * 76) - 6 - 2;
    //   }
    // }

    // // // if (finalRate < 1) return 66;
    // if (finalRate < 1) return 76 - 6 - 2;
    // // else return (((finalRate - 1) * conversionRate) + 1) * 66;
    // // else return (finalRate * conversionRate) * 72 - 6;
    // else return ((Math.round(finalRate) * conversionRate) * 76) - 6 - 2;
  }

  // 수업 시간에 따른 크기 변환 ( 호버링 전용 ) 계산 //
  function onCalcHoverTDHeight(data) {
    let startTime = data.classTime.split('-')[0];
    let endTime = data.classTime.split('-')[1];

    let startTimeHour = parseInt(startTime.split(':')[0]);
    let startTimeMinute = parseInt(startTime.split(':')[1]);

    let endTimeHour = parseInt(endTime.split(':')[0]);
    let endTimeMinute = parseInt(endTime.split(':')[1]);

    let startValue = getTimeValue(startTimeHour, startTimeMinute);
    let endValue = getTimeValue(endTimeHour, endTimeMinute);

    let finalRate = endValue - startValue;

    // if (finalRate < 1) return 64;
    if (finalRate < 1) return 76 - 6 - 4;
    // else return ((((finalRate - 1) * conversionRate) + 1) * 66) - 2;
    else return ((Math.round(finalRate) * conversionRate) * 76) - 6 - 4;
    // return ((finalRate * conversionRate) * 72) - 2 - 6;
  }

  // 수업 위의 시간대에 수업 추가 버튼 생성 결정 및 크기 계산 //
  function onCalcUpAdditionalButtonHeight(weeklyData, index) {
    let startTime = weeklyData.scheduleList[index].classTime.split('-')[0];

    let startTimeHour = parseInt(startTime.split(':')[0]);
    let startTimeMinute = parseInt(startTime.split(':')[1]);

    let startValue = getTimeValue(startTimeHour, startTimeMinute);

    // 처음 시작하는 컴포넌트인경우 //
    if (index === 0) {
      // 주간에 처음 시작하는 컴포넌트의 시간과 다른지 확인 //
      let firstComponentRate = startValue - totalClassTimeInfo.fastestStartValue;

      if (firstComponentRate < 1) return 0;
      // else return ((firstComponentRate * conversionRate) * 64) - 8 - 6;
      else return ((Math.round(firstComponentRate) * conversionRate) * 76) - 6 - 8;
    }
    // 그 이외의 경우 //
    // 이전 시간 수업이 끝나는 시간과 현재 수업 시작시간 계산 //
    else {
      let prevEndTime = weeklyData.scheduleList[index - 1].classTime.split('-')[1];

      let prevEndTimeHour = parseInt(prevEndTime.split(':')[0]);
      let prevEndTimeMinute = parseInt(prevEndTime.split(':')[1]);

      let prevEndValue = getTimeValue(prevEndTimeHour, prevEndTimeMinute);

      let componentRate = startValue - prevEndValue;

      // 나와 같은 시간대의 수업이 어느 위치에 있는지 //
      let sameTimeMaxHeight = 0;
      // 내 수업은 어느 위치에 있는지 //
      let myComponentHeight = 0;
      let isApply = false;

      for (let i = 0; i < index; i++) {
        myComponentHeight += weeklyData.scheduleList[i].height;
      }

      for (let i = 0; i < totalData.length; i++) {
        // 내 데이터가 아닌 상대 데이터인 경우 //
        if (totalData[i].classDate !== weeklyData.classDate) {
          let tmpSameTimeMaxHeight = 0;

          for (let j = 0; j < totalData[i].scheduleList.length; j++) {
            let tmpStartTime = totalData[i].scheduleList[j].classTime.split('-')[0];

            let tmpStartTimeHour = parseInt(tmpStartTime.split(':')[0]);

            if (startTimeHour === tmpStartTimeHour) {
              // console.log('\n');
              // console.log('\n');
              // console.log('\n');
              isApply = true;
              break;
            }

            tmpSameTimeMaxHeight += totalData[i].scheduleList[j].height;
          }
          
          if (sameTimeMaxHeight < tmpSameTimeMaxHeight) sameTimeMaxHeight = tmpSameTimeMaxHeight;
          
        }
      }

      let finalHight = 0;

      if (sameTimeMaxHeight > myComponentHeight) finalHight = (sameTimeMaxHeight - 6) - (myComponentHeight - 6); 

      if (componentRate < 0.7) {
        if (isApply && finalHight > 0) return finalHight;
        else return 0;
      }
      else {
        if (isApply && finalHight > 0) return ((Math.round(componentRate) * conversionRate) * 76) + finalHight + - 8 - 6;
        else return ((Math.round(componentRate) * conversionRate) * 76) - 8 - 6;
      }
      // if (componentRate < 0.7) return 0;
      // // else if (componentRate < 0.8) return ((componentRate * conversionRate) * 40) - 8 - 6;
      // else return ((Math.round(componentRate) * conversionRate) * 76) - 8 - 6;
    } 
    // else if (weeklyData.scheduleList[index].duplicateRate < 1) {
    //   let prevEndTime = weeklyData.scheduleList[index - 1].classTime.split('-')[1];

    //   let prevEndTimeHour = parseInt(prevEndTime.split(':')[0]);
    //   let prevEndTimeMinute = parseInt(prevEndTime.split(':')[1]);

    //   let prevEndValue = getTimeValue(prevEndTimeHour, prevEndTimeMinute);

    //   let componentRate = startValue - prevEndValue;

    //   if (componentRate < 0.7) return 0;
    //   // else if (componentRate < 0.8) return ((componentRate * conversionRate) * 40) - 8 - 6;
    //   else return ((Math.round(componentRate) * conversionRate) * 76) - 8 - 6;
    // }
    // else {
    //   let noneHeight = 0;

    //   for (let i = 0; i < totalData.length; i++) {
    //     // 크기 산정중인 날짜는 비교 제외 //
    //     if (totalData[i].classDate !== weeklyData.classDate) {
    //       for (let j = 0; j < totalData[i].scheduleList.length; j++) {
    //         let comparisonTargetStartTime = totalData[i].scheduleList[j].classTime.split('-')[0];

    //         let comparisonTargetStartTimeHour = parseInt(comparisonTargetStartTime.split(':')[0]);

    //         // if (startTimeHour === comparisonTargetStartTimeHour) {
    //         //   console.log(startTimeHour);
    //         //   console.log(comparisonTargetStartTimeHour);
    //         //   console.log(startTimeHour === comparisonTargetStartTimeHour);
    //         // }
    //         // 시작시간이 분단위 제외하고 동일한 경우 모자란 크기만큼 + 컴포넌트 산정 //
    //         if (startTimeHour === comparisonTargetStartTimeHour) {
    //           let fianlWeeklyDataHeight = 0;
    //           let finalTotalDataHeight = 0;

    //           for (let h = 0; h < index; h++) {
    //             fianlWeeklyDataHeight += weeklyData.scheduleList[h].height;
    //           }

    //           for (let h = 0; h < j; h++) {
    //             finalTotalDataHeight += totalData[i].scheduleList[h].height;
    //           }

    //           // console.log('total : ' + finalTotalDataHeight);
    //           // console.log('weekly : ' + fianlWeeklyDataHeight);
    //           if (finalTotalDataHeight > fianlWeeklyDataHeight) {
    //             let totalHeight = (finalTotalDataHeight - fianlWeeklyDataHeight) - 6;

    //             if (noneHeight < totalHeight) noneHeight = totalHeight;
    //           }
    //           else {
    //             let prevEndTime = weeklyData.scheduleList[index - 1].classTime.split('-')[1];

    //             let prevEndTimeHour = parseInt(prevEndTime.split(':')[0]);
    //             let prevEndTimeMinute = parseInt(prevEndTime.split(':')[1]);

    //             let prevEndValue = getTimeValue(prevEndTimeHour, prevEndTimeMinute);

    //             let componentRate = startValue - prevEndValue;

    //             if (componentRate < 0.7) return 0;
    //             // else if (componentRate < 0.8) return ((componentRate * conversionRate) * 40) - 8 - 6;
    //             else return ((Math.round(componentRate) * conversionRate) * 76) - 8 - 6;
    //           }
    //         }
    //       }
    //     }
    //   }

    //   return noneHeight;
    // }
  }

  // 같은 시간대에 있는 수업이 너무 긴경우 아랫쪽에 수업 추가 버튼을 생성할지 말지 결정 //
  function onCalcDownAdditionalButtonHeight(weeklyData, index) {
    // 맨 마지막 컴포넌트인 경우에만 계산 //
    if (weeklyData.scheduleList.length - 1 === index) {
      let endTime = weeklyData.scheduleList[index].classTime.split('-')[1];

      let endTimeHour = parseInt(endTime.split(':')[0]);
      let endTimeMinute = parseInt(endTime.split(':')[1]);

      let endValue = getTimeValue(endTimeHour, endTimeMinute);

      let lastComponentRate = totalClassTimeInfo.lastestEndValue - endValue;

      // console.log('------');
      // console.log('endValue : ' + endValue);
      // console.log('totalClassTimeInfo.lastestEndValue : ' + totalClassTimeInfo.lastestEndValue);
      // console.log('componentRate : ' + lastComponentRate);

      if (lastComponentRate < 1) return 0;
      // else return ((lastComponentRate * conversionRate) * 64) - 8 - 6;
      else return ((Math.round(lastComponentRate) * conversionRate) * 76) - 8 - 6;
    }
    else return 0;
  }

  return (
    <TopLevelWrapper ref={TopLevelRef}>
      <WeeklyColumn today={startDay} standardDate={today} />
      {
        isLoading ?
          <LoadingWrapper minHeight={minHeight} />
          :
          <ColumnWrapper ref={ColumnRef}>
            {
              totalData.length !== 0 ?
                <TableWrapper>
                  <TimeDivisionLineWrapper>
                    {/* {
                      totalData.map((data, index) => (
                        <NewTimeDivisionLine
                          key={index} 
                          index={index} 
                          conversionRate={conversionRate}
                          totalData={totalData} />
                      ))
                    } */}
                  </TimeDivisionLineWrapper>
                  <Table>
                    <Tbody>
                      {
                        totalData.map((data, index) => (
                          <Tr key={index}>
                            {
                              data.scheduleList.map((innerData, idx) => (
                                // innerData.className === '수업없음' ?
                                //   <ComponentWrapper key={idx} /*ref={(element) => TimeDeleteRef.current[(index * 7) + index] = element}*/>
                                //     <TDWrapper ref={(element) => ComponentRef.current[(index * 7) + idx] = element}>
                                //       <NoneClassTd
                                //         height={onCalcNoneTDHeight(data)}
                                //         backgroundColor={hexToRGB('#ECECEC', 0.8)}
                                //         onClick={() => onClickPlusColumnBox(data, innerData)}>
                                //         <Plus src={PlusPath} />
                                //       </NoneClassTd>
                                //     </TDWrapper>
                                //     <BetweenEmptyBox ref={(element) => ButtonRef.current[(index * 7) + idx] = element} />
                                //   </ComponentWrapper>
                                //   :
                                <ComponentWrapper
                                  key={idx}>
                                  {
                                    index === 0 &&
                                    <FirstPlusButtonWrapper
                                      ref={(element) => FirstButtonWrapperRef.current[idx] = element}
                                      onMouseEnter={() => onMouseEnterOfFirstButton(idx)}
                                      onMouseLeave={() => onMouseLeaveOfFirstButton(idx)}>
                                      <FirstPlusButton
                                        ref={(element) => FirstButtonRef.current[idx] = element}
                                        onClick={() => onClickFirstPlusColumnBox(innerData)}>
                                        <BetweenPlusWidth />
                                        <BetweenPlusHeight />
                                      </FirstPlusButton>
                                    </FirstPlusButtonWrapper>
                                  }
                                  {
                                    // onCalcUpAdditionalButtonHeight(data, innerData) !== 0 &&
                                    onCalcUpAdditionalButtonHeight(data, idx) !== 0 &&
                                    <TDWrapper>
                                      <NoneClassTd
                                        // style={{ marginBottom: '6px' }}
                                        height={onCalcUpAdditionalButtonHeight(data, idx)}
                                        backgroundColor={hexToRGB('#ECECEC', 0.8)}
                                        onClick={() => onClickTopPlusColumnBox(data, innerData)}>
                                        <Plus src={PlusPath} />
                                      </NoneClassTd>
                                    </TDWrapper>
                                  }
                                  <TDWrapper
                                    ref={(element) => ComponentRef.current[(index * 7) + idx] = element}
                                    onMouseEnter={() => onMouseEnterTimeComponent(index, idx)}
                                    onMouseLeave={() => onMouseLeaveTimeComponent(index, idx)}>
                                    <Td
                                      ref={(element) => InnerComponentRef.current[(index * 7) + idx] = element}
                                      onClick={(e) => onClickWeeklyScheduleBox(e, index, idx, innerData)}
                                      isValid={innerData.className !== '수업없음'}
                                      height={innerData.height}
                                      hoverHeight={innerData.hoverHeight}
                                      color={innerData.className === '수업없음' ? '#ECECEC' : innerData.color}
                                      // hoverBackColor={innerData.className === '수업없음' ? '#FFFFFF' : hexToRGB(innerData.color, 0.2)}
                                      hoverBackColor={hexToRGB(innerData.color, 0.8)}
                                      backgroundColor={innerData.color}>
                                      <DeleteButtonWrapper ref={(element) => DeleteTotalRef.current[(index * 7) + idx] = element}>
                                        <DeleteBackground
                                          ref={(element) => DeleteBackgroundRef.current[(index * 7) + idx] = element}
                                          backgroundColor={hexToRGB('#FF3131', 0.4)}>
                                          <DeleteButton
                                            ref={(element) => DeleteButtonRef.current[(index * 7) + idx] = element}
                                            onMouseEnter={() => onMouseEnterDeleteButton(index, idx)}
                                            onMouseLeave={() => onMouseLeaveDeleteButton(index, idx)}
                                            onClick={() => onClickDeletedClass(innerData, 'Component')}>
                                            <DeleteIcon src={WhiteTrashIcon} />
                                          </DeleteButton>
                                        </DeleteBackground>
                                      </DeleteButtonWrapper>
                                      <Text>
                                        {/* <ClassName color={innerData.className === '수업없음' ? '#BDBDBD' : '#232323'}>{innerData.className + console.log(innerData)}</ClassName> */}
                                        <ClassNameWrapper>
                                          <ClassName color={innerData.className === '수업없음' ? '#BDBDBD' : '#232323'}>{innerData.className}</ClassName>
                                        </ClassNameWrapper>
                                        <ClassTimeWrapper>
                                          <ClassTime>{innerData.classTime}</ClassTime>
                                        </ClassTimeWrapper>
                                        <CoachNameWrapper>
                                          <CoachName>{innerData.teacherName}</CoachName>
                                        </CoachNameWrapper>
                                      </Text>
                                    </Td>
                                  </TDWrapper>
                                  {
                                    onCalcDownAdditionalButtonHeight(data, idx) !== 0 &&
                                    <TDWrapper>
                                      <NoneClassTd
                                        // style={{ marginTop: '6px' }}
                                        height={onCalcDownAdditionalButtonHeight(data, idx)}
                                        backgroundColor={hexToRGB('#ECECEC', 0.8)}
                                        onClick={() => onClickBottomPlusColumnBox(data, innerData)}>
                                        <Plus src={PlusPath} />
                                      </NoneClassTd>
                                    </TDWrapper>
                                  }
                                  {/* {
                                      (onCalcDownAdditionalButtonHeight(data, innerData) === 0 && selectBetweenButtonView(index, idx)) ?
                                        <BetweenPlusButtonWrapper
                                          ref={(element) => ButtonWrapperRef.current[(index * 7) + idx] = element}
                                          marginTop={onCalcTDHeight(innerData)}
                                          onMouseEnter={() => onMouseEnter(index, idx, onCalcTDHeight(innerData))}
                                          onMouseLeave={() => onMouseLeave(index, idx)}>
                                          <BetweenPlusButton
                                            ref={(element) => ButtonRef.current[(index * 7) + idx] = element}
                                            onClick={() => onClickPlusColumnBox(data, innerData)}>
                                            <BetweenPlusWrapper>
                                              <BetweenPlusWidth />
                                              <BetweenPlusHeight />
                                            </BetweenPlusWrapper>
                                          </BetweenPlusButton>
                                        </BetweenPlusButtonWrapper>
                                        :
                                        <BetweenEmptyBox />
                                    } */}
                                </ComponentWrapper>
                              ))
                            }
                          </Tr>
                        ))
                      }
                    </Tbody>
                  </Table>
                </TableWrapper>
                :
                <EmptyClassInfoBox>
                  <EmptyImage src={EmptySign} />
                  <EmptyText>수업이 존재하지 않습니다</EmptyText>
                </EmptyClassInfoBox>
            }
          </ColumnWrapper>
      }
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  // position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 1020px;
  // height: 449px;
  // height: 686px;

  margin-top: 28px;

  // padding-top: 28px;
  // padding-bottom: 28px;

  // margin-top: 138px;
  // margin-bottom: 96px;

	// opacity: 1;
	// z-index: -3;

  // border-radius: 30px;
  // box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  // background-color: #FFFFFF;
  
  // transition: all 0.3s ease-in-out;
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 974px;
  // min-height: 644px;
  min-height: ${props => props.minHeight}px;
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;
  
  width: 974px;
  height: auto;
  // height: 644px;
  
  // overflow-y: scroll;
  // // overflow-x: hidden;
  // overflow-x: scroll;

  // &::-webkit-scrollbar {
  //   width: 4px;
  // }

  // &::-webkit-scrollbar-thumb {
  //   border: 3px solid transparent;
  //   background-color: #DADADA;
  //   border-radius: 2px;
  // }

  // &::-webkit-scrollbar-track {
  //   height: 439px;
  //   //border: 4px solid transparent;
  //   background-color: #FFFFFF;
  //   border-radius: 2px;
  // }
`;

const TimeDivisionLineWrapper = styled.div`
	display: flex;
	flex-direction: column;

  justify-content: flex-start;
  align-items: center;
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 94.36%;
  // height: 100%;
  // width: 921px;
  // height: 407px;
`;

const Tbody = styled.div`
  display: flex;
  // flex-direction: column;
  flex-direction: row;

  justify-content: center;
  align-items: flex-start;

  width: 100%;
  // height: 100%;

  gap: 8px;
  // gap: 10px;
`;

const Tr = styled.div` 
  display: flex;
  // flex-direction: row;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  // width: 100%;
  // height: 10.04%;
  
  // width: 911px;
  // height: 68px;

  gap: 6px;
  margin: 0 0 0 0;
  // padding: 1px 0 0 0;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  gap: 6px;

  transition: all 0.3s ease-in-out;
`;

const TDWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  transition: all 0.1s ease-in-out;
`;

const Td = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  // justify-content: center;
  justify-content: flex-start;
  align-items: center;

  // width: 123px;
  // height: 66px;

  // 원본 크기 //
  // width: 125px;
  // height: 68px;

  width: 123px;
  // height: 66px;
  height: ${props => props.height}px;

  border: 1px solid ${props => props.color};
  border-radius: 4px;
  // background-color: #FFFFFF;
  background-color: ${props => props.backgroundColor};
  // background-color: blue;
  
  // transition: all 0.3s ease-in-out;

  :hover {
    width: 121px;
    // height: 64px;
    height: ${props => props.hoverHeight}px;

    border: 2px solid ${props => props.color};
    cursor: ${props => props.isValid ? `pointer` : `default`};
    background-color: ${props => props.hoverBackColor};
  }
`;

const DeleteButtonWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-end;

  margin-top: -2px;
  width: 125px;
  // height: 68px;
  // height: 100%;
  height: calc(100% + 4px);

  z-index: 5;
  opacity: 0;

  overflow: hidden;
  // background-color: yellow;
  background-color: transparent;
`;

const DeleteBackground = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-end;

  z-index: 3;

  // width: 125px;
  // height: 68px;
  width: 20px;
  height: 17px;

  // border-radius: 0px 4px 0px 4px;
  border-radius: 15px 0px 0px 90px;
  // background-color: #FF3131;
  background-color: ${props => props.backgroundColor};

  transition: all 0.3s ease-in-out;
`;

const DeleteButton = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 20px;
  height: 17px;

  border-radius: 0px 4px 0px 4px;
  background-color: #FF3131;

  z-index: 6;

  :hover {
    cursor: pointer;
  }

  transition: none;
`;

const DeleteIcon = styled.img`
  width: 12px;
  height: 12px;
`;

const BetweenEmptyBox = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 68px;

  width: 50px;
  height: 8px;

  opacity: 0;
  background-color: #FFFFFF;
`;

const BetweenPlusButtonWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // margin-top: 68px;
  margin-top: ${props => props.marginTop}px;

  width: 125px;

  :hover {
    cursor: pointer;
  }

  transition: all 0.1s ease-in-out;
`;

const BetweenPlusButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 50px;
  height: 8px;

  opacity: 0;

  border-radius: 4px;
  background-color: #FFFFFF;
  // background-color: red;

  transition: all 0.1s ease-in-out;
`;

const BetweenPlusWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 12px;
  height: 100%;
`;

const BetweenPlusWidth = styled.div`
  position: absolute;

  width: 7px;
  height: 1px;

  border-radius: 3px;
  background-color: #FFFFFF;
`;

const BetweenPlusHeight = styled.div`
  position: absolute;

  width: 1px;
  height: 7px;

  border-radius: 3px;
  background-color: #FFFFFF;
`;

const FirstPlusButtonWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: -8px;

  width: 125px;

  :hover {
    cursor: pointer;
  }

  transition: all 0.1s ease-in-out;
`;

const FirstPlusButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 50px;
  height: 8px;

  opacity: 0;

  border-radius: 4px;
  background-color: #FFFFFF;

  transition: all 0.1s ease-in-out;
`;

const NoneClassTd = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 125px;
  // height: 68px;

  // width: 116px;
  width: 117px;
  // height: 60px;
  height: ${props => props.height}px;

  border: 4px solid #FFFFFF;
  border-radius: 4px;
  background-color: ${props => props.backgroundColor};
  
  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 4px solid #FF8B48;
    background-color: #FF8B48;
  }
`;

const Plus = styled.img`
  width: 20px;
  height: 20px;
`;

const Text = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  margin-top: 2px;
  margin-left: -16px;

  // width: 103px;
  width: 93px;
  height: 50px;

  z-index: 4;

  // margin: 0 0 0 0;
  // background-color: red;
`;

const ClassName = styled.p`
  // font-family: NotoSansKR-Medium;
  font-family: NotoSansKR-Bold;
  font-size: 12px;

  // color: ${props => props.color};
  color: #FFFFFF;

  margin: 0 0 0 0;

  width: 100%;
  min-height: 17px;

  overflow: hidden;
`;

const ClassTime = styled.p`
  font-family: Poppins-Regular;
  font-size: 10px;

  // color: #777777;
  color: #FFFFFF;

  margin: 0 0 0 0;
`;

const ClassNameWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 110px;
  height: 17px;

  overflow: hidden;
`;

const ClassTimeWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 110px;
  height: 16px;

  overflow: hidden;
`;

const CoachNameWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 110px;
  height: 15px;

  overflow: hidden;
`;

const CoachName = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 10px;

  // color: #777777;
  color: #FFFFFF;

  margin: 0 0 0 0;
`;

const EmptyClassInfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 974px;
  height: 644px;
`;

const EmptyImage = styled.img`
  width: 148px;
  height: 62px;
`;

const EmptyText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;

  margin: 8px 0 0 0;

  :hover {
    cursor: default;
  }
`;