import { React } from 'react';
import styled from 'styled-components';

export default function GuestNameInput(props) {
	const { value, setValue, placeholder } = props;

	function handleClick(e) {
	}

	function handleChange(e) {
    setValue(e.target.value);
	}

	function handleFocus(e) {
	}

	function handleBlur(e) {
	}

  return (
    <Input
      type="text"
      placeholder={placeholder}
      value={value}
      onClick={handleClick}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur} />
    )
};

const Input = styled.input`
  font-family: NotoSansKR-Medium;
  font-size: 12px;

  margin: 0 0 0 0;
  padding: 0 0 0 0;

  width: 100%;
  height: 100%;

	outline: none;
  border: 0px;
  
	color: #595959;

	::placeholder {
    font-family: NotoSansKR-Medium;
    font-size: 12px;
    
    color: #595959;
	}
	
  transition: all 0.3s ease-in-out;
`;