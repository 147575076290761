import { React, useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

import CheckImage from '../../../image/WhiteCheck.png';

function MovementOptionModal(props) {
  const { data, refreshSectionList, close } = props;

  const [isUseCalories, setIsUseCalories] = useState(false);
  const [isUseCount, setIsUseCount] = useState(false);
  const [isUseDistance, setIsUseDistance] = useState(false);
  const [isUseHeight, setIsUseHeight] = useState(false);
  const [isUseRest, setIsUseRest] = useState(false);
  const [isUseTempo, setIsUseTempo] = useState(false);
  const [isUseTime, setIsUseTime] = useState(false);
  const [isUseWeight, setIsUseWeight] = useState(false);

  // Modal 창 스크롤 제어 //
  useEffect(() => {
    // document.body.style.cssText = `position: fixed; top: -${window.scrollY}px`

    // return () => {
    //   const scrollY = document.body.style.top;
    //   document.body.style.cssText = `position: ""; top: "";`
    //   window.scrollTo(0, parseInt(scrollY || '0') * -1)
    // }

    document.body.style= `overflow: hidden`;
    return () => document.body.style = `overflow: auto`
  }, [])

  // 데이터 기반 설정값 세팅 //
  useEffect(() => {
    setIsUseCalories(data.isUseCalories);
    setIsUseCount(data.isUseCount);
    setIsUseDistance(data.isUseDistance);
    setIsUseHeight(data.isUseHeight);
    setIsUseRest(data.isUseRest);
    setIsUseTempo(data.isUseTempo);
    setIsUseTime(data.isUseTime);
    setIsUseWeight(data.isUseWeight);
  }, [data])

  // 완료버튼 클릭시 //
  function onClickSuccess() {
    data.isUseCalories = isUseCalories;
    data.isUseCount = isUseCount;
    data.isUseDistance = isUseDistance;
    data.isUseHeight = isUseHeight;
    data.isUseRest = isUseRest;
    data.isUseTempo = isUseTempo;
    data.isUseTime = isUseTime;
    data.isUseWeight = isUseWeight;

    refreshSectionList();
    close();
  }

  return (
    <TopLevelWrapper>
      <ModalView>
        <TotalView>
          <Title>동작 옵션</Title>
          <LayerWrapper>
            <CheckBoxWrapper>
              <CheckText>무게</CheckText>
              <CheckBox isClicked={isUseWeight} onClick={() => setIsUseWeight(!isUseWeight)}>
                <Check src={CheckImage}/>
              </CheckBox>
            </CheckBoxWrapper>
            <CheckBoxWrapper>
              <CheckText>횟수</CheckText>
              <CheckBox isClicked={isUseCount} onClick={() => setIsUseCount(!isUseCount)}>
                <Check src={CheckImage}/>
              </CheckBox>
            </CheckBoxWrapper>
            <CheckBoxWrapper>
              <CheckText>시간</CheckText>
              <CheckBox isClicked={isUseTime} onClick={() => setIsUseTime(!isUseTime)}>
                <Check src={CheckImage}/>
              </CheckBox>
            </CheckBoxWrapper>
            <CheckBoxWrapper>
              <CheckText>템포</CheckText>
              <CheckBox isClicked={isUseTempo} onClick={() => setIsUseTempo(!isUseTempo)}>
                <Check src={CheckImage}/>
              </CheckBox>
            </CheckBoxWrapper>
            <CheckBoxWrapper>
              <CheckText>거리</CheckText>
              <CheckBox isClicked={isUseDistance} onClick={() => setIsUseDistance(!isUseDistance)}>
                <Check src={CheckImage}/>
              </CheckBox>
            </CheckBoxWrapper>
            <CheckBoxWrapper>
              <CheckText>높이</CheckText>
              <CheckBox isClicked={isUseHeight} onClick={() => setIsUseHeight(!isUseHeight)}>
                <Check src={CheckImage}/>
              </CheckBox>
            </CheckBoxWrapper>
            <CheckBoxWrapper>
              <CheckText>열량</CheckText>
              <CheckBox isClicked={isUseCalories} onClick={() => setIsUseCalories(!isUseCalories)}>
                <Check src={CheckImage}/>
              </CheckBox>
            </CheckBoxWrapper>
          </LayerWrapper>
          <ButtonWrapper>
            <ExitButton onClick={() => close()}>취소</ExitButton>
            <SaveButton onClick={onClickSuccess}>완료</SaveButton>
          </ButtonWrapper>
        </TotalView>
      </ModalView>
    </TopLevelWrapper>
  )
}

export default MovementOptionModal;

const startAnimation = keyframes`
	0% { opacity: 0; }
	50% { opacity: 0.5; }
	100% { opacity: 1; }
`;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  justify-content: center;
  align-items: center;

  z-index: 1000;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.2);
	
	animation-name: ${startAnimation};
	animation-duration: 0.5s;
	animation-timing-function: ease-out;
`;

const ModalView = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 428px;
  height: 240px;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(0, 0, 0, 0.1);

  background-color: #FFFFFF;
`;

const TotalView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 87.14%;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: 0 0 0 0;
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 26px;

  margin-top: 24px;
`;

const CheckBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  gap: 7px;
`;

const CheckText = styled.div`
  font-family: Poppins-Medium;
  font-size: 14px;

  color: #4F4F4F;

  margin: 0 0 0 0;
`;

const CheckBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 30px;
  height: 30px;

  border-radius: 6px;
  background-color: ${props => props.isClicked ? `#6DD49E` : `#E0E0E0`};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const Check = styled.img`
  width: 15.75px;
  height: 13.5px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  gap: 16px;
  
  width: 100%;

  margin-top: 39px;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 72px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;
  
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #777777;
    color: #777777;
  }
`;

const SaveButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 74px;
  height: 40px;

  border-radius: 15px;
  background-color: #5BD98A;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    background-color: #2BCB67;
    cursor: pointer;
  }
`;
