import { React, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import SearchInput from '../../../../Component/ClassManageComponent/SearchInput';

import FetchModule from '../../../Share/Network/FetchModule';

import ExitButtonImagePath from '../../../../image/ExitButton.png';

function MemberReservationModal(props) {
  const { data, cancel, historyOpen, reserveDataReLoad, setReserveDataReLoad, scheduleData } = props;

  const [memberList, setMemberList] = useState([]);
  const [useMemberList, setUseMemberList] = useState([]);
  const [useReserveList, setUseReserveList] = useState([]);

  const [isKorea, setIsKorea] = useState(false);
  const [classTime, setIsClassTime] = useState(false);

  const [searchData, setSearchData] = useState('');

  const [state, setState] = useState(false);

  const SearchRef = useRef();

  const history = useHistory();

  useEffect(() => {
    // setNowSelectWeek(oneWeekData);
    document.body.style.cssText = `position: fixed; top: -${window.scrollY}px`

    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = `position: ""; top: "";`
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }
  }, [])

  useEffect(() => {
    const korea = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
    
    if (korea.test(scheduleData.className)) setIsKorea(true);

    var classTime = scheduleData.classTime.split('-');
    var startTime = '';
    var endTime = '';

    if (classTime[0].split(':')[0] >= 12) startTime = classTime[0] + ' PM';
    else startTime = classTime[0] + ' AM'

    if (classTime[1].split(':')[0] >= 12) endTime = classTime[1] + ' PM';
    else endTime = classTime[1] + ' AM';

    setIsClassTime(startTime + ' ~ ' + endTime);
  }, [scheduleData])
  
  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getData('member/time-line/', 'GET');
        // console.log(responseData.data);
        if (responseData.status === 200) setMemberList(responseData.data);
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }, [history])

  useEffect(() => {
    // var start = moment(scheduleData.classDate.replaceAll('.', '-') + 'T' + scheduleData.classTime.split('-')[0] + ':00');
    var end = moment(scheduleData.classDate.replaceAll('.', '-') + 'T' + scheduleData.classTime.split('-')[1] + ':00');
    var now = moment();
    
    if (end.diff(now) > 0) setState(true);
    else setState(false);
  }, [scheduleData])

  useEffect(() => {
    var newData = [];
    var newReserveData = [];

    for (var i = 0; i < memberList.length; i++) {
      var flag = true;

      for (var j = 0; j < data.length; j++) {
        if (memberList[i].userId === data[j].userId) {
          // console.log(data[j]);
          memberList[i].lessonReserveId = data[j].id;
          newReserveData.push(memberList[i]);
          flag = false;
          break;
        }
      }

      if (flag) newData.push(memberList[i]);
    }

    setUseReserveList(newReserveData);
 
    var findData = [];

    if (searchData !== '') {
      for (i = 0; i < newData.length; i++) {
        if (newData[i].name.includes(searchData) || newData[i].phone.includes(searchData)) findData.push(newData[i]);
      }
    }
    else findData = newData;

    setUseMemberList(findData);
  }, [memberList, data, reserveDataReLoad, searchData])

  function onClickMemberReserveButton(index) { 
    // console.log(useMemberList[index]);
    // console.log(scheduleData);

    if (!state) {
      alert("예약 조절 가능시간이 아닙니다.");
      return;
    }

    var requestData = {
      scheduleId: scheduleData.scheduleId,
      lessonId: scheduleData.lessonId,
      userId: useMemberList[index].memberId,
      classDate: scheduleData.classDate.replaceAll('.', '-') + "T00:00:00",
      classTime: scheduleData.classTime,
      status: 0
    }
    // console.log(requestData);
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postData('reserve/create/manage', 'POST', requestData);
        
        if (responseData.status === 200) setReserveDataReLoad(!reserveDataReLoad);
        else if (responseData.status === 201) alert('알맞은 회원권을 소지한 회원이 아닙니다');
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }

  function onClickMemberExceptButton(index) {
    if (!state) {
      alert("예약 조절 가능시간이 아닙니다.");
      return;
    }
    // console.log(useReserveList[index]);
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postData('reserve/delete/manage/' + useReserveList[index].lessonReserveId, 'POST');
        
        if (responseData.status === 200) setReserveDataReLoad(!reserveDataReLoad);
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }

  return (
    <TopLevelWrapper>
      <ModalView>
        <TotalWrapper>
          <TitleWrapper>
            <Title>회원 예약</Title>
            {/* <MemberReserveButton onClick={historyOpen}>예약내역</MemberReserveButton> */}
            <ClassTitleWrapper>
              <ClassTitle isKorea={isKorea}>{scheduleData.className}</ClassTitle>
              <ClassTime>{classTime}</ClassTime>
            </ClassTitleWrapper>
          </TitleWrapper>
          <SearchInputWrapper>
            <SearchInput ref={SearchRef} value={searchData} setValue={setSearchData} placeholder='회원의 이름이나 전화번호를 검색해주세요' type='text' />
            <SubTitleWrapper>
              <SubTitle>예약된 회원</SubTitle>
              <SubTitle>{useReserveList.length}/{scheduleData.maxBookedPeople}</SubTitle>
            </SubTitleWrapper>
          </SearchInputWrapper>
          <InfoBoxWrapper>
            <InfoBox>
              {
                useMemberList.map((data, index) => (
                  <InfoWrapper key={index}>
                    {/* <Name>{data.name}</Name> */}
                    <NameWrapper>
                      <Name>{data.name}</Name>
                    </NameWrapper>
                    <Phone>{data.phone.slice(0,3) + '-' + data.phone.slice(3, 7) + '-' + data.phone.slice(7, 11)}</Phone>
                    {/* <ExceptButton src={ExitButtonImagePath} onClick={onClickMemberExceptButton} /> */}
                    <AdditionButton onClick={() => onClickMemberReserveButton(index)}>추가</AdditionButton>
                  </InfoWrapper>
                ))
              }
            </InfoBox>
            <InfoBox>
              {
                useReserveList.map((innerData, index) => (
                  <InfoWrapper key={index}>
                    <NameWrapper>
                      <Name>{innerData.name}</Name>
                    </NameWrapper>
                    <Phone>{innerData.phone.slice(0, 3) + '-' + innerData.phone.slice(3, 7) + '-' + innerData.phone.slice(7, 11)}</Phone>
                    <ExceptButton src={ExitButtonImagePath} onClick={() => onClickMemberExceptButton(index)} />
                  </InfoWrapper>
                ))
              }
            </InfoBox>
          </InfoBoxWrapper>
          <ExitWrapper>
            <ExitButton onClick={cancel}>닫기</ExitButton>
          </ExitWrapper>
        </TotalWrapper>
      </ModalView>
    </TopLevelWrapper>
  )
}

export default MemberReservationModal;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  justify-content: center;
  align-items: center;

  z-index: 1000;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.2);
`;

const ModalView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 322px;
  width: 609px;
  // height: 354px;
  height: 354px;

  border-radius: 30px;
  background-color: #FFFFFF;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  // width: 266px;
  width: 553px;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: 0 0 0 0;
`;

const ClassTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-end;
`;

const ClassTitle = styled.p`
  font-family: ${props => props.isKorea ? `NotoSansKR-Medium` : `Poppins-Medium`};
  font-size: 16px;

  color: #232323;

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const ClassTime = styled.p`
  font-family: Poppins-Medium;
  font-size: 12px;

  color: #777777;

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const MemberReserveButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 83px;
  height: 30px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background-color: #FFFFFF;

  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #D0D0D0;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    color: #232323;
    border: 1px solid #232323;
  }
`;

const SearchInputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 18px;

  gap: 20px;
`;

const SubTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 163px;
`;

const SubTitle = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 12px;

  color: #D0D0D0;

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const InfoBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 20px;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 24px;

  width: 267px;
  height: 124px;

  overflow-y: scroll;
  overflow-x: none;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
		border: 3px solid transparent;
		background-color: #DADADA;
		border-radius: 200px;
	}

  &::-webkit-scrollbar-track {
		background-color: #F0F0F0;
		border-radius: 200px;
	}
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 252px;
  height: 32px;

  border-bottom: 1px solid #F5F5F5;
`;

const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-right: 7px;

  width: 50px;
  // height: 32px;

  overflow: hidden;
`;

const Name = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 12px;

  color: #595959;

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

// const Name = styled.div`
//   display: flex;
//   flex-direction: column;

//   justify-content: center;
//   align-items: flex-start;

//   width: 57px;
//   height: 32px;

//   font-family: NotoSansKR-Medium;
//   font-size: 12px;

//   color: #595959;

//   overflow: hidden;
// `;

const Phone = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: 124px;
  height: 32px;

  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #777777;
  
  overflow: hidden;

  :hover {
    cursor: default;
  }
`;

const ExceptButton = styled.img`
  width: 16px;
  height: 16px;

  margin: 0 0 0 55px;

  :hover {
    cursor: pointer;
  }
`;

const ExitWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-end;

  width: 100%;

  margin-top: 46px;
`;

const AdditionButton = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 12px;

  color: #5BD98A;

  margin: 0 0 0 45px;

  :hover {
    cursor: pointer;
  }
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  // border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #5BD98A;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    // border: 1px solid #232323;
    // color: #232323;
    background-color: #2BCB67;
  }
`;