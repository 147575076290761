import React, { useState } from "react";
import styled from "styled-components";

import TagExplanationModal from './TagExplanationModal';

import InfoIcon from '../../image/InfomationIcon.png';
import TagSelectBox from "../Component/TagSelectBox";
import RoundInput from "../Component/RoundInput";
import TimeLimitInput from "../Component/TimeLimitInput";
import ExerciseTimeInput from "../Component/ExerciseTimeInput";
import EMOMInput from "../Component/EMOMInput";
import CustomScoreSelectBox from "../Component/CustomScoreSelectBox";
import CustomScoreDetailSelectBox from "../Component/CustomScoreDetailSelectBox";

export default function DivisionBox(props) {
  const { sectionData, refreshTotalData } = props;

  const [isClickedTagExplanation, setIsClickedTagExplanation] = useState(false);

  // 받아온 데이터로 섹션 태그 정리
  function decideSectionTag() {
    // if (sectionData.)
    return { name: sectionData.tag };
  }

  // 받아온 데이터로 시간제한 분 계산 //
  function decideSectionTimeLimitMin() {
    return parseInt(sectionData.timeLimit / 60);
  }

  // 받아온 데이터로 시간제한 초 계산 //
  function decideSectionTimeLimitSec() {
    return parseInt(sectionData.timeLimit % 60);
  }

  // 받아온 데이터로 운동시간 분 계산 //
  function decideSectionExerciseTimeMin() {
    return parseInt(sectionData.exerciseTime / 60);
  }

  // 받아온 데이터로 운동시간 초 계산 //
  function decideSectionExerciseTimeSec() {
    return parseInt(sectionData.exerciseTime % 60);
  }

  // 받아온 데이터로 쉬는시간시간 분 계산 //
  function decideSectionRestTimeMin() {
    return parseInt(sectionData.restTime / 60);
  }

  // 받아온 데이터로 쉬는시간 초 계산 //
  function decideSectionRestTimeSec() {
    return parseInt(sectionData.restTime % 60);
  }

  // EMOM 총 운동 진행시간 표출 //
  function decideEMOMExerciseTime() {
    let min = parseInt(sectionData.round * sectionData.exerciseTime / 60);
    let sec = parseInt(sectionData.round * sectionData.exerciseTime % 60);

    if (min > 0 && sec > 0) return min + '분 ' + sec + '초';
    else if (min > 0) return min + ' 분';
    else if (sec > 0) return sec + ' 초';
    else return '0 분';
  }

  // TABATA 총 운동 진행시간 표출 //
  function decideTABATAExerciseTime() {
    // let min = parseInt(sectionData.round * sectionData.exerciseTime / 60) + parseInt(sectionData.round * sectionData.restTime / 60);
    // let sec = parseInt(sectionData.round * sectionData.exerciseTime % 60) + parseInt(sectionData.round * sectionData.restTime % 60);

    let min = parseInt(sectionData.round * (parseInt(sectionData.exerciseTime) + parseInt(sectionData.restTime)) / 60);
    let sec = parseInt(sectionData.round * (parseInt(sectionData.exerciseTime) + parseInt(sectionData.restTime)) % 60);

    if (min > 0 && sec > 0) return min + '분 ' + sec + '초';
    else if (min > 0) return min + ' 분';
    else if (sec > 0) return sec + ' 초';
    else return '0 분';
  }

  // 커스텀 점수 기준 정리 //
  function decideCustomScoreDetailDisabled() {
    if (sectionData.scoreKeyword === '점수 없음' 
      || sectionData.scoreKeyword === '피크와트' 
      || sectionData.scoreKeyword === '최고 속도'
      || sectionData.scoreKeyword === 'Not Scored'
      || sectionData.scoreKeyword === 'Peak Watts'
      || sectionData.scoreKeyword === 'Max Speed') return true;
    else return false;
  }

  // 섹션 태그 변경시 //
  function onChangeSectionTag(value) {
    sectionData.tag = value.name;

    refreshTotalData();
  }

  // 라운드 변경시 //
  function onChangeSectionRound(value) {

    // if (value !== '') value = parseInt(value);

    sectionData.round = value;

    refreshTotalData();
  }

  // 시간제한 여부 변경 클릭시 //
  function onChangeSectionUseTimeLimit() {
    sectionData.isUseTimeLimit = !sectionData.isUseTimeLimit;

    refreshTotalData();
  }

  // 시간제한 분 변경시 //
  function onChangeSectionTimeLimitMin(value) {
    sectionData.timeLimit = (value * 60) + parseInt(sectionData.timeLimit % 60);

    refreshTotalData();
  }

  // 시간제한 초 변경시 //
  function onChangeSectionTimeLimitSec(value) {
    let minute = sectionData.timeLimit - parseInt(sectionData.timeLimit % 60);

    sectionData.timeLimit = parseInt(minute) + parseInt(value);

    refreshTotalData();
  }

  // 운동시간 분 변경시 //
  function onChangeSectionExerciseTimeMin(value) {
    sectionData.exerciseTime = (value * 60) + parseInt(sectionData.exerciseTime % 60);

    refreshTotalData();
  }

  // 운동시간 초 변경시 //
  function onChangeSectionExerciseTimeSec(value) {
    let minute = sectionData.exerciseTime - parseInt(sectionData.exerciseTime % 60);

    sectionData.exerciseTime = parseInt(minute) + parseInt(value);

    refreshTotalData();
  }

  // 운동시간 분 변경시 //
  function onChangeSectionRestTimeMin(value) {
    sectionData.restTime = (value * 60) + parseInt(sectionData.restTime % 60);

    refreshTotalData();
  }

  // 운동시간 초 변경시 //
  function onChangeSectionRestTimeSec(value) {
    let minute = sectionData.restTime - parseInt(sectionData.restTime % 60);

    sectionData.restTime = parseInt(minute) + parseInt(value);

    refreshTotalData();
  }

  // Custom Exercise ScoreKeyword 변경시 //
  function onChangeScoreKeyword(scoreKeyword) {
    sectionData.scoreKeyword = scoreKeyword;

    refreshTotalData();
  }

  // Custom Exercise ScorePriority 변경시 //
  function onChangeScorePriority(scorePriority) {
    sectionData.scorePriority = scorePriority;

    refreshTotalData();
  }


  return (
    <DivisionBoxWrapper>
      {isClickedTagExplanation && <TagExplanationModal close={() => setIsClickedTagExplanation(!isClickedTagExplanation)} />}
      <MainIconWrapper onClick={() => setIsClickedTagExplanation(true)}>
        <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#666666' hover='pointer'>메인 운동</Text>
        <Icon width={20} height={20} src={InfoIcon} />
      </MainIconWrapper>
      <TagSelectBoxWrapper>
        <TagSelectBox value={decideSectionTag()} setValue={onChangeSectionTag} />
      </TagSelectBoxWrapper>
      {
        sectionData.tag === 'ForTime' &&
        <TagTotalRoundBox>
          <LayerWrapper>
            <HalfWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#666666' hover='defualt'>라운드</Text>
              <RoundInput value={sectionData.round} setValue={onChangeSectionRound} unit='R' />
            </HalfWrapper>
            <HalfWrapper>
              <TimeLimitWrapper onClick={onChangeSectionUseTimeLimit}>
                <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#666666' hover='pointer'>시간 제한{sectionData.isUseTimeLimit ? ' 있음' : ' 없음'}</Text>
                <TimeLimitCheckBox isClicked={sectionData.isUseTimeLimit} />
              </TimeLimitWrapper>
              <TimeLimitInput
                isUseTimeLimit={sectionData.isUseTimeLimit}
                min={decideSectionTimeLimitMin()}
                setMin={onChangeSectionTimeLimitMin}
                sec={decideSectionTimeLimitSec()}
                setSec={onChangeSectionTimeLimitSec} />
            </HalfWrapper>
          </LayerWrapper>
        </TagTotalRoundBox>
      }
      {
        sectionData.tag === 'AMRAP' &&
        <TagTotalRoundBox>
          <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#666666' hover='defualt'>운동 시간</Text>
          <TagInputWrapper>
            <ExerciseTimeInput
              min={decideSectionExerciseTimeMin()}
              setMin={onChangeSectionExerciseTimeMin}
              sec={decideSectionExerciseTimeSec()}
              setSec={onChangeSectionExerciseTimeSec} />
          </TagInputWrapper>
        </TagTotalRoundBox>
      }
      {
        sectionData.tag === 'EMOM' &&
        <TagTotalRoundBox>
          <LayerWrapper>
            <QuarterWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#666666' hover='defualt'>라운드</Text>
              <LayerWrapper style={{ alignItems: 'center', marginTop: '8px' }}>
                <EMOMInputWrapper>
                  <EMOMInput value={sectionData.round} setValue={onChangeSectionRound} placeholder='0' />
                </EMOMInputWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={12} color='#666666' hover='defualt'>R</Text>
              </LayerWrapper>
            </QuarterWrapper>
            <QuarterWrapper>
              <DivisionLine style={{ marginTop: '42px' }} />
            </QuarterWrapper>
            <QuarterWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#666666' hover='defualt'>라운드별 운동시간</Text>
              <LayerWrapper style={{ alignItems: 'center', marginTop: '8px' }}>
                <EMOMInputWrapper>
                  <EMOMInput value={decideSectionExerciseTimeMin()} setValue={onChangeSectionExerciseTimeMin} placeholder='0' />
                </EMOMInputWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={12} color='#666666' hover='defualt'>분</Text>
              </LayerWrapper>
            </QuarterWrapper>
            <QuarterWrapper>
              <LayerWrapper style={{ justifyContent: 'flex-start', gap: '4px' }}>
                <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#666666' hover='defualt'>총</Text>
                <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#6DD49E' hover='defualt'>{decideEMOMExerciseTime() + ' 운동'}</Text>
              </LayerWrapper>
              <LayerWrapper style={{ alignItems: 'center', marginTop: '8px' }}>
                <EMOMInputWrapper>
                  <EMOMInput value={decideSectionExerciseTimeSec()} setValue={onChangeSectionExerciseTimeSec} placeholder='0' />
                </EMOMInputWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={12} color='#666666' hover='defualt'>초</Text>
              </LayerWrapper>
            </QuarterWrapper>
          </LayerWrapper>
        </TagTotalRoundBox>
      }
      {
        sectionData.tag === 'TABATA' &&
        <TagTotalRoundBox>
          <LayerWrapper>
            <HalfWrapper style={{ gap: '0px' }}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#666666' hover='defualt'>라운드</Text>
              <LayerWrapper style={{ alignItems: 'center', marginTop: '8px' }}>
                <EMOMInputWrapper style={{ width: '92%' }}>
                  <EMOMInput value={sectionData.round} setValue={onChangeSectionRound} placeholder='0' />
                </EMOMInputWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={12} color='#666666' hover='defualt'>R</Text>
              </LayerWrapper>
            </HalfWrapper>
            <HalfWrapper style={{ gap: '0px' }}>
              <LayerWrapper style={{ justifyContent: 'flex-start', marginTop: '35px', gap: '4px' }}>
                <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#666666' hover='defualt'>총</Text>
                <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#6DD49E' hover='defualt'>{decideTABATAExerciseTime()}</Text>
                <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#666666' hover='defualt'>동안 진행됩니다</Text>
              </LayerWrapper>
            </HalfWrapper>
          </LayerWrapper>
          <LayerWrapper style={{ marginTop: '14px'}}>
            <HalfWrapper style={{ gap: '0px' }}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#666666' hover='defualt'>동작별 운동 시간</Text>
              <TagInputWrapper>
                <ExerciseTimeInput
                  min={decideSectionExerciseTimeMin()}
                  setMin={onChangeSectionExerciseTimeMin}
                  sec={decideSectionExerciseTimeSec()}
                  setSec={onChangeSectionExerciseTimeSec} />
              </TagInputWrapper>
            </HalfWrapper>
            <DivisionHeight/>
            <HalfWrapper style={{ gap: '0px' }}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#666666' hover='defualt'>동작별 쉬는 시간</Text>
              <TagInputWrapper>
                <ExerciseTimeInput
                  min={decideSectionRestTimeMin()}
                  setMin={onChangeSectionRestTimeMin}
                  sec={decideSectionRestTimeSec()}
                  setSec={onChangeSectionRestTimeSec} />
              </TagInputWrapper>
            </HalfWrapper>
          </LayerWrapper>
        </TagTotalRoundBox>
      }
      {
        sectionData.tag === 'Custom' &&
        <TagTotalRoundBox style={{zIndex: '8'}}>
          <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#666666' hover='defualt'>점수 기록 방식</Text>
          <LayerWrapper style={{ marginTop: '8px' }}>
            <HalfWrapper style={{ height: '36px' }}>
              <CustomScoreSelectBox value={sectionData.scoreKeyword} setValue={onChangeScoreKeyword} />
            </HalfWrapper>
            <HalfWrapper style={{ height: '36px' }}>
              <CustomScoreDetailSelectBox value={sectionData.scorePriority} setValue={onChangeScorePriority} disabled={decideCustomScoreDetailDisabled()} />
            </HalfWrapper>
          </LayerWrapper>
        </TagTotalRoundBox>
      }
    </DivisionBoxWrapper>
  )
}

const DivisionBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
`;

const MainIconWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
`;

const TagSelectBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
  
  margin-top: 8px;

  width: 100%;
  height: 34px;

  z-index: 10;
`;

const TagTotalRoundBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 20px;

  width: 100%;
`;

const TagInputWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
  
  margin-top: 8px;

  width: 100%;
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  width: 100%;
`;

const HalfWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 48%;

  gap: 8px;
`;

const QuarterWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 23%;
`;

const EMOMInputWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 85%;
`;

const TimeLimitWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 100%;

  gap: 4px;

  :hover {
    cursor: pointer;
  }
`;

const TimeLimitCheckBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 16px;
  height: 16px;

  border-radius: 4px;
  background-color: ${props => props.isClicked ? '#5BD98A' : '#D9D9D9'};

  transition: all 0.3s ease-in-out;
`;

const DivisionLine = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 1px;

  background-color: #E5E5E5;
`;


const DivisionHeight = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 30px;

  width: 2px;
  height: 25px;

  border-radius: 2px;
  background-color: #E5E5E5;
`;

const Icon = styled.img`
  width: ${props => props.width}px;
  height: ${props => props.height}px;

  :hover {
    cursor: pointer;
  }
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  
  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;
