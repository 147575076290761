import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import RecordStandardBox from './RecordStandardBox';
import AddMovementBox from './AddMovementBox';

import WhiteGoBackImage from '../../image/WhiteGoBackButton.png';
import TrashImage from '../../image/TrashIcon.png';
import ScaleExerciseBox from './ScaleExerciseBox';

export default function ScaleWodDetaiBox(props) {
  const {
    width,
    maxHeight,
    clickData,

    dates,
    clickDate,
    clickWeekIndex,
    clickDayIndex,
    clickWodIndex,

    nowSection,
    setNowSection,
    nowScaleSection,
    setNowScaleSection,

    movements,
    setMovements,
    recentlySearchHistory,
    setRecentlySearchHistory,

    refreshTotalData,

    onClickDeleteScaleSection,
    onClickExitScaleWodDetailBox
  } = props;

  const [isNewVersionWod, setIsNewVersionWod] = useState(false);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (dates.length === 0
      || clickWeekIndex === undefined
      || clickDayIndex === undefined
      || clickWodIndex === undefined
      || nowSection === undefined
      || nowScaleSection === undefined) {
      setIsReady(false);
      return;
    }

    let wod = dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex];

    if (wod.wodVersion === 2) {
      let size = dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex].sectionList[nowSection].scaleSections.length;
      
      setIsNewVersionWod(wod.wodVersion === 2);
      setIsReady(size > 0);
    }
    else {
      setIsNewVersionWod(false);
      setIsReady(false);
    }
  }, [dates, clickWeekIndex, clickDayIndex, clickWodIndex, nowSection, nowScaleSection])

  return (
    <WodDetailBoxWrapper width={width} height={maxHeight}>
      <ScaleWodDetailBoxWrapper>
        <ScaleOptionHeader>
          <ScaleOptionHeaderInnerWrapper>
            <Text fontFamily='NotoSansKR-Black' fontSize={14} color='#FFFFFF' hover='default'>{String.fromCharCode(nowSection + 65) + ' Session'}</Text>
            <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF' hover='default'>Scale Option</Text>
          </ScaleOptionHeaderInnerWrapper>
          <GoBackButton onClick={onClickExitScaleWodDetailBox}>
            <GoBackButtonImg src={WhiteGoBackImage} />
          </GoBackButton>
        </ScaleOptionHeader>
        <DivisionLine style={{ margin: '8px 0px' }} />
        <DivisionBoxWrapper>
          <ScaleSectionStateWrapper>
            <ScaleWodSectionButton>
              <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF' hover='pointer'>{String.fromCharCode(nowSection + 65) + '-' + (nowScaleSection + 2)}</Text>
            </ScaleWodSectionButton>
            <WodSectionFunctionButton backgroundColor='#FF3131' onClick={() => onClickDeleteScaleSection(nowScaleSection)}>
              <WodSectionButtonImage width={17} height={17} src={TrashImage} />
            </WodSectionFunctionButton>
          </ScaleSectionStateWrapper>
        </DivisionBoxWrapper>
        {
          isReady 
          && nowScaleSection !== undefined
          && dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex].wodVersion === 2
          && dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex].sectionList[nowSection].scaleSections.length > nowScaleSection && 
          <WodWrapper>
            <ScaleExerciseBox
              clickData={dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex]}

              movements={movements}
              recentlySearchHistory={recentlySearchHistory}
              setRecentlySearchHistory={setRecentlySearchHistory}

              refreshTotalData={refreshTotalData}

              nowSection={nowSection}
              setNowSection={setNowSection}
              nowScaleSection={nowScaleSection}
              setNowScaleSection={setNowScaleSection}

              clickDate={clickDate}
              clickWeekIndex={clickWeekIndex}
              clickDayIndex={clickDayIndex}
              clickWorkoutIndex={clickWodIndex} />
          </WodWrapper>
        }
      </ScaleWodDetailBoxWrapper>
      {
        (isReady 
          && isNewVersionWod 
          && nowScaleSection !== undefined
          && dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex].wodVersion === 2
          && dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex].sectionList[nowSection].scaleSections.length > nowScaleSection
          && dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex].sectionList[nowSection].scaleSections[nowScaleSection].tag !== 'SINGLE') && 
        <NewWodBottomWrapper>
          <DivisionLine />
          <RecordStandardWrapper>
            <RecordStandardBox sectionData={dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex].sectionList[nowSection].scaleSections[nowScaleSection]} />
          </RecordStandardWrapper>
          {
            dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex].sectionList[nowSection].scaleSections[nowScaleSection].tag !== 'Custom' &&
            <AddMovementBoxWrapper>
              <AddMovementBox
                sectionData={dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex].sectionList[nowSection].scaleSections[nowScaleSection]}
                movements={movements}
                setMovements={setMovements}
                recentlySearchHistory={recentlySearchHistory}
                setRecentlySearchHistory={setRecentlySearchHistory}
                refreshTotalData={refreshTotalData} />
            </AddMovementBoxWrapper>
          }
        </NewWodBottomWrapper>
      }
    </WodDetailBoxWrapper>
  )
}

const WodDetailBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: ${props => props.width};
  min-width: ${props => props.width};
  height: ${props => props.height}px;
  min-height: ${props => props.height}px;

  overflow-x: hidden;
  overflow-y: scroll;
  
  outline: none;

  &::-webkit-scrollbar {
    // width: 2px;
    // height: 4px;
    width: 0px;
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
		// border: 2px solid transparent;
		border: 0px solid transparent;
		background-color: #D9D9D9;
		border-radius: 2px;
	}

  &::-webkit-scrollbar-track {
		// background-color: #F9F9F9;
		border-radius: 200px;
    opacity: 0;
	}

  transition: all 0.3s ease-in-out;
`;

const ScaleWodDetailBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 100%;

  // overflow-x: hidden;
  
  border-radius: 10px;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);

  transition: all 0.3s ease-in-out;
`;

const ScaleOptionHeader = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding: 0px 15px 0px 25px;

  width: calc(100% - 40px);
  height: 55px;

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #4D92EF;
`;

const ScaleOptionHeaderInnerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 100%;

  gap: 10px;
`;

const GoBackButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 35px;
  height: 35px;

  border-radius: 35px;
  background-color: #599CF6;

  :hover {
    cursor: pointer;
    background-color: #006DFF;
  }

  transition: all 0.3s ease-in-out;
`;

const GoBackButtonImg = styled.img`
  width: 9px;
  height: 15px;

  :hover {
    cursor: pointer;
  }
`;

const DivisionLine = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 1px;

  background-color: #E5E5E5;
`;

const ScaleSectionStateWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;


  width: 100%;
`;

const ScaleWodSectionButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 43px;
  height: 34px;

  border-radius: 10px;
  background-color: #4D92EF;

  transition: all 0.3s ease-in-out;
`;

const DivisionBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 0px 0px 20px 0px;

  width: calc(100% - 30px);
`;

const WodSectionFunctionButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 34px;
  height: 34px;

  border-radius: 10px;
  background-color: ${props => props.backgroundColor};

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const WodSectionButtonImage = styled.img`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;

const NewWodBottomWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 35px;

  width: 100%;
`;

const RecordStandardWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 35px;

  width: 100%;
`;

const AddMovementBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 12px;

  width: 100%;
`;

const WodWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;

  z-index: 3;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  
  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;
