import { React, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import NormalMovementBox from './NormalMovementBox';
import TagExplanationModal from './TagExplanationModal';
import VideoViewModal from './VideoViewModal';
import RankingCommentBox from './RankingCommentBox';

import FetchModule from '../../Share/Network/FetchModule';

// Image Import //
import CheckImage from '../../../image/WhiteCheck.png';
import NextRepsImage from '../../../image/NextRepsImage.png';
import InfoIcon from '../../../image/InfomationIcon.png';
import ExericseReview from './ExerciseReview';
import RankingCommentTextArea from '../../../Component/NoteComponent/RankingCommentTextArea';
// Image Import //

function RankNormalSectionBox(props) {
  const { index, sectionRecordData, sectionList, scrollIntoBottom } = props;

  const history = useHistory();

  const [title, setTitle] = useState('');
  const [scoreKeyword, setScoreKeyword] = useState('점수 없음');
  const [scorePriority, setScorePriority] = useState('');
  const [videoLink, setVideoLink] = useState('');
  const [dataReps, setDataReps] = useState('');

  const [isClickedTagExplanation, setIsClickedTagExplanation] = useState(false);
  const [isClickedVideoView, setIsClickedVideoView] = useState(false);

  const [isClickedViewComment, setIsClickedViewComment] = useState(true);
  const [isClickedViewCommentIndex, setIsClickedViewCommentIndex] = useState(-1);
  const [comments, setComments] = useState([]);
  const [commentValue, setCommentValue] = useState('');

  // Data Setting //
  useEffect(() => {
    let newTitle = '';

    if (sectionList.tag === 'ForTime-Rounds') newTitle = sectionList.round + ' Rounds For Time';
    else if (sectionList.tag === 'ForTime-Different Reps') {
      newTitle = 'For Time ';

      for (let i = 0; i < sectionList.reps.length; i++) {
        if (i === 0) newTitle += sectionList.reps[i];
        else newTitle += '-' + sectionList.reps[i];
      }

      // DifferentReps Data Change //
      let newReps = '';

      if (sectionList.reps.length <= 6) {
        for (let i = 0; i < sectionList.reps.length; i++) {
          if (i !== 0) newReps += ' - ';
          newReps += parseInt(sectionList.reps[i]);
        }
      }
      else {
        newReps += parseInt(sectionList.reps[0]) + ' - '
          + parseInt(sectionList.reps[1]) + ' - '
          + parseInt(sectionList.reps[2]) + ' - '
          + parseInt(sectionList.reps[3]) + ' - ... - '
          + parseInt(sectionList.reps[sectionList.reps.length - 1]);
      }

      setDataReps(newReps);
    }
    else if (sectionList.tag === 'AMRAP') {
      let minute = parseInt(sectionList.exerciseTime / 60);
      let second = parseInt(sectionList.exerciseTime % 60);

      if (minute > 0) {
        if (second > 0) newTitle += minute + ' Minute ' + second + ' Second AMRAP';
        else newTitle += minute + ' Minute AMRAP';
      }
      else {
        if (second > 0) newTitle += second + ' Second AMRAP';
        else newTitle += 'AMRAP';
      }
    }
    else if (sectionList.tag === 'EMOM') {
      let minute = parseInt((sectionList.exerciseTime * sectionList.round) / 60);
      let second = parseInt((sectionList.exerciseTime * sectionList.round) % 60);

      if (minute > 0) {
        if (second > 0) newTitle += minute + ' Minute ' + second + ' Second EMOM';
        else newTitle += minute + ' Minute EMOM';
      }
      else {
        if (second > 0) newTitle += second + ' Second EMOM';
        else newTitle += 'EMOM';
      }
    }
    else if (sectionList.tag === 'TABATA') {
      let exerciseMinute = parseInt(sectionList.exerciseTime / 60);
      let exerciseSecond = parseInt(sectionList.exerciseTime % 60);
      let restMinute = parseInt(sectionList.restTime / 60);
      let restSecond = parseInt(sectionList.restTime % 60);

      newTitle += sectionList.round + ' Rounds TABATA ';

      if (exerciseMinute > 0) {
        if (exerciseSecond > 0) newTitle += exerciseMinute + ' m ' + exerciseSecond + ' s ON / ';
        else newTitle += exerciseMinute + ' m ON / ';
      }
      else {
        if (exerciseSecond > 0) newTitle += exerciseSecond + ' s ON / ';
        else newTitle += ' 0 s ON / ';
      }

      if (restMinute > 0) {
        if (restSecond > 0) newTitle += restMinute + ' m ' + restSecond + ' s OFF ';
        else newTitle += restMinute + ' m OFF ';
      }
      else {
        if (restSecond > 0) newTitle += restSecond + ' s OFF ';
        else newTitle += ' 0 s OFF ';
      }

      newTitle += 'With no break between movement'
    }
    else if (sectionList.tag === 'SINGLE') {
      if (sectionList.mainExerciseList.length === 1) newTitle += sectionList.mainExerciseList[0].movementName;
      else newTitle += 'Multiple Single';
    }
    else if (sectionList.tag === 'Custom') {
      newTitle += sectionList.customTitle;

      // '점수 없음', '시간', '횟수', '라운드 + 횟수', '거리', '무게', '포인트', '피크와트', '최고 속도' //
      if (sectionList.scoreKeyword === 'Not Scored') setScoreKeyword('점수 없음');
      else if (sectionList.scoreKeyword === 'Time') setScoreKeyword('시간');
      else if (sectionList.scoreKeyword === 'Reps') setScoreKeyword('횟수');
      else if (sectionList.scoreKeyword === 'Rounds And Reps') setScoreKeyword('라운드 + 횟수');
      else if (sectionList.scoreKeyword === 'Distance') setScoreKeyword('거리');
      else if (sectionList.scoreKeyword === 'Weight') setScoreKeyword('무게');
      else if (sectionList.scoreKeyword === 'Points') setScoreKeyword('포인트');
      else if (sectionList.scoreKeyword === 'Peak Watts') setScoreKeyword('피크와트');
      else if (sectionList.scoreKeyword === 'Max Speed') setScoreKeyword('최고 속도');

      if (sectionList.scorePriority === 'heigher') setScorePriority('높을 수록');
      else if (sectionList.scorePriority === 'lower') setScorePriority('낮을 수록');
    }

    setTitle(newTitle);
  }, [sectionList])

  useEffect(() => {

    if (sectionRecordData === undefined) return;

    let requestData = {
      sectionRecordId: sectionRecordData.sectionRecordId
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataOfExercise('comment/', 'POST', requestData);
        // console.log(responseData.data);
        if (responseData.status === 200) {
          setComments(responseData.data);
          setCommentValue('');
          scrollIntoBottom();
        }
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }, [sectionRecordData])


  // 동작 영상 모달 열기 클릭시 //
  function onClickMovementVideoViewModal(division, index) {
    console.log(division);
    if (division === 'buyin') setVideoLink(sectionList.buyinExerciseList[index].movement.videoLinkId);
    else if (division === 'main') setVideoLink(sectionList.mainExerciseList[index].movement.videoLinkId);
    else if (division === 'main.emom') setVideoLink(sectionList.emomExerciseList[index].movement.videoLinkId);
    else if (division === 'buyout') setVideoLink(sectionList.buyoutExerciseList[index].movement.videoLinkId);

    setIsClickedVideoView(true);
  }

  // 댓글 등록 버튼 클릭시 //
  function onClickCommentSaveButton() {

    if (commentValue === '') {
      alert('댓글 내용을 입력해주세요!');
      return;
    }

    let requestData = {
      commentId: undefined,
      storeId: undefined,
      userId: 'None',
      sectionRecordId: sectionRecordData.sectionRecordId,
      text: commentValue
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataOfExercise('comment/save_zone', 'POST', requestData);

        if (responseData.status === 200) {
          setComments(responseData.data);
          setCommentValue('');
          scrollIntoBottom();
        }
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }

  // EMOM 시간 표시 //
  function viewEMOMTimeTitle(index) {
    let time = sectionList.exerciseTime;
    let nowTime = time * index;
    let nextTime = time * (index + 1);

    if (time === 0) return '0 Min - 0 Min';
    else if (index === 0) {
      nextTime = time;

      if (nextTime % 60 === 0) return '0 Min - ' + (nextTime / 60) + ' Min';
      else return '0 Min - ' + parseInt(nextTime / 60) + ' Min ' + (nextTime % 60) + ' Sec';
    }
    // 현재 시작시간이 분단위로 나눠떨어질 경우 //
    else if (nowTime % 60 === 0) {
      // 끝나는 시간이 분단위로 나눠떨어질 경우 //
      if (nextTime % 60 === 0) return (nowTime / 60) + ' Min ' + ' - ' + (nextTime / 60) + ' Min';
      else return (nowTime / 60) + ' Min ' + ' - ' + parseInt(nextTime / 60) + ' Min ' + (nextTime % 60) + ' Sec';
    }
    else {
      // 끝나는 시간이 분단위로 나눠떨어질 경우 //
      if (nextTime % 60 === 0) return parseInt(nowTime / 60) + ' Min ' + (nowTime % 60) + ' Sec ' + ' - ' + (nextTime / 60) + ' Min';
      else return parseInt(nowTime / 60) + ' Min ' + (nowTime % 60) + ' Sec ' + ' - ' + parseInt(nextTime / 60) + ' Min ' + (nextTime % 60) + ' Sec';
    }
  }

  // EMOM 대기 안내문 표시 //
  function viewEMOMWaitMent(index) {
    let time = sectionList.exerciseTime;
    let nextTime = time * (index + 1);

    if (time === 0) return '다음 0분이 될때까지 기다리세요';
    // 끝나는 시간이 분단위로 나눠떨어질 경우 //
    else if (nextTime % 60 === 0) return '다음 ' + (nextTime / 60) + ' 분이 될때까지 기다리세요';
    else return '다음 ' + parseInt(nextTime / 60) + ' 분 ' + (nextTime % 60) + ' 초가 될때까지 기다리세요';
  }


  return (
    <TopLevelWrapper>
      {isClickedTagExplanation && <TagExplanationModal close={() => setIsClickedTagExplanation(!isClickedTagExplanation)} />}
      {isClickedVideoView && <VideoViewModal data={videoLink} close={() => setIsClickedVideoView(!isClickedVideoView)} />}
      <DivisionLine />
      <SectionDivisionWrapper>
        <SectionNumberBox>{String.fromCharCode(index + 65)}</SectionNumberBox>
        <SectionTitleBox>
          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>{title}</Text>
        </SectionTitleBox>
      </SectionDivisionWrapper>
      {
        // Time Limit Setting //
        (sectionList.tag === 'ForTime-Rounds' || sectionList.tag === 'ForTime-Different Reps' || sectionList.tag === 'SINGLE') &&
        <TimeLimitWrapper>
          <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>시간 제한</Text>
          <TimeComponentWrapper>
            <CheckBox isClicked={sectionList.isUseTimeLimit}>
              <Check src={CheckImage} />
            </CheckBox>
            <Text fontFamily='Poppins-Medium' fontSize={14} color='#828282'>제한 없음</Text>
            <TimeDataBoxWrapper>
              <TimeDataBox isClicked={sectionList.isUseTimeLimit}>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{parseInt(sectionList.timeLimit / 60) > 0 ? parseInt(sectionList.timeLimit / 60) : 0}</Text>
                <Text fontFamily='Poppins-Medium' fontSize={14} color='#BDBDBD'>분</Text>
              </TimeDataBox>
              <TimeDataBox isClicked={sectionList.isUseTimeLimit}>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{parseInt(sectionList.timeLimit % 60) > 0 ? parseInt(sectionList.timeLimit % 60) : 0}</Text>
                <Text fontFamily='Poppins-Medium' fontSize={14} color='#BDBDBD'>초</Text>
              </TimeDataBox>
            </TimeDataBoxWrapper>
          </TimeComponentWrapper>
          {sectionList.tag !== 'SINGLE' && <DivisionLine />}
        </TimeLimitWrapper>
        // Time Limit Setting //
      }
      {
        // Buy in Setting //
        (sectionList.tag === 'ForTime-Rounds' || sectionList.tag === 'ForTime-Different Reps' || sectionList.tag === 'AMRAP') &&
        <BuyWrapper isAMRAP={sectionList.tag === 'AMRAP'}>
          <BuyCheckBoxWrapper>
            <CheckBox isClicked={sectionList.isUseBuyin}>
              <Check src={CheckImage} />
            </CheckBox>
            <Text fontFamily='Poppins-Regular' fontSize={14} color='#4F4F4F'>Buy in</Text>
          </BuyCheckBoxWrapper>
          {
            sectionRecordData !== undefined &&
            sectionRecordData.buyinExerciseList.map((data, idx) => (
              <NormalMovementBox
                key={idx}
                data={data}
                idx={idx}
                tag='None'
                reps={dataReps}
                division='buyin'
                scaleOptionList={sectionRecordData.buyinScaleExerciseList}
                onClickMovementVideo={onClickMovementVideoViewModal} />
            ))
          }
        </BuyWrapper>
        // Buy In Setting //
      }
      {/* Main */}
      <MainComponentWrapper>
        <DivisionLine />
        <MainIconWrapper>
          <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>메인 운동</Text>
          <Icon src={InfoIcon} onClick={() => setIsClickedTagExplanation(true)} />
        </MainIconWrapper>
        <MainSelectBoxWrapper>
          <TagBox>
            <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F'>{sectionList.tag.split('-')[0]}</Text>
          </TagBox>
          <TagBox isEmpty={sectionList.tag.split('-')[1] === undefined}>
            <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F'>{sectionList.tag.split('-')[1] === undefined ? '-' : sectionList.tag.split('-')[1]}</Text>
          </TagBox>
        </MainSelectBoxWrapper>
        {
          // Section Tag가 For Time - Rounds일 경우 //
          sectionList.tag === 'ForTime-Rounds' &&
          <MainExerciseTagWrapper>
            <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>라운드</Text>
            <RoundBox>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{sectionList.round}</Text>
              <Text fontFamily='Poppins-Medium' fontSize={14} color='#BDBDBD'>R</Text>
            </RoundBox>
          </MainExerciseTagWrapper>
        }
        {
          // Section Tag가 For Time - Different Reps일 경우 //
          sectionList.tag === 'ForTime-Different Reps' &&
          <MainExerciseTagWrapper>
            <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>랩 수</Text>
            <RepsInputWrapper>
              {
                sectionList.reps.map((data, idx) => (
                  <RepsWrapper key={idx}>
                    <RepsBox>
                      <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data}</Text>
                    </RepsBox>
                    {sectionList.reps.length - 1 !== idx && <NextRepsIcon src={NextRepsImage} />}
                  </RepsWrapper>
                ))
              }
            </RepsInputWrapper>
          </MainExerciseTagWrapper>
        }
        {
          // Section Tag가 AMRAP일 경우 //
          sectionList.tag === 'AMRAP' &&
          <MainExerciseTagWrapper>
            <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>운동 시간</Text>
            <TimeWrapper>
              <TagBox>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{parseInt(sectionList.exerciseTime / 60)}</Text>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>분</Text>
              </TagBox>
              <TagBox>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{parseInt(sectionList.exerciseTime % 60)}</Text>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>초</Text>
              </TagBox>
            </TimeWrapper>
          </MainExerciseTagWrapper>
        }
        {
          // Section Tag가 EMOM일 경우 //
          sectionList.tag === 'EMOM' &&
          <MainExerciseTagWrapper>
            <TimeTextWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>라운드 / 운동 시간</Text>
              <TextInfoWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={12} color='#595959' style={{ marginRight: '4px' }}>총</Text>
                {
                  parseInt(sectionList.round * sectionList.exerciseTime / 60) > 0 &&
                  <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#6DD49E'>{parseInt(sectionList.round * sectionList.exerciseTime / 60) + '분'}</Text>
                }
                {
                  parseInt(sectionList.round * sectionList.exerciseTime % 60) > 0 &&
                  <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#6DD49E' style={{ marginLeft: '4px' }}>{parseInt(sectionList.round * sectionList.exerciseTime % 60) + '초'}</Text>
                }
                <Text fontFamily='NotoSansKR-Medium' fontSize={12} color='#595959'>동안 진행됩니다</Text>
              </TextInfoWrapper>
            </TimeTextWrapper>
            <RoundBox>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{sectionList.round}</Text>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>R</Text>
            </RoundBox>
            <TimeWrapper>
              <TagBox>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{parseInt(sectionList.exerciseTime / 60)}</Text>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>분</Text>
              </TagBox>
              <TagBox>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{parseInt(sectionList.exerciseTime % 60)}</Text>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>초</Text>
              </TagBox>
            </TimeWrapper>
          </MainExerciseTagWrapper>
        }
        {
          // Section Tag가 TABATA일 경우 //
          sectionList.tag === 'TABATA' &&
          <MainExerciseTagWrapper>
            <TimeTextWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>동작 별 라운드</Text>
              <TextInfoWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={12} color='#595959' style={{ marginRight: '4px' }}>총</Text>
                {
                  parseInt(((sectionList.round * sectionList.exerciseTime) + (sectionList.round > 0 ? (sectionList.round - 1) * sectionList.restTime : 0)) / 60) > 0 &&
                  <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#6DD49E'>{parseInt(((sectionList.round * sectionList.exerciseTime) + (sectionList.round > 0 ? (sectionList.round - 1) * sectionList.restTime : 0)) / 60) + '분'}</Text>
                }
                {
                  parseInt(((sectionList.round * sectionList.exerciseTime) + (sectionList.round > 0 ? (sectionList.round - 1) : 0 * sectionList.restTime)) % 60) > 0 &&
                  <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#6DD49E' style={{ marginLeft: '4px' }}>{parseInt(((sectionList.round * sectionList.exerciseTime) + ((sectionList.round > 0 ? (sectionList.round - 1) : 0) * sectionList.restTime)) % 60) + '초'}</Text>
                }
                <Text fontFamily='NotoSansKR-Medium' fontSize={12} color='#595959'>동안 진행됩니다</Text>
              </TextInfoWrapper>
            </TimeTextWrapper>
            <RoundBox>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{sectionList.round}</Text>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>R</Text>
            </RoundBox>
            <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>동작 별 운동 시간</Text>
            <TimeWrapper>
              <TagBox>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{parseInt(sectionList.exerciseTime / 60)}</Text>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>분</Text>
              </TagBox>
              <TagBox>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{parseInt(sectionList.exerciseTime % 60)}</Text>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>초</Text>
              </TagBox>
            </TimeWrapper>
            <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>동작 별 쉬는 시간</Text>
            <TimeWrapper>
              <TagBox>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{parseInt(sectionList.restTime / 60)}</Text>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>분</Text>
              </TagBox>
              <TagBox>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{parseInt(sectionList.restTime % 60)}</Text>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>초</Text>
              </TagBox>
            </TimeWrapper>
          </MainExerciseTagWrapper>
        }
        {
          sectionList.tag === 'Custom' &&
          <MainExerciseTagWrapper>
            <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>점수 기준</Text>
            <MainSelectBoxWrapper>
              <TagBox>
                <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F'>{scoreKeyword}</Text>
              </TagBox>
              <TagBox isEmpty={scoreKeyword === '점수 없음' || scoreKeyword === '피크와트' || scoreKeyword === '최고 속도'}>
                <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F'>{(scoreKeyword === '점수 없음' || scoreKeyword === '피크와트' || scoreKeyword === '최고 속도') ? '-' : scorePriority}</Text>
              </TagBox>
            </MainSelectBoxWrapper>
            <DivisionLine />
            <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>운동 설명</Text>
            <DescriptionBox>
              <DescriptionText>{sectionList.description}</DescriptionText>
            </DescriptionBox>
          </MainExerciseTagWrapper>
        }
        {/* {
          sectionList.tag === 'EMOM' &&
          <MainExerciseDesignWrapper>
            <DivisionLine />
            <MainExerciseTextWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>메인 운동 디자인</Text>
            </MainExerciseTextWrapper>
            {
              sectionRecordData !== undefined &&
              sectionRecordData.emomExerciseList.map((data, idx) => (
                <EmomSectionWrapper key={idx}>
                  {
                    // 모래시계 모형과 기다리세요 안내문 띄우기 //
                    (idx !== 0 && idx % (sectionRecordData.mainExerciseList.length) === 0) &&
                    <EmomWaitMentWrapper>
                      <TrapezoidShapeWrapper>
                        <TopTrapezoidShape />
                        <BottomTrapezoidShape />
                      </TrapezoidShapeWrapper>
                      <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{viewEMOMWaitMent(idx)}</Text>
                    </EmomWaitMentWrapper>
                  }
                  {
                    // 시간 안내표 띄우기 //
                    (idx % sectionRecordData.mainExerciseList.length) === 0 &&
                    <EmomTimeTitleWrapper>
                      <EmomTimeTitle>
                        <Text fontFamily='Poppins-Regular' fontSize={14} color='#FFFFFF'>{viewEMOMTimeTitle(idx)}</Text>
                      </EmomTimeTitle>
                    </EmomTimeTitleWrapper>
                  }
                  <NormalMovementBox
                    key={idx}
                    data={data}
                    idx={idx}
                    reps={dataReps}
                    tag={sectionList.tag}
                    division='main.emom'
                    scaleOptionList={sectionRecordData.emomScaleExerciseList}
                    onClickMovementVideo={onClickMovementVideoViewModal} />
                </EmomSectionWrapper>
              ))
            }
          </MainExerciseDesignWrapper>
        } */}
        {
          sectionList.tag === 'EMOM' &&
          <MainExerciseDesignWrapper>
            <DivisionLine />
            <MainExerciseTextWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>메인 운동 디자인</Text>
            </MainExerciseTextWrapper>
            {
              sectionRecordData !== undefined &&
              sectionRecordData.roundList.map((roundData, roundIndex) => (
                <EmomSectionWrapper key={`emom-round-` + roundIndex}>

                  <EmomTimeTitleWrapper>
                    <EmomTimeTitle>
                      <Text fontFamily='Poppins-Regular' fontSize={14} color='#FFFFFF'>{viewEMOMTimeTitle(roundIndex)}</Text>
                    </EmomTimeTitle>
                  </EmomTimeTitleWrapper>
                  {
                    sectionRecordData.emomExerciseList.map((data, idx) => (
                      <EmomSectionWrapper key={idx}>
                        <NormalMovementBox
                          key={idx}
                          data={data}
                          idx={idx}
                          reps={dataReps}
                          tag={sectionList.tag}
                          division='main.emom'
                          scaleOptionList={sectionRecordData.emomScaleExerciseList}
                          onClickMovementVideo={onClickMovementVideoViewModal} />
                      </EmomSectionWrapper>
                    ))
                  }
                  <EmomWaitMentWrapper>
                    <TrapezoidShapeWrapper>
                      <TopTrapezoidShape />
                      <BottomTrapezoidShape />
                    </TrapezoidShapeWrapper>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{viewEMOMWaitMent(roundIndex)}</Text>
                  </EmomWaitMentWrapper>
                </EmomSectionWrapper>
              ))
            }
          </MainExerciseDesignWrapper>
        }
        {
          (sectionList.tag !== 'Custom' && sectionList.tag !== 'EMOM') &&
          <MainExerciseDesignWrapper>
            <DivisionLine />
            <MainExerciseTextWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>메인 운동 디자인</Text>
            </MainExerciseTextWrapper>
            {
              sectionRecordData !== undefined &&
              sectionRecordData.mainExerciseList.map((data, idx) => (
                <NormalMovementBox
                  key={idx}
                  data={data}
                  idx={idx}
                  tag={sectionList.tag}
                  reps={dataReps}
                  division='main'
                  scaleOptionList={sectionRecordData.mainScaleExerciseList}
                  onClickMovementVideo={onClickMovementVideoViewModal} />
              ))
            }
            <DivisionLine />
          </MainExerciseDesignWrapper>
        }
      </MainComponentWrapper>
      {/* Main */}
      {
        // Buy out Setting //
        (sectionList.tag === 'ForTime-Rounds' || sectionList.tag === 'ForTime-Different Reps') &&
        <BuyWrapper>
          <BuyCheckBoxWrapper>
            <CheckBox isClicked={sectionList.isUseBuyout}>
              <Check src={CheckImage} />
            </CheckBox>
            <Text fontFamily='Poppins-Regular' fontSize={14} color='#4F4F4F'>Buy out</Text>
          </BuyCheckBoxWrapper>
          {
            sectionRecordData !== undefined &&
            sectionRecordData.buyoutExerciseList.map((data, idx) => (
              <NormalMovementBox
                key={idx}
                data={data}
                idx={idx}
                tag='None'
                reps={dataReps}
                division='buyout'
                scaleOptionList={sectionRecordData.buyoutScaleExerciseList}
                onClickMovementVideo={onClickMovementVideoViewModal} />
            ))
          }
        </BuyWrapper>
        // Buy out Setting //
      }
      {
        sectionRecordData !== undefined &&
        <ExericseReview sectionList={sectionList} sectionRecordData={sectionRecordData} />
      }
      <DivisionLine />
      <CommentTitleWrapper>
        <CommentInfoWrapper>
          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#232323'>댓글</Text>
          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#777777'>{comments.length}</Text>
        </CommentInfoWrapper>
        <HoverText onClick={() => setIsClickedViewComment(!isClickedViewComment)} fontFamily='NotoSansKR-Medium' fontSize={14} color='#FF8B48'>{isClickedViewComment ? `접기` : `펴기`}</HoverText>
      </CommentTitleWrapper>
      {
        isClickedViewComment &&
        comments.map((data, index) => (
          <RankingCommentBox
            key={index}
            index={index}
            data={data}
            setComments={setComments}
            isClickedViewCommentIndex={isClickedViewCommentIndex}
            setIsClickedViewCommentIndex={setIsClickedViewCommentIndex} />
        ))
      }
      <DivisionLine />
      <CommentWriteBox>
        <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#232323'>댓글 작성</Text>
        <CommentInputWrapper>
          <RankingCommentTextArea value={commentValue} setValue={setCommentValue} placeholder='내용을 입력해주세요' />
          <CommentSaveButton onClick={onClickCommentSaveButton}>
            <HoverText fontFamily='NotoSansKR-Regular' fontSize={14} color='#FFFFFF'>등록</HoverText>
          </CommentSaveButton>
        </CommentInputWrapper>
      </CommentWriteBox>
    </TopLevelWrapper>
  )
}

export default RankNormalSectionBox;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 97.71%;
`;

const SectionDivisionWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  // margin-top: 24px;

  width: 100%;
`;

const SectionNumberBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 32px;
  height: 32px;

  border-radius: 6px;
  background-color: #6DD49E;

  font-family: Poppins-Bold;
  font-size: 14px;

  color: #FFFFFF;
`;

const SectionTitleBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: calc(92.21% - 24px);
  height: 32px;

  padding: 0px 12px 0px 12px;

  font-family: NotoSansKR-Medium;
  font-size: 14px;
  
  color: #333333;
  
  border-radius: 6px;
  background-color: #F9F9F9;
`;

const HoverText = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  padding: 0 0 0 0;
  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  padding: 0 0 0 0;
  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const DivisionLine = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  // height: 1px;

  margin-top: 24px;
  margin-bottom: 24px;

  border: 1px solid #ECECEC;
`;

const BuyWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  margin-top: ${props => props.isAMRAP ? `24px` : `0px`};
`;

const BuyCheckBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 100%;

  // margin-top: 24px;
  gap: 6px;
`;

const CheckBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;

  border-radius: 6px;
  background-color: ${props => props.isClicked ? `#6DD49E` : `#E0E0E0`};
`;

const Check = styled.img`
  width: 10px;
  height: 9px;
`;

const MainComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
`;

const MainSelectBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;

  margin-top: 9px;
`;

const MainIconWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
`;

const TagBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: calc(47.66% - 34px);
  height: 34px;

  margin: 0 0 0 0;
  padding: 0 16px 0 16px;

  border: 1px solid #E0E0E0;
  border-radius: 15px;
  background-color: ${props => props.isEmpty ? `#ECECEC` : `#FFFFFF`};
`;

const RoundBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: calc(100% - 34px);
  height: 34px;

  border: 1px solid #E0E0E0;
  border-radius: 15px;
  background-color: #FFFFFF;

  margin: 0 0 0 0;
  padding: 0 16px 0 16px;
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;

  :hover {
    cursor: pointer;
  }
`;

const MainExerciseTagWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 8px;
  margin-top: 24px;

  width: 100%;
`;

const MainExerciseDesignWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
`;

const MainExerciseTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
`;

const RepsInputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  flex-wrap: wrap;

  width: 100%;
`;

const RepsWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  // width: 25.29%;
  width: 25%;
  // background-color: red;
  gap: 4px;
  margin-top: 8px;
`;

const RepsBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: calc(84.61% - 34px);
  height: 34px;

  border: 1px solid #E0E0E0;
  border-radius: 15px;
  background-color: #FFFFFF;

  margin: 0 0 0 0;
  padding: 0 16px 0 16px;
`;

const NextRepsIcon = styled.img`
  width: 16px;
  height: 16px;
`;

const TimeWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const TimeTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const TextInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const TimeLimitWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: 100%;
  
  gap: 6px;

  margin-top: 24px;
`;

const TimeComponentWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 7px;

  width: 100%;

  margin-top: ${props => props.topMargin}px;
`;

const TimeDataBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 82.29%;
  height: 34px;

  margin: 0 0 0 0;
`;

const TimeDataBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: calc(47.11% - 34px);
  height: 34px;

  padding: 0 16px 0 16px;
  margin: 0 0 0 0;

  border: 1px solid #E0E0E0;
  border-radius: 15px;
  background-color: ${props => props.isClicked ? `#ECECEC` : `#FFFFFF`};
`;

const CommentTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 8px;
`;

const CommentInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 4px;
`;

const CommentWriteBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  gap: 8px;
`;

const CommentInputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const CommentSaveButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 66px;
  height: 38px;
  
  // border: 1px solid #DFDFDF;
  border: 1px solid #FF8B48;
  border-radius: 15px;
  background-color: #FF8B48;

  :hover {
    cursor: pointer;
  }
`;

const DescriptionBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: calc(100% - 34px);
  height: auto;
  min-height: 497px;

  padding: 8px 16px 8px 16px;

  border: 1px solid #E0E0E0;
  border-radius: 15px;
  background-color: #FFFFFF;
`;

const DescriptionText = styled.pre`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;

  margin: 0 0 0 0;
`;

const EmomSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const EmomTimeTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 26px;

  width: 100%;
`;

const EmomTimeTitle = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  padding: 0px 8px 0px 8px;
  
  height: 18px;

  border-radius: 2px;
  background-color: #6DD49E;
`;

const EmomWaitMentWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 20px;

  width: 100%;

  gap: 4px;
`;

const TrapezoidShapeWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const TopTrapezoidShape = styled.div`
  border-top: 7px solid #66D49E;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;

  height: 0px;

  width: 3px;
`;

const BottomTrapezoidShape = styled.div`
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-bottom: 7px solid #66D49E;

  height: 0px;

  width: 3px;
`;