import { useState, useEffect } from 'react';

// Hook
function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        // width: window.innerWidth,
        // height: window.innerHeight,
        width: window.visualViewport.width,
        height: window.visualViewport.height
      });
    }
    
    window.addEventListener("resize", handleResize);
    
    handleResize();
    
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}

export default useWindowSize;