import { React, useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';

import FetchModule from '../../Share/Network/FetchModule';
import ExerciseBoxLegacy from './ExerciseBoxLegacy';
import ExerciseBox from './ExerciseBox';
import ExerciseNoticeView from './ExerciseNotice';

const BoardView = () => {
  const history = useHistory();

  const [data, setData] = useState({
    workoutId: 0,
    scheduleId: 0,
    storeId: parseInt(window.localStorage.getItem('StoreId')),
    classDate: moment(),
    title: '작성이 필요합니다.',
    sectionList: []
  });
  const [date, setDate] = useState(moment());
  const [dateList, setDateList] = useState([]);
  const [clickDate, setClickDate] = useState(date.clone().format('YYYY.MM.DD'));
  const [workoutId, setWorkoutId] = useState(0);
  const [lessonId, setLessonId] = useState(-1);
  const [scheduleId, setScheduleId] = useState(-1);
  const [classTime, setClassTime] = useState(undefined);
  const [rendering, setRendering] = useState(false);

  const [useData, setUseData] = useState([{
    workoutId: 0,
    scheduleId: 0,
    storeId: parseInt(window.localStorage.getItem('StoreId')),
    classDate: moment(),
    title: '작성이 필요합니다.',
    sectionList: []
  }]);

  const [notepad, setNotepad] = useState({
    id: 0,
    workoutId: 0,
    contents: '',
  });

  const ExerciseRef = useRef();

  const firstWeek = date.clone().startOf('month').week();
  const lastWeek = date.clone().endOf('month').week() === 1 ? 53 : date.clone().endOf('month').week();

  // useEffect(() => {
  //   console.log(date);
  // }, [date])
  // Calendar Date Setting //
  useEffect(() => {
    let newDateList = [];

    for (let week = firstWeek; week <= lastWeek; week++) {
      for (let i = 0; i < 7; i++) {
        let days = date.clone().startOf('year').week(week).startOf('week').add(i, 'day');
        
        if (days.format('MM') === date.format('MM')) newDateList.push(days);
      } 
    }
    setClickDate(date.clone().format('YYYY.MM.DD'));
    setDateList(newDateList);
  }, [date, firstWeek, lastWeek]) 

    
  // Workout API Call //
  useEffect(() => {
    if (workoutId === 0) return;

    let data = {
      workoutId: workoutId
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        let responseData = await fetchModule.postDataOfExerciseVersion2('workout/board_workout/', 'POST', data);
        // console.log(responseData);
        if (responseData.status === 200) {
         
          let workoutData = responseData.data;

          // 운동 데이터 정리 //
          workoutData.sectionList.sort(function (a, b) {
            return a.sectionIndex < b.sectionIndex ? -1 : a.sectionIndex > b.sectionIndex ? 1 : 0;
          })

          for (let j = 0; j < workoutData.sectionList.length; j++) {
            workoutData.sectionList[j].buyinExerciseList = [];
            workoutData.sectionList[j].buyinScaleExerciseList = [];
            workoutData.sectionList[j].mainExerciseList = [];
            workoutData.sectionList[j].mainScaleExerciseList = [];
            workoutData.sectionList[j].emomExerciseList = [];
            workoutData.sectionList[j].emomScaleExerciseList = [];
            workoutData.sectionList[j].buyoutExerciseList = [];
            workoutData.sectionList[j].buyoutScaleExerciseList = [];
            workoutData.sectionList[j].reps = workoutData.sectionList[j].reps.split('-');

            for (let h = 0; h < workoutData.sectionList[j].exerciseList.length; h++) {
              workoutData.sectionList[j].exerciseList[h].movementId = workoutData.sectionList[j].exerciseList[h].movement.movementId;
              workoutData.sectionList[j].exerciseList[h].movementName = workoutData.sectionList[j].exerciseList[h].movement.movementName;
              workoutData.sectionList[j].exerciseList[h].videoLinkId = workoutData.sectionList[j].exerciseList[h].movement.videoLinkId;

              // 무게 구분 데이터 사용할 경우 //
              if (workoutData.sectionList[j].exerciseList[h].divisionWeight !== ''
                && workoutData.sectionList[j].exerciseList[h].divisionWeight !== 'None') {
                workoutData.sectionList[j].exerciseList[h].weight = workoutData.sectionList[j].exerciseList[h].divisionWeight;
              }
              // 거리 구분 데이터 사용할 경우 //
              if (workoutData.sectionList[j].exerciseList[h].divisionDistance !== ''
                && workoutData.sectionList[j].exerciseList[h].divisionDistance !== 'None') {
                workoutData.sectionList[j].exerciseList[h].distance = workoutData.sectionList[j].exerciseList[h].divisionDistance;
              }
              // 높이 구분 데이터 사용할 경우 //
              if (workoutData.sectionList[j].exerciseList[h].divisionHeight !== ''
                && workoutData.sectionList[j].exerciseList[h].divisionHeight !== 'None') {
                workoutData.sectionList[j].exerciseList[h].height = workoutData.sectionList[j].exerciseList[h].divisionHeight;
              }
              // 칼로리 구분 데이터 사용할 경우 //
              if (workoutData.sectionList[j].exerciseList[h].divisionCalories !== ''
                && workoutData.sectionList[j].exerciseList[h].divisionCalories !== 'None') {
                workoutData.sectionList[j].exerciseList[h].calories = workoutData.sectionList[j].exerciseList[h].divisionCalories;
              }
              // 템포 구분 데이터 사용할 경우 //
              if (workoutData.sectionList[j].exerciseList[h].divisionTempo !== ''
                && workoutData.sectionList[j].exerciseList[h].divisionTempo !== 'None') {
                workoutData.sectionList[j].exerciseList[h].tempo = workoutData.sectionList[j].exerciseList[h].divisionTempo;
              }
              // Sets 구분 데이터 사용할 경우 //
              if (workoutData.sectionList[j].exerciseList[h].divisionSets !== ''
                && workoutData.sectionList[j].exerciseList[h].divisionSets !== 'None') {
                workoutData.sectionList[j].exerciseList[h].sets = workoutData.sectionList[j].exerciseList[h].divisionSets;
              }

              if (workoutData.sectionList[j].exerciseList[h].division === 'Buyin') {
                workoutData.sectionList[j].buyinExerciseList.push(workoutData.sectionList[j].exerciseList[h]);
              }
              else if (workoutData.sectionList[j].exerciseList[h].division === 'Buyin.Scale') {
                workoutData.sectionList[j].buyinScaleExerciseList.push(workoutData.sectionList[j].exerciseList[h]);
              }
              else if (workoutData.sectionList[j].exerciseList[h].division === 'Main') {
                workoutData.sectionList[j].mainExerciseList.push(workoutData.sectionList[j].exerciseList[h]);
              }
              else if (workoutData.sectionList[j].exerciseList[h].division === 'Main.Scale') {
                workoutData.sectionList[j].mainScaleExerciseList.push(workoutData.sectionList[j].exerciseList[h]);
              }
              else if (workoutData.sectionList[j].exerciseList[h].division === 'Main.Emom') {
                workoutData.sectionList[j].emomExerciseList.push(workoutData.sectionList[j].exerciseList[h]);
              }
              else if (workoutData.sectionList[j].exerciseList[h].division === 'Main.Emom.Scale') {
                workoutData.sectionList[j].emomScaleExerciseList.push(workoutData.sectionList[j].exerciseList[h]);
              }
              else if (workoutData.sectionList[j].exerciseList[h].division === 'Buyout') {
                workoutData.sectionList[j].buyoutExerciseList.push(workoutData.sectionList[j].exerciseList[h]);
              }
              else if (workoutData.sectionList[j].buyoutScaleExerciseList[h].division === 'Buyout.Scale') {
                workoutData.sectionList[j].buyoutScaleExerciseList.push(workoutData.sectionList[j].exerciseList[h]);
              }
            }

            // exerciseIndex.asc 진행 //
            workoutData.sectionList[j].buyinExerciseList.sort(function (a, b) {
              return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
            })
            // exerciseIndex.asc 진행 //
            workoutData.sectionList[j].buyinScaleExerciseList.sort(function (a, b) {
              return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
            })
            // exerciseIndex.asc 진행 //
            workoutData.sectionList[j].mainExerciseList.sort(function (a, b) {
              return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
            })
            // exerciseIndex.asc 진행 //
            workoutData.sectionList[j].mainScaleExerciseList.sort(function (a, b) {
              return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
            })
            // exerciseIndex.asc 진행 //
            workoutData.sectionList[j].emomExerciseList.sort(function (a, b) {
              return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
            })
            // exerciseIndex.asc 진행 //
            workoutData.sectionList[j].emomScaleExerciseList.sort(function (a, b) {
              return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
            })
            // exerciseIndex.asc 진행 //
            workoutData.sectionList[j].buyoutExerciseList.sort(function (a, b) {
              return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
            })
            // exerciseIndex.asc 진행 //
            workoutData.sectionList[j].buyoutScaleExerciseList.sort(function (a, b) {
              return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
            })
          }
          // console.log(workoutData);
          setData(workoutData);
        }
        // else if (responseData.status === 204) setData([]);
        // else if (responseData.status === 401) history.push('/');
      }
    )();
  }, [workoutId])

  // 운동 메모 불러오기 //
  useEffect(() => {
    if (workoutId === 0) return;

    var requestData = {
      workoutId: workoutId
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        var responseData = await fetchModule.postDataOfExercise('notepad/', 'POST', requestData);
        
        if (responseData.status === 200) {
          if (responseData.data[0] !== null) setNotepad(responseData.data[0]);
        }
        // else if (responseData.status === 401) history.push('/');
      }
    )();
  }, [workoutId])


  return (
    <TopLevelWrapper>
      <TitleWrapper>
        <Title>보드</Title>
        <ExplanationWrapper>
          <Explanation>기록사항을 편하게 적어두세요</Explanation>
          <Go onClick={() => history.push('/board')}>자세히 보기</Go>
        </ExplanationWrapper>
      </TitleWrapper>
      <InfoBox>
        <ExerciseBox
          ref={ExerciseRef} 
          clickDate={clickDate} 
          useData={data}
          date={date}
          classTime={classTime}
          workoutId={workoutId}
          setWorkoutId={setWorkoutId}
          setLessonId={setLessonId} 
          setScheduleId={setScheduleId} 
          setClassTime={setClassTime} 
          rendering={() => setRendering(!rendering)}/>
        <ExerciseNoticeView notepad={notepad}/>
      </InfoBox>
    </TopLevelWrapper>
  )
};

export default BoardView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 1054px;
  // height: 385px;

  border-radius: 30px;
  background-color: #FFFFFF;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 28px;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: 0 0 0 0;
`;

const ExplanationWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 791px;
  margin-top: 4px;
  margin-bottom: 24px;
`;

const Explanation = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;
  
  color: #777777;
  
  margin: 0 0 0 0;
`;

const Go = styled.p`
  font-family: NotoSansKr-Medium;
  font-size: 12px;

  color: #FF8B48;
  margin: 0 0 0 0;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    color: #FF7322;
  }
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 24px;

  border-top: 1px solid #ECECEC;

  padding-top: 24px;

  width: 998px;
`;

