import { React } from 'react';
import styled from 'styled-components';

import LockerView from '../Model/Individual/LockerModel/LockerView.js';

function LockerPage() {

  return (
    <Container>
      <LockerView/>
    </Container>
  )
}

export default LockerPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
`;