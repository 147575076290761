import { React, useState, useRef, useImperativeHandle, forwardRef } from 'react';
import styled from 'styled-components';

// import SettingTimeSelectBox from './SettingTimeSelectBox';

import DownImage from '../../image/down.png';
import UpImage from '../../image/up.png';

const ReserveOpenCountInput = forwardRef((props, ref) => {
  const { text } = props;
  const [dateVal, setDateVal] = useState(0); 
  const [units, setUnits] = useState('일');
	const [changed, setChanged] = useState(false);
  const [time, setTime] = useState('');
  const [timeBorder, setTimeBorder] = useState('')

  const InputRef = useRef();
  const TimeInputRef = useRef();

	useImperativeHandle(ref, () => ({
		setValue: (val) => {
      setDateVal(val);
      // SelectRef.current.reSet();
		},
    setUnit: (val) => {
      setUnits(val);
      // if (val === 'min') SelectRef.current.setValue({ value: '분' });
      // else if (val === 'time') SelectRef.current.setValue({ value: '시간' });
      // else SelectRef.current.setValue({ value: '일' });
    },
		getValue: () => {
      // let units = SelectRef.current.getValue();
      // 일, 시간, 분을 서버에 올리기 위해 분으로 환산하기 위한 곱수
      let multiplier = 1; 
      
      if (units === '일') multiplier = 60 * 24;
      else if (units === '시간') multiplier = 60;
      else if (units === '분') multiplier = 1;

			return (dateVal * multiplier);
		},
    getUnit: () => {
      // return SelectRef.current.getValue();
      return units;
    },
    setTime: (val) => {
      if (val === undefined || val.length < 5) setTime('00:00');
      else setTime(val);
    },
    getTime: () => {
      return time === '' ? '00:00' : time;
    }
	}))

	function handleChange(e) {
    let regex = /[^0-9]/g;				// 숫자가 아닌 문자열을 매칭하는 정규식
    let result = e.target.value.replace(regex, "");	// 원래 문자열에서 숫자가 아닌 모든 문자열을 빈 문자로 변경
    // console.log(result);	
		setDateVal(result === '' ? 0 : parseInt(result));
		setChanged(true);
	}

	function handleFocus(e) {

	}

  function upButtonClick() {
    setDateVal(dateVal + 1);
  }

  function downButtonClick(){
    if (dateVal > 0) setDateVal(dateVal - 1);
  }

  // 오픈시간 변경시 //
  function handleChangeTime(e) {
    if (e.target.value.length > 5) {
      return;
    }

    const regex = /^[0-9:\-\b]{0,11}$/;
		
		if (regex.test(e.target.value)) {
			if (e.target.value.length === 2) setTime(e.target.value.replace(/(\d{2})/, '$1:'));
			else if (e.target.value.length === 5) setTime(e.target.value.replace(/(\d{2}):(\d{2})/, '$1:$2'))
			else setTime(e.target.value);
		}
  }

  // 클릭시 데이터 지워주기 //
  function handleFocusTime() {
    setTime('');
    setTimeBorder('1px solid #FF8B48');
  }

  function onBlurTime() {
    setTimeBorder('1px solid #DFDFDF');
  }

	function handleKeyDown(e) {
		if (e.key === 'Backspace') {
			if (time.length === 3 || time.length === 6 || time.length === 9) {
				setTime(time.substring(0, time.length - 1));
			}
		}
	}

  return (
    <TopLevelWrapper>
      <TopWrapper>
        <LeftWrapper>
          <Input
            ref={InputRef}
            placeholder={0}
            value={dateVal}
            onChange={handleChange}
            onFocus={handleFocus} />
          <ButtonWrapper>
            <Button img={UpImage} onClick={upButtonClick} />
            <Button img={DownImage} onClick={downButtonClick} />
          </ButtonWrapper>
        </LeftWrapper>
        {/* <SettingTimeSelectBox ref={SelectRef}/> */}
        <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#CBCBCB'>일</Text>
        <TimeInput
          ref={TimeInputRef}
          placeholder='00:00'
          value={time}
          border={timeBorder}
          onKeyDown={handleKeyDown}
          onChange={handleChangeTime}
          onBlur={onBlurTime}
          onFocus={handleFocusTime}/>
      </TopWrapper>
      <Text style={{ marginTop: '6px' }} fontFamily='NotoSansKR-Regular' fontSize={12} color='#FF8B48'>{`수업 시작하기 "${dateVal}일"전 ${time === '' ? '00:00' : time}부터 예약 가능합니다`}</Text>
    </TopLevelWrapper>
  )
})

export default ReserveOpenCountInput;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
  
  margin-top: 8px;

  transition: all 0.3s ease-in-out;
`;

const TopWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 10px;
`;

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const Input = styled.input`
  display: flex;
  flex-direction: row;

  align-items: center;

  // width: 69px;
  width: 53px;
  height: 30px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background-color: #FFFFFF;

  padding: 0 8px 0 8px;
  // margin: 0 5px 0 0;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  // color: #CBCBCB;
  color: #000000;

  ::placeholder {
    color: #CBCBCB;
  }
`;

const TimeInput = styled.input`
  display: flex;
  flex-direction: row;

  flex-direction: center;
  align-items: center;

  // width: 77px;
  width: 39px;
  height: 30px;

  border: 1px solid #DFDFDF;
  // border: ${props => props.border};
  border-radius: 8px;
  background-color: #FFFFFF;

  padding: 0 19px 0 19px;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  // color: #CBCBCB;
  color: #000000;

  ::placeholder {
    color: #CBCBCB;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
  // margin-right: 8px;
`;

const Button = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 30px;
  height: 30px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background: url(${props => props.img}) no-repeat center;
  background-color: #ECECEC;

  :hover {
    cursor: pointer;
  }
`;

const Text = styled.p`
  font-fmaily: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};
  // font-family: NotoSansKR-Regular;
  // font-size: 12px;

  // color: #CBCBCB;

  // margin: 0 0 0 79px;
  margin: 0 0 0 0;
`;