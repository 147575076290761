import { React, useRef, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import UserProfile from '../../../Component/Normal/UserProfile';

export default function NewWaittingMemberInfo(props){
  const { data, index, isIgnore, isUsePaymentSystem, onClickedAgree, onClickedIgnore } = props;

  const history = useHistory();

  const [isLinkPayHover, setIsLinkPayHover] = useState(false);
  const [isLinkPay, setIsLinkPay] = useState(false);
  const [gender, setGender] = useState('');
  const [birth, setBirth] = useState('');
  const [memberShip, setMemberShip] = useState('-');

  const WrapperRef = useRef();
  const RegistrationRef = useRef();
  const ExitRef = useRef();

  // 데이터 정리 //
  useEffect(() => {
    let gender = '';

    if (data.birthDay[7] === '1') gender = '남성';
    else if (data.birthDay[7] === '2') gender = '여성';
    else if (data.birthDay[7] === '3') gender = '남성';
    else if (data.birthDay[7] === '4') gender = '여성';

    // if (data.memberShip === undefined) setMemberShip('-');
    setGender(gender);
    setIsLinkPay(data.merchantUid.split('-')[2] === '1' ? true : false);
  }, [data])

  // 회원 정보 클릭시 //
  function onClickWaitingMember(e) {
    if (ExitRef.current.contains(e.target) || RegistrationRef.current.contains(e.target) || WrapperRef.current.contains(e.target)) return;
    
    if (data.memberId === -1) {
      alert("아직 체육관에 등록되어있지 않습니다.\n회원권을 등록해주세요.");
      return;
    }

    history.push('valid/detail/' + data.memberId);
  }

  // 회원 전화번호 이쁘게 정리 //
  function calcUserPhone() {
    return data.phone.slice(0, 3) + '-' + data.phone.slice(3, 7) + '-' + data.phone.slice(7, 11);
  }

  // 등록버튼에 마우스 올라갈 경우 //
  function onMouseOverRegistButton() {
    if (isIgnore) WrapperRef.current.style.backgroundColor = '#ADECC4';
    else {
      if (isUsePaymentSystem && isLinkPay && data.isUseLinkPay) WrapperRef.current.style.backgroundColor = '#ADECC4';
      else WrapperRef.current.style.backgroundColor = '#FFC6A5';
    }
    RegistrationRef.current.style.opacity = '1';
    ExitRef.current.style.opacity = '0';
  }

  // 등록버튼에 마우스를 땔 경우 //
  function onMouseLeaveRegistButton() {
    WrapperRef.current.style.backgroundColor = '#FFFFFF';
    RegistrationRef.current.style.opacity = '0.5';
    ExitRef.current.style.opacity = '0.5';
  }

  // 거절버튼에 마우스 올라갈 경우 //
  function onMouseOverExitButton() {
    WrapperRef.current.style.backgroundColor = '#FD4040';
    RegistrationRef.current.style.opacity = '0';
    ExitRef.current.style.opacity = '1';
  }

  // 거절버튼에 마우스를 땔 경우 //
  function onMouseLeaveExitButton() {
    WrapperRef.current.style.backgroundColor = '#FFFFFF';
    RegistrationRef.current.style.opacity = '0.5';
    ExitRef.current.style.opacity = '0.5';
  }

  // 버튼 텍스트 결정 //
  function decideButtonText() {
    if (isIgnore) return '재등록';
    else {
      if (isUsePaymentSystem && isLinkPay && data.isUseLinkPay) return '결제 승인';
      else return '현장 등록';
    }
  }

  // 버튼 색상 결정 //
  function decideButtonBackgroundColor() {
    if (isIgnore) return '#5BD98A';
    else {
      if (isUsePaymentSystem && isLinkPay && data.isUseLinkPay) return '#5BD98A';
      else return '#FF8B48';
    }
  }

  // 등록 및 재등록 클릭시 //
  function onClickAgree() {
    onClickedAgree(data.userId, index);
  }

  // 거절 및 영구삭제 클릭시 //
  function onClickExit() {
    onClickedIgnore(data.userId, index, isIgnore);
  }

  return (
    <TopLevelWrapper onClick={onClickWaitingMember}>
      <PayUserBox isHover={isLinkPayHover}>
        <Text fontFmaily='NotoSansKR-Bold' fontSize={10} color='#FFFFFF'>링크페이 이용자</Text>
      </PayUserBox>
      <TotalWrapper>
        <LinkPayIconWrapper>
          {
            (isUsePaymentSystem && data.isUseLinkPay && isLinkPay) && <LinkPayIcon isHover={isLinkPayHover}  onMouseOver={() => setIsLinkPayHover(true)} onMouseLeave={() => setIsLinkPayHover(false)} />
          }
        </LinkPayIconWrapper>
        <ProfileWrapper>
          {/* <Profile src={data.profile === '' ? 'https://picture.link-zone.org/NoneImage.png' : data.profile}/> */}
          <UserProfile profile={data.profile} defaultProfile='https://picture.link-zone.org/NoneImage.png'/>
        </ProfileWrapper>
        <ComponentWrapper width={81}>
          <UserInfoWrapper>
            <UserNameWrapper>
              <Text fontFmaily='NotoSansKR-Medium' fontSize={16} color='#000000'>{data.userName}</Text>
            </UserNameWrapper>
            <Text fontFmaily='NotoSansKR-Medium' fontSize={14} color='#595959'>{gender}</Text>
          </UserInfoWrapper>
          <Text fontFmaily='NotoSansKR-Regular' fontSize={14} color='#CBCBCB'>{data.memberState}</Text>
        </ComponentWrapper>
        <ComponentWrapper width={75}>
          <Text fontFmaily='NotoSansKR-Medium' fontSize={14} color='#000000'>링크코치 ID</Text>
          <Text fontFmaily='NotoSansKR-Regular' fontSize={14} color='#595959'>{data.userNickName}</Text>
        </ComponentWrapper>
        <ComponentWrapper width={117}>
          <Text fontFmaily='NotoSansKR-Medium' fontSize={14} color='#232323'>전화번호</Text>
          <Text fontFmaily='NotoSansKR-Regular' fontSize={14} color='#595959'>{calcUserPhone()}</Text>
        </ComponentWrapper>
        <ComponentWrapper width={154}>
          <Text fontFmaily='NotoSansKR-Medium' fontSize={14} color='#232323'>이전 회원권</Text>
          <PrevMemberShipNameWrapper>
            <Text fontFmaily='NotoSansKR-Regular' fontSize={14} color='#595959'>{data.prevMemberShip === undefined ? '없음' : data.prevMemberShip.name}</Text>
          </PrevMemberShipNameWrapper>
        </ComponentWrapper>
        <ComponentWrapper width={157}>
          <Text fontFmaily='NotoSansKR-Medium' fontSize={14} color='#232323'>신청 회원권</Text>
          <Text fontFmaily='NotoSansKR-Regular' fontSize={14} color='#595959'>{data.itemName}</Text>
        </ComponentWrapper>
        <ComponentWrapper width={71}>
          <Text fontFmaily='NotoSansKR-Medium' fontSize={14} color='#232323'>등록 요청일</Text>
          <Text fontFmaily='NotoSansKR-Regular' fontSize={14} color='#595959'>{data.createdDate.split('T')[0].replaceAll('-', '.')}</Text>
        </ComponentWrapper>
        <ButtonWrapper ref={WrapperRef}>
          <Button 
            ref={RegistrationRef} 
            // backgroundColor='#5BD98A'
            backgroundColor={decideButtonBackgroundColor()}
            onMouseOver={onMouseOverRegistButton}
            onMouseLeave={onMouseLeaveRegistButton}
            onClick={onClickAgree}>
            {/* <Text fontFmaily='NotoSansKR-Regular' fontSize={14} color='#FDFCFB'>{isIgnore ? `재등록` : `결제 승인`}</Text> */}
            <Text fontFmaily='NotoSansKR-Regular' fontSize={14} color='#FDFCFB'>{decideButtonText()}</Text>
          </Button>
          <Button 
            ref={ExitRef} 
            backgroundColor='#FD0000'
            onMouseOver={onMouseOverExitButton}
            onMouseLeave={onMouseLeaveExitButton}
            onClick={onClickExit}>
            <Text fontFmaily='NotoSansKR-Regular' fontSize={14} color='#FDFCFB'>{isIgnore ? `영구 삭제` : `거절`}</Text>
          </Button>
        </ButtonWrapper>
      </TotalWrapper>
    </TopLevelWrapper>
  )
};

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  // display: flex;
  // flex-direction: row;

  // justify-content: flex-start;
  // align-items: center;

  // width: 995px;
  // height: 85px;

  // background: #FFFFFF;

  // margin-top: 8px;

  // box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  // border: 1px solid #FFFFFF;
  // // border-radius: 25px;
  // border-radius: 16px;

  // overflow: hidden;
  // transition: all 0.3s ease-in-out;

  // :hover {
  //   cursor: pointer;
  //   // border: 1px solid #FF8B48;
  // }
`;

const TotalWrapper = styled.div`
  // display: flex;
  // flex-direction: row;

  // justify-content: flex-start;
  // align-items: center;

  // width: 100%;
  // height: 100%;


  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 995px;
  height: 85px;

  background: #FFFFFF;

  // margin-top: 8px;

  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  // border: 1px solid #FFFFFF;
  // border-radius: 25px;
  border-radius: 16px;

  overflow: hidden;
  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const PayUserBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-left: 19px;

  width: 78px;
  height: 16px;

  opacity: ${props => props.isHover ? 1 : 0};
  border-top-left-radius: 4px;
  border-bottom-right-radius: 4px;

  background-color: #37AF71;

  transition: all 0.3s ease-in-out;
`;

const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-end;

  // margin-left: 14px;

  // width: 77px;
  width: 63px;
  height: 63px;

  border-radius: 24px;

  overflow: hidden;
`;

const LinkPayIconWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 19px;
  height: 100%;
`;

const LinkPayIcon = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 19px;
  height: 16px;

  border-top-left-radius: 16px;
  border-bottom-right-radius: 8px;
  background-color: ${props => props.isHover ? `#37AF71` : `#ADECC5`};

  transition: all 0.3s ease-in-out;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  align-items: flex-start; 

  margin-left: 15px;

  width: ${props => props.width}px;
  height: 51px;

  overflow: hidden;
  // gap: 9px;
`;

const UserInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center; 

  width: 100%;
`;

const UserNameWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start; 

  width: 64.19%;
  height: 23px;

  overflow: hidden;
`;

const PrevMemberShipNameWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start; 

  width: 100%;
  height: 20px;

  overflow: hidden;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-left: 76px;
  gap: 4px;

  width: 97px;
  height: 85px;

  border-radius: 25px;
  background-color: #FFFFFF;

  transition: all 0.3s ease-in-out;
`;

const Button = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 75px;
  height: 34px;

  opacity: 0.5;

  border-radius: 25px;
  background-color: ${props => props.backgroundColor};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const Text = styled.p`
  font-family: ${props => props.fontFmaily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
`;