import { useState, useImperativeHandle, forwardRef, useRef, React, useEffect } from 'react';
import styled from 'styled-components';

export default function ProductCountInput(props) {
	const { value, setValue, changeFn, placeholder, width } = props;
	// const [value, setValue] = useState(0);
	// const [changed, setChanged] = useState(false);
	const [clickCount, setClickCount] = useState(0);
	// const [defaultPlaceholder, setDefaultPlaceholder] = useState(placeholder);

	const inputRef = useRef();

	// useImperativeHandle(ref, () => ({
	// 	setValue: (val) => {
	// 		setValue(val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));
	// 		setChanged(true);
	// 	},
	// 	getValue: () => {
	// 		if (value === '') return 0;
	// 		else return parseInt(value.toString().replaceAll(',', ''));
	// 	}
	// }));

	// useEffect(() => {
	// 	changeFn();
	// }, [value])

	function handleClick(e) {
		inputRef.current.style.borderColor = '#FF8B48';
		// setDefaultPlaceholder('');
	}

	function handleChange(e) {
		// const curValue = e.target.value;
		// const newValue = curValue.replace(/[^0-9]/g, '');
		// const finalValue = newValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
		
		// setValue(finalValue);
    setValue(e.target.value);
	}

	function handleFocus(e) {
		// if (changed) {
		// 	// do nothing
		// } else {
		// 	setValue("");
		// }
	}

	function handleBlur(e) {
		setClickCount(clickCount + 1);
		inputRef.current.style.borderColor = '#E8E8E8';
		if (value === "") {
			// setDefaultPlaceholder(placeholder);
			// inputRef.current.style.borderColor = '#FF3131';
		} 
		else {
			// inputRef.current.style.borderColor = '#E8E8E8';
		}
	}

	return (
    <TopLevelWrapper>
      <Input
        ref={inputRef}
        width={width}
        clickCount={clickCount}
        placeholder={placeholder}
        value={value || ""}
        onClick={handleClick}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur} />
        <Text>개</Text>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

	margin-top: 8px;
`;

const Input = styled.input`
	font-family: NotoSansKR-Regular;
	font-size: 14px;
	line-height: 20px;

	// width: 262px;
  width: ${props => props.width}px;
	height: 30px;

	padding: 0 8px 0 8px;
	// margin-top: 8px;

	outline: none;
	border: 1px solid #DFDFDF;
	border-radius: 8px;
	color: #000000;
  background-color: ${props => props.disabled ? '#ECECEC' : '#FFFFFF'};

	::placeholder {
		color: #CBCBCB;
	}
	
  transition: all 0.3s ease-in-out;
`;

const Text = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #777777;

  margin: 0px 0px 0px 5px;
`;