import { forwardRef, React, useEffect, useImperativeHandle, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';

import AccountInput from '../../../Component/AccountComponent/AccountInput.js';
import SignUpEmailInput from '../../../Component/AccountComponent/SignUpEmailInput.js';
import SocialSecurityInputWrapper from '../../../Component/AccountComponent/SocialSecurityInputWrapper.js';
import FetchModule from '../../Share/Network/FetchModule.js';

const SignUpInfo2 = forwardRef((props, ref) => {
	const [socialSecurityClickCount, setSocialSecurityClickCount] = useState(0);
	const [phoneClickCount, setPhoneClickCount] = useState(0);
	const [emailClickCount, setEmailClickCount] = useState(0);
	const [isInvalidSocialSecurityNumber, setIsInvalidSocialSecurityNumber] = useState(false);
	const [isInvalidPhoneNumber, setIsInvalidPhoneNumber] = useState(false);
	const [isEmptyEmail, setIsEmptyEmail] = useState(true);
	const [isInvalidEmail, setIsInvalidEmail] = useState(false);
	const [isSuccessToSendEmail, setIsSuccessToSendEmail] = useState(false);
	// const [isCheckEmail, setIsCheckEmail] = useState(false);	
	const [isCheckEmail, setIsCheckEmail] = useState(true);	
	const [isClicked, setIsClicked] = useState(false);

	const IntervalRef = useRef(null);

	const TopLevelRef = useRef();
	const SocialSecurityRef = useRef();
	const PhoneRef = useRef();
	const EmailRef = useRef();
	const SocialSecurityPlanationRef = useRef();
	const PhonePlanationRef = useRef();
	const EmailPlanationRef = useRef();

	useImperativeHandle(ref, () => ({
		getSocialSecurityNumber: () => {
			return SocialSecurityRef.current.getValue();
		},
		getPhoneNumber: () => {
			return PhoneRef.current.getValue();
		},
		getEmail: () => {
			return EmailRef.current.getValue();
		},
		isBlankSocialSecurityNumber: () => {
			setIsInvalidSocialSecurityNumber(false);
			SocialSecurityRef.current.isSocialSecurityBlank();
			SocialSecurityPlanationRef.current.style.opacity = 1;
		},
		isInvalidSocialSecurityNumber: () => {
			setIsInvalidSocialSecurityNumber(true);
			SocialSecurityRef.current.isSocialSecurityBlank();
			SocialSecurityPlanationRef.current.style.opacity = 1;
		},
		isBlankPhoneNumber: () => {
			setIsInvalidPhoneNumber(false);
			PhoneRef.current.setDefaultPlaceholder('-를 제외하고 입력해주세요');
			PhoneRef.current.plusClickCount();
			PhonePlanationRef.current.style.opacity = 1;
		},
		isInvalidPhoneNumber: () => {
			setIsInvalidPhoneNumber(true);
			PhoneRef.current.setDefaultPlaceholder('-를 제외하고 입력해주세요');
			PhonePlanationRef.current.style.opacity = 1;
		},
		isBlankEmail: () => {
			setIsInvalidEmail(false);
			EmailRef.current.setDefaultPlaceholder('수신 가능한 이메일을 입력해주세요');
			EmailRef.current.plusClickCount();
			EmailPlanationRef.current.style.opacity = 1;
		},
		isInvalidEmail: () => {
			setIsInvalidEmail(true);
			EmailRef.current.setDefaultPlaceholder('수신 가능한 이메일을 입력해주세요');
			EmailPlanationRef.current.style.opacity = 1;
		},
		didNotCheckEmail: () => {
			setIsEmptyEmail(false);
			EmailRef.current.setDefaultPlaceholder('수신 가능한 이메일을 입력해주세요');
			EmailPlanationRef.current.style.opacity = 1;
		},
		getIsCheckEmail: () => {
			return isCheckEmail;
		},
		setOpacity: (val) => {
			TopLevelRef.current.style.opacity = val;
		},
		setLocation: (val) => {
			TopLevelRef.current.style.transform = `translateX(${val}%)`;
			setPhoneClickCount(0);
			setEmailClickCount(0);
			PhoneRef.current.reset('-를 제외하고 입력해주세요');
			EmailRef.current.reset('수신 가능한 이메일을 입력해주세요');
		}
	}));

	// input 애니메이션 및 상태 처리
	useEffect(() => {
		if (phoneClickCount === 0 && emailClickCount === 0 && socialSecurityClickCount === 0) return;

		if (PhoneRef.current.getValue() === '' && phoneClickCount > 0) {
			PhonePlanationRef.current.style.color = '#FF3131';
			PhonePlanationRef.current.style.opacity = 1;
		}
		else {
			PhonePlanationRef.current.style.opacity = 0;
		}

		if (EmailRef.current.getValue() === '' && emailClickCount > 0) {
			EmailPlanationRef.current.style.color = '#FF3131';
			EmailPlanationRef.current.style.opacity = 1;
		}
		else {
			EmailPlanationRef.current.style.opacity = 0;
		}

		if (SocialSecurityRef.current.getValue().length !== 14 && socialSecurityClickCount >= 0) {
			SocialSecurityPlanationRef.current.style.color = '#FF3131';
			SocialSecurityPlanationRef.current.style.opacity = 1;
		}
		else {
			SocialSecurityPlanationRef.current.style.opacity = 0;
		}

	}, [phoneClickCount, emailClickCount, socialSecurityClickCount])

	useEffect(() => {
		if (isSuccessToSendEmail) EmailPlanationRef.current.style.color = '#FF8B48';
		else EmailPlanationRef.current.style.color = '#FF3131';
	}, [isSuccessToSendEmail])

	useEffect(() => {
		if (!isClicked) {
			clearInterval(IntervalRef.current);
		}
	}, [isClicked])

	// email형식 확인 정규식
	function isEmail(asValue) {
		var regExp = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,6}$/i;

		return regExp.test(asValue);
	}

	function clickCountFn(className, clickCount) {
		if (className === 'PhoneInput') setPhoneClickCount(clickCount);
		else if (className === 'EmailInput') setEmailClickCount(clickCount);
		else if (className === 'SocialSecurityInput') setSocialSecurityClickCount(clickCount);
	}

	function emailAvailableCheck() {
		var email = EmailRef.current.getValue();

		if (email === '') {
			setIsEmptyEmail(true);
			EmailRef.current.setDefaultPlaceholder('수신 가능한 이메일을 입력해주세요');
			EmailPlanationRef.current.style.opacity = 1;
			return;
		}

		if (email === '' || !isEmail(email)) {
			setIsInvalidEmail(true);
			EmailRef.current.setDefaultPlaceholder('수신 가능한 이메일을 입력해주세요');
			EmailPlanationRef.current.style.opacity = 1;
			return;
		}

		EmailPlanationRef.current.style.opacity = 1;
		setIsSuccessToSendEmail(true);
		setIsClicked(true);
		
		// 테스트를 위한 강제 승인 //
		// setIsCheckEmail(true);

		const fetchModule = new FetchModule();
		(
			async () => {
				const responseData = await fetchModule.postData('account/asp/available/' + email, 'POST');
				var count = 0;
				if (responseData.status === 200) {
					EmailPlanationRef.current.style.opacity = 1;

					startInterval(email);
				}
				else if (responseData.status === 205) {
					alert("이미 사용중인 이메일 입니다.");
					setIsSuccessToSendEmail(false);
				}
				else {
					alert("이메일 전송에 실패하였습니다.\n다시 시도하여 주세요");
					setIsSuccessToSendEmail(false);
				}
			})();
		// 테스트를 위한 강제 승인 //
	}

	function startInterval(email) {
		var count = 0;

		IntervalRef.current = setInterval(() => {
			
		const fetchModule = new FetchModule();
			(
				async () => {
					const responseData = await fetchModule.postData('account/asp/state/certification/' + email, 'POST');
					// console.log("ResponseData : " + responseData);
					count += 1;

					if (responseData.status === 200) {
						setIsCheckEmail(true);
						clearInterval(IntervalRef.current);
					}

					if (count === 180) clearInterval(IntervalRef.current);
				})
				();
		}, 1000)
	}

	return (
		<TopLevelWrapper ref={TopLevelRef}>
			<InputInfo>주민등록번호</InputInfo>
			<SocialSecurityInputWrapper ref={SocialSecurityRef} wrapperClassName='SocialSecurityInput' clickCountFn={clickCountFn} />
			<AccountExplanation ref={SocialSecurityPlanationRef}>{isInvalidSocialSecurityNumber ? '주민번호가 올바르지 않습니다' : '필수항목을 입력해주세요'}</AccountExplanation>
			<InputInfo className='phone'>휴대전화 번호</InputInfo>
			<AccountInput ref={PhoneRef} className='PhoneInput' placeholder='-를 제외하고 입력해주세요' type='text' clickCountFn={clickCountFn} />
			<AccountExplanation ref={PhonePlanationRef}>{isInvalidPhoneNumber ? '휴대전화 번호가 올바르지 않습니다.' : '필수항목을 입력해주세요'}</AccountExplanation>
			<InputInfo>이메일 계정</InputInfo>
			<SignUpEmailInput ref={EmailRef} isClicked={isClicked} setIsClicked={setIsClicked} className='EmailInput' placeholder='수신 가능한 이메일을 입력해주세요' type='text' clickCountFn={clickCountFn} clickFn={emailAvailableCheck} text='계정확인' />
			{
				isSuccessToSendEmail ?
					<AccountExplanation ref={EmailPlanationRef}>{isCheckEmail ? '사용가능한 이메일입니다' : '이메일 수신함에서 인증 완료 버튼을 눌러주세요'}</AccountExplanation>
					:
					<AccountExplanation ref={EmailPlanationRef}>{isInvalidEmail ? '이메일을 정확하게 입력해주세요' : isEmptyEmail ? '필수항목을 입력해주세요' : '이메일 인증을 진행해 주세요'}</AccountExplanation>
			}
			{/* <AccountExplanation ref={EmailPlanationRef}>{isInvalidEmail ? '이메일을 정확하게 입력해주세요' : '필수항목을 입력해주세요'}</AccountExplanation> */}
		</TopLevelWrapper>
	)
})

export default SignUpInfo2;

const startAnimation = keyframes`
	0% { opacity: 0; transform: translateX(150%); }
	10% { opacity: 0.1; transform: translateX(130%); }
	20% { opacity: 0.2; transform: translateX(100%); }
	30% { opacity: 0.3; transform: translateX(70%); }
	40% { opacity: 0.4; transform: translateX(40%); }
	50% { opacity: 0.5; transform: translateX(20%); }
	60% { opacity: 0.6; transform: translateX(0%); }
	70% { opacity: 0.7;}
	80% { opacity: 0.8; }
	90% { opacity: 0.9;}
	100% { opacity: 1;}
`;

const TopLevelWrapper = styled.div`
	position: absolute;
	display: flex;
	flex-direction: column; 

	// justify-content: flex-start;
	// align-items: flex-start;

	width: 468px;
	// width: max(271px, 24.375vw);
	// width: max(250px, 24.375vw);

	margin-top: 159px;
	// margin-top: max(100px, 8.28125vw);

	background-color: rgba(255, 255, 255, 1);

	// opacity: 1;
	// z-index: 1;
	// transform: translateX(150%);

	animation-name: ${startAnimation};
	animation-duration: 0.5s;
	animation-timing-function: ease-out;

  transition: all 0.3s ease-in-out;
`;

const InputInfo = styled.p`
	font-family: NotoSansKR-Regular;
	font-size: 14px;
	// font-size: max(12px, 0.7291666666666667vw);
	// line-height: 20px;

	// margin: 40px 0 0 0;
	margin: ${props => props.className === 'phone' ? `4px 0 0 0` : `19px 0 0 0`};
	// margin-top: ${props => props.className === 'phone' ? `4px` : `19px`};
	// margin-right: 0px;
	// margin-bottom: 0px;
	// margin-left: 0px;

	cursor: default;
`;

const AccountExplanation = styled.p`
	font-family: NotoSansKR-Regular;
	font-size: 12px;
	// font-size: max(12px, 0.625vw);
	// line-height: 17.38px;

	color: #FF3131;

	margin: 4px 0 0 0;

	opacity: 0;

  transition: all 0.5s ease-in-out;
`;