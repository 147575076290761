import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Document, Page, pdfjs } from 'react-pdf';

import NextPDF from '../../../image/NextPDF.png';
import PrevPDF from '../../../image/PrevPDF.png';
import PictureDeleteButton from '../../../image/PDFExitButton.png'

import CenterTermsUploadCheckModal from './CenterTermsUploadCheckModal';
import FetchModule from '../../Share/Network/FetchModule';
import moment from 'moment';
import CenterTermsDeleteCheckModal from './CenterTermsDeleteCheckModal';

export default function CenterSubscriptionTermsEdit(props) {

  const { terms, setTerms } = props;

  const history = useHistory();

  const [isFirst, setIsFirst] = useState(true);
  const [totalNumberPage, setTotalNumberPage] = useState(0);
  const [nowPage, setNowPage] = useState(1);
  const [height, setHeight] = useState('auto');
  const [isClickedFold, setIsClickedFold] = useState(false);
  const [isClickedUploadModal, setIsClickedUploadModal] = useState(false);
  const [isClickedDeleteModal, setIsClickedDeleteModal] = useState(false);
  const [isViewDeleteButton, setIsViewDeleteModal] = useState(false);
  const [pdfBase64, setPdfBase64] = useState('');

  const PDFRef = useRef();
  const hiddenFileInput = useRef(null);

  // 삭제 버튼 보일지 결정 //
  useEffect(() => {
    if (terms.id === 0) setIsViewDeleteModal(false);
    else setIsViewDeleteModal(true);
  }, [terms])

  // PDF Viewer Setting //
  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, [])

  // 로딩 완료시 총 페이지 수 받아오기 //
  function onLoadSuccess({ numPages }) {
    if (!isFirst) return;

    setTotalNumberPage(numPages);

    if (numPages !== 0) setNowPage(1);

    setIsFirst(false);
  }

  // 가입 약관 이전 버튼 클릭시 //
  function onClickPrev() {
    if (nowPage === 1) return;

    setHeight(PDFRef.current.clientHeight + 'px');
    setNowPage(nowPage - 1);
  }

  // 가입 약관 다음 버튼 클릭시 //
  function onClickNext() {
    if (nowPage === totalNumberPage) return;

    setHeight(PDFRef.current.clientHeight + 'px');
    setNowPage(nowPage + 1);
  }

  // 접기, 펼치기 버튼 클릭시 //
  function onClickFold() {
    setIsClickedFold(!isClickedFold);

    if (!isClickedFold) {
      setIsFirst(true);
      setNowPage(0);
      setTotalNumberPage(0);
    }
  }

  // 센터 가입 약관 업로드 클릭시 //
  function onClickUpload() {
    if (PDFRef.current !== null && terms.termsUrl !== '') setHeight(PDFRef.current.clientHeight + 'px');
    setIsClickedUploadModal(true);
  }

  // 약관 업로드 경고 모달내에서 업로드 클릭시 //
  function onClickYesUpload() {
    hiddenFileInput.current.click();
  }


  // PDF파일 선택시 //
  function handleChange(e) {

    let pathpoint = e.target.value.lastIndexOf('.');
    let filepoint = e.target.value.substring(pathpoint + 1, e.target.length);
    let filetype = filepoint.toLowerCase();
    let selectFile = e.target.files;
    let file = null;

    if (filetype === 'pdf') {
      let fileToLoad = selectFile[0];

      const fileReader = new FileReader();

      fileReader.onload = (e) => {
        file = e.target.result;

        if (file) {
          setPdfBase64(file.toString());

          var requestData = {
            storeId: 0,
            registrationDate: moment().format('YYYY-MM-DDTHH:mm:ss'),
            termsUrl: file.toString()
          }

          const fetchModule = new FetchModule();
          (
            async () => {
              const responseData = await fetchModule.postData('center_terms/save', 'POST', requestData);
              // console.log(responseData.data);
              if (responseData.status === 200) {
                setTerms(responseData.data[0]);
                setHeight('auto');
                setIsClickedUploadModal(false);
                setIsFirst(true);
              }
              else if (responseData.status === 401) history.push('/');
            }
          )();
        }
      }

      fileReader.readAsDataURL(fileToLoad);
    }
    else {
      alert('.pdf 파일만 업로드 가능합니다!');
    }
  };

  // 약관 삭제 버튼 클릭시 //
  function onClickDeletedButton() {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postData('center_terms/delete', 'POST');
        
        if (responseData.status === 200) {
          setTerms({
            id: 0,
            registrationDate: '2000-01-01 00:00:00',
            termsUrl: ''
          });
          setHeight('auto');
          setIsClickedDeleteModal(false);
        }
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }

  return (
    <TopLevelWrapper>
      {isClickedUploadModal && <CenterTermsUploadCheckModal onClickUpload={onClickYesUpload} onClickExit={() => setIsClickedUploadModal(false)} />}
      {isClickedDeleteModal && <CenterTermsDeleteCheckModal onClickDelete={onClickDeletedButton} onClickExit={() => setIsClickedDeleteModal(false)}/>}
      <InfoView>
        <TitleWrapper>
          <SubWrapper>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#232323'>센터 가입 약관</Text>
            <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>{terms.termsUrl === '' ? '( 없음 )' : '( ' + moment(terms.registrationDate).format('YYYY.MM.DD HH:mm:ss') + ' )'}</Text>
          </SubWrapper>
          <RightButtonWrapper>
            <ButtonWrapper onClick={onClickFold}>
              <HoverText fontFamily='NotoSansKR-Regular' fontSize={14} color='#FF8B48'>{isClickedFold ? `펼치기` : `접기`}</HoverText>
            </ButtonWrapper>
            <UploadButton onClick={onClickUpload}>
              <HoverText fontFamily='NotoSansKR-Regular' fontSize={12} color='#777777'>수정하기</HoverText>
            </UploadButton>
          </RightButtonWrapper>
        </TitleWrapper>
        {
          !isClickedFold &&
          <InfoWrapper>
            {
              terms.termsUrl !== '' ?
                <ViewWrapper ref={PDFRef} height={height}>
                  <Document
                    // file={{ url: 'http://link-picture.s3-website.ap-northeast-2.amazonaws.com/zone/wecrossfit2/terms/39b0e907-3614-45ba-b5c6-25f39010f790_terms_wecrossfit2.pdf' }}
                    file={{ url: terms.termsUrl }}
                    onLoadSuccess={onLoadSuccess}>
                    <Page pageNumber={nowPage} />
                  </Document>
                  <PDFButtonWrapper>
                    {
                      nowPage > 1 ?
                        <PDFButton onClick={onClickPrev}>
                          <Image src={PrevPDF} />
                        </PDFButton>
                        :
                        <EmptyButton />
                    }
                    {
                      (nowPage !== totalNumberPage) ?
                        <PDFButton onClick={onClickNext}>
                          <Image src={NextPDF} />
                        </PDFButton>
                        :
                        <EmptyButton />
                    }
                  </PDFButtonWrapper>
                  <ExitButtonWrapper>
                    <ExitButton onClick={() => setIsClickedDeleteModal(true)} src={PictureDeleteButton}/>
                  </ExitButtonWrapper>
                </ViewWrapper>
                :
                <EmptyViewWrapper>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>등록된 센터 약관이 없습니다</Text>
                </EmptyViewWrapper>
            }
          </InfoWrapper>
        }
        <input
          type="file"
          ref={hiddenFileInput}
          onChange={handleChange}
          style={{ display: 'none' }} />
      </InfoView>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 34px;
  // margin-bottom: 149px;

  width: 100%;
`;

const InfoView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 28px 0px 28px 0px;

  width: 1191px;

  border-radius: 30px;
  background-color: #FFFFFF;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  width: 1135px;
`;

const SubWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const RightButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  aling-items: center;

  gap: 14px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  :hover {
    cursor: pointer;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 24px;

  border-top: 1px solid #ECECEC;

  width: 1135px;
`;

const ViewWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  // justify-content: center;
  // align-items: center;
  justify-content: flex-start;
  align-items: center;

  margin-top: 24px;

  width: 100%;
  height: ${props => props.height};
  max-height: 1369px;
`;

// const ExitButton = styled.div`
//   position: absolute;
//   display: flex;
//   flex-direction: row;

//   justify-content: center;
//   align-items: center;

//   width: 65px;
//   height 65px;

//   border-radius: 65px;
//   // backgroud-color: rgba(0, 0, 0, 0.5);
//   background-color: red;
  
//   :hover {
//     cursor: pointer;
//   }
// `;

const ExitButtonWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  width: 100%;
`;

const ExitButton = styled.img`
  width: 42px;
  height: 42px;

  border-radius: 64px;

  object-fit: cover;


  :hover {
    cursor: pointer;
  }
`;

const EmptyViewWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  
  width: 100%;
  height: 100px;
`;

const PDFButtonWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 1135px;
  height: 100%;
`;

const PDFButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 80px;
  height: 80px;

  :hover {
    cursor: pointer;
  }
`;

const EmptyButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 80px;
  height: 80px;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
`;

const UploadButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // margin-top: 24px;

  // width: 1135px;
  width: 85px;
  height: 30px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const DeleteButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 87px;
  height: 32px;

  // border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FF3131;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const HoverText = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;