import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import GrayExitPath from '../../../../image/GrayExit.png';
import NonePicturePath from '../../../../image/WhiteNonePictureIcon.png';
import InventoryCountInput from '../../../../Component/SettingComponent/CountInput';
import PriceInput from '../../../../Component/SettingComponent/PriceInput';
import TextInput from '../../../../Component/SettingComponent/TextInput';

export default function ProductAddModal(props) {
  const { data, onClickSaveButton, onClickExitButton } = props;

  const [name, setName] = useState('');
  const [price, setPrice] = useState(0);
  const [inventory, setInventory] = useState(0);

  const [profile, setProfile] = useState('');
	const [imgBase64, setImgBase64] = useState();
  const [imgFile, setImgFile] = useState();
  const [isUpLoad, setIsUpLoad] = useState(false);
  const [imgLocalPath, setImgLocalPath] = useState('');

	const hiddenFileInput = useRef();

  // Modal 창 스크롤 제어 //
  useEffect(() => {
    document.body.style = `overflow: hidden`;
    return () => document.body.style = `overflow: auto`;
  }, [])


  // 데이터 수정인 경우 데이터 세팅 //
  useEffect(() => {
    if (data === undefined) return;

    setName(data.name);
    setPrice(data.price);
    setInventory(data.inventory);
    setProfile(data.picture);
  }, [data])

  // 저장하기 클릭시 //
  function onClickSave() {
    onClickSaveButton(name, price, inventory, profile);
  }

  // 사진 변경 클릭시 //
  const handleClick = (e) => {
    hiddenFileInput.current.click();
	};

  // 사진변경시 //
  const handleChange = (e) => { 
    let pathpoint = e.target.value.lastIndexOf('.');
    let filepoint = e.target.value.substring(pathpoint + 1, e.target.length);
    let filetype = filepoint.toLowerCase();

    if (filetype === 'jpg' || filetype === 'png' || filetype === 'jpeg') {

      // 정상적인 이미지 확장자 파일인 경우

    } 
    else {
      alert('.jpeg .jpg .png 파일만 가능합니다!');
      let parentObj = e.target.parentNode
      let node = parentObj.replaceChild(e.target.cloneNode(true), e.target);

      return false;
    }

		const reader = new FileReader();
    let tmp = e.target.value.split('\\');
    setImgLocalPath(tmp[tmp.length - 1]);
    
		reader.onloadend = () => {
			const base64 = reader.result;
			if (base64){
				// setImgBase64(base64.toString());
        setProfile(base64.toString());
        setIsUpLoad(true);
			}
		};
		if (e.target.files[0]) {
      reader.readAsDataURL(e.target.files[0]);
      setImgFile(e.target.files[0]);
		}
	};

  return (
    <TopLevelWrapper>
      <Modal>
        <InnerWrapper>
          <TitleWrapper>
            <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#232323'>물품 추가하기</Text>
            {/* <TitleExitButtonWrapper>
              <TitleExitButton src={GrayExitPath} />
            </TitleExitButtonWrapper> */}
          </TitleWrapper>
          <ContentWrapper>
            {
              profile === '' ?
                <EmptyProfileBox onClick={handleClick}>
                  {/* <PlusBox>
                    <Width />
                    <Height />
                  </PlusBox> */}
                  <NonePicture src={NonePicturePath}/>
                </EmptyProfileBox>
                :
                <ProfileBox onClick={handleClick}>
                  <Profile src={profile} />
                </ProfileBox>
            }
            <input 
              type="file"
              ref={hiddenFileInput}
              onChange={handleChange}
              style={{ display: 'none' }} />
            <InputBoxWrapper>
              <InputBox>
                <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>물품 이름</Text>
                <InputWrapper>
                  <TextInput value={name} setValue={setName} placeholder='물품의 이름을 입력해주세요' />
                </InputWrapper>
              </InputBox>
              <InputBox>
                <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>가격 설정</Text>
                <InputWrapper>
                  <PriceInput value={price} setValue={setPrice} />
                </InputWrapper>
              </InputBox>
              <InputBox>
                <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>재고 관리</Text>
                <InputWrapper>
                  <InventoryCountInput value={inventory} setValue={setInventory} unit='개' />
                </InputWrapper>
              </InputBox>
            </InputBoxWrapper>
          </ContentWrapper>
          <SaveButtonWrapper>
            <ButtonWrapper>
              <ExitButton onClick={onClickExitButton}>취소</ExitButton>
              <DeleteButton onClick={onClickSave}>저장하기</DeleteButton>
            </ButtonWrapper>
          </SaveButtonWrapper>
        </InnerWrapper>
      </Modal>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 1000;

  background-color: rgba(0, 0, 0, 0.2);
  
  transition: all 0.3s ease-in-out;
`;

const Modal = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 454px;
  // height: 277px;
  height: 330px;

  border-radius: 15px;
  background-color: #FFFFFF;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 90.33%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;


const TitleExitButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;

  :hover {
    cursor: pointer
  }
`;

const TitleExitButton = styled.img`
  width: 100%;
  height: 100%;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: 18px;

  width: 100%;
  height: 190px;
`;

const ProfileBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 190px;
  height: 190px;

  border-radius: 10px;
  background-color: #FFFFFF;

  overflow: hidden;

  :hover {
    cursor: pointer;
  }
`;

const Profile = styled.img`
  width: 100%;
  height: 100%;

  object-fit: contain;
`;

const NonePicture = styled.img`
  width: 35.26%;
  height: 35.26%;
`;

const EmptyProfileBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 190px;
  height: 190px;

  border-radius: 10px;
  // background-color: #D9D9D9;
  opacity: 0.7;
  background-color: #000000;

  :hover {
    cursor: pointer;
  }
`;

const PlusBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 66px;
  height: 66px;
`;

const Width = styled.div`
  position: absolute;
  width: 66px;
  height: 8px;

  border-radius: 8px;
  background-color: #FFFFFF;
`;

const Height = styled.div`
  position: absolute;
  width: 8px;
  height: 66px;

  border-radius: 8px;
  background-color: #FFFFFF;
`;

const InputBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  align-items: center;

  width: 208px;
  height: 100%;
  
  background-colo: red;
`;

const InputBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 8px;

  width: 100%;
`;

const SaveButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: flex-end;

  margin-top: 24px;

  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 16px;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 72px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;
  
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #777777;
    color: #777777;
  }
`;

const DeleteButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 15px;
  background-color: #5BD98A;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;


const Text = styled.p`
	font-family: ${props => props.fontFamily};
	font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  padding: 0 0 0 0;

  :hover {
    cursor: ${props => props.hover};
  }
`;
