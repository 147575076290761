import { React } from 'react';
import styled from 'styled-components';

export default function MyStoreSettingTopModel(props) {
  const { onClickDownloadMemberList, onClickSubscribe } = props;

  return (
    <TopLevelWrapper>
      <LayerWrapper>
        <TextWrapper>
          <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#232323'>마이페이지</Text>
          <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#777777'>센터 관련 정보를 확인하고 변경할 수 있어요</Text>
        </TextWrapper>
        <RightWrapper>
          <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FF8B48' cursor='pointer' onClick={onClickDownloadMemberList}>회원목록 다운로드</Text>
          <SubscribeButton onClick={onClickSubscribe}>
            <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF' cursor='pointer'>서비스 구독하기</Text>
          </SubscribeButton>
        </RightWrapper>
      </LayerWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  // width: 1191px;
  width: 100%;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  // margin-left: 28px;

  gap: 4px;
`;

const RightWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 17px;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  margin: 0 0 0 0;

  color: ${props => props.color};

  :hover {
    cursor: ${props => props.hover};
  }
`;

const SubscribeButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 120px;
  height: 32px;

  border-radius: 5px;

  background-color: #FF8B48;
  
  font-family: NotoSansKR-Bold;
  font-size: 14px;

  margin: 0 0 0 0;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #FF7322;
  }
`;