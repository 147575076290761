import { React, useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import FetchModule from '../../Model/Share/Network/FetchModule';

import SelectOffPath from '../../image/SelectOff.png';
import BlackPlusIcon from '../../image/BlackPlus.svg';
import CheckImage from '../../image/CheckImage.png';
import OnSearchImage from '../../image/OnSearchButton.png';
import OffSearchImage from '../../image/OffSearchButton.png';

export default function MembershipSortingSelectBox(props) {
  const { value, setValue } = props;
  // const history = useHistory();

	const [isClicked, setIsClicked] = useState(false);
  const [options, setOptions] = useState([]);
  const [tmpValue, setTmpValue] = useState([]);

  const TopLevelRef = useRef();
  const TotalSelectRef = useRef();
	const SelectRef = useRef();

  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getDataVersion2('membership/', 'GET');
        
        if (responseData.status === 200) {
          // setMemberShipData(responseData.data.memberShips);
          setOptions(responseData.data.memberShips);
          // setCategoryData(responseData.data.categories);
          // setLessonData(responseData.data.lessonInfos);
        }
      }
    )();
  }, [])

  useEffect(() => {
    let newTempValue = [];

    for (let i = 0; i < options.length; i++) {
      for (let j = 0; j < value.length; j++) {
        if (options[i].name === value[j].name) {
          newTempValue.push(options[i]);
        }
      }
    }

    setTmpValue(newTempValue);
  }, [value, options])

  // 컴포넌트 밖을 클릭하면 셀렉트 창 닫기
  useEffect(() => {
    document.addEventListener("mousedown", onClickOutSide);

    return () => {
      document.removeEventListener("mousedown", onClickOutSide);
    }
  }, [])

  // 컴포넌트 밖을 클릭하면 셀렉트 창 닫기
  function onClickOutSide(e) {
    if (TopLevelRef.current !== e.target && !TopLevelRef.current.contains(e.target)) {
      TotalSelectRef.current.style.height = '0px';
      TotalSelectRef.current.style.border = '0px';
      // SelectRef.current.style.border = '0px';
      SelectRef.current.style.height = `0px`;
      SelectRef.current.style.overflow = 'hidden';
      setIsClicked(false);
    }
  }

	function onClick() {
		if (isClicked) {
      TotalSelectRef.current.style.height = '0px';
			TotalSelectRef.current.style.border = '0px';
			// SelectRef.current.style.border = '0px';
			SelectRef.current.style.height = `0px`;
			SelectRef.current.style.overflow = 'hidden';
			setIsClicked(false);
		}
		else {
      TotalSelectRef.current.style.height = (32 * (options.length + 1 )) > 230 ? `264px` : (((32 * (options.length + 1)) + 36) + 'px');
			TotalSelectRef.current.style.border = '1px solid #FF8B48';
      // SelectRef.current.style.border = '1px solid #FF8B48';
			SelectRef.current.style.height = (32 * (options.length + 1 )) > 230 ? `230px` : ((32 * (options.length + 1)) + 'px');
			SelectRef.current.style.overflowY = 'scroll';
			SelectRef.current.style.overflowX = 'none';
			setIsClicked(true);
		}
	}

  // 코치 클릭시
	function optionalClick(index) {
    // 입력시 중복확인 => 존재하면 삭제, 존재하지 않으면 추가
    let newDefaultVal = tmpValue.slice();

    if (newDefaultVal.includes(options[index])) {
      let deletedIndex = 0;

      newDefaultVal.filter(function(item, idx) {
        // 두개가 같으면
        if (options[index] === item) {
          deletedIndex = idx;
          return;
        }
      })

      newDefaultVal.splice(deletedIndex, 1);
      // setValue(newDefaultVal);
      setTmpValue(newDefaultVal);
    }
    else {
      newDefaultVal.push(options[index]);
      // setValue(newDefaultVal);
      setTmpValue(newDefaultVal);
    }
	}

  // 확인 버튼 클릭시 //
  function onClickComplete() {
    setValue(tmpValue);
    onClick();
  }
  
  // 초기화 버튼 클릭시 //
  function onClickReset() {
    setValue([]);
    setTmpValue([]);
    onClick();
  }

	return (
    <TopLevelWrapper ref={TopLevelRef}>
      <SearchButton img={isClicked ? OnSearchImage : OffSearchImage} onClick={onClick}/>
      <TotalSelectBox ref={TotalSelectRef}>
      <SelectBox
        ref={SelectRef}
        isClicked={isClicked}>
        {
          options.map((data, index) =>
            <OptionWrapper key={index} onClick={() => optionalClick(index)}>
              <TextWrapper>
                <Option isClicked={tmpValue.includes(data)}>{data.name}</Option>
              </TextWrapper>
              { tmpValue.includes(data) && <Check src={CheckImage}/> }
            </OptionWrapper>
          )
        }
      </SelectBox>
      <CompleteButtonWrapper>
        <TextButton onClick={onClickComplete}>확인</TextButton>
        <TextButton onClick={onClickReset}>초기화</TextButton>
      </CompleteButtonWrapper>
      </TotalSelectBox>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
	display: flex;
	flex-direction: column;
	
  width: 20px;
	height: 20px;
`;

const SearchButton = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;

  background: url(${props => props.img}) no-repeat center center;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const Check = styled.img`
  width: 12px;
  height: 9px;
`;

const TotalSelectBox = styled.div`
	position: absolute;
	display: flex;
	flex-direction: column;

	justify-content: flex-start;
	align-items: flex-start;

  margin-top: 25px;
  margin-left: -72px;

  width: 160px;
	height: 0px;

  // padding: 0 8px 0 8px;

  z-index: 2;

  border: 0px;
	border-radius: 8px;
	overflow: hidden;

	background-color: rgba(255, 255, 255, 1);

  transition: all 0.3s ease-in-out;
`;

const SelectBox = styled.div`
	// position: absolute;
	display: flex;
	flex-direction: column;

	justify-content: flex-start;
	align-items: flex-start;

  // margin-top: 25px;
  // margin-left: -72px;

  width: 144px;
	height: 0px;

  padding: 0 8px 0 8px;

  z-index: 2;

  border: 0px;
	border-radius: 8px;
	overflow: hidden;

	background-color: rgba(255, 255, 255, 1);

  transition: all 0.3s ease-in-out;

	:hover {
		cursor: pointer;
	}

	&::-webkit-scrollbar {
		width: 9px;
	}

	&::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 20px #FF8B48;
		border: 3px solid transparent;
		height: 25px;
		border-radius: 200px;
	}

	&::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px transparent;
		border: 4px solid transparent;
		background-color: transparent;
	}

	&::-webkit-scrollbar-track-piece {
		background-color: transparent;
	}
`;

const OptionWrapper = styled.div`
	display: flex;
	flex-direction: row;
  
  justify-content: space-between;
  align-items: center;

	width: 100%;
	// width: 122px;

  // margin-top: 12px;
  margin: 6px 0px 6px 0px;
  
	:hover {
		cursor: pointer;
	}
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  // margin: 6px 0 6px 0;

  width: calc(100% - 16px);
  height: 19px;

  overflow: hidden;
`;

const Option = styled.p`
	font-family: NotoSansKR-Regular;
	font-size: 13px;

  color: ${props => props.isClicked ? `#FF8B48` : `#555555`};

  // margin: 6px 0 6px 0;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
`;

const CompleteButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  padding: 0px 8px 0px 8px;

  gap: 8px;

  width: calc(100% - 16px);
  height: 36px;

  border-top: 1px solid #DBDBDB;
`;

const TextButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #BDBDBD;

  :hover {
    cursor: pointer;
    color: #FF8B48;
  }

  transition: all 0.3s ease-in-out;
`;

// const Text = styled.p`
//   font-family: ${props => props.fontFamily};
//   font-size: ${props => props.fontSize}px;

//   color: ${props => props.color};

//   margin: 0 0 0 0;

//   :hover {
//     cursor: ${props => props.cursor};
//   }
// `;