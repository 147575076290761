import { React, useState, forwardRef, useImperativeHandle, useRef, useEffect } from 'react';
import styled from 'styled-components';

import DownImage from '../../image/down.png';
import UpImage from '../../image/up.png';

export default function InventoryCountInput(props) {
  const { value, setValue, unit } = props;

  const InputRef = useRef();


	function handleChange(e) {
    let regex = /[^0-9]/g;				// 숫자가 아닌 문자열을 매칭하는 정규식
    let result = e.target.value.replace(regex, "");	// 원래 문자열에서 숫자가 아닌 모든 문자열을 빈 문자로 변경
   
    if (e.target.value === '') setValue(0);
		else setValue(parseInt(result));
	}

	function handleFocus(e) {

	}

  function upButtonClick() {
    setValue(value + 1);
  }

  function downButtonClick(){
    if (value > 0) setValue(value - 1);
  }

  return (
    <TopLevelWrapper>
      <InputWrapper>
        <Input 
          ref={InputRef}
          placeholder={0}
          value={value}
          onChange={handleChange}
          onFocus={handleFocus}/>
        <ButtonWrapper>
          <Button img={UpImage} onClick={upButtonClick} />
          <Button img={DownImage} onClick={downButtonClick} />
        </ButtonWrapper>
        <Text>{unit}</Text>
      </InputWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  
  transition: all 0.3s ease-in-out;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 100%;
`;

const Input = styled.input`
  display: flex;
  flex-direction: row;

  // width: calc(46.31% - 18px);
  width: 90px;
  height: 30px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;

  padding: 0 8px 0 8px;
  margin: 0 11px 0 0;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;

  :disabled {
    color: #FFFFFF;
    background-color: #FF8B48;
  }

  transition: all 0.3s ease-in-out;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
`;

const Button = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 30px;
  height: 30px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background: url(${props => props.img}) no-repeat center;
  background-color: #ECECEC;

  :hover {
    cursor: pointer;
  }
`;

const Text = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #777777;

  margin: 0 0 0 11px;
`;