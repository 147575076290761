import { React, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
// import moment from 'moment';

import PriceInput from '../../../Component/MySettingPageComponent/PriceInput';
import FetchModule from '../../Share/Network/FetchModule';
import moment from 'moment';
import CalenderInput from '../../../Component/LockerComponent/LockerCalenderInput';
import RefundReasonInput from '../../../Component/MemberComponent/MemberDetail/RefundReasonInput';

function RefundModal(props) {
  const { className, rendering, exitClick, refundData, index } = props;

  const history = useHistory();

  const [refundPrice, setRefundPrice] = useState(0);
  const [originalRefundCardPrice, setOriginalRefundCardPrice] = useState(0);
  const [originalRefundCashPrice, setOriginalRefundCashPrice] = useState(0);
  const [refundCardPrice, setRefundCardPrice] = useState(0);
  const [refundCashPrice, setRefundCashPrice] = useState(0);
  const [approvalDate, setApprovalDate] = useState(moment());
  const [reason, setReason] = useState('');

  // 센터 환불인지 링크 환불인지 여부
  const [isCenterRefund, setIsCenterRefund] = useState(true);

  // 스크롤 정지 //
  useEffect(() => {
    document.body.style= `overflow: hidden`;
    return () => document.body.style = `overflow: auto`;
  }, [])

  // 이전 환불 신청이 있는지 확인 및 세팅 //
  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getDataVersion2('sales/refund/state/' + refundData.merchantUid, 'GET');
        
        if (responseData.status === 200) {
          setRefundCardPrice(responseData.data.cardPrice);
          setRefundCashPrice(responseData.data.cashPrice);
          setOriginalRefundCardPrice(responseData.data.cardPrice);
          setOriginalRefundCashPrice(responseData.data.cashPrice);
          // setRefundPrice(responseData.data.cardPrice + responseData.data.cashPrice);
          setApprovalDate(moment(responseData.data.approvalDate));
          setReason(responseData.data.reason);
        }
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }, [history, refundData])

  useEffect(() => {
    // if (refundData.merchantUid === undefined) return;

    // if (refundData.merchantUid.split('-')[2] !== '3') {
    //   const fetchModule = new FetchModule();
    //   (
    //     async () => {
    //       const responseData = await fetchModule.getIndividualData('sales/refund_info/' + refundData.merchantUid, 'GET');

    //       if (responseData.status === 200) setRefundPrice(responseData.data[0]);
    //       else if (responseData.status === 401) history.push('/');
    //     }
    //   )();
    // }
    // else {
    //   setIsCenterRefund(true);
    // }

    if (refundData.merchantUid === undefined) return;

    if (refundData.merchantUid.split('-')[2] !== '3') setIsCenterRefund(false);
    else setIsCenterRefund(true);

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getIndividualData('sales/refund_info/' + refundData.merchantUid, 'GET');

        if (responseData.status === 200) setRefundPrice(responseData.data[0]);
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }, [history, refundData])

  // 환불버튼 클릭시 //
  function onClickRefund() {

    if (!isCenterRefund) {
      if (parseInt(refundCardPrice) + parseInt(refundCashPrice) === 0) {
        alert("환불금액을 입력해주세요");
        return;
      } 

      let requestData = {
        merchantUid: refundData.merchantUid,
        cardPrice: refundCardPrice,
        cashPrice: refundCashPrice,
        reason: reason,
        // approvalDate: approvalDate.format('YYYY-MM-DDTHH:mm:ss')
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataVersion2('sales/refund/linkpay', 'POST', requestData);
          
          if (responseData.status === 200) {
            rendering();
            exitClick();
          }
          else if (responseData.status === 201) {
            alert('올바르지 않은 요청입니다.');
            return;
          }
          else if (responseData.status === 202) {
            alert('결제가 완료된 내역이 아닙니다.');
            return;
          }
          else if (responseData.status === 203) {
            alert('링크페이 결제 내역이 아닙니다.');
            return;
          }
          else if (responseData.status === 204) {
            alert('결제 데이터가 존재하지 않습니다.');
            return;
          }
          else if (responseData.status === 205) {
            alert('이미 환불이 완료되었습니다.');
            return;
          }
          else if (responseData.status === 206) {
            alert('환불 가능 금액을 초과하였습니다.');
            return;
          }
          else if (responseData.status === 401) history.push('/');
          else {
            alert(responseData.error);
            return;
          }
        }
      )();
    }
    else {
      if (parseInt(refundCardPrice) + parseInt(refundCashPrice) === 0) {
        alert("환불금액을 입력해주세요");
        return;
      } 

      let requestData = {
        merchantUid: refundData.merchantUid,
        cardPrice: refundCardPrice,
        cashPrice: refundCashPrice,
        reason: reason,
        approvalDate: approvalDate.format('YYYY-MM-DDTHH:mm:ss')
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataVersion2('sales/refund/center', 'POST', requestData);
          
          if (responseData.status === 200) {
            rendering();
            exitClick();
          }
          else if (responseData.status === 204) {
            alert("환불 가능 금액을 초과하였습니다.");
          }
          else if (responseData.status === 401) history.push('/');
        }
      )();
    }
  }


  function onClickApprovalDate() {
  }

  
  function onChangeApprovalDate(data) {
    if (!isCenterRefund) {
      alert('링크페이 결제내역은 환불일을 변경할 수 없습니다.');
      return;
    }

    setApprovalDate(data);
  }


  return (
    <TopLevelWrapper>
      <ModalView>
        <TextWrapper topMagin={28}>
          {/* {
            isCenterRefund ?
              <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#232323'>센터에서 직접 환불하기</Text>
                :
              <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#232323'>링크 환불 요청하기</Text>
          } */}
        <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#232323'>회원권 환불하기</Text>
        </TextWrapper>
        <TextWrapper topMagin={16}>
          <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>선택한 결제 내역에 대한 환불을 진행하며</Text>
        </TextWrapper>
        <TextWrapper topMagin={14}>
          <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>되돌릴 수 없습니다. 환불을 계속 하시겠습니까?</Text>
        </TextWrapper>
        {
          // isCenterRefund ?
            <ComponentWrapper>
              <CalenderWrapper>
                <Text style={{ marginRight: '45px' }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>환불일</Text>
                <CalenderInput width={209} value={approvalDate} setValue={onChangeApprovalDate} className='' clickFn={onClickApprovalDate}/>
              </CalenderWrapper>
              <TextWrapper topMagin={13}>
                <PriceInput division='카드' value={refundCardPrice} setValue={setRefundCardPrice} />
              </TextWrapper>
              <TextWrapper topMagin={13}>
                <PriceInput division='현금' value={refundCashPrice} setValue={setRefundCashPrice} />
              </TextWrapper>
              <TextWrapper topMagin={13}>
                <RefundReasonInput value={reason} setValue={setReason}/>
              </TextWrapper>
              <CenterTextWrapper>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>총</Text>
                <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FF3131' style={{ marginLeft: `4px` }}>{(parseInt(refundCashPrice) + parseInt(refundCardPrice)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원 </Text>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>을 환급합니다.</Text>
              </CenterTextWrapper>
            </ComponentWrapper>
            // :
            // <ComponentWrapper>
            //   <TextWrapper topMagin={27}>
            //     <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>이 회원은</Text>
            //   </TextWrapper>
            //   <TextWrapper topMagin={14}>
            //     <LayerWrapper>
            //       <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>지금 환불 요청하면 </Text>
            //       <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#595959'> {refundPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원 </Text>
            //       <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'> 환급 가능합니다.</Text>
            //     </LayerWrapper>
            //     <RefundRuleButton onClick={() => history.push('/rule/refund')}>[환불 규정 확인하기]</RefundRuleButton>
            //   </TextWrapper>
            // </ComponentWrapper>
        }
        <ButtonWrapper isCenterRefund={isCenterRefund}>
          <ExitButton onClick={exitClick}>취소</ExitButton>
          <SaveButton onClick={onClickRefund}>확인</SaveButton>
        </ButtonWrapper>
      </ModalView>
    </TopLevelWrapper>
  )
}

export default RefundModal;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 1000;
  
  width: 100vw;
  height: 100vh;

  overflow-y: scroll;

  background-color: rgba(0, 0, 0, 0.2);
`;

const ModalView = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 371px;
  // height: 343px;
  height: 518px;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(0, 0, 0, 0.1);
  background-color: #FFFFFF;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  // margin-top: ${props => props.isCenterRefund ? `28px` : `37px`};
  margin-top: 28px;
  gap: 16px;

  width: 314px;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 72px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;
  
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #777777;
    color: #777777;
  }
`;

const SaveButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 15px;
  background-color: #5BD98A;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }
`;

const CenterTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  width: 100%;

  margin-top: 13px;
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  margin-top: ${props => props.topMagin}px;

  width: 314px;
`;

const CalenderWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  margin-top: 21px;

  width: 315px;
`;

// const SelectBox = styled.div`
//   display: flex;
//   flex-direction: column;

//   justify-content: center;
//   align-items: center;

//   width: 16px;
//   height: 16px;

//   bor
// `;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const RefundRuleButton = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 14px;

  color: red;

  margin: 0 0 0 160px;

  :hover {
    cursor: pointer;
  }
`;