import { React, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

import UserNameChangeInput from '../../../../../Component/MemberComponent/MemberDetail/UserNameChangeInput.js';
import CalenderInput from '../../../../../Component/MemberComponent/CalenderInput.js';
import moment from 'moment';
import PhoneChangeInput from '../../../../../Component/MemberComponent/MemberDetail/PhoneInput.js';
import FetchModule from '../../../../Share/Network/FetchModule.js';

function UserNameModifyModal(props) {
  const { userName, birthDay, gender, phone, onClickClose, onClickSave } = props;

  const [date, setDate] = useState(moment(birthDay).format('YYYY.MM.DD'));
  const [changedName, setChangedName] = useState(userName);
  const [changeGender, setChangeGender] = useState(gender);
  
  const [isCheckedPhone, setIsCheckedPhone] = useState(false);
  const [originalPhone, setOriginalPhone] = useState(phone);
  const [changePhone, setChangePhone] = useState(phone);

  // 모달 활성화 시 전체 스크롤 제어 //
  useEffect(() => {
    document.body.style= `overflow: hidden`;
    return () => document.body.style = `overflow: auto`
  }, [])

  // Do Nothing //
  function clickFn() {}

  // 저장 버튼 클릭시 //
  function onClickSaveButton() {
    // if (changePhone !== originalPhone && !isCheckedPhone) {
    //   alert('변경된 휴대폰의 가입자를 확인해주세요!');
    //   return;
    // }

    onClickSave(changedName, moment(date).format('YYYYMMDD'), changeGender, changePhone);
  }

  // 전화번호 확인 버튼 클릭시 //
  function onClickCheckButton() {

    if (changePhone === originalPhone) return;
    else if (isCheckedPhone) return;
    
    if (changePhone.length !== 11) {
      alert('휴대폰 번호를 정확히 입력해주세요');
      return;
    }
    // else if (coachId === '') {
    //   alert('링크코치 아이디를 정확히 입력해주세요');
    //   return;
    // }

    var data = {
      phone: changePhone,
      userId: '',
      profile: ''
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataOfCoach('userInfo/detail/invalid/user', 'POST', data);
      //  console.log(responseData);
        if (responseData.status === 200) {
          // ProfileRef.current.setImgBase64(responseData.data[0].profile);
          // IdRef.current.setValue(responseData.data[0].userId);
          alert('가입자 확인이 완료되었습니다');
          setChangePhone(responseData.data[0].phone);
          setIsCheckedPhone(true);
        }
        else if (responseData.status === 201) {
          alert('회원정보가 일치하지 않습니다.');
        }
        else if (responseData.status === 204) {
          alert('회원정보가 존재하지 않습니다.');
        }
      }
    )();
  }

  return (
    <TopLevelWrapper>
      <ModalView>
        <TotalWrapper>
          <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#232323'>회원 정보 변경</Text>
          <InputWrapper>
            <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>변경할 회원 명</Text>
            <UserNameChangeInput value={changedName} setValue={setChangedName} placeholder='회원 명을 입력해 주세요'/>
          </InputWrapper>
          <InputWrapper>
            <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>변경할 휴대폰 번호</Text>
            <PhoneChangeInput 
              value={changePhone} 
              setValue={setChangePhone} 
              isCheckedPhone={isCheckedPhone}
              setIsCheckedPhone={setIsCheckedPhone}
              originalPhone={originalPhone}
              placeholder='전화번호를 입력해 주세요' 
              onClickCheck={onClickCheckButton}/>
          </InputWrapper>
          <DateInputWrapper>
            <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>변경할 회원 생년월일</Text>
            <CalenderInput value={date} setValue={setDate} clickFn={clickFn} width={208}/>
          </DateInputWrapper>
          <InputWrapper>
            <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>변경할 회원 성별</Text>
            <GenderBoxWrapper>
              <GenderBox isClicked={changeGender === 'male'} onClick={() => setChangeGender('male')}>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color={ changeGender === 'male' ? '#FFFFFF' : '#CBCBCB'}>남성</Text>
              </GenderBox>
              <GenderBox isClicked={changeGender === 'female'} onClick={() => setChangeGender('female')}>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color={ changeGender === 'female' ? '#FFFFFF' : '#CBCBCB'}>여성</Text>
              </GenderBox>
            </GenderBoxWrapper>
          </InputWrapper>
        <ButtonWrapper>
          <ExitButton onClick={onClickClose}>취소</ExitButton>
          <SaveButton onClick={onClickSaveButton}>저장하기</SaveButton>
        </ButtonWrapper>
        </TotalWrapper>
      </ModalView>
    </TopLevelWrapper>
  )
}

export default UserNameModifyModal;

const startAnimation = keyframes`
	0% { opacity: 0; }
	50% { opacity: 0.5; }
	100% { opacity: 1; }
`;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 1000;
  
  width: 100vw;
  height: 100vh;

  overflow-y: scroll;

  background-color: rgba(0, 0, 0, 0.2);

	animation-name: ${startAnimation};
	animation-duration: 0.5s;
	animation-timing-function: ease-out;
`;

const ModalView = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 266px;
  // height: 228px;
  // height: 362px;
  height: 436px;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(0, 0, 0, 0.1);
  background-color: #FFFFFF;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 78.94%;
`;

const GenderBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const GenderBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: calc(47.61% - 2px);
  height: 30px;

  border-radius: 8px;
  border: ${props => props.isClicked ? '1px solid #FF8B48' : '1px solid #DFDFDF'};
  background-color: ${props => props.isClicked ? '#FF8B48' : '#FFFFFF'};

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 16px;

  width: 100%;

  gap: 8px;
`;

const DateInputWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 16px;

  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  margin-top: 36px;
  gap: 16px;

  width: 100%;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 72px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;
  
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #777777;
    color: #777777;
  }
`;

const SaveButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 15px;
  background-color: #5BD98A;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }
`;

const HoverText = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;
