import { React } from 'react';
import styled from 'styled-components';

import DietInfo from './DietInfo.js';
import BodyInfo from './BodyInfo.js';

const CoachHistoryView = (props) => {
  const { physicalData } = props;
  return (
    <TopLevelWrapper>
      <DietInfo/>
      <BodyInfo physicalData={physicalData}/>
    </TopLevelWrapper>
  )
}

export default CoachHistoryView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  gap: 30px;

  margin-top: 34px;
`;