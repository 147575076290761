import { React, useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';

import FetchModule from '../../Share/Network/FetchModule';

import LeftArrow from '../../../image/Left.png'//왼쪽 화살표
import RightArrow from '../../../image/Right.png'//우측 화살표
import ScheduleBox from './ScheduleBox';
import ExerciseBox from './ExerciseBox';
import ExerciseSaveModal from './ExerciseSaveModal';
import ExerciseDeleteModal from './ExerciseDeleteModal';
import NewExerciseBox from './NewExerciseBox';

function ProgramView() {
  const history = useHistory();

  const [data, setData] = useState([]);
  const [date, setDate] = useState(moment());
  const [rendering, setRendering] = useState(false);
  // const [selectedScheduleData, setSelectedScheduleData] = useState(undefined);
  const [workoutId, setWorkoutId] = useState(0);
  const [buttonText, setButtonText] = useState('');
  const [isClickedModify, setIsClickedModify] = useState(false);
  const [isViewConfirm, setIsViewConfirm] = useState(false);
  const [isClickedDelete, setIsClickedDelete] = useState(false);

  const [selectedLesson, setSelectedLesson] = useState(undefined);
  const [clickDate, setClickDate] = useState(date.clone().format('YYYY.MM.DD'));
  const [clickWorkoutIndex, setClickWorkoutIndex] = useState(0);
  const [tmpClickDate, setTmpClickDate] = useState(undefined);
  const [dateList, setDateList] = useState([]);
  const [useData, setUseData] = useState([{
    classDate: moment(),
    workouts: [{
      workoutId: 0,
      scheduleId: 0,
      storeId: parseInt(window.localStorage.getItem('StoreId')),
      classDate: moment(),
      title: '작성이 필요합니다.',
      sectionList: []
    }]
  }]);

  // const [batchSaveData, setBatchSaveData] = useState([]);

  const firstWeek = date.clone().startOf('month').week();
  const lastWeek = date.clone().endOf('month').week() === 1 ? 53 : date.clone().endOf('month').week();

  const TopLevelRef = useRef();
  const ExerciseRef = useRef();
  const ClassSelectRef = useRef();
  const ScheduleRef = useRef();

  // workout Data에 따라 버튼 텍스트 변경 //
  useEffect(() => {

    for (var i = 0; i < useData.length; i++) {
      if (useData[i].classDate.format('YYYY.MM.DD') === clickDate) {
        if (isClickedModify) setButtonText('저장하기');
        else {
          if (useData[i].workouts[clickWorkoutIndex] !== undefined && useData[i].workouts[clickWorkoutIndex].workoutId === 0) setButtonText('추가하기');
          else setButtonText('수정하기');
        }
        break;
      }
    }
  }, [isClickedModify, clickDate, useData, clickWorkoutIndex])

  // 날짜 데이터 세팅 //
  useEffect(() => {
    let newDateList = [];

    for (let week = firstWeek; week <= lastWeek; week++) {
      for (let i = 0; i < 7; i++) {
        let days = date.clone().startOf('year').week(week).startOf('week').add(i, 'day');

        if (days.format('MM') === date.format('MM')) newDateList.push(days);
      }
    }
    
    setDateList(newDateList);
  }, [date, firstWeek, lastWeek])

  // 업체의 운동 데이터 총 소환 //
  useEffect(() => {
    let data = {
      month: date.clone().format('MM'),
      year: date.clone().format('YYYY')
    }
    
    const fetchModule = new FetchModule();
    (
      async () => {
        let responseData = await fetchModule.postDataOfExerciseVersion2('workout/', 'POST', data);
        // console.log(responseData.data);
        if (responseData.status === 200) {
          let workoutData = responseData.data.slice();
          
          // 운동 데이터 정리 //
          for (let i = 0; i < workoutData.length; i++) {

            workoutData[i].sectionList.sort(function (a, b) {
              return a.sectionIndex < b.sectionIndex ? -1 : a.sectionIndex > b.sectionIndex ? 1 : 0;
            })

            for (let j = 0; j < workoutData[i].sectionList.length; j++) {
              workoutData[i].sectionList[j].buyinExerciseList = [];
              workoutData[i].sectionList[j].buyinScaleOptionExerciseList = [];
              workoutData[i].sectionList[j].mainExerciseList = [];
              workoutData[i].sectionList[j].mainScaleOptionExerciseList = [];
              workoutData[i].sectionList[j].emomExerciseList = [];
              workoutData[i].sectionList[j].emomScaleOptionExerciseList = [];
              workoutData[i].sectionList[j].buyoutExerciseList = [];
              workoutData[i].sectionList[j].buyoutScaleOptionExerciseList = [];
              workoutData[i].sectionList[j].reps = workoutData[i].sectionList[j].reps.split('-');

              for (let h = 0; h < workoutData[i].sectionList[j].exerciseList.length; h++) {
                
                workoutData[i].sectionList[j].exerciseList[h].movementId = workoutData[i].sectionList[j].exerciseList[h].movement.movementId;
                workoutData[i].sectionList[j].exerciseList[h].movementName = workoutData[i].sectionList[j].exerciseList[h].movement.movementName;
                workoutData[i].sectionList[j].exerciseList[h].videoLinkId = workoutData[i].sectionList[j].exerciseList[h].movement.videoLinkId;

                // 무게 구분 데이터 사용할 경우 //
                if (workoutData[i].sectionList[j].exerciseList[h].divisionWeight !== ''
                  && workoutData[i].sectionList[j].exerciseList[h].divisionWeight !== 'None') {
                  workoutData[i].sectionList[j].exerciseList[h].weight = workoutData[i].sectionList[j].exerciseList[h].divisionWeight;
                }
                // 거리 구분 데이터 사용할 경우 //
                if (workoutData[i].sectionList[j].exerciseList[h].divisionDistance !== ''
                  && workoutData[i].sectionList[j].exerciseList[h].divisionDistance !== 'None') {
                  workoutData[i].sectionList[j].exerciseList[h].distance = workoutData[i].sectionList[j].exerciseList[h].divisionDistance;
                }
                // 높이 구분 데이터 사용할 경우 //
                if (workoutData[i].sectionList[j].exerciseList[h].divisionHeight !== ''
                  && workoutData[i].sectionList[j].exerciseList[h].divisionHeight !== 'None') {
                  workoutData[i].sectionList[j].exerciseList[h].height = workoutData[i].sectionList[j].exerciseList[h].divisionHeight;
                }
                // 칼로리 구분 데이터 사용할 경우 //
                if (workoutData[i].sectionList[j].exerciseList[h].divisionCalories !== ''
                  && workoutData[i].sectionList[j].exerciseList[h].divisionCalories !== 'None') {
                    workoutData[i].sectionList[j].exerciseList[h].calories = workoutData[i].sectionList[j].exerciseList[h].divisionCalories;
                }
                // 템포 구분 데이터 사용할 경우 //
                if (workoutData[i].sectionList[j].exerciseList[h].divisionTempo !== ''
                  && workoutData[i].sectionList[j].exerciseList[h].divisionTempo !== 'None') {
                    workoutData[i].sectionList[j].exerciseList[h].tempo = workoutData[i].sectionList[j].exerciseList[h].divisionTempo;
                }
                // Sets 구분 데이터 사용할 경우 //
                if (workoutData[i].sectionList[j].exerciseList[h].divisionSets !== '' 
                  && workoutData[i].sectionList[j].exerciseList[h].divisionSets !== 'None') {
                    workoutData[i].sectionList[j].exerciseList[h].sets = workoutData[i].sectionList[j].exerciseList[h].divisionSets;
                }
                // Rest 구분 데이터 사용할 경우 //
                if (workoutData[i].sectionList[j].exerciseList[h].divisionRest !== '' 
                  && workoutData[i].sectionList[j].exerciseList[h].divisionRest !== 'None') {
                    workoutData[i].sectionList[j].exerciseList[h].rest = workoutData[i].sectionList[j].exerciseList[h].divisionRest;
                }

                if (workoutData[i].sectionList[j].exerciseList[h].division === 'Buyin') {
                  workoutData[i].sectionList[j].buyinExerciseList.push(workoutData[i].sectionList[j].exerciseList[h]);
                }
                else if (workoutData[i].sectionList[j].exerciseList[h].division === 'Buyin.Scale') {
                  workoutData[i].sectionList[j].buyinScaleOptionExerciseList.push(workoutData[i].sectionList[j].exerciseList[h]);
                }
                else if (workoutData[i].sectionList[j].exerciseList[h].division === 'Main') {
                  workoutData[i].sectionList[j].mainExerciseList.push(workoutData[i].sectionList[j].exerciseList[h]);
                }
                else if (workoutData[i].sectionList[j].exerciseList[h].division === 'Main.Scale'){
                  workoutData[i].sectionList[j].mainScaleOptionExerciseList.push(workoutData[i].sectionList[j].exerciseList[h]);
                }
                else if (workoutData[i].sectionList[j].exerciseList[h].division === 'Main.Emom') {
                  workoutData[i].sectionList[j].emomExerciseList.push(workoutData[i].sectionList[j].exerciseList[h]);
                }
                else if (workoutData[i].sectionList[j].exerciseList[h].division === 'Main.Emom.Scale'){
                  workoutData[i].sectionList[j].emomScaleOptionExerciseList.push(workoutData[i].sectionList[j].exerciseList[h]);
                }
                else if (workoutData[i].sectionList[j].exerciseList[h].division === 'Buyout') {
                  workoutData[i].sectionList[j].buyoutExerciseList.push(workoutData[i].sectionList[j].exerciseList[h]);
                }
                else if (workoutData[i].sectionList[j].exerciseList[h].division === 'Buyout.Scale'){
                  workoutData[i].sectionList[j].buyoutScaleOptionExerciseList.push(workoutData[i].sectionList[j].exerciseList[h]);
                }
              }

              // exerciseIndex.asc 진행 //
              workoutData[i].sectionList[j].buyinExerciseList.sort(function (a, b) {
                return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
              })
              // exerciseIndex.asc 진행 //
              workoutData[i].sectionList[j].buyinScaleOptionExerciseList.sort(function (a, b) {
                return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
              })
              // exerciseIndex.asc 진행 //
              workoutData[i].sectionList[j].mainExerciseList.sort(function (a, b) {
                return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
              })
              // exerciseIndex.asc 진행 //
              workoutData[i].sectionList[j].mainScaleOptionExerciseList.sort(function (a, b) {
                return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
              })
              // exerciseIndex.asc 진행 //
              workoutData[i].sectionList[j].emomExerciseList.sort(function (a, b) {
                return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
              })
              // exerciseIndex.asc 진행 //
              workoutData[i].sectionList[j].emomScaleOptionExerciseList.sort(function (a, b) {
                return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
              })
              // exerciseIndex.asc 진행 //
              workoutData[i].sectionList[j].buyoutExerciseList.sort(function (a, b) {
                return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
              })
              // exerciseIndex.asc 진행 //
              workoutData[i].sectionList[j].buyoutScaleOptionExerciseList.sort(function (a, b) {
                return a.exerciseIndex < b.exerciseIndex ? -1 : a.exerciseIndex > b.exerciseIndex ? 1 : 0;
              })
            }
          }
          
          setData(workoutData);
        }
        else if (responseData.status === 204) setData([]);
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }, [history, date, rendering])

  useEffect(() => {
    if (dateList.length === 0) return;
    
    let workoutData = data.slice();
    let newData = [];
    let startValue = 0;

    // 데이터가 없으면 1개 넣고 시작 //
    if (workoutData.length === 0) {

      let tmpData = {
        workoutId: 0,
        scheduleId: 0,
        storeId: parseInt(window.localStorage.getItem('StoreId')),
        classDate: dateList[0],
        title: '',
        lessonName: '',
        sectionList: []
      }

      let tempDateData = {
        isNone: true,
        classDate: dateList[0],
        workouts: [tmpData]
      }

      // newDataList.push(tmpData);
      startValue = 1;
      newData.push(tempDateData);
    }

    for (let i = startValue; i < dateList.length; i++) {
       
      let isNone = true;

      let tempDateData = {
        isNone: false,
        classDate: dateList[i],
        workouts: []
      }

      for (let j = 0; j < workoutData.length; j++) {
        if (dateList[i].format('MM.DD') === moment(workoutData[j].classDate).format('MM.DD')) {
          isNone = false;

          workoutData[j].classDate = moment(workoutData[j].classDate);
          tempDateData.workouts.push(workoutData[j]);
        }
      }

      if (isNone) {
        let tmpEmptyData = {
          workoutId: 0,
          scheduleId: 0,
          storeId: parseInt(window.localStorage.getItem('StoreId')),
          classDate: dateList[i],
          title: '',
          lessonName: '',
          sectionList: []
        }
        
        let tempEmptyDateData = {
          isNone: true,
          classDate: dateList[i],
          workouts: [tmpEmptyData]
        }

        newData.push(tempEmptyDateData);
      }
      else {
        // 마지막 운동 추가용 데이터 삽입 //
        let tmpEmptyData = {
          workoutId: 0,
          scheduleId: 0,
          storeId: parseInt(window.localStorage.getItem('StoreId')),
          classDate: dateList[i],
          title: '',
          lessonName: '',
          sectionList: []
        }
        
        tempDateData.workouts.push(tmpEmptyData);
        newData.push(tempDateData);
      }
    }

    newData.sort(function (a, b) {
      return a.classDate < b.classDate ? -1 : a.classDate > b.classDate ? 1 : 0;
    })
    
    setUseData(newData);
  }, [dateList, data])
  
  // 저장 모달에 띄울 수업 이름 저장 //
  useEffect(() => {
    setWorkoutId(ExerciseRef.current.getWorkoutId());
  }, [isViewConfirm, ClassSelectRef, ExerciseRef])

  // 현재 클릭된 Workout Id, Lesson Name 추출 //
  useEffect(() => {
    var tmpData = useData.slice();

    for (var i = 0; i < tmpData.length; i++) {
      if (tmpData[i].classDate.clone().format('YYYY.MM.DD') === clickDate) {
        if (tmpData[i].workouts[clickWorkoutIndex] === undefined) setWorkoutId(0);
        else setWorkoutId(tmpData[i].workouts[clickWorkoutIndex].workoutId);
      }
    }
  }, [useData, clickDate, clickWorkoutIndex])

  // 일정표내의 날짜 클릭시 //
  function dateClick(value, workoutIndex) {
    // 운동 내용 변경중인지 확인 //
    if (isClickedModify) {
      let exerciseData = ExerciseRef.current.getValue();
      
      if (exerciseData.length === 0) {
        setClickDate(value.classDate.format('YYYY.MM.DD'));
        setIsClickedModify(true);
      }
      else {
        // 데이터가 1개만 있을경우 자동생성 데이터인지 확인 //
        if (exerciseData.length === 1) {
          if (exerciseData[0].buyinExerciseList.length === 0
            && exerciseData[0].mainExerciseList.length === 0
            && exerciseData[0].buyoutExerciseList.length === 0
            && exerciseData[0].buyinNote === ''
            && exerciseData[0].mainNote === ''
            && exerciseData[0].buyoutNote === ''
            && exerciseData[0].customTitle === ''
            && exerciseData[0].description === '') {
              setClickDate(value.classDate.format('YYYY.MM.DD'));

              if (workoutIndex === undefined) setIsClickedModify(false);
              else setIsClickedModify(true);
            }
          else {
            setTmpClickDate(value.classDate.format('YYYY.MM.DD'));
            setIsViewConfirm(true);
          }
        }
        else {
          setTmpClickDate(value.classDate.format('YYYY.MM.DD'));
          setIsViewConfirm(true);
        }
      }
    }
    else {
      setClickDate(value.classDate.format('YYYY.MM.DD'));

      if (workoutIndex === undefined) setIsClickedModify(false);
      else setIsClickedModify(true);
    }
  }

  // 월 단위 날짜 변경 클릭시 //
  function onClickDateArrow(division) {
    if (isClickedModify) {
      let exerciseData = ExerciseRef.current.getValue();

      if (exerciseData.length === 0) {
        if (division === 'Prev') {
          setClickDate(date.clone().subtract(1, 'month').format('YYYY.MM.DD'));
          setDate(date.clone().subtract(1, 'month'));
        }
        else if (division === 'Next') {
          setClickDate(date.clone().add(1, 'month').format('YYYY.MM.DD'));
          setDate(date.clone().add(1, 'month'));
        }
        
        setIsClickedModify(false);
      }
      else {
        // 데이터가 1개만 있을경우 자동생성 데이터인지 확인 //
        if (exerciseData.length === 1) {
          if (exerciseData[0].buyinExerciseList.length === 0
            && exerciseData[0].mainExerciseList.length === 0
            && exerciseData[0].buyoutExerciseList.length === 0
            && exerciseData[0].buyinNote === ''
            && exerciseData[0].mainNote === ''
            && exerciseData[0].buyoutNote === ''
            && exerciseData[0].customTitle === ''
            && exerciseData[0].description === '') {
              if (division === 'Prev') {
                setClickDate(date.clone().subtract(1, 'month').format('YYYY.MM.DD'));
                setDate(date.clone().subtract(1, 'month'));
              }
              else if (division === 'Next') {
                setClickDate(date.clone().add(1, 'month').format('YYYY.MM.DD'));
                setDate(date.clone().add(1, 'month'));
              }

              setIsClickedModify(false);
            }
          else {
            if (division === 'Prev') setTmpClickDate(date.clone().subtract(1, 'month').format('YYYY.MM.DD'));
            else if (division === 'Next') setTmpClickDate(date.clone().add(1, 'month').format('YYYY.MM.DD'));
            setIsViewConfirm(true);
          }
        }
        else {
          if (division === 'Prev') setTmpClickDate(date.clone().subtract(1, 'month').format('YYYY.MM.DD'));
          else if (division === 'Next') setTmpClickDate(date.clone().add(1, 'month').format('YYYY.MM.DD'));
          setIsViewConfirm(true);
        }
      }
    }
    else {
        if (division === 'Prev') {
          setClickDate(date.clone().subtract(1, 'month').format('YYYY.MM.DD'));
          setDate(date.clone().subtract(1, 'month'));
        }
        else if (division === 'Next') {
          setClickDate(date.clone().add(1, 'month').format('YYYY.MM.DD'));
          setDate(date.clone().add(1, 'month'));
        }
    }
  }

  // 스크롤 애니메이션를 위한 UI제어 //
  function changeTopLevelWrapperPosition() {
    if (TopLevelRef.current.style.position === 'fixed') {
      TopLevelRef.current.style.position = 'relative';
    }
    else {
      TopLevelRef.current.style.position = 'fixed';
      TopLevelRef.current.style.top = '0px';
    }
  }

  // 수정하기 클릭시 //
  function onClickModifyButton() {
    if (isClickedModify) setIsViewConfirm(true);
    else setIsClickedModify(true);
  }

  // 수정 저장하기 클릭시 //
  function onClickConfirmYes() {
    let storeId = ExerciseRef.current.getStoreId();
    let title = ExerciseRef.current.getTotalTitle();
    let exerciseData = ExerciseRef.current.getValue();
    let workoutId = ExerciseRef.current.getWorkoutId();
    let isLock = ExerciseRef.current.getIsLock();

    // 운동 타이틀 작성 여부 확인 //
    if (title === '작성이 필요합니다.' || title === '') {
      title = clickDate;
    }
    else if (exerciseData.length === 0) {
      alert('디자인된 운동이 없습니다!');
      setIsViewConfirm(false);
      return;
    }

    for (let i = 0; i < exerciseData.length; i++) {
      // Buy in 운동 디자인 여부 확인 //
      if (exerciseData[i].isUseBuyin && exerciseData[i].buyinExerciseList.length === 0) {
        alert('Buy in 운동 디자인을 마무리해주세요!\nBuy in을 사용하지 않을 경우 체크를 해제해주세요!');
        setIsViewConfirm(false);
        return;
      }
      // Buy out 운동 디자인 여부 확인 //
      else if (exerciseData[i].isUseBuyout && exerciseData[i].buyoutExerciseList.length === 0) {
        alert('Buy out 운동 디자인을 마무리해주세요!\nBuy out을 사용하지 않을 경우 체크를 해제해주세요!');
        setIsViewConfirm(false);
        return;
      }
      // Main 운동 디자인 여부 확인 //
      else if (exerciseData[i].mainExerciseList.length === 0 && exerciseData[i].tag !== 'Custom') {
        alert('메인 운동 디자인을 마무리해주세요!');
        setIsViewConfirm(false);
        return;
      }
      // 섹션별 YoutubeLink 유효성 확인 //
      if (exerciseData[i].youtubeLink !== '') {
        let url = exerciseData[i].youtubeLink;
        let isFail = false;
  
        if (!url.includes('https://youtu.be/') && !url.includes('https://www.youtube.com/watch?v=')) {
          isFail = true;
        }
        else if (url.includes('https://youtu.be/')
          && (url.split('https://youtu.be/')[0] !== '' || url.split('https://youtu.be/')[1] === '')) {
          isFail = true;
        }
        else if (url.includes('https://www.youtube.com/watch?v=')
          && (url.split('https://www.youtube.com/watch?v=')[0] !== '' || url.split('https://www.youtube.com/watch?v=')[1] === '')) {
          isFail = true;
        }

        if (isFail) {
          alert("올바른 Youtube 링크를 입력해주세요");
          return;
        }
      }
    }
    
    let requestData = {
      workoutId: workoutId === 0 ? undefined : workoutId,
      lessonId: selectedLesson.id,
      scheduleId: 0,
      lessonName: selectedLesson.name,
      classDate: clickDate.replaceAll('.', '-') + 'T00:00:00',
      storeId: storeId,
      title: title,
      sectionList: exerciseData,
      isLock: isLock
    }

    // console.log(requestData);
    const fetchModule = new FetchModule();
    (
      async () => {
        let responseData = await fetchModule.postDataOfExerciseVersion2('workout/save', 'POST', requestData);

        if (responseData.status === 200) {
          setRendering(!rendering);
        }
      }
    )();

    setIsViewConfirm(false);
    setIsClickedModify(false);
  }

  // 삭제버튼 클릭시 //
  function onClickDeleteWorkoutButton() {
    setIsClickedDelete(true);
  }

  // 삭제 버튼 ( 스케줄 박스 내에 있는 ) 클릭시 //
  function onClickDeleteInSchedule(data) {
    setWorkoutId(data.workoutId);
    setIsClickedDelete(true);
  }

  // 삭제 확인 버튼 클릭시 //
  function onClickDelete() {
    if (workoutId === 0) {
      alert("유효한 데이터가 아닙니다.");
      setIsClickedDelete(false);

      return;
    }

    let requestData = {
      workoutId: workoutId
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        let responseData = await fetchModule.postDataOfExerciseVersion2('workout/delete', 'POST', requestData);
        
        if (responseData.status === 200) {
          setRendering(!rendering);
          setIsClickedDelete(false);
          return;
        }
        else if (responseData.status === 201) {
          alert('이미 삭제되었거나 존재하지 않는 데이터입니다.');
          setIsClickedDelete(false);
          return;
        }
        else if (responseData.status === 202) {
         alert('팔로우중인 운동 데이터는 삭제할 수 없습니다');
         setIsClickedDelete(false);
         return; 
        }
        else if (requestData.status === 401) {
          alert('올바르지 않은 권한의 접근입니다.');
          setIsClickedDelete(false);
          return;
        }
      }
    )();
  }

  // 무시하고 진행하기 클릭시 //
  function onClickIgnoreButton() {
    setIsClickedModify(false);

    if (tmpClickDate !== undefined) {
      setClickDate(tmpClickDate);
      setDate(moment(tmpClickDate));
      setTmpClickDate(undefined);
    }
    else setRendering(!rendering);
    
    setIsViewConfirm(false);
  }

  // 취소하기 클릭시 //
  function onClickExitButton() {
    setData([]);
    setIsClickedModify(false);

    setRendering(!rendering);
  }

  return (
    <TopLevelWrapper ref={TopLevelRef}>
      {
        isViewConfirm &&
        <ExerciseSaveModal
          yesFn={onClickConfirmYes}
          ignoreFn={onClickIgnoreButton}
          noFn={() => setIsViewConfirm(false)} />
      }
      {
        isClickedDelete &&
        <ExerciseDeleteModal
          yesFn={onClickDelete}
          noFn={() => setIsClickedDelete(false)} />
      }
      <TopBarWrapper>
        <TextWrapper>
          <Title>프로그램</Title>
          <Explanation>링크코치와 연동하여 회원 별 맞춤형 피트니스 프로그램을 계획할 수 있어요</Explanation>
        </TextWrapper>
      </TopBarWrapper>
      <TopButtonWrapper>
        <LeftWrapper>
          <DateButtonWrapper>
            <ArrowWrapper onClick={() => onClickDateArrow('Prev')}>
              <ArrowImage src={LeftArrow} />
            </ArrowWrapper>
            <DateText>{date.clone().format('YYYY년 MM월')}</DateText>
            <ArrowWrapper onClick={() => onClickDateArrow('Next')} >
              <ArrowImage src={RightArrow} />
            </ArrowWrapper>
          </DateButtonWrapper>
        </LeftWrapper>
        <ButtonWrapper>
          {(buttonText === '저장하기') && <ExitButton onClick={onClickExitButton}>취소하기</ExitButton>}
          {workoutId !== 0 && <DeleteButton onClick={onClickDeleteWorkoutButton}>삭제하기</DeleteButton>}
          {(buttonText !== '추가하기') && <ModifyButton isClickedModify={isClickedModify} onClick={onClickModifyButton}>{buttonText}</ModifyButton>}
        </ButtonWrapper>
      </TopButtonWrapper>
      <MainView>
        <LeftComponent>
          <ScheduleBox
            ref={ScheduleRef}
            clickDate={clickDate}
            clickWorkoutIndex={clickWorkoutIndex}
            setClickWorkoutIndex={setClickWorkoutIndex}
            useData={useData}
            dateClickFn={dateClick}
            onClickDeleteInSchedule={onClickDeleteInSchedule}
            onClickModifyButton={onClickModifyButton}
            changeTopLevelWrapperPosition={changeTopLevelWrapperPosition} />
        </LeftComponent>
        <NewExerciseBox
          ref={ExerciseRef}
          isClickedModify={isClickedModify}
          clickDate={clickDate}
          clickWorkoutIndex={clickWorkoutIndex}
          useData={useData}
          selectedLesson={selectedLesson}
          setSelectedLesson={setSelectedLesson}
          onClickModifyButton={onClickModifyButton}
          changeTopLevelWrapperPosition={changeTopLevelWrapperPosition} />
      </MainView>
    </TopLevelWrapper>
  )
}

export default ProgramView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  // margin-left: 34px;
  margin-left: 42px;
  
  transition: all 0.3s ease-in-out;
`;

const TopBarWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-left: 28px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: 0 0 0 0;
`;

const Explanation = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #777777;

  margin: 0 0 0 0;
`;

const TopButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;

  margin-top: 38px;
`;

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 30px;
`;

const ScheduleSelectBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 408px;

  gap: 16px;
`;

const DateButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 205px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;

  gap: 37px;
`;

const ArrowWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 22px;
  height: 22px;

  :hover { 
    cursor: pointer;
  }
`;

const ArrowImage = styled.img`
  display: flex;
  flex-direction: row;

  width: 9px;
  height: 15px;
`;

const DateText = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #595959;

  margin: 0 0 0 0;
`;

const MainView = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: flex-start;

  gap: 30px;
  margin-top: 16px;
  margin-bottom: 255px;
`;

const LeftComponent = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  gap: 30px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 16px;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 15px;
  background-color: #FF8B48;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;
  
  margin: 0 0 0 0;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #FF7322;
  }
`;

const DeleteButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 15px;
  background-color: #FF3131;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;
  
  margin: 0 0 0 0;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #DD0000;
  }
`;

const ModifyButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 15px;
  background-color: ${props => props.isClickedModify ? `#5BD98A` : `#FF8B48`};

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  margin: 0 0 0 0;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: ${props => props.isClickedModify ? `#2BCB67` : `#FF7322`};
  }
`;