import { React, useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';

export default function CoachAuthBox(props) {
  const { isMemberAuth, setIsMemberAuth, isExerciseAuth, setIsExerciseAuth, isSalesAuth, setIsSalesAuth } = props;

  return(
    <TopLevelWrapper>
      <MemberAuthButton isClicked={isMemberAuth} /*onClick={() => setIsMemberAuth(!isMemberAuth)}*/>
        <ButtonText isClicked={isMemberAuth}>회원 관리</ButtonText>
      </MemberAuthButton>
      <ExerciseAuthButton isClicked={isExerciseAuth} onClick={() => setIsExerciseAuth(!isExerciseAuth)}>
        <ButtonText isClicked={isExerciseAuth}>운동 관리</ButtonText>
      </ExerciseAuthButton>
      <SalesAuthButton isClicked={isSalesAuth} onClick={() => setIsSalesAuth(!isSalesAuth)}>
        <ButtonText isClicked={isSalesAuth}>매출 관리</ButtonText>
      </SalesAuthButton>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direcion: row;

  justify-content: space-between;
  align-items: center;

  gap: 8px;
  margin-top: 8px;

  width: 100%;
`;

const MemberAuthButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 105px;
  height: 30px;

  border: ${props => props.isClicked ? `1px solid #FF8B48` : `1px solid #DFDFDF`};
  border-radius: 8px;
  background-color: ${props => props.isClicked ? '#FF8B48' : '#FFFFFF'};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const ExerciseAuthButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 105px;
  height: 30px;

  border: ${props => props.isClicked ? `1px solid #6DD49E` : `1px solid #DFDFDF`};
  border-radius: 8px;
  background-color: ${props => props.isClicked ? '#6DD49E' : '#FFFFFF'};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const SalesAuthButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 105px;
  height: 30px;

  border: ${props => props.isClicked ? `1px solid #FFB800` : `1px solid #DFDFDF`};
  border-radius: 8px;
  background-color: ${props => props.isClicked ? '#FFB800' : '#FFFFFF'};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const ButtonText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: ${props => props.isClicked ? `#FFFFFF` : `#CBCBCB`};

  margin: 0 0 0 0;
  
  transition: all 0.3s ease-in-out;
`;