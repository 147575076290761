import { React, useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';

import moment from 'moment';

import PencelImg from '../../../../image/GrayPencel.png';
import WhiteTrashIcon from '../../../../image/GrayTrash.png';


export default function ProductUsageInfoView (props) {
  const { 
    data, 
    index, 
    size,
    onClickEditProductUsage,
    onClickDeleteProductUsage
  } = props;

  const [isClicked, setIsClicked] = useState(false);
  // const [buttonImage, setButtonImage] = useState(MemberShipHistoryInfoButtonImagePath);
  const [fontColor, setFontColor] = useState('#595959');
  const [border, setBorder] = useState('1px solid #FFFFFF');
  const [hoverBorder, setHoverBorder] = useState('1px solid #FF8B48');
  const [backgroundColor, setBackgroundColor] = useState('#FFFFFF');


  return (
    <TopLevelWrapper>
      <MemberShipComponent 
        zIndex={20 + size - index}
        border={border} 
        hoverBorder={hoverBorder} 
        backgroundColor={backgroundColor}>
      <Table>
        <Body>
          <Tr style={{ width: '428px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '410px' }}>
                <Text color={fontColor}>{data.itemName}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '91px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '62px' }}>
                {/* <Text>{data.totalPrice}</Text> */}
                <Text color={fontColor}>{(data.cardPrice + data.cashPrice).toLocaleString('ko-KR')}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '91px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '62px' }}>
                <Text color={fontColor}>{data.cardPrice.toLocaleString('ko-KR')}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '91px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '62px' }}>
                <Text color={fontColor}>{data.cashPrice.toLocaleString('ko-KR')}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '91px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '62px' }}>
                <Text color={fontColor}>{data.notReceivedPrice.toLocaleString('ko-KR')}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '93px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '93px' }}>
                <Text color={fontColor}>{data.approvalDate.slice(2, 10).replaceAll('-', '.')}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr>
            <Td>
              {/* <OptionButton ref={OptionButtonRef} src={buttonImage} onClick={() => setIsClicked(!isClicked)} /> */}
              <OptionButtonWrapper>
                <OptionButton src={PencelImg} onClick={() => onClickEditProductUsage(data)} />
                <OptionButton src={WhiteTrashIcon} onClick={() => onClickDeleteProductUsage(data)} />
              </OptionButtonWrapper>
            </Td>
          </Tr>
        </Body>
      </Table>
      </MemberShipComponent>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 1018px;

  // height: 
  // display: flex;
  // flex-direction: column;

  // justify-content: center;
  // align-items: flex-start;

  // width: 1018px;
  // height: 50px;

  // box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);

  // border: ${props => props.border};
  // border-radius: 30px;
  // background-color: ${props => props.backgroundColor};
    
  // transition: all 0.3s ease-in-out;

  // :hover {
  //   cursor: pointer;
  //   border: ${props => props.hoverBorder};
  // }
`;

const MemberShipComponent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: 1018px;
  height: 50px;

  // box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  box-shadow: 0px 10px 50px rgba(240, 232, 227, 0.5);

  z-index: ${props => props.zIndex};

  border: ${props => props.border};
  border-radius: 30px;
  background-color: ${props => props.backgroundColor};
    
  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: ${props => props.hoverBorder};
  }
`;

const Table = styled.table`
  display: flex;
  flex-direction: row;
`;

const Body = styled.tbody`
  display: flex;
  flex-direction: row;
    
  margin-left: 28px;
`;

const Tr = styled.tr`
  display: flex;
  flex-direction: row;
`;

const Td = styled.td`
  display: flex;
  flex-direction: row;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: flex-start;

  overflow: hidden;

  max-height: 20px;
`;

const Text = styled.span`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  padding: 0 0 0 0;
`;

const OptionButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 16px;

  // background-color: red;
`;
const OptionButton = styled.img`
  width: 24px;
  height: 24px;

  // opacity: 0;
    
  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

// const OptionWrapper = styled.div`
//   position: absolute;

//   display: flex;
//   flex-direction: column;

//   justify-content: center;
//   align-items: center;

//   overflow: hidden;
    
//   z-index: 10;

//   margin-top: 40px;
//   margin-left: -20px;

//   border-radius: 8px;

//   transition: all 0.2s ease-in-out;
// `;

// const Option = styled.div`
//   display: flex;
//   flex-direction: column;;

//   justify-content: center;
//   align-items: center;

//   width: 58px;
//   height: 38px;

//   margin: 0 0 0 0;

//   font-family: NotoSansKR-Regular;
//   font-size: 14px;

//   color: #FF8B48;
//   background-color: #FFFFFF;

//   transition: all 0.3s ease-in-out;

//   :hover {
//     cursor: pointer;
//     color: #FFFFFF;
//     background-color: #FF8B48;
//   }
// `;

// const ExpertComponentWrapper = styled.div`
//   position: relative;
//   display: flex;
//   flex-direction: column;

//   justify-content: center;
//   align-items: center;

//   width: 100%;

//   z-index: ${props => props.zIndex};
// `;