import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";

import WhiteSelectOffPath from '../../image/WhiteSelectOff.png';
import SelectOnPath from '../../image/SelectOn.png';
import SelectOffPath from '../../image/SelectOff.png';

export default function CustomScoreSelectBox(props) {
  const { value, setValue } = props;

  const [isClicked, setIsClicked] = useState(false);
  
  const TotalRef = useRef();
  const SelectListRef = useRef();

  const options = ['점수 없음', '시간', '렙수', '라운드 + 렙수', '거리', '무게', '포인트', '피크와트', '최고 속도'];

  // 컴포넌트 밖을 클릭하면 셀렉트 창 닫기
  useEffect(() => {
    document.addEventListener("mousedown", onClickOutSide);

    return () => {
      document.removeEventListener("mousedown", onClickOutSide);
    }
  }, [onClickOutSide])

  // 변수 영어로 변환시 보여줄 한글 텍스트 //
  function decideValue() {
    if (value === 'Not Scored') return '점수 없음';
    else if (value === 'Time') return '시간';
    else if (value === 'Reps') return '렙수';
    else if (value === 'Rounds And Reps') return '라운드 + 렙수';
    else if (value === 'Distance') return '거리';
    else if (value === 'Weight') return '무게';
    else if (value === 'Points') return '포인트';
    else if (value === 'Peak Watts') return '피크와트';
    else if (value === 'Max Speed') return '최고 속도';
    else return value;
  }

  // 옆에 띄워줄 영어 텍스트 결정 //
  function decideEnglishText() {
    if (value === '점수 없음' || value === 'Not Scored') return 'Not Scored';
    else if (value === '시간' || value === 'Time') return 'Time';
    else if (value === '렙수' || value === 'Reps') return 'Reps';
    else if (value === '라운드 + 렙수' || value === 'Rounds And Reps') return 'Rounds And Reps';
    else if (value === '거리' || value === 'Distance') return 'Distance';
    else if (value === '무게' || value === 'Weight') return 'Weight';
    else if (value === '포인트' || value === 'Points') return 'Points';
    else if (value === '피크와트' || value === 'Peak Watts') return 'Peak Watts';
    else if (value === '최고 속도' || value === 'Max Speed') return 'Max Speed';
  }

  // 컴포넌트 밖을 클릭하면 셀렉트 창 닫기
  function onClickOutSide(e) {
    if (TotalRef.current !== e.target && !TotalRef.current.contains(e.target)) {
      setIsClicked(false);
    }
  }

  // 셀렉트 박스 클릭시 //
  function onClickSelectBox() {
		if (isClicked) {
			setIsClicked(false);
		}
		else {
			setIsClicked(true);
		}
  }

  // 수업 선택시 //
  function onClickSelectList(data) {
    setValue(data);

    setIsClicked(false);
  }

  return (
    <SelectBoxWrapper ref={TotalRef}>
      <SeletedBox onClick={onClickSelectBox}>
          <SelectBoxTextWrapper>
            <Text fontFamily='Poppins-Medium' fontSize={14} color='#000000' hover='pointer'>{decideValue()}</Text>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='rgba(79, 79, 79, 0.20)' hover='pointer'>{decideEnglishText()}</Text>
          </SelectBoxTextWrapper>
        <ArrowImage src={SelectOffPath} isClicked={isClicked}/>
      </SeletedBox>
      <WillSelectList ref={SelectListRef} isClicked={isClicked}>
        {
          options.map((data, index) => (
            <SelectListTextWrapper 
              key={'CustomTag-' + index}
              isClicked={isClicked}
              onClick={() => onClickSelectList(data)}>
              {data}
            </SelectListTextWrapper>
          ))
        }
      </WillSelectList>
    </SelectBoxWrapper>
  )
}

const SelectBoxWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  height: 100%;
`;

const SeletedBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding: 0px 15px;

  width: calc(100% - 32px);
  height: calc(100% - 2px);

  border: 1px solid #CBCBCB;
  border-radius: 30px;
  background-color: #FFFFFF;

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const ArrowImage = styled.img`
  width: 24px;
  height: 24px;

  transform: ${props => props.isClicked ? `rotate(-180deg)` : `rotate(0deg)`};

  transition: all 0.3s ease-in-out;
`;

const WillSelectList = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 37px;

  width: 100%;
  height: ${props => props.isClicked ? `300px` : `0px`};

  border: ${props => props.isClicked ? `1px solid #FF8B48` : `0px`};
  border-radius: 6px;


	overflow: hidden;

	:hover {
		cursor: pointer;
	}

	&::-webkit-scrollbar {
		// width: 9px;
		width: 0px;
	}

	&::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 20px #FF8B48;
		border: 3px solid transparent;
		height: 25px;
		// background-color: #FF8B48;
		border-radius: 200px;
	}

	&::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px transparent;
		border: 4px solid transparent;
		background-color: transparent;
	}

	&::-webkit-scrollbar-track-piece {
		background-color: transparent;
	}

  transition: all 0.3s ease-in-out;
`;

const SelectBoxTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 80%;
  height: 21px;

  gap: 4px;

  overflow: hidden;
`;

const SelectListTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  padding: 0px 10px;

  width: calc(100% - 20px);
  min-height: 34px;

  overflow: hidden;
  
  background-color: #FFFFFF;

  font-family: Poppins-Medium;
  font-size: 14px;

  color: #4F4F4F;

  :hover {
    cursor: pointer;
    color: #FFFFFF;
    background-color: #FF8B48;
  }

  transition: all 0.3s ease-in-out;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  
  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;