import { React, useRef, useState, useEffect } from 'react';
import styled from 'styled-components';

export default function IncludeSlashDataInput(props) {
	const { value, setValue, unit, column, division, index } = props;

  const [useUnit, setUseUnit] = useState('');

	const InputRef = useRef();

  useEffect(() => {
    if (unit === '%RM' || unit === '%Body') setUseUnit('%');
    else if (unit === 'Pounds') setUseUnit('lb');
    else if (unit === 'Kg') setUseUnit('Kg');
    else setUseUnit(unit);
  }, [unit])

	function handleChange(e) {
    // var regex = /[^0-9]^\d*[.]\d*$/g;				// 숫자가 아닌 문자열을 선택하는 정규식
    let regex = /[^0-9]/g; // 숫자만 감지 
    
    let changedValue = e.target.value;
    
    if (changedValue === '') {
      setValue(index, division, column, 0);
      return;
    }
    
    let lastValue = changedValue[changedValue.length - 1];

    // if (regex.test(lastValue)) 
    // console.log('\n\n===========');
    // console.log(regex);
    // console.log(lastValue);
    // console.log(regex.test(lastValue));

    if (regex.test(lastValue)) {
      if (lastValue === '/'
        || lastValue === '~'
        || lastValue === '-'
        || lastValue === '.'
        || lastValue === '@') setValue(index, division, column, changedValue);
    }
    else {
      if (regex.test(changedValue)) setValue(index, division, column, changedValue);
      else setValue(index, division, column, parseInt(changedValue));
    }
    // if (changedValue[changedValue.length - 1] !== '.') changedValue = parseFloat(changedValue);
    // var result = changedValue.toString().replace(regex, "");

    // if (result === '') setValue(index, division, column, 0);
		// else setValue(index, division, column, result);

    // if (changedValue.includes('/')) setValue(index, division, column, changedValue);
    // else if (changedValue[changedValue.length - 1] !== '.') setValue(index, division, column, parseFloat(changedValue));
    // else setValue(index, division, column, changedValue);

    // if (changedValue.includes('/')
    //   || changedValue.includes('~')
    //   || changedValue.includes('-')
    //   || changedValue.includes('@')) 
	}

	return (
    <TopLevelWrapper>
      <Input
        ref={InputRef}
        type='text'
        placeholder=''
        value={value}
        onChange={handleChange}/>
      <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>{useUnit}</Text>
    </TopLevelWrapper>
  )
};

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // width: 286px;
  // width: 166px;
  width: calc(100% - 34px);
  height: 34px;

  padding-left: 16px;
  padding-right: 16px;

  border: 1px solid #E0E0E0;
  border-radius: 30px;

  background-color: ${props => props.disabled ? `#ECECEC` : `#F6F6F6`};
  
  transition: all 0.3s ease-in-out;
`;

const Input = styled.input` 
	font-family: NotoSansKR-Regular;
	font-size: 14px;
	line-height: 20px;
	
  display: flex;
  flex-direction: row;

  width: 100%;

  border: 0px;
  background-color: #F6F6F6;

  color: #595959;

	outline: none;

	::placeholder {
    font-family: NotoSansKR-Regular;
    font-size: 14px;

		color: #CBCBCB;
	}

  &:disabled {
    background-color: #ECECEC;
  }

  transition: all 0.3s ease-in-out;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;
