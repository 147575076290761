import { React, useRef } from 'react';
import styled from 'styled-components';

import UpButtonArrowImagePath from '../../../image/UpButtonArrow.png';
import DownButtonArrowImagePath from '../../../image/DownButtonArrow.png';

export default function NewDayCountInput(props) {
  const { disabled, value, setValue } = props;

  const InputRef = useRef();

  function handleChange(e) {
    const curValue = e.target.value
		const newValue = curValue.replace(/[^0-9]/g, '')

    if (newValue < 9999999)
		  setValue(newValue);
  }

  function handleFocus() {
    InputRef.current.style.border = `1px solid #FF8B48`;
  }

  function handleBlur() {
    InputRef.current.style.border = `1px solid #DFDFDF`;
  }

  function upClick() {
    if (disabled) return;

    if (value < 9999999) setValue(parseInt(value) + 1)
    else setValue(0);
  }

  function downClick() {
    if (disabled) return;

    if (value > 0) setValue(parseInt(value) - 1)
  }

  return (
    <TopLevelWrapper>
      <LayerWrapper>
        <Title>연장 일수</Title>
        <Input 
          ref={InputRef}
          placeholder='0'
          value={value || ''}
          disabled={disabled}
          onFocus={handleFocus}
          onChange={handleChange}
          onBlur={handleBlur}/>
      </LayerWrapper>
      <LayerWrapper>
        <Text>일</Text>
      </LayerWrapper>
      <LayerWrapper>
        <ButtonWrapper>
          <Button img={UpButtonArrowImagePath} onClick={upClick}/>
          <Button img={DownButtonArrowImagePath} onClick={downClick}/>
        </ButtonWrapper>
      </LayerWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const Title = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;

  margin: 0 16px 0 0;
`;

const Input = styled.input`
  width: 61px;
  height: 28px;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  margin: 0 8px 0 0;
  padding: 0 0 0 8px;

  border: 1px solid #DFDFDF;
  border-radius: 5px;
  outline: none;

  color: #CBCBCB;
  background-color: ${props => props.disabled ? '#ECECEC' : '#FFFFFF'};

  transition: all 0.3s ease-in-out;

  ::placeholder {
    color: #CBCBCB;
  }
`;

const Text = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #777777;

  margin: 0 8px 0 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
`;

const Button = styled.div`
  display: flex;
  flex-direction: column;
  
  justify-content: center;
  align-items: center;

  width: 28px;
  height: 28px;

  border: 1px solid #DFDFDF;
  border-radius: 5px;

  background: url(${props => props.img}) no-repeat center center;
  background-color: #ECECEC;

  :hover {
    cursor: pointer;
  }
`;