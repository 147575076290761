import { React, useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import LockerAddModal from './LockerAddModal';
import LockerInfo from '../../../Component/LockerComponent/LockerInfo';

import FetchModule from '../../Share/Network/FetchModule';
import LockerChangeNameModal from './LockerChangeNameModal';
import LockerUsingStopModal from './LockerUsingStopModal';
import LockerStartModal from './LockerStartModal';
import moment from 'moment';
import LockerHistoryModal from './LockerHistoryModal';
import CustomAlert from '../../Share/Normal/CustomAlert';
import LockerExtendsModal from './LockerExtendsModal';

function LockerView () {
  const history = useHistory();

  const [isClickedAdd, setIsClickedAdd] = useState(false);
  const [isClickedChangeName, setIsClickedChangeName] = useState(false);
  const [isClickedStart, setIsClickedStart] = useState(false);
  const [isClickedStop, setIsClickedStop] = useState(false);
  const [isClickedHistory, setIsClickedHistory] = useState(false);
  const [isClickedExtend, setIsClickedExtend] = useState(false);
  const [isViewCustomAlert, setIsViewCustomAlert] = useState(false);

  const [lockerInfo, setLockerInfo] = useState(undefined);

  const [text, setText] = useState('');
  const [lockerData, setLockerData] = useState([]);
  const [index, setIndex] = useState(-1);
  const [rendering, setRendering] = useState(false);

  const AddModalRef = useRef();
  const NameModalRef = useRef();
  const StartModalRef = useRef();
  const LockerRef = useRef([]);

  useEffect(() => {
    const fetchModule = new FetchModule();
		(
			async () => {
				// const responseData = await fetchModule.getData('locker/', 'GET');
				const responseData = await fetchModule.postData('locker/', 'POST');
        
				if (responseData.status === 200) {
          setLockerData(responseData.data);
				}
				else if (responseData.status === 401) {
          alert("로그인 후 사용하세요");
          history.push('/');
        }
			}
		)();
  }, [history, rendering])

  function modalExitClick() {
   setIsClickedAdd(false);
  }

  function addModalSaveClick() {
    var value = AddModalRef.current.getValue();

    const fetchModule = new FetchModule();
		(
			async () => {
				const responseData = await fetchModule.postData('locker/create/' 
                                                        // + window.sessionStorage.getItem('StoreId') 
                                                        + window.localStorage.getItem('StoreId')
                                                        + '/' 
                                                        + value, 'POST');
        
				if (responseData.status === 200) {
          setRendering(!rendering);
          setIsClickedAdd(false);
				}
        else if (responseData.status === 204) {}
				else if (responseData.status === 401) {
          alert("로그인 후 사용하세요");
          history.push('/');
        }
			}
		)();
  }

  function changeModalSaveClick(name) {
    const fetchModule = new FetchModule();
		(
			async () => {
				const responseData = await fetchModule.patchData('locker/modify/name/' 
                                                        + lockerData[index].id
                                                        + '/' 
                                                        + name, 'PATCH');
        
				if (responseData.status === 200) {
          setRendering(!rendering);
          setIsClickedChangeName(false);
          setIndex(-1);
          clickMoreButton();
				}
        else if (responseData.status === 204) {}
				else if (responseData.status === 401) {
          alert("로그인 후 사용하세요");
          history.push('/');
        }
			}
		)();
  }
  
  function clickMoreButton() {
    for (var i = 0; i < lockerData.length; i++) {
      LockerRef.current[i].setClickedMore(false);
    }
  }

  function clickUsageHistory(index) {
    setIndex(index);
    setIsClickedHistory(true);
  }
  
  //
  function clickChangeState(index) {
    if (lockerData[index].state === '사용중') {
      // alert('사용 종료후 다시시도하세요!');
      setText('사용 종료후 다시시도하세요!');
      setIsViewCustomAlert(true);
      return;
    }
    else if (lockerData[index].state === '사용불가') {
      // alert('이미 잠금 상태입니다.');
      setText('이미 잠금 상태입니다.');
      setIsViewCustomAlert(true);
      return;
    }

    var state = '';

    if (lockerData[index].state === '비어있음') state = 'Invalid';

    const fetchModule = new FetchModule();
		(
			async () => {
				const responseData = await fetchModule.patchData('locker/modify/' 
                                                        + lockerData[index].id
                                                        + '/'
                                                        + state, 'PATCH');
        
				if (responseData.status === 200) {
          setRendering(!rendering);
          clickMoreButton();
				}
        else if (responseData.status === 204) {}
				else if (responseData.status === 401) {
          alert("로그인 후 사용하세요");
          history.push('/');
        }
			}
		)();
  }


  function clickDeleteLocker(index) {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.deleteData('locker/delete/'
          + lockerData[index].id, 'DELETE');
          
        if (responseData.status === 200) {
          setRendering(!rendering);
          clickMoreButton();
        }
        else if (responseData.status === 205) {
          // alert("사용중인 락커입니다. 사용을 종료 후 다시시도해 주세요.");
          setText('사용중인 락커입니다.\n사용을 종료 후 다시시도해 주세요.');
          setIsViewCustomAlert(true);
        }
        else if (responseData.status === 401) {
          alert("로그인 후 사용하세요");
          history.push('/');
        }
      }
    )();
  }

  function clickChangeName(index) {
    setIndex(index);
    setIsClickedChangeName(true);
  }

  function clickUsingStartOrEndOrChangeValid(index) {
    setIndex(index);
    
    var state = '';

    if (lockerData[index].state === '사용불가') {
      state = 'Empty';

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.patchData('locker/modify/'
                                                          + lockerData[index].id
                                                          + '/'
                                                          + state, 'PATCH');
          
          if (responseData.status === 200) {
            setRendering(!rendering);
            clickMoreButton();
          }
          else if (responseData.status === 204) { }
          else if (responseData.status === 401) {
            alert("로그인 후 사용하세요");
            history.push('/');
          }
        }
      )();
      return;
    }

    if (lockerData[index].state === '사용중') setIsClickedStop(true);
    else if (lockerData[index].state === '비어있음') setIsClickedStart(true);
  }

  function startModalSaveClick() {

    var storeId = parseInt(window.localStorage.getItem('StoreId'));
    var lockerId = lockerData[index].id;
    // var lockerName = lockerData[index].lockerName;
    var memberId = StartModalRef.current.getMemberData().memberId;
    // var price = StartModalRef.current.getPrice();
    var card = StartModalRef.current.getCard();
    var cash = StartModalRef.current.getCash();
    var approvalDate = moment().format('YYYY-MM-DDTHH:mm:ss');
    var startDate = StartModalRef.current.getStartDate() === '' ? '' : StartModalRef.current.getStartDate().format('YYYY-MM-DD') + 'T' + approvalDate.split('T')[1];
    var endDate = StartModalRef.current.getEndDate() === '' ? '' : StartModalRef.current.getEndDate().format('YYYY-MM-DD') + 'T23:59:59';

    // if (price - (cardPrice + cashPrice) < 0) {
    //   alert('결제금액보다 큰 금액을 입력할 수 없습니다.');
    //   return;
    // }
    if (startDate === '') {
      // alert('시작일을 선택해 주세요');
      setText('시작일을 선택해 주세요');
      setIsViewCustomAlert(true);
      return;
    }
    else if (endDate === '') {
      // alert('종료일을 선택해 주세요');
      setText('종료일을 선택해 주세요');
      setIsViewCustomAlert(true);
      return;
    }
    else if (memberId === 0 || memberId === undefined) {
      // alert('사용할 회원을 선택해주세요');
      setText('사용할 회원을 선택해주세요');
      setIsViewCustomAlert(true);
      return;
    }

    var data = {
      storeId: storeId,
      lockerId: lockerId,
      memberId: memberId,
      startDate: startDate,
      endDate: endDate,
      approvalDate: approvalDate,
      cardPrice: card === 0 ? card : card.replaceAll(',', ''),
      cashPrice: cash === 0 ? cash : cash.replaceAll(',', '')
    } 

    const fetchModule = new FetchModule();
		(
			async () => {
				const responseData = await fetchModule.postDataVersion2('locker/start', 'POST', data);
        
				if (responseData.status === 200) {
          setRendering(!rendering);
          setIsClickedStart(false);
          setIndex(-1);
          clickMoreButton();
				}
        else if (responseData.status === 204) {
          if (responseData.error === 'Locker Already Using') {
            // alert("이미 사용중인 락커입니다.");
            setText('이미 사용중인 락커입니다.');
            setIsViewCustomAlert(true);
          }
          else if (responseData.error === 'Invalid Using') {
            // alert("사용이 불가능한 락커입니다.");
            setText('사용이 불가능한 락커입니다.');
            setIsViewCustomAlert(true);
          }
          else if (responseData.error === 'Invalid User Data') {
            // alert("올바르지 않은 회원 정보입니다.");
            setText('올바르지 않은 회원 정보입니다.');
            setIsViewCustomAlert(true);
          }
        }
				else if (responseData.status === 401) {
          alert("로그인 후 사용하세요");
          history.push('/');
        }
			}
		)();
  }

  function stopModalSaveClick() {
    const fetchModule = new FetchModule();
		(
			async () => {
				const responseData = await fetchModule.postData('locker/stop/' + lockerData[index].id, 'POST');
        
				if (responseData.status === 200) {
          setRendering(!rendering);
          setIsClickedStop(false);
          setIndex(-1);
          clickMoreButton();
				}
        else if (responseData.status === 204) {}
				else if (responseData.status === 401) {
          alert("로그인 후 사용하세요");
          history.push('/');
        }
			}
		)();
  }

  // 문자발송 버튼 클릭시
  function onClickSendMessageButton(index) {

    let text = lockerData[index].userName + '님, 지금까지 이용하셨던 락커 ' + lockerData[index].lockerName + '이(가) 만료되었어요';

    let requestData = {
      userId: lockerData[index].linkCoachId,
      data: text,
      location: 'Reservation',
      jsonData: ''
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataOfCoach('push_notification/send', 'POST', requestData);

        if (responseData.status === 200) {
          // alert("푸시알림을 전송하였습니다!");
          setText('푸시알림을 전송하였습니다!');
          setIsViewCustomAlert(true);
          LockerRef.current[index].setClickedMore(false);
        }
        else if (responseData.status === 204) {
          // alert("Link Coach를 탈퇴한 회원입니다!");
          setText('Link Coach에 가입되어있지 않은 회원입니다!');
          setIsViewCustomAlert(true);
          LockerRef.current[index].setClickedMore(false);
        }
      }
    )();
  }

  // 사용 연장버튼 클릭시 //
  function onClickExtendsButton(index) {
    setLockerInfo(lockerData[index]);
    setIsClickedExtend(true);
  }

  // 사용 내역 연장 모달 내에서 저장하기 버튼 클릭시 //
  function onClickSaveExtends(endDate, cardPrice, cashPrice) {
    // console.log(lockerInfo);
    // console.log(endDate.clone().format('YYYY-MM-DDTHH:mm:ss'));
    
    if (moment().clone().format('YYYY-MM-DDTHH:mm:ss') > endDate.clone().format('YYYY-MM-DDTHH:mm:ss')) {
      alert('사용 날짜 변경은 금일 보다 이전일 수 없습니다.');
      return;
    }

    let requestData = {
      lockerId: lockerInfo.id,
      usageId: lockerInfo.usageId,
      endDate: endDate.clone().format('YYYY-MM-DDT23:59:59'),
      merchantUid: lockerInfo.merchantUid,
      cardPrice: parseInt(cardPrice.toString().replaceAll(',', '')),
      cashPrice: parseInt(cashPrice.toString().replaceAll(',', ''))
    }
    
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postData('locker/extend', 'POST', requestData);

        if (responseData.status === 200) {
          alert('변경이 완료되었습니다.');
          setRendering(!rendering);
          setIsClickedExtend(false);
        }
      }
    )();
  }

  return (
    <TopLevelWrapper>
      { isViewCustomAlert && <CustomAlert text={text} closeFn={() => setIsViewCustomAlert(false)}/>}
      { isClickedAdd && <LockerAddModal ref={AddModalRef} exitClick={modalExitClick} saveClick={addModalSaveClick}/>}
      { isClickedChangeName && <LockerChangeNameModal ref={NameModalRef} data={lockerData} index={index} exitClick={() => setIsClickedChangeName(false)} saveClick={changeModalSaveClick}/>}
      { isClickedStop && <LockerUsingStopModal data={lockerData[index]} exitClick={() => setIsClickedStop(false)} saveClick={stopModalSaveClick}/>}
      { isClickedStart && <LockerStartModal ref={StartModalRef} exitClick={() => setIsClickedStart(false)} saveClick={startModalSaveClick}/>}
      { isClickedHistory && <LockerHistoryModal data={lockerData[index]} exitClick={() => setIsClickedHistory(false)}/>}
      { isClickedExtend && <LockerExtendsModal data={lockerInfo} saveFn={onClickSaveExtends} closeFn={() => setIsClickedExtend(false)}/> } 
      <TopComponentWrapper>
        <TitleWrapper>
          <Title>락커</Title>
          <Explanation>센터의 락커 관리와 이용 현황을 알 수 있어요</Explanation>
        </TitleWrapper>
        <AddButton onClick={() => setIsClickedAdd(true)}>락커추가</AddButton>
      </TopComponentWrapper>
      <ViewBox>
        <InfoBox>
          {
            lockerData.map((data, index) => (
              <LockerInfo
                key={index}
                data={data}
                index={index}
                ref={element => (LockerRef.current[index] = element)}
                clickUsageHistoryFn={clickUsageHistory}
                clickFunctionFn={clickUsingStartOrEndOrChangeValid}
                clickChangeStateFn={clickChangeState}
                clickDeleteLockerFn={clickDeleteLocker} 
                clickChangeNameFn={clickChangeName}
                clickSendMessageFn={onClickSendMessageButton}
                clickExtendFn={onClickExtendsButton}/>
            ))
          }
        </InfoBox>
      </ViewBox>
    </TopLevelWrapper>
  )
}

export default LockerView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin-left: 14px;
`;

const TopComponentWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100%;

  gap: 674px;
  margin-left: 28px;
`;


const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  gap: 4px;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: 0 0 0 0;
`;

const Explanation = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #777777;

  margin: 0 0 0 0;
`;

const AddButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 15px;
  background-color: #5BD98A;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }
`;

const ViewBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 1020px;

  margin-top: 38px;
  margin-bottom: 96px;
  margin-left: 28px;

  border-radius: 30px;
  background-color: #FFFFFF;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: row;

  flex-wrap: wrap;

  width: 962px;

  gap: 18px;

  margin-top: 28px;
  margin-bottom: 28px;
`;