import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

import BlackArrow from '../../../image/BlackDownArrow.png';
import OrangeArrow from '../../../image/OrangeDownArrow.png';

export default function SelectRemainDayBox(props) {
  const {
    remainDayStart,
    remainDayEnd,
    setRemainDayStart,
    setRemainDayEnd
  } = props;

  const [isClicked, setIsClicked] = useState(false);

  const ButtonRef = useRef(null);
  const SelectBoxRef = useRef(null);

  // 컴포넌트 외부 클릭시 이벤트를 받기위한 리스너 연결
  useEffect(() => {
    document.addEventListener("mousedown", onClickOutSide);

    return () => {
      document.removeEventListener("mousedown", onClickOutSide);
    }
  }, [])

  // 컴포넌트 외부 클릭시 실행
  function onClickOutSide(e) {
    if (SelectBoxRef.current !== e.target 
      && !SelectBoxRef.current.contains(e.target)
      && ButtonRef.current !== e.target 
      && !ButtonRef.current.contains(e.target)) {
        setIsClicked(false);
    }
  }

  // 시작일 변경시 //
  function onChangeRemainDayStart(e) {
    let regex = /[^0-9]/g;				                  // 숫자가 아닌 문자열을 매칭하는 정규식
    let result = e.target.value.replace(regex, "");	// 원래 문자열에서 숫자가 아닌 모든 문자열을 빈 문자로 변경
    
    if (result === "") setRemainDayStart(0);
		else setRemainDayStart(parseInt(result));
  }

  // 시작일 포커스 없어진 경우 //
  function onBlurRemainDayStart() {
    if (remainDayStart !== '' && remainDayEnd !== '') {
      if (remainDayStart > remainDayEnd) setRemainDayEnd(remainDayStart);
    }
  }

  // 종료일 변경시 //
  function onChangeRemainDayEnd(e) {
    let regex = /[^0-9]/g;				                  // 숫자가 아닌 문자열을 매칭하는 정규식
    let result = e.target.value.replace(regex, "");	// 원래 문자열에서 숫자가 아닌 모든 문자열을 빈 문자로 변경
    
    if (result === "") setRemainDayEnd(0);
		else setRemainDayEnd(parseInt(result));
  }

  // 종료일 포커스 없어진 경우 //
  function onBlurRemainDayEnd() {
    if (remainDayStart !== '' && remainDayEnd !== '') {
      if (remainDayStart > remainDayEnd) setRemainDayEnd(remainDayStart);
    }
  }

  return (
    <StatusBoxWrapper>
      <StatusBox ref={ButtonRef} isClicked={isClicked} onClick={() => setIsClicked(!isClicked)}>
        <Text fontFamily='NotoSansKR-Regular' fontSize={12} color={isClicked ? `#FF8B48` : `#3E4148`} hover='pointer'>잔여 일수</Text>
        {
          (remainDayStart === 0 && remainDayEnd === 0) &&
          <ArrowWrapper isClicked={isClicked}>
            <Arrow src={BlackArrow} opacity={isClicked ? 0 : 1} />
            <Arrow src={OrangeArrow} opacity={isClicked ? 1 : 0} />
          </ArrowWrapper>
        }
        {
          (remainDayStart !== 0 || remainDayEnd !== 0) &&
          <Text fontFamily='NotoSansKR-Regular' fontSize={12} color={isClicked ? `#FF8B48` : `#3E4148`} hover='pointer'>:</Text>
        }
        {
          (remainDayStart !== 0 && remainDayStart < remainDayEnd) &&
          <Text fontFamily='NotoSansKR-Regular' fontSize={12} color={isClicked ? `#FF8B48` : `#3E4148`} hover='pointer'>{remainDayStart + ' 일'}</Text>
        }
        {
          ((remainDayStart !== 0 || remainDayEnd !== 0) && remainDayStart >= remainDayEnd) &&
          <Text fontFamily='NotoSansKR-Regular' fontSize={12} color={isClicked ? `#FF8B48` : `#3E4148`} hover='pointer'>{remainDayStart + ' 일'}</Text>
        }
        {
          ((remainDayStart !== 0 || remainDayEnd !== 0) && remainDayStart < remainDayEnd) &&
          <Text fontFamily='NotoSansKR-Regular' fontSize={12} color={isClicked ? `#FF8B48` : `#3E4148`} hover='pointer'>{remainDayStart !== 0 ? ('~ ' + remainDayEnd + ' 일') : (remainDayEnd + ' 일')}</Text>
        }
      </StatusBox>
      <SelectBox ref={SelectBoxRef} isClicked={isClicked}>
        <InputTotalWrapper>
          <InputWrapper>
            <Input
		          value={remainDayStart}
		          onChange={onChangeRemainDayStart}
              onBlur={onBlurRemainDayStart}
              placeholder='0'/>
            <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#777777' hover='default'>일</Text>
          </InputWrapper>
          <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#777777' hover='default'>~</Text>
          <InputWrapper>
            <Input
		          value={remainDayEnd}
		          onChange={onChangeRemainDayEnd}
              onBlur={onBlurRemainDayEnd}
              placeholder='0'/>
            <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#777777' hover='default'>일</Text>
          </InputWrapper>
        </InputTotalWrapper>
      </SelectBox>
    </StatusBoxWrapper>
  )
}

const StatusBoxWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const StatusBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  padding: 0px 4px;

  height: 20px;

  gap: 4px;

  border: ${props => props.isClicked ? `1px solid #FF8B48` : `1px solid #DBDDE4`};
  border-radius: 5px;
  background-color: ${props => props.isClicked ? `#FFFFFF` : `#F5F6F8`};

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const ArrowWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 10px;
  height: 6px;

  transition: all 0.3s ease-in-out;

  transform: ${props => props.isClicked ? `rotateZ(-180deg)` : `rotateZ(0deg)`};
`;

const Arrow = styled.img`
  position: absolute;

  width: 10px;
  height: 6px;

  opacity: ${props => props.opacity};

  transition: all 0.3s ease-in-out;
`;

const SelectBox = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 26px;

  width: 135px;
  height: ${props => props.isClicked ? `52px` : `0px`};

  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  border-radius: 10px;
  background-color: #FFFFFF;

  overflow: hidden;

  transition: all 0.3s ease-in-out;
`;

const InputTotalWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 115px;  
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px; 
`;

const Input = styled.input`
	font-family: NotoSansKR-Regular;
	font-size: 12px;
	line-height: 20px;

  // padding: 0 0 0 4px;
  padding: 0px 6px;

  width: 19px;
  height: 30px;

	outline: none;
  border: 1px solid #DFDFDF;
  border-radius: 5px;
	color: #000000;

	::placeholder {
    color: #CBCBCB;
	}
	
  transition: all 0.3s ease-in-out;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;