import { React, useState } from 'react';
import styled, { keyframes } from 'styled-components';

import moment from 'moment';

import MemberInfo from './MemberInfo.js';
import TodayClass from './TodayClass.js';
import BoardView from './BoardView.js';
import LockerView from './LockerView.js';
import NoticeView from './NoticeView.js';

function MainView() {
  const [today, setToday] = useState(moment());

  return (
    <TopLevelWrapper>
      <CompoenntWrapper>
        <MemberInfo today={today} setToday={setToday}/>
        <TodayClass today={today} setToday={setToday}/>
      </CompoenntWrapper>
      {/* <BoardView /> */}
      <LockerView />
      <NoticeView />
    </TopLevelWrapper>
  )
}

export default MainView;

const startAnimation = keyframes`
  0% { opacity: 0; }
  50% { opacity: 0.5; } 
  100% { opacity: 1; }
`;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  // justify-content: center;
  align-items: center;
  vertical-align: bottom;
  background-color: #FDFCFB;
  
  // width: 100%;
  gap: 38px;
  // gap: max(16px, 1.979166666666667vw);
  
  animation-name: ${startAnimation};
  animation-duration: 0.3s;
`;

const CompoenntWrapper = styled.div`
  display: flex;
  flex-direction: row;

  flex-wrap: wrap;

  gap: 38px;
  // gap: max(16px, 1.979166666666667vw);

  width: 1062px;
  // width: max(355px, 55.3125vw);
`;