import { React, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

export default function NoHavePhoneAlertModal(props) {
  const { onClickYes, onClickNo } = props;

  useEffect(() => {
    document.body.style= `overflow: hidden`;
    return () => document.body.style = `overflow: auto`
  }, [])

  return (
    <TopLevelWrapper>
      <ModalView>
        <Title>휴대전화를 입력하지 않으시겠습니까?</Title>
        <TextWrapper>
          <Explanation>휴대전화 번호를 입력하지 않는경우</Explanation>
          <ExplanationWrapper>
            <ColorExplanation>Link Coach</ColorExplanation>
            <Explanation>와 회원권 연동이 되지 않습니다.</Explanation>
          </ExplanationWrapper>
        </TextWrapper>
        <ButtonWrapper>
          <ExitButton onClick={onClickNo}>취소</ExitButton>
          <CancelButton onClick={onClickYes}>진행하기</CancelButton>
        </ButtonWrapper>
      </ModalView>
    </TopLevelWrapper>
  )
}

const startAnimation = keyframes`
	0% { opacity: 0; }
	50% { opacity: 0.5; }
	100% { opacity: 1; }
`;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  justify-content: center;
  align-items: center;

  z-index: 1000;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.2);
	
	animation-name: ${startAnimation};
	animation-duration: 0.5s;
	animation-timing-function: ease-out;
`;

const ModalView = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: 371px;
  height: 248px;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(0, 0, 0, 0.1);
  background-color: #FFFFFF;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: 0 0 16px 28px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin-left: 28px;
  
  gap: 14px;
`;

const Explanation = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;
  
  margin: 0 0 0 0;
`;

const ColorExplanation = styled.p`
  font-family: Poppins-SemiBold;
  font-size: 14px;

  color: #6DD49E;
  
  margin: 0 0 0 0;
`;

const ExplanationWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;

  gap: 16px;

  margin: 53px 0 0 153px;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 72px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;
  
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #777777;
    color: #777777;
  }
`;

const CancelButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 15px;
  background-color: #FF3131;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;