import { React, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Password 변경 이메일 전송시 session storage에 토큰을 저장후 redirection을 진행하기 위한 페이지

const PasswordPermutePage = ({match}) => {
  const history = useHistory();

  useEffect(() => {
    window.sessionStorage.setItem("PermuteToken", match.params.token);
    window.sessionStorage.setItem("name", match.params.name);
    history.push('/permute');
  }, [])

  return <div/>
}

export default PasswordPermutePage;