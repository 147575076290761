import { React, useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import lodash from 'lodash';
import Lottie from 'lottie-react-web';

// import TimeDivisionLine from './TimeDivisionLine';
import TimeDivisionLine from '../../../Component/MainComponent/TimeDivisionLine.js';
import WeeklyColumn from './WeeklyColumn';
import EmptySign from '../../../image/EmptySign.png';

import PlusPath from '../../../image/ClassAddPlus.png';
import WeeklyClass from './WeeklyClass.js';
import NewWeeklyClass from './NewWeeklyClass.js';
import ImprovedWeeklyClass from './ImprovedWeeklyClass.js';
import ImprovedWeeklyClassVer2 from './ImprovedWeeklyClassVer2.js';

export default function WeeklyClassBox(props) {
  const {
    today,
    standardDate,
    isLoading,
    setIsLoading,
    scheduleData,
    breakTimeData,
    setClickDate,
    onClickDeleteButton,
    onClickScheduleDetail,
    onClickPlusColumn
  } = props;

  const [standardDateList, setStandardDateList] = useState([]);
  const [completeList, setCompleteList] = useState([]);

  // 매 주차 첫번째날 뽑기 //
  useEffect(() => {
    let newList = [];

    newList.push(standardDate.clone());

    let nextStandardDate = standardDate.clone().add(7, 'days');

    while (true) {
      if (standardDate.clone().format('MM') === nextStandardDate.clone().format('MM')) {
        newList.push(nextStandardDate.clone());

        nextStandardDate.add(7, 'days');
      }
      else break;
    }

    // setCompleteList([]);
    let newCompleteList = [];

    for (let i = 0; i < newList.length; i++) {
      newCompleteList.push(false);
    }

    setCompleteList(newCompleteList);
    setStandardDateList(newList);
    // setTimeout(() => {
    //   setIsLoading(false);
    // }, 500)
  }, [standardDate])

  return (
    <TopLevelWrapper>
      {/* {
          standardDateList.map((data, index) => (
            <WeeklyWrapper>
              <WeeklyClass
                key={index}
                index={index}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                scheduleData={scheduleData}
                breakTimeData={breakTimeData}
                // today={today}
                today={data}
                setClickDate={setClickDate}
                onClickDeleteButton={onClickDeleteButton}
                onClickScheduleDetail={onClickScheduleDetail}
                onClickPlusColumn={onClickPlusColumn} />
              {standardDateList.length !== index + 1 && <DivisionLine />}
            </WeeklyWrapper>
          ))
      } */}
      {
          standardDateList.map((data, index) => (
            <WeeklyWrapper>
              <NewWeeklyClass
                key={index}
                index={index}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                scheduleData={scheduleData}
                breakTimeData={breakTimeData}
                // today={today}
                today={data}
                setClickDate={setClickDate}
                onClickDeleteButton={onClickDeleteButton}
                onClickScheduleDetail={onClickScheduleDetail}
                onClickPlusColumn={onClickPlusColumn} />
              { standardDateList.length !== index + 1 && <DivisionLine /> }
            </WeeklyWrapper>
          ))
      }
      {/* {
        standardDateList.map((data, index) => (
          <WeeklyWrapper>
            <ImprovedWeeklyClass
              key={index}
              index={index}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              scheduleData={scheduleData}
              breakTimeData={breakTimeData}
              today={data}
              setClickDate={setClickDate}
              onClickDeleteButton={onClickDeleteButton}
              onClickScheduleDetail={onClickScheduleDetail}
              onClickPlusColumn={onClickPlusColumn} />
            {standardDateList.length !== index + 1 && <DivisionLine />}
          </WeeklyWrapper>
        ))
      } */}
      {/* {
        standardDateList.map((data, index) => (
          index === 0 &&
          <WeeklyWrapper>
            <ImprovedWeeklyClassVer2
              key={index}
              index={index}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              scheduleData={scheduleData}
              breakTimeData={breakTimeData}
              // today={today}
              today={data}
              setClickDate={setClickDate}
              onClickDeleteButton={onClickDeleteButton}
              onClickScheduleDetail={onClickScheduleDetail}
              onClickPlusColumn={onClickPlusColumn} />
            {standardDateList.length !== index + 1 && <DivisionLine />}
          </WeeklyWrapper>
        ))
      } */}
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  // position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 1020px;
  // height: 449px;
  // height: 686px;

  // padding-top: 28px;
  padding-bottom: 28px;

  // margin-top: 138px;
  margin-bottom: 96px;

	// opacity: 0;
	// z-index: -3;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  background-color: #FFFFFF;
  
  transition: all 0.3s ease-in-out;
`;

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 974px;
  min-height: 644px;
`;

const WeeklyWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const DivisionLine = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 25px;

  width: 974px;
  height: 2px;

  border-radius: 4px;
  background-color: rgba(255, 139, 72, 0.5);
`;
