import { forwardRef, React, useEffect, useRef, useState, useImperativeHandle } from 'react';
import styled from 'styled-components';

const EditSettingMessageInput = forwardRef((props, ref) => {
  const { 
    value, 
    setValue, 
    lastCursorPosition,
    setLastCursorPosition,
    placeholder 
  } = props;


  const [byte, setByte] = useState(0);

  const wordsToDelete = [
    '{시설 이름}',
    '{회원 이름}',
    '{보유 회원권}',
    '{운동 기간}',
    '{락커 번호}',
    '{코치 이름}',
    '{수업 이름}',
    '{예약 시간}',
    // '{수업 시간}', 
    '{홀딩 시작일}',
    '{홀딩 종료일}',
  ];

  const InputRef = useRef(null);

  useImperativeHandle(ref, () => ({
    prevFocus: (addValueLength) => {
      let endPoint = lastCursorPosition + addValueLength;
      
      setTimeout(() => {
        InputRef.current.setSelectionRange(endPoint, endPoint);
        InputRef.current.focus();
      }, 0)

      setLastCursorPosition(endPoint);
    }
  }));

  // 문자 Byte 계산 //
  useEffect(() => {
    let byte = checkValueByte(value);

    setByte(byte);
  }, [value])

  function handleChange(e) {
    let text = e.target.value
    
    let byte = checkValueByte(text);

    if (byte <= 2000) {
      setByte(byte);
      setValue(text);
    }
  }

  function checkValueByte(str) {
    //UTF8 기준 Byte Length 계산
    let byteLength = 0;

    for (let i = 0, strLength = str.length; i < strLength; i++) {
      let code = str.charCodeAt(i);
      if (code < 0x0080) {
        //[0x0000, 0x007F]
        byteLength += 1;
      }
      else if (code < 0x0800) {
        //[0x0080, 0x07FF]
        byteLength += 2;
      }
      else if (code < 0xD800) {
        //[0x0800, 0xD7FF]
        byteLength += 3;
      }
      else if (code < 0xDC00) {
        //[0xD800, 0xDBFF]
        let codeNext = str.charCodeAt(++i);

        if (i < strLength && codeNext >= 0xDC00 && codeNext <= 0xDFFF) {
          //followed by [0xDC00, 0xDFFF]
          byteLength += 4;
        }
        else {
          throw new Error("UCS-2 String malformed");
        }
      }
      else if (code < 0xE000) {
        //[0xDC00, 0xDFFF]
        throw new Error("UCS-2 String malformed");
      }
      else {
        //[0xE000, 0xFFFF]
        byteLength += 3;
      }
    }
    return byteLength;
  }

  function handleKeyDown(event) {
    if (event.key === '{' || event.key === '}') {
      event.preventDefault(); // 기본 키눌림 동작 방지
    }
    // Backspace 키가 눌렸는지 확인
    else if (event.key === 'Backspace') {
      const start = event.target.selectionStart;
      const currentValue = value;
      // console.log(currentValue);
      // console.log(currentValue[start - 1]);
      // 현재 커서 위치에서 앞에 있는 문자를 확인
      if (start > 0 && currentValue[start - 1] === '}') {
        let wordLength = 0;

        // 가장 마지막에 위치한 단어 찾기
        const lastWordIndex = wordsToDelete.reduce((lastIndex, word) => {
          const index = currentValue.lastIndexOf(word, start);
          // return index > lastIndex ? index : lastIndex;

          if (index > lastIndex) {
            wordLength = word.length;
            return index;
          }
          else return lastIndex;
        }, -1);

        // 마지막 단어가 유효한 경우 삭제
        if (lastWordIndex !== -1) {
          const word = currentValue.substring(lastWordIndex, lastWordIndex + currentValue.length);
          // const wordLength = currentValue.substring(lastWordIndex, lastWordIndex + currentValue.length).length;
          const newValue = currentValue.slice(0, lastWordIndex) + currentValue.slice(lastWordIndex + wordLength);
          // const newValue = currentValue.slice(0, lastWordIndex) + currentValue.slice(wordLength, currentValue.length);
          // setValue(newValue);
          // console.log(newValue);

          // // 커서가 맨 뒤인 경우 //
          // if (currentValue.length === start) currentValue.slice(0, lastWordIndex) + currentValue.slice(lastWordIndex + wordLength);
          // else 

          // console.log('--------');
          // console.log(currentValue.slice(0, lastWordIndex));
          // console.log('========');
          // console.log(currentValue.slice(lastWordIndex + wordLength));
          // console.log('lastWordIndex : ' + lastWordIndex);
          // console.log('currentValue.length : ' + currentValue.length);
          // console.log('start : ' + start);
          // console.log('lastWordIndex : ' + lastWordIndex);
          // console.log('wordLength : ' + wordLength);
          // console.log(word);
          // console.log(newValue);

          let byte = checkValueByte(newValue);

          setByte(byte);
          setValue(newValue);

          // 커서 위치 조정
          setTimeout(() => {
            event.target.selectionStart = lastWordIndex;
            event.target.selectionEnd = lastWordIndex;
          }, 0);
          // event.target.selectionStart = lastWordIndex;
          // event.target.selectionEnd = lastWordIndex;
          event.preventDefault(); // 기본 Backspace 동작 방지
        }
      }
    }
  }

  function handleCursorChange(event) {
    const textarea = InputRef.current;
    const cursorPosition = textarea.selectionStart;
    const text = textarea.value;

    // 특정 단어의 패턴 정의
    const wordPattern = /\{(.*?)\}/g; // 대괄호 안의 단어를 찾는 정규 표현식

    let match;
    // let found = false;

    while ((match = wordPattern.exec(text)) !== null) {
      const matchStart = match.index;
      const matchEnd = match.index + match[0].length;

      // 커서가 단어 안에 있는지 확인
      if (cursorPosition >= matchStart && cursorPosition <= matchEnd) {
        // 커서를 단어 뒤로 이동
        textarea.setSelectionRange(matchEnd, matchEnd);
        textarea.focus();
        // found = true;
        break;
      }
    }

    // 커서 위치를 기억
    setLastCursorPosition(textarea.selectionStart);
    // 단어 안에 커서가 없을 경우 아무 동작도 하지 않음
    // if (!found) {
    //     console.log('커서가 특정 단어 안에 있지 않습니다.');
    // }
  }

  return (
    <TopLevelWrapper>
      <InputView>
        <Input
          ref={InputRef}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onMouseUp={handleCursorChange}
          onKeyUp={handleCursorChange}
        />
      </InputView>
      <TotalByteWrapper>
        <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#595959' hover='default'>{byte < 90 ? 'SMS' : 'LMS'}</Text>
        <ByteWrapper>
          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#000000' hover='default'>{value === '' ? 0 : byte}</Text>
          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#000000' hover='default'>/</Text>
          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FF8B48' hover='default'>1,500 Byte</Text>
        </ByteWrapper>
      </TotalByteWrapper>
    </TopLevelWrapper>
  )
})

export default EditSettingMessageInput;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 20px;

  width: 100%
`;


const InputView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  padding: 8px 8px 8px 8px;

  width: calc(100% - 18px);
  height: 262px;

  overflow: hidden;

  border: 1px solid #969696;
  border-radius: 10px;
  background-color: #F9F9F9;
`;

const Input = styled.textarea`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: calc(100% - 2px);
  height: calc(100% - 2px);

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #232323;

  padding: 0px 0px 0px 0px;
  border: 0px;
  background-color: #F9F9F9;

  outline: none;
  resize: none;
	overflow: visible;

  transition: all 0.3s ease-in-out;

  ::placeholder {
    color: #CBCBCB;
  }
`;

const TotalByteWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: 10px;

  width: 100%;

  gap: 4px;
`;

const ByteWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: flex-start;

  // margin-top: 10px;

  // width: 100%;

  gap: 4px;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.hover};
  }
`;