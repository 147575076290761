import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import VideoIcon from '../../image/ProgramVideoIcon.png';
import VideoViewModal from './VideoViewModal';

export default function RenewalResultExerciseBox(props) {
  const { index, tag, reps, division, exerciseData, onClickMovementVideo } = props;

  const [movementName, setMovementName] = useState('');
  const [movementVideoLink, setMovementVideoLink] = useState('');
  const [isClickedVideoView, setIsClickedVideoView] = useState(false);

  useEffect(() => {
    setMovementName(exerciseData.movement.movementName);
    setMovementVideoLink(exerciseData.movement.videoLinkId);
  }, [exerciseData, tag, reps])


  // 한자리 숫자 앞에 0 붙이기 //
  function numberPad(n, width) {
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
  }

  return (
    <TopLevelWrapper>
      {isClickedVideoView && <VideoViewModal data={movementVideoLink} close={() => setIsClickedVideoView(!isClickedVideoView)} />}
      <TotalWrapper>
        <TitleLineWrapper>
          <TitleWrapper>
            <TitleNumberBox isMain={division === 'Main'}>
              <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>{numberPad(index + 1, 2)}</Text>
            </TitleNumberBox>
            <Text fontFamily='Poppins-Medium' fontSize={12} color='#4F4F4F'>{movementName}</Text>
          </TitleWrapper>
          <ButtonWrapper>
            {
              movementVideoLink !== '' &&
              <Button activeOpacity={0.8} onPress={() => onClickMovementVideo(movementVideoLink)}>
                <WithLocalSvg
                  width={20}
                  height={20}
                  src={VideoIcon} />
              </Button>
            }
          </ButtonWrapper>
        </TitleLineWrapper>
        <TextWrapper>
          {/* 무게 ( Weight ) */}
          {
            exerciseData.isUseWeight && (exerciseData.weightUnit === '%RM' || exerciseData.weightUnit === '%Body') &&
            <StandardWrapper>
              <InfoWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#666666'>기준</Text>
                <DivisionLine />
                <DataWrapper>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>{exerciseData.weight}</Text>
                  <UnitWrapper>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>{exerciseData.weightUnit}</Text>
                  </UnitWrapper>
                </DataWrapper>
              </InfoWrapper>
              <InfoWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#666666'>무게</Text>
                <DivisionLine />
                <DataWrapper>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>{exerciseData.conversionWeight}</Text>
                  <UnitWrapper>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>{exerciseData.conversionUnit}</Text>
                  </UnitWrapper>
                </DataWrapper>
              </InfoWrapper>
            </StandardWrapper>
          }
          {
            exerciseData.isUseWeight && (exerciseData.weightUnit !== '%RM' && exerciseData.weightUnit !== '%Body') &&
            <InfoWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#666666'>무게</Text>
              <DivisionLine />
              <DataWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>{exerciseData.weight}</Text>
                <UnitWrapper>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>{exerciseData.weightUnit}</Text>
                </UnitWrapper>
              </DataWrapper>
            </InfoWrapper>
          }
          {/* 거리 ( Distance ) */}
          {
            exerciseData.isUseDistance &&
            <InfoWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#666666'>거리</Text>
              <DivisionLine />
              <DataWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>{exerciseData.distance}</Text>
                <UnitWrapper>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>{exerciseData.distanceUnit}</Text>
                </UnitWrapper>
              </DataWrapper>
            </InfoWrapper>
          }
          {/* 높이 ( Height ) */}
          {
            exerciseData.isUseHeight &&
            <InfoWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#666666'>높이</Text>
              <DivisionLine />
              <DataWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>{exerciseData.height}</Text>
                <UnitWrapper>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>{exerciseData.heightUnit}</Text>
                </UnitWrapper>
              </DataWrapper>
            </InfoWrapper>
          }
          {/* 열량 ( Calories ) */}
          {
            exerciseData.isUseCalories &&
            <InfoWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#666666'>열량</Text>
              <DivisionLine />
              <DataWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#666666'>{exerciseData.calories}</Text>
                <UnitWrapper>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>{exerciseData.caloriesUnit}</Text>
                </UnitWrapper>
              </DataWrapper>
            </InfoWrapper>
          }
          {/* 횟수 ( Count ) */}
          {
            exerciseData.isUseCount &&
            (
              tag === 'ForTime-Different Reps' ?
                <RepsInfoWrapper>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#666666'>횟수</Text>
                  <RepsDivisionLine />
                  <Text style={{ width: '30%', textAlign: 'right' }} numberOfLines={5} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>{reps}</Text>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>Reps</Text>
                </RepsInfoWrapper>
                :
                <InfoWrapper>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#666666'>횟수</Text>
                  <DivisionLine />
                  <DataWrapper>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>{exerciseData.count}</Text>
                    <UnitWrapper>
                      <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>Reps</Text>
                    </UnitWrapper>
                  </DataWrapper>
                  {
                    tag === 'SINGLE' &&
                    <DataWrapper>
                      <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>{exerciseData.sets}</Text>
                      <UnitWrapper>
                        <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>Sets</Text>
                      </UnitWrapper>
                    </DataWrapper>
                  }
                </InfoWrapper>
            )
          }
          {/* 시간 ( Time ) */}
          {
            exerciseData.isUseTime &&
            <InfoWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#666666'>시간</Text>
              <DivisionLine />
              <DataWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>{parseInt(exerciseData.time / 60)}</Text>
                <UnitWrapper>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>MIN</Text>
                </UnitWrapper>
              </DataWrapper>
              <DataWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>{parseInt(exerciseData.time % 60)}</Text>
                <UnitWrapper>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>SEC</Text>
                </UnitWrapper>
              </DataWrapper>
            </InfoWrapper>
          }
          {/* 템포 ( Tempo ) */}
          {
            exerciseData.isUseTempo &&
            <InfoWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#666666'>템포</Text>
              <DivisionLine />
              <DataWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>{exerciseData.tempo}</Text>
                <UnitWrapper>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>Tempo</Text>
                </UnitWrapper>
              </DataWrapper>
            </InfoWrapper>
          }
        </TextWrapper>
        {
          (tag === 'SINGLE' && exerciseData.isUseRest) &&
          <SingleRestBox>
            <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>{'동작간 쉬는 시간 ' + exerciseData.rest + ' 초'}</Text>
          </SingleRestBox>
        }
      </TotalWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  padding: 0px 0px 0px 0px;

  width: 100%;
  
  overflow: hidden;

  margin-bottom: 10px;
  
  // background-color: #F9F9F9;
  background-color: #FFFFFF;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  padding: 12px 0px 16px 0px;

  width: 100%;
`;

const TitleLineWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 90.33%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;
  
  width: 72.45%;
`;

const TitleNumberBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-right: 8px;

  width: 27px;
  height: 18px;

  border-radius: 6px;
  background-color: ${props => props.isMain ? `#FF8B48` : `#6DD49E`};
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // width: 13.9%;
`;

const Button = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  
  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  // justify-content: center;
  // align-items: center; 

  width: 90.33%;
  margin-top: 10px;
`;

const StandardWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
  margin-top: 16px;
`;

const RepsInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  width: 100%;
  margin-top: 16px;
`;

const DataWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const UnitWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-left: 4px;
`;

const DivisionLine = styled.div`
  flex: 0.9;

  width: 100%;
  height: 1px;

  padding: 0px 12px 0px 12px;

  background-color: #E0E0E0;
`;

const RepsDivisionLine = styled.div`
  min-width: 40%;
  height: 1px;

  margin-top: 10px;
  padding: 0px 12px 0px 12px;

  background-color: #E0E0E0;
`;

const SingleRestBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 15px;

  width: 100%;
  height: 22px;

  background-color: #6DD49E;
`;

const WithLocalSvg = styled.img`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;