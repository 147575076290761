import { React, useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import FetchModule from '../../Model/Share/Network/FetchModule';

import SelectOnPath from '../../image/SelectOn.png';
import SelectOffPath from '../../image/SelectOff.png';
import OrangeSelectOnPath from '../../image/OrangeSelectOn.png';
import WhiteSelectOffPath from '../../image/WhiteSelectOff.png';

export default function LessonSelectBox(props) {
	const { defaultVal, setDefaultVal, originalLessonId } = props;

  const history = useHistory();

	const [isClicked, setIsClicked] = useState(false);
  const [options, setOptions] = useState([]);
	// const [defaultVal, setDefaultVal] = useState({ id: -1, name: '선택' });

  const TopLevelRef = useRef();
	const SelectRef = useRef();
	const OptionRef = useRef([]);

  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getDataVersion2('lesson/zone', 'GET');
        
        if (responseData.status === 200) {
					var newData = responseData.data;
	
          if (newData.length > 0) {
						setDefaultVal(newData[0]);

						SelectRef.current.style.backgroundColor = newData[0].color;
						SelectRef.current.style.border = '1px solid ' + newData[0].color;
					}

					setOptions(newData);
        }
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }, [history, SelectRef])

	// 이미 저장된 수업은 먼저 세팅해주기 //
	useEffect(() => {
		if (options.length === 0 || originalLessonId === 0) return;
		
		options.map((data) => {
			if (data.id === originalLessonId) {
				setDefaultVal(data);
				return;
			}
		})
	}, [options, originalLessonId])

  // 컴포넌트 밖을 클릭하면 셀렉트 창 닫기
  useEffect(() => {
    document.addEventListener("mousedown", onClickOutSide);

    return () => {
      document.removeEventListener("mousedown", onClickOutSide);
    }
  }, [onClickOutSide])

  // 컴포넌트 밖을 클릭하면 셀렉트 창 닫기
  function onClickOutSide(e) {
    if (TopLevelRef.current !== e.target && !TopLevelRef.current.contains(e.target)) {
			SelectRef.current.style.backgroundColor = defaultVal.color;
			SelectRef.current.style.border = '1px solid ' + defaultVal.color;
			// SelectRef.current.style.border = '1px solid #CBCBCB';
			SelectRef.current.style.height = `38px`;
			SelectRef.current.style.overflow = 'hidden';
      setIsClicked(false);
    }
  }

	function onClick() {
		if (isClicked) {
			// SelectRef.current.style.border = '1px solid #CBCBCB';
			// SelectRef.current.style.backgroundColor = defaultVal.color;
			// SelectRef.current.style.border = '1px solid ' + defaultVal.color;

			SelectRef.current.style.height = `38px`;
			SelectRef.current.style.overflow = 'hidden';
			setIsClicked(false);
		}
		else {
			SelectRef.current.style.backgroundColor = '#FFFFFF';
			SelectRef.current.style.border = '1px solid #FF8B48';
			SelectRef.current.style.height = 32 * (options.length) > 500 ? `500px` : (32 * (options.length) + 'px');
			SelectRef.current.style.maxHeight = `500px`;
			SelectRef.current.style.overflowY = 'scroll';
			SelectRef.current.style.overflowX = 'none';
			setIsClicked(true);
		}
	}

	function optionalClick(index) {
		SelectRef.current.style.backgroundColor = options[index].color;
		SelectRef.current.style.border = '1px solid ' + options[index].color;
		// console.log(options[index])
		setDefaultVal(options[index]);
		// selectFn(options[index].id);
	}

	function onMouseEnter(index) {
		OptionRef.current[index].style.backgroundColor = '#FF8B48';
	}

	function onMouseLeave(index) {
		OptionRef.current[index].style.backgroundColor = '#FFFFFF';
	}

	return (
		<TopLevelWrapper ref={TopLevelRef}>
			<SelectBox
				ref={SelectRef} 
				isClicked={isClicked}
				buttonPath={isClicked ? OrangeSelectOnPath : WhiteSelectOffPath}
				onClick={onClick}>
				{
					isClicked ?
						options.map((data, index) =>
							<OptionWrapper 
								ref={(element) => OptionRef.current[index] = element} 
								key={index} 
								isClicked={isClicked} 
								onMouseEnter={() => onMouseEnter(index)}
								onMouseLeave={() => onMouseLeave(index)}
								onClick={() => optionalClick(index)}>
								<TextWrapper>
									<Option>{data.name}</Option>
								</TextWrapper>
							</OptionWrapper>
						)
						:
						<OptionWrapper isClicked={isClicked}>
							<Option>
								<TextWrapper>
								{
									defaultVal === undefined ? '수업 없음' : defaultVal.name
								}
								</TextWrapper>
							</Option>
						</OptionWrapper>
				}
			</SelectBox>
		</TopLevelWrapper>
	)
}

const TopLevelWrapper = styled.div`
	display: flex;
	flex-direction: column;

	width: 196px;
	height: 40px;
`;

const SelectBox = styled.div`
	position: absolute;

	display: flex;
	flex-direction: column;

  justify-content: center;
	align-items: flex-start;

	width: 194px;
	// width: 
	height: 38px;

  z-index: ${props => props.isClicked ? 5 : 0};

	border: 1px solid #CBCBCB;
	border-radius: 15px;
	overflow: hidden;

  background: url(${props => props.buttonPath}) no-repeat 96% ${props => props.isClicked ? `3` : `55`}%;
	background-color: rgba(255, 255, 255, 1);
  transition: all 0.3s ease-in-out;

	:hover {
		cursor: pointer;
	}

	&::-webkit-scrollbar {
		// width: 9px;
		width: 0px;
	}

	&::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 20px #FF8B48;
		border: 3px solid transparent;
		height: 25px;
		// background-color: #FF8B48;
		border-radius: 200px;
	}

	&::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px transparent;
		border: 4px solid transparent;
		background-color: transparent;
	}

	&::-webkit-scrollbar-track-piece {
		background-color: transparent;
	}
`;

const OptionWrapper = styled.div`
	display: flex;
	flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  // margin-left: 16px;
	padding-left: 16px;

	// width: calc(100% - 40px);
	width: calc(100% - 16px);
  min-height: 32px;
	height: 32px;

  color: ${props => props.isClicked ? `#595959` : `#FFFFFF`};

	:hover {
		cursor: pointer;
    // color: #FF8B48;
	}

  transition: all 0.3s ease-in-out;
`;

const TextWrapper = styled.div`
	display: flex;
	flex-direction: row;

	justify-content: flex-start;
	align-items: flex-start;

	width: 100%;
	height: 21px;

	overflow: hidden;
`;

const Option = styled.p`
	font-family: NotoSansKR-Medium;
	font-size: 14px;

  // color: #595959;

  margin: 0 0 0 0;
`;