import React from 'react';
import styled from 'styled-components';

import WhitePencelIcon from '../../image/WhitePencel.svg';
import ProvisionTextContent from './ProvisionTextContent';
import DefaultProvisionTextContent from './DefaultProvisionTextContent';
import moment from 'moment';
import TermBox from './TermBox';

export default function TermContract(props) {
  const {
    isClicked,
    terms,
    onClickModifyTerms,
    provisions,
    defaultProvisions,

    onClickTermsStatus,

    onClickViewTerms,
    onClickEditTerms,
    onClickDeleteTerms,

    onClickModifyProvision,
    onClickDeleteProvision
  } = props;

  return (
    <TopLevelWrapper>
      <TitleWrapper>
        <TitleBox backgroundColor='#F17DB4'>
          <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF' hover='default'>전자 계약</Text>
        </TitleBox>
        <ModifyButton isClicked={isClicked} onClick={onClickModifyTerms}>
          {!isClicked && <PencelImage src={WhitePencelIcon} />}
          <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF' hover='pointer'>{isClicked ? `저장하기` : `수정하기`}</Text>
        </ModifyButton>
      </TitleWrapper>
      <InfoView>
        <InnerTextWrapper>
          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#232323' hover='default'>만들어진 계약서</Text>
        </InnerTextWrapper>
        <TermsViewWrapper style={ !isClicked && terms.length === 0 ? { justifyContent: 'center', alignItems: 'center' } : {}}>
          {
            !isClicked && terms.length === 0 ?
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#777777' hover='pointer'>만들어진 계약서가 없습니다</Text>
              :
              terms.map((data, index) => (
                <TermBox 
                  key={'Terms-' + index} 
                  data={data}
                  index={index}
                  isClicked={isClicked}
                  onClickTermsStatus={onClickTermsStatus}
                  onClickViewTerms={onClickViewTerms}
                  onClickEditTerms={onClickEditTerms}
                  onClickDeleteTerms={onClickDeleteTerms}/>
              ))
          }
          {
            isClicked &&
            <PlusBox isEmpty={terms.length === 0} onClick={() => onClickEditTerms(undefined)}>
              <StickWrapper width={50} height={50}>
                <Stick width={50} height={6} radius={20} backgroundColor='#969696' />
                <Stick width={6} height={50} radius={20} backgroundColor='#969696' />
              </StickWrapper>
            </PlusBox>
          }
        </TermsViewWrapper>

        <ProvisionWrapper>
          {
            provisions.map((data, index) => (
              <ProvisionTextContent
                key={'provision-' + index}
                index={index}
                data={data}
                lastIndex={provisions.length > 0 ? provisions.length - 1 : 0} 
                onClickModifyProvision={onClickModifyProvision}
                onClickDeleteProvision={onClickDeleteProvision}/>
            ))
          }
        </ProvisionWrapper>

        <InnerTextWrapper style={{ marginTop: '14px' }}>
          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#232323' hover='default'>약관 항목 설정</Text>
        </InnerTextWrapper>
        <AddProvisionButtonWrapper>
          <AddProvisionButton onClick={onClickModifyProvision}>
            <StickWrapper width={14} height={14}>
              <Stick width={14} height={2} radius={2} backgroundColor='#969696' />
              <Stick width={2} height={14} radius={2} backgroundColor='#969696' />
            </StickWrapper>
            <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#969696' hover='pointer'>규정 추가 하기</Text>
          </AddProvisionButton>
        </AddProvisionButtonWrapper>

        <InnerTextWrapper style={{ marginTop: '40px' }}>
          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#232323' hover='default'>동의서 설정</Text>
        </InnerTextWrapper>
        <DefaultProvisionWrapper>
          {
            defaultProvisions.map((data, index) => (
              <DefaultProvisionTextContent
                key={'DefaultProvision-' + index}
                index={index}
                data={data} />
            ))
          }
        </DefaultProvisionWrapper>
      </InfoView>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 30px;

  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const TitleBox = styled.p`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 2px 20px;
  margin: 0 0 0 0;

  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  background-color: ${props => props.backgroundColor};
`;

const ModifyButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 82px;
  height: 24px;

  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  
  background-color: ${props => props.isClicked ? `#6DD49E` : `#CBCBCB`};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const InfoView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 10px 20px;

  width: calc(100% - 40px);

  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  background-color: #FFFFFF;
`;

const InnerTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  padding: 4px 0px 14px 0px;

  width: 1115px;

  border-bottom: 2px solid #F8F8F8;
`;

const TermsViewWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  padding: 10px 15px;
  margin-top: 10px;

  width: calc(100% - 20px);
  min-height: 150px;

  gap: 10px;

  border-radius: 10px;
  background-color: #F8F8F8;
  // background-color: #FCFCFC;
`;

const PencelImage = styled.img`
  width: 20px;
  height: 20px;

  :hover {
    cursor: pointer;
  }
`;

const PlusBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: ${props => props.isEmpty ? `0px` : `34.5px`};

  width: 150px;
  height: 150px;

  border-radius: 15px;
  background-color: #ECECEC;
  
  :hover {
    cursor: pointer;
  }
`;

const StickWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;

const Stick = styled.div`
  position: absolute;

  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: ${props => props.radius}px;
  background-color: ${props => props.backgroundColor};

  :hover {
    cursor: pointer;
  }
`;

const ProvisionWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 10px;

  width: 100%;
  min-height: 100px;

  // border: 1px solid #969696;
  // border-radius: 10px;
  background-color: #FFFFFF;
`;

const AddProvisionButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  margin-top: 10px;

  width: 100%;
`;

const AddProvisionButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  padding: 0px 12px;

  height: 30px;

  gap: 4px;

  border-radius: 5px;
  background-color: #ECECEC;

  :hover {
    cursor: pointer;
  }
`;


const DefaultProvisionWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 10px;

  width: calc(100% - 4px);
  min-height: 100px;

  border: 2px solid #DBDBDB;
  border-radius: 10px;
  background-color: #FFFFFF;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  margin: 0 0 0 0;

  color: ${props => props.color};

  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;