import { React, useRef, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const WaittingMemberInfo = (props) => {
  const { data, index, isIgnore, onClickedAgree, onClickedIgnore } = props;

  const history = useHistory();

  const [gender, setGender] = useState('');
  const [birth, setBirth] = useState('');
  const [memberShip, setMemberShip] = useState('-');

  const RegistrationRef = useRef();
  const ExitRef = useRef();

  useEffect(() => {
    var gender = '';
    var birth = '';

    if (data.birthDay[7] === '1') {
      gender = '남성';
      birth = "19" + data.birthDay[0] + data.birthDay[1] + '.' + data.birthDay[2] + data.birthDay[3] + '.' + data.birthDay[4] + data.birthDay[5];
    }
    else if (data.birthDay[7] === '2') {
      gender = '여성';
      birth = "19" + data.birthDay[0] + data.birthDay[1] + '.' + data.birthDay[2] + data.birthDay[3] + '.' + data.birthDay[4] + data.birthDay[5];
    }
    else if (data.birthDay[7] === '3') {
      gender = '남성';
      birth = "20" + data.birthDay[0] + data.birthDay[1] + '.' + data.birthDay[2] + data.birthDay[3] + '.' + data.birthDay[4] + data.birthDay[5];
    }
    else if (data.birthDay[7] === '4') {
      gender = '여성';
      birth = "20" + data.birthDay[0] + data.birthDay[1] + '.' + data.birthDay[2] + data.birthDay[3] + '.' + data.birthDay[4] + data.birthDay[5];
    }

    if (data.memberShip === undefined) setMemberShip('-');

    setGender(gender);
    setBirth(birth);
  }, [data])

  // 회원 정보 클릭시 //
  function onClickWaitingMember(e) {
    if (ExitRef.current === e.target || RegistrationRef.current === e.target) return;

    if (data.memberId === -1) {
      alert("아직 체육관에 등록되어있지 않습니다.\n회원권을 등록해주세요.");
      return;
    }

    history.push('valid/detail/' + data.memberId);
  }

  return (
    <TopLevelWrapper onClick={onClickWaitingMember}>
      <Table>
        <Body>
          <Tr style={{ width: '68px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '58px' }}>
                <Text>{data.userName}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '51px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '26px' }}>
                {/* <Text>남성</Text> */}
                <Text>{gender}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '97px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '70px' }}>
                <Text>{birth}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '115px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '115px' }}>
                <Text>{data.phone}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '94px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '94px' }}>
                <Text>{data.memberState}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '177px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '177px' }}>
                <Text>{data.prevMemberShip === undefined ? '없음' : data.prevMemberShip.name}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '169px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '169px' }}>
                <Text>{data.itemName}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '116px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '70x' }}>
                <Text>{data.createdDate.split('T')[0].replaceAll('-', '.')}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={isIgnore ? { width: '60px'} : { width : '41px' }}>
            <Td>
              <ExitButton ref={ExitRef} style={{ minWidth: '28px'}} onClick={() => onClickedIgnore(data.userId, index, isIgnore)}>{isIgnore ? `영구삭제` : `거절`}</ExitButton>
            </Td>
          </Tr>
          <Tr style={{ width: '41px' }}>
            <Td>
              {!isIgnore && <RegistrationButton ref={RegistrationRef} style={{ minWidth: '41px'}} onClick={() => onClickedAgree(data.userId, index)}>{`등록`}</RegistrationButton>}
            </Td>
          </Tr>
        </Body>
      </Table>
    </TopLevelWrapper>
  )
};

export default WaittingMemberInfo;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  // justify-content: space-around;
  // justify-content: center;
  align-items: center;

  width: 1018px;
  height: 50px;

  background: #FFFFFF;

  margin-top: 8px;

  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  border: 1px solid #FFFFFF;
  border-radius: 30px;
  
  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #FF8B48;
  }
`;

const Table = styled.table`
  display: flex;
  flex-direction: row;
`;

const Body = styled.tbody`
  display: flex;
  flex-direction: row;
  
  margin-left: 28px;
`;

const Tr = styled.tr`
  display: flex;
  flex-direction: row;
`;

const Td = styled.td`
  display: flex;
  flex-direction: row;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  // justify-content: center;
  justify-content: flex-start;
  align-items: flex-start;

  overflow: hidden;

  max-height: 20px;
  // border: 1px solid #232323;
`;

const Text = styled.span`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;

  margin: 0 0 0 0;
`;

const RegistrationButton = styled.span`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  // color: #FFFFFF;
  color: #FF8B48;

  margin: 0 0 0 0;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    font-family: NotoSansKR-Bold;
  }
`;

const ExitButton = styled.span`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  // color: #FFFFFF;
  color: #777777;

  margin: 0 0 0 0;
  
  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;