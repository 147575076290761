import { useState, useRef, React, useEffect } from 'react';
import styled from 'styled-components';

export default function LegacyDescriptionTextArea(props) {
	const { value, setValue } = props;
  
	const [changed, setChanged] = useState(false);

	const inputRef = useRef();

	useEffect(() => {
    inputRef.current.style.height = 'auto';
		inputRef.current.style.height = inputRef.current.scrollHeight + `px`;
	}, [])

	function handleChange(e) {
    inputRef.current.style.height = 'auto';
    inputRef.current.style.height = inputRef.current.scrollHeight + `px`;

		setValue(e.target.value);
		setChanged(true);
	}

	function handleFocus(e) {
		// setChangedPlaceholderColor(false);
	}

	function handleBlur(e) {
		// setClickCount(clickCount + 1);
	}

  function onPress(e) {
    // if (e.key === 'Enter') {
    //   inputRef.current.style.height = 'auto';
    //   // inputRef.current.style.height = `${(inputRef.current.scrollHeight + 9)}px`;
    //   console.log(inputRef.current.scrollHeight);
    //   inputRef.current.style.height = inputRef.current.scrollHeight + `px`;
    // }
  }

 
	return (
    <InputWrapper>
      <Input
        ref={inputRef}
        value={value}
        rows={1}
        placeholder='오늘의 운동을 입력해주세요'
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyPress={onPress} />
    </InputWrapper>
  )
}

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // padding: 0px;
  padding: 8px 0px 8px 0px;
  margin: 0px;

  // width: 512px;
  width: 470px;
  // height: 544px;
  // max-height: 544px;

  border: 1px solid #E0E0E0;
  border-radius: 15px;
  background-color: #FFFFFF;
`;

const Input = styled.textarea`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: calc(100% - 34px);
  // height: 100%;
  // height: calc(100% - 18px);
  
  resize: none;
  // overflow-y: hidden;
  // overflow-y: scroll;
  // overflow-x: none;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;
  border: 0px solid #DFDFDF;

  padding: 0px;
  margin: 0px;

	outline: none;

  background-color: #FFFFFF;
  // background-color: red;

	::placeholder {
    color: #CBCBCB;
	}

  // &::-webkit-scrollbar {
  //   width: 2px;
  // }

  // &::-webkit-scrollbar-thumb {
	// 	border: 3px solid transparent;
	// 	// height: 92px;
	// 	background-color: #DADADA;
	// 	border-radius: 200px;
	// }

  // &::-webkit-scrollbar-track {
  //   // height: 439px;
	// 	// border: 4px solid transparent;
	// 	background-color: #F0F0F0;
	// 	border-radius: 200px;
	// }
`;