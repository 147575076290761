import { forwardRef, React, useEffect, useImperativeHandle, useRef, useState } from 'react';
import styled, { keyframes }from 'styled-components';

import AccountInput from '../../../Component/AccountComponent/AccountInput.js';
import SignUpSelectBox from '../../../Component/AccountComponent/SignUpSelectBox.js';

const SignUpInfo3 = forwardRef((props, ref) => {
	const [storeClickCount, setStoreClickCount] = useState(0);
	const [typeClickCount, setTypeClickCount] = useState(0);
	const [telClickCount, setTelClickCount] = useState(0);

	const TopLevelRef = useRef();
	const StoreRef = useRef();
	const TypeRef = useRef();
	const TelRef = useRef();
	const StorePlanationRef = useRef();
	const TelPlanationRef = useRef();

	useImperativeHandle(ref, () => ({
		getStoreName: () => {
			return StoreRef.current.getValue();
		},
		getStoreType: () => {
			return TypeRef.current.getValue();
		},
		getStoreTel: () => {
			return TelRef.current.getValue();
		},
		isBlankStoreName: () => {
			StoreRef.current.setDefaultPlaceholder('센터의 공식명칭을 입력해주세요');
			StoreRef.current.plusClickCount();
			StorePlanationRef.current.style.opacity = 1;
		},
		isBlankStoreTel: () => {
			TelRef.current.setDefaultPlaceholder('센터 대표 전화 번호를 입력해주세요');
			TelRef.current.plusClickCount();
			TelPlanationRef.current.style.opacity = 1;
		},
		setOpacity: (val) => {
			TopLevelRef.current.style.opacity = val;
		},
		setLocation: (val) => {
			TopLevelRef.current.style.transform = `translateX(${val}%)`;
			setStoreClickCount(0);
			setTypeClickCount(0);
			setTelClickCount(0);
			StoreRef.current.reset('센터의 공식명칭을 입력해주세요');
			TelRef.current.reset('센터 대표 전화 번호를 입력해주세요');
		}
	}));

	useEffect(() => {
		if (storeClickCount === 0 && telClickCount === 0) return;
		
		if (StoreRef.current.getValue() === '' && storeClickCount > 0) StorePlanationRef.current.style.opacity = 1;
		else StorePlanationRef.current.style.opacity = 0;
		
		if (TelRef.current.getValue() === '' && telClickCount > 0) TelPlanationRef.current.style.opacity = 1;
		else TelPlanationRef.current.style.opacity = 0;
		
	}, [storeClickCount, typeClickCount, telClickCount])

	function clickCountFn(className, clickCount) {
		if (className === 'StoreInput') setStoreClickCount(clickCount);
		else if(className === 'TelInput') setTelClickCount(clickCount);
	}

	return (
		<TopLevelWrapper ref={TopLevelRef}>
			<InputInfo>상호명</InputInfo>
			<AccountInput ref={StoreRef} className='StoreInput' placeholder='센터의 공식명칭을 입력해주세요' type='text' clickCountFn={clickCountFn}/>
			<AccountExplanation ref={StorePlanationRef}>필수항목을 입력해주세요</AccountExplanation>
			<InputInfo isSelect={true}>센터 타입</InputInfo>
			<SignUpSelectBox ref={TypeRef} className='TypeInput'/>
			<InputInfo>센터 전화 번호</InputInfo>
			<AccountInput ref={TelRef} className='TelInput' placeholder='센터 대표 전화 번호를 입력해주세요' type='text' clickCountFn={clickCountFn}/>
			<AccountExplanation ref={TelPlanationRef}>필수항목을 입력해주세요</AccountExplanation>
		</TopLevelWrapper>
	)
})

export default SignUpInfo3;

const startAnimation = keyframes`
	0% { opacity: 0; transform: translateX(150%); }
	10% { opacity: 0.1; transform: translateX(130%); }
	20% { opacity: 0.2; transform: translateX(100%); }
	30% { opacity: 0.3; transform: translateX(70%); }
	40% { opacity: 0.4; transform: translateX(40%); }
	50% { opacity: 0.5; transform: translateX(20%); }
	60% { opacity: 0.6; transform: translateX(0%); }
	70% { opacity: 0.7;}
	80% { opacity: 0.8; }
	90% { opacity: 0.9;}
	100% { opacity: 1;}
`;


const TopLevelWrapper = styled.div`
	position: absolute;
	display: flex;

	flex-direction: column; 
	justify-content: flex-start;

	width: 468px;
	// width: max(271px, 24.375vw);
	// height: 281px;

	margin-top: 159px;
	// margin-top: max(100px, 8.28125vw);

	background-color: rgba(255, 255, 255, 1);

	// opacity: 1;
	// z-index: 2;
	// transform: translateX(150%);

	animation-name: ${startAnimation};
	animation-duration: 0.5s;
	animation-timing-function: ease-out;

  transition: all 0.3s ease-in-out;
`;

const InputInfo = styled.p`
	font-family: NotoSansKR-Regular;
	font-size: 14px;
	// font-size: max(12px, 0.7291666666666667vw);
	// line-height: 20px;
 
	// margin: 40px 0 0 0;
	margin: ${props => props.tel ? `80px 0 0 0` : `19px 0 0 0`};
	// margin-top: ${props => props.isSelect ? `max(31.73px, 2.083333333333333vw)` : `19px`};
	// margin-top: 16px;
	// margin-right: 0px;
	// margin-bottom: 0px;
	// margin-left: 0px;

	cursor: default;
`;

const AccountExplanation = styled.p`
	font-family: NotoSansKR-Regular;
	font-size: 12px;
	// font-size: max(12px, 0.625vw);
	// line-height: 17.38px;

	color: #FF3131;

	margin: 4px 0 0 0;

	opacity: 0;
  
	transition: all 0.5s ease-in-out;
`;

