import { React, useRef, forwardRef, useImperativeHandle, useEffect } from 'react';
import styled from 'styled-components';

import LockerChangeNameInput from '../../../Component/LockerComponent/LockerChangeNameInput';

const LockerChangeNameModal = forwardRef((props, ref) => {
  const { exitClick, saveClick, data, index } = props;

  const InputRef = useRef();

  useImperativeHandle(ref, () => ({
		getValue: () => {
      return InputRef.current.getValue();
    }
	}));

  // Modal Page Scroll Controll //
  useEffect(() => {
    document.body.style= `overflow: hidden`;
    return () => document.body.style = `overflow: auto`;
  }, [])

  useEffect(() => {
    if (index === -1) return;

    InputRef.current.setValue(data[index].lockerName);
  }, [data, index])

  return (
    <TopLevelWrapper>
      <ModalView>
        <TotalWrapper>
        <Title>이름 변경</Title>
        <LockerChangeNameInput ref={InputRef}/>
        <ButtonWrapper>
          <ExitButton onClick={exitClick}>취소</ExitButton>
          <SaveButton onClick={() => saveClick(InputRef.current.getValue())}>저장하기</SaveButton>
        </ButtonWrapper>
        </TotalWrapper>
      </ModalView>
    </TopLevelWrapper>
  )
})

export default LockerChangeNameModal;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 1000;

  width: 100vw;
  height: 100vh;

  overflow-y: scroll;

  background-color: rgba(0, 0, 0, 0.2);
`;

const ModalView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 266px;
  height: 228px;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(0, 0, 0, 0.1);
  background-color: #FFFFFF;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: 0 0 0 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 16px;
  margin-top: 36px;
  margin-left: 20px;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 72px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;

  transition: all 0.2s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #777777;
    color: #777777;
  }
`;

const SaveButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 15px;
  background-color: #5BD98A;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }
`;