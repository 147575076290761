import { React, useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';
import moment from 'moment';

const WeeklyColumn = forwardRef((props, ref) => {
  const { today, standardDate } = props;
  // const [startDay, setStartDay] = useState(moment());
  const [dateList, setDateList] = useState([today]);

  // useImperativeHandle(ref, () => ({
  //   setStartDay: (val) => {
  //     setStartDay(val)
  //   }
  // }));
 
  useEffect(() => {
    var newDateList = [];

    for (var i = 0; i < 7; i++) {
      newDateList.push(today.clone().add(i, 'day'));
    }

    setDateList(newDateList);
  }, [today])

  // 몇주차인지 계산하기 //
  function getWeekNumber(date) {
    const standard = new Date(date);

    const currentDate = standard.getDate();
    const startOfMonth = new Date(standard.setDate(1));
    const weekDay = startOfMonth.getDay();

    return parseInt(((weekDay - 1) + currentDate) / 7) + 1;
  }

  return (
    <TopLevelWrapper>
      <WeekBox>
        <Text fontFmaily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF' hover='default'>{getWeekNumber(standardDate) + '주차'}</Text>
      </WeekBox>
        {
          dateList.map((data, index) => (
            index === 0 ?
              <FirstColumnWrapper key={index}>
                {/* <Column>{data.format('DD일 (dd)')}</Column> */}
                <Text fontFmaily='NotoSansKR-Bold' fontSize={14} color={data.format('YYYY.MM.DD') === moment().format('YYYY.MM.DD') ? `#FF8B48` : `#595959`} hover='default' >{data.format('DD일 (dd)')}</Text>
              </FirstColumnWrapper>
              :
              <ColumnWrapper key={index}>
                {/* <Column>{data.format('DD일 (dd)')}</Column> */}
                <Text fontFmaily='NotoSansKR-Bold' fontSize={14} color={data.format('YYYY.MM.DD') === moment().format('YYYY.MM.DD') ? `#FF8B48` : `#595959`} hover='default' >{data.format('DD일 (dd)')}</Text>
              </ColumnWrapper>
          ))
        }
    </TopLevelWrapper>
  )
})

export default WeeklyColumn;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  // width: 911px;
  // width: 962px;
  width: 974px;

  gap: 6px;
  // margin-left: 25px;
  margin-bottom: 28px;
`;

const WeekBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 71px;
  height: 23px;

  border-radius: 4px;
  background-color: #FF8B48;
`;

const FirstColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  padding-left: 10px;

  // width: 89px;
  width: 83px;
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // width: 125px;
  width: 123px;
`;

const Column = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 14px;

  color: #595959;

  margin: 0 0 0 0;
  
  transition: all 0.3s ease-in-out;
`;

const Text = styled.p`
  font-family: ${props => props.fontFmaily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.hover};
  }
`;