import React from "react";
import styled from "styled-components";

export default function SettingInput(props) {
  const { value, setValue, placeholder } = props;


	function handleClick(e) {
	}

	function handleChange(e) {
    let regex = /[^0-9]/g;				// 숫자가 아닌 문자열을 매칭하는 정규식
    let result = e.target.value.replace(regex, "");	// 원래 문자열에서 숫자가 아닌 모든 문자열을 빈 문자로 변경
   
    if (e.target.value === '') setValue(0);
		else setValue(parseInt(result));
	}

	function handleFocus(e) {
	}

	function handleBlur(e) {
	}

  return (
    <InputView>
      <Input
        placeholder={placeholder}
        value={value}
        onClick={handleClick}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}/>
    </InputView>
  );
}

const InputView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 0;

  width: calc(100% - 2px);
  height: calc(100% - 2px);

  border: 1px solid #DFDFDF;
  border-radius: 5px;
  background-color: #FFFFFF;
`;

const Input = styled.input`
	font-family: NotoSansKR-Regular;
	font-size: 12px;
	line-height: 20px;

  padding: 0;

  width: calc(100% - 20px);
  height: 100%;

	outline: none;
  border: 0px;
	color: #777777;

	::placeholder {
    color: #CBCBCB;
	}
	
  transition: all 0.3s ease-in-out;
`;