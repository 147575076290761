import { React, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import Lottie from 'lottie-react-web';

import MemberList from './MemberList';

import Duration from '../../../image/DurationImage.png';
import NewImage from '../../../image/NewImage.png';

import LoadingAnimation from '../../../Animation/LoadingAnimation.json';
import moment from 'moment';

const MaintainMemberAndNewMemberView = (props) => {
  const {
    allMembers,
    salesData,
    startDate,
    endDate,
    calcDateText,
    // aliveData,
    // leaveData,
    selectedPartnerGym,
    isOpenPartnerGymSales,
    isLoadingAllMember,
    isAliveLoading,
    setIsAliveLoading,
    durationAvg,
    durationMembers,
    isDurationLoading
  } = props;

  const history = useHistory();
  const [newMembers, setNewMembers] = useState([]);
  const [newMemberPrice, setNewMemberPrice] = useState(0);

  // 신규 등록 회원 구분 //
  useEffect(() => {
    if (isLoadingAllMember) return;

    let newMembers = [];

    for (let i = 0; i < allMembers.length; i++) {
      let isReregist = false;

      for (let j = 0; j < allMembers[i].usages.length; j++) {
        if ((startDate.isBefore(allMembers[i].usages[j].startDate) || startDate.isSame(allMembers[i].usages[j].startDate))
          && (endDate.isAfter(allMembers[i].usages[j].startDate) || endDate.isSame(allMembers[i].usages[j].startDate))
          && allMembers[i].usages.length <= 1) {
          allMembers[i].startDate = allMembers[i].usages[j].startDate;
          isReregist = true;
          break;
        }
        // else isReregist = false;
      }

      if (isReregist) newMembers.push(allMembers[i]);
    }


    setNewMembers(newMembers);

    setTimeout(() => {
      setIsAliveLoading(false);
    }, 1000)
  }, [startDate, endDate, allMembers, isLoadingAllMember])


  useEffect(() => {
    if (salesData.length === 0 || allMembers.length === 0) return;

    let newMembers = [];
    let newMemberPrice = 0;

    for (let i = 0; i < allMembers.length; i++) {
      for (let j = 0; j < allMembers[i].usages.length; j++) {
        if ((startDate.isBefore(allMembers[i].usages[j].approvalDate) || startDate.isSame(allMembers[i].usages[j].approvalDate))
          && (endDate.isAfter(allMembers[i].usages[j].approvalDate) || endDate.isSame(allMembers[i].usages[j].approvalDate))
          && allMembers[i].usages.length <= 1) {
          newMembers.push(allMembers[i]);
          break;
        }
      }
    }
    for (let i = 0; i < salesData.length; i++) {
      let isExist = false;
      // if (salesData[i].name === '노예린') console.log(salesData[i]);

      for (let j = 0; j < newMembers.length; j++) {
        if (salesData[i].memberId === newMembers[j].memberId) {
          isExist = true;
          break;
        }
      }

      if (isExist) {
        newMemberPrice += (salesData[i].cardPrice + salesData[i].cashPrice);
        // console.log(salesData[i]);
      }

    }

    setNewMemberPrice(newMemberPrice);
  }, [salesData, allMembers])

  // useEffect(() => {
  //   let newMemberData = [];
  //   let memberPrice = 0;

  //   for (let i = 0; i < aliveData.length; i++) {
  //     if (startDate.isBefore(aliveData[i].startDate)
  //       && endDate.isAfter(aliveData[i].startDate)
  //       && aliveData[i].alreadyMembershipCount === 1) {
  //         newMemberData.push(aliveData[i]);
  //     }
  //   }

  //   newMemberData.sort(function (a, b) {
  //     let x = a.startDate;
  //     let y = b.startDate;

  //     if (x < y) return 1;
  //     if (x > y) return -1;
  //   })

  //   // setNewMemberPrice(memberPrice);
  //   setNewMember(newMemberData);

  //   setTimeout(() => {
  //     setIsAliveLoading(false)
  //   }, 1000)
  // }, [startDate, endDate, aliveData])

  // useEffect(() => {
  //   if (salesData.length === 0) return;

  //   let mergeDates = [];
  //   let finalDates = [];
  //   let finalPrice = 0;

  //   mergeDates = aliveData.concat(leaveData);

  //   for (let i = 0; i < mergeDates.length; i++) {
  //     if ((mergeDates[i].alreadyMembershipCount <= 1)
  //       && (startDate.isBefore(mergeDates[i].startDate) || startDate.isSame(mergeDates[i].endDate))
  //       && (endDate.isAfter(mergeDates[i].startDate) || endDate.isSame(mergeDates[i].endDate))) {
  //         let isExist = false;

  //         for (let j = 0; j < finalDates.length; j++) {
  //           if (mergeDates[i].memberId === finalDates[j].memberId) {
  //             isExist = true;
  //             break;
  //           }
  //         }

  //         if (!isExist) finalDates.push(mergeDates[i]);
  //       }
  //   }

  //   for (let i = 0; i < mergeDates.length; i++) {
  //     if ((mergeDates[i].alreadyMembershipCount <= 1)
  //       && (startDate.isBefore(mergeDates[i].approvalDate) || startDate.isSame(mergeDates[i].approvalDate))
  //       && (endDate.isAfter(mergeDates[i].approvalDate) || endDate.isSame(mergeDates[i].approvalDate))) {
  //         let isExist = false;

  //         for (let j = 0; j < finalDates.length; j++) {
  //           if (mergeDates[i].memberId === finalDates[j].memberId) {
  //             isExist = true;
  //             break;
  //           }
  //         }

  //         if (!isExist) finalDates.push(mergeDates[i]);
  //       }
  //   }

  //   // console.log('new_len : ' + finalDates.length)
  //   for (let i = 0; i < salesData.length; i++) {
  //     for (let j = 0; j < finalDates.length; j++) {
  //       if (salesData[i].memberId === finalDates[j].memberId) {
  //         finalPrice += (salesData[i].cardPrice + salesData[i].cashPrice);
  //         break;
  //       }
  //     }
  //   }

  //   setNewMemberPrice(finalPrice);
  // }, [startDate, endDate, aliveData, leaveData, salesData])


  // 전화번호 표시 //
  function calcPhoneView(data) {
    if (data === '') return '없음';
    else return data.slice(0, 3) + '-' + data.slice(3, 7) + '-' + data.slice(7, 11);
  }

  // 장기 지속일 표시 //
  function calcDuration(data) {
    if (data < 1) return '총 01개월';
    else {
      if (data < 10) return '총 0' + Math.floor(data) + '개월';
      else return '총 ' + Math.floor(data) + '개월';
    }
  }

  // 회원 클릭시 페이지 넘기기 //
  function onClickDurationMember(memberId) {
    if (selectedPartnerGym === undefined || selectedPartnerGym.isMyStore) history.push('/member/valid/detail/' + memberId);
    else history.push('/member/partner_gym/detail/' + memberId);
  }

  return (
    <TopLevelWrapper>
      <InfoBox>
        <Title>{calcDateText() + ' 평균 회원 유지일'}</Title>
        {
          isDurationLoading ?
            <AnimationBox>
              <LottieWrapper>
                <Lottie options={{ animationData: LoadingAnimation }} />
              </LottieWrapper>
            </AnimationBox>
            :
            <InnerInfoBox>
              {
                isOpenPartnerGymSales ?
                  // <InfoBox>
                    <InfoView>
                    <ImageBoxWrapper gap={8}>
                      <DurationImage src={Duration} />
                      <DurationColorTextBox backgroundColor='#6DD49E'>
                        <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FFFFFF' hover='default'>평균 회원 지속일</Text>
                        <Text style={{ marginLeft: `4px` }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF' hover='default'>{durationAvg}개월</Text>
                      </DurationColorTextBox>
                    </ImageBoxWrapper>
                    <DurationMemberBoxWrapper>
                      <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#595959' hover='default'>장기 회원 목록</Text>
                      <DurationMemberBox>
                        <DurationMemberColumn>
                          <Text style={{ marginRight: `40px` }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='default'>이름</Text>
                          <Text style={{ marginRight: `29px` }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='default'>성별</Text>
                          <Text style={{ marginRight: `46px` }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='default'>생년월일</Text>
                          <Text style={{ marginRight: `49px` }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='default'>핸드폰번호</Text>
                          <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='default'>장기지속일</Text>
                        </DurationMemberColumn>
                        <DurationMemberList>
                          {
                            durationMembers.map((data, index) => (
                              <DurationMemberWrapper key={index} onClick={() => onClickDurationMember(data.memberId)}>
                                <DurationNameBox>
                                  <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='pointer'>{data.name}</Text>
                                </DurationNameBox>
                                <DurationGenderBox>
                                  <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='pointer'>{data.gender === 'male' ? '남성' : '여성'}</Text>
                                </DurationGenderBox>
                                <DurationBirthBox>
                                  <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='pointer'>{data.birthDay.slice(0, 4) + '.' + data.birthDay.slice(4, 6) + '.' + data.birthDay.slice(6, 8)}</Text>
                                </DurationBirthBox>
                                <DurationPhoneBox>
                                  <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='pointer'>{calcPhoneView(data.phone)}</Text>
                                </DurationPhoneBox>
                                <DurationContentBox>
                                  <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='pointer'>{calcDuration(data.duration)}</Text>
                                </DurationContentBox>
                              </DurationMemberWrapper>
                            ))
                          }
                        </DurationMemberList>
                      </DurationMemberBox>
                    </DurationMemberBoxWrapper>
                    </InfoView>
                  // </InfoBox>
                  :
                  <NotOpenTextWrapper>
                    <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#444444' cursor='default'>이 업체는 파트너 체육관에 매출을 비공개 합니다</Text>
                    <Text fontFamily='NotoSansKR-Regular' fontSize={15} color='#444444' cursor='default'>링크존 설정에서 “파트너 체육관”에서 권한 설정해 주세요</Text>
                  </NotOpenTextWrapper>
              }
            </InnerInfoBox>
        }
      </InfoBox>

      <BoxDivisionLine />

      <InfoBox>
        <Title>{calcDateText() + ' 신규등록 회원'}</Title>
        {
          isAliveLoading ?
            <AnimationBox>
              <LottieWrapper>
                <Lottie options={{ animationData: LoadingAnimation }} />
              </LottieWrapper>
            </AnimationBox>
            :
            <InnerInfoBox>
              {
                isOpenPartnerGymSales ?
                  <InfoView>
                    <TopUIWrapper>
                      <LeaveIcon src={NewImage} />
                      <ReRegistrationPersent>
                        <PersentText>신규 등록 회원</PersentText>
                        <PersentNumber>{newMembers.length}명</PersentNumber>
                      </ReRegistrationPersent>
                    </TopUIWrapper>
                    <ListTitleWrapper>
                      <ListTitle>신규등록 회원 목록</ListTitle>
                      <PriceTextWrapper>
                        <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BABABA' hover='default'>신규 회원 매출</Text>
                        <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#595959' hover='default'>{newMemberPrice.toLocaleString('ko-KR')}</Text>
                        <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BABABA' hover='default'>원</Text>
                      </PriceTextWrapper>
                    </ListTitleWrapper>
                    <MemberList column='등록 날짜' data={newMembers} selectedPartnerGym={selectedPartnerGym}/>
                  </InfoView>
                  :
                  <NotOpenTextWrapper>
                    <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#444444' cursor='default'>이 업체는 파트너 체육관에 매출을 비공개 합니다</Text>
                    <Text fontFamily='NotoSansKR-Regular' fontSize={15} color='#444444' cursor='default'>링크존 설정에서 “파트너 체육관”에서 권한 설정해 주세요</Text>
                  </NotOpenTextWrapper>
              }
            </InnerInfoBox>
        }
      </InfoBox>
    </TopLevelWrapper>
  )
}

export default MaintainMemberAndNewMemberView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 1020px;
  height: 448px;

  gap: 28px;
  margin-top: 38px;

  border-radius: 30px;
  box-shadow: 0 40px 50px rgba(240, 232, 227, 0.5);
  background-color: #FFFFFF;
`;

const BoxDivisionLine = styled.div`
  diplay: flex;
  flex-direction: row;

  width: 1px;
  height: 392px;

  background-color: #ECECEC;
  // border: 1px solid #ECECEC;
`;

const InnerInfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  // width: 454px;
  // width: 44.5%;
  height: 364px;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-conetent: center;
  align-items: flex-start;

  // width: 44.5%;
  // width: 100%;
  width: 454px;
`;

const InfoView = styled.div`
  display: flex;
  flex-direction: column;

  justify-conetent: center;
  align-items: flex-start;
`;

const Title = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;
`;

const AnimationBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  z-index: 5;
  
  width: 100%;
  // height: 100%;
  height: 364px;

  // background-color: rgba(0, 0, 0, 0.2);
  background-color: #FFFFFF;
`;

const LottieWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 80px;
  height: 80px;
`;

const TopUIWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 24px;
`;

const LeaveIcon = styled.img`
  // width: 48px;
  width: 35px;
  // height: 48px;
  height: 36px;

  margin-right: 8px;
`;

const LeavePersent = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 133px;
  height: 28px;

  border-radius: 8px;
  background-color: #FF8B48;
`;

const ReRegistrationPersent = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 133px;
  height: 28px;

  border-radius: 8px;
  background-color: #F7D17F;
`;

const PersentText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #FFFFFF;

  margin: 0 3px 0 0;
`;

const PersentNumber = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 14px;

  color: #FFFFFF;

  margin: 0 0 0 0;
`;

const DivisionLine = styled.div`
  display: flex;
  flex-direction: row;

  width: 120px;
  height: 1px;

  margin: 0 16px 0 16px;
  border: 1px solid #ECECEC;
`;

const ResultBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 113px;
  height: 28px;

  border-radius: 8px;
  background-color: #ECECEC;
`;

const ResultText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #777777;

  margin: 0 0 0 0;
`;

const ResultNumber = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 14px;

  color: #777777;

  margin: 0 0 0 0;
`;

const ListTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: 24px;

  width: 100%;
`;

const ListTitle = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #595959;

  // margin: 24px 0 0 0;
  margin: 0 0 0 0;
`;
const ImageBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 24px;

  gap: ${props => props.gap}px;
`;

const DurationImage = styled.img`
  // width: 52px;
  width: 43px;
  // height: 52px;
  height: 46px;
`;

const MoneyImage = styled.img`
  width: 40px;
  height: 38px;
`;

const DurationColorTextBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 9px;
  // padding: 0px 19px 0px 19px;

  width: 187px;
  height: 28px;

  border-radius: 8px;
  background-color: #53B38F;
`;

const ColorTextBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 9px;
  padding: 0px 19px 0px 19px;

  height: 28px;

  border-radius: 8px;
  background-color: ${props => props.backgroundColor};
`;

const DurationMemberBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 24px;
`;

const DurationMemberBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 16px;

  width: 454px;
  height: 240px;
`;

const DurationMemberColumn = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  padding: 0px 8px 0px 8px;

  width: calc(100% - 16px);
  height: 32px;

  border-radius: 8px;
  background-color: #ECECEC;
`;

const DurationMemberList = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  height: 216px;

  overflow-y: scroll;
  overflow-x: none;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
		border: 3px solid transparent;
		// height: 92px;
		background-color: #DADADA;
		border-radius: 200px;
	}

  &::-webkit-scrollbar-track {
    // height: 439px;
		// border: 4px solid transparent;
		background-color: #F0F0F0;
		border-radius: 200px;
	}
`;

const DurationMemberWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  padding: 0px 8px 0px 8px;

  gap: 8px;

  width: calc(100% - 16px);
  min-height: 36px;
  max-height: 36px;

  background-color: #FFFFFF;

  :hover {
    background-color: #F4F2F0;
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const DurationNameBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 59px;
  height: 20px;

  overflow: hidden;
`;

const DurationGenderBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 46px;
  height: 20px;

  overflow: hidden;
`;

const DurationBirthBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 90px;
  height: 20px;

  overflow: hidden;
`;

const DurationPhoneBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 106px;
  height: 20px;

  overflow: hidden;
`;

const DurationContentBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 101px;
  height: 20px;

  overflow: hidden;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.hover};
  }
`;

const PriceTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
  
  gap: 4px;
`;

const NotOpenTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;