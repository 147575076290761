import { React, useRef } from 'react';
import styled from 'styled-components';

export default function ModifySingleInput(props) {
	const { value, setValue, index } = props;

	const InputRef = useRef(null);
  const TextRef = useRef(null);

  const fontSize = 14;
  const inputBorderWidth = 2;
  const inputPadding = 20;
  const inputMaxWidth = 160;
  const inputMinWidth = 30;

	function handleClick(e) {
	}

	function handleChange(e) {
    let regex = /[^0-9]/g;				// 숫자가 아닌 문자열을 선택하는 정규식
    let result = e.target.value.replace(regex, "");
    
    if (result === '') setValue(0);
		else setValue(parseInt(result));
	}

	function handleFocus(e) {
	}

	function handleBlur(e) {
	}

  // Input 컴포넌트 길이 계산 //
  function decideInputWidth() {
    if (value === undefined || TextRef.current === null) return inputMinWidth;
    
    // let calcWidth = (fontSize * 0.65) * (value.toString().length) + inputBorderWidth + inputPadding;
    // let calcWidth = (fontSize * 0.65) * (value.toString().length) - (inputBorderWidth + inputPadding);
    let calcWidth = (fontSize * 0.65) * (value.toString().length);
    
    if (calcWidth > inputMaxWidth) return inputMaxWidth;
    else if (calcWidth < inputMinWidth) return inputMinWidth;
    else return calcWidth;
  }

	return (
    <TopLevelWrapper width={decideInputWidth()}>
      <TextWidthSpan ref={TextRef}>
        <Text fontFamily='Poppins-Medium' fontSize={fontSize} color='#FFFFFF'>{value}</Text>
      </TextWidthSpan>
      <Input
        ref={InputRef}
        type='text'
        placeholder=''
        value={value}
        onClick={handleClick}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur} />
    </TopLevelWrapper>
  )
};

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding: 0px 10px;

  // width: calc(48% - 32px);
  width: ${props => props.width}px;
  height: 34px;

  border-radius: 5px;
  border: 1px solid #CBCBCB;
  background-color: #F6F6F6;
  
  // gap: 8px;

  transition: all 0.3s ease-in-out;
`;

const Input = styled.input` 
	font-family: Poppins-Medium;
	font-size: 14px;
	line-height: 20px;
	
  display: flex;
  flex-direction: row;

  padding: 0 0 0 0;
  // margin: -1px 0px 0px -2px;
  width: 100%;
  // width: 140px;

  border: 0px;
  background-color: #F6F6F6;

  color: #595959;

	outline: none;

	::placeholder {
    font-family: Poppins-Medium;
    font-size: 14px;

		color: #CBCBCB;
	}

  &:disabled {
    background-color: #ECECEC;
  }

  transition: all 0.3s ease-in-out;
`;

const TextWidthSpan = styled.span`
  position: absolute;

  z-index: -300;
  opacity: 0;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;
