import { React } from 'react';
import styled from 'styled-components';

export default function SubscribeSelectBox(props) {
  const { isUsePaymentSystem, isUseSubscribe, setIsUseSubscribe } = props;

  // 구독형 회원권 승인 여부로 글자색 결정 //
  function decideFontColor() {
    if (isUseSubscribe === 1) return '#6DD49E';
    else if (isUseSubscribe === 2) return '#FFB800';
    else return '#CBCBCB';
  } 

  // 구독제 회원권 승인 여부로 테두리 색상 결정 //
  function decideBorder() {
    if (isUseSubscribe === 1) return '2px solid #5BD98A';
    else if (isUseSubscribe === 2) return '2px solid #FFB800';
    else return '2px solid #DFDFDF';
  } 

  // 구독제 회원권 승인 여부로 텍스트 결정 //
  function decideText() {
    if (isUseSubscribe === 1) return '승인';
    else if (isUseSubscribe === 2) return '심사중';
    else return '미승인';
  } 

  return (
    <TopLevelWrapper>
      <ExplanationWrapper>
        <Explanation>회원권 정기 구독</Explanation>
      </ExplanationWrapper>
      <BoxWrapper>
        {/* <SelectBox 
          color={decideFontColor('use')} 
          backgroundColor={decideBackgroundColor('use')}
          onClick={() => onClickUseScribeButton('use')}>사용</SelectBox>
        <SelectBox 
          color={decideFontColor('not use')} 
          backgroundColor={decideBackgroundColor('not use')}
          onClick={() => onClickUseScribeButton('not use')}>미사용</SelectBox> */}
          <SubscribeStatetBox
            color={decideFontColor()} 
            border={decideBorder()}>
            {decideText()}
          </SubscribeStatetBox>
      </BoxWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  
  transition: all 0.3s ease-in-out;
`;

const ExplanationWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const Explanation = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #595959;

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const BoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;
  
  margin-top: 8px;
  gap: 8px;

  width: 100%;
`;

const SubscribeStatetBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: calc(100% - 3px);
  // height: 32px;
  height: 28px;

  // border: 3px solid #DFDFDF;
  border: ${props => props.border};
  border-radius: 8px;
  // background-color: ${props => props.backgroundColor};
  background-color: #FFFFFF;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: ${props => props.color};

  :hover {
    // cursor: pointer;
    cursor: default;
  }

  transition: all 0.3s ease-in-out;
`;

const SelectBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: calc(42% - 2px);
  height: 30px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background-color: ${props => props.backgroundColor};

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: ${props => props.color};

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;