import { React, useEffect, useState } from "react";
import styled from "styled-components";

export default function TotalAttendanceCountBox(props) {
  const { lessonHistory } = props;

  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    let count = 0;

    for (let i = 0; i < lessonHistory.length; i++) {
      if (lessonHistory[i].status === 1 || lessonHistory[i].status === 2) count += 1;

      setTotalCount(count);
    }
  }, [lessonHistory])

  function desideCountTextSize() {
    if (totalCount >= 1000000) return 60;
    else if (totalCount >= 100000) return 70;
    else if (totalCount >= 10000) return 80;
    else if (totalCount >= 1000) return 100;
    else return 150;
  }

  return (
    <Box>
      <Text fontFamily='NotoSansKR-Bold' fontSize={desideCountTextSize()} color='#595959' cursor='default'>{totalCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Text>
      <TitleBox>
        <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#777777' cursor='default'>누적 출석 횟수</Text>
      </TitleBox>
    </Box>
  )
}

const Box = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 301px;
  height: 268px;

  border-radius: 8px;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 175px;
  height: 33px;
  
  border-radius: 8px;
  background-color: #ECECEC;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  padding: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }
`;