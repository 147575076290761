import React from 'react';
import styled from 'styled-components';

// Model Import //
import CertificationView from '../../Model/Individual/AccountModel/CertificationView.js';

const CertificationPage = () => {
  return (
    <Container>
      <CertificationView/>
    </Container>
  )
}

export default CertificationPage;

const Container = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 132px;
  top: 0;
  left: 0;

  // width: 1920px;
  width: 100vw;
  min-width: 1194px;
  height: 100vh;

  background-color: #FDFCFB;
`;