import React, { useEffect, useState } from "react";
import styled from "styled-components";
import UserProfile from "../../../../Component/Normal/UserProfile";

export default function MemberReservationInfoBox(props) {
  const {
    memberData,
    index,
    nowReserveLength,
    maxReserve,
    onViewUserPhone,
    openChoiceButtonIndex,
    setOpenChoiceButtonIndex,
    onClickMemberProfileOrText,
    onClickAddQueueButton,
    onClickMemberReserveButton
  } = props;

  const [isViewChoiceAddButton, setIsViewChoiceAddButton] = useState(false);
  const [isPlusButtonHover, setIsPlusButtonHover] = useState(false);

  useEffect(() => {
    if (isViewChoiceAddButton && openChoiceButtonIndex !== undefined) {
      if (openChoiceButtonIndex !== index) {
        setIsPlusButtonHover(false);
        setIsViewChoiceAddButton(false);
      }
    }
  }, [isViewChoiceAddButton, openChoiceButtonIndex, index])

  // 예약 추가 클릭시 //
  function onClickAddReserveButton() {
    if (nowReserveLength < maxReserve) {
      onClickMemberReserveButton(index);
      setIsPlusButtonHover(false);
    }
    else {
      setOpenChoiceButtonIndex(index);
      setIsViewChoiceAddButton(true);
    }
  }

  // 예약 인원 초과 후 예약열 추가 클릭시 //
  function onClickAddReserve() {
    onClickMemberReserveButton(index);
    setOpenChoiceButtonIndex(undefined);
    setIsViewChoiceAddButton(false);
    setIsPlusButtonHover(false);
  }

  // 예약 인원 초과 후 대기열 추가 클릭시 //
  function onClickAddQueue() {
    onClickAddQueueButton(index);
    setOpenChoiceButtonIndex(undefined);
    setIsViewChoiceAddButton(false);
    setIsPlusButtonHover(false);
  }
  return (
    <TotalInfoWrapper key={index}>
      <InfoWrapper>
        <ImageWrapper onClick={() => onClickMemberProfileOrText(memberData)}>
          <UserProfile profile={memberData.profile} defaultProfile='https://picture.link-zone.org/ZoneLogo.png' />
        </ImageWrapper>
        <MemberDataWrapper>
          <TextWrapper onClick={() => onClickMemberProfileOrText(memberData)}>
            <NameWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#777777' cursor='pointer'>{memberData.name}</Text>
            </NameWrapper>
            <PhoneWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#777777' cursor='pointer'>{onViewUserPhone(memberData.phone)}</Text>
            </PhoneWrapper>
          </TextWrapper>
          {
            !isViewChoiceAddButton &&
            <PlusButton
              onMouseOver={() => setIsPlusButtonHover(true)}
              onMouseLeave={() => setIsPlusButtonHover(false)}
              onClick={onClickAddReserveButton}>
              <PlusImage>
                <PlusWidth width={10} height={2} border={2} backgroundColor={isPlusButtonHover ? '#FFFFFF' : '#6DD49E'} />
                <PlusWidth width={2} height={10} border={2} backgroundColor={isPlusButtonHover ? '#FFFFFF' : '#6DD49E'} />
              </PlusImage>
            </PlusButton>
          }
        </MemberDataWrapper>
      </InfoWrapper>
      <TotalChoiceWrapper isView={isViewChoiceAddButton}>
        <ChoiceAddButtonWrapper>
          <AddButton backgroundColor='#6DD49E' onClick={onClickAddReserve}>
            <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF' cursor='pointer'>예약열 추가</Text>
          </AddButton>
          <AddButton backgroundColor='#FFB800' onClick={onClickAddQueue}>
            <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF' cursor='pointer'>대기열 추가</Text>
          </AddButton>
        </ChoiceAddButtonWrapper>
      </TotalChoiceWrapper>
    </TotalInfoWrapper>
  );
}
const TotalInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 96%;

  border-bottom: 1px solid #F5F5F5;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 100%;
  height: 38px;

  gap: 10px;
`;

const TotalChoiceWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 100%;
  height: ${props => props.isView ? '47px' : '0px'};

  overflow: hidden;

  transition: all 0.3s ease-in-out;
`;

const ChoiceAddButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 37px;

  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  overflow: hidden;
`;

const AddButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 50%;
  height: 100%;

  background-color: ${props => props.backgroundColor};

  :hover {
    cursor: pointer;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 38px;
  height: 38px;

  border-radius: 2px;

  overflow: hidden;

  :hover {
    cursor: pointer;
  }
`;

const MemberDataWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: calc(100% - 32px);
  height: 38px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  gap: 4px;

  :hover {
    cursor: pointer;
  }
`;

const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 56px;
  height: 17px;

  overflow: hidden;
`;

const PhoneWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 105px;
  height: 17px;

  overflow: hidden;
`;

const PlusButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;

  border-radius: 20px;
  background-color: #FFFFFF;

  :hover {
    cursor: pointer;
    background-color: #6DD49E;
  }

  transition: all 0.3s ease-in-out;
`;

const PlusImage = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 10px;
  height: 10px;
`;

const PlusWidth = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: ${props => props.radius}px;
  background-color: ${props => props.backgroundColor};

  transition: all 0.3s ease-in-out;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }
`;