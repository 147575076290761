import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { cloneDeep } from 'lodash';

import EditResultExerciseBox from './EditRenewalResultExerciseBoxInReview';

// import WhiteLeftIcon from '../../Image/WhiteLeftTriangle.svg';
// import WhiteRightIcon from '../../Image/WhiteRightTriangle.svg';
import WhiteLeftIcon from '../../image/PictureRightButton.png';
import WhiteRightIcon from '../../image/PictureLeftButton.png';
import ModifyTimeInput from '../Component/ModifyTimeInput';
import RoundCountInput from '../Component/RoundCountInput';
import ModifyCustomInput from '../Component/ModifyCustomInput';

export default function EditRenewalExerciseResultBoxInRankingDetail(props) {
  const {
    isClickedEdit,
    editData,
    setEditData,
    data,
    userName,
    onClickMovementVideo
  } = props;

  const [pictureSize, setPictureSize] = useState(414);
  // const [recordTime, setRecordTime] = useState('00:00');
  const [recordMin, setRecordMin] = useState(0);
  const [recordSec, setRecordSec] = useState(0);
  // const [lastMovement, setLastMovement] = useState('');
  const [imageList, setImageList] = useState([
    // 'https://link-picture.s3.ap-northeast-2.amazonaws.com/CoachLogo.jpg',
    // 'https://link-picture.s3.ap-northeast-2.amazonaws.com/ZoneLogo.png',
    // 'https://link-picture.s3.ap-northeast-2.amazonaws.com/CoachLogo.jpg',
    // 'https://link-picture.s3.ap-northeast-2.amazonaws.com/ZoneLogo.png',
  ]);
  const [imageIndex, setImageIndex] = useState(0);
  const [completeRoundCount, setCompleteRoundCount] = useState([]);


  useEffect(() => {
    // // 끝난 시간 계산 //
    // let newRecordTime = editData.recordTime;
    // let minute = parseInt(newRecordTime / 60) === 0 ? '0' : parseInt(newRecordTime / 60);
    // let second = parseInt(newRecordTime % 60) === 0 ? '0' : parseInt(newRecordTime % 60);

    // setRecordMin(minute);
    // setRecordSec(second);
    // EMOM, TABATA 데이터 정리 
    if (editData.tag === 'EMOM' || editData.tag === 'TABATA') {
      if (editData.roundOfCount === '' || Array.isArray(editData.roundOfCount)) {
        let newList = [];

        for (let i = 0; i < editData.round; i++) {
          newList.push('');
        }

        editData.roundOfCount = newList;
        setCompleteRoundCount(newList);
      }
      else {
        if (!Array.isArray(editData.roundOfCount)) {
          setCompleteRoundCount(editData.roundOfCount.split('-'));
        }
      }
    }

    // 운동 사진 리스트 생성 //
    let newImageList = [];

    if (editData.photos !== '') newImageList = editData.photos.split('<-@->');

    setImageList(newImageList);
  }, [editData])

  // 다음 사진 버튼 클릭시 //
  function onPressNextImage() {
    if (imageList.length > imageIndex) setImageIndex(imageIndex + 1);
  }

  // 이전 사진 버튼 클릭시 //
  function onPressPrevImage() {
    if (imageIndex !== 0) setImageIndex(imageIndex - 1);
  }

  // ForTime 성공 실패여부 클릭시 //
  function onClickEditDataSuccess(value) {
    let newEditData = cloneDeep(editData);

    newEditData.success = value;

    setEditData(newEditData);
  }

  // 시간 변경시 //
  function onChangeEditDataMin(value) {
    let newEditData = cloneDeep(editData);

    newEditData.minute = value;

    setEditData(newEditData);
  }

  // 시간 변경시 //
  function onChangeEditDataSec(value) {
    let newEditData = cloneDeep(editData);

    newEditData.second = value;

    setEditData(newEditData);
  }

  // Complete Round 변경시 //
  function onChangeCompleteRound(value) {
    let newEditData = cloneDeep(editData);

    newEditData.completeRound = value;

    setEditData(newEditData);
  }

  // Complete Reps 변경시 //
  function onChangeLastRound(value) {
    let newEditData = cloneDeep(editData);

    newEditData.lastRound = value;

    setEditData(newEditData);
  }

  // Round Count 수정 //
  function onChangeRoundCount(value, index) {
    let newCompleteRoundCount = completeRoundCount.slice();

    newCompleteRoundCount[index] = value;

    let newRoundOfCount = '';

    for (let i = 0; i < newCompleteRoundCount.length; i++) {
      if (i === 0) newRoundOfCount += newCompleteRoundCount[i];
      else newRoundOfCount += '-' + newCompleteRoundCount[i];
    }
    
    let newEditData = cloneDeep(editData);

    newEditData.roundOfCount = newRoundOfCount;

    setEditData(newEditData);
    setCompleteRoundCount(newCompleteRoundCount);
  }

  // 동작 무게 변경시 //
  function onChangeExerciseDataWeight(value, index) {
    let newEditData = cloneDeep(editData);

    newEditData.mainExerciseList[index].weight = value;

    setEditData(newEditData);
  }

  // 동작 거리 변경시 //
  function onChangeExerciseDataDistance(value, index) {
    let newEditData = cloneDeep(editData);

    newEditData.mainExerciseList[index].distance = value;

    setEditData(newEditData);
  }

  // 동작 높이 변경시 //
  function onChangeExerciseDataHeight(value, index) {
    let newEditData = cloneDeep(editData);

    newEditData.mainExerciseList[index].height = value;

    setEditData(newEditData);
  }

  // 동작 칼로리 변경시 //
  function onChangeExerciseDataCalories(value, index) {
    let newEditData = cloneDeep(editData);

    newEditData.mainExerciseList[index].calories = value;

    setEditData(newEditData);
  }

  // 동작 횟수 변경시 //
  function onChangeExerciseDataCount(value, index) {
    let newEditData = cloneDeep(editData);

    newEditData.mainExerciseList[index].count = value;

    setEditData(newEditData);
  }

  // 동작 시간 변경시 //
  function onChangeExerciseDataTime(value, index) {
    let newEditData = cloneDeep(editData);

    newEditData.mainExerciseList[index].time = value;

    setEditData(newEditData);
  }

  // 동작 템포 변경시 //
  function onChangeExerciseDataTempo(value, index) {
    let newEditData = cloneDeep(editData);

    newEditData.mainExerciseList[index].tempo = value;

    setEditData(newEditData);
  }

  // customLastRound 변경시 //
  function onChangeExerciseDataCustomLastRound(value) {
    let newEditData = cloneDeep(editData);

    newEditData.customLastRound = value;

    setEditData(newEditData);
  }

  // customLastReps 변경시 //
  function onChangeExerciseDataCustomLastReps(value) {
    let newEditData = cloneDeep(editData);

    newEditData.customLastReps = value;

    setEditData(newEditData);
  }

  // customKeywordValue 변경시 //
  function onChangeExerciseDataCustomKeywordValue(value) {
    let newEditData = cloneDeep(editData);

    newEditData.customKeywordValue = value;

    setEditData(newEditData);
  }

  return (
    <TopLevelWrapper>
      {
        editData.tag !== 'SINGLE' &&
        <ResultTextWrapper>
          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#000000'>{userName + '님의 동작 기록'}</Text>
        </ResultTextWrapper>
      }
      {
        (editData.success === 'Not Done') ?
          <ResultWrapper>
            <EmptyResultWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>진행하지 않은 운동입니다</Text>
            </EmptyResultWrapper>
          </ResultWrapper>
          :
          <ResultWrapper>
            {
              editData.tag === 'ForTime' &&
              <InfoWrapper>
                <InfoInnerWrapper>
                  <ForTimeSuccessButtonView
                    isClickedEdit={isClickedEdit}
                    isSuccess={editData.success === 'Success'}
                    onClick={() => onClickEditDataSuccess('Success')}>
                    <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>타임캡 안에 성공</Text>
                  </ForTimeSuccessButtonView>
                  <ForTimeFailButtonView
                    isClickedEdit={isClickedEdit}
                    isSuccess={editData.success !== 'Success'}
                    onClick={() => onClickEditDataSuccess('Not Success')}>
                    <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>타임캡 터치</Text>
                  </ForTimeFailButtonView>
                </InfoInnerWrapper>
              </InfoWrapper>
            }
            {
              editData.tag === 'ForTime' && editData.success === 'Success' &&
              <InfoWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>기록된 시간을 알려주세요</Text>
                <InfoInnerWrapper>
                  <ModifyTimeInput
                    value={editData.minute}
                    setValue={onChangeEditDataMin}
                    unit='min' />
                  <ModifyTimeInput
                    value={editData.second}
                    setValue={onChangeEditDataSec}
                    unit='sec' />
                </InfoInnerWrapper>
              </InfoWrapper>
            }
            {
              editData.tag === 'ForTime' && editData.success !== 'Success' &&
              <InfoWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>얼마나 많은 라운드, 랩 수를 했나요?</Text>
                <InfoInnerWrapper>
                  <ModifyTimeInput
                    value={editData.completeRound}
                    setValue={onChangeCompleteRound}
                    unit='R' />
                  <ModifyTimeInput
                    value={editData.lastRound}
                    setValue={onChangeLastRound}
                    unit='Reps' />
                </InfoInnerWrapper>
              </InfoWrapper>
            }
            {
              editData.tag === 'AMRAP' &&
              <InfoWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>얼마나 많은 라운드, 랩 수를 했나요?</Text>
                <InfoInnerWrapper>
                  <ModifyTimeInput
                    value={editData.completeRound}
                    setValue={onChangeCompleteRound}
                    unit='R' />
                  <ModifyTimeInput
                    value={editData.lastRound}
                    setValue={onChangeLastRound}
                    unit='Reps' />
                </InfoInnerWrapper>
              </InfoWrapper>
            }
            {
              (editData.tag === 'EMOM' || editData.tag === 'TABATA') &&
              <InfoWrapper style={{ marginTop: 0 }}>
                <RoundCountWrapper>
                  {
                    completeRoundCount.map((roundData, roundIndex) => (
                      <RoundCountBox key={'roundCount-' + roundIndex} isLast={completeRoundCount === roundIndex + 1}>
                        <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F'>{(roundIndex + 1) + 'R'}</Text>
                        <RoundCountInput index={roundIndex} value={roundData} setValue={onChangeRoundCount} />
                      </RoundCountBox>
                    ))
                  }
                </RoundCountWrapper>
              </InfoWrapper>
            }
            {
              (editData.tag !== 'SINGLE' && editData.tag !== 'Custom') &&
              <InfoWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>수행한 동작을 기록해주세요</Text>
                <TotalMovementInfoWrapper>
                  {
                    editData.mainExerciseList.map((data, index) => (
                      <EditResultExerciseBox
                        key={index}
                        index={index}
                        division='Main'
                        tag={data.tag}
                        exerciseData={data}
                        onClickMovementVideo={onClickMovementVideo}

                        onChangeExerciseDataWeight={onChangeExerciseDataWeight}
                        onChangeExerciseDataDistance={onChangeExerciseDataDistance}
                        onChangeExerciseDataHeight={onChangeExerciseDataHeight}
                        onChangeExerciseDataCalories={onChangeExerciseDataCalories}
                        onChangeExerciseDataCount={onChangeExerciseDataCount}
                        onChangeExerciseDataTime={onChangeExerciseDataTime}
                        onChangeExerciseDataTempo={onChangeExerciseDataTempo} />
                    ))
                  }
                </TotalMovementInfoWrapper>
              </InfoWrapper>
            }
            {/* Custom */}
            {
              editData.tag === 'Custom' && editData.scoreKeyword === 'Time' && editData.success === 'Success' &&
              <InfoWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>기록된 시간을 알려주세요</Text>
                <InfoInnerWrapper>
                  {/* <TimeInputView>
                    <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F'>{recordMin}</Text>
                    <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F'>min</Text>
                  </TimeInputView>
                  <TimeInputView>
                    <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F'>{recordSec}</Text>
                    <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F'>sec</Text>
                  </TimeInputView> */}
                  <ModifyTimeInput
                    value={editData.minute}
                    setValue={onChangeEditDataMin}
                    unit='min' />
                  <ModifyTimeInput
                    value={editData.second}
                    setValue={onChangeEditDataSec}
                    unit='sec' />
                </InfoInnerWrapper>
              </InfoWrapper>
            }
            {
              editData.tag === 'Custom' && editData.scoreKeyword === 'Reps' && editData.success === 'Success' &&
              <InfoWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>총 횟수</Text>
                <InfoInnerWrapper>
                  <ModifyCustomInput
                    value={editData.customKeywordValue}
                    setValue={onChangeExerciseDataCustomKeywordValue}
                    unit={editData.customKeywordUnit} />
                  {/* <InputView>
                    <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F'>{editData.customKeywordValue}</Text>
                    <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F'>{editData.customKeywordUnit}</Text>
                  </InputView> */}
                </InfoInnerWrapper>
              </InfoWrapper>
            }
            {
              editData.tag === 'Custom' && editData.scoreKeyword === 'Rounds And Reps' && editData.success === 'Success' &&
              <InfoWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>얼마나 많은 라운드, 랩 수를 했나요?</Text>
                <InfoInnerWrapper>
                  {/* <TimeInputView>
                    <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F'>{data.customLastRound}</Text>
                    <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F'>R</Text>
                  </TimeInputView>
                  <TimeInputView>
                    <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F'>{data.customLastReps}</Text>
                    <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F'>Reps</Text>
                  </TimeInputView> */}
                  <ModifyTimeInput
                    value={editData.customLastRound}
                    setValue={onChangeExerciseDataCustomLastRound}
                    unit='R' />
                  <ModifyTimeInput
                    value={editData.customLastReps}
                    setValue={onChangeExerciseDataCustomLastReps}
                    unit='Reps' />
                </InfoInnerWrapper>
              </InfoWrapper>
            }
            {
              editData.tag === 'Custom' && editData.scoreKeyword === 'Distance' && editData.success === 'Success' &&
              <InfoWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>총 거리</Text>
                <InfoInnerWrapper>
                  {/* <InputView>
                    <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F'>{editData.customKeywordValue}</Text>
                    <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F'>{editData.customKeywordUnit}</Text>
                  </InputView> */}
                  <ModifyCustomInput
                    value={editData.customKeywordValue}
                    setValue={onChangeExerciseDataCustomKeywordValue}
                    unit={editData.customKeywordUnit} />
                </InfoInnerWrapper>
              </InfoWrapper>
            }
            {
              editData.tag === 'Custom' && editData.scoreKeyword === 'Weight' && editData.success === 'Success' &&
              <InfoWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>총 무게</Text>
                <InfoInnerWrapper>
                  {/* <InputView>
                    <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F'>{editData.customKeywordValue}</Text>
                    <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F'>{editData.customKeywordUnit}</Text>
                  </InputView> */}
                  <ModifyCustomInput
                    value={editData.customKeywordValue}
                    setValue={onChangeExerciseDataCustomKeywordValue}
                    unit={editData.customKeywordUnit} />
                </InfoInnerWrapper>
              </InfoWrapper>
            }
            {
              editData.tag === 'Custom' && editData.scoreKeyword === 'Points' && editData.success === 'Success' &&
              <InfoWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>포인트</Text>
                <InfoInnerWrapper>
                  {/* <InputView>
                    <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F'>{editData.customKeywordValue}</Text>
                    <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F'>{editData.customKeywordUnit}</Text>
                  </InputView> */}
                  <ModifyCustomInput
                    value={editData.customKeywordValue}
                    setValue={onChangeExerciseDataCustomKeywordValue}
                    unit={editData.customKeywordUnit} />
                </InfoInnerWrapper>
              </InfoWrapper>
            }
            {
              editData.tag === 'Custom' && editData.scoreKeyword === 'Peak Watts' && editData.success === 'Success' &&
              <InfoWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>피크와트</Text>
                <InfoInnerWrapper>
                  {/* <InputView>
                    <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F'>{editData.customKeywordValue}</Text>
                    <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F'>{editData.customKeywordUnit}</Text>
                  </InputView> */}
                  <ModifyCustomInput
                    value={editData.customKeywordValue}
                    setValue={onChangeExerciseDataCustomKeywordValue}
                    unit={editData.customKeywordUnit} />
                </InfoInnerWrapper>
              </InfoWrapper>
            }
            {
              editData.tag === 'Custom' && editData.scoreKeyword === 'Max Speed' && editData.success === 'Success' &&
              <InfoWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>최고 속도</Text>
                <InfoInnerWrapper>
                  {/* <InputView>
                    <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F'>{editData.customKeywordValue}</Text>
                    <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F'>{editData.customKeywordUnit}</Text>
                  </InputView> */}
                  <ModifyCustomInput
                    value={editData.customKeywordValue}
                    setValue={onChangeExerciseDataCustomKeywordValue}
                    unit={editData.customKeywordUnit} />
                </InfoInnerWrapper>
              </InfoWrapper>
            }
            {/* Custom */}
            {
              imageList.length > 0 &&
              <ExercisePictureWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>운동 사진</Text>
                <PictureBoxWrapper size={pictureSize}>
                  <PictureBox size={pictureSize}
                    src={imageList[imageIndex]} />
                  <CenterButtonWrapper size={pictureSize}>
                    {/* 사진 크기의 1/2 - 페이지 이동 버튼 크기의 1/2 (27) */}
                    <MoveButtonWrapper marginTop={(pictureSize / 2) - 27}>
                      {
                        imageIndex !== 0 ?
                          <Button onPress={onPressPrevImage} activeOpacity={0.8} hitSlop={{ top: 15, right: 15, bottom: 15, left: 15 }}>
                            <PictureButton src={WhiteLeftIcon} />
                          </Button>
                          :
                          <EmptyBox />
                      }
                      {
                        imageIndex !== imageList.length - 1 ?
                          <Button onPress={onPressNextImage} activeOpacity={0.8} hitSlop={{ top: 15, right: 15, bottom: 15, left: 15 }}>
                            <PictureButton src={WhiteRightIcon} />
                          </Button>
                          :
                          <EmptyBox />
                      }
                    </MoveButtonWrapper>
                  </CenterButtonWrapper>
                </PictureBoxWrapper>
              </ExercisePictureWrapper>
            }
            <FeelBoxWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>나만의 운동 노트</Text>
              <FeelBox>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>{editData.userExperience}</Text>
              </FeelBox>
            </FeelBoxWrapper>
          </ResultWrapper>
      }
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  // margin: 0px 0px 111px 0px;

  width: 100%;

  overflow: hidden;
`;

const ResultTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;
  
  margin-top: 8px;

  width: 90.33%;
  // height: 32px;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  
  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;

const ResultWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  padding-bottom: 20px;

  width: 100%;

  background-color: #F9F9F9;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  align-items: flex-start;

  margin-top: 20px;

  width: 90.33%;
`;

const InfoInnerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;
  
  margin-top: 8px;

  width: 100%;
`;

const RoundCountWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  flex-wrap: wrap;

  width: 100%;
`;

const RoundCountBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 16px;
  margin-right: ${props => props.isLast ? `0px` : `12px`};

  width: 16%;
`;

const RoundCountInputView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 4px;

  width: 100%;
  height: 34px;

  border: 1px solid #CBCBCB;
  border-radius: 10px;
  background-color: #F6F6F6;
`;

const TotalMovementInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;
  
  margin-top: 8px;

  width: 100%;
`;

const ExercisePictureWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 16px;
  margin-bottom: 26px;
  
  width: 90.33%;
`;

const PictureBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.size}px;
  height: ${props => props.size}px;
`;

const PictureBox = styled.img`
  width: ${props => props.size}px;
  height: ${props => props.size}px;

  margin-top: 12px;

  border-radius: 12px;
  background-color: #E0E0E0;
`;

const FeelBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 40px;

  width: 90.33%;
`;

const FeelBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 8px;
  padding: 10px 12px 10px 12px;

  width: calc(100% - 26px);
  min-height: 131px;

  border-radius: 12px;
  border: 1px solid #E0E0E0;
  background-color: #FFFFFF;
`;

const CenterButtonWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  algin-items: flex-start;

  width: ${props => props.size}px;
  height: ${props => props.size}px;
`;

const MoveButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: ${props => props.marginTop}px;
  padding: 15px 15px 15px 15px;

  width: 100%;
`;

const Button = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 24px;
  height: 24px;

  opacity: 1;

  border-radius: 24px;
  background-color: rgba(0, 0, 0, 0.6);
`;

const EmptyBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 24px;
  height: 24px;
`;

const EmptyResultWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 20px;
`;


const ForTimeSuccessButtonView = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 48%;
  height: 35px;

  border-radius: 12px;
  background-color: ${props => props.isSuccess ? '#6DD49E' : '#CBCBCB'};

  :hover {
    cursor: ${props => props.isClickedEdit ? 'pointer' : 'default'};
  }
`;

const ForTimeFailButtonView = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 48%;
  height: 35px;

  border-radius: 12px;
  background-color: ${props => props.isSuccess ? '#FF3131' : '#CBCBCB'};

  :hover {
    cursor: ${props => props.isClickedEdit ? 'pointer' : 'default'};
  }
`;

const TimeInputView = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding: 0px 15px;

  width: calc(48% - 32px);
  height: 35px;

  border-radius: 12px;
  border: 1px solid #CBCBCB;
  background-color: #F6F6F6;
`;

const InputView = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding: 0px 15px;

  width: 100%;
  height: 35px;

  border-radius: 12px;
  border: 1px solid #CBCBCB;
  background-color: #F6F6F6;
`;

const PictureButton = styled.img`
  width: 23.1px;
  height: 23.1px;

  :hover {
    cursor: pointer;
  }
`;