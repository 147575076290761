import { React, useEffect, useState } from "react";
import styled from "styled-components";

import RightArrow from '../../../../image/Right.png';
import LeftArrow from '../../../../image/Left.png';
import moment from "moment";

export default function AttendanceRateBox(props) {
  const { lessonHistory } = props;

  const [division, setDivision] = useState('month');
  const [rate, setRate] = useState(0);

  useEffect(() => {
    if (division === 'week') {
      let today = moment();
      let startDay = moment();

      for (let i = 0; i < 7; i++) {
        if (today.clone().subtract(i, 'day').format('dd') === 'Su') {
          startDay = today.clone().subtract(i, 'day');
          break;
        }
      }

      let historyCount = 0;

      for (let i = 0; i < lessonHistory.length; i++) {
        if (startDay.isBefore(moment(lessonHistory[i].classDate).subtract(1, 'milliseconds')) 
          && startDay.clone().add(7, 'day').subtract(1, 'milliseconds').isAfter(moment(lessonHistory[i].classDate))
          && (lessonHistory[i].status === 1 || lessonHistory[i].status === 2)) {
          historyCount += 1;
        }
      }
      
      // console.log(historyCount);
      setRate(parseInt(Math.round((historyCount / 7) * 100)));
    }
    else {
      let year = moment().format('YYYY');
      let month = moment().format('MM');
      let date = new Date(year, month, 0).getDate();

      let startDay = moment(year + '-' + month + '-01T00:00:00').subtract(1, 'milliseconds');
      let endDay = startDay.clone().add(date, 'day').subtract(1, 'milliseconds'); 

      let historyCount = 0;

      for (let i = 0; i < lessonHistory.length; i++) {
        if (startDay.isBefore(moment(lessonHistory[i].classDate)) 
          && endDay.isAfter(moment(lessonHistory[i].classDate))
          && (lessonHistory[i].status === 1 || lessonHistory[i].status === 2)) {
          historyCount += 1;
        }
      }
      
      setRate(Math.round((historyCount / date) * 100));
    }

  }, [lessonHistory, division])


  return (
    <Box>
      <TextWrapper>
        <Text fontFamily='NotoSansKR-Bold' fontSize={150} color='#595959' cursor='default'>{rate}</Text>
        <Text style={{ marginTop: '80px' }} fontFamily='NotoSansKR-Bold' fontSize={50} color='#595959' cursor='default'>%</Text>
      </TextWrapper>
      <TitleBox>
        <Button isClicked={division === 'week'} onClick={() => setDivision('week')}>
          {
            division === 'month' &&
            <Image src={LeftArrow} />
          }
        </Button>
        <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#777777' cursor='default'>{division === 'month' ? '월간 출석률' : '주간 출석률'}</Text>
        <Button isClicked={division === 'week'} onClick={() => setDivision('month')}>
          {
            division === 'week' &&
            <Image src={RightArrow} />
          }
        </Button>
      </TitleBox>
    </Box>
  )
}

const Box = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 301px;
  height: 268px;

  border-radius: 8px;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 278px;
  height: 196px;
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding: 0px 8px 0px 8px;
  margin-top: 18px;

  // width: 175px;
  width: 159px;
  height: 33px;
  
  border-radius: 8px;
  background-color: #ECECEC;
`;

const Button = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 3px;

  width: 20px;
  height: 20px;
  
  :hover {
    cursor: ${props => props.isClicked ? `default` : `pointer`};
  }
`;

const Image = styled.img`
  width: 7px;
  height: 14px;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  padding: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }
`;