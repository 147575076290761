import { useState, useImperativeHandle, forwardRef, useRef, React } from 'react';
import styled from 'styled-components';

import UserProfile from '../Normal/UserProfile';
import DefaultLogoPath from '../../image/DefaultLogo.png';

const NewTitleInput = forwardRef((props, ref) => {
	const { storeLogo, placeholder } = props;
	const [value, setValue] = useState('');
	const [changed, setChanged] = useState(false);
	const [changedPlaceholderColor, setChangedPlaceholderColor] = useState(false);
	const [clickCount, setClickCount] = useState(0);
	const [defaultPlaceholder, setDefaultPlaceholder] = useState(placeholder);

	const inputRef = useRef();

	useImperativeHandle(ref, () => ({
		setValue: (val) => {
			setValue(val);
			setChanged(true);
		},
		getValue: () => {
			return value;
		},
		plusClickCount: () => {
			setClickCount(clickCount + 1)
		},
		getClickCount: () => {
			return clickCount;
		},
		setDefaultPlaceholder: (val) => {
			setDefaultPlaceholder(val)
			setChangedPlaceholderColor(true);
			inputRef.current.style.borderColor = '#FF3131';
		}
	}));

	function handleChange(e) {
		setValue(e.target.value);
	}

	function handleFocus(e) {
		setChangedPlaceholderColor(false);
		// if (changed) {
		// 	// do nothing
		// } else {
		// 	setValue("");
		// }
	}

	function handleBlur(e) {
		// setValue('')
		// setClickCount(clickCount + 1);
		// inputRef.current.style.borderColor = '#E8E8E8';
		// if (value === "") {
		// 	setValue();
		// 	// setDefaultPlaceholder(placeholder);
		// 	// inputRef.current.style.borderColor = '#FF3131';
		// } 
		// else {
		// 	// inputRef.current.style.borderColor = '#E8E8E8';
		// }
	}

	return (
		<TopLevelWrapper>
			<ProfileBoxWrapper>
				<UserProfile profile={storeLogo} defaultProfile={DefaultLogoPath} />
			</ProfileBoxWrapper>
			<Input
				ref={inputRef}
				clickCount={clickCount}
				type='text'
				placeholder={defaultPlaceholder}
				value={value || ''}
				changedColor={changedPlaceholderColor}
				onChange={handleChange}
				onFocus={handleFocus}
				onBlur={handleBlur} />
		</TopLevelWrapper>)
});

export default NewTitleInput;

const TopLevelWrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;

	justify-content: flex-start;
	align-items: center;

	margin-top: 12px;

	width: 100%;
`;

const Input = styled.input`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  // width: 940px;
  // width: 548px;
	// width: 503px;
	width: calc(100% - 71px);
  height: 39px;

  // margin-top: 8px;
  // padding: 0 12px 0 12px;
  padding: 0 12px 0 57px;

  color: #595959;
	font-family: NotoSansKR-Bold;
	font-size: 14px;
  
  border: 1px solid #DFDFDF;
  border-radius: 34px;

  color: #595959;

	outline: none;

	::placeholder {
		color: ${props => props.changedColor ? `#FF3131` : `#CBCBCB`};
	}

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
		border: 3px solid transparent;
		background-color: #DADADA;
		border-radius: 200px;
	}

  &::-webkit-scrollbar-track {
    // height: 439px;
		// border: 4px solid transparent;
		background-color: #F0F0F0;
		border-radius: 200px;
	}

  transition: all 0.3s ease-in-out;
`;

const ProfileBoxWrapper = styled.div`
	position: absolute;
  width: 48px;
  height: 48px;

  border-radius: 48px;
  
  overflow: hidden;

  :hover {
    cursor: pointer;
    filter: drop-shadow(0px 0px 8px #FF8B48);
  }

  transition: all 0.3s ease-in-out;

	background-color: #FBFAF9;
`;