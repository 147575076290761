import { React } from 'react';
import styled from 'styled-components';

export default function NewGuestNameInput(props) {
  const { value, setValue, placeholder } = props;

  function handleClick(e) {
  }

  function handleChange(e) {
    setValue(e.target.value);
  }

  function handleFocus(e) {
  }

  function handleBlur(e) {
  }

  return (
    <InputWrapper>
      <Input
        type="text"
        placeholder={placeholder}
        value={value}
        onClick={handleClick}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur} />
    </InputWrapper>
  )
};

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  padding: 7px 12px 7px 12px;

  width: calc(100% - 26px);
  
  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background-color: #FFFFFF;
`;

const Input = styled.input`
  font-family: NotoSansKR-Medium;
  font-size: 12px;

  margin: 0 0 0 0;
  padding: 0 0 0 0;

  width: 100%;
  height: 100%;

	outline: none;
  border: 0px;
  
	color: #595959;

	::placeholder {
    font-family: NotoSansKR-Medium;
    font-size: 12px;
    
    color: #595959;
	}
	
  transition: all 0.3s ease-in-out;
`;