import { React, useRef, useState, useEffect } from 'react';
import styled from 'styled-components';

function DataInput(props) {
	const { value, setValue, unit, column, division, index } = props;

  const [useUnit, setUseUnit] = useState('');

	const InputRef = useRef();

  useEffect(() => {
    if (unit === '%RM' || unit === '%Body') setUseUnit('%');
    else if (unit === 'Pounds') setUseUnit('lb');
    else if (unit === 'Kg') setUseUnit('Kg');
    else setUseUnit(unit);
  }, [unit])

	function handleClick(e) {
	}

	function handleChange(e) {
    var regex = /[^0-9]^\d*[.]\d*$/g;				// 숫자가 아닌 문자열을 선택하는 정규식
    
    var changedValue = e.target.value;
    
    if (changedValue === '') {
      setValue(index, division, column, 0);
      return;
    }
    
    if (changedValue[changedValue.length - 1] !== '.') changedValue = parseFloat(changedValue);
    var result = changedValue.toString().replace(regex, "");

    if (result === '') setValue(index, division, column, 0);
		else setValue(index, division, column, result);
	}

	function handleFocus(e) {
	}

	function handleBlur(e) {
		if (value === "") {
			// setDefaultPlaceholder(placeholder);
			// inputRef.current.style.borderColor = '#FF3131';
		} 
		else {
			// inputRef.current.style.borderColor = '#E8E8E8';
		}
	}

	return (
    <TopLevelWrapper>
      <Input
        ref={InputRef}
        type='text'
        placeholder=''
        // value={value === 'MAX' ? value : parseInt(value)}
        value={value}
        onClick={handleClick}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur} />
      <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>{useUnit}</Text>
    </TopLevelWrapper>
  )
};

export default DataInput;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 286px;
  height: 34px;

  padding-left: 16px;
  padding-right: 16px;

  border: 1px solid #E0E0E0;
  border-radius: 15px;

  background-color: ${props => props.disabled ? `#ECECEC` : `#FFFFFF`};
  
  transition: all 0.3s ease-in-out;
`;

const Input = styled.input` 
	font-family: NotoSansKR-Regular;
	font-size: 14px;
	line-height: 20px;
	
  display: flex;
  flex-direction: row;

  width: 100%;

  border: 0px;
  background-color: #FFFFFF;

  color: #595959;

	outline: none;

	::placeholder {
    font-family: NotoSansKR-Regular;
    font-size: 14px;

		color: #CBCBCB;
	}

  &:disabled {
    background-color: #ECECEC;
  }

  transition: all 0.3s ease-in-out;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;
