import { React, useRef, forwardRef, useState, useEffect, useImperativeHandle } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import CustomAlert from '../../Share/Normal/CustomAlert';

import SettingCountInput from '../../../Component/SettingComponent/SettingCountInput';

import FetchModule from '../../Share/Network/FetchModule';
import HoldingSettingCountInput from '../../../Component/SettingComponent/HoldingSettingCountInput';
import NewCountInput from '../../../Component/SettingComponent/NewCountInput';
import ReserveOpenCountInput from '../../../Component/SettingComponent/ReserveOpenCountInput';
import SaveCompleteModal from './Modal/SaveCompleModal';
import NotRegistrationCardModal from '../../Share/Normal/NotRegistrationCardModal';
import MaxReserveQueue from '../../../Component/SettingComponent/MaxReserveQueueInput';

const SystemSettingView = forwardRef((props, ref) => {
  const history = useHistory();
  const [rendering, setRendering] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [data, setData] = useState([{
    id: -1,
    reservationOpenTime: 0,
    reservationOpenTimeUnit: 'day',
    reservationChangeTime: 0,
    reservationChangeTimeUnit: 'min',
    reserveAvailableTime: 0,
    reserveAvailableTimeUnit: 'min',
    holdingAvailableTime: 0,
    holdingAvailableTimeUnit: 'min',
    minimumHoldingDay: 0,
    minimumHoldingDayUnit: 'day',
    maxReserveQueue: 0,
    isViewReserveList: false,
    isAvailableWeekendHolding: true,
    isAvailableAttendanceInApp: false,
    isOnlyTodayOpenExercise: false,
    isOpenPartnerGymSales: false,
  }]);

  const [isViewReserveList, setIsViewReserveList] = useState(false);
  const [isOpenTimeline, setIsOpenTimeline] = useState(false);
  const [isAvailableWeekendHolding, setIsAvailableWeekendHolding] = useState(true);
  const [isAvailableAttendanceInApp, setIsAvailableAttendanceInApp] = useState(false);
  const [isOnlyTodayOpenExercise, setIsOnlyTodayOpenExercise] = useState(false);
  const [isOpenPartnerGymSales, setIsOpenPartnerGymSales] = useState(false);
  const [isViewPartnerGymSetting, setIsViewPartnerGymSetting] = useState(false);
  const [isRegistBillingInfo, setIsRegistBillingInfo] = useState(false);
  const [isUseNoShowPenalty, setIsUseNoShowPenalty] = useState(false);

  const [isUseFiveDayKakaoMessage, setIsUseFiveDayKakaoMessage] = useState(false);
  const [isUseThreeDayKakaoMessage, setIsUseThreeDayKakaoMessage] = useState(false);
  const [isUseOneDayKakaoMessage, setIsUseOneDayKakaoMessage] = useState(false);
  const [isDuplicateMembershipAlertLimited, setIsDuplicateMembershipAlertLimited] = useState(false);

  const [isViewRegistrationCard, setIsViewRegistrationCard] = useState(false);
  const [isAutoAttendance, setIsAutoAttendance] = useState(false);

  const [maxReserveQueue, setMaxReserveQueue] = useState(0);
  const TopLevelRef = useRef();
  const ReservationOpenTimeRef = useRef();
  const ReservationCancelTimeRef = useRef();
  const ReservationAvailableTimeRef = useRef();
  const AttendanceDeadLineRef = useRef();
  const ReservationChangeTimeRef = useRef();
  const HoldingAvaliableRef = useRef();
  const MinimumHoldingRef = useRef();
  const MaximumHoldingRef = useRef();

  useImperativeHandle(ref, () => ({
    setOpacity: (val) => {
      TopLevelRef.current.style.opacity = val;
    },
    setZindex: (val) => {
      TopLevelRef.current.style.zIndex = val;
    },
    save: () => {
      save();
    }
  }))

  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getIndividualData('system/', 'GET');
        // console.log(responseData.data);
        if (responseData.status === 200) {
          // 아무런 데이터가 없을경우 //
          if (responseData.data[0] === null) {
            setData([{
              id: -1,
              reservationOpenTime: 0,
              reservationOpenTimeUnit: 'day',
              reservationChangeTime: 0,
              reservationChangeTimeUnit: 'min',
              reserveAvailableTime: 0,
              reserveAvailableTimeUnit: 'min',
              holdingAvailableTime: 0,
              holdingAvailableTimeUnit: 'day',
              minimumHoldingDay: 0,
              minimumHoldingDayUnit: 'day',
              maximumHoldingDay: 0,
              maximumHoldingDayUnit: 'day',
              maxReserveQueue: 0,
              isViewReserveList: false,
              isOpenTimeline: false,
              isAvailableWeekendHolding: true,
              isAvailableAttendanceInApp: false,
              isOnlyTodayOpenExercise: false,
              isOpenPartnerGymSales: false,
              isUseFiveDayKakaoMessage: false,
              isUseThreeDayKakaoMessage: false,
              isUseOneDayKakaoMessage: false,
              isUseNoShowPenalty: false,
              isUseAutoAttendance: false,
              isDuplicateMembershipAlertLimited: false
            }])

            setIsViewPartnerGymSetting(false);
          }
          // 데이터가 존재하는 경우
          else {
            setData(responseData.data);
            setIsViewPartnerGymSetting(responseData.data[0].isViewPartnerGymSetting);
          }
        }
        else if (responseData.status === 401) history.push('/');
        // else if (responseData.status !== 204) alert("시스템 정보를 불러오는데 실패하였습니다.\n잠시후 다시시도 해주세요");
      }
    )();
  }, [history, rendering])

  useEffect(() => {
    // console.log(data[0].reservationOpenTime)
    if (data[0].reservationOpenTimeUnit === 'min') ReservationOpenTimeRef.current.setValue(data[0].reservationOpenTime);
    else if (data[0].reservationOpenTimeUnit === 'time') ReservationOpenTimeRef.current.setValue(parseInt(data[0].reservationOpenTime / 60));
    else ReservationOpenTimeRef.current.setValue(parseInt((data[0].reservationOpenTime / 60) / 24));

    if (data[0].reservationChangeTimeUnit === 'min') ReservationChangeTimeRef.current.setValue(data[0].reservationChangeTime);
    else if (data[0].reservationChangeTimeUnit === 'time') ReservationChangeTimeRef.current.setValue(parseInt(data[0].reservationChangeTime / 60));
    else ReservationChangeTimeRef.current.setValue(parseInt((data[0].reservationChangeTime / 60) / 24));

    if (data[0].reserveAvailableTimeUnit === 'min') ReservationAvailableTimeRef.current.setValue(data[0].reserveAvailableTime);
    else if (data[0].reserveAvailableTimeUnit === 'time') ReservationAvailableTimeRef.current.setValue(parseInt(data[0].reserveAvailableTime / 60));
    else ReservationAvailableTimeRef.current.setValue(parseInt((data[0].reserveAvailableTime / 60) / 24));

    HoldingAvaliableRef.current.setValue(data[0].holdingAvailableTime);

    // ReservationOpenTimeRef.current.setUnit(data[0].reservationOpenTimeUnit);
    ReservationOpenTimeRef.current.setTime(data[0].reservationOpenStandardTime);
    ReservationChangeTimeRef.current.setUnit(data[0].reservationChangeTimeUnit);
    ReservationAvailableTimeRef.current.setUnit(data[0].reserveAvailableTimeUnit);

    MinimumHoldingRef.current.setValue(data[0].minimumHoldingDay);
    MaximumHoldingRef.current.setValue(data[0].maximumHoldingDay);

    setMaxReserveQueue(data[0].maxReserveQueue)

    setIsOpenTimeline(data[0].isOpenTimeline);
    setIsViewReserveList(data[0].isViewReserveList);
    setIsAvailableAttendanceInApp(data[0].isAvailableAttendanceInApp);
    setIsOnlyTodayOpenExercise(data[0].isOnlyTodayOpenExercise);
    setIsAvailableWeekendHolding(data[0].isAvailableWeekendHolding);
    setIsOpenPartnerGymSales(data[0].isOpenPartnerGymSales);
    setIsRegistBillingInfo(data[0].isRegistBillingInfo);

    setIsUseFiveDayKakaoMessage(data[0].isUseFiveDayKakaoMessage);
    setIsUseThreeDayKakaoMessage(data[0].isUseThreeDayKakaoMessage);
    setIsUseOneDayKakaoMessage(data[0].isUseOneDayKakaoMessage);
    setIsUseNoShowPenalty(data[0].isUseNoShowPenalty);
    setIsAutoAttendance(data[0].isAutoAttendance);
    setIsDuplicateMembershipAlertLimited(data[0].isDuplicateMembershipAlertLimited);
  }, [data])

  // 카카오 알림톡 설명 텍스트 결정 //
  function decideKakaoText() {
    if (!isUseFiveDayKakaoMessage
      && !isUseThreeDayKakaoMessage
      && !isUseOneDayKakaoMessage) return '회원권 만료전 알림톡 발송 안함';
    else {
      let str = '';

      if (isUseFiveDayKakaoMessage) str += ',5';
      if (isUseThreeDayKakaoMessage) str += ',3';
      if (isUseOneDayKakaoMessage) str += ',1';

      return '회원권 만료 ' + str.slice(1, str.length) + '일전 알림톡 발송';
    }
  }

  // 카카오 알림톡 5일 클릭시 //
  function onClickFiveDays() {
    if (!isRegistBillingInfo) {
      setIsViewRegistrationCard(true);
      return;
    }

    setIsUseFiveDayKakaoMessage(!isUseFiveDayKakaoMessage);
  }

  // 카카오 알림톡 3일 클릭시 //
  function onClickThreeDays() {
    if (!isRegistBillingInfo) {
      setIsViewRegistrationCard(true);
      return;
    }

    setIsUseThreeDayKakaoMessage(!isUseThreeDayKakaoMessage);
  }
  // 카카오 알림톡 1일 클릭시 //
  function onClickOneDays() {
    if (!isRegistBillingInfo) {
      setIsViewRegistrationCard(true);
      return;
    }

    setIsUseOneDayKakaoMessage(!isUseOneDayKakaoMessage);
  } 

  // 입력하기 버튼 클릭시 //
  function onClickRegistrationCard() {
    const obj = {};

    obj.PCD_PAY_TYPE = 'card'
    obj.PCD_PAY_WORK = 'AUTH'
    obj.PCD_CARD_VER = '01';
    obj.PCD_PAYER_AUTHTYPE = 'sms';

    obj.callbackFunction = getResult;
    obj.clientKey = '0F44C92BFB07F1BE60C5235A7254316D';
    // obj.clientKey = 'test_DF55F29DA654A8CBC0F0A9DD4B556486';
    
    window.PaypleCpayAuthCheck(obj);
  }
  
  // 결제 이후 콜백 //
  function getResult(res) {
    if (res.PCD_PAY_RST === 'success' && (res.PCD_PAY_CODE === '0000' || res.PCD_PAY_CODE === 'CDAU1000')) {
      let requestData = {
        billingKey: res.PCD_PAYER_ID
      }
      // console.log(requestData);
      // console.log(res);
      // console.log(res.PCD_PAYER_ID);
      // console.log(res.PCD_PAYER_NO);
      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataVersion2('billing/registry', 'POST', requestData);

          if (responseData.status === 200) {
            alert('카드번호 등록이 완료되었습니다!');
            // setCountData({isRegistrationCard: true, validCount: countData.validCount, invalidCount: countData.invalidCount });
            setIsViewRegistrationCard(false);
            setIsRegistBillingInfo(true);
          }
        }
      )();
    }
    else {
      if (res.PCD_PAY_CODE === undefined) {
        console.log(res.PCD_PAY_MSG);
        alert(res.PCD_PAY_MSG);
      }
      else {
        console.log('[' + res.PCD_PAY_CODE + ']' + ' ' + res.PCD_PAY_MSG);
        alert('[' + res.PCD_PAY_CODE + ']' + ' ' + res.PCD_PAY_MSG);
      }
    }
  }

  // 저장하기 //
  function save() {
    let reservationOpenTime = ReservationOpenTimeRef.current.getValue();
    let reservationOpenStandardTime = ReservationOpenTimeRef.current.getTime();
    let reservationChangeTime = ReservationChangeTimeRef.current.getValue();
    let reservationAvailableTime = ReservationAvailableTimeRef.current.getValue();
    let reservationOpenTimeUnit = 'day';
    let reservationChangeTimeUnit = ReservationChangeTimeRef.current.getUnit();
    let reservationAvailableTimeUnit = ReservationAvailableTimeRef.current.getUnit();
    let holdingAvailableTime = HoldingAvaliableRef.current.getValue();
    let holdingAvailableTimeUnit = 'day';
    let minimumHoldingDay = MinimumHoldingRef.current.getValue();
    let minimumHoldingDayUnit = 'day';
    let maximumHoldingDay = MaximumHoldingRef.current.getValue();
    let maximumHoldingDayUnit = 'day';

    if (reservationOpenTimeUnit === '분') reservationOpenTimeUnit = 'min';
    else if (reservationOpenTimeUnit === '시간') reservationOpenTimeUnit = 'time';
    else reservationOpenTimeUnit = 'day';

    if (reservationChangeTimeUnit === '분') reservationChangeTimeUnit = 'min';
    else if (reservationChangeTimeUnit === '시간') reservationChangeTimeUnit = 'time';
    else reservationChangeTimeUnit = 'day';

    if (reservationAvailableTimeUnit === '분') reservationAvailableTimeUnit = 'min';
    else if (reservationAvailableTimeUnit === '시간') reservationAvailableTimeUnit = 'time';
    else reservationAvailableTimeUnit = 'day';

    if (isUseNoShowPenalty && isAutoAttendance) {
      alert('노쇼 패널티와 자동 출석 체크는 같이 설정할 수 없습니다');
      return;
    }

    let newData = {
      id: data[0].id,
      reservationOpenTime: reservationOpenTime,
      reservationOpenStandardTime: reservationOpenStandardTime,
      reservationChangeTime: reservationChangeTime,
      reserveAvailableTime: reservationAvailableTime,
      reservationOpenTimeUnit: reservationOpenTimeUnit,
      reservationChangeTimeUnit: reservationChangeTimeUnit,
      reserveAvailableTimeUnit: reservationAvailableTimeUnit,
      // reserveAvailableTime: 0,
      // reserveAvailableTimeUnit: 'min',
      holdingAvailableTime: holdingAvailableTime,
      holdingAvailableTimeUnit: holdingAvailableTimeUnit,
      minimumHoldingDay: minimumHoldingDay,
      minimumHoldingDayUnit: minimumHoldingDayUnit,
      maximumHoldingDay: maximumHoldingDay,
      maximumHoldingDayUnit: maximumHoldingDayUnit,

      isViewReserveList: isViewReserveList,
      isOpenTimeline: isOpenTimeline,
      isAvailableAttendanceInApp: isAvailableAttendanceInApp,
      isOnlyTodayOpenExercise: isOnlyTodayOpenExercise,
      isAvailableWeekendHolding: isAvailableWeekendHolding,
      isOpenPartnerGymSales: isOpenPartnerGymSales,
      isUseFiveDayKakaoMessage: isUseFiveDayKakaoMessage,
      isUseThreeDayKakaoMessage: isUseThreeDayKakaoMessage,
      isUseOneDayKakaoMessage: isUseOneDayKakaoMessage,
      isUseNoShowPenalty: isUseNoShowPenalty,
      isAutoAttendance: isAutoAttendance,
      maxReserveQueue: maxReserveQueue,
      isDuplicateMembershipAlertLimited: isDuplicateMembershipAlertLimited
    }
    // console.log(newData);
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.patchData('system/save', 'POST', newData);

        if (responseData.status === 200) {
          setIsSaved(true);
          setData(responseData.data);
        }
        else if (responseData.status === 401) history.push('/');
        else if (responseData.status !== 204) alert("시스템 정보를 저장하는데 실패하였습니다.\n잠시후 다시시도 해주세요");
      }
    )();
  }

  return (
    <TopLevelWrapper ref={TopLevelRef}>
      { isSaved && <SaveCompleteModal closeFn={() => setIsSaved(false)} /> }
      { isViewRegistrationCard && <NotRegistrationCardModal division='Notice' setIsViewModal={setIsViewRegistrationCard} onClickRegistCard={onClickRegistrationCard}/> }
      <InfoBoxWrapper>
        <Catrgory width={71} backgroundColor='#FF8B48'>
          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='default'>수업</Text>
        </Catrgory>
        <InfoBox width={964}>
          <ComponentColumnWrapper>
            <ComponentWrapper>
              <Title>수업 예약 오픈 시간</Title>
              <Explanation>수업일 기준 며칠 전부터 예약을 오픈할지 설정하세요</Explanation>
              {/* <SettingCountInput ref={ReservationOpenTimeRef} text='전부터' /> */}
              <ReserveOpenCountInput ref={ReservationOpenTimeRef} />
            </ComponentWrapper>
            <ComponentRowWrapper style={{ marginTop: '13px' }}>
              <SlideTitleWrapper>
                <Title>예약 대기 인원 설정</Title>
                <Explanation>예약 대기 인원을 제한 수</Explanation>
              </SlideTitleWrapper>
              <SlideComponentWrappper style={{ marginLeft: '41px' }}>
                <MaxReserveQueue value={maxReserveQueue} setValue={setMaxReserveQueue}/>
              </SlideComponentWrappper>
            </ComponentRowWrapper>
            <ComponentRowWrapper style={{ marginTop: '35px' }}>
              <SlideTitleWrapper>
                <Title>자동 출석 체크</Title>
                <Explanation>수업 예약 인원 자동 출석 완료</Explanation>
              </SlideTitleWrapper>
              <SlideComponentWrappper style={{ marginLeft: '16px' }} onClick={() => setIsAutoAttendance(!isAutoAttendance)}>
                <SlideOutBox isClicked={isAutoAttendance}>
                  <SlideInBox isClicked={isAutoAttendance} />
                </SlideOutBox>
                <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#CBCBCB' cursor='default'>{isAutoAttendance ? '자동' : '수동'}</Text>
              </SlideComponentWrappper>
            </ComponentRowWrapper>
          </ComponentColumnWrapper>
          <ComponentColumnWrapper>
            <ComponentWrapper>
              <Title>수업 예약 허용 시간</Title>
              <Explanation>수업 시작 전 예약 가능한 시간을 설정해주세요</Explanation>
              <SettingCountInput ref={ReservationAvailableTimeRef} text='전까지' />
            </ComponentWrapper>
            
            <ComponentRowWrapper style={{ marginTop: '32px' }}>
              <SlideTitleWrapper>
                <Title>예약 인원 노출</Title>
                <Explanation>링크코치 명단 노출 설정</Explanation>
              </SlideTitleWrapper>
              <SlideComponentWrappper style={{ marginLeft: '43px' }} onClick={() => setIsViewReserveList(!isViewReserveList)}>
                <SlideOutBox isClicked={isViewReserveList}>
                  <SlideInBox isClicked={isViewReserveList} />
                </SlideOutBox>
                <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#CBCBCB' cursor='default'>{isViewReserveList ? '공개' : '비공개'}</Text>
              </SlideComponentWrappper>
            </ComponentRowWrapper>

            <ComponentRowWrapper style={{ marginTop: '34px' }}>
              <SlideTitleWrapper>
                <Title>노쇼 패널티 사용</Title>
                <Explanation>노쇼회원 예약 제한 (1일)</Explanation>
              </SlideTitleWrapper>
              <SlideComponentWrappper style={{ marginLeft: '40px' }} onClick={() => setIsUseNoShowPenalty(!isUseNoShowPenalty)}>
                <SlideOutBox isClicked={isUseNoShowPenalty}>
                  <SlideInBox isClicked={isUseNoShowPenalty} />
                </SlideOutBox>
                <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#CBCBCB' cursor='default'>{isUseNoShowPenalty ? '활성' : '비활성'}</Text>
              </SlideComponentWrappper>
            </ComponentRowWrapper>
          </ComponentColumnWrapper>
          <ComponentColumnWrapper>
            <ComponentWrapper>
              <Title>수업 취소 가능 시간</Title>
              <Explanation>수업 시작 전 취소 가능한 시간을 설정해주세요</Explanation>
              <SettingCountInput ref={ReservationChangeTimeRef} text='전까지' />
            </ComponentWrapper>

            <ComponentRowWrapper style={{ marginTop: '32px' }}>
              <SlideTitleWrapper>
                <Title>링크코치 앱 출석 가능</Title>
                <Explanation>회원 스스로 앱에서 출석 가능</Explanation>
              </SlideTitleWrapper>
              <SlideComponentWrappper style={{ marginLeft: '18px' }} onClick={() => setIsAvailableAttendanceInApp(!isAvailableAttendanceInApp)}>
                <SlideOutBox isClicked={isAvailableAttendanceInApp}>
                  <SlideInBox isClicked={isAvailableAttendanceInApp} />
                </SlideOutBox>
                <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#CBCBCB' cursor='default'>{isAvailableAttendanceInApp ? '가능' : '불가능'}</Text>
              </SlideComponentWrappper>
            </ComponentRowWrapper>
            {/* <ComponentRowWrapper style={{ marginTop: '32px' }}>
              <SlideTitleWrapper>
                <Title>노쇼 패널티 사용</Title>
                <Explanation>노쇼회원 예약 제한 (1일)</Explanation>
              </SlideTitleWrapper>
              <SlideComponentWrappper style={{ marginLeft: '18px' }} onClick={() => setIsUseNoShowPenalty(!isUseNoShowPenalty)}>
                <SlideOutBox isClicked={isUseNoShowPenalty}>
                  <SlideInBox isClicked={isUseNoShowPenalty} />
                </SlideOutBox>
                <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#777777' cursor='default'>{isUseNoShowPenalty ? '활성' : '비활성'}</Text>
              </SlideComponentWrappper>
            </ComponentRowWrapper> */}
          </ComponentColumnWrapper>
        </InfoBox>
      </InfoBoxWrapper>
      <InfoBoxWrapper>
        <Catrgory width={71} backgroundColor='#FFB800'>
          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='default'>회원권</Text>
        </Catrgory>
        <InfoBox width={964}>
          <ComponentColumnWrapper>
            <ComponentWrapper>
              <Title>홀딩 신청 가능 시간</Title>
              <Explanation>홀딩 적용일 기준 며칠 전까지 신청이 가능한지 설정해주세요</Explanation>
              <HoldingSettingCountInput ref={HoldingAvaliableRef} text='일 전까지' />
            </ComponentWrapper>
            <ComponentRowWrapper style={{ marginTop: '32px' }}>
              <SlideTitleWrapper>
                <Title>주말 홀딩 가능 여부</Title>
                <Explanation>토, 일요일을 포함한 홀딩신청</Explanation>
              </SlideTitleWrapper>
              <SlideComponentWrappper style={{ marginLeft: '18px' }} onClick={() => setIsAvailableWeekendHolding(!isAvailableWeekendHolding)}>
                <SlideOutBox isClicked={isAvailableWeekendHolding}>
                  <SlideInBox isClicked={isAvailableWeekendHolding} />
                </SlideOutBox>
                <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#CBCBCB' cursor='default'>{isAvailableWeekendHolding ? '가능' : '불가능'}</Text>
              </SlideComponentWrappper>
            </ComponentRowWrapper>
          </ComponentColumnWrapper>
          <ComponentColumnWrapper>
            <ComponentWrapper>
              <Title>최소 홀딩 신청 가능 일수</Title>
              <Explanation>홀딩신청이 가능한 최소일수를 설정해주세요</Explanation>
              <HoldingSettingCountInput ref={MinimumHoldingRef} text='일부터' />
            </ComponentWrapper>
          </ComponentColumnWrapper>
          <ComponentColumnWrapper>
            <ComponentWrapper>
              <Title>최대 홀딩 신청 가능 일수</Title>
              <Explanation>홀딩신청이 가능한 최대일수를 설정해주세요</Explanation>
              <HoldingSettingCountInput ref={MaximumHoldingRef} text='일까지' />
            </ComponentWrapper>
          </ComponentColumnWrapper>
        </InfoBox>
      </InfoBoxWrapper>
      <InfoBoxWrapper>
        <Catrgory width={71} backgroundColor='#FF4848'>
          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='default'>알림</Text>
        </Catrgory>
        <InfoBox width={610}>
          <ComponentColumnWrapper>
            <ComponentRowWrapper style={{ marginTop: '13px' }}>
              <SlideTitleWrapper style={{ width: '170px' }}>
                <Title>카카오톡 자동 알림톡</Title>
                <Explanation>{decideKakaoText()}</Explanation>
                <Text style={{ marginTop: '8px' }} fontFamily='NotoSansKR-Regular' fontSize={10} color='#D92828'>추가 요금 발생 (1건/10원)</Text>
              </SlideTitleWrapper>
              <KakaoDayBoxWrapper style={{ marginTop: '-24px', marginLeft: '6px' }}>
                <KakaoDayBox isClicked={isUseFiveDayKakaoMessage} onClick={onClickFiveDays}>5일</KakaoDayBox>
                <KakaoDayBox isClicked={isUseThreeDayKakaoMessage} onClick={onClickThreeDays}>3일</KakaoDayBox>
                <KakaoDayBox isClicked={isUseOneDayKakaoMessage} onClick={onClickOneDays}>1일</KakaoDayBox>
              </KakaoDayBoxWrapper>
            </ComponentRowWrapper>
          </ComponentColumnWrapper>

          <ComponentColumnWrapper>
            <ComponentRowWrapper style={{ marginTop: '13px' }}>
              <SlideTitleWrapper>
                <Title>중복 회원권 알림톡 제한</Title>
                <Explanation>회원권을 이미 소지하고 있는 경우</Explanation>
                <Explanation>알림톡을 발송하지 않습니다.</Explanation>
              </SlideTitleWrapper>
              <SlideComponentWrappper style={{ marginTop: '-24px', marginLeft: '18px' }} onClick={() => setIsDuplicateMembershipAlertLimited(!isDuplicateMembershipAlertLimited)}>
                <SlideOutBox isClicked={isDuplicateMembershipAlertLimited}>
                  <SlideInBox isClicked={isDuplicateMembershipAlertLimited} />
                </SlideOutBox>
                <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#CBCBCB' cursor='default'>{isDuplicateMembershipAlertLimited ? '활성' : '비활성'}</Text>
              </SlideComponentWrappper>
            </ComponentRowWrapper>
          </ComponentColumnWrapper>
        </InfoBox>
      </InfoBoxWrapper>
      <InfoBoxWrapper>
        <Catrgory width={71} backgroundColor='#6DD49E'>
          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='default'>링크코치</Text>
        </Catrgory>
        <InfoBox width={644}>
          <ComponentColumnWrapper>
            <ComponentRowWrapper style={{ marginTop: '13px' }}>
              <SlideTitleWrapper>
                <Title>타임라인 회원 작성 권한</Title>
                <Explanation>타임라인 글쓰기에 회원도 참여</Explanation>
              </SlideTitleWrapper>
              <SlideComponentWrappper style={{ marginLeft: '16px' }} onClick={() => setIsOpenTimeline(!isOpenTimeline)}>
                <SlideOutBox isClicked={isOpenTimeline}>
                  <SlideInBox isClicked={isOpenTimeline} />
                </SlideOutBox>
                <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#CBCBCB' cursor='default'>{isOpenTimeline ? '가능' : '불가능'}</Text>
              </SlideComponentWrappper>
            </ComponentRowWrapper>
          </ComponentColumnWrapper>
          <ComponentColumnWrapper>
            <ComponentRowWrapper style={{ marginTop: '13px' }}>
              <SlideTitleWrapper>
                <Title>타임라인 운동 당일 오픈</Title>
                <Explanation>운동을 당일에 오픈해드립니다</Explanation>
              </SlideTitleWrapper>
              <SlideComponentWrappper style={{ marginLeft: '16px' }} onClick={() => setIsOnlyTodayOpenExercise(!isOnlyTodayOpenExercise)}>
                <SlideOutBox isClicked={isOnlyTodayOpenExercise}>
                  <SlideInBox isClicked={isOnlyTodayOpenExercise} />
                </SlideOutBox>
                <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#CBCBCB' cursor='default'>{isOnlyTodayOpenExercise ? '당일 오픈' : '항상 오픈'}</Text>
              </SlideComponentWrappper>
            </ComponentRowWrapper>
          </ComponentColumnWrapper>
        </InfoBox>
      </InfoBoxWrapper>
      {
        isViewPartnerGymSetting &&
        <InfoBoxWrapper>
          <Catrgory width={100} backgroundColor='#00A3FF'>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='default'>파트너 체육관</Text>
          </Catrgory>
          <InfoBox width={266}>
            <ComponentColumnWrapper>
              <ComponentRowWrapper style={{ marginTop: '13px' }}>
                <SlideTitleWrapper style={{ width: '152px' }}>
                  <Title>파트너 체육관 매출</Title>
                  <Explanation>파트너 체육관에서 매출을</Explanation>
                  <Explanation>오픈합니다</Explanation>
                </SlideTitleWrapper>
                <SlideComponentWrappper style={{ marginTop: '-24px', marginLeft: '16px' }} onClick={() => setIsOpenPartnerGymSales(!isOpenPartnerGymSales)}>
                  <SlideOutBox isClicked={isOpenPartnerGymSales}>
                    <SlideInBox isClicked={isOpenPartnerGymSales} />
                  </SlideOutBox>
                  <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#CBCBCB' cursor='default'>{isOpenPartnerGymSales ? '공개' : '비공개'}</Text>
                </SlideComponentWrappper>
              </ComponentRowWrapper>
            </ComponentColumnWrapper>
          </InfoBox>
        </InfoBoxWrapper>
      }
    </TopLevelWrapper>
  )
})

export default SystemSettingView;

const TopLevelWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  // width: 1020px;
  // width: 964px;
  // height: 258px;

  // opacity: 0;
  // z-index: -3000;

  // margin: -800px 0 545px 0;
  // padding: 28px 28px 28px 28px;
  margin-bottom: 260px;

  // gap: 80px;
  gap: 33px;

  // border-radius: 30px;
  // box-shadow: 0px 40px 50px rgba(240, 232, 227, 0);
  // background-color: #FFFFFF;

  transition: all 0.3s ease-in-out;
`;

const InfoBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const Catrgory = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // width: 71px;
  width: ${props => props.width}px;
  height: 27px;

  border-radius: 10px 10px 0px 0px;
  background-color: ${props => props.backgroundColor};
`;

const InfoBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  padding: 28px 28px 28px 28px;
  // margin-bottom: 260px;

  // width: 964px;
  width: ${props => props.width}px;
  
  // gap: 50px;
  gap: 30px;

  border-radius: 16px;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0);
  background-color: #FFFFFF;

  transition: all 0.3s ease-in-out;
`;

const ComponentColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  // align-items: center;

  // gap: 32px;

  // margin-top: 28px;
`;

const ComponentRowWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width 300px;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width 300px;
  // gap: 8px;
  gap: 4px;
`;

const SlideTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 4px;
`;

const Title = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;
`;

const Explanation = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #CBCBCB;

  margin: 0 0 0 0;
`;

const SlideComponentWrappper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  gap: 8px;
`;

const SlideOutBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 69px;
  height: 30px;

  border: ${props => props.isClicked ? '1px solid #FF8B48' : '1px solid #DFDFDF'};
  border-radius: 8px;
  background-color: ${props => props.isClicked ? '#FF8B48' : '#FFFFFF'};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const SlideInBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-left: ${props => props.isClicked ? '41px' : '2px'};
  width: 26px;
  height: 26px;

  border-radius: 6px;
  background-color: ${props => props.isClicked ? '#FFFFFF' : '#DFDFDF'};

  transition: all 0.3s ease-in-out;
`;

const KakaoDayBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 6px;
`;


const KakaoDayBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 30px;
  height: 30px;

  border: ${props => props.isClicked ? '1px solid #FF8B48' : '1px solid #DFDFDF'};
  border-radius: 8px;
  background-color: ${props => props.isClicked ? '#FF8B48' : '#FFFFFF'};

  font-family: NotoSansKR-Medium;
  font-size: 12px;

  color: ${props => props.isClicked ? `#FFFFFF` : `#DFDFDF`};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;


const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }
`;