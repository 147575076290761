import { useState, useImperativeHandle, forwardRef, useRef, React } from 'react';
import styled from 'styled-components';

export default function UnitInput(props) {
	const { className, placeholder, value, setValue, unit } = props;

	const [changed, setChanged] = useState(false);
	const [changedPlaceholderColor, setChangedPlaceholderColor] = useState(false);
	const [clickCount, setClickCount] = useState(0);
	const [defaultPlaceholder, setDefaultPlaceholder] = useState(placeholder);

	const inputRef = useRef();

	function handleClick(e) {
		inputRef.current.style.borderColor = '#FF8B48';
		// setDefaultPlaceholder('');
	}

	function handleChange(e) {
    var regex = /[^0-9]/g;				// 숫자가 아닌 문자열을 매칭하는 정규식
    var result = e.target.value.replace(regex, "");	// 원래 문자열에서 숫자가 아닌 모든 문자열을 빈 문자로 변경
    
		if (result === '') setValue(0);
    else if (result < 9999999) setValue(parseInt(result).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));

		setChanged(true);
	}

	function handleFocus(e) {
		setChangedPlaceholderColor(false);
		if (changed) {
			// do nothing
		} else {
			setValue("");
		}
	}

	function handleBlur(e) {
		setClickCount(clickCount + 1);
		inputRef.current.style.borderColor = '#E8E8E8';
		if (value === "") {
			// setDefaultPlaceholder(placeholder);
			// inputRef.current.style.borderColor = '#FF3131';
		} 
		else {
			// inputRef.current.style.borderColor = '#E8E8E8';
		}
	}

	return (
    <TopLevelWrapper>
      <Input
        ref={inputRef}
        clickCount={clickCount}
        placeholder={defaultPlaceholder}
        value={value}
        changedColor={changedPlaceholderColor}
        onClick={handleClick}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur} />
        <Text>{unit}</Text>
    </TopLevelWrapper>
  ) 
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // gap: 5px;
  gap: 12px;
`;

const Text = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #777777;

  margin: 0 0 0 0;
`;

const Input = styled.input`
  text-align: right;
	font-family: NotoSansKR-Regular;
	font-size: 14px;
	line-height: 20px;
	
	padding: 0 8px 0 8px;

  // width: 69px;
  width: 145px;
  height: 30px;

	outline: none;
  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background-color: #FFFFFF;

	color: #000000;

	::placeholder {
		color: ${props => props.changedColor ? `#FF3131` : `#CBCBCB`};
	}
	
  transition: all 0.3s ease-in-out;
`;