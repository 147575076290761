import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import UserProfile from "../../Component/Normal/UserProfile";
import InfoInput from "../../Component/MySettingPageComponent/InfoInput";
import CenterTypeSelect from "../../Component/MySettingPageComponent/CenterTypeSelect";
import OpenTimeInput from "../../Component/MySettingPageComponent/OpenTimeInput";
import UsePaymentSystemButton from "../../Component/MySettingPageComponent/UsePaymentSystemButton";
import CenterIntroTextArea from "../../Component/MySettingPageComponent/CenterIntroTextArea";

import FetchModule from "../../Model/Share/Network/FetchModule";
import LogoView from "../../Component/MySettingPageComponent/LogoView";

import DefaultLogoPath from '../../image/DefaultLogo.png';
import WhitePencelIcon from '../../image/WhitePencel.svg';
import PictureAddImage from '../../image/PictureAdditionPlus.png';
import PictureView from "./PictureView";


export default function MyStoreIntroductionBox(props) {
  const {
    isClicked,
    onClickModifyCenterInfo,

    data,
    storeLogo,
    setStoreLogo,
    storeName,
    setStoreName,
    tel,
    setTel,
    type,
    setType,
    address,
    setAddress,
    openingHour,
    setOpeningHour,
    isUsePaymentSystem,
    setIsUsePaymentSystem,
    storeIntro,
    setStoreIntro,
    onClickSave,

    profiles,
    setProfiles,
    setData,
    autoSave
  } = props;


  const [logoImgBase64, setLogoImgBase64] = useState();
  const [logoImgFile, setLogoImgFile] = useState();
  const [logoImgLocalPath, setLogoImgLocalPath] = useState('');

  const [centerImgBase64, setCenterImgBase64] = useState();
  const [centerImgFile, setCenterImgFile] = useState();
  const [centerImgLocalPath, setCenterImgLocalPath] = useState('');

  const LogoCanvasRef = useRef();
  const LogoHiddenFileInput = useRef(null);
  const LogoFileInputRef = useRef();

  const CenterCanvasRef = useRef();
  const CenterHiddenFileInput = useRef(null);
  const CenterFileInputRef = useRef();

  const logoHandleClick = e => {
    LogoHiddenFileInput.current.click();
    LogoFileInputRef.current.style.borderColor = '#FF8B48';
  };

  const logoHandleChange = e => {
    var pathpoint = e.target.value.lastIndexOf('.');
    var filepoint = e.target.value.substring(pathpoint + 1, e.target.length);
    var filetype = filepoint.toLowerCase();

    if (filetype === 'jpg' || filetype === 'png' || filetype === 'jpeg') {

      // 정상적인 이미지 확장자 파일인 경우
      const reader = new FileReader();
      // setImgLocalPath(e.target.value);
      var tmp = e.target.value.split('\\');
      setLogoImgLocalPath(tmp[tmp.length - 1]);
      reader.onloadend = () => {
        const base64 = reader.result;
        const naturalImage = new Image();

        naturalImage.src = reader.result.toString();

        // if (base64){
        //   setStoreLogo(base64.toString());

        // }
        naturalImage.onload = function (e) {

          let naturalWidth = e.target.naturalWidth;
          let naturalHeight = e.target.naturalHeight;

          let resizeWidth = 0;
          let resizeHeight = 0;

          if (naturalWidth < naturalHeight) {
            resizeWidth = 600;
            resizeHeight = (600 * naturalHeight) / naturalWidth;
          }
          else if (naturalWidth > naturalHeight) {
            resizeHeight = 600;
            resizeWidth = (600 * naturalWidth) / naturalHeight;
          }
          else {
            resizeWidth = 600;
            resizeHeight = 600;
          }

          const image = new Image();
          const canvas = LogoCanvasRef.current;
          const ctx = canvas?.getContext('2d');

          if (ctx) {
            canvas.width = 600;
            canvas.height = 600;
            // canvas.backgroundColor = '#FFFFFF';

            image.src = base64.toString();

            // 사진 크기 조정후 Canvas에 그리기 //
            image.onload = function () {
              ctx.clearRect(0, 0, canvas.width, canvas.height);
              // ctx.drawImage(image, 0, 0, resizeWidth, resizeHeight);
              ctx.drawImage(image, ((600 - resizeWidth) / 2), ((600 - resizeHeight) / 2), resizeWidth, resizeHeight);
            }

            // 크기 조절된 사진 데이터 정리하기 //
            canvas.toBlob(function (blob) {
              const reader = new FileReader();
              // Canvas에 다 그려졌을때 Event //
              reader.onload = function (e) {
                setStoreLogo(canvas.toDataURL());
              }
              reader.readAsDataURL(blob);
            })
          }
          else {
            // throw new Error('Could not get context');
            console.log("Could not get context");
          }
        }
      };
      if (e.target.files[0]) {
        reader.readAsDataURL(e.target.files[0]);
        setLogoImgFile(e.target.files[0]);
        // setStoreLogo(e.target.files[0]);
      }
    }
    else {
      alert('.jpeg .jpg .png 파일만 가능합니다!');
    }
  };



  const centerHandleClick = e => {
    // if (autoSave()) CenterHiddenFileInput.current.click();
    CenterHiddenFileInput.current.click();
    // FileInputRef.current.style.borderColor = '#FF8B48';
  };

  const centerHandleChange = e => {

    var pathpoint = e.target.value.lastIndexOf('.');
    var filepoint = e.target.value.substring(pathpoint + 1, e.target.length);
    var filetype = filepoint.toLowerCase();

    if (filetype === 'jpg' || filetype === 'png' || filetype === 'jpeg') {

      // 정상적인 이미지 확장자 파일인 경우

    }
    else {
      alert('.jpeg .jpg .png 파일만 가능합니다!');
      var parentObj = e.target.parentNode
      var node = parentObj.replaceChild(e.target.cloneNode(true), e.target);

      return false;
    }

    const reader = new FileReader();
    // setImgLocalPath(e.target.value);
    var tmp = e.target.value.split('\\');
    setCenterImgLocalPath(tmp[tmp.length - 1]);
    reader.onloadend = () => {
      const base64 = reader.result;
      const naturalImage = new Image();

      naturalImage.src = reader.result.toString();

      if (base64) {
        naturalImage.onload = function (e) {
          let standardSize = 800;

          let naturalWidth = e.target.naturalWidth;
          let naturalHeight = e.target.naturalHeight;

          let resizeWidth = 0;
          let resizeHeight = 0;

          // 원본 사이즈로 기준사이즈 정해서 비율계산//
          if (naturalWidth < naturalHeight) {
            resizeWidth = standardSize;
            resizeHeight = (standardSize * naturalHeight) / naturalWidth;
          }
          else if (naturalWidth > naturalHeight) {
            resizeHeight = standardSize;
            resizeWidth = (standardSize * naturalWidth) / naturalHeight;
          }
          else {
            resizeWidth = standardSize;
            resizeHeight = standardSize;
          }

          let image = new Image();
          let canvas = CenterCanvasRef.current;
          let ctx = canvas?.getContext('2d');

          if (ctx) {
            canvas.width = standardSize;
            canvas.height = standardSize;
            canvas.backgroundColor = '#FFFFFF';

            image.src = base64.toString();

            // 사진 크기 조정후 Canvas에 그리기 //
            image.onload = function () {
              ctx.clearRect(0, 0, canvas.width, canvas.height);

              // 800 x 800 일반 설정형 //
              // ctx.drawImage(image, 0, 0, standardSize, standardSize);

              // 원본 비율 생존형 //
              ctx.drawImage(image, ((standardSize - resizeWidth) / 2), ((standardSize - resizeHeight) / 2), resizeWidth, resizeHeight);

              // 원본 사진 크롭형 //
              // ctx.drawImage(image, ((naturalWidth / 2) - (standardSize / 2)), ((naturalHeight / 2) - (standardSize / 2)), standardSize, standardSize, 0, 0, 800, 800);
            }

            // 크기 조절된 사진 데이터 정리하기 //
            canvas.toBlob(function (blob) {
              const reader = new FileReader();
              // Canvas에 다 그려졌을때 Event //
              reader.onload = function (e) {
                var requestData = {
                  profile: canvas.toDataURL()
                }

                const fetchModule = new FetchModule();
                (
                  async () => {
                    const responseData = await fetchModule.postDataVersion2('account/profile/upload', 'POST', requestData);

                    if (responseData.status === 200) setData(responseData.data);
                  }
                )();
              }
              reader.readAsDataURL(blob);
            })
          }
          else {
            // throw new Error('Could not get context');
            console.log("Could not get context");
          }
        }
      }
    };
    if (e.target.files[0]) {
      reader.readAsDataURL(e.target.files[0]);
      setCenterImgFile(e.target.files[0]);
    }
  };

  function onClickDeleteButton(index) {
    let requestData = {
      profile: profiles[index]
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataVersion2('account/profile/delete', 'POST', requestData);

        if (responseData.status === 200) setData(responseData.data);
      }
    )();
  }

  return (
    <TopLevelWrapper>
      <TitleWrapper>
        <TitleBox backgroundColor='#FFB800'>
          <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF' hover='default'>센터 정보</Text>
        </TitleBox>
        <ModifyButton isClicked={isClicked} onClick={onClickModifyCenterInfo}>
          {!isClicked && <PencelImage src={WhitePencelIcon} />}
          <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF' hover='pointer'>{isClicked ? `저장하기` : `수정하기`}</Text>
        </ModifyButton>
      </TitleWrapper>
      {
        isClicked ?
          <InfoView>
            <InfoWrapper>
              <LayerWrapper>
                <LogoWrapper>
                  {/* <Logo src={storeLogo ? storeLogo : DefaultLogoPath} /> */}
                  <UserProfile profile={storeLogo} defaultProfile={DefaultLogoPath} />
                  <canvas ref={LogoCanvasRef} style={{ display: 'none' }} />
                  <input
                    type="file"
                    ref={LogoHiddenFileInput}
                    onChange={logoHandleChange}
                    style={{ display: 'none' }} />
                  <UploadButton ref={LogoFileInputRef} onClick={logoHandleClick}>사진 변경</UploadButton>
                </LogoWrapper>
                <TextWrapper>
                  <InputWrapper topMargin={0}>
                    <InfoInput column='상호명' defaultVal={storeName} setVal={setStoreName} width='457' />
                    <InfoInput column='전화번호' defaultVal={tel} setVal={setTel} width='224' />
                    <CenterTypeSelect defaultVal={type} setVal={setType} />
                  </InputWrapper>
                  <InputWrapper topMargin={28}>
                    <InfoInput column='센터 주소' defaultVal={address} setVal={setAddress} width='457' />
                    <OpenTimeInput column='영업시간' defaultVal={openingHour} setVal={setOpeningHour} width='224' />
                    <UsePaymentSystemButton isUsePaymentSystem={isUsePaymentSystem} setIsUsePaymentSystem={setIsUsePaymentSystem} width={240} />
                  </InputWrapper>
                </TextWrapper>
              </LayerWrapper>
            </InfoWrapper>
            <TitleWrapper style={{ marginTop: `24px`, width: '1115px' }}>
              <TitleTextWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#232323' cursor='default'>센터 소개</Text>
                <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#BDBDBD' cursor='default'>(최대 250자)</Text>
              </TitleTextWrapper>
            </TitleWrapper>
            <InfoWrapper>
              <TextWrapper style={{ marginTop: `24px` }}>
                <CenterIntroTextArea placeholder='센터 소개글을 입력해주세요' defaultValue={storeIntro} setVal={setStoreIntro} />
              </TextWrapper>
            </InfoWrapper>
            <TitleWrapper style={{ marginTop: `24px`, width: '1115px' }}>
              <TitleTextWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#232323' cursor='default'>센터 사진</Text>
                <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#BDBDBD' cursor='default'>(최대 10장)</Text>
              </TitleTextWrapper>
            </TitleWrapper>
            <DivisionLine />
            <TitleWrapper>
              <canvas ref={CenterCanvasRef} style={{ display: 'none' }} />
              <input
                type="file"
                ref={CenterHiddenFileInput}
                onChange={centerHandleChange}
                style={{ display: 'none' }} />
            </TitleWrapper>
            <InfoWrapper>
              <LayerWrapper>
                {
                  profiles.map((data, index) => (
                    <LogoView key={index} isClicked={isClicked} data={data} index={index} onClickDeleteButton={onClickDeleteButton} />
                  ))
                }
                <AdditionBox onClick={centerHandleClick}>
                  <AdditionImage src={PictureAddImage} />
                </AdditionBox>
              </LayerWrapper>
            </InfoWrapper>
          </InfoView>
          :
          <InfoView>
            <InfoWrapper>
              <LayerWrapper>
                <LogoBox>
                  <UserProfile profile={storeLogo} defaultProfile={DefaultLogoPath} />
                </LogoBox>
                <TextWrapper>

                  <TitleTextWrapper>
                    <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#000000'>{storeName}</Text>
                    <UsePaymentWrapper>
                      <UsePaymentBracketText>(</UsePaymentBracketText>
                      <UsePaymentText isUsePaymentSystem={isUsePaymentSystem === 'Yes'}>{isUsePaymentSystem === 'Yes' ? '결제 사용' : '결제 미사용'}</UsePaymentText>
                      <UsePaymentBracketText>)</UsePaymentBracketText>
                    </UsePaymentWrapper>
                  </TitleTextWrapper>

                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#969696'>{address}</Text>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#969696'>{tel}</Text>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#969696'>{openingHour}</Text>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#969696'>{type}</Text>
                </TextWrapper>
              </LayerWrapper>
            </InfoWrapper>
            <TitleWrapper style={{ marginTop: `24px`, width: '1115px' }}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#232323' cursor='default'>센터 소개</Text>
            </TitleWrapper>
            <DivisionLine />
            <IntroInfoWrapper>
              <IntroTextWrapper>
                <PreText fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>{storeIntro}</PreText>
              </IntroTextWrapper>
            </IntroInfoWrapper>
            <TitleWrapper style={{ marginTop: `24px`, width: '1115px' }}>
              <TitleTextWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#232323' cursor='default'>센터 사진</Text>
                <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#BDBDBD' cursor='default'>(최대 10장)</Text>
              </TitleTextWrapper>
            </TitleWrapper>
            <DivisionLine />
            <TitleWrapper>
              <canvas ref={CenterCanvasRef} style={{ display: 'none' }} />
              <input
                type="file"
                ref={CenterHiddenFileInput}
                onChange={centerHandleChange}
                style={{ display: 'none' }} />
            </TitleWrapper>
            <InfoWrapper style={{ width: '1115px' }}>
              {
                profiles.length === 0 ?
                  <LayerWrapper style={{ justifyContent: 'center' }}>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD' cursor='default'>업로드된 센터 사진이 없습니다</Text>
                  </LayerWrapper>
                  :
                  <LayerWrapper>
                    {
                      profiles.map((data, index) => (
                        <PictureView 
                          key={index} 
                          isEdit={isClicked} 
                          data={data} 
                          index={index} 
                          onClickDeleteButton={onClickDeleteButton} />
                      ))
                    }
                  </LayerWrapper>
              }
            </InfoWrapper>
          </InfoView>
      }
    </TopLevelWrapper >
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 30px;

  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const InfoView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 35px 0px 25px 0px;
  // width: 1191px;
  width: 100%;
  // height: 499px;

  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  background-color: #FFFFFF;
`;

const TitleBox = styled.p`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 2px 20px;
  margin: 0 0 0 0;

  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  background-color: ${props => props.backgroundColor};
`;

const ModifyButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 82px;
  height: 24px;

  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  
  background-color: ${props => props.isClicked ? `#6DD49E` : `#CBCBCB`};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 1135px;
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 100%;
  // height: 100px;

  gap: 20px;

  margin-top: ${props => props.topMargin}px;
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const UploadButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 120px;
  height: 32px;

  border-radius: 5px;

  background-color: #33C8FF;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }

  :hover {
    cursor: pointer;
  }
`;

const TitleTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  gap: 8px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: ${props => props.topMargin}px;

  width: 995px;
`;

const DivisionLine = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 12px 0px;

  width: 1115px;
  height: 1px;

  border-radius: 1px;
  background-color: #DBDBDB;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  margin: 0 0 0 0;

  color: ${props => props.color};

  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;

const PencelImage = styled.img`
  width: 20px;
  height: 20px;

  :hover {
    cursor: pointer;
  }
`;

const AdditionBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 99px;
  height: 99px;

  border-radius: 5px;
  background-color: #E7E7E7;

  object-fit: cover;

  :hover {
    cursor: pointer;
  }
`;

const AdditionImage = styled.img`
  width: 64px;
  height: 64px;

  object-fit: cover;
`;

const PreText = styled.pre`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  
  white-space: pre-wrap;

  :hover {
    cursor: pointer;
  }
`;

const UsePaymentWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const UsePaymentBracketText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const UsePaymentText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: ${props => props.isUsePaymentSystem ? `#87C8A6` : `#FF3131`};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const LogoBox = styled.div`
  width: 140px;
  height: 140px;

  border-radius: 15px;

  object-fit: cover;
  overflow: hidden;
`;

const IntroInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 1115px;
`;

const IntroTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  word-break:break-all;
  flex-wrap: wrap;

  // margin-top: 24px;
  // margin-bottom: 28px;

  width: 100%;
`;