import { useState, useImperativeHandle, forwardRef, useRef, React, useEffect } from 'react';
import styled from 'styled-components';

const TextArea = forwardRef((props, ref) => {
	const { className, placeholder, type, value, setValue, setIsFocused } = props;
	// const [value, setValue] = useState(defaultValue);
	const [changed, setChanged] = useState(false);
	const [changedPlaceholderColor, setChangedPlaceholderColor] = useState(false);
	const [clickCount, setClickCount] = useState(0);
	const [defaultPlaceholder, setDefaultPlaceholder] = useState(placeholder);

	const inputRef = useRef();

	useImperativeHandle(ref, () => ({
		setValue: (val) => {
			setValue(val);
			setChanged(true);
		},
		getValue: () => {
			return value;
		},
		plusClickCount: () => {
			setClickCount(clickCount + 1)
		},
		getClickCount: () => {
			return clickCount;
		},
		setDefaultPlaceholder: (val) => {
			setDefaultPlaceholder(val)
			setChangedPlaceholderColor(true);
			inputRef.current.style.borderColor = '#FF3131';
		},
		reset: (val) => {
			setClickCount(0);
			setDefaultPlaceholder(val)
			inputRef.current.style.borderColor = '#E8E8E8';
		}
	}));

	// useEffect(() => {
	// 	inputRef.current.style.height = `${(inputRef.current.scrollHeight + 9)}px`;
	// 	// inputRef.current.style.height = `100%`;
	// }, [])

	// useEffect(() => {
	// 	inputRef.current.style.height = '1px';
	// 	inputRef.current.style.height = (10 + inputRef.current.scrollHeight) + 'px';
	// }, [value])

	function handleChange(e) {
		setValue(e.target.value);
		setChanged(true);
	}

	function handleFocus(e) {
		setChangedPlaceholderColor(false);
    setIsFocused(true);
	}

	function handleBlur(e) {
		setClickCount(clickCount + 1);
    // setIsFocused(false);
	}

  // function onPress(e) {
  //   if (e.key === 'Enter') {
  //     inputRef.current.style.height = `${(inputRef.current.scrollHeight + 9)}px`;
  //   }
  // }

	// function resize() {
	// 	inputRef.current.style.height = '1px';
	// 	inputRef.current.style.height = (10 + inputRef.current.scrollHeight) + 'px';
	// }

	return <Input
		ref={inputRef}
		clickCount={clickCount}
		type={type}
		placeholder={defaultPlaceholder}
		value={value}
		changedColor={changedPlaceholderColor}
		className={`${className} ${changed ? "changed" : ""}`}
		onChange={handleChange}
		onFocus={handleFocus}
		onBlur={handleBlur} />
});

export default TextArea;

const Input = styled.textarea`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  // width: 482px;
  width: 100%;
	min-height: 100%;
  // height: 20px;
  // height: auto;
  // height: 32px;
  resize: none;
  // overflow-y: hidden;
	overflow: visible;
  // resize: vertical;

  // padding-left: 16px;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;
  border: 0px solid #DFDFDF;
	// line-height: 20px;

  color: #595959;

	outline: none;

	::placeholder {
		color: ${props => props.changedColor ? `#FF3131` : `#CBCBCB`};
	}
	
  // transition: all 0.3s ease-in-out;
`;