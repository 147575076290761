import { React, useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

const ClassInfoBox = (props) => {
  const { data, index, coachList, modifyClickFn, deleteClickFn } = props;

  const [teacherIdList, setTeacherIdList] = useState([]);

  const TopLevelRef = useRef();
  const ButtonRef = useRef();
  const CoachRef = useRef([]);
  const TimeRef = useRef();

  useEffect(() => {
    var newTeacherIdList = data.teacherIds.split(',');

    setTeacherIdList(newTeacherIdList);
  }, [data])

  function mouseOver() {
    TopLevelRef.current.style.backgroundColor = data.color;
    TimeRef.current.style.backgroundColor = data.color;

    for (var i = 0; i < teacherIdList.length; i++) {
      if (i === 3) break;
      CoachRef.current[i].style.backgroundColor = data.color;
    }

    ButtonRef.current.style.opacity = 1;
  }

  function mouseLeave() {
    TopLevelRef.current.style.backgroundColor = `#DFDFDF`;
    // CoachRef.current.style.backgroundColor = `#CBCBCB`;

    // for (var i = 0; i < CoachRef.current.length; i++) {
    //   CoachRef.current[i].style.backgroundColor = `#CBCBCB`;
    // }
    for (var i = 0; i < teacherIdList.length; i++) {
      if (i === 3) break;
      CoachRef.current[i].style.backgroundColor = `#CBCBCB`;
    }

    TimeRef.current.style.backgroundColor = `#CBCBCB`;
    ButtonRef.current.style.opacity = 0;
  }

  return (
    <TopLevelWrapper ref={TopLevelRef} onMouseOver={mouseOver} onMouseLeave={mouseLeave}>
      <TotalWrapper>
      <TextWrapper>
        <Text>{data.name}</Text>
        <ButtonWrapper ref={ButtonRef}>
          <Button color='#595959' onClick={() => modifyClickFn(index)}>수정</Button>
          <Button color='#FF3131' onClick={() => deleteClickFn(index)}>삭제</Button>
        </ButtonWrapper>
      </TextWrapper>
        <InfoWrapper>
          {
            teacherIdList.map((teacherData, idx) => (
              coachList.length !== 0 && idx < 3 && 
              <InfoBox key={idx} ref={element => (CoachRef.current[idx] = element)}>
                  <InfoText>
                    { idx === 0 || idx === 1 ? coachList[coachList.findIndex(i => i.id === parseInt(teacherData))].name + '코치' : "외 " + (teacherIdList.length - 2) + "명"}
                  </InfoText>
              </InfoBox>
            ))
          }
          {/* <InfoText>
            {
              coachList.length !== 0 ?
              coachList[coachList.findIndex(i => i.id === data.teacherId)].name
              : ""
            } 코치
          </InfoText> */}
          <InfoBox ref={TimeRef}>
            <InfoText>{data.time}분</InfoText>
          </InfoBox>
      </InfoWrapper>
      </TotalWrapper>
    </TopLevelWrapper>
  )
}

export default ClassInfoBox;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-end;

  width: 416px;
  height: 69px;

  // border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #DFDFDF;
  // background-color: red;

  margin: 8px 0 0 0;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 394px;
  height: 67px;

  margin: 1px 1px 1px 0;
  // margin-right: 1px;
  border-radius: 0 15px 15px 0;

  background-color: #FFFFFF;
`;


const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 95%;

  margin-top: 10px;
`;

const Text = styled.div`
  display: flex;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;

  margin: 0 0 0 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  gap: 8px;

  opacity: 0;

  transition: all 0.3s ease-in-out;
`;

const Button = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;
  
  gap: 4px;
  margin: 5px 0 10px 0;

  width: 95%;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  padding: 0px 8px 0px 8px;

  height: 24px;

  border-radius: 8px;
  background-color: #CBCBCB;

  transition: all 0.3s ease-in-out;
`;

const InfoText = styled.div`
  font-fmaily: NotoSansKR-Regular;
  font-size: 12px;

  color: #FFFFFF;

  margin: 0 0 0 0;
  // margin: 3px 8px 4px 8px;
`;