import { React, useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import styled from 'styled-components';

const DayApplyInput = forwardRef((props, ref) => {
  const [value, setValue] = useState(0);

	useImperativeHandle(ref, () => ({
		setValue: (val) => {
			setValue(val);
		},
    getValue: () => {
      return value;
    }
	}))

  useEffect(() => {
    if (value === '') setValue(0);
    
  }, [value])

  return (
    <TopLevelWrapper>
      <Title>적용 일수</Title>
      <Input>{value}</Input>
      <Text>일</Text>
    </TopLevelWrapper>
  )
})

export default DayApplyInput;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const Title = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;

  margin: 0 16px 0 0;
`;

const Input = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 61px;
  height: 30px;

  margin-right: 8px;
  padding: 0 0 0 8px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background-color: #ECECEC;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;
`;

const Text = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #777777;

  margin: 0 0 0 0;
`;



