import { React, forwardRef, useState, useRef, useImperativeHandle } from 'react';
import styled from 'styled-components';

import SelectOnPath from '../../image/SelectOn.png';
import SelectOffPath from '../../image/SelectOff.png';

const NumberOfMonthSelectBox = forwardRef((props, ref) => {
	const { selectClickFn } = props;

	const [isClicked, setIsClicked] = useState(false);
	const [defaultVal, setDefaultVal] = useState({ value: '일' });

	const SelectRef = useRef()

	const options = [
    {
      value: '일'
    },
    {
      value: '개월'
    },
    {
      value: '년'
    }
	];

	useImperativeHandle(ref, () => ({
		getValue: () => {
			return defaultVal.value;
		},
		setIsClicked: () => {
			SelectRef.current.style.border = '1px solid #CBCBCB';
			SelectRef.current.style.height = `30px`;
			SelectRef.current.style.overflow = 'hidden';
			setIsClicked(false);
		}
	}))

	function onClick() {
		// selectClickFn();
		if (isClicked) {
			SelectRef.current.style.border = '1px solid #CBCBCB';
			SelectRef.current.style.height = `30px`;
			SelectRef.current.style.overflow = 'hidden';
			setIsClicked(false);
		}
		else {
			SelectRef.current.style.border = '1px solid #FF8B48';
			SelectRef.current.style.height = `80px`;
			SelectRef.current.style.overflowY = 'scroll';
			SelectRef.current.style.overflowX = 'none';
			setIsClicked(true);
		}
	}

	function optionalClick(index) {
		setDefaultVal(options[index]);
	}

	return (
		<TopLevelWrapper>
			<SelectBox
				ref={SelectRef}
				isClicked={isClicked}
				buttonPath={isClicked ? SelectOnPath : SelectOffPath}
				onClick={onClick}>
				{
					isClicked ?
						options.map((data, index) =>
							<OptionWrapper key={index} onClick={() => optionalClick(index)}>
								<Option>{data.value}</Option>
							</OptionWrapper>
						)
						:
						<OptionWrapper>
							<Option>
								{
									defaultVal.value
								}
							</Option>
						</OptionWrapper>
				}
			</SelectBox>
		</TopLevelWrapper>
	)
})

export default NumberOfMonthSelectBox;

const TopLevelWrapper = styled.div`
	display: flex;
	flex-direction: column;
  margin-top: -30px;
	// justify-content: center;
`;

const SelectBox = styled.div`
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;

	width: 129px;
	height: 30px;

	border: 1px solid #CBCBCB;
	border-radius: 8px;
	overflow: hidden;

  background: url(${props => props.buttonPath}) no-repeat 96% ${props => props.isClicked ? `3` : `55`}%;
	background-color: rgba(255, 255, 255, 1);
  transition: all 0.3s ease-in-out;

	:hover {
		cursor: pointer;
	}

	&::-webkit-scrollbar {
		width: 9px;
	}

	&::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 20px #FF8B48;
		border: 3px solid transparent;
		height: 25px;
		// background-color: #FF8B48;
		border-radius: 200px;
	}

	&::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px transparent;
		border: 4px solid transparent;
		background-color: transparent;
	}

	&::-webkit-scrollbar-track-piece {
		background-color: transparent;
	}
`;

const OptionWrapper = styled.div`
	display: flex;
	flex-direction: column;

  justify-content: center;

	width: 100%;

	:hover {
		cursor: pointer;
	}
`;

const Option = styled.p`
	font-family: NotoSansKR-Regular;
	font-size: 14px;

	color: #CBCBCB;

	margin: 3px 0 3px 13px;
`;