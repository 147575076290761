import { React, useEffect } from 'react';
import styled from 'styled-components';

function DeleteConfirmModal(props) {
  const { title, itemName, clickNoFn, clickYesFn } = props;

  // Modal 창 스크롤 제어 //
  useEffect(() => {
    document.body.style = `overflow: hidden`;
    return () => document.body.style = `overflow: auto`;
  }, [])

	return (
    <Background>
      <ModalView>
        <InfoWrapper>
        <TitleWrapper>
          <Title>{title}</Title>
        </TitleWrapper>
        <ExplanationWrapper topMargin={16}>
          <Explanation color='#595959'>{itemName}을 삭제하시겠습니까?</Explanation>
          <Explanation color='#FF3131'>삭제시 되돌릴 수 없습니다.</Explanation>
          <Explanation color='#595959'>확인을 누르면 삭제를 진행합니다.</Explanation>
        </ExplanationWrapper>
        <ButtonWrapper topMargin={24}>
          <NoButton onClick={clickNoFn}>취소</NoButton>
          <YesButton onClick={clickYesFn}>확인</YesButton>
        </ButtonWrapper>
        </InfoWrapper>
      </ModalView>
    </Background>
  )
}
 
export default  DeleteConfirmModal;

const Background = styled.div`
  position: fixed;
	display: flex;
	flex-direction: column;

  justify-content: center;
  align-items: center;

  top: 0;
  left: 0;

  z-index: 500;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.5);
`;

const ModalView = styled.div`
	display: flex;
	flex-direction: column;

  justify-content: center;
  align-items: center;

	width: 372px;
	// height: 249px;
  height: 230px;
  
	// border-radius: 30px;
  border-radius: 15px;
  background-color: #FFFFFF;

  transition: all 0.5s ease-in-out;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 84.6774%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: 0 0 0 0;
`;

const ExplanationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 8px;

  margin-top: ${props => props.topMargin}px;
`;

const Explanation = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: ${props => props.color};

  margin: 0 0 0 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 16px;

  margin-top: ${props => props.topMargin}px;
  margin-left: 125px;
`;

const YesButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 15px;
  background-color: #5BD98A;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }
`;

const NoButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 72px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #CBCBCB;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #232323;
    color: #232323;
  }
`;