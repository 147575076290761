import { React, useRef } from 'react';
import styled from 'styled-components';

export default function PrintInput(props) {
  const { value, setValue } = props;

  const InputRef = useRef();

  function handleChange(e) {
    const curValue = e.target.value
		const newValue = curValue.replace(/[^0-9]/g, '')

		setValue(newValue);
  }

  function handleFocus() {
    InputRef.current.style.border = `1px solid #FF8B48`;
  }

  function handleBlur() {
    InputRef.current.style.border = `1px solid #DFDFDF`;
  }

  return (
    <TopLevelWrapper>
      <LayerWrapper>
        <Input 
          ref={InputRef}
          placeholder='0'
          value={value || ''}
          // disabled={disabled}
          onFocus={handleFocus}
          onChange={handleChange}
          onBlur={handleBlur}/>
      </LayerWrapper>
      <LayerWrapper>
        <Text>원</Text>
      </LayerWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  gap: 8px;
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const Input = styled.input`
  // width: 61px;
  width: 180px;
  height: 30px;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  // margin: 0 8px 0 0;
  padding: 0 0 0 8px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  outline: none;

  color: #CBCBCB;
  background-color: ${props => props.disabled ? '#ECECEC' : '#FFFFFF'};

  transition: all 0.3s ease-in-out;

  ::placeholder {
    color: #CBCBCB;
  }
`;

const Text = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #777777;

  margin: 0 8px 0 0;
`;
