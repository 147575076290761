import { React } from 'react';
import styled from 'styled-components';
import CenterIntroTextArea from '../../../Component/MySettingPageComponent/CenterIntroTextArea';

function MyGymIntroductionText(props) {
  const { isClicked, storeIntro, setStoreIntro } = props;

  return (
    <TopLevelWrapper>
      <InfoView>
        <TitleWrapper>
          <Title>센터 소개</Title>
        </TitleWrapper>
        <InfoWrapper>
          <TextWrapper>
            {
              isClicked ?
                <CenterIntroTextArea placeholder='센터 소개글을 입력해주세요' defaultValue={storeIntro} setVal={setStoreIntro}/>
                :
                <Text>{storeIntro}</Text>
            }
          </TextWrapper>
        </InfoWrapper>
      </InfoView>
    </TopLevelWrapper>
  )
}

export default MyGymIntroductionText;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 34px;

  width: 100%;
`;

const InfoView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 1191px;

  border-radius: 30px;
  background-color: #FFFFFF;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: 1135px;

  margin-top: 28px;
`;

const Title = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 24px;
  border-top: 1px solid #ECECEC;

  width: 1135px;
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 100%;

  margin-top: 24px;
  gap: 20px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  flex-wrap: wrap;

  margin-top: 24px;
  margin-bottom: 28px;
`;

const Text = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;