import { React, useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';

// Component Import //
import TotalTitleInputBox from '../../../Component/NoteComponent/TotalTitleInputBox';
import LessonSelectBox from '../../../Component/NoteComponent/LessonSelectBox';
import ModifySectionBox from './ModifySectionBox';
import NormalSectionBox from './NormalSectionBox';
import WodLoadingModal from './WodLoadingModal';
import useScrollToWithAnimation from '../../Share/Normal/useScrollToWithAnimation';
// Component Import //

// Image Import //
import PlusImage from '../../../image/PlusButton.png';
import TrashImage from '../../../image/TrashIcon.png';
import AdditionExerciseButtonImage from '../../../image/AdditionExerciseButton.png';
// Image Import //

const ExerciseBox = forwardRef((props, ref) => {
  const { 
    isClickedModify, 
    clickDate,
    clickWorkoutIndex, 
    useData, 
    selectedLesson, 
    setSelectedLesson, 
    onClickModifyButton, 
  } = props;

  const { scrollToWithAnimation } = useScrollToWithAnimation();

  const [workoutId, setWorkoutId] = useState(0);
  const [title, setTitle] = useState('');
  const [sectionList, setSectionList] = useState([]);
  const [scroll, setScroll] = useState(0);
  const [nowSection, setNowSection] = useState(0);
  const [lastBottomMargin, setLastBottomMargin] = useState(0);
  const [isClickedWodLoading, setIsClickedWodLoading] = useState(false);
  const [originalLessonId, setOriginalLessonId] = useState(0);

  const ScrollRef = useRef(null);
  const SectionRef = useRef([]);

  const EmptySection = {
    title: '',
    isUseTimeLimit: false,
    timeLimit: 0,
    isUseBuyin: false,
    buyinExerciseList: [],
    // tag: 'ForTime-Rounds',
    tag: 'Custom',
    round: 1,
    reps: [0],
    exerciseTime: 0,
    restTime: 0,
    mainExerciseList: [],
    emomExerciseList: [],
    isUseBuyout: false,
    buyoutExerciseList: [],
    customTitle: '',
    scoreKeyword: '점수 없음',
    scorePriority: '높을 수록',
    description: '',
    buyinNote: '',
    mainNote: '',
    buyoutNote: ''
  }

  useImperativeHandle(ref, () => ({
    getValue: () => {
      for (let i = 0; i < sectionList.length; i++) {
        // '점수 없음', '시간', '횟수', '라운드 + 횟수', '거리', '무게', '포인트', '피크와트', '최고 속도' //
        if (sectionList[i].scoreKeyword === '점수 없음') sectionList[i].scoreKeyword = 'Not Scored';
        else if (sectionList[i].scoreKeyword === '시간') sectionList[i].scoreKeyword = 'Time';
        else if (sectionList[i].scoreKeyword === '렙수') sectionList[i].scoreKeyword = 'Reps';
        else if (sectionList[i].scoreKeyword === '라운드 + 렙수') sectionList[i].scoreKeyword = 'Rounds And Reps';
        else if (sectionList[i].scoreKeyword === '거리') sectionList[i].scoreKeyword = 'Distance';
        else if (sectionList[i].scoreKeyword === '무게') sectionList[i].scoreKeyword = 'Weight';
        else if (sectionList[i].scoreKeyword === '포인트') sectionList[i].scoreKeyword = 'Points';
        else if (sectionList[i].scoreKeyword === '피크와트') sectionList[i].scoreKeyword = 'Peak Watts';
        else if (sectionList[i].scoreKeyword === '최고 속도') sectionList[i].scoreKeyword = 'Max Speed';

        if (sectionList[i].scorePriority === '높을 수록') sectionList[i].scorePriority = 'heigher';
        else if (sectionList[i].scorePriority === '낮을 수록') sectionList[i].scorePriority = 'lower';

        // Exercise Weight에 ('/')가 포함되어있는지 확인 //
        // Buyin 확인 //
        for (let j = 0; j < sectionList[i].buyinExerciseList.length; j++) {
          // Buyin 운동중 무게가 구분데이터를 사용하는지 확인 //
          if (typeof (sectionList[i].buyinExerciseList[j].weight) === 'string' && sectionList[i].buyinExerciseList[j].weight.includes('/')) {
            sectionList[i].buyinExerciseList[j].divisionWeight = sectionList[i].buyinExerciseList[j].weight;
            sectionList[i].buyinExerciseList[j].weight = 0;
          }
          // Buyin 운동중 거리가 구분데이터를 사용하는지 확인 //
          if (typeof(sectionList[i].buyinExerciseList[j].distance) === 'string' && sectionList[i].buyinExerciseList[j].distance.includes('/')) {
            sectionList[i].buyinExerciseList[j].divisionDistance = sectionList[i].buyinExerciseList[j].distance;
            sectionList[i].buyinExerciseList[j].distance = parseFloat(sectionList[i].buyinExerciseList[j].distance.split('/')[0]);
          }
          // Buyin 운동중 높이가 구분데이터를 사용하는지 확인 //
          if (typeof(sectionList[i].buyinExerciseList[j].height) === 'string' && sectionList[i].buyinExerciseList[j].height.includes('/')) {
            sectionList[i].buyinExerciseList[j].divisionHeight = sectionList[i].buyinExerciseList[j].height;
            sectionList[i].buyinExerciseList[j].height = parseFloat(sectionList[i].buyinExerciseList[j].height.split('/')[0]);
          }
          // Buyin 운동중 칼로리가 구분데이터를 사용하는지 확인 //
          if (typeof(sectionList[i].buyinExerciseList[j].calories) === 'string' && sectionList[i].buyinExerciseList[j].calories.includes('/')) {
            sectionList[i].buyinExerciseList[j].divisionCalories = sectionList[i].buyinExerciseList[j].calories;
            sectionList[i].buyinExerciseList[j].calories = parseFloat(sectionList[i].buyinExerciseList[j].calories.split('/')[0]);
          }
          // Buyin 운동중 템포가 구분데이터를 사용하는지 확인 //
          if (typeof(sectionList[i].buyinExerciseList[j].tempo) === 'string' && sectionList[i].buyinExerciseList[j].tempo.includes('/')) {
            sectionList[i].buyinExerciseList[j].divisionTempo = sectionList[i].buyinExerciseList[j].tempo;
            sectionList[i].buyinExerciseList[j].tempo = parseInt(sectionList[i].buyinExerciseList[j].tempo.split('/')[0]);
          }
        }

        // EMOM인 경우 데이터 따로 구성을 위해 검사 //
        if (sectionList[i].tag === "EMOM") {
          // EMOM 확인 //
          for (let j = 0; j < sectionList[i].emomExerciseList.length; j++) {
            // 메인 운동중 무게가 구분데이터를 사용하는지 확인 //
            if (typeof (sectionList[i].emomExerciseList[j].weight) === 'string' && sectionList[i].emomExerciseList[j].weight.includes('/')) {
              sectionList[i].emomExerciseList[j].divisionWeight = sectionList[i].emomExerciseList[j].weight;
              sectionList[i].emomExerciseList[j].weight = 0;
            }
            // 메인 운동중 거리가 구분데이터를 사용하는지 확인 //
            if (typeof(sectionList[i].emomExerciseList[j].distance) === 'string' && sectionList[i].emomExerciseList[j].distance.includes('/')) {
              sectionList[i].emomExerciseList[j].divisionDistance = sectionList[i].emomExerciseList[j].distance;
              sectionList[i].emomExerciseList[j].distance = parseFloat(sectionList[i].emomExerciseList[j].distance.split('/')[0]);
            }
            // 메인 운동중 높이가 구분데이터를 사용하는지 확인 //
            if (typeof(sectionList[i].emomExerciseList[j].height) === 'string' && sectionList[i].emomExerciseList[j].height.includes('/')) {
              sectionList[i].emomExerciseList[j].divisionHeight = sectionList[i].emomExerciseList[j].height;
              sectionList[i].emomExerciseList[j].height = parseFloat(sectionList[i].emomExerciseList[j].height.split('/')[0]);
            }
            // 메인 운동중 칼로리가 구분데이터를 사용하는지 확인 //
            if (typeof(sectionList[i].emomExerciseList[j].calories) === 'string' && sectionList[i].emomExerciseList[j].calories.includes('/')) {
              sectionList[i].emomExerciseList[j].divisionCalories = sectionList[i].emomExerciseList[j].calories;
              sectionList[i].emomExerciseList[j].calories = parseFloat(sectionList[i].emomExerciseList[j].calories.split('/')[0]);
            }
            // 메인 운동중 템포가 구분데이터를 사용하는지 확인 //
            if (typeof(sectionList[i].emomExerciseList[j].tempo) === 'string' && sectionList[i].emomExerciseList[j].tempo.includes('/')) {
              sectionList[i].emomExerciseList[j].divisionTempo = sectionList[i].emomExerciseList[j].tempo;
              sectionList[i].emomExerciseList[j].tempo = parseInt(sectionList[i].emomExerciseList[j].tempo.split('/')[0]);
            }
            // 메인 운동중 Single일 경우만 해당 Sets가 구분데이터를 사용하는지 확인 //
            if (typeof(sectionList[i].emomExerciseList[j].sets) === 'string' && sectionList[i].emomExerciseList[j].sets.includes('/')) {
              sectionList[i].emomExerciseList[j].divisionSets = sectionList[i].emomExerciseList[j].sets;
              sectionList[i].emomExerciseList[j].sets = parseInt(sectionList[i].emomExerciseList[j].sets.split('/')[0]);
            }
          }
        }
        // 그 외의 경우 //
        else {
          // Main 확인 //
          for (let j = 0; j < sectionList[i].mainExerciseList.length; j++) {
            // 메인 운동중 무게가 구분데이터를 사용하는지 확인 //
            if (typeof (sectionList[i].mainExerciseList[j].weight) === 'string' && sectionList[i].mainExerciseList[j].weight.includes('/')) {
              sectionList[i].mainExerciseList[j].divisionWeight = sectionList[i].mainExerciseList[j].weight;
              sectionList[i].mainExerciseList[j].weight = 0;
            }
            // 메인 운동중 거리가 구분데이터를 사용하는지 확인 //
            if (typeof (sectionList[i].mainExerciseList[j].distance) === 'string' && sectionList[i].mainExerciseList[j].distance.includes('/')) {
              sectionList[i].mainExerciseList[j].divisionDistance = sectionList[i].mainExerciseList[j].distance;
              sectionList[i].mainExerciseList[j].distance = parseFloat(sectionList[i].mainExerciseList[j].distance.split('/')[0]);
            }
            // 메인 운동중 높이가 구분데이터를 사용하는지 확인 //
            if (typeof (sectionList[i].mainExerciseList[j].height) === 'string' && sectionList[i].mainExerciseList[j].height.includes('/')) {
              sectionList[i].mainExerciseList[j].divisionHeight = sectionList[i].mainExerciseList[j].height;
              sectionList[i].mainExerciseList[j].height = parseFloat(sectionList[i].mainExerciseList[j].height.split('/')[0]);
            }
            // 메인 운동중 칼로리가 구분데이터를 사용하는지 확인 //
            if (typeof (sectionList[i].mainExerciseList[j].calories) === 'string' && sectionList[i].mainExerciseList[j].calories.includes('/')) {
              sectionList[i].mainExerciseList[j].divisionCalories = sectionList[i].mainExerciseList[j].calories;
              sectionList[i].mainExerciseList[j].calories = parseFloat(sectionList[i].mainExerciseList[j].calories.split('/')[0]);
            }
            // 메인 운동중 템포가 구분데이터를 사용하는지 확인 //
            if (typeof (sectionList[i].mainExerciseList[j].tempo) === 'string' && sectionList[i].mainExerciseList[j].tempo.includes('/')) {
              sectionList[i].mainExerciseList[j].divisionTempo = sectionList[i].mainExerciseList[j].tempo;
              sectionList[i].mainExerciseList[j].tempo = parseInt(sectionList[i].mainExerciseList[j].tempo.split('/')[0]);
            }
            // 메인 운동중 Single일 경우만 해당 Sets가 구분데이터를 사용하는지 확인 //
            if (typeof (sectionList[i].mainExerciseList[j].sets) === 'string' && sectionList[i].mainExerciseList[j].sets.includes('/')) {
              sectionList[i].mainExerciseList[j].divisionSets = sectionList[i].mainExerciseList[j].sets;
              sectionList[i].mainExerciseList[j].sets = parseInt(sectionList[i].mainExerciseList[j].sets.split('/')[0]);
            }
          }
        }

        // Buyout 확인 //
        for (let j = 0; j < sectionList[i].buyoutExerciseList.length; j++) {
          // Buyout 운동중 무게가 구분데이터를 사용하는지 확인 //
          if (typeof (sectionList[i].buyoutExerciseList[j].weight) === 'string' && sectionList[i].buyoutExerciseList[j].weight.includes('/')) {
            sectionList[i].buyoutExerciseList[j].divisionWeight = sectionList[i].buyoutExerciseList[j].weight;
            sectionList[i].buyoutExerciseList[j].weight = 0;
          }
          // Buyout 운동중 거리가 구분데이터를 사용하는지 확인 //
          if (typeof(sectionList[i].buyoutExerciseList[j].distance) === 'string' && sectionList[i].buyoutExerciseList[j].distance.includes('/')) {
            sectionList[i].buyoutExerciseList[j].divisionDistance = sectionList[i].buyoutExerciseList[j].distance;
            sectionList[i].buyoutExerciseList[j].distance = parseFloat(sectionList[i].buyoutExerciseList[j].distance.split('/')[0]);
          }
          // Buyout 운동중 높이가 구분데이터를 사용하는지 확인 //
          if (typeof(sectionList[i].buyoutExerciseList[j].height) === 'string' && sectionList[i].buyoutExerciseList[j].height.includes('/')) {
            sectionList[i].buyoutExerciseList[j].divisionHeight = sectionList[i].buyoutExerciseList[j].height;
            sectionList[i].buyoutExerciseList[j].height = parseFloat(sectionList[i].buyoutExerciseList[j].height.split('/')[0]);
          }
          // Buyout 운동중 칼로리가 구분데이터를 사용하는지 확인 //
          if (typeof(sectionList[i].buyoutExerciseList[j].calories) === 'string' && sectionList[i].buyoutExerciseList[j].calories.includes('/')) {
            sectionList[i].buyoutExerciseList[j].divisionCalories = sectionList[i].buyoutExerciseList[j].calories;
            sectionList[i].buyoutExerciseList[j].calories = parseFloat(sectionList[i].buyoutExerciseList[j].calories.split('/')[0]);
          }
          // Buyout 운동중 템포가 구분데이터를 사용하는지 확인 //
          if (typeof(sectionList[i].buyoutExerciseList[j].tempo) === 'string' && sectionList[i].buyoutExerciseList[j].tempo.includes('/')) {
            sectionList[i].buyoutExerciseList[j].divisionTempo = sectionList[i].buyoutExerciseList[j].tempo;
            sectionList[i].buyoutExerciseList[j].tempo = parseInt(sectionList[i].buyoutExerciseList[j].tempo.split('/')[0]);
          }
        }

        if (sectionList[i].tag === 'Custom') {
          if (sectionList[i].title === '') sectionList[i].title = 'Custom';
        }
      }
      
      return sectionList;
    },
    getTotalTitle: () => {
      return title;
    },
    getWorkoutId: () => {
      return workoutId;
    }
  }));

  // 날짜에 맞게 데이터 세팅 //
  useEffect(() => {
    let EmptySection = {
      title: '',
      isUseTimeLimit: false,
      timeLimit: 0,
      isUseBuyin: false,
      buyinExerciseList: [],
      // tag: 'ForTime-Rounds',
      tag: 'Custom',
      round: 1,
      reps: [0],
      exerciseTime: 0,
      restTime: 0,
      mainExerciseList: [],
      emomExerciseList: [],
      isUseBuyout: false,
      buyoutExerciseList: [],
      customTitle: '',
      scoreKeyword: '점수 없음',
      scorePriority: '높을 수록',
      description: '',
      buyinNote: '',
      mainNote: '',
      buyoutNote: ''
    }

    for (let i = 0; i < useData.length; i++) {
      if (useData[i].classDate.clone().format('YYYY.MM.DD') === clickDate) {

        if (useData[i].workouts[clickWorkoutIndex] === undefined) {
          setTitle('');
          setWorkoutId(0);
          setOriginalLessonId(0);
        }
        else {
          setTitle(useData[i].workouts[clickWorkoutIndex].title);
          setWorkoutId(useData[i].workouts[clickWorkoutIndex].workoutId);
          setOriginalLessonId(useData[i].workouts[clickWorkoutIndex].lessonId);

          // 저장된 데이터가 없을경우 //
          if (useData[i].workouts[clickWorkoutIndex].sectionList.length === 0) {
            useData[i].workouts[clickWorkoutIndex].sectionList.push(EmptySection);
            setSectionList(useData[i].workouts[clickWorkoutIndex].sectionList);
          }
          else setSectionList(useData[i].workouts[clickWorkoutIndex].sectionList);
        }
      }
    }
  }, [useData, clickDate, clickWorkoutIndex])

  // 스크롤 제어를 위한 컴포넌트 크기 조정 //
  useEffect(() => {
    if (sectionList.length === 0) return;

    let newIndex = 0;
    
    for (let i = 0; i < sectionList.length; i++) {
      if (SectionRef.current[i]?.offsetTop <= (scroll + 348 + 24 + 64)) {
        newIndex = i;
      }
    }
    setNowSection(newIndex);

    let height = SectionRef.current[sectionList.length - 1]?.clientHeight === undefined ? 0 : SectionRef.current[sectionList.length - 1]?.clientHeight;

    if (height >= 894) setLastBottomMargin(0);
    else setLastBottomMargin(894 - height);

  }, [SectionRef, sectionList, scroll])


  // 스크롤 움직임 이벤트 확인 // 
  function onScrollExerciseView(e) {
    setScroll(e.target.scrollTop);
  }
  
  // 섹션 이동 버튼 클릭시 //
  async function onClickSectionButton(index) {
    if (index === 0) scrollToWithAnimation(ScrollRef, -100, 500);
    else {
      let scrollRefY = ScrollRef.current.getBoundingClientRect().top;
      let sectionY = SectionRef.current[index]?.getBoundingClientRect().top;

      scrollToWithAnimation(ScrollRef, (sectionY - scrollRefY + scroll), 500);
    }
  }

  // 섹션 추가 버튼 클릭시 //
  function onClickSectionPlus() {
    let newSectionList = sectionList.slice();

    newSectionList.push(EmptySection);

    setSectionList(newSectionList);

    setTimeout(() => {
      onClickSectionButton(newSectionList.length - 1);
    }, 10)
  }

  // 섹션 삭제 버튼 클릭시 //
  function onClickSectionDelete() {
    let newSectionList = sectionList.slice();

    newSectionList.splice(nowSection, 1);

    setSectionList(newSectionList);
  }

  // 섹션 데이터 최신화 메서드 //
  function refreshSectionList() {
    let newSectionList = sectionList.slice();

    setSectionList(newSectionList);
  }

  // 프로그램 가져오기 완료 버튼 클릭시 //
  function onClickCompleteWodLoading(data) {
    setTitle(data.title);
    setWorkoutId(data.workoutId);
    setSectionList(data.sectionList);
    setIsClickedWodLoading(false);
  }

  return (
    <Container>
      {isClickedWodLoading && <WodLoadingModal standardDate={clickDate} completeFn={onClickCompleteWodLoading} closeFn={() => setIsClickedWodLoading(false)} />}
      {
        isClickedModify ?
          <TopLevelWrapper>
            <TotalWrapper>
              <TopDateWrapper>
                <Text style={{ marginTop: '28px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#232323'>{clickDate}</Text>
                <HistoryBox onClick={() => setIsClickedWodLoading(true)}>
                  <HoverText fontFamily='Poppins-SemiBold' fontSize={14} color='#FFFFFF'>HISTORY</HoverText>
                </HistoryBox>
              </TopDateWrapper>
              <SelectBoxWrapper>
                <LessonSelectBox defaultVal={selectedLesson} setDefaultVal={setSelectedLesson} originalLessonId={originalLessonId}/>
              </SelectBoxWrapper>
              <TitleWrapper topMargin={24}>
                <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>제목</Text>
              </TitleWrapper>
              <TotalTitleInputBox value={title} setValue={setTitle} />
              <SectionWrapper>
                {
                  sectionList.map((data, index) => (
                    <SectionBox key={index} nowSection={nowSection === index} onClick={() => onClickSectionButton(index)}>{String.fromCharCode(index + 65)}</SectionBox>
                  ))
                }
                {
                  sectionList.length < 26 &&
                  <SectionFunctionBox backgroundColor='#FF8B48' hoverBackgroundColor='#FF7322' onClick={onClickSectionPlus}>
                    <Icon src={PlusImage} />
                  </SectionFunctionBox>
                }
                {
                  sectionList.length > 0 &&
                  <SectionFunctionBox backgroundColor='#FF3131' hoverBackgroundColor='#DD0000' onClick={onClickSectionDelete}>
                    <Icon src={TrashImage} />
                  </SectionFunctionBox>
                }
              </SectionWrapper>
              <ScrollWrapper 
                ref={ScrollRef}
                onScroll={onScrollExerciseView} 
                isModify={isClickedModify}>
                {
                  sectionList.map((data, index) => (
                    <ModifySectionBoxWrapper
                      ref={element => (SectionRef.current[index] = element)}
                      key={index}>
                      <SectionDivisionBoxWrapper>
                        <SectionDivisionBox>
                          <Text fontFamily='Poppins-Bold' fontSize={14} color='#FFFFFF'>{String.fromCharCode(index + 65)}</Text>
                          <Text fontFamily='Poppins-Bold' fontSize={12} color='#FFFFFF'>Session</Text>
                        </SectionDivisionBox>
                      </SectionDivisionBoxWrapper>
                      <ModifySectionBox
                        scroll={scroll}
                        sectionData={data}
                        refreshSectionList={refreshSectionList} />
                    </ModifySectionBoxWrapper>
                  ))
                }
                <LastBottomMarginComponent height={lastBottomMargin} />
              </ScrollWrapper>
            </TotalWrapper>
            <BottomSaveButtonWrapper>
              <BottomSaveButton onClick={onClickModifyButton}>
                <HoverText fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF'>저장하기</HoverText>
              </BottomSaveButton>
            </BottomSaveButtonWrapper>
          </TopLevelWrapper>
          :
          (title === '' || title === '작성이 필요합니다.') ?
            <EmptyBox>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#232323'>{clickDate}</Text>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#232323'>새로운 운동 디자인 하기</Text>
              <AdditionExerciseButtonWrapper onClick={onClickModifyButton}>
                <AdditionExerciseButton src={AdditionExerciseButtonImage} />
              </AdditionExerciseButtonWrapper>
            </EmptyBox>
            :
            <TopLevelWrapper style={{ paddingTop: `28px` }}>
              <TotalWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#232323'>{clickDate}</Text>
                <ScrollWrapper isModify={isClickedModify}>
                  <TitleWrapper topMargin={0}>
                    <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959'>제목</Text>
                  </TitleWrapper>
                  <TotalTitleBox>{title}</TotalTitleBox>
                  {
                    sectionList.map((data, index) => (
                      <NormalSectionBox
                        key={index}
                        index={index}
                        sectionData={data} />
                    ))
                  }
                </ScrollWrapper>
              </TotalWrapper>
            </TopLevelWrapper>
      }
    </Container>
  )
})

export default ExerciseBox;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;
`;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 582px;
  // height: 813px;
  min-height: 707px;
  // max-height: 1083px;
  max-height: 1190px;
  
  // padding-top: 28px;
  // padding-bottom: 28px;
  padding-bottom: 58px;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  background-color: #FFFFFF;
`;

const TopDateWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  width: 100%;
`;

const HistoryBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-right: -28px;

  width: 100px;
  height: 38px;

  border-radius: 0px 19px 0px 30px;
  background-color: #E0E0E0;

  :hover {
    cursor: pointer;
    background-color: #FF8B48;
  }

  transition: all 0.3s ease-in-out;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 90.33%;
`;

const EmptyBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 582px;
  height: 298px;

  gap: 4px;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  background-color: #FFFFFF;
`;

const AdditionExerciseButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 12px;

  :hover {
    cursor: pointer;
  }
`;

const AdditionExerciseButton = styled.img`
  width: 100px;
  height: 100px;
`;

const ClassSettingButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 13px;

  width: 155px;
  height: 45px;

  border-radius: 23px;
  background-color: #6DD49E;

  :hover {
    cursor: pointer;
  }
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const SelectBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 24px;

  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 97.71%;

  margin-top: ${props => props.topMargin}px;
`;

const GetProgramButton = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 12px;

  color: #FF8B48;

  margin: 0 0 0 0;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    color: #FF7322;
  }
`;

const TotalTitleBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: calc(97.71% - 34px);
  min-height: 34px;

  margin-top: 8px;
  padding-right: 16px;
  padding-left: 16px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  flex-wrap: wrap;

  width: 100%;

  margin-top: 24px;
  // margin-bottom: 24px;

  gap: 8px;
`;

const SectionBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 32px;
  height: 32px;

  border-radius: 6px;
  background-color: ${props => props.nowSection ? `#6DD49E` : `#E0E0E0`};

  font-family: Poppins-Bold;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: ${props => props.nowSection ? `#6DD49E` : `#D7D7D7`};
  }
`;

const LockBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 32px;
  height: 32px;

  border-radius: 6px;
  background-color: ${props => props.isClicked ? `#FF3131` : `#E0E0E0`};

  font-family: Poppins-Bold;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    // background-color: #FF8B48;
  }
`;

const SectionFunctionBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 32px;
  height: 32px;

  border-radius: 6px;
  background-color: ${props => props.backgroundColor};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: ${props => props.hoverBackgroundColor};
  }
`;

const LockIcon = styled.img`
  width: 13px;
  height: 19px;
  
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;
`;

const ScrollWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  margin-top: 24px;

  width: 100%;
  max-height: ${props => props.isModify ? `894px` : `1039px`};
  
  overflow-x: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
		border: 3px solid transparent;
		// height: 92px;
		background-color: #DADADA;
		border-radius: 200px;
	}

  &::-webkit-scrollbar-track {
    // height: 439px;
		// border: 4px solid transparent;
		background-color: #F0F0F0;
		border-radius: 200px;
	}
`;

const ModifySectionBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  margin-bottom: ${props => props.lastBottomMargin}px;
`;

const SectionDivisionBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  margin-bottom: 24px;
`;

const SectionDivisionBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 90px;
  height: 32px;

  gap: 19px;
  padding: 0 11px 0 11px;

  border-radius: 6px;
  background-color: #6DD49E;
`;

const LastBottomMarginComponent = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  min-height: ${props => props.height}px;

  background-color: #FFFFFF;
`;

const BottomSaveButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-end;

  margin-top: 15px;

  width: 90.33%;
`;

const BottomSaveButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 15px;
  background-color: #5BD98A;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  margin: 0 0 0 0;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }
`;

const HoverText = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;