import { React, useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import CategorySelectBoxLegacy from '../../../Component/SettingComponent/ASPCategorySelectBox';
import CategorySelectBox from '../../../Component/SettingComponent/CategorySelectBox';
import MemberShipAddModal from './Modal/MemberShipAddModal';
import ClassAddModal from './Modal/ClassAddModal';
import MemberShipInfoBox from '../../../Component/SettingComponent/MemberShipInfoBox';
import ClassInfoBox from '../../../Component/SettingComponent/ClassInfoBox';

import FetchModule from '../../Share/Network/FetchModule';
import DeleteConfirmModal from './Modal/DeleteConfirmModal';
import CustomAlert from '../../Share/Normal/CustomAlert';

const MemberShipAndClassView = forwardRef((props, ref) => {
  const history = useHistory();

  const [isMemberShipAddClick, setIsMemberShipAddClick] = useState(false);
  const [isClassAddClick, setIsClassAddClick] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [isViewCustomAlert, setIsViewCustomAlert] = useState(false);

  const [rendering, setRendering] = useState(false);
  const [categoryRendering, setCategoryRendering] = useState(false);
  const [memberShipData, setMemberShipData] = useState([]);
  const [classData, setClassData] = useState([]);
  const [memberShipSplitData, setMemberShipSplitData] = useState([]);
  const [classSplitData, setClassSplitData] = useState([]);
  const [memberShipClickIndex, setMemberShipClickIndex] = useState(-1);
  const [classClickIndex, setClassClickIndex] = useState(-1);

  const [toBeDeletedDivision, setToBeDeletedDivision] = useState('None');
  const [toBeDeletedData, setToBeDeletedDate] = useState(-1);

  const [coachList, setCoachList] = useState([]);
  const [options, setOptions] = useState([]);

  const [alertText, setAlertText] = useState('');

  const TopLevelRef = useRef();
  const MemberShipCategoryRef = useRef();
  const ClassCategoryRef = useRef();
  const MemberShipAddModalRef = useRef();
  const ClassAddModalRef = useRef();

  useImperativeHandle(ref, () => ({
		setOpacity: (val) => {
			TopLevelRef.current.style.opacity = val;
		},
    setZindex: (val) => {
      TopLevelRef.current.style.zIndex = val;
    }
	}))

  // 페이지 렌더링시 API호출
  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getData('membership/', 'GET');
        
        if (responseData.status === 200) {
          setMemberShipData(responseData.data);
          setMemberShipSplitData(responseData.data);
        }
        else if (responseData.status === 401) history.push('/');
      }
    )();

    (
      async () => {
        const responseData = await fetchModule.getData('lesson/', 'GET');
        
        if (responseData.status === 200) {
          setClassData(responseData.data);
          setClassSplitData(responseData.data);
        }
        else if (responseData.status === 401) history.push('/');


        const data = await fetchModule.getData('coach/', 'GET');
        
        if (data.status === 200) setCoachList(data.data);
        else if (data.status === 401) history.push('/');
      }
    )();

    ClassCategoryRef.current?.setValue({ id: undefined, name: '전체' });
    MemberShipCategoryRef.current?.setValue({ id: undefined, name: '전체' });
  }, [history, rendering])

  // 카테고리 정보 받아오기
  useEffect(() => {
    // setOptions([]);
    (
      async () => {
        const fetchModule = new FetchModule();
        const data = await fetchModule.getData('membership/category/', 'GET');
        
        if (data.status === 200) {
					var newData = data.data;
	
					newData.unshift({ id: undefined, name: '전체' });
					setOptions(newData);

          ClassCategoryRef.current?.setValue({ id: undefined, name: '전체' });
          MemberShipCategoryRef.current?.setValue({ id: undefined, name: '전체' });
				}
        else if (data.status === 204) {
          setOptions([{ id: undefined, name: '전체'}]);

          ClassCategoryRef.current?.setValue({ id: undefined, name: '전체' });
          MemberShipCategoryRef.current?.setValue({ id: undefined, name: '전체' });
        }
        else if (data.status === 401) history.push('/');
      }
    )();
  }, [history, categoryRendering])

  // Modal이 꺼지면 전달 Index를 재 정리
  useEffect(() => {
    if (!isMemberShipAddClick) setMemberShipClickIndex(-1);
  }, [isMemberShipAddClick])

  // Modal이 꺼지면 전달 Index를 재 정리
  useEffect(() => {
    if (!isClassAddClick) setClassClickIndex(-1);
  }, [isClassAddClick])

  // 카테고리 선택시 회원권 정보를 카테고리에 맞게 정리
  function selectMemberShipValueChange(value) {
    
    if (value.name === '전체') {
      setMemberShipSplitData(memberShipData);

      return;
    }
    
    var tmpData = memberShipData.slice();
    var categoryId = value.id;
    var newDataList = [];

    for (var i = 0; i < tmpData.length; i++) {
      if (tmpData[i].categoryId === categoryId) newDataList.push(tmpData[i]);
    }

    setMemberShipSplitData(newDataList);
  }

  // 카테고리 선택시 수업 정보를 카테고리에 맞게 정리
  function selectClassValueChange(value) {

    if (value.name === '전체') {
      setClassSplitData(classData);

      return;
    }

    var tmpData = classData.slice();
    var categoryId = value.id;
    var newDataList = [];

    for (var i = 0; i < tmpData.length; i++) {
      if (tmpData[i].categoryId === categoryId) {
        newDataList.push(tmpData[i]);
      }
    }

    setClassSplitData(newDataList);
  }

  // 회원권 수정 클릭시 Index를 전달
  function memberShipModifyClick(index) {
    setMemberShipClickIndex(index);
    setIsMemberShipAddClick(true);
  }

  // 회원권 삭제 클릭시 API호출
  function memberShipDeleteClick(index) {
    setToBeDeletedDivision('MemberShip');
    setToBeDeletedDate(memberShipSplitData[index]);
    setIsConfirm(true);
  }

  // 회원권 수정 클릭시 Index를 전달
  function classModifyClick(index) {
    setClassClickIndex(index);
    setIsClassAddClick(true);
  }

  // 수업 삭제 클릭시 API호출
  function classDeleteClick(index) {
    setToBeDeletedDivision('Lesson');
    setToBeDeletedDate(classSplitData[index]);
    setIsConfirm(true);
  }

  // 삭제 확인 버튼 클릭시
  function deleteYesClick() {
    if (toBeDeletedDivision === 'Lesson') {
      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.deleteData('lesson/delete/' + toBeDeletedData.id, 'DELETE');
          // console.log(responseData);
          if (responseData.status === 200) setRendering(!rendering);
          else if (responseData.status === 203) {
            setAlertText('아직 수업 일정이 존재합니다.\n해당 수업과 관련된 스케줄을 삭제 후 다시 시도해주세요.');
            setIsViewCustomAlert(true);
          }
          else if (responseData.status === 204) {
            setAlertText('회원권 정보를 삭제하는데 실패하였습니다.\n잠시후 다시시도 해주세요');
            setIsViewCustomAlert(true);
          }
          else if (responseData.status === 401) history.push('/');
        }
      )();
    }
    else if (toBeDeletedDivision === 'MemberShip') {
      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.deleteData('membership/delete/' + toBeDeletedData.id, 'DELETE');

          if (responseData.status === 200) setRendering(!rendering);
          else if (responseData.status === 203) {
            setAlertText('아직 만료되지 않은 회원권이 존재합니다.\n진행중인 회원권을 삭제 후 다시시도해 주세요.');
            setIsViewCustomAlert(true);
          }
          else if (responseData.status === 204) {
            setAlertText("회원권 정보를 삭제하는데 실패하였습니다.\n잠시후 다시시도 해주세요");
            setIsViewCustomAlert(true);
          }
          else if (responseData.status === 401) history.push('/');
        }
      )();
    }
    setIsConfirm(false);
  }

  // 삭제 취소 클릭시
  function deleteButtonClick() {
    setToBeDeletedDivision('None');
    setToBeDeletedDate(undefined);
    setIsConfirm(false);
  }

  // Modal 취소 버튼 클릭시
  function exitClickFn(className) {
    if (className === 'MemberShip') setIsMemberShipAddClick(false);
    else if (className === 'Class') setIsClassAddClick(false);
  }

  function selectClickFn(className) {
    if (className === 'class') MemberShipCategoryRef.current.setIsClicked();
    else ClassCategoryRef.current.setIsClicked();
  }

  return (
    <TopLevelWrapper ref={TopLevelRef}>
      { isViewCustomAlert && <CustomAlert text={alertText} closeFn={() => setIsViewCustomAlert(false)}/>}
      { isMemberShipAddClick && <MemberShipAddModal ref={MemberShipAddModalRef} data={memberShipSplitData} index={memberShipClickIndex} rendering={() => setRendering(!rendering)} exitClickFn={exitClickFn} /> }
      { isClassAddClick && <ClassAddModal ref={ClassAddModalRef} data={classSplitData} index={classClickIndex} rendering={() => setRendering(!rendering)} exitClickFn={exitClickFn} /> }
      { isConfirm && <DeleteConfirmModal title={toBeDeletedDivision === 'Lesson' ? '수업 삭제' : '회원권 삭제'} itemName={toBeDeletedData.name + (toBeDeletedDivision === 'Lesson' ? ' 수업' : ' 회원권')} clickNoFn={deleteButtonClick} clickYesFn={deleteYesClick}/>}
      <InfoBox width={480}>
        <ModelWrapper>
          <Title>수업</Title>
          <ComponentWrapper>
            <Text>카테고리</Text> 
            <CategorySelectBox ref={ClassCategoryRef} options={options} width={415} rendering={categoryRendering} setRendering={setCategoryRendering} changeVal={selectClassValueChange} isModal={false} selectClickFn={() => selectClickFn('class')}/>
          </ComponentWrapper>
          <ComponentWrapper>
            <Text>수업</Text>
            <InfoWrapper style={{width: `432px`}}>
              {
                classSplitData.map((data, index) => 
                (
                  <ClassInfoBox 
                    key={index} 
                    data={data} 
                    index={index} 
                    coachList={coachList}
                    modifyClickFn={classModifyClick}
                    deleteClickFn={classDeleteClick}/>
                ))
              }
            </InfoWrapper>
          </ComponentWrapper>
          <ClassAddButton onClick={() => setIsClassAddClick(true)}>수업 추가</ClassAddButton>
        </ModelWrapper>
      </InfoBox>
      <InfoBox width={510}>
        <ModelWrapper>
          <Title>회원권</Title>
          <ComponentWrapper>
            <Text>카테고리</Text>
            <CategorySelectBox ref={MemberShipCategoryRef} options={options} width={450} rendering={categoryRendering} setRendering={setCategoryRendering} changeVal={selectMemberShipValueChange} isModal={false} selectClickFn={() => selectClickFn('membership')}/>
          </ComponentWrapper>
          <ComponentWrapper>
            <Text>회원권</Text>
            <InfoWrapper>
              {
                memberShipSplitData.map((data, index) => 
                (<MemberShipInfoBox 
                  key={index} 
                  data={data} 
                  index={index}
                  modifyClickFn={memberShipModifyClick} 
                  deleteClickFn={memberShipDeleteClick}/>)
                )
              }
            </InfoWrapper>
          </ComponentWrapper>
          <MemberShipAddButton onClick={() => setIsMemberShipAddClick(true)}>회원권 추가</MemberShipAddButton>
        </ModelWrapper>
      </InfoBox>
    </TopLevelWrapper>
  )
})

export default MemberShipAndClassView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 30px;

  // margin-bottom: 213px;
  margin-bottom: 96px;
  
  transition: all 0.3s ease-in-out;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  
  width: ${props => props.width}px;
  height: 590px;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  background-color: #FFFFFF;
`;

const ModelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
`;

const Title = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  margin-top: 24px;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  // justify-content: center;
  align-items: flex-start;

  width: 462px;
  height: 300px;

  padding: 0 0 0 0;
  
  overflow-y: scroll;
  overflow-x: none;

	&::-webkit-scrollbar {
		width: 9px;
	}

	&::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 20px #DFDFDF;
		border: 3px solid transparent;
		height: 25px;
		// background-color: #FF8B48;
		border-radius: 200px;
	}

	&::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px transparent;
		border: 4px solid transparent;
		background-color: transparent;
	}

	&::-webkit-scrollbar-track-piece {
		background-color: transparent;
	}
`;

const Text = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #595959;

  margin: 0 0 0 0;
`;

const MemberShipAddButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 114px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #CBCBCB;

  margin: 46px 0 0 338px;

  :hover {
    cursor: pointer;
  }
`;

const ClassAddButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 101px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #CBCBCB;

  margin: 46px 0 0 321px;

  :hover {
    cursor: pointer;
  }
`;