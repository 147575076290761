import { React, useState, useEffect } from 'react';
import styled from 'styled-components';

import FetchModule from '../../Share/Network/FetchModule.js';
import ExitButtonImg from '../../../image/BlackDeleteButton.png';

export default function WaitingPagePaymentModal(props) {
  const { 
    isUsePaymentSystem,
    name, 
    validity, 
    price, 
    cashPrice, 
    discount, 
    notReceived, 
    merchantUid, 
    totalRegistData,
    onRegist, 
    cancel, 
    getApprovalDate, 
  } = props;

  const [isInProgress, setIsInProgress] = useState(false);

  useEffect(() => {
    document.body.style= `overflow: hidden`;
    return () => document.body.style = `overflow: auto`;
  }, [])


  function onClickOnSitePayment() {
    let approvalDate = getApprovalDate();

    let requestData = {
      merchantUid: merchantUid,
      cardPrice: price,
      cashPrice: cashPrice,
      discount: discount,
      notReceived: notReceived,
      approvalDate: approvalDate
    }
    // console.log(requestData);
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataVersion2('sales/onSite/effectiveness', 'POST', requestData);

        if (responseData.status === 200) {
          // console.log('Effect Success');
          onRegist(responseData.data);
        }
        else if (responseData.status === 201) {
          alert('올바르지 않은 요청입니다');
          return;
        }
        else if (responseData.status === 202) {
          alert('이미 결제가 진행된 건 입니다.');
          return;
        }
        else if (responseData.status === 204) {
          alert('결제 내역이 존재하지 않습니다.');
          return;
        }
        // cancel();
      }
    )();
  }

  // 일반결제 버튼 클릭시 ( 페이플 ) //
  function onClickPaymentOnPayple() {
    if (isInProgress) {
      alert('결제를 진행중입니다!');
      return;
    }
    
    setIsInProgress(true);
    
    totalRegistData.merchantUid = merchantUid;

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataVersion2('member/registration/waiting/linkpay', 'POST', totalRegistData);

        // console.log(responseData);
        if (responseData.status === 200) {
          // onRegist(merchant_uid);
          cancel(responseData.data);
        }
        else if (responseData.status === 201) {
          alert('링크코치 가입자가 아닙니다.');
          setIsInProgress(false);
          return;
        }
        else if (responseData.status === 202) {
          alert('데이터가 존재하지 않습니다');
          setIsInProgress(false);
          return;
        }
        else if (responseData.status === 203) {
          alert('데이터가 존재하지 않습니다');
          setIsInProgress(false);
          return;
        }
        else if (responseData.status === 204) {
          alert('회원님의 카드가 등록되어있지 않습니다.\n등록 후 사용해주세요');
          setIsInProgress(false);
          return;
        }
        else if (responseData.status === 205) {
          alert('결제중 에러가 발생하였습니다.\n잠시 후 다시시도해 주세요');
          setIsInProgress(false);
          return;
        }
        else {
          alert(responseData.error);
          setIsInProgress(false);
          return;
        }
      }
    )();
  }

  return (
    <TopLevelWrapper>
      <ModalView isUsePaymentSystem={isUsePaymentSystem}>
        <TotalWrapper>
          <TitleLineWrapper>
            <Title>상품 정보</Title>
            <ExitButton src={ExitButtonImg} onClick={() => cancel()} />
          </TitleLineWrapper>
          <ProductInfoBox>
            <Logo src='https://picture.link-zone.org/ZoneLogo.png' />
            <TextWrapper>
              <TextEng>Link Zone</TextEng>
              <TextKor>{name}</TextKor>
              <TextExplanation>{validity} 일</TextExplanation>
            </TextWrapper>
          </ProductInfoBox>
          <PriceWrapper>
            <Title>최종 결제 금액</Title>
            <LayerWrapper style={{ marginTop: `24px` }}>
              <TextNormal style={{ color: `#595959` }}>결제 금액</TextNormal>
              <DivisionLine />
              <TextNormal style={{ color: `#595959` }}>{(parseInt(price) + parseInt(cashPrice)).toLocaleString('ko-KR')}원</TextNormal>
            </LayerWrapper>
            <LayerWrapper style={{ marginTop: `8px` }}>
              <TextBold style={{ color: `#232323` }}>최종 금액</TextBold>
              <DivisionLine />
              <TextBold style={{ color: `#FF8B48` }}>{(parseInt(price) + parseInt(cashPrice)).toLocaleString('ko-KR')}원</TextBold>
            </LayerWrapper>
          </PriceWrapper>
          {
            isUsePaymentSystem &&
            <OnSiteWrapper>
              <LinkPayPayment onClick={onClickPaymentOnPayple}>회원님의 링크페이로 결제하기</LinkPayPayment>
            </OnSiteWrapper>
          }
          <OnSiteWrapper isUsePaymentSystem={isUsePaymentSystem}>
            <OnSitePayment onClick={onClickOnSitePayment}>현장에서 직접 결제하기</OnSitePayment>
          </OnSiteWrapper>
        </TotalWrapper>
      </ModalView>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 1100;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.3);

  transition: all 0.3s ease-in-out;
`;

const ModalView = styled.div`
  // display: ${props => props.isClicked ? `none` : `flex`};
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 378px;
  // height: 416px;
  height: ${props => props.isUsePaymentSystem ? `454px` : `416px`};
  // height: 454px;

  border-radius: 30px;
  background-color: #FFFFFF;
  // box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  // width: 322px;
`;

const TitleLineWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const Title = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;
`;

const ExitButton = styled.img`
  width: 16px;
  height: 16px;

  :hover {
    cursor: pointer;
  }
`;

const ProductInfoBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  gap: 16px;
  margin: 24px 0 0 0;
  padding: 0 16px 0 16px;

  width: 290px;
  height: 100px;

  border-radius: 15px;
  background-color: #FF8B48;
`;

const Logo = styled.img`
  width: 60px;
  height: 60px;

  margin: 0 0 0 0;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const TextEng = styled.p`
  font-family: Poppins-Bold;
  font-size: 14px;

  color: #FFFFFF;

  margin: 0 0 0 0;
`;

const TextKor = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  margin: 0 0 0 0;
`;

const TextExplanation = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 12px;

  color: rgba(255, 255, 255, 0.5);

  margin: 0 0 0 0;
`;

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin: 24px 0 0 0;
  padding: 24px 0 0 0;

  width: 322px;

  border-top: 1px solid #ECECEC;
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const TextBold = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 14px;

  // color: #232323;

  margin: 0 0 0 0;
`;

const TextNormal = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  // color: #232323;

  margin: 0 0 0 0;
`;

const DivisionLine = styled.div`
  width: 160px;
  height: 1px;

  border-top: 1px dashed #ECECEC;
`;
const OnSiteWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // margin-top: 12px;
  margin-top: ${props => props.isUsePaymentSystem ? `12px` : `36px`};

  width: 100%;
`;

const OnSitePayment = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // width: 290px;
  width: 100%;
  height: 38px;

  border: 1px solid #E0E0E0;
  border-radius: 15px;
  background-color: #FFFFFF;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #BDBDBD;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #232323;
    color: #232323;
  }
`;

const LinkPayPayment = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // width: 155px;
  width: 100%;
  // height: 40px;
  height: 38px;

  border: 1px solid #E0E0E0;
  border-radius: 15px;
  background-color: #FFFFFF;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #BDBDBD;
  
  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    // background-color: #2BCB67;
    border: 1px solid #6DD49E;
    color: #6DD49E;
  }
`;