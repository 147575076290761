import { React, forwardRef, useState, useRef, useImperativeHandle, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';

import FetchModule from '../../Share/Network/FetchModule';

// import SelectOnPath from '../../image/SelectOn.png';
// import SelectOffPath from '../../image/SelectOff.png';

const FullListSelectBox = forwardRef((props, ref) => {
	const { date, setScheduleId, setLessonId, setClassTime, rendering } = props;
  const history = useHistory();

	const [isClicked, setIsClicked] = useState(false);
	const [scheduleData, setScheduleData] = useState([]);
  const [options, setOptions] = useState([]);
	const [categoryId, setCategoryId] = useState('');
	const [defaultVal, setDefaultVal] = useState({ name: '' });
  const [clickIndex, setClickIndex] = useState(-1);

	const SelectRef = useRef();

	useImperativeHandle(ref, () => ({
		setValue: (val) => {
			setCategoryId(val);
		},
		getValue: () => {
			return defaultVal;
		}
	}))
	// Schedule Data API호출
	useEffect(() => {
		const fetchModule = new FetchModule();
		(
			async () => {
				const responseData = await fetchModule.getData('schedule/', 'GET');
				// console.log(responseData.data);
				if (responseData.status === 200) setScheduleData(responseData.data);
				else if (responseData.status === 401) history.push('/');
			}
		)();
	}, [history])

	// Schedule Data를 사용 가능한 Data로 가공
	useEffect(() => {
		var tmp = scheduleData.slice();
		var newScheduleData = [];

		for (var i = 0; i < tmp.length; i++) {
			var endPoint = moment(tmp[i].classDate.split('T')[0]);

			while (tmp[i].endDate.split('T')[0] > endPoint.format('YYYY-MM-DD')) {
				if (endPoint.diff(date, 'days') >= 30) break;

				if (tmp[i].applyWeek.includes(endPoint.format('ddd')) || tmp[i].applyWeek === 'All') {
					var colSpan = 1;
					var rowSpan = 1;

					// 세로부분 합칠 크기 지정 ( 시간에 맞게 계산 )
					colSpan = parseInt(tmp[i].classTime.split('-')[1].split(':')[0]) - parseInt(tmp[i].classTime.split('-')[0].split(':')[0]);

					var newData = {
						classId: tmp[i].classId,
						classDate: endPoint.format('YYYY.MM.DD'),
						className: tmp[i].className,
						classTime: tmp[i].classTime,
						color: tmp[i].color,
						endDate: tmp[i].endDate,
						lessonId: tmp[i].lessonId,
						scheduleId: tmp[i].scheduleId,
						teacherName: tmp[i].teacherName,
						applyWeek: tmp[i].applyWeek,
						rowSpan: rowSpan,
						colSpan: colSpan,
						maxBookedPeople: tmp[i].maxBookedPeople,
						deduction: tmp[i].deduction
					}
					newScheduleData.push(newData);
				}
				endPoint = endPoint.clone().add(1, 'day');
			}
		}

		newScheduleData.sort(function (a, b) {
			return a.classDate < b.classDate ? -1 : a.classDate > b.classDate ? 1 : 0;
		})

		var newData = [];

		for (i = 0; i < newScheduleData.length; i++) {

			if (newScheduleData[i].classDate === date.clone().format('YYYY.MM.DD')) {
				newData.push(newScheduleData[i]);
			}
		}

		newData.sort(function (a, b) {
			return a.classTime < b.classTime ? -1 : a.classTime > b.classTime ? 1 : 0;
		})
		
		if (newData.length !== 0) {
			setScheduleId(newData[0].scheduleId);
			setClassTime(newData[0].classTime);
			setLessonId(newData[0].lessonId);
			setDefaultVal(options[0]);
		}
		setOptions(newData);
	}, [scheduleData, date])

	// Options Init Setting //
	useEffect(() => {
		if (options.length !== 0) {
			setScheduleId(options[0].scheduleId);
			setClassTime(options[0].classTime);
			setLessonId(options[0].lessonId);
			setDefaultVal(options[0]);
		}
	}, [options])

	function onClick() {
		if (isClicked) {
			SelectRef.current.style.border = '0px solid #CBCBCB';
			SelectRef.current.style.height = `0px`;
			SelectRef.current.style.overflow = 'hidden';
			setIsClicked(false);
		}
		else {
			SelectRef.current.style.border = '1px solid #FF8B48';
			// SelectRef.current.style.height = `240px`;
      SelectRef.current.style.height = `12.5vw`;
			SelectRef.current.style.overflowY = 'scroll';
			SelectRef.current.style.overflowX = 'none';
			setIsClicked(true);
		}
	}

	function optionalClick(index) {
		setDefaultVal(options[index]);
    setClickIndex(index);
		setLessonId(options[index].id);
		rendering();
	}

	return (
		<TopLevelWrapper>
      <ListButton onClick={onClick}>목록</ListButton>
			<SelectBox
				ref={SelectRef}
				isClicked={isClicked}
        onClick={onClick}>
				{
					isClicked &&
						options.map((data, index) =>
							<OptionWrapper key={index} onClick={() => optionalClick(index)}>
								<Option isClicked={clickIndex === index}>{data.className} ({data.classTime})</Option>
							</OptionWrapper>
						)
				}
			</SelectBox>
		</TopLevelWrapper>
	)
})

export default FullListSelectBox;

const TopLevelWrapper = styled.div`
	display: flex;
	flex-direction: column;
  
  justify-content: flex-start;
  align-items: flex-end;
	// width: 143px;
	// height: 38px;
`;

const SelectBox = styled.div`
	position: absolute;
	display: flex;
	flex-direction: column;
	// justify-content: center;
	align-items: flex-start;

	// width: 234px;
  width: 12.1875vw;
	height: 0px;
  // margin-top: 20px;
  margin-top: 1.041666666666667vw;

  z-index: 3;

	border: 0px solid #CBCBCB;
	border-radius: 15px;
	overflow: hidden;

	background-color: rgba(255, 255, 255, 1);
  transition: all 0.3s ease-in-out;

	:hover {
		cursor: pointer;
	}

	&::-webkit-scrollbar {
		width: 9px;
	}

	&::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 20px #FF8B48;
		border: 3px solid transparent;
		height: 25px;
		// background-color: #FF8B48;
		border-radius: 200px;
	}

	&::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px transparent;
		border: 4px solid transparent;
		background-color: transparent;
	}

	&::-webkit-scrollbar-track-piece {
		background-color: transparent;
	}
`;

const OptionWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;

	:hover {
		cursor: pointer;
	}
`;

const Option = styled.p`
	font-family: NotoSansKR-Medium;
	// font-size: 14px;
  font-size: 0.7291666666666667vw;

	// margin: 10px 0 10px 16px;
  maring-top: 0.5208333333333333vw;
  margin-right: 0px;
  margin-bottom: 0.5208333333333333vw;
  margin-left: 0.8333333333333333vw;

  color: ${props => props.isClicked ? '#FF8B48' : '#595959'};
`;

const ListButton = styled.p`
  font-family: NotoSansKR-Regular;
  // font-size: 14px;
  font-size: 0.7291666666666667vw;

  color: #777777;

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;