import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import lodash from 'lodash';

import FetchModule from '../../Share/Network/FetchModule';
import LessonBox from './LessonBox';

import EmptyValueLogo from '../../../image/EmptyValueLogo.png';
import BlackPlusIcon from '../../../image/BlackPlus.svg';
import BlackPencelIcon from '../../../image/BlackPencel.svg';
import BlackExitIcon from '../../../image/BlackExit.svg';
import MemberShipInfoBox from './MemberShipInfoBox';
import LessonEditBox from './LessonEditBox';
import MemberShipEditBox from './MemberShipEditBox';
import DeleteConfirmModal from './Modal/DeleteConfirmModal';
import CustomAlert from '../../Share/Normal/CustomAlert';
import LessonSaveModal from './Modal/LessonSaveModal';
import NewLessonSaveModal from './Modal/NewLessonSaveModal';

export default function ClassView() {
  const history = useHistory();

  const [isClickedSaveLesson, setIsClickedSaveLesson] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [isViewCustomAlert, setIsViewCustomAlert] = useState(false);
  const [alertText, setAlertText] = useState('');
  const [rendering, setRendering] = useState(false);
  const [memberShipData, setMemberShipData] = useState([]);
  const [useMemberShipData, setUseMemberShipData] = useState([]);
  const [memberShipBodyHeight, setMemberShipBodyHeight] = useState(0);
  const [lessonData, setLessonData] = useState([]);

  const [isProcessing, setIsProcessing] = useState(false);

  const [isClickedLessonEdit, setIsClickedLessonEdit] = useState(false);
  const [isClickedMemberShipEdit, setIsClickedMemberShipEdit] = useState(false);

  const [clickLessonData, setClickLessonData] = useState(undefined);
  const [clickEditLessonData, setClickEditLessonData] = useState(undefined);
  // const [clickMemberShipData, setClickMemberShipData] = useState(undefined);
  const [clickEditMemberShipData, setClickEditMemberShipData] = useState(undefined);

  // 수업 움직임 //
  const [totalScrollY, setTotalScrollY] = useState(0);
  const [target, setTarget] = useState(undefined);
  const [targetStartY, setTargetStartY] = useState(0);
  const [targetEndY, setTargetEndY] = useState(0);
  const [scrollY, setScrollY] = useState(0);
  const [clickDivision, setClickDivision] = useState(undefined);
  const [clickIndex, setClickIndex] = useState(undefined);
  const [changedIndex, setChangedIndex] = useState(undefined);
  const [emptyHeight, setEmptyHeight] = useState(0);
  const [isNotClick, setIsNotClick] = useState(false);
  const [isMoving, setIsMoving] = useState(false);
  // 수업 움직임 //

  // 수업 추가하기 클릭시 이미 클릭했던 수업 정보 담기 //
  const [tempClickLessonData, setTempClickLessonData] = useState(undefined);

  const [toBeDeletedDivision, setToBeDeletedDivision] = useState('None');
  const [toBeDeletedData, setToBeDeletedData] = useState(undefined);

  const LessonWrapperRef = useRef();

  // 페이지 전체 큰 스크롤 정리 //
  useEffect(() => {
    window.addEventListener("scroll", onScrollTotalWindow);
    return () => {
      window.removeEventListener("scroll", onScrollTotalWindow);
    };
  }, []);  

  // Init API Call //
  useEffect(() => {
    // const fetchModule = new FetchModule();
    // (
    //   async () => {
    //     const responseData = await fetchModule.getDataVersion2('membership/', 'GET');
    //     console.log(responseData);
    //     if (responseData.status === 200) {
    //       setMemberShipData(responseData.data.memberShips);
    //       setLessonData(responseData.data.lessonInfos);
    //     }
    //     else if (responseData.status === 401) history.push('/');
    //   }
    // )();

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getDataVersion2('lesson/zone', 'GET');
        // console.log(responseData);
        if (responseData.status === 200) {
          // setMemberShipData(responseData.data.memberShips);
          // setLessonData(responseData.data.lessonInfos);
          setLessonData(responseData.data);
        }
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }, [rendering])

  // 수업 클릭시 회원권 내역 찾기 및 회원권 뷰 크기 계산 //
  useEffect(() => {
    if (clickLessonData === undefined) return;

    var finalData = [];

    for (var i = 0; i < memberShipData.length; i++) {
      if (memberShipData[i].lessonId === clickLessonData.id) {
        finalData.push(memberShipData[i]);
      }
    }

    setUseMemberShipData(finalData);

    if (finalData.length === 0) setMemberShipBodyHeight(97);
    else {
      var height = 97 + 21 + (30 * finalData.length);

      if (height > 472) setMemberShipBodyHeight(472);
      else setMemberShipBodyHeight(height);
    }
  }, [clickLessonData, memberShipData])


  // 수업 추가하기 클릭시 //
  function onClickAddLessonEdit() {
    setTempClickLessonData(clickLessonData);
    setClickLessonData(undefined);
    setClickEditLessonData(undefined);
    setIsClickedLessonEdit(true);
  }

  // 수업 추가하기 닫기 클릭시 //
  function onClickExitAddLessonEdit() {
    setClickLessonData(tempClickLessonData);
    setTempClickLessonData(undefined);
    setClickEditLessonData(undefined);
    setIsClickedLessonEdit(false);
  }

  // 수업 수정 클릭시 //
  function onClickEditLessonButton(data) {
    setClickEditLessonData(data);
    setTempClickLessonData(clickLessonData);
    setClickLessonData(undefined);
    setIsClickedLessonEdit(true);
  }

  // 회원권 추가하기 클릭시 //
  function onClickMemberShipEdit() {
    if (isClickedMemberShipEdit) return;

    setClickEditMemberShipData(undefined);
    setIsClickedMemberShipEdit(true);
  }

  // 회원권 클릭시 ( 수정 겸용 ) //
  function onClickEditMemberShipButton(data) {
    setClickEditMemberShipData(data);
    setIsClickedMemberShipEdit(true);
  }

  // 회원권 추가하기 닫기 클릭시 //
  function onClickExitAddMemberShipEdit() {
    setClickEditMemberShipData(undefined);
    // setTempClickMemberShipData(undefined);
    setIsClickedMemberShipEdit(false);
  }

  // 회원권 저장 클릭시 //
  function onSaveMemberShip(requestData) {
    if (isProcessing) {
      alert('저장을 진행중입니다');
      return;
    }

    setIsProcessing(true);

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataVersion2('membership/save', 'POST', requestData);

        if (responseData.status === 200) {
          // setMemberShipData(responseData.data.memberShips);
          // setLessonData(responseData.data.lessonInfos);
          var memberShip = memberShipData.slice();

          var isChanged = false;

          for (var i = 0; i < memberShip.length; i++) {
            if (memberShip[i].id === responseData.data.id) {
              memberShip[i] = responseData.data;
              isChanged = true;
              break;
            }
          }

          if (!isChanged) memberShip.push(responseData.data);

          setMemberShipData(memberShip);
          onClickExitAddMemberShipEdit();
        }

        setIsProcessing(false);
      }
    )();
  }

  // 수업 저장 클릭시 //
  function onSaveLessonInfo(requestData) {
    if (requestData.id === undefined) {
      requestData.isAutoSaveAvailableReserveLesson = false;
      
      setClickLessonData(requestData);
      setIsClickedSaveLesson(true);
    }
    else {
      if (isProcessing) {
        alert('저장을 진행중입니다');
        return;
      }

      setIsProcessing(true);

      requestData.isAutoSaveAvailableReserveLesson = false;

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataVersion2('lesson/save', 'POST', requestData);

          if (responseData.status === 200) {
            // setMemberShipData(responseData.data.memberShips);
            // setLessonData(responseData.data.lessonInfos);
            let lesson = lessonData.slice();
            let isChanged = false;

            for (let i = 0; i < lesson.length; i++) {
              if (lesson[i].id === responseData.data.id) {
                lesson[i] = responseData.data;
                isChanged = true;
                break;
              }
            }

            if (!isChanged) lesson.push(responseData.data);

            setLessonData(lesson);
            onClickExitAddLessonEdit();
          }

          setIsProcessing(false);
        }
      )();
    }
  }

  // 수업 저장 클릭시 //
  function onClickSaveLessonInModal(isClicked) {
    if (isProcessing) {
      alert('저장을 진행중입니다');
      return;
    }
    
    setIsProcessing(true);
    
    let requestData = lodash.cloneDeep(clickLessonData);
    // console.log(requestData);d
    requestData.isAutoSaveAvailableReserveLesson = isClicked;

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataVersion2('lesson/save', 'POST', requestData);

        if (responseData.status === 200) {
          let lesson = lessonData.slice();
          let isChanged = false;

          for (let i = 0; i < lesson.length; i++) {
            if (lesson[i].id === responseData.data.id) {
              lesson[i] = responseData.data;
              isChanged = true;
              break;
            }
          }

          if (!isChanged) lesson.push(responseData.data);

          setLessonData(lesson);
          onClickExitAddLessonEdit();
        }

        setIsProcessing(false);
        setIsClickedSaveLesson(false);
      }
    )();
  }

  // 회원권 삭제 클릭시 //
  function onClickDeletedMemberShip(data) {
    setIsConfirm(true);
    setToBeDeletedDivision('MemberShip');
    setToBeDeletedData(data);
  }

  // 수업 삭제 클릭시 //
  function onClickDeletedLesson(data) {
    setIsConfirm(true);
    setToBeDeletedDivision("Lesson");
    setToBeDeletedData(data);
  }

  // 삭제 취소 클릭시
  function deleteExitButtonClick() {
    setToBeDeletedDivision('None');
    setToBeDeletedData(undefined);
    setIsConfirm(false);
  }

  // 삭제 확인 버튼 클릭시
  function deleteYesClick() {
    if (toBeDeletedDivision === 'Lesson') {
      var requestData = {
        lessonId: toBeDeletedData.id
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataVersion2('lesson/delete/', 'POST', requestData);
          // console.log(responseData);
          if (responseData.status === 200) {
            onClickExitAddLessonEdit();
            setRendering(!rendering);
          }
          else if (responseData.status === 201) {
            setAlertText('해당 수업과 연결된 회원권이 존재합니다.\n예약 가능 수업을 삭제 후 다시 시도해주세요.');
            setIsViewCustomAlert(true);
          }
          else if (responseData.status === 203) {
            setAlertText('아직 수업 일정이 존재합니다.\n스케줄을 삭제 후 다시 시도해주세요.');
            setIsViewCustomAlert(true);
          }
          else if (responseData.status === 401) history.push('/');
        }
      )();
    }
    else if (toBeDeletedDivision === 'MemberShip') {
      var requestData = {
        memberShipId: toBeDeletedData.id
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataVersion2('membership/delete/', 'POST', requestData);

          if (responseData.status === 200) setRendering(!rendering);
          else if (responseData.status === 203) {
            setAlertText('아직 만료되지 않은 회원권이 존재합니다.\n진행중인 회원권을 삭제 후 다시시도해 주세요.');
            setIsViewCustomAlert(true);
          }
          else if (responseData.status === 204) {
            setAlertText("회원권 정보를 삭제하는데 실패하였습니다.\n잠시후 다시시도 해주세요");
            setIsViewCustomAlert(true);
          }
          else if (responseData.status === 401) history.push('/');
        }
      )();
    }
    setIsConfirm(false);
  }

  // Movement Component 이동 클릭시 //
  function onMouseDown(e, clickTarget, idx) {
    // 좌클릭에만 반응하도록 //
    if (e.button === 0) {
      // console.log(e);
      // console.log('-----');
      // console.log(idx);
      setTarget(clickTarget);
      setClickIndex(idx);
      setChangedIndex(idx);
    }
    // 우클릭일 경우 원상복구 // 
    else if (target !== undefined && e.button === 2) {
      clickTarget.style.top = 'auto';
      // clickTarget.style.left = (clickTarget.offsetLeft - totalXScroll) + 'px';
      clickTarget.style.position = 'static';
      clickTarget.style.zIndex = 1;
      // clickTarget.style.width = '100%';
      clickTarget.style.width = '463px';

      setTarget(undefined);
      setTargetStartY(0);
      setTargetEndY(0);
      // setClickDivision(undefined);
      setClickIndex(undefined);
      setChangedIndex(undefined);
    }
  }

  // 선택된 Movement Component 이동 //
  function onMouseMove(e) {
    if (target === undefined) return;
    // console.log('-------------');
    // console.log(target.getBoundingClientRect().top);
    // console.log(target.getBoundingClientRect().top + 67);
    // console.log(e.clientY);
    if (isMoving) {
      setIsNotClick(true);
      setTargetStartY(e.clientY - (23 + 9));
      setTargetEndY(e.clientY - (23 + 9) + target.offsetHeight);
      
      target.style.top = ((e.clientY + totalScrollY) - (23 + 9)) + 'px';
      target.style.top = ((e.clientY + totalScrollY) - (23 + 9)) + 'px';
    }
    // else if (target.getBoundingClientRect().top > (e.clientY + totalScrollY) || (target.getBoundingClientRect().top + target.offsetHeight) < (e.clientY + totalScrollY)) {
    //   // console.log("???");
    //   target.style.width = '463px';
    //   // clickTarget.style.position = 'fixed';
    //   target.style.position = 'absolute';
    //   target.style.zIndex = 50;

    //   setIsMoving(true);
    //   setEmptyHeight(target.offsetHeight);
    // }
    else if (!target.contains(e.target)) {
      setEmptyHeight(target.offsetHeight);
      // console.log("???");
      target.style.width = '463px';
      target.style.position = 'absolute';
      target.style.zIndex = 50;

      setIsMoving(true);
    }
  }

  // useEffect(() => {
  //   console.log('changedIndex : ' + changedIndex);
  //   console.log('clickIndex : ' + clickIndex);
  // }, [changedIndex, clickIndex])

  // 선택된 Movement Component에서 Focus를 잃을경우 ( 마우스 클릭을 떼거나 컴포넌트 밖으로 나갈경우 ) //
  function onMouseUpAndMouseLeave(e) {
    if (target !== undefined) {
      let newList = []

      // List의 자리변경을 위한 임시변수 //
      var swap = undefined;

      // // 원래의 자신의 자리인경우 실행하지 않음 //
      if (changedIndex !== undefined) {
        let requestData = {
          lessonId: lessonData[clickIndex].id,
          orderNum: changedIndex + 1
        }

        const fetchModule = new FetchModule();
        (
          async () => {
            const responseData = await fetchModule.postDataVersion2('lesson/change/order/', 'POST', requestData);
  
            if (responseData.status === 200) {
              setRendering(!rendering);
            }
          }
        )();
        
        newList = lodash.clone(lessonData);

        // 움직일 데이터 추출 //
        swap = newList[clickIndex];
        // 움직일 데이터 원본 삭제 //
        newList.splice(clickIndex, 1);
        // 움직인 자리에 데이터 삽입 //
        newList.splice(changedIndex, 0, swap);

        setLessonData(newList);
      }

      target.style.top = 'auto';
      target.style.left = 'auto';
      target.style.position = 'static';
      target.style.zIndex = 1;
      // target.style.width = '100%';
      target.style.width = '463px';

      setTarget(undefined);
      setTargetStartY(0);
      setTargetEndY(0);
      setClickDivision(undefined);
      setClickIndex(undefined);
      setChangedIndex(undefined);
      setIsMoving(false);
      setEmptyHeight(0);

      setTimeout(() => {
        setIsNotClick(false);
      }, 300)
    }
  }

  // 카테고리 목록 스크롤 이벤트 감지 //
  function onScrollCategoryBox(e) {
    setScrollY(e.target.scrollTop);
  }

  // 페이지 스크롤 이벤트 감지 //
  function onScrollTotalWindow() {
    setTotalScrollY(window.scrollY);
  }

  return (
    <TopLevelWrapper>
      {/* {isClickedSaveLesson && <LessonSaveModal onClickExit={() => setIsClickedSaveLesson(false)} onClickSave={onClickSaveLessonInModal}/>} */}
      {isClickedSaveLesson && <NewLessonSaveModal onClickExit={() => setIsClickedSaveLesson(false)} onClickNoAdd={() => onClickSaveLessonInModal(false)} onClickAutoAdd={() => onClickSaveLessonInModal(true)}/>}
      {/* {<LessonSaveModal onClickExit={() => setIsClickedSaveLesson(false)} onClickSave={onClickSaveLessonInModal}/>} */}
      {isViewCustomAlert && <CustomAlert text={alertText} closeFn={() => setIsViewCustomAlert(false)} />}
      {isConfirm && <DeleteConfirmModal title={toBeDeletedDivision === 'Lesson' ? '수업 삭제' : '회원권 삭제'} itemName={toBeDeletedData.name + (toBeDeletedDivision === 'Lesson' ? ' 수업' : ' 회원권')} clickNoFn={deleteExitButtonClick} clickYesFn={deleteYesClick} />}
      <InfoBoxWrapper>
        <LessonBoxTotalWrapper isClickedLessonEdit={isClickedLessonEdit}>
          {
            // !isClickedMemberShipEdit &&
            <LessonBoxInnerWrapper isClickedMemberShipEdit={isClickedMemberShipEdit}>
              <LessonBoxWrapper
                ref={LessonWrapperRef}
                onMouseMove={onMouseMove} 
                onMouseUp={onMouseUpAndMouseLeave} 
                onMouseLeave={onMouseUpAndMouseLeave}
                onScroll={onScrollCategoryBox}>
                {
                  lessonData.map((data, index) => (
                    <LessonBox
                      key={index}
                      data={data}
                      setClickLessonData={setClickLessonData}
                      isClickedLessonEdit={isClickedLessonEdit}
                      onClickEditLessonButton={onClickEditLessonButton}
                      onClickDeletedLesson={onClickDeletedLesson} 
                      
                      // 움직임 //
                      index={index}
                      dataListLength={lessonData.length}
                      target={target}
                      targetStartY={targetStartY}
                      targetEndY={targetEndY}
                      emptyHeight={emptyHeight}
                      clickIndex={clickIndex}
                      changedIndex={changedIndex}
                      setChangedIndex={setChangedIndex}
                      clickDivision={clickDivision}
                      isNotClick={isNotClick}
                      setIsNotClick={setIsNotClick}
                      onMouseDown={onMouseDown}
                      // 움직임 //
                      />
                  ))
                }
              </LessonBoxWrapper>
              <AddLessonButton onClick={onClickAddLessonEdit}>
                <TextWrapper>
                  <Image src={BlackPlusIcon} />
                  <Text fontFmaily='NotoSansKR-Medium' fontSize={14} cursor='pointer'>수업 추가 하기</Text>
                </TextWrapper>
              </AddLessonButton>
            </LessonBoxInnerWrapper>
          }
          {/* <MemberShipEditBox
            lessonData={lessonData}
            clickLessonData={clickLessonData}
            clickEditMemberShipData={clickEditMemberShipData}
            isClickedMemberShipEdit={isClickedMemberShipEdit}
            onClickExitAddMemberShipEdit={onClickExitAddMemberShipEdit}
            onSaveMemberShip={onSaveMemberShip} /> */}
        </LessonBoxTotalWrapper>
        <MemberShipBoxTotalWrapper>
          {
            clickLessonData === undefined ?
              <EmptyMemberShipBoxWrapper>
                <EmptyLogoView src={EmptyValueLogo} />
              </EmptyMemberShipBoxWrapper>
              :
              <MemberShipBoxWrapper isClickedMemberShipEdit={isClickedMemberShipEdit}>
                <MemberShipBoxTitle backgroundColor={clickLessonData.color}>
                  <Text fontFmaily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF' cursor='default'>{clickLessonData.name}</Text>
                </MemberShipBoxTitle>
                <MemberShipBoxBody height={memberShipBodyHeight}>
                  <MemberShipScrollBox isExist={useMemberShipData.length > 0}>
                    {
                      useMemberShipData.map((data, index) => (
                        <MemberShipInfoBox
                          key={index}
                          data={data}
                          isClickedMemberShipEdit={isClickedMemberShipEdit}
                          onClickEditMemberShipButton={onClickEditMemberShipButton}
                          onClickDeletedMemberShip={onClickDeletedMemberShip}
                          onClickDeletedLesson={onClickDeletedLesson} />
                      ))
                    }
                  </MemberShipScrollBox>
                  <AddMemberShipButton onClick={onClickMemberShipEdit}>
                    <TextWrapper>
                      <Image src={BlackPlusIcon} />
                      <Text fontFmaily='NotoSansKR-Medium' fontSize={14} cursor='pointer'>회원권 추가 하기</Text>
                    </TextWrapper>
                  </AddMemberShipButton>
                </MemberShipBoxBody>
              </MemberShipBoxWrapper>
          }
          <LessonEditBox
            clickEditLessonData={clickEditLessonData}
            isClickedLessonEdit={isClickedLessonEdit}
            onClickExitAddLessonEdit={onClickExitAddLessonEdit}
            onSave={onSaveLessonInfo} />
        </MemberShipBoxTotalWrapper>
      </InfoBoxWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 30px;

  margin-bottom: 96px;

  transition: all 0.3s ease-in-out;
`;

const InfoBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  // gap: 15px;
  gap: 9px;
`;

const LessonBoxTotalWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 510px;
  max-height: 585px;

  // background-color: red;

  overflow: hidden;

  // opacity: ${props => props.isClickedLessonEdit ? '0.2' : '1'};

  transition: all 0.3s ease-in-out;
`;

const LessonBoxInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  z-index: ${props => props.isClickedMemberShipEdit ? `-3` : `1`};
  // opacity: ${props => props.isClickedMemberShipEdit ? `0` : `1`};

  transition: all 0.3s ease-in-out;
`;

const LessonBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  // gap: 15px;

  width: 510px;
  max-height: 522px;

  overflow-y: scroll;
  overflow-x: hidden;

	&::-webkit-scrollbar {
		width: 9px;
	}

	&::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 20px #DFDFDF;
		border: 3px solid transparent;
		height: 25px;
		// background-color: #FF8B48;
		border-radius: 200px;
	}

	&::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px transparent;
		border: 4px solid transparent;
		background-color: transparent;
	}

	&::-webkit-scrollbar-track-piece {
		background-color: transparent;
	}
`;

const AddLessonButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 15px;

  width: 495px;
  height: 52px;

  color: #595959;

  border-radius: 12px;
  background-color: #FFFFFF;
  box-shadow: 0px 10px 10px rgba(0, 54, 71, 0.02);

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
`;

const MemberShipBoxTotalWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  padding: 3px;

  width: 495px;
  min-width: 495px;
  max-width: 495px;
  height: 585px;

  overflow: hidden;
`;

const MemberShipBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  min-width: 495px;
  max-width: 495px;

  opacity: ${props => props.isClickedMemberShipEdit ? '0.2' : '1'};

  border-radius: 0px 0px 30px 30px;
  box-shadow: 0px 0px 5px rgba(240, 232, 227, 0.5);
  transition: all 0.3s ease-in-out;
`;

const EmptyMemberShipBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 495px;
  min-width: 495px;
  max-width: 495px;
  height: 585px;
`;

const MemberShipBoxTitle = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 52px;

  border-radius: 12px 12px 0px 0px;
  background-color: ${props => props.backgroundColor};

  transition: all 0.3s ease-in-out;
`;

const MemberShipBoxBody = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  align-items: center;

  color: #595959;

  width: 100%;
  height: ${props => props.height}px;

  overflow: hidden;

  transition: all 0.3s ease-in-out;

  border-radius: 0px 0px 30px 30px;
  background-color: #FFFFFF;

  // box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
`;

const MemberShipScrollBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: ${props => props.isExist ? `31` : `0`}px;

  width: 459px;
  max-height: 344px;

  gap: 10px;

  overflow-y: scroll;
  overflow-x: hidden;

  transition: all 0.3s ease-in-out;

	&::-webkit-scrollbar {
		width: 9px;
	}

	&::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 20px #DFDFDF;
		border: 3px solid transparent;
		height: 25px;
		// background-color: #FF8B48;
		border-radius: 200px;
	}

	&::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px transparent;
		border: 4px solid transparent;
		background-color: transparent;
	}

	&::-webkit-scrollbar-track-piece {
		background-color: transparent;
	}
`;

// const MemberShipInfoBox = styled.div`
//   display: flex;
//   flex-direction: row;

//   justify-content: flex-start;
//   align-items: flex-start;

//   min-width: 100%;
//   height: 20px;
// `;

const AddMemberShipButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  
  margin: 37px 0px 15px 0px;

  width: 442px;
  height: 45px;

  border-radius: 12px;
  background-color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const Text = styled.div`
  font-family: ${props => props.fontFmaily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }
`;

const EmptyLogoView = styled.img`
  width: 180px;
  height: 180px;

  margin-bottom: 20px;
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 6px;
`;

const Image = styled.img`
  width: 20px;
  height: 20px;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;