import { React, useRef, forwardRef, useImperativeHandle, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import LogoPath from '../../../image/CompleteSignUpLogo.png';

const CompleteSignUp= forwardRef((props, ref) => {
  const [userName, setUserName] = useState('');
  const history = useHistory();
  const TopLevelRef = useRef();

	useImperativeHandle(ref, () => ({
    setUserName: (val) => {
      setUserName(val)
    },
    setOpacity: (val) => {
      if (val === 0) {
        TopLevelRef.current.style.zIndex = -10;
        TopLevelRef.current.style.opacity = val;
      }
      else {
        TopLevelRef.current.style.zIndex = 2;
        TopLevelRef.current.style.opacity = val;
      }
    }
	}))

  function onClickGoHomeButton() {
    // history.push('/');
    window.location.reload();
  }

	return (
		<TopLevelWrapper ref={TopLevelRef}>
      <LogoWrapper>
        <Logo src={LogoPath}/>
      </LogoWrapper>
      <Name>{userName}님!</Name>
      <ExplanationWrapper>
        <EngExplanation>Link Zone</EngExplanation>
        <KorExplanation>가입을</KorExplanation>
        <KorExplanation>진심으로 환영합니다!</KorExplanation>
      </ExplanationWrapper>
      <GoHomeButton>
        <ButtonText onClick={onClickGoHomeButton}>홈페이지로 돌아가기</ButtonText>
      </GoHomeButton>
		</TopLevelWrapper>
	)
})
 
export default CompleteSignUp;

const TopLevelWrapper = styled.div`
	position: absolute;
	display: flex;
	flex-direction: column;

  justify-content: center;
  align-items: center;

	// width: 582px;
  width: max(356px, 30.3125vw);
	// height: 453px;
  height: max(278px, 23.59375vw);
  
	// border-radius: 50px;
  border-radius: max(30px, 2.604166666666667vw);
	box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  
  z-index: -10;
  opacity: 0;

  transition: all 0.5s ease-in-out;
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  // margin-top: -180px;
  margin-top: min(-110px, -9.375vw);
`;

const Logo = styled.img`
  // width: 250px;
  width: max(152px, 13.02083333333333vw);
  // height: 250px;
  height: max(152px, 13.02083333333333vw);
`;

const Name = styled.p`
  font-family: NotoSansKR-Bold;
  // font-size: 36px;
  font-size: max(22px, 1.875vw);

  margin: 24px 0 0 0;
`;

const ExplanationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  justify-content: center;
  // width: 232px;
  width: max(151px, 12.08333333333333vw);

  // margin-top: 16px;
  margin-top: max(10px, 0.8333333333333333vw);
`;

const EngExplanation = styled.p`
  font-family: Poppins-Regular;
  // font-size: 24px;
  font-size: max(14px, 1.25vw);

  margin: 0 0 0 0;
`;

const KorExplanation = styled.p`
  font-family: NotoSansKR-Regular;
  // font-size: 24px;
  font-size: max(14px, 1.25vw);

  margin: 0 0 0 0;
`;

const GoHomeButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // width: 354px;
  width: max(215px, 18.4375vw);
  // height: 46px;
  height: max(28px, 2.395833333333333vw);

  background-color: #FF8B48;
  // border-radius: 23px;
  border-radius: max(14px, 1.197916666666667vw);

  // margin-top: 56px;
  margin-top: max(34px, 2.916666666666667vw);

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background: #FF7322;
  }
`;

const ButtonText = styled.p`
  font-family: NotoSansKR-Medium;
  // font-size: 18px;
  font-size: max(12px, 0.9375vw);
  color: #FFFFFF;

  margin: 0 0 0 0;
`;