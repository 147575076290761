import React from "react";
import styled from "styled-components";

export default function RecordStandardBox(props) {
  const { sectionData } = props;

  // EMOM, TABATA 같은 라운드 반복 컴포넌트 만들기 //
  function decideRepeatComponent() {
    let roundList = [];

    for (let i = 0; i < sectionData.round; i++) {
      roundList.push('00');
    }

    return (
      <TotalRoundRepsInputWrapper>
        {
          roundList.map((data, index) => (
            <RoundRepsInputWrapper key={index}>
              <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F' hover='default'>{(index + 1) + 'R'}</Text>
              <RoundRepsInputView>
                <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F' hover='default'>{data}</Text>
              </RoundRepsInputView>
            </RoundRepsInputWrapper>
          ))
        }
      </TotalRoundRepsInputWrapper>
    )
  }

  // Custom 점수 기록 방식 정리 //
  function decideCustomScoreStandard() {
    // const options = ['점수 없음', '시간', '렙수', '라운드 + 렙수', '거리', '무게', '포인트', '피크와트', '최고 속도'];
    // const options = ['높을 수록', '낮을 수록'];
    // if (sectionData.scoreKeyword === '점수 없음'
    //   || sectionData.scoreKeyword === '피크와트'
    //   || sectionData.scoreKeyword === '최고 속도') {
    //   return (
    //     <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#000000' hover='default'>{'Custom_' + sectionData.scoreKeyword + '_점수 기록 방식'}</Text>
    //   )
    // }
    // else {
    //   return (
    //     <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#000000' hover='default'>{'Custom_' + sectionData.scoreKeyword + '_' + sectionData.scorePriority + '_점수 기록 방식'}</Text>
    //   )
    // }

    if (sectionData.scoreKeyword === '점수 없음') {
      return (
        <TagStandardBox>
          <CustomTextTitleWrapper>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#000000' hover='default'>Custom_점수 없음_점수 기록 방식</Text>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='rgba(79, 79, 79, 0.20)' hover='default'>Not Scored</Text>
          </CustomTextTitleWrapper>
          <Text style={{ marginTop: '10px' }} fontFamily='NotoSansKR-Regular' fontSize={12} color='#666666' hover='default'>점수 기준 없음</Text>
          <LongInputView>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F' hover='default'> 점수 없음</Text>
          </LongInputView>
        </TagStandardBox>
      )
    }
    else if (sectionData.scoreKeyword === '피크와트') {
      return (
        <TagStandardBox>
          <CustomTextTitleWrapper>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#000000' hover='default'>Custom_피트와트_점수 기록 방식</Text>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='rgba(79, 79, 79, 0.20)' hover='default'>Peak Watts</Text>
          </CustomTextTitleWrapper>
          <Text style={{ marginTop: '10px' }} fontFamily='NotoSansKR-Regular' fontSize={12} color='#666666' hover='default'>얼마나 많은 피크와트를 기록하셨나요?</Text>
          <LongInputView>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F' hover='default'>00</Text>
            <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F' hover='default'>Watts</Text>
          </LongInputView>
        </TagStandardBox>
      )
    }
    else if (sectionData.scoreKeyword === '최고 속도') {
      return (
        <TagStandardBox>
          <CustomTextTitleWrapper>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#000000' hover='default'>Custom_최고 속도_점수 기록 방식</Text>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='rgba(79, 79, 79, 0.20)' hover='default'>Speed</Text>
          </CustomTextTitleWrapper>
          <Text style={{ marginTop: '10px' }} fontFamily='NotoSansKR-Regular' fontSize={12} color='#666666' hover='default'>얼마나 높은 최고속도를 기록하셨나요?</Text>
          <LongInputView>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F' hover='default'>00</Text>
            <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F' hover='default'>Speed</Text>
          </LongInputView>
        </TagStandardBox>
      )
    }
    else if (sectionData.scoreKeyword === '시간') {
      return (
        <TagStandardBox>
          <CustomTextTitleWrapper>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#000000' hover='default'>{`Custom_시간_` + sectionData.scorePriority + `_점수 기록 방식`}</Text>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='rgba(79, 79, 79, 0.20)' hover='default'>{'Time : ' + (sectionData.scorePriority === '높을 수록' ? 'higher is better' : 'lower is better')}</Text>
          </CustomTextTitleWrapper>
          <Text style={{ marginTop: '10px' }} fontFamily='NotoSansKR-Regular' fontSize={12} color='#666666' hover='default'>{sectionData.scorePriority === '높을 수록' ? '얼마나 오랫동안 운동을 진행하셨나요?' : '얼마나 빠르게 동작을 완료하였나요?'}</Text>
          <TotalInputWrapper>
            <InputView>
              <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F' hover='default'>00</Text>
              <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F' hover='default'>Min</Text>
            </InputView>
            <InputView>
              <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F' hover='default'>00</Text>
              <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F' hover='default'>Sec</Text>
            </InputView>
          </TotalInputWrapper>
        </TagStandardBox>
      )
    }
    else if (sectionData.scoreKeyword === '렙수') {
      return (
        <TagStandardBox>
          <CustomTextTitleWrapper>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#000000' hover='default'>{`Custom_렙수_` + sectionData.scorePriority + `_점수 기록 방식`}</Text>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='rgba(79, 79, 79, 0.20)' hover='default'>{'Reps : ' + (sectionData.scorePriority === '높을 수록' ? 'higher is better' : 'lower is better')}</Text>
          </CustomTextTitleWrapper>
          <Text style={{ marginTop: '10px' }} fontFamily='NotoSansKR-Regular' fontSize={12} color='#666666' hover='default'>{sectionData.scorePriority === '높을 수록' ? '얼마나 많은 랩 수를 완벽하게 하셨나요?' : '얼마나 적은 랩 수를 완벽하게 하셨나요?'}</Text>
          <LongInputView>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F' hover='default'>00</Text>
            <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F' hover='default'>Rep</Text>
          </LongInputView>
        </TagStandardBox>
      )
    }
    else if (sectionData.scoreKeyword === '라운드 + 렙수') {
      return (
        <TagStandardBox>
          <CustomTextTitleWrapper>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#000000' hover='default'>{`Custom_렙수_` + sectionData.scorePriority + `_점수 기록 방식`}</Text>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='rgba(79, 79, 79, 0.20)' hover='default'>{'Rounds and Reps : ' + (sectionData.scorePriority === '높을 수록' ? 'higher is better' : 'lower is better')}</Text>
          </CustomTextTitleWrapper>
          <Text style={{ marginTop: '10px' }} fontFamily='NotoSansKR-Regular' fontSize={12} color='#666666' hover='default'>몇 라운드, 렙 수를 완벽하게 하셨나요?</Text>
          <TotalInputWrapper>
            <InputView>
              <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F' hover='default'>00</Text>
              <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F' hover='default'>R</Text>
            </InputView>
            <InputView>
              <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F' hover='default'>00</Text>
              <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F' hover='default'>Rep</Text>
            </InputView>
          </TotalInputWrapper>
        </TagStandardBox>
      )
    }
    else if (sectionData.scoreKeyword === '거리') {
      return (
        <TagStandardBox>
          <CustomTextTitleWrapper>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#000000' hover='default'>{`Custom_거리_` + sectionData.scorePriority + `_점수 기록 방식`}</Text>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='rgba(79, 79, 79, 0.20)' hover='default'>{'Distance : ' + (sectionData.scorePriority === '높을 수록' ? 'higher is better' : 'lower is better')}</Text>
          </CustomTextTitleWrapper>
          <Text style={{ marginTop: '10px' }} fontFamily='NotoSansKR-Regular' fontSize={12} color='#666666' hover='default'>{sectionData.scorePriority === '높을 수록' ? '얼마나 많은 거리를 완벽하게 하셨나요?' : '얼마나 적은 거리를 완벽하게 하셨나요?'}</Text>
          <LongInputView>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F' hover='default'>00</Text>
            <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F' hover='default'>m</Text>
          </LongInputView>
        </TagStandardBox>
      )
    }
    else if (sectionData.scoreKeyword === '무게') {
      return (
        <TagStandardBox>
          <CustomTextTitleWrapper>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#000000' hover='default'>{`Custom_무게_` + sectionData.scorePriority + `_점수 기록 방식`}</Text>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='rgba(79, 79, 79, 0.20)' hover='default'>{'Weight : ' + (sectionData.scorePriority === '높을 수록' ? 'higher is better' : 'lower is better')}</Text>
          </CustomTextTitleWrapper>
          <Text style={{ marginTop: '10px' }} fontFamily='NotoSansKR-Regular' fontSize={12} color='#666666' hover='default'>{sectionData.scorePriority === '높을 수록' ? '얼마나 많은 무게를 완벽하게 하셨나요?' : '얼마나 적은 무게를 완벽하게 하셨나요?'}</Text>
          <LongInputView>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F' hover='default'>00</Text>
            <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F' hover='default'>lbs</Text>
          </LongInputView>
        </TagStandardBox>
      )
    }
    else if (sectionData.scoreKeyword === '포인트') {
      return (
        <TagStandardBox>
          <CustomTextTitleWrapper>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#000000' hover='default'>{`Custom_포인트_` + sectionData.scorePriority + `_점수 기록 방식`}</Text>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='rgba(79, 79, 79, 0.20)' hover='default'>{'Point : ' + (sectionData.scorePriority === '높을 수록' ? 'higher is better' : 'lower is better')}</Text>
          </CustomTextTitleWrapper>
          <Text style={{ marginTop: '10px' }} fontFamily='NotoSansKR-Regular' fontSize={12} color='#666666' hover='default'>{sectionData.scorePriority === '높을 수록' ? '얼마나 많은 포인트를 완벽하게 하셨나요?' : '얼마나 적은 포인트를 완벽하게 하셨나요?'}</Text>
          <LongInputView>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F' hover='default'>00</Text>
            <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F' hover='default'>Point</Text>
          </LongInputView>
        </TagStandardBox>
      )
    }
  }

  return (
    <RecordStandardBoxWrapper>
      {
        sectionData.tag === 'ForTime' &&
        <TagStandardBox>
          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#000000' hover='default'>FORTIME 점수 기록 방식</Text>
          <Text style={{ marginTop: '10px' }} fontFamily='NotoSansKR-Regular' fontSize={12} color='#666666' hover='default'>동작을 타임캡 안에 성공했을 경우</Text>
          <TotalInputWrapper>
            <InputView>
              <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F' hover='default'>00</Text>
              <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F' hover='default'>Min</Text>
            </InputView>
            <InputView>
              <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F' hover='default'>00</Text>
              <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F' hover='default'>Sec</Text>
            </InputView>
          </TotalInputWrapper>
          <Text style={{ marginTop: '10px' }} fontFamily='NotoSansKR-Regular' fontSize={12} color='#666666' hover='default'>동작을 타임캡 안에 성공 못했을 경우 (끝낸 라운드+랩수를 알려주세요)</Text>
          <TotalInputWrapper>
            <InputView>
              <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F' hover='default'>00</Text>
              <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F' hover='default'>R</Text>
            </InputView>
            <InputView>
              <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F' hover='default'>00</Text>
              <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F' hover='default'>Rep</Text>
            </InputView>
          </TotalInputWrapper>
        </TagStandardBox>
      }
      {
        sectionData.tag === 'AMRAP' &&
        <TagStandardBox>
          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#000000' hover='default'>AMRAP 점수 기록 방식</Text>
          <Text style={{ marginTop: '10px' }} fontFamily='NotoSansKR-Regular' fontSize={12} color='#666666' hover='default'>몇 라운드, 랩 수를 완벽하게 하셨나요?</Text>
          <TotalInputWrapper>
            <InputView>
              <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F' hover='default'>00</Text>
              <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F' hover='default'>R</Text>
            </InputView>
            <InputView>
              <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F' hover='default'>00</Text>
              <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F' hover='default'>Rep</Text>
            </InputView>
          </TotalInputWrapper>
        </TagStandardBox>
      }
      {
        (sectionData.tag === 'EMOM' || sectionData.tag === 'TABATA') &&
        <TagStandardBox>
          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#000000' hover='default'>{sectionData.tag} 점수 기록 방식</Text>
          <Text style={{ marginTop: '10px' }} fontFamily='NotoSansKR-Regular' fontSize={12} color='#666666' hover='default'>라운드별, 성공한 랩 수를 적어주세요</Text>
          {decideRepeatComponent()}
        </TagStandardBox>
      }
      {
        sectionData.tag === 'Custom' && decideCustomScoreStandard()
      }
    </RecordStandardBoxWrapper>
  )
}

const RecordStandardBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  padding: 20px 0px;

  width: 100%;

  border-radius: 10px;
  background-color: #FFFFFF;
`;

const TagStandardBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: calc(100% - 30px);
`;

const TotalInputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  margin-top: 8px;

  width: 100%;
`;

const InputView = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding: 0px 15px;
  margin-top: 8px;

  width: calc(49% - 32px);
  height: 34px;

  border: 1px solid #CBCBCB;
  border-radius: 30px;
  background-color: #F6F6F6;
`;

const LongInputView = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding: 0px 15px;
  margin-top: 8px;

  width: calc(100% - 32px);
  height: 34px;

  border: 1px solid #CBCBCB;
  border-radius: 30px;
  background-color: #F6F6F6;
`;

const TotalRoundRepsInputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  flex-wrap: wrap;

  margin-top: 8px;

  width: 100%;

  gap: 8px;
`;

const RoundRepsInputWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 8px;

  width: 13%;
`;

const RoundRepsInputView = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  padding: 0px 15px;
  margin-top: 4px;

  // width: calc(13% - 32px);
  width: calc(100% - 32px);
  height: 34px;

  border: 1px solid #CBCBCB;
  border-radius: 10px;
  background-color: #F6F6F6;
`;

const CustomTextTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 100%;

  gap: 10px
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  
  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;