import { React, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

import TermsDeleteIcon from '../../image/TermsDeleteIcon.png';
import ExitButtonIcon from '../../image/ExitButtonImage.svg';

export default function TermsDeleteModal(props) {
  const { onClickExit, onClickExcuteDeleteTerms } = props;

  // 스크롤 제어 //
  useEffect(() => {
    document.body.style= `overflow: hidden`;
    return () => document.body.style = `overflow: auto`;
  }, [])

  return (
    <TopLevelWrapper>
      <ModalView>
        <RedTrashBoxWrapper>
          <EmptyButtonWrapper/>
          <RedTermsDeleteBox>
            <TermsDeleteImage src={TermsDeleteIcon} />
          </RedTermsDeleteBox>
          <ExitButtonWrapper onClick={onClickExit}>
            <ExitImage src={ExitButtonIcon} />
          </ExitButtonWrapper>
        </RedTrashBoxWrapper>
        <TotalWrapper>
          <Title>약관 삭제하기</Title>
          <TextWrapper>
            <Explanation>약관을 삭제하시면 되돌릴 수 없습니다</Explanation>
            <Explanation>삭제를 진행하시겠습니까?</Explanation>
          </TextWrapper>
        </TotalWrapper>
        <ButtonWrapper>
          <CancelButton 
            onClick={onClickExit}>
            취소
          </CancelButton>
          <DeleteButton
            onClick={onClickExcuteDeleteTerms}>
            삭제하기
          </DeleteButton>
        </ButtonWrapper>
      </ModalView>
    </TopLevelWrapper> 
  )
}

const startAnimation = keyframes`
	0% { opacity: 0; }
	50% { opacity: 0.5; }
	100% { opacity: 1; }
`;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  justify-content: center;
  align-items: center;

  z-index: 1000;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.2);
	
	animation-name: ${startAnimation};
	animation-duration: 0.5s;
	animation-timing-function: ease-out;
`;

const ModalView = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 354px;
  height: 200px;

  border-radius: 15px;
  box-shadow: 0px 40px 50px rgba(0, 0, 0, 0.1);
  background-color: #FFFFFF;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  margin-top: 12px;

  width: 90.33%;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: 0 0 0 0;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  justify-content: center;
  align-items: center;

  margin-top: 8px;
  
  // gap: 4px;
`;

const Explanation = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 12px;

  color: #595959;
  
  margin: 0 0 0 0;
`;

const RedTrashBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding: 0px 12px 0px 12px;
  margin-top: -43px;

  width: calc(100% - 24px);
`;

const RedTermsDeleteBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  min-width: 86px;
  max-width: 86px;
  width: 86px;

  min-height: 86px;
  max-height: 86px;
  height: 86px;

  border-radius: 86px;
  background-color: #FF3131;
`;

const TermsDeleteImage = styled.img`
  width: 47px;
  height: 47px;
`;

const EmptyButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 12px;
  height: 12px;
`;

const ExitButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 36px;

  width: 12px;
  height: 12px;

  :hover {
    cursor: pointer;
  }
`;

const ExitImage = styled.img`
  width: 12px;
  height: 12px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 28px;

  width: 100%;
  height: 52px;

  overflow: hidden;

  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
`;

const CancelButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 50%;
  height: 100%;

  background-color: #CFCFCF;
  
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #6DD49E;
  }
`;

const DeleteButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 50%;
  height: 100%;

  background-color: #CFCFCF;
  
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #FF3131;
  }
`;

const GoBackButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  border-radius: 15px;
  background-color: #FF3131;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;