import { React, useEffect, useRef } from 'react';
import styled from 'styled-components';

import WhiteVideoButton from '../../../image/WhiteVideoButton.png';
import WhiteExitButton from '../../../image/WhiteExitButton.png';

export default function EMOMTitleMovementBox(props) {
  const {
    data,
    index,
    dataListLength,
    emomScrollX,
    totalScrollX,
    target,
    targetStartX,
    targetEndX,
    clickIndex,
    changedIndex,
    setChangedIndex,
    emptyWidth,
    onMouseDown,
    onClickMovementVideo,
    onClickDeleteMovement
  } = props;


  const TopLevelRef = useRef();
  const TotalRef = useRef();
  const EmptyRef = useRef();

  useEffect(() => {
    if (TotalRef === null || target === TotalRef.current || changedIndex === undefined) {
      TotalRef.current.style.transform = `translateX(0px)`;
      TotalRef.current.style.transition = `none`;

      return;
    }
    // 현재 컴포넌트 시작 좌표 //
    let myStartX = TotalRef.current.getBoundingClientRect().left;
    // 컴포넌트 크기 //
    let myWidth = TotalRef.current.offsetWidth;
    // 현재 컴포넌트 끝 좌표 //
    let myEndX = myStartX + myWidth;
    // 컴포넌트 움직임을 결정할 기준점 //
    let standardX = (myStartX + (myWidth / 2));
    // 타겟 컴포넌트의 기준점 ( 정중앙 ) //
    let targetStandardX = ((targetStartX + targetEndX) / 2);

    // 움직임 에니메이션 주기 //
    TotalRef.current.style.transition = `all 0.3s ease-in-out`;

    // console.log('**************');
    // console.log('index : ' + index);
    // console.log('clickIndex : ' + clickIndex);
    // console.log('changedIndex : ' + changedIndex);
    // console.log('myStartX : ' + myStartX);
    // console.log('myEndX : ' + myEndX);
    // console.log('myWidth : ' + myWidth);
    // console.log('targetStartX : ' + targetStartX);
    // console.log('targetEndX : ' + targetEndX);
    // console.log('절대위치 : ' + myStartX);
    // // console.log('크기 : ' + myHeight);
    // console.log('기준점 : ' + standardX);
    // console.log('**************');

    // // 타겟이 움직여야할 컴포넌트보다 뒤에 있을 경우 //
    // if (clickIndex > index) {
    //   // 가동 범위 내에 있는지 확인 //
    //   if (myStartX < targetEndX || targetStartX < myEndX) {
    //     // 타겟의 기준점이 움직임 기준점을 넘기면 이동
    //     if (targetStartX < myEndX) {
    //       TotalRef.current.style.transform = `translateX(${targetEndX - targetStartX}px)`;
    //       if (index === 0) console.log("?? : " + index);
    //       setChangedIndex(index);
    //     }
    //     else if (targetEndX > myStartX) {
    //       TotalRef.current.style.transform = `translateX(0px)`;
    //       if (index === 0) console.log("!! : " + (index === dataListLength - 1 ? dataListLength : index + 1));
    //       setChangedIndex(index === dataListLength - 1 ? dataListLength : index + 1);
    //     }
    //   }
    // }
    // // 타겟이 움직여야할 컴포넌트보다 앞에 있을 경우 //
    // else if (clickIndex < index) {
    //   // 가동 범위 내에 있는지 확인 //
    //   if (myStartX < targetEndX || targetStartX < myEndX) {
    //     if (targetEndX > myStartX) {
    //       TotalRef.current.style.transform = `translateX(-${targetEndX - targetStartX}px)`;
    //       if (index === 0) console.log("** : " + index);
    //       setChangedIndex(index);
    //     }
    //     // 다시 위로 올라갈경우 제자리로 //
    //     else if (targetStartX < myEndX) {
    //       TotalRef.current.style.transform = `translateX(0px)`;
    //       if (index === 0) console.log("^^ : " + (index === 0 ? 0 : index - 1));
    //       setChangedIndex(index === 0 ? 0 : index - 1);
    //     }
    //   }
    // }
    // console.log('------');
    // console.log('click : ' + clickIndex);
    // console.log('index : ' + index);
    // console.log('myStart : ' + myStartX);
    // console.log('myEnd : ' + myEndX);
    // console.log('standardX : ' + standardX);
    // console.log('targetStart : ' + targetStartX);
    // console.log('targetEnd : ' + targetEndX);
    // console.log('targetStandardX : ' + targetStandardX);

    // // 타겟이 움직여야할 컴포넌트보다 뒤에 있을 경우 //
    // if (clickIndex > index) {
    //   // 가동 범위 내에 있는지 확인 //
    //   if (myStartX < targetStandardX && targetStandardX < myEndX) {
    //     // 타겟의 기준점이 움직임 기준점을 넘기면 이동
    //     if (targetStandardX < standardX) {
    //       TotalRef.current.style.transform = `translateX(${targetEndX - targetStartX}px)`;
    //       setChangedIndex(index);
    //     }
    //     else if (targetStandardX > standardX) {
    //       TotalRef.current.style.transform = `translateX(0px)`;
    //       setChangedIndex(index === dataListLength - 1 ? dataListLength : index + 1);
    //     }
    //   }
    // }
    // // 타겟이 움직여야할 컴포넌트보다 앞에 있을 경우 //
    // else if (clickIndex < index) {
    //   // 가동 범위 내에 있는지 확인 //
    //   if (myStartX < targetStandardX && targetStandardX < myEndX) {
    //     if (targetStandardX > standardX) {
    //       TotalRef.current.style.transform = `translateX(-${targetEndX - targetStartX + 8}px)`;
    //       setChangedIndex(index);
    //     }
    //     // 다시 위로 올라갈경우 제자리로 //
    //     else if (targetStandardX < standardX) {
    //       TotalRef.current.style.transform = `translateX(0px)`;
    //       setChangedIndex(index === 0 ? 0 : index - 1);
    //     }
    //   }
    // }

    // 타겟이 움직여야할 컴포넌트보다 뒤에 있을 경우 //
    if (clickIndex > index) {
      // 가동 범위 내에 있는지 확인 //
      if (myStartX < targetStandardX && targetStandardX < myEndX) {
        // 타겟의 기준점이 움직임 기준점을 넘기면 이동
        if (targetEndX > standardX) {
          TotalRef.current.style.transform = `translateX(${targetEndX - targetStartX}px)`;
          setChangedIndex(index);
        }
        else if (targetStartX < standardX) {
          TotalRef.current.style.transform = `translateX(0px)`;
          setChangedIndex(index === dataListLength - 1 ? dataListLength : index + 1);
        }
      }
    }
    // 타겟이 움직여야할 컴포넌트보다 앞에 있을 경우 //
    else if (clickIndex < index) {
      // 가동 범위 내에 있는지 확인 //
      if (myStartX < targetStandardX && targetStandardX < myEndX) {
        if (targetEndX > standardX) {
          TotalRef.current.style.transform = `translateX(-${targetEndX - targetStartX + 8}px)`;
          setChangedIndex(index);
        }
        // 다시 위로 올라갈경우 제자리로 //
        else if (targetStartX < standardX) {
          TotalRef.current.style.transform = `translateX(0px)`;
          setChangedIndex(index === 0 ? 0 : index - 1);
        }
      }
    }

    // // 타겟이 움직여야할 컴포넌트보다 아래에 있을 경우 //
    // if (clickIndex > idx) {
    //   // 가동 범위 내에 있는지 확인 //
    //   if (myStartY < targetStandardY || targetStandardY < myEndY) {
    //     // 타겟의 기준점이 움직임 기준점을 넘기면 이동
    //     if (targetStandardY < standardY) {
    //       TotalRef.current.style.transform = `translateY(${targetEndY - targetStartY}px)`;
    //       // if (idx === 0) console.log("?? : " + idx);
    //       setChangedIndex(idx);
    //     }
    //     else if (targetStandardY > standardY) {
    //       TotalRef.current.style.transform = `translateY(0px)`;
    //       // if (idx === 0) console.log("!! : " + (idx === dataListLength - 1 ? dataListLength : idx + 1));
    //       setChangedIndex(idx === dataListLength - 1 ? dataListLength : idx + 1);
    //     }
    //   }
    // }
    // // 타겟이 움직여야할 컴포넌트보다 위에 있을 경우 //
    // else if (clickIndex < idx) {
    //   // 가동 범위 내에 있는지 확인 //
    //   if (myStartY < targetStandardY || targetStandardY < myEndY) {
    //     if (targetStandardY > standardY) {
    //       TotalRef.current.style.transform = `translateY(-${targetEndY - targetStartY}px)`;
    //       // if (idx === 0) console.log("** : " + idx);
    //       setChangedIndex(idx);
    //     }
    //     // 다시 위로 올라갈경우 제자리로 //
    //     else if (targetStandardY < standardY) {
    //       TotalRef.current.style.transform = `translateY(0px)`;
    //       // if (idx === 0) console.log("^^ : " + (idx === 0 ? 0 : idx - 1));
    //       setChangedIndex(idx === 0 ? 0 : idx - 1);
    //     }
    //   }
    // }
    // console.log(TotalRef.current.style.transform);
  }, [TotalRef, target, targetStartX, targetEndX, clickIndex, changedIndex, index, dataListLength, emomScrollX, totalScrollX])

  // 영문, 한글 구분하여 폰트 적용하기 //
  function selectFontFamilyByLanguege() {
    let koreaPattern = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/; //한글

    // 한글이 포함되어있지 않은 경우 //
    if (!koreaPattern.test(data.movementName)) return 'Poppins-Regular';
    // 한글이 포함되어있는 경우 //
    else return 'NotoSansKR-Medium';
  }

  // 이동(동작 번호 및 동작 이름) 버튼 클릭시 //
  function onComponentMouseDown(e) {
    onMouseDown(e, TotalRef.current, index);
  }

  return (
    <TopLevelWrapper ref={TopLevelRef} index={target === undefined ? dataListLength - index : null}>
      {
        target === TotalRef.current && <EmptyBox ref={EmptyRef} width={emptyWidth} />
      }
      <TotalWrapper ref={TotalRef}>
        <NumberBox onMouseDown={onComponentMouseDown}>
          <Text fontFamily='Poppins-Medium' fontSize={12} color='#FFFFFF' hover='default'>{index + 1}</Text>
        </NumberBox>
        <InfoBox>
          <Text fontFamily={selectFontFamilyByLanguege()} fontSize={12} color='#FFFFFF' hover='default'>{data.movementName}</Text>
          <ButtonWrapper>
            <Button src={WhiteVideoButton} onClick={onClickMovementVideo} />
            <Button src={WhiteExitButton} onClick={() => onClickDeleteMovement('main.emom', index)} />
          </ButtonWrapper>
        </InfoBox>
      </TotalWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  z-index: ${props => props.index};
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 5px;
`;

const NumberBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 30px;
  height: 32px;

  border-radius: 6px;
  background-color: #6DD49E;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  padding: 0px 8px 0px 15px;
  gap: 38px;

  height: 32px;

  border-radius: 6px;
  background-color: #FF8B48;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const Button = styled.img`
  width: 20px;
  height: 20px;

  :hover {
    cursor: pointer;
  }
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  white-space:pre;
  
  :hover {
    cursor: ${props => props.hover};
  }
`;

const EmptyBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  min-width: ${props => props.width}px;
  min-height: 32px;
`;