import React, { useRef } from 'react';
import styled from 'styled-components';
import moment from 'moment';

import WhiteTrashIcon from '../../../../image/WhiteTrashIcon.png';
import WhitePencelIcon from '../../../../image/WhitePencelIcon.png';

export default function RefundInfoView(props) {
  const { 
    data,
    onClickHoldingAndExtendButton, 
    onClickHoldingEdit,
    onClickDeleteHoldingHistory,
    onClickModifyRefund,
    onClickDeleteRefund 
  } = props;

  const ComponentRef = useRef();
  const ModifyRef = useRef();
  const DeleteRef = useRef();

  // 이행 일수 계산 후 표출 //
  function decidePriceView() {
    if (data.refundCardPrice === 0) return '현금 - ' + data.refundCashPrice.toLocaleString('ko-KR') + ' 원';
    else if (data.refundCashPrice === 0) return '카드 - ' + data.refundCardPrice.toLocaleString('ko-KR') + ' 원';
    else return '카드 - ' + data.refundCardPrice.toLocaleString('ko-KR') + ' 원, 현금 - ' + data.refundCashPrice.toLocaleString('ko-KR') + ' 원';
  }

  // 컴포넌트 마우스 호버링 //
  function onMouseOver() {
    ModifyRef.current.style.opacity = 1;
    DeleteRef.current.style.opacity = 1;
  }

  // 컴포넌트 마우스 호버링 //
  function onMouseLeave() {
    ModifyRef.current.style.opacity = 0;
    DeleteRef.current.style.opacity = 0;
  }

  // 컴포넌트 클릭시 //
  function onClickComponent(e) {
    if (ModifyRef.current.contains(e.target) || DeleteRef.current.contains(e.target)) return;
    
  }

  // 수정버튼 클릭시 //
  function onClickModify() {
    onClickModifyRefund();
  }

  // 삭제버튼 클릭시 //
  function onClickDelete() {
    onClickDeleteRefund(data);
  }

  return (
    <TopLevelWrapper>
      <ComponentBox 
        ref={ComponentRef}
        // backgroundColor={calcBackgroundColor()}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        onClick={onClickComponent}>
        <TotalTextWrapper>
          <TextWrapper width={79}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FFFFFF' cursor='pointer'>환불 완료</Text>
          </TextWrapper>
          <TextWrapper width={95}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FFFFFF' cursor='pointer'>{moment(data.refundApprovalDate).format('YYYY.MM.DD')}</Text>
          </TextWrapper>
          <TextWrapper width={217}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FFFFFF' cursor='pointer'>{decidePriceView()}</Text>
          </TextWrapper>
          <TextWrapper width={385}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FFFFFF' cursor='pointer'>{data.reason}</Text>
          </TextWrapper>
          <TotalIconWrapper>
            <IconWrapper ref={ModifyRef} onClick={onClickModify}>
              <Icon src={WhitePencelIcon}/>
            </IconWrapper>
            <IconWrapper ref={DeleteRef} onClick={onClickDelete}>
              <Icon src={WhiteTrashIcon}/>
            </IconWrapper>
          </TotalIconWrapper>
        </TotalTextWrapper>
      </ComponentBox>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: flex-start;

  width: 100%;
`;

const ComponentBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: -35px;

  width: 88.62%;
  height: 87px;
  
  border-radius: 0px 0px 12px 12px;
  box-shadow: 0px 1px 30px rgba(0, 0, 0, 0.1);
  // background-color: #FB0000;
  background-color: #E35655;
  // background-color: ${props => props.backgroundColor};


  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const TotalTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  margin-left: 16px;

  margin-top: 50px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  margin-right: 8px;

  width: ${props => props.width}px;
  height: 20px;

  overflow: hidden;
`;

const TotalIconWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 12px;
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 24px;
  height: 24px;

  opacity: 0;

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const Icon = styled.img`
  width: 100%;
  height: 100%;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }
`;