import moment from 'moment';
import { React, useEffect, useState } from 'react';
import styled from 'styled-components';

const ValidMemberInfo = (props) => {
  const { data, onClick } = props;

  const [isExistNotReceived, setIsExistNotReceived] = useState(false);

  useEffect(() => {
    if (data.notReceivedPrice === 0 || data.notReceivedPrice === undefined) setIsExistNotReceived(false);
    else setIsExistNotReceived(true);
  }, [data])

  // 전화번호 뷰 정리 //
  function selectPhoneView() {
    if (data.phone === '') return '없음';
    else {
      return data.phone[0]
        + data.phone[1]
        + data.phone[2]
        + '-'
        + data.phone[3]
        + data.phone[4]
        + data.phone[5]
        + data.phone[6]
        + '-' +
        + data.phone[7]
        + data.phone[8]
        + data.phone[9]
        + data.phone[10];
    }
  }

  return (
    <TopLevelWrapper onClick={onClick}>
      <Table>
        <Body>
          <Tr style={{ width: '71px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '52px' }}>
                <Text isExistNotReceived={isExistNotReceived}>{data.name}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          {/* <Tr style={{ width: '55px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '26px' }}>
                <Text isExistNotReceived={isExistNotReceived}>{data.gender === 'male' ? '남성' : '여성'}</Text>
              </TextWrapper>
            </Td>
          </Tr> */}
          <Tr style={{ width: '70px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '26px' }}>
                <Text isExistNotReceived={isExistNotReceived}>{data.gender === 'male' ? '남성' : '여성'}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          {/* <Tr style={{ width: '98px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '98px' }}>
                <Text isExistNotReceived={isExistNotReceived}>{data.birthDay[0]
                  + data.birthDay[1]
                  + data.birthDay[2]
                  + data.birthDay[3]
                  + '.'
                  + data.birthDay[4]
                  + data.birthDay[5]
                  + '.'
                  + data.birthDay[6]
                  + data.birthDay[7]}</Text>
              </TextWrapper>
            </Td>
          </Tr> */}
          <Tr style={{ width: '122px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '98px' }}>
                <Text isExistNotReceived={isExistNotReceived}>{data.birthDay[0]
                  + data.birthDay[1]
                  + data.birthDay[2]
                  + data.birthDay[3]
                  + '.'
                  + data.birthDay[4]
                  + data.birthDay[5]
                  + '.'
                  + data.birthDay[6]
                  + data.birthDay[7]}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '114px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '114px' }}>
                {/* <Text>{data.phone[0] 
                      + data.phone[1]
                      + data.phone[2]
                      + '-'
                      + data.phone[3]
                      + data.phone[4]
                      + data.phone[5]
                      + data.phone[6]
                      + '-' +
                      + data.phone[7]
                      + data.phone[8]
                      + data.phone[9]
                      + data.phone[10]}</Text> */}
                <Text isExistNotReceived={isExistNotReceived}>{selectPhoneView()}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '242px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '218px' }}>
                <Text isExistNotReceived={isExistNotReceived}>{data.memberShipName}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '98px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '70px' }}>
                <Text isExistNotReceived={isExistNotReceived}>{data.startDate.split('T')[0].replaceAll('-', '.')}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          {/* <Tr style={{ width: '98px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '70px' }}>
                <Text isExistNotReceived={isExistNotReceived}>{data.endDate.split('T')[0].replaceAll('-', '.')}</Text>
              </TextWrapper>
            </Td>
          </Tr> */}
          <Tr style={{ width: '180px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '70px' }}>
                <Text isExistNotReceived={isExistNotReceived}>{data.endDate.split('T')[0].replaceAll('-', '.')}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          {/* <Tr style={{ width: '115px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '93px' }}>
              </TextWrapper>
            </Td>
          </Tr> */}
          <Tr style={{ width: '24px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '24px' }}>
                <Text isExistNotReceived={isExistNotReceived}>{data.count}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          {
            moment(data.endDate).diff(moment(), 'day') <= 5 &&
            <Tr style={{ width: '45px' }}>
              <Td>
                <TextWrapper style={{ maxWidth: '45px' }}>
                  <Dday>{moment(moment(data.endDate).format('YYYY-MM-DD')).diff(moment(moment().format('YYYY-MM-DD')), 'day') === 0 ? 'D-day' : 'D-' + moment(moment(data.endDate).format('YYYY-MM-DD')).diff(moment(moment().format('YYYY-MM-DD')), 'day')}</Dday>
                </TextWrapper>
              </Td>
            </Tr>
          }
        </Body>
      </Table>
    </TopLevelWrapper>
  )
};

export default ValidMemberInfo;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  // justify-content: space-around;
  // justify-content: center;
  align-items: center;

  width: 1018px;
  height: 50px;

  background: #FFFFFF;

  margin-top: 8px;

  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  border: 1px solid #FFFFFF;
  border-radius: 30px;
  
  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #FF8B48;
  }
`;

const Table = styled.table`
  display: flex;
  flex-direction: row;
`;

const Body = styled.tbody`
  display: flex;
  flex-direction: row;
  
  margin-left: 28px;
`;

const Tr = styled.tr`
  display: flex;
  flex-direction: row;
`;

const Td = styled.td`
  display: flex;
  flex-direction: row;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  overflow: hidden;

  max-height: 20px;
  // border: 1px solid #232323;
`;

const Text = styled.span`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: ${props => props.isExistNotReceived ? `#FF8B48` : `#595959`};
  
  margin: 0 0 0 0;
`;

const Dday = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 45px;
  height: 20px;

  border-radius: 30px;
  background-color: #FF8B48;

  font-family: NotoSansKR-Regular;
  font-size: 11px;

  color: #FFFFFF;

  margin: 0 0 0 0;
`;