import React from "react";
import styled from "styled-components";
import { cloneDeep } from 'lodash';

import CheckBox from "./CheckBox";
import FetchModule from "../../../Model/Share/Network/FetchModule";

export default function RegistrationSettingMessageBox(props) {
  const {
    isActivate,
    registration,
    setRegistration,
    onClickEdit,
  } = props;

  // 체크박스 클릭시 //
  function onClickCheckBox(division) {
    if (!isActivate) return;
    
    let newData = cloneDeep(registration);
    
    // 신규 등록시 //
    if (division === 'NewRegistration') {
      newData.newRegistration.isUseSetting = !newData.newRegistration.isUseSetting;

      saveMessageSetting(newData.newRegistration);
    }
    // 재등록시 //
    else {
      newData.reRegistration.isUseSetting = !newData.reRegistration.isUseSetting;

      saveMessageSetting(newData.reRegistration);
    }

    // setRegistration(newData);
  }

  // 변수 치환 //
  function replaceText(value) {
    return value.replaceAll('{store_name}', '{시설 이름}')
                .replaceAll('{user_name}', '{회원 이름}')
                .replaceAll('{membership_name}', '{보유 회원권}')
                .replaceAll('{exercise_period}', '{운동 기간}')
                .replaceAll('{locker_name}', '{락커 번호}')
                .replaceAll('{coach_name}', '{코치 이름}')
                .replaceAll('{class_name}', '{수업 이름}')
                .replaceAll('{reserve_time}', '{예약 시간}')
                .replaceAll('{class_time}', '{수업 시간}')
                .replaceAll('{holding_start_date}', '{홀딩 시작일}')
                .replaceAll('{holding_end_date}', '{홀딩 종료일}')
  }

  // 데이터 저장 //
  function saveMessageSetting(data) {
    let requestData = {
      id: data.id,
      storeId: data.storeId,
      division: data.division,
      text: data.text,
      title: data.title,
      applyDay: data.applyDay,
      applyTime: data.applyTime,
      isUseSetting: data.isUseSetting
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataVersion2('message_setting/save', 'POST', requestData);

        if (responseData.status === 200) {
          let newData = cloneDeep(registration);

          // 회원 신규 등록시 //
          if (data.division === 'NewRegistration') newData.newRegistration = responseData.data;
          // 회원권 연장 및 재 등록시 //
          else if (data.division === 'ReRegistration') newData.reRegistration = responseData.data;

          setRegistration(newData);
        }
      }
    )();
  }

  // 아무것도 하지 않기 //
  function doNothing() {}

  return (
    <TopLevelWrapper>
      <DivisionBox isActivate={isActivate}>
        <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>등록</Text>
      </DivisionBox>
      <SettingBox>
        <SettingColumnBox>
          <CheckBoxWrapper onClick={() => onClickCheckBox('NewRegistration')}>
            <CheckBox
              isClicked={registration.newRegistration.isUseSetting && isActivate}
              onClickCheckBox={doNothing}

              boxWidth={15}
              boxHeight={15}
              boxRadius={5}

              imageWidth={10}
              imageHeight={7} />
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='pointer'>{registration.newRegistration.title}</Text>
          </CheckBoxWrapper>
          <SettingTextWrapper>
            <TextWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='pointer'>{replaceText(registration.newRegistration.text)}</Text>
            </TextWrapper>
          </SettingTextWrapper>
          <ModifyButton onClick={() => onClickEdit(registration.newRegistration)}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FF8B48' hover='pointer'>수정하기</Text>
          </ModifyButton>
        </SettingColumnBox>

        <DivisionLine/>

        <SettingColumnBox>
          <CheckBoxWrapper onClick={() => onClickCheckBox('ReRegistration')}>
            <CheckBox
              isClicked={registration.reRegistration.isUseSetting && isActivate}
              onClickCheckBox={doNothing}

              boxWidth={15}
              boxHeight={15}
              boxRadius={5}

              imageWidth={10}
              imageHeight={7} />
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='pointer'>{registration.reRegistration.title}</Text>
          </CheckBoxWrapper>
          <SettingTextWrapper>
            <TextWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='pointer'>{replaceText(registration.reRegistration.text)}</Text>
            </TextWrapper>
          </SettingTextWrapper>
          <ModifyButton onClick={() => onClickEdit(registration.reRegistration)}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FF8B48' hover='pointer'>수정하기</Text>
          </ModifyButton>
        </SettingColumnBox>
      </SettingBox>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
`;

const DivisionBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 0px 20px;

  height: 25px;

  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  background-color: ${props => props.isActivate ? `#FF8B48` : `#CBCBCB`};

  transition: all 0.3s ease-in-out;
`;

const SettingBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 10px 5px;

  width: calc(100% - 10px);
  
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  background-color: #FFFFFF;
`;

const SettingColumnBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100%;
  min-height: 40px;
`;

const CheckBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  padding: 0px 5px;

  width: 304px;
  height: 100%;

  gap: 14px;

  :hover {
    cursor: pointer;
  }
`;

const SettingTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  justify-content: center;
  align-items: center;

  width: 604px;
  height: 100%;

  border-left: 1px solid #969696;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  padding: 10px 15px;

  width: calc(100% - 30px);
`;

const ModifyButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 76px;
  height: 100%;

  background-color: #FFFFFF;

  :hover {
    cursor: pointer;
  }
`;

const DivisionLine = styled.div`
  width: calc(100% - 20px);
  height: 1px;

  margin: 15px 0px;

  background-color: #D9D9D9;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;