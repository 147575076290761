import { React, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

import LessonAddIcon from '../../../../image/LessonAddImage.png';
import ExitButtonIcon from '../../../../image/ExitButtonImage.svg';

export default function SelectReserveAndQueueModal(props) {
  const { onClickExit, onClickAddReserve, onClickAddQueue } = props;

  // 스크롤 제어 //
  useEffect(() => {
    document.body.style= `overflow: hidden`;
    return () => document.body.style = `overflow: auto`;
  }, [])

  return (
    <TopLevelWrapper>
      <ModalView>
        <RedTrashBoxWrapper>
          <EmptyButtonWrapper/>
          <RedTrashBox>
            <TrashImage src={LessonAddIcon} />
          </RedTrashBox>
          <ExitButtonWrapper onClick={onClickExit}>
            <ExitImage src={ExitButtonIcon} />
          </ExitButtonWrapper>
        </RedTrashBoxWrapper>
        <TotalWrapper>
          <Title>예약 인원 추가하기</Title>
          <TextWrapper>
            <Explanation>예약 인원이 다 찼습니다</Explanation>
            <Explanation>신규 예약 인원을 대기열에 추가하시겠습니까?</Explanation>
          </TextWrapper>
        </TotalWrapper>
        <ButtonWrapper>
          <NoButton onClick={onClickAddReserve}>예약열 추가</NoButton>
          <YesButton onClick={onClickAddQueue}>대기열 추가</YesButton>
        </ButtonWrapper>
      </ModalView>
    </TopLevelWrapper> 
  )
}

const startAnimation = keyframes`
	0% { opacity: 0; }
	50% { opacity: 0.5; }
	100% { opacity: 1; }
`;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  justify-content: center;
  align-items: center;

  z-index: 1000;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.6);
	
	animation-name: ${startAnimation};
	animation-duration: 0.5s;
	animation-timing-function: ease-out;
`;

const ModalView = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 354px;
  height: 200px;

  border-radius: 15px;
  box-shadow: 0px 40px 50px rgba(0, 0, 0, 0.1);
  background-color: #FFFFFF;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  margin-top: 12px;

  width: 90.33%;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: 0 0 0 0;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  justify-content: center;
  align-items: center;

  margin-top: 8px;
  
  // gap: 4px;
`;

const Explanation = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 12px;

  color: #595959;
  
  margin: 0 0 0 0;
`;

const RedTrashBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding: 0px 12px 0px 12px;
  margin-top: -43px;

  width: calc(100% - 24px);
`;

const RedTrashBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  min-width: 86px;
  max-width: 86px;
  width: 86px;

  min-height: 86px;
  max-height: 86px;
  height: 86px;

  border-radius: 86px;
  background-color: #FF8B48;
`;

const TrashImage = styled.img`
  width: 53px;
  height: 53px;
`;

const EmptyButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 12px;
  height: 12px;
`;

const ExitButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 36px;

  width: 12px;
  height: 12px;

  :hover {
    cursor: pointer;
  }
`;

const ExitImage = styled.img`
  width: 12px;
  height: 12px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 28px;

  width: 100%;
  height: 52px;

  overflow: hidden;

  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
`;

const NoButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 50%;
  height: 100%;

  background-color: #CFCFCF;
  
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #6DD49E;
  }
`;

const YesButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 50%;
  height: 100%;

  background-color: #CFCFCF;
  
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #FF8B48;
  }
`;

// const YesButton = styled.div`
//   display: flex;
//   flex-direction: column;

//   justify-content: center;
//   align-items: center;

//   width: 100px;
//   height: 40px;

//   border-radius: 15px;
//   background-color: #FF3131;

//   font-family: NotoSansKR-Medium;
//   font-size: 14px;

//   color: #FFFFFF;

//   transition: all 0.3s ease-in-out;

//   :hover {
//     cursor: pointer;
//   }
// `;