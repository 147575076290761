import { forwardRef, React, useImperativeHandle, useRef } from 'react';
import styled, { keyframes } from 'styled-components';

import LinkManagerLogoPath from '../../../image/LinkManagerLogo.png';

const MobileLogoView = forwardRef((props, ref) => {
	const TopLevelRef = useRef();

	useImperativeHandle(ref, () => ({
		setOpacity: (val) => {
			if (val === 0) {
				TopLevelRef.current.style.opacity = val;
			}
			else {
				TopLevelRef.current.style.transform = val;
			}
		}
	}))

	return (
		<TopLevelWrapper ref={TopLevelRef}>
			<BackgroundShape>
				<LogoWrapper>
					<Logo src={LinkManagerLogoPath} />
				</LogoWrapper>
        <TextWrapper>
          <MainName>Link Zone</MainName>
          <Explanation>쉽고 간편한 피트니스 회원 관리 프로그램</Explanation>
        </TextWrapper>
			</BackgroundShape>
		</TopLevelWrapper>
	)
});

export default MobileLogoView;

// const startAnimation = keyframes`
// 	0% { transform: translateY(1500px); }
// 	100% { transform: translateY(0px); }
// `;

const TopLevelWrapper = styled.div`
	display: flex;
	flex-direction: column;
  
  justify-content: center;
	align-items: center;

	width: 449px;
	// width: max(351px, 23.38541666666667vw);
	// width: max(290px, 23.38541666666667vw);
	// width: max(290px, 30.3125vw);

	height: 115px;
	// height: max(92px, 5.989583333333333vw);
	// height: max(92px, 14.58333333333333vw);

	margin-top: 16px;
	// margin-top: max(13px, 0.8333333333333333vw);
  margin-bottom: 16px;
	// margin-bottom: max(13px, 0.8333333333333333vw);

	border-radius: 30px;
	box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);

	background-color: #FF8B48;
`;

const BackgroundShape = styled.div`
	display: flex;
	flex-direction: row;

  justify-content: center;
	align-items: center;
`;

const LogoWrapper = styled.div`
	display: flex;
	flex-direction: column;

	margin-top: 87px;
	// margin-top: max(30px, 4.53125vw);
  margin-top: 0px;
  margin-right: 16px;
	// margin-right: max(6px, 0.8333333333333333vw);
  margin-bottom: 0px;
  margin-left: 0px;
`;

const Logo = styled.img`
	width: 250px;
	// width: max(68px, 13.02083333333333vw);
	height: 250px;
	// height: max(68px, 13.02083333333333vw);
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
	// justify-content: center;
  align-items: flex-start;
`;

const MainName = styled.p`
	font-family: Poppins-Regular;
	// font-size: 50px;
	font-size: max(28px, 2.604166666666667vw);
	// line-height: 75px;
	color: rgba(255, 255, 255, 1);
		
	margin: 0 0 0 0;

	cursor: default;
`;

const Explanation = styled.p`
	font-family: NotoSansKR-Regular;
	// font-size: 20px;
	font-size: max(10px, 1.041666666666667vw);
	// line-height: 29px;
	color: #FFFFFF;
	opacity: 0.7;

	// margin: 16px 0 0 0;
	margin-top: 0.8333333333333333vw; //2.667 + 6
	margin-top: calc(1.284739583333333vw - 12.667px);
	margin-right: 0px;
	margin-bottom: 0px;
	margin-left: 0px;
		
	cursor: default;
`;
