import { React, useState, useEffect } from 'react';
import styled from 'styled-components';

import AscSortButtonPath from '../../../image/AscSortButton.png';
import DescSortButtonPath from '../../../image/DescSortButton.png';

const WaittingColumnView = () => {
  const [startButtonImg, setStartButtonImg] = useState(AscSortButtonPath);

  // true === ACS Sort && false === DESC
  const [startDateSort, setStartDateSort] = useState(true);

  useEffect(() => {
    if (startDateSort) setStartButtonImg(AscSortButtonPath);
    else setStartButtonImg(DescSortButtonPath)
  }, [startDateSort])

  return (
    // <TopLevelWrapper>
    //   <Column style={{marginRight: '40px', marginLeft: '28px'}}>이름</Column>
    //   <Column style={{marginRight: '29px'}}>성별</Column>
    //   <Column style={{marginRight: '46px'}}>생년월일</Column>
    //   <Column style={{marginRight: '49px'}}>핸드폰번호</Column>
    //   <Column style={{marginRight: '150px'}}>지점</Column>
    //   <Column style={{marginRight: '119px'}}>회원권</Column>
    //   <SortingWrapper>
    //     <Column style={{ marginRight: '4px' }}>등록 요청 날짜</Column>
    //     <SortingButton img={startButtonImg} onClick={() => setStartDateSort(!startDateSort)} style={{marginRight: '35px'}}/>
    //   </SortingWrapper>
    // </TopLevelWrapper>
    <TopLevelWrapper>
      <Column style={{marginRight: '40px', marginLeft: '28px'}}>이름</Column>
      <Column style={{marginRight: '29px'}}>성별</Column>
      <Column style={{marginRight: '46px'}}>생년월일</Column>
      <Column style={{marginRight: '51px'}}>핸드폰번호</Column>
      <Column style={{marginRight: '68px'}}>상태</Column>
      <Column style={{marginRight: '109px'}}>이전 회원권</Column>
      <Column style={{marginRight: '101px'}}>신청 회원권</Column>
      <SortingWrapper>
        <Column style={{ marginRight: '4px' }}>등록 요청 날짜</Column>
        <SortingButton img={startButtonImg} onClick={() => setStartDateSort(!startDateSort)} style={{marginRight: '35px'}}/>
      </SortingWrapper>
    </TopLevelWrapper>
  )
}

export default WaittingColumnView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  // justify-content: center;
  
  // justify-content: center;
  align-items: center;

  width: 1020px;
  // margin-top: 34px;
`;

const Column = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;
`;

const SortingWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const SortingButton = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;

  background: url(${props => props.img}) no-repeat center center;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;
