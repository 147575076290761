import { React, useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';

const ClassSizeInput = forwardRef((props, ref) => {
  const [nowClickedType, setNowClickedType] = useState('Group');

  const OnlineButtonRef = useRef();
  const OnlineTextRef = useRef();
  const OfflineButtonRef = useRef();
  const OfflineTextRef = useRef();

  useImperativeHandle(ref, () => ({
    setValue: (val) => {
      setNowClickedType(val);
    },
		getValue: () => {
			return nowClickedType;
		}
	}));

  useEffect(() => {
    if (nowClickedType === '1:1') {
      OnlineButtonRef.current.style.backgroundColor = `#FF8B48`;
      OnlineTextRef.current.style.color = `#FFFFFF`;
      OfflineButtonRef.current.style.backgroundColor = `#FFFFFF`;
      OfflineTextRef.current.style.color = `#CBCBCB`;
    }
    else if (nowClickedType === 'Group') {
      OnlineButtonRef.current.style.backgroundColor = `#FFFFFF`;
      OnlineTextRef.current.style.color = `#CBCBCB`;
      OfflineButtonRef.current.style.backgroundColor = `#FF8B48`;
      OfflineTextRef.current.style.color = `#FFFFFF`;
    }
  }, [nowClickedType])

  return(
    <TopLevelWrapper>
      <Button ref={OfflineButtonRef} onClick={() => setNowClickedType('Group')}>
        <ButtonText ref={OfflineTextRef}>Group</ButtonText>
      </Button>
      <Button ref={OnlineButtonRef} onClick={() => setNowClickedType('1:1')}>
        <ButtonText ref={OnlineTextRef}>1:1</ButtonText>
      </Button>
    </TopLevelWrapper>
  )
})

export default ClassSizeInput;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direcion: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
  margin-top: 8px;
`;

const Button = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 167px;
  height: 30px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;

  background-color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const ButtonText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;

  margin: 0 0 0 0;
  
  transition: all 0.3s ease-in-out;
`;