import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import moment from "moment";

export default function ProvisionTextContent(props) {
  const { 
    index, 
    data, 
    lastIndex,
    onClickModifyProvision,
    onClickDeleteProvision
  } = props;

  const [height, setHeight] = useState(0);
  const [modifyFontColor, setModifyFontColor] = useState('#595959');
  const [deleteFontColor, setDeleteFontColor] = useState('#595959');
  const [borderColor, setBolderColor] = useState('#DBDBDB');
  const [backgroundColor, setBackgroundColor] = useState('#FFFFFF');

  const componentRef = useRef(null);

  // 컴포넌트 높이 계산 //
  useEffect(() => {
    const handleResize = (entries) => {
      for (let entry of entries) {
        setHeight(entry.contentRect.height); // 변경된 높이를 상태에 저장
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);

    if (componentRef.current) {
      resizeObserver.observe(componentRef.current); // ref를 사용하여 관찰 시작
    }

    // Cleanup
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  // 

  // 수정하기 버튼 마우스 호버링 시작 //
  function onMouseOverModifyButton() {
    setBolderColor('#6DD49E');
    setModifyFontColor('#6DD49E');
    setBackgroundColor('#F0FBF5');
  }

  // 수정하기 버튼 마우스 호버링 끝 //
  function onMouseLeaveModifyButton() {
    setBolderColor('#DBDBDB');
    setModifyFontColor('#595959');
    setBackgroundColor('#FFFFFF');
    // F0FBF5
  }

  // 삭제하기 버튼 마우스 호버링 시작 //
  function onMouseOverDeleteButton() {
    setBolderColor('#EE0000');
    setDeleteFontColor('#FF3131');
    setBackgroundColor('#FEF2F2');
  }

  // 삭제하기 버튼 마우스 호버링 끝 //
  function onMouseLeaveDeleteButton() {
    setBolderColor('#DBDBDB');
    setDeleteFontColor('#595959');
    setBackgroundColor('#FFFFFF');
  }

  return (
    <TopLevelWrapper 
      ref={componentRef} 
      isFirst={index === 0} 
      isLast={index === lastIndex}
      borderColor={borderColor}
      backgroundColor={backgroundColor}>
      <HistoryTextWrapper style={{ justifyContent: 'center' }} width={93}>
        <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#595959' hover='default'>{data.title}</Text>
      </HistoryTextWrapper>
      <DivisionLine height={height} backgroundColor='#DBDBDB' />
      <HistoryTextWrapper width={817}>
        <PreText fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='default'>{data.text}</PreText>
      </HistoryTextWrapper>
      <DivisionLine height={height} backgroundColor='#DBDBDB' />
      <Button 
        width={90} 
        height={height} 
        onMouseOver={onMouseOverModifyButton}
        onMouseLeave={onMouseLeaveModifyButton}
        onClick={() => onClickModifyProvision(data)}>
        <Text fontFamily='NotoSansKR-Regular' fontSize={14} color={modifyFontColor} hover='pointer'>수정하기</Text>
      </Button>
      <DivisionLine height={height} backgroundColor='#DBDBDB' />
      <Button 
        width={90} 
        height={height}
        onMouseOver={onMouseOverDeleteButton}
        onMouseLeave={onMouseLeaveDeleteButton}
        onClick={() => onClickDeleteProvision(data)}>
        <Text fontFamily='NotoSansKR-Regular' fontSize={14} color={deleteFontColor} hover='pointer'>삭제하기</Text>
      </Button>
    </TopLevelWrapper>
  );
}

const TopLevelWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: calc(100% - 4px);

  // border-top: ${props => props.isFirst ? '0px' : '1px solid #969696'};

  
  // border: 1px solid #969696;
  // border-radius: 10px;

  border-top: ${props => props.isFirst ? '2px solid ' + props.borderColor : '1px solid ' + props.borderColor};
  border-bottom: ${props => props.isLast ? '2px solid ' + props.borderColor : '1px solid ' + props.borderColor};
  border-right: ${props => '2px solid ' + props.borderColor};
  border-left: ${props => '2px solid ' + props.borderColor};

  border-top-left-radius: ${props => props.isFirst ? '10px' : '0px'};
  border-top-right-radius: ${props => props.isFirst ? '10px' : '0px'};
  border-bottom-left-radius: ${props => props.isLast ? '10px' : '0px'};
  border-bottom-right-radius: ${props => props.isLast ? '10px' : '0px'};

  background-color: ${props => props.backgroundColor};

  transition: all 0.3s ease-in-out;
`;

const HistoryTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  padding: 10px 8px;

  width: ${props => props.width}px;
`;

const DivisionLine = styled.div`
  width: 2px;
  height: ${props => props.height}px;

  background-color: ${props => props.backgroundColor};
`;

const Button = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  padding: 0px 8px;

  width: ${props => props.width}px;
  height: ${props => props.height}px;

  :hover {
    cursor: pointer;
  }
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;

const PreText = styled.pre`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  white-space: pre-wrap;

  :hover {
    cursor: ${props => props.hover};
  }
`;