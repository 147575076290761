import { React } from 'react';
import styled from 'styled-components';

export default function NewClassSizeInput(props) {
  const { lessonSize, setLessonSize } = props;

  return(
    <TopLevelWrapper>
      <Button isClicked={lessonSize === '1:1'} onClick={() => setLessonSize('1:1')}>
        <ButtonText isClicked={lessonSize === '1:1'}>1 : 1</ButtonText>
      </Button>
      <Button isClicked={lessonSize === 'Group'} onClick={() => setLessonSize('Group')}>
        <ButtonText isClicked={lessonSize === 'Group'}>그룹</ButtonText>
      </Button>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direcion: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const Button = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: calc(49.34% - 2px);
  height: 30px;

  border: ${props => props.isClicked ? `1px solid #FF8B48` : `1px solid #DFDFDF`};
  border-radius: 8px;

  background-color: ${props => props.isClicked ? `#FF8B48` : `#FFFFFF`};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const ButtonText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: ${props => props.isClicked ? `#FFFFFF` : `#CBCBCB`};

  margin: 0 0 0 0;
  
  transition: all 0.3s ease-in-out;
`;