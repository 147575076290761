import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Document, Page, pdfjs } from 'react-pdf';

import NextButton from '../../../../../image/PDFNextButton.png';
import PrevButton from '../../../../../image/PDFPrevButton.png';

export default function CenterTermsPreviewModal(props) {
  const { terms, closeFn } = props;

  const [isFirst, setIsFirst] = useState(true);
  const [totalNumberPage, setTotalNumberPage] = useState(0);
  const [nowPage, setNowPage] = useState(1);

  // PDF Viewer Setting //
  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, [])

  // 모달 스크롤 제어 //
  useEffect(() => {
    document.body.style= `overflow: hidden`;
    return () => document.body.style = `overflow: auto`
  }, [])

  // 로딩 완료시 총 페이지 수 받아오기 //
  function onLoadSuccess({ numPages }) {
    if (!isFirst) return;

    setTotalNumberPage(numPages);

    if (numPages !== 0) setNowPage(1);

    setIsFirst(false);
  }

  // 가입 약관 이전 버튼 클릭시 //
  function onClickPrev() {
    if (nowPage === 1) return;

    setNowPage(nowPage - 1);
  }

  // 가입 약관 다음 버튼 클릭시 //
  function onClickNext() {
    if (nowPage === totalNumberPage) return;

    setNowPage(nowPage + 1);
  }

  return (
    <TopLevelWrapper>
      <Modal>
        <PageMoveButtonWrapper>
          <PageMoveButton onClick={onClickPrev}>
            <ButtonImage src={PrevButton}/>
          </PageMoveButton>
          <PageTextWrapper>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F' cursor='pointer'>{nowPage}</Text>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F' cursor='pointer'>/</Text>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F' cursor='pointer'>{totalNumberPage}</Text>
          </PageTextWrapper>
          <PageMoveButton onClick={onClickNext}>
            <ButtonImage src={NextButton}/>
          </PageMoveButton>
        </PageMoveButtonWrapper>
        <View>
          <Document
            // style={{ width: '425px', height: '600px' }}
            file={{ url: terms.termsUrl }}
            onLoadSuccess={onLoadSuccess}>
            <Page 
              width={390}
              height={550}
              pageNumber={nowPage} />
          </Document>
        </View>
        <ExitButtonWrapper>
          <ExitButton onClick={closeFn}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#CBCBCB' cursor='pointer'>닫기</Text>
          </ExitButton>
        </ExitButtonWrapper>
      </Modal>
    </TopLevelWrapper>
  )
}

const startAnimation = keyframes`
	0% { opacity: 0; }
	50% { opacity: 0.5; }
	100% { opacity: 1; }
`;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  justify-content: center;
  align-items: center;

  z-index: 1000;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.2);
	
	animation-name: ${startAnimation};
	animation-duration: 0.5s;
	animation-timing-function: ease-out;
`;

const Modal = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 28px;

  // width: 481px;
  // height: 656px;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(0, 0, 0, 0.1);
  background-color: #FFFFFF;
`;

const PageMoveButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  width: 100%;

  gap: 12px;
`;

const PageMoveButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 28px;
  height: 28px;

  :hover {
    cursor: pointer;
  }
`;

const PageTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
`;

const ButtonImage = styled.img`
  width: 28px;
  height: 28px;

  object-fit: cover;
`;

const View = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 248px;
  // height: 350px;

  // width: 425px;
  width: 390px;
  // height: 600px;
  height: 550px;
`;

const ExitButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: flex-start;

  margin-top: 36px;

  width: 100%;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 72px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;

  :hover {
    cursor: pointer;
  }
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};
  
  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }
`;