import { React, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';

import DefaultLogo from '../../image/DefaultLogo.png';
import RedTrash from '../../image/RedTrash.png';
// import RankingReplyTextArea from '../../Component/NoteComponent/RankingReplyTextArea';
import FetchModule from '../../Model/Share/Network/FetchModule';
import RankingCommentDeleteModal from '../../Model/Individual/NoteModel/RankingCommentDeleteModal';
import RankingReplyBox from './RenewalRankingReplyBox';
// import CommentDeleteModal from './CommentDeleteModal';

export default function RenewalRankingCommentBox(props) {
  const { data, index, rendering, commentRef, infoRef, setComments, onPressInput, isClickedViewCommentIndex, setIsClickedViewCommentIndex } = props;

  const history = useHistory();

  const [deleteClick, setDeleteClick] = useState(false);
  const [isClickedViewReplyInput, setIsClickedViewReplyInput] = useState(false);
  const [replyValue, setReplyValue] = useState('');

  const TopLevelRef = useRef();
  const RightRef = useRef();
  const LineRef = useRef();

  // 답글의 주체가 내가 아닌경우 감추기 //
  useEffect(() => {
    if (index !== isClickedViewCommentIndex) setIsClickedViewReplyInput(false);
  }, [index, isClickedViewCommentIndex])

  // 답글달기 클릭시 //
  function onClickViewComment(division) {
    // 댓글의 답글일 경우 확인 //
    if (division === 'comment') {
      if (isClickedViewReplyInput) {
        setIsClickedViewCommentIndex(-1);
        setIsClickedViewReplyInput(false);
      }
      else {
        setIsClickedViewCommentIndex(index);
        setIsClickedViewReplyInput(true);
      }
    }
    // 답글의 답글일 경우 확인 //
    else {
      setIsClickedViewCommentIndex(index);
      setIsClickedViewReplyInput(true);
    }
  }

  // 댓글 삭제 클릭시 //
  function onClickCommentDeleteButton() {
    var requestData = {
      commentId: data.commentId
    } 

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataOfExercise('comment/delete_zone', 'POST', requestData);
        
        if (responseData.status === 200) {
          setComments(responseData.data);
          setReplyValue('');
          setIsClickedViewReplyInput(false);
          setDeleteClick(false);
        }
        else if (responseData.status === 201) {
          alert('답글이 존재할 경우 삭제가 불가능합니다!\n답글을 삭제 후 다시시도해주세요!');
          setDeleteClick(false);
          setIsClickedViewReplyInput(false);
          setDeleteClick(false);
        }
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }

  // 답글 저장 클릭시 //
  function onClickReplySaveButton() {

    if (replyValue === '') {
      alert('답글 내용을 작성해주세요');
      return;
    }

    var requestData = {
      id: undefined,
      storeId: undefined,
      userId: undefined,
      commentId: data.commentId,
      text: replyValue
    } 

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataOfExercise('reply/save_zone', 'POST', requestData);
        
        if (responseData.status === 200) {
          setComments(responseData.data);
          setReplyValue('');
          setIsClickedViewReplyInput(false);
        }
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }

  return (
    <TopLevelWrapper ref={TopLevelRef}>
      {deleteClick && <RankingCommentDeleteModal isComment={true} deleteFn={onClickCommentDeleteButton} exitClickFn={() => setDeleteClick(false)}/>}
      <TotalWrapper>
      <LeftWrapper>
        <Profile src={data.userProfile === '' ? DefaultLogo : data.userProfile}/>
      </LeftWrapper>
      <MemberInfoWrapper ref={RightRef}>
        <NameWrapper>
          <NameInfoWrapper>
            <Name>{data.userName}</Name>
            <Info>{data.storeId !== 0 ? '코치' : '회원'}</Info>
          </NameInfoWrapper>
          <ReplyDeleteButton onClick={() => setDeleteClick(true)}>
            <TrashImage src={RedTrash}/>
          </ReplyDeleteButton>
        </NameWrapper>
        <TextWrapper>
          <Text>{data.text}</Text>
          {data.image && <Image src={data.image}/>}
        </TextWrapper>
        <BottomWrapper>
          <Date>{moment(data.createdDate).format('YYYY.MM.DD HH:mm')}</Date>
          <ReplyButton  onClick={() => onPressInput(data.commentId)}>답글 달기</ReplyButton>
        </BottomWrapper>
      </MemberInfoWrapper>
      </TotalWrapper>
      {
        data.replyList.map((replyData, index) => (
          <RankingReplyBox 
            key={index} 
            data={replyData}
            setComments={setComments} 
            commentId={data.id} 
            isLast={data.replyList.length === index + 1} 
            onClickReplyViewButton={() => onClickViewComment('reply')}
            rendering={rendering} 
            commentRef={commentRef} 
            infoRef={infoRef}/>
        ))
      }
      {/* {
        isClickedViewReplyInput &&
        <ReplyInputWrapper>
          <InputWrapper>
            <RankingReplyTextArea value={replyValue} setValue={setReplyValue} placeholder='답글을 입력해주세요' />
            <ReplySaveButton onClick={onClickReplySaveButton}>
              <HoverText fontFamily='NotoSansKR-Regular' fontSize={14} color='#FFFFFF'>등록</HoverText>
            </ReplySaveButton>
          </InputWrapper>
        </ReplyInputWrapper>
      } */}
      {/* <DivisionLine /> */}
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 100%;
  padding: 16px 0 0 0;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;
  
  width: 94%;

  gap: 10px;
`;

const DivisionLine = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  // height: 1px;

  margin-top: 16px;
  margin-bottom: 16px;

  border: 1px solid #ECECEC;
`;

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 100%;
  width: 24px;
  height: 100%;

  // background-color: red;
`;

const LineWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  // background-color: red;
`;

const EmptyComponent = styled.div`
  width: 50%;
  height: 100%;
`;

const Line = styled.div`
  width: 50%;
  height: 100%;

  border-left: ${props => props.isView ? `2px solid #FFFFFF` : `2px solid #DFDFDF`};
`;

const MemberInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: calc(100% - 24px);
`;

const Profile = styled.img`
  width: 24px;
  height: 24px;

  border-radius: 24px;
`;

const NameWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const NameInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
`;

const Name = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #828282;

  margin: 0 0 0 0;
`;

const Info = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #828282;

  margin: 0 0 0 0;
`;

const State = styled.p`
  display: flex;
  font-family: NotoSansKR-Medium;
  font-size: 12px;

  color: #FF8B48;

  margin: 0 0 0 0;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 4px;
`;

const Text = styled.pre`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #828282;

  margin: 0 0 0 0;
`;

const Image = styled.img`
  width: 260px;
  height: 260px;
  
  object-fit: cover;
`;

const BottomWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: 8px;

  width: 100%;
  
  gap: 16px;
`;

const Date = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 10px;

  color: #828282;

  margin: 0 0 0 0;
`;

const ReplyButton = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 10px;

  color: #828282;

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;

const TrashImage = styled.img`
  width: 17px;
  height: 17px;
`;

const ReplyDeleteButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  :hover {
    cursor: pointer;
  }
`;

const ReplyInputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  width: 100%;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 94.16%;
`;

const ReplySaveButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 66px;
  height: 38px;
  
  border: 1px solid #FF8B48;
  border-radius: 15px;
  background-color: #FF8B48;

  :hover {
    cursor: pointer;
  }
`;

const HoverText = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  padding: 0 0 0 0;
  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;
