import { React, useRef, forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import CoachSettingInfoBox from './CoachSettingInfoBox';
import CoachAddModal from './Modal/CoachAddModal';

import FetchModule from '../../Share/Network/FetchModule';
import DeleteConfirmModal from './Modal/DeleteConfirmModal';
import NewCoachAddModal from './Modal/NewCoachAddModal';

const CoachSettingView = forwardRef((props, ref) => {
  const history = useHistory();

  const [isClicked, setIsClicked] = useState(false);
  const [isClickedDelete, setIsClickedDelete] = useState(false);
  const [data, setData] = useState([]);
  const [clickIndex, setClickIndex] = useState(-1);
  const [deleteData, setDeletedDate] = useState(undefined);
  const [rendering, setRendering] = useState(false);

  const TopLevelRef = useRef();

	useImperativeHandle(ref, () => ({
		setOpacity: (val) => {
			TopLevelRef.current.style.opacity = val;
		},
    setZindex: (val) => {
      TopLevelRef.current.style.zIndex = val;
    },
    setIsClicked: (val) => {
      setIsClicked(val);
    }
	}))

  // 렌더링시 데이터 호출하는 API
  useEffect(() => {
    const fetchModule = new FetchModule();
		(
			async () => {
				const responseData = await fetchModule.getData('coach/', 'GET');
        // console.log(responseData.data);
				if (responseData.status === 200) setData(responseData.data);
				else if (responseData.status === 401) {
          alert('로그인후 이용해주세요.');
          history.push('/');
        }
        else {}
			}
		)();
  }, [history, rendering])
  
  useEffect(() => {
    if (!isClicked) setClickIndex(-1);
  }, [isClicked])

  function modifyClick(index) {
    setClickIndex(index);
    setIsClicked(true);
  }

  function deleteClick(index) {
    setDeletedDate(data[index]);
    setIsClickedDelete(true);
    // const fetchModule = new FetchModule();
		// (
		// 	async () => {
		// 		const responseData = await fetchModule.deleteData('coach/delete/' + data[index].id, 'DELETE');
        
		// 		if (responseData.status === 200) setRendering(!rendering);
		// 		else if (responseData.status === 401) {
    //       alert("로그인후 사용해주세요.");
    //       history.push('/');
    //     }
    //     else if (responseData.status === 203) {
    //       alert("코치님이 진행하시는 수업이 존재합니다.\n수업 삭제 혹은 코치 변경 후 다시 시도해주세요");
    //     }
		// 	}
		// )();
  }

  function onClickDeleteYes() {
    const fetchModule = new FetchModule();
		(
			async () => {
				const responseData = await fetchModule.deleteData('coach/delete/' + deleteData.id, 'DELETE');
        
				if (responseData.status === 200) setRendering(!rendering);
				else if (responseData.status === 401) {
          alert("로그인후 사용해주세요.");
          history.push('/');
        }
        else if (responseData.status === 203) {
          alert("코치님이 진행하시는 수업이 존재합니다.\n수업 삭제 혹은 코치 변경 후 다시 시도해주세요");
        }

        setIsClickedDelete(false);
        setDeletedDate(undefined);
			}
		)();
  }

  return (
    <TopLevelWrapper ref={TopLevelRef}>
      {/* { isClicked && <CoachAddModal data={data} index={clickIndex} exitClickFn={() => setIsClicked(false)} rendering={() => setRendering(!rendering)}/> } */}
      { isClicked && <NewCoachAddModal data={data} index={clickIndex} exitClickFn={() => setIsClicked(false)} rendering={() => setRendering(!rendering)} /> }
      { isClickedDelete && <DeleteConfirmModal title='코치 데이터 삭제' itemName={deleteData === undefined ? 'None' : (deleteData.name + ' 코치님')} clickNoFn={() => setIsClickedDelete(false)} clickYesFn={onClickDeleteYes}/> }
      {
        data.map((data, index) => (
          <CoachSettingInfoBox key={index} data={data} index={index} modifyClickFn={modifyClick} deleteClickFn={deleteClick}/>
        ))
      }
    </TopLevelWrapper>
  )
})

export default CoachSettingView;

const TopLevelWrapper = styled.div`
  // position: relative;
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;

  // width: 100%;
  width: 1020px;
  min-height: 500px;

  gap: 30px;

  // opacity: 0;
  // z-index: -3000;

  // margin: -790px 0 545px 0;
  
  // margin-left: 34px;
  margin-bottom: 96px;

  transition: all 0.3s ease-in-out;
`;