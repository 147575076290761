import { React } from 'react';
import styled from 'styled-components';

import SearchImgPath from '../../image/SearchIcon.png';

export default function MemberSearchInput(props) {
	const { value, setValue, type } = props;

	function handleChange(e) {
		setValue(e.target.value);
	}

	function handleFocus(e) {
    
	}

	function handleBlur(e) {
	}
  
  return (
    <TopLevelWrapper>
      <Input
        img={SearchImgPath}
        type={type}
        placeholder='회원의 이름 또는 전화번호를 검색해주세요'
        value={'' || value}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur} />
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: flex-start;

  margin-top: 12px;

  width: 100%;
`;

const Input = styled.input`
	font-family: NotoSansKR-Regular;
	font-size: 12px;
	line-height: 20px;

  padding: 0 0 0 40px;
  width: calc(100% - 42px);
  height: 34px;

	outline: none;
  border: 1px solid #DFDFDF;
  border-radius: 8px;
	color: #000000;

  background: url(${props => props.img}) no-repeat 8px center;

	::placeholder {
		color: ${props => props.changedColor ? `#FF3131` : `#CBCBCB`};
	}
	
  transition: all 0.3s ease-in-out;
`;