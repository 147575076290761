import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';

import CalenderInput from '../../../Component/LockerComponent/LockerCalenderInput';
import RemainDaysInput from '../../../Component/LockerComponent/RemainDaysInput';
import UnitInput from '../../../Component/LockerComponent/UnitInput';

export default function LockerExtendsModal(props) {
  const { data, saveFn, closeFn } = props;

  const [days, setDays] = useState('');
  const [originalEndDate,setOriginalEndDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [placeholder, setPlaceholder] = useState('');
  const [cardPrice, setCardPrice] = useState(0);
  const [cashPrice, setCashPrice] = useState(0);

  // const StartCalenderRef = useRef();
  const EndCalenderRef = useRef();

  // Modal Page Scroll Controll //
  useEffect(() => {
    document.body.style= `overflow: hidden`;
    return () => document.body.style = `overflow: auto`;
  }, [])

  // Init Data Setting //
  useEffect(() => {
    setOriginalEndDate(data.endDate);
    setEndDate(moment(data.endDate));

    if (moment(data.endDate).format('YYYY.MM.DD') > moment().format('YYYY.MM.DD')) setPlaceholder((moment(data.endDate).diff(moment(), 'days') + 1) + ' 일 남음');
    else if (moment(data.endDate).format('YYYY.MM.DD') === moment().format('YYYY.MM.DD')) setPlaceholder('0');
    else return setPlaceholder((moment().diff(moment(data.endDate), 'days') + 1) + ' 일 초과중');
  }, [data])

  // 달력 클릭시 //
  function CalenderClick(className) {
    // if (className === 'StartCalender') EndCalenderRef.current.closeCalender();
    // else StartCalenderRef.current.closeCalender();
  }


  // Days 변경시 //
  function onChangeDays(value) {
    // console.log(value);
    // console.log(originalEndDate);

    setDays(value);

    if (value === '') setEndDate(moment(originalEndDate));
    else if (value > 0) setEndDate(moment().add(value - 1, 'day'));
  }

  // EndDate 변경시 //
  function onChangeEndDate(value) {
    // console.log(moment().format('YYYY.MM.DD'));
    // console.log(value.format('YYYY.MM.DD 23:59:59'));
    // console.log(moment(value.format('YYYY.MM.DD 23:59:59')).diff(moment(), 'days') + 1);
    setDays(moment(value.format('YYYY.MM.DD 23:59:59')).diff(moment(), 'days') + 1);
    setEndDate(value);
  }

  return (
    <TopLevelWrapper>
      <Modal>
        <ComponentWrapper>
          <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#232323' hover='default'>사용 연장</Text>
          <LayerWrapper style={{ marginTop: `15px` }}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='default'>락커 번호</Text>
            <InputBox>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#232323' hover='default'>{data.lockerName}</Text>
            </InputBox>
          </LayerWrapper>
          <LayerWrapper style={{ marginTop: `13px` }}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='default'>회원 이름</Text>
            <InputBox>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#232323' hover='default'>{data.userName}</Text>
            </InputBox>
          </LayerWrapper>
          <LayerWrapper style={{ marginTop: `13px` }}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='default'>추가 일수</Text>
            <DdayInputWrapper>
              {/* <DdayInputBox>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#232323' hover='default'>{moment(endDate).format('YYYY.MM.DD') > moment().format('YYYY.MM.DD') ? (moment(endDate).diff(moment(), 'days') + 1) : '일수 초과'}</Text>
              </DdayInputBox> */}
              <RemainDaysInput value={days} setValue={onChangeDays} placeholder={placeholder}/>
              {/* <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#777777' hover='default'>일</Text> */}
            </DdayInputWrapper>
          </LayerWrapper>
          <LayerWrapper style={{ marginTop: `13px` }}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='default'>종료일</Text>
            <CalenderInput ref={EndCalenderRef} value={endDate} setValue={onChangeEndDate} disabled={false} className='EndCalender' clickFn={CalenderClick} placeholder='종료일' width={181} />
          </LayerWrapper>
          <Text style={{ marginTop: '21px' }} fontFamily='NotoSansKR-Bold' fontSize={16} color='#232323' hover='default'>추가 금액</Text>
          <LayerWrapper style={{ marginTop: `15px` }}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>카드</Text>
            <DdayInputWrapper>
              <UnitInput value={cardPrice} setValue={setCardPrice} unit='원'/>
            </DdayInputWrapper>
          </LayerWrapper>
          <LayerWrapper style={{ marginTop: `13px` }}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>현금</Text>
            <DdayInputWrapper>
              <UnitInput value={cashPrice} setValue={setCashPrice} unit='원'/>
            </DdayInputWrapper>
          </LayerWrapper>
          <ButtonWrapper>
            <ExitButton onClick={closeFn}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#CBCBCB' hover='pointer'>취소</Text>
            </ExitButton>
            <SaveButton onClick={() => saveFn(endDate, cardPrice, cashPrice)}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' hover='pointer'>저장하기</Text>
            </SaveButton>
          </ButtonWrapper>
        </ComponentWrapper>
      </Modal>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 1000;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.3);

  transition: all 0.3s ease-in-out;
`;

const Modal = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 323px;
  // height: 337px;
  height: 456px;

  border-radius: 30px;
  background-color: #FFFFFF;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 82.66%;
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const InputBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  padding: 0px 8px 0px 8px;

  width: calc(68.53% - 18px);
  height: 30px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background-color: #ECECEC;
`;

const DdayInputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 68.53%;

  // gap: 5px;
`;

const DdayInputBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  padding: 0px 8px 0px 8px;

  width: calc(90.71% - 18px);
  height: 30px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background-color: #ECECEC;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  width: 100%;

  gap: 16px;

  margin-top: 36px;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 72px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;

  :hover {
    cursor: pointer;
  }
`;

const SaveButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 15px;
  background-color: #6DD49E;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }

  transition: all 0.3s ease-in-out;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.hover};
  }
`;