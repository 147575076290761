import { React, useState, useEffect } from 'react';
import styled from 'styled-components';

import NormalSortButtonPath from '../../../image/NormalSortButton.png';
import AscSortButtonPath from '../../../image/AscSortButton.png';
import DescSortButtonPath from '../../../image/DescSortButton.png';

function MyMemberPaymentColumn(props) {
  const { sortButtonFn } = props;

  const [approvalButtonImg, setApprovalButtonImg] = useState(AscSortButtonPath);
  const [approvalDateButtonImg, setApprovalDateButtonImg] = useState(AscSortButtonPath);

  const [approvalSort, setApprovalSort] = useState('Normal');
  const [approvalDateSort, setApprovalDateSort] = useState('Normal');

  useEffect(() => {
    if (approvalSort === 'Normal') setApprovalButtonImg(NormalSortButtonPath);
    else if (approvalSort === 'ASC') setApprovalButtonImg(AscSortButtonPath);
    else if (approvalSort === 'DESC') setApprovalButtonImg(DescSortButtonPath);
  }, [approvalSort])
  
  useEffect(() => {
    if (approvalDateSort === 'Normal') setApprovalDateButtonImg(NormalSortButtonPath);
    else if (approvalDateSort === 'ASC') setApprovalDateButtonImg(AscSortButtonPath);
    else if (approvalDateSort === 'DESC') setApprovalDateButtonImg(DescSortButtonPath);
  }, [approvalDateSort])

  function onClickApprovalSort() {
    setApprovalDateSort('Normal');

    if (approvalSort === 'Normal') {
      setApprovalSort('ASC');
      sortButtonFn('status', 'asc');
    }
    else if (approvalSort === 'ASC') {
      setApprovalSort('DESC');
      sortButtonFn('status', 'desc');
    }
    else if (approvalSort === 'DESC') {
      setApprovalSort('Normal');
      sortButtonFn('status', 'normal');
    }
  }

  function onClickApprovalDateSort() {
    setApprovalSort('Normal');

    if (approvalDateSort === 'Normal') {
      setApprovalDateSort('ASC');
      sortButtonFn('approvalDate', 'asc');
    }
    else if (approvalDateSort === 'ASC') {
      setApprovalDateSort('DESC');
      sortButtonFn('approvalDate', 'desc');
    }
    else if (approvalDateSort === 'DESC') {
      setApprovalDateSort('Normal');
      sortButtonFn('approvalDate', 'normal');
    }
  }

  return (
    <TopLevelWrapper>
      <SortingWrapper>
        <Column style={{ marginRight: '4px', marginLeft: '28px' }}>승인 날짜</Column>
        <SortingButton img={approvalDateButtonImg} onClick={() => onClickApprovalDateSort()} style={{marginRight: '49px'}}/>
      </SortingWrapper>
      <Column style={{marginRight: '43px'}}>결제자 성함</Column>
      <Column style={{marginRight: '36px'}}>결제 구분</Column>
      <Column style={{marginRight: '61px'}}>결제 방법</Column>
      <Column style={{marginRight: '263px'}}>결제 내역</Column>
      <Column style={{marginRight: '64px'}}>최종 결제 금액</Column>
      <SortingWrapper>
        <Column style={{ marginRight: '4px' }}>승인 여부</Column>
        <SortingButton img={approvalButtonImg} onClick={() => onClickApprovalSort()}/>
      </SortingWrapper>
    </TopLevelWrapper>
  )
}

export default  MyMemberPaymentColumn;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  // justify-content: center;
  
  // justify-content: center;
  align-items: center;

  width: 100%;
  margin-top: 38px;
`;

const Column = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;
`;

const SortingWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const SortingButton = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;

  background: url(${props => props.img}) no-repeat center center;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;
