import { React, useRef } from 'react';
import styled from 'styled-components';

const ClassInfoBox = (props) => {
	const { 
		data, 
		openDetailModal, 
		reserveData, 
		openReservationModal, 
		openReserveLockModal,
		onClickScheduleDetail, 
		clickFn } = props;

	const DetailRef = useRef();
	const ReserveRef = useRef();
	const ReserveLockRef = useRef();

	function onMouseOver() {
		if (data.classId === -1) return;

		DetailRef.current.style.color = `#FF8B48`;
		ReserveRef.current.style.color = `#FF8B48`;
		// ReserveLockRef.current.style.color = `#FF8B48`;
	}

	function onMouseLeave() {
		if (data.classId === -1) return;
		
		DetailRef.current.style.color = `#FFFFFF`;
		ReserveRef.current.style.color = `#FFFFFF`;
		// ReserveLockRef.current.style.color = `#FFFFFF`;
	}


	return (
		<TopLevelWrapper color={data.color} onClick={clickFn} onMouseOver={onMouseOver} onMouseLeave={onMouseLeave} >
			<WhiteBox>
				<TextWrapper>
					<ClassName>{data.className}</ClassName>
					{
						data.classId === -1 ?
							<InfoWrapper>
								<InfoBox>
									<TimeText>{data.startTime} ~ {data.endTime}</TimeText>
								</InfoBox>
							</InfoWrapper>
							:
							<InfoWrapper>
								<InfoBox>
									<TimeText>{data.startTime} ~ {data.endTime}</TimeText>
									<Division />
									{/* <Number>예약 {reserveData && reserveData.length}명</Number> */}
									<Number>예약 {reserveData === undefined ? '0' : reserveData.count}명</Number>
									<Division />
									<TrainerName>{data.teacherName}</TrainerName>
								</InfoBox>
								<ButtonWrapper>
									<Button ref={DetailRef} onClick={onClickScheduleDetail}>수업 상세정보</Button>
									<Button ref={ReserveRef} onClick={openReservationModal}>예약</Button>
									{/* <Button ref={ReserveLockRef} onClick={openReserveLockModal}>예약 잠금</Button> */}
								</ButtonWrapper>
							</InfoWrapper>
					}
				</TextWrapper>
			</WhiteBox>
		</TopLevelWrapper>
	)
}

export default ClassInfoBox;

const TopLevelWrapper = styled.div`
	display: flex;
	flex-direction: row;

	justify-content: flex-end;
	align-items: center;

	// width: ${props => props.isOver ? `max(583px, 75.91145833333333vw)` : `max(238px, 74.375vw)`};
  width: 913px;
	// height: ${props => props.isOver ? `66px` : `76px`};
  height: 68px;

	border: 1px solid #ECECEC;
	// border-radius: 15px;
	border-top-left-radius: 4px;
	border-top-right-radius: 15px;
	border-bottom-right-radius: 15px;
	border-bottom-left-radius: 4px;

	background-color: #ECECEC;
	
  transition: all 0.3s ease-in-out;
  
	:hover {
		// background-color: #5BD98A;
		// border: 1px solid #5BD98A;
		
		background-color: ${props => props.color};
		border: 1px solid ${props => props.color};
	}	
`;

const WhiteBox = styled.div`
	display: flex;
	flex-direction: column;

	justify-content: center;
	align-items: flex-start;

	margin-right: 1px;

  // width: 893px;
  width: 892px;
  height: 66px;

	// border-radius: 0 15px 15px 0;
	border-radius: 0 13px 13px 0;
	background-color: #FFFFFF;
`;

const TextWrapper = styled.div`
	display: flex;
	flex-direction: column;

	justify-content: center;
	align-items: flex-start;

	gap:4px;

  margin-left: 15px;
`;

const ClassName = styled.p`
	font-family: NotoSansKR-Medium;
  font-size: 14px;

	color: #232323;

	margin: 0 0 0 0;
`;

const InfoWrapper = styled.div`
	display: flex;
	flex-direction: row;
	
	justify-content: space-between;
  align-items: center;

  width: 862px;
`;

const InfoBox = styled.div`
	display: flex;
  flex-direction: row;

	justify-content: center;
  align-items: center;
`;

const ButtonWrapper = styled.div`
	display: flex;
	flex-direction: row;

	justify-content: center;
	align-items: center;

  gap: 16px;

	:hover {
		cursor: pointer;
	}
`;

const TimeText = styled.p`
	font-family: Poppins-Regular;
  font-size: 12px;

	color: #777777;

	margin: 0 0 0 0;
`;

const Division = styled.div`
	display: flex;
	flex-direction: column;

	justify-content: center;
	align-items: center;

	width: 1px;
	height: 9px;

	margin: 0 8px 0 8px;

	border-right: 1px solid #969696;
`;

const Number = styled.p`
	font-family: NotoSansKR-Regular;
  font-size: 12px;

	color: #777777;
	
	margin: 0 0 0 0;
`;


const TrainerName = styled.p`
	font-family: NotoSansKR-Regular;
  font-size: 12px;

	color: #777777;
	
	margin: 0 0 0 0;
`;

const Button = styled.p`
	font-family: NotoSansKR-Medium;	
  font-size: 12px;

	color: #FFFFFF;

	margin: 0 0 0 0;

  transition: all 0.3s ease-in-out;
  
	:hover{
		cursor: pointer;
		color: #FF7322;
	}
`;