import React from "react";
import styled from "styled-components";

export default function DayOfWeekBox(props) {
  const { isMinimum } = props; 

  return (
    <DayOfTheWeekBoxWrapper>
      <DayOfTheWeekBox>
        <Text fontFamily='NotoSansKR-Regular' fontSize={isMinimum ? 12 : 18} color='#000000' hover='default'>Sun</Text>
      </DayOfTheWeekBox>
      <DayOfTheWeekBox>
        <Text fontFamily='NotoSansKR-Regular' fontSize={isMinimum ? 12 : 18} color='#000000' hover='default'>Mon</Text>
      </DayOfTheWeekBox>
      <DayOfTheWeekBox>
        <Text fontFamily='NotoSansKR-Regular' fontSize={isMinimum ? 12 : 18} color='#000000' hover='default'>Tue</Text>
      </DayOfTheWeekBox>
      <DayOfTheWeekBox>
        <Text fontFamily='NotoSansKR-Regular' fontSize={isMinimum ? 12 : 18} color='#000000' hover='default'>Wed</Text>
      </DayOfTheWeekBox>
      <DayOfTheWeekBox>
        <Text fontFamily='NotoSansKR-Regular' fontSize={isMinimum ? 12 : 18} color='#000000' hover='default'>Thu</Text>
      </DayOfTheWeekBox>
      <DayOfTheWeekBox>
        <Text fontFamily='NotoSansKR-Regular' fontSize={isMinimum ? 12 : 18} color='#000000' hover='default'>Fri</Text>
      </DayOfTheWeekBox>
      <DayOfTheWeekBox>
        <Text fontFamily='NotoSansKR-Regular' fontSize={isMinimum ? 12 : 18} color='#000000' hover='default'>Sat</Text>
      </DayOfTheWeekBox>
    </DayOfTheWeekBoxWrapper>
  )
}

const DayOfTheWeekBoxWrapper = styled.div`
  // position: absolute;
  display: flex;
  flex-direction: row;

  justify-content: space-evenly;
  align-items: flex-start;

  width: 100%;

  background-color: #FDFCFB;
`;

const DayOfTheWeekBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 13.6%;
  height: 22px;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  
  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;